import { useState, useCallback } from 'react';

export default function useFetch(requestFunction, stateSetter) {
	const [data, setData] = useState(null);
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);

	const request = useCallback(
		async (...args) => {
			// Set our values to a known state and indicate busy
			setData(null);
			setError(false);
			setLoading(true);

			let _data;
			let _error;

			// Get the cancel token in case we need to clean up
			try {
				_data = await requestFunction(...args);
				setData(_data);

				if (stateSetter) {
					stateSetter(_data);
				}
			} catch (error) {
				_error = error;
				setError(error);
			} finally {
				setLoading(false);
			}

			// Return response data/error and OK status
			return {
				...(!!_data && { data: _data }),
				...(!!_error && { error: _error }),
				ok: !_error,
			};
		},
		[stateSetter, requestFunction]
	);

	return { data, error, loading, request };
}
