import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { useNavContext } from '../../context/ContextManager';
import _ from 'lodash';
import { useSnackbar } from 'notistack';

// Data for landing page selection table and contextual navigation
import beacon_admin_options from './BeaconAdminOptions';
import beacon_admin_nav_item from './BeaconAdminNavItem';

import useFetch from '../../hooks/useFetch';

import AddIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { BtFormContainer } from '../../components/generic/forms';
import BtLoading from '../../components/generic/BtLoading';
import BtSelectionTable from '../../components/generic/BtSelectionTable';
import BtTableSkeleton from '../../components/generic/BtTableSkeleton';
import NewOrganisationDialog from './NewOrganisationDialog';

import { platformAdminOrganisationDeleteSubscription } from '../../API';
import BtProgressBar from '../../components/generic/BtProgressBar';
import BtInfoChip from '../../components/generic/BtInfoChip';
import SubscriptionAddEditDialog from './SubscriptionAddEditDialog';
import { IconButton } from '@mui/material';
import { Delete } from 'mdi-material-ui';
import BtConfirmDialog from '../../components/generic/BtConfirmDialog';

const tableColumns = [
	{ field: 'subscription', text: 'Subscription' },
	{ field: 'description', text: 'Description', minBreakpoint: 'sm' },
	{ field: 'renewalDate', text: 'Renewal Date', minBreakpoint: 'lg' },
	{ field: 'renewalCost', text: 'Renewal Cost', minBreakpoint: 'sm' },
	{ field: 'assignment', text: 'Assignment' },
	{ field: 'remove', text: 'Remove' },
];

export default function OrganisationSubscriptions({
	orgUuid,
	subscriptions,
	refreshSubscriptions,
}) {
	const { enqueueSnackbar } = useSnackbar();
	const [dialogOpen, setDialogOpn] = useState(false);
	const [confirmRemoveOpen, setConfirmRemoveOpen] = useState(false);
	const [subToRemove, setSubToRemove] = useState(null);
	const [selectedSubscription, setSelectedSubscription] = useState(null);

	const handleSubscriptionSelect = subUuid => {
		const sub = _.find(subscriptions, { uuid: subUuid });
		if (sub) {
			setSelectedSubscription(sub);
		}
		setDialogOpn(true);
	};

	const handleSubscriptionAdd = () => {
		setSelectedSubscription(null);
		setDialogOpn(true);
	};

	const handleSubscriptionRemove = async () => {
		try {
			console.log('handleSubscriptionRemove', subToRemove);
			await platformAdminOrganisationDeleteSubscription({
				orgUuid: orgUuid,
				subUuid: subToRemove?.uuid,
			});
			enqueueSnackbar(
				`Removed subscription '${subToRemove.subscription}'`,
				{
					variant: 'success',
				}
			);
			refreshSubscriptions();
		} catch (error) {
			enqueueSnackbar(
				`Error removing subscription '${subToRemove.subscription}'`,
				{
					variant: 'error',
				}
			);
		}
	};

	return (
		<>
			<BtSelectionTable
				title={
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							flexWrap: 'wrap',
							gap: 10,
						}}
					>
						<Typography
							sx={{
								fontWeight: 'bold',
								padding: '4px 0',
							}}
							variant="h5"
						>
							Organisation Subscriptions
						</Typography>
						<Button
							variant="contained"
							onClick={handleSubscriptionAdd}
							startIcon={<AddIcon />}
							disableElevation
						>
							Add Subscription
						</Button>
					</div>
				}
				subject="collections"
				columns={tableColumns}
				onClick={handleSubscriptionSelect}
				data={(subscriptions || []).map(
					({
						uuid,
						subscription,
						description,
						type,
						renewalDate,
						renewalCost,
						assigned,
						maxUsers,
					}) => ({
						id: uuid,
						subscription: subscription,
						description: description,

						assignment:
							type === 'user' ? (
								<BtProgressBar
									value={assigned}
									goal={maxUsers}
								/>
							) : (
								<BtInfoChip text="Everyone" showIcon={false} />
							),
						renewalDate: format(
							new Date(renewalDate || 0),
							'dd MMM yyyy'
						),
						renewalCost: renewalCost,

						remove: (
							<IconButton
								size="small"
								// onClick={handleSubscriptionRemove(uuid)}
								onClick={event => {
									event.stopPropagation();
									setConfirmRemoveOpen(true);
									setSubToRemove({
										uuid: uuid,
										subscription: subscription,
									});
								}}
								color="error"
							>
								<Delete />
							</IconButton>
						),
						route: `/BeaconAdmin/Organisation/${uuid}`,
					})
				)}
			/>

			<SubscriptionAddEditDialog
				orgUuid={orgUuid}
				subscription={selectedSubscription}
				onClose={() => {
					setDialogOpn(false);
				}}
				open={dialogOpen}
				refreshSubscriptions={refreshSubscriptions}
				currentSubscriptions={(subscriptions || []).map(({ uuid }) => {
					return uuid;
				})}
			/>

			<BtConfirmDialog
				title="Remove Subscription"
				action={handleSubscriptionRemove}
				ActionIcon={<Delete />}
				onClose={() => {
					setConfirmRemoveOpen(false);
				}}
				isDestructive={true}
				open={confirmRemoveOpen}
				prompt={`Are you sure you wish to remove the '${
					subToRemove?.subscription
				}' subscription?`}
				verb="Remove"
			/>
		</>
	);
}
