import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';

import {
	DialogActions,
	DialogContent,
	DialogTitle,
	Input,
	Typography,
} from '@mui/material';

import BtError from '../../../../components/generic/BtError';
import BtLoading from '../../../../components/generic/BtLoading';
import FilterIcon from '@mui/icons-material/FilterList';
import useFetch from '../../../../hooks/useFetch';
import {
	useWizard,
	WizardButtons,
	WizardHeading,
} from '../../../../components/wizard';
import { ScreenDistributionWizardContext } from './ScreenDistributionWizardContext';
import useGetTagGroupIdByName from '../../../../hooks/useGetTagGroupIdByName';
import { ScreenDistributionGroup } from './ScreenDistributionGroup';

const isSelected = (target, data, type) => {
	switch (data.method) {
		case 'assignTo':
			return data.assignTo
				.find(({ screen_group }) => screen_group === data.screenGroup)
				[type].includes(target);
		case 'availableTo':
			return data[data.method][type].includes(target);
		default:
			break;
	}
};

export default function DistributionTargets({
	userAdminGetList,
	roleGetList,
	tagGroupGet,
	sending,
	handleAssign,
	handleAvailable,
}) {
	const activeRef = useRef(null);
	const contentRef = useRef(null);
	const blockAutoScroll = useRef(null);

	const { data, onClose, setData, resetTargets } = useContext(
		ScreenDistributionWizardContext
	);

	const tagGroupId = useGetTagGroupIdByName('User Groups');

	const {
		loading: userListLoading,
		data: userList,
		error: userListError,
		request: getUserList,
	} = useFetch(userAdminGetList);

	useEffect(
		() => {
			if (!userList) {
				getUserList();
			}
		},
		[getUserList, userList]
	);

	const {
		loading: rolesLoading,
		data: rolesList,
		error: rolesError,
		request: getRoles,
	} = useFetch(roleGetList);

	useEffect(
		() => {
			getRoles();
		},
		[getRoles]
	);

	const {
		loading: groupsLoading,
		data: groupsList,
		error: groupsError,
		request: getGroups,
	} = useFetch(tagGroupGet);

	useEffect(
		() => {
			getGroups({ tagGroupUuid: tagGroupId });
		},
		[getGroups, tagGroupId]
	);

	const { goToStep, isFirstStep, isLastStep } = useWizard();

	const [searchTerm, setSearchTerm] = useState('');

	useEffect(() => {
		// Auto-scroll to the selected method
		if (
			!blockAutoScroll?.current &&
			!!data.method &&
			!!activeRef?.current &&
			!searchTerm
		) {
			activeRef.current.scrollIntoView({ block: 'center' });
			blockAutoScroll.current = true;
		}
	});

	useEffect(
		() => {
			// Scroll to the top when filtering
			if (searchTerm && contentRef) {
				contentRef.current.scrollTo({ top: 0, left: 0 });
				blockAutoScroll.current = false;
			}
		},
		[searchTerm]
	);

	const targetTypes = useMemo(
		() => {
			if (userList && groupsList && rolesList) {
				const targetTypes = [
					{
						id: 'users',
						name: 'Users',
						targets: userList.map(({ user_name, uuid }) => ({
							name: user_name,
							uuid: uuid,
						})),
					},
					{
						id: 'userGroups',
						name: 'User Groups',
						targets: groupsList.values.map(({ uuid, name }) => ({
							name: name,
							uuid: uuid,
						})),
					},
					{
						id: 'roles',
						name: 'Roles',
						targets: rolesList.map(({ uuid, name }) => ({
							name: name,
							uuid: uuid,
						})),
					},
				].map(({ id, name, targets }) => ({
					id: id,
					name: name,
					targets: targets,
				}));
				console.log({ targetTypes, userList });
				return targetTypes;
			}
		},
		[userList, groupsList, rolesList]
	);

	const filteredTargetTypes = useMemo(
		() => {
			if (Array.isArray(targetTypes)) {
				const term = (searchTerm || '').toLowerCase().trim();

				const tempTargetTypes = targetTypes.reduce(
					(accumulator, type) => {
						const composePermission = targets => [
							...accumulator,
							{ ...type, targets },
						];

						const targets = type.targets;

						if (targets.length === 0) return [...accumulator];

						// If search term of group name matches
						if (
							!searchTerm ||
							type.name.toLowerCase().includes(term)
						) {
							return composePermission(targets);
						}

						// Filter down methods in the group
						const filteredTargets = targets.filter(target => {
							const name = target.name;
							return (name || '').toLowerCase().includes(term);
							// (description || '').toLowerCase().includes(term)
						});

						// If any methods remain include the group...
						if (filteredTargets.length > 0) {
							return composePermission(filteredTargets);
						}

						// ...otherwise drop the group entirely
						return [...accumulator];
					},
					[]
				);
				return tempTargetTypes;
			}
			return [];
		},
		[searchTerm, targetTypes]
	);

	const handleTargetSelect = useCallback(
		(target, type) => {
			const tempData = structuredClone(data);

			// method type switch
			switch (data.method) {
				case 'assignTo':
					// clone the group assign to definition
					const newGroup = structuredClone(
						data.assignTo.find(
							({ screen_group }) =>
								screen_group === data.screenGroup
						)
					);

					// close the assignTo definition with selected screen group definition removed
					let newAssignTo = [
						...data.assignTo.filter(
							({ screen_group }) =>
								screen_group !== data.screenGroup
						),
					];
					// assess if selected or not
					if (isSelected(target, data, type)) {
						// remove the target from the assignees array
						newGroup[type] = newGroup[type].filter(
							uuid => uuid !== target
						);
					} else {
						// insert new target
						newGroup[type] = [...newGroup[type], target];
					}
					// insert updated group into assignTo definition
					newAssignTo.push(newGroup);

					// update output with updated assignTo
					tempData.assignTo = newAssignTo;
					break;
				case 'availableTo':
					// set select/deselect state by adding or removing the target uuid from the state array
					if (isSelected(target, data, type)) {
						let newAvailableType = data.availableTo[type].filter(
							id => id !== target
						);
						tempData.availableTo[type] = newAvailableType;
					} else {
						let newAvailableType = [
							...data.availableTo[type],
							target,
						];
						tempData.availableTo[type] = newAvailableType;
					}
					break;
				default:
					break;
			}
			setData(tempData);
		},
		[data, setData]
	);

	const handleConfirm = useCallback(
		() => {
			switch (data.method) {
				case 'assignTo':
					handleAssign({
						screenGroup: data.screenGroup,
						assignees: data.assignTo.find(
							({ screen_group }) =>
								screen_group === data.screenGroup
						),
					});
					break;
				case 'availableTo':
					handleAvailable({
						availableTo: data.availableTo,
					});
					break;
				default:
					break;
			}
		},
		[data, handleAssign, handleAvailable]
	);

	const handleGoBack = useCallback(
		() => {
			if (data.method === 'availableTo') {
				resetTargets();
				goToStep(0);
			} else {
				resetTargets();
				goToStep(1);
			}
		},
		[data.method, goToStep, resetTargets]
	);

	if (userListError) {
		return (
			<BtError
				title="Retrieval Error"
				description="An error occurred when attempting to retrieve the list of users."
				action={() => userAdminGetList()}
				onClose={onClose}
				fullScreen={false}
			/>
		);
	}

	if (rolesError) {
		return (
			<BtError
				title="Retrieval Error"
				description="An error occurred when attempting to retrieve the list of roles."
				action={() => getRoles()}
				onClose={onClose}
				fullScreen={false}
			/>
		);
	}

	if (groupsError) {
		return (
			<BtError
				title="Retrieval Error"
				description="An error occurred when attempting to retrieve the list of groups."
				action={() => getGroups({ tagGroupUuid: tagGroupId })}
				onClose={onClose}
				fullScreen={false}
			/>
		);
	}

	return (
		<BtLoading
			loading={groupsLoading || rolesLoading || userListLoading}
			style={{ maxHeight: '400px', height: '100vh' }}
		>
			<DialogTitle>
				<WizardHeading
					onCancelClick={onClose}
					title={`Select users, roles and groups to ${
						data.method === 'assign'
							? 'assign the screen to'
							: 'make the screen available to'
					} `}
				>
					<Input
						fullWidth
						placeholder="Filter targets"
						autoFocus
						startAdornment={
							<FilterIcon style={{ marginRight: '0.2em' }} />
						}
						value={searchTerm}
						onChange={event => setSearchTerm(event.target.value)}
						style={{ margin: '1em 0 0.2em 0' }}
						error={filteredTargetTypes.length === 0}
						disabled={sending}
					/>
				</WizardHeading>
			</DialogTitle>
			<DialogContent ref={contentRef}>
				{filteredTargetTypes?.map(type => (
					<ScreenDistributionGroup
						key={type.id}
						activeRef={activeRef}
						type={type}
						onTargetSelect={(target, type) => {
							blockAutoScroll.current = true;
							handleTargetSelect(target, type);
						}}
						selectedTarget={data?.method}
						isSelected={isSelected}
						disabled={sending}
					/>
				))}

				{filteredTargetTypes?.length === 0 && (
					<div
						style={{
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							opacity: 0.8,
						}}
					>
						<Typography>No matches found</Typography>
					</div>
				)}
			</DialogContent>
			<DialogActions>
				<WizardButtons
					onCancelClick={onClose}
					isFirstStep={isFirstStep}
					isLastStep={isLastStep}
					onNextClick={handleConfirm}
					disabled={sending}
					onPreviousClick={() => handleGoBack()}
				/>
			</DialogActions>
		</BtLoading>
	);
}

DistributionTargets.propTypes = {
	userAdminGetList: PropTypes.func.isRequired,
	roleGetList: PropTypes.func.isRequired,
	tagGroupGet: PropTypes.func.isRequired,
	sending: PropTypes.bool,
	handleAssign: PropTypes.func.isRequired,
	handleAvailable: PropTypes.func.isRequired,
};

DistributionTargets.displayName = 'DistributionTargets';
