import React, { useCallback, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useAppContext } from '../../context/ContextManager';

import { useSnackbar } from 'notistack';

import { Container, Typography } from '@mui/material';

import BTAuthCard from './BTAuthCard';
import ChangePasswordForm from './ChangePasswordForm';

export default function ChangePassword(props) {
	const [sending, setSending] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const { userHasAuthenticated } = useAppContext();

	const handleFormSubmit = useCallback(
		async pwd => {
			try {
				setSending(true);
				await Auth.signIn(
					props.userProps.user,
					props.userProps.userToken
				)
					.then(user => {
						if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
							Auth.completeNewPassword(user, pwd) // OPTIONAL, the required attributes // the Cognito User Object // the new password
								.then(user => {
									userHasAuthenticated(true);
									props.changeSetPasswordResetRequired(false);

									enqueueSnackbar('Password changed.', {
										variant: 'success',
										anchorOrigin: {
											vertical: 'top',
											horizontal: 'center',
										},
									});
								})
								.catch(e => {
									enqueueSnackbar(
										'There was a problem, try resetting your password.',
										{
											variant: 'error',
											anchorOrigin: {
												vertical: 'top',
												horizontal: 'center',
											},
										}
									);
									console.log(e);
								});
						} else {
							// other situations
							enqueueSnackbar(
								'There was a problem, try resetting your password.',
								{
									variant: 'error',
									anchorOrigin: {
										vertical: 'top',
										horizontal: 'center',
									},
								}
							);
						}
					})
					.catch(e => {
						console.log(e);

						enqueueSnackbar(
							'There was a problem, try resetting your password.',
							{
								variant: 'error',
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'center',
								},
							}
						);
						// }
					});
			} catch (e) {
				enqueueSnackbar(
					'There was a problem, try resetting your password.',
					{
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'center',
						},
					}
				);
				console.log(e.message);
			}
		},
		[
			enqueueSnackbar,
			props.userProps.user,
			props.userProps.userToken,
			userHasAuthenticated,
		]
	);

	return (
		<BTAuthCard
			cardHeightOverride={'550px'}
			headingHeightOverride={true}
			heading={
				<Typography align="center">
					We need to change your password. Please choose a new
					password that meets the requirements, no spaces are
					allowed.' '{props.user_email}
				</Typography>
			}
			form={
				<Container maxWidth="md">
					<ChangePasswordForm
						handleFormSubmit={handleFormSubmit}
						user_email={props.userProps.user}
						sending={sending}
					/>
				</Container>
			}
		/>
	);
}
