const {
	ERROR,
	setOperator,
	evaluateExpression,
	OPERATION_MODE,
	getExpressionType,
	EXPRESSION,
} = require('../../expression.js');

const operatorKey = '$push';

setOperator(
	operatorKey,
	/**
	 * @type {ExpressionAccumulatorFunction<any, any[]>}
	 */
	(context, args, options) => {
		const arr = !Array.isArray(args) ? [args] : args;

		if (arr.length !== 1) {
			throw new Error(ERROR.INVALID_NUMBER_ARGS(operatorKey, 1, 1));
		}

		const value = evaluateExpression(context, arr[0]) ?? null;

		const acc = context.fieldValues || [];

		acc.push(value);
		context.fieldValues = acc;
		return acc;
	},
	[OPERATION_MODE.ACCUMULATE]
);
