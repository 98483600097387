const yup = require('yup');

const tagPostSchema = yup.object({
	tagGroupUuid: yup
		.string()
		.uuid()
		.required(),
	tagValueUuid: yup
		.string()
		.uuid()
		.required(),
	resourceUuid: yup
		.string()
		.uuid()
		.required(),
	resourceGroup: yup.string().required(),
});

const tagGetAppliedListSchema = yup.array().of(
	yup.object({
		name: yup.string().required(),
		organisation: yup
			.string()
			.uuid()
			.required(),
		tagGroupUuid: yup
			.string()
			.uuid()
			.required(),
		tagValueUuid: yup
			.string()
			.uuid()
			.required(),
		resourceUuid: yup
			.string()
			.uuid()
			.required(),
		resourceGroup: yup.string().required(),
	})
);

module.exports = {
	tagPostSchema,
	tagGetAppliedListSchema,
};
