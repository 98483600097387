import React, {
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';

import { BtTab, BtTabBar, BtTabPanel, a11yProps } from '../BtTabView';

import {
	getVariablesInUse,
	handleConfigSchemaTransform,
	queryBuilderReducer,
	queryBuilderReducerInit,
	traverseAndReplaceVariables,
} from './processing';
import {
	QueryBuilderDispatchContext,
	QueryBuilderStageContext,
	useQueryBuilderDataContext,
	useQueryBuilderVariableContext,
} from './context';
import BtQueryBuilderStage from './components/BtQueryBuilderStage';
import BtQueryBuilderFullQueryTextEditor from './components/BtQueryBuilderFullQueryTextEditor';
import { pipeline } from 'mongodb_query/src';
import BtQueryBuilderResourcePicker from './components/BtQueryBuilderResourcePicker';
import BtQueryBuilderStageActions from './components/BtQueryBuilderStageActions';

export default function BtQueryBuilder(props) {
	const {
		//
		onChange,
		//
		disabled,
		readOnly,
		disableStageRawInput,

		collectionRenderMode,
		//
		allowedStages,
		disableCollection,
		//
		initialQuery: _initialQuery,
		initialInputs,
		// props after these are used for live querying data.
		// not passing them will use the mongodb_query processing for each stage
		//
		// set to handle data queries yourself
		// must also set initialQuery and initialInputs
		onDataQuery,
	} = props;

	const initialQuery = useMemo(
		() => {
			if (typeof _initialQuery === 'string') {
				try {
					return JSON.parse(_initialQuery);
				} catch (err) {
					return [];
				}
			}

			return _initialQuery;
		},
		[_initialQuery]
	);

	const [currentStage, setCurrentStage] = useState(
		initialQuery.length ? initialQuery.length - 1 : 0
	);

	const [allowStageRawInput] = useState(!disableStageRawInput);

	const [currentTab, setCurrentTab] = useState(0);

	const {
		onDataQuery: providerOnDataQuery,
		resource,
		dataContextActive,
	} = useQueryBuilderDataContext();

	const { variables } = useQueryBuilderVariableContext();

	const [startInputs, setStartInputs] = useState({
		configSchema: dataContextActive
			? resource.configSchema
			: initialInputs?.configSchema,
		collection: dataContextActive
			? resource.collection
			: initialInputs?.collection,
	});

	const previousInitialInputs = useRef({}); // useRef({ ...startInputs });

	useEffect(
		() => {
			let newConfigSchema = initialInputs?.configSchema;
			let newCollection = initialInputs?.collection;
			if (dataContextActive) {
				newConfigSchema = resource.configSchema;
				newCollection = resource.collection;

				if (resource.configSchemaLoading) {
					return;
				} else if (resource.collectionLoading) {
					return;
				}
			}

			if (
				newConfigSchema !==
					previousInitialInputs.current.configSchema ||
				newCollection !== previousInitialInputs.current.collection
			) {
				setStartInputs({
					configSchema: newConfigSchema,
					collection: newCollection,
				});
			}
		},
		[dataContextActive, resource, initialInputs]
	);

	const [queryState, dispatchQueryStateEvent] = useReducer(
		queryBuilderReducer,
		{
			action: 'init',
			query: initialQuery,
			configSchema: startInputs.configSchema,
			collection: startInputs.collection,
			disableCollection: !!disableCollection,
		},
		queryBuilderReducerInit
	);

	const getStageResultData = useCallback(
		(stageId, fullQuery) => {
			let cancel = false;
			const inputSchema = queryState?.inputConfigSchema;
			const inputCollection = queryState?.inputCollection;
			const inputVariables = variables;

			if (!disableCollection) {
				dispatchQueryStateEvent({
					id: stageId,
					action: 'collectionRequested',
				});

				let collectionPromise = null;

				const q = traverseAndReplaceVariables(
					fullQuery,
					inputVariables
				);

				if (dataContextActive) {
					collectionPromise = providerOnDataQuery(q);
				} else if (onDataQuery) {
					collectionPromise = onDataQuery(q);
				} else {
					collectionPromise = pipeline(inputCollection, q);
				}

				if (collectionPromise) {
					collectionPromise
						.then(collection => {
							if (!cancel) {
								dispatchQueryStateEvent({
									id: stageId,
									action: 'collectionLoaded',
									collection: collection,
								});
							}
						})
						.catch(error => {
							if (!cancel) {
								dispatchQueryStateEvent({
									id: stageId,
									action: 'collectionLoaded',
									error: error,
								});
							}
						});
				}
			}

			dispatchQueryStateEvent({
				id: stageId,
				action: 'configSchemaRequested',
			});

			handleConfigSchemaTransform(inputSchema, fullQuery, inputVariables)
				.then(configSchema => {
					if (!cancel) {
						dispatchQueryStateEvent({
							id: stageId,
							action: 'configSchemaLoaded',
							configSchema: configSchema,
						});
					}
				})
				.catch(error => {
					if (!cancel) {
						dispatchQueryStateEvent({
							id: stageId,
							action: 'configSchemaLoaded',
							error: error,
						});
					}
				});

			return () => {
				cancel = true;
			};
		},
		[
			providerOnDataQuery,
			onDataQuery,
			dataContextActive,
			disableCollection,
			variables,
			queryState?.inputCollection,
			queryState?.inputConfigSchema,
		]
	);

	const requestedInput = useRef(0);

	const handleStagePosChange = useCallback(
		stagePos => {
			if (currentStage !== stagePos) {
				setCurrentStage(stagePos);
				const stageId = queryState.stageOrder[stagePos];
				const stage = queryState.stages[stageId];

				if (
					stage &&
					!stage.inputReady &&
					!stage.inputLoading &&
					stagePos > 0
				) {
					const prevStageId = queryState.stageOrder[stagePos - 1];
					const prevStage = queryState.stages[prevStageId];

					if (
						!prevStage.resultReady &&
						!prevStage.resultLoading &&
						requestedInput.current !== stagePos
					) {
						// get result data of previous stage as it is outdated
						// and we require it as inputs for the fields of this
						// stage
						getStageResultData(
							prevStageId,
							prevStage.result.fullQuery
						);
					}
				}
			}
		},
		[
			currentStage,
			getStageResultData,
			queryState.stages,
			queryState.stageOrder,
		]
	);

	const onAddStage = useCallback(
		position => {
			if (!allowedStages || allowedStages.length > 1) {
				dispatchQueryStateEvent({
					action: 'add',
					position: position,
				});
			} else if (allowedStages.length === 1) {
				dispatchQueryStateEvent({
					action: 'add',
					position: position,
					type: allowedStages[0],
				});
			}

			if ((position ?? -1) !== -1) {
				// if we're adding a stage to anywhere but the end, change tab
				handleStagePosChange(position);
			}
		},
		[allowedStages, handleStagePosChange]
	);

	const previousQueryState = useRef({});
	useEffect(
		() => {
			const result = queryState.result || {};
			if (
				result.queryStr !== previousQueryState.current.queryStr ||
				result.configSchemaStr !==
					previousQueryState.current.configSchemaStr
			) {
				if (onChange) {
					previousQueryState.current = {
						queryStr: result.queryStr,
						configSchemaStr: result.configSchemaStr,
					};

					if (variables) {
						const varInQuery = getVariablesInUse(
							queryState.result.query,
							variables
						);

						const varOut = {};

						variables.forEach(v => {
							if (varInQuery[v.key]) {
								varOut[v.key] = { value: v.schemaPath };
							}
						});

						onChange({ ...result, variables: varOut });
					} else {
						onChange(result);
					}
				}
			}
		},
		[queryState.result, onChange, variables]
	);

	const refreshLastStageData = useCallback(
		() => {
			const query = queryState.result?.query || initialQuery;

			if (query.length > 0) {
				if (query.length > 1) {
					// get the penultimate stage result (input for final stage)
					getStageResultData(query.length - 2, query.slice(0, -1));
				}
				// get last stage result
				getStageResultData(query.length - 1, query);
			}
		},
		[initialQuery, queryState.result, getStageResultData]
	);

	useEffect(
		() => {
			if (
				startInputs.configSchema !==
					previousInitialInputs.current.configSchema ||
				startInputs.collection !==
					previousInitialInputs.current.collection
			) {
				previousInitialInputs.current = {
					// query: initialQuery,
					configSchema: startInputs.configSchema,
					collection: startInputs.collection,
				};
				dispatchQueryStateEvent({
					action: 'init',
					// query: initialQuery,
					configSchema: startInputs.configSchema,
					collection: startInputs.collection,
				});

				refreshLastStageData();
			}
		},
		[currentTab, startInputs, initialQuery, refreshLastStageData]
	);

	const handleEditModeChange = useCallback(
		newTab => {
			if (currentTab !== newTab) {
				setCurrentTab(newTab);

				if (newTab === 0) {
					setCurrentStage(queryState?.stageOrder.length - 1);
					refreshLastStageData();
				}
			}
		},
		[
			currentTab,
			setCurrentStage,
			queryState?.stageOrder,
			refreshLastStageData,
		]
	);

	const tabs = useMemo(
		() => {
			return queryState.stageOrder.map((stageId, i) => ({
				label: queryState.stages[stageId].type || '** Select **',
				value: `${i + 1}`,
				id: stageId,
				//icon: null,
				content: (
					<>
						{stageId} -{' '}
						{queryState.stages[stageId].type || '** Select **'}
					</>
				),
			}));
		},
		[queryState.stageOrder, queryState.stages]
	);

	return (
		<Box>
			{dataContextActive && <BtQueryBuilderResourcePicker />}
			<QueryBuilderDispatchContext.Provider
				value={dispatchQueryStateEvent}
			>
				<BtTabBar
					currentTab={currentTab}
					onTabChange={(event, selectedTab) =>
						handleEditModeChange(selectedTab)
					}
					style={{ marginBottom: '1em' }}
				>
					<BtTab label="Builder" {...a11yProps(0)} />
					{allowStageRawInput && (
						<BtTab label="Query Text Editor" {...a11yProps(1)} />
					)}
				</BtTabBar>
				{queryState.stageOrder.length > 0 ? (
					<BtTabPanel currentTab={currentTab} index={0}>
						<Stack
							direction="row"
							flexWrap={true}
							alignItems="flex-end"
							justifyContent="space-between"
						>
							<Box flexGrow={1} minWidth="200px">
								<BtTabBar
									currentTab={currentStage}
									onTabChange={(event, selectedTab) =>
										handleStagePosChange(selectedTab)
									}
									style={{ marginBottom: '1em' }}
								>
									{tabs.map((t, i) => (
										<BtTab
											label={t.label}
											{...a11yProps(i)}
											key={t.id}
										/>
									))}
									<BtTab
										label={<AddIcon />}
										{...a11yProps(tabs.length + 1)}
										key={tabs.length + 1}
									/>
								</BtTabBar>
							</Box>
							<Box>
								<BtQueryBuilderStageActions
									stageId={
										queryState.stageOrder[currentStage]
									}
									stageType={
										queryState.stages[
											queryState.stageOrder[currentStage]
										]?.type
									}
									disabled={
										disabled ||
										!queryState.stages[
											queryState.stageOrder[currentStage]
										]?.type
									}
									isFirst={currentStage === 0}
									isLast={
										currentStage ===
										queryState.stageOrder.length - 1
									}
									readOnly={readOnly}
								/>
							</Box>
						</Stack>
						{queryState.stageOrder.length > currentStage ? (
							<QueryBuilderStageContext.Provider
								value={
									queryState.stages[
										queryState.stageOrder[currentStage]
									]
								}
							>
								<BtQueryBuilderStage
									getStageResultData={getStageResultData}
									disableCollection={!!disableCollection}
									// display related props
									disabled={!!disabled} // whether the form interactivity is disabled
									readOnly={!!readOnly}
									isFirst={currentStage === 0}
									isLast={
										currentStage ===
										queryState.stageOrder.length - 1
									}
									allowRawInput={!!allowStageRawInput}
									allowedStages={allowedStages}
									inputCollection={queryState.inputCollection}
									inputConfigSchema={
										queryState.inputConfigSchema
									}
									collectionRenderMode={collectionRenderMode}
								/>
							</QueryBuilderStageContext.Provider>
						) : (
							<Box textAlign="center">
								<Typography variant="caption">
									Create a new stage or choose a stage above
									to edit
								</Typography>
								<br />
								{!readOnly && (
									<Button
										sx={{ margin: '1rem' }}
										variant="outlined"
										startIcon={<AddIcon />}
										onClick={() => onAddStage()}
										disabled={disabled}
									>
										Add a new Query Stage
									</Button>
								)}
							</Box>
						)}
					</BtTabPanel>
				) : (
					<BtTabPanel currentTab={currentTab} index={0}>
						<Box textAlign="center">
							<Typography variant="caption">
								{queryState.stageOrder.length > 0
									? 'Step deleted, you can create a new one or edit another stage'
									: 'This is a brand new query'}
							</Typography>
							<br />
							{!readOnly &&
								(tabs.length === 0 && (
									<Button
										sx={{ margin: '1rem' }}
										variant="outlined"
										startIcon={<AddIcon />}
										onClick={() => onAddStage()}
										disabled={disabled}
									>
										Add First Query Stage
									</Button>
								))}
						</Box>
					</BtTabPanel>
				)}

				{allowStageRawInput && (
					<BtTabPanel currentTab={currentTab} index={1}>
						<BtQueryBuilderFullQueryTextEditor
							initialQuery={
								queryState.result?.queryStr || initialQuery
							}
							disableCollection={!!disableCollection}
							initialCollection={startInputs.collection}
							initialConfigSchema={startInputs.configSchema}
							onDataQuery={
								dataContextActive
									? providerOnDataQuery
									: onDataQuery
							}
							disabled={disabled}
							collectionRenderMode={collectionRenderMode}
						/>
					</BtTabPanel>
				)}
			</QueryBuilderDispatchContext.Provider>
		</Box>
	);
}

BtQueryBuilder.propTypes = {
	//
	onChange: PropTypes.func,
	//
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	disableStageRawInput: PropTypes.bool,

	collectionRenderMode: PropTypes.oneOf(['dataset', 'json-view']),

	//
	allowedStages: PropTypes.arrayOf(PropTypes.string),

	//
	disableCollection: PropTypes.bool,
	//
	initialQuery: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.object),
	]),
	initialInputs: PropTypes.shape({
		configSchema: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		collection: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.arrayOf(PropTypes.object),
		]),
	}),
	//
	onDataQuery: PropTypes.func,
};
