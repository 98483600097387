import { has } from 'lodash';

const checkValid = value => {
	// console.log('value', value);
	return ['string', 'number'].includes(typeof value);
};

// if defined, returns the affixes, otherwise returns empty strings
const getAffixesAtIndex = (visOptions, index) => {
	const affixes = { suffix: '', prefix: '' };
	// check that we have some params in the array
	if (visOptions.params !== null) {
		// try to retrieve each affix
		['prefix', 'suffix'].map(affix => {
			if (
				has(visOptions.params[index], affix) &&
				checkValid(visOptions.params[index][affix])
			) {
				affixes[affix] = visOptions.params[index][affix];
			}
		});
	}
	return affixes;
};

const attachAffixesToValue = (valueString, suffix, prefix) => {
	return `${prefix || ''}${valueString}${suffix || ''}`;
};

const resolveAffixes = (valueString, visOptions, paramIndex) => {
	const affixes = getAffixesAtIndex(visOptions, paramIndex);
	const resolvedString = attachAffixesToValue(
		valueString,
		affixes.suffix,
		affixes.prefix
	);
	return resolvedString;
};

export { resolveAffixes, attachAffixesToValue };
