import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Auth } from 'aws-amplify';
import { useNavContext } from '../../context/ContextManager';
import { useAppContext } from '../../context/ContextManager';

// Utils
import { no_spaces_regex } from '../../utils/regexLibrary';

// STYLES
import makeStyles from '@mui/styles/makeStyles';

// COMPONENTS
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// ICONS
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LaunchIcon from '@mui/icons-material/Launch';

// CUSTOM COMPONENTS
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from '../../components/generic/CustomAccordion';

// Data for landing page selection table and contextual navigation
import IoT_options from './IoTOptions';

import IoT_breadcrumbs from './IoTBreadcrumbs';

import IoT_nav_item from './IoTNavItem';

const styles = makeStyles(theme => ({
	button_row: {},
	root: {
		width: '100%',
		minWidth: 200,
	},
	config_paper: {
		padding: 10,
	},
	expansion_panel: {},
	expansion_panel_summery: {
		backgroundColor: theme.palette.primary.shade_light,
		borderRadius: 8,
		border: '1px solid rgba(0, 0, 0, .25)',
	},
	heading: {
		fontSize: '1.5rem',
		flexBasis: '20%',
		flexShrink: 0,
		fontWeight: 500,
		color: theme.palette.text.secondary,
	},
	secondHeading: {
		fontSize: '1.5rem',
		flexGrow: 1,
		fontWeight: 500,
		color: theme.palette.text.secondary,
	},
	thirdHeading: {
		fontSize: '1.5rem',
		flexBasis: '30%',
		flexShrink: 0,
		fontWeight: 500,
		color: theme.palette.text.secondary,
	},
	config_panel: {
		display: 'block',
	},
	form_error_msg: {
		color: 'red',
	},
}));

export default function DeviceConfig() {
	const classes = styles();
	const [expanded, setExpanded] = React.useState(false);
	// const [page, setPage] = useState(1);
	const [page] = useState(1);
	const [commitHistory, setCommitHistory] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const history = useHistory();
	const [addConfigOpen, setAddConfigOpen] = React.useState(false);
	const { setBreadcrumbs } = useNavContext();
	const { setContextualNav } = useNavContext();
	const { appOrg } = useAppContext();

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([...IoT_nav_item, ...IoT_options]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([...IoT_breadcrumbs, { text: 'Config', link: '' }]);
		},
		[setBreadcrumbs]
	);

	//const loadMoreCommit = () => {setPage(page + 1);};
	const handleChange = panel => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};
	const handle_update_config = config_id => event => {
		history.push('/config/' + config_id);
	};
	const handleClickAddConfigOpen = () => {
		setAddConfigOpen(true);
	};
	const handleAddConfigClose = () => {
		setAddConfigOpen(false);
	};
	//const handleClickAddConfigAdd = () => {setAddConfigOpen(false);};

	useEffect(
		() => {
			var req_url = process.env.REACT_APP_API_SERVER_URL + `/v1/config`;

			Auth.currentSession().then(user_session => {
				let user_token = user_session.getAccessToken();
				fetch(req_url, {
					method: 'GET',
					headers: {
						Authorization: user_token.jwtToken,
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Organisation: appOrg.uuid,
					},
				})
					.then(res => res.json())
					.then(response => {
						setCommitHistory(response.config_list);
						setIsLoading(false);
					})
					.catch(error => {
						console.log(error);
						setIsLoading(false);
					});
			});
		},
		[page]
	);

	return (
		<>
			<Container className={classes.button_row}>
				<Button
					variant="outlined"
					startIcon={<AddCircleIcon />}
					onClick={handleClickAddConfigOpen}
				>
					Add New Configuration
				</Button>
			</Container>
			{isLoading && (
				<Paper>
					<p>Loading...</p>
					<CircularProgress size={'120px'} />
				</Paper>
			)}

			{!isLoading && (
				<Container maxWidth="lg">
					<div className={classes.root}>
						{commitHistory.map((c, index) => (
							<Accordion
								key={index}
								expanded={expanded === 'panel' + index}
								onChange={handleChange('panel' + index)}
							>
								<AccordionSummary
									aria-controls="panel2bh-content"
									id="panel2bh-header"
								>
									<Typography className={classes.heading}>
										{c.name}:{c.last_version}
									</Typography>
									<Typography
										className={classes.secondHeading}
									>
										{c.last_description}
									</Typography>
									<Typography
										className={classes.thirdHeading}
									>
										Last Updated:{' '}
										{new Date(
											c.last_timestamp
										).toLocaleString()}
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									{c.name && (
										<>
											<h5>
												Config ID: {c.name}:
												{c.last_version}
											</h5>
											<h5>
												Description:
												{c.description}
											</h5>
											<h5>UUID: {c.last_config_uuid}</h5>
											<h5>
												Device Type: {c.device_type}
											</h5>
											<h5>
												Last Updated:{' '}
												{new Date(
													c.last_timestamp
												).toLocaleString()}
											</h5>
											<div>
												<pre>
													{JSON.stringify(
														c.last_config,
														null,
														2
													)}
												</pre>
											</div>
										</>
									)}
									<Divider />
									<Button
										variant="outlined"
										startIcon={<LaunchIcon />}
										onClick={handle_update_config(
											c.config_uuid
										)}
									>
										Inspect / Edit
									</Button>
								</AccordionDetails>
							</Accordion>
						))}
					</div>
				</Container>
			)}

			<Dialog
				open={addConfigOpen}
				onClose={handleAddConfigClose}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">
					Add New Configuration
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Please enter a unique name for the configuration. The
						name must only contain the following: 1-0, a-z, A-Z, or
						'_'
					</DialogContentText>

					<Formik
						initialValues={{ new_config_name: '' }}
						validate={values => {
							const errors = {};

							// REGEX to find word with not whitespace
							if (!values.new_config_name) {
								errors.new_config_name = 'Required';
							} else if (
								!no_spaces_regex.regex.test(
									values.new_config_name
								)
							) {
								errors.new_config_name =
									no_spaces_regex.error_text;
							}
							return errors;
						}}
						onSubmit={(values, { setSubmitting }) => {
							var req_url =
								process.env.REACT_APP_API_SERVER_URL +
								`/v1/config/by_name/` +
								values.new_config_name;

							Auth.currentSession().then(user_session => {
								let user_token = user_session.getAccessToken();
								fetch(req_url, {
									method: 'GET',
									headers: {
										Authorization: user_token.jwtToken,
										Accept: 'application/json',
										'Content-Type': 'application/json',
										Organisation: appOrg.uuid,
									},
								})
									.then(res => res.json())
									.then(response => {
										// The API will respond with OK if a config with this name is already present
										if (
											response['result'] === 'Not Found'
										) {
											handleAddConfigClose();
											history.push(
												'/config/AddConfig/' +
													values.new_config_name
											);
										} else if (
											response['result'] === 'OK'
										) {
											alert(
												'Configuration name already in use, please try another.'
											);
										} else {
											alert('Server connection error.');
										}
										setSubmitting(false);
									})
									.catch(error => {
										console.log(error);
										setSubmitting(false);
									});
							});
						}}
					>
						{({ isSubmitting }) => (
							<Form>
								<Field
									autoFocus
									margin="dense"
									type="text"
									name="new_config_name"
									/*id="new_config_name"*/
									label="Configuration Name"
									fullWidth
									variant="outlined"
									/*component={TextField}*/ // TODO: this was removed in the migration of mui to v5 and needs fixing
								/>
								<ErrorMessage
									name="new_config_name"
									className={classes.form_error_msg}
									component="div"
								/>

								<DialogActions>
									<Button
										onClick={handleAddConfigClose}
										color="primary"
									>
										Cancel
									</Button>
									<Button
										type="submit"
										disabled={isSubmitting}
										color="primary"
									>
										Add
									</Button>
								</DialogActions>
							</Form>
						)}
					</Formik>
				</DialogContent>
			</Dialog>
		</>
	);
}
