export function msTimeToStr(ms) {
	let days = Math.floor(ms / (24 * 60 * 60 * 1000));
	ms -= days * (24 * 60 * 60 * 1000);

	let hours = Math.floor(ms / (60 * 60 * 1000));
	ms -= hours * (60 * 60 * 1000);

	let minutes = Math.floor(ms / (60 * 1000));
	ms -= minutes * (60 * 1000);

	let seconds = Math.floor(ms / 1000);
	ms -= seconds * 1000;

	let ms_str = '';
	if (days > 0) {
		ms_str = ms_str + `${days}d `;
	}
	if (hours > 0) {
		ms_str = ms_str + `${hours}h `;
	}
	if (minutes > 0) {
		ms_str = ms_str + `${minutes}m `;
	}
	if (seconds > 0) {
		ms_str = ms_str + `${seconds}s `;
	}
	return ms_str;
}

export const dateFormatSanitiser = dateFormat => {
	if (!dateFormat || typeof dateFormat !== 'string') {
		throw new Error(
			'A date format string must be provided to the date format sanitiser.'
		);
	}

	let _dateFormat = dateFormat;

	const conversions = {
		D: 'd',
		m: 'M',
		Y: 'y',
	};

	const conversionKeys = Object.keys(conversions);

	conversionKeys.forEach(
		key =>
			(_dateFormat = _dateFormat.replace(
				new RegExp(key, 'g'),
				() => conversions[key]
			))
	);

	return _dateFormat;
};
