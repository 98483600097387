import React, { useCallback, useContext, useEffect, useState } from 'react';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './overridden-placeholder.css';

import RGL, { WidthProvider } from 'react-grid-layout';
import { v4 as uuid } from 'uuid';
import BtLoading from '../BtLoading';
import { useAppContext } from '../../../context/ContextManager';
import _ from 'lodash';
import { Controls } from './components/controls/Controls';
import PropTypes from 'prop-types';
import { tileVariants } from './tileConstants';
import { useTheme } from '@mui/material';
import NativeConfig from './components/nativeConfig/NativeConfig';
import { ScreensContext } from './ScreensContext';
import Tile from './components/Tile';
import { useMobileOrientation } from 'react-device-detect';
import useBreakpointValue from '../../../hooks/useBreakpointValue';
import useResizeObserver from '@react-hook/resize-observer';

/**
 * Column breakpoints
 */
const cols = {
	xl: 16, // desktop lg
	lg: 16, // desktop md
	md: 8, // tablet landscape / desktop sm
	sm: 8, // mobile landscape / tablet portrait
	xs: 4, // mobile portrait
	xxs: 4,
};
// const cols = {
// 	xl: 24, // desktop lg
// 	lg: 12, // desktop md
// 	md: 6, // tablet landscape / desktop sm
// 	sm: 6, // mobile landscape / tablet portrait
// 	xs: 3, // mobile portrait
// 	xxs: 3,
// };

/**
 * Returns the number of columns to show by evaluating device type, screen orientation and breakpoint
 *
 */
const evaluateColumns = (deviceType, breakpoint, isLandscape) => {
	// console.log('evaluateColumns', breakpoint, deviceType, isLandscape);
	if (deviceType === 'mobile') {
		switch (isLandscape) {
			case true:
				return cols.sm;
			case false:
				return cols.xs;
			default:
				break;
		}
	} else if (deviceType === 'tablet') {
		switch (isLandscape) {
			case true:
				return cols.lg;
			case false:
				return cols.md;
			default:
				break;
		}
	} else {
		return cols[breakpoint];
	}
};

export default function BtTileLayout({
	components,
	screenData,
	onLayoutChange,
	setShowAddMenu,
	showAddMenu,
	onScreenChange,
	screenList,
	onTileChange,
	onDeleteTile,
	onContainerChange,
	onNativeChange,
	hidden,
}) {
	// the transformed layout data
	const [layouts, setLayouts] = useState(null);
	// the transformed tiles data
	const [tileList, setTileList] = useState();
	// flag to control layout rendering
	const [gridReady, setGridReady] = useState(false);

	const mobileOrientation = useMobileOrientation();
	const breakpoint = useBreakpointValue();

	const {
		editEnabled,
		currentScreen,
		isEditingDisabled,
		nativeEditMode,
		screenGroupKey,
		columns,
		setColumns,
		cellSize,
		setCellSize,
	} = useContext(ScreensContext);

	const { deviceType } = useAppContext();
	const THEME = useTheme();

	const ReactGridLayout = WidthProvider(RGL);

	const layoutContainer = React.useRef(null);

	const [parentSize, setParentSize] = useState();
	React.useLayoutEffect(
		() => {
			setParentSize(layoutContainer.current.getBoundingClientRect());
		},
		[setParentSize, layoutContainer]
	);

	useResizeObserver(layoutContainer, entry =>
		setParentSize(entry.contentRect)
	);

	/**
	 * Returns the row height required for an approximate square grid cell
	 */
	const evaluateRowHeight = useCallback((columnCount, containerWidth) => {
		// console.log('evaluateRowHeight');
		const gridPadding = columnCount * 10 + 10;

		const tileWidth = (containerWidth - gridPadding) / columnCount;

		return tileWidth;
	}, []);

	// When the layout container dimensions change, the column count and row height is re-evaluated
	useEffect(
		() => {
			if (layouts && deviceType) {
				// console.log('container size change');
				setGridReady(false);
				setColumns(null);
				setCellSize(null);

				const newColumns = evaluateColumns(
					deviceType,
					breakpoint,
					mobileOrientation.isLandscape
				);
				const newRowHeight = evaluateRowHeight(
					newColumns,
					layoutContainer.current.parentElement.offsetWidth
				);

				setColumns(newColumns);
				setCellSize(newRowHeight);
				if (tileList) {
					setGridReady(true);
				}
			}
		},
		[
			breakpoint,
			deviceType,
			evaluateRowHeight,
			mobileOrientation,
			layouts,
			setCellSize,
			setColumns,
			tileList,
			parentSize,
		]
	);

	/**
	 * transforms the layout data and loads it into state
	 */
	const initializeLayouts = useCallback(
		() => {
			// console.log('initializeLayouts');

			// if (!screen.layouts) {
			if (!screenData.layouts) {
				console.log('Missing component list');
				setLayouts({ lg: [] });
				setTileList([]);
			} else {
				var desktop_layout = screenData.layouts.desktop.map(item => {
					let layout_item = {
						i: item.tile_uuid,
						tile: item.uuid,
						x: item.x,
						y: item.y,
						w: item.w,
						h: item.h,
					};
					return layout_item;
				});
				var tablet_layout = screenData.layouts.tablet.map(item => {
					let layout_item = {
						i: item.tile_uuid,
						tile: item.uuid,
						x: item.x,
						y: item.y,
						w: item.w,
						h: item.h,
					};
					return layout_item;
				});
				var mobile_layout = screenData.layouts.mobile.map(item => {
					let layout_item = {
						i: item.tile_uuid,
						tile: item.uuid,
						x: item.x,
						y: item.y,
						w: item.w,
						h: item.h,
					};
					return layout_item;
				});

				// console.log({ desktop_layout, tablet_layout, mobile_layout });
				return {
					desktop: [...desktop_layout],
					tablet: [...tablet_layout],
					mobile: [...mobile_layout],
					native: screenData.layouts.native,
				};
			}
		},
		[screenData.layouts]
	);

	/**
	 * Returns an array of tiles to render for the current device type
	 */
	const evaluateTileList = useCallback(
		(layouts, deviceType) => {
			if (!nativeEditMode)
				if (layouts && deviceType) {
					// console.log('evaluateTileList');
					let tileList = layouts[deviceType].map(layoutItem => {
						// the tile component as retrieved from user's data

						let tileComp = components[layoutItem.i];
						// console.log({ tileComp });
						// processed tile component
						let newTile = {
							...tileComp,
						};
						newTile.uuid = layoutItem?.i;
						newTile.title = tileComp?.title;
						newTile.editmode = false; // Is this component currently selected for edit
						return newTile;
					});
					// setTileList(tileList);

					return tileList;
				}
		},
		[components, nativeEditMode]
	);

	// initialize all the layouts
	useEffect(
		() => {
			// if (!tilesContextLoading) {
			const newLayouts = initializeLayouts();
			setLayouts(newLayouts);
			// }
		},
		[initializeLayouts]
	);

	// load the appropriate layout for the current device
	useEffect(
		() => {
			if (layouts && deviceType && !tileList) {
				// console.log('useEffect evaluateTileList');
				const newTiles = evaluateTileList(layouts, deviceType);
				setTileList(newTiles);
				setGridReady(true);
			}
		},
		[deviceType, evaluateTileList, layouts, tileList]
	);

	// callback passed to tile container delete tile button
	const handleDeleteTile = tileUuid => {
		let newLayouts = structuredClone(layouts);

		Object.keys(layouts).forEach(layoutKey => {
			if (layoutKey === 'native') {
				const newLayout = layouts[layoutKey].filter(
					layoutItem => layoutItem !== tileUuid
				);
				newLayouts[layoutKey] = newLayout;
			} else {
				const newLayout = _.filter(layouts[layoutKey], item => {
					if (item.i !== tileUuid) return item;
				});
				newLayouts[layoutKey] = newLayout;
			}
		});

		setLayouts(newLayouts);

		// update the RGL tiles
		let tileListUpdate = _.filter(tileList, tile => {
			if (tile.uuid !== tileUuid) return tile;
		});
		setTileList(tileListUpdate);

		// pass the layout update to the callback which will update the user's tiles data in backend and local storage
		onDeleteTile(newLayouts, currentScreen, tileUuid);
	};

	// called by RGL events onDragStop and onResizeStop
	const handleNewLayout = useCallback(
		layout => {
			// pass the layout update to the callback which will update the user's tiles data in backend and local storage
			onLayoutChange(layout, currentScreen);

			// update the RGL state
			let gridLayouts = layouts;
			gridLayouts[deviceType] = layout;
			setLayouts(gridLayouts);
		},
		[deviceType, currentScreen, layouts, onLayoutChange]
	);

	const onDragStart = type => event => {
		// This is a hack for firefox
		// Firefox requires some kind of initialization which we can do by adding this attribute @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
		event.dataTransfer.setData('dragData', type);
		//console.log('onDragStart');
	};

	const onDrop = useCallback(
		(layout, layoutItem, event) => {
			// console.log('onDrop');
			let variant = event.dataTransfer.getData('dragData');
			console.log({ variant, layout, layoutItem });

			let newUuid = uuid();
			layoutItem.i = newUuid; // Give the new item a unique id

			// new tile template - to be passed to onLayoutChange,
			// and to RGL state value after adding additional properties
			const newTile = { ...tileVariants[variant] };
			if (variant === 'container') {
				newTile.data.screen_group = screenGroupKey;
				newTile.data.screen_uuid = screenData.uuid;
			}

			// if (variant === 'information') {
			// 	newTile.data.items = [
			// 		screens[currentScreen].screen_name,
			// 		screens[currentScreen].ownerType === 'organisation'
			// 			? 'Organisation Screen'
			// 			: 'User Screen',
			// 	];
			// }

			newTile.uuid = newUuid;
			newTile.editmode = false;

			let newTiles = [...tileList];

			newTiles.push(newTile);
			// console.log(newTiles);

			let newLayouts = structuredClone(layouts);
			newLayouts[deviceType] = layout;
			setLayouts(newLayouts);

			setTileList(newTiles);

			// pass the layout update to the callback which will update the user's tiles data in backend and local storage
			onLayoutChange(layout, currentScreen, newTile, newUuid);
		},
		[
			tileList,
			layouts,
			deviceType,
			onLayoutChange,
			currentScreen,
			screenGroupKey,
			screenData.uuid,
		]
	);

	// // const onToggleEditable = () => {
	// // 	setEditEnabled(editEnabled ? false : true);
	// // 	setWidgetList(
	// // 		_.filter(widgetList, widget => {
	// // 			widget.editmode = false;
	// // 			return widget;
	// // 		})
	// // 	);
	// // };

	// const onEditClick = widget_id => () => {
	// 	//console.log('onEdit: ' + widget_id);
	// 	setModalEditEnabled(true);
	// 	setShowFullscreen(true);
	// 	setSelectedComponent(_.filter(widgetList, { uuid: widget_id })[0]);
	// 	// Only allow widget select when edit is enabled
	// 	/*if (editEnabled) {
	// 		setWidgetList(
	// 			_.filter(widgetList, widget => {
	// 				widget.uuid === widget_id
	// 					? (widget.editmode = true)
	// 					: (widget.editmode = false);
	// 				console.log(widget);
	// 				return widget;
	// 			})
	// 		);
	// 	}*/
	// };

	// const onFullscreenClick = widget_id => () => {
	// 	//console.log('onFullscreen: ' + widget_id);
	// 	setModalEditEnabled(false);
	// 	setShowFullscreen(true);
	// 	setSelectedComponent(_.filter(widgetList, { uuid: widget_id })[0]);

	// 	// Only allow widget select when edit is enabled
	// 	/*if (editEnabled) {
	// 		setWidgetList(
	// 			_.filter(widgetList, widget => {
	// 				widget.uuid === widget_id
	// 					? (widget.editmode = true)
	// 					: (widget.editmode = false);
	// 				console.log(widget);
	// 				return widget;
	// 			})
	// 		);
	// 	}*/
	// };

	// const onFullscreenClose = () => {
	// 	setShowFullscreen(false);
	// };

	// useEffect(
	// 	() => {
	// 		if (!editEnabled) {
	// 			setGridLayoutWidth('100%');
	// 		} else {
	// 			switch (editWidth) {
	// 				default:
	// 				case 'Desktop':
	// 					setGridLayoutWidth('100%');
	// 					break;
	// 				case 'Tablet':
	// 					setGridLayoutWidth(THEME.breakpoints.values.md + 'px');
	// 					break;
	// 				case 'Mobile':
	// 					setGridLayoutWidth(THEME.breakpoints.values.sm + 'px');
	// 					break;
	// 			}
	// 		}
	// 	},
	// 	[editWidth]
	// );

	// /**
	//  * handles the tile resize event -
	//  * - TODO optional aspect ratio and min max locks
	//  * - passes new layout to handleNewLayout which transforms the data and saves it to state
	//  */
	// const handleResize = useCallback(
	// 	// aspect ratio lock borrowed in it's entirety from -
	// 	// https://github.com/react-grid-layout/react-grid-layout/issues/267

	// 	(layout, oldLayoutItem, layoutItem, placeholder) => {
	// 		const heightDiff = layoutItem.h - oldLayoutItem.h;
	// 		const widthDiff = layoutItem.w - oldLayoutItem.w;
	// 		const changeCoef = oldLayoutItem.w / oldLayoutItem.h;
	// 		if (Math.abs(heightDiff) < Math.abs(widthDiff)) {
	// 			layoutItem.h = layoutItem.w / changeCoef;
	// 			placeholder.h = layoutItem.w / changeCoef;
	// 		} else {
	// 			layoutItem.w = layoutItem.h * changeCoef;
	// 			placeholder.w = layoutItem.h * changeCoef;
	// 		}

	// 		// handleNewLayout(layout);
	// 	},

	// 	[]
	// );

	// console.log({ rowHeight });

	// const onDropDragOver = () => {
	// 	console.log('onDropDragOver');
	// 	return;
	// };

	// console.log(screenData);

	return (
		<div
			ref={layoutContainer}
			style={{
				width: '100%',
				margin: 'auto',
				height: '100%',
				// minHeight: '2000px',
				minHeight: `calc(100vh - 64px)`,
				backgroundColor: nativeEditMode
					? 'white'
					: screenData?.background_color &&
					  screenData?.background_color !== ''
						? screenData?.background_color
						: THEME.palette.background.insights,
				// visibility: hidden ? 'hidden' : 'visible',
				display: hidden ? 'none' : 'block',
			}}
		>
			{nativeEditMode ? (
				<NativeConfig
					screenData={screenData}
					onNativeChange={onNativeChange}
					onTileChange={onTileChange}
					onContainerChange={onContainerChange}
				/>
			) : (
				<BtLoading loading={!gridReady}>
					{gridReady &&
						tileList && (
							<ReactGridLayout
								style={{
									minHeight: `calc(100vh - 64px)`,
								}}
								// autoSize={true}
								layout={layouts[deviceType]}
								cols={columns}
								rowHeight={cellSize}
								margin={[10, 10]}
								className="layout"
								isDroppable={editEnabled && !isEditingDisabled}
								isDraggable={editEnabled && !isEditingDisabled}
								isResizable={editEnabled && !isEditingDisabled}
								// onResize={handleResize} // to be implemented once tiles data has the flags lock aspect ratio and
								onResizeStop={handleNewLayout}
								onDragStart={onDragStart}
								onDragStop={handleNewLayout}
								measureBeforeMount={true}
								compactType={null}
								// compactType="vertical"
								// preventCollision={true}
								onDrop={onDrop}
								// onDropDragOver={onDropDragOver}
								containerPadding={[10, 10]}
							>
								{tileList.map((component, index) => (
									<div
										// style={{ width: cellSize }}
										key={component.uuid}
									>
										<Tile
											key={component.uuid}
											layoutDefinition={layouts[
												deviceType
											].find(
												item =>
													item.i === component.uuid
											)}
											screenData={screenData}
											component={component}
											handleDeleteTile={handleDeleteTile}
											onTileChange={onTileChange}
											onContainerChange={
												onContainerChange
											}
										/>
									</div>
								))}
							</ReactGridLayout>
						)}
				</BtLoading>
			)}

			<Controls
				showAddMenu={showAddMenu}
				setShowAddMenu={setShowAddMenu}
				onDragStart={onDragStart}
				onScreenChange={onScreenChange}
				screenList={screenList}
				onNativeChange={onNativeChange}
				currentScreenData={screenData}
			/>
		</div>
	);
}

BtTileLayout.propTypes = {
	components: PropTypes.object.isRequired,
	screenData: PropTypes.object.isRequired,
	hidden: PropTypes.bool,
	onLayoutChange: PropTypes.func.isRequired,
	containerDimensions: PropTypes.objectOf(PropTypes.number),
	setShowAddMenu: PropTypes.func,
	showAddMenu: PropTypes.bool,
	setShowAddScreenDialog: PropTypes.func,
	onTileChange: PropTypes.func.isRequired,
	onDeleteTile: PropTypes.func.isRequired,
};
