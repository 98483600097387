import CollectionDraft from '../draft/CollectionDraft';
import { RecordStatus } from '../status/RecordStatus';
import {
	TabLateralContainer,
	TabLateralWrapper,
	TabPanelContainer,
} from '../generic/TabLayout';
import GeneralSettings from '../generalSettings/GeneralSettings';
import React from 'react';

const OverviewTab = () => {
	return (
		<TabPanelContainer maxWidth="lg">
			<TabLateralWrapper>
				<TabLateralContainer>
					<RecordStatus />
					<CollectionDraft />
				</TabLateralContainer>
				<TabLateralContainer>
					<GeneralSettings />
				</TabLateralContainer>
			</TabLateralWrapper>
		</TabPanelContainer>
	);
};

export { OverviewTab };
