import { apiClient } from './api_client';

import {
	orgDetailsGetSchema,
	orgDetailsPutSchema,
} from './validations/orgDetailsValidation';

//========================================================================================================
async function orgDetailsGet() {
	const req_url = 'org_details';
	try {
		const response = await apiClient({ method: 'get', url: req_url });

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				await orgDetailsGetSchema.validate(response.orgDetails);
				return response.orgDetails;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function orgDetailsPut(orgDetails) {
	const req_url = 'org_details';

	// Validate the orgDetails
	try {
		await orgDetailsPutSchema.validate(orgDetails);
	} catch (error) {
		console.log(error);
		throw error;
	}

	try {
		const apiRes = await apiClient({
			method: 'put',
			url: req_url,
			body: orgDetails,
		});

		if (apiRes) {
			if (apiRes.result === 'OK') {
				return;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

export { orgDetailsGet, orgDetailsPut, orgDetailsPutSchema };
