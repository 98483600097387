import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useNavContext } from '../../context/ContextManager';
import { useAppContext } from '../../context/ContextManager';

import queryString from 'query-string';

import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import SaveIcon from '@mui/icons-material/SaveAlt';
import CloseIcon from '@mui/icons-material/Close';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import TextField from '@mui/material/TextField';
import BtLoading from '../../components/generic/BtLoading';

import ConfigChannels from '../../components/DeviceComponents/ConfigChannels';

import _ from 'lodash';

import { deviceTypeGetList, deviceTypeGet } from '../../API';

function getDeviceList(orgUuid) {
	return new Promise((resolve, reject) => {
		var req_url = process.env.REACT_APP_API_SERVER_URL + '/v1/device_type';

		Auth.currentSession().then(user_session => {
			let user_token = user_session.getAccessToken();
			fetch(req_url, {
				method: 'GET',
				headers: {
					Authorization: user_token.jwtToken,
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Organisation: orgUuid,
				},
			})
				.then(res => res.json())
				.then(response => {
					// The API will respond with OK if a config with this name is already present
					if (response['result'] === 'OK') {
						/*setDeviceTypeList(response['device_types']);
                    if(response['device_types'].length > 0)
                        setDeviceType(response['device_types'][0].uuid);*/
						resolve(response['device_types']);
					} else {
						alert('Server connection error.');
						reject('');
					}
				})
				.catch(error => {
					alert(error);
					reject('');
				});
		});
	});
}

export default function DeviceConfig({ match, location }) {
	const [deviceUuid, setDeviceUuid] = useState('');
	const [description, setDescription] = useState('');
	//const [version, setVersion] = React.useState(1);
	//const [json, setJson] = useState('[]');
	const history = useHistory();
	const [commsActive, setCommsActive] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [deviceTypeList, setDeviceTypeList] = useState([]);
	const [deviceDef, setDeviceDef] = useState({});
	//const [configChannels, setConfigChannels] = useState([]);
	const { appOrg } = useAppContext();

	// navContext breadcrumbs
	const { setBreadcrumbs } = useNavContext();

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'Config', link: '/Config' },
				{ text: 'New Config', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	var config_id = match.params.id;
	//const url_query = queryString.parse(location.search);

	// TODO: check the version type is correct
	const url_query = queryString.parse(location.search);
	var init_version = 1;
	if (url_query.version !== undefined) {
		init_version = url_query.version;
	}

	const handleChangeDevice = event => {
		setDeviceUuid(event.target.value);
		console.log(_.find(deviceTypeList, { uuid: event.target.value }));
		setDeviceDef(_.find(deviceTypeList, { uuid: event.target.value }));
	};
	const handleDescriptionChange = event => {
		setDescription(event.target.value);
	};
	const handleCancel = event => {
		history.goBack();
	};

	useEffect(
		() => {
			const onLoad = async () => {
				try {
					let devDetails = await deviceTypeGet({
						typeId: deviceUuid,
					});
					console.log(devDetails);
					setDeviceDef(devDetails);
				} catch (error) {
					console.log(error);
				}
			};

			if (deviceUuid) {
				onLoad();
			}
		},
		[deviceUuid]
	);

	useEffect(() => {
		const onLoad = async () => {
			try {
				setIsLoading(true);
				let devList = await deviceTypeGetList();
				console.log(devList);
				setDeviceTypeList(devList);
				setDeviceUuid(devList[0].uuid);
				setIsLoading(false);
			} catch (error) {
				console.log(error);
				setIsLoading(false);
			}
		};

		onLoad();
	}, []);

	// useEffect(
	// 	() => {
	// 		setCommsActive(true);
	// 		getDeviceList(appOrg.uuid)
	// 			.then(device_type_list => {
	// 				console.log(device_type_list);
	// 				setDeviceTypeList(device_type_list);
	// 				if (device_type_list.length > 0) {
	// 					setDevice(device_type_list[0].uuid);
	// 					setDeviceDef(device_type_list[0]);
	// 				}
	// 				setCommsActive(false);
	// 			})
	// 			.catch(res => {
	// 				alert('Failed to load dev list');
	// 				setCommsActive(false);
	// 			});
	// 	},
	// 	[config_id, init_version]
	// );

	const handleSave = event => {
		/*try{
        	var config_str = JSON.parse(json)
      	} catch (e){
        	console.log(e);
        	alert("JOSN parse error, please check your syntax.")
        	return;
      	}*/

		var config = {};
		config['name'] = config_id;
		config['device'] = deviceUuid;
		config['description'] = description;
		config['channels'] = [];
		//config['channels'] = configChannels;
		console.log(config);

		setCommsActive(true);

		var req_url = process.env.REACT_APP_API_SERVER_URL + `/v1/config`;

		Auth.currentSession().then(user_session => {
			let user_token = user_session.getAccessToken();
			fetch(req_url, {
				method: 'POST',
				headers: {
					Authorization: user_token.jwtToken,
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Organisation: appOrg.uuid,
				},
				body: JSON.stringify(config),
			})
				.then(res => res.json())
				.then(response => {
					if (response['result'] !== 'OK') {
						alert('Internal server error.');
					} else {
						alert('Configuration Saved.');
						history.goBack();
					}
					setCommsActive(false);
				})
				.catch(error => {
					alert('Failed to connect to the server.');
					setCommsActive(false);
				});
		});
	};

	return (
		<BtLoading loading={isLoading}>
			<div>
				<form noValidate autoComplete="off">
					<h4>Device Config: {config_id}</h4>
					<h4>Version: {init_version}</h4>

					<Divider />

					<div>
						<Typography variant="h5">
							Configuration Description:
						</Typography>
						<TextField
							id="outlined-multiline-static"
							multiline
							variant="standard"
							fullWidth
							onChange={handleDescriptionChange}
							value={description}
						/>
					</div>

					<Divider />

					<Typography variant="h5">Device Type:</Typography>
					<FormControl variant="standard">
						<Select
							labelId="demo-simple-select-outlined-label"
							id="demo-simple-select-outlined"
							value={deviceUuid}
							onChange={handleChangeDevice}
						>
							{deviceTypeList.map((device_type, index) => (
								<MenuItem key={index} value={device_type.uuid}>
									{device_type.device_name}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<Divider />

					<ConfigChannels
						config={[]}
						editable={true}
						deviceDef={deviceDef}
					/>

					<Divider />
					<Box
						display="flex"
						flexDirection="row-reverse"
						p={1}
						m={1}
						bgcolor="background.paper"
					>
						<Button
							variant="outlined"
							startIcon={<SaveIcon />}
							onClick={handleSave}
						>
							Save
						</Button>

						<Button
							variant="outlined"
							startIcon={<CloseIcon />}
							onClick={handleCancel}
						>
							Cancel
						</Button>
					</Box>
				</form>
			</div>
		</BtLoading>
	);
}
