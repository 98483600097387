import {
	Button,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	// IconButton,
	// Tooltip,
} from '@mui/material';
import { styled } from '@mui/system';
// import { Information } from 'mdi-material-ui';
import { useCallback, useState } from 'react';
import BtDialog from '../../../BtDialog';
import { StatusDisplay, StatusTitle } from '../generic/StatusDisplay';
import { useRecordManagementContext } from '../../RecordManagementContext';
import { DraftChangesStatus } from './DraftChangesStatus';
import { DraftNotes } from './DraftNotes';
import { DraftLockStatus } from './DraftLockStatus';

const StyledDivider = styled(Divider)(({ theme }) => ({
	marginTop: '5px',
	marginBottom: '5px',

	[theme.breakpoints.up('sm')]: {
		marginLeft: '40px',
	},
}));

export default function CollectionDraft() {
	const { moduleAssets } = useRecordManagementContext();
	const [draftInfoDialogOpen, setDraftInfoDialogOpen] = useState(false);

	// const handleOpenDraftInfo = useCallback(() => {
	// 	setDraftInfoDialogOpen(true);
	// }, []);

	const handleCloseDraftInfo = useCallback(() => {
		setDraftInfoDialogOpen(false);
	}, []);

	// const InfoButton = () => {
	// 	return (
	// 		<Tooltip title={'What is the Collection Draft?'} arrow>
	// 			<IconButton color="info" onClick={handleOpenDraftInfo}>
	// 				<Information
	// 					sx={{
	// 						fontSize: '30px',
	// 					}}
	// 				/>
	// 			</IconButton>
	// 		</Tooltip>
	// 	);
	// };

	return (
		<StatusDisplay>
			<StatusTitle
				title={`${moduleAssets.moduleTitle} Draft`}
				// ButtonComponent={InfoButton}
			/>
			<DraftLockStatus />
			<StyledDivider />
			<DraftChangesStatus />
			<StyledDivider />

			<DraftNotes />
			<BtDialog open={draftInfoDialogOpen} onClose={handleCloseDraftInfo}>
				<DialogTitle>{'What is the Collection Draft?'}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit,
						sed do eiusmod tempor incididunt ut labore et dolore
						magna aliqua. Ut enim ad minim veniam, quis nostrud
						exercitation ullamco laboris nisi ut aliquip ex ea
						commodo consequat. Duis aute irure dolor in
						reprehenderit in voluptate velit esse cillum dolore eu
						fugiat nulla pariatur. Excepteur sint occaecat cupidatat
						non proident, sunt in culpa qui officia deserunt mollit
						anim id est laborum.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleCloseDraftInfo}
						disableElevation
						variant="contained"
					>
						Close
					</Button>
				</DialogActions>
			</BtDialog>
		</StatusDisplay>
	);
}
