import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { useAppContext } from '../../context/ContextManager';

function ScrollToTop({ history }) {
	const { activityIndicator, setActivityIndicator } = useAppContext();

	useEffect(
		() => {
			const unlisten = history.listen(() => {
				window.scrollTo(0, 0);
				if (activityIndicator) {
					setActivityIndicator(false);
				}
			});
			return () => {
				unlisten();
			};
		},
		[history, activityIndicator, setActivityIndicator]
	);

	return null;
}

export default withRouter(ScrollToTop);
