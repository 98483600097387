import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useNavContext } from '../../../../../context/ContextManager';
import {
	Button,
	MenuItem,
	Menu,
	ListItemIcon,
	ListItemText,
} from '@mui/material';
import _ from 'lodash';

import insights_edit_options from '../InsightCollectionEditNavOptions';
import insights_breadcrumbs from '../../../InsightsBreadcrumbs';

import BtSelectionTable from '../../../../../components/generic/BtSelectionTable';
import AddIcon from '@mui/icons-material/AddCircle';
import { useParams, useLocation, useHistory } from 'react-router';
import { mdiContentCopy, mdiDelete, mdiMenu } from '@mdi/js';
import v4 from 'uuid/v4';
import { AddDataSourceDialog } from './AddDataSourceDialog';
import BtLoading from '../../../../../components/generic/BtLoading';
import {
	addDataSourcesToCollection,
	evaluateCopyName,
	getData,
} from './utils/utils';
import BtError from '../../../../../components/generic/BtError';
import { useUpdateCollectionDraft } from './utils/useUpdateCollectionDraft';
import {
	TabPanelContainer,
	TabPanelTitle,
} from '../../../../../components/generic/recordManagement/components/generic/TabLayout';
import { ContentCopy, Delete } from 'mdi-material-ui';
import { BtIconButtonGroup } from '../../../../../components/generic/BtIconButtonGroup';
import { transform } from 'dot-object';

const tableColumns = [
	{ field: 'name', text: 'Name' },
	{ field: 'description', text: 'Description', minBreakpoint: 'sm' },
	{ field: 'options', text: 'Options' },
];

const TableButtons = ({
	handleRemoveDataSource,
	handleDuplicateSource,
	index,
}) => {
	const attributes = useMemo(
		() => [
			{
				onClick: event => {
					event.stopPropagation();
					handleRemoveDataSource(index);
				},
				// disabled: PropTypes.bool,
				icon: mdiDelete,
				tooltip: 'Delete',
				size: 'small',
			},
			{
				onClick: event => {
					event.stopPropagation();
					handleDuplicateSource(index);
				},
				// disabled: PropTypes.bool,
				icon: mdiContentCopy,
				tooltip: 'Copy',
				size: 'small',
			},
		],
		[handleDuplicateSource, handleRemoveDataSource, index]
	);

	return <BtIconButtonGroup buttonGroupAttributes={attributes} />;
};

export default function InsightCollectionDataSources() {
	const { setBreadcrumbs, setContextualNav } = useNavContext();
	// const [dataSources, setDataSources] = useState();
	const [dataViews, setDataViews] = useState(null);
	const { id, version } = useParams();
	const location = useLocation();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [insightCollection, setInsightCollection] = useState(null);
	const [addDataSourceDialogOpen, setAddDataSourceDialogOpen] = useState(
		false
	);
	const history = useHistory();

	const updateCollection = useUpdateCollectionDraft();

	// Set breadcrumbs
	useEffect(
		() => {
			if (insightCollection) {
				setBreadcrumbs([
					...insights_breadcrumbs,
					{ text: 'Collections', link: '/InsightCollections' },
					{
						text: insightCollection.name,
						link: `/InsightCollection/${insightCollection.uuid}`,
					},
					{
						text: 'Data Sources',
						link: '',
					},
				]);
			} else {
				setBreadcrumbs([
					...insights_breadcrumbs,
					{ text: 'Collections', link: '/InsightCollections' },
				]);
			}
		},
		[setBreadcrumbs, insightCollection]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			//setContextualNav([...insights_nav_item, ...insights_edit_options]);
			setContextualNav([...insights_edit_options(id)]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav, id]
	);

	const loadData = useCallback(
		async () => {
			const {
				collection,
				dataViewList,
				error: dataError,
				loading: dataLoading,
			} = await getData(id, location, version);

			if (!dataError && !dataLoading) {
				setInsightCollection(collection);
				setDataViews(dataViewList);
				setLoading(false);
			}
			if (dataError) {
				setLoading(false);
				setError(true);
			}
		},
		[id, location, version]
	);

	useEffect(
		() => {
			loadData();
		},
		[loadData]
	);

	const dataSources = useMemo(() => insightCollection?.datasources ?? [], [
		insightCollection,
	]);

	const handleSourceUpdate = useCallback(
		update => {
			const collectionUpdate = addDataSourcesToCollection(
				insightCollection,
				update
			);
			setInsightCollection(collectionUpdate);
			loadData();
		},
		[insightCollection, loadData]
	);

	const handleSourcesUpdate = useCallback(
		async dataSourcesUpdate => {
			console.log('handleSourceUpdate', dataSourcesUpdate);

			const messages = {
				success: 'Insight collection draft updated',
				error: 'Error updating Insight collection draft',
			};
			const callbacks = {
				success: update => handleSourceUpdate(update.datasources),
				// error: update => console.log(update.datasources),
			};

			updateCollection({
				update: { datasources: dataSourcesUpdate },
				messages,
				callbacks,
			});
		},
		[handleSourceUpdate, updateCollection]
	);

	const handleRemoveDataSource = useCallback(
		index => {
			const dataSourcesUpdate = [...dataSources];
			dataSourcesUpdate.splice(index, 1);
			handleSourcesUpdate(dataSourcesUpdate);
		},
		[dataSources, handleSourcesUpdate]
	);

	const handleDuplicateSource = useCallback(
		index => {
			const newDataSource = { ...dataSources[index] };
			newDataSource.uuid = v4();
			newDataSource.name = evaluateCopyName(dataSources, index);
			newDataSource.transforms = newDataSource.transforms.map(
				transform => {
					const newTransform = { ...transform };
					newTransform.uuid = v4();
					return newTransform;
				}
			);
			const update = [...dataSources, newDataSource];
			handleSourcesUpdate(update);
		},
		[dataSources, handleSourcesUpdate]
	);

	const tableData = useMemo(
		() => {
			return (dataSources || []).map((datasource, index) => {
				return {
					id: datasource.uuid,
					name: datasource.name,
					description: datasource.description,
					options: (
						<TableButtons
							handleDuplicateSource={handleDuplicateSource}
							handleRemoveDataSource={handleRemoveDataSource}
							index={index}
						/>
					),
				};
			});
		},
		[dataSources, handleDuplicateSource, handleRemoveDataSource]
	);

	const handleTableClick = useCallback(
		dataSourceId =>
			history.push(
				`/InsightCollection/${
					insightCollection.uuid
				}/Edit/DataSources/${dataSourceId}`
			),
		[history, insightCollection]
	);

	if (error) {
		return <BtError />;
	}

	return (
		<BtLoading loading={loading}>
			<TabPanelContainer maxWidth="md">
				<TabPanelTitle
					title="Insight Collection Data Sources"
					ButtonComponent={
						<Button
							variant="contained"
							startIcon={<AddIcon />}
							disableElevation
							onClick={() => setAddDataSourceDialogOpen(true)}
						>
							Add Data Source
						</Button>
					}
				/>
				<BtSelectionTable
					subject="data sources"
					columns={tableColumns}
					data={tableData}
					onClick={handleTableClick}
				/>
			</TabPanelContainer>
			<AddDataSourceDialog
				dataSources={dataSources}
				dataViews={dataViews}
				insightUuid={id}
				onClose={() => setAddDataSourceDialogOpen(false)}
				open={addDataSourceDialogOpen}
				setDataSources={handleSourcesUpdate}
			/>
		</BtLoading>
	);
}
