import React, { Children, cloneElement, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import pluralize from 'pluralize';
import { styled, Typography } from '@mui/material';
import { v4 as generateUuid } from 'uuid';
import _ from 'lodash';

import CommentsIconButton from '../../../../../components/generic/comments/CommentsIconButton';
import components, {
	componentTypeExists,
} from '../../processing/config/workflowComponents';
import {
	COMPOUND_COMPONENTS,
	FULL_WIDTH_COMPONENTS,
	GROUP,
	NON_COMMENTABLE,
} from '../../processing/utils/constants';
import { useWorkflowPageContext } from '../../contexts/WorkflowPageContext';
import { useWorkflowSessionContext } from '../../contexts/WorkflowSessionContext';

const AnswerWrapperContainer = styled('div')(({ fullwidth }) => ({
	alignItems: 'center',
	display: 'flex',
	flexDirection: fullwidth ? 'column' : 'row',
	justifyContent: 'space-between',
	marginBottom: fullwidth ? 10 : 0,
}));

const LeftBlank = styled(Typography)(({ theme }) => ({
	color: theme.palette.indicators.info.text,
}));

const AnswerContainer = ({ children, label, style }) => (
	<div style={{ margin: '1.2em 0', ...style }}>
		<Typography style={{ opacity: 0.85 }} variant="subtitle1">
			{label}
		</Typography>
		{children}
	</div>
);

export const AnswerWrapper = ({ children, componentData }) => {
	const {
		comments,
		maxCount,
		minCount,
		refUuid,
		repeatable,
		type,
	} = componentData;

	const { flowElement } = useWorkflowSessionContext();
	const { setComments } = useWorkflowPageContext();

	const commentable = useMemo(
		() => {
			const componentsStatus =
				flowElement?.commentAvailability?.components;

			return (
				!NON_COMMENTABLE.includes(type) &&
				componentsStatus &&
				componentsStatus !== 'hidden'
			);
		},
		[flowElement, type]
	);

	const fullWidth = useMemo(
		() => FULL_WIDTH_COMPONENTS.includes(componentData.type),
		[componentData]
	);

	const handleCommentPress = useCallback(
		() => setComments(refUuid, comments),
		[comments, refUuid, setComments]
	);

	const processedChildren = useMemo(
		() =>
			Children.map(children, child => {
				if (COMPOUND_COMPONENTS.includes(type)) {
					return cloneElement(children, {
						maxCount,
						minCount,
						repeatable,
					});
				}

				return child;
			}),
		[children, maxCount, minCount, repeatable, type]
	);

	return (
		<AnswerWrapperContainer fullwidth={+fullWidth}>
			{processedChildren}
			{commentable && (
				<div>
					<CommentsIconButton
						comments={comments}
						condensed={!fullWidth}
						onClick={handleCommentPress}
						style={{
							marginTop: -15,
						}}
					/>
				</div>
			)}
		</AnswerWrapperContainer>
	);
};

export const WorkflowAnswer = ({ answers, child, ...other }) => {
	const getSummaryComponent = useCallback(
		(componentChild = child) => {
			const { name, refUuid, type, uuid } = componentChild;

			if (!componentTypeExists(type)) {
				return null;
			}

			const { Component, props } =
				components[type]?.summaryComponent || {};

			const answer = answers[uuid];

			if (
				(typeof answer === 'string' && answer.trim() === '') ||
				_.isNil(answer)
			) {
				return (
					<AnswerWrapper
						componentData={componentChild}
						key={refUuid || uuid}
					>
						<LeftBlank>** Left Blank **</LeftBlank>
					</AnswerWrapper>
				);
			}

			return (
				<AnswerWrapper
					componentData={componentChild}
					key={generateUuid()}
				>
					<Component label={name} value={answers[uuid]} {...props} />
				</AnswerWrapper>
			);
		},
		[answers, child]
	);

	if (child.type === GROUP) {
		const { children: groupElements } = child;

		const name = groupElements[0].name;

		return (
			<AnswerContainer
				label={groupElements.length > 1 ? pluralize.plural(name) : name}
				{...other}
			>
				{groupElements.map(groupElement =>
					getSummaryComponent(groupElement)
				)}
			</AnswerContainer>
		);
	}

	return (
		<AnswerContainer label={child.name} {...other}>
			{getSummaryComponent()}
		</AnswerContainer>
	);
};

WorkflowAnswer.propTypes = {
	answers: PropTypes.object.isRequired,
	child: PropTypes.object.isRequired,
};

AnswerContainer.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	label: PropTypes.string,
	style: PropTypes.object,
};

AnswerWrapper.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	componentData: PropTypes.object.isRequired,
};

export default WorkflowAnswer;
