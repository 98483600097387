import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
	TextField,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import ActionButtonContainer from '../../../components/generic/ActionButtonContainer';
import AddTagIcon from '@mui/icons-material/Add';
import { tagGroupAddValue, tagGroupUpdateValue } from '../../../API/tags.api';
import UpdateIcon from '@mui/icons-material/ArrowUpward';

export default function TagValuesDialog({
	groupId,
	onClose,
	sending,
	setGroup,
	setSending,
	tagValues,
	valueToEdit,
}) {
	const { enqueueSnackbar } = useSnackbar();

	const isEdit = useMemo(() => !!valueToEdit, [valueToEdit]);
	const schema = useMemo(
		() =>
			yup.object({
				tagName: yup
					.string()
					.min(3)
					.required()
					.notOneOf(
						(tagValues || [])
							.map(({ name }) => name)
							.filter(name => name !== valueToEdit?.name),
						'A tag with this name already exists within this group'
					)
					.label('Name'),
			}),
		[tagValues, valueToEdit]
	);

	const {
		register,
		handleSubmit,
		formState: { errors, isDirty },
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: { tagName: isEdit ? valueToEdit?.name : '' },
	});

	const onSubmit = useCallback(
		async values => {
			const { tagName } = values;
			try {
				setSending(true);
				if (isEdit) {
					const { uuid } = valueToEdit;
					const newGroup = await tagGroupUpdateValue({
						tagGroupUuid: groupId,
						tagGroupValueUuid: uuid,
						tagValueUpdate: { name: tagName },
					});
					setGroup(newGroup);
				} else {
					const newGroup = await tagGroupAddValue({
						tagGroupUuid: groupId,
						newTagValue: {
							name: tagName,
							status: 'Active',
						},
					});
					setGroup(newGroup);
				}
				onClose();
			} catch (error) {
				const verb = isEdit ? 'update' : 'add';
				console.error(`Failed to ${verb} the tag value`, error);
				enqueueSnackbar(`Failed to ${verb} the tag value`, {
					variant: 'error',
				});
			} finally {
				setSending(false);
			}
		},
		[
			enqueueSnackbar,
			groupId,
			isEdit,
			onClose,
			setGroup,
			setSending,
			valueToEdit,
		]
	);

	return (
		<>
			{sending && (
				<LinearProgress
					style={{ position: 'absolute', top: 0, width: '100%' }}
				/>
			)}
			<DialogTitle>{`${
				isEdit ? 'Update' : 'Add a'
			} Tag Value`}</DialogTitle>
			<DialogContent>
				<form>
					<TextField
						{...register('tagName')}
						variant="standard"
						size="small"
						label="Name"
						color="primary"
						autoFocus
						onKeyDown={event => {
							if (event.key === 'Enter') {
								handleSubmit(onSubmit)(event);
							}
						}}
						fullWidth
						helperText={errors['tagName']?.message ?? ''}
						error={!!errors['tagName']}
					/>
				</form>
			</DialogContent>
			<DialogActions>
				<ActionButtonContainer>
					<Button
						onClick={() => {
							onClose();
						}}
						variant="outlined"
						disabled={sending}
						disableElevation
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						startIcon={isEdit ? <UpdateIcon /> : <AddTagIcon />}
						onClick={handleSubmit(onSubmit)}
						disabled={!isDirty || sending}
						disableElevation
					>
						{`${isEdit ? 'Update' : 'Add'} Value`}
					</Button>
				</ActionButtonContainer>
			</DialogActions>
		</>
	);
}

TagValuesDialog.propTypes = {
	defaultValues: PropTypes.shape({
		tagName: PropTypes.string.isRequired,
	}),
	groupId: PropTypes.string,
	onClose: PropTypes.func.isRequired,
	sending: PropTypes.bool,
	setGroup: PropTypes.func.isRequired,
	setSending: PropTypes.func.isRequired,
	tagValues: PropTypes.arrayOf(PropTypes.object),
};
