import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';

import { useAppContext } from '../../context/ContextManager';

const Container = styled('div')(() => ({
	width: '100%',
	overflow: 'hidden',
	padding: '0.6em 0',
}));

const Details = styled('div')(() => ({
	display: 'flex',
	alignItems: 'center',
	gap: '0.8em',
}));

const Identifiers = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
}));

const NameContainer = styled('div')(() => ({
	display: 'flex',
	alignItems: 'center',
	width: '100%',
}));

const InitialsContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: 36,
	minWidth: 36,
	height: 36,
	minHeight: 36,
	borderRadius: 18,
	backgroundColor: theme.palette.primary.main + 55,
}));

export default function BtUserRow({ user, hideAvatar, removing, style }) {
	const { user_name, first_name, last_name, email, initials } = user || {};

	const { userInfo } = useAppContext();
	const theme = useTheme();

	const isYou = useMemo(() => userInfo?.uuid === user?.uuid, [
		userInfo,
		user,
	]);

	return (
		<Container style={style}>
			<Details style={{ opacity: removing ? 0.5 : 1.0 }}>
				{!hideAvatar && (
					<InitialsContainer>
						<Typography>
							{initials?.substring(2, 0) ||
								(first_name || '').charAt(0) +
									(last_name || '').charAt(0)}
						</Typography>
					</InitialsContainer>
				)}
				<Identifiers>
					<NameContainer>
						<Typography
							fontWeight={500}
							style={{
								maxWidth: `calc(100% - ${isYou ? 73 : 45}px)`,
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
						>
							{user_name}
						</Typography>
						{isYou && (
							<Typography
								style={{
									marginLeft: '0.8ch',
									color: theme.palette.primary.main,
								}}
							>
								{`• You`}
							</Typography>
						)}
					</NameContainer>
					<Typography
						style={{
							opacity: 0.8,
							overflowWrap: 'break-word',
							maxWidth: 'calc(100% - 45px)',
						}}
					>
						{email}
					</Typography>
				</Identifiers>
			</Details>
		</Container>
	);
}

BtUserRow.propTypes = {
	user: PropTypes.object.isRequired,
	hideAvatar: PropTypes.bool,
	style: PropTypes.object,
};
