import React, { useContext, useEffect } from 'react';

import { Typography } from '@mui/material';
import { useAppContext } from '../../../../context/ContextManager';
import useFetch from '../../../../hooks/useFetch';
import { workflowSubmissionGetList } from '../../../../API';
import _ from 'lodash';

import BtLoading from '../../BtLoading';
import BtSelectionTable from '../../BtSelectionTable';

import IconResolver from '../../../../utils/iconResolver';
import Icon from '@mdi/react';

import { format } from 'date-fns';
import { TileContext } from '../components/TileContext';

const tableColumns = [
	{ field: 'templateGroupIcon', text: 'Group', minBreakpoint: 'sm' },
	{ field: 'templateGroup', text: '', minBreakpoint: 'lg' },
	{ field: 'titleTemplate', text: 'Title & Template', minBreakpoint: 'sm' },
	{ field: 'dueDate', text: 'Due Date', minBreakpoint: 'md' },
	{ field: 'status', text: 'Status', minBreakpoint: 'sm' },
];

export default function WorkflowSubmissionsTile() {
	const { appOrg } = useAppContext();
	const { setError } = useContext(TileContext);

	const {
		loading: submissionListLoading,
		data: submissionList,
		error: submissionListError,
		request: getSubmissionList,
	} = useFetch(workflowSubmissionGetList);

	useEffect(
		() => {
			getSubmissionList();
		},
		[getSubmissionList]
	);

	useEffect(
		() => {
			if (submissionListError)
				setError({ serverError: submissionListError });
		},
		[setError, submissionListError]
	);

	// console.log({ submissionList });

	return (
		<div style={{ height: '100%', width: '100%' }}>
			<BtLoading loading={submissionListLoading}>
				{submissionList && (
					<BtSelectionTable
						containerResponsive
						size="small"
						columns={tableColumns}
						data={(submissionList || []).map(
							({
								uuid,
								template,
								create_timestamp,
								status,
								editable,
								due_date,
								primary_answer,
							}) => ({
								templateGroupIcon: (
									<Icon
										path={IconResolver(
											_.find(
												appOrg?.workflow_template_groups,
												{ uuid: template?.group }
											).icon
										)}
										size={2}
										color={
											_.find(
												appOrg?.workflow_template_groups,
												{ uuid: template?.group }
											).colour
										}
									/>
								),
								templateGroup: (
									<Typography
										variant="body"
										sx={{
											color: _.find(
												appOrg?.workflow_template_groups,
												{ uuid: template?.group }
											).colour,
										}}
									>
										{' '}
										{
											_.find(
												appOrg?.workflow_template_groups,
												{ uuid: template?.group }
											).name
										}{' '}
									</Typography>
								),
								titleTemplate: (
									<div>
										<Typography
											sx={{ fontWeight: 'bold' }}
											variant="body2"
										>
											{primary_answer || '-'}
										</Typography>
										<Typography variant="body2">
											{template.name}
										</Typography>
									</div>
								),
								status: status.status,
								dueDate: due_date
									? format(
											new Date(create_timestamp || 0),
											'dd MMM yyyy, hh:mm a'
									  )
									: '-',
								route: `/Workflows/Submissions/${uuid}`,
							})
						)}
					/>
				)}
			</BtLoading>
		</div>
	);
}
