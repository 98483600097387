export default {
	Completed: {
		color: 'success',
		icon: 'check-circle-outline',
		pastTense: ' ',
		status: 'Completed',
	},
	Draft: {
		color: 'info',
		icon: 'pencil',
		pastTense: ' • Last saved ',
		status: 'Draft',
	},
	InProgress: {
		color: 'info',
		icon: 'progress-pencil',
		pastTense: ' • Last edited ',
		status: 'In Progress',
	},
	Issued: {
		color: 'warning',
		icon: 'star',
		pastTense: ' ',
		status: 'Issued',
	},
	Overdue: {
		color: 'error', // This is called 'danger' in the native app
		icon: 'exclamation',
		pastTense: ' • Due',
		status: 'Overdue',
	},
	Review: {
		color: 'success',
		icon: 'progress-upload',
		pastTense: ' ',
		status: 'Review',
	},
	Rejected: {
		color: 'error', // This is called 'danger' in the native app
		icon: 'close-circle-outline',
		pastTense: ' ',
		status: 'Rejected',
	},
	Submitted: {
		color: 'success',
		icon: 'progress-upload',
		pastTense: ' ',
		status: 'Submitted',
	},
};
