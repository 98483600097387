import React, { useState, useMemo, useEffect } from 'react';
import { Typography } from '@mui/material';

import admin_nav_item from '../AdminNavItem';
import admin_options from '../AdminOptions';
import {
	a11yProps,
	BtTab,
	BtTabBar,
	BtTabPanel,
	BtTabView,
} from '../../../components/generic/BtTabView';
import BtError from '../../../components/generic/BtError';
import { BtFormContainer } from '../../../components/generic/forms';
import BtOverview from '../../../components/generic/BtOverview';
import { tagGroupGet } from '../../../API/tags.api';
import TagsEditorDetails from './TagsEditorDetails';
import TagsEditorValues from './TagsEditorValues';
import useFetch from '../../../hooks/useFetch';
import { useNavContext } from '../../../context/ContextManager';
import BtLoading from '../../../components/generic/BtLoading';
import BtManagedBy from '../../../components/generic/BtManagedBy';

export default function TagsEditor({ match }) {
	const editId = useMemo(() => match.params.id, [match]);

	const [group, setGroup] = useState();
	const [currentTab, setCurrentTab] = useState(0);

	// Set contextual navigation items and breadcrumbs
	const { setContextualNav, setBreadcrumbs } = useNavContext();
	useEffect(
		() => {
			setContextualNav([...admin_nav_item, ...admin_options]);
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'Admin', link: '/Admin' },
				{ text: 'Tags', link: '/Tags' },
				{ text: `Edit Group`, link: '' },
			]);
			return () => setContextualNav(null);
		},
		[setContextualNav, setBreadcrumbs]
	);

	const { error, loading, request: getGroup } = useFetch(
		tagGroupGet,
		setGroup
	);

	useEffect(
		() => {
			if (editId) {
				getGroup({ tagGroupUuid: editId });
			}
		},
		[getGroup, editId]
	);

	const { values, uuid } = useMemo(() => group || {}, [group]);

	const stats = useMemo(
		() => {
			const total = group?.values.length;
			const active = group?.values.filter(
				({ status }) => status === 'Active'
			).length;
			const archived = total - active;

			return {
				active,
				archived,
				total,
			};
		},
		[group]
	);

	if (error) {
		return (
			<BtError
				title="Retrieval Error"
				description={
					<>
						<Typography>
							An error occurred whilst trying to retrieve the
							group.
						</Typography>
						<Typography>{`(group id: ${editId})`}</Typography>
					</>
				}
				action={() => getGroup({ tagGroupUuid: editId })}
				variant="error"
			/>
		);
	}

	return (
		<BtTabView>
			<BtFormContainer
				title={
					<div
						style={{
							alignItems: 'center',
							display: 'flex',
							flexWrap: 'wrap',
							justifyContent: 'space-between',
							width: '100%',
						}}
					>
						<Typography variant="h3">
							{group?.name || ''}
						</Typography>
						{group?.managedBy && (
							<BtManagedBy
								managedBy={group?.managedBy}
								platformTooltip="You cannot edit the details of this group"
								organisationTooltip="You are free to edit all aspects of this group"
								style={{ margin: '4px 0' }}
							/>
						)}
					</div>
				}
				header={
					<BtLoading
						loading={loading}
						LoadingComponent={<div style={{ height: 50 }} />}
					>
						<BtTabBar
							currentTab={currentTab}
							onTabChange={(event, selectedTab) =>
								setCurrentTab(selectedTab)
							}
							style={{
								transform: 'translateY(-1px)',
								background: 'none',
								marginTop: 10,
							}}
						>
							<BtTab label="Details" {...a11yProps(0)} />
							<BtTab
								label={`Values${
									(group?.values || []).length > 0
										? ` (${group.values.length})`
										: ''
								}`}
								{...a11yProps(1)}
							/>
						</BtTabBar>
					</BtLoading>
				}
				style={{ maxWidth: 800 }}
			>
				<BtTabPanel currentTab={currentTab} index={0}>
					<BtOverview
						createdTimestamp={group?.create_timestamp}
						modifiedTimestamp={group?.modify_timestamp}
						loading={loading}
					>
						<div
							style={{
								display: 'flex',
								flexWrap: 'wrap',
							}}
						>
							<Typography
								variant="subtitle"
								style={{ marginRight: '2em' }}
							>
								{stats.total} values in total
							</Typography>
							{stats.total > 0 &&
								['active', 'archived'].map(status => (
									<Typography
										key={status}
										variant="subtitle"
										style={{ marginRight: '2em' }}
									>
										{`${stats[status]} ${status} values`}
									</Typography>
								))}
						</div>
					</BtOverview>
					<TagsEditorDetails
						loading={loading}
						group={group}
						setGroup={setGroup}
					/>
				</BtTabPanel>
				<BtTabPanel currentTab={currentTab} index={1}>
					<TagsEditorValues
						groupId={uuid}
						setGroup={setGroup}
						tagValues={values}
					/>
				</BtTabPanel>
			</BtFormContainer>
		</BtTabView>
	);
}
