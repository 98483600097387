const yup = require('yup');

const configVersionGetSchema = yup.object({
	name: yup.string().required(),
	description: yup.string(),
	device_type: yup
		.string()
		.uuid()
		.required(),
	version: yup.number().required(),
	version_uuid: yup
		.string()
		.uuid()
		.required(),
	create_timestamp: yup.number().required(),
	channels: yup
		.array()
		.of(
			yup.object({
				name: yup.string().required(),
				params: yup
					.array()
					.of(
						yup.object({
							name: yup.string().required(),
							input: yup.string().required(),
							function: yup.string().required(),
						})
					)
					.required(),
				trigger_start: yup
					.object({
						type: yup.string().required(),
						time_unit: yup.string(),
						count: yup.number(),
						auto_reload: yup.boolean(),
					})
					.required(),
			})
		)
		.required(),
});

const configHistoryGetSchema = yup.object({
	uuid: yup
		.string()
		.uuid()
		.required(),
	name: yup.string().required(),
	description: yup.string(),
	device_type: yup
		.string()
		.uuid()
		.required(),
	versions: yup
		.array()
		.of(
			yup
				.object({
					version: yup.number().required(),
					uuid: yup
						.string()
						.uuid()
						.required(),
					create_timestamp: yup.number().required(),
					channels: yup
						.array()
						.of(
							yup.object({
								name: yup.string().required(),
								params: yup
									.array()
									.of(
										yup.object({
											name: yup.string().required(),
											input: yup.string().required(),
											function: yup.string().required(),
										})
									)
									.required(),
								trigger_start: yup
									.object({
										type: yup.string().required(),
										time_unit: yup.string(),
										count: yup.number(),
										auto_reload: yup.boolean(),
									})
									.required(),
							})
						)
						.required(),
				})
				.required()
		)
		.required(),
});

const configLatestGetSchema = yup.object({
	name: yup.string().required(),
	description: yup.string(),
	device_type: yup
		.string()
		.uuid()
		.required(),
	version: yup.number().required(),
	version_uuid: yup
		.string()
		.uuid()
		.required(),
	create_timestamp: yup.number().required(),
	channels: yup
		.array()
		.of(
			yup.object({
				name: yup.string().required(),
				params: yup
					.array()
					.of(
						yup.object({
							name: yup.string().required(),
							input: yup.string().required(),
							function: yup.string().required(),
						})
					)
					.required(),
				trigger_start: yup
					.object({
						type: yup.string().required(),
						time_unit: yup.string(),
						count: yup.number(),
						auto_reload: yup.boolean(),
					})
					.required(),
			})
		)
		.required(),
});

module.exports = {
	configVersionGetSchema,
	configHistoryGetSchema,
	configLatestGetSchema,
};
