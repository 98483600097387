import React, {
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';

import {
	AddCircle,
	ArrowDownward,
	ArrowUpward,
	Delete,
} from '@mui/icons-material';
import {
	Card,
	CardActionArea,
	Checkbox,
	Collapse,
	Divider,
	FormControl,
	FormControlLabel,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	styled,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import produce from 'immer';
import { useFormContext } from 'react-hook-form';
import { v4 as generateUuid } from 'uuid';

import BtConfirmDialog from '../../../../components/generic/BtConfirmDialog';
import { imageFormat } from '../../../../API/validations/imageRepoValidation';
import { useAppContext } from '../../../../context/ContextManager';

const AddFormatContent = styled('div')(({ theme }) => ({
	alignItems: 'center',
	color: theme.palette.primary.main,
	display: 'flex',
	justifyContent: 'center',
}));

const FormatContent = styled('div')(() => ({
	display: 'flex',
	justifyContent: 'space-between',
}));

const Format = ({
	disabled,
	format,
	onChange,
	onDelete,
	onMoveDown,
	onMoveUp,
}) => {
	const { grayscale, maxWidthHeight, name, watermark } = format;

	const [errors, setErrors] = useState({});

	const { showMobile } = useAppContext();

	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [touched, setTouched] = useState([]);

	const hasWatermark = watermark && Object.keys(watermark).length > 0;

	const currentWatermark = useRef(
		hasWatermark
			? format.watermark
			: { type: 'text', text: '', dpi: 300, tile: true }
	);

	const handleChange = useCallback(
		(key, value) => {
			if (key === 'watermark' && value !== null) {
				currentWatermark.current = value;
			}

			onChange(
				produce(format, () => {
					format[key] = value;
				})
			);
		},
		[format, onChange]
	);

	const handleWatermarkChange = useCallback(
		(key, value) => {
			handleChange('watermark', { ...format.watermark, [key]: value });
		},
		[format, handleChange]
	);

	const handleTouch = useCallback(
		name => {
			if (!touched.includes(name)) {
				setTouched(prev => [...prev, name]);
			}
		},
		[touched]
	);

	const visibleErrors = useMemo(
		() => {
			return touched.reduce((accumulator, name) => {
				const error = errors[name];

				if (error) {
					return { ...accumulator, [name]: error };
				}

				return accumulator;
			}, {});
		},
		[errors, touched]
	);

	useLayoutEffect(
		() => {
			try {
				imageFormat.validateSync(format, { abortEarly: false });

				setErrors({});
			} catch (error) {
				setErrors(
					(error.inner || []).reduce(
						(accumulator, { message, path }) => ({
							...accumulator,
							[path]: message,
						}),
						{}
					)
				);
			}
		},
		[format]
	);

	useEffect(() => {
		const blockEnterKey = event => {
			if (event.code === 'Enter' || event.code === 'NumpadEnter') {
				event.preventDefault();
			}
		};

		document.addEventListener('keydown', blockEnterKey);

		return () => {
			document.removeEventListener('keydown', blockEnterKey);
		};
	}, []);

	const componentMargin = '1em 1em 0 0';

	return (
		<>
			<Card
				style={{ padding: '1em', marginBottom: '1em' }}
				variant="outlined"
			>
				<FormatContent
					style={{
						flexDirection: showMobile ? 'column-reverse' : 'row',
					}}
				>
					<div style={{ marginRight: '1em', width: '100%' }}>
						<TextField
							disabled={disabled}
							error={!!visibleErrors.name}
							fullWidth
							helperText={visibleErrors.name ?? ''}
							label="Name"
							onBlur={() => handleTouch('name')}
							onChange={event =>
								handleChange('name', event.currentTarget.value)
							}
							value={name}
							variant="standard"
						/>
						<TextField
							disabled={disabled}
							error={!!visibleErrors.maxWidthHeight}
							helperText={visibleErrors.maxWidthHeight ?? ''}
							label="Max Width/Height"
							onBlur={() => handleTouch('maxWidthHeight')}
							onChange={event =>
								handleChange(
									'maxWidthHeight',
									event.currentTarget.value
								)
							}
							style={{ margin: componentMargin }}
							type="number"
							value={maxWidthHeight}
							variant="standard"
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={grayscale ?? false}
									disabled={disabled}
									onChange={event =>
										handleChange(
											'grayscale',
											event.currentTarget.checked
										)
									}
								/>
							}
							label="Grayscale"
							style={{ margin: componentMargin }}
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={hasWatermark ?? false}
									disabled={disabled}
									onChange={event => {
										const checked =
											event.currentTarget.checked;

										if (checked) {
											handleChange('watermark', {
												...currentWatermark.current,
											});

											return;
										}

										handleChange('watermark', null);
									}}
								/>
							}
							label="Watermark"
							style={{ margin: componentMargin }}
						/>
						<Collapse in={hasWatermark}>
							<div style={{ marginTop: '1.5em', width: '100%' }}>
								<Divider />
								<Typography style={{ marginTop: '1em' }}>
									Watermark Settings
								</Typography>
								<FormControl
									style={{
										margin: componentMargin,
										minWidth: 140,
									}}
									variant="standard"
								>
									<InputLabel
										id="wmType-label"
										style={{ float: 'left' }}
									>
										Type
									</InputLabel>
									<Select
										disabled={disabled}
										label="Type"
										labelId="wmType-label"
										onChange={event =>
											handleWatermarkChange(
												'type',
												event.currentTarget.value
											)
										}
										value={watermark?.type || 'text'}
									>
										<MenuItem value={'text'}>Text</MenuItem>
									</Select>
								</FormControl>
								<TextField
									disabled={disabled}
									label="Text"
									onChange={event =>
										handleWatermarkChange(
											'text',
											event.currentTarget.value
										)
									}
									style={{ margin: componentMargin }}
									value={
										watermark?.text ??
										currentWatermark.current.text
									}
									variant="standard"
								/>
								<TextField
									disabled={disabled}
									label="DPI"
									onChange={event =>
										handleWatermarkChange(
											'dpi',
											event.currentTarget.value
										)
									}
									style={{ margin: componentMargin }}
									type="number"
									value={
										watermark?.dpi ??
										currentWatermark.current.dpi
									}
									variant="standard"
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={
												watermark?.tile ??
												currentWatermark.current.tile
											}
											disabled={disabled}
											onChange={event =>
												handleWatermarkChange(
													'tile',
													event.currentTarget.checked
												)
											}
										/>
									}
									label="Tile"
									style={{ margin: componentMargin }}
								/>
							</div>
						</Collapse>
					</div>
					<div
						style={{
							alignItems: showMobile ? 'center' : 'flex-start',
							display: 'flex',
							flexDirection: showMobile ? 'row' : 'column',
							justifyContent: 'flex-start',
							marginBottom: showMobile ? '0.5em' : 0,
						}}
					>
						<Tooltip
							disableInteractive
							title={onMoveUp ? 'Move Up' : ''}
						>
							<span>
								<IconButton
									disabled={disabled || !onMoveUp}
									onClick={() => onMoveUp?.()}
								>
									<ArrowUpward />
								</IconButton>
							</span>
						</Tooltip>
						<Tooltip
							disableInteractive
							title={onMoveDown ? 'Move Down' : ''}
						>
							<span>
								<IconButton
									disabled={disabled || !onMoveDown}
									onClick={() => onMoveDown?.()}
								>
									<ArrowDownward />
								</IconButton>
							</span>
						</Tooltip>
						<Tooltip disableInteractive title="Delete">
							<IconButton
								color="error"
								disabled={disabled}
								onClick={() => setShowDeleteDialog(true)}
							>
								<Delete />
							</IconButton>
						</Tooltip>
					</div>
				</FormatContent>
			</Card>
			<BtConfirmDialog
				action={onDelete}
				ActionIcon={<Delete />}
				isDestructive
				onClose={() => setShowDeleteDialog(false)}
				open={showDeleteDialog}
				prompt="Deleting a format will cause any currently uploaded images of this format to become dissociated with the repository. This cannot be undone."
				title="Delete Format"
				verb="Delete"
			/>
		</>
	);
};

export default function FormImageFormatList({ name }) {
	const { sending, setValue, watch } = useFormContext() || {};

	const value = watch(name);

	const missingFormats = (value || []).length === 0;

	const newImageFormat = useCallback(
		() => ({
			uuid: generateUuid(),
			name: '',
			maxWidthHeight: 300,
			grayscale: false,
			watermark: null,
		}),
		[]
	);

	const moveElement = useCallback(
		(index, direction = 'down') => {
			return produce(draft => {
				const swapIndex = index + (direction === 'down' ? 1 : -1);
				const temp = value[swapIndex];

				draft[swapIndex] = value[index];
				draft[index] = temp;
			})(value);
		},
		[value]
	);

	const updateValue = useCallback(
		value =>
			setValue(name, value, {
				shouldValidate: true,
				shouldDirty: true,
				shouldTouch: true,
			}),
		[name, setValue]
	);

	return (
		<>
			<Typography style={{ margin: '0.5em 0 0.2em' }} variant="h6">
				Formats
			</Typography>
			{!missingFormats && (
				<>
					{value.map((format, index) => (
						<Format
							key={format.uuid}
							onMoveUp={
								index > 0
									? () =>
											updateValue(
												moveElement(index, 'up')
											)
									: null
							}
							onMoveDown={
								index < value.length - 1
									? () =>
											updateValue(
												moveElement(index, 'down')
											)
									: null
							}
							disabled={sending}
							format={format}
							onDelete={() =>
								updateValue(
									produce(value, draft => {
										draft.splice(index, 1);
									})
								)
							}
							onChange={updatedFormat =>
								updateValue(
									produce(value, draft => {
										draft[index] = updatedFormat;
									})
								)
							}
						/>
					))}
				</>
			)}
			<Card
				style={{
					borderStyle: 'dashed',
					height: 100,
					marginBottom: '2em',
				}}
				variant="outlined"
			>
				<CardActionArea
					disableTouchRipple
					onClick={() => updateValue([...value, newImageFormat()])}
					style={{
						minHeight: '100%',
					}}
				>
					<AddFormatContent>
						<AddCircle style={{ marginRight: '0.2em' }} />
						<Typography>{`Add ${
							missingFormats ? 'a' : 'Another'
						} Format`}</Typography>
					</AddFormatContent>
				</CardActionArea>
			</Card>
		</>
	);
}

Format.propTypes = {
	disabled: PropTypes.bool,
	format: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	onMoveDown: PropTypes.func,
	onMoveUp: PropTypes.func,
};

FormImageFormatList.propTypes = {
	name: PropTypes.string.isRequired,
};
