import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useNavContext } from '../../context/ContextManager';

import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';

import AddReporterDialog from '../../components/dialogs/AddReporterDialog';
import {
	ListTable,
	ListRow,
	ListHeader,
	ListCell,
} from '../../components/generic/ListTable';
import {
	BtTabView,
	BtTabBar,
	BtTab,
	BtTabPanel,
	a11yProps,
} from '../../components/generic/BtTabView';

// Data for landing page selection table and contextual navigation
import data_manager_options from './DataManagerOptions';
import data_manager_breadcrumbs from './DataManagerBreadcrumbs';
import data_manager_nav_item from './DataManagerNavItem';

const styles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(2),
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
	},
	inputValue: {
		fontSize: '4rem',
		fontWeight: 700,
		textAlign: 'center',
	},
}));

export default function Exports() {
	const classes = styles();
	const [isLoading, setIsLoading] = useState(true);
	const [newReporterData, setNewReporterData] = useState([]);
	const [reporters, setReporters] = useState([]);
	const [addReporterOpen, setAddReporterOpen] = useState(false);
	const history = useHistory();
	const { setBreadcrumbs } = useNavContext();
	const { setContextualNav } = useNavContext();

	const [currentTab, setCurrentTab] = useState(0);
	const handleTabChange = (event, newTabValue) => {
		setCurrentTab(newTabValue);
	};

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				...data_manager_breadcrumbs,
				{ text: 'Exports', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([
				...data_manager_nav_item,
				...data_manager_options,
			]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	useEffect(
		() => {
			var new_reporters_list = [];
			newReporterData.forEach(newReporter => {
				if (newReporter.uuid !== undefined) {
					console.log('adding reporter');
					//setReporters([...reporters, newReporter]);
					new_reporters_list.push(newReporter);
				}
			});
			setReporters(new_reporters_list);
		},
		[newReporterData]
	);

	const refreshReports = () => {
		var req_url = process.env.REACT_APP_API_SERVER_URL + `/v1/reporter`;

		Auth.currentSession().then(user_session => {
			let user_token = user_session.getAccessToken();
			fetch(req_url, {
				method: 'GET',
				headers: {
					Authorization: user_token.jwtToken,
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			})
				.then(res => res.json())
				.then(response => {
					setNewReporterData(response.reporters);
					setIsLoading(false);
				})
				.catch(error => {
					console.log(error);
					setIsLoading(false);
				});
		});
	};

	useEffect(() => {
		refreshReports();
	}, []);

	const handleClickAddReporter = () => {
		setAddReporterOpen(true);
	};
	const handleAddReporterClose = new_report => {
		setAddReporterOpen(false);
		if (new_report === true) {
			refreshReports();
		}
	};

	const handleEdit = uuid => () => {
		console.log('UUID: ' + uuid);
		history.push('/ReporterEdit/' + uuid);
	};

	// const breadcrumbs = [{"text":"Home", "link":"/"},{"text":"Exports", "link":""}];
	return (
		<>
			{isLoading && (
				<Paper>
					<p>Loading...</p>
					<CircularProgress size={'120px'} />
				</Paper>
			)}

			{!isLoading && (
				<div className={classes.root}>
					<BtTabView>
						<BtTabBar
							currentTab={currentTab}
							onTabChange={handleTabChange}
						>
							<BtTab label="External Reports" {...a11yProps(0)} />
							<BtTab label="Other" {...a11yProps(1)} />
						</BtTabBar>

						<BtTabPanel currentTab={currentTab} index={0}>
							<Typography variant="h4">
								External Report Services
							</Typography>
							<Container className={classes.button_row}>
								<Button
									variant="outlined"
									startIcon={<AddCircleIcon />}
									onClick={handleClickAddReporter}
								>
									Add New Reporter
								</Button>
							</Container>
							{reporters.map((reporter, index) => (
								<Paper key={index}>
									<ListTable>
										<ListRow>
											<ListHeader>Name:</ListHeader>
											<ListCell>{reporter.name}</ListCell>
										</ListRow>
										<ListRow>
											<ListHeader>Device:</ListHeader>
											<ListCell>{reporter.uid}</ListCell>
										</ListRow>
										<ListRow>
											<ListHeader>Channel:</ListHeader>
											<ListCell>
												{reporter.channel}
											</ListCell>
										</ListRow>
										<ListRow>
											<ListHeader>URL:</ListHeader>
											<ListCell>{reporter.url}</ListCell>
										</ListRow>
										<ListRow>
											<ListHeader />
											<ListCell>
												<Button
													variant="outlined"
													startIcon={<EditIcon />}
													onClick={handleEdit(
														reporter.uuid
													)}
												>
													Edit
												</Button>
											</ListCell>
										</ListRow>
									</ListTable>
								</Paper>
							))}
						</BtTabPanel>
						<BtTabPanel currentTab={currentTab} index={1}>
							<Typography variant="h4">Reports</Typography>
						</BtTabPanel>
					</BtTabView>
				</div>
			)}

			<AddReporterDialog
				setOpen={addReporterOpen}
				onClose={handleAddReporterClose}
			/>
		</>
	);
}
