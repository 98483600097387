import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';

import {
	AddPhotoAlternateRounded as AddImageIcon,
	ArrowBack,
	Edit,
	Refresh,
	Save,
	WarningRounded,
} from '@mui/icons-material';
import { Button, IconButton, styled, Tooltip } from '@mui/material';
import { Prompt } from 'react-router-dom';

import {
	a11yProps,
	BtTab,
	BtTabBar,
	BtTabPanel,
	BtTabView,
} from '../../../../components/generic/BtTabView';
import ActionButtonContainer from '../../../../components/generic/ActionButtonContainer';
import BtConfirmDialog from '../../../../components/generic/BtConfirmDialog';
import BtError from '../../../../components/generic/BtError';
import { BtFormContainer } from '../../../../components/generic/forms';
import BtLoading from '../../../../components/generic/BtLoading';
import data_manager_breadcrumbs from '../../DataManagerBreadcrumbs';
import data_manager_nav_item from '../../DataManagerNavItem';
import data_manager_options from '../../DataManagerOptions';
import ImageAddDialog from './ImageAddDialog';
import ImageRepoConfig from './ImageRepoConfig';
import { imageRepoGet, imageRepoImagesGet } from '../../../../API';
import ImageRepoImageList from './ImageRepoImageList';
import { useAppContext } from '../../../../context/ContextManager';
import useAvailableToUser from '../../../../hooks/useAvailableToUser';
import useFetch from '../../../../hooks/useFetch';
import { useHistory } from 'react-router';
import { useNavContext } from '../../../../context/ContextManager';

const WarningIcon = styled(WarningRounded)(({ theme }) => ({
	color: theme.palette.indicators.warning.main,
}));

export default function ImageRepo({ match }) {
	const configRef = useRef();

	const checkAvailability = useAvailableToUser();
	const history = useHistory();
	const { setBreadcrumbs, setContextualNav } = useNavContext();
	const { userInfo } = useAppContext();

	const [currentTab, setCurrentTab] = useState(0);
	const [editMode, setEditMode] = useState(false);
	const [images, setImages] = useState();
	const [repo, setRepo] = useState();
	const [showAddDialog, setShowAddDialog] = useState(false);
	const [showDiscardDialog, setShowDiscardDialog] = useState(false);

	const repoUuid = useMemo(() => match.params.uuid, [match]);

	const canViewImages = checkAvailability({
		requiredPermissionsAll: {
			dataManager: ['ImageRepoView'],
		},
	}).available;

	const canAddImages = checkAvailability({
		requiredPermissionsAll: {
			dataManager: ['ImageRepoCreateImage'],
		},
	}).available;

	const canEditConfig = checkAvailability({
		requiredPermissionsAll: {
			dataManager: ['ImageRepoEdit'],
		},
	}).available;

	const {
		error: repoError,
		loading: loadingRepo,
		request: getImageRepo,
	} = useFetch(imageRepoGet, setRepo);

	const {
		error: imageListError,
		loading: loadingImages,
		request: getImageList,
	} = useFetch(imageRepoImagesGet, setImages);

	const {
		error: imageDeleteError,
		loading: deletingImage,
		request: deleteImage,
	} = useFetch();

	const refreshImageList = useCallback(
		() => {
			if (!canViewImages) return;

			getImageList({ imageRepo: repoUuid });
		},
		[canViewImages, getImageList, repoUuid]
	);

	const handleImageAdd = useCallback(
		() => {
			setShowAddDialog(false);

			refreshImageList();
		},
		[refreshImageList]
	);

	const handleDeleteImage = useCallback(() => {});

	const handleRepoUpdate = useCallback(
		repoUpdates => {
			setEditMode(false);

			setRepo({
				...repo,
				...repoUpdates,
				modify_by: userInfo.uuid,
				modify_timestamp: +new Date(),
			});
		},
		[repo, userInfo]
	);

	useEffect(
		() => {
			setBreadcrumbs([
				...data_manager_breadcrumbs,
				{ text: 'Data Sources', link: '/DataSources' },
				{ text: `Image Repo: ${repo?.name || 'Loading...'}`, link: '' },
			]);
		},
		[repo, setBreadcrumbs]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([
				...data_manager_nav_item,
				...data_manager_options,
			]);

			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	useEffect(
		() => {
			if (repoUuid) {
				getImageRepo({ imageRepo: repoUuid });

				refreshImageList();
			}
		},
		[getImageRepo, refreshImageList, repoUuid]
	);

	const repoMissingFormats = repo && (repo?.formats || []).length === 0;

	if (repoError) {
		return (
			<BtError
				action={() => {
					getImageRepo({ imageRepo: repoUuid });

					if (imageListError) {
						refreshImageList();
					}
				}}
				actionLabel="Try Again"
				description="An error occurred when attempting to retrieve the repo."
				title="Repo Error"
			/>
		);
	}

	if (loadingRepo) {
		return <BtLoading showLogo={false} />;
	}

	return (
		<>
			<BtTabView>
				<BtFormContainer
					ActionButtons={
						<>
							{currentTab === 1 && (
								<>
									{!editMode && (
										<Button
											disableElevation
											onClick={() => setEditMode(true)}
											startIcon={<Edit />}
											variant="contained"
										>
											Edit
										</Button>
									)}
									{editMode && (
										<ActionButtonContainer
											style={{ padding: 0 }}
										>
											<Button
												disableElevation
												onClick={() =>
													configRef.current.saveChanges()
												}
												startIcon={<Save />}
												type="submit"
												variant="contained"
											>
												Save
											</Button>
											<Button
												disableElevation
												onClick={() =>
													setShowDiscardDialog(true)
												}
											>
												Discard Changes
											</Button>
										</ActionButtonContainer>
									)}
								</>
							)}
						</>
					}
					header={
						<BtTabBar
							currentTab={currentTab}
							onTabChange={(event, selectedTab) =>
								setCurrentTab(selectedTab)
							}
						>
							<BtTab
								disabled={editMode}
								label="Images"
								{...a11yProps(0)}
							/>
							{canEditConfig && (
								<BtTab
									label={
										<div
											style={{
												alignItems: 'center',
												display: 'flex',
											}}
										>
											Config
											{repoMissingFormats && (
												<Tooltip
													disableInteractive
													style={{
														marginLeft: '0.2em',
													}}
													title="This repository has not been fully configured."
												>
													<WarningIcon />
												</Tooltip>
											)}
										</div>
									}
									{...a11yProps(1)}
								/>
							)}
						</BtTabBar>
					}
					maxWidth="md"
					title={repo?.name}
				>
					<BtTabPanel currentTab={currentTab} index={0}>
						<div
							style={{
								alignItems: 'center',
								display: 'flex',
								margin: '-0.5em 0 0.5em',
							}}
						>
							{!repoMissingFormats &&
								canAddImages && (
									<Button
										disableElevation
										onClick={() => setShowAddDialog(true)}
										startIcon={<AddImageIcon />}
										style={{ marginRight: '0.5em' }}
										variant="contained"
									>
										Add Image
									</Button>
								)}
							{!loadingImages &&
								canViewImages &&
								(images || []).length > 0 && (
									<Tooltip
										disableInteractive
										title="Refresh Page"
									>
										<IconButton
											onClick={refreshImageList}
											size="small"
										>
											<Refresh />
										</IconButton>
									</Tooltip>
								)}
						</div>
						{imageListError && (
							<BtError
								action={() => refreshImageList()}
								actionLabel="Try Again"
								description="An error occurred when attempting to retrieve the images."
								title="Images Error"
							/>
						)}
						{canViewImages && (
							<ImageRepoImageList
								images={images}
								loading={loadingImages}
								onRefresh={refreshImageList}
								onDeleteImage={handleDeleteImage}
								repo={repo}
							/>
						)}
						{!canViewImages && (
							<BtError
								action={() => history.push('/DataSources')}
								actionIcon={<ArrowBack />}
								actionLabel="Return"
								description="You do not have permission to view images."
								variant="forbidden"
								title="Forbidden"
							/>
						)}
					</BtTabPanel>
					<BtTabPanel currentTab={currentTab} index={1}>
						<ImageRepoConfig
							ref={configRef}
							editMode={editMode}
							onUpdateRepo={handleRepoUpdate}
							repo={repo}
						/>
					</BtTabPanel>
				</BtFormContainer>
			</BtTabView>
			<ImageAddDialog
				onAdd={handleImageAdd}
				onClose={() => setShowAddDialog(false)}
				open={showAddDialog}
				repo={repo}
			/>
			<Prompt
				message="You have unsaved changes to the repository, are you sure you want to leave?"
				when={editMode}
			/>
			<BtConfirmDialog
				action={() => setEditMode(false)}
				isDestructive
				onClose={() => setShowDiscardDialog(false)}
				open={showDiscardDialog}
				prompt="Are you sure you want to discard the changes you have made to this repository? This cannot be undone."
				title="Discard Changes"
				verb="Discard"
			/>
		</>
	);
}

ImageRepo.propTypes = {
	match: PropTypes.object.isRequired,
};
