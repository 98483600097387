import React from 'react';

// MUI ICONS
// import Dashboard from '@mui/icons-material/Dashboard';
// import InsightsIcon from '@mui/icons-material/Insights';
import ConstructionIcon from '@mui/icons-material/Construction';

const beacon_admin_nav_item = [
	{
		id: 'BeaconAdmin',
		text: 'Beacon Admin',
		route: '/BeaconAdmin',
		icon: <ConstructionIcon />,
	},
];

export default beacon_admin_nav_item;
