import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { BtFormActionsContainer } from '.';
import { Container, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useSize from '@react-hook/size';
import { useFormContext } from 'react-hook-form';

export default function BtFormContainer({
	ActionButtons,
	actionsContainerProps,
	children,
	header,
	style,
	subtitle,
	title,
	...props
}) {
	const containerRef = useRef();
	const headerRef = useRef();
	const [containerWidth] = useSize(containerRef);
	const [, headerHeight] = useSize(headerRef);

	const theme = useTheme();
	const screen_downSm = useMediaQuery(theme.breakpoints.down('sm'));

	const { loading } = useFormContext() || {};

	return (
		<>
			<Container
				ref={containerRef}
				style={{ position: 'relative', ...style }}
				{...props}
			>
				<div
					ref={headerRef}
					style={{
						height: 'auto',
						width: `calc(${containerWidth}px - ${
							screen_downSm ? '32px' : '48px'
						})`,
						paddingTop: '1em',
						position: 'fixed',
						zIndex: 900,
						transform: 'translateY(-1.5em)',
					}}
				>
					<div
						style={{
							backgroundColor: theme.palette.background.default,
							display: 'flex',
							flexDirection: 'column',
							minHeight: '100%',
							padding: '1.5em 5px 0 5px',
							transform: 'translateX(-5px)',
							width: 'calc(100% + 10px)',
						}}
					>
						<div
							style={{
								display: 'flex',
								flexWrap: 'wrap',
								gap: 10,
								justifyContent: 'space-between',
								width: '100%',
							}}
						>
							{typeof title === 'string' ? (
								<Typography variant="h3">{title}</Typography>
							) : (
								<>{title}</>
							)}
							{ActionButtons &&
								!screen_downSm &&
								!loading && (
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: 10,
										}}
										{...actionsContainerProps}
									>
										{ActionButtons}
									</div>
								)}
						</div>

						{subtitle && (
							<Typography variant="h5">{subtitle}</Typography>
						)}
						{header && <div>{header}</div>}
					</div>
					<div
						style={{
							width: 'calc(100% + 10px)',
							height: 20,
							transform: 'translate(-5px, -0.5px)',
							background: `linear-gradient(0deg, ${
								theme.palette.background.default
							}00 0%, ${theme.palette.background.default} 33%, ${
								theme.palette.background.default
							} 100%)`,
						}}
					/>
				</div>
				<div
					style={{
						paddingTop: `calc(${headerHeight}px + 0.5em - 20px)`,
						marginBottom: ActionButtons && screen_downSm ? 180 : 0,
					}}
				>
					{children}
				</div>
			</Container>
			{ActionButtons &&
				screen_downSm && (
					<div
						style={{
							display: 'block',
							position: 'fixed',
							bottom: 0,
							width: containerWidth,
							zIndex: 900,
						}}
					>
						<div
							style={{
								width: 'calc(100% + 10px)',
								height: 20,
								transform: 'translate(-5px, 0.5px)',
								background: `linear-gradient(180deg, ${
									theme.palette.background.default
								}00 0%, ${
									theme.palette.background.default
								} 33%, ${
									theme.palette.background.default
								} 100%)`,
							}}
						/>
						<div
							style={{
								backgroundColor:
									theme.palette.background.default,
								padding: '0 0 0.5em 0',
							}}
						>
							<BtFormActionsContainer>
								{ActionButtons}
							</BtFormActionsContainer>
						</div>
					</div>
				)}
		</>
	);
}

BtFormContainer.propTypes = {
	ActionButtons: PropTypes.node,
	actionsContainerProps: PropTypes.object,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	header: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	style: PropTypes.object,
	subtitle: PropTypes.string,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
