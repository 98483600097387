import {
	ARRAY,
	BOOLEAN,
	DATE,
	NUMBER,
	OBJECT,
	STRING,
} from '../../../utils/commonDataTypes';

const primativeDefaults = {
	[BOOLEAN]: false,
	[NUMBER]: 0,
	[STRING]: '',
};

export const elementGenerator = schema => {
	const outputNode = (schema, isRoot) => {
		const {
			arrayContent,
			default: defaultValue,
			objectContent,
			type,
		} = schema;

		if (type === ARRAY) {
			return [...(isRoot ? [outputNode(arrayContent)] : [])];
		}

		if (type === OBJECT) {
			return Object.keys(objectContent).reduce(
				(accumulator, key) => ({
					...accumulator,
					[key]: outputNode(objectContent[key]),
				}),
				{}
			);
		}

		if (type === DATE) {
			return new Date().toISOString();
		}

		return defaultValue?.value ?? primativeDefaults[type];
	};

	return outputNode(schema, true);
};

export default elementGenerator;
