import {
	COMMIT_DRAFT,
	COMMIT_DRAFT_API,
	UPDATE_RECORD,
	UPDATE_RECORD_API,
} from '../constants';
import { lookupEndpoint } from '../lookupTables/apiEndpoints';

const handleCommitDraft = async ({
	apiUuidParamPayload,
	moduleFullName,
	module,
	recordHistoryGet,
	payload,
}) => {
	// Initialise return value
	const result = {};
	result.variant = 'success';

	try {
		// get the commit request from lookup
		const commitDraftEndpoint = lookupEndpoint(module, COMMIT_DRAFT_API);

		// get the update collection request from lookup
		const updateRecordEndpoint = lookupEndpoint(module, UPDATE_RECORD_API);

		if (!commitDraftEndpoint || !updateRecordEndpoint) {
			throw new Error('');
		}

		// commit the draft to the versions array
		await commitDraftEndpoint(apiUuidParamPayload);

		// If the makeCommitCurrent flag is set, update the record current_version
		// to the one we just committed
		if (payload.makeCommitCurrent) {
			await updateRecordEndpoint({
				...apiUuidParamPayload,
				update: {
					current_version: payload.newCurrentVersion,
				},
			});
			// Update return value
			result.text = `${moduleFullName} draft committed and made current`;
		} else {
			// Update return value
			result.text = `${moduleFullName} draft committed`;
		}
		// // Update return value
		// result.text = `${moduleFullName} draft committed`;
	} catch (error) {
		console.log(error);
		result.text = `Error committing ${moduleFullName} draft`;
		result.variant = 'error';
	} finally {
		// Reload history data
		recordHistoryGet();
		return result;
	}
};

export { handleCommitDraft };
