const { OPERATION_MODE } = require('../../expression/expression');
const { ERROR } = require('../../pipeline/pipeline');
const { deepCopy, isDocument } = require('../../utils');
const { evaluateExpressionSchema } = require('../expression');
const { setStageSchemaFn } = require('../pipeline');
const {
	newDefaultSchema,
	dotGetInSchema,
	getType,
	getArrayOf,
	dotSetInSchema,
} = require('../utils');

function replaceRoot(context, args, options) {
	const stageKey = options.stage;

	if (!isDocument(args) || !Object.hasOwnProperty.call(args, 'newRoot')) {
		throw new Error(
			ERROR.INVALID_STAGE_ARGUMENT(
				stageKey,
				'a document with the newRoot property',
				args
			)
		);
	}

	const expression = evaluateExpressionSchema(
		{
			...context,
			operationMode: OPERATION_MODE.AGGREGATE,
		},
		args.newRoot
	);

	if (getType(expression) !== 'object') {
		if (stageKey === '$replaceWith') {
			return newDefaultSchema('object');
		}

		throw new Error(
			ERROR.INVALID_STAGE_ARGUMENT(
				stageKey,
				'newRoot expression to resolve to a document',
				expression
			)
		);
	}

	return expression;
}

setStageSchemaFn('$replaceRoot', (context, args, options) =>
	replaceRoot(context, args, { ...(options || {}), stage: '$replaceRoot' })
);

setStageSchemaFn('$replaceWith', (context, args, options) =>
	replaceRoot(
		context,
		{ newRoot: args },
		{ ...(options || {}), stage: '$replaceWith' }
	)
);
