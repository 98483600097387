import { Button, Box, Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/styles';
import { memo } from 'react';

const StatusPaper = styled(Paper)(
	({ background_colour, text_colour, theme }) => ({
		backgroundColor: background_colour && background_colour,
		color: text_colour && text_colour,
		minHeight: '68px',
		height: 'fit-content',
		borderRadius: '10px',
		width: '100%',
		paddingLeft: '17px',
		paddingRight: '17px',
		display: 'flex',
		flexDirection: 'column',

		[theme.breakpoints.up('xs')]: {
			paddingTop: '10px',
			paddingBottom: '10px',
		},
	})
);

const StatusRowContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	width: '100%',
	minHeight: '48px',

	[theme.breakpoints.down('sm')]: {
		flexDirection: 'column',
		paddingTop: '17px',
	},
}));

const BodyInfoContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	width: '100%',

	[theme.breakpoints.down('sm')]: {
		alignItems: 'flex-start',
	},

	[theme.breakpoints.up('xs')]: {
		alignItems: 'center',
	},
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexShrink: 0,
	width: '100%',

	[theme.breakpoints.down('sm')]: {
		margin: '10px 17px 17px 17px',
		width: '100%',
	},

	[theme.breakpoints.up('sm')]: {
		margin: '0px 0px 0px 10px',
		width: 'auto',
	},
}));

const StyledButton = styled(Button)(() => ({
	height: '36px',
	width: '100%',
}));

const TextContainer = styled('div')(() => ({
	overflowWrap: 'break-word',
	whiteSpace: 'normal',
	textOverflow: 'clip',
}));

const IconContainer = styled('div')(() => ({
	width: '40px',
	height: '30px',
	display: 'flex',
	flexShrink: 0,
}));

const StatusRow = memo(
	({
		icon,
		label,
		text,
		onClick,
		buttonText,
		buttonComponent,
		bodyComponent,
		buttonDisabled,
		loading,
		titleComponent,
	}) => {
		return (
			<StatusRowContainer>
				<BodyInfoContainer>
					<IconContainer>{icon && icon}</IconContainer>
					<div style={{ width: '100%' }}>
						{titleComponent && titleComponent}
						{(label || text) && (
							<TextContainer>
								{label && (
									<Typography variant="h6">
										{label}
									</Typography>
								)}
								{text && (
									<Typography variant="body2">
										{text}
									</Typography>
								)}
							</TextContainer>
						)}
						{bodyComponent && bodyComponent}
					</div>
				</BodyInfoContainer>
				{buttonComponent && (
					<ButtonContainer>{buttonComponent}</ButtonContainer>
				)}
				{buttonText &&
					onClick && (
						<ButtonContainer>
							<StyledButton
								variant="contained"
								disableElevation
								onClick={onClick}
								disabled={buttonDisabled || loading}
							>
								{buttonText}
							</StyledButton>
						</ButtonContainer>
					)}
			</StatusRowContainer>
		);
	}
);

const StatusTitle = memo(({ title, ButtonComponent }) => {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
			}}
		>
			<Typography
				variant="h4"
				sx={{
					marginTop: '10px',
					marginBottom: '10px',
				}}
			>
				{title && title}
			</Typography>
			{ButtonComponent && <ButtonComponent />}
		</div>
	);
});

const StatusDisplay = memo(
	({ backgroundColour, children, textColour, elevation }) => {
		return (
			<StatusPaper
				background_colour={backgroundColour}
				text_colour={textColour}
				elevation={elevation && elevation}
			>
				{children && children}
			</StatusPaper>
		);
	}
);

StatusDisplay.propTypes = {
	backgroundColour: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
	textColour: PropTypes.string,
	elevation: PropTypes.bool,
};

StatusTitle.propTypes = {
	title: PropTypes.string,
	ButtonComponent: PropTypes.func,
};

StatusRow.propTypes = {
	icon: PropTypes.element,
	label: PropTypes.string,
	text: PropTypes.string,
	onClick: PropTypes.func,
	buttonText: PropTypes.string,
	buttonComponent: PropTypes.func,
	bodyComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
	titleComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
	buttonDisabled: PropTypes.bool,
	loading: PropTypes.bool,
};

StatusDisplay.displayName = 'StatusDisplay';
StatusTitle.displayName = 'StatusTitle';
StatusRow.displayName = 'StatusRow';

export { StatusRow, StatusDisplay, StatusTitle, StatusPaper };
