import React from 'react';

// import StorageIcon from '@mui/icons-material/Storage';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import RouterIcon from '@mui/icons-material/Router';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PermMediaOutlined from '@mui/icons-material/PermMediaOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
// import MyLocationIcon from '@mui/icons-material/MyLocation';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import ListAltIcon from '@mui/icons-material/ListAlt';

const IoT_options = [
	{
		id: 'Devices',
		description: 'Devices',
		text: 'Devices',
		route: '/Devices',
		icon: <AccountTreeIcon />,
		validSubscriptionsAny: ['IoT'],
	},
	{
		id: 'Files',
		description: 'Files',
		text: 'Files',
		route: '/Files',
		icon: <PermMediaOutlined />,
		validSubscriptionsAny: ['IoT'],
	},
	{
		id: 'Config',
		description: 'Config',
		text: 'Config',
		route: '/Config',
		icon: <SettingsIcon />,
		validSubscriptionsAny: ['IoT'],
	},
	{
		id: 'Container Tasks',
		description: 'Container Tasks',
		text: 'Container Tasks',
		route: '/ContainerTaskList',
		icon: <ListAltIcon />,
		validSubscriptionsAny: ['IoT'],
	},
	{
		id: 'Fleet Admin',
		description: 'Fleet Admin',
		text: 'Fleet Admin',
		route: '/FleetAdmin',
		icon: <GroupWorkIcon />,
		validSubscriptionsAny: ['IoT'],
	},
];

export default IoT_options;
