import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

import { useWatch } from 'react-hook-form';
import { InputController, resolveVisOptionsSchema } from './InputController';
import { VisOptionsFormSectionWrapper } from './VisOptionsFormSectionWrapper';

const VIS_OPTIONS = 'vis_options';
export const ORDINAL_AXIS = 'ordinalAxis';

const OrdinalAxisFormSection = ({ sectionKey, fields, path }) => {
	return (
		<VisOptionsFormSectionWrapper title={sectionKey}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: '10px',
				}}
			>
				{Object.keys(fields).map(fieldKey => (
					<InputController
						key={fieldKey}
						label={fieldKey}
						name={`${path}.${fieldKey}`}
						propDetails={{
							type: fields[fieldKey].type,
							oneOf: fields[fieldKey].oneOf,
						}}
					/>
				))}
			</Box>
		</VisOptionsFormSectionWrapper>
	);
};

export const OrdinalAxisForm = () => {
	const type = useWatch({ name: 'type' });
	const ordinalAxisDesc = useMemo(
		() => resolveVisOptionsSchema(type).ordinalAxis.fields,
		[type]
	);
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '10px',
			}}
		>
			{Object.keys(ordinalAxisDesc).map(sectionKey => (
				<OrdinalAxisFormSection
					key={sectionKey}
					sectionKey={sectionKey}
					path={`${VIS_OPTIONS}.${ORDINAL_AXIS}.${sectionKey}`}
					fields={ordinalAxisDesc[sectionKey].fields}
					type={type}
				/>
			))}
		</Box>
	);
};

OrdinalAxisFormSection.propTypes = {
	sectionKey: PropTypes.string.isRequired,
	fields: PropTypes.object.isRequired,
	path: PropTypes.string.isRequired,
};
