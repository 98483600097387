const yup = require('yup');

const workflowTemplateGetListSchema = yup.array().of(
	yup.object({
		uuid: yup
			.string()
			.uuid()
			.required(),
		status: yup.string().required(),
		name: yup.string().required(),
		description: yup.string().nullable(),
		group: yup.string().nullable(),
		current_version: yup.number().required(),
	})
);

const workflowTemplateHistoryGetSchema = yup.object({
	uuid: yup
		.string()
		.uuid()
		.required(),
	status: yup.string().required(),
	organisation: yup
		.string()
		.uuid()
		.required(),
	name: yup.string().nullable(),
	description: yup.string().nullable(),
	group: yup.string().nullable(),
	versions: yup
		.array()
		.nullable()
		.of(
			yup.object().shape({
				publish_timestamp: yup.number().required(),
				publish_by: yup.object().shape({
					uuid: yup
						.string()
						.uuid()
						.required(),
					avatar: yup.string().nullable(),
					email: yup.string().required(),
					first_name: yup.string().required(),
					initials: yup.string().required(),
					last_name: yup.string().required(),
					user_name: yup.string().required(),
				}),
			})
		),
});

const workflowTemplateGetSchema = yup.object({
	uuid: yup
		.string()
		.uuid()
		.required(),
	status: yup.string().required(),
	organisation: yup
		.string()
		.uuid()
		.required(),
	name: yup.string().required(),
	description: yup.string().nullable(),
	group: yup.string().nullable(),
	template: yup
		.object()
		.required()
		.shape({
			publish_timestamp: yup.number().required(),
			publish_by: yup
				.string()
				.uuid()
				.required(),
			pages: yup
				.array()
				.required()
				.of(
					yup.object().shape({
						uuid: yup
							.string()
							.uuid()
							.required(),
						name: yup.string().required(),
						description: yup.string().nullable(),
						isRoot: yup.boolean().required(),
						children: yup
							.array()
							.required()
							.of(
								yup.object().shape({
									uuid: yup
										.string()
										.uuid()
										.required(),
									name: yup.string().nullable(),
									description: yup.string().nullable(),
									type: yup.string().required(),
									validation: yup.array().nullable(),
								})
							),
					})
				),
			flow: yup
				.array()
				.required()
				.of(
					yup.object().shape({
						state: yup.string().required(),
						next: yup
							.array()
							.nullable()
							.of(
								yup.object().shape({
									state: yup.string().required(),
									text: yup.string().required(),

									default: yup.boolean().required(),
									confirm: yup.boolean().required(),
								})
							),
						viewers: yup
							.object()
							.required()
							.shape({
								users: yup
									.array()
									.nullable()
									.of(
										yup
											.string()
											.uuid()
											.nullable()
									),
								editors: yup
									.array()
									.nullable()
									.of(
										yup
											.string()
											.uuid()
											.nullable()
									),
								groups: yup
									.array()
									.nullable()
									.of(
										yup
											.string()
											.uuid()
											.nullable()
									),
							}),
					})
				),
			dataSources: yup
				.array()
				.nullable()
				.of(
					yup.object().shape({
						type: yup.string().required(),
						uuid: yup.string().required(),
						source_uuid: yup.string().required(),
						query: yup.string().required(),
						variables: yup.object().shape({}),
					})
				),
		}),
});

const workflowTemplateDraftGetSchema = yup.object({
	uuid: yup
		.string()
		.uuid()
		.required(),
	status: yup.string().required(),
	organisation: yup
		.string()
		.uuid()
		.required(),
	name: yup.string().required(),
	description: yup.string().nullable(),
	group: yup.string().nullable(),
	draft: yup
		.object()
		.required()
		.shape({
			locked_to: yup
				.string()
				.uuid()
				.required(),
			pages: yup
				.array()
				.nullable()
				.of(
					yup.object().shape({
						uuid: yup
							.string()
							.uuid()
							.required(),
						name: yup.string().nullable(),
						description: yup.string().nullable(),
						isRoot: yup.boolean().required(),
						children: yup
							.array()
							.required()
							.of(
								yup.object().shape({
									uuid: yup
										.string()
										.uuid()
										.required(),
									name: yup.string().nullable(),
									description: yup.string().nullable(),
									type: yup.string().required(),
									validation: yup.array().nullable(),
								})
							),
					})
				),
			flow: yup
				.array()
				.nullable()
				.of(
					yup.object().shape({
						state: yup.string().required(),
						next: yup
							.array()
							.nullable()
							.of(
								yup.object().shape({
									state: yup.string().required(),
									text: yup.string().required(),
									default: yup.boolean().required(),
									confirm: yup.boolean().required(),
								})
							),
						viewers: yup
							.object()
							.required()
							.shape({
								users: yup
									.array()
									.nullable()
									.of(
										yup
											.string()
											.uuid()
											.nullable()
									),
								editors: yup
									.array()
									.nullable()
									.of(
										yup
											.string()
											.uuid()
											.nullable()
									),
								groups: yup
									.array()
									.nullable()
									.of(
										yup
											.string()
											.uuid()
											.nullable()
									),
							}),
					})
				),
		}),
});

const workflowTemplatePostSchema = yup.object({
	name: yup.string().required(),
	description: yup.string().nullable(),
	group: yup
		.string()
		.uuid()
		.required(),
});

const workflowTemplatePostResponseSchema = yup.object({
	uuid: yup
		.string()
		.uuid()
		.required(),
	name: yup.string().required(),
});

// Fields are not required so as partial updates can be made
const workflowTemplatePutSchema = yup
	.object()
	.noUnknown()
	.strict()
	.shape({
		current_version: yup.number().min(0),
		description: yup.string(),
		group: yup.string().uuid(),
		name: yup.string(),
	});

module.exports = {
	workflowTemplateGetListSchema,
	workflowTemplateGetSchema,
	workflowTemplateDraftGetSchema,
	workflowTemplateHistoryGetSchema,
	workflowTemplatePostSchema,
	workflowTemplatePostResponseSchema,
	workflowTemplatePutSchema,
};
