import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { ScreensContext } from '../../ScreensContext';

// Main app context for infrequent changes like user
const ScreensConfigContext = createContext(null);

const useScreensConfigContext = () => {
	const context = useContext(ScreensConfigContext);
	if (context === undefined) {
		throw new Error(
			'useScreensConfigContext was used outside of its Provider'
		);
	}
	return context;
};

// clean state object
const initAddScreenParams = {
	addScreenReference: null,
	copyScreenReference: null,
	newScreenName: null,
	type: null,
	position: null,
	backgroundColor: '',
};

// clean state object
const initEditScreenParams = {
	screenUuid: null,
	newScreenName: null,
	position: null,
	backgroundColor: null,
	managedBy: null,
	ownerType: null,
	existingPosition: null,
	existingBackgroundColor: null,
	existingScreenName: null,
};

function ScreensConfigContextProvider({
	children,
	screenCount,
	addScreenOptions,
	screenToEditOptions,
	copyScreenOptions,
}) {
	const [currentTab, setCurrentTab] = useState(0);
	// const [currentScreenCount, setCurrentScreenCount] = useState(0)

	// edit screen state
	const [editScreenStepsCount, setEditScreenActiveCount] = useState(null);
	const [editScreenActiveStep, setEditScreenActiveStep] = useState(0);
	const [editNextStepDisabled, setEditNextStepDisabled] = useState(null);
	const [editScreenParams, setEditScreenParams] = useState(
		initEditScreenParams
	);

	// add screen state
	const [addScreenStepsCount, setAddScreenStepsCount] = useState(null);
	const [addScreenActiveStep, setAddScreenActiveStep] = useState(0);
	const [addNextStepDisabled, setAddNextStepDisabled] = useState(null);
	const [addScreenParams, setAddScreenParams] = useState(initAddScreenParams);

	useEffect(() => {
		const newParams = structuredClone(initAddScreenParams);
		newParams.position = screenCount + 1;
		// newParams.newScreenName = 'New screen';
		setAddScreenParams(newParams);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const addOptions = useMemo(() => addScreenOptions, [addScreenOptions]);
	const editOptions = useMemo(() => screenToEditOptions, [
		screenToEditOptions,
	]);

	// const groupEditOnly = useMemo(() => userPermissions)

	// useEffect(
	// 	() => {
	// 		console.log({ editScreenParams });
	// 	},
	// 	[editScreenParams]
	// );

	// useEffect(
	// 	() => {
	// 		console.log({ addScreenParams });
	// 	},
	// 	[addScreenParams]
	// );

	return (
		<ScreensConfigContext.Provider
			value={{
				currentTab,
				setCurrentTab,
				//
				addScreenActiveStep,
				setAddScreenActiveStep,
				addScreenStepsCount,
				setAddScreenStepsCount,
				addScreenParams,
				setAddScreenParams,
				addNextStepDisabled,
				setAddNextStepDisabled,
				editScreenActiveStep,
				setEditScreenActiveStep,
				editScreenStepsCount,
				setEditScreenActiveCount,
				editNextStepDisabled,
				setEditNextStepDisabled,
				editScreenParams,
				setEditScreenParams,
				addOptions,
				editOptions,
				screenCount,
				copyScreenOptions,
			}}
		>
			{children}
		</ScreensConfigContext.Provider>
	);
}

export { ScreensConfigContextProvider, useScreensConfigContext };
