import React from 'react';

import ModeCommentIcon from '@mui/icons-material/ModeComment';
import { Tooltip, Typography } from '@mui/material';

import { BtFormContainer } from '../../../../../components/generic/forms';
import BtMessage from '../../../../../components/generic/BtMessage';
import { useWorkflowSessionContext } from '../../contexts/WorkflowSessionContext';
import WorkflowSummaryActions from './WorkflowSummaryActions';
import WorkflowSummaryNode from './WorkflowSummaryNode';
import WorkflowHeaderActionButton from '../core/WorkflowHeaderActionButton';
import BtBadge from '../../../../../components/generic/BtBadge';

const Final = () => {
	const { workflowSubmission } = useWorkflowSessionContext();

	const status = workflowSubmission?.status?.status;

	return (
		<BtMessage
			message={
				<div
					style={{
						alignItems: 'flex-start',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Typography variant="h5">
						This workflow cannot be edited.
					</Typography>
					<Typography>{`Current status: ${status}`}</Typography>
				</div>
			}
			style={{ marginBottom: '1em' }}
		/>
	);
};

export default function WorkflowSummary() {
	const {
		commentAvailability,
		isFinal,
		setShowWorkflowComments,
		tree,
		workflowComments,
	} = useWorkflowSessionContext();

	return (
		<BtFormContainer
			header={<WorkflowSummaryActions />}
			maxWidth="sm"
			title={
				<div
					style={{
						alignItems: 'flex-start',
						display: 'flex',
						justifyContent: 'space-between',
						width: '100%',
					}}
				>
					<Typography variant="h3">Summary</Typography>
					{commentAvailability.workflow !== 'hidden' && (
						<Tooltip
							disableInteractive
							style={{ marginTop: '0.45em' }}
							title="Workflow Comments"
						>
							<WorkflowHeaderActionButton
								focusRipple
								onClick={() => setShowWorkflowComments(true)}
							>
								<ModeCommentIcon />
								<BtBadge
									count={workflowComments?.length}
									style={{ top: -5, right: -5 }}
								/>
							</WorkflowHeaderActionButton>
						</Tooltip>
					)}
				</div>
			}
			subtitle="Below is an overall summary of the workflow."
		>
			{isFinal && <Final />}
			<WorkflowSummaryNode isRoot page={tree} />
		</BtFormContainer>
	);
}
