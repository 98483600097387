import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import {
	Box,
	styled,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import Color from 'color';
import { useHistory } from 'react-router-dom';
import { v4 as generateUuid } from 'uuid';

import {
	ASC,
	DEFAULT_PAGE_SIZES,
	DESC,
	LOOSE,
	STANDARD,
	STRICT,
} from './table-utils/constants';
import { BOOLEAN, DATE, NUMBER, STRING } from '../../utils/commonDataTypes';
import BtNoItems from './BtNoItems';
import BtFilters from './filters/BtFilters';
import BtPagination from './BtPagination';
import TableSearch from './table-utils/components/TableSearch';
import { useCellFormatter } from './table-utils/hooks/useCellFormatter';
import { useTableData } from './table-utils/hooks/useTableData';
import { useTableLayout } from './table-utils';
import { useTheme } from '@mui/styles';
import { XS, SM, MD, LG, XL } from '../../style/muiTheme';

const Container = styled('div')(() => ({
	maxHeight: '100%',
	position: 'relative',
	width: '100%',
}));

const OrderButton = styled('button')(() => ({
	background: 'none',
	border: 'none',
	height: '100%',
	margin: 0,
	padding: 0,
	width: '100%',

	'& > span': {
		alignItems: 'center',
		display: 'flex',
		userSelect: 'none',
	},

	'& .order-wrapper': {
		height: 16,
		marginLeft: 4,
		opacity: 0,
		width: 16,
	},

	'&:focus-visible .order-wrapper': {
		opacity: 0.5,
	},

	'&:hover .order-wrapper': {
		opacity: 0.5,
	},

	'& .order-wrapper.active': {
		opacity: 1.0,
	},
}));

const StickyContainer = styled('div')(({ color, theme }) => ({
	background: `linear-gradient(180deg, ${color ??
		Color(theme.palette.background.default)
			.alpha(0)
			.toString()} 0%, ${color ??
		theme.palette.background.default} 20%, ${color ??
		theme.palette.background.default} 100%)`,
	bottom: 0,
	padding: '0.33em 0',
	position: 'sticky',
	width: '100%',
}));

const orderIconStyle = { height: 16, width: 16 };

export const BtSelectionTable = memo(
	({
		columns,
		containerResponsive,
		data,
		dataOffset,
		debounceSearchInput,
		defaultTimeRange,
		disabled,
		disableRowPredicate,
		disableSearchColumnOptions,
		disableSearchExactnessOptions,
		enableFiltering,
		enableGeneralSorting,
		enablePagination,
		enableSearching,
		filters,
		Footer,
		getFilterOptions,
		hideHeadings,
		hideSearchColumn,
		hideSearchExactness,
		idKey,
		initialFilters,
		initialPageSize,
		initialSearchColumn,
		initialSearchExactness,
		initialSort,
		onChangeFilters,
		onChangeTimeRange,
		onClick,
		onOffsetUpdate,
		onPageSizeUpdate,
		onSearchColumnChange,
		onSearchExactnessChange,
		onSearchTermChange,
		onSort,
		pageData,
		pageSize,
		pageSizeOptions,
		paginationBackgroundColor,
		paginationFunc,
		recordCount,
		searchColumn,
		searchExactness,
		searchFunc,
		searchInputDebounceTimeMs,
		searchTerm,
		size,
		sort,
		sortingFunc,
		stickyHeader,
		subject,
		tableContainerStyle,
		title,
	}) => {
		const ref = useRef();
		const bodyRef = useRef();

		const formatCell = useCellFormatter();
		const history = useHistory();

		const THEME = useTheme();

		// Ensure all columns have a field name
		const _columns = useMemo(
			() =>
				(columns || []).map(
					column =>
						!column.field
							? {
									...column,
									field: generateUuid(),
									transient: true,
							  }
							: column
				),
			[columns]
		);

		const searchableColumns = useMemo(
			() =>
				(_columns || []).filter(
					({ searchable, text, transient }) =>
						searchable !== false && text && !transient
				),
			[_columns]
		);

		const [_searchColumn, setSearchColumn] = useState(
			searchColumn ?? initialSearchColumn
		);
		const [_searchExactness, setSearchExactness] = useState(
			searchExactness ?? initialSearchExactness ?? STANDARD
		);
		const [searchOffset, setSearchOffset] = useState();
		const [_searchTerm, setSearchTerm] = useState(searchTerm ?? '');

		const { redactedColumns, showNumberOnly } = useTableLayout({
			columns: _columns,
			containerResponsive,
			ref,
		});

		const [_offset, setOffset] = useState(dataOffset ?? 0);
		const [_pageSize, setPageSize] = useState(
			pageSize ||
				initialPageSize ||
				((pageSizeOptions || []).length > 0
					? pageSizeOptions?.[0]
					: null) ||
				DEFAULT_PAGE_SIZES[0]
		);
		const [_sort, setSort] = useState(sort ?? initialSort);

		const {
			pageData: _pageData,
			recordCount: postSearchRecordCount,
		} = useTableData({
			columns: _columns,
			data,
			enablePagination,
			idKey,
			offset: dataOffset ?? searchOffset ?? _offset ?? 0,
			pageData,
			pageSize: pageSize ?? _pageSize,
			paginationFunc,
			searchableColumns,
			searchColumn: searchColumn ?? _searchColumn,
			searchExactness: _searchExactness,
			searchFunc,
			searchTerm: searchTerm ?? _searchTerm,
			sort: sort ?? _sort,
			sortingFunc,
			recordCount,
		});

		const _recordCount = useMemo(
			() => recordCount ?? postSearchRecordCount,
			[recordCount, postSearchRecordCount]
		);

		const columnisedData = useMemo(
			() => {
				const rowsData = pageData ?? _pageData ?? [];

				if (!redactedColumns || !rowsData) return [];

				const headings = redactedColumns.map(({ field }) => field);

				return rowsData.reduce(
					(accumulator, bodyRow) => [
						...accumulator,
						headings.map(field => bodyRow[field]),
					],
					[]
				);
			},
			[pageData, _pageData, redactedColumns]
		);

		const onRowClick = useCallback(
			row => {
				const { id, route } = row;

				if (onClick) {
					return onClick(id ?? row);
				} else if (route) {
					history.push(route);
				}
			},
			[history, onClick]
		);

		const handleOffsetUpdate = useCallback(
			newOffset => {
				if (searchTerm || _searchTerm) {
					setSearchOffset(newOffset);
				} else {
					setOffset(newOffset);
				}

				onOffsetUpdate?.(newOffset);
			},
			[onOffsetUpdate, searchTerm, _searchTerm]
		);

		const handleSort = useCallback(
			newSort => {
				setSort(newSort);

				onSort?.(newSort);
			},
			[onSort]
		);

		const handlePageSizeUpdate = useCallback(
			newSize => {
				setPageSize(newSize);

				onPageSizeUpdate?.(newSize);
			},
			[onPageSizeUpdate]
		);

		const handleHeadingClick = useCallback(
			item => {
				const { field: newOrderBy } = item;

				if (!newOrderBy) return;

				const { order, orderBy: currentOrderBy } = sort ?? _sort ?? {};

				const sameField = currentOrderBy === newOrderBy;

				if (sameField && order === DESC) {
					handleSort(null);

					return;
				}

				handleSort({
					order: sameField && order === ASC ? DESC : ASC,
					orderBy: newOrderBy,
				});
			},
			[handleSort, sort, _sort]
		);

		const resetSearchOffset = useCallback(nullify => {
			if (nullify) {
				setSearchOffset(null);

				return;
			}

			setSearchOffset(0);
		}, []);

		const handleSearchExactnessChange = useCallback(
			newExactness => {
				resetSearchOffset();

				setSearchExactness(newExactness);
				onSearchExactnessChange?.(newExactness);
			},
			[onSearchExactnessChange, resetSearchOffset]
		);

		const handleSearchColumnChange = useCallback(
			newSearchColumn => {
				resetSearchOffset();

				setSearchColumn(newSearchColumn);
				onSearchColumnChange?.(newSearchColumn);
			},
			[onSearchColumnChange, resetSearchOffset]
		);

		const handleSearchTermChange = useCallback(
			newTerm => {
				resetSearchOffset(!newTerm);

				setSearchTerm(newTerm);
				onSearchTermChange?.(newTerm);
			},
			[onSearchTermChange, resetSearchOffset]
		);

		if (showNumberOnly) {
			return (
				<div
					style={{
						alignItems: 'center',
						display: 'flex',
						flexDirection: 'column',
						height: '100%',
						justifyContent: 'center',
					}}
				>
					<Typography variant="h5">{(data || []).length}</Typography>
				</div>
			);
		}

		return (
			<Container
				sx={{
					height: '100%',
				}}
				ref={ref}
			>
				<TableContainer
					component={Box}
					sx={{
						...tableContainerStyle,
						maxHeight: 'calc(100% - 64px)',
					}}
				>
					<Table
						sx={{ height: '100%' }}
						size={size}
						stickyHeader={stickyHeader}
					>
						<TableHead>
							{(title || enableFiltering || enableSearching) && (
								<TableRow className="table_title">
									<TableCell
										align="left"
										colSpan={'100%'}
										id={title}
										style={{
											backgroundColor: paginationBackgroundColor
												? paginationBackgroundColor
												: THEME.palette.background
														.default,
											padding: 0,
										}}
									>
										{typeof title === 'string' ? (
											<Typography
												sx={{
													fontWeight: 'bold',
													padding: '4px 0',
												}}
												variant="h5"
											>
												{title}
											</Typography>
										) : (
											title
										)}
										{enableFiltering && (
											<BtFilters
												defaultTimeRange={
													defaultTimeRange
												}
												filters={filters}
												getFilterOptions={
													getFilterOptions
												}
												initialFilters={initialFilters}
												onChangeFilters={
													onChangeFilters
												}
												onChangeTimeRange={
													onChangeTimeRange
												}
											/>
										)}
										{enableSearching && (
											<TableSearch
												debounceInput={
													debounceSearchInput
												}
												debounceTimeMs={
													searchInputDebounceTimeMs
												}
												disableExactnessOptions={
													disableSearchExactnessOptions
												}
												disableSearchColumnOptions={
													disableSearchColumnOptions
												}
												exactness={
													searchExactness ??
													_searchExactness
												}
												hideExactness={
													hideSearchExactness
												}
												hideSearchColumn={
													hideSearchColumn
												}
												onColumnChange={
													handleSearchColumnChange
												}
												onExactnessChange={
													handleSearchExactnessChange
												}
												onTermChange={
													handleSearchTermChange
												}
												searchableFields={
													searchableColumns
												}
												searchColumn={
													searchColumn ??
													_searchColumn
												}
												searchTerm={
													searchTerm ?? _searchTerm
												}
											/>
										)}
									</TableCell>
								</TableRow>
							)}

							{!hideHeadings &&
								redactedColumns &&
								columnisedData?.length > 0 && (
									<TableRow className="table_head">
										{redactedColumns.map((item, index) => {
											const sortable =
												item.field &&
												((enableGeneralSorting &&
													item.sortable !== false) ||
													item.sortable === true);

											const sortActive =
												(sort ?? _sort ?? {})
													.orderBy === item.field;

											const orderBtnTitle = (() => {
												if (!sortable) return '';

												const columnName =
													item.text ?? item.field;

												if (sortActive) {
													if (
														(sort ?? _sort)
															.order === ASC
													) {
														return `Sort the ${columnName} column in descending order`;
													}

													return `Turn off sorting for the ${columnName} column`;
												}

												return `Sort the ${columnName} column in ascending order`;
											})();

											return (
												<TableCell
													id={item.field ?? item.text}
													key={index}
													sx={{
														backgroundColor: paginationBackgroundColor
															? paginationBackgroundColor
															: THEME.palette
																	.background
																	.default,
														marginTop: 5,
													}}
												>
													<OrderButton
														aria-label={
															orderBtnTitle
														}
														disabled={!sortable}
														onClick={() =>
															handleHeadingClick(
																item
															)
														}
														title={orderBtnTitle}
														type="button"
													>
														<span>
															{item.text}
															<div
																className={`order-wrapper${
																	sortActive
																		? ' active'
																		: ''
																}`}
															>
																{sortable && (
																	<>
																		{(
																			sort ??
																			_sort ??
																			{}
																		)
																			.order !==
																			DESC ||
																		!sortActive ? (
																			<ArrowUpward
																				style={
																					orderIconStyle
																				}
																			/>
																		) : (
																			<ArrowDownward
																				style={
																					orderIconStyle
																				}
																			/>
																		)}
																	</>
																)}
															</div>
														</span>
													</OrderButton>
												</TableCell>
											);
										})}
									</TableRow>
								)}
						</TableHead>

						{_pageData &&
							columnisedData?.length > 0 && (
								<TableBody className="table_body" ref={bodyRef}>
									{(pageData ?? _pageData).map(
										(row, index) => {
											const enabled = !(
												disabled ||
												(row &&
													disableRowPredicate?.(
														row || {}
													))
											);

											return (
												<TableRow
													id={row.id}
													key={row[idKey] ?? index}
													disabled={disableRowPredicate?.()}
													onClick={() => {
														if (enabled) {
															onRowClick(row);
														}
													}}
													sx={{
														...((onClick ||
															row.route) &&
															enabled && {
																'&:hover': {
																	backgroundColor:
																		'action.hover',
																},
																cursor:
																	'pointer',
															}),
													}}
												>
													{columnisedData[index].map(
														(item, index) => {
															const Content = (() => {
																// Use the element defined in the columns
																const {
																	CellContent,
																} = redactedColumns[
																	index
																];

																if (
																	CellContent
																) {
																	return CellContent(
																		item,
																		row
																	);
																}

																// Otherwise, get the type and render accordingly
																const type =
																	redactedColumns[
																		index
																	].type ??
																	typeof item;

																return formatCell(
																	type,
																	item
																);
															})();

															return (
																<TableCell
																	id={row.id}
																	key={`${
																		row[
																			idKey
																		]
																	}-${index}`}
																>
																	{Content}
																</TableCell>
															);
														}
													)}
												</TableRow>
											);
										}
									)}
								</TableBody>
							)}

						{Footer && (
							<TableFooter className="table_footer">
								<TableRow>
									<TableCell
										align="left"
										colSpan={'100%'}
										sx={{ paddingBottom: '15px' }}
									>
										{Footer}
									</TableCell>
								</TableRow>
							</TableFooter>
						)}
					</Table>
				</TableContainer>

				{(columnisedData || []).length === 0 && (
					<BtNoItems subject={subject} />
				)}

				{enablePagination && (
					<StickyContainer color={paginationBackgroundColor}>
						<BtPagination
							dataOffset={dataOffset ?? searchOffset ?? _offset}
							onOffsetUpdate={handleOffsetUpdate}
							onPageSizeUpdate={handlePageSizeUpdate}
							pageSize={pageSize ?? _pageSize}
							pageSizeOptions={
								pageSizeOptions ?? DEFAULT_PAGE_SIZES
							}
							recordCount={_recordCount}
						/>
					</StickyContainer>
				)}
			</Container>
		);
	}
);

BtSelectionTable.displayName = 'BtSelectionTable';

BtSelectionTable.defaultProps = {
	debounceSearchInput: true,
	idKey: 'uuid',
	subject: 'item',
};

const exactnessPropType = PropTypes.oneOf([LOOSE, STANDARD, STRICT]);

const filtersPropType = PropTypes.arrayOf(PropTypes.object);

const sortPropType = PropTypes.shape({
	order: PropTypes.oneOf([ASC, DESC]).isRequired,
	orderBy: PropTypes.string.isRequired,
});

BtSelectionTable.propTypes = {
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			CellContent: PropTypes.func,
			field: PropTypes.string,
			minBreakpoint: PropTypes.oneOf([XS, SM, MD, LG, XL]),
			ordinalValues: PropTypes.arrayOf(PropTypes.any),
			sortable: PropTypes.bool,
			sortingComparator: PropTypes.func, // (a, b, order) => { return result; }
			text: PropTypes.string,
			type: PropTypes.oneOf([BOOLEAN, DATE, NUMBER, STRING]),
		})
	),
	containerResponsive: PropTypes.bool,
	data: PropTypes.arrayOf(PropTypes.object),
	dataOffset: PropTypes.number,
	debounceSearchInput: PropTypes.bool,
	defaultTimeRange: PropTypes.object,
	disabled: PropTypes.bool,
	disableRowPredicate: PropTypes.func,
	disableSearchColumnOptions: PropTypes.bool,
	disableSearchExactnessOptions: PropTypes.bool,
	enableFiltering: PropTypes.bool,
	enableGeneralSorting: PropTypes.bool,
	enablePagination: PropTypes.bool,
	enableSearching: PropTypes.bool,
	filters: filtersPropType,
	Footer: PropTypes.node,
	getFilterOptions: PropTypes.func,
	hideHeadings: PropTypes.bool,
	hideSearchColumn: PropTypes.bool,
	hideSearchExactness: PropTypes.bool,
	idKey: PropTypes.string,
	initialFilters: filtersPropType,
	initialPageSize: PropTypes.number,
	initialSearchColumn: PropTypes.string,
	initialSearchExactness: exactnessPropType,
	initialSort: sortPropType,
	onChangeFilters: PropTypes.func,
	onChangeTimeRange: PropTypes.func,
	onClick: PropTypes.func,
	onOffsetUpdate: PropTypes.func,
	onPageSizeUpdate: PropTypes.func,
	onSearchColumnChange: PropTypes.func,
	onSearchExactnessChange: PropTypes.func,
	onSearchTermChange: PropTypes.func,
	onSort: PropTypes.func,
	pageData: PropTypes.arrayOf(PropTypes.object),
	pageSize: PropTypes.number,
	pageSizeOptions: PropTypes.arrayOf(PropTypes.number.isRequired),
	paginationBackgroundColor: PropTypes.string,
	paginationFunc: PropTypes.func,
	recordCount: PropTypes.number,
	searchColumn: PropTypes.string,
	searchExactness: exactnessPropType,
	searchFunc: PropTypes.func,
	searchInputDebounceTimeMs: PropTypes.number,
	searchTerm: PropTypes.string,
	size: PropTypes.string,
	sort: sortPropType,
	sortingFunc: PropTypes.func,
	stickyHeader: PropTypes.bool,
	subject: PropTypes.string,
	tableContainerStyle: PropTypes.object,
	title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default BtSelectionTable;

/*********************************
        Original version 
        Still in use
**********************************/
export const SelectionTableContainer = props => <TableContainer {...props} />;
export const SelectionTable = props => <Table {...props} />;
export const SelectionTableHead = props => (
	<TableHead size={props.table_size} {...props} />
);
export const SelectionTableHeadRow = props => <TableRow {...props} />;
export const SelectionTableBody = props => <TableBody {...props} />;
export const SelectionTableRow = props => (
	<TableRow
		{...props}
		sx={{
			'&:hover': { backgroundColor: 'action.hover' },
			cursor: 'pointer',
		}}
	/>
);
export const SelectionTableCell = props => <TableCell {...props} />;

SelectionTableHead.propTypes = {
	table_size: PropTypes.any,
};
