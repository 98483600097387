import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useNavContext } from '../../context/ContextManager';

// MUI COMPONENTS
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
// import { Typography } from "@mui/material";

// MUI ICONS
// import Dashboard from '@mui/icons-material/Dashboard';

// CUSTOM COMPONENTS
import {
	SelectionTable,
	SelectionTableContainer,
	SelectionTableHead,
	SelectionTableHeadRow,
	SelectionTableBody,
	SelectionTableRow,
	SelectionTableCell,
} from '../../components/generic/BtSelectionTable';

// Data for landing page selection table and contextual navigation
import IoT_options from './IoTOptions';

import IoT_nav_item from './IoTNavItem';
import useNavRedactor from '../../hooks/useNavRedactor';

export default function IoT() {
	const history = useHistory();
	// navContext contextual navigation items
	const { setContextualNav } = useNavContext();
	// navContext breadcrumbs
	const { setBreadcrumbs } = useNavContext();
	const redactNav = useNavRedactor();

	const redactedIotOptions = useMemo(() => redactNav(IoT_options), [
		redactNav,
	]);

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'IoT', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([...IoT_nav_item, ...redactedIotOptions]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav, redactedIotOptions]
	);

	const onClickUtil = route => () => {
		history.push(route);
	};

	return (
		<>
			<Container maxWidth="lg">
				<SelectionTableContainer component={Box}>
					<SelectionTable
						sx={{ minWidth: 650 }}
						aria-label="simple table"
					>
						<SelectionTableHead>
							<SelectionTableHeadRow>
								<SelectionTableCell />
								<SelectionTableCell>Name</SelectionTableCell>
								<SelectionTableCell>
									Description
								</SelectionTableCell>
							</SelectionTableHeadRow>
						</SelectionTableHead>
						<SelectionTableBody>
							{redactedIotOptions.map((util, index) => (
								<SelectionTableRow
									onClick={onClickUtil(util.route)}
									key={index}
								>
									<SelectionTableCell>
										{util.icon}
									</SelectionTableCell>
									<SelectionTableCell>
										{util.text}
									</SelectionTableCell>
									<SelectionTableCell>
										{util.description}
									</SelectionTableCell>
								</SelectionTableRow>
							))}
						</SelectionTableBody>
					</SelectionTable>
				</SelectionTableContainer>
			</Container>
		</>
	);
}
