import { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { componentTypeExists } from '../config/workflowComponents';
import { GROUP, NON_VALIDATABLE, PAGE, SECTION } from '../utils/constants';
import useMissingComponentsError from './useMissingComponentsError';
import useWorkflowDefaults from './useWorkflowDefaults';
import { useWorkflowSessionContext } from '../../contexts/WorkflowSessionContext';
import useWorkflowValidation from './useWorkflowValidation';

export const usePageParser = page => {
	const { workflowSubmission } = useWorkflowSessionContext();
	const workflowState = workflowSubmission?.state;

	const refComponents = useRef({ renderable: [], validatable: [] });

	const missingError = useMissingComponentsError();

	const [components, validatable] = useMemo(
		() => {
			let missingComponentsTypes = [];
			refComponents.current = { renderable: [], validatable: [] };

			const filterSupported = components => {
				let valid = [];

				(components || []).forEach(component => {
					const { type } = component;

					if (!componentTypeExists(type)) {
						if (
							!missingComponentsTypes.includes(type) &&
							type !== GROUP
						) {
							missingComponentsTypes = [
								...missingComponentsTypes,
								type,
							];
						}
					} else {
						valid = [...valid, component];
					}
				});

				return valid;
			};

			const getComponents = (child, isRoot) => {
				const { open, openConditions, type } = child;

				const addRenderableChild = () => {
					const {
						primary,
						primaryAnswers,
						primaryLock,
						statusAttributes,
					} = child;

					if (statusAttributes) {
						Object.keys(statusAttributes).forEach(key => {
							if (statusAttributes[key].includes(workflowState)) {
								child[key] = true;
							}
						});
					}

					if (
						primary &&
						primaryLock &&
						page.primaryIndex !== -1 &&
						(page.primaryIndex || 0) < (primaryAnswers || []).length
					) {
						child.readOnly = true;
					}

					refComponents.current.renderable = [
						...refComponents.current.renderable,
						child,
					];
				};

				if (type === SECTION) {
					const supportedChildren = filterSupported(child.children);

					if (!openConditions || open) {
						return _.flatMapDeep(supportedChildren, children =>
							getComponents(children, false)
						);
					}

					return [];
				}

				if (type === PAGE) {
					if (isRoot) {
						addRenderableChild();
					}

					return [];
				}

				if (child.groupUuid) {
					const supportedChildren = filterSupported(child.children);

					refComponents.current.renderable = [
						...refComponents.current.renderable,
						{ ...child, children: supportedChildren },
					];

					const validatableChildren = supportedChildren.filter(
						supportedChild =>
							!NON_VALIDATABLE.includes(supportedChild.type)
					);

					return validatableChildren;
				}

				const supportedChild = filterSupported([child]);

				if (supportedChild.length > 0) {
					addRenderableChild();
				}

				return supportedChild;
			};

			if (page.children) {
				refComponents.current.validatable = _.flatMapDeep(
					page.children,
					child => getComponents(child, true)
				);
			}

			if (missingComponentsTypes.length > 0) {
				missingError(missingComponentsTypes);
			}

			return [
				[...refComponents.current.renderable],
				[...refComponents.current.validatable],
			];
		},
		[missingError, page, workflowState]
	);

	const defaultValues = useWorkflowDefaults(validatable);

	const validationSchema = useWorkflowValidation(validatable);

	const items = useMemo(
		() => ({
			components,
			defaultValues,
			validationSchema,
		}),
		[components, defaultValues, validationSchema]
	);

	return items;
};

usePageParser.PropTypes = {
	page: PropTypes.object,
};

export default usePageParser;
