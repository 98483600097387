import React, { useCallback, useEffect, useMemo, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import BtQueryDialog from '../../../../../components/generic/query-builder/BtQueryDialog';
import BtQueryBuilderVariableProvider from '../../../../../components/generic/query-builder/context/BtQueryBuilderVariableProvider';
import { BtQueryBuilderDataProvider } from '../../../../../components/generic/query-builder/context';
import BtDialog from '../../../../../components/generic/BtDialog';
import {
	Box,
	Button,
	CircularProgress,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
} from '@mui/material';
import BtQueryBuilder from '../../../../../components/generic/query-builder/BtQueryBuilder';
import ActionButtonContainer from '../../../../../components/generic/ActionButtonContainer';
import { Done, Save } from '@mui/icons-material';
import { Close } from 'mdi-material-ui';
import { addUpdatedTransformToDataSources } from './utils/utils';
import { useUpdateCollectionDraft } from './utils/useUpdateCollectionDraft';
import * as yup from 'yup';
import {
	BtForm,
	BtFormActionButtons,
	BtFormActionsContainer,
	BtFormChangesBlocker,
	BtFormContent,
	BtFormLateralContainer,
	BtFormSelect,
	BtFormTextField,
} from '../../../../../components/generic/forms';
import { isEqual, result } from 'lodash';
import v4 from 'uuid/v4';

const defaultQuery = [{ $match: {} }];

const extraOptions = [
	{
		key: 'allowResourceGroupChange',
		defaultValue: false,
		label: 'allow changing resource group',
	},
	{
		key: 'allowResourceChange',
		defaultValue: false,
		label: 'allow changing resource',
	},
];

const initDefaultValues = values => {
	return {
		name: values.name,
		description: values.description,
	};
};

export default function DataTransformDialog(props) {
	const {
		open,
		onClose,
		transform,
		onTransformEdit,
		options,
		addOptions,
		dataViewUuid,
		label,
		//
		transforms,
		dataSource,
		dataSources,
		handleSourceUpdate,
		//
		variables,
		disabled,
		loading,
		...rest
	} = props;
	const updateCollection = useUpdateCollectionDraft();
	const [queryValue, setQueryValue] = useState(null);
	const [formValues, setFormValues] = useState(initDefaultValues(transform));
	const [formIsValid, setFormIsValid] = useState(false);
	const [formIsDirty, setFormIsDirty] = useState(false);
	const [sending, setSending] = useState(false);

	const queryIsDirty = useMemo(
		() => {
			const prevValues = {};
			prevValues.pipeline = transform.pipeline;
			prevValues.configSchema = transform?.configSchema ?? {};

			const nextValues = {};
			nextValues.pipeline = queryValue?.queryStr ?? '';
			nextValues.configSchema = queryValue?.configSchema ?? {};

			return !isEqual(prevValues, nextValues);
		},
		[queryValue, transform]
	);

	const existingNames = useMemo(
		() =>
			transforms
				?.map(({ name }) => name)
				.filter(name => name !== transform.name),
		[transform, transforms]
	);

	const schema = useMemo(
		() =>
			yup.object({
				name: yup
					.string()
					.min(3)
					.max(50)
					.notOneOf(
						existingNames || [],
						'A Data Source with this name already exists'
					)
					.required()
					.label('Transform Name'),
				description: yup
					.string()
					.max(300)
					.label('Transform Description'),
			}),
		[existingNames]
	);

	const defaultValues = useMemo(() => initDefaultValues(transform), [
		transform,
	]);

	const handleFormStateChange = useCallback(
		(state, values) => {
			setFormIsValid(state.isValid);
			setFormIsDirty(state.isDirty);
			if (!isEqual(formValues, values)) {
				setFormValues(values);
			}
		},
		[formValues]
	);

	const handleDialogClose = useCallback(
		(reason, values) => {
			// console.log({ reason, queryValue, values });
			if (reason === 'done') {
				setSending(true);
				const transformUpdate = { ...transform };
				transformUpdate.pipeline = queryValue.queryStr;
				transformUpdate.configSchema = queryValue.configSchema;
				transformUpdate.name = formValues.name;
				transformUpdate.description = formValues.description;

				if (!transform) {
					transformUpdate.uuid = v4();
				}

				const dataSourcesUpdate = addUpdatedTransformToDataSources(
					transforms,
					transformUpdate,
					dataSource,
					dataSources
				);

				const callbacks = {
					success: update => {
						handleSourceUpdate(update);
						setSending(false);
						onClose();
					},
					error: setSending(false),
				};

				updateCollection({
					update: { datasources: dataSourcesUpdate },
					callbacks,
				});

				// onTransformEdit(transformUpdate);
			}
			onClose();
		},
		[
			dataSource,
			dataSources,
			formValues,
			handleSourceUpdate,
			onClose,
			queryValue,
			transform,
			transforms,
			updateCollection,
		]
	);

	return (
		<BtDialog
			open={open}
			onClose={handleDialogClose}
			fullWidth
			maxWidth="xl"
			disableEscapeKeyDown
		>
			<DialogTitle id="form-dialog-title">{label}</DialogTitle>

			<DialogContent>
				<Box sx={{ marginBottom: '20px' }}>
					<BtForm
						validationSchema={schema}
						defaultValues={defaultValues}
						sending={sending}
						loading={loading}
						onSubmit={values => {
							handleDialogClose('done', values);
							// handleSubmit(values);
						}}
						onFormStateChange={handleFormStateChange}
					>
						<BtFormContent>
							<BtFormLateralContainer>
								<BtFormTextField
									// disabled={!editing}
									name="name"
									label="Name"
								/>
								<BtFormTextField
									// disabled={!editing}
									name="description"
									label="Description"
									multiline
								/>
							</BtFormLateralContainer>

							<BtFormChangesBlocker />
						</BtFormContent>
					</BtForm>
				</Box>
				{/* <tFormActionsContainer>
					<BtFormActionButtons
						SubmitIcon={<Upload />}
						submitVerb={'save changes'}
						destructiveVerb={'Discard'}
						onDestroy={() => setEditing(false)}
					/>
				</BtFormActionsContainer> */}
				<BtQueryBuilderVariableProvider variables={variables}>
					<BtQueryBuilderDataProvider
						resourceGroup="data_views"
						resource={dataViewUuid}
						// allowResourceGroupChange={allowResourceGroupChange}
						// allowResourceChange={allowResourceChange}
					>
						<BtQueryBuilder
							{...rest}
							disabled={disabled || sending}
							initialQuery={transform?.pipeline}
							onChange={setQueryValue}
							allowStageRawInput={true}
						/>
					</BtQueryBuilderDataProvider>
				</BtQueryBuilderVariableProvider>
			</DialogContent>
			<DialogActions>
				{/* <BtFormActionsContainer>
						<Button
							variant={disabled ? 'contained' : 'outlined'}
							startIcon={<Close />}
							onClick={() => handleDialogClose('cancel')}
							disableElevation
						>
							{disabled ? 'Close' : 'Cancel'}
						</Button>
						{!disabled && (
							<Button
								type="submit"
								variant="contained"
								startIcon={<Done />}
								// onClick={() => handleDialogClose('done')}
								disableElevation
							>
								Done
							</Button>
						)}
					</BtFormActionsContainer> */}
				<ActionButtonContainer>
					<Button
						variant={disabled ? 'contained' : 'outlined'}
						startIcon={<Close />}
						onClick={() => handleDialogClose('cancel')}
						disableElevation
						disabled={sending}
					>
						{disabled ? 'Close' : 'Cancel'}
					</Button>
					{!disabled && (
						<Button
							variant="contained"
							startIcon={
								sending ? (
									<CircularProgress size={20} />
								) : (
									<Done />
								)
							}
							onClick={() => handleDialogClose('done')}
							disableElevation
							disabled={
								!formIsValid ||
								sending ||
								(!formIsDirty && !queryIsDirty)
							}
						>
							Done
						</Button>
					)}
				</ActionButtonContainer>
			</DialogActions>
		</BtDialog>
	);

	return (
		<BtQueryDialog
			{...options}
			// value={queryResults?.query || defaultQuery}
			value={transform?.pipeline}
			type="query"
			resourceGroup="data_views"
			// resourceGroup="datasets"
			resource={dataViewUuid}
			open={open}
			onClose={(query, results, reason) => {
				// onTransformEdit(results);
				console.log({ query, results, reason });
				// handleChange(results.queryStr, results.configSchema);
				handleChange(results, reason);
				// onClose();
			}}
			// allowResourceGroupChange: false,
			// "allowResourceChange": false,
			// readOnly={false}
			// "disabled": false,
			// "isRequired": false,
			allowStageRawInput={true}
			// hideStagePreviews={false}
			// allowedStages={null}
		/>
	);
}
