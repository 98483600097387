import React from 'react';
import PropTypes from 'prop-types';

import pluralize from 'pluralize';
import { Typography } from '@mui/material';

export default function BtNoItems({
	message,
	subject,
	style,
	typographyProps,
	...other
}) {
	return (
		<div
			style={{
				alignItems: 'center',
				display: 'flex',
				height: 60,
				justifyContent: 'center',
				opacity: 0.8,
				width: '100%',
				...style,
			}}
			{...other}
		>
			<Typography style={{ textAlign: 'center' }} {...typographyProps}>
				{message
					? message
					: `No ${
							subject ? pluralize.plural(subject) : 'items'
					  } to display`}
			</Typography>
		</div>
	);
}

BtNoItems.propTypes = {
	subject: 'items',
};

BtNoItems.propTypes = {
	message: PropTypes.string,
	subject: PropTypes.string,
	style: PropTypes.object,
	typographyProps: PropTypes.object,
};
