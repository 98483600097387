import React from 'react';

// MUI ICONS
// import Dashboard from '@mui/icons-material/Dashboard';
// import InsightsIcon from '@mui/icons-material/Insights';
// import StorageIcon from '@mui/icons-material/Storage';
// import HubIcon from '@mui/icons-material/Hub';
import RouterIcon from '@mui/icons-material/Router';

const IoT_nav_item = [
	{
		id: 'IoT',
		text: 'IoT',
		route: '/IoT',
		icon: <RouterIcon />,
		requiredSubscriptions: ['IoT'],
	},
];

export default IoT_nav_item;
