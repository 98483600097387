import { userAdminGetList } from '../../../../../API';

const getOrgUsers = async ({ setError }) => {
	// Initialise return value
	let result;

	try {
		result = await userAdminGetList();
	} catch (error) {
		setError(true);
		console.log(error);
	} finally {
		return result;
	}
};

export { getOrgUsers };
