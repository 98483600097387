import React, {
	forwardRef,
	useCallback,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import _ from 'lodash';

import BtContributorLabel from '../../../../components/generic/BtContributorLabel';
import BtDetailSection from '../../../../components/generic/BtDetailSection';
import {
	BtForm,
	BtFormLateralContainer,
	BtFormTextField,
} from '../../../../components/generic/forms';
import BtLoading from '../../../../components/generic/BtLoading';
import BtMessage from '../../../../components/generic/BtMessage';
import FormImageFormatList from './FormImageFormatEditor';
import ImageFormatCard from './ImageFormatCard';
import { imageRepoPutSchema } from '../../../../API/validations/imageRepoValidation';
import { imageRepoUpdate } from '../../../../API';
import useFetch from '../../../../hooks/useFetch';
import { useAppContext } from '../../../../context/ContextManager';

const ImageRepoConfig = forwardRef(({ editMode, onUpdateRepo, repo }, ref) => {
	const formRef = useRef();

	const updateApi = useFetch(imageRepoUpdate);

	const { enqueueSnackbar } = useSnackbar();
	const { setActivityIndicator } = useAppContext();

	const [sending, setSending] = useState(false);

	const {
		create_by,
		create_timestamp,
		description,
		formats,
		modify_by,
		modify_timestamp,
		name,
	} = repo;

	const defaultValues = useMemo(
		() => _.pick(repo, ['description', 'formats', 'name']),
		[repo]
	);

	useImperativeHandle(ref, () => ({
		saveChanges: () => formRef.current.submit(),
	}));

	const handleSubmit = useCallback(
		async values => {
			setSending(true);
			setActivityIndicator(true);

			const { ok } = await updateApi.request({
				imageRepoUuid: repo.uuid,
				imageRepoUpdate: values,
			});

			setSending(false);
			setActivityIndicator(false);

			if (!ok) {
				enqueueSnackbar('Failed to update image repository', {
					variant: 'error',
				});

				return;
			}

			enqueueSnackbar('Image repository has been updated', {
				variant: 'success',
			});

			onUpdateRepo(values);

			return () => {
				setActivityIndicator(false);
			};
		},
		[enqueueSnackbar, onUpdateRepo, repo, setActivityIndicator, updateApi]
	);

	const missingFormats = (formats || []).length === 0;

	if (!repo) {
		return <BtLoading />;
	}

	if (!editMode) {
		return (
			<>
				<Typography style={{ paddingBottom: '0.5em' }} variant="h5">
					Details
				</Typography>
				<BtDetailSection data={name} label="Name" />
				<BtDetailSection data={description} label="Description" />
				<BtFormLateralContainer style={{ maxWidth: 640 }}>
					<BtDetailSection
						Component={
							<BtContributorLabel
								date={create_timestamp}
								omitOpening
								userUuid={create_by}
								variant="create"
							/>
						}
						label="Created By"
					/>
					{create_timestamp !== modify_timestamp && (
						<BtDetailSection
							Component={
								<BtContributorLabel
									date={modify_timestamp}
									omitOpening
									userUuid={modify_by}
									variant="create"
								/>
							}
							label="Last Modified By"
						/>
					)}
				</BtFormLateralContainer>
				<Typography
					style={{ marginTop: '0.5em', paddingBottom: '0.5em' }}
					variant="h5"
				>
					{`Formats (${formats?.length || 0})`}
				</Typography>
				<div style={{ marginBottom: '2em' }}>
					{!missingFormats &&
						formats.map(format => (
							<ImageFormatCard
								key={format.uuid}
								format={format}
							/>
						))}
					{missingFormats && (
						<BtMessage
							message="No images can be added until one or more formats have been specified."
							variant="warning"
						/>
					)}
				</div>
			</>
		);
	}

	return (
		<div ref={ref}>
			<BtForm
				ref={formRef}
				defaultValues={defaultValues}
				onSubmit={handleSubmit}
				sending={sending}
				validationSchema={imageRepoPutSchema}
			>
				<BtFormTextField
					label="Name"
					name="name"
					style={{ marginBottom: '1em', maxWidth: 360 }}
				/>
				<BtFormTextField
					label="Description"
					multiline
					name="description"
					style={{ marginBottom: '1em' }}
				/>
				<FormImageFormatList name="formats" />
			</BtForm>
		</div>
	);
});

ImageRepoConfig.defaultProps = {
	repo: {},
};

ImageRepoConfig.propTypes = {
	editMode: PropTypes.bool,
	onUpdateRepo: PropTypes.func.isRequired,
	repo: PropTypes.object,
};

ImageRepoConfig.displayName = 'ImageRepoConfig';

export default ImageRepoConfig;
