import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAppContext } from '../../context/ContextManager';

import useMediaQuery from '@mui/material/useMediaQuery';

import { ThemeProvider } from '@mui/material/styles';
import BtLoading from '../../components/generic/BtLoading';
import Box from '@mui/material/Box';

// used in dev view override
// import { Checkbox } from '@mui/material';
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
// import FormLabel from '@mui/material/FormLabel';

import { lightTheme } from '../../style/muiTheme';

import Footer from './footer/Footer';

import AuthView from './AuthView';

export default function Authentication() {
	const [passwordResetRequired, setPasswordResetRequired] = useState(false);
	const [authLoading, setAuthLoading] = useState(false);
	const { userInfo, setLoadApp } = useAppContext();
	const [authView, setAuthView] = useState('Login');
	// State value for dev view override
	// const [showViews, setShowViews] = React.useState(true);
	const theme = useTheme();
	const screen_downSm = useMediaQuery(theme.breakpoints.down('sm'));
	const [userProps, setUserProps] = useState({
		user: '',
		userToken: '',
		firstTimeLogin: false,
	});

	// // Dev auth view override logic
	// function handleShowViewOptions(event) {
	// 	setShowViews(event.target.checked);
	// }
	// function handleViewChange(event) {
	// 	setAuthView(event.target.value);
	// }

	// Callback for children to change the auth view which is rendered
	function changeView(view) {
		setAuthView(view);
	}

	// Callback  for children to change password reset required flag based on user status
	function changeSetPasswordResetRequired(resetReq) {
		setPasswordResetRequired(resetReq);
	}

	// Callback for login view to pass user props to change password
	function setUserPropsCallback(userProps) {
		setUserProps({
			...userProps,
		});
	}

	// Callback to allow children to set state of auth loader
	function setAuthLoadingCallback(value) {
		setAuthLoading(value);
	}

	// TODO - Similar logic may be implemented at a later date to support access via hyperlink
	/*
    Check if the URL includes the 'ChangePassword/' path, 
    if present the URL parameters are passed to children for use with Cognito
	*/
	// useEffect(
	// 	() => {
	// 		const pathname = location.pathname;
	// 		const params = new URLSearchParams(location.search);
	// 		if (
	// 			(pathname === '/ChangePassword' || '/FirstTimeLogin') &&
	// 			params.has('user', 'token')
	// 		) {
	// 			setUserProps({
	// 				user: params.get('user'),
	// 				userToken: params.get('token'),
	// 				// firstTimeLogin: pathname === '/FirstTimeLogin',
	// 			});
	// 			// setAuthView(pathname);
	// 		}
	// 		// } else {
	// 		// 	setAuthView('login');
	// 		// }
	// 	},
	// 	[location.pathname, location.search, userProps.user, setAuthView]
	// );

	// Auth routing based on value of the user's status
	// Here we decide if the app can be loaded or not, based on user status being equal to 'Active'
	useEffect(
		() => {
			if (userInfo) {
				setAuthLoading(false);
				if (userInfo.status === 'AccountSetupRequired') {
					setAuthView('AccountSetup');
				} else if (userInfo.status === 'Disabled') {
					setAuthView('Disabled');
				} else if (userInfo.status === 'Active') {
					setLoadApp(true);
				} else {
					setAuthView('Error');
				}
			}
		},
		[setLoadApp, userInfo]
	);

	// Auth routing override if change password flag is set to true,
	// this flag gets set to true if cognito returns 'NEW_PASSWORD_REQUIRED' on auth request
	useEffect(
		() => {
			if (passwordResetRequired) {
				setAuthView('ChangePassword');
			}
		},
		[passwordResetRequired]
	);

	return (
		<BtLoading loading={authLoading} showLogo={true} fullScreen>
			<ThemeProvider theme={lightTheme}>
				<Box
					style={{
						display: 'flex',
						minHeight: '100vh',
						backgroundColor: 'white',
						padding: '1.5em 1em 0em 1em',
					}}
				>
					<Box
						style={{
							position: `${screen_downSm ? '' : 'fixed'}`,
							left: `${screen_downSm ? '' : '0px'}`,
							top: `${screen_downSm ? '' : '100px'}`,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							minWidth: '100%',
							minHeight: 'calc(100% - 65px)',
							marginBottom: '65px',
						}}
					>
						<AuthView
							view={authView}
							userProps={userProps}
							changeView={changeView}
							setUserPropsCallback={setUserPropsCallback}
							passwordResetRequired={passwordResetRequired}
							changeSetPasswordResetRequired={
								changeSetPasswordResetRequired
							}
							setAuthLoadingCallback={setAuthLoadingCallback}
						/>

						{/* This is the Dev view override UI which allows switching between views */}
						{/* <Box
							display="flex"
							width="100vw"
							justifyContent="space-evenly"
							alignItems="flex-end"
						>
							<FormControl style={{ color: 'GrayText' }}>
								<FormLabel id="auth-view">
									Dev auth view override
								</FormLabel>
								<Checkbox
									checked={showViews}
									onChange={handleShowViewOptions}
									inputProps={{ 'aria-label': 'controlled' }}
								/>
							</FormControl>
						</Box>
						{showViews && (
							<Box
								display="flex"
								width="100vw"
								justifyContent="center"
								alignItems="center"
							>
								<FormControl style={{ color: 'GrayText' }}>
									<RadioGroup
										row
										aria-labelledby="auth-view"
										name="auth-view"
										value={authView}
										onChange={handleViewChange}
									>
										<FormControlLabel
											value="Login"
											control={<Radio />}
											label="Login"
										/>
										<FormControlLabel
											value="ForgotPassword"
											control={<Radio />}
											label="ForgotPassword"
										/>
										<FormControlLabel
											value="ChangePassword"
											control={<Radio />}
											label="ChangePassword"
										/>
										<FormControlLabel
											value="Disabled"
											control={<Radio />}
											label="Disabled"
										/>
										<FormControlLabel
											value="Error"
											control={<Radio />}
											label="Error"
										/>
										<FormControlLabel
											value="AccountSetup"
											control={<Radio />}
											label="AccountSetup"
										/>
									</RadioGroup>
								</FormControl>
							</Box>
						)} */}
					</Box>
					<Footer />
				</Box>
			</ThemeProvider>
		</BtLoading>
	);
}
