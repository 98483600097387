// == Beacon Tech User Pool ==
const cognito_bt = {
	REGION: 'eu-west-2',
	USER_POOL_ID: 'eu-west-2_VdcenlqbU',
	APP_CLIENT_ID: '4a4pvfn5bnf01ir2e2msfjifa',
	IDENTITY_POOL_ID: 'eu-west-2:3e3e793c-e2d3-4f1c-94c6-b772434fdc83',
};

// == Briiv User Pool ==
const cognito_briiv = {
	REGION: 'eu-west-2',
	USER_POOL_ID: 'eu-west-2_D0CnOJiQP',
	APP_CLIENT_ID: '30mnm5fk35g1cbmsj9mvotvi76',
	IDENTITY_POOL_ID: 'notset',
};

var cognito = {};
if (process.env.REACT_APP_API_USERPOOL === 'BT') {
	cognito = { ...cognito_bt };
} else if (process.env.REACT_APP_API_USERPOOL === 'BRIIV') {
	cognito = { ...cognito_briiv };
} else {
	console.log('User Pool config error!');
	throw new Error('User Pool config error!');
}

const config = { cognito };

// =========================
export default config;
