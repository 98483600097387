import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material';

const Root = styled('div')(({ loading, theme }) => ({
	alignItems: 'center',
	display: 'flex',
	justifyContent: loading ? 'flex-end' : 'space-between',
	gap: 20,

	'& > button': {
		display: loading ? 'none' : 'inherit',
	},

	'& > div': {
		width: 'auto',
	},

	[theme.breakpoints.down('sm')]: {
		flexDirection: 'column-reverse',

		'& > button': {
			width: '100%',
		},

		'& > div': {
			width: '100%',
		},
	},
}));

export default function BtDeleteOptionWrapper({ children, loading }) {
	return <Root loading={+loading}>{children}</Root>;
}

BtDeleteOptionWrapper.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	loading: PropTypes.bool,
};
