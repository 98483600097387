import { createContext, useContext } from 'react';

export const QueryBuilderDataContext = createContext({});
export const useQueryBuilderDataContext = () => {
	return useContext(QueryBuilderDataContext);
};

export const QueryBuilderStageContext = createContext({});
export const useQueryBuilderStageContext = () => {
	return useContext(QueryBuilderStageContext);
};

export const QueryBuilderDispatchContext = createContext(() => undefined);
export const useQueryBuilderDispatchContext = () => {
	return useContext(QueryBuilderDispatchContext);
};

export const QueryBuilderVariableContext = createContext({
	variables: null,
	configSchema: { type: 'object', objectContent: {} },
	onChange: () => undefined,
});
export const useQueryBuilderVariableContext = () => {
	return useContext(QueryBuilderVariableContext);
};

export {
	default as BtQueryBuilderDataProvider,
} from './BtQueryBuilderDataProvider';
