import React from 'react';
import PropTypes from 'prop-types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import * as yup from 'yup';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { BtFormSelect } from '../../../forms';
import BtQueryBuilderPropertyFormField from '../BtQueryBuilderPropertyField';
import { baseStagePropTypes } from './base-prop-type';
import { determineValueType, replaceDynamicTypedValue } from './utils';

const stageType = '$project';

const operatorOptions = [
	{ label: 'include', value: 'include' },
	{ label: 'exclude', value: 'exclude' },
	// { label: 'map to a field value', value: 'remap' },
	{ label: 'calculated value', value: 'addField' },
];

const formSchema = yup.object().shape({
	rules: yup
		.array()
		.min(1)
		.of(
			yup.object().shape({
				operator: yup
					.string()
					.oneOf(operatorOptions.map(opt => opt.value))
					.required()
					.nullable(false),

				// include or exclude a field
				field_type: yup.string().oneOf(['field-path']),
				field: yup
					.string()
					.nullable(true)
					.when(['operator'], {
						is: operator =>
							operator === 'exclude' || operator === 'include',
						then: schema => schema.required(),
						otherwise: schema => schema,
					}),

				// // remap value from field to another field
				// remap_source: yup.string().nullable(true),
				// remap_source_type: yup.string().when(['operator'], {
				// 	is: operator => operator === 'remap',
				// 	then: schema => schema.required().nullable(false),
				// 	otherwise: schema => schema,
				// }),
				// remap_to: yup.string().nullable(true),
				// remap_to_type: yup.string().when(['operator'], {
				// 	is: operator => operator === 'remap',
				// 	then: schema => schema.required().nullable(false),
				// 	otherwise: schema => schema,
				// }),

				// set field to an expression value
				addField_target_type: yup.string().required(),
				addField_target: yup.string().when(['operator'], {
					is: operator => operator === 'addField',
					then: schema =>
						schema
							.required()
							.nullable(false)
							.min(1),
					otherwise: schema => schema.nullable(true),
				}),
				addField_expression_type: yup
					.string()
					.nullable(true)
					.when(['operator'], {
						is: operator => operator === 'addField',
						then: schema => schema.required().nullable(false),
						otherwise: schema => schema,
					}),
				addField_expression: yup
					.string()
					.nullable(true)
					.when(['operator'], {
						is: operator => operator === 'addField',
						then: schema => schema.required().nullable(false),
						otherwise: schema => schema,
					}),
			})
		),
});

const defaultField = () => ({
	operator: null,
	// include / exclude
	field: null,
	field_type: 'field-path', // always field-path

	// remap fields
	// remap_source: null,
	// remap_source_type: 'field-path', // always field path
	// remap_to: null,
	// remap_to_type: 'static_string',

	// addField
	addField_target: '',
	addField_target_type: 'static_string',
	addField_expression: null,
	addField_expression_type: null,
});

function buildFormValues(queryStage, flatConfigSchema, variables) {
	const values = { rules: [] };

	Object.keys(queryStage[stageType]).forEach(k => {
		const projection = queryStage[stageType][k];

		const field = defaultField();

		if (typeof projection === 'boolean' || typeof projection === 'number') {
			return values.rules.push({
				...field,
				operator: projection ? 'include' : 'exclude',
				field: k,
				field_type: 'field-path',
			});
		}

		const [propValType, propVal] = determineValueType(
			k,
			flatConfigSchema,
			true,
			variables
		);

		const [valueType, value] = determineValueType(
			projection,
			flatConfigSchema,
			false,
			variables
		);

		// if (
		// 	(typeof projection === 'string' ||
		// 		projection === null ||
		// 		(typeof projection === 'object' && projection['$literal'])) &&
		// 	valueType !== 'variable'
		// ) {
		// 	if (
		// 		projection &&
		// 		typeof projection === 'object' &&
		// 		projection['$literal']
		// 	) {
		// 		const [valueTypeLiteral, valueLiteral] = determineValueType(
		// 			projection['$literal'],
		// 			{}, // literal so don't allow lookup values
		// 			false,
		// 			variables
		// 		);

		// 		return values.rules.push({
		// 			...field,
		// 			operator: 'remap',
		// 			remap_source: valueLiteral,
		// 			remap_source_type: valueTypeLiteral,
		// 			remap_to: propVal,
		// 			remap_to_type: propValType || 'field-path',
		// 		});
		// 	}

		// 	return values.rules.push({
		// 		...field,
		// 		operator: 'remap',
		// 		remap_source: value,
		// 		remap_source_type: valueType,
		// 		remap_to: propVal,
		// 		remap_to_type: propValType || 'field-path',
		// 	});
		// } else {
		return values.rules.push({
			...field,
			operator: 'addField',
			addField_target: propVal,
			addField_target_type: propValType,
			addField_expression: value,
			addField_expression_type: valueType,
		});
		// }
	});

	if (values.rules.length === 0) {
		values.rules.push(defaultField());
	}

	return values;
}

function buildQueryStage(fields, variables) {
	const query = {};

	fields?.rules?.forEach(rule => {
		switch (rule.operator) {
			case 'include':
				query[rule.field] = 1;
				break;
			case 'exclude':
				query[rule.field] = 0;
				break;
			// case 'remap': {
			// 	try {
			// 		if (
			// 			rule.remap_source_type === 'static_number' ||
			// 			rule.remap_source_type === 'static_boolean' ||
			// 			(rule.remap_source_type === 'static_string' &&
			// 				rule.remap_source[0] === '$')
			// 		) {
			// 			// query[rule.remap_to] = { $literal: rule.remap_source };
			// 			query[rule.remap_to] = rule.remap_source;
			// 			return;
			// 		}

			// 		const val = replaceDynamicTypedValue('remap_source', rule);

			// 		query[rule.remap_to] =
			// 			rule.remap_source_type === 'static_object'
			// 				? { $literal: val }
			// 				: val;
			// 	} catch (e) {
			// 		query[rule.remap_to] = null;
			// 	}
			// 	break;
			// }
			case 'addField': {
				try {
					query[rule.addField_target] = replaceDynamicTypedValue(
						'addField_expression',
						rule,
						variables
					);
				} catch (e) {
					query[rule.addField_target] = null;
				}
				break;
			}
			default:
				break;
		}
	});

	return {
		[stageType]: query,
	};
}

const ProjectOperatorFields = props => {
	const { field, index, operator, disabled, readOnly } = props;

	if (operator === 'include' || operator === 'exclude') {
		return (
			<BtQueryBuilderPropertyFormField
				name={`rules.${index}.field`}
				asPath
				isRequired
				disabled={disabled}
				readOnly={readOnly}
				allowedFieldTypes={['field-path']}
			/>
		);
	}

	// if (operator === 'remap') {
	// 	return (
	// 		<>
	// 			<Box>
	// 				<BtQueryBuilderPropertyFormField
	// 					key={field.id + 'remap_to'}
	// 					name={`rules.${index}.remap_to`}
	// 					label="To field"
	// 					type="string"
	// 					disabled={disabled}
	// 					readOnly={readOnly}
	// 					// allowEmptyString
	// 					isRequired
	// 					asPath
	// 					allowedFieldTypes={['field-path', 'static']}
	// 				/>
	// 			</Box>
	// 			<Box>
	// 				<BtQueryBuilderPropertyFormField
	// 					key={field.id + 'remap_source'}
	// 					name={`rules.${index}.remap_source`}
	// 					label="Map value"
	// 					disabled={disabled}
	// 					readOnly={readOnly}
	// 					// allowEmptyString
	// 					allowedFieldTypes={['field-path', 'static']}
	// 				/>
	// 			</Box>
	// 		</>
	// 	);
	// }

	if (operator === 'addField') {
		return (
			<>
				<BtQueryBuilderPropertyFormField
					key={field.id + 'addField_target'}
					name={`rules.${index}.addField_target`}
					label="Path in Output"
					type="string"
					asPath
					isRequired
					disabled={disabled}
					readOnly={readOnly}
					allowedFieldTypes={['static', 'field-path']}
				/>

				<BtQueryBuilderPropertyFormField
					key={field.id + 'addField_expression'}
					name={`rules.${index}.addField_expression`}
					label="Value"
					isRequired
					disabled={disabled}
					readOnly={readOnly}
					allowedFieldTypes={[
						'field-path',
						'variable',
						'static',
						'expression',
					]}
				/>
			</>
		);
	}

	return null;
};

const ProjectFormContent = props => {
	const { disabled, flatConfigSchema, readOnly } = props;

	const { control, getValues } = useFormContext();

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'rules',
	});

	const values = getValues();

	const hasExclude = values?.rules?.filter(r => r.operator === 'exclude');
	const hasInclude = values?.rules?.filter(
		r => r.operator && r.operator !== 'exclude'
	);

	const options =
		values?.rules?.length <= 1 ||
		(hasExclude?.length === 0 && hasInclude?.length === 0)
			? operatorOptions
			: operatorOptions.filter(
					opt =>
						hasExclude?.length
							? opt.value === 'exclude'
							: opt.value !== 'exclude'
			  );

	return (
		<>
			{fields.map((field, index) => {
				const operator = getValues(`rules.${index}.operator`);

				return (
					<Box key={field.id}>
						<Stack
							direction="row"
							spacing={2}
							justifyContent="space-between"
						>
							<Box width="200px">
								{readOnly ? (
									<>
										<Typography>Operator</Typography>
										<Typography>
											{operator || '** unset **'}
										</Typography>
									</>
								) : (
									<BtFormSelect
										label="Operator"
										key={field.id + 'operator'}
										disabled={disabled}
										readOnly={readOnly}
										name={`rules.${index}.operator`}
										items={options}
										fullWidth
										isRequired
										hideNone
									/>
								)}
							</Box>
							{!readOnly && (
								<Box>
									<IconButton
										onClick={() => remove(index)}
										disabled={disabled}
									>
										<DeleteIcon />
									</IconButton>
								</Box>
							)}
						</Stack>
						<Stack direction="row" spacing={2} maxWidth="100%">
							<Box width="100%">
								{operator && (
									<ProjectOperatorFields
										field={field}
										index={index}
										key={field.id}
										operator={operator}
										disabled={disabled}
										readOnly={readOnly}
										flatConfigSchema={flatConfigSchema}
									/>
								)}
							</Box>
						</Stack>
						{index < fields.length - 1 && (
							<Divider sx={{ margin: 2 }} />
						)}
					</Box>
				);
			})}
			{!readOnly && (
				<Box sx={{ textAlign: 'center' }}>
					<Button
						onClick={() => append(defaultField())}
						disabled={disabled}
						startIcon={<AddIcon />}
					>
						Add projection
					</Button>
				</Box>
			)}
		</>
	);
};

export default {
	formSchema: formSchema,
	FormContent: ProjectFormContent,
	buildQueryStage: buildQueryStage,
	buildFormValues: buildFormValues,
};

// BtQueryBuilderStageProject.propTypes = baseStagePropTypes;

ProjectOperatorFields.propTypes = {
	field: PropTypes.object,
	index: PropTypes.number,
	operator: PropTypes.string,
	flatConfigSchema: PropTypes.object,
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
};
ProjectFormContent.propTypes = baseStagePropTypes;
