import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import * as yup from 'yup';
import _ from 'lodash';

import { Button, IconButton, Typography, Box } from '@mui/material';
import { format } from 'date-fns';
import produce from 'immer';
import RemoveIcon from '@mui/icons-material/PersonRemove';
import AddIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CachedIcon from '@mui/icons-material/Cached';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import {
	a11yProps,
	BtTab,
	BtTabBar,
	BtTabPanel,
	BtTabView,
} from '../../../components/generic/BtTabView';
import BtError from '../../../components/generic/BtError';
import {
	BtFormContainer,
	BtFormLateralContainer,
} from '../../../components/generic/forms';
import BtListComposer from '../../../components/generic/BtListComposer';
import BtLoading from '../../../components/generic/BtLoading';
import BtTags from '../../../components/generic/BtTags';
import {
	apiManagerGet,
	apiManagerGenKey,
	apiManagerDelete,
	apiManagerUpdate,
	apiManagerDeleteIpFilter,
} from '../../../API';
import useFetch from '../../../hooks/useFetch';
import { useNavContext } from '../../../context/ContextManager';
import BtDetailSection from '../../../components/generic/BtDetailSection';
import BtConfirmDialog from '../../../components/generic/BtConfirmDialog';
import BtOverview from '../../../components/generic/BtOverview';
import BtMessage from '../../../components/generic/BtMessage';
import BtSelectionTable from '../../../components/generic/BtSelectionTable';
import {
	BtForm,
	BtFormContent,
	BtFormTextField,
	BtFormSwitch,
	BtFormCheckbox,
	BtFormActionsContainer,
	BtFormActionButtons,
} from '../../../components/generic/forms';
import BtDeleteOptionWrapper from '../../../components/generic/BtDeleteOptionWrapper';
import { useAppContext } from '../../../context/ContextManager';
import ApiManagerPermissions from './ApiManagerPermissions';
import AddIpFilterDialog from './AddIpFilterDialog';
import SaveIcon from '@mui/icons-material/Save';

// Data for landing page selection table and contextual navigation
import data_manager_options from '../DataManagerOptions';

import data_manager_breadcrumbs from '../DataManagerBreadcrumbs';

import data_manager_nav_item from '../DataManagerNavItem';

const latContainerStyle = { marginBottom: '1em' };

const schema = yup.object({
	name: yup
		.string()
		.min(3)
		.required()
		.label('API Key Name'),
	description: yup
		.string()
		.min(3)
		.required()
		.label('API Key Description'),
	enabled: yup
		.boolean()
		.required()
		.label('API Key Enabled'),
});

export default function ApiManagerEditor({ match }) {
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();

	const { setActivityIndicator } = useAppContext();

	const [currentTab, setCurrentTab] = useState(0);
	const [deleteDialog, setDeleteDialog] = useState(false);
	const [genNewKeyDialog, setGenNewKeyDialog] = useState(false);
	const [newIpAddressDialogOpen, setNewIpAddressDialogOpen] = useState(false);
	const [deleteIpFilterDialog, setDeleteIpFilterDialog] = useState(false);
	const [deleteIpFilterUuid, setDeleteIpFilterUuid] = useState(null);
	const [apiKey, setApiKey] = useState();
	const [ipList, setIpList] = useState([]);
	const [selectedIpFilter, setSelectedIpFilter] = useState(null);
	const [removing, setRemoving] = useState(false);
	const [sending, setSending] = useState(false);
	const [apiKeyValue, setApiKeyValue] = useState(null);

	const { loading, error: apiKeyError, request: getApiKey } = useFetch(
		apiManagerGet,
		setApiKey
	);

	useEffect(
		() => {
			if (apiKey) {
				setIpList(apiKey.ip_list);
			}
		},
		[setIpList, apiKey]
	);

	const apiKeyId = useMemo(() => match.params.uuid, [match]);

	// Set contextual navigation items and breadcrumbs
	const { setContextualNav, setBreadcrumbs } = useNavContext();
	useEffect(
		() => {
			setContextualNav([
				...data_manager_nav_item,
				...data_manager_options,
			]);
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'Data Manager', link: '/DataManager' },
				{ text: 'API Manager', link: '/ApiManager' },
				{ text: 'Edit API Key', link: '' },
			]);
			return () => setContextualNav(null);
		},
		[setContextualNav, setBreadcrumbs]
	);

	const defaultValues = useMemo(
		() => {
			const { name, description, enabled } = apiKey || {};
			return { name, description, enabled };
		},
		[apiKey]
	);

	const onSubmit = useCallback(
		async (values, reset) => {
			setSending(true);

			try {
				await apiManagerUpdate({
					apiManagerUuid: apiKey?.uuid,
					apiKeyUpdate: values,
				});

				setApiKey(
					produce(draft => {
						draft.name = values.name;
						draft.description = values.description;
						draft.modify_timestamp = Date.now();
					})
				);

				reset(values);
			} catch {
				enqueueSnackbar('Failed to update the API key details', {
					variant: 'error',
				});
			} finally {
				setSending(false);
			}
		},
		[enqueueSnackbar, apiKey, setApiKey]
	);

	useEffect(
		() => {
			if (apiKeyId && !apiKey) {
				getApiKey({ apiKeyUuid: apiKeyId });
			}
		},
		[getApiKey, apiKey, apiKeyId]
	);

	useEffect(() => setActivityIndicator(removing), [
		setActivityIndicator,
		removing,
	]);

	const handleDeleteApiKey = useCallback(
		async () => {
			try {
				await apiManagerDelete({ apiKeyUuid: apiKey.uuid });
				enqueueSnackbar('API key deleted', { variant: 'success' });
				history.push('/ApiManager');
			} catch (error) {
				enqueueSnackbar('Failed to delete API key', {
					variant: 'error',
				});
			}
		},
		[enqueueSnackbar, history, apiKey]
	);

	const handleDeleteApiKeyIpFilter = useCallback(
		async () => {
			try {
				console.log(deleteIpFilterUuid);
				await apiManagerDeleteIpFilter({
					apiKeyUuid: apiKey.uuid,
					ipFilterUuid: deleteIpFilterUuid,
				});
				enqueueSnackbar('API key IP Filter deleted', {
					variant: 'success',
				});
				setNewIpAddressDialogOpen(false);

				// Remove the IP from our current list in state

				const newIpList = ipList.filter(
					({ uuid }) => uuid !== deleteIpFilterUuid
				);
				setIpList(newIpList);
			} catch (error) {
				console.log(error);
				enqueueSnackbar('Failed to delete API key IP Filter', {
					variant: 'error',
				});
			}
		},
		[enqueueSnackbar, history, apiKey, deleteIpFilterUuid]
	);

	const handleGenApiKey = useCallback(
		async () => {
			try {
				let newApiKey = await apiManagerGenKey({
					apiKeyUuid: apiKey.uuid,
				});

				setApiKeyValue(newApiKey.prefix + '.' + newApiKey.key);
				setApiKey({
					...apiKey,
					prefix: newApiKey.prefix,
					generate_timestamp: newApiKey.generate_timestamp,
				});
				enqueueSnackbar('New API Key Generated', {
					variant: 'success',
				});
			} catch (error) {
				enqueueSnackbar('Failed to generate API key', {
					variant: 'error',
				});
			}
		},
		[enqueueSnackbar, history, apiKey]
	);

	const handleCopyKey = () => {
		navigator.clipboard.writeText(apiKeyValue);
		enqueueSnackbar('API Key copied to clipboard', { variant: 'success' });
	};

	const handleAddNewIpFilterClick = () => {
		setSelectedIpFilter(null);
		setNewIpAddressDialogOpen(true);
	};

	const handleIpFilterRemoveClick = ipFilterUuid => () => {
		setDeleteIpFilterUuid(ipFilterUuid);
		setDeleteIpFilterDialog(true);
	};

	const handleIpFilterAdd = ipFilter => {
		setIpList([...ipList, ipFilter]);
	};

	if (apiKeyError) {
		return (
			<BtError
				title="Retrieval Error"
				description="An error occurred when attempting to retrieve the API key information"
			/>
		);
	}

	return (
		<>
			<BtTabView>
				<BtFormContainer
					title={apiKey?.name}
					style={{ maxWidth: 680 }}
					header={
						<BtLoading
							loading={loading || removing}
							LoadingComponent={<div style={{ height: 50 }} />}
						>
							<BtTabBar
								currentTab={currentTab}
								onTabChange={(event, selectedTab) =>
									setCurrentTab(selectedTab)
								}
								style={{
									transform: 'translateY(-1px)',
									marginTop: 10,
								}}
							>
								<BtTab label="Details" {...a11yProps(0)} />
								<BtTab label="Key Value" {...a11yProps(1)} />
								<BtTab label="Permissions" {...a11yProps(2)} />
								<BtTab
									label="Access Security"
									{...a11yProps(3)}
								/>
							</BtTabBar>
						</BtLoading>
					}
				>
					<BtLoading loading={loading || removing}>
						<BtTabPanel currentTab={currentTab} index={0}>
							<BtOverview
								loading={loading || removing}
								createdTimestamp={apiKey?.create_timestamp}
								modifiedTimestamp={apiKey?.modify_timestamp}
							>
								<div
									style={{
										display: 'flex',
										flexWrap: 'wrap',
									}}
								>
									{/* <Typography
								variant="subtitle"
								style={{ marginRight: '2em' }}
							>
								{`${role?.users?.length || 0} user${
									role?.users?.length !== 1 ? 's' : ''
								} assigned`}
							</Typography> */}
									<Typography variant="subtitle">
										{`${apiKey?.permissions?.length ||
											0} permission${
											apiKey?.permissions?.length !== 1
												? 's'
												: ''
										} applied`}
									</Typography>
								</div>
							</BtOverview>

							<BtForm
								defaultValues={defaultValues || {}}
								validationSchema={schema}
								onSubmit={onSubmit}
								loading={loading}
								sending={sending}
							>
								<BtFormContent>
									<BtFormTextField name="name" label="Name" />
									<BtFormTextField
										name="description"
										label="Description"
										multiline
										maxRows={4}
									/>
									<BtFormCheckbox
										name="enabled"
										label="Enabled"
										style={{ marginRight: '1em' }}
									/>

									<BtDeleteOptionWrapper loading={loading}>
										<Button
											color="error"
											onClick={() =>
												setDeleteDialog(true)
											}
											startIcon={<DeleteIcon />}
											variant="outlined"
											disableElevation
										>
											Delete API Key
										</Button>
										<BtFormActionsContainer
											style={{
												padding: '1em 0',
											}}
										>
											<BtFormActionButtons
												SubmitIcon={<SaveIcon />}
												submitVerb={'Save'}
												destructiveVerb={
													'Discard changes'
												}
											/>
										</BtFormActionsContainer>
									</BtDeleteOptionWrapper>
								</BtFormContent>
							</BtForm>
						</BtTabPanel>
						<BtTabPanel currentTab={currentTab} index={1}>
							<BtFormLateralContainer style={latContainerStyle}>
								<BtDetailSection
									label="Key Prefix"
									data={apiKey?.prefix}
								/>
								<BtDetailSection
									label="Generated"
									data={format(
										apiKey?.generate_timestamp || 0,
										'dd MMM yyyy, hh:mm a'
									)}
								/>
							</BtFormLateralContainer>
							<Button
								onClick={() => setGenNewKeyDialog(true)}
								startIcon={<CachedIcon />}
								variant="outlined"
								disableElevation
							>
								Generate new key
							</Button>
							{apiKeyValue && (
								<>
									<BtMessage
										message="This is the only chance you can access this API Key, please copy it and keep it safe."
										showIcon
										variant="warning"
										style={{
											marginTop: '1em',
										}}
									/>
									<BtFormLateralContainer
										style={{
											...latContainerStyle,
											marginTop: '1em',
										}}
									>
										<BtDetailSection
											label="API Key Value"
											data={apiKeyValue}
										/>
									</BtFormLateralContainer>
									<Button
										onClick={handleCopyKey}
										startIcon={<ContentCopyIcon />}
										variant="outlined"
										disableElevation
									>
										Copy to clipboard
									</Button>
								</>
							)}
						</BtTabPanel>
						<BtTabPanel currentTab={currentTab} index={2}>
							<ApiManagerPermissions
								apiKey={apiKey}
								setApiKey={setApiKey}
								apiKeyId={apiKeyId}
							/>
						</BtTabPanel>
						<BtTabPanel currentTab={currentTab} index={3}>
							<BtSelectionTable
								title={
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											flexWrap: 'wrap',
											gap: 10,
										}}
									>
										<Typography
											sx={{
												fontWeight: 'bold',
												padding: '4px 0',
											}}
											variant="h5"
										>
											IP Address Access List
										</Typography>
										<Button
											variant="contained"
											onClick={handleAddNewIpFilterClick}
											startIcon={<AddIcon />}
											disableElevation
										>
											Add IP Address
										</Button>
									</div>
								}
								subject="IP Access List"
								columns={[
									{ field: 'ip', text: 'IP Address' },
									{ field: 'name', text: 'Name' },
									{
										field: 'expire_timestamp',
										text: 'Expires',
									},
									{ field: 'remove', text: 'Remove' },
								]}
								data={(ipList || []).map(
									({ uuid, name, expire_timestamp, ip }) => ({
										name: name,
										expire_timestamp:
											expire_timestamp === 0
												? '-'
												: format(
														new Date(
															expire_timestamp ||
																0
														),
														'dd MMM yyyy, hh:mm a'
												  ),
										ip: ip,

										remove: (
											<IconButton
												onClick={handleIpFilterRemoveClick(
													uuid
												)}
											>
												<DeleteIcon />
											</IconButton>
										),
									})
								)}
							/>
						</BtTabPanel>
					</BtLoading>
				</BtFormContainer>
			</BtTabView>
			<BtConfirmDialog
				action={() => {
					handleDeleteApiKey();
				}}
				isDestructive
				open={deleteDialog}
				onClose={() => setDeleteDialog(false)}
				title="Delete API Key?"
				prompt={`Are you sure you want to delete ${apiKey?.name}`}
				verb="Delete API Key"
				ActionIcon={<DeleteIcon />}
			/>
			<BtConfirmDialog
				action={() => {
					handleGenApiKey();
				}}
				isDestructive
				open={genNewKeyDialog}
				onClose={() => setGenNewKeyDialog(false)}
				title="Generate a New API Key?"
				prompt={
					'Are you sure you want to generate a new key, this will replace the current key and will effect systems currently using this key?'
				}
				verb="Generate New API Key"
				ActionIcon={<CachedIcon />}
			/>
			<BtConfirmDialog
				action={() => {
					handleDeleteApiKeyIpFilter();
				}}
				isDestructive
				open={deleteIpFilterDialog}
				onClose={() => setDeleteIpFilterDialog(false)}
				title="Delete IP Address?"
				prompt={`Are you sure you want to delete this IP Address`}
				verb="Delete IP Addess"
				ActionIcon={<DeleteIcon />}
			/>
			<AddIpFilterDialog
				open={newIpAddressDialogOpen}
				onClose={() => setNewIpAddressDialogOpen(false)}
				apiKeyUuid={apiKeyId}
				addFilter={handleIpFilterAdd}
			/>
		</>
	);
}
