import axios from 'axios';
import apiAuthHeaders from './auth_headers';

import serverUrl from '../utils/serverUrl';

const apiClient = async ({
	method,
	url,
	body = null,
	headers = null,
	params = null,
}) => {
	const authHeaders = await apiAuthHeaders(); // Get the auth headers

	if (url) {
		try {
			const req_url = serverUrl() + '/v1/' + url;
			const resp = await axios({
				method: method,
				url: req_url,
				headers: {
					...headers,
					...authHeaders,
				},
				data: body,
				params: params,
			});
			const data = await resp.data;
			return data;
		} catch (error) {
			console.log(error);
			if (error.response) {
				throw new Error(error.response.data); // => the response payload
			} else {
				throw new Error('Server Error');
			}
		}
	}
};

export { apiClient };
