const _ = require('lodash');

const MAX_INPUT_DOCS = 10000;

// ===========================================================================
function isArray(input) {
	return Object.prototype.toString.call(input) === '[object Array]';
}

// ===========================================================================
function convertToDot(input, output) {
	Object.keys(input).forEach((prop) => {
		if (isArray(input[prop])) {
			for (var i = 0; i < input[prop].length; i++) {
				const k = `${prop}[${i}]`;
				const v = input[prop][i];
				if (v === null) {
					output[k] = null;
				} else if (typeof v === 'object') {
					output[k] = {};
					convertToDot(v, output[k]);
				} else {
					output[k] = v;
				}
			}
		} else if (input[prop] === null) {
			// typeof null is "object"
			output[prop] = null;
		} else if (typeof input[prop] === 'object') {
			output[prop] = {};
			convertToDot(input[prop], output[prop]);
		} else {
			output[prop] = input[prop];
		}
	});

	return Object.prototype.toString.call(input) === '[object Array]';
}

// ===========================================================================
function validateGeoJSON(input) {
	// Ensure the type prop exists
	if (!input.type) {
		return false;
	}

	// The only supported type is 'Point'
	if (input.type !== 'Point') {
		return false;
	}

	// Ensure the coordinates prop is set
	if (!input.coordinates) {
		return false;
	}

	// Coordinates must an array
	if (!isArray(input.coordinates)) {
		return false;
	}

	// Coordinates length must be 2 for longitude then latitude
	if (input.coordinates.length !== 2) {
		return false;
	}

	// Longitude must be a number
	if (typeof input.coordinates[0] !== 'number') {
		return false;
	}

	// Valid longitude values at -180 to 180 inclusive
	if (input.coordinates[0] < -180 || input.coordinates[0] > 180) {
		return false;
	}

	// Latitude must be a number
	if (typeof input.coordinates[1] !== 'number') {
		return false;
	}

	// Valid latitude values at -180 to 180 inclusive
	if (input.coordinates[1] < -90 || input.coordinates[1] > 90) {
		return false;
	}
	return true;
}

// ===========================================================================
function calculateGeoDistance(coord1, coord2) {
	const { PI, cos, sin, asin, sqrt } = Math;

	// equatorial mean radius of Earth (in km)
	const radius = 6378.137;

	const toRad = (x) => (x * PI) / 180.0;
	const hav = (x) => sin(x / 2) ** 2;

	const coord1Long = toRad(coord1[0]);
	const coord2Long = toRad(coord2[0]);

	const coord1Lat = toRad(coord1[1]);
	const coord2Lat = toRad(coord2[1]);

	const havTheta =
		hav(coord2Long - coord1Long) +
		cos(coord1Long) * cos(coord2Long) * hav(coord2Lat - coord1Lat);

	return 2 * radius * asin(sqrt(havTheta));
}

// ===========================================================================
// TODO: array matching add: $in, $all, and array of query values
function match(input, query) {
	var flatInput = input;
	if (typeof input === 'object') {
		flatInput = {};
		convertToDot(input, flatInput);
	}

	// If query in not an object do a simple equality comparision
	if (typeof query !== 'object') {
		if (flatInput !== query) {
			return false;
		}
	} else {
		// Process each prop in query object
		for (const queryProp of Object.keys(query)) {
			// Check if the queryProp start with a '$' and so an operator
			if (queryProp[0] === '$') {
				switch (queryProp) {
					case '$and':
						if (!isArray(query[queryProp])) {
							throw new Error(
								'MongoDb Query: $and value must of type array'
							);
						}

						// Check each expression in the array, return false if any are false
						if (
							query[queryProp]
								.map((query) => {
									return objectMatch(input, query);
								})
								.includes(false)
						) {
							return false;
						}
						break;
					case '$or':
						if (!isArray(query[queryProp])) {
							throw new Error(
								'MongoDb Query: $or value must of type array'
							);
						}

						// Check each expression in the array, return false all are false
						if (
							!query[queryProp]
								.map((query) => {
									return objectMatch(input, query);
								})
								.includes(true)
						) {
							return false;
						}
						break;

					default:
						return matchTestValue(input, query);
				}
			} else {
				if (!matchTest(input, queryProp, query[queryProp])) {
					return false;
				}
			}
		}
	}

	return true;
}

// ===========================================================================
function matchTestValue(input, query) {
	if (typeof query !== 'object') {
		if (input !== query) {
			return false;
		}
	}
	// If query is null, check if input is null or undefined
	else if (query === null) {
		if (input !== null && input !== undefined) {
			return false;
		}
	} else {
		for (const queryProp of Object.keys(query)) {
			if (queryProp[0] === '$') {
				switch (queryProp) {
					// case '$not':
					// 	break;
					// case '$cmp':
					// 	break;
					case '$eq':
						if (!(input === query[queryProp])) {
							// TODO: check query[queryProp] type
							return false;
						}
						break;
					case '$gt':
						if (!(input > query[queryProp])) {
							// TODO: check query[queryProp] type
							return false;
						}
						break;
					case '$gte':
						if (!(input >= query[queryProp])) {
							// TODO: check query[queryProp] type
							return false;
						}
						break;
					case '$lt':
						if (!(input < query[queryProp])) {
							// TODO: check query[queryProp] type
							return false;
						}
						break;
					case '$lte':
						if (!(input <= query[queryProp])) {
							// TODO: check query[queryProp] type
							return false;
						}
						break;
					case '$ne':
						if (!(input !== query[queryProp])) {
							// TODO: check query[queryProp] type
							return false;
						}
						break;
					case '$exists':
						if (typeof query[queryProp] !== 'boolean') {
							throw new Error(
								'MongoDb Query: $exists can only take a boolean value'
							);
						}

						return query[queryProp]
							? input !== undefined
							: input === undefined;
					case '$near':
						// console.log('Do near test', input, query[queryProp]);
						if (!validateGeoJSON(input)) {
							return false;
						}
						if (!query[queryProp].$geometry) {
							throw new Error(
								'MongoDb Query: $near missing $geometry value'
							);
						}
						if (!validateGeoJSON(query[queryProp].$geometry)) {
							throw new Error(
								'MongoDb Query: invalid $geometry value for $near'
							);
						}
						const distance = calculateGeoDistance(
							input.coordinates,
							query[queryProp].$geometry.coordinates
						);
						//console.log('distance', distance);
						if (query[queryProp].$minDistance) {
							if (distance < query[queryProp].$minDistance) {
								return false;
							}
						}
						if (query[queryProp].$maxDistance) {
							if (distance > query[queryProp].$maxDistance) {
								return false;
							}
						}
						break;
					case '$regex':
						// TODO nin option

						const options =
							typeof query['$options'] !== 'string'
								? undefined
								: query['$options'];

						const expr = new RegExp(query[queryProp], options);

						if (!expr.test(input)) {
							// TODO: check query[queryProp] type
							return false;
						}
						break;
					case '$options':
						// options is an extension of other operators - e.g regex
						continue;
					default:
						throw new Error(
							`MongoDb Query: unknown objectMatch operator: ${queryProp}`
						);
				}
			} else {
				// Is the query value an expression
				if (typeof query[queryProp] === 'object') {
					// Test the nested query
					if (!objectMatch(flatInput[queryProp], query[queryProp])) {
						return false;
					}
				} else {
					// Basic value test
					if (flatInput[queryProp] !== query[queryProp]) {
						return false;
					}
				}
			}
		}
	}
	return true;
}

// ===========================================================================
function matchTest(input, queryProp, queryValue) {
	const dotIndex = queryProp.indexOf('.');
	let firstQueryProp = queryProp;
	let remainingQueryProp = null;

	let remainingQueryPropPartOne = null;
	let remainingQueryPropPartPlusOne = null;

	if (dotIndex > -1) {
		firstQueryProp = queryProp.substring(0, dotIndex);
		remainingQueryProp = queryProp.substring(dotIndex + 1);

		const remainingDotIndex = remainingQueryProp.indexOf('.');
		remainingQueryPropPartOne = remainingQueryProp;

		if (remainingDotIndex > -1) {
			remainingQueryPropPartOne = remainingQueryProp.substring(
				0,
				remainingDotIndex
			);
			remainingQueryPropPartPlusOne = remainingQueryProp.substring(
				remainingDotIndex + 1
			);
		}
	}

	if (isArray(input[firstQueryProp])) {
		const intVal = parseInt(remainingQueryPropPartOne, 10);
		if (!isNaN(intVal) && intVal >= 0) {
			// we're looking for a specific key in an array
			const arr_item = input[firstQueryProp][intVal];
			// Check if the element is an array
			if (
				typeof arr_item === 'object' &&
				arr_item !== null &&
				remainingQueryPropPartPlusOne
			) {
				return matchTest(
					arr_item,
					remainingQueryPropPartPlusOne,
					queryValue
				);
			} else {
				return matchTestValue(arr_item, queryValue);
			}
		} else {
			const resultArray = input[firstQueryProp].map((inputElem) => {
				// Check if the element is an array
				if (typeof inputElem === 'object') {
					return matchTest(inputElem, remainingQueryProp, queryValue);
				} else {
					return matchTestValue(inputElem, queryValue);
				}
			});

			return resultArray.includes(true);
		}
	} else if (
		typeof input[firstQueryProp] === 'object' &&
		input[firstQueryProp] !== null
	) {
		return matchTest(input[firstQueryProp], remainingQueryProp, queryValue);
	} else if (remainingQueryProp !== null) {
		// we have further subpaths to navigate, but the input is not an object
		// or array
		return false;
	} else {
		return matchTestValue(input[firstQueryProp], queryValue);
	}
}

// ===========================================================================
function objectMatch(input, query) {
	// Check if the input is an array if so check each element as an OR
	if (isArray(input)) {
		const results = input.map((inputItem) => {
			return objectMatch(inputItem, query);
		});

		return results.includes(true);
	} else {
		return match(input, query);
	}
}

// ===========================================================================
async function processDocs(input, stage, operationFunc) {
	var result = [];

	if (isArray(input)) {
		for (var i = 0; i < input.length; i++) {
			operationFunc(input[i], stage, i, result);
		}
	} else {
		var docIndex = 0;

		while (true) {
			// Limit the amount of docs which can be processed
			if (docIndex > MAX_INPUT_DOCS) {
				break;
			}

			var inputDoc = await input(docIndex);
			if (!inputDoc) {
				break;
			}
			if (typeof inputDoc !== 'object') {
				throw new Error('input documnet not an object');
			}

			operationFunc(inputDoc, stage, docIndex, result);

			docIndex = docIndex + 1;
		}
	}

	return result;
}

// ===========================================================================
async function pipelineMatch(input, stage) {
	const op = (inputDoc, stage, docIndex, accumulator) => {
		if (objectMatch(inputDoc, stage)) {
			accumulator.push(inputDoc);
		}
	};

	return await processDocs(input, stage, op);
}

// ===========================================================================
async function pipelineLimit(input, stage) {
	if (typeof stage !== 'number') {
		throw new Error('$limit value must be a number');
	}

	const op = (inputDoc, stage, docIndex, accumulator) => {
		if (docIndex < stage) {
			accumulator.push(inputDoc);
		}
	};

	return await processDocs(input, stage, op);
}

// ===========================================================================
async function pipelineSort(input, stage) {
	if (typeof stage !== 'object') {
		throw new Error('$sort value must be an object');
	}

	const sortProps = Object.keys(stage);
	const sortDirections = sortProps.map((prop) => {
		return stage[prop] === -1 ? 'desc' : 'asc';
	});

	const op = (inputDoc, stage, docIndex, accumulator) => {
		accumulator.push(inputDoc);
	};

	var inputDocs = await processDocs(input, stage, op);

	return _.orderBy(inputDocs, sortProps, sortDirections);
}

// ===========================================================================
function processUnwind(input, stage) {
	const dotIndex = stage.indexOf('.');
	var firstProp = stage;
	var remainingProps = null;
	if (dotIndex > -1) {
		firstProp = stage.substring(0, dotIndex);
		remainingProps = stage.substring(dotIndex + 1);

		const updatedValue = processUnwind(input[firstProp], remainingProps);

		if (updatedValue === null) {
			const { firstProp, ...inputResult } = input;
			return inputResult;
		}

		if (isArray(updatedValue)) {
			if (updatedValue.length === 0) {
				delete input[firstProp];
				return input;
			}

			return updatedValue.map((value) => {
				return { ...input, [firstProp]: value };
			});
		} else {
			return { ...input, [firstProp]: updatedValue };
		}
	} else {
		if (!input) {
			return null;
		}

		if (!isArray(input)) {
			if (!isArray(input[stage])) {
				return input;
			}

			const unwindRes = input[stage].map((prop) => {
				return { ...input, [stage]: prop };
			});
			return unwindRes;
		} else {
			const unwindRes = input.map((inputElem) => {
				return processUnwind(inputElem, stage);
			});
			return unwindRes;
		}
	}
}

// ===========================================================================
async function pipelineUnwind(input, stage) {
	// Check stage begins with '$'
	if (stage[0] !== '$') {
		throw new Error("$unwind property must begin with '$'");
	}

	const op = (inputDoc, stage, docIndex, accumulator) => {
		const outputDoc = processUnwind(inputDoc, stage.substring(1));
		if (!!outputDoc) {
			if (isArray(outputDoc)) {
				outputDoc.forEach((newData) => {
					accumulator.push(newData);
				});
			} else {
				accumulator.push(outputDoc);
			}
		}
	};

	return await processDocs(input, stage, op);
}

// ===========================================================================
function pipelineGetElement(input, path) {
	const dotIndex = path.indexOf('.');
	var firstProp = path;
	var remainingProps = null;
	if (dotIndex > -1) {
		firstProp = path.substring(0, dotIndex);
		remainingProps = path.substring(dotIndex + 1);
		return pipelineGetElement(input[firstProp], remainingProps);
	} else {
		return input[path];
	}
}

// ===========================================================================
function processReplaceRoot(inputDoc, stage) {
	var outputDoc = null;

	// If the newRoot is an object then we are creating a new documnet
	// else we are promoting a single document
	if (typeof stage.newRoot === 'object') {
		outputDoc = {};

		const newRootKeys = Object.keys(stage.newRoot);
		if (newRootKeys[0] === '$mergeObjects') {
			if (!isArray(stage.newRoot.$mergeObjects)) {
				throw new Error(`$replaceRoot $mergeObjects must an array`);
			}

			var outputDoc = {};
			for (const mergeObj of stage.newRoot.$mergeObjects) {
				if (mergeObj === '$$ROOT') {
					outputDoc = { ...outputDoc, ...inputDoc };
				} else {
					// Check stage begins with '$'
					if (mergeObj[0] !== '$') {
						throw new Error(
							"$replaceRoot newRoot document promotion must begin with '$'"
						);
					}

					const foundElement = pipelineGetElement(
						inputDoc,
						mergeObj.substring(1)
					);
					outputDoc = { ...outputDoc, ...foundElement };
				}
			}
		} else {
			throw new Error(
				`$replaceRoot unsupported newRoot operator ${newRootKeys[0]}`
			);
		}
	} else {
		// Check stage begins with '$'
		if (stage.newRoot[0] !== '$') {
			throw new Error(
				"$replaceRoot newRoot document promotion must begin with '$'"
			);
		}

		outputDoc = pipelineGetElement(inputDoc, stage.newRoot.substring(1));
	}

	if (typeof outputDoc !== 'object') {
		throw new Error('$replaceRoot result not a document');
	}

	return outputDoc;
}

// ===========================================================================
async function pipelineReplaceRoot(input, stage) {
	// Stage must be an object
	if (typeof stage !== 'object') {
		throw new Error('$replaceRoot prop must an object');
	}

	// The stage requires a prop call newRoot
	if (!stage.newRoot) {
		throw new Error('$replaceRoot missing newRoot prop');
	}

	const op = (inputDoc, stage, docIndex, accumulator) => {
		const outputDoc = processReplaceRoot(inputDoc, stage);

		if (!!outputDoc) {
			accumulator.push(outputDoc);
		}
	};

	return await processDocs(input, stage, op);
}

// ===========================================================================
function piplelineProjectRemove(input, path) {
	const dotIndex = path.indexOf('.');
	var firstProp = path;
	var remainingProps = null;
	if (dotIndex > -1) {
		firstProp = path.substring(0, dotIndex);
		remainingProps = path.substring(dotIndex + 1);

		if (isArray(input[firstProp])) {
			input[firstProp] = input[firstProp].map((element) => {
				// TODO: catch is value is not object
				return piplelineProjectRemove(element, remainingProps);
			});
			return input;
		} else {
			input[firstProp] = piplelineProjectRemove(
				input[firstProp],
				remainingProps
			);
			return input;
		}
	} else {
		delete input[path];
		return input;
	}
}

// ===========================================================================
function piplelineProjectAdd(current, input, path) {
	const dotIndex = path.indexOf('.');
	var firstProp = path;
	var remainingProps = null;
	if (dotIndex > -1) {
		firstProp = path.substring(0, dotIndex);
		remainingProps = path.substring(dotIndex + 1);

		if (isArray(input[firstProp])) {
			if (!current[firstProp]) {
				current[firstProp] = [];
			}

			for (const element of input[firstProp]) {
				if (typeof element === 'object') {
					current[firstProp].push(
						piplelineProjectAdd({}, element, remainingProps)
					);
				}
			}
			return current;
		} else {
			input[firstProp] = piplelineProjectAdd(
				current[firstProp],
				input[firstProp],
				remainingProps
			);
			return input;
		}
	} else {
		return { ...current, [path]: input[path] };
	}
}

// ===========================================================================
function processProject(inputDoc, stage) {
	// Copy the stage and remove the _id field
	const projectProps = { ...stage };
	delete projectProps._id;

	const props = Object.keys(projectProps);
	const operations = props.map((prop) => {
		if (projectProps[prop] === 0 || projectProps[prop] === false) {
			return false;
		} else if (projectProps[prop] === 1 || projectProps[prop] === true) {
			return true;
		} else {
			throw new Error(
				'$project cannot mix inclusion and exculsion statments'
			);
		}
	});

	if (operations.includes(true) && operations.includes(false)) {
		throw new Error(
			'$project cannot mix inclusion and exculsion statments'
		);
	}

	var outputDoc = {};
	if (operations.includes(true)) {
		// Inclusion
		for (const prop of props) {
			outputDoc = piplelineProjectAdd(outputDoc, inputDoc, prop);
		}
	} else {
		// Exclusion
		outputDoc = { ...inputDoc };

		for (const prop of props) {
			outputDoc = piplelineProjectRemove(outputDoc, prop);
		}
	}

	// Handle the _id field seperatly
	if (inputDoc._id !== undefined) {
		if (stage._id !== undefined) {
			if (stage._id === 0 || stage._id === false) {
				delete outputDoc._id;
			} else {
				outputDoc._id = inputDoc._id;
			}
		} else {
			outputDoc._id = inputDoc._id;
		}
	}

	return outputDoc;
}

// ===========================================================================
async function pipelineProject(input, stage) {
	// Check stage begins with '$'
	if (typeof stage !== 'object') {
		throw new Error('$project must be an object');
	}

	const op = (inputDoc, stage, docIndex, accumulator) => {
		const outputDoc = processProject(inputDoc, stage);
		accumulator.push(outputDoc);
	};

	return await processDocs(input, stage, op);
}

// ===========================================================================
const Operators = {
	$match: pipelineMatch,
	$limit: pipelineLimit,
	$sort: pipelineSort,
	$unwind: pipelineUnwind,
	$replaceRoot: pipelineReplaceRoot,
	$project: pipelineProject,
};

// ===========================================================================
async function processPipeline(input, pipeline) {
	// Both input and pipline must be arrays
	if (!isArray(input) && !(typeof input === 'function')) {
		throw new Error(
			`processPipeline: input must be an array or a function: ${typeof input}`
		);
	}
	if (!isArray(pipeline)) {
		throw new Error('processPipeline: pipeline must be an array');
	}

	var pipelineData = input;

	for (const stage of pipeline) {
		const stageKeys = Object.keys(stage);
		// There should only be one operator key
		if (stageKeys.length !== 1) {
			throw new Error(
				`processPipeline: stage can only have one operator key: ${stageKeys}`
			);
		}

		const stageOperation = stageKeys[0];

		// Check if the stage is valid
		if (!Operators[stageOperation]) {
			throw new Error(
				`processPipeline: unsupported stage operator ${stageKeys[0]}`
			);
		}

		// Execute the stage function, store the output ready for the input of the next stage
		pipelineData = await Operators[stageOperation](
			pipelineData,
			stage[stageOperation]
		);
	}

	return pipelineData;
}

exports.objectMatch = objectMatch;
exports.processPipeline = processPipeline;
