import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Slider, TextField } from '@mui/material';
import { ChevronRight } from 'mdi-material-ui';
import ValueError from './filters/components/filter/ValueError';

export default function BtSlider({
	disabled,
	getAriaValueText,
	marks,
	max,
	min,
	onChange,
	value,
	unit,
	textInput,
	sliderProps,
}) {
	const localValue = useMemo(
		() => {
			const validValue = [];
			// validValue.push(
			// 	value[0] === undefined || value[0] === null ? min : value[0]
			// );
			// validValue.push(
			// 	value[1] === undefined || value[1] === null ? max : value[1]
			// );
			validValue.push(
				value.min === undefined || value.min === null ? min : value.min
			);
			validValue.push(
				value.max === undefined || value.max === null ? max : value.max
			);

			// console.log(validValue);
			return validValue;
		},
		[max, min, value]
	);

	const handleRangeChange = useCallback(
		(event, newValue) => {
			const nullNewValues = [
				newValue[0] === min ? null : newValue[0],
				newValue[1] === max ? null : newValue[1],
			];
			// console.log({ min, max, nullNewValues });

			// onChange(nullNewValues);
			onChange({ min: nullNewValues[0], max: nullNewValues[1] });
		},
		[max, min, onChange]
	);

	const handleTypedRangeChange = useCallback(
		(newValue, position) => {
			// console.log({ value, position });
			const numericValue = _.toNumber(newValue);
			switch (position) {
				case 0:
					handleRangeChange(null, [numericValue, value[1]]);
					break;
				case 1:
					handleRangeChange(null, [value[0], numericValue]);
					break;
				default:
					break;
			}
		},
		[handleRangeChange, value]
	);

	// console.log(
	// 	'min:',
	// 	min,
	// 	'max:',
	// 	max,
	// 	'value:',
	// 	value
	// 	// 'localValue:',
	// 	// localValue
	// );

	// the step of slider and text inputs is calculated by counting the
	// greatest number of decimal places of min and max values
	// TODO - improve with logic similar to that of y axis tick generator
	const step = useMemo(
		() => {
			const maxDecimals = values => {
				if (values) {
					const str = values
						.map(value => Math.abs(value).toString())
						.sort(function(a, b) {
							return b.length - a.length;
						})[0];
					// console.log(str);

					if (!str.includes('.')) {
						return 0;
					} else {
						return str.slice(str.indexOf('.') + 1).length;
					}
				}
			};

			const step = 1 / Math.pow(10, maxDecimals([min, max]));
			// console.log(step);
			return step;
		},
		[max, min]
	);

	if (min === null || max === null) {
		return <ValueError />;
	}

	return (
		<div>
			<div style={{ margin: '0px 7.5px' }}>
				<Slider
					disabled={disabled}
					getAriaValueText={getAriaValueText && getAriaValueText}
					marks={marks}
					max={max}
					min={min}
					step={step}
					onChange={handleRangeChange}
					// onChangeCommitted={handleRangeChange}
					value={localValue}
					valueLabelDisplay={textInput ? 'off' : 'on'}
					// disableSwap
					{...sliderProps}
				/>
			</div>

			{textInput && (
				<div
					style={{
						// marginBottom: '30px',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginLeft: 5,
						marginRight: 5,
					}}
				>
					{[0, 'spacer', 1].map(
						position =>
							position === 'spacer' ? (
								<ChevronRight key={position} />
							) : (
								<TextField
									disabled={disabled}
									style={{ maxWidth: 100, minWidth: 80 }}
									key={position}
									value={
										localValue[position] !== 0
											? localValue[position]
											: ''
									}
									onChange={event => {
										handleTypedRangeChange(
											event.target.value,
											position
										);
									}}
									variant="standard"
									label={unit && unit}
									inputProps={{
										step: step,
										min: min,
										max: max,
										type: 'number',
										'aria-labelledby': 'input-slider',
									}}
								/>
							)
					)}
				</div>
			)}
		</div>
	);
}

BtSlider.propTypes = {
	disabled: PropTypes.bool,
	getAriaValueText: PropTypes.func,
	marks: PropTypes.func,
	max: PropTypes.number,
	min: PropTypes.number,
	onChange: PropTypes.func,
	value: PropTypes.object,
	unit: PropTypes.string,
	textInput: PropTypes.bool,
	sliderProps: PropTypes.object,
};
