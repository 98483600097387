import React from 'react';
import PropTypes from 'prop-types';

import { useTheme, useMediaQuery } from '@mui/material';
import BtDialogHeading from '../generic/BtDialogHeading';

export default function WizardHeading({
	children,
	hideCancel,
	onCancelClick,
	subtitle,
	title,
}) {
	const theme = useTheme();
	const screenDownSm = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<>
			<BtDialogHeading
				onClose={onCancelClick}
				title={title}
				subtitle={subtitle}
				hideClose={!screenDownSm || hideCancel}
				closeVerb="Cancel"
			/>
			{children && children}
		</>
	);
}

WizardHeading.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	hideCancel: PropTypes.bool,
	onCancelClick: PropTypes.func,
	subtitle: PropTypes.string,
	subtitleProps: PropTypes.object,
	title: PropTypes.string,
	titleProps: PropTypes.object,
};
