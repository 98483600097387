import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import { Button } from '@mui/material';
import { format } from 'date-fns';
import produce from 'immer';
import RemoveIcon from '@mui/icons-material/PersonRemove';

import beacon_admin_options from './BeaconAdminOptions';
import beacon_admin_nav_item from './BeaconAdminNavItem';

import {
	a11yProps,
	BtTab,
	BtTabBar,
	BtTabPanel,
	BtTabView,
} from '../../components/generic/BtTabView';
import BtError from '../../components/generic/BtError';
import {
	BtFormContainer,
	BtFormLateralContainer,
} from '../../components/generic/forms';
import BtListComposer from '../../components/generic/BtListComposer';
import BtLoading from '../../components/generic/BtLoading';
import BtTags from '../../components/generic/BtTags';
import {
	platformAdminOrgGetUser,
	platformAdminOrgRoleAddUser,
	platformAdminOrgRoleDeleteUser,
	platformAdminOrgRoleGetList,
} from '../../API';
import useFetch from '../../hooks/useFetch';
import { useNavContext } from '../../context/ContextManager';

import useGetResourceGroupIdByName from '../../hooks/useGetResourceGroupIdByName';
import BtDetailSection from '../../components/generic/BtDetailSection';
import BtConfirmDialog from '../../components/generic/BtConfirmDialog';
import { useAppContext } from '../../context/ContextManager';

const latContainerStyle = { marginBottom: '1em' };

export default function OrganisationUserEditor({ match }) {
	const rolesRequested = useRef();

	const { appOrg, setActivityIndicator } = useAppContext();

	const resourceGroupId = 'users';

	const [currentTab, setCurrentTab] = useState(0);
	const [deleteDialog, setDeleteDialog] = useState(false);
	const [roles, setRoles] = useState();
	const [user, setUser] = useState();
	const [removing, setRemoving] = useState(false);

	const {
		loading: loadingRoles,
		error: rolesError,
		request: getRoles,
	} = useFetch(platformAdminOrgRoleGetList, rolesResp =>
		setRoles(rolesResp.map(({ uuid, name }) => ({ uuid, name })))
	);

	const {
		loading: loadingUser,
		error: userError,
		request: getUser,
	} = useFetch(platformAdminOrgGetUser, setUser);

	const userId = useMemo(() => match.params.user_uuid, [match]);
	const orgId = useMemo(() => match.params.org_uuid, [match]);

	// Set contextual navigation items and breadcrumbs
	const { setContextualNav, setBreadcrumbs } = useNavContext();
	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'BeaconAdmin', link: '/BeaconAdmin' },
				{ text: 'Organisations', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	//Boilerplate for future implementation set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([
				...beacon_admin_nav_item,
				...beacon_admin_options,
			]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	useEffect(
		() => {
			if (!roles && !rolesRequested.current) {
				getRoles({ orgUuid: orgId });
				rolesRequested.current = true;
			}
		},
		[getRoles, roles]
	);

	useEffect(
		() => {
			console.log('userId', userId);
			getUser({ orgUuid: orgId, userUuid: userId });
		},
		[getUser, userId]
	);

	useEffect(() => setActivityIndicator(removing), [
		setActivityIndicator,
		removing,
	]);

	const loading = useMemo(() => loadingRoles || loadingUser, [
		loadingRoles,
		loadingUser,
	]);

	const handleRolesUpdate = useCallback(
		(role, updatedRoles) =>
			setUser(
				produce(draft => {
					draft.roles = [...updatedRoles];
				})
			),
		[]
	);

	const handleTagsChange = useCallback(newTags => {
		setUser(
			produce(draft => {
				draft.tags = newTags;
			})
		);
	}, []);

	useEffect(
		() => {
			if (rolesError) {
				console.log(rolesError);
			}
		},
		[rolesError]
	);

	if (userError) {
		console.log(userError);
		return (
			<BtError
				title="Retrieval Error"
				description="An error occurred when attempting to retrieve the user's information"
			/>
		);
	}

	return (
		<>
			<BtTabView>
				<BtFormContainer
					title={user?.user_name}
					style={{ maxWidth: 680 }}
					header={
						<BtLoading
							loading={loading || removing}
							LoadingComponent={<div style={{ height: 50 }} />}
						>
							<BtTabBar
								currentTab={currentTab}
								onTabChange={(event, selectedTab) =>
									setCurrentTab(selectedTab)
								}
								style={{
									transform: 'translateY(-1px)',
									marginTop: 10,
								}}
							>
								<BtTab label="Details" {...a11yProps(0)} />
								<BtTab label="Roles" {...a11yProps(1)} />
							</BtTabBar>
						</BtLoading>
					}
				>
					<BtLoading loading={loading || removing}>
						<BtTabPanel currentTab={currentTab} index={0}>
							<BtFormLateralContainer style={latContainerStyle}>
								<BtDetailSection
									label="First Name"
									data={user?.first_name}
								/>
								<BtDetailSection
									label="Last Name"
									data={user?.last_name}
								/>
							</BtFormLateralContainer>
							<BtFormLateralContainer style={latContainerStyle}>
								<BtDetailSection
									label="User Name"
									data={user?.user_name}
								/>
								<BtDetailSection
									label="Initials"
									data={user?.initials}
								/>
							</BtFormLateralContainer>
							<BtFormLateralContainer style={latContainerStyle}>
								<BtDetailSection
									label="Email Address"
									data={user?.email}
								/>
								<BtDetailSection
									label="Last Logged In"
									data={format(
										user?.last_login_timestamp || 0,
										'dd MMM yyyy, hh:mm a'
									)}
								/>
							</BtFormLateralContainer>
							<BtDetailSection
								label="Tags"
								Component={
									<BtTags
										resourceId={user?.uuid}
										resourceGroupId={resourceGroupId}
										onTagsChange={handleTagsChange}
										initialTags={user?.tags}
									/>
								}
							/>
							<Button
								color="error"
								onClick={() => setDeleteDialog(true)}
								startIcon={<RemoveIcon />}
								style={{ marginTop: '1.5em' }}
								variant="outlined"
								disableElevation
							>
								Remove User
							</Button>
						</BtTabPanel>
						<BtTabPanel currentTab={currentTab} index={1}>
							{!rolesError ? (
								<BtListComposer
									allItems={roles}
									confirmDeletion
									defaultItems={user?.roles}
									deleteable
									deleteVerb="Remove"
									primaryField="name"
									subject="roles"
									onItemAdd={role =>
										platformAdminOrgRoleAddUser({
											orgUuid: orgId,
											roleUuid: role.uuid,
											userUuid: userId,
										})
									}
									onItemAddSuccess={handleRolesUpdate}
									onItemDelete={role =>
										platformAdminOrgRoleDeleteUser({
											orgUuid: orgId,
											roleUuid: role.uuid,
											userUuid: userId,
										})
									}
									onItemDeleteSuccess={handleRolesUpdate}
								/>
							) : (
								<BtError
									title="Retrieval Error"
									description="An error occurred when attempting to retrieve the roles for this organisation."
								/>
							)}
						</BtTabPanel>
					</BtLoading>
				</BtFormContainer>
			</BtTabView>
			<BtConfirmDialog
				action={() => {
					// TODO - Add endpoint call
					setDeleteDialog(false);
					setRemoving(true);
				}}
				isDestructive
				open={deleteDialog}
				onClose={() => setDeleteDialog(false)}
				title="Remove User?"
				prompt={
					`Are you sure you want to remove ${user?.first_name} ${
						user?.last_name
					}` +
					`${
						appOrg ? ' from ' + appOrg.name : ''
					}? You will need to re-invite them should they ` +
					'require access in future.'
				}
				verb="Remove User"
				ActionIcon={<RemoveIcon />}
			/>
		</>
	);
}
