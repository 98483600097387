import BtDialog from '../../../BtDialog';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { v4 as uuidV4 } from 'uuid';

import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import { tileVariants } from '../../tileConstants';
import ActionButtonContainer from '../../../ActionButtonContainer';
import ContainerButtonConfig from './components/ContainerButtonConfig';
import { ScreensContext } from '../../ScreensContext';
import { TileContext } from '../TileContext';
import { useAppContext } from '../../../../../context/ContextManager';

export const ContainerAddTileDialog = ({
	onClose,
	onContainerChange,
	nativeMode,
}) => {
	const [childTileProps, setChildTileProps] = useState({
		...tileVariants.button,
	});
	const [isFormValid, setIsFormValid] = useState(false);

	const { screens, currentScreen } = useContext(ScreensContext);
	const { tileData } = useContext(TileContext);
	const { deviceType } = useAppContext();

	const onAddTile = () => {
		console.log({ childTileProps });
		const newTileUuid = uuidV4();
		// const screenUpdate = structuredClone(screenData);
		const screenUpdate = structuredClone(screens[currentScreen]);

		// add the new tile to the child array
		screenUpdate.tiles[tileData.uuid].data.children.push(newTileUuid);

		// add the tile to the tiles array
		screenUpdate.tiles[newTileUuid] = childTileProps;

		// change the container layout to reflect the new number of child tiles
		screenUpdate.tiles[tileData.uuid].data.container_layout = {
			h: screenUpdate.tiles[tileData.uuid].data.children.length,
			w: 1,
		};

		onContainerChange({ screenUpdate });
	};

	return (
		<>
			<DialogTitle>Add a button tile to the container</DialogTitle>
			<DialogContent
				sx={{
					width: deviceType === 'desktop' ? 600 : '100%',
					paddingBottom: 0,
				}}
			>
				<div
				// style={{
				// 	display: 'flex',
				// 	alignItems: 'center',
				// 	// width: 300,
				// 	paddingBottom: 0,
				// }}
				>
					<ContainerButtonConfig
						childTileProps={childTileProps}
						setChildTileProps={setChildTileProps}
						setIsFormValid={setIsFormValid}
						isNewTile
					/>
				</div>
			</DialogContent>
			<DialogActions>
				<ActionButtonContainer>
					<Button
						color="primary"
						onClick={onClose}
						variant="outlined"
						disableElevation
						// style={{ color: 'white' }}
					>
						Cancel
					</Button>
					<Button
						disableElevation
						style={{ color: 'white' }}
						variant="contained"
						// color="error"
						onClick={onAddTile}
						disabled={!isFormValid}
					>
						add tile
					</Button>
				</ActionButtonContainer>
			</DialogActions>
		</>
	);
};

ContainerAddTileDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
	onContainerChange: PropTypes.func.isRequired,
};
