import React from 'react';

// MUI ICONS
// import Dashboard from '@mui/icons-material/Dashboard';
// import InsightsIcon from '@mui/icons-material/Insights';
// import StorageIcon from '@mui/icons-material/Storage';
import HubIcon from '@mui/icons-material/Hub';

const developer_nav_item = [
	{
		id: 'Data Manager',
		text: 'Data Manager',
		route: '/DataManager',
		icon: <HubIcon />,
		requiredPermissionsAll: {
			dataManager: ['DatasetView'],
		},
	},
];

export default developer_nav_item;
