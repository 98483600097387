import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useNavContext } from '../../context/ContextManager';
import { useAppContext } from '../../context/ContextManager';

import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import SaveIcon from '@mui/icons-material/SaveAlt';
import CloseIcon from '@mui/icons-material/Close';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import TextField from '@mui/material/TextField';

import NotificationDialog from '../../components/dialogs/NotificationDialog';

// Data for landing page selection table and contextual navigation
import IoT_options from './IoTOptions';

import IoT_breadcrumbs from './IoTBreadcrumbs';

import IoT_nav_item from './IoTNavItem';

const styles = makeStyles(theme => ({
	button_row: {},
	root: {
		width: '100%',
		minWidth: 200,
		'& .MuiTextField-root': {
			margin: '10px 0',
		},
	},
	config_paper: {
		padding: 10,
		margin: '10px 0',
	},
	description_text: {
		height: 200,
		color: 'red',
	},
	textField: {
		backgroundColor: '#f5f5f5',
		margin: '0px 0',
		fontSize: '2rem',
	},
	tabPanel: {
		margin: 0,
		padding: 0,
		marginBottom: 16,
		backgroundColor: '#f5f5f5',
		borderRadius: 8,
	},
	tabsAppBar: {
		backgroundColor: '#ffffff',
		boxShadow: 'none',
	},
	actionButton: {
		minWidth: '120px',
	},
	deviceSelect: {
		margin: '16px 0',
		minWidth: 200,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	fileInfoBox: {
		padding: '8px 16px',
	},
	errorText: {
		color: 'red',
	},
}));

function getDeviceList(orgUuid) {
	return new Promise((resolve, reject) => {
		var req_url = process.env.REACT_APP_API_SERVER_URL + '/v1/device_type';

		Auth.currentSession().then(user_session => {
			let user_token = user_session.getAccessToken();
			fetch(req_url, {
				method: 'GET',
				headers: {
					Authorization: user_token.jwtToken,
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Organisation: orgUuid,
				},
			})
				.then(res => res.json())
				.then(response => {
					// The API will respond with OK if a config with this name is already present
					if (response['result'] === 'OK') {
						resolve(response['device_types']);
					} else {
						alert('Server connection error.');
						reject('');
					}
				})
				.catch(error => {
					reject('');
				});
		});
	});
}

export default function AddFirmware() {
	const classes = styles();
	const [deviceTypeList, setDeviceTypeList] = useState([]);
	const [device, setDeviceType] = useState('');
	const [description, setDescription] = useState('');
	const [notes, setNotes] = useState('');
	const [version, setVersion] = useState('');
	const [userFilename, setUserFilename] = useState('');
	const history = useHistory();
	const [commsActive, setCommsActive] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [filenameSet, setFilenameSet] = useState(true);
	const [showComplete, setShowComplete] = useState(false);

	const [showError, setShowError] = useState(false);
	const [errorText, setErrorText] = useState('');

	const [fileDetails, setFileDetails] = useState({});

	const [filename, setFilename] = useState('');
	const [filesize, setFilesize] = useState('');
	const [filedate, setFiledate] = useState('');

	const { appOrg } = useAppContext();

	const handleUserFilenameChange = event => {
		setUserFilename(event.target.value);
	};
	const handleVersionChange = event => {
		setVersion(event.target.value);
	};
	const handleChangeDevice = event => {
		setDeviceType(event.target.value);
	};
	const handleDescriptionChange = event => {
		setDescription(event.target.value);
	};
	const handleNotesChange = event => {
		setNotes(event.target.value);
	};

	const handleCancel = event => {
		history.goBack();
	};
	const handleComplete = () => {
		history.goBack();
	};

	const { setContextualNav } = useNavContext();
	const { setBreadcrumbs } = useNavContext();

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				...IoT_breadcrumbs,
				{ text: 'Files', link: '/Files' },
				{ text: 'Add New Firmware File', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([...IoT_nav_item, ...IoT_options]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	useEffect(() => {
		var req_url = process.env.REACT_APP_API_SERVER_URL + '/v1/organisation';

		Auth.currentSession().then(user_session => {
			let user_token = user_session.getAccessToken();
			fetch(req_url, {
				method: 'GET',
				headers: {
					Authorization: user_token.jwtToken,
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Organisation: appOrg.uuid,
				},
			})
				.then(res => res.json())
				.then(response => {
					// The API will respond with OK if a config with this name is already present
					if (response['result'] === 'OK') {
						getDeviceList(appOrg.uuid)
							.then(device_type_list => {
								setDeviceTypeList(device_type_list);
								if (device_type_list.length > 0)
									setDeviceType(device_type_list[0].uuid);
								setIsLoading(false);
							})
							.catch(res => {
								alert('Failed to load dev list');
								setIsLoading(false);
							});
					} else {
						alert('Server connection error.');
						setIsLoading(false);
					}
				})
				.catch(error => {
					alert('Server Error!');
				});
		});
	}, []);

	const handleFileSelect = event => {
		console.log(event.target.files);
		if (event.target.files.length !== 0) {
			setFilename(event.target.files[0].name);
			setFilesize(event.target.files[0].size);
			setFiledate(event.target.files[0].lastModifiedDate);

			setFileDetails(event.target.files[0]);
			setFilenameSet(false);
		}
	};

	const handleSave = event => {
		// TODO: do a better check than this
		if (userFilename === '') {
			setShowError(true);
			setErrorText('Please enter a valid file name');
			return;
		}

		// TODO: do a better check than this
		if (version === '') {
			setShowError(true);
			setErrorText('Please enter a valid version string');
			return;
		}

		setShowError(false);
		setErrorText('');

		var req_url = process.env.REACT_APP_API_SERVER_URL + '/v1/file';
		var formData = new FormData();
		formData.append('file', fileDetails);

		formData.append('filename', userFilename);
		formData.append('version_str', version);
		formData.append('description', description);
		formData.append('notes', notes);
		formData.append('file_type', 'firmware');
		formData.append('status', 'active');
		formData.append('device_type', device);

		setCommsActive(true);

		Auth.currentSession().then(user_session => {
			let user_token = user_session.getAccessToken();
			fetch(req_url, {
				body: formData,
				method: 'post',
				headers: {
					Authorization: user_token.jwtToken,
					Accept: 'application/json',
					//'Content-Type': 'application/json'
					//'Content-Type': 'multipart/form-data' // Do not set the header type as this will do done automatically
					Organisation: appOrg.uuid,
				},
			})
				.then(response => {
					setCommsActive(false);
					setShowComplete(true);
				})
				.catch(error => {
					setShowError(true);
					setErrorText('File upload error');
					setCommsActive(false);
				});
		});
	};

	return (
		<div className={classes.root}>
			{isLoading && (
				<Paper>
					<p>Loading...</p>
					<CircularProgress size={'120px'} />
				</Paper>
			)}

			{!isLoading && (
				<form className={classes.root} noValidate autoComplete="off">
					<Paper className={classes.config_paper}>
						<h4>Upload new Firmware File</h4>

						<Divider />

						<div>
							<Button
								variant="outlined"
								startIcon={<InsertDriveFileOutlinedIcon />}
								className={classes.actionButton}
								component="label"
							>
								Select File
								<input
									type="file"
									name="myFile"
									onChange={handleFileSelect}
									style={{ display: 'none' }}
								/>
							</Button>
							{filename && (
								<Box className={classes.fileInfoBox}>
									<Typography variant="subtitle2">
										File Name: {filename}
									</Typography>
									<Typography variant="subtitle2">
										File Size: {filesize}
									</Typography>
									<Typography variant="subtitle2">
										File Date:{' '}
										{new Date(filedate).toLocaleString()}
									</Typography>
								</Box>
							)}
						</div>

						<div>
							<Typography variant="h5">File Name:</Typography>
							<TextField
								variant="outlined"
								fullWidth
								className={classes.textField}
								onChange={handleUserFilenameChange}
							/>
						</div>

						<div>
							<Typography variant="h5">
								Version String:
							</Typography>
							<TextField
								variant="outlined"
								fullWidth
								className={classes.textField}
								onChange={handleVersionChange}
							/>
						</div>

						<div>
							<Typography variant="h5">Device Type:</Typography>
							<FormControl
								variant="outlined"
								className={classes.formControl}
							>
								<Select
									labelId="demo-simple-select-outlined-label"
									id="demo-simple-select-outlined"
									value={device}
									onChange={handleChangeDevice}
									className={classes.deviceSelect}
								>
									{deviceTypeList.map(
										(device_type, index) => (
											<MenuItem
												key={index}
												value={device_type.uuid}
											>
												{device_type.device_name}
											</MenuItem>
										)
									)}
								</Select>
							</FormControl>
						</div>

						<div>
							<Typography variant="h5">
								Firmware Description:
							</Typography>
							<TextField
								multiline
								variant="outlined"
								fullWidth
								className={classes.textField}
								onChange={handleDescriptionChange}
							/>
						</div>

						<div>
							<Typography variant="h5">Notes:</Typography>
							<TextField
								multiline
								variant="outlined"
								fullWidth
								className={classes.textField}
								onChange={handleNotesChange}
							/>
						</div>

						{showError && (
							<Box>
								<Typography
									variant="h6"
									className={classes.errorText}
								>
									Error:
									{errorText}
								</Typography>
							</Box>
						)}

						<Divider />

						<Box
							display="flex"
							flexDirection="row-reverse"
							p={1}
							m={1}
							bgcolor="background.paper"
						>
							<Button
								variant="outlined"
								startIcon={<SaveIcon />}
								onClick={handleSave}
								className={classes.actionButton}
								disabled={filenameSet}
							>
								Save
							</Button>

							<Button
								variant="outlined"
								startIcon={<CloseIcon />}
								onClick={handleCancel}
								className={classes.actionButton}
							>
								Cancel
							</Button>
						</Box>
					</Paper>

					<Backdrop className={classes.backdrop} open={commsActive}>
						<Typography variant="h3">Uploading File...</Typography>
						<CircularProgress color="inherit" />
					</Backdrop>

					<NotificationDialog
						setOpen={showComplete}
						onClose={handleComplete}
						title="File Upload"
						text="The file has be uploaded successfully"
						icon=""
					/>
				</form>
			)}
		</div>
	);
}
