import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	CircularProgress,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import BackArrowIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ForwardArrowIcon from '@mui/icons-material/ArrowForward';

const Container = styled('div')(({ theme }) => ({
	alignContent: 'center',
	display: 'flex',
	flexDirection: 'row-reverse',
	gap: 10,
	justifyContent: 'space-between',
	padding: '1em',
	width: '100%',
	backgroundColor: theme.palette.background.paper,
	zIndex: 100,
}));

const NavBtnContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row-reverse',
	width: 'auto',
	justifyContent: 'flex-end',

	[theme.breakpoints.down('sm')]: {
		width: '100%',

		'& > button': {
			width: '100%',
			maxWidth: '50%',
		},
	},

	[theme.breakpoints.down(300)]: {
		width: '100%',
		flexDirection: 'column',
		alignContent: 'center',

		'& > button': {
			width: '100%',
			maxWidth: '100%',
			margin: '0 !important',

			'&.prevButton': {
				marginTop: '10px !important',
			},
		},
	},
}));

export default function WizardButtons({
	cancelLabel,
	canGoPrevious,
	canGoForward,
	disabled,
	hideCancel,
	hideNext,
	hidePrevious,
	isFirstStep,
	isLastStep,
	nextLabel,
	onCancelClick,
	onNextClick,
	onPreviousClick,
	previousLabel,
	sending,
	...props
}) {
	const theme = useTheme();
	const screenDownSm = useMediaQuery(theme.breakpoints.down('sm'));

	const _nextLabel = useMemo(
		() => {
			if (nextLabel) return nextLabel;
			return isLastStep ? 'Confirm' : 'Next';
		},
		[nextLabel, isLastStep]
	);

	return (
		<Container {...props}>
			<NavBtnContainer>
				{!hideNext && (
					<Button
						//
						variant="contained"
						startIcon={
							sending ? (
								<CircularProgress size={20} />
							) : (
								isLastStep && <CheckIcon />
							)
						}
						style={{ marginLeft: '5px' }}
						endIcon={!isLastStep && <ForwardArrowIcon />}
						onClick={onNextClick}
						disableElevation
						disabled={!canGoForward || disabled || sending}
					>
						{_nextLabel}
					</Button>
				)}
				{!isFirstStep &&
					!hidePrevious && (
						<Button
							className="prevButton"
							//
							startIcon={<BackArrowIcon />}
							onClick={onPreviousClick}
							style={{ marginRight: '5px' }}
							disabled={!canGoPrevious || disabled || sending}
						>
							{previousLabel ?? 'Previous'}
						</Button>
					)}
			</NavBtnContainer>
			{onCancelClick &&
				!hideCancel &&
				!screenDownSm && (
					<Button
						//
						startIcon={<CloseIcon />}
						onClick={onCancelClick}
						disableElevation
						variant="outlined"
						disabled={disabled || sending}
					>
						{cancelLabel ?? 'Cancel'}
					</Button>
				)}
		</Container>
	);
}

WizardButtons.defaultProps = {
	canGoPrevious: true,
	canGoForward: true,
};

WizardButtons.propTypes = {
	cancelLabel: PropTypes.string,
	canGoPrevious: PropTypes.bool,
	canGoForward: PropTypes.bool,
	disabled: PropTypes.bool,
	hideCancel: PropTypes.bool,
	hideNext: PropTypes.bool,
	hidePrevious: PropTypes.bool,
	isFirstStep: PropTypes.bool,
	isLastStep: PropTypes.bool,
	nextLabel: PropTypes.string,
	onClose: PropTypes.func,
	onNextClick: PropTypes.func,
	onPreviousClick: PropTypes.func,
	previousLabel: PropTypes.string,
	sending: PropTypes.bool,
};
