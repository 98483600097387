import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import CancelIcon from '@mui/icons-material/Close';
import InviteIcon from '@mui/icons-material/ForwardToInbox';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import {
	BtForm,
	BtFormActionButtons,
	BtFormActionsContainer,
	BtFormCheckbox,
	BtFormContent,
	BtFormLateralContainer,
	BtFormTextField,
} from '../../../components/generic/forms';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import useFetch from '../../../hooks/useFetch';
import { useHistory } from 'react-router-dom';
import { user_names_regex } from '../../../utils/regexLibrary';
import { userAdminPost } from '../../../API';

const schema = yup.object({
	firstName: yup
		.string()
		.required()
		.matches(user_names_regex.regex, user_names_regex.error_text, {
			excludeEmptyString: true,
		})
		.label('First Name'),
	lastName: yup
		.string()
		.required()
		.matches(user_names_regex.regex, user_names_regex.error_text, {
			excludeEmptyString: true,
		})
		.label('Surname'),
	email: yup
		.string()
		.email()
		.required()
		.label('Email Address'),
	mobile_required: yup.boolean().label('Mobile Required'),
	groups: yup.array().label('Groups'),
	roles: yup.array().label('Roles'),
});

const blankDefaults = {
	firstName: '',
	lastName: '',
	email: '',
	mobile_required: false,
};

export default function UsersInvite({ onClose }) {
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const inviteApi = useFetch(userAdminPost);

	const [sending, setSending] = useState(false);

	const handleSubmit = useCallback(
		async values => {
			setSending(true);
			const { email, firstName, lastName, mobile_required } = values;

			const userInfo = {
				email,
				first_name: firstName,
				last_name: lastName,
				mobile_required,
			};

			// const { data, ok } = await inviteApi.request(userInfo);
			const inviteRes = await inviteApi.request(userInfo);

			if (!inviteRes.ok) {
				enqueueSnackbar('Failed to send user invite', {
					variant: 'error',
				});
				setSending(false);
				return;
			}
			console.log(inviteRes.data);
			enqueueSnackbar(
				inviteRes.data?.data?.message || 'User has been invited',
				{
					variant: 'success',
				}
			);
			history.push(`/Users/Edit/${inviteRes.data?.data?.user_uuid}`, {
				params: {
					newUser: true,
					tempPassword: inviteRes.data.data.temp_password,
				},
			});
		},
		[enqueueSnackbar, history, inviteApi]
	);

	return (
		<BtForm
			defaultValues={blankDefaults}
			validationSchema={schema}
			sending={sending}
			onSubmit={handleSubmit}
		>
			<DialogTitle>Invite a User</DialogTitle>
			<DialogContent>
				<BtFormContent>
					<BtFormLateralContainer>
						<BtFormTextField name="firstName" label="First Name" />
						<BtFormTextField name="lastName" label="Surname" />
					</BtFormLateralContainer>
					<BtFormTextField name="email" label="Email Address" />
					<BtFormCheckbox
						name="mobile_required"
						label="This user is required to provide a mobile number"
					/>
				</BtFormContent>
			</DialogContent>
			<DialogActions>
				<BtFormActionsContainer>
					<BtFormActionButtons
						alwaysAllowDestructive
						DestructiveIcon={<CancelIcon />}
						destructiveVerb="Cancel"
						onDestroy={onClose}
						showDestructivePrompt={false}
						SubmitIcon={<InviteIcon />}
						submitVerb="Send Invite"
					/>
				</BtFormActionsContainer>
			</DialogActions>
		</BtForm>
	);
}

UsersInvite.propTypes = {
	onClose: PropTypes.func.isRequired,
};
