const yup = require('yup');

const resourceGroupGetSchema = yup
	.object({
		resources: yup
			.array()
			.of(
				yup
					.object({
						id: yup.string().required(),
						name: yup.string().required(),
					})
					.nullable()
			)
			.required(),
	})
	.required();

module.exports = {
	resourceGroupGetSchema,
};
