import { Chip, DialogContent, DialogTitle } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import filterOptions from './utils/filterOptions';
import { BtFilterSelectInput } from './components/BtFilterSelectInput';
import {
	BtFilterSelectListDisplay,
	BtFilterSelectNoItems,
} from './components/BtFilterSelectOptionsDisplay';
import { FLAT, GROUPED } from './utils/constants';
import PropTypes from 'prop-types';
import evalOptionsType from './utils/evalOptionsType';
import BtDialogHeading from '../BtDialogHeading';
import handleSelectItem from './utils/handleSelectItem';
import normaliseSingularSelect from './utils/normaliseSingularSelect';

const ChipItem = ({ disabled, onClick, selected, title }) => {
	return (
		<Chip
			sx={{ margin: '5px' }}
			label={title}
			clickable
			disabled={disabled}
			onClick={onClick}
			color={selected ? 'primary' : 'default'}
		/>
	);
};

export default function BtFilterSelect({
	dense,
	groupedOptions,
	multiple,
	onChange,
	options,
	RenderItem,
	title,
	sortOptions,
	placeholderText,
	disableFilter,
}) {
	const [searchTerm, setSearchTerm] = useState('');
	const [optionsData, setOptionsData] = useState([]);

	if (options && groupedOptions) {
		console.warn(
			`Too many props passed, received 'options' and 'groupedOptions', 'groupedOptions' will be ignored`
		);
	}

	if (!options && !groupedOptions) {
		console.warn(
			`Missing props, received neither 'options' or 'groupedOptions'.`
		);
	}

	// confirm if the options should be grouped
	const optionsType = useMemo(
		() => {
			return evalOptionsType(options, groupedOptions);
		},
		[groupedOptions, options]
	);

	// declare the options data, dependent on the optionsType and multiple flags
	useEffect(
		() => {
			switch (optionsType) {
				case FLAT:
					setOptionsData(
						multiple
							? options
							: normaliseSingularSelect(options, optionsType)
					);
					break;
				case GROUPED:
					setOptionsData(
						multiple
							? groupedOptions
							: normaliseSingularSelect(
									groupedOptions,
									optionsType
							  )
					);
					break;
				default:
					setOptionsData([]);
			}
		},
		[groupedOptions, multiple, options, optionsType]
	);

	// the filtered values
	const filteredOptions = useMemo(
		() => {
			if (optionsData)
				return filterOptions(searchTerm, optionsData, optionsType);
		},
		[searchTerm, optionsData, optionsType]
	);

	const handleSelectOption = useCallback(
		(option, groupId) => {
			// console.log({ option, groupId });
			// update the selected values
			const newOptionsData = handleSelectItem(
				optionsData,
				option.id,
				multiple,
				groupId
			);
			// dispatch the onChange
			if (multiple) {
				onChange(newOptionsData.options);
			} else {
				onChange(newOptionsData.singleSelectedOption);
			}
			// set the internal state
			setOptionsData(newOptionsData.options);
		},
		[multiple, onChange, optionsData]
	);

	return (
		<>
			{(title || !disableFilter) && (
				<DialogTitle>
					{title && <BtDialogHeading title={title} />}
					{!disableFilter && (
						<BtFilterSelectInput
							error={false}
							disabled={false}
							onChange={setSearchTerm}
							placeholderText={placeholderText}
						/>
					)}
				</DialogTitle>
			)}

			<DialogContent>
				{filteredOptions?.length === 0 ? (
					<BtFilterSelectNoItems />
				) : (
					<BtFilterSelectListDisplay
						optionsType={optionsType}
						onSelectOption={handleSelectOption}
						options={filteredOptions}
						dense={dense}
						RenderItem={RenderItem}
						sortOptions={sortOptions}
					/>
				)}
			</DialogContent>
		</>
	);
}

const optionPropType = PropTypes.shape({
	disabled: PropTypes.bool,
	id: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	selected: PropTypes.bool,
	subtitle: PropTypes.string,
});

const groupedOptionsPropType = PropTypes.shape({
	groupName: PropTypes.string.isRequired,
	id: PropTypes.string,
	options: PropTypes.arrayOf(optionPropType).isRequired,
});

BtFilterSelect.defaultProps = {
	dense: false,
	multiple: false,
	disableFilter: false,
};

BtFilterSelect.propTypes = {
	dense: PropTypes.bool,
	groupedOptions: PropTypes.arrayOf(groupedOptionsPropType),
	multiple: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	options: PropTypes.arrayOf(optionPropType),
	RenderItem: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.func,
		PropTypes.element,
	]),
	sortOptions: PropTypes.bool,
	title: PropTypes.string,
	placeholderText: PropTypes.string,
	disableFilter: PropTypes.bool,
};
