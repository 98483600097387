import React, { createContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import useAvailableToUser from '../../../hooks/useAvailableToUser';
import { buttonTypes } from './tileConstants';

const ScreensContext = createContext();

const ScreensContextProvider = ({
	children,
	screenGroupKey,
	screenAdminUuid,
}) => {
	const [editEnabled, setEditEnabled] = useState(false);
	const [screens, setScreens] = useState();
	const [currentScreen, setCurrentScreen] = useState(0);
	const [nativeEditMode, setNativeEditMode] = useState(false);
	const [isEditingDisabled, setIsEditingDisabled] = useState(false);
	const [loading, setLoading] = useState(true);
	const adminMode = useMemo(() => (screenAdminUuid ? true : false), [
		screenAdminUuid,
	]);
	const [columns, setColumns] = useState();
	const [cellSize, setCellSize] = useState(100);

	const checkAvailability = useAvailableToUser();

	// useEffect(
	// 	() => {
	// 		if (screens) console.log(screens[currentScreen], currentScreen);
	// 	},
	// 	[currentScreen, screens]
	// );

	const userPermissions = useMemo(
		() => {
			const perms = {};
			[
				'ScreenEdit',
				'ScreenGroupEdit',
				'ScreenCreate',
				'ScreenAdminEdit',
			].forEach(
				perm =>
					(perms[perm] = checkAvailability({
						requiredPermissionsAll: {
							screens: [perm],
						},
					}).available)
			);

			return perms;
		},
		[checkAvailability]
	);

	const userButtonPerms = useMemo(
		() => {
			const result = {};
			buttonTypes.forEach(type => {
				const typeAvailability = checkAvailability({
					requiredPermissionsAll: type.requiredPermissionsAll,
					validSubscriptionsAny: type.validSubscriptionsAny,
				});
				result[type.value] = typeAvailability;
			});
			return result;
		},
		[checkAvailability]
	);

	useEffect(
		() => {
			if (screens) {
				if (screens?.length > 0)
					if (adminMode) {
						setIsEditingDisabled(!userPermissions.ScreenAdminEdit);
						console.log({ adminMode, userPermissions });
					} else if (
						screens[currentScreen]?.ownerType === 'Organisation'
					) {
						setIsEditingDisabled(true);
					} else if (userPermissions.ScreenEdit) {
						setIsEditingDisabled(false);
					} else if (!userPermissions.ScreenEdit) {
						setIsEditingDisabled(true);
					}
			}
		},
		[adminMode, currentScreen, screens, userPermissions]
	);

	return (
		<ScreensContext.Provider
			value={{
				screenGroupKey,
				screenAdminUuid,
				editEnabled,
				setEditEnabled,
				adminMode,
				screens,
				setScreens,
				currentScreen,
				setCurrentScreen,
				isEditingDisabled,
				nativeEditMode,
				setNativeEditMode,
				loading,
				setLoading,
				userPermissions,
				userButtonPerms,
				columns,
				setColumns,
				cellSize,
				setCellSize,
			}}
		>
			{children}
		</ScreensContext.Provider>
	);
};

ScreensContextProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

export { ScreensContext, ScreensContextProvider };
