const yup = require('yup');

const firmwareVersionGetSchema = yup.object({
	description: yup.string(),
	file_type: yup.string().required(),
	filename: yup.string().required(),
	device_type: yup
		.string()
		.uuid()
		.required(),
	version_str: yup.string().required(),
	file_uuid: yup
		.string()
		.uuid()
		.required(),
	create_timestamp: yup.number().required(),
	resource_uuid: yup
		.string()
		.uuid()
		.required(),
	notes: yup.string(),
	size: yup.number().required(),
	status: yup.string().required(),
});

module.exports = {
	firmwareVersionGetSchema,
};
