import { apiClient } from '../api_client';

import {
	dataViewGetListSchema,
	dataViewGetSchema,
	dataViewPostSchema,
} from '../validations/dataViewsValidation';

const baseUrl = 'data_view';
const OK = 'OK';

// Get a full list of Data Views
export async function dataViewGetList() {
	try {
		const response = await apiClient({ method: 'get', url: baseUrl });

		if (response) {
			if (response.result === OK) {
				return await dataViewGetListSchema.validate(
					response.data_views
				);
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

// Get a single detailed Data View by uuid
export async function dataViewGet({ dataViewUuid }) {
	const url = `${baseUrl}/${dataViewUuid}`;

	try {
		const response = await apiClient({ method: 'get', url });

		if (response) {
			if (response.result === OK) {
				return await dataViewGetSchema.validate(response.data_view);
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

// Create a new Data View
export async function dataViewCreate({ newDataView }) {
	try {
		const response = await apiClient({
			body: newDataView,
			method: 'post',
			url: baseUrl,
		});

		if (response) {
			if (response.result === OK) {
				return await dataViewPostSchema.validate(response.data_view);
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error', error);
	}
}

// Update a Data View by uuid
export async function dataViewUpdate({ dataViewUuid, dataViewUpdate }) {
	try {
		await dataViewGetSchema.validate(dataViewUpdate);
	} catch (error) {
		throw new Error(error);
	}

	const url = `${baseUrl}/${dataViewUuid}`;

	try {
		const response = await apiClient({
			body: dataViewUpdate,
			method: 'put',
			url,
		});

		if (response) {
			if (response.result === OK) {
				return;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error(error);
	}
}

// Delete a Data View by uuid
export async function dataViewDelete({ dataViewUuid }) {
	const url = `${baseUrl}/${dataViewUuid}`;

	try {
		const response = await apiClient({
			method: 'delete',
			url,
		});

		if (response) {
			if (response.result === OK) {
				return response;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error', error);
	}
}
