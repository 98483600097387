import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import NewUserIcon from '@mui/icons-material/PersonAdd';
import { format } from 'date-fns';

import admin_nav_item from '../AdminNavItem';
import admin_options from '../AdminOptions';
import { BtFormContainer } from '../../../components/generic/forms';
import { useNavContext } from '../../../context/ContextManager';
import { userAdminGetList } from '../../../API/user_admin';
import BtDialog from '../../../components/generic/BtDialog';
import UsersInvite from './UsersInvite';
import BtTags from '../../../components/generic/BtTags';
import { BtChipsDisplay } from '../../../components/generic/BtChips';
import BtRemoteTable from '../../../components/generic/BtRemoteTable';
import { useHistory } from 'react-router-dom';

export default function Users() {
	const history = useHistory();

	const [showInviteDialog, setShowInviteDialog] = useState(false);

	// Set contextual navigation items and breadcrumbs
	const { setContextualNav, setBreadcrumbs } = useNavContext();
	useEffect(
		() => {
			setContextualNav([...admin_nav_item, ...admin_options]);
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'Admin', link: '/Admin' },
				{ text: 'Users', link: '' },
			]);
			return () => setContextualNav(null);
		},
		[setContextualNav, setBreadcrumbs]
	);

	const tableColumns = [
		{
			field: 'user',
			text: 'User',
			searchable: true,
			sortable: true,
			CellContent: (item, row) => {
				return (
					<Typography variant="subtitle" title={row.user_name}>
						{`${row.first_name} ${row.last_name}`}
					</Typography>
				);
			},
		},
		// { field: 'email', text: 'Email', minBreakpoint: 'sm' },
		{
			field: 'status',
			text: 'Status',
			minBreakpoint: 'sm',
			searchable: true,
			sortable: true,
			CellContent: (item, row) => {
				return (
					<Typography variant="subtitle" title={row.status}>
						{`${row.status}`}
					</Typography>
				);
			},
		},
		{
			field: 'roles',
			text: 'Roles',
			minBreakpoint: 'md',
			searchable: true,
			sortable: true,
			CellContent: (item, row) => {
				return (
					<BtChipsDisplay
						value={row.roles}
						primaryField="name"
						idKey="uuid"
						size="small"
						readOnly
						partOfButton
						singleLine
					/>
				);
			},
		},
		{
			field: 'tags',
			text: 'Tags',
			minBreakpoint: 'lg',
			searchable: true,
			sortable: true,
			CellContent: (item, row) => {
				return (
					<BtTags
						initialTags={row.tags}
						readOnly
						size="small"
						singleLine
						partOfButton
					/>
				);
			},
		},
		{
			field: 'last_login_timestamp',
			text: 'Last Login',
			minBreakpoint: 'sm',
			searchable: true,
			sortable: true,
			CellContent: (item, row) => {
				return row.last_login_timestamp === 0
					? '-'
					: format(
							new Date(row.last_login_timestamp || 0),
							'dd MMM yyyy, hh:mm a'
					  );
			},
		},
	];

	const onUserClick = ({ uuid }) => {
		history.push(`/Users/Edit/${uuid}`);
	};

	return (
		<>
			<BtFormContainer title="Users" maxWidth="lg">
				<BtRemoteTable
					title={
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								flexWrap: 'wrap',
								gap: 10,
							}}
						>
							<Typography
								sx={{
									fontWeight: 'bold',
									padding: '4px 0',
								}}
								variant="h5"
							>
								{`Your organisation's users`}
							</Typography>
							<Button
								variant="contained"
								onClick={() => setShowInviteDialog(true)}
								startIcon={<NewUserIcon />}
								disableElevation
							>
								Invite a New User
							</Button>
						</div>
					}
					apiRequest={userAdminGetList}
					// apiRequestProps={}
					columns={tableColumns}
					// enableSearching
					// enableGeneralSorting
					enablePagination
					idField="uuid"
					stickyHeader
					subject="Workflow Submissions"
					onClick={onUserClick}
					tableId="users"
				/>
			</BtFormContainer>

			<BtDialog
				open={showInviteDialog}
				onClose={() => setShowInviteDialog(false)}
				fullWidth
				maxWidth="sm"
			>
				<UsersInvite onClose={() => setShowInviteDialog(false)} />
			</BtDialog>
		</>
	);
}
