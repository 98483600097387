import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';

import { ChevronDoubleUp } from 'mdi-material-ui';
import { Chip as MuiChip, styled, Tooltip, Typography } from '@mui/material';

import BtPopper from '../../BtPopper';
import Filter from './filter/Filter';
import useOverflowedElementCount from '../../../../hooks/useOverflowedElementCount';
import { useTheme } from '@mui/styles';
import { InsightsFilterChip } from './InsightsFilterChip';
import { useAppContext } from '../../../../context/ContextManager';
import useBreakpointValue from '../../../../hooks/useBreakpointValue';
import useBreakpointStatuses from '../../../../hooks/useBreakpointStatuses';

const containerStyle = ({ singleline }) => ({
	alignItems: 'flex-start',
	border: 'none',
	display: 'flex',
	height: (() => {
		if (singleline) {
			return 28;
		}
		return 'auto';
	})(),
	justifyContent: 'flex-start',
	marginLeft: 10,
	minHeight: 'inherit',
	outline: 'none',
	overflow: 'hidden',
	padding: 0,
	pointerEvents: 'all',
	width: '100%',
	maxWidth: '100%',
});

export const ChipWrapper = styled('div')(({ hashidden }) => ({
	alignItems: 'center',
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'flex-start',
	paddingRight: (() => {
		if (hashidden) {
			return 26;
		}
		return 0;
	})(),
	width: '100%',
}));

export const MoreIndicator = styled('div')(({ left, theme }) => ({
	alignItems: 'center',
	backgroundColor: theme.palette.insightsChips.backgroundColor,
	borderRadius: 12,
	color: theme.palette.text.primary,
	cursor: 'pointer',
	display: 'flex',
	fontSize: 10,
	fontWeight: 'bold',
	height: 24,
	justifyContent: 'center',
	left,
	margin: 3,
	minWidth: 24,
	pointerEvents: 'all',
	position: 'absolute',
	top: 0,
	width: 24,
	'&:hover': {
		backgroundColor: theme.palette.insightsChips.hover,
	},

	'&:active': {
		backgroundColor: theme.palette.insightsChips.active,
		boxShadow: 'none',
	},
}));

const Display = styled('div')(containerStyle);

const FilterChip = ({
	filter,
	filters,
	label,
	setFilters,
	showMobileFilterChips,
	containerWidth,
	insightsChips,
}) => {
	const [popperOpen, setPopperOpen] = useState(false);
	const { showMobile } = useAppContext();
	const breakpoint = useBreakpointValue();

	const handleFilterPopperOpen = useCallback(() => {
		setPopperOpen(prev => !prev);
	}, []);

	const handleFilterPopperClose = useCallback(() => {
		setPopperOpen(false);
	}, []);

	// Sets the filterEnable
	const onChangeEnabled = useCallback(
		event => {
			event.stopPropagation();

			const filtersUpdate = filters.map(item => {
				if (item.name === filter.name) {
					return {
						...item,
						filterEnabled: !item.filterEnabled,
					};
				}

				return item;
			});

			setFilters([...filtersUpdate]);
		},
		[filter, filters, setFilters]
	);

	const filterIsEnabled = useMemo(() => filter.filterEnabled, [
		filter.filterEnabled,
	]);

	const popperStyle = useMemo(
		() => {
			if (showMobile) {
				switch (breakpoint) {
					case 'xs':
						return {
							width: '100vw',
						};
					case 'sm':
						return {
							width: '50vw',
						};
					default:
						return {
							maxWidth: `calc(100vw *.5)`,
							minWidth: `calc(100vw *.3)`,
						};
				}
			} else {
				return {
					maxWidth: `calc(100vw *.5)`,
					minWidth: `calc(100vw *.3)`,
				};
			}
		},
		[breakpoint, showMobile]
	);

	return (
		<BtPopper
			key={filter.name}
			content={
				<Filter
					editMode={true}
					filter={filter}
					filters={filters}
					onClose={handleFilterPopperClose}
					setFilters={setFilters}
				/>
			}
			onClose={handleFilterPopperClose}
			open={popperOpen}
			placement="bottom-start"
			variant="extended"
			// style={{
			// 	maxWidth: `calc(100vw *.5)`,
			// 	minWidth: `calc(100vw *.3)`,
			// }}
			style={{ ...popperStyle }}
			extendOffset
		>
			{insightsChips ? (
				<span>
					<InsightsFilterChip
						containerWidth={containerWidth}
						field={label}
						filterIsEnabled={filterIsEnabled}
						filterType={filter.field.type}
						filterValue={filter.filterValue}
						onClick={handleFilterPopperOpen}
						showMobileFilterChips={showMobileFilterChips}
						switchChecked={filterIsEnabled}
						switchDisabled={filter.required}
						switchOnChange={onChangeEnabled}
					/>
				</span>
			) : (
				<MuiChip
					label={label}
					onClick={handleFilterPopperOpen}
					style={{
						margin: showMobileFilterChips ? 5 : 3,
					}}
					size="small"
					variant={filter.filterEnabled ? 'filled' : 'outlined'}
				/>
			)}
		</BtPopper>
	);
};

const ChipContainer = ({
	filters,
	setExpand,
	setFilters,
	setIsSingleLine,
	showMobileFilterChips,
	singleLine,
	insightsChips,
}) => {
	const containerRef = useRef();

	const {
		assessOverflow: recheckOverflow,
		count: overflowCount,
		topRowWidth: moreIndicatorPos,
		firstHiddenIndex,
		width,
	} = useOverflowedElementCount(containerRef);

	const hasHidden = useMemo(() => +(overflowCount > 0), [overflowCount]);

	useEffect(() => recheckOverflow(), [recheckOverflow, filters]);
	// console.log(width);
	return (
		<div style={{ width: '100%', position: 'relative' }}>
			<ChipWrapper ref={containerRef} hashidden={hasHidden}>
				{filters.map(item => {
					return (
						<FilterChip
							containerWidth={width}
							variant="outlined"
							key={item.name}
							label={item.name}
							filter={item}
							setFilters={setFilters}
							filters={filters}
							showMobileFilterChips={showMobileFilterChips}
							insightsChips={insightsChips}
						/>
					);
				})}
			</ChipWrapper>
			{!showMobileFilterChips &&
				overflowCount > 0 &&
				singleLine && (
					<Tooltip
						title={
							<ul
								style={{
									listStyleType: 'none',
									margin: 0,
									padding: 0,
								}}
							>
								{filters
									.slice(-(filters.length - firstHiddenIndex))
									.map((value, index) => (
										<li key={index}>
											{(() => {
												return value.name;
											})()}
										</li>
									))}
							</ul>
						}
						disableInteractive
					>
						<MoreIndicator
							left={moreIndicatorPos}
							onClick={() => {
								setIsSingleLine(false);
								setExpand(true);
							}}
						>
							<span>
								{overflowCount < 100
									? `+${overflowCount}`
									: '...'}
							</span>
						</MoreIndicator>
					</Tooltip>
				)}
			{!showMobileFilterChips &&
				!singleLine && (
					<MoreIndicator
						left={moreIndicatorPos}
						onClick={() => {
							setIsSingleLine(true);
							setExpand(false);
						}}
					>
						<ChevronDoubleUp fontSize="small" size="small" />
					</MoreIndicator>
				)}
		</div>
	);
};

export const FilterChips = ({
	expand,
	filters,
	placeholder,
	primaryField,
	setExpand,
	setFilters,
	showMobileFilterChips,
	singleLine,
	insightsChips,
}) => {
	const Container = Display;
	const isEmpty = useMemo(() => (filters || []).length === 0, [filters]);
	const [isSingleLine, setIsSingleLine] = useState(singleLine);

	return (
		<Container
			expand={+expand}
			isempty={+isEmpty}
			singleline={+isSingleLine}
		>
			{(filters || []).length === 0 ? (
				<Typography
					className="overflow_not_countable"
					style={{
						alignSelf: 'center',
						margin: '0 1em',
						opacity: 0.7,
					}}
					variant="subtitle2"
				>
					{placeholder}
				</Typography>
			) : (
				<ChipContainer
					expand={+expand}
					filters={filters}
					insightsChips={insightsChips}
					primaryField={primaryField}
					setExpand={setExpand}
					setFilters={setFilters}
					setIsSingleLine={setIsSingleLine}
					singleLine={isSingleLine}
					showMobileFilterChips={showMobileFilterChips}
				/>
			)}
		</Container>
	);
};

const commonProps = {
	filters: PropTypes.arrayOf(PropTypes.object).isRequired,
	setFilters: PropTypes.func.isRequired,
	insightsChips: PropTypes.bool,
};

FilterChip.propTypes = {
	...commonProps,
	filter: PropTypes.object,
	label: PropTypes.string.isRequired,
	showMobileFilterChips: PropTypes.bool,
};

ChipContainer.propTypes = {
	...commonProps,
	setExpand: PropTypes.func.isRequired,
	setIsSingleLine: PropTypes.func.isRequired,
	showMobileFilterChips: PropTypes.bool,
	singleLine: PropTypes.bool,
};

FilterChips.propTypes = {
	...commonProps,
	expand: PropTypes.bool.isRequired,
	placeholder: PropTypes.string,
	primaryField: PropTypes.string,
	setExpand: PropTypes.func.isRequired,
	showMobileFilterChips: PropTypes.bool,
	singleLine: PropTypes.bool,
};

export default FilterChips;
