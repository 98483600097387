import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withFormContextMethods } from '../../../../../../../components/generic/forms';
import { Box, Button, Typography, styled } from '@mui/material';
import _, { has } from 'lodash';
import { XS } from '../../../../../../../components/generic/BtIconButton';
import { mdiDelete, mdiPencil, mdiPencilOff } from '@mdi/js';

import { BtIconButtonGroup } from '../../../../../../../components/generic/BtIconButtonGroup';
import { InputController, resolveVisOptionsSchema } from './InputController';
import { radialBarChartParam } from '../../../../../../../API/validations/insightVisualizationsValidation';
import { RADIAL_BAR_CHART } from '../../../../../InsightPage/visualisationConstants';

const ParamBox = styled(Box)(({ editing, theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: editing && '10px',
	border: `1px solid ${theme.palette.divider}`,
	padding: '8px',
	borderRadius: '8px',
}));

const resolveParamsSchema = type => {
	return resolveVisOptionsSchema(type).params.innerType.fields;
};

const resolvePropertySchema = (type, propKey) => {
	const paramSchemaDesc = resolveParamsSchema(type);

	const getPropDetails = propKey => {
		const propSchemaDesc = paramSchemaDesc[propKey];
		const result = {};
		result.type = propSchemaDesc.type;
		result.oneOf = propSchemaDesc.oneOf;
		return result;
	};

	return getPropDetails(propKey);
};

const ParamWrite = ({ param, index, type }) => {
	return _.sortBy(Object.keys(param)).map(propKey => (
		<InputController
			name={`vis_options.params.${index}.${propKey}`}
			key={propKey}
			label={propKey}
			propDetails={resolvePropertySchema(type, propKey)}
		/>
	));
};

const ParamRead = ({ param, index }) => {
	return _.sortBy(Object.keys(param)).map(propKey => (
		<Typography
			variant="caption"
			label={propKey}
			name={`vis_options.params.${index}.${propKey}`}
			key={propKey}
		>{`${propKey}: ${param[propKey]}`}</Typography>
	));
};

const Param = ({ param, index, type }) => {
	const [editMode, setEditMode] = useState(false);

	const Buttons = withFormContextMethods(({ setValue, getValues }) => {
		const handleDeleteParam = () => {
			const params = getValues('vis_options.params');
			params.splice(index, 1);
			if (params.length === 0) {
				setValue('vis_options.params', null, {
					shouldDirty: true,
					shouldTouch: true,
				});
			} else {
				setValue('vis_options.params', params, {
					shouldDirty: true,
					shouldTouch: true,
				});
			}
		};

		return (
			<BtIconButtonGroup
				buttonGroupAttributes={[
					{
						size: XS,
						icon: mdiDelete,
						onClick: handleDeleteParam,
						tooltip: 'Delete param',
					},
					{
						size: XS,
						icon: editMode ? mdiPencilOff : mdiPencil,
						onClick: () => setEditMode(!editMode),
						tooltip: 'Edit param',
					},
				]}
			/>
		);
	});

	return (
		<ParamBox editing={+editMode}>
			<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Typography>{`Param ${index}: ${param.label}`}</Typography>
				<Buttons />
			</Box>

			{editMode ? (
				<ParamWrite type={type} index={index} param={param} />
			) : (
				<ParamRead index={index} param={param} />
			)}
		</ParamBox>
	);
};

const resolveDefault = paramDesc => {
	if (paramDesc.oneOf.length > 0) {
		return paramDesc.oneOf[0];
	}

	const defaultValues = {
		string: '',
		boolean: false,
	};
	return defaultValues[paramDesc.type];
};

const generateNewParam = type => {
	const defaultParamsDesc = resolveParamsSchema(type);
	let newParam = {};

	const defaultParamValues = {
		[RADIAL_BAR_CHART]: radialBarChartParam,
	};
	if (has(defaultParamValues, type)) {
		newParam = defaultParamValues[type].validateSync({});
	} else {
		console.log(
			`No default param values for ${type}, using generic values`
		);
		Object.keys(defaultParamsDesc).forEach(
			key => (newParam[key] = resolveDefault(defaultParamsDesc[key]))
		);
	}
	console.log(newParam);
	return newParam;
};

const addDefaultParamToParams = (type, params) => {
	const newParam = generateNewParam(type);
	if (!params) {
		return [newParam];
	} else {
		return [...params, newParam];
	}
};

export const ParamsForm = withFormContextMethods(
	({ watch, getValues, setValue }) => {
		const type = getValues('type');
		const params = watch('vis_options.params');

		const handleAddParam = () => {
			const newParams = addDefaultParamToParams(type, params);
			setValue('vis_options.params', newParams, {
				shouldDirty: true,
				shouldTouch: true,
				shouldValidate: true,
			});
		};

		// console.log(type, params);

		return (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: '10px',
				}}
			>
				{params === null ? (
					<Typography>
						{
							<em>
								No configured params, they will be generated
								from the data
							</em>
						}
					</Typography>
				) : (
					params.map((param, index) => (
						<Param
							key={`Param ${index}`}
							param={param}
							index={index}
							type={type}
						/>
					))
				)}
				<Button
					disableElevation
					variant={'contained'}
					onClick={handleAddParam}
				>
					Add Param
				</Button>
			</Box>
		);
	}
);

Param.propTypes = {
	param: PropTypes.object.isRequired,
	index: PropTypes.number.isRequired,
	type: PropTypes.string.isRequired,
};
