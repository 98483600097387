import React from 'react';
import PropTypes from 'prop-types';

import DataTypeList from '../../DataTypes/DataTypeList';
import PopoverContent from './PopoverContent';

export default function DataTypePane({
	disabled,
	onBack,
	onTypeSelect,
	title,
}) {
	return (
		<PopoverContent disabled={disabled} onBack={onBack} title={title}>
			<DataTypeList
				disabled={disabled}
				onClick={(event, type) => onTypeSelect(type)}
			/>
		</PopoverContent>
	);
}

DataTypePane.propTypes = {
	disabled: PropTypes.bool,
	onBack: PropTypes.func,
	onTypeSelect: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
};
