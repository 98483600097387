import React from 'react';
import { useChartsContext } from '../contexts/ChartsContexts';
import { STATUS_TIMELINE_CHART } from '../Constants/constants';

// TODO: Change teh following to be a div instead of an svg
const DefaultTooltip = ({ data, tooltipLocation }) => {
	return (
		<g transform={`translate(${tooltipLocation.x}, ${tooltipLocation.y})`}>
			<rect x="0" y="0" width="100" height="100" fill="#444444" />
			<text x="5" y="20" fill="white">
				{data.id}
			</text>
		</g>
	);
};

// TODO: Change teh following to be a div instead of an svg
const StatusTimelineTooltip = ({ data, tooltipLocation }) => {
	return (
		<g transform={`translate(${tooltipLocation.x}, ${tooltipLocation.y})`}>
			<rect x="0" y="0" width="100" height="100" fill="#444444" />
			<text x="5" y="20" fill="white">
				{data.id}
			</text>
			<text x="5" y="40" fill="white">
				{data.status}
			</text>
		</g>
	);
};

export function Tooltip({ content }) {
	const {
		chartType,
		setTooltipLabel: label,
		tooltipActive: active,
		tooltipLocation,
		tooltipData: payload,
	} = useChartsContext();

	// console.log('tooltipLocation', tooltipLocation);
	// console.log('tooltipData', payload);
	// console.log('chartType', chartType);
	// console.log('active', active);
	// console.log('content', content);
	// If the tooltip is not active, return null
	if (!active) return null;

	// If a custom tooltip is passed in, use it
	if (content) {
		const Content = content;
		// console.log('should render Content');
		return <Content active={active} payload={payload} label={label} />;
	}

	// Switch for the correct type of tooltip dependent on the chart type
	switch (chartType) {
		case STATUS_TIMELINE_CHART:
			return (
				<StatusTimelineTooltip
					data={payload}
					tooltipLocation={tooltipLocation}
				/>
			);
		default:
			return (
				<DefaultTooltip
					data={payload}
					tooltipLocation={tooltipLocation}
				/>
			);
	}
}
