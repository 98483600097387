import React from 'react';
import { Prompt } from 'react-router';
import { useFormContext } from 'react-hook-form';

export default function BtFormChangesBlocker() {
    const {
        formState: { isDirty },
        overrideBlocking,
    } = useFormContext() || { formState: {} };

    return (
        <Prompt
            when={isDirty && !overrideBlocking}
            message="You have unsaved changes, are you sure you want to leave?"
        />
    );
}
