import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Typography,
} from '@mui/material';

import { useEditFormContext } from '../EditFormContext';
import { withFormContextMethods } from '../../../../../../components/generic/forms';
import { useWatch } from 'react-hook-form';

const ActionButtons = withFormContextMethods(
	({ reset, targetVisUuid, setTargetVisUuid }) => {
		const { insightCollection } = useEditFormContext();
		const currentVisualisationUuid = useWatch({ name: 'uuid' });
		const handleSubmit = useCallback(
			() => {
				const targetData = {
					...insightCollection.visualisations.find(
						({ uuid }) => uuid === targetVisUuid
					),
				};
				targetData.uuid = currentVisualisationUuid;
				setTargetVisUuid('');
				reset(targetData, { keepDefaultValues: true });
			},
			[
				insightCollection,
				currentVisualisationUuid,
				setTargetVisUuid,
				reset,
				targetVisUuid,
			]
		);

		return (
			<>
				<Button
					variant="contained"
					disabled={!targetVisUuid}
					onClick={handleSubmit}
					disableElevation
				>
					copy data..
				</Button>
			</>
		);
	}
);

export default function CloneVisualisationSection() {
	const { insightCollection } = useEditFormContext();
	const [targetVis, setTargetVis] = useState('');
	const currentVisualisationUuid = useWatch({ name: 'uuid' });

	const options = useMemo(
		() => {
			if (insightCollection) {
				return insightCollection.visualisations
					.filter(({ uuid }) => uuid !== currentVisualisationUuid)
					.map(({ uuid, title, type }) => ({
						value: uuid,
						label: `${title} - ${type}`,
					}));
			}
		},
		[insightCollection, currentVisualisationUuid]
	);

	const handleChange = event => {
		setTargetVis(event.target.value);
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '20px',
				width: '100%',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',

					width: '100%',
				}}
			>
				<Typography sx={{ fontWeight: 'bold' }}>
					Copy the data form an existing visualisation
				</Typography>

				<FormControl variant="standard" style={{ width: '100%' }}>
					<InputLabel id="select-vis-to-clone">
						Visualisation to clone
					</InputLabel>
					<Select
						labelId="select-vis-to-clone"
						id="select-vis-to-clone"
						value={targetVis}
						label="Visualisation"
						onChange={handleChange}
						size="small"
					>
						<MenuItem value="">
							<em>None</em>
						</MenuItem>
						{options.map(({ label, value }) => (
							<MenuItem key={value} value={value}>
								{label}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
			<ActionButtons
				setTargetVisUuid={setTargetVis}
				targetVisUuid={targetVis}
			/>
		</Box>
	);
}

ActionButtons.propTypes = {
	targetVisUuid: PropTypes.string,
	setTargetVisUuid: PropTypes.func.isRequired,
};

CloneVisualisationSection.propTypes = {};
