import React from 'react';
import PropTypes from 'prop-types';

import AddIcon from '@mui/icons-material/Add';
import Color from 'color';
import pluralize from 'pluralize';
import { styled } from '@mui/material';
import _ from 'lodash';

const AddAnotherButton = styled('button')(({ theme }) => ({
	backgroundColor: Color(theme.palette.text.solid)
		.fade(1)
		.toString(),
	border: 'none',
	borderRadius: 13,
	height: 26,
	marginLeft: 6,

	'& > span': {
		alignItems: 'center',
		color: Color(theme.palette.text.solid)
			.fade(0.25)
			.toString(),
		display: 'flex',
		padding: '0 5px',
	},

	'&:hover': {
		backgroundColor: Color(theme.palette.text.solid)
			.fade(0.9)
			.toString(),
	},
}));

const AddAnotherCurve = styled('div')(({ theme }) => ({
	borderBottom: `1px solid ${Color(theme.palette.text.solid)
		.fade(0.85)
		.toString()}`,
	borderBottomLeftRadius: 13,
	borderLeft: `1px solid ${Color(theme.palette.text.solid)
		.fade(0.85)
		.toString()}`,
	height: 13,
	width: 13,
}));

const AddIconContainer = styled('div')(({ theme }) => ({
	alignItems: 'center',
	backgroundColor: Color(theme.palette.text.solid)
		.fade(0.55)
		.toString(),
	borderRadius: 4,
	color: theme.palette.background.default,
	display: 'flex',
	height: 16,
	justifyContent: 'center',
	marginRight: 5,
	width: 16,
}));

const Container = styled('div')(() => ({
	display: 'flex',
	height: 26,
	paddingLeft: 15,
}));

export const AddAnother = ({ disabled, empty, onAdd, title }) => (
	<Container>
		<AddAnotherCurve />
		<AddAnotherButton disabled={disabled} onClick={onAdd}>
			<span>
				<AddIconContainer>
					<AddIcon style={{ fontSize: 16 }} />
				</AddIconContainer>
				{`Add ${empty ? 'a' : 'Another'} ${_.startCase(
					_.toLower(pluralize.singular(title))
				)}`}
			</span>
		</AddAnotherButton>
	</Container>
);

AddAnother.propTypes = {
	disabled: PropTypes.bool,
	empty: PropTypes.bool,
	onAdd: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
};

export default AddAnother;
