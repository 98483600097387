import { IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { Cog, Delete, MinusBoxOutline, PlusBoxOutline } from 'mdi-material-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import TileContent from '../../content/TileContent';
import _ from 'lodash';
import { TileConfigDialog } from '../tileConfig/TileConfigDialog';
// import TileConfigButton from '../controls/TileConfigButton';
import { ContainerRemoveTileDialog } from '../tileConfig/ContainerRemoveTileDialog';
import { ContainerAddTileDialog } from '../tileConfig/ContainerAddTileDialog';
import { resolveIcon, tileLabels } from '../../tileConstants';
import { TileConfigButton } from '../controls/ButtonComponents';
import NativeTile from './NativeTile';

const RenderedContent = ({ tileUuid, component }) => {
	return (
		<TileContent
			tileUuid={tileUuid}
			editEnabled={false}
			// key={component.uuid}
			component={component}
			// setClickDisabled={setClickDisabled}
			clickDisabled={true}
			// layoutDefinition={layoutDefinition}
			rowHeight={100}
		/>
	);
};

// const Tile = ({
// 	tile,
// 	background,
// 	handleDeleteTile,
// 	onTileChange,
// 	screenData,
// 	onContainerChange,
// }) => {
// 	const [showEditDialog, setShowEditDialog] = useState(false);
// 	const [
// 		showContainerRemoveTileDialog,
// 		setShowContainerRemoveTileDialog,
// 	] = useState(false);
// 	const [
// 		showContainerAddTileDialog,
// 		setShowContainerAddTileDialog,
// 	] = useState(false);
// 	const [
// 		showContainerEditTileDialog,
// 		setShowContainerEditTileDialog,
// 	] = useState(false);

// 	const configOptions = useMemo(
// 		() => {
// 			switch (tile.variant) {
// 				case 'workflow_templates':
// 					return 'none';
// 				case 'workflow_submissions':
// 					return 'none';
// 				case 'container':
// 					return 'container';
// 				default:
// 					return 'default';
// 			}
// 		},
// 		[tile.variant]
// 	);

// 	const TileControls = () => {
// 		return (
// 			<div style={{ display: 'flex' }}>
// 				{configOptions === 'default' && (
// 					<TileConfigButton
// 						onClick={() => setShowEditDialog(true)}
// 						title="Configure tile"
// 						icon={<Cog fontSize="small" />}
// 					/>
// 				)}

// 				{configOptions === 'container' && (
// 					<>
// 						<TileConfigButton
// 							onClick={() => setShowContainerAddTileDialog(true)}
// 							title="Add tile to container"
// 							icon={<PlusBoxOutline fontSize="small" />}
// 						/>
// 						<TileConfigButton
// 							onClick={() =>
// 								setShowContainerRemoveTileDialog(true)
// 							}
// 							title="Remove tile from container"
// 							icon={<MinusBoxOutline fontSize="small" />}
// 							disabled={tile.data.children.length === 0}
// 						/>
// 					</>
// 				)}
// 				<TileConfigButton
// 					onClick={handleDeleteTile(tile.uuid)}
// 					title="Remove tile"
// 					icon={<Delete fontSize="small" />}
// 				/>
// 			</div>
// 		);
// 	};

// 	const ChildTiles = () => {
// 		return (
// 			<ChildrenContainer>
// 				{tile.data.children.length === 0 ? (
// 					<Typography>
// 						<em>Empty</em>
// 					</Typography>
// 				) : (
// 					tile.data.children.map(
// 						childUuid =>
// 							screenData.tiles[childUuid].data.button_type !==
// 							'start_workflow' ? (
// 								<Typography key={childUuid}>
// 									<em>Unsupported button</em>
// 								</Typography>
// 							) : (
// 								<Typography key={childUuid}>
// 									{screenData.tiles[childUuid].title}
// 								</Typography>
// 							)
// 					)
// 				)}
// 			</ChildrenContainer>
// 		);
// 	};

// 	const ChildrenContainer = ({ children }) => {
// 		return (
// 			<div
// 				style={{
// 					display: 'flex',
// 					flexDirection: 'column',
// 					marginLeft: 10,
// 				}}
// 			>
// 				{children}
// 			</div>
// 		);
// 	};

// 	return (
// 		<>
// 			<div
// 				style={{
// 					paddingTop: 5,
// 					paddingBottom: 5,
// 					paddingRight: 10,
// 					paddingLeft: 10,
// 					margin: 7,
// 					borderRadius: 5,
// 					backgroundColor: background,
// 					display: 'flex',
// 					flexDirection: 'column',
// 				}}
// 			>
// 				<div
// 					style={{
// 						display: 'flex',
// 						alignItems: 'center',
// 						justifyContent: 'space-between',
// 					}}
// 				>
// 					<div style={{ display: 'flex' }}>
// 						{/* {resolveIcon({
// 							type: tile.variant,
// 							disabled: true,
// 						})} */}
// 						<div style={{ marginLeft: 5, overflow: 'clip' }}>
// 							<Typography>{tileLabels[tile.variant]}</Typography>
// 						</div>
// 					</div>
// 					<TileControls />
// 				</div>
// 				{tile.variant === 'container' && <ChildTiles />}
// 				{tile.variant === 'button' && (
// 					<ChildrenContainer>
// 						<Typography>
// 							{tile.data.target.value === '' ? (
// 								<em>{tile.title}</em>
// 							) : (
// 								tile.title
// 							)}
// 						</Typography>
// 					</ChildrenContainer>
// 				)}
// 			</div>
// 			<TileConfigDialog
// 				open={showEditDialog}
// 				onClose={() => setShowEditDialog(false)}
// 				tileData={tile}
// 				onTileChange={onTileChange}
// 				screenUuid={screenData.uuid}
// 				nativeMode={true}
// 			/>
// 			{configOptions === 'container' && (
// 				<>
// 					<ContainerRemoveTileDialog
// 						open={showContainerRemoveTileDialog}
// 						onClose={() => setShowContainerRemoveTileDialog(false)}
// 						tileData={tile}
// 						// onTileChange={onTileChange}
// 						// screenUuid={screenData.uuid}
// 						screenData={screenData}
// 						onContainerChange={onContainerChange}
// 					/>
// 					<ContainerAddTileDialog
// 						open={showContainerAddTileDialog}
// 						onClose={() => setShowContainerAddTileDialog(false)}
// 						tileData={tile}
// 						// onTileChange={onTileChange}
// 						// screenUuid={screenData.uuid}
// 						screenData={screenData}
// 						onContainerChange={onContainerChange}
// 						nativeMode={true}
// 					/>
// 				</>
// 			)}
// 		</>
// 	);
// };

export default function NativeLayout({
	filteredLayout,
	screenData,
	onNativeChange,
	onTileChange,
	onContainerChange,
}) {
	const onDragEnd = useCallback(
		async dragResult => {
			console.log({ dragResult });

			console.log('onDragEnd fired');
			if (!dragResult.destination) {
				return;
			}
			let newLayout = [...filteredLayout];
			const [removed] = newLayout.splice(dragResult.source.index, 1);
			newLayout.splice(dragResult.destination.index, 0, removed);

			if (_.isEqual(newLayout, filteredLayout)) {
				console.log('no layout change, backend update aborted');
				return;
			}

			const update = structuredClone(screenData);
			update.layouts.native = newLayout;

			onNativeChange({
				newScreen: update,
				mode: 'layoutUpdate',
			});
		},
		[filteredLayout, onNativeChange, screenData]
	);

	const handleDeleteTile = tileUuid => event => {
		const layoutIndex = _.findIndex(filteredLayout, {
			uuid: tileUuid,
		});

		let newLayoutList = [...filteredLayout];
		newLayoutList.splice(layoutIndex, 1);

		onNativeChange({
			tileToRemove: tileUuid,
			mode: 'removeTile',
			currentScreenData: screenData,
		});
	};

	const THEME = useTheme();

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId="layout">
				{provided => (
					<div {...provided.droppableProps} ref={provided.innerRef}>
						{filteredLayout.map((tileUuid, index) => (
							<Draggable
								draggableId={tileUuid}
								index={index}
								key={tileUuid}
							>
								{provided => (
									<div
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
									>
										{/* <Tile
											id={tileUuid}
											tile={screenData.tiles[tileUuid]}
											handleDeleteTile={handleDeleteTile}
											background={
												THEME.palette.background
													.paperContrast
											}
											onTileChange={onTileChange}
											screenData={screenData}
											onContainerChange={
												onContainerChange
											}
										/> */}
										<NativeTile
											tile={screenData.tiles[tileUuid]}
											handleDeleteTile={handleDeleteTile}
											onTileChange={onTileChange}
											screenData={screenData}
											onContainerChange={
												onContainerChange
											}
										/>
									</div>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
}
