import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownIcon from '@mui/icons-material/ArrowDownward';
import SwapTypeIcon from '@mui/icons-material/SwapHoriz';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import DuplicateIcon from '@mui/icons-material/ContentCopy';

import { useQueryBuilderDispatchContext } from '../context';

export default function BtQueryBuilderStageActions(props) {
	const {
		disabled: disableInteraction,
		isFirst,
		isLast,
		readOnly,
		stageType: type,
		stageId,
	} = props;

	const onChange = useQueryBuilderDispatchContext();

	const dispatch = useCallback(
		action => {
			onChange({
				id: stageId,
				action: action,
			});
		},
		[stageId, onChange]
	);

	const actions = useMemo(
		() => {
			const actions = [
				{
					key: 'moveUp',
					title: 'Move to before previous stage',
					icon: <ArrowUpIcon />,
					disabled: isFirst,
					action: 'moveUp',
				},
				{
					key: 'moveDown',
					title: 'Move to after next stage',
					icon: <ArrowDownIcon />,
					disabled: isLast,
					action: 'moveDown',
				},

				{
					key: 'addBefore',
					title: 'Add a stage before this stage',
					icon: (
						<ControlPointDuplicateIcon
							sx={{ transform: 'rotate(180deg)' }}
						/>
					),
					action: 'addBefore',
				},

				{
					key: 'addAfter',
					title: 'Add stage after this stage',
					icon: <ControlPointDuplicateIcon />,
					action: 'addAfter',
				},
			];

			if (type) {
				actions.push(
					{
						key: 'duplicate',
						title: 'Duplicate',
						icon: <DuplicateIcon />,
						action: 'duplicate',
					},
					{
						key: 'clearType',
						title: 'Change stage type',
						icon: <SwapTypeIcon />,
						action: 'clearType',
					}
				);
			}

			actions.push({
				key: 'deleteDelete',
				title: 'Delete this stage',
				icon: <DeleteIcon />,
				action: 'deleteDelete',
			});

			return actions;
		},
		[isFirst, isLast, type] //, stageData.enabled]
	);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const menuOpen = Boolean(anchorEl);
	const handleMenuOpen = useCallback(event => {
		setAnchorEl(event.currentTarget);
	}, []);
	const handleMenuClose = useCallback(() => {
		setAnchorEl(null);
	}, []);

	const handleMenuSelect = action => {
		handleMenuClose();
		dispatch(action);
	};

	if (readOnly) {
		return null;
	}

	return (
		<Box marginBottom={'1em'}>
			<IconButton
				id={'action-select'}
				aria-controls={menuOpen ? 'action-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={menuOpen ? 'true' : undefined}
				onClick={handleMenuOpen}
				disabled={disableInteraction}
			>
				<MoreVertIcon />
			</IconButton>

			<Menu
				id={'type-menu'}
				onClose={handleMenuClose}
				anchorEl={anchorEl}
				MenuListProps={{
					'aria-labelledby': 'type-select',
				}}
				open={menuOpen}
				transformOrigin={{
					horizontal: 'right',
					vertical: 'top',
				}}
				anchorOrigin={{
					horizontal: 'right',
					vertical: 'bottom',
				}}
			>
				{actions.map(({ key, title, icon, action, disabled }) => (
					<MenuItem
						key={key}
						onClick={() => handleMenuSelect(action)}
					>
						<ListItemIcon>{icon}</ListItemIcon>
						<ListItemText>{title}</ListItemText>
					</MenuItem>
				))}
			</Menu>
		</Box>
	);
}

BtQueryBuilderStageActions.propTypes = {
	disabled: PropTypes.bool,
	isFirst: PropTypes.bool,
	isLast: PropTypes.bool,
	readOnly: PropTypes.bool,
	stageType: PropTypes.string,
	stageId: PropTypes.number,
};
