import { findIndex } from 'lodash';
import { dataViewGetList } from '../../../../../../API/DataManager/data_views';
import { getInsightCollection } from '../../../utils';

export const getData = async (id, location, version) => {
	try {
		let collection;
		let dataViewList;
		collection = await getInsightCollection(id, location, version);
		dataViewList = await dataViewGetList();
		return { collection, dataViewList, error: false, loading: false };
	} catch (error) {
		console.log(error);
		return {
			collection: null,
			dataViewList: null,
			error: true,
			loading: false,
		};
	}
};

export const addDataSourcesToCollection = (insightCollection, update) => {
	const collectionUpdate = { ...insightCollection };
	collectionUpdate.datasources = update.datasources;
	return collectionUpdate;
};

export const extractDataSource = (insightCollection, dataSourceId) => {
	if (insightCollection) {
		return insightCollection.datasources.find(
			({ uuid }) => uuid === dataSourceId
		);
	} else return {};
};

export const evaluateCopyName = (items, index) => {
	const existingNames = items.map(({ name }) => name);

	let copyName = items[index].name;

	let copyNameArray = copyName.split(' ');

	if (
		copyNameArray[copyNameArray.length - 1] !== 'copy' ||
		copyNameArray.length < 2
	) {
		copyName = `${items[index].name} copy`;
	}

	let copyIndex = 1;

	const evaluateCopyInName = name => {
		const nameArray = name.split(' ');
		if (nameArray.length > 2) {
			return nameArray[nameArray.length - 2] === 'copy';
		} else return false;
	};

	while (existingNames.includes(copyName)) {
		const existingCopyIndex = Number(copyName.split(' ').slice(-1));

		if (!isNaN(existingCopyIndex) && evaluateCopyInName(copyName)) {
			copyIndex = existingCopyIndex + 1;
			let copyNameArray = copyName.split(' ');
			copyNameArray.splice(-1, 1, `${copyIndex}`);
			copyName = copyNameArray.join(' ');
		} else {
			copyName = `${copyName} ${copyIndex}`;
			copyIndex++;
		}
	}
	return copyName;
};

export const updateDataSources = (
	transformsUpdate,
	dataSource,
	dataSources
) => {
	// add the updated transforms to the data source
	const dataSourceUpdate = { ...dataSource };
	dataSourceUpdate.transforms = transformsUpdate;

	// add the updated data source to the data sources
	const dataSourceIndex = findIndex(dataSources, {
		uuid: dataSource.uuid,
	});
	const dataSourcesUpdate = [...dataSources];
	dataSourcesUpdate.splice(dataSourceIndex, 1, dataSourceUpdate);

	return dataSourcesUpdate;
};

export const addUpdatedTransformToDataSources = (
	transforms,
	transformUpdate,
	dataSource,
	dataSources
) => {
	const transformIndex = findIndex(transforms, {
		uuid: transformUpdate.uuid,
	});
	const transformsUpdate = [...transforms];

	// if the transform is not found it must be new so add it, otherwise replace it
	if (transformIndex === -1) {
		transformsUpdate.push(transformUpdate);
	} else {
		transformsUpdate.splice(transformIndex, 1, transformUpdate);
	}

	const dataSourcesUpdate = updateDataSources(
		transformsUpdate,
		dataSource,
		dataSources
	);
	return dataSourcesUpdate;
};
