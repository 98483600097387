import React, {
	memo,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';
import useResizeObserver from '@react-hook/resize-observer';
import { useAppContext } from '../../../context/ContextManager';
import { TabsDisplay } from './components/TabsDisplay';
import { styled } from '@mui/styles';
import { Box } from '@mui/material';

const ContainerBox = styled(Box)(() => ({
	width: '100%',
}));

const seedIdValueProps = tabs => {
	try {
		return tabs.map((tab, index) => ({
			...tab,
			id: `tab${index + 1}`,
			value: `${index + 1}`,
		}));
	} catch (error) {
		return [];
	}
};

const stripIdValueProps = tabs => {
	return tabs.map(tab => {
		const newTab = { ...tab };
		delete newTab.id;
		delete newTab.value;
		return newTab;
	});
};

/**
 * 	@param autoComplete boolean to show autocomplete, default = false
 * 	@param currentTab for controlled tabs, string value of 1-based current tab, will require onChangeCurrentTab to work as expected
 * 	@param draggable boolean to enable draggable tabs, default = false
 * 	@param onChangeCurrentTab for controlled tabs, will require currentTab to work as expected
 * 	@param onChangeOrder callback fired when tabs are dragged
 * 	@param tabs *required array of tabs  {label: string.isRequired, content: node.isRequired, icon: node}
 * 	@param top pass when using tabs in a dialog to fix tab height issue - !!requires improvement!!
 *
 */
const BtTabs = memo(function BtTabs({
	autoComplete,
	currentTab,
	draggable,
	top,
	onChangeOrder,
	onChangeCurrentTab,
	tabs,
}) {
	const [_tabs, _setTabs] = useState(seedIdValueProps(tabs));
	const [_value, _setValue] = useState('1');

	const [containerWidth, setContainerWidth] = useState();
	const [initializing, setInitializing] = useState(true);
	const { drawerOpen, swipeDrawerOpen } = useAppContext();
	const containerRef = useRef();
	const [offsetTop, setOffsetTop] = useState(null);

	useResizeObserver(containerRef, entry => callback(entry));

	const callback = useCallback(entry => {
		setContainerWidth(entry.contentRect.width);
		setOffsetTop(containerRef.current.offsetTop);
		// console.log(entry.contentRect);
	}, []);

	useEffect(() => _setTabs(seedIdValueProps(tabs)), [tabs]);

	const hasExternalState = useMemo(
		() => {
			return (
				currentTab !== undefined &&
				typeof onChangeCurrentTab === 'function'
			);
		},
		[currentTab, onChangeCurrentTab]
	);

	const value = useMemo(() => (hasExternalState ? currentTab : _value), [
		_value,
		currentTab,
		hasExternalState,
	]);

	const setValue = useMemo(
		() => (hasExternalState ? onChangeCurrentTab : _setValue),
		[hasExternalState, onChangeCurrentTab]
	);

	// if we have a size for the parent container set initializing to
	// false, which allows the tabs bar to render
	useEffect(
		() => {
			if (containerWidth) {
				setInitializing(false);
			}
		},
		[containerWidth]
	);

	// watch the window size and trigger a resize if it changes
	useEffect(() => {
		// console.log('handleResize', containerRef);
		function handleResize() {
			setInitializing(true);
			setContainerWidth(containerRef.current.clientWidth);
			setInitializing(false);
		}

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});

	useEffect(
		() => {
			const timeoutId = setTimeout(() => {
				setInitializing(true);
				setContainerWidth(containerRef.current?.clientWidth);
				setInitializing(false);
			}, drawerOpen || swipeDrawerOpen ? 230 : 200);

			return () => {
				clearTimeout(timeoutId);
			};
		},
		[drawerOpen, swipeDrawerOpen]
	);

	// TODO
	// - make tab bar sticky(optional??)
	// - handle tab content layout -> long labels and icons overflow...
	// - improve mobile support for autocomplete

	// options for the AutoComplete
	const options = useMemo(
		() => {
			if (autoComplete) {
				return _tabs.map(({ label, value }) => ({
					label: label,
					id: value,
				}));
			}
		},
		[autoComplete, _tabs]
	);

	const onDragEnd = useCallback(
		result => {
			let newTabs = Array.from(_tabs);

			// move the dragged tab
			const draggedTab = newTabs.splice(result.source.index, 1)[0];
			newTabs.splice(result.destination.index, 0, draggedTab);

			// update the current tab as its index may have changed
			const newValue = newTabs.findIndex(tab => tab.value === value);
			setValue(`${newValue + 1}`);

			// overwrite the id and value props to ensure they are sequential
			newTabs = seedIdValueProps(newTabs);

			if (onChangeOrder) {
				onChangeOrder(stripIdValueProps(newTabs));
			}
			_setTabs(newTabs);
		},
		[_tabs, onChangeOrder, setValue, value]
	);

	if (!tabs) return null;

	return (
		<ContainerBox ref={containerRef}>
			<TabsDisplay
				autoComplete={autoComplete}
				autoCompleteOptions={options}
				containerWidth={containerWidth}
				draggable={draggable}
				initializing={initializing}
				offsetTop={top ? top : offsetTop}
				onDragEnd={onDragEnd}
				setValue={setValue}
				tabs={_tabs}
				value={value}
			/>
		</ContainerBox>
	);
});

BtTabs.displayName = 'BtTabs';

const tabShape = PropTypes.shape({
	label: PropTypes.string.isRequired,
	content: PropTypes.node.isRequired,
	icon: PropTypes.node,
});

BtTabs.defaultProps = {
	autoComplete: false,
	draggable: false,
};

BtTabs.propTypes = {
	autoComplete: PropTypes.bool,
	currentTab: PropTypes.string,
	draggable: PropTypes.bool,
	onChangeCurrentTab: PropTypes.func,
	onChangeOrder: PropTypes.func,
	tabs: PropTypes.arrayOf(tabShape).isRequired,
	top: PropTypes.bool,
};

export { BtTabs };
