const utils = require('./pipeline.js');

// sets up all allowed operators
require('../expression/index.js');

// add stages

// read stages
require('./stage/match.js');
require('./stage/limit.js');
require('./stage/skip.js');

require('./stage/sort.js');
require('./stage/project.js');
require('./stage/unwind.js');
require('./stage/replaceRoot.js');
require('./stage/group.js');

module.exports.objectMatch = utils.evaluateObjectMatch;
module.exports.pipeline = utils.evaluatePipeline;
