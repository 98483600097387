import { Button } from '@mui/material';
import {
	BtTab,
	BtTabBar,
	BtTabPanel,
	BtTabView,
	a11yProps,
} from '../../BtTabView';
import { ArrowLeft, ArrowRight } from 'mdi-material-ui';
import React, { useMemo, useState } from 'react';
import { useRecordManagementContext } from '../RecordManagementContext';
import { OverviewTab } from './overview/RecordOverview';
import { VersionControl } from './versionControl/VersionControl';
import { INSIGHT_COLLECTION, WORKFLOW_TEMPLATE } from '../utils/constants';
// import { DistributionTab } from './distribution/Distribution';
import useBreakpointValue from '../../../../hooks/useBreakpointValue';
import {
	TabBarButtonBoxDesktop,
	TabBarButtonBoxMobile,
	TabBarContainer,
	TabBarTabsContainer,
} from './generic/TabLayout';

export default function ManagerTabDisplay() {
	const [currentTab, setCurrentTab] = useState(0);
	const {
		handleLeave,
		module,
		loading,
		moduleAssets,
	} = useRecordManagementContext();

	const breakpoint = useBreakpointValue();

	const small = useMemo(() => ['xs', 'xxs'].includes(breakpoint), [
		breakpoint,
	]);

	const tabs = [
		{
			label: 'Overview',
			tabContent: <OverviewTab />,
		},
		{
			label: 'Published Versions',
			tabContent: <VersionControl />,
		},
		// TODO - Distribution is for future development, will be similar to screens
		// {
		// 	label: 'Distribution',
		// 	tabContent: (
		// 		<DistributionTab />
		// 	),
		// },
	];

	return (
		!loading && (
			<BtTabView
				style={{
					width: '100%',
				}}
			>
				<TabBarContainer>
					<TabBarTabsContainer>
						<BtTabBar
							currentTab={currentTab}
							onTabChange={(event, selectedTab) =>
								setCurrentTab(selectedTab)
							}
							style={{
								width: '100%',
								height: '62px',
								paddingTop: '10px',
								justifyContent: 'flex-end',
							}}
						>
							{tabs.map((tab, index) => (
								<BtTab
									key={`tab ${index}`}
									label={tab.label}
									{...a11yProps(index)}
								/>
							))}
						</BtTabBar>
					</TabBarTabsContainer>
					{!small && (
						<TabBarButtonBoxDesktop>
							{(module === INSIGHT_COLLECTION ||
								module === WORKFLOW_TEMPLATE) && (
								<Button
									onClick={handleLeave}
									disableElevation
									variant="outlined"
									startIcon={<ArrowLeft />}
									// endIcon={<ArrowRight />}
								>
									{moduleAssets.moduleBackText}
								</Button>
							)}
						</TabBarButtonBoxDesktop>
					)}
				</TabBarContainer>
				{small && (
					<TabBarButtonBoxMobile>
						<Button
							fullWidth
							onClick={handleLeave}
							disableElevation
							variant="outlined"
						>
							Back to collection
						</Button>
					</TabBarButtonBoxMobile>
				)}
				{tabs.map((tab, index) => (
					<BtTabPanel
						key={`tab panel ${index}`}
						currentTab={currentTab}
						index={index}
					>
						{tab.tabContent}
					</BtTabPanel>
				))}
			</BtTabView>
		)
	);
}
