import React, { memo } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { colourSwatchGeometryStyle } from '../../palette/utils/constants';
import {
	BtFormContainerStackedActionButtons,
	CONTAINED,
	OUTLINED,
	TEXT,
} from '../../generic/forms/BtFormContainerStackedActionButtons';
import PropTypes from 'prop-types';
import { BtIconButtonContainer } from '../../generic/BtIconButtonGroup';

const ComponentContainer = styled(Container)(() => ({
	marginTop: '1em',
}));

const ComponentTitleBox = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
	justifyContent: 'space-between',
}));

const PaletteTitleText = styled(Typography)(({ selected }) => ({
	// textTransform: 'capitalize',
	fontWeight: selected && 500,
}));

const PaletteRow = styled(Box)(({ theme, disabled, selected }) => ({
	// width: 'fitContent',
	padding: '5px 10px ',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	borderLeft: selected
		? `3px solid ${theme.palette.primary.main}`
		: `3px solid transparent`,
	// backgroundColor: selected ? theme.palette.primary.main + '10' : 'none',

	'&:hover': {
		backgroundColor: theme.palette.primary.main + '10',
	},

	'&:hover #childToShowOnHover': {
		// display: !disabled && 'inline-block',
		visibility: !disabled && 'visible',
	},
	[theme.breakpoints.down('sm')]: {
		padding: '0px',
		borderLeft: `3px solid transparent`,
	},
}));

const TitleEditContainer = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	// gap: '15px',
	height: '25px',
	marginBottom: '5px',
}));

const PalettesContainer = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	gap: '5px',
}));

const EditButtonBox = styled(BtIconButtonContainer)(() => ({
	marginTop: '10px',
	visibility: 'hidden',
}));

const EmptyPalettePlaceHolderContainer = styled(Box)(({ theme }) => ({
	...colourSwatchGeometryStyle,
	width: '300px',
	maxWidth: '300px',
	border: `dashed 1px ${theme.palette.grey[500]}`,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

const EmptyPalettePlaceHolder = () => {
	return (
		<EmptyPalettePlaceHolderContainer>
			<Typography variant="h4">No colours in palette</Typography>
		</EmptyPalettePlaceHolderContainer>
	);
};

const MobileButtonsOuterBox = styled(Box)(() => ({
	display: 'block',
	position: 'fixed',
	bottom: 0,
	left: 0,
	width: '100%',
	zIndex: 900,
}));

const MobileButtonsFadeBox = styled(Box)(({ theme }) => ({
	height: 20,
	transform: 'translate(-5px, 0.5px)',
	background: `linear-gradient(180deg, ${
		theme.palette.background.default
	}00 0%, ${theme.palette.background.default} 33%, ${
		theme.palette.background.default
	} 100%)`,
}));

const MobileButtonsInnerBox = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	padding: '0 0.5em 0.5em 0.5em',
	width: '100%',
}));

const MobileButtons = memo(function MobileBottomButtons({ buttonAttr }) {
	return (
		<MobileButtonsOuterBox>
			<MobileButtonsFadeBox />
			<MobileButtonsInnerBox>
				<BtFormContainerStackedActionButtons buttonAttr={buttonAttr} />
			</MobileButtonsInnerBox>
		</MobileButtonsOuterBox>
	);
});

const buttonAttrItem = PropTypes.shape({
	onClick: PropTypes.func,
	type: PropTypes.oneOf(['submit']),
	variant: PropTypes.oneOf([TEXT, OUTLINED, CONTAINED]),
	title: PropTypes.string.isRequired,
	startIcon: PropTypes.element,
	endIcon: PropTypes.element,
});

MobileButtons.propTypes = {
	buttonAttr: PropTypes.arrayOf(buttonAttrItem),
};

export {
	EditButtonBox,
	EmptyPalettePlaceHolder,
	PaletteRow,
	PalettesContainer,
	TitleEditContainer,
	PaletteTitleText,
	ComponentTitleBox,
	MobileButtons,
	ComponentContainer,
};
