import { Box, Typography } from '@mui/material';
import { Launch } from 'mdi-material-ui';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { tileLabels } from '../tileConstants';
import { ScreensContext } from '../ScreensContext';
import WorkflowSubmissionsTile from './WorkflowSubmissionsTile';
import WorkflowTemplatesTile from './WorkflowTemplatesTile';
import { TileContext } from '../components/TileContext';

export default function WorkflowTile() {
	const [mouseDown, setMouseDown] = useState(false);
	const history = useHistory();
	const { editEnabled } = useContext(ScreensContext);
	const { tileData, tileSize } = useContext(TileContext);

	const handlePressHeader = () => {
		if (editEnabled) {
			return;
		} else if (tileData.variant === 'workflow_templates') {
			history.push(`/Workflows/Templates`);
		} else if (tileData.variant === 'workflow_submissions') {
			history.push(`/Workflows/Submissions`);
		}
	};

	const handleMouseUp = useCallback(
		e => {
			if (!editEnabled) {
				setMouseDown(false);
				window.removeEventListener('mouseup', handleMouseUp, true);
				window.removeEventListener('touchend', handleMouseUp, true);
			}
		},
		[editEnabled]
	);

	const handleMouseDown = useCallback(
		e => {
			if (!editEnabled) {
				setMouseDown(true);
				window.addEventListener('mouseup', handleMouseUp, true);
				window.addEventListener('touchend', handleMouseUp, true);
			}
		},
		[editEnabled, handleMouseUp]
	);

	const isNumberOnly = useMemo(() => tileSize.w < 245, [tileSize]);

	const Content = () => {
		if (tileData.variant === 'workflow_templates') {
			return <WorkflowTemplatesTile />;
		}

		if (tileData.variant === 'workflow_submissions') {
			return <WorkflowSubmissionsTile />;
		}
	};

	return (
		<div
			style={{
				height: '100%',
				width: '100%',
			}}
		>
			<div
				style={{
					width: '100%',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'flex-start',
					justifyContent: 'space-between',
					paddingLeft: 8,
					paddingRight: 8,
					paddingTop: 8,
					height: 'auto',
					borderTopLeftRadius: 8,
					borderTopRightRadius: 8,
					cursor: !editEnabled ? 'pointer' : 'auto',
					backgroundColor: mouseDown
						? 'rgba(0,0,0,0.2'
						: 'transparent',
				}}
				onClick={handlePressHeader}
				onMouseDown={handleMouseDown}
				onTouchStart={handleMouseDown}
			>
				<Typography variant={isNumberOnly ? 'body2' : 'h6'}>
					{tileLabels[tileData.variant]}
				</Typography>
			</div>
			{!isNumberOnly && (
				<Launch style={{ position: 'absolute', top: 8, right: 8 }} />
			)}

			{/* <Divider /> */}
			<Box
				style={{
					height: isNumberOnly
						? 'calc(100% - 40px)'
						: 'calc(100% - 40px)',
					width: '100%',
					overflow: 'scroll',
				}}
			>
				<Content />
			</Box>
		</div>
	);
}
