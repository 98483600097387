import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BtError from '../../../components/generic/BtError';
import BtLoading from '../../../components/generic/BtLoading';

import BtSelectionTable from '../../../components/generic/BtSelectionTable';
import {
	InsightCollectionEditContextProvider,
	useInsightCollectionEditContext,
} from '../../../views/Insights/insightCollectionEditContext/InsightCollectionEditContext';
import { useDataProviderSubscribe } from '../../../context/DataProvider/DataProvider';

const columns = [
	{ field: 'Retailer', text: 'Retailer' },
	{ field: 'price_NotOnPromotion', text: 'Price', type: 'number' },
	{
		field: 'price_OnPromotion',
		text: 'Price (On Promotion)',
		sortable: false,
	},
	{ field: 'priceEach_NotOnPromotion', text: 'Price Each', type: 'number' },
	{
		field: 'priceEach_OnPromotion',
		text: 'Price Each (On Promotion)',
		sortable: false,
	},
	{
		field: 'priceKg_NotOnPromotion',
		text: 'Price Per KG',
		type: 'number',
		sortable: false,
	},
	{
		field: 'priceKg_OnPromotion',
		text: 'Price Per KG (On Promotion)',
		sortable: false,
	},
];

const withInsightCollection = Component => () => {
	const [showTable, setShowTable] = useState(false);

	useEffect(() => {
		const timeout = setTimeout(() => setShowTable(true), 500);

		return () => clearTimeout(timeout);
	}, []);

	const ShownComponent = useMemo(() => (showTable ? <Component /> : <></>), [
		showTable,
	]);

	return (
		<InsightCollectionEditContextProvider insightCollectionUuid="73888ae0-e15e-4aa0-ae6e-8534430dbb52">
			{ShownComponent}
		</InsightCollectionEditContextProvider>
	);
};

export const Filtering = withInsightCollection(() => {
	const {
		contextDefaultTimeRange,
		getFilterOptions,
		insightFilters,
		setContextTimeRange,
		setInsightFilters,
	} = useInsightCollectionEditContext();

	const handleFilterChange = useCallback(
		newFilters => {
			setInsightFilters(newFilters);
		},
		[setInsightFilters]
	);

	const { data, error, loading } = useDataProviderSubscribe(
		'1cdd0c2d-9c4b-4af4-b32a-50fba5d2d098',
		'07dbb7ff-c2ad-460d-a34c-20adcef062c0'
	);

	if (error) {
		<BtError
			title="Retrieval Error"
			message="An error occurred when attempting to retrieve the table data."
		/>;
	}

	if (loading) {
		return <BtLoading />;
	}

	return (
		<BtSelectionTable
			columns={columns}
			data={data}
			defaultTimeRange={contextDefaultTimeRange}
			enableFiltering
			enableGeneralSorting
			enablePagination
			filters={insightFilters}
			getFilterOptions={getFilterOptions}
			idField="uuid"
			onChangeFilters={handleFilterChange}
			onChangeTimeRange={setContextTimeRange}
		/>
	);
});

export default Filtering;
