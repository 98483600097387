import { useCallback, useEffect, useRef, useState } from 'react';

export const useDebounce = ms => {
	const [waiting, setWaiting] = useState(false);
	const ref = useRef({
		delay: Number(ms) || 1000,
		unmounted: false,
		timeout: null,
	});

	const debounceWrap = useCallback(
		(fn, ...values) => {
			if (ref.current?.timeout) {
				clearTimeout(ref.current.timeout);
			}

			setWaiting(true);
			const timeout = setTimeout(() => {
				setWaiting(false);
				fn(...values);
			}, ref.current.delay);

			ref.current.timeout = timeout;
		},
		[setWaiting]
	);

	useEffect(() => {
		return () => {
			if (ref.current?.timeout) {
				// eslint-disable-next-line
				clearTimeout(ref.current.timeout);
			}
		};
	}, []);

	return { debounce: debounceWrap, waiting: waiting };
};
