import React from 'react';
import { Chart } from '../Components/Chart';
import { RADIAL_BAR_CHART } from '../Constants/constants';
import propTypes from 'prop-types';

export function RadialBarChart({ data, children }) {
	return (
		<Chart data={data} type={RADIAL_BAR_CHART}>
			{children}
		</Chart>
	);
}

RadialBarChart.propTypes = {
	data: propTypes.array,
	children: propTypes.array,
};
