import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Color from 'color';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { format } from 'date-fns';
import {
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Popover,
	styled,
	Tooltip,
	Typography,
} from '@mui/material';
import MoreIcon from '@mui/icons-material/MoreHoriz';

import BtConfirmDialog from '../BtConfirmDialog';
import CommentField from './CommentField';
import { useCommentsContext } from './CommentsContext';

const Container = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.comment,
	borderRadius: 8,
	display: 'flex',
	flexDirection: 'column',
	marginBottom: '0.5em',
	padding: '0.4em 1em 1em',
	position: 'relative',
	width: '100%',

	'&:hover .Comment-EditButton': {
		opacity: 1,
	},
}));

const EditableIndicator = styled('div')(({ theme }) => ({
	alignItems: 'center',
	alignSelf: 'center',
	display: 'flex',
	height: 12,
	justifyContent: 'center',
	marginLeft: '0.6rem',
	width: 12,

	'& > span': {
		backgroundColor: theme.palette.indicators.info.main,
		borderRadius: 3,
		height: 6,
		minWidth: 6,
		width: 6,
	},
}));

const EditButtonContainer = styled('div')(({ show, theme }) => ({
	background: `radial-gradient(circle, ${theme.palette.comment} 0%, ${
		theme.palette.comment
	} 40%, ${Color(theme.palette.comment)
		.alpha(0)
		.toString()} 70%)`,
	position: 'absolute',
	opacity: show ? 1 : 0,
	right: 2,
	top: 2,

	'&:focus-within': {
		opacity: 1,
	},
}));

const optionIconStyle = {
	marginRight: '0.5em',
};

export default function Comment({ comment }) {
	const {
		availability,
		isEditablePredicate,
		markAsNewPredicate,
		modificationMutator,
		newCommentMessage,
		onEditComment,
		onDeleteComment,
		renderAdornment,
	} = useCommentsContext();
	const optionsRef = useRef();

	const { comment: commentText, timestamp, user } = comment;

	const { userName } = user;

	const [editMode, setEditMode] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showOptions, setShowOptions] = useState(false);

	const handleSubmit = useCallback(
		commentEdit => {
			const newComment = {
				...comment,
				comment: commentEdit,
				timestamp: +new Date(),
			};

			onEditComment({
				...newComment,
				...(modificationMutator?.(newComment) ?? {}),
			});

			setEditMode(false);
		},
		[comment, modificationMutator, onEditComment]
	);

	const editable = useMemo(
		() => isEditablePredicate?.(comment) && availability === 'editable',
		[availability, comment, isEditablePredicate]
	);

	return (
		<>
			<Container>
				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						justifyContent: 'space-between',
						marginBottom: '0.8em',
					}}
				>
					<div style={{ display: 'flex', margin: '0.4em 0 0' }}>
						<Typography
							style={{ fontWeight: 'bold', marginRight: '0.5ch' }}
							variant="subtitle2"
						>
							{userName}
						</Typography>
						<Typography variant="subtitle2">{`• ${format(
							timestamp || 0,
							'dd MMM yyyy, HH:mm'
						)}`}</Typography>
						{markAsNewPredicate?.(comment) && (
							<Tooltip
								disableInteractive
								title={newCommentMessage ?? 'New comment'}
							>
								<EditableIndicator>
									<span />
								</EditableIndicator>
							</Tooltip>
						)}
					</div>
					<div style={{ marginTop: '0.4em' }}>
						{renderAdornment && renderAdornment(comment)}
					</div>
				</div>
				{!editMode && (
					<Typography
						sx={{
							// retain user spacing
							whiteSpace: 'pre-wrap',
							// break long words into multiple lines
							wordBreak: 'break-word',
						}}
					>
						{commentText}
					</Typography>
				)}
				{editMode && (
					<CommentField
						autoFocus
						defaultValue={commentText}
						editMode
						onCancel={() => setEditMode(false)}
						onSubmit={handleSubmit}
					/>
				)}
				{editable &&
					!editMode && (
						<EditButtonContainer
							className="Comment-EditButton"
							show={+showOptions}
						>
							<Tooltip disableInteractive title="Options">
								<IconButton
									ref={optionsRef}
									onMouseUp={event =>
										event.currentTarget.blur()
									}
									onClick={() => setShowOptions(true)}
									size="small"
								>
									<MoreIcon />
								</IconButton>
							</Tooltip>
						</EditButtonContainer>
					)}
			</Container>
			<Popover
				anchorEl={optionsRef?.current}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				onClose={() => setShowOptions(false)}
				open={showOptions}
			>
				<List style={{ minWidth: 110 }}>
					<ListItem disablePadding>
						<ListItemButton
							onClick={() => {
								setShowOptions(false);
								setEditMode(true);
							}}
						>
							<EditIcon style={optionIconStyle} />
							<ListItemText primary="Edit" />
						</ListItemButton>
					</ListItem>
					<ListItem disablePadding>
						<ListItemButton
							onClick={() => {
								setShowOptions(false);
								setShowDeleteModal(true);
							}}
						>
							<DeleteIcon style={optionIconStyle} />
							<ListItemText primary="Delete" />
						</ListItemButton>
					</ListItem>
				</List>
			</Popover>
			<BtConfirmDialog
				action={() => onDeleteComment(comment)}
				ActionIcon={<DeleteIcon />}
				isDestructive
				open={showDeleteModal}
				onClose={() => setShowDeleteModal(false)}
				prompt="Are you sure you want to delete this comment?"
				title="Delete Comment"
				verb="delete"
			/>
		</>
	);
}

Comment.propTypes = {
	comment: PropTypes.shape({
		comment: PropTypes.string.isRequired,
		timestamp: PropTypes.number.isRequired,
		user: PropTypes.shape({
			userName: PropTypes.string.isRequired,
			uuid: PropTypes.string.isRequired,
		}),
		uuid: PropTypes.string.isRequired,
	}).isRequired,
};
