import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import configureStore, { history } from './configureStore';
import { Amplify } from 'aws-amplify';
import config from './config';

import App from './App';

const store = configureStore();
const render = () => {
	ReactDOM.render(
		<Provider store={store}>
			<App history={history} />
		</Provider>,
		document.getElementById('root')
	);
};

Amplify.configure({
	Auth: {
		mandatorySignIn: true,
		region: config.cognito.REGION,
		userPoolId: config.cognito.USER_POOL_ID,
		identityPoolId: config.cognito.IDENTITY_POOL_ID,
		userPoolWebClientId: config.cognito.APP_CLIENT_ID,
	},
});

render();

// Hot reloading
if (module.hot) {
	// Reload components
	module.hot.accept('./App', () => {
		render();
	});
}
