import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { pipelineSchema as transformPipelineSchema } from 'mongodb_query/src';

import {
	dataSetGet,
	dataSetStoreQueryGet,
	dataViewQueryGet,
} from '../../../../API';
import { resourceGroupGet } from '../../../../API/resourceGroups';
import useFetch from '../../../../hooks/useFetch';

import { QueryBuilderDataContext, useQueryBuilderVariableContext } from '.';
import { parseSchema } from '../../../../utils/yup-ast';
import { dataViewGet } from '../../../../API/DataManager/data_views';
import { traverseAndReplaceVariables } from '../processing';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const resourceGroups = [
	{
		key: 'datasets',
		name: 'Data Set',
	},
	{
		key: 'data_views',
		name: 'Data View',
	},
];

export default function BtQueryBuilderDataProvider(props) {
	const {
		resourceGroup: initialResourceGroup,
		resource: initialResource,
		allowResourceGroupChange,
		allowResourceChange,
		children,
	} = props;

	const { variables } = useQueryBuilderVariableContext();

	const [resourceGroup, setResourceGroup] = useState(
		resourceGroups.find(k => k.key === initialResourceGroup) || null
	);

	const {
		loading: loadingResources,
		data: resources,
		error: errorResources,
		request: getResources,
	} = useFetch(resourceGroupGet);

	const [resourceUuid, setResourceUuid] = useState(
		resourceGroup ? initialResource : null
	);

	const loadedResourceGroup = useRef();

	useEffect(
		() => {
			// Get resources for selected method from API
			if (
				resourceGroup?.key &&
				(!resources ||
					resourceGroup.key !== loadedResourceGroup.current)
			) {
				loadedResourceGroup.current = resourceGroup.key;
				getResources({
					group: resourceGroup.key,
				});
			}
		},
		[resourceGroup, resources, getResources]
	);

	const loadedDataSet = useRef('');

	const [resourceData, setResourceData] = useState({
		name: '',
		uuid: resourceUuid,
		configSchema: null,
		configSchemaLoading: false,
		configSchemaError: null,
		collection: null,
		collectionLoading: false,
		collectionError: null,
	});

	useEffect(
		() => {
			if (
				resourceGroup &&
				resourceUuid &&
				loadedDataSet.current !== resourceUuid
			) {
				const baseQuery = [{ $limit: 10 }];

				loadedDataSet.current = resourceUuid;
				setResourceData(r => ({
					...r,
					uuid: resourceUuid,
					collectionLoading: true,
					configSchemaLoading: true,
				}));

				if (resourceGroup.key === 'data_views') {
					dataViewGet({
						dataViewUuid: resourceUuid,
					}).then(data => {
						let configSchema =
							data.config ?? parseSchema(data.schema);

						setResourceData(r => ({
							...r,
							name: data.name,
							description: data.description,
							configSchema: configSchema,
							configSchemaLoading: false,
							configSchemaError: false,
						}));
					});

					dataViewQueryGet({
						dataViewUuid: resourceUuid,
						query: {
							query: baseQuery,
						},
					})
						.then(data => {
							setResourceData(r => ({
								...r,
								collection: data,
								collectionLoading: false,
								collectionError: false,
							}));
						})
						.catch(error => {
							setResourceData(r => ({
								...r,
								collectionLoading: false,
								collectionError: error,
							}));
						});
				}

				if (resourceGroup.key === 'datasets') {
					dataSetGet({
						dataSetUuid: resourceUuid,
					})
						.then(data => {
							let configSchema =
								data.config ?? parseSchema(data.schema);

							try {
								transformPipelineSchema(configSchema, [
									{
										$addFields: {
											uuid: 'uuid string',
											data_set: 'uuid string',
											insert_timestamp: 100,
											update_timestamp: 100,
											primaryTimeKey: 'a string',
											primaryValueKey: 'a string',
										},
									},
								]).then(schema => {
									setResourceData(r => ({
										...r,
										name: data.name,
										description: data.description,
										configSchema: schema,
										configSchemaLoading: false,
										configSchemaError: false,
									}));
								});
							} catch (err) {
								console.log(
									'error adding defaults to dataset configSchema',
									err
								);
							}
						})
						.catch(error => {
							setResourceData(r => ({
								...r,
								configSchemaLoading: false,
								configSchemaError: error,
							}));
						});

					dataSetStoreQueryGet({
						dataSetUuid: resourceUuid,
						query: {
							query: baseQuery,
						},
					})
						.then(data => {
							setResourceData(r => ({
								...r,
								collection: data,
								collectionLoading: false,
								collectionError: false,
							}));
						})
						.catch(error => {
							setResourceData(r => ({
								...r,
								collectionLoading: false,
								collectionError: error,
							}));
						});
				}
			}
		},
		[resourceGroup, resourceUuid]
	);

	const handleDataQuery = useCallback(
		query => {
			if (resourceUuid) {
				let q = query;

				if (variables) {
					q = traverseAndReplaceVariables(q, variables);
				}

				q = q.concat([{ $limit: 10 }]);

				if (resourceGroup.key === 'datasets') {
					return dataSetStoreQueryGet({
						dataSetUuid: resourceUuid,
						// sourceList,
						// timeRange,
						// primaryKey,
						query: {
							query: q,
						},
					});
				} else if (resourceGroup.key === 'data_views') {
					return dataViewQueryGet({
						dataViewUuid: resourceUuid,
						// filters,
						// timeRange,
						// primaryKey,
						query: {
							query: q,
						},
					});
				}
			}

			return new Promise(res => res());
		},
		[resourceGroup, resourceUuid, variables]
	);

	const handleResourceGroupChange = useCallback(value => {
		if (value) {
			const rg = resourceGroups.find(k => k.key === value);
			setResourceGroup(rg);
			setResourceUuid('');
		}
	}, []);

	const handleResourceChange = useCallback(
		value => {
			if (value) {
				const res = resources.find(k => k.uuid === value);
				if (res) {
					setResourceUuid(value);
				}
			}
		},
		[resources]
	);

	const loading =
		resourceData?.collectionLoading || resourceData?.configSchemaLoading;
	const ready = resourceData?.collection && resourceData?.configSchema;
	const error =
		resourceData?.collectionError || resourceData?.configSchemaError;

	return (
		<QueryBuilderDataContext.Provider
			value={{
				allowResourceChange,
				allowResourceGroupChange,
				resourceGroups: resourceGroups,
				onResourceGroupChange: handleResourceGroupChange,
				resourceGroup: resourceGroup,

				resources: resources,
				loadingResources: loadingResources,

				onResourceChange: handleResourceChange,
				resource: resourceData,
				resourceUuid: resourceUuid,

				dataContextActive: typeof initialResourceGroup === 'string',

				onDataQuery: handleDataQuery,
			}}
		>
			{loading ? (
				<Box
					sx={{
						textAlign: 'center',
						minHeight: { md: '70vh' },
					}}
				>
					<Typography variant="subtitle1">
						Fetching collection data
					</Typography>
					<div>
						<CircularProgress />
					</div>
				</Box>
			) : error && !ready ? (
				<Box sx={{ textAlign: 'center', minHeight: { md: '70vh' } }}>
					<Typography variant="subtitle1">
						Something went wrong loading the resource data
					</Typography>
				</Box>
			) : (
				children
			)}
		</QueryBuilderDataContext.Provider>
	);
}

BtQueryBuilderDataProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	resourceGroup: PropTypes.oneOf(resourceGroups.map(r => r.key)),
	resource: PropTypes.string,
	allowResourceGroupChange: PropTypes.bool,
	allowResourceChange: PropTypes.bool,
};
