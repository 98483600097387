import {
	uk_mobile_regex,
	user_names_regex,
	user_name_regex,
	initials_regex,
} from '../../utils/regexLibrary';

const yup = require('yup');

export const platformAdminOrgGetSchema = yup.object({
	uuid: yup
		.string()
		.uuid()
		.required(''),
	name: yup.string().default(''),
	initials: yup.string().default(''),
	tags: yup
		.array()
		.of(
			yup.object({
				uuid: yup
					.string()
					.uuid()
					.required(),
				name: yup.string().required(),
				displayColour: yup.string().required(),
				status: yup.string().required(),
			})
		)
		.required(),
	regionalSettings: yup
		.object({
			currency: yup.string().default('GBP'),
			dateFormat: yup.string().default('dd/MM/yyyy'),
			firstWeekDay: yup.string().default('MONDAY'),
			numberFormat: yup.string().default('1,000,000.00'),
		})
		.default({
			currency: 'GBP',
			dateFormat: 'dd-MMM-yyyy',
			firstWeekDay: 'MONDAY',
			numberFormat: '1,000,000.00',
		}),
	logo32: yup.string().nullable(null),
	roles: yup
		.array()
		.required()
		.of(
			yup.object().shape({
				uuid: yup
					.string()
					.uuid()
					.required(),
				name: yup.string().required(),
				managedBy: yup.string().required(),
				description: yup.string().required(),
				create_timestamp: yup.number().required(),
				modify_timestamp: yup.number().required(),
				create_by: yup
					.string()
					.uuid()
					.required(),
				modify_by: yup
					.string()
					.uuid()
					.required(),
				users: yup
					.array()
					.required()
					.of(yup.string().uuid()),
				permissions: yup.array().of(
					yup.object().shape({
						uuid: yup
							.string()
							.uuid()
							.required(),
						group: yup.string().required(),
						method: yup.string().required(),
						resources: yup
							.array()
							.of(yup.string())
							.required(),
					})
				),
			})
		),
});

export const platformAdminOrgListGetSchema = yup.array().of(
	yup.object({
		name: yup.string().default(''),
		uuid: yup
			.string()
			.uuid()
			.required(),
		logo32: yup.string().nullable(null),
		initials: yup.string().default('new'),
	})
);

export const platformAdminOrgCreateResponseSchema = yup
	.object()
	.required()
	.shape({
		uuid: yup.string().required(),
	});

export const platformAdminOrgDeleteResponseSchema = yup
	.object()
	.required()
	.shape({
		dataSetStoreCount: yup.number().required(),
		dataSetCount: yup.number().required(),
		dataStreamStoreCount: yup.number().required(),
		dataStreamCount: yup.number().required(),
		deviceCount: yup.number().required(),
		insightCount: yup.number().required(),
		reporterCount: yup.number().required(),
		tagCount: yup.number().required(),
		templateCount: yup.number().required(),
		submissionCount: yup.number().required(),
		organisationCount: yup.number().required(),
	});

export const platformAdminOrgSubscriptionAddSchema = yup
	.object()
	.required()
	.shape({
		uuid: yup
			.string()
			.uuid()
			.required(),
		renewalDate: yup.number().required(),
		renewalCost: yup.string().nullable(),
		maxUsers: yup.number().required(),
	});

export const platformAdminOrgSubscriptionUpdateSchema = yup
	.object()
	.required()
	.shape({
		renewalDate: yup.number().required(),
		renewalCost: yup.string().nullable(),
		maxUsers: yup.number().required(),
	});

export const platformAdminUserListGetSchema = yup.array().of(
	yup.object({
		uuid: yup
			.string()
			.uuid()
			.required(),
		status: yup.string().required(),
		user_name: yup.string().required(),
		first_name: yup.string().required(),
		last_name: yup.string().required(),
		initials: yup.string().required(),
		email: yup.string().required(),
		create_timestamp: yup.number().required(),
		modify_timestamp: yup.number().required(),
		last_login_timestamp: yup.number().required(),
	})
);

export const platformAdminOrgUserGetSchema = yup.object({
	uuid: yup
		.string()
		.uuid()
		.required(),
	user_name: yup.string().required(),
	first_name: yup.string().required(),
	last_name: yup.string().required(),
	email: yup
		.string()
		.email()
		.required(),
	initials: yup.string().required(),
	avatar: yup.string().nullable(),
	last_login_timestamp: yup.number().required(),
	status: yup.string().required(),
	tags: yup
		.array()
		.of(
			yup.object({
				tagGroupUuid: yup
					.string()
					.uuid()
					.required(),
				tagValueUuid: yup
					.string()
					.uuid()
					.required(),
				name: yup.string().required(),
			})
		)
		.default([]),
});

export const platformAdminRoleGetListSchema = yup.array().of(
	yup.object({
		uuid: yup
			.string()
			.uuid()
			.required(),
		name: yup.string().required(),
		description: yup.string().required(),
		userCount: yup.number().required(),
	})
);

export const platformAdminRoleAddSchema = yup.object({
	name: yup
		.string()
		.min(3)
		.max(30)
		.required(),
	description: yup
		.string()
		.max(256)
		.required(),
});

export const platformAdminOrgInviteUserSchema = yup.object({
	mobile_required: yup.boolean().required(),
	first_name: yup
		.string()
		.min(2)
		.max(30)
		.required()
		.matches(user_names_regex.regex),
	last_name: yup
		.string()
		.min(2)
		.max(30)
		.required()
		.matches(user_names_regex.regex),
	email: yup
		.string()
		.email()
		.required(),
	mobile_required: yup.boolean().required(),
});
