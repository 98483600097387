import { useCallback } from 'react';

import useAvailableToUser from './useAvailableToUser';

export default function useNavRedactor() {
	const checkAvailability = useAvailableToUser();

	return useCallback(
		navItems => {
			return (navItems || []).reduce((accumulator, navItem) => {
				const {
					requiredPermissionsAll,
					requiredPermissionsAny,
					validSubscriptionsAny,
				} = navItem;
				const availability = checkAvailability({
					requiredPermissionsAll,
					requiredPermissionsAny,
					validSubscriptionsAny,
				});
				const {
					available,
					expiredSubscriptions,
					missingPermissionsAll,
					missingPermissionsAny,
					missingSubscriptions,
				} = availability;

				if (available) {
					return [...accumulator, navItem];
				}

				if (
					!missingPermissionsAll &&
					!missingSubscriptions &&
					!missingPermissionsAny &&
					expiredSubscriptions
				) {
					return [
						...accumulator,
						{ ...navItem, subscriptionExpired: true },
					];
				}

				return [...accumulator];
			}, []);
		},
		[checkAvailability]
	);
}
