import { createContext, useCallback, useContext, useMemo } from 'react';
import { useWorkflowSessionContext } from './WorkflowSessionContext';

const WorkflowGroupContext = createContext();

export const useWorkflowGroupContext = () => useContext(WorkflowGroupContext);

export const WorkflowGroupContextProvider = ({
	children,
	template,
	...otherValues
}) => {
	const { removeGroupElement } = useWorkflowSessionContext();

	const components = template?.children;
	const { name, page } = components[0] || {};

	const { primary, primaryAnswers, primaryLock } = useMemo(
		() => page?.children?.find(({ primary }) => primary) || {},
		[page]
	);

	const removeElement = useCallback(
		elementUuid => removeGroupElement(template.uuid, elementUuid),
		[removeGroupElement, template]
	);

	return (
		<WorkflowGroupContext.Provider
			value={{
				groupName: name,
				primary,
				primaryAnswers,
				primaryLock,
				removeElement,
				...otherValues,
			}}
		>
			{children}
		</WorkflowGroupContext.Provider>
	);
};
