import React, { useEffect, useMemo } from 'react';

import useFetch from '../../hooks/useFetch';
import { tagGroupGet } from '../../API';

import GroupRemoveIcon from '@mui/icons-material/GroupRemove';

import BtListComposer from './BtListComposer';
import { Skeleton } from '@mui/material';

export default function BtUserGroupList({
	currentUserGroups,
	onUserGroupListUpdate,
}) {
	const {
		data: userGroupList,
		error: userGroupError,
		loading: loadingUserGroups,
		request: getUserGroups,
	} = useFetch(tagGroupGet);
	useEffect(
		() => {
			if (!userGroupList) {
				getUserGroups({
					tagGroupUuid: '5ee3f79d-662b-465c-9537-8a64d190762c',
				});
			}
		},
		[getUserGroups, userGroupList]
	);
	const userGroups = useMemo(
		() =>
			(userGroupList?.values || []).filter(({ uuid }) =>
				(currentUserGroups || []).includes(uuid)
			),
		[currentUserGroups, userGroupList]
	);

	const addUserGroup = userToAdd => {
		var newUserList = [...currentUserGroups];
		newUserList.push(userToAdd.uuid);
		onUserGroupListUpdate(newUserList);
	};
	const removeUserGroup = userToRemove => {
		onUserGroupListUpdate(
			[...currentUserGroups].filter(user => user !== userToRemove.uuid)
		);
	};

	if (loadingUserGroups) {
		return <Skeleton style={{ width: '100%' }} />;
	}

	return (
		<BtListComposer
			allItems={userGroupList?.values || []}
			confirmDeletion
			deleteable
			deleteVerb="Remove"
			items={userGroups}
			primaryField="name"
			DeleteIcon={<GroupRemoveIcon />}
			sortComparator={(a, b) => a?.name?.localeCompare(b?.name)}
			subject="user group"
			onItemAdd={addUserGroup}
			onItemDelete={removeUserGroup}
		/>
	);
}
