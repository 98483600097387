import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/styles';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import ErrorIcon from '@mui/icons-material/ErrorOutline';
import CopyToClipboardIcon from '@mui/icons-material/ContentPasteGo';

import { a11yProps, BtTab, BtTabBar, BtTabPanel } from '../../BtTabView';
import DataConfig from '../../DataConfig/DataConfig';
import DataSetJsonView from '../../../../views/DataManager/DataSources/data-set/record-list/DataSetJsonView';
import DataSetNode from '../../../../views/DataManager/DataSources/data-set/record-editor/DataSetNode';
import { DataSetEditorContextProvider } from '../../../../views/DataManager/contexts/DataSetEditorContext';

const PanelContainer = styled(Box)(() => ({
	height: '100%',
	overflow: 'scroll',
	position: 'relative',
}));

export default function BtQueryBuilderStageOutputPreview(props) {
	const {
		title,
		disableCollection,
		collectionError,
		collectionLoading,
		collectionStr,
		collection,
		configSchema,
		configSchemaStr,
		configSchemaError,
		configSchemaLoading,
		// collectionRenderMode,
	} = props;

	const collectionRenderMode = 'json-view';

	const [resultViewMode, setResultViewMode] = useState(0);

	const onResultViewModeChange = useCallback(
		(_tab, selectedTabIndex) => setResultViewMode(selectedTabIndex),
		[]
	);

	return (
		<Box
			sx={{
				position: 'relative',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Box>
				<Typography variant="subtitle1">{title}</Typography>
				<BtTabBar
					currentTab={resultViewMode}
					onTabChange={onResultViewModeChange}
					style={{
						marginBottom:
							collectionLoading || configSchemaLoading
								? '0em'
								: '1.25em',

						position: 'sticky',
					}}
				>
					{!disableCollection && (
						<BtTab
							label="Collection"
							{...a11yProps(0)}
							icon={
								collectionError ? (
									<ErrorIcon fontSize="small" color="error" />
								) : null
							}
							iconPosition="end"
						/>
					)}
					<BtTab
						label="Config Schema"
						{...a11yProps(disableCollection ? 0 : 1)}
						icon={
							configSchemaError ? (
								<ErrorIcon fontSize="small" color="error" />
							) : null
						}
						iconPosition="end"
					/>
				</BtTabBar>
				{(collectionLoading || configSchemaLoading) && (
					<Box sx={{ mb: '1em' }}>
						<LinearProgress />
					</Box>
				)}
			</Box>
			<Box
				sx={{
					flex: 1,
					overflow: 'auto',
				}}
			>
				<Box
					sx={{
						overflow: 'scroll',
						...(collectionRenderMode !== 'json-view' && {
							'& .MuiCollapse-wrapperInner': {
								width: 'auto',
							},
							'& .MuiCollapse-wrapper': {
								width: 'auto',
							},
							'& .MuiCollapse-wrapperInner > div > div': {
								width: 'auto',
							},
						}),
					}}
				>
					{!disableCollection && (
						<BtTabPanel currentTab={resultViewMode} index={0}>
							<PanelContainer>
								{collectionError && (
									<Alert severity="error">
										{collectionError + ''}
									</Alert>
								)}

								{collection && (
									<>
										<Typography variant="caption">
											<em>
												{collection?.length === 10
													? 'Limited to first 10 items'
													: collection?.length +
													  ' items'}
											</em>
										</Typography>
										{collection.map((item, idx) => (
											<Box key={idx} sx={{ mb: 1, p: 1 }}>
												{collectionRenderMode ===
												'json-view' ? (
													<DataSetJsonView
														data={item}
														name={false}
														displayObjectSize={
															false
														}
														enableClipboard={false}
													/>
												) : (
													<DataSetEditorContextProvider
														// make sure the node refreshes when query def changes
														key={collectionStr}
													>
														<DataSetNode
															disabled={true}
															data={item}
															schema={
																configSchema
															}
															title={
																'Collection item'
															}
															subTitle={idx + ''}
														/>
													</DataSetEditorContextProvider>
												)}
											</Box>
										))}
										{!collectionError &&
											!collectionError &&
											collection.length > 0 && (
												<IconButton
													onClick={() => {
														const str =
															collectionStr ||
															JSON.stringify(
																collection
															);

														navigator?.clipboard?.writeText?.(
															str
														);
													}}
													sx={{
														position: 'absolute',
														right: 20,
														top: 0,
													}}
												>
													<CopyToClipboardIcon />
												</IconButton>
											)}
									</>
								)}
							</PanelContainer>
						</BtTabPanel>
					)}
					<BtTabPanel
						currentTab={resultViewMode}
						index={disableCollection ? 0 : 1}
					>
						<PanelContainer>
							{configSchemaError && (
								<Alert sx={{}} severity="error">
									{configSchemaError + ''}
								</Alert>
							)}
							<Box sx={{ position: 'relative' }}>
								{configSchema && (
									<>
										<DataConfig
											readOnly={true}
											config={configSchema}
										/>
										{!configSchemaError && (
											<IconButton
												onClick={() => {
													navigator?.clipboard?.writeText?.(
														configSchemaStr
													);
												}}
												sx={{
													position: 'absolute',
													right: 20,
													top: 0,
												}}
											>
												<CopyToClipboardIcon />
											</IconButton>
										)}
									</>
								)}
							</Box>
						</PanelContainer>
					</BtTabPanel>
				</Box>
			</Box>
		</Box>
	);
}

BtQueryBuilderStageOutputPreview.propTypes = {
	title: PropTypes.string,
	disableCollection: PropTypes.bool,
	collectionError: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
		PropTypes.symbol,
	]),
	collectionLoading: PropTypes.bool,
	collectionStr: PropTypes.string,
	collection: PropTypes.array,
	configSchemaStr: PropTypes.string,
	configSchema: PropTypes.object,
	configSchemaError: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
		PropTypes.symbol,
	]),
	configSchemaLoading: PropTypes.bool,
	collectionRenderMode: PropTypes.oneOf(['json-view', 'dataset']),
};
