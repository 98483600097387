import { useMemo } from 'react';

import dot from 'dot-object';
import _ from 'lodash';

import { useDataConfigContext } from '../DataConfigContext';

export default function useGetSelectionData() {
	const { editedConfig, selection } = useDataConfigContext();

	return useMemo(
		() => {
			let config,
				parent,
				parentIsObject = false,
				parentLocation,
				siblingKeys,
				key;

			config = dot.pick(selection || '', editedConfig || {});

			if (config && selection.includes('.')) {
				const lastDotIndex = selection.lastIndexOf('.');

				key = selection.substring(lastDotIndex + 1, selection.length);

				parentLocation = selection.substring(0, lastDotIndex);

				parent = dot.pick(parentLocation || '', editedConfig || {});
			}

			if (parentLocation?.endsWith('objectContent')) {
				parentIsObject = true;

				siblingKeys = _.pull(Object.keys(parent), key);
			}

			return {
				...(config ? { config } : {}),
				...(parent ? { parent, parentLocation } : {}),
				...(siblingKeys ? { siblingKeys } : {}),
				...(key ? { key } : {}),
				location: selection,
				parentIsObject,
			};
		},
		[editedConfig, selection]
	);
}
