import { Grid } from '@mui/material';
import { useTheme } from '@mui/styles';

export default function NativeScreen({ backgroundColor, nativeDisplay, info }) {
	const THEME = useTheme();
	return (
		<div
			style={{
				height: '100%',
				minHeight: 'calc(100vh - 64px)',
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: backgroundColor,
				// screenData.background_color.length > 0
				// 	? screenData.background_color
				// 	: THEME.palette.background.insights,
			}}
		>
			<Grid container spacing={0}>
				{/* <Grid item xs={4.5}>
					<div
						style={{
							height: '100%',
							width: '100%',
							// backgroundColor: 'lavender',
						}}
					/>
				</Grid> */}
				{/* <Grid item xs={3}> */}
				<Grid item xs={6}>
					<div
						style={{
							height: '100%',
							width: '100%',
							// backgroundColor: 'beige',
							display: 'flex',
							alignItems: 'flex-end',
							flexDirection: 'column',
							paddingRight: 20,
						}}
					>
						<div
							style={{
								height: 'calc(100vh - 164px)',
								width: 'calc((100vh - 164px) * 0.462 )',
								borderRadius: 'calc((100vh - 164px) * 0.065 )',
								// backgroundColor: 'lightgray',
								backgroundColor:
									THEME.palette.background.paperContrast,
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<div
								style={{
									backgroundColor: backgroundColor,
									// screenData.background_color.length > 0
									// 	? screenData.background_color
									// 	: THEME.palette.background.insights,
									// height: dims.height - 150,
									// width: dims.width - 20,
									height:
										'calc((100vh - 164px - ((100vh - 164px) * 0.190 )) )',
									width:
										'calc((100vh - 164px - ((100vh - 164px) * 0.024 )) * 0.450 )',
									// padding: 5,
									display: 'flex',
									flexDirection: 'column',
									overflow: 'scroll',
								}}
							>
								{nativeDisplay}
							</div>
						</div>
					</div>
				</Grid>
				{/* <Grid item xs={4.5}> */}
				<Grid item xs={6}>
					<div
						style={{
							height: '100%',
							width: '75%',
							paddingRight: 150,
							paddingTop: 50,
							// backgroundColor: 'darkcyan',
						}}
					>
						{info}
					</div>
				</Grid>
			</Grid>
		</div>
	);
}
