import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useAppContext, useNavContext } from '../../context/ContextManager';

// COMPONENTS
// import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
// import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// CUSTOM COMPONENTS
import {
	ListTable,
	ListRow,
	ListHeader,
	ListCell,
} from '../../components/generic/ListTable';
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from '../../components/generic/CustomAccordion';
import SectionHeader from '../../components/generic/SectionHeader';

// Data for landing page selection table and contextual navigation
import IoT_options from './IoTOptions';

import IoT_breadcrumbs from './IoTBreadcrumbs';

import IoT_nav_item from './IoTNavItem';

// ICONS
// import AddCircleIcon from '@mui/icons-material/AddCircle';

export default function ContainerTaskRevision({ match }) {
	const [task, setTask] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const { appOrg } = useAppContext();
	const { setContextualNav } = useNavContext();
	const { setBreadcrumbs } = useNavContext();

	var task_uuid = match.params.uuid;
	var task_revision = match.params.revision;

	// const task_uuid = match.params.uuid;
	// const task_revision = match.params.revision;

	// Set breadcrumbs
	useEffect(
		() => {
			// to use task name
			setBreadcrumbs([
				...IoT_breadcrumbs,
				{ text: 'Container Tasks', link: '/ContainerTaskList' },
				{ text: task.name, link: `/ContainerTask/${task_uuid}` },
				{ text: task_revision, link: '' },
			]);
		},
		[task.name, task_uuid, task_revision, setBreadcrumbs]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([...IoT_nav_item, ...IoT_options]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	const [expanded, setExpanded] = useState('');
	const handleChangeAccordion = panel => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	useEffect(
		() => {
			var req_url = `${
				process.env.REACT_APP_API_SERVER_URL
			}/v1/container_task/${task_uuid}/${task_revision}`;

			Auth.currentSession().then(user_session => {
				let user_token = user_session.getAccessToken();
				fetch(req_url, {
					method: 'GET',
					headers: {
						Authorization: user_token.jwtToken,
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Organisation: appOrg.uuid,
					},
				})
					.then(res => res.json())
					.then(response => {
						console.log(response.task);
						setTask(response.task);
						setIsLoading(false);
					})
					.catch(error => {
						console.log(error);
						setIsLoading(false);
					});
			});
		},
		[appOrg.uuid, task_revision, task_uuid]
	);

	return (
		<>
			{isLoading && (
				<Container
					maxWidth="lg"
					sx={{ display: 'flex', flexDirection: 'column' }}
				>
					<Typography variant="h3" sx={{ m: 'auto' }}>
						Loading...
					</Typography>
					<CircularProgress sx={{ m: 'auto' }} size={'120px'} />
				</Container>
			)}

			{!isLoading && (
				<Container maxWidth="lg">
					<SectionHeader>Task Details</SectionHeader>
					<Container>
						<ListTable>
							<ListRow>
								<ListHeader>Task Name:</ListHeader>
								<ListCell>{task.name}</ListCell>
							</ListRow>
							<ListRow>
								<ListHeader>Revision:</ListHeader>
								<ListCell>{task_revision}</ListCell>
							</ListRow>
							<ListRow>
								<ListHeader>Description:</ListHeader>
								<ListCell>{task.description}</ListCell>
							</ListRow>
						</ListTable>
					</Container>

					{/*JSON.stringify(task)*/}

					<SectionHeader variant="h4">Containers</SectionHeader>
					{task.containers.map((container, index) => (
						<Accordion
							expanded={expanded === container.name}
							onChange={handleChangeAccordion(container.name)}
							key={index}
						>
							<AccordionSummary
								aria-controls="panel1d-content"
								id="panel1d-header"
							>
								<Typography variant="h5">
									{container.name}
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<SectionHeader>Container Details</SectionHeader>
								<ListTable>
									<ListRow>
										<ListHeader>Name:</ListHeader>
										<ListCell>{container.name}</ListCell>
									</ListRow>
									<ListRow>
										<ListHeader>Image:</ListHeader>
										<ListCell>{container.image}</ListCell>
									</ListRow>
								</ListTable>

								<SectionHeader>
									Registry Authentication
								</SectionHeader>
								{/*container.registry_authentication*/}
								{/*<FormGroup>
      								<FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
      								<FormControlLabel disabled control={<Checkbox />} label="Disabled" />
    							</FormGroup>*/}

								<SectionHeader>Memory Limit</SectionHeader>
								<ListTable>
									<ListRow>
										<ListHeader>Soft Limit:</ListHeader>
										<ListCell>
											{container.memory_soft_limit}
										</ListCell>
									</ListRow>
								</ListTable>

								<SectionHeader>Command</SectionHeader>
								{/*<ListRow><ListHeader>Command:</ListHeader><ListCell>{container.command}</ListCell></ListRow>*/}

								<SectionHeader>Links</SectionHeader>
								{container.links.map((link, index) => (
									<Typography variant="h6" key={index}>
										{link}
									</Typography>
								))}

								<SectionHeader>Environment</SectionHeader>
								<TableContainer component={Box}>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>Name</TableCell>
												<TableCell>Value</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{container.environment.map(
												(environment, index) => (
													<TableRow key={index}>
														<TableCell>
															{environment.name}
														</TableCell>
														<TableCell>
															{environment.value}
														</TableCell>
													</TableRow>
												)
											)}
										</TableBody>
									</Table>
								</TableContainer>

								<SectionHeader>Mount Points</SectionHeader>
								<TableContainer component={Box}>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>
													Container Path
												</TableCell>
												<TableCell>
													Source Path
												</TableCell>
												<TableCell>Read Only</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{container.mount_points.map(
												(mount_point, index) => (
													<TableRow key={index}>
														<TableCell>
															{
																mount_point.container_path
															}
														</TableCell>
														<TableCell>
															{
																mount_point.source_volume
															}
														</TableCell>
														<TableCell>
															{
																mount_point.read_only
															}
														</TableCell>
													</TableRow>
												)
											)}
										</TableBody>
									</Table>
								</TableContainer>

								<SectionHeader>Port Mappings</SectionHeader>
								<TableContainer component={Box}>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>Host Port</TableCell>
												<TableCell>
													Container Port
												</TableCell>
												<TableCell>Protocol</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{container.port_mappings.map(
												(port_mapping, index) => (
													<TableRow key={index}>
														<TableCell>
															{
																port_mapping.host_port
															}
														</TableCell>
														<TableCell>
															{
																port_mapping.container_port
															}
														</TableCell>
														<TableCell>
															{
																port_mapping.protocol
															}
														</TableCell>
													</TableRow>
												)
											)}
										</TableBody>
									</Table>
								</TableContainer>
							</AccordionDetails>
						</Accordion>
					))}

					<SectionHeader>Volumes</SectionHeader>
					{task.volumes.map((volume, index) => (
						<Container key={index}>
							<ListTable>
								<ListRow>
									<ListHeader>Name:</ListHeader>
									<ListCell>{volume.name}</ListCell>
								</ListRow>
								<ListRow>
									<ListHeader>Volume Type:</ListHeader>
									<ListCell>{volume.type}</ListCell>
								</ListRow>
								<ListRow>
									<ListHeader>Source Path:</ListHeader>
									<ListCell>{volume.source_path}</ListCell>
								</ListRow>
							</ListTable>
							<Divider />
						</Container>
					))}

					{/*<SelectionTableContainer component={Box}>
							<SelectionTable aria-label="Container task revision table">
								<SelectionTableHead>
									<SelectionTableHeadRow>
										<SelectionTableCell>Name</SelectionTableCell>
										<SelectionTableCell>Revision</SelectionTableCell>
										<SelectionTableCell>Last Updated</SelectionTableCell>
										<SelectionTableCell>Status</SelectionTableCell>
									</SelectionTableHeadRow>
								</SelectionTableHead>
								<SelectionTableBody>
								{
									task.revisions.map((task_rev, index) => (
										<SelectionTableRow key={index} onClick={onTaskClick(task_rev.revision)} title={task.name}>
											<SelectionTableCell component="th" scope="row">
												{task.name}
											</SelectionTableCell>
											<SelectionTableCell>{task_rev.revision}</SelectionTableCell>
											<SelectionTableCell>{new Date(task_rev.timestamp).toLocaleString()}</SelectionTableCell>
											<SelectionTableCell>{task_rev.status}</SelectionTableCell>
										</SelectionTableRow>
									))
								}
								</SelectionTableBody>
							</SelectionTable>
							</SelectionTableContainer>*/}
				</Container>
			)}
		</>
	);
}
