import React from 'react';
import { useComponentsContext } from '../Components/Chart';
import propTypes from 'prop-types';
import { useChartsContext } from '../contexts/ChartsContexts';
import { Tooltip } from '../Components/Tooltip';
import { useTheme } from '@mui/styles';

export function ChartContainer({ children }) {
	const { tooltipActive, tooltipData, tooltipLocation } = useChartsContext();
	const theme = useTheme();
	const {
		chartDimensions,
		margin,
		hasTooltip,
		tooltipContent,
	} = useComponentsContext();

	const scaleWidth =
		chartDimensions.width /
		(margin.left + margin.right + chartDimensions.width);
	const scaleHeight =
		chartDimensions.height /
		(margin.top + margin.bottom + chartDimensions.height);

	// console.log('children', children);
	return (
		<>
			<svg
				width={chartDimensions.width}
				height={
					chartDimensions.height < 6.5
						? chartDimensions.height
						: chartDimensions.height - 6.5
				}
			>
				<rect
					x="0"
					y="0"
					width="100%"
					height="100%"
					fill={theme.palette.background.paper}
					// fill={'#222222'}
				/>
				<g
					transform={`translate(${margin.left} ${
						margin.top
					}) scale(${scaleWidth} ${scaleHeight})`}
				>
					{children}
				</g>
			</svg>

			{hasTooltip && (
				<>
					{tooltipActive &&
						tooltipData &&
						tooltipLocation && (
							<div
								style={{
									position: 'absolute',
									top: `${tooltipLocation.y + 40}px`, // TODO: This is a hack to get the tooltip to appear in the right place
									left: `${tooltipLocation.x + 20}px`, // TODO: This is a hack to get the tooltip to appear in the right place
									pointerEvents: 'none',
								}}
							>
								<Tooltip content={tooltipContent} />
							</div>
						)}
				</>
			)}
		</>
	);
}

ChartContainer.defaultProps = {
	children: null,
};

ChartContainer.propTypes = {
	children: propTypes.node,
};
