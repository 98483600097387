const {
	EXPRESSION,
	getExpressionType,
} = require('../../expression/expression.js');
const { dotGet, deepCopy, dotAdd, dotRemove } = require('../../utils.js');
const { setStage, ERROR } = require('../pipeline.js');

const stageKey = '$unwind';

function processUnwind(current, accumulator, options) {
	let arr = null;

	const path = options.path.slice(1);

	try {
		arr = dotGet(current, path);
	} catch (err) {
		throw new Error(
			ERROR.INVALID_STAGE_ARGUMENT(
				stageKey,
				'cannot unwind an array within an array, unwind the parent array first'
			)
		);
	}

	if (!Array.isArray(arr) || arr.length === 0) {
		if (
			!options.preserveNullAndEmptyArrays &&
			(arr === null ||
				arr === undefined ||
				(Array.isArray && arr.length === 0))
		) {
			return accumulator;
		}

		let newObj = deepCopy(current);
		dotRemove(newObj, path);

		if (!Array.isArray(arr) && arr !== undefined) {
			newObj = dotAdd(newObj, arr, path);
		}

		if (options.includeArrayIndex) {
			newObj = dotAdd(newObj, null, options.includeArrayIndex);
		}

		accumulator.push(newObj);
		return accumulator;
	}

	for (let index = 0; index < arr.length; index++) {
		const element = arr[index];

		let newObj = deepCopy(current);
		dotRemove(newObj, path);
		newObj = dotAdd(newObj, element, path);

		if (options.includeArrayIndex) {
			newObj = dotAdd(newObj, index, options.includeArrayIndex);
		}

		accumulator.push(newObj);
	}

	return accumulator;
}

setStage(
	stageKey,
	/**
	 * @type {PipelineStageFunction}
	 */
	(context, args, options) => {
		const expression = getExpressionType(args);

		if (
			expression.type !== EXPRESSION.FIELD_PATH &&
			expression.type !== EXPRESSION.EXPRESSION_OBJECT
		) {
			throw new Error(
				ERROR.INVALID_STAGE_ARGUMENT(
					stageKey,
					'expected a field path or document',
					args
				)
			);
		}

		const arg =
			expression.type === EXPRESSION.FIELD_PATH
				? {
						path: expression.path,
						includeArrayIndex: null,
						preserveNullAndEmptyArrays: null,
				  }
				: {
						path: expression.value.path,
						includeArrayIndex:
							expression.value.includeArrayIndex || null,
						preserveNullAndEmptyArrays:
							expression.value.preserveNullAndEmptyArrays || null,
				  };

		if (
			!Object.hasOwnProperty.call(arg, 'path') ||
			arg.path.length < 2 ||
			arg.path[0] !== '$'
		) {
			throw new Error(
				ERROR.INVALID_STAGE_ARGUMENT(
					stageKey,
					' a field path as the "path" value',
					arg.path
				)
			);
		}

		const accumulator = [];

		for (let c = 0; c < context.collection.length; c++) {
			const current = context.collection[c];

			processUnwind(current, accumulator, arg);
		}

		return accumulator;
	}
);
