import { Controller, useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { v4 as uuidV4 } from 'uuid';

export default function TilesTextField({
	setIsTextFieldValid,
	onChange,
	initialValue,
	schema,
	label,
	disabled,
	resetForm,
	setResetForm,
}) {
	const { watch, control, setValue, reset } = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			// textField: initialValue,
			textField: '',
		},
		mode: 'onChange',
	});

	useEffect(
		() => {
			if (initialValue)
				setValue('textField', initialValue, {
					shouldValidate: true,
					shouldDirty: true,
				});
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[initialValue]
	);

	// trigger parent state update if form validity changes
	const { isValid } = useFormState({ control });

	useEffect(
		() => {
			// console.log({ isValid });
			setIsTextFieldValid(isValid);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isValid]
	);

	// pass input value to parent
	const textField = watch('textField');
	useEffect(
		() => {
			// console.log({ textField });
			onChange(textField);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[textField]
	);

	useEffect(
		() => {
			if (resetForm) {
				reset({ textField: '' });
				setResetForm(false);
			}
		},
		[resetForm]
	);

	return (
		<div style={{ height: 70 }}>
			<Controller
				render={({ field, fieldState: { error } }) => (
					<TextField
						{...field}
						error={!!error}
						variant="standard"
						helperText={error?.message}
						label={label}
						sx={{
							width: 266,
							paddingBottom: !error ? '21.5px' : 0,
						}}
						disabled={disabled}
					/>
				)}
				name="textField"
				control={control}
			/>
			{/* {!isValid && <div style={{ height: 17.5, marginTop: 3 }} />} */}
		</div>
	);
}

TilesTextField.propTypes = {
	setIsTextFieldValid: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	initialValue: PropTypes.string.isRequired,
	schema: PropTypes.object.isRequired,
	label: PropTypes.string,
	disabled: PropTypes.bool,
};
