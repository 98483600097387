import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';

import { capitalize } from 'lodash';
import { ChevronRight } from '@mui/icons-material';
import { Collapse, styled, Typography } from '@mui/material';
import Color from 'color';
import pluralize from 'pluralize';

import AddFieldButton from './AddFieldButton';
import { ARRAY, OBJECT, UNDEFINED } from '../../../utils/commonDataTypes';
import { attributeOrder, omittedAttributes } from './utils/constants';
import DataConfigArrayContent from './DataConfigArrayContent';
import DataType from '../DataTypes/DataType';
import FieldPopover from './popover-panes/FieldPopover';
import { useDataConfigContext } from './DataConfigContext';

const Chevron = styled(ChevronRight)(({ expanded, theme }) => ({
	transform: `rotate(${expanded ? 90 : 0}deg)`,

	transition: theme.transitions.create(['transform'], {
		duration: 250,
	}),
}));

const ChevronButton = styled('button')(({ offset, theme }) => ({
	alignItems: 'center',
	backgroundColor: 'transparent',
	border: 'none',
	borderRadius: 4,
	display: 'flex',
	height: 22,
	justifyContent: 'flex-start',
	left: offset + 2,
	marginRight: 2,
	padding: 0,
	position: 'absolute',
	transform: 'translateX(0.5px)',
	width: 22,

	'&:hover': {
		backgroundColor: Color(theme.palette.text.solid)
			.alpha(0.1)
			.toString(),
	},

	'&:disabled': {
		pointerEvents: 'none',
	},
}));

const CollapseContent = styled('div')(({ offset, theme }) => ({
	position: 'relative',
	width: '100%',

	'& .lineContainer': {
		display: 'flex',
		height: '100%',
		marginLeft: offset + 12.5,
		position: 'absolute',

		'& > .collapseLine': {
			width: 2,
			alignSelf: 'stretch',
			height: '100%',

			'& > line': {
				stroke: theme.palette.lines,
				strokeWidth: 1,
			},
		},

		'& > .collapseCurve': {
			height: 15,
			width: 15,
			position: 'absolute',
			top: '100%',

			'& > path': {
				stroke: theme.palette.lines,
				fill: 'none',
			},
		},
	},
}));

const Container = styled('div')(({ theme }) => ({
	alignItems: 'center',
	display: 'flex',
	position: 'relative',
	width: '100%',

	'& .action_container': {
		opacity: 0,
		pointerEvents: 'none',
		transform: 'translateX(-3px)',

		transition: theme.transitions.create(['opacity', 'transform'], {
			duration: 100,
		}),
	},

	'&:hover .action_container, &:focus-within .action_container': {
		opacity: 1,
		pointerEvents: 'all',
		transform: 'translateX(0px)',
	},

	'&:hover .canAddIndicator': {
		opacity: 0,
		transform: 'translateX(8px)',
	},
}));

const ElementContainer = styled('button')(
	({ isArrayChild, isDisabled, offset, offsetLine, showChevron, theme }) => ({
		alignItems: 'center',
		backgroundColor: 'transparent',
		border: '1px solid transparent',
		borderRadius: 4,
		boxSizing: 'border-box',
		custor: 'pointer',
		display: 'flex',
		height: 26,
		padding: `0 0 0 ${offset + (showChevron || !isArrayChild ? 24 : 4)}px`,
		position: 'relative',
		userSelect: 'none',
		width: '100%',

		'&.disabled': {
			cursor: 'default',
		},

		'& .readOnlyDetail': {
			color: Color(theme.palette.text.solid)
				.alpha(0.55)
				.toString(),
			display: 'none',
			marginLeft: '0.6ch',
		},

		'&:not(.disabled):hover': {
			backgroundColor: isDisabled
				? 'transparent'
				: Color(theme.palette.text.solid)
						.alpha(0.05)
						.toString(),

			'&.readOnly .readOnlyDetail': {
				display: 'inline-block',
			},
		},

		'&:hover .readOnlyDetail': {
			display: 'inline-block',
		},

		'&.selected:not(.readOnly):not(:disabled)': {
			backgroundColor: `${Color(theme.palette.indicators.info.main)
				.alpha(0.2)
				.toString()} !important`,
			border: `1px solid ${Color(theme.palette.indicators.info.text)
				.alpha(0.2)
				.toString()}`,
		},

		'& > .elementLineContainer': {
			display: 'flex',
			height: 22,
			left: offset - (offsetLine ? 20 : 0),
			position: 'absolute',
			transform: 'translateX(0.5px)',
			overflow: 'visible',

			'& > .elementLine': {
				width: 22,
				alignSelf: 'stretch',
				height: 22,
				overflow: 'visible',

				'& > polyline': {
					fill: 'none',
					stroke: theme.palette.lines,
					strokeWidth: 1,
				},
			},
		},
	})
);

const InnerContent = styled('div')(({ error, theme }) => ({
	alignItems: 'center',
	backgroundColor: error ? theme.palette.solidErrorHighlight : 'transparent',
	borderRadius: 4,
	display: 'flex',
	height: '100%',
	padding: '0 2px',
	width: '100%',
}));

const Element = ({
	arrayChildType,
	config,
	expanded,
	isArrayChild,
	isObjectChild,
	location,
	offset,
	onChangeExpanded,
	parentLocation,
	isRootNode,
	showChevron,
	title,
	type,
	...other
}) => {
	const ref = useRef();

	const hideChevron = !showChevron;

	const {
		disabled,
		readOnly,
		nodeSelectable,
		scrollToSelected,
		select,
		selection,
	} = useDataConfigContext();

	const _disabled = (!location || readOnly || disabled) && !nodeSelectable;

	const isSelected = selection === location;

	useEffect(
		() => {
			if (isSelected && scrollToSelected.current) {
				ref.current.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
				});

				scrollToSelected.current = false;
			}
		},
		[isSelected, scrollToSelected]
	);

	const attributes = useMemo(
		() => {
			if (isRootNode) return;

			const keys = Object.keys(config);

			let attrs = config.required?.value ? ['Required'] : ['Optional'];

			return keys
				.filter(key => !omittedAttributes.includes(key))
				.sort(
					(a, b) =>
						attributeOrder.indexOf(a) - attributeOrder.indexOf(b)
				)
				.reduce((accumulator, key) => {
					const value = config[key].value;

					if (typeof value === 'boolean')
						return [
							...accumulator,
							`${capitalize(key)}: ${value ? 'True' : 'False'}`,
						];

					return [...accumulator, `${capitalize(key)}: ${value}`];
				}, attrs)
				.join(', ');
		},
		[config, isRootNode]
	);

	const classes = useMemo(
		() =>
			[
				...(isSelected ? ['selected'] : []),
				// ...(readOnly ? ['readOnly'] : []),
				...(_disabled ? ['disabled'] : []),
			].join(' ') || undefined,
		// [_disabled, isSelected, readOnly]
		[_disabled, isSelected]
	);

	return (
		<Container ref={ref}>
			<ElementContainer
				className={classes}
				disabled={_disabled}
				isArrayChild={+isArrayChild}
				isReadonly={+readOnly}
				offset={offset}
				offsetLine={+(isObjectChild && type === OBJECT)}
				onClick={() => {
					if (!readOnly || nodeSelectable) {
						select(location);
					}
				}}
				showChevron={+showChevron}
				type={'button'}
				{...other}
			>
				<InnerContent>
					{title && (
						<>
							<Typography
								style={{
									fontWeight: 'bold',
									whiteSpace: 'nowrap',
								}}
							>
								{config.label || title}
							</Typography>
							<Typography style={{ marginRight: '0.66ch' }}>
								:
							</Typography>
						</>
					)}
					<DataType
						style={{
							marginRight: 4,
						}}
						type={type}
					/>
					<Typography
						style={{
							textTransform: 'capitalize',
							whiteSpace: 'nowrap',
						}}
					>
						{`${type}${
							arrayChildType && arrayChildType !== UNDEFINED
								? ` of ${pluralize.plural(
										capitalize(arrayChildType)
								  )}`
								: ''
						}`}
					</Typography>
					{attributes && (
						<Typography
							className={'readOnlyDetail'}
							style={{
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
							}}
						>
							{`• ${attributes}`}
						</Typography>
					)}
				</InnerContent>
				{title &&
					parentLocation && (
						<div className="elementLineContainer">
							<svg className="elementLine">
								<polyline
									points={`12,11 0,11${
										readOnly ? ' 0,-2' : ''
									}`}
								/>
								<line x1="0" y1="11" x2="12" y2="11" />
							</svg>
						</div>
					)}
			</ElementContainer>
			{!(hideChevron && isArrayChild) && (
				<ChevronButton
					disabled={hideChevron}
					offset={offset}
					onClick={event => {
						event.stopPropagation();

						onChangeExpanded();
					}}
					type="button"
				>
					{showChevron && <Chevron expanded={+expanded} />}
				</ChevronButton>
			)}
		</Container>
	);
};

const ArrayParser = ({
	config,
	isArrayChild,
	isObjectChild,
	location,
	offset,
	parentLocation,
	siblingKeys,
	title,
}) => {
	const { arrayContent, contentTitle } = config;

	const { type: childType } = arrayContent;

	return (
		<>
			<Element
				arrayChildType={childType}
				config={config}
				isArrayChild={isArrayChild}
				isObjectChild={isObjectChild}
				location={location}
				offset={offset}
				parentLocation={parentLocation}
				siblingKeys={siblingKeys}
				title={title}
				type={ARRAY}
			/>
			<DataConfigArrayContent offset={offset + 26}>
				<DataConfigNode
					arrayTitle={title}
					config={arrayContent}
					isArrayChild
					location={location + `.arrayContent`}
					offset={offset + 40}
					title={contentTitle}
				/>
			</DataConfigArrayContent>
		</>
	);
};

const CollapseLine = ({ enabled }) => (
	<div className="lineContainer">
		<svg className="collapseLine">
			<line
				vectorEffect="non-scaling-stroke"
				x1="1"
				x2="1"
				y1="0"
				y2="100%"
			/>
		</svg>
		{enabled && (
			<svg className="collapseCurve" width={15} height={15}>
				<path d="M1,0 C0,13 14,13 14,13" />
			</svg>
		)}
	</div>
);

const ObjectParser = ({
	arrayTitle,
	config,
	isArrayChild,
	isObjectChild,
	isRootNode,
	location,
	offset,
	parentLocation,
	siblingKeys,
	title,
}) => {
	const { objectContent } = config;

	const {
		disabled,
		readOnly,
		scrollToSelected,
		update,
	} = useDataConfigContext();

	const enabled = !(disabled || readOnly);

	const [expanded, setExpanded] = useState(true);
	const [newFieldAnchor, setNewFieldAnchor] = useState();

	const absoluteLocation = useMemo(
		() => `${location}${location ? '.' : ''}objectContent`,
		[location]
	);

	const keys = useMemo(
		() =>
			(objectContent ? Object.keys(objectContent) : []).sort((a, b) =>
				a.localeCompare(b)
			),
		[objectContent]
	);

	const Item = useCallback(
		({ keyString }) => (
			<DataConfigNode
				key={keyString}
				config={objectContent?.[keyString]}
				data={config?.[keyString]}
				disabled={disabled}
				isObjectChild
				location={
					location +
					`${location ? '.' : ''}objectContent.${keyString}`
				}
				offset={
					offset +
					(objectContent?.[keyString]?.type === OBJECT ? 32 : 12)
				}
				parentLocation={absoluteLocation}
				siblingKeys={keys?.filter(k => k !== keyString)}
				title={keyString}
			/>
		),
		[
			absoluteLocation,
			config,
			disabled,
			keys,
			location,
			objectContent,
			offset,
		]
	);

	const items = useMemo(
		() => {
			if (keys.length === 0) {
				return null;
			}

			if (keys.length === 1) {
				return {
					LastItem: <Item keyString={keys[0]} />,
					PreLastItems: [],
				};
			}

			if (keys.length > 1) {
				return {
					LastItem: <Item keyString={keys[keys.length - 1]} />,
					PreLastItems: keys
						.slice(0, -1)
						.map(key => <Item key={key} keyString={key} />),
				};
			}
		},
		[Item, keys]
	);

	return (
		<>
			<Element
				config={config}
				expanded={expanded}
				isArrayChild={isArrayChild}
				isObjectChild={isObjectChild}
				location={location}
				offset={offset}
				onChangeExpanded={() => setExpanded(prev => !prev)}
				parentLocation={parentLocation}
				isRootNode={isRootNode}
				showChevron
				siblingKeys={siblingKeys}
				title={title}
				type={OBJECT}
			/>
			<Collapse in={expanded} timeout={100}>
				<CollapseContent offset={offset}>
					{!readOnly && <CollapseLine enabled={enabled} />}
					<div style={{ position: 'relative' }}>
						{readOnly && <CollapseLine enabled={enabled} />}
						{items?.PreLastItems && items.PreLastItems}
					</div>
					{items?.LastItem && items.LastItem}
				</CollapseContent>
				{enabled && (
					<AddFieldButton
						arrayTitle={arrayTitle}
						offset={offset}
						onAdd={event => setNewFieldAnchor(event.currentTarget)}
						title={title}
					/>
				)}
			</Collapse>
			<FieldPopover
				anchorEl={newFieldAnchor}
				onAddField={({ content, fieldName }) => {
					scrollToSelected.current = true;

					update(`${absoluteLocation}.${fieldName}`, content, true);
				}}
				onClose={() => setNewFieldAnchor(null)}
				takenKeys={keys}
			/>
		</>
	);
};

export default function DataConfigNode({
	arrayTitle,
	config,
	isArrayChild,
	isObjectChild,
	location,
	offset,
	parentLocation,
	isRootNode,
	siblingKeys,
	title,
}) {
	const { type } = config || {};

	const Component = useMemo(
		() => {
			if (!type) {
				return null;
			}

			const coreProps = {
				arrayTitle,
				config,
				isArrayChild,
				isObjectChild,
				location,
				offset,
				parentLocation,
				siblingKeys,
				title,
				type,
			};

			switch (type) {
				case ARRAY:
					return <ArrayParser {...coreProps} />;

				case OBJECT:
					return (
						<ObjectParser isRootNode={isRootNode} {...coreProps} />
					);

				default:
					return <Element {...coreProps} />;
			}
		},
		[
			arrayTitle,
			config,
			isArrayChild,
			isObjectChild,
			location,
			offset,
			parentLocation,
			isRootNode,
			siblingKeys,
			title,
			type,
		]
	);

	return Component;
}

DataConfigNode.defaultProps = {
	location: '',
	offset: 0,
};

const sharedPropTypes = {
	config: PropTypes.object,
	isArrayChild: PropTypes.bool,
	isObjectChild: PropTypes.bool,
	location: PropTypes.string,
	offset: PropTypes.number,
	parentLocation: PropTypes.string,
	siblingKeys: PropTypes.arrayOf(PropTypes.string),
	title: PropTypes.string,
};

CollapseLine.propTypes = {
	enabled: PropTypes.bool,
};

Element.propTypes = {
	...sharedPropTypes,
	arrayChildType: PropTypes.string,
	expanded: PropTypes.bool,
	onChangeExpanded: PropTypes.func,
	showChevron: PropTypes.bool,
	isRootNode: PropTypes.bool,
	type: PropTypes.string,
};

ArrayParser.propTypes = {
	...sharedPropTypes,
};

ObjectParser.propTypes = {
	...sharedPropTypes,
	arrayTitle: PropTypes.string,
	disabled: PropTypes.bool,
	onAddField: PropTypes.func,
	isRootNode: PropTypes.bool,
};

DataConfigNode.propTypes = {
	...sharedPropTypes,
	arrayTitle: PropTypes.string,
	disabled: PropTypes.bool,
	isRootNode: PropTypes.bool,
};
