import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import * as yup from 'yup';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import BtQueryBuilderPropertyFormField from '../BtQueryBuilderPropertyField';
import { determineValueType, replaceDynamicTypedValue } from './utils';
import { baseStagePropTypes } from './base-prop-type';

const stageType = '$match';

const formSchema = yup.object().shape({
	rules: yup.array().of(
		yup.object().shape({
			field: yup.string().required(),
			comparator: yup.string().required(),
			value: yup.mixed(),
		})
	),
});

const comparators = [
	{ value: '$eq', label: 'Equal to', icon: '=' },
	{ value: '$ne', label: 'Not equal to', icon: '≠' },
	{ value: '$lt', label: 'Less than', icon: '<' },
	{ value: '$lte', label: 'Less than or equal to', icon: '≤' },
	{ value: '$gt', label: 'Greater than', icon: '>' },
	{ value: '$gte', label: 'Greater than or equal to', icon: '≥' },

	// not supported by mongodb_query js lib yet
	// { value: '$in', label: 'is in', icon: '∈' },
	// { value: '$nin', label: 'is not in', icon: '∉' },
];

const defaultField = () => ({
	field: null,
	field_type: 'field-path',
	comparator: '$eq',
	value: null,
	value_type: 'static_null',
});

function buildFormValues(queryStage, flatConfigSchema, variables) {
	const values = { rules: [] };

	Object.keys(queryStage[stageType]).forEach(k => {
		const matchRule = queryStage[stageType][k];

		if (
			matchRule === null ||
			typeof matchRule === 'string' ||
			typeof matchRule === 'number' ||
			typeof matchRule === 'bigint' ||
			typeof matchRule === 'boolean'
		) {
			const [valueType, value] = determineValueType(
				matchRule,
				flatConfigSchema,
				false,
				variables
			);

			values.rules.push({
				field: k,
				field_type: 'field-path',
				comparator: '$eq',
				value: value,
				value_type: valueType,
			});

			return;
		}

		const comparator = Object.keys(matchRule)[0] || null;

		if (!comparator) {
			const [valueType, value] = determineValueType(
				matchRule,
				flatConfigSchema,
				false,
				variables
			);
			values.rules.push({
				field: k,
				field_type: 'field-path',
				comparator: '$eq',
				value: value,
				value_type: valueType,
			});
		} else {
			for (const comparator in matchRule) {
				if (Object.hasOwnProperty.call(matchRule, comparator)) {
					const [valueType, value] = determineValueType(
						matchRule[comparator],
						flatConfigSchema,
						false,
						variables
					);

					switch (comparator) {
						case '$eq':
						case '$ne':
						case '$gt':
						case '$gte':
						case '$lt':
						case '$lte':
							values.rules.push({
								field: k,
								field_type: 'field-path',
								comparator: comparator,
								value: value,
								value_type: valueType,
							});
							continue;

						default:
							break;
					}
				}
			}
		}
	});

	if (values.rules.length === 0) {
		values.rules.push(defaultField());
	}

	return values;
}

function buildQueryStage(fields, variables) {
	const query = {};

	fields?.rules?.forEach(rule => {
		switch (rule.comparator) {
			case '$eq':
			case '$ne':
			case '$gt':
			case '$gte':
			case '$lt':
			case '$lte':
			case '$in':
			case '$nin':
				if (query[rule.field]) {
					query[rule.field] = {
						...query[rule.field],
						[rule.comparator]: rule.value,
					};
					return;
				}
				query[rule.field] = {
					[rule.comparator]: replaceDynamicTypedValue(
						'value',
						rule,
						variables
					),
				};
				return;

			default:
				break;
		}
	});

	return {
		[stageType]: query,
	};
}

function ComparatorSelect(props) {
	const { index, onChange, disabled, readOnly, id } = props;

	const { watch } = useFormContext();

	const value = watch(`rules.${index}.comparator`);

	const comparator = useMemo(() => comparators.find(c => c.value === value), [
		value,
	]);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const menuOpen = Boolean(anchorEl);
	const handleMenuOpen = useCallback(event => {
		setAnchorEl(event.currentTarget);
	}, []);
	const handleMenuClose = useCallback(() => {
		setAnchorEl(null);
	}, []);

	const handleSelect = useCallback(
		(index, val) => {
			handleMenuClose();
			if (value !== val) {
				onChange(index, val);
			}
		},
		[handleMenuClose, onChange, value]
	);

	return (
		<>
			<IconButton
				onClick={handleMenuOpen}
				disabled={disabled || readOnly}
				id={'comparator-select' + id}
				aria-controls={menuOpen ? 'comparator-menu' + id : undefined}
				aria-haspopup="true"
				aria-expanded={menuOpen ? 'true' : undefined}
			>
				<Box width="1em" height="1em" lineHeight="1em" fontSize="1em">
					{comparator.icon}
				</Box>
			</IconButton>
			<Menu
				id={'comparator-menu' + id}
				onClose={handleMenuClose}
				anchorEl={anchorEl}
				MenuListProps={{
					'aria-labelledby': 'type-select',
				}}
				open={menuOpen}
				transformOrigin={{
					horizontal: 'left',
					vertical: 'top',
				}}
				anchorOrigin={{
					horizontal: 'left',
					vertical: 'bottom',
				}}
			>
				{comparators.map(c => (
					<MenuItem
						key={c.value}
						onClick={() => handleSelect(index, c.value)}
						selected={c.value === comparator.value}
					>
						<ListItemIcon>{c.icon}</ListItemIcon>
						<ListItemText>{c.label}</ListItemText>
					</MenuItem>
				))}
			</Menu>
		</>
	);
}

const MatchFormContent = props => {
	const { control, setValue } = useFormContext();

	const { disabled, readOnly } = props;

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'rules',
	});

	const handleComparatorSelect = useCallback(
		(index, val) => {
			setValue(`rules.${index}.comparator`, val, {
				shouldValidate: true,
				shouldDirty: true,
				shouldTouch: true,
			});
		},
		[setValue]
	);

	return (
		<>
			{fields.map((field, index) => {
				return (
					<Box key={field.id}>
						<Stack
							direction={{ xs: 'column', md: 'row' }}
							spacing={2}
							alignItems={{ xs: 'stretch', md: 'center' }}
						>
							<Box width={{ xs: '100%', md: '40%' }}>
								<BtQueryBuilderPropertyFormField
									key={field.id + 'field'}
									name={`rules.${index}.field`}
									asPath
									isRequired
									disabled={disabled}
									allowedFieldTypes={['field-path']}
								/>
							</Box>
							<Stack
								direction="row"
								spacing={2}
								alignItems="center"
								flexGrow={1}
							>
								<ComparatorSelect
									index={index}
									id={field.id}
									onChange={handleComparatorSelect}
									disabled={disabled}
									readOnly={readOnly}
								/>
								<Box flexGrow={1}>
									<BtQueryBuilderPropertyFormField
										key={field.id + 'value'}
										name={`rules.${index}.value`}
										disabled={disabled}
										allowedFieldTypes={[
											'static',
											'variable',
										]}
									/>
								</Box>
							</Stack>

							{!readOnly && (
								<Box flexShrink={1}>
									<IconButton
										onClick={() => remove(index)}
										disabled={disabled}
									>
										<DeleteIcon />
									</IconButton>
								</Box>
							)}
						</Stack>
						{index < fields.length - 1 && (
							<Divider sx={{ margin: 2 }} />
						)}
					</Box>
				);
			})}
			{!readOnly && (
				<Box
					sx={{
						textAlign: 'center',
					}}
				>
					<Button
						onClick={() => append(defaultField())}
						disabled={disabled}
						startIcon={<AddIcon />}
					>
						Add match rule
					</Button>
				</Box>
			)}
		</>
	);
};

export default {
	formSchema: formSchema,
	FormContent: MatchFormContent,
	buildQueryStage: buildQueryStage,
	buildFormValues: buildFormValues,
};

// BtQueryBuilderStageMatch.propTypes = baseStagePropTypes;

MatchFormContent.propTypes = baseStagePropTypes;
ComparatorSelect.propTypes = {
	index: PropTypes.number,
	id: PropTypes.string,
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	onChange: PropTypes.func,
	value: PropTypes.oneOf(comparators.map(c => c.value)),
};
