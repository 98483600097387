import React from 'react';
import { Typography } from '@mui/material';
import { AlertCircle } from 'mdi-material-ui';
import { BarSkeleton } from './BarSkeleton';

export default function InsightNoData() {
	return (
		<div
			style={{
				paddingLeft: 20,
				paddingRight: 20,
				paddingBottom: 20,
				paddingTop: 20,
				height: '100%',
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'flex-end',
			}}
		>
			<Typography>There is no data to display</Typography>
			<Typography>This is probably due to the filters applied</Typography>

			<BarSkeleton animation={false} />
			<AlertCircle
				style={{
					opacity: 0.8,
					position: 'absolute',
					display: 'flex',
					justifySelf: 'center',
					bottom: '40%',
					fontSize: 30,
				}}
			/>
		</div>
	);
}
