import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

const Container = styled('div')(() => ({
	width: '100%',
	overflow: 'visible',
}));

const Line = styled('svg')(({ offset, theme }) => ({
	height: 14,
	overflow: 'visible',
	position: 'absolute',
	marginLeft: offset + 1,
	width: 14,

	'& > polyline': {
		stroke: theme.palette.lines,
		strokeWidth: 1,
		fill: 'none',
	},
}));

export default function DataConfigArrayContent({ children, offset }) {
	return (
		<Container>
			<Line offset={offset}>
				<polyline points="1,1 1,13, 13,13" />
			</Line>
			{children}
		</Container>
	);
}

DataConfigArrayContent.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	offset: PropTypes.number,
};
