import React from 'react';
import { useTheme } from '@mui/styles';

import { fontSize } from '../Utils';
import { useComponentsContext } from './Chart';

// NOTE: This a basic implamentation of the YAxis component, it only renders the labels for the YAxis.
export function YAxis({ dataKey }) {
	const THEME = useTheme();

	const { plotArea, dataKeys } = useComponentsContext();

	const segmentHeight = plotArea.height / dataKeys.length;

	return (
		<g fill="none">
			{dataKeys.map((dataKey, index) => (
				<g key={index}>
					<text
						x={plotArea.x - 10}
						y={index * segmentHeight + segmentHeight / 2}
						fill={THEME.palette.chart.label}
						fontSize={fontSize}
						textAnchor="end"
						alignmentBaseline="middle"
					>
						{dataKey}
					</text>
				</g>
			))}
		</g>
	);
}
