import PropTypes from 'prop-types';

export const dataSources = PropTypes.arrayOf(
	PropTypes.shape({
		create_by: PropTypes.string.isRequired,
		create_timestamp: PropTypes.number.isRequired,
		description: PropTypes.string.isRequired,
		modify_by: PropTypes.string,
		modify_timestamp: PropTypes.number,
		name: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		uuid: PropTypes.string.isRequired,
	})
);
