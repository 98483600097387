import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	CircularProgress,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
} from '@mui/material';
import CreateIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import BtDialog from '../../../components/generic/BtDialog';
import {
	BtForm,
	BtFormActionsContainer,
	BtFormContent,
	BtFormSelect,
	BtFormTextField,
	withFormContextMethods,
} from '../../../components/generic/forms';
import { insightCollectionCreate } from '../../../API';
import { useAppContext } from '../../../context/ContextManager';
import { orgDetailsGet } from '../../../API/orgDetails';

const blankDefaults = {
	name: '',
	description: '',
};

const ActionButtons = withFormContextMethods(
	({ formState: { isDirty }, onClose, sending }) => {
		return (
			<>
				<Button variant="outlined" onClick={onClose} disabled={sending}>
					Cancel
				</Button>
				<Button
					variant="contained"
					disabled={!isDirty || sending}
					type="submit"
					startIcon={
						sending ? (
							<CircularProgress size={20} />
						) : (
							<CreateIcon />
						)
					}
					disableElevation
				>
					Create Insight Collection
				</Button>
			</>
		);
	}
);

export default function NewInsightCollectionDialog({
	existingNames,
	onClose,
	open,
}) {
	const history = useHistory();
	const { appOrg } = useAppContext();
	const { enqueueSnackbar } = useSnackbar();
	const [sending, setSending] = useState(false);

	const schema = useMemo(
		() =>
			yup.object({
				name: yup
					.string()
					.min(3)
					.notOneOf(
						existingNames || [],
						'A Insight Collection with this name already exists'
					)
					.required()
					.label('Insight Collection Name'),
				description: yup
					.string()
					.min(3)
					.required()
					.label('Insight Collection Description'),
				group: yup
					.string()
					.oneOf(appOrg.insight_groups.map(group => group.uuid))
					.required('A group must be selected')
					.label('Group'),
			}),
		[appOrg, existingNames]
	);

	const onSubmit = useCallback(
		async (values, reset) => {
			setSending(true);
			try {
				// get the default org palette uuid to set the initial palette config value
				const orgDetails = await orgDetailsGet();
				const paletteConfigItem = {};
				paletteConfigItem.uuid = orgDetails.theme.palettes[0].uuid;
				paletteConfigItem.colours = [];
				const paletteConfig = [paletteConfigItem];

				console.log(paletteConfig);

				const newCollection = {
					name: values.name,
					description: values.description,
					group: values.group,
					palette_config: paletteConfig,
				};
				const { uuid } = await insightCollectionCreate({
					newCollection: newCollection,
				});
				reset(values);
				enqueueSnackbar('Created Insight Collection', {
					variant: 'success',
				});
				history.push(`/InsightCollection/${uuid}/settings`);
			} catch (error) {
				setSending(false);
				enqueueSnackbar('Failed to create Insight Collection', {
					variant: 'error',
				});
			}
		},
		[enqueueSnackbar, history]
	);

	return (
		<BtDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
			{sending && (
				<LinearProgress
					style={{
						position: 'absolute',
						top: 0,
						width: '100%',
					}}
				/>
			)}
			<DialogTitle>Create New Insight Collection</DialogTitle>
			<BtForm
				onSubmit={onSubmit}
				sending={sending}
				validationSchema={schema}
				defaultValues={blankDefaults}
			>
				<DialogContent>
					<BtFormContent>
						<BtFormTextField name="name" label="Name" autoFocus />
						<BtFormTextField
							name="description"
							label="Description"
							multiline
						/>
						<BtFormSelect
							name="group"
							label="Group"
							items={appOrg.insight_groups.map(group => {
								const item = {};
								item.value = group.uuid;
								item.label = group.name;
								return item;
							})}
						/>
					</BtFormContent>
				</DialogContent>
				<DialogActions>
					<BtFormActionsContainer>
						<ActionButtons onClose={onClose} sending={sending} />
					</BtFormActionsContainer>
				</DialogActions>
			</BtForm>
		</BtDialog>
	);
}

ActionButtons.propTypes = {
	isDirty: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	sending: PropTypes.bool.isRequired,
};

NewInsightCollectionDialog.propTypes = {
	existingNames: PropTypes.arrayOf(PropTypes.string),
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};
