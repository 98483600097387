import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Auth } from 'aws-amplify';
import { useAppContext } from '../../context/ContextManager';

// MUI COMPONENTS
import Button from '@mui/material/Button';
import { Card, CardHeader, CardActions } from '@mui/material';

import DialogConfirm from '../dialogs/DialogConfirm';

import LoopIcon from '@mui/icons-material/Loop';
import SyncAltIcon from '@mui/icons-material/SyncAlt';

const styles = makeStyles(theme => ({
	button_row: {},
	root: {
		width: '100%',
		minWidth: 200,
	},
	config_paper: {
		padding: 10,
	},
	tableHeader: {
		fontWeight: 700,
	},
	tableRow: {
		cursor: 'pointer',
		borderBottom: '1px solid red',
		borderBottomColor: theme.palette.primary.shade_dark,
		'&:hover': {
			backgroundColor: theme.palette.primary.shade_light,
		},
	},
}));

export default function DeviceAdminPanel({ dev_id }) {
	const classes = styles();
	const [restartDialogOpen, setRestartDialogOpen] = useState(false);
	const [syncDialogOpen, setSyncDialogOpen] = useState(false);
	const { appOrg } = useAppContext();

	const handleDeviceRestart = () => {
		setRestartDialogOpen(true);
	};
	const handleDeviceSync = () => {
		setSyncDialogOpen(true);
	};

	const sendDeviceRequest = request_data => {
		var req_url =
			process.env.REACT_APP_API_SERVER_URL + '/v1/device_admin/' + dev_id;

		Auth.currentSession().then(user_session => {
			let user_token = user_session.getAccessToken();
			fetch(req_url, {
				method: 'POST',
				headers: {
					Authorization: user_token.jwtToken,
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Organisation: appOrg.uuid,
				},
				body: JSON.stringify(request_data),
			})
				.then(res => res.json())
				.then(response => {
					if (response['result'] !== 'OK') {
						console.log(
							'Failed to send request: ' + response['result']
						);
					} else {
						console.log('Request sent');
					}
				})
				.catch(error => console.log(error));
		});
	};

	const handleRestartDialogClose = result => {
		if (result.result === 'confirm') {
			console.log('Sending Restart to: ' + dev_id);
			var put_data = {};
			put_data['request'] = 'restart';
			sendDeviceRequest(put_data);
		}
		setRestartDialogOpen(false);
	};

	const handleSyncDialogClose = result => {
		if (result.result === 'confirm') {
			console.log('Sending sync to: ' + dev_id);
			var put_data = {};
			put_data['request'] = 'sync';
			sendDeviceRequest(put_data);
		}
		setSyncDialogOpen(false);
	};

	return (
		<Card variant="outlined">
			<CardHeader title="Device Actions" />

			<CardActions>
				<Button
					variant="outlined"
					startIcon={<LoopIcon />}
					onClick={handleDeviceRestart}
					className={classes.button}
				>
					Restart Device
				</Button>

				<Button
					variant="outlined"
					startIcon={<SyncAltIcon />}
					onClick={handleDeviceSync}
					className={classes.button}
				>
					Send Sync
				</Button>
			</CardActions>

			<DialogConfirm
				setOpen={restartDialogOpen}
				onClose={handleRestartDialogClose}
				title="Device Restart"
				text="Are you sure you want to restart this device?"
				icon=""
			/>
			<DialogConfirm
				setOpen={syncDialogOpen}
				onClose={handleSyncDialogClose}
				title="Send Device Sync"
				text="Are you sure you want to send a sync message to this device?"
				icon=""
			/>
		</Card>
	);
}
