import { isValidElement } from 'react';

import _ from 'lodash';

import {
	ARRAY,
	BOOLEAN,
	DATE,
	NUMBER,
	OBJECT,
	STRING,
} from '../../../../utils/commonDataTypes';
import { ASC } from '../constants';

const ORDINAL = 'ordinal';

const numberComparator = (a, b, order) => (order === ASC ? a - b : b - a);

const typeComparators = {
	[ARRAY]: order => (a, b) =>
		numberComparator((a || []).length, (b || []).length, order),

	[BOOLEAN]: order => (a, b) =>
		a === b ? 0 : (order === ASC ? a : b) ? 1 : -1,

	[DATE]: order => (a, b) => {
		const aEpoch = +new Date(a);
		const bEpoch = +new Date(b);

		return numberComparator(aEpoch, bEpoch, order);
	},

	[NUMBER]: order => (a, b) => numberComparator(a, b, order),

	[OBJECT]: order => (a, b) =>
		numberComparator(
			Object.keys(a || {}).length,
			Object.keys(b || {}).length,
			order
		),

	[ORDINAL]: (order, ordinalValues) => (a, b) =>
		ordinalValues.indexOf(order === ASC ? a : b) -
		ordinalValues.indexOf(order === ASC ? b : a),

	[STRING]: order => (a, b) =>
		(order === ASC ? a : b || '').localeCompare(
			order === ASC ? b : a || ''
		),
};

export default function sortingFunc({
	CellContent,
	comparator,
	data,
	order = ASC,
	orderBy,
	ordinalValues,
	type,
}) {
	if (!orderBy) {
		throw new Error('An orderBy field was not specified.');
	}

	// If ordinalValues supplied, ensure it's a valid array
	if (
		ordinalValues &&
		!Array.isArray(ordinalValues) &&
		ordinalValues.length > 0
	) {
		throw new Error(
			'Any supplied ordinalValues must be an array of ordered reference items.'
		);
	}

	let isTransient = false;

	// Use supplied data type, otherwise infer the type
	const _type =
		type ??
		(() => {
			const showWarning = errorMessage => {
				if (process.env.NODE_ENV === 'development') {
					console.warn(errorMessage);
				}
			};

			// If there is no data then abort
			if (!data?.length) return;

			// A sample value under the orderBy field of the first element
			const firstDataElement = data[0][orderBy];

			// If the orderBy field is not present or a specific type
			if (_.isNil(firstDataElement)) {
				// Incase the column is transient, attempt to infer the result.
				const content = CellContent?.(null, data[0]);

				if (!content) {
					showWarning(
						'Unable to interrogate the cell content in order to establish a sorting method; alter the CellContent or disable sorting on the column.'
					);

					return;
				}

				// Cannot sort React elements, abort if so
				if (isValidElement(content)) {
					showWarning(
						'It is not possible to sort React elements; alter the CellContent or disable sorting on the column.'
					);

					return;
				}

				// If CellContent produces a valid type, return the type and flag transience
				const inferredTransientType = typeof content;

				if (
					Object.keys(typeComparators).includes(inferredTransientType)
				) {
					isTransient = true;

					return inferredTransientType;
				}

				// Out of inferrence options at this point
				showWarning(
					`The type of data could not be inferred for sorting. Does the "${orderBy}" field exist in all of the data elements?`
				);

				return;
			}

			return typeof firstDataElement;
		})();

	// Use supplied comparator, otherwise use the ordinal comparator
	// if there are ordinal values. Ultimately, lookup the a comparator
	// based on column data type.
	const comparatorFunc = (() => {
		if (comparator) {
			return o => (a, b) => comparator(a, b, o);
		} else if (ordinalValues) {
			return typeComparators[ORDINAL];
		} else if (_type) {
			return typeComparators[_type];
		}

		return null;
	})();

	if (!comparatorFunc) return data;

	return data.sort((a, b) => {
		const _a = isTransient ? CellContent(null, a) : a[orderBy];
		const _b = isTransient ? CellContent(null, b) : b[orderBy];

		return comparatorFunc(order, ordinalValues)(_a, _b);
	});
}
