import { apiClient } from './api_client';

import { resourceGroupGetSchema } from './validations/resourceGroupsValidation';

//========================================================================================================
async function resourceGroupGet({ group }) {
	const req_url = 'resource_group/' + group;
	try {
		const response = await apiClient({ method: 'get', url: req_url });
		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				await resourceGroupGetSchema.validate(response);
				return response.resources;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

export { resourceGroupGet };
