import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, IconButton, Tooltip } from '@mui/material';
import { AddCircle, Delete } from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';

import BtLoading from '../../../components/generic/BtLoading';
import BtSelectionTable from '../../../components/generic/BtSelectionTable';
import DataViewAddSourcesDialog from './DataViewAddSourcesDialog';
import { INITIAL_SORT, SOURCE_TYPE_URL_ITEM_LOOKUP } from '../utils/constants';
import { LOOSE } from '../../../components/generic/table-utils/constants';
import { useDataViewEditorContext } from './DataViewEditorContext';
import TableActionContainer from '../../../components/generic/table-utils/components/TableActionContainer';
import BtOpenInNew from '../../../components/generic/BtOpenInNew';

const SOURCES = 'sources';

export default function DataViewSources({ canEditDataView }) {
	const { dataSources, loadingDataSources } = useDataViewEditorContext();
	const { sending, setValue, watch } = useFormContext();

	const sources = watch(SOURCES);

	const [showAddSourceDialog, setShowAddSourceDialog] = useState(false);

	const columns = useMemo(
		() => [
			{ field: 'name', text: 'Name' },
			{ field: 'description', text: 'Description', minBreakpoint: 'lg' },
			{ field: 'type', text: 'Type', minBreakpoint: 'md' },
			{
				text: '',
				searchable: false,
				sortable: false,
				CellContent: (cellData, rowData) => (
					<TableActionContainer>
						<BtOpenInNew
							buttonProps={{ color: undefined }}
							name="View source"
							size="medium"
							url={`DataSources/${
								SOURCE_TYPE_URL_ITEM_LOOKUP[rowData.type]
							}/${rowData.uuid}`}
						/>
						{canEditDataView && (
							<Tooltip
								disableInteractive
								style={{ margin: '0 0.5em' }}
								title={sending ? '' : 'Remove'}
							>
								<span>
									<IconButton
										color="error"
										disabled={sending}
										onClick={() =>
											setValue(
												SOURCES,
												sources.filter(
													({ uuid }) =>
														uuid !== rowData.uuid
												),
												{
													shouldDirty: true,
													shouldTouch: true,
													shouldValidate: true,
												}
											)
										}
									>
										<Delete />
									</IconButton>
								</span>
							</Tooltip>
						)}
					</TableActionContainer>
				),
			},
		],
		[canEditDataView, sending, setValue, sources]
	);

	const [availableSources, selectedSources] = useMemo(
		() => {
			const selectedUuids = (sources || []).map(({ uuid }) => uuid);

			const availableSources = (dataSources || []).filter(
				({ type, uuid }) =>
					!selectedUuids.includes(uuid) && type === 'data_set'
			);

			const selectedSources = (dataSources || []).filter(({ uuid }) =>
				selectedUuids.includes(uuid)
			);

			return [availableSources, selectedSources];
		},
		[dataSources, sources]
	);

	const handleAddSource = useCallback(
		newSource => {
			const { uuid } = newSource;

			setValue(SOURCES, [...sources, { type: 'dataSet', uuid }], {
				shouldValidate: true,
				shouldDirty: true,
				shouldTouch: true,
			});
		},
		[setValue, sources]
	);

	return (
		<>
			<div style={{ marginBottom: '1em' }}>
				{canEditDataView && (
					<Button
						disableElevation
						disabled={sending}
						onClick={() => setShowAddSourceDialog(true)}
						startIcon={<AddCircle />}
						style={{ marginBottom: '0.5em' }}
						variant="contained"
					>
						Add a Source
					</Button>
				)}
				{loadingDataSources && <BtLoading />}
				{!loadingDataSources && (
					<BtSelectionTable
						columns={columns}
						data={selectedSources}
						debounceSearchInput={false}
						enableGeneralSorting
						enablePagination
						enableSearching
						hideSearchColumn
						hideSearchExactness
						initialSearchExactness={LOOSE}
						initialSort={INITIAL_SORT}
					/>
				)}
			</div>
			<DataViewAddSourcesDialog
				availableSources={availableSources}
				onAddSource={handleAddSource}
				onClose={() => setShowAddSourceDialog(false)}
				open={showAddSourceDialog}
			/>
		</>
	);
}

DataViewSources.propTypes = {
	canEditDataView: PropTypes.bool,
};
