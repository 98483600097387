import React, { forwardRef, useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
	Card,
	CardActions,
	IconButton,
	Tooltip,
	Typography,
	Collapse,
	Paper,
	useTheme,
} from '@mui/material';
import { styled, makeStyles } from '@mui/styles';
import { useSnackbar, SnackbarContent } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classnames from 'classnames';

const DEFAULT = 'default';
const INFO = 'info';
const SUCCESS = 'success';
const WARNING = 'warning';
const ERROR = 'error';

const Message = styled('div')(() => ({
	display: 'flex',
	gap: 8,
	alignItems: 'center',

	'& > svg': {
		color: '#fff',
	},
}));

const IconButtons = styled('div')(() => ({
	display: 'flex',
	gap: 4,
}));

const useStyles = makeStyles(theme => ({
	card: {
		backgroundColor: '#313131',
		width: '100%',
		justifyContent: 'space-between',
	},
	actionRoot: {
		padding: '8px 8px 8px 16px',
		justifyContent: 'space-between !important',
		backgroundColor: '#313131',
	},
	expand: {
		padding: 8,
		transform: 'rotate(0deg)',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
	collapse: {
		padding: 16,
		borderRadius: 0,
	},
}));

const BtDetailSnackbar = forwardRef(
	({ Icon, id, message, variant, children }, ref) => {
		const classes = useStyles();
		const theme = useTheme();

		const { closeSnackbar } = useSnackbar();

		const [expanded, setExpanded] = useState(false);

		const handleExpandClick = useCallback(() => {
			setExpanded(prev => !prev);
		}, []);

		const handleDismiss = useCallback(
			() => {
				closeSnackbar(id);
			},
			[id, closeSnackbar]
		);

		const snackbarColor = useMemo(
			() => theme.palette.indicators[variant]?.main || '#313131',
			[theme, variant]
		);

		return (
			<SnackbarContent ref={ref}>
				<Card
					className={classes.card}
					style={{ backgroundColor: snackbarColor }}
				>
					<CardActions
						classes={{ root: classes.actionRoot }}
						style={{
							backgroundColor: snackbarColor,
							justifyContent: 'space-between',
						}}
					>
						<Message>
							{!!Icon && Icon}
							<Typography
								variant="subtitle2"
								style={{ color: '#fff' }}
							>
								{message}
							</Typography>
						</Message>
						<IconButtons>
							<Tooltip
								title={
									expanded ? 'Hide Options' : 'Show Options'
								}
								disableInteractive
							>
								<IconButton
									className={classnames(classes.expand, {
										[classes.expandOpen]: expanded,
									})}
									onClick={handleExpandClick}
								>
									<ExpandMoreIcon style={{ color: '#fff' }} />
								</IconButton>
							</Tooltip>
							<Tooltip title="Dismiss" disableInteractive>
								<IconButton onClick={handleDismiss}>
									<CloseIcon style={{ color: '#fff' }} />
								</IconButton>
							</Tooltip>
						</IconButtons>
					</CardActions>
					<Collapse in={expanded} timeout="auto" unmountOnExit>
						<Paper
							className={classes.collapse}
							style={{ borderRadius: 0 }}
						>
							{children}
						</Paper>
					</Collapse>
				</Card>
			</SnackbarContent>
		);
	}
);

BtDetailSnackbar.propTypes = {
	variant: DEFAULT,
};

BtDetailSnackbar.propTypes = {
	Icon: PropTypes.object,
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	message: PropTypes.string.isRequired,
	variant: PropTypes.oneOf([DEFAULT, INFO, SUCCESS, WARNING, ERROR]),
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

BtDetailSnackbar.displayName = 'DetailSnackbar';

export default BtDetailSnackbar;
