import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material';

const COMPETENT = 'competent',
	EXPERT = 'expert',
	MASTERFUL = 'masterful',
	NOVICE = 'novice',
	PROFICIENT = 'proficient';

const skillBandColorLookup = {
	[NOVICE]: '#0158f0',
	[COMPETENT]: '#0a07f7',
	[PROFICIENT]: '#5c04e0',
	[EXPERT]: '#c507f7',
	[MASTERFUL]: '#ed07a1',
};

const Container = styled('div')(({ level }) => ({
	alignItems: 'center',
	backgroundColor: skillBandColorLookup[level] ?? '#777',
	borderRadius: 9,
	boxSizing: 'border-box',
	display: 'flex',
	height: 18,
	justifyContent: 'center',
	maxWidth: 120,
	padding: '0 6px',

	'& > span': {
		color: '#fff',
		fontWeight: 'bold',
		textTransform: 'capitalize',
	},
}));

export const SkillLevel = ({ skillLevel }) => (
	<Container level={skillLevel}>
		<span>{skillLevel ?? 'unknown'}</span>
	</Container>
);

SkillLevel.propTypes = {
	skillLevel: PropTypes.oneOf([
		COMPETENT,
		EXPERT,
		MASTERFUL,
		NOVICE,
		PROFICIENT,
	]),
};

export default SkillLevel;
