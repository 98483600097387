import React from 'react';
import { ListTable, ListRow, ListHeader, ListCell } from '../generic/ListTable';

export default function DeviceHeader({ dev_info }) {
	//console.log(dev_info);
	return (
		<>
			{!dev_info && <div>LOADING</div>}

			{dev_info && (
				<ListTable>
					<ListRow>
						<ListHeader>Name:</ListHeader>
						<ListCell>{dev_info.name}</ListCell>
					</ListRow>
					<ListRow>
						<ListHeader>Status:</ListHeader>
						<ListCell>{dev_info.status}</ListCell>
					</ListRow>
					<ListRow>
						<ListHeader>Description:</ListHeader>
						<ListCell>{dev_info.description}</ListCell>
					</ListRow>
					<ListRow>
						<ListHeader>Device Type:</ListHeader>
						<ListCell>{dev_info.type.device_code}</ListCell>
					</ListRow>
					<ListRow>
						<ListHeader>Organisation:</ListHeader>
						<ListCell>{dev_info.organisation}</ListCell>
					</ListRow>
					<ListRow>
						<ListHeader>Site:</ListHeader>
						<ListCell>{dev_info.site}</ListCell>
					</ListRow>
					<ListRow>
						<ListHeader>Line:</ListHeader>
						<ListCell>{dev_info.line}</ListCell>
					</ListRow>
					<ListRow>
						<ListHeader>Server Address:</ListHeader>
						<ListCell>{dev_info.server.address}</ListCell>
					</ListRow>
					<ListRow>
						<ListHeader>Server Port:</ListHeader>
						<ListCell>{dev_info.server.port}</ListCell>
					</ListRow>
				</ListTable>
			)}
		</>
	);
}
