import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import {
	Button,
	Collapse,
	IconButton,
	Tooltip,
	Typography,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material';
import { v4 as generateUuid } from 'uuid';

import { GROUP, PAGE, SECTION } from '../../processing/utils/constants';
import { useWorkflowSessionContext } from '../../contexts/WorkflowSessionContext';
import WorkflowAnswer from './WorkflowAnswer';

const Container = styled('div')(({ theme }) => ({
	borderLeftColor: theme.palette.grey['400'],
	margin: '1em 0',
	width: '100%',
}));

const Header = styled('div')(() => ({
	alignItems: 'center',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	width: '100%',
}));

const TitleContainer = styled('div')(() => ({
	alignItems: 'center',
	display: 'flex',
}));

export default function WorkflowSummaryNode({
	isRoot,
	navigationStack = [],
	page,
}) {
	const { isFinal, returnFromSummary } = useWorkflowSessionContext();

	const { answers } = page;

	const [collapsed, setCollapsed] = useState();

	const flattenSections = children => {
		const sectionChildren = children.filter(
			({ open, type }) => type === SECTION && open
		);

		const nonSectionChildren = children.filter(
			({ type }) => type !== SECTION
		);

		const flattened = sectionChildren.reduce(
			(accumulator, { children }) => [
				...accumulator,
				...flattenSections(children),
			],
			[]
		);

		return [...nonSectionChildren, ...flattened];
	};

	const unifiedChildren = flattenSections(page.children);

	const pageName = useMemo(
		() => {
			let name = page.name;

			const { uuid } =
				page.children?.find(({ primary }) => primary) || {};

			const primaryAnswer = (page.answers || {})[uuid];

			if (primaryAnswer) {
				return primaryAnswer;
			}

			return name;
		},
		[page]
	);

	return (
		<Container
			style={{
				...(!isRoot
					? {
							borderLeftStyle: 'solid',
							borderLeftWidth: 2,
							paddingLeft: 20,
					  }
					: {}),
			}}
		>
			<Header>
				<TitleContainer>
					<Typography style={{ display: 'inline' }} variant="h5">
						{pageName}
					</Typography>
					<Tooltip
						disableInteractive
						style={{ marginLeft: 5 }}
						title={collapsed ? 'Expand' : 'Collapse'}
					>
						<IconButton onClick={() => setCollapsed(prev => !prev)}>
							{collapsed ? (
								<ExpandMoreIcon />
							) : (
								<ExpandLessIcon />
							)}
						</IconButton>
					</Tooltip>
				</TitleContainer>
				{!isFinal && (
					<Button
						disableElevation
						onClick={() => returnFromSummary(page, navigationStack)}
					>
						Edit Page
					</Button>
				)}
			</Header>
			<Collapse in={!collapsed}>
				{unifiedChildren.map(child => {
					const { childType, hidden, linkUuid, type } = child;

					if (
						hidden ||
						(Array.isArray(child.children) &&
							child.children[0]?.hidden)
					) {
						return null;
					}

					const getChildPage = childPage => {
						return (
							<WorkflowSummaryNode
								key={generateUuid()}
								page={childPage.page}
								navigationStack={[
									...navigationStack,
									{
										page,
										referrer: linkUuid,
									},
								]}
							/>
						);
					};

					if (type === PAGE || childType === PAGE) {
						if (type === GROUP) {
							return child.children.map(page =>
								getChildPage(page)
							);
						}

						return getChildPage(child);
					}

					return (
						<WorkflowAnswer
							answers={answers || {}}
							child={child}
							key={child.uuid}
						/>
					);
				})}
			</Collapse>
		</Container>
	);
}

WorkflowSummaryNode.propTypes = {
	isRoot: PropTypes.bool,
	navigationStack: PropTypes.array,
	page: PropTypes.object.isRequired,
};
