import {
	BOOLEAN,
	DATE,
	NUMBER,
	STRING,
} from '../../../../utils/commonDataTypes';

export const getTypeDefault = type => {
	switch (type) {
		case BOOLEAN:
			return false;

		case DATE:
			return new Date();

		case NUMBER:
			return 0;

		case STRING: {
			return '';
		}

		default:
			return null;
	}
};
