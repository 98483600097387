import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import HorizontalRule from '@mui/icons-material/HorizontalRule';
import { styled } from '@mui/material/styles';
import { Tooltip } from '@mui/material';
import _ from 'lodash';

const SMALL = 'small';
const MEDIUM = 'medium';

const containerStyle = ({
	clickable,
	colour,
	disabled,
	disableinteractive,
	selected,
	size,
	theme,
}) => ({
	alignItems: 'center',
	backgroundColor: !!colour
		? theme.palette.colourPicker[colour]
		: `#777${selected ? 1 : 0}`,
	border: !colour ? `1px solid ${theme.palette.grey['400']}` : 'none',
	borderRadius: size === SMALL ? 8 : 12,
	boxShadow: `0 0 0 1px #000000${selected ? '17' : '10'}`,
	boxSizing: 'border-box',
	cursor: clickable ? 'pointer' : 'default',
	display: 'flex',
	height: size === SMALL ? 16 : 24,
	justifyContent: 'center',
	margin: 0,
	opacity: disabled ? 0.7 : 1.0,
	pointerEvents: disableinteractive ? 'none' : 'inherit',
	width: size === SMALL ? 16 : 24,

	transition: theme.transitions.create(['boxShadow', 'transform'], {
		duration: '0.15s',
	}),

	'&:hover': {
		transform: clickable ? 'scale(1.25)' : 'none',
		boxShadow: `0 0 0 ${
			clickable ? '0px #00000000' : `1px #000000${selected ? '17' : '10'}`
		}`,
	},
});

const Button = styled('button')(containerStyle);
const Indictor = styled('div')(containerStyle);

const NoColour = styled(HorizontalRule)(({ selected, size, theme }) => ({
	fill: `${theme.palette.indicators.error.main}${selected ? '99' : 'ff'}`,
	height: size === 'small' ? 18 : 24,
	position: 'absolute',
	transform: 'rotate(45deg)',
	width: size === 'small' ? 18 : 24,
}));

const Check = styled(CheckIcon)(({ none, size, theme }) => ({
	color: none ? theme.palette.text.primary : theme.palette.background.default,
	height: size === 'small' ? 18 : 24,
	position: 'absolute',
	transform: 'scale(0.9)',
	width: size === 'small' ? 18 : 24,
}));

export default function BtColour({
	colour,
	disabled,
	disableInteractive,
	onClick,
	selected,
	size,
	style,
	...props
}) {
	const Container = useMemo(() => (disableInteractive ? Indictor : Button), [
		disableInteractive,
	]);

	return (
		<div style={style}>
			<Tooltip
				title={colour ? _.capitalize(colour) : 'None'}
				disableInteractive
			>
				<Container
					role={disableInteractive ? null : 'button'}
					clickable={+Boolean(onClick)}
					colour={colour?.toLowerCase()}
					disabled={+Boolean(disabled)}
					disableinteractive={+Boolean(disableInteractive)}
					onClick={() => {
						if (!disabled && !disableInteractive) {
							onClick?.();
						}
					}}
					selected={+Boolean(selected)}
					size={size}
					{...props}
				>
					{!colour && <NoColour selected={selected} size={size} />}
					{selected && <Check none={+Boolean(!colour)} />}
				</Container>
			</Tooltip>
		</div>
	);
}

BtColour.defaultProps = {
	size: MEDIUM,
};

BtColour.propTypes = {
	colour: PropTypes.string,
	disabled: PropTypes.bool,
	disableInteractive: PropTypes.bool,
	onClick: PropTypes.func,
	selected: PropTypes.bool,
	size: PropTypes.oneOf([SMALL, MEDIUM]),
	style: PropTypes.object,
};
