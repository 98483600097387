import React from 'react';

import { Typography } from '@mui/material';

function FooterTypography(props) {
	return (
		<Typography
			variant="caption"
			onClick={props.onClick}
			sx={{ cursor: 'pointer' }}
		>
			{props.text}
		</Typography>
	);
}

function FooterTypographyBarSpacer() {
	return (
		<Typography
			sx={{ paddingLeft: '10px', paddingRight: '10px' }}
			variant="caption"
		>
			{' '}
			|{' '}
		</Typography>
	);
}

export { FooterTypography, FooterTypographyBarSpacer };
