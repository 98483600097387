import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';

import ReplayIcon from '@mui/icons-material/Replay';
import SubmitIcon from '@mui/icons-material/ArrowUpward';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import BtConfirmDialog from '../../../../components/generic/BtConfirmDialog';
import { useAppContext } from '../../../../context/ContextManager';
import useStateManager from '../processing/hooks/useStateManager';
import WorkflowNextStateDialog from '../components/core/WorkflowNextStateDialog';
import { workflowSubmissionUpdate } from '../../../../API';

const WorkflowSessionContext = createContext();

export const useWorkflowSessionContext = () => {
	const context = useContext(WorkflowSessionContext);

	if (context === undefined) {
		throw new Error(
			'useWorkflowSessionContext was used outside of its Provider'
		);
	}

	return context;
};

export function WorkflowSessionContextProvider({
	children,
	repeatTemplate,
	submission,
}) {
	const {
		complete,
		generateSubmissionUpdate,
		processAttachmentUpload,
		initialiseWorkflowSession,
		isFinal,
		navigateToPage,
		navigationStack,
		nextStates,
		sessionUuid,
		setWorkflowComments,
		submitPage,
		workflowComments,
		workflowSubmission,
		...other
	} = useStateManager();

	const blockRepeat = useRef(false);
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const { userInfo } = useAppContext();

	const [canLeave, setCanLeave] = useState(false);
	const [nextState, setNextState] = useState();
	const [nextStateDialog, setNextStateDialog] = useState(false);
	const [repeatDialog, setRepeatDialog] = useState(false);
	const [sessionSubmitting, setSessionSubmitting] = useState(false);
	const [showConfirmDialog, setShowConfirmDialog] = useState(false);
	const [showSummary, setShowSummary] = useState(false);
	const [showWorkflowComments, setShowWorkflowComments] = useState(false);
	const [success, setSuccess] = useState(false);

	const isInitialState = useMemo(() => submission.log.length === 1, [
		submission.log,
	]);

	const flowElement = useMemo(
		() =>
			submission?.template.flow.find(
				({ state }) => state === submission.status.status
			),
		[submission]
	);

	const disableSummary = submission?.template.disableSummary;

	const isRoot = useMemo(() => (navigationStack || []).length === 0, [
		navigationStack,
	]);

	const singleState = useMemo(() => nextStates?.length === 1, [nextStates]);

	const commentAvailability = useMemo(
		() =>
			['components', 'workflow'].reduce(
				(accumulator, element) => {
					const availability =
						flowElement?.commentAvailability?.[element];

					if (!availability) return accumulator;

					return {
						...accumulator,
						[element]:
							availability === 'editable' && isFinal
								? 'readOnly'
								: availability,
					};
				},
				{ components: 'hidden', workflow: 'hidden' }
			),
		[flowElement, isFinal]
	);

	const submitSession = useCallback(
		async newState => {
			setCanLeave(true);
			setSessionSubmitting(true);

			const allowRepeat = !blockRepeat.current;
			blockRepeat.current = false;

			try {
				const newSessionLog = {
					uuid: sessionUuid,
					timestamp: Date.now(),
					status: newState,
					issuer_type: 'User',
					issuer_id: userInfo.uuid,
				};

				// Process any new attachments that need uploading
				await processAttachmentUpload();

				// Upload the submission updates
				await workflowSubmissionUpdate(
					generateSubmissionUpdate(newSessionLog)
				);

				setSessionSubmitting(false);
				setSuccess(true);

				enqueueSnackbar('Workflow Submission Updated', {
					variant: 'success',
				});

				if (
					submission?.template.repeatable &&
					allowRepeat &&
					isInitialState
				) {
					setRepeatDialog(true);

					return;
				}

				history.push('/Workflows/Submissions');
			} catch (error) {
				console.error(error);

				setCanLeave(false);
				setSessionSubmitting(false);

				enqueueSnackbar(`Workflow Submission Update Failed!`, {
					variant: 'error',
				});
			}
		},
		[
			enqueueSnackbar,
			generateSubmissionUpdate,
			history,
			isInitialState,
			sessionUuid,
			setCanLeave,
			setSessionSubmitting,
			submission,
			userInfo,
		]
	);

	const saveChanges = useCallback(
		async () => {
			blockRepeat.current = true;

			const status = workflowSubmission.status.status;

			const showError = () =>
				enqueueSnackbar(
					`An error occurred when attempting to save your changes`,
					{
						variant: 'error',
					}
				);

			if (!status) {
				showError();
			}

			await submitSession(status);
		},
		[enqueueSnackbar, submitSession, workflowSubmission]
	);

	const onConfirmDialogClose = useCallback(
		() => {
			setShowConfirmDialog(false);

			if (!singleState) {
				setNextState(null);
			}
		},
		[singleState]
	);

	const onConfirmSubmit = useCallback(
		() => {
			setShowConfirmDialog(false);

			submitSession(nextState.state);
		},
		[nextState, submitSession]
	);

	const confirmBeforeSubmission = useCallback(
		(state = nextState) => {
			if (state?.confirm) {
				if (nextState !== state) {
					setNextState(state);
				}

				setShowConfirmDialog(true);

				return;
			}

			submitSession(state.state);
		},
		[nextState, submitSession]
	);

	const handlePageSubmit = useCallback(
		() => {
			submitPage();

			if (complete && isRoot) {
				if (disableSummary) {
					if (nextState) {
						confirmBeforeSubmission();

						return;
					}

					setNextStateDialog(true);

					return;
				}

				setShowSummary(true);
			}
		},
		[
			complete,
			confirmBeforeSubmission,
			disableSummary,
			isRoot,
			nextState,
			submitPage,
		]
	);

	const handleStatusSelection = useCallback(
		status => {
			setNextState(status);

			setNextStateDialog(false);

			confirmBeforeSubmission(status);
		},
		[confirmBeforeSubmission]
	);

	const returnFromSummary = useCallback(
		(page, navStack) => {
			navigateToPage(page, navStack);

			setShowSummary(false);
		},
		[navigateToPage]
	);

	useEffect(
		() => {
			if (singleState) {
				setNextState(nextStates[0]);
			}
		},
		[nextStates, singleState]
	);

	useEffect(
		() => {
			if (submission) {
				initialiseWorkflowSession(submission);
			}
		},
		[initialiseWorkflowSession, submission]
	);

	return (
		<WorkflowSessionContext.Provider
			value={{
				canLeave,
				commentAvailability,
				complete,
				confirmBeforeSubmission,
				disableSummary,
				flowElement,
				generateSubmissionUpdate,
				handlePageSubmit,
				initialiseWorkflowSession,
				isFinal,
				navigateToPage,
				navigationStack,
				nextStates,
				returnFromSummary,
				saveChanges,
				sessionSubmitting,
				sessionUuid,
				setCanLeave,
				setShowWorkflowComments,
				setWorkflowComments,
				showSummary,
				showWorkflowComments,
				submitPage,
				success,
				workflowComments,
				workflowSubmission,
				...other,
			}}
		>
			{children}
			<WorkflowNextStateDialog
				onClose={() => setNextStateDialog(false)}
				onStateSubmit={handleStatusSelection}
				open={nextStateDialog}
				states={nextStates}
			/>
			<BtConfirmDialog
				action={onConfirmSubmit}
				ActionIcon={<SubmitIcon />}
				onClose={onConfirmDialogClose}
				open={showConfirmDialog}
				prompt={`Are you sure you want to change the state of this workflow to ${
					nextState?.text
				}?`}
				title="Confirm Update"
				verb="Submit"
			/>
			<BtConfirmDialog
				action={repeatTemplate}
				ActionIcon={<ReplayIcon />}
				cancelAction={() => history.push('/Workflows/Submissions')}
				cancelVerb="Finish"
				onClose={() => setRepeatDialog(false)}
				open={repeatDialog}
				prompt={`Would you like to create another ${
					submission?.template.name
				} workflow?`}
				title="Repeat Workflow"
				verb="Create Another"
			/>
		</WorkflowSessionContext.Provider>
	);
}

WorkflowSessionContextProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	repeatTemplate: PropTypes.func.isRequired,
	submission: PropTypes.object,
};
