import React from 'react';

import {
	BtFormContent,
	BtFormSection,
	BtFormSelect,
} from '../../../components/generic/forms';
import { regionSettingsSelectData } from './selectData';

export default function SectionRegionalSettings() {
	return (
		<BtFormSection title="Regional Settings">
			<BtFormContent>
				{regionSettingsSelectData.map(select => {
					const { field, label, data } = select;
					return (
						<BtFormSelect
							key={field}
							name={field}
							label={label}
							items={data}
						/>
					);
				})}
			</BtFormContent>
		</BtFormSection>
	);
}
