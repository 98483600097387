import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	CircularProgress,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from '@mui/material';
import CreateIcon from '@mui/icons-material/Add';
import * as yup from 'yup';

import BtDialog from '../../../components/generic/BtDialog';
import {
	BtForm,
	BtFormActionsContainer,
	BtFormContent,
	BtFormCheckbox,
	BtFormTextField,
	BtFormSelect,
	withFormContextMethods,
	BtFormLateralContainer,
} from '../../../components/generic/forms';

import {
	a11yProps,
	BtTab,
	BtTabBar,
	BtTabPanel,
} from '../../../components/generic/BtTabView';

import { dataSetGetList, imageRepoGetList } from '../../../API';
import useFetch from '../../../hooks/useFetch';
import BtFormValidationBuilder from '../../../components/generic/forms/BtFormValidationBuilder';

import ReactJson from 'react-json-view';
import { useThemeContext } from '../../../context/ThemeContext';

const ActionButtons = withFormContextMethods(
	({ formState: { isDirty }, onClose, sending }) => {
		return (
			<>
				<Button variant="outlined" onClick={onClose} disabled={sending}>
					Cancel
				</Button>
				<Button
					variant="contained"
					disabled={!isDirty || sending}
					type="submit"
					startIcon={
						sending ? (
							<CircularProgress size={20} />
						) : (
							<CreateIcon />
						)
					}
					disableElevation
				>
					Apply Changes
				</Button>
			</>
		);
	}
);

export default function WorkflowTemplateComponentDialog({
	onClose,
	open,
	component,
	componentUpdate,
	componentIndex,
	pageList,
	templateDataSources,
	imageRepos,
}) {
	const [currentTab, setCurrentTab] = useState(0);
	const [defaultAnswers, setDefaultAnswers] = useState({});
	const { themeDark } = useThemeContext();

	const schema = yup.object({
		name: yup
			.string()
			.min(3)
			.required()
			.label('Minimum name length of 3 requiured'),
		description: yup.string(),
		type: yup.string().required(),
		required: yup.boolean().nullable(),
		repeatable: yup.boolean().nullable(),
		minCount: yup.number().nullable(),
		maxCount: yup.number().nullable(),
	});

	const {
		data: datasetList,
		error: datasetListError,
		loading: datasetListLoading,
		request: getDatasetList,
	} = useFetch(dataSetGetList);

	const {
		data: imageRepoList,
		error: imageRepoListError,
		loading: imageRepoListLoading,
		request: getImageRepoList,
	} = useFetch(imageRepoGetList);

	useEffect(
		() => {
			if (component) {
				// Load the dataset and image repo list ready to use
				getDatasetList();
				getImageRepoList();
			}
		},
		[component, getDatasetList, getImageRepoList]
	);

	useEffect(
		() => {
			if (datasetList && imageRepoList) {
				// console.log(component);
				var newDefaultAnswers = {};
				newDefaultAnswers.name = component.name || '';
				newDefaultAnswers.description = component.description || '';
				newDefaultAnswers.type = component.type || 'TextField';
				newDefaultAnswers.required = component.required || false;
				newDefaultAnswers.repeatable = component.repeatable || false;
				newDefaultAnswers.maxCount = component.maxCount || 1;
				newDefaultAnswers.minCount = component.minCount || 1;

				newDefaultAnswers.sticky =
					component.stickiness?.isSticky || false;
				newDefaultAnswers.stickyExpiryType =
					component.stickiness?.expiryType || 'indefinite';

				newDefaultAnswers.stickyDistance = 0.5;
				newDefaultAnswers.stickyTemporalValue = 'today';
				newDefaultAnswers.stickyDurationValue = 1;
				newDefaultAnswers.stickyDurationUnit = 'hours';
				switch (newDefaultAnswers.stickyExpiryType) {
					case 'geographical_distance':
						newDefaultAnswers.stickyDistance =
							component.stickiness?.expiryValue || 0.5;
						break;

					case 'temporal_deictic_term':
						newDefaultAnswers.stickyTemporalValue =
							component.stickiness?.expiryValue || 'today';
						break;

					default:
					case 'time_duration':
						newDefaultAnswers.stickyDurationValue =
							component.stickiness?.expiryValue?.quantity || 9;
						newDefaultAnswers.stickyDurationUnit =
							component.stickiness?.expiryValue?.unit || 'hours';
						break;
				}

				if (component.answerSource) {
					if (
						component.answerSource.uuid &&
						component.answerSource.propName
					) {
						newDefaultAnswers.answerSource = true;
						newDefaultAnswers.answerSourceUuid =
							component.answerSource.uuid;
						newDefaultAnswers.answerSourcePropName =
							component.answerSource.propName;
					}
				}

				switch (component.type) {
					default:
					case 'TextField':
						break;
					case 'NumberField':
						break;
					case 'Select':
						newDefaultAnswers.selectOptionSource =
							component.optionSource;
						// if (component.optionSource === 'list') {
						// } else if (component.optionSource === 'dataset') {
						// 	// console.log(component.dataSource);

						// 	newDefaultAnswers.selectDatasetType =
						// 		component.dataSource?.type ?? 'dataset';
						// 	newDefaultAnswers.selectDatasetUuid =
						// 		component.dataSource?.uuid;
						// 	newDefaultAnswers.selectDatasetFilter =
						// 		component.dataSource?.filter ?? '';
						// 	newDefaultAnswers.selectDatasetSort =
						// 		component.dataSource?.sort ?? '';
						// 	newDefaultAnswers.selectDatasetQuery =
						// 		component.dataSource?.query ?? '';
						// 	newDefaultAnswers.selectDatasetVariables =
						// 		component.dataSource?.variables ?? [];
						// 	newDefaultAnswers.selectDatasetLabel =
						// 		component.dataSource?.label ?? '';
						// 	newDefaultAnswers.selectDatasetValue =
						// 		component.dataSource?.value ?? '';
						// }
						break;
					case 'Section':
						break;
					case 'Page':
						newDefaultAnswers.pagePageId = component.pageUuid || '';
						break;
					case 'Checkbox':
						break;
					case 'Image':
						newDefaultAnswers.imageRepoUuid =
							component.imageRepoUuid;
						break;
					case 'Signature':
						newDefaultAnswers.signatureImageRepoUuid =
							component.imageRepoUuid;
						break;
					case 'Barcode':
						break;
					case 'Date':
						break;
					case 'DateTime':
						break;
					case 'Time':
						break;
				}
				// console.log('newDefaultAnswers', newDefaultAnswers);
				setDefaultAnswers(newDefaultAnswers);
			}
		},
		[component, datasetList, imageRepoList]
	);

	const onSubmit = formData => {
		console.log('OnSubmit Clicked', formData);

		var updatedComponent = {
			uuid: component.uuid,
			name: formData.name,
			description: formData.description,
			type: formData.type,
			validation: formData.validation || [],
		};

		if (formData.required) {
			updatedComponent.required = true;
		}

		if (formData.repeatable) {
			updatedComponent.repeatable = true;
			updatedComponent.maxCount = formData.maxCount || 1;
			updatedComponent.minCount = formData.minCount || 1;
		}

		if (formData.sticky) {
			switch (formData.stickyExpiryType) {
				case 'indefinite':
					updatedComponent.stickiness = {
						isSticky: true,
						expiryType: 'indefinite',
					};
					break;

				case 'geographical_distance':
					updatedComponent.stickiness = {
						isSticky: true,
						expiryType: 'geographical_distance',
						expiryValue: formData.stickyDistance,
					};
					break;

				case 'temporal_deictic_term':
					updatedComponent.stickiness = {
						isSticky: true,
						expiryType: 'temporal_deictic_term',
						expiryValue: formData.stickyTemporalValue,
					};
					break;

				case 'time_duration':
					updatedComponent.stickiness = {
						isSticky: true,
						expiryType: 'time_duration',
						expiryValue: {
							quantity: formData.stickyDurationValue,
							unit: formData.stickyDurationUnit,
						},
					};
					break;

				default:
					break;
			}
		}

		if (formData.answerSource) {
			updatedComponent.answerSource = {
				uuid: formData.answerSourceUuid,
				propName: formData.answerSourcePropName,
			};
		}

		switch (updatedComponent.type) {
			default:
			case 'TextField':
				break;
			case 'NumberField':
				break;
			case 'Select':
				updatedComponent.optionSource = formData.selectOptionSource;
				if (formData.selectOptionSource === 'list') {
					// options: [
					// 	{
					// 		value: 'warehouse',
					// 		label: 'Warehouse',
					// 	},
					// 	{
					// 		value: 'cutting',
					// 		label: 'Cutting',
					// 	},
					// 	{
					// 		value: 'end_finish',
					// 		label: 'End Finish',
					// 	},
					// 	{
					// 		value: 'bending',
					// 		label: 'Bending',
					// 	},
					// 	{
					// 		value: 'finishing',
					// 		label: 'Finishing',
					// 	},
					// ],
				} else if (formData.selectOptionSource === 'dataset') {
					// dataSource{
					// type: 'dataset',
					// 					uuid: '28cb9da8-59ce-46a0-857a-92fa6db83951',
					// 					filter: '',
					// 					sort: '',
					// 					query: '[{"$group":{"_id":"$Category", "Category":{"$first":"$Category"}}},{"$sort":{"Category":1}}]',
					// 					variables: null,
					// 					label: 'Category',
					// 					value: 'Category',
					// }
					// updatedComponent.dataSource = {};
					// updatedComponent.dataSource.type =
					// 	formData.selectDatasetType ?? 'dataset';
					// updatedComponent.dataSource.uuid =
					// 	formData.selectDatasetUuid;
					// updatedComponent.dataSource.filter =
					// 	formData.selectDatasetFilter ?? '';
					// updatedComponent.dataSource.sort =
					// 	formData.selectDatasetSort ?? '';
					// updatedComponent.dataSource.query =
					// 	formData.selectDatasetQuery ?? '';
					// updatedComponent.dataSource.variables =
					// 	formData.selectDatasetVariables ?? null;
					// updatedComponent.dataSource.label =
					// 	formData.selectDatasetLabel ?? '';
					// updatedComponent.dataSource.value =
					// 	formData.selectDatasetValue ?? '';
				}
				break;
			case 'Section':
				updatedComponent.children = component.children;
				updatedComponent.openConditions =
					component.sectionOpenConditions;
				break;
			case 'Page':
				updatedComponent.pageUuid = formData.pagePageId;
				break;
			case 'Checkbox':
				break;
			case 'Image':
				updatedComponent.imageRepoUuid = formData.imageRepoUuid;
				break;
			case 'Signature':
				updatedComponent.imageRepoUuid =
					formData.signatureImageRepoUuid;
				break;
			case 'Barcode':
				break;
			case 'Date':
				break;
			case 'DateTime':
				break;
			case 'Time':
				break;
		}

		console.log('updatedComponent', updatedComponent);

		componentUpdate(updatedComponent);

		onClose();
	};

	const RepatableController = withFormContextMethods(({ watch }) => {
		const isVisible = watch('repeatable');

		if (isVisible) {
			return (
				<BtFormLateralContainer>
					<BtFormTextField
						type="number"
						name="minCount"
						label="Minimium Reapeat"
					/>

					<BtFormTextField
						type="number"
						name="maxCount"
						label="Maximum Reapeat"
					/>
				</BtFormLateralContainer>
			);
		}

		return null;
	});

	const StickyController = withFormContextMethods(({ watch }) => {
		const isVisible = watch('sticky');

		if (isVisible) {
			return (
				<>
					<BtFormSelect
						name="stickyExpiryType"
						label="Sticky Type"
						items={[
							{ label: 'Indefinite', value: 'indefinite' },
							{
								label: 'Distance',
								value: 'geographical_distance',
							},
							{
								label: 'Temporal',
								value: 'temporal_deictic_term',
							},
							{ label: 'Duration', value: 'time_duration' },
						]}
					/>
					<StickyTypeController />
				</>
			);
		}

		return null;
	});

	const AnswerSourceController = withFormContextMethods(({ watch }) => {
		const isVisible = watch('answerSource');

		if (isVisible) {
			return (
				<>
					<BtFormSelect
						name="answerSourceUuid"
						label="Template Data Source Uuid"
						items={(templateDataSources || []).map(dataSource => {
							return {
								label: dataSource.name || dataSource.uuid,
								value: dataSource.uuid,
							};
						})}
						required
					/>
					<BtFormTextField
						name="answerSourcePropName"
						label="Prop Name"
						required
					/>
				</>
			);
		}

		return null;
	});

	const StickyTypeController = withFormContextMethods(({ watch }) => {
		const stickyType = watch('stickyExpiryType');

		if (stickyType === 'geographical_distance') {
			return (
				<BtFormTextField
					type="number"
					name="stickyDistance"
					label="Distance (km)"
				/>
			);
		} else if (stickyType === 'temporal_deictic_term') {
			return (
				<BtFormSelect
					name="stickyTemporalValue"
					label="Persist For"
					items={[
						{ label: 'This Minute', value: 'minute' },
						{
							label: 'This Hour',
							value: 'hour',
						},
						{ label: 'Today', value: 'today' },
						{ label: 'Today & Tomorrow', value: 'tomorrow' },
						{ label: 'This Week', value: 'week' },
						{ label: 'This Month', value: 'month' },
						{ label: 'This Year', value: 'year' },
					]}
				/>
			);
		} else if (stickyType === 'time_duration') {
			return (
				<BtFormLateralContainer>
					<BtFormTextField
						type="number"
						name="stickyDurationValue"
						label="Duration"
					/>

					<BtFormSelect
						name="stickyDurationUnit"
						label="Unit"
						sx={{ minWidth: '200px' }}
						items={[
							{ label: 'Seconds', value: 'seconds' },
							{
								label: 'Minutes',
								value: 'minutes',
							},
							{ label: 'Hours', value: 'hours' },
							{ label: 'Days', value: 'days' },
							{ label: 'Weeks', value: 'weeks' },
							{ label: 'Months', value: 'months' },
							{ label: 'Years', value: 'years' },
						]}
					/>
				</BtFormLateralContainer>
			);
		} else if (stickyType === 'indefinite') {
			// return (
			// 	<BtFormLateralContainer>
			// 		<BtFormTextField
			// 			type="number"
			// 			name="minCount"
			// 			label="Minimium Reapeat"
			// 		/>
			// 		<BtFormTextField
			// 			type="number"
			// 			name="maxCount"
			// 			label="Maximum Reapeat"
			// 		/>
			// 	</BtFormLateralContainer>
			// );
		}

		return null;
	});

	const ComponentTypePropsController = withFormContextMethods(({ watch }) => {
		const typeValue = watch('type');

		switch (typeValue) {
			default:
			case 'TextField':
				return (
					<>
						<BtFormValidationBuilder
							valueType={'String'}
							currentValidation={component.validation}
							title={'String Validation'}
							name={'validation'}
						/>
					</>
				);
			case 'NumberField':
				return (
					<>
						<BtFormValidationBuilder
							valueType={'Number'}
							currentValidation={component.validation}
							title={'Number Validation'}
							name={'validation'}
						/>
					</>
				);
			case 'Select':
				return (
					<>
						<Typography variant="h6">Select Options</Typography>
						<BtFormSelect
							label="Options Source"
							sx={{ width: '100%' }}
							name="selectOptionSource"
							items={[
								{ label: 'List', value: 'list' },
								{
									label: 'Data Set',
									value: 'dataset',
								},
							]}
						/>
						<SelectOptionsController />
					</>
				);
			case 'Section':
				break;
			case 'Page':
				return (
					<>
						<Typography variant="h6">Page Options</Typography>
						<Typography variant="body">
							When selecting a page it's important to make sure
							that you do not nested loop. This editor does not
							currently perform any checking!
						</Typography>
						<BtFormSelect
							label="Page"
							sx={{ width: '100%' }}
							name="pagePageId"
							//items={[{ label: 'GET THE LIST', value: 'list' }]}
							items={pageList.map((page, index) => {
								return { label: page.name, value: page.uuid };
							})}
						/>
					</>
				);
			case 'Checkbox':
				break;
			case 'Image':
				return (
					<>
						<Typography variant="h6">Image Options</Typography>
						<BtFormSelect
							label="Image Repo"
							sx={{ width: '100%' }}
							name="imageRepoUuid"
							items={(imageRepos || []).map(imageRepo => {
								return {
									label: imageRepo.name,
									value: imageRepo.uuid,
								};
							})}
							required
						/>
					</>
				);
			case 'Signature':
				return (
					<>
						<Typography variant="h6">Signature Options</Typography>
						<BtFormSelect
							label="Image Repo"
							sx={{ width: '100%' }}
							name="signatureImageRepoUuid"
							items={(imageRepos || []).map(imageRepo => {
								return {
									label: imageRepo.name,
									value: imageRepo.uuid,
								};
							})}
							required
						/>
					</>
				);

			case 'Barcode':
				return (
					<>
						<Typography variant="h6">Barcode Options</Typography>
						<Typography variant="body">
							Add support to select supported barcode types.
						</Typography>
					</>
				);
			case 'Date':
				break;
			case 'DateTime':
				break;
			case 'Time':
				break;
		}

		return null;
	});

	const SelectOptionsController = withFormContextMethods(({ watch }) => {
		const selectSource = watch('selectOptionSource');

		if (selectSource === 'list') {
			return null;
		} else if (selectSource === 'dataset') {
			return null;

			// return (
			// 	<>
			// 		<BtFormTextField
			// 			name="selectDatasetType"
			// 			label="Type"
			// 			disabled={true}
			// 		/>
			// 		<BtFormSelect
			// 			label="Data Set"
			// 			sx={{ width: '100%' }}
			// 			name="selectDatasetUuid"
			// 			items={(datasetList || []).map(dataset => {
			// 				return { label: dataset.name, value: dataset.uuid };
			// 			})}
			// 			fetching={datasetListLoading}
			// 		/>
			// 		<BtFormTextField
			// 			name="selectDatasetFilter"
			// 			label="Filter"
			// 		/>
			// 		<BtFormTextField name="selectDatasetSort" label="Sort" />
			// 		<BtFormTextField name="selectDatasetQuery" label="Query" />
			// 		<BtFormTextField
			// 			name="selectDatasetVariables"
			// 			label="Variables"
			// 		/>
			// 		<BtKeyValueList
			// 			listItems={(component.options || []).map(option => ({
			// 				key: option.value,
			// 				value: option.label,
			// 			}))}
			// 			// onListItemsUpdate={onSelectListUpdate}
			// 		/>
			// 		<BtFormTextField name="selectDatasetLabel" label="Label" />
			// 		<BtFormTextField name="selectDatasetValue" label="Value" />
			// 	</>
			// );
		}

		return null;
	});

	const onJsonEdit = edit => {
		console.log('edit', edit);
		componentUpdate(edit.updated_src);
	};

	const onJsonAdd = add => {
		console.log('add', add);
		componentUpdate(add.updated_src);
	};

	return (
		<BtDialog open={open} onClose={onClose} minwidth="sm" fullWidth>
			<DialogTitle>Component Edit - {component?.uuid}</DialogTitle>
			<BtForm
				onSubmit={onSubmit}
				// sending={sending}
				validationSchema={schema}
				defaultValues={defaultAnswers || {}}
			>
				<DialogContent>
					<BtTabBar
						currentTab={currentTab}
						onTabChange={(event, selectedTab) =>
							setCurrentTab(selectedTab)
						}
						style={{
							transform: 'translateY(-1px)',
							marginTop: 10,
						}}
					>
						<BtTab label="Editor" {...a11yProps(0)} />
						<BtTab label="JSON" {...a11yProps(1)} />
					</BtTabBar>

					<BtTabPanel currentTab={currentTab} index={0}>
						<BtFormContent>
							<BtFormSelect
								name="type"
								label="Input Type"
								items={[
									{ label: 'Text Field', value: 'TextField' },
									{
										label: 'Number Field',
										value: 'NumberField',
									},
									{ label: 'Select', value: 'Select' },
									{ label: 'Page', value: 'Page' },
									{ label: 'Section', value: 'Section' },
									{ label: 'Check Box', value: 'Checkbox' },
									{ label: 'Image', value: 'Image' },
									{ label: 'Signature', value: 'Signature' },
									{ label: 'Barcode', value: 'Barcode' },
									{ label: 'Date', value: 'Date' },
									{ label: 'DateTime', value: 'DateTime' },
									{ label: 'Time', value: 'Time' },
								]}
							/>
							<BtFormTextField
								name="name"
								label="Name"
								autoFocus
							/>
							<BtFormTextField
								name="description"
								label="Description / Helper text"
							/>

							<BtFormCheckbox
								name="required"
								label="Required"
								style={{ marginRight: '1em' }}
							/>

							<BtFormCheckbox
								name="repeatable"
								label="Repeatable"
								style={{ marginRight: '1em' }}
							/>
							<RepatableController />

							<BtFormCheckbox
								name="sticky"
								label="Sticky"
								style={{ marginRight: '1em' }}
							/>
							<StickyController />

							<BtFormCheckbox
								name="answerSource"
								label="Has Answer Source"
								style={{ marginRight: '1em' }}
							/>
							<AnswerSourceController />

							{/* <BtFormTextField
								name="validation"
								label="YUP validation Array"
								multiline={true}
							/> */}

							<ComponentTypePropsController />
						</BtFormContent>
					</BtTabPanel>

					<BtTabPanel currentTab={currentTab} index={1}>
						{/* <pre>{JSON.stringify(component, null, 4)}</pre> */}

						<ReactJson
							src={component}
							style={{
								backgroundColor: 'transparent',
								fontSize: 14,
							}}
							theme={themeDark ? 'chalk' : 'rjv-default'}
							onEdit={onJsonEdit}
							onAdd={onJsonAdd}
						/>
					</BtTabPanel>
				</DialogContent>
				<DialogActions>
					<BtFormActionsContainer>
						{/* <ActionButtons onClose={onClose} sending={sending} /> */}
						<ActionButtons onClose={onClose} />
					</BtFormActionsContainer>
				</DialogActions>
			</BtForm>
		</BtDialog>
	);
}

ActionButtons.propTypes = {
	isDirty: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	//sending: PropTypes.bool.isRequired,
};

WorkflowTemplateComponentDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};
