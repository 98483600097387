import React, {
	useCallback,
	useEffect,
	useState,
	forwardRef,
	useImperativeHandle,
} from 'react';

import PropTypes from 'prop-types';
import { XS, SM, MD, LG, XL } from '../../style/muiTheme';
import { BOOLEAN, DATE, NUMBER, STRING } from '../../utils/commonDataTypes';

import BtSelectionTable from './BtSelectionTable';
import useFetch from '../../hooks/useFetch';
import BtError from './BtError';
import { Button, CircularProgress } from '@mui/material';

import HomeIcon from '@mui/icons-material/Home';
import RefreshIcon from '@mui/icons-material/Refresh';

const BtRemoteTable = forwardRef(function BtRemoteTable(
	{ title, apiRequest, apiRequestProps, tableId, enableRefresh, ...other },
	ref
) {
	const [dataOffset, setDataOffset] = useState(0);
	const [pageSize, setPageSize] = useState(
		+JSON.parse(localStorage.getItem(`table-${tableId}`))?.pageSize || 5
	);
	const [sort, setSort] = useState(null);
	const [searchColumn, setSearchColumn] = useState(null);
	const [searchExactness, setSearchExactness] = useState(null);
	const [searchTerm, setSearchTerm] = useState(null);

	const {
		data,
		error: dataRequestError,
		loading: dataLoading,
		request: loadData,
	} = useFetch(apiRequest);

	const triggerDataRequest = useCallback(
		() => {
			console.log('sort', sort);
			var pagination = {
				limit: pageSize,
				offset: dataOffset,
				// anchor:'',
				// sort:''
			};

			if (sort) {
				pagination.sort = `${sort.orderBy}:${sort.order}`;
			}
			// console.log('pagination', pagination);

			loadData({ ...apiRequestProps, pageData: pagination });
		},
		[loadData, apiRequestProps, pageSize, dataOffset, sort]
	);

	useImperativeHandle(ref, () => ({
		triggerTableRefresh() {
			triggerDataRequest();
		},
	}));

	useEffect(
		() => {
			triggerDataRequest();
		},
		[triggerDataRequest]
	);

	useEffect(
		() => {
			console.log({
				searchColumn: searchColumn,
				searchExactness: searchExactness,
				searchTerm: searchTerm,
			});
		},
		[searchColumn, searchExactness, searchTerm]
	);

	const refreshPage = () => {
		// loadData({ ...apiRequestProps });
		triggerDataRequest();
	};

	const handlePageSizeChange = newPageSize => {
		setPageSize(newPageSize);
		localStorage.setItem(
			`table-${tableId}`,
			JSON.stringify({ pageSize: newPageSize })
		);
	};

	if (dataRequestError) {
		return (
			<BtError
				action={() => history.push('/')}
				actionIcon={<HomeIcon />}
				actionLabel="Go To Home"
				description="An error occurred when attempted to retrieve the workflow submissions."
				title="Retrieval Error"
			/>
		);
	}

	return (
		<>
			{!dataLoading && (
				<BtSelectionTable
					title={
						enableRefresh ? (
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									flexWrap: 'wrap',
									gap: 10,
								}}
							>
								{title}
								<div>
									{!dataLoading ? (
										<Button
											color="primary"
											onClick={refreshPage}
											size="small"
											startIcon={<RefreshIcon />}
										>
											Refresh
										</Button>
									) : (
										<CircularProgress
											size={22}
											style={{ marginLeft: 6 }}
										/>
									)}
								</div>
							</div>
						) : (
							{ title }
						)
					}
					// data={data?.data}
					pageData={data?.data}
					onOffsetUpdate={newOffset => {
						setDataOffset(newOffset);
					}}
					onPageSizeUpdate={handlePageSizeChange}
					onSort={newSort => {
						setSort(newSort);
					}}
					sort={sort}
					onSearchColumnChange={setSearchColumn}
					onSearchExactnessChange={setSearchExactness}
					onSearchTermChange={setSearchTerm}
					dataOffset={dataOffset}
					pageSize={pageSize}
					recordCount={data?.pagination?.total}
					{...other}
				/>
			)}
		</>
	);
});

export default BtRemoteTable;

// Specifies the default values for props:
BtRemoteTable.defaultProps = {
	enableRefresh: true,
};

BtRemoteTable.propTypes = {
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			CellContent: PropTypes.func,
			field: PropTypes.string,
			minBreakpoint: PropTypes.oneOf([XS, SM, MD, LG, XL]),
			ordinalValues: PropTypes.arrayOf(PropTypes.any),
			sortable: PropTypes.bool,
			sortingComparator: PropTypes.func, // (a, b, order) => { return result; }
			text: PropTypes.string.isRequired,
			type: PropTypes.oneOf([BOOLEAN, DATE, NUMBER, STRING]),
		})
	),
	// containerResponsive: PropTypes.bool,
	// data: PropTypes.arrayOf(PropTypes.object),
	// dataOffset: PropTypes.string,
	// debounceSearchInput: PropTypes.bool,
	// defaultTimeRange: PropTypes.object,
	// disableSearchColumnOptions: PropTypes.bool,
	// disableSearchExactnessOptions: PropTypes.bool,
	// enableFiltering: PropTypes.bool,
	// enableGeneralSorting: PropTypes.bool,
	// enablePagination: PropTypes.bool,
	// enableSearching: PropTypes.bool,
	// filters: filtersPropType,
	// Footer: PropTypes.node,
	// getFilterOptions: PropTypes.func,
	// hideHeadings: PropTypes.bool,
	// hideSearchColumn: PropTypes.bool,
	// hideSearchExactness: PropTypes.bool,
	// idKey: PropTypes.string,
	// initialFilters: filtersPropType,
	// initialPageSize: PropTypes.number,
	// initialSearchColumn: PropTypes.string,
	// initialSearchExactness: exactnessPropType,
	// initialSort: sortPropType,
	// onChangeFilters: PropTypes.func,
	// onChangeTimeRange: PropTypes.func,
	// onClick: PropTypes.func,
	// onOffsetUpdate: PropTypes.func,
	// onPageSizeUpdate: PropTypes.func,
	// onSearchColumnChange: PropTypes.func,
	// onSearchExactnessChange: PropTypes.func,
	// onSearchTermChange: PropTypes.func,
	// onSort: PropTypes.func,
	// pageData: PropTypes.arrayOf(PropTypes.object),
	// pageSize: PropTypes.number,
	// pageSizeOptions: PropTypes.arrayOf(PropTypes.number.isRequired),
	// paginationBackgroundColor: PropTypes.string,
	// paginationFunc: PropTypes.func,
	// recordCount: PropTypes.number,
	// searchColumn: PropTypes.string,
	// searchExactness: exactnessPropType,
	// searchFunc: PropTypes.func,
	// searchInputDebounceTimeMs: PropTypes.number,
	// searchTerm: PropTypes.string,
	// size: PropTypes.string,
	// sort: sortPropType,
	// sortingFunc: PropTypes.func,
	// stickyHeader: PropTypes.bool,
	subject: PropTypes.string,
	tableContainerStyle: PropTypes.object,
	title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	apiRequest: PropTypes.func.isRequired,
	apiRequestProps: PropTypes.object,
	tableId: PropTypes.string,
	enableRefresh: PropTypes.bool,
	onClick: PropTypes.func,
};
