import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import BtChips from './BtChips';
import { Typography } from '@mui/material';
import { v4 as uuid } from 'uuid';

export default function BtDetailSection({
	chips,
	Component,
	data,
	label,
	...props
}) {
	const isArray = useMemo(() => Array.isArray(data), [data]);

	return (
		<div style={{ marginBottom: '1em', width: '100%' }} {...props}>
			<Typography variant="subtitle2">{label}</Typography>
			{Component && Component}
			{isArray &&
				!Component && (
					<>
						{chips ? (
							<BtChips readOnly value={data} size="small" />
						) : (
							data.map(text => (
								<Typography key={uuid()} variant="body1">
									{`• ${text}`}
								</Typography>
							))
						)}
					</>
				)}
			{!isArray &&
				!Component && <Typography variant="body1">{data}</Typography>}
		</div>
	);
}

BtDetailSection.propTypes = {
	chips: PropTypes.bool,
	Component: PropTypes.node,
	data: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
	label: PropTypes.string.isRequired,
};
