import { initDraftUser } from './initDraftUser';
import { lookupEndpoint } from '../lookupTables/apiEndpoints';
import { moduleUuidParam } from '../lookupTables/apiParams';
import { lookupModuleAssets, moduleUrl } from '../lookupTables/moduleAssets';
import { getOrgUsers } from '../requests/getOrgUsers';
import { handleGetHistory } from '../requests/getRecordHistory';

const defaultStaticContextValues = {
	getRecordHistoryRequest: null,
	leavePayload: null,
	apiUuidParamPayload: null,
	moduleAssets: {
		moduleFullName: null,
		moduleTitle: null,
	},
};

const defaultDraftLockValues = {
	status: null,
	lockUser: null,
};

async function contextInitializer({ module, recordUuid, userInfo, setError }) {
	const result = {};

	// set the error state to true if a lookup result is null
	const errorSetter = value => {
		if (!value) setError(true);
	};

	// define the getHistory uuid parameter & payload
	// eg insightUuid : [recordUuid]
	const initApiUuidParamPayload = () => {
		const payload = {};
		const param = moduleUuidParam(module);
		errorSetter(param);
		if (param) {
			payload[param] = recordUuid;
		}
		return payload;
	};

	// define the getHistory api endpoint
	const initGetRecordHistoryRequest = () => {
		const request = lookupEndpoint(module, 'getHistory');
		errorSetter(request);
		return request;
	};

	// define the text strings for the module
	const initModuleAssets = () => {
		const value = lookupModuleAssets(module);
		errorSetter(value);
		return value;
	};

	// retrieve the org users
	const initOrgUsers = async () => {
		const users = await getOrgUsers(setError);
		return users;
	};

	// get the record history
	const initHistory = async () => {
		const history = await handleGetHistory({
			apiUuidParamPayload: result.apiUuidParamPayload,
			getRecordHistoryRequest: result.getRecordHistoryRequest,
			setError,
		});
		return history;
	};

	// define the url for leaving the settings page
	const initLeavePayload = () => {
		const urlPayload = moduleUrl(module, 'backToRecord', recordUuid);
		return urlPayload;
	};

	result.apiUuidParamPayload = initApiUuidParamPayload();
	result.getRecordHistoryRequest = initGetRecordHistoryRequest();
	result.moduleAssets = initModuleAssets();
	result.orgUsers = await initOrgUsers();
	result.historyData = await initHistory();
	result.leavePayload = initLeavePayload();

	const { lockUser, draftLockStatus } = await initDraftUser(
		result.historyData.draft.locked_to,
		userInfo,
		setError
	);

	result.lockUser = lockUser;
	result.draftLockStatus = draftLockStatus;

	return result;
}

export {
	contextInitializer,
	defaultStaticContextValues,
	defaultDraftLockValues,
};
