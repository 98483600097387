// Basic Info
export const organisationTypes = [
	{
		value: 'SOLE_TRADER',
		label: 'Sole Trader',
	},
	{
		value: 'PARTNERSHIP',
		label: 'Partnership',
	},
	{
		value: 'NOT_FOR_PROFIT',
		label: 'Not For Profit',
	},
	{
		value: 'LIMITED_COMPANY',
		label: 'Limited Company',
	},
];

// Regional Settings
const dateFormats = [
	{
		value: 'dd.MM.yyyy',
		label: 'dd.mm.yyyy',
	},
	{
		value: 'dd-MM-yyyy',
		label: 'dd-mm-yyyy',
	},
	{
		value: 'dd/MM/yyyy',
		label: 'dd/mm/yyyy',
	},
	{
		value: 'dd-MMM-yyyy',
		label: 'dd-MMM-yyyy',
	},
	{
		value: 'MM.dd.yyyy',
		label: 'mm.dd.yyyy',
	},
	{
		value: 'MM-dd-yyyy',
		label: 'mm-dd-yyyy',
	},
	{
		value: 'MM/dd/yyyy',
		label: 'mm/dd/yyyy',
	},
	{
		value: 'MMM-dd-yyyy',
		label: 'MMM-dd-yyyy',
	},
];

const daysOfTheWeek = [
	{
		value: 'MONDAY',
		label: 'Monday',
	},
	{
		value: 'TUESDAY',
		label: 'Tuesday',
	},
	{
		value: 'WEDNESDAY',
		label: 'Wednesday',
	},
	{
		value: 'THURSDAY',
		label: 'Thursday',
	},
	{
		value: 'FRIDAY',
		label: 'Friday',
	},
	{
		value: 'SATURDAY',
		label: 'Saturday',
	},
	{
		value: 'SUNDAY',
		label: 'Sunday',
	},
];

const numberFormats = [
	{
		value: '1,000,000.00',
		label: '1,000,000.00',
	},
	{
		value: '1.000.000,00',
		label: '1.000.000,00',
	},
	{
		value: '1 000 000.00',
		label: '1 000 000.00',
	},
	{
		value: '1 000 000,00',
		label: '1 000 000,00',
	},
];

const currencies = [
	{ value: 'AFN', label: 'Afghan Afghani (AFN)' },
	{ value: 'ALL', label: 'Albanian Lek (ALL)' },
	{ value: 'DZD', label: 'Algerian Dinar (DZD)' },
	{ value: 'AOA', label: 'Angolan Kwanza (AOA)' },
	{ value: 'ARS', label: 'Argentine Peso (ARS)' },
	{ value: 'AMD', label: 'Armenian Dram (AMD)' },
	{ value: 'AWG', label: 'Aruban Florin (AWG)' },
	{ value: 'AUD', label: 'Australian Dollar (AUD)' },
	{ value: 'AZN', label: 'Azerbaijani Manat (AZN)' },
	{ value: 'BSD', label: 'Bahamian Dollar (BSD)' },
	{ value: 'BHD', label: 'Bahraini Dinar (BHD)' },
	{ value: 'BDT', label: 'Bangladeshi Taka (BDT)' },
	{ value: 'BBD', label: 'Barbadian Dollar (BBD)' },
	{ value: 'BYR', label: 'Belarusian Ruble (BYR)' },
	{ value: 'BEF', label: 'Belgian Franc (BEF)' },
	{ value: 'BZD', label: 'Belize Dollar (BZD)' },
	{ value: 'BMD', label: 'Bermudan Dollar (BMD)' },
	{ value: 'BTN', label: 'Bhutanese Ngultrum (BTN)' },
	{ value: 'BTC', label: 'Bitcoin (BTC)' },
	{ value: 'BOB', label: 'Bolivian Boliviano (BOB)' },
	{ value: 'BAM', label: 'Bosnia-Herzegovina Convertible Mark (BAM)' },
	{ value: 'BWP', label: 'Botswanan Pula (BWP)' },
	{ value: 'BRL', label: 'Brazilian Real (BRL)' },
	{ value: 'GBP', label: 'British Pound Sterling (GBP)' },
	{ value: 'BND', label: 'Brunei Dollar (BND)' },
	{ value: 'BGN', label: 'Bulgarian Lev (BGN)' },
	{ value: 'BIF', label: 'Burundian Franc (BIF)' },
	{ value: 'KHR', label: 'Cambodian Riel (KHR)' },
	{ value: 'CAD', label: 'Canadian Dollar (CAD)' },
	{ value: 'CVE', label: 'Cape Verdean Escudo (CVE)' },
	{ value: 'KYD', label: 'Cayman Islands Dollar (KYD)' },
	{ value: 'XOF', label: 'CFA Franc BCEAO (XOF)' },
	{ value: 'XAF', label: 'CFA Franc BEAC (XAF)' },
	{ value: 'XPF', label: 'CFP Franc (XPF)' },
	{ value: 'CLP', label: 'Chilean Peso (CLP)' },
	{ value: 'CNY', label: 'Chinese Yuan (CNY)' },
	{ value: 'COP', label: 'Colombian Peso (COP)' },
	{ value: 'KMF', label: 'Comorian Franc (KMF)' },
	{ value: 'CDF', label: 'Congolese Franc (CDF)' },
	{ value: 'CRC', label: 'Costa Rican ColÃ³n (CRC)' },
	{ value: 'HRK', label: 'Croatian Kuna (HRK)' },
	{ value: 'CUC', label: 'Cuban Convertible Peso (CUC)' },
	{ value: 'CZK', label: 'Czech Republic Koruna (CZK)' },
	{ value: 'DKK', label: 'Danish Krone (DKK)' },
	{ value: 'DJF', label: 'Djiboutian Franc (DJF)' },
	{ value: 'DOP', label: 'Dominican Peso (DOP)' },
	{ value: 'XCD', label: 'East Caribbean Dollar (XCD)' },
	{ value: 'EGP', label: 'Egyptian Pound (EGP)' },
	{ value: 'ERN', label: 'Eritrean Nakfa (ERN)' },
	{ value: 'EEK', label: 'Estonian Kroon (EEK)' },
	{ value: 'ETB', label: 'Ethiopian Birr (ETB)' },
	{ value: 'EUR', label: 'Euro (EUR)' },
	{ value: 'FKP', label: 'Falkland Islands Pound (FKP)' },
	{ value: 'FJD', label: 'Fijian Dollar (FJD)' },
	{ value: 'GMD', label: 'Gambian Dalasi (GMD)' },
	{ value: 'GEL', label: 'Georgian Lari (GEL)' },
	{ value: 'DEM', label: 'German Mark (DEM)' },
	{ value: 'GHS', label: 'Ghanaian Cedi (GHS)' },
	{ value: 'GIP', label: 'Gibraltar Pound (GIP)' },
	{ value: 'GRD', label: 'Greek Drachma (GRD)' },
	{ value: 'GTQ', label: 'Guatemalan Quetzal (GTQ)' },
	{ value: 'GNF', label: 'Guinean Franc (GNF)' },
	{ value: 'GYD', label: 'Guyanaese Dollar (GYD)' },
	{ value: 'HTG', label: 'Haitian Gourde (HTG)' },
	{ value: 'HNL', label: 'Honduran Lempira (HNL)' },
	{ value: 'HKD', label: 'Hong Kong Dollar (HKD)' },
	{ value: 'HUF', label: 'Hungarian Forint (HUF)' },
	{ value: 'ISK', label: 'Icelandic KrÃ³na (ISK)' },
	{ value: 'INR', label: 'Indian Rupee (INR)' },
	{ value: 'IDR', label: 'Indonesian Rupiah (IDR)' },
	{ value: 'IRR', label: 'Iranian Rial (IRR)' },
	{ value: 'IQD', label: 'Iraqi Dinar (IQD)' },
	{ value: 'ILS', label: 'Israeli New Sheqel (ILS)' },
	{ value: 'ITL', label: 'Italian Lira (ITL)' },
	{ value: 'JMD', label: 'Jamaican Dollar (JMD)' },
	{ value: 'JPY', label: 'Japanese Yen (JPY)' },
	{ value: 'JOD', label: 'Jordanian Dinar (JOD)' },
	{ value: 'KZT', label: 'Kazakhstani Tenge (KZT)' },
	{ value: 'KES', label: 'Kenyan Shilling (KES)' },
	{ value: 'KWD', label: 'Kuwaiti Dinar (KWD)' },
	{ value: 'KGS', label: 'Kyrgystani Som (KGS)' },
	{ value: 'LAK', label: 'Laotian Kip (LAK)' },
	{ value: 'LVL', label: 'Latvian Lats (LVL)' },
	{ value: 'LBP', label: 'Lebanese Pound (LBP)' },
	{ value: 'LSL', label: 'Lesotho Loti (LSL)' },
	{ value: 'LRD', label: 'Liberian Dollar (LRD)' },
	{ value: 'LYD', label: 'Libyan Dinar (LYD)' },
	{ value: 'LTL', label: 'Lithuanian Litas (LTL)' },
	{ value: 'MOP', label: 'Macanese Pataca (MOP)' },
	{ value: 'MKD', label: 'Macedonian Denar (MKD)' },
	{ value: 'MGA', label: 'Malagasy Ariary (MGA)' },
	{ value: 'MWK', label: 'Malawian Kwacha (MWK)' },
	{ value: 'MYR', label: 'Malaysian Ringgit (MYR)' },
	{ value: 'MVR', label: 'Maldivian Rufiyaa (MVR)' },
	{ value: 'MRO', label: 'Mauritanian Ouguiya (MRO)' },
	{ value: 'MUR', label: 'Mauritian Rupee (MUR)' },
	{ value: 'MXN', label: 'Mexican Peso (MXN)' },
	{ value: 'MDL', label: 'Moldovan Leu (MDL)' },
	{ value: 'MNT', label: 'Mongolian Tugrik (MNT)' },
	{ value: 'MAD', label: 'Moroccan Dirham (MAD)' },
	{ value: 'MZM', label: 'Mozambican Metical (MZM)' },
	{ value: 'MMK', label: 'Myanmar Kyat (MMK)' },
	{ value: 'NAD', label: 'Namibian Dollar (NAD)' },
	{ value: 'NPR', label: 'Nepalese Rupee (NPR)' },
	{ value: 'ANG', label: 'Netherlands Antillean Guilder (ANG)' },
	{ value: 'TWD', label: 'New Taiwan Dollar (TWD)' },
	{ value: 'NZD', label: 'New Zealand Dollar (NZD)' },
	{ value: 'NIO', label: 'Nicaraguan CÃ³rdoba (NIO)' },
	{ value: 'NGN', label: 'Nigerian Naira (NGN)' },
	{ value: 'KPW', label: 'North Korean Won (KPW)' },
	{ value: 'NOK', label: 'Norwegian Krone (NOK)' },
	{ value: 'OMR', label: 'Omani Rial (OMR)' },
	{ value: 'PKR', label: 'Pakistani Rupee (PKR)' },
	{ value: 'PAB', label: 'Panamanian Balboa (PAB)' },
	{ value: 'PGK', label: 'Papua New Guinean Kina (PGK)' },
	{ value: 'PYG', label: 'Paraguayan Guarani (PYG)' },
	{ value: 'PEN', label: 'Peruvian Nuevo Sol (PEN)' },
	{ value: 'PHP', label: 'Philippine Peso (PHP)' },
	{ value: 'PLN', label: 'Polish Zloty (PLN)' },
	{ value: 'QAR', label: 'Qatari Rial (QAR)' },
	{ value: 'RON', label: 'Romanian Leu (RON)' },
	{ value: 'RUB', label: 'Russian Ruble (RUB)' },
	{ value: 'RWF', label: 'Rwandan Franc (RWF)' },
	{ value: 'SVC', label: 'Salvadoran ColÃ³n (SVC)' },
	{ value: 'WST', label: 'Samoan Tala (WST)' },
	{ value: 'SAR', label: 'Saudi Riyal (SAR)' },
	{ value: 'RSD', label: 'Serbian Dinar (RSD)' },
	{ value: 'SCR', label: 'Seychellois Rupee (SCR)' },
	{ value: 'SLL', label: 'Sierra Leonean Leone (SLL)' },
	{ value: 'SGD', label: 'Singapore Dollar (SGD)' },
	{ value: 'SKK', label: 'Slovak Koruna (SKK)' },
	{ value: 'SBD', label: 'Solomon Islands Dollar (SBD)' },
	{ value: 'SOS', label: 'Somali Shilling (SOS)' },
	{ value: 'ZAR', label: 'South African Rand (ZAR)' },
	{ value: 'KRW', label: 'South Korean Won (KRW)' },
	{ value: 'XDR', label: 'Special Drawing Rights (XDR)' },
	{ value: 'LKR', label: 'Sri Lankan Rupee (LKR)' },
	{ value: 'SHP', label: 'St. Helena Pound (SHP)' },
	{ value: 'SDG', label: 'Sudanese Pound (SDG)' },
	{ value: 'SRD', label: 'Surinamese Dollar (SRD)' },
	{ value: 'SZL', label: 'Swazi Lilangeni (SZL)' },
	{ value: 'SEK', label: 'Swedish Krona (SEK)' },
	{ value: 'CHF', label: 'Swiss Franc (CHF)' },
	{ value: 'SYP', label: 'Syrian Pound (SYP)' },
	{ value: 'STD', label: 'São Tomé and Príncipe Dobra (STD)' },
	{ value: 'TJS', label: 'Tajikistani Somoni (TJS)' },
	{ value: 'TZS', label: 'Tanzanian Shilling (TZS)' },
	{ value: 'THB', label: 'Thai Baht (THB)' },
	{ value: 'TOP', label: "Tongan pa'anga (TOP)" },
	{ value: 'TTD', label: 'Trinidad & Tobago Dollar (TTD)' },
	{ value: 'TND', label: 'Tunisian Dinar (TND)' },
	{ value: 'TRY', label: 'Turkish Lira (TRY)' },
	{ value: 'TMT', label: 'Turkmenistani Manat (TMT)' },
	{ value: 'UGX', label: 'Ugandan Shilling (UGX)' },
	{ value: 'UAH', label: 'Ukrainian Hryvnia (UAH)' },
	{ value: 'AED', label: 'United Arab Emirates Dirham (AED)' },
	{ value: 'UYU', label: 'Uruguayan Peso (UYU)' },
	{ value: 'USD', label: 'US Dollar (USD)' },
	{ value: 'UZS', label: 'Uzbekistan Som (UZS)' },
	{ value: 'VUV', label: 'Vanuatu Vatu (VUV)' },
	{ value: 'VEF', label: 'Venezuelan BolÃ­var (VEF)' },
	{ value: 'VND', label: 'Vietnamese Dong (VND)' },
	{ value: 'YER', label: 'Yemeni Rial (YER)' },
	{ value: 'ZMK', label: 'Zambian Kwacha (ZMK)' },
];

export const regionSettingsSelectData = [
	{
		field: 'currency',
		label: 'Currency',
		data: currencies,
	},
	{
		field: 'dateFormat',
		label: 'Date Format',
		data: dateFormats,
	},
	{
		field: 'firstWeekDay',
		label: 'First Week Day',
		data: daysOfTheWeek,
	},
	{
		field: 'numberFormat',
		label: 'Number Format',
		data: numberFormats,
	},
];
