import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import CancelIcon from '@mui/icons-material/Close';
import InviteIcon from '@mui/icons-material/ForwardToInbox';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import {
	BtForm,
	BtFormActionButtons,
	BtFormActionsContainer,
	BtFormCheckbox,
	BtFormContent,
	BtFormLateralContainer,
	BtFormTextField,
} from '../../components/generic/forms';
import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from '@mui/material';
import useFetch from '../../hooks/useFetch';
import { useHistory } from 'react-router-dom';
import { user_names_regex } from '../../utils/regexLibrary';
import { userAdminPost, platformAdminUserGetList } from '../../API';
import BtSelectionTable from '../../components/generic/BtSelectionTable';
import { platformAdminOrgAddUser } from '../../API/PlatformAdmin/platformAdminOrganisation.api';

const tableColumns = [
	{ field: 'status', text: 'Status' },
	{ field: 'user_name', text: 'User Name' },
	{ field: 'email', text: 'e-mail' },
	{ field: 'last_login_timestamp', text: 'Last Log In' },
];

export default function BeaconAdminUserAdd({ orgUuid, onRefreshOrg, onClose }) {
	const { enqueueSnackbar } = useSnackbar();

	const [sending, setSending] = useState(false);

	const { loading, data: userList, error, request: getUserList } = useFetch(
		platformAdminUserGetList
	);

	useEffect(
		() => {
			getUserList();
		},
		[getUserList]
	);

	const handleTableClick = async userUuid => {
		try {
			setSending(true);
			await platformAdminOrgAddUser({
				orgUuid: orgUuid,
				userUuid: userUuid,
			});
			enqueueSnackbar(`Added user to organisation`, {
				variant: 'success',
			});
			onRefreshOrg();
			onClose();
		} catch (error) {
			enqueueSnackbar(`Error adding user to organisation`, {
				variant: 'error',
			});
		}
	};

	return (
		<>
			<DialogTitle>Add an existing user</DialogTitle>
			<DialogContent>
				<BtSelectionTable
					title={
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								flexWrap: 'wrap',
								gap: 10,
							}}
						>
							<Typography
								sx={{
									fontWeight: 'bold',
									padding: '4px 0',
								}}
								variant="h5"
							>
								Current Platform Users
							</Typography>
						</div>
					}
					onClick={handleTableClick}
					subject="users"
					columns={tableColumns}
					data={(userList || []).map(
						({
							uuid,
							status,
							user_name,
							email,
							last_login_timestamp,
						}) => ({
							id: uuid,
							status: status,
							user_name: user_name,
							email: email,
							last_login_timestamp: format(
								new Date(last_login_timestamp || 0),
								'dd MMM yyyy, hh:mm a'
							),
						})
					)}
				/>
			</DialogContent>
			<DialogActions>
				<BtFormActionsContainer>
					<Button
						variant="outlined"
						onClick={onClose}
						startIcon={<CancelIcon />}
					>
						Cancel
					</Button>
				</BtFormActionsContainer>
			</DialogActions>
		</>
	);
}

BeaconAdminUserAdd.propTypes = {
	onClose: PropTypes.func.isRequired,
};
