import { GET_VERSION_API, UPDATE_DRAFT_API } from '../constants';
import { lookupEndpoint } from '../lookupTables/apiEndpoints';

const handleUpdateDraft = async ({
	apiUuidParamPayload,
	moduleFullName,
	module,
	recordHistoryGet,
	payload,
}) => {
	// Initialise return value
	const result = {};
	result.variant = 'success';

	try {
		// get the request from lookup
		const updateDraftEndpoint = lookupEndpoint(module, UPDATE_DRAFT_API);

		// get the getVersion endpoint
		const getVersionEndpoint = lookupEndpoint(module, GET_VERSION_API);

		// raise and error if missing any endpoint
		if (!updateDraftEndpoint || !getVersionEndpoint) {
			throw new Error('API request not defined');
		}

		// if the draft is to be rebased to a version
		if (Object.hasOwn(payload, 'draft_base')) {
			const version = await getVersionEndpoint({
				...apiUuidParamPayload,
				versionIndex: payload.draft_base,
			});
			console.log({ version });
			const newDraft = {};
			newDraft.data_sources = version.data_sources;
			newDraft.definition_version = version.definition_version;
			newDraft.description = version.description;
			newDraft.draft_base = payload.draft_base; // set the draft base to the index of the version used
			newDraft.has_changes = false; // set the has changes flag to false as out draft is new
			newDraft.initial_time_range = version.initial_time_range;
			newDraft.locked_to = version.locked_to;
			newDraft.name = version.name;
			newDraft.notes = version.notes;
			newDraft.pages = version.pages;
			newDraft.palette = version.palette;
			newDraft.tile = version.tile;
			newDraft.visualisations = version.visualisations;

			await updateDraftEndpoint({
				...apiUuidParamPayload,
				draftUpdate: newDraft,
			});

			// Update return value
			result.text = `${moduleFullName} draft updated`;
		} else {
			const partialDraft = { ...payload };
			partialDraft.has_changes = true;
			await updateDraftEndpoint({
				...apiUuidParamPayload,
				draftUpdate: partialDraft,
			});

			// Update return value
			result.text = `${moduleFullName} draft updated`;
		}
	} catch (error) {
		console.log(error);
		result.text = `Error updating ${moduleFullName}`;
		result.variant = 'error';
	} finally {
		// Reload history data
		recordHistoryGet();
		return result;
	}
};

export { handleUpdateDraft };
