const {
	EXPRESSION,
	getExpressionType,
	OPERATION_MODE,
} = require('../expression/expression.js');
const { isDocument } = require('../utils.js');

const stages = {};

const MAX_INPUT_DOCS = 10000;

const ERROR = {
	UNSUPPORTED_STAGE: (stage) =>
		'UNSUPPORTED STAGE: stage is not supported: ' + stage,
	INVALID_COLLECTION_TYPE: (collection) =>
		'INVALID COLLECTION TYPE: input collection must be an array of objects, received: ' +
		typeof collection,
	INVALID_PIPELINE: (pipeline) =>
		'INVALID PIPELINE: pipeline must be an array of objects, received: ' +
		typeof pipeline,
	INVALID_STAGE_ARGUMENT: (stage, expected_type, received, position) =>
		`INVALID STAGE ARGUMENT - ${stage.slice(1)} expected${
			Array.isArray(expected_type)
				? ' one of: ' + JSON.stringify(expected_type)
				: ': ' + expected_type
		}${
			received
				? ', but received:' +
				  ' ' +
				  typeof received +
				  ' - ' +
				  JSON.stringify(received) +
				  ''
				: ''
		}${position ? ' - at position: ' + position : ''}`,
	INVALID_COLLECTION_INPUT: (doc_type, pos) =>
		'INVALID COLLECTION INPUT: expected collection to be objects' +
		(doc_type ? ', found ' + doc_type : '') +
		(pos !== undefined ? ' at pos ' + pos : ''),
};
module.exports.ERROR = ERROR;

module.exports.setStage = setStage;
function setStage(key, fn, options) {
	if (stages[key] !== undefined) {
		console.warn('Overwriting stage', key, stages[key], fn);
	}

	stages[key] = fn;
}

function getStageExpression(stage) {
	const expression = getExpressionType(stage);

	if (expression.type !== EXPRESSION.OPERATOR) {
		throw new Error(ERROR.UNSUPPORTED_STAGE(expression.type));
	}

	if (!stages[expression.operator]) {
		throw new Error(ERROR.UNSUPPORTED_STAGE(expression.operator));
	}

	return expression;
}

function compactStages(stages) {
	let newStages = [];
	let index = 0;

	for (index = 0; index < stages.length; index++) {
		let expression = getStageExpression(stages[index]);

		if (newStages.length > 0) {
			if (newStages[index - 1]?.operator === '$match') {
				let match = newStages[index - 1];
				if (expression.operator === '$match') {
					match.arguments = {
						$and: [match.arguments, expression.arguments],
					};
					continue;
				}

				if (expression.operator === '$limit') {
					// this means limit is not properly validated
					let lim = Math.min(
						match.options.limit || Infinity,
						expression.arguments,
						Infinity
					);
					match.options.limit = lim === Infinity ? undefined : lim;
					continue;
				}
			}
		}

		newStages.push(expression);
	}

	return newStages;
}

module.exports.evaluateStage = evaluateStage;
function evaluateStage(context, stageExpression) {
	// const expression = determineStageType(context, stage);

	return stages[stageExpression.operator](
		context,
		stageExpression.arguments,
		stageExpression.options
	);
}

module.exports.evaluatePipeline = evaluatePipeline;
async function evaluatePipeline(collection, pipeline, options) {
	/**
	 * @type {PipelineStageContext}
	 */
	const context = {
		collection: collection,
		now: new Date(options?.now || Date.now()),
		pipelineOptions: {
			maxInputDocs:
				Number(options?.maxInputDocs || 0) > 0
					? Number(options?.maxInputDocs)
					: MAX_INPUT_DOCS,
		},
		userVariables: options?.variables || [],
	};

	if (!Array.isArray(pipeline)) {
		throw new Error(ERROR.INVALID_PIPELINE(pipeline));
	}

	let p = pipeline;

	if (!options?.noCompact) {
		p = compactStages(pipeline);
	}

	let hasReadCollection = false;

	for (let s = 0; s < p.length; s++) {
		let stageExpression = p[s];

		if (options?.noCompact) {
			stageExpression = getStageExpression(p[s]);
		}

		if (!hasReadCollection) {
			await readCollection(
				context,
				stageExpression.operator === '$match' ? stageExpression : null
			);

			if (stageExpression.operator === '$match') {
				continue;
			}
		}

		context.collection = evaluateStage(context, stageExpression);
	}

	return context.collection;
}

module.exports.readCollection = readCollection;
async function readCollection(context, matchStage = null) {
	if (Array.isArray(context.collection)) {
		const collection = context.collection.slice(0, MAX_INPUT_DOCS);

		if (matchStage) {
			context.collection = evaluateStage(
				{ ...context, collection: collection },
				matchStage
			);
		}

		// else just leave it as is and continue - everything is good to go.
		return;
	} else if (typeof context.collection === 'function') {
		let idx = 0;

		const collection = [];

		const toProcess = context.pipelineOptions.maxInputDocs;

		while (idx < toProcess && idx < MAX_INPUT_DOCS) {
			// // Limit the amount of docs which can be processed
			// if (idx > MAX_INPUT_DOCS) {
			// 	break;
			// }

			const inputDoc = await context.collection(idx);
			if (inputDoc === null) {
				break;
			}
			if (typeof inputDoc !== 'object') {
				throw new Error(
					ERROR.INVALID_COLLECTION_INPUT(typeof inputDoc, idx)
				);
			}

			idx++;

			// if we have an optional match stage set then carry out the match
			// against the single item as a collection, if result of match is
			// an empty array then the single item failed the match
			// exclude it and continue
			if (matchStage) {
				// act on the single item as a collection
				const matchResult = evaluateStage(
					{ ...context, current: inputDoc, collection: [inputDoc] },
					matchStage
				);
				if (matchResult.length === 0) {
					continue;
				}
			}

			collection.push(inputDoc);
			if (
				matchStage?.options?.limit &&
				collection.length >= matchStage?.options?.limit
			) {
				// if there's a limit option set on the stage via earlier compact
				// then no need to read the rest of the data set
				break;
			}
		}

		context.collection = collection;

		return;
	}

	throw new Error(ERROR.INVALID_COLLECTION_TYPE(context.collection));
}

module.exports.evaluateObjectMatch = evaluateObjectMatch;
function evaluateObjectMatch(obj, query) {
	let q = query;
	let o = obj;

	if (!isDocument(obj)) {
		o = { prop: obj };
		q = { prop: query };
	}
	if (query['$near']) {
		o = { point: obj };
		q = { point: query };
	}

	//const stageExpression = getExpressionType({ $match: q });

	const context = {
		collection: Array.isArray(o) ? o : [o],
		now: new Date(),
		pipelineOptions: {
			maxInputDocs: MAX_INPUT_DOCS,
		},
	};

	let expr = getStageExpression({ $match: q });
	expr.options = { limit: 1 };

	let result = evaluateStage(context, expr);

	return result.length > 0;
}
