import React from 'react';
import PropTypes from 'prop-types';
import { ScreenDistributionWizardContextProvider } from './ScreenDistributionWizardContext';
import { withWizard, Wizard } from '../../../../components/wizard';
import { DistributionMethod } from './DistributionMethod';
import { ScreenGroups } from './ScreenGroups';
import DistributionTargets from './DistributionTargets';

const ScreenDistributionWizard = withWizard(
	({
		onClose,
		userAdminGetList,
		roleGetList,
		tagGroupGet,
		sending,
		handleAssign,
		handleAvailable,
		screenData,
	}) => {
		return (
			<ScreenDistributionWizardContextProvider
				onClose={onClose}
				seedTargets={{
					assignTo: screenData.assignTo,
					availableTo: screenData.availableTo,
				}}
			>
				<Wizard>
					<DistributionMethod />
					<ScreenGroups />
					<DistributionTargets
						userAdminGetList={userAdminGetList}
						roleGetList={roleGetList}
						tagGroupGet={tagGroupGet}
						sending={sending}
						handleAssign={handleAssign}
						handleAvailable={handleAvailable}
					/>
				</Wizard>
			</ScreenDistributionWizardContextProvider>
		);
	}
);

ScreenDistributionWizard.propTypes = {
	onClose: PropTypes.func.isRequired,
	userAdminGetList: PropTypes.func.isRequired,
	roleGetList: PropTypes.func.isRequired,
	tagGroupGet: PropTypes.func.isRequired,
	sending: PropTypes.bool,
	handleAssign: PropTypes.func.isRequired,
	handleAvailable: PropTypes.func.isRequired,
	screenData: PropTypes.object.isRequired,
};

ScreenDistributionWizard.displayName = 'ScreenDistributionWizard';

export default ScreenDistributionWizard;
