import { Box, Button, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/styles';
import React, { memo } from 'react';
import PropTypes from 'prop-types';

const TEXT = 'text';
const OUTLINED = 'outlined';
const CONTAINED = 'contained';

const ActionButtonContainer = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	gap: '10px',
	margin: '1px',
	alignItems: 'flex-end',
}));

const ActionButton = memo(function ActionButton({
	title,
	onClick,
	variant,
	startIcon,
	endIcon,
	type,
}) {
	const theme = useTheme();
	const screen_downSm = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<Button
			fullWidth={screen_downSm ? true : false}
			onClick={onClick}
			disableElevation
			variant={variant}
			startIcon={startIcon}
			endIcon={endIcon}
			type={type}
		>
			{title}
		</Button>
	);
});

/**
 * @param Array of button objects with shape - {onClick, disableElevation, variant, startIcon, endIcon, title}
 */
const BtFormContainerStackedActionButtons = memo(
	function BtFormContainerStackedActionButtons({ buttonAttr }) {
		if (!Array.isArray(buttonAttr)) return null;
		return (
			<ActionButtonContainer>
				{buttonAttr.map(attr => (
					<ActionButton key={attr.title} {...attr} />
				))}
			</ActionButtonContainer>
		);
	}
);

const buttonAttrItem = PropTypes.shape({
	onClick: PropTypes.func,
	type: PropTypes.oneOf(['submit']),
	variant: PropTypes.oneOf([TEXT, OUTLINED, CONTAINED]),
	title: PropTypes.string.isRequired,
	startIcon: PropTypes.element,
	endIcon: PropTypes.element,
});

ActionButton.propTypes = {
	onClick: PropTypes.func,
	type: PropTypes.oneOf(['submit']),
	variant: PropTypes.oneOf([TEXT, OUTLINED, CONTAINED]),
	title: PropTypes.string.isRequired,
	startIcon: PropTypes.element,
	endIcon: PropTypes.element,
};

BtFormContainerStackedActionButtons.propTypes = {
	buttonAttr: PropTypes.arrayOf(buttonAttrItem).isRequired,
};

BtFormContainerStackedActionButtons.displayName =
	'BtFormContainerStackedActionButtons';

export { BtFormContainerStackedActionButtons, TEXT, OUTLINED, CONTAINED };
