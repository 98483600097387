import React from 'react';
import { useAppContext } from '../context/ContextManager';
import Authentication from '../views/auth/Authentication';

// COMPONENTS
import Content from './Content';
import Layout from './Layout';

import BtLoading from '../components/generic/BtLoading';

export default function MainPage() {
	const { masterLoading, loadApp } = useAppContext();
	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<BtLoading loading={masterLoading} showLogo={true} fullScreen>
				{loadApp ? (
					<Layout>
						<Content />
					</Layout>
				) : (
					<Authentication />
				)}
			</BtLoading>
		</div>
	);
}
