import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import AddIcon from '@mui/icons-material/Add';
import Color from 'color';
import pluralize from 'pluralize';
import { styled } from '@mui/material';
import _ from 'lodash';

const AddIconContainer = styled('div')(({ theme }) => ({
	alignItems: 'center',
	backgroundColor: Color(theme.palette.text.solid)
		.alpha(0.55)
		.toString(),
	borderRadius: 4,
	color: theme.palette.background.default,
	display: 'flex',
	height: 16,
	justifyContent: 'center',
	marginRight: 5,
	width: 16,
}));

const Button = styled('button')(({ theme }) => ({
	backgroundColor: Color(theme.palette.text.solid)
		.alpha(0)
		.toString(),
	border: 'none',
	borderRadius: 13,
	height: 26,
	marginLeft: 15,
	userSelect: 'none',

	'& > span': {
		alignItems: 'center',
		color: Color(theme.palette.text.solid)
			.fade(0.2)
			.toString(),
		display: 'flex',
		padding: '0 4px',
	},

	'&:hover': {
		backgroundColor: Color(theme.palette.text.solid)
			.alpha(0.1)
			.toString(),
	},
}));

const Container = styled('div')(({ offset }) => ({
	display: 'flex',
	position: 'relative',
	height: 26,
	marginLeft: offset,
	paddingLeft: 15,
}));

export const AddFieldButton = ({
	arrayTitle,
	disabled,
	offset,
	onAdd,
	title,
}) => {
	const label = useMemo(
		() => {
			const text = title || arrayTitle;

			if (!text) return 'Add Field';

			const splitName = text.replace(/([A-Z])/g, ' $1');

			const finalFieldName = arrayTitle
				? pluralize.singular(splitName)
				: splitName;

			return `Add ${_.startCase(_.toLower(finalFieldName))} Field`;
		},
		[arrayTitle, title]
	);

	return (
		<Container offset={offset}>
			<Button disabled={disabled} onClick={onAdd} type="button">
				<span>
					<AddIconContainer>
						<AddIcon style={{ fontSize: 16 }} />
					</AddIconContainer>
					{label}
				</span>
			</Button>
		</Container>
	);
};

AddFieldButton.propTypes = {
	arrayTitle: PropTypes.string,
	disabled: PropTypes.bool,
	offset: PropTypes.number,
	onAdd: PropTypes.func.isRequired,
	title: PropTypes.string,
};

export default AddFieldButton;
