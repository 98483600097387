const yup = require('yup');

const workflowSubmissionGetListSchema = yup.array().of(
	yup.object({
		uuid: yup
			.string()
			.uuid()
			.required(),
		// user: yup
		// 	.string()
		// 	.uuid()
		// 	.required(),
		isEditable: yup.boolean().required(),
		isDeletable: yup.boolean().required(),
		create_by: yup
			.object()
			.shape({
				type: yup.string().required(),
				id: yup
					.string()
					.uuid()
					.required(),
			})
			.required(),
		create_user: yup.object().shape({
			uuid: yup.string().uuid(),
			user_name: yup.string(),
			initials: yup.string(),
			email: yup.string(),
		}),
		template: yup
			.object()
			.required()
			.shape({
				uuid: yup
					.string()
					.uuid()
					.required(),
				//version: yup.number().required(),
				name: yup.string().required(),
			}),
		create_timestamp: yup.number().required(),
		status: yup
			.object()
			.required()
			.shape({
				timestamp: yup.number().required(),
				status: yup.string().required(),
				issuer_id: yup
					.string()
					.uuid()
					.required(),
				issuer_type: yup.string().required(),
			}),
		primary_answer: yup.string().nullable(),
		comments: yup
			.array()
			.of(
				yup.object().shape({
					uuid: yup
						.string()
						.uuid()
						.required(),
					user: yup.object().shape({
						uuid: yup
							.string()
							.uuid()
							.required(),
						userName: yup.string().required(),
					}),
					timestamp: yup.number().required(),
					comment: yup.string().required(),
				})
			)
			.nullable(),
	})
);

const workflowSubmissionGetSchema = yup.object({
	uuid: yup
		.string()
		.uuid()
		.required(),
	isEditable: yup.boolean().required(),
	editors: yup
		.object()
		.required()
		.shape({
			users: yup
				.array()
				.nullable()
				.of(yup.string()),
			groups: yup
				.array()
				.nullable()
				.of(yup.string()),
		}),
	viewers: yup
		.object()
		.required()
		.shape({
			users: yup
				.array()
				.nullable()
				.of(yup.string()),
			groups: yup
				.array()
				.nullable()
				.of(yup.string()),
		}),
	template: yup
		.object()
		.required()
		.shape({
			uuid: yup
				.string()
				.uuid()
				.required(),
			//name: yup.string().required(),
			//version: yup.number().required(),
		}),
	create_timestamp: yup.number().required(),
	primary_answer: yup.string().nullable(),
	log: yup
		.array(
			yup.object().shape({
				timestamp: yup.number().required(),
				status: yup.string().required(),
				issuer_id: yup
					.string()
					.uuid()
					.required(),
				issuer_type: yup.string().required(),
			})
		)
		.required(),
	data: yup
		.object()
		.nullable()
		.shape
		// yup
		// 	.object()
		// 	.required()
		// 	.shape({
		// 		key: yup.string().required(),
		// 		value: yup.string().required(),
		// 		log: yup
		// 			.array()
		// 			.required()
		// 			.of(yup.object()),
		// 	})
		(),
	status: yup
		.object()
		.required()
		.shape({
			timestamp: yup.number().required(),
			status: yup.string().required(),
			issuer_id: yup
				.string()
				.uuid()
				.required(),
			issuer_type: yup.string().required(),
		}),
});

const workflowSubmissionAddSchema = yup.object({
	uuid: yup
		.string()
		.uuid()
		.required(),
	template: yup
		.object()
		.required()
		.shape({
			uuid: yup
				.string()
				.uuid()
				.required(),
			version: yup.number().required(),
		}),
	create_timestamp: yup.number().required(),
	// create_by: yup
	// 	.object()
	// 	.shape({
	// 		type: yup.string().required(),
	// 		id: yup
	// 			.string()
	// 			.uuid()
	// 			.required(),
	// 	})
	// 	.required(),
	// create_user: yup.object().shape({
	// 	uuid: yup.string().uuid(),
	// 	user_name: yup.string(),
	// 	initials: yup.string(),
	// 	email: yup.string(),
	// }),
	primary_answer: yup.string().nullable(),
	data: yup
		.object()
		.required()
		.shape({}),
	log: yup
		.array()
		.required()
		.of(
			yup
				.object()
				.required()
				.shape({
					uuid: yup
						.string()
						.uuid()
						.required(),
					timestamp: yup.number().required(),
					issuer_type: yup.string().required(),
					issuer_id: yup
						.string()
						.uuid()
						.required(),
					status: yup.string().required(),
				})
		),
	session_data: yup
		.object()
		.required()
		.shape({}),
});

const workflowSubmissionPutSchema = yup.object({
	uuid: yup
		.string()
		.uuid()
		.required(),
	primary_answer: yup.string().nullable(),
	data: yup
		.object()
		.required()
		.shape({}),
	log: yup
		.array()
		.required()
		.of(yup.object()),
	session_data: yup
		.object()
		.required()
		.shape({}),
});

export {
	workflowSubmissionGetListSchema,
	workflowSubmissionGetSchema,
	workflowSubmissionAddSchema,
	workflowSubmissionPutSchema,
};
