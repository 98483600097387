import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import DataConfig from '../../../components/generic/DataConfig/DataConfig';
import { historyPropType } from '../../../components/generic/DataConfig/utils/propTypes';
import { useFormContext } from 'react-hook-form';

const CONFIG = 'config',
	SCHEMA = 'schema';

export default function DataViewConfig({
	canEditDataView,
	history,
	historyPos,
	initialSchema,
	setHistory,
	setHistoryPos,
}) {
	const { sending, setValue, watch } = useFormContext();
	const config = watch(CONFIG);

	const handleConfigChange = useCallback(
		({ config, schema, historyPos: newHistoryPos }) => {
			if (config) {
				setValue(CONFIG, config, {
					shouldDirty: true,
					shouldTouch: true,
					shouldValidate: true,
				});
			}

			const isAtStart = newHistoryPos === 0;

			if (schema || isAtStart) {
				setValue(SCHEMA, isAtStart ? initialSchema : schema, {
					shouldDirty: true,
					shouldTouch: true,
					shouldValidate: true,
				});
			}
		},
		[initialSchema, setValue]
	);

	return (
		<DataConfig
			config={config}
			disabled={sending}
			history={history}
			historyPos={historyPos}
			onChange={handleConfigChange}
			onChangeHistory={setHistory}
			onChangeHistoryPos={setHistoryPos}
			readOnly={!canEditDataView}
		/>
	);
}

DataViewConfig.propTypes = {
	canEditDataView: PropTypes.bool,
	history: historyPropType,
	historyPos: PropTypes.number,
	initialSchema: PropTypes.array,
	setHistory: PropTypes.func,
	setHistoryPos: PropTypes.func,
};
