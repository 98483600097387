import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { useNavContext } from '../../context/ContextManager';
import { useSnackbar } from 'notistack';

// Data for landing page selection table and contextual navigation
import beacon_admin_options from './BeaconAdminOptions';
import beacon_admin_nav_item from './BeaconAdminNavItem';
import beacon_admin_breadcrumbs from './BeaconAdminBreadcrumbs';

import useFetch from '../../hooks/useFetch';
import useNavRedactor from '../../hooks/useNavRedactor';

import AddIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { BtFormContainer } from '../../components/generic/forms';
import BtLoading from '../../components/generic/BtLoading';
import BtSelectionTable from '../../components/generic/BtSelectionTable';
import BtTableSkeleton from '../../components/generic/BtTableSkeleton';
import NewOrganisationDialog from './NewOrganisationDialog';
import { IconButton } from '@mui/material';
import { Delete } from 'mdi-material-ui';

import {
	platformAdminOrganisationGetList,
	platformAdminOrganisationDelete,
} from '../../API';
import BtConfirmDialog from '../../components/generic/BtConfirmDialog';

const tableColumns = [
	{ field: 'name', text: 'Organisation' },
	{ field: 'createTimestamp', text: 'Created', minBreakpoint: 'sm' },
	{ field: 'updateTimestamp', text: 'Modified', minBreakpoint: 'sm' },
	{ field: 'delete', text: 'Delete' },
];

export default function BeaconAdminOrganisations() {
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	// navContext contextual navigation items
	const { setContextualNav } = useNavContext();
	// navContext breadcrumbs
	const { setBreadcrumbs } = useNavContext();
	const redactNav = useNavRedactor();

	const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
	const [orgToRemove, setOrgToRemove] = useState(null);

	const [newOrganisationDialog, setNewOrganisationDialog] = useState(false);

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				...beacon_admin_breadcrumbs,
				{ text: 'Organisations', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	const redactedInsightsOptions = useMemo(
		() => redactNav(beacon_admin_options),
		[redactNav]
	);

	//Boilerplate for future implementation set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([
				...beacon_admin_nav_item,
				...redactedInsightsOptions,
			]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	const {
		loading,
		data: organisationList,
		error,
		request: getOrgList,
	} = useFetch(platformAdminOrganisationGetList);

	useEffect(
		() => {
			getOrgList();
		},
		[getOrgList]
	);

	const handleDeleteOrg = async () => {
		console.log('Delete org', orgToRemove.uuid);
		try {
			const deleteRes = await platformAdminOrganisationDelete({
				orgUuid: orgToRemove.uuid,
			});
			console.log(deleteRes);
			enqueueSnackbar(`Deleted organisation '${orgToRemove.name}'`, {
				variant: 'success',
			});
			getOrgList();
		} catch (error) {
			enqueueSnackbar(`Error deleteing '${orgToRemove.uuid}'`, {
				variant: 'error',
			});
		}
	};

	return (
		<>
			<BtFormContainer title="Platform Organisations" maxWidth="lg">
				<BtLoading
					loading={loading}
					LoadingComponent={<BtTableSkeleton hasActionButton />}
				>
					<BtSelectionTable
						title={
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									flexWrap: 'wrap',
									gap: 10,
								}}
							>
								<Typography
									sx={{
										fontWeight: 'bold',
										padding: '4px 0',
									}}
									variant="h5"
								>
									Platform Organisations
								</Typography>
								<Button
									variant="contained"
									onClick={() =>
										setNewOrganisationDialog(true)
									}
									startIcon={<AddIcon />}
									disableElevation
								>
									Create Organisation
								</Button>
							</div>
						}
						subject="collections"
						columns={tableColumns}
						data={(organisationList || []).map(
							({
								uuid,
								name,
								create_timestamp,
								update_timestamp,
							}) => ({
								uuid: uuid,
								name: name,
								createTimestamp: format(
									new Date(create_timestamp || 0),
									'dd MMM yyyy, hh:mm a'
								),
								updateTimestamp: format(
									new Date(update_timestamp || 0),
									'dd MMM yyyy, hh:mm a'
								),
								delete: (
									<IconButton
										size="small"
										// onClick={handleSubscriptionRemove(uuid)}
										onClick={event => {
											event.stopPropagation();
											setConfirmDeleteOpen(true);
											setOrgToRemove({
												uuid: uuid,
												name: name,
											});
										}}
										color="error"
									>
										<Delete />
									</IconButton>
								),
								route: `/BeaconAdmin/Organisation/${uuid}`,
							})
						)}
					/>
				</BtLoading>
			</BtFormContainer>

			<NewOrganisationDialog
				existingNames={organisationList?.map(({ name }) => name)}
				open={newOrganisationDialog}
				onClose={() => setNewOrganisationDialog(false)}
			/>

			<BtConfirmDialog
				title="Delete Organisation"
				action={handleDeleteOrg}
				ActionIcon={<Delete />}
				onClose={() => {
					setConfirmDeleteOpen(false);
				}}
				isDestructive={true}
				open={confirmDeleteOpen}
				prompt={`Are you sure you wish to delete the '${
					orgToRemove?.name
				}' organisation?`}
				verb="Delete"
				textInputConfirm={orgToRemove?.name}
			/>
		</>
	);
}
