const {
	ERROR,
	setOperator,
	evaluateExpression,
	OPERATION_MODE,
} = require('../expression.js');

const operatorKey = '$or';

setOperator(
	operatorKey,
	/**
	 * @type {ExpressionFunction<unknown, boolean>}
	 */
	(context, args, options) => {
		const arr = !Array.isArray(args) ? [args] : args;

		const match = false;

		for (let pos = 0; pos < arr.length; pos++) {
			const arg = arr[pos];

			/**
			 * @type {any}
			 */
			let value = evaluateExpression(context, arg);

			if (value !== undefined && value !== null && value) {
				return true;
			}
		}

		return match;
	},

	[OPERATION_MODE.AGGREGATE, OPERATION_MODE.QUERY]
);
