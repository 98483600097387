import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
	Box,
	Button,
	CircularProgress,
	Container,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
	Typography,
} from '@mui/material';
import { CardMultipleOutline, CardPlusOutline } from 'mdi-material-ui';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import * as yup from 'yup';

import BtDialog from '../../components/generic/BtDialog';
import {
	BtForm,
	BtFormActionsContainer,
	BtFormContent,
	BtFormDateTimePicker,
	BtFormFilePicker,
	BtFormSelect,
	BtFormTextField,
	withFormContextMethods,
} from '../../components/generic/forms';
import BtFormIconRadio from '../../components/generic/forms/BtFormIconRadio';
import BtMessage from '../../components/generic/BtMessage';
import {
	platformAdminOrganisationAddSubscription,
	platformAdminOrganisationUpdateSubscription,
} from '../../API';

const blankDefaults = {
	subscription: null,
};

const schema = yup.object({
	uuid: yup
		.string()
		.uuid()
		.required(),
	renewalDate: yup.string().required(),
	maxUsers: yup.string().nullable(),
});

const subscriptionList = [
	{
		uuid: '0ea9a31e-c6ef-11ec-9d64-0242ac120002',
		subscription: 'Insights Viewer',
		description: 'View Insights dashboards',
		type: 'user',
	},
	{
		uuid: 'd886cc00-a899-4348-bcd2-e0cc703d14f1',
		subscription: 'Insights Editor',
		description: 'Create new and edit Insights dashboards',
		type: 'user',
	},
	{
		uuid: '0ea9a31e-c6ef-11ec-9d64-0242ac120003',
		subscription: 'IoT',
		description: 'Device intergration and managment',
		type: 'organisation',
	},
	{
		uuid: 'c294f5e4-6b89-441b-8bba-8d0be752df17',
		subscription: 'Workflow User',
		description: 'Workflow submission creation and editing',
		type: 'user',
	},
	{
		uuid: '7de60d6b-a237-4ce2-be20-2538fb17046e',
		subscription: 'Workflow Editor',
		description: 'Workflow template creation and editing',
		type: 'user',
	},
	{
		uuid: '254304c3-e9f5-4ff9-98b9-c1c82c0f381d',
		subscription: 'Data Manager',
		description: 'Data Manager Functionality',
		type: 'organisation',
	},
	// {
	// 	uuid: 'e9428473-44ac-4dc3-8e00-bc7aceae015e',
	// 	subscription: 'Platform Admin',
	// 	description: 'Beacon Tech Platform Admin',
	// 	type: 'user',
	// },
];

const ActionButtons = withFormContextMethods(
	({ formState: { isDirty }, onClose, sending, isNew }) => {
		return (
			<>
				<Button
					disabled={sending}
					disableElevation
					onClick={onClose}
					variant="outlined"
				>
					Cancel
				</Button>
				<Button
					disabled={!isDirty || sending}
					disableElevation
					startIcon={
						sending ? (
							<CircularProgress size={20} />
						) : (
							<UploadFileIcon />
						)
					}
					type="submit"
					variant="contained"
				>
					{!isNew && <>Update Subscription</>}
					{isNew && <>Add Subscription</>}
				</Button>
			</>
		);
	}
);

export default function SubscriptionAddEditDialog({
	orgUuid,
	subscription,
	onClose,
	open,
	refreshSubscriptions,
	currentSubscriptions,
}) {
	const [error, setError] = useState(null);
	const [sending, setSending] = useState(false);
	const [availableSubscriptions, setAvailableSubscriptions] = useState([]);

	const SubScopeController = withFormContextMethods(({ watch }) => {
		const subUuid = watch('uuid');

		const subInfo = _.find(subscriptionList, { uuid: subUuid });

		if (subInfo) {
			if (subInfo.type === 'user') {
				return (
					<BtFormTextField
						label="maxUsers"
						name="maxUsers"
						type="number"
					/>
				);
			} else {
				return (
					<>
						<BtFormTextField
							label="maxUsers"
							name="maxUsers"
							type="number"
							disabled={true}
						/>
						<BtMessage message="Available to all users in the organisation." />
					</>
				);
			}
		} else {
			return (
				<BtFormTextField
					label="maxUsers"
					name="maxUsers"
					type="number"
					disabled={true}
				/>
			);
		}
	});

	useEffect(
		() => {
			if (currentSubscriptions) {
				const subs = _.filter(subscriptionList, sub => {
					return !currentSubscriptions.includes(sub.uuid);
				});
				setAvailableSubscriptions(subs);
			}
		},
		[currentSubscriptions]
	);

	const onSubmit = async (values, reset) => {
		setSending(true);
		setError(null);

		try {
			// The backend requires a maxUsers value
			if (!values.maxUsers) {
				values.maxUsers = 0;
			}

			if (subscription) {
				const subUpdate = {
					renewalDate: parseInt(values.renewalDate),
					renewalCost: values.renewalCost,
					maxUsers: parseInt(values.maxUsers),
				};
				await platformAdminOrganisationUpdateSubscription({
					orgUuid: orgUuid,
					subUuid: subscription.uuid,
					subscriptionUpdate: subUpdate,
				});
			} else {
				const newSub = {
					uuid: values.uuid,
					renewalDate: parseInt(values.renewalDate),
					renewalCost: values.renewalCost,
					maxUsers: parseInt(values.maxUsers),
				};
				await platformAdminOrganisationAddSubscription({
					orgUuid: orgUuid,
					newSubscription: newSub,
				});
			}

			reset();
			refreshSubscriptions();
		} catch (error) {
			setError('There was an error adding/editing the subscription');
		} finally {
			setSending(false);
		}
	};

	return (
		<BtDialog open={open} onClose={onClose} minwidth="sm" fullWidth>
			{sending && (
				<LinearProgress
					style={{
						position: 'absolute',
						top: 0,
						width: '100%',
					}}
				/>
			)}

			{subscription && (
				<DialogTitle>Edit {subscription.subscription}</DialogTitle>
			)}

			{!subscription && <DialogTitle>Add Subscription</DialogTitle>}

			<BtForm
				onSubmit={onSubmit}
				sending={sending}
				validationSchema={schema}
				defaultValues={subscription || blankDefaults}
			>
				<DialogContent>
					<Container maxWidth="xs">
						{error && (
							<Box sx={{ paddingBottom: '36px' }}>
								<BtMessage variant="error" message={error} />
							</Box>
						)}
						<BtFormContent>
							{subscription && (
								<Typography>
									{subscription.subscription} Subscription
								</Typography>
							)}
							{!subscription && (
								<BtFormSelect
									label="Subscription"
									name="uuid"
									// items={[{ value: 'this', label: 'that' }]}
									items={availableSubscriptions.map(sub => {
										return {
											label: sub.subscription,
											value: sub.uuid,
										};
									})}
								/>
							)}

							<BtFormDateTimePicker
								label="Renewal Date"
								name="renewalDate"
								variant="date"
							/>

							<SubScopeController />
						</BtFormContent>
					</Container>
				</DialogContent>
				<DialogActions>
					<BtFormActionsContainer>
						<ActionButtons
							onClose={onClose}
							sending={sending}
							isNew={!subscription}
						/>
					</BtFormActionsContainer>
				</DialogActions>
			</BtForm>
		</BtDialog>
	);
}
