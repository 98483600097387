import React from 'react';
import Box from '@mui/material/Box';

import {
	Container,
	IconButton,
	MenuItem,
	Select,
	Typography,
} from '@mui/material';
import {
	ChevronDoubleLeft,
	ChevronDoubleRight,
	ChevronLeft,
	ChevronRight,
} from 'mdi-material-ui';

export default function BtPagination({
	dataOffset,
	pageSize,
	pageSizeOptions,
	onOffsetUpdate,
	onPageSizeUpdate,
	recordCount,
}) {
	const atStart = dataOffset === 0;
	const atEnd = dataOffset + pageSize >= recordCount;
	const getRangeText = atEnd
		? `${
				recordCount > 0 ? +dataOffset + 1 : 0
		  } to ${recordCount} of ${recordCount}`
		: `${+dataOffset + 1} to ${+dataOffset + +pageSize} of ${recordCount}`;

	return (
		<Container maxWidth="xs">
			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					justifyContent: 'space-between',
					width: '100%',
				}}
			>
				<IconButton
					color="primary"
					disabled={atStart}
					onClick={() => {
						onOffsetUpdate(0);
					}}
					sx={{
						flexGrow: 0,
						float: 'left',
						paddingLeft: '8px',
					}}
					variant="outlined"
				>
					<ChevronDoubleLeft />
				</IconButton>
				<IconButton
					color="primary"
					disabled={atStart}
					onClick={() => {
						onOffsetUpdate(
							dataOffset - pageSize < 0
								? 0
								: dataOffset - pageSize
						);
					}}
					sx={{
						flexGrow: 0,
						float: 'left',
						paddingLeft: '8px',
					}}
					variant="outlined"
				>
					<ChevronLeft />
				</IconButton>
				<Typography
					variant="body"
					align="center"
					sx={{
						flexGrow: 1,
					}}
				>
					{getRangeText}
				</Typography>
				{pageSizeOptions && (
					<Select
						onChange={event => {
							onPageSizeUpdate(event.target.value);
						}}
						value={pageSize}
						variant="standard"
						sx={{
							flexGrow: 0,
							textAlign: 'center',
						}}
					>
						{pageSizeOptions.map((pageSize, index) => (
							<MenuItem value={pageSize} key={index}>
								{pageSize}
							</MenuItem>
						))}
					</Select>
				)}
				<IconButton
					color="primary"
					disabled={atEnd}
					onClick={() => {
						onOffsetUpdate(dataOffset + pageSize);
					}}
					sx={{
						flexGrow: 0,
						float: 'left',
						paddingLeft: '8px',
					}}
					variant="outlined"
				>
					<ChevronRight />
				</IconButton>
				<IconButton
					color="primary"
					disabled={atEnd}
					onClick={() => {
						onOffsetUpdate(recordCount - pageSize);
					}}
					sx={{
						flexGrow: 0,
						float: 'left',
						paddingLeft: '8px',
					}}
					variant="outlined"
				>
					<ChevronDoubleRight />
				</IconButton>
			</Box>
		</Container>
	);
}
