import React from 'react';

import BusinessIcon from '@mui/icons-material/Business';
import UsersIcon from '@mui/icons-material/People';
import ConstructionIcon from '@mui/icons-material/Construction';

const beacon_admin_options = [
	{
		id: 'Organisations',
		description: 'Create, View, Edit and Delete Organosations',
		text: 'Organisations',
		route: '/BeaconAdmin/Organisations',
		icon: <BusinessIcon />,
		requiredPermissionsAll: {
			platform: ['OrgView'],
		},
	},
	{
		id: 'Users',
		description: 'View platform users',
		text: 'Users',
		route: '/BeaconAdmin/Users',
		icon: <UsersIcon />,
		requiredPermissionsAll: {
			platform: ['UserView'],
		},
	},
];

export default beacon_admin_options;
