import _, { findIndex } from 'lodash';
import { screenUpdate } from '../../../../API';
import { screenAdminUpdate } from '../../../../API/screen_admin.api';
import { tileVariants } from '../tileConstants';
import { v4 as uuidV4 } from 'uuid';

export const updateScreenTile = async ({
	screenUuid,
	tileUpdate,
	screens,
	setScreens,
	currentScreen,
	setScreensRefreshPending,
	adminMode,
}) => {
	const newScreen = structuredClone(screens[currentScreen]);
	newScreen.tiles[tileUpdate.uuid] = tileUpdate;
	console.log(adminMode);

	if (adminMode) {
		await screenAdminUpdate({
			screenUuid,
			screenUpdate: newScreen,
		});
	} else {
		await screenUpdate({
			screenUuid,
			screenUpdate: newScreen,
		});
	}

	const newScreens = [...screens];
	newScreens.splice(currentScreen, 1, newScreen);

	setScreens(newScreens);

	setScreensRefreshPending(false);
};

export const updateScreen = async ({
	screens,
	setScreens,
	newScreen,
	currentScreen,
	setScreensRefreshPending,
	adminMode,
}) => {
	if (adminMode) {
		await screenAdminUpdate({
			screenUuid: newScreen.uuid,
			screenUpdate: newScreen,
		});
	} else {
		await screenUpdate({
			screenUuid: newScreen.uuid,
			screenUpdate: newScreen,
		});
	}

	const newScreens = [...screens];
	newScreens.splice(currentScreen, 1, newScreen);

	setScreens(newScreens);
	console.log({ newScreens });

	setScreensRefreshPending(false);
};

export const nativeLayoutUpdate = ({
	adminMode,
	newScreen,
	setScreensRefreshPending,
	setScreens,
	screens,
}) => {
	nativeRequests({
		adminMode,
		newScreen,
		setScreensRefreshPending,
		setScreens,
		screens,
	});
};

export const nativeRemoveTile = ({
	currentScreenData,
	tileToRemove,
	adminMode,
	setScreensRefreshPending,
	setScreens,
	screens,
}) => {
	const newScreen = structuredClone(currentScreenData);

	// remove the tile for the layout definitions
	Object.keys(newScreen.layouts).forEach(layout => {
		if (layout === 'native') {
			const newLayout = currentScreenData.layouts[layout].filter(
				layoutItem => layoutItem !== tileToRemove
			);
			newScreen.layouts[layout] = newLayout;
		} else {
			const newLayout = currentScreenData.layouts[layout].filter(
				layoutItem => layoutItem.tile_uuid !== tileToRemove
			);
			newScreen.layouts[layout] = newLayout;
		}
	});

	// delete the tile
	delete newScreen.tiles[tileToRemove];

	// check for and remove any container child tile definitions
	if (currentScreenData.tiles[tileToRemove].variant === 'container') {
		currentScreenData.tiles[tileToRemove].data.children.forEach(
			childTile => {
				delete newScreen.tiles[childTile];
				console.log(
					'deleted child: ',
					currentScreenData.tiles[childTile].title
				);
			}
		);
	}

	// execute backend requests and component update
	nativeRequests({
		adminMode,
		newScreen,
		setScreensRefreshPending,
		setScreens,
		screens,
	});
};

export const nativeAddTile = ({
	currentScreenData,
	variantToAdd,
	adminMode,
	setScreensRefreshPending,
	setScreens,
	screens,
}) => {
	// clone new tile data structure
	const newTile = structuredClone(tileVariants[variantToAdd]);

	// clone existing screen data
	const newScreen = structuredClone(currentScreenData);

	// get fresh uuid
	newTile.uuid = uuidV4();

	// add new tile to screen
	newScreen.tiles[newTile.uuid] = newTile;

	// add layout definitions for the new tile
	Object.keys(currentScreenData.layouts).forEach(layout => {
		if (layout === 'native') {
			newScreen.layouts.native.push(newTile.uuid);
		} else {
			const newLayoutItem = {
				tile_uuid: newTile.uuid,
				uuid: uuidV4(),
				x: 0,
				y: 0,
				w: 1,
				h: 1,
			};
			newScreen.layouts[layout].push(newLayoutItem);
		}
	});

	// execute backend requests and component update
	nativeRequests({
		adminMode,
		newScreen,
		setScreensRefreshPending,
		setScreens,
		screens,
	});
};

const nativeRequests = async ({
	adminMode,
	newScreen,
	setScreensRefreshPending,
	setScreens,
	screens,
}) => {
	const updateComponent = () => {
		const screenIndex = _.findIndex(screens, { uuid: newScreen.uuid });

		const newScreens = [...screens];
		newScreens.splice(screenIndex, 1, newScreen);

		console.log({ newScreens, screenIndex });
		setScreens(newScreens);
		setScreensRefreshPending(false);
	};

	if (adminMode) {
		await screenAdminUpdate({
			screenUuid: newScreen.uuid,
			screenUpdate: newScreen,
		});
		updateComponent();
	} else {
		await screenUpdate({
			screenUuid: newScreen.uuid,
			screenUpdate: newScreen,
		});
		updateComponent();
	}
};
