import React, { useCallback, useMemo, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
	BtForm,
	BtFormContent,
	BtFormLateralContainer,
	BtFormTextField,
} from '../../../components/generic/forms';
import BtQueryFormField from '../../../components/generic/forms/BtFormQueryField';
import useQueryBuilderDemoData from './useQueryBuilderDemoData';

import * as yup from 'yup';

const formSchema = yup.object().shape({
	query: yup.string().required(),
});

export default function FromField(props) {
	const { options } = props;

	const demoData = useQueryBuilderDemoData();
	const { collection, configSchema, query } = demoData;

	const defaultInputs = useMemo(
		() => ({
			collection: collection,
			configSchema: configSchema,
		}),
		[collection, configSchema]
	);

	const defaultFormValues = useMemo(
		() => ({
			query: query,
			queryName: '',
			queryVersion: 1,
		}),
		[query]
	);

	const [formOutput, setFormOutput] = useState(
		JSON.stringify(defaultFormValues)
	);

	const onFormStateChange = useCallback((formState, values) => {
		const vStr = JSON.stringify({
			queryName: values.queryName,
			query: values.query,
			queryVersion: values.queryVersion,
		});
		setFormOutput(vStr);
	}, []);

	return (
		<Box>
			<BtForm
				validationSchema={formSchema}
				defaultValues={defaultFormValues}
				onFormStateChange={onFormStateChange}
				key="hooked-form"
			>
				<BtFormContent>
					<BtFormTextField
						name="queryName"
						label="Query Name"
						isRequired
					/>

					<BtQueryFormField
						key="queryField"
						name="query"
						label="A query field"
						initialInputs={defaultInputs}
						// isRequired={options.isRequired}
						// config
						{...options}
					/>

					<BtFormTextField
						name="queryVersion"
						label="Query Version Number"
						type="number"
						isRequired
					/>
				</BtFormContent>
			</BtForm>

			<Box>
				<Typography>FormOutput</Typography>
				<pre
					style={{
						overflowX: 'hidden',
						whiteSpace: 'pre-wrap',
						wordBreak: 'break-word',
					}}
				>
					{formOutput}
				</pre>
			</Box>
		</Box>
	);
}
