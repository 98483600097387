import React from 'react';
import PropTypes from 'prop-types';

import {
	Drawer as MuiDrawer,
	SwipeableDrawer as MuiSwipeableDrawer,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { useAppContext } from '../context/ContextManager';

export const drawerWidth = 200;
export const swipeDrawerWidth = 250;

const openedMixin = theme => ({
	overflowX: 'hidden',
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	width: drawerWidth,
});

const closedMixin = theme => ({
	overflowX: 'hidden',
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(7)} + 1px)`,
	},
});

const commonMixin = () => ({
	boxSizing: 'border-box',
	flexShrink: 0,
	marginLeft: '0px',
	pl: '0px',
	whiteSpace: 'nowrap',
});

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
	...commonMixin(theme),
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}));

const SwipeableDrawer = styled(MuiSwipeableDrawer)(({ theme }) => ({
	...commonMixin(theme),
	...openedMixin(theme),
	width: swipeDrawerWidth,

	'& .MuiDrawer-paper': {
		...openedMixin(theme),
		width: swipeDrawerWidth,
	},
}));

export default function BtResponsiveDrawer({
	children,
	drawerOpen,
	setSwipeDrawerOpen,
	swipeDrawerOpen,
}) {
	const { showMobile } = useAppContext();

	if (showMobile) {
		return (
			<SwipeableDrawer
				onClose={() => setSwipeDrawerOpen(false)}
				onOpen={() => setSwipeDrawerOpen(true)}
				open={swipeDrawerOpen}
			>
				{children}
			</SwipeableDrawer>
		);
	}

	return (
		<Drawer
			open={drawerOpen}
			sx={{ flexShrink: 0, zIndex: 800 }}
			variant="permanent"
		>
			{children}
		</Drawer>
	);
}

BtResponsiveDrawer.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	drawerOpen: PropTypes.bool.isRequired,
	setSwipeDrawerOpen: PropTypes.func.isRequired,
	swipeDrawerOpen: PropTypes.bool.isRequired,
};
