import { apiClient } from './api_client';
import { uuidRegex } from './utils';

import {
	screenGetListSchema,
	screenGetSchema,
	screenGroupGetSchema,
	screenPostSchema,
	screenPutSchema,
	screenGroupPutSchema,
	screenAdminGetSchema,
	assigneeSchema,
	screenGroupSchema,
	assignToSchema,
} from './validations/screenValidation';

//========================================================================================================

async function screenAdminListGet() {
	var req_url = `screen_admin`;
	try {
		// if (props?.ownerFilter) {
		// 	if (props.ownerFilter === 'Organisation') {
		// 		req_url = `screen?owner=Organisation`;
		// 	} else if (props.ownerFilter === 'User') {
		// 		req_url = `screen?owner=User`;
		// 	}
		// }

		const response = await apiClient({ method: 'get', url: req_url });
		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				return await screenGetListSchema.validate(response.screens);
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function screenAdminGet({ screenUuid }) {
	if (!uuidRegex.test(screenUuid)) {
		throw new Error('Param Error');
	}
	// console.log({ screenUuid });
	var req_url = `screen_admin/${screenUuid}`;
	try {
		const response = await apiClient({ method: 'get', url: req_url });
		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				return await screenAdminGetSchema.validate(response.screen);
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
// post new screen
async function screenAdminCreate({ newScreen }) {
	// Validate the new submission data
	try {
		await screenPostSchema.validate(newScreen);
	} catch (error) {
		throw error;
	}
	console.log({ newScreen });
	var req_url = `screen_admin`;
	try {
		const response = await apiClient({
			method: 'post',
			url: req_url,
			body: newScreen,
		});
		if (response) {
			if (response.result !== 'OK') {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
// put screen update
async function screenAdminUpdate({ screenUuid, screenUpdate }) {
	if (!uuidRegex.test(screenUuid)) {
		throw new Error('Param Error');
	}
	try {
		await screenPutSchema.validate(screenUpdate);
	} catch (error) {
		throw error;
	}
	console.log({ screenUuid, screenUpdate });
	var req_url = `screen_admin/${screenUuid}`;
	try {
		const response = await apiClient({
			method: 'put',
			url: req_url,
			body: screenUpdate,
		});
		if (response) {
			if (response.result !== 'OK') {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
// put assign
async function screenAdminAssign({ screenUuid, group, assignTo }) {
	if (!uuidRegex.test(screenUuid)) {
		throw new Error('Param Error');
	}
	// validate the assignee object
	try {
		await assignToSchema.validate(assignTo);
	} catch (error) {
		throw error;
	}
	// validate the screen group
	try {
		await screenGroupSchema.validate(group);
	} catch (error) {
		throw error;
	}

	console.log({ screenUuid, assignTo, group });
	var req_url = `screen_admin/${screenUuid}/assign`;
	try {
		const response = await apiClient({
			method: 'put',
			url: req_url,
			// body: screenUpdate,
			body: {
				screenGroup: group,
				assignTo: assignTo,
			},
		});
		if (response) {
			if (response.result !== 'OK') {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
// put available
async function screenAdminAvailable({ screenUuid, availableTo }) {
	if (!uuidRegex.test(screenUuid)) {
		throw new Error('Param Error');
	}
	// validate the assignee object
	try {
		await assignToSchema.validate(availableTo);
	} catch (error) {
		throw error;
	}

	console.log({ screenUuid, availableTo });
	var req_url = `screen_admin/${screenUuid}/available`;
	try {
		const response = await apiClient({
			method: 'put',
			url: req_url,
			// body: screenUpdate,
			body: {
				availableTo: availableTo,
			},
		});
		if (response) {
			if (response.result !== 'OK') {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

export {
	screenAdminListGet,
	screenAdminGet,
	screenAdminCreate,
	screenAdminUpdate,
	screenAdminAssign,
	screenAdminAvailable,
};
