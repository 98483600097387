import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import { useContext } from 'react';
import { useAppContext } from '../../../../../context/ContextManager';
import PermsSubsErrorDetailDialog from '../../../../dialogs/PermsSubsErrorDetailDialog';
import ActionButtonContainer from '../../../ActionButtonContainer';
import BtError from '../../../BtError';
import BtMessage from '../../../BtMessage';
import { ScreensContext } from '../../ScreensContext';
import { tileLabels } from '../../tileConstants';
import { TileContext } from '../TileContext';
import ButtonConfig from './components/ButtonConfig';
import ContainerConfig from './components/ContainerConfig';
import InsightCollectionConfig from './components/InsightCollectionConfig';
import InsightConfig from './components/InsightConfig';
import { useTileConfigContext } from './TileConfigDialogContext';

const CancelButton = ({ onClose }) => {
	return (
		<Button
			color="primary"
			onClick={onClose}
			variant="outlined"
			disableElevation
			// style={{ color: 'white' }}
		>
			Cancel
		</Button>
	);
};

const ActionButton = ({ onClick, label, disabled }) => {
	return (
		<Button // maybe this should be a text button on non mobile??
			disableElevation
			style={{ color: 'white' }}
			variant="contained"
			onClick={onClick}
			disabled={disabled}
		>
			{label}
		</Button>
	);
};

const ActionButtons = () => {
	const {
		tileUpdateParams,
		setIsFormValid,
		isFormValid,
		screenUuid,
	} = useTileConfigContext();
	const { onTileChange } = useContext(TileContext);
	return (
		<>
			{/* <ActionButton
				onClick={() => console.log('next')}
				label="back"
				disabled={false}
			/> */}

			<ActionButton
				onClick={() =>
					onTileChange({ tileUpdate: tileUpdateParams, screenUuid })
				}
				label="done"
				disabled={!isFormValid}
			/>
		</>
	);
};

const ContextualDialogActions = ({ onClose }) => {
	const { deviceType } = useAppContext();
	return (
		<DialogActions>
			{deviceType === 'mobile' ? (
				<ActionButtonContainer>
					<CancelButton onClose={onClose} />
					<ActionButtons onClose={onClose} />
				</ActionButtonContainer>
			) : (
				<>
					<ActionButtonContainer>
						<CancelButton onClose={onClose} />
					</ActionButtonContainer>
					<ActionButtonContainer>
						<ActionButtons onClose={onClose} />
					</ActionButtonContainer>
				</>
			)}
		</DialogActions>
	);
};

const content = {
	button: <ButtonConfig />,
	insight_collection: <InsightCollectionConfig />,
	insight_visualisation: <InsightConfig />,
	// container: <ContainerConfig />, not used here, has it's own dialog
};

const Content = ({ existingTileData }) => {
	const { userButtonPerms } = useContext(ScreensContext);

	switch (existingTileData.variant) {
		case 'insight_collection':
			return <InsightCollectionConfig />;
		case 'insight_visualisation':
			return <InsightConfig />;
		case 'button':
			if (existingTileData.data.button_type === '') {
				return <ButtonConfig />;
			} else if (
				userButtonPerms[existingTileData.data.button_type].available
			) {
				return <ButtonConfig />;
			} else {
				return (
					<BtMessage
						style={{ margin: '1em' }}
						message={
							<PermsSubsErrorDetailDialog
								descriptionOnly
								error={
									userButtonPerms[
										existingTileData.data.button_type
									]
								}
							/>
						}
					/>
				);
			}
		default:
			break;
	}
};

export default function TileConfigDialogContent({ onClose }) {
	const { existingTileData, error } = useTileConfigContext();
	const { deviceType } = useAppContext();
	return error ? (
		<BtError fullScreen={false} onClose={onClose} />
	) : (
		<>
			<DialogTitle>
				{tileLabels[existingTileData.variant] + ' configuration'}
			</DialogTitle>
			<DialogContent
				sx={{
					width: deviceType === 'desktop' ? 600 : '100%',
					paddingBottom: 0,
				}}
			>
				<Content existingTileData={existingTileData} />
			</DialogContent>
			<ContextualDialogActions onClose={onClose} />
		</>
	);
}
