import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import BtFormContent from '../../../../../components/generic/forms/BtFormContent';
import {
	COMPOUND_COMPONENTS,
	GROUP,
	NON_WRAPPABLE,
	SECTION,
} from '../../processing/utils/constants';
import useWorkflowComponents from '../../processing/hooks/useWorkflowComponents';
import { useWorkflowPageContext } from '../../contexts/WorkflowPageContext';
import WorkflowComponentGroup from './WorkflowComponentGroup';
import WorkflowComponentWrapper from './WorkflowComponentWrapper';
import WorkflowReadOnly from './WorkflowReadOnly';
import WorkflowSection from './WorkflowSection';

const ConditionalComponentWrapper = ({ children, component, index }) => {
	const { hidden, type } = component;

	if (hidden) return null;

	if (NON_WRAPPABLE.includes(type)) {
		return children;
	}

	return (
		<WorkflowComponentWrapper component={component} index={index}>
			{children}
		</WorkflowComponentWrapper>
	);
};

export default function WorkflowComponentsParser({ components, Parser }) {
	const elementToScrollTo = useRef();

	const { scrollElementId } = useWorkflowPageContext();

	const getComponentOfType = useWorkflowComponents();

	const nests = useMemo(
		() => ({
			[GROUP]: WorkflowComponentGroup,
			[SECTION]: WorkflowSection,
		}),
		[]
	);

	const getNestComponent = useCallback(
		type => {
			const component = nests[type];

			return { Component: component, props: { Parser } };
		},
		[nests, Parser]
	);

	useEffect(
		() => {
			if (scrollElementId.current && elementToScrollTo.current) {
				elementToScrollTo.current.scrollIntoView({
					block: 'center',
					inline: 'center',
				});

				scrollElementId.current = null;
			}
		},
		[scrollElementId]
	);

	if (!components?.length > 0) {
		return null;
	}

	const getOptions = (
		optionSource,
		options
		// dataSource
	) => {
		if (!optionSource) {
			return undefined;
		}

		if (optionSource === 'list') {
			return options;
		} else if (optionSource === 'dataset') {
			return options || [];
		} else {
			// TODO: handle unknown options type
			return undefined;
		}
	};

	return (
		<BtFormContent>
			{(components || []).map((component, index) => {
				const {
					name,
					dataSource,
					injectedProps,
					maxCount,
					minCount,
					options,
					optionSource,
					readOnly,
					repeatable,
					type,
					uuid,
				} = component;

				if (readOnly) {
					return (
						<WorkflowReadOnly component={component} key={uuid} />
					);
				}

				let elementData;
				if (Object.keys(nests).includes(type)) {
					elementData = getNestComponent(type);
				} else {
					elementData = getComponentOfType(type);
				}

				const compoundProps = COMPOUND_COMPONENTS.includes(type)
					? { maxCount, minCount, repeatable }
					: {};

				return (
					<ConditionalComponentWrapper
						key={`${uuid}-wrapper`}
						component={component}
						index={index}
					>
						<elementData.Component
							key={uuid}
							items={getOptions(
								optionSource,
								options,
								dataSource
							)}
							label={name}
							name={uuid}
							template={component}
							{...elementData.props}
							{...injectedProps}
							{...compoundProps}
						/>
					</ConditionalComponentWrapper>
				);
			})}
		</BtFormContent>
	);
}

ConditionalComponentWrapper.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	component: PropTypes.object.isRequired,
	index: PropTypes.number,
};

WorkflowComponentsParser.propTypes = {
	components: PropTypes.arrayOf(PropTypes.object),
	Parser: PropTypes.elementType.isRequired,
};
