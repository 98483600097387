const {
	EXPRESSION,
	OPERATION_MODE,
	getExpressionType,
	evaluateExpressionType,
	evaluateExpression,
	flattenExpressionForProject,
} = require('../../expression/expression.js');
const {
	deepCopy,
	dotCopyFromObject,
	dotRemove,
	dotAdd,
} = require('../../utils.js');
const { setStage, ERROR } = require('../pipeline.js');

function projectionExclude(input, projection) {
	const output = deepCopy(input);
	for (const prop in projection) {
		if (Object.hasOwnProperty.call(projection, prop)) {
			if (prop === '_id' && projection[prop] !== false) {
				// then don't remove it
				continue;
			}
			dotRemove(output, prop);
		}
	}

	return output;
}
function projectionInclude(input, projection) {
	const current = {};

	for (const prop in projection) {
		if (Object.hasOwnProperty.call(projection, prop)) {
			if (prop === '_id' && projection[prop] === false) {
				// don't add the id as its listed to be excluded
				continue;
			}

			dotCopyFromObject(current, input, prop);
		}
	}

	return current;
}

function projectionAddFields(context, addFields) {
	let newCurrent = deepCopy(context.current);
	for (const field in addFields) {
		if (Object.hasOwnProperty.call(addFields, field)) {
			const expr = addFields[field];

			const value = evaluateExpression(context, expr);

			newCurrent = dotAdd(newCurrent, value, field);
		}
	}

	return newCurrent;
}

/**
 * @type {PipelineStageFunction<any[], {skipProjectStage?: boolean, stageKey?: string}>}
 */
function project(context, args, options) {
	const expression = getExpressionType(args);
	const stageKey = options?.stageKey;

	if (expression.type !== EXPRESSION.EXPRESSION_OBJECT) {
		throw new Error(ERROR.INVALID_STAGE_ARGUMENT(stageKey));
	}

	const flatExpression = flattenExpressionForProject(expression.value);

	const projection = { _id: true }; // default to include the _id
	const addFields = {};

	const projectionStage = options?.skipProjectStage !== true;

	let addFieldsStage = !projectionStage ? true : false;
	let isExclude = !projectionStage ? false : null;

	for (const key in flatExpression) {
		if (Object.hasOwnProperty.call(flatExpression, key)) {
			const fieldExpression = flatExpression[key];

			if (!projectionStage) {
				addFields[key] = fieldExpression;
				continue;
			}

			if (
				typeof fieldExpression === 'number' ||
				typeof fieldExpression === 'boolean'
			) {
				if (isExclude === null) {
					if (key !== '_id') {
						isExclude = !fieldExpression;
					}
				} else if (isExclude !== !fieldExpression) {
					throw "can't mix include & exclude";
				}

				// num !== 0 || bool true => include
				projection[key] = !!fieldExpression;

				continue;
			}

			if (isExclude) {
				throw "can't add fields in an exclude";
			}

			addFieldsStage = true;
			isExclude = false;

			addFields[key] = fieldExpression;
			projection[key] = true;
		}
	}

	if (isExclude === null && flatExpression['_id'] === undefined) {
		throw new Error(
			ERROR.INVALID_STAGE_ARGUMENT(
				stageKey,
				'specification to have at least one field'
			)
		);
	} else if (isExclude === null && projection['_id'] === false) {
		isExclude = true;
	}

	const accumulator = [];

	for (let c = 0; c < context.collection.length; c++) {
		let current = context.collection[c];

		if (addFieldsStage && !isExclude) {
			current = projectionAddFields(
				{
					...context,
					current: current,
					operationMode: OPERATION_MODE.AGGREGATE,
				},
				addFields
			);
		} else if (isExclude) {
			accumulator.push(projectionExclude(current, projection));
			continue;
		}

		if (projectionStage) {
			accumulator.push(projectionInclude(current, projection));
			continue;
		}

		accumulator.push(current);
	}

	return accumulator;
}

setStage(
	'$project',
	/**
	 * @type {PipelineStageFunction<any[], {skipProjectStage?: boolean, stageKey?: string}>}
	 */
	(context, args, options) =>
		project(context, args, {
			...(options || {}),
			stageKey: '$project',
		})
);

setStage(
	'$addFields',
	/**
	 * @type {PipelineStageFunction<any[], {skipProjectStage?: boolean, stageKey?: string}>}
	 */
	(context, args, options) =>
		project(context, args, {
			...(options || {}),
			stageKey: '$addFields',
			skipProjectStage: true,
		})
);
