import React from 'react';
import PropTypes from 'prop-types';

import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import { styled, Typography } from '@mui/material';

const BoolContainer = styled('div')(() => ({
	display: 'flex',
	alignItems: 'center',
	width: '100%',
}));

const boolIconStyle = ({ istrue, theme }) => ({
	color: theme.palette.indicators[+istrue ? 'success' : 'error'].main,
	marginRight: '0.2em',
});

const TrueIcon = styled(CheckIcon)(boolIconStyle);
const FalseIcon = styled(CloseIcon)(boolIconStyle);

const ValueOutput = ({ value }) => {
	if (typeof value === 'boolean') {
		const Icon = value ? TrueIcon : FalseIcon;

		return value ? (
			<BoolContainer>
				<Icon istrue={1} />
				<Typography>Yes</Typography>
			</BoolContainer>
		) : (
			<BoolContainer>
				<Icon />
				<Typography>No</Typography>
			</BoolContainer>
		);
	}

	return <Typography>{`${value}`}</Typography>;
};

export const WorkflowBasicResult = ({ value }) => (
	<>
		{Array.isArray(value) ? (
			value.map(valueElement => <ValueOutput value={valueElement} />)
		) : (
			<ValueOutput value={value} />
		)}
	</>
);

const coreTypes = [PropTypes.bool, PropTypes.number, PropTypes.string];

ValueOutput.propTypes = {
	value: PropTypes.oneOfType(coreTypes),
};

WorkflowBasicResult.propTypes = {
	value: PropTypes.oneOfType([
		...coreTypes,
		PropTypes.arrayOf(PropTypes.oneOfType(coreTypes)),
	]).isRequired,
};

export default WorkflowBasicResult;
