import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

import {
	BtForm,
	BtFormTextField,
	withFormContextMethods,
} from '../../generic/forms';
import * as yup from 'yup';
import BtPalette from '../../palette/BtPalette';
import BtMessage from '../../generic/BtMessage';
import { mdiClose, mdiCreation } from '@mdi/js';
import { Warning } from '@mui/icons-material';
import { styled } from '@mui/styles';
import { generateRandomPalette } from '../utils/functions';
import { BtIconButtonGroup } from '../../generic/BtIconButtonGroup';

const FormContainer = styled(Box)(() => ({
	display: 'flex',
	gap: '5px',
	flexDirection: 'column',
}));

const InputContainer = styled(Box)(() => ({
	justifyItems: 'center',
	alignItems: 'center',
	display: 'flex',
	gap: '20px',
}));

const WarningContainer = styled(Box)(() => ({
	display: 'flex',
	marginTop: '5px',
	gap: '5px',
}));

const WarningTypography = styled(Typography)(() => ({ fontWeight: 500 }));

const ActionButtons = withFormContextMethods(({ handleCancel }) => {
	const buttonAttribs = useMemo(
		() => [
			{
				tooltip: 'Cancel',
				onClick: handleCancel,
				icon: mdiClose,
				disabled: false,
			},
			{
				tooltip: 'Generate',
				icon: mdiCreation,
				// icon: mdiCheck,
				disabled: false,
				type: 'submit',
			},
		],
		[handleCancel]
	);

	return (
		<BtIconButtonGroup
			containerStyle={{ marginTop: '10px' }}
			buttonGroupAttributes={buttonAttribs}
		/>
	);
});

export default function GeneratePaletteForm({
	handleCancel,
	handleGeneratePalette,
}) {
	const [baseColour, setBaseColour] = useState([]);

	const handleSubmit = useCallback(
		(values, reset) => {
			const palette = generateRandomPalette(
				baseColour[0]?.hex || null,
				values.number
			);
			handleGeneratePalette({ palette: palette });
		},
		[baseColour, handleGeneratePalette]
	);

	const schema = useMemo(
		() =>
			yup.object({
				number: yup
					.number()
					.min(4)
					.max(24)
					.required()
					.label('Number of colours'),
			}),
		[]
	);

	const Message = () => {
		return (
			<Box>
				<Typography variant="h6">Generating a Palette</Typography>
				<ul>
					<li>
						We recommend no more than 10 colours but you can add up
						to 24
					</li>
					<li>a minimum of 4 is required</li>
					<li>You can choose a base colour if you wish</li>
				</ul>
				<WarningContainer>
					<Warning color="warning" />
					<WarningTypography>
						The current palette will be cleared when generating
						colours
					</WarningTypography>
				</WarningContainer>
			</Box>
		);
	};

	return (
		<FormContainer>
			<BtForm
				onSubmit={handleSubmit}
				validationSchema={schema}
				defaultValues={{ number: 8 }}
			>
				<InputContainer>
					<BtPalette
						disableTags
						singleColour
						palette={baseColour}
						onChange={setBaseColour}
					/>
					<BtFormTextField
						type="number"
						name="number"
						label="Number of colours"
						autoFocus
						sx={{
							width: '100px',
						}}
					/>
				</InputContainer>
				<ActionButtons handleCancel={handleCancel} />
			</BtForm>
			<BtMessage message={<Message />} />
		</FormContainer>
	);
}

ActionButtons.propTypes = {
	handleCancel: PropTypes.func.isRequired,
};

GeneratePaletteForm.propTypes = {
	handleCancel: PropTypes.func.isRequired,
	handleGeneratePalette: PropTypes.func.isRequired,
};
