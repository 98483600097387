import React from 'react';
import {
	insightCollectionGet,
	insightCollectionGetDraft,
	insightCollectionGetVersion,
} from '../../../API';
import InsightPage from '../InsightPage/InsightPage';
import v4 from 'uuid/v4';

// check the url to see if we are working with the draft
const evalDraft = location => {
	const pathParts = location.pathname.split('/');

	// return ['Edit', 'Pages'].every(check => {
	// 	return pathParts.includes(check);
	// });
	return ['Edit'].every(check => {
		return pathParts.includes(check);
	});
};

const evalVersion = version => {
	if (version === undefined) {
		return false;
	} else {
		return true;
	}
};

// gets the collection, draft or version
const getCollection = async (isDraft, isVersion, id, version) => {
	let collection;
	try {
		if (isDraft) {
			// console.log('Getting draft...');
			collection = await insightCollectionGetDraft({
				insightUuid: id,
			});
		} else if (isVersion) {
			// console.log('Getting version...');
			collection = await insightCollectionGetVersion({
				insightUuid: id,
				versionIndex: version,
			});
		} else {
			// console.log('Getting collection...');
			collection = await insightCollectionGet({
				insightUuid: id,
			});
		}

		return collection;
	} catch (error) {
		console.error(error);
	}
};

/**
 *
 * @param {string} id the insight collection uuid
 * @param {object} location the location data as returned by react-router useLocation
 * @param {string} version the version
 * @returns the insight collection
 */
const getInsightCollection = async (id, location, version) => {
	const isDraft = evalDraft(location);
	const isVersion = evalVersion(version);
	const collection = await getCollection(isDraft, isVersion, id, version);
	return collection ? collection : null;
	// console.log({ isDraft, isVersion, collection });
};

/**
 * Prepares the tabs data for the pages,
 * including the InsightPage component for each page
 * @param {object[]} pages
 * @param {object[]} visualisations
 * @param {function} handlePageUpdate
 * @param {boolean} editing
 * @returns {object[]}
 */
const mapTabs = (
	pages,
	visualisations,
	handlePageUpdate,
	editing,
	palette,
	refreshCollectionData,
	collectionPalettes,
	paletteConfig
) => {
	// console.log({
	// 	palette,
	// 	refreshCollectionData,
	// 	collectionPalettes,
	// 	paletteConfig,
	// });
	const tabs = [...pages].map(page => {
		const tab = { ...page };
		tab.label = tab.name;
		tab.content = (
			<InsightPage
				handlePageUpdate={handlePageUpdate}
				insightPage={page}
				visualisations={visualisations}
				editEnabled={editing}
				palette={palette}
				collectionPalettes={collectionPalettes}
				paletteConfig={paletteConfig}
				refreshCollectionData={refreshCollectionData}
			/>
		);
		return tab;
	});
	return tabs;
};

const stripTabProps = tabs => {
	const pages = tabs.map(tab => {
		const page = { ...tab };
		delete page.content;
		delete page.label;
		return page;
	});
	return pages;
};

/**
 * replaces the page at currentPage index with the update
 * @param {*} pages the insight collection pages array
 * @param {*} update the page to update
 * @param {*} currentPage the 1-based index of the
 * @returns {object[]} updated pages array
 */
const updatePagesArray = (pages, update, currentPage) => {
	const pagesUpdate = [...pages];
	const pageUpdate = pagesUpdate.splice(currentPage - 1, 1)[0];
	Object.keys(update).forEach(key => (pageUpdate[key] = update[key]));
	pagesUpdate.splice(currentPage - 1, 0, pageUpdate);
	return pagesUpdate;
};

const seedNewPageProps = (name, description) => {
	const newUuid = v4();
	const newPage = {};
	newPage.description = description ? description : '';
	newPage.layouts = { desktop: [], tablet: [], mobile: [] };
	newPage.name = name;
	newPage.uuid = newUuid;
	return newPage;
};

export {
	getInsightCollection,
	mapTabs,
	evalDraft,
	stripTabProps,
	updatePagesArray,
	seedNewPageProps,
};
