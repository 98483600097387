export const DOES_NOT_EQUAL = 'does not equal';
export const DOES_NOT_HAVE_FIELD = 'does not have field';
export const DOES_NOT_INCLUDE = 'does not include';
export const ENDS_WITH = 'ends with';
export const EQUALS = 'equals';
export const HAS_FIELD = 'has field';
export const HAS_LENGTH_GREATER_THAN = 'has length greater than';
export const HAS_LENGTH_GREATER_THAN_OR_EQUAL_TO =
	'has length greater than or equal to';
export const HAS_LENGTH_LESS_THAN = 'has length less than';
export const HAS_LENGTH_LESS_THAN_OR_EQUAL_TO =
	'has length less than or equal to';
export const HAS_LENGTH_OF = 'has length of';
export const INCLUDES = 'includes';
export const IS_BETWEEN = 'is between';
export const IS_EMPTY = 'is empty';
export const IS_GREATER_THAN = 'greater than';
export const IS_GREATER_THAN_OR_EQUAL_TO = 'greater than or equal to';
export const IS_INSIDE_GEO_RADIUS = 'is inside geo distance';
export const IS_LESS_THAN = 'less than';
export const IS_LESS_THAN_OR_EQUAL_TO = 'less than or equal to';
export const IS_NOT_BETWEEN = 'is not between';
export const IS_NOT_EMPTY = 'is not empty';
export const IS_NOT_ONE_OF = 'is not one of';
export const IS_ONE_OF = 'is one of';
export const IS_OUTSIDE_GEO_RADIUS = 'is outside geo distance';
export const STARTS_WITH = 'starts with';

export const comparativeOperations = [
	DOES_NOT_EQUAL,
	DOES_NOT_HAVE_FIELD,
	DOES_NOT_INCLUDE,
	ENDS_WITH,
	EQUALS,
	HAS_FIELD,
	HAS_LENGTH_GREATER_THAN,
	HAS_LENGTH_GREATER_THAN_OR_EQUAL_TO,
	HAS_LENGTH_LESS_THAN,
	HAS_LENGTH_LESS_THAN_OR_EQUAL_TO,
	HAS_LENGTH_OF,
	INCLUDES,
	IS_BETWEEN,
	IS_EMPTY,
	IS_GREATER_THAN,
	IS_GREATER_THAN_OR_EQUAL_TO,
	IS_INSIDE_GEO_RADIUS,
	IS_LESS_THAN,
	IS_LESS_THAN_OR_EQUAL_TO,
	IS_NOT_BETWEEN,
	IS_NOT_EMPTY,
	IS_NOT_ONE_OF,
	IS_ONE_OF,
	IS_OUTSIDE_GEO_RADIUS,
	STARTS_WITH,
];

export default comparativeOperations;
