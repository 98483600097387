import {
	mdiCardTextOutline,
	mdiChartBar,
	mdiChartLine,
	mdiChartPie,
	mdiGauge,
	mdiImage,
	mdiInformationVariant,
	mdiRadar,
	mdiShapePlus,
	mdiTable,
	mdiChartTimeline,
} from '@mdi/js';
import LineChartComponent from '../Components/VisComponents/LineChartComponent';
import PieChartComponent from '../Components/VisComponents/PieChartComponent';
import BarChartComponent from '../Components/VisComponents/BarChartComponent';
import TableComponent from '../Components/VisComponents/TableComponent';
import StatusCardComponent from '../Components/VisComponents/StatusCardComponent';
import InfoCardComponent from '../Components/VisComponents/InfoCardComponent';
import SvgComponent from '../Components/VisComponents/SvgComponent';
import GaugeComponent from '../Components/VisComponents/GaugeComponent';
import ImageGalleryComponent from '../Components/VisComponents/ImageGalleryComponent';
import RadialBarChartComponent from '../Components/VisComponents/RadialBarChartComponent';
import StatusTimelineComponent from '../Components/VisComponents/StatusTimelineComponent';

const DESKTOP = 'desktop';
const TABLET = 'tablet';
const MOBILE = 'mobile';
const LAYOUT_TYPES = [DESKTOP, TABLET, MOBILE];

const LAYOUT_COORDINATE_KEYS = ['x', 'y'];
const LAYOUT_SIZE_KEYS = ['h', 'w'];
const LAYOUT_KEYS = [...LAYOUT_COORDINATE_KEYS, ...LAYOUT_SIZE_KEYS];

const PRIMARY_AXIS_PREFIX_OPTIONS = ['£', '%'].map(prefix => ({
	value: prefix,
	label: prefix,
}));

const LINE_CHART = 'LineChart';
const PIE_CHART = 'PieChart';
const BAR_CHART = 'BarChart';
const TABLE = 'Table';
const RADIAL_BAR_CHART = 'RadialBarChart';
const STATUS_TIMELINE_CHART = 'StatusTimelineChart';
const STATUS_CARD = 'StatusCard';
const IMAGE_GALLERY = 'ImageGallery';
const INFO_CARD = 'InfoCard';
const GAUGE = 'Gauge';
const SVG = 'Svg';

const VIS_LIST_PROPERTIES = {
	[BAR_CHART]: {
		type: BAR_CHART,
		name: 'Bar Chart',
		icon: mdiChartBar,
		component: BarChartComponent,
	},
	[GAUGE]: {
		type: GAUGE,
		name: 'Gauge',
		icon: mdiGauge,
		component: GaugeComponent,
	},
	[IMAGE_GALLERY]: {
		type: IMAGE_GALLERY,
		name: 'Image Gallery',
		icon: mdiImage,
		component: ImageGalleryComponent,
	},
	[INFO_CARD]: {
		type: INFO_CARD,
		name: 'Information Card',
		icon: mdiInformationVariant,
		component: InfoCardComponent,
	},
	[LINE_CHART]: {
		type: LINE_CHART,
		name: 'Line Chart',
		icon: mdiChartLine,
		component: LineChartComponent,
	},
	[PIE_CHART]: {
		type: PIE_CHART,
		name: 'Pie Chart',
		icon: mdiChartPie,
		component: PieChartComponent,
	},
	[RADIAL_BAR_CHART]: {
		type: RADIAL_BAR_CHART,
		name: 'Radial Bar Chart',
		icon: mdiRadar,
		component: RadialBarChartComponent,
	},
	[STATUS_CARD]: {
		type: STATUS_CARD,
		name: 'Status Card',
		icon: mdiCardTextOutline,
		component: StatusCardComponent,
	},
	// svg component for future development
	// [SVG]: {
	// 	type: SVG,
	// 	name: 'Svg',
	// 	icon: mdiShapePlus,
	// 	component: SvgComponent,
	// },
	[TABLE]: {
		type: TABLE,
		name: 'Data Table',
		icon: mdiTable,
		component: TableComponent,
	},
	[STATUS_TIMELINE_CHART]: {
		type: STATUS_TIMELINE_CHART,
		name: 'Status Timeline Chart',
		icon: mdiChartTimeline,
		component: StatusTimelineComponent,
	},
};

/**
 * Used to populate new insight menu in EditControls.js
 */
const VIS_LIST = Object.keys(VIS_LIST_PROPERTIES).map(key => ({
	type: VIS_LIST_PROPERTIES[key].type,
	name: VIS_LIST_PROPERTIES[key].name,
	icon: VIS_LIST_PROPERTIES[key].icon,
}));

/**
 * Array of visualisation types as strings, used for validations
 */
const VIS_LIST_TYPES = VIS_LIST.map(({ type }) => type);

/**
 * Used in select for changing insight type in InsightComponentEdit.js
 */
const VIS_LIST_SELECT_OPTIONS = VIS_LIST.map(({ type, name }) => {
	return {
		value: type,
		label: name,
	};
});

/**
 * Column breakpoints
 */
const COLUMNS = {
	xl: 16, // desktop lg
	lg: 16, // desktop md
	md: 8, // tablet landscape / desktop sm
	sm: 8, // mobile landscape / tablet portrait
	xs: 4, // mobile portrait
	xxs: 4,
};

export {
	LAYOUT_TYPES,
	LAYOUT_COORDINATE_KEYS,
	LAYOUT_SIZE_KEYS,
	LAYOUT_KEYS,
	VIS_LIST,
	VIS_LIST_TYPES,
	COLUMNS,
	VIS_LIST_SELECT_OPTIONS,
	VIS_LIST_PROPERTIES,
	PRIMARY_AXIS_PREFIX_OPTIONS,
	// VIS_LIST_COMPONENTS,
	LINE_CHART,
	PIE_CHART,
	BAR_CHART,
	TABLE,
	RADIAL_BAR_CHART,
	STATUS_CARD,
	IMAGE_GALLERY,
	INFO_CARD,
	GAUGE,
	SVG,
	STATUS_TIMELINE_CHART,
};
