import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useNavContext } from '../../context/ContextManager';

// MUI COMPONENTS
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

// MUI ICONS
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/SaveAlt';
import EditIcon from '@mui/icons-material/Edit';

// CUSTOM COMPONENTS
import {
	ListTable,
	ListRow,
	ListHeader,
	ListCell,
} from '../../components/generic/ListTable';

import SourceSelectDialog from '../../components/dialogs/SourceSelectDialog';

// Data for landing page selection table and contextual navigation
import data_manager_options from './DataManagerOptions';
import data_manager_breadcrumbs from './DataManagerBreadcrumbs';
import data_manager_nav_item from './DataManagerNavItem';

const styles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(2),
		'& .MuiTextField-root': {
			margin: '10px 0',
		},
	},
	main_paper: {
		padding: 10,
		margin: '10px 0',
	},
	dialogTitle: {
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.contrastText,
	},
	dialogContent: {
		minWidth: '600px',
	},
	textField: {
		margin: '0px 0',
		fontSize: '2rem',
	},
	groupBox: {
		border: '1px solid black',
		borderRadius: theme.shape.borderRadius,
		borderColor: theme.palette.primary.border_color,
		margin: '1rem 1rem',
		padding: '1rem 1rem',
	},
	triggerBox: {
		height: '100%',
	},
	paramBox: {
		minWidth: '300px',
	},
	select: {
		minWidth: '200px',
	},
	table_cell: {
		padding: '0px 8px',
		width: '25%',
	},
}));

export default function ConfigEditChannelDialog({ match }) {
	const classes = styles();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(true);
	const [selectSourceOpen, setSelectSourceOpen] = useState(false);
	const [reporter, setReporter] = useState({});
	const { setBreadcrumbs } = useNavContext();
	const { setContextualNav } = useNavContext();

	var reporter_uuid = match.params.uuid;

	useEffect(
		() => {
			setBreadcrumbs([
				...data_manager_breadcrumbs,
				{ text: 'Reports', link: '/Reports' },
				{ text: 'New Report', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([
				...data_manager_nav_item,
				...data_manager_options,
			]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	useEffect(
		() => {
			if (reporter_uuid !== null) {
				// Get the reporter info from the server
				var req_url =
					process.env.REACT_APP_API_SERVER_URL +
					'/v1/reporter/' +
					reporter_uuid;

				Auth.currentSession().then(user_session => {
					let user_token = user_session.getAccessToken();
					fetch(req_url, {
						method: 'GET',
						headers: {
							Authorization: user_token.jwtToken,
							Accept: 'application/json',
							'Content-Type': 'application/json',
						},
					})
						.then(res => res.json())
						.then(response => {
							console.log(response);
							// The API will respond with OK if a config with this name is already present
							if (response['result'] === 'OK') {
								setReporter(response.reporter);
							} else {
								alert('Server connection error.');
							}
							setIsLoading(false);
						})
						.catch(error => {
							setIsLoading(false);
						});
				});
			} else {
				let new_reporter = {};
				new_reporter.name = '';
				new_reporter.enabled = true;
				new_reporter.source = { type: 'not set' };
				setReporter(new_reporter);

				setIsLoading(false);
			}
		},
		[reporter_uuid]
	);

	const handleCancel = event => {
		history.goBack();
	};

	const handleSave = event => {
		console.log('SAVE');
		/*
		try{
        	var config_str = JSON.parse(json)
      	} catch (e){
        	console.log(e);
        	alert("JOSN parse error, please check your syntax.")
        	return;
      	}

		var config = {};
		config['version'] = version;
		config['channels'] = configChannels;

		setCommsActive(true);

		var req_url = process.env.REACT_APP_API_SERVER_URL + `/v1/config/` + config_id;
        fetch(
          req_url,
          {
			method: "PUT",
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(config),
          }
        )
        .then(res => res.json())
        .then(response => {
			if(response['result'] !== "OK") {
				alert("Internal server error.");
				setCommsActive(false);
			} else {
				alert("Configuration Saved.");
				setCommsActive(false);
				history.goBack();
			}
        })
        .catch(error => {
			console.log(error)
			alert("Failed to connect to the server.");
			setCommsActive(false);
		});
		*/
	};

	const handleNameChange = event => {
		//var new_params = [...channel.params];
		//new_params.splice(index, 1, {...channel.params[index], name:event.target.value});
		//setChannel({...channel, params:new_params});

		console.log('name change');
	};

	const handleEnableChange = event => {
		/*var chan = channel;
		if(event.target.checked){
			chan.trigger_end = {};
			chan.trigger_end['type'] = "edge_f";
			chan.trigger_end['input'] = "D0";
		} else {
			
			delete chan.trigger_end;
		}
		setChannel(chan);
		setEnableEndTrigger(!enableEndTrigger);*/
	};

	const handleSelectSource = () => {
		//setEditChannel(channel_index);
		setSelectSourceOpen(true);
	};

	const handleSelectSourceDialogClose = () => {
		console.log('source change');
		setSelectSourceOpen(false);
	};

	const handleExportTypeSelect = event => {
		console.log('EXPORT TYPE SELECT');
	};

	const handleUrlChange = event => {
		//var new_params = [...channel.params];
		//new_params.splice(index, 1, {...channel.params[index], name:event.target.value});
		//setChannel({...channel, params:new_params});

		console.log('url change');
	};

	const handleExportNameChange = event => {
		console.log('export name change');
	};

	const handleParamExportEnableChange = event => {
		console.log('export enable change');
	};

	const handleParamFormatTypeSelect = event => {
		console.log('param format change');
	};

	return (
		<>
			{isLoading && (
				<Paper>
					<p>Loading...</p>
					<CircularProgress size={'120px'} />
				</Paper>
			)}

			{!isLoading && (
				<>
					<div className={classes.root}>
						<Paper className={classes.main_paper}>
							<ListTable>
								<ListRow>
									<ListHeader>Enabled:</ListHeader>
									<ListCell>
										<FormControlLabel
											control={
												<Checkbox
													checked={reporter.enabled}
													onChange={
														handleEnableChange
													}
													name="enable_reporter"
													color="primary"
												/>
											}
										/>
									</ListCell>
								</ListRow>
								<ListRow>
									<ListHeader>Name:</ListHeader>
									<ListCell>
										<TextField
											variant="outlined"
											fullWidth
											defaultValue={reporter.name}
											className={classes.textField}
											onChange={handleNameChange}
										/>
									</ListCell>
								</ListRow>
							</ListTable>

							<Divider />
							<Typography variant="h4">Source</Typography>

							<ListTable>
								<ListRow>
									<ListHeader>Type:</ListHeader>
									<ListCell>
										{reporter.source.source_type}
									</ListCell>
								</ListRow>
								<ListRow>
									<ListHeader>ID:</ListHeader>
									<ListCell>{reporter.source.uid}</ListCell>
								</ListRow>
								<ListRow>
									<ListHeader>Channel:</ListHeader>
									<ListCell>
										{reporter.source.channel}
									</ListCell>
								</ListRow>
							</ListTable>

							<Button
								variant="outlined"
								startIcon={<EditIcon />}
								onClick={handleSelectSource}
								className={classes.button}
							>
								Select Source
							</Button>

							<Divider />

							<Typography variant="h4">Target</Typography>

							<ListTable>
								<ListRow>
									<ListHeader>Type:</ListHeader>
									<ListCell>
										<FormControl
											variant="outlined"
											className={classes.formControl}
										>
											<Select
												labelId="demo-simple-select-outlined-label"
												id="demo-simple-select-outlined"
												value={reporter.reporter_type}
												className={classes.select}
												onChange={
													handleExportTypeSelect
												}
											>
												<MenuItem value="REST">
													REST API
												</MenuItem>
												<MenuItem value="SQL">
													SQL
												</MenuItem>
												<MenuItem value="POWER_BI">
													Power BI
												</MenuItem>
												<MenuItem value="SMS">
													SMS
												</MenuItem>
												<MenuItem value="EMAIL">
													E-Mail
												</MenuItem>
											</Select>
										</FormControl>
									</ListCell>
								</ListRow>

								<ListRow>
									<ListHeader>URL:</ListHeader>
									<ListCell>
										<TextField
											variant="outlined"
											fullWidth
											defaultValue={reporter.url}
											className={classes.textField}
											onChange={handleUrlChange}
											multiline={true}
										/>
									</ListCell>
								</ListRow>
							</ListTable>

							<Divider />

							<Typography variant="h4">
								Output Parameters
							</Typography>
							<table style={{ width: '100%' }}>
								<tbody>
									<tr>
										<th style={styles.table_cell}>
											Source Name:
										</th>
										<th style={styles.table_cell}>
											Export Name:
										</th>
										<th style={styles.table_cell}>
											Exported:
										</th>
										<th style={styles.table_cell}>
											Formatting
										</th>
									</tr>
									{reporter.export_params.map(
										(param, index) => (
											<tr key={index}>
												<td style={styles.table_cell}>
													{param.source_name}
												</td>

												<td style={styles.table_cell}>
													<TextField
														variant="outlined"
														fullWidth
														defaultValue={
															param.export_name
														}
														className={
															classes.textField
														}
														onChange={
															handleExportNameChange
														}
													/>
												</td>

												<td style={styles.table_cell}>
													<FormControlLabel
														control={
															<Checkbox
																checked={
																	param.export
																}
																onChange={
																	handleParamExportEnableChange
																}
																name="enable_reporter"
																color="primary"
															/>
														}
													/>
												</td>

												<td style={styles.table_cell}>
													<FormControl
														variant="outlined"
														className={
															classes.formControl
														}
													>
														<Select
															labelId="demo-simple-select-outlined-label"
															id="demo-simple-select-outlined"
															value={
																param.format
																	.type
															}
															className={
																classes.select
															}
															onChange={
																handleParamFormatTypeSelect
															}
														>
															<MenuItem value="NONE">
																No Formating
															</MenuItem>
															<MenuItem value="ISO_TIME">
																ISO Time
															</MenuItem>
														</Select>
													</FormControl>
												</td>
											</tr>
										)
									)}
								</tbody>
							</table>

							<Divider />

							<Box
								display="flex"
								flexDirection="row-reverse"
								p={1}
								m={1}
								bgcolor="background.paper"
							>
								<Button
									variant="outlined"
									startIcon={<SaveIcon />}
									onClick={handleSave}
									className={classes.actionButton}
								>
									Save
								</Button>

								<Button
									variant="outlined"
									startIcon={<CloseIcon />}
									onClick={handleCancel}
									className={classes.actionButton}
								>
									Cancel
								</Button>
							</Box>
						</Paper>
					</div>
					<SourceSelectDialog
						setOpen={selectSourceOpen}
						onClose={handleSelectSourceDialogClose}
						source={reporter.Source}
					/>
				</>
			)}
		</>
	);
}
