import React, {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';

import { Box, Fade, styled } from '@mui/material';

import BtLoading from '../../../../../components/generic/BtLoading';
import BtPagination from '../../../../../components/generic/BtPagination';
import DataSetEditActions from '../record-editor/DataSetEditActions';
import { DataSetEditorContextProvider } from '../../../contexts/DataSetEditorContext';
import DataSetListItem from './DataSetListItem';
import { dataSetStoreGet } from '../../../../../API';
import { parseSchema } from '../../../../../utils/yup-ast';
import { useDataSetContext } from '../../../hocs/withDataSetContext';

const StickyContainer = styled('div')(({ theme }) => ({
	background: `linear-gradient(180deg, ${
		theme.palette.background.default
	}00 0%, ${theme.palette.background.default} 20%, ${
		theme.palette.background.default
	} 100%)`,
	bottom: 0,
	padding: '0.33em 0',
	position: 'sticky',
	transform: 'translateY(1px)',
	width: '100%',
}));

const PAGE_SIZE_LSKEY = 'dataSet_pageSize';

export const DataSetList = forwardRef(
	({ dataSet, dataSetUuid, viewMode }, ref) => {
		const contextRef = useRef();

		const [dataOffset, setDataOffset] = useState(0);
		const [dataSetRecords, setDataSetRecords] = useState(null);
		const [isLoading, setIsLoading] = useState(false);
		const [pageSize, setPageSize] = useState(
			+localStorage.getItem(PAGE_SIZE_LSKEY) || 5
		);
		const [recordCount, setRecordCount] = useState(0);
		const [schema, setSchema] = useState(null);

		const { editData } = useDataSetContext();

		const loadData = useCallback(
			async onFinish => {
				const newDataRecords = await dataSetStoreGet({
					dataSetUuid: dataSetUuid,
					limit: pageSize,
					offset: dataOffset,
				});

				setDataSetRecords(newDataRecords.records);
				setRecordCount(newDataRecords.totalRecordCount);

				onFinish?.();
			},
			[dataOffset, dataSetUuid, pageSize]
		);

		useEffect(
			() => {
				if (dataSetUuid) {
					setIsLoading(true);

					loadData(() => setIsLoading(false));
				}
			},
			[dataOffset, dataSetUuid, pageSize, loadData]
		);

		useEffect(
			() => {
				if (dataSet) {
					setSchema(parseSchema(dataSet.schema));
				}
			},
			[dataSet]
		);

		const discardChanges = useCallback(() => {
			contextRef?.current?.discardChanges?.();
		}, []);

		const saveChanges = useCallback(() => {
			contextRef?.current?.saveChanges?.();
		}, []);

		useImperativeHandle(ref, () => ({
			refreshPage: onFinish => {
				loadData(onFinish);
			},
		}));

		if (isLoading) {
			return <BtLoading showLogo={false} style={{ margin: '2em' }} />;
		}

		return (
			<Box ref={ref} sx={{ width: '100%' }}>
				{dataSetRecords && (
					<Fade in={!!dataSetRecords}>
						<div style={{ margin: '1em 0' }}>
							{dataSetRecords.map(record => (
								<DataSetEditorContextProvider
									ref={
										record.uuid === editData.recordUuid
											? contextRef
											: null
									}
									key={record.uuid}
									data={record}
									dataSetUuid={dataSetUuid}
									loadData={loadData}
									schema={schema}
								>
									<DataSetListItem
										schema={schema}
										viewMode={viewMode}
									/>
								</DataSetEditorContextProvider>
							))}
							{editData.isEditing && (
								<StickyContainer>
									<DataSetEditActions
										discardChanges={discardChanges}
										saveChanges={saveChanges}
									/>
								</StickyContainer>
							)}
							{!editData.isEditing && (
								<StickyContainer>
									<BtPagination
										dataOffset={dataOffset}
										pageSize={pageSize}
										pageSizeOptions={[5, 10, 25, 50]}
										onOffsetUpdate={setDataOffset}
										onPageSizeUpdate={newSize => {
											localStorage.setItem(
												PAGE_SIZE_LSKEY,
												newSize
											);
											setPageSize(newSize);
										}}
										recordCount={recordCount}
									/>
								</StickyContainer>
							)}
						</div>
					</Fade>
				)}
			</Box>
		);
	}
);

DataSetList.propTypes = {
	dataSet: PropTypes.object,
	dataSetUuid: PropTypes.string.isRequired,
	viewMode: PropTypes.string.isRequired,
};

DataSetList.displayName = 'DataSetList';

export default DataSetList;
