import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import useSize from '@react-hook/size';

import { styled } from '@mui/material';

const Resizer = styled('div')(
	({ default_height, duration, target_height, theme }) => ({
		height: target_height ?? default_height ?? 0,
		overflow: 'hidden',

		transition: theme.transitions.create(['height'], {
			duration: `${duration}ms`,
		}),
	})
);

export default function BtHeightAnimator({
	children,
	defaultHeight,
	duration,
	onAnimationFinish,
	...other
}) {
	const childContainerRef = useRef();
	const [, height] = useSize(childContainerRef);

	useEffect(
		() => {
			let timeout;

			timeout = setTimeout(
				() => {
					onAnimationFinish?.(height);
				},
				[duration]
			);

			return () => timeout && clearTimeout(timeout);
		},
		[duration, height, onAnimationFinish]
	);

	return (
		<Resizer
			default_height={defaultHeight}
			duration={duration}
			target_height={height}
			{...other}
		>
			<div ref={childContainerRef}>{children}</div>
		</Resizer>
	);
}

BtHeightAnimator.defaultProps = {
	defaultHeight: 0,
	duration: 250,
};

BtHeightAnimator.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	defaultHeight: PropTypes.number,
	duration: PropTypes.number,
	onAnimationFinish: PropTypes.func,
};
