import { useCallback, useEffect, useState, useRef } from 'react';

import useSize from '@react-hook/size';

/**
 *
 * @param {*} containerRef
 * @param {boolean} multiRow - if true the overflow of a container with multiple rows is supported
 * @returns
 */
export default function useOverflowedElementCount(containerRef, multiRow) {
	const firstHiddenIndex = useRef();
	const topRowWidth = useRef();

	const [width, height] = useSize(containerRef);

	const [count, setCount] = useState();

	const assessOverflow = useCallback(
		() => {
			const childNodes = Array.from(
				containerRef?.current?.children || []
			).filter(
				childNode =>
					!Array.from(childNode.classList).includes(
						'overflow_not_countable'
					)
			);
			firstHiddenIndex.current = Math.max(
				(childNodes || []).findIndex(
					childNode =>
						!multiRow
							? childNode.offsetTop > 0
							: childNode.offsetTop >= height
					// childNode => childNode.offsetTop >= height
				),
				0
			);
			if (firstHiddenIndex?.current > 0) {
				const lastTopChild = childNodes[firstHiddenIndex.current - 1];
				const { clientWidth, offsetLeft } = lastTopChild;
				topRowWidth.current = clientWidth + offsetLeft;
				// console.log({ clientWidth, offsetLeft });
			}

			const total = childNodes.length;
			const overflowCount = firstHiddenIndex.current
				? total - firstHiddenIndex.current
				: 0;

			if (overflowCount !== count) {
				setCount(overflowCount);
			}
		},
		[containerRef, count, height, multiRow]
	);

	useEffect(
		() => {
			assessOverflow();
		},
		[assessOverflow, width]
	);

	useEffect(
		() => {
			window.addEventListener('resize', assessOverflow);

			return () => window.removeEventListener('resize', assessOverflow);
		},
		[assessOverflow]
	);

	return {
		assessOverflow,
		count,
		firstHiddenIndex: firstHiddenIndex?.current || undefined,
		topRowWidth: topRowWidth?.current,
		width,
	};
}
