import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { useAppContext } from '../../context/ContextManager';

// MUI STYLES
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

// MUI COMPONENTS
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';

// MUI ICONS
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TextField from '@mui/material/TextField';
import { ListTable, ListRow, ListHeader, ListCell } from '../generic/ListTable';

import {
	a11yProps,
	BtTab,
	BtTabBar,
	BtTabPanel,
} from '../../components/generic/BtTabView';

// CUSTOM COMPONENTS
import ConfigEditChannelDialog from '../dialogs/ConfigEditChannelDialog';

// const AntTabs = withStyles(theme => ({
// 	root: {
// 		backgroundColor: '#ffffff',
// 	},
// 	indicator: {
// 		backgroundColor: theme.palette.primary.light,
// 	},
// }))(Tabs);

// const AntTab = withStyles(theme => ({
// 	root: {
// 		textTransform: 'none',
// 		minWidth: 100,
// 		fontWeight: theme.typography.fontWeightRegular,
// 		fontSize: '1.25rem',
// 		marginRight: theme.spacing(2),
// 		backgroundColor: theme.palette.primary.dark,
// 		color: theme.palette.primary.contrastText,

// 		borderRadiusTopLeft: theme.shape.borderRadius,

// 		'&:hover': {
// 			color: theme.palette.primary.shade_light,
// 			opacity: 1,
// 		},
// 		'&$selected': {
// 			color: theme.palette.primary.shade_light,
// 			fontWeight: theme.typography.fontWeightMedium,
// 		},
// 		'&:focus': {
// 			color: theme.palette.primary.shade_light,
// 		},
// 	},
// 	selected: {},
// }))(props => <Tab disableRipple {...props} />);

// function TabPanel(props) {
// 	const { children, value, index, ...other } = props;

// 	return (
// 		<Typography
// 			component="div"
// 			role="tabpanel"
// 			hidden={value !== index}
// 			id={`simple-tabpanel-${index}`}
// 			aria-labelledby={`simple-tab-${index}`}
// 			{...other}
// 		>
// 			{value === index && <Box p={2}>{children}</Box>}
// 		</Typography>
// 	);
// }

// TabPanel.propTypes = {
// 	children: PropTypes.node,
// 	index: PropTypes.any.isRequired,
// 	value: PropTypes.any.isRequired,
// };

// function a11yProps(index) {
// 	return {
// 		id: `simple-tab-${index}`,
// 		'aria-controls': `simple-tabpanel-${index}`,
// 	};
// }

export default function ConfigChannels({ config, editable, deviceDef }) {
	const [currentTab, setCurrentTab] = useState(0);
	const [json, setJson] = useState('[]');
	const [configChannels, setConfigChannels] = useState([]);

	const [editChannel, setEditChannel] = useState({});
	const [channelEditOpen, setChannelEditOpen] = useState(false);

	const handleJsonChange = event => {
		setJson(event.target.value);
	};

	const handleEdit = channel_index => event => {
		setEditChannel(channel_index);
		setChannelEditOpen(true);
	};

	const handleAddChannel = () => event => {
		setEditChannel(config.length); // Set the edit channel to pass the end of the array to create new
		setChannelEditOpen(true);
	};

	const handleDelete = channel_index => event => {
		var temp_channels = [...config];
		temp_channels.splice(channel_index, 1);
		setConfigChannels(temp_channels);
	};

	const handleChannelEditDialogClose = channelArray => {
		setChannelEditOpen(false);
		console.log(channelArray);
	};

	const triggerType = trigger => {
		switch (trigger.type) {
			case 'edge_r':
				return (
					<ListTable>
						<ListRow>
							<ListHeader>Type:</ListHeader>
							<ListCell>Rising Edge</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>Input:</ListHeader>
							<ListCell>{trigger.input}</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>Count:</ListHeader>
							<ListCell>{trigger.count}</ListCell>
						</ListRow>
					</ListTable>
				);

			case 'edge_f':
				return (
					<ListTable>
						<ListRow>
							<ListHeader>Type:</ListHeader>
							<ListCell>Falling Edge</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>Input:</ListHeader>
							<ListCell>{trigger.input}</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>Count:</ListHeader>
							<ListCell>{trigger.count}</ListCell>
						</ListRow>
					</ListTable>
				);

			case 'periodic':
				return (
					<ListTable>
						<ListRow>
							<ListHeader>Type:</ListHeader>
							<ListCell>Periodic</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>Time Unit Type:</ListHeader>
							<ListCell>{trigger.time_unit}</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>Time Unit Count:</ListHeader>
							<ListCell>{trigger.count}</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>Auto Reload:</ListHeader>

							{trigger.auto_reload === true ? (
								<ListCell>Enabled</ListCell>
							) : (
								<ListCell>Disabled</ListCell>
							)}
						</ListRow>
					</ListTable>
				);

			case 'scheduled':
				return (
					<ListTable>
						<ListRow>
							<ListHeader>Type:</ListHeader>
							<ListCell>Periodic</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>Time Unit Type:</ListHeader>
							<ListCell>{trigger.time_uint}</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>Time Unit Count:</ListHeader>
							<ListCell>{trigger.count}</ListCell>
						</ListRow>
					</ListTable>
				);

			case 'value_l':
				return (
					<ListTable>
						<ListRow>
							<ListHeader>Type:</ListHeader>
							<ListCell>Threshold Low</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>Input:</ListHeader>
							<ListCell>{trigger.input}</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>Low Threshold:</ListHeader>
							<ListCell>{trigger.threshold_low}</ListCell>
						</ListRow>
					</ListTable>
				);

			case 'value_h':
				return (
					<ListTable>
						<ListRow>
							<ListHeader>Type:</ListHeader>
							<ListCell>Threshold High</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>Input:</ListHeader>
							<ListCell>{trigger.input}</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>High Threshold:</ListHeader>
							<ListCell>{trigger.threshold_high}</ListCell>
						</ListRow>
					</ListTable>
				);

			case 'value_ri':
				return (
					<ListTable>
						<ListRow>
							<ListHeader>Type:</ListHeader>
							<ListCell>In Range</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>Input:</ListHeader>
							<ListCell>{trigger.input}</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>Low Threshold:</ListHeader>
							<ListCell>{trigger.threshold_low}</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>High Threshold:</ListHeader>
							<ListCell>{trigger.threshold_high}</ListCell>
						</ListRow>
					</ListTable>
				);

			case 'value_ro':
				return (
					<ListTable>
						<ListRow>
							<ListHeader>Type:</ListHeader>
							<ListCell>Out of Range</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>Input:</ListHeader>
							<ListCell>{trigger.input}</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>Low Threshold:</ListHeader>
							<ListCell>{trigger.threshold_low}</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>High Threshold:</ListHeader>
							<ListCell>{trigger.threshold_high}</ListCell>
						</ListRow>
					</ListTable>
				);

			case 'logic':
				return (
					<ListTable>
						<ListRow>
							<ListHeader>Type:</ListHeader>
							<ListCell>Logic</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>Input:</ListHeader>
							<ListCell>TBD</ListCell>
						</ListRow>
					</ListTable>
				);

			default:
				return (
					<Typography variant="h5">Trigger Type ERROR!</Typography>
				);
		}
	};

	return (
		<>
			<form noValidate autoComplete="off">
				<BtTabBar
					currentTab={currentTab}
					onTabChange={(event, selectedTab) =>
						setCurrentTab(selectedTab)
					}
					style={{
						height: '5.2rem',
					}}
				>
					<BtTab
						label="Builder"
						{...a11yProps(0)}
						key={0}
						sx={{ marginBottom: 0 }}
					/>
					<BtTab
						label="JSON"
						{...a11yProps(1)}
						key={1}
						sx={{ marginBottom: 0 }}
					/>
				</BtTabBar>

				<BtTabPanel currentTab={currentTab} index={0} key={0}>
					{config.map((channel, index) => (
						<Paper key={index}>
							<Typography variant="h6">
								Channel {index}: {channel.name}
							</Typography>

							<Grid container alignItems="stretch" spacing={3}>
								<Grid item xs={12} sm={6}>
									<Box>
										<Typography variant="h6">
											Start Trigger:
										</Typography>
										{triggerType(channel.trigger_start)}
									</Box>
								</Grid>

								<Grid item xs={12} sm={6}>
									<Box>
										<Typography variant="h6">
											End Trigger:
										</Typography>
										{channel.trigger_end && (
											<>
												{triggerType(
													channel.trigger_end
												)}
											</>
										)}

										{!channel.trigger_end && (
											<Typography variant="h6">
												No End Trigger
											</Typography>
										)}
									</Box>
								</Grid>
							</Grid>

							<Divider />

							<Typography variant="h6">Parameters:</Typography>
							<Grid container>
								{channel.params.map((param, index) => (
									<Grid
										item
										xs={12}
										sm={6}
										md={4}
										xl={3}
										key={index}
									>
										<Box>
											<ListTable>
												<ListRow>
													<ListHeader>
														Name:
													</ListHeader>
													<ListCell>
														{param.name}
													</ListCell>
												</ListRow>
												<ListRow>
													<ListHeader>
														Input:
													</ListHeader>
													<ListCell>
														{param.input}
													</ListCell>
												</ListRow>
												<ListRow>
													<ListHeader>
														Function:
													</ListHeader>
													<ListCell>
														{param.function}
													</ListCell>
												</ListRow>
											</ListTable>
										</Box>
									</Grid>
								))}
							</Grid>

							{editable && (
								<Grid
									container
									direction="row"
									alignItems="center"
								>
									<Grid item>
										<Button
											variant="outlined"
											startIcon={<EditIcon />}
											onClick={handleEdit(index)}
										>
											Edit
										</Button>
									</Grid>

									<Grid item>
										<Button
											variant="outlined"
											startIcon={<DeleteOutlineIcon />}
											onClick={handleDelete(index)}
										>
											Delete
										</Button>
									</Grid>
								</Grid>
							)}
						</Paper>
					))}

					{editable && (
						<Button
							variant="outlined"
							startIcon={<AddCircleIcon />}
							onClick={handleAddChannel()}
						>
							Add Channel
						</Button>
					)}
				</BtTabPanel>

				<BtTabPanel currentTab={currentTab} index={1} key={1}>
					<TextField
						id="outlined-multiline-static"
						multiline
						rows={25}
						variant="outlined"
						fullWidth
						onChange={handleJsonChange}
						value={json}
					/>
				</BtTabPanel>
			</form>

			<ConfigEditChannelDialog
				setOpen={channelEditOpen}
				onClose={handleChannelEditDialogClose}
				channelData={config}
				selectedChannel={editChannel}
				deviceDef={deviceDef}
			/>
		</>
	);
}
