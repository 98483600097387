import {
	ARRAY,
	BOOLEAN,
	DATE,
	NUMBER,
	OBJECT,
	STRING,
	UNDEFINED,
} from '../../../../utils/commonDataTypes';

export const DEFAULT = 'default';
export const MIN_MAX = 'min_max';
export const REQUIRED = 'required';

export const ON_CHANGE = 'onChange';
export const ON_ENTER_OR_BLUR = 'onEnterOrBlur';

export const typeConfigDefaults = {
	[ARRAY]: {
		arrayContent: {
			type: UNDEFINED,
		},
		type: ARRAY,
	},
	[BOOLEAN]: {
		type: BOOLEAN,
	},
	[DATE]: {
		type: DATE,
	},
	[NUMBER]: {
		type: NUMBER,
	},
	[OBJECT]: {
		objectContent: {},
		type: OBJECT,
	},
	[STRING]: {
		type: STRING,
	},
};

export const attributeOrder = ['min', 'max', 'default'];
export const omittedAttributes = [
	'arrayContent',
	'objectContent',
	'required',
	'type',
];
