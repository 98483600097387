import React, { useEffect, useState } from 'react';

import { Typography, Grid, FormGroup, FormControlLabel } from '@mui/material';
import Switch from '@mui/material/Switch';
import { Card, CardHeader, CardContent } from '@mui/material';

function ListCard({ title, listData }) {
	return (
		<Card variant="outlined">
			<CardHeader title={title} />
			<CardContent>
				{listData.map((line, index) => (
					<Grid key={index} container spacing={3} alignItems="center">
						<Grid item xs={4}>
							<Typography variant="h6">{line.key}:</Typography>
						</Grid>
						<Grid item xs={8}>
							<Typography variant="h2">{line.value}</Typography>
						</Grid>
					</Grid>
				))}
			</CardContent>
		</Card>
	);
}

export default function DeviceInputs({
	deviceType,
	inputValues,
	enableRT,
	inRT,
}) {
	const [voltageValues, setVoltageValues] = useState([]);
	const [currentValues, setCurrentValues] = useState([]);
	const [activePowerValues, setActivePowerValues] = useState([]);
	const [reactivePowerValues, setReactivePowerValues] = useState([]);

	useEffect(
		() => {
			if (inputValues) {
				try {
					setVoltageValues([
						{
							key: 'Average',
							value:
								(
									(inputValues.VRMS1 +
										inputValues.VRMS2 +
										inputValues.VRMS3) /
									3 /
									1000
								).toFixed(2) + 'V',
						},
						{
							key: 'V1',
							value: (inputValues.VRMS1 / 1000).toFixed(2) + 'V',
						},
						{
							key: 'V2',
							value: (inputValues.VRMS2 / 1000).toFixed(2) + 'V',
						},
						{
							key: 'V3',
							value: (inputValues.VRMS3 / 1000).toFixed(2) + 'V',
						},
					]);
				} catch (error) {
					console.log('Error with voltage data');
					console.log(error);
				}

				try {
					setCurrentValues([
						{
							key: 'Total',
							value:
								(
									(inputValues.IRMS1 +
										inputValues.IRMS2 +
										inputValues.IRMS3) /
									1000000
								).toFixed(3) + 'A',
						},
						{
							key: 'I1',
							value:
								(inputValues.IRMS1 / 1000000).toFixed(3) + 'A',
						},
						{
							key: 'I2',
							value:
								(inputValues.IRMS2 / 1000000).toFixed(3) + 'A',
						},
						{
							key: 'I3',
							value:
								(inputValues.IRMS3 / 1000000).toFixed(3) + 'A',
						},
					]);
				} catch (error) {
					console.log('Error with current data');
					console.log(error);
				}

				try {
					setActivePowerValues([
						{
							key: 'Total',
							value:
								inputValues.APP_P1 +
								inputValues.APP_P2 +
								inputValues.APP_P3 +
								'W',
						},
						{ key: 'P1', value: inputValues.APP_P1 + 'W' },
						{ key: 'P2', value: inputValues.APP_P2 + 'W' },
						{ key: 'P3', value: inputValues.APP_P3 + 'W' },
					]);
				} catch (error) {
					console.log('Error with active power data');
					console.log(error);
				}

				try {
					setReactivePowerValues([
						{
							key: 'Total',
							value:
								inputValues.ACT_P1 +
								inputValues.ACT_P2 +
								inputValues.ACT_P3 +
								'W',
						},
						{ key: 'P1', value: inputValues.ACT_P1 + 'W' },
						{ key: 'P2', value: inputValues.ACT_P2 + 'W' },
						{ key: 'P3', value: inputValues.ACT_P3 + 'W' },
					]);
				} catch (error) {
					console.log('Error with reactive power data');
					console.log(error);
				}
			}
		},
		[inputValues]
	);

	return (
		<>
			<Grid container spacing={3} alignItems="stretch">
				<Grid item xs={12}>
					<Typography variant="h4">{deviceType}</Typography>
					<FormGroup>
						<FormControlLabel
							control={
								<Switch
									checked={inRT}
									onChange={enableRT}
									name="RT Mode"
								/>
							}
							label={'Enable Realtime Data'}
						/>
					</FormGroup>
				</Grid>

				<Grid item xs={12} md={6} lg={3}>
					<ListCard title="Voltage" listData={voltageValues} />
				</Grid>

				<Grid item xs={12} md={6} lg={3}>
					<ListCard title="Current" listData={currentValues} />
				</Grid>

				<Grid item xs={12} md={6} lg={3}>
					<ListCard
						title="Active Power"
						listData={activePowerValues}
					/>
				</Grid>

				<Grid item xs={12} md={6} lg={3}>
					<ListCard
						title="Reactive Power"
						listData={reactivePowerValues}
					/>
				</Grid>
			</Grid>
		</>
	);
}
