import React, { useEffect, useMemo } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import BtSelectionTable from '../../../../components/generic/BtSelectionTable';
import { useTheme } from '@mui/styles';

export default function TableComponent({
	data,
	visOptions,
	dataSchema,
	id,
	editable,
	removeItem,
}) {
	const THEME = useTheme();

	const FormattedCell = ({ field, data }) => {
		switch (dataSchema.objectContent[field].type) {
			case 'string':
				return <Typography>{data}</Typography>;
			case 'number':
				return <Typography>{data}</Typography>;
			case 'date':
				return <Typography>{data}</Typography>;
			case 'boolean':
				return <Typography>{data ? 'True' : 'False'}</Typography>;
			default:
				return <Typography>{data}</Typography>;
		}
	};

	// useEffect(
	// 	() => {
	// 		console.log(data);
	// 		console.log(visOptions);
	// 	},
	// 	[data, visOptions]
	// );

	// const columns = [{ field: 'FullName', text: 'Full Name' }];

	const columns = useMemo(
		() => {
			const visableParams = (visOptions?.params || []).filter(
				param => !param.hidden === true
			);

			return visableParams.map(param => {
				return { field: param.value, text: param.label };
			});
		},
		[visOptions]
	);

	return (
		<div
			style={{
				width: '100%',
				height: '100%',
			}}
		>
			{visOptions?.params && (
				<BtSelectionTable
					columns={columns}
					data={data}
					// enableGeneralSorting
					enablePagination
					idField="uuid"
					// enableSearching
					stickyHeader
					paginationBackgroundColor={THEME.palette.background.paper}
				/>
			)}
		</div>
	);

	// return (
	// 	<div
	// 		style={{
	// 			width: '100%',
	// 			height: '100%',
	// 		}}
	// 	>
	// 		{visOptions?.params && (
	// 			<TableContainer component={Box}>
	// 				<Table sx={{ minWidth: 650 }} aria-label="simple table">
	// 					<TableHead>
	// 						<TableRow>
	// 							{(visOptions.params || []).map(param => (
	// 								<TableCell key={param.label}>
	// 									{param.label}
	// 								</TableCell>
	// 							))}
	// 						</TableRow>
	// 					</TableHead>
	// 					<TableBody>
	// 						{data.map((dataRow, index) => (
	// 							<TableRow
	// 								key={index}
	// 								sx={{
	// 									'&:last-child td, &:last-child th': {
	// 										border: 0,
	// 									},
	// 								}}
	// 							>
	// 								{(visOptions.params || []).map(
	// 									(param, index) => (
	// 										<TableCell key={index}>
	// 											{dataRow[param.value]}
	// 											{/* <FormattedCell
	// 												field={param.value}
	// 												data={dataRow[param.value]}
	// 											/> */}
	// 										</TableCell>
	// 									)
	// 								)}
	// 							</TableRow>
	// 						))}
	// 					</TableBody>
	// 				</Table>
	// 			</TableContainer>
	// 		)}
	// 	</div>
	// );
}
