const { OPERATION_MODE } = require('../../expression');
const { setOperatorSchemaFn } = require('../expression');
const { inferSchema } = require('../utils');

const operatorKey = '$literal';

setOperatorSchemaFn(
	operatorKey,
	(context, args, options) => {
		return inferSchema(args);
	},
	[OPERATION_MODE.AGGREGATE]
);
