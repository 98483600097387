import React, { useEffect, useState } from 'react';

import { channelDataGet } from '../../API/IoT/channelData.api';

import { Grid, Box, Paper, CircularProgress, Typography } from '@mui/material';
import { Card, CardHeader, CardContent } from '@mui/material';

import { Button, IconButton } from '@mui/material';

import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Chart from '../../components/generic/Chart';
import ReportCard from '../../components/DeviceComponents/ReportCard';

import { useSubscribe } from '../../context/SocketContext/SocketContext';
import BtLoading from '../generic/BtLoading';

function ChannelDataView({
	deviceId,
	channelName,
	data,
	params,
	startTime,
	endTime,
}) {
	const [channelData, setChannelData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const { message } = useSubscribe(
		`SVR/${deviceId}/channel_data/${channelName}`
	);
	useEffect(
		() => {
			if (message) {
				const packet = JSON.parse(message);
				//console.log('packet', packet);
				var newChannelData = [...channelData, packet];
				if (newChannelData.length > 100) {
					newChannelData.splice(0, newChannelData.length - 100);
				}
				setChannelData(newChannelData);
			}
		},
		[message]
	);

	useEffect(
		() => {
			const onLoadData = async () => {
				setIsLoading(true);

				try {
					var newChannelData = await channelDataGet({
						deviceId: deviceId,
						channelName: channelName,
						startTime: startTime,
						endTime: endTime,
					});

					console.log('newChannelData', newChannelData);
					if (newChannelData.length > 100) {
						newChannelData.splice(0, newChannelData.length - 100);
					}
					setChannelData(newChannelData);
				} catch (error) {
					console.log(error);
				}

				setIsLoading(false);
			};
			console.log('channelName', channelName);
			if (deviceId && channelName) {
				onLoadData();
			}
		},
		[deviceId, channelName, startTime, endTime]
	);
	return (
		<Card variant="outlined">
			<CardHeader title={'Channel: ' + channelName} />
			<CardContent>
				<BtLoading loading={isLoading}>
					<Grid container alignItems="stretch" spacing={3}>
						<Grid item sm={9}>
							{
								<Chart
									data={channelData}
									param_names={params}
									xaxis_name="start_time"
								/>
							}
						</Grid>
						<Grid item sm={3}>
							<Box
								display="flex"
								flexDirection="column"
								style={{
									height: '100%',
								}}
							>
								{params.map((param, index) => (
									<ReportCard
										data={channelData}
										param_name={param}
										style={{
											width: '100%',
										}}
										key={index}
									/>
								))}
							</Box>
						</Grid>
					</Grid>
				</BtLoading>
			</CardContent>
		</Card>
	);
}

const period = 60 * 60 * 24 * 1000;

export default function ChannelDataCollection({ deviceId, channelConfig }) {
	const [startTime, setStartTime] = useState(Date.now() - period);
	const [endTime, setEndTime] = useState(Date.now());

	const handleForwardClick = () => {
		setStartTime(startTime + period);
		setEndTime(endTime + period);
	};

	const handleBackClick = () => {
		setStartTime(startTime - period);
		setEndTime(endTime - period);
	};

	return (
		<Grid container alignItems="stretch" spacing={3}>
			<Grid item sm={12}>
				<IconButton size="small" onClick={handleBackClick}>
					<ArrowBackIcon />
				</IconButton>
				<Typography style={{ display: 'inline-block' }}>
					{new Date(startTime).toLocaleString()} -{' '}
					{new Date(endTime).toLocaleString()}
				</Typography>

				<IconButton size="small" onClick={handleForwardClick}>
					<ArrowForwardIcon />
				</IconButton>
				{/* <IconButton size="small" onClick={onDataRefresh}>
							<RefreshIcon />
						</IconButton> */}
			</Grid>
			{channelConfig.map((channel, index) => (
				<Grid item sm={12} key={index}>
					<ChannelDataView
						deviceId={deviceId}
						channelName={channel.name}
						key={index}
						params={channel.params}
						startTime={startTime}
						endTime={endTime}
					/>
				</Grid>
			))}
		</Grid>
	);
}
