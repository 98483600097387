import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
	Avatar,
	Button,
	ButtonBase,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	styled,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import Color from 'color';
import { Refresh } from '@mui/icons-material';

import BtImageDialog, {
	ImageSource,
} from '../../../../components/generic/BtImageDialog';
import BtLoading from '../../../../components/generic/BtLoading';
import BtNoItems from '../../../../components/generic/BtNoItems';
import { formatFormatter } from './utils';

const ImageAvatar = styled(Avatar)(({ theme }) => ({
	borderRadius: 4,
	boxShadow: `0 0 0 1px ${Color(theme.palette.text.solid)
		.alpha(0.1)
		.toString()}`,
	height: 46,
	cursor: 'pointer',
	width: 46,
}));

const DetailContainer = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
	marginTop: '-0.2em',
}));

const FormatLink = styled(ButtonBase)(({ theme }) => ({
	backgroundColor: Color(theme.palette.indicators.info.main)
		.alpha(0.15)
		.toString(),
	borderRadius: 4,
	color: theme.palette.indicators.info.text,
	cursor: 'pointer',
	padding: '2px 6px',
	margin: '0 0.5em 0.5em 0',
	textTransform: 'capitalize',

	'&:last-of-type': {
		marginRight: '0',
	},
}));

export default function ImageRepoImageList({
	images,
	loading,
	onRefresh,
	onDeleteImage,
	repo,
}) {
	const [formatUuid, setFormatUuid] = useState();
	const [selectedImage, setSelectedImage] = useState();
	const [showImage, setShowImage] = useState(false);

	const onDeleteClick = uuid => {
		console.log('Delete', uuid);
		console.log(repo);
	};

	if (loading || !repo) {
		return <BtLoading />;
	}

	return (
		<>
			{(images || []).map(image => {
				const { filename, thumbnail, uuid: imageUuid } = image;

				return (
					<List key={imageUuid} style={{ padding: 0 }}>
						<ListItem disableGutters style={{ padding: '1em 0' }}>
							<ListItemAvatar>
								<ImageAvatar
									alt={filename}
									onClick={() => {
										setFormatUuid(null);
										setSelectedImage(image);
										setShowImage(true);
									}}
									src={thumbnail}
								/>
							</ListItemAvatar>
							<DetailContainer>
								<ListItemText primary={filename} />
								<ListItemText
									secondary={
										<>
											{repo.formats.map(format => {
												const { name, uuid } = format;

												return (
													<FormatLink
														key={uuid}
														focusRipple
														onClick={() => {
															setFormatUuid(uuid);
															setSelectedImage(
																image
															);
															setShowImage(true);
														}}
													>
														{formatFormatter(name)}
													</FormatLink>
												);
											})}
										</>
									}
									style={{ margin: 0 }}
								/>
							</DetailContainer>
							<IconButton
								onClick={() => {
									onDeleteClick(imageUuid);
								}}
								size="small"
							>
								<DeleteIcon />
							</IconButton>
						</ListItem>
						<Divider />
					</List>
				);
			})}
			{(images || []).length === 0 && (
				<div
					style={{
						alignItems: 'center',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<BtNoItems subject="images" />
					<Button onClick={onRefresh} startIcon={<Refresh />}>
						Refresh
					</Button>
				</div>
			)}
			<BtImageDialog
				formatUuid={formatUuid}
				image={selectedImage}
				onClose={() => setShowImage(false)}
				open={showImage}
				imageRepoUuid={repo?.uuid}
				imageSource={ImageSource.IMAGE_REPO}
				imageUuid={selectedImage?.uuid}
			/>
		</>
	);
}

ImageRepoImageList.propTypes = {
	images: PropTypes.arrayOf(PropTypes.object),
	loading: PropTypes.bool,
	onRefresh: PropTypes.func,
	onDeleteImage: PropTypes.func,
	repo: PropTypes.object,
};
