import React from 'react';
import * as yup from 'yup';

import BtQueryBuilderPropertyFormField from '../BtQueryBuilderPropertyField';
import { baseStagePropTypes } from './base-prop-type';
import { determineValueType, replaceDynamicTypedValue } from './utils';

const stageType = '$replaceRoot';

const formSchema = yup.object().shape({
	newRoot_type: yup.string().oneOf(['field-path', 'expression']),
	newRoot: yup.mixed().when(['newRoot_type'], {
		is: newRoot_type => newRoot_type === 'field-path',
		then: yup.string().required(),
		else: yup.object().required(),
	}),
});

function buildFormValues(queryStage, flatConfigSchema, variables) {
	let newRoot =
		(typeof queryStage[stageType] === 'string'
			? queryStage[stageType]
			: queryStage[stageType]?.newRoot) || null;

	let newRootType = 'field-path';

	if (newRoot !== null) {
		if (typeof newRoot === 'string') {
			[newRootType, newRoot] = determineValueType(
				newRoot,
				flatConfigSchema,
				false,
				variables
			);
		} else {
			newRoot = JSON.stringify(newRoot);
			newRootType = 'expression';
		}
	}

	return {
		newRoot: newRoot || null,
		newRoot_type: newRootType,
	};
}

function buildQueryStage(fields, variables) {
	return {
		[stageType]: {
			newRoot: replaceDynamicTypedValue('newRoot', fields) ?? null,
		},
	};
}

function ReplaceRootFormContent(props) {
	const { readOnly, disabled } = props;

	return (
		<BtQueryBuilderPropertyFormField
			name="newRoot"
			allowedConfigSchemaTypes={['object']}
			label="New Root"
			isRequired
			disabled={disabled}
			allowedFieldTypes={['field-path', 'expression']}
			readOnly={readOnly}
		/>
	);
}

export default {
	formSchema: formSchema,
	FormContent: ReplaceRootFormContent,
	buildQueryStage: buildQueryStage,
	buildFormValues: buildFormValues,
};

// BtQueryBuilderStageReplaceRoot.propTypes = baseStagePropTypes;

ReplaceRootFormContent.propTypes = baseStagePropTypes;
