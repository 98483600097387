import React from 'react';
import UsersIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import SubscriptionsIcon from '@mui/icons-material/EventRepeat';
//import BillingIcon from '@mui/icons-material/AttachMoney';
import RolesIcon from '@mui/icons-material/Category';
import TagsIcon from '@mui/icons-material/LocalOffer';
import { CardBulletedOutline } from 'mdi-material-ui';
//import DocumentsIcon from '@mui/icons-material/Article';

const admin_options = [
	{
		id: 'General Information',
		description:
			"View and update your organisation's information, contact details and regional settings.",
		text: 'General',
		route: '/GeneralInfo',
		icon: <BusinessIcon />,
		requiredPermissionsAll: {
			orgAdmin: ['OrgDetailsGet'],
		},
	},
	{
		id: 'Subscriptions',
		description: "View your organisations's subscriptions.",
		text: 'Subscriptions',
		route: '/Subscriptions',
		icon: <SubscriptionsIcon />,
		requiredPermissionsAll: {
			orgAdmin: ['SubscriptionList'],
		},
	},
	/*{
		id: 'Billing',
		description:
			'View invoices and credit notes applied to your organisation.',
		text: 'Billing',
		route: '/Billing',
		icon: <BillingIcon />,
	},*/
	{
		id: 'Roles',
		description: 'View and configure user roles.',
		text: 'Roles',
		route: '/Roles',
		icon: <RolesIcon />,
		requiredPermissionsAll: {
			orgAdmin: ['RolesList'],
		},
	},
	{
		id: 'Users',
		description:
			'Invite new users, or view and edit existing user details.',
		text: 'Users',
		route: '/Users',
		icon: <UsersIcon />,
		requiredPermissionsAll: {
			orgAdmin: ['UserList'],
		},
	},
	{
		id: 'Tags',
		description: 'View and edit tag categories and values.',
		text: 'Tags',
		route: '/Tags',
		icon: <TagsIcon />,
		requiredPermissionsAll: {
			orgAdmin: ['TagGroupEdit', 'TagValueEdit'],
		},
	},
	{
		id: 'Screens',
		description: 'View and configure organisation screens and tiles.',
		text: 'Screens',
		route: '/Screens',
		icon: <CardBulletedOutline />,
		requiredPermissionsAll: {
			screens: [
				'ScreenAdminView',
				// 'ScreenAdminCreate',
				// 'ScreenAdminEdit',
				// 'ScreenAdminDistribution',
			],
		},
	},
	/*{
		id: 'Documents',
		description: 'View and download copies of documents.',
		text: 'Documents',
		route: '/Documents',
		icon: <DocumentsIcon />,
	},*/
];

export default admin_options;
