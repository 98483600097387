import { Button, styled } from '@mui/material';

export const WorkflowActionButton = styled(Button)(({ theme }) => ({
	padding: '0.5em 2em',
	margin: '1em',

	[theme.breakpoints.down('sm')]: {
		width: '100%',
	},
}));

export default WorkflowActionButton;
