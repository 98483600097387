import React, { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
	Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import BtDetailSection from '../../../../components/generic/BtDetailSection';
import BtMessage from '../../../../components/generic/BtMessage';
import { PermWizardContext } from './PermWizardContext';
import produce from 'immer';
import {
	useWizard,
	WizardButtons,
	WizardHeading,
} from '../../../../components/wizard';

export default function PermissionConfirmation({
	ownerId: ownerUuid,
	setOwner,
	editPermission,
	addPermission,
}) {
	const { enqueueSnackbar } = useSnackbar();
	const { goToStep, isFirstStep, isLastStep, previousStep } = useWizard();

	const { data, onClose, permToEdit } = useContext(PermWizardContext);

	const [sending, setSending] = useState(false);

	const isEdit = useMemo(() => permToEdit || data.editId, [permToEdit, data]);
	const shouldHaveResources = useMemo(() => !!data?.method?.resourceGroup, [
		data,
	]);

	const nothingChanged = useMemo(
		() => {
			if (permToEdit && !shouldHaveResources) {
				return true;
			}

			const originalSelectAll =
				permToEdit?.resources?.includes('*') ||
				data?.editResourceIds?.includes('*');

			if (
				originalSelectAll !== null &&
				originalSelectAll !== !!data?.selectAllResources
			) {
				return false;
			}

			const originalResources =
				data?.editResourceIds || permToEdit?.resources || [];
			const newResources =
				(data?.resources || []).map(({ id }) => id) || [];

			if (originalResources.length !== newResources.length) {
				return false;
			}

			return originalResources.every(resource => {
				if (newResources.includes(resource)) {
					return true;
				}

				return false;
			});
		},
		[data, permToEdit, shouldHaveResources]
	);

	const title = useMemo(
		() => {
			if (nothingChanged) {
				return 'Summary';
			}
			return isEdit
				? 'Confirm permission update'
				: 'Confirm new permission';
		},
		[isEdit, nothingChanged]
	);

	const handleRequest = useCallback(
		async () => {
			setSending(true);

			const composeResources = () => {
				if (data.selectAllResources) {
					return ['*'];
				}
				return (data?.resources || []).map(({ id }) => id);
			};

			const newPermission = {
				group: data.group,
				method: data.method.name,
				resources: shouldHaveResources ? composeResources() : null,
			};

			try {
				if (isEdit) {
					const id = permToEdit?.uuid || data.editId;

					await editPermission({
						ownerUuid,
						permissionUuid: id,
						newPermission,
					});

					enqueueSnackbar('Permission updated', {
						variant: 'success',
					});

					setOwner(
						produce(draft => {
							const index = (draft.permissions || []).findIndex(
								permission => permission.uuid === id
							);
							if (index !== -1) {
								draft.permissions[
									index
								].resources = newPermission.resources || ['*'];
								draft.modify_timestamp = Date.now();
							}
						})
					);
				} else {
					const response = await addPermission({
						ownerUuid,
						newPermission,
					});
					const { uuid } = response;

					const newStatePermission = {
						...newPermission,
						uuid,
						...(!shouldHaveResources ? { resources: ['*'] } : {}),
					};

					enqueueSnackbar('New permission added', {
						variant: 'success',
					});

					setOwner(
						produce(draft => {
							draft.permissions = [
								...draft.permissions,
								newStatePermission,
							];
							draft.modify_timestamp = Date.now();
						})
					);
				}

				onClose();
			} catch (error) {
				enqueueSnackbar(
					`Failed to ${isEdit ? 'update' : 'add'} permission`,
					{
						variant: 'error',
					}
				);
				console.error(error);
			} finally {
				setSending(false);
			}
		},
		[
			addPermission,
			data,
			editPermission,
			enqueueSnackbar,
			isEdit,
			onClose,
			permToEdit,
			ownerUuid,
			setOwner,
			shouldHaveResources,
		]
	);

	return (
		<>
			{sending && (
				<LinearProgress
					style={{ position: 'absolute', width: '100%', top: 0 }}
				/>
			)}
			<DialogTitle>
				<WizardHeading title={title} onCancelClick={onClose} />
			</DialogTitle>
			<DialogContent>
				{(nothingChanged || !shouldHaveResources) && (
					<BtMessage
						message={
							shouldHaveResources
								? 'You did not make any changes.'
								: 'There are no applicable resources for this permission'
						}
						style={{ marginBottom: '2em' }}
					/>
				)}
				<Typography style={{ margin: '0.8em 0' }}>
					Permission Summary:
				</Typography>
				<BtDetailSection
					label="Name"
					data={data?.method?.name || 'Unknown Name'}
				/>
				<BtDetailSection label="Group" data={data?.group} />
				{!!data?.method?.resourceGroup && (
					<BtDetailSection
						label="Resources"
						chips
						data={
							data?.selectAllResources
								? 'All Available'
								: (data?.resources || [])
										.map(({ name }) => name)
										.sort()
						}
					/>
				)}
			</DialogContent>
			<DialogActions>
				<WizardButtons
					nextLabel={nothingChanged ? 'Done' : 'Confirm'}
					canGoForward={!!data}
					hideCancel={!!permToEdit && !shouldHaveResources}
					hidePrevious={!!permToEdit && !shouldHaveResources}
					isFirstStep={isFirstStep}
					isLastStep={isLastStep}
					onCancelClick={onClose}
					onPreviousClick={() => {
						if (shouldHaveResources) {
							previousStep();
						} else {
							goToStep(0);
						}
					}}
					onNextClick={() => {
						if (nothingChanged) {
							onClose();
						} else {
							handleRequest();
						}
					}}
					sending={sending}
				/>
			</DialogActions>
		</>
	);
}

PermissionConfirmation.propTypes = {
	roleId: PropTypes.string,
	setOwner: PropTypes.func.isRequired,
};
