import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import Color from 'color';
import { Tooltip } from '@mui/material';

const Container = styled('div')(() => ({
	alignItems: 'center',
	display: 'flex',
	height: '100%',
}));

const DeleteButton = styled('button')(({ theme }) => ({
	alignItems: 'center',
	backgroundColor: theme.palette.indicators.error.main,
	border: 'none',
	borderRadius: 9,
	color: '#ffffff',
	display: 'flex',
	fontWeight: 'bold',
	height: 18,
	marginLeft: 5,
	opacity: '0',
	padding: '0 8px',
	pointerEvents: 'none',
	transform: 'translateX(-5px)',

	transition: theme.transitions.create(
		['backgroundColor', 'opacity', 'transform'],
		{
			duration: 100,
		}
	),

	'&.visible': {
		opacity: '1',
		pointerEvents: 'all',
		transform: 'translateX(0px)',
	},

	'&.visible:hover': {
		backgroundColor: Color(theme.palette.indicators.error.main)
			.darken(0.1)
			.toString(),
	},

	'&.visible:active': {
		backgroundColor: Color(theme.palette.indicators.error.main)
			.darken(0.15)
			.toString(),
	},
}));

const ToggleButton = styled('button')(({ theme }) => ({
	backgroundColor: theme.palette.indicators.error.main,
	border: 'none',
	borderRadius: 9,
	height: 18,
	padding: 0,
	transform: 'rotate(0deg)',
	width: 18,

	transition: theme.transitions.create(['backgroundColor', 'transform'], {
		duration: 100,
	}),

	'&:hover': {
		backgroundColor: Color(theme.palette.indicators.error.main)
			.darken(0.1)
			.toString(),
	},

	'&:active': {
		backgroundColor: Color(theme.palette.indicators.error.main)
			.darken(0.15)
			.toString(),
	},

	'&.dangerous': {
		backgroundColor: Color(theme.palette.text.solid)
			.fade(0.9)
			.toString(),
		transform: 'rotate(45deg)',
	},

	'&.dangerous:hover': {
		backgroundColor: Color(theme.palette.text.solid)
			.fade(0.85)
			.toString(),
	},

	'&.dangerous:active': {
		backgroundColor: Color(theme.palette.text.solid)
			.fade(0.8)
			.toString(),
	},

	'& line.primaryLine': {
		strokeWidth: 2,
	},

	'&.dangerous line.primaryLine': {
		stroke: Color(theme.palette.text.solid)
			.fade(0.1)
			.toString(),
		strokeWidth: 1,
	},

	'& line.secondaryLine': {
		opacity: 0,
		transform: 'scale(0)',
	},

	'&.dangerous line.secondaryLine': {
		opacity: 1,
		stroke: Color(theme.palette.text.solid)
			.fade(0.1)
			.toString(),
		strokeWidth: 1,
		transform: 'scale(1)',
	},
}));

export default function DeleteItem({ onDelete }) {
	const [dangerous, setDangerous] = useState(false);

	return (
		<Container>
			<Tooltip disableInteractive title={dangerous ? 'Cancel' : 'Delete'}>
				<Container>
					<ToggleButton
						className={dangerous ? 'dangerous' : ''}
						onClick={event => {
							if (dangerous) {
								event.currentTarget.blur();
							}

							setDangerous(prev => !prev);
						}}
					>
						<span>
							<svg height="18" width="18">
								<line
									className="primaryLine"
									x1="2"
									y1="9"
									x2="16"
									y2="9"
									stroke="#ffffff"
								/>
								<line
									className="secondaryLine"
									x1="9"
									y1="2"
									x2="9"
									y2="16"
									stroke="#ffffff"
								/>
							</svg>
						</span>
					</ToggleButton>
				</Container>
			</Tooltip>
			<DeleteButton
				className={dangerous ? 'visible' : ''}
				disabled={!dangerous}
				onClick={onDelete}
			>
				<span>Delete</span>
			</DeleteButton>
		</Container>
	);
}

DeleteItem.propTypes = {
	onDelete: PropTypes.func.isRequired,
};
