import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import BtSlider from '../../../BtSlider';
import CategoryAutocomplete from '../CategoryAutocomplete';

const FilterInput = ({
	disabled,
	editMode,
	filter,
	filters,
	setFilter,
	setFilters,
}) => {
	const handleCategoricalChange = useCallback(
		newValue => {
			// console.log({ newValue });
			if (editMode) {
				setFilters(
					filters.map(item => {
						if (item.name === filter.name) {
							return {
								...item,
								filterValue: newValue,
							};
						}

						return item;
					})
				);
				return;
			}
			setFilter({
				...filter,
				// value: values,
				filterValue: newValue,
				// value: newValue,
			});
		},
		[editMode, filter, filters, setFilter, setFilters]
	);

	const handleSingleValueChange = useCallback(
		event => {
			if (editMode) {
				setFilters(
					filters.map(item => {
						if (item.name === filter.name) {
							return {
								...item,
								filterValue: !item.singleValue
									? filter.field.categories.slice(0, 1)
									: [],
								singleValue: !item.singleValue,
								// value: values,
							};
						}
						// console.log({ item });
						return item;
					})
				);

				return;
			}

			setFilter(prev => ({
				...prev,

				filterValue: !prev.singleValue
					? filter.field.categories.slice(0, 1)
					: [],
				singleValue: !prev.singleValue,
				// value: values,
			}));
		},
		[editMode, filter, filters, setFilter, setFilters]
	);

	// Handle changes to slider values
	// If the filter is new the values are held in separate filter object until 'apply' button is clicked
	// after which the vales are added the filters array.
	// If the filter is not new then the filters array is updated on change
	const onRangeChange = useCallback(
		newValue => {
			// console.log({ newValue });
			if (editMode) {
				const filtersUpdate = filters.map(item => {
					if (item.name === filter.name) {
						return {
							...item,
							filterValue: newValue,
						};
					}

					return item;
				});

				setFilters([...filtersUpdate]);

				return;
			}

			setFilter({
				...filter,
				filterValue: newValue,
			});
		},
		[editMode, filter, filters, setFilter, setFilters]
	);

	// Handle changes to a boolean switch
	// If the filter is new the values are held in separate filter object until 'apply' button is clicked
	// after which the vales are added the filters array.
	// If the filter is not new then the filters array is updated on change
	const handleSwitchChange = useCallback(
		event => {
			const newValue = event.target.checked;

			if (editMode) {
				const filtersUpdate = filters.map(item => {
					if (item.name === filter.name) {
						return {
							...item,
							filterValue: newValue,
						};
					}
					return item;
				});

				setFilters([...filtersUpdate]);

				return;
			}

			setFilter({
				...filter,
				filterValue: newValue,
			});
		},
		[editMode, filter, filters, setFilter, setFilters]
	);

	// Used to format the screen reader text of the slider value
	function valueText(value) {
		return `${filter.value}${filter.field.name}`;
	}

	if (filter.field.type === 'categorical') {
		return (
			<CategoryAutocomplete
				singleValue={filter.singleValue}
				handleSingleValueChange={handleSingleValueChange}
				onChange={handleCategoricalChange}
				value={
					filter.singleValue
						? filter.filterValue[0]
						: filter.filterValue
				}
				options={filter.field.categories || []}
				singleValueRequired={filter.required}
			/>
		);
	} else if (filter.field.type === 'quantitative') {
		return (
			<BtSlider
				// marks={marks()}
				getAriaValueText={() => valueText()}
				disabled={disabled}
				min={filter.field.range.min}
				max={filter.field.range.max}
				onChange={onRangeChange}
				value={filter.filterValue}
				// unit="unit"
				textInput
			/>
		);
	} else if (filter.field.type === 'boolean') {
		return (
			<Box sx={{ margin: '0px 24px' }}>
				<FormControlLabel
					control={
						<Switch
							checked={filter.filterValue}
							onChange={handleSwitchChange}
							disabled={disabled}
						/>
					}
					label={filter.field.label}
				/>
			</Box>
		);
	} else {
		return (
			<Typography>There are no filters for this data type.</Typography>
		);
	}
};

FilterInput.propTypes = {
	disabled: PropTypes.bool,
	editMode: PropTypes.bool,
	filter: PropTypes.object,
	filters: PropTypes.arrayOf(PropTypes.object),
	setFilter: PropTypes.func,
	setFilters: PropTypes.func,
};

export default FilterInput;
