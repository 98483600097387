import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/styles';
import { Typography } from '@mui/material';

import BtList from './BtList';

const Header = styled('div')(() => ({
	alignItems: 'center',
	display: 'flex',
}));

const ColourIndicator = styled('div')(({ color }) => ({
	borderRadius: 8,
	backgroundColor: color,
	height: 16,
	marginRight: '0.5em',
	width: 16,
}));

export default function BtResultGroup({
	colour,
	idKey,
	loading,
	onItemClick,
	primaryField,
	results,
	title,
}) {
	return (
		<div style={{ marginTop: '2em', opacity: loading ? 0.7 : 1.0 }}>
			<Header>
				{colour && <ColourIndicator color={colour} />}
				<Typography variant="subtitle2" fontWeight="bold">
					{title}
				</Typography>
			</Header>
			<BtList
				idKey={idKey}
				items={results}
				onItemClick={onItemClick}
				primaryField={primaryField}
				disabled={loading}
			/>
		</div>
	);
}

BtResultGroup.propTypes = {
	colour: PropTypes.string,
	idKey: PropTypes.string,
	loading: PropTypes.bool,
	onItemClick: PropTypes.func.isRequired,
	primaryKey: PropTypes.string,
	results: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.string, PropTypes.object])
	),
	title: PropTypes.string.isRequired,
};
