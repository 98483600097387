import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import AddIcon from '@mui/icons-material/AddCircle';
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Prompt } from 'react-router';
import { useHistory } from 'react-router-dom';

import ActionButtonContainer from '../../../../../components/generic/ActionButtonContainer';
import BtConfirmDialog from '../../../../../components/generic/BtConfirmDialog';
import BtError from '../../../../../components/generic/BtError';
import { BtFormContainer } from '../../../../../components/generic/forms';
import BtLoading from '../../../../../components/generic/BtLoading';
import data_manager_breadcrumbs from '../../../DataManagerBreadcrumbs';
import data_manager_nav_item from '../../../DataManagerNavItem';
import data_manager_options from '../../../DataManagerOptions';
import { DataSetEditorContextProvider } from '../../../contexts/DataSetEditorContext';
import { dataSetGet } from '../../../../../API';
import elementGenerator from '../../../utils/elementGenerator';
import NewDataSetRecordEditor from './NewDataSetRecordEditor';
import { parseSchema } from '../../../../../utils/yup-ast';
import useFetch from '../../../../../hooks/useFetch';
import { useNavContext } from '../../../../../context/ContextManager';
import { withDataSetContext } from '../../../hocs/withDataSetContext';

export const NewDataSetRecord = withDataSetContext(({ match }) => {
	const contextRef = useRef();

	const dataSetUuid = match.params.uuid;

	const [blockLeave, setBlockLeave] = useState(true);

	const history = useHistory();
	const { setBreadcrumbs, setContextualNav } = useNavContext();

	const [dataSet, setDataSet] = useState();
	const [schema, setSchema] = useState();
	const [showDiscardModal, setShowDiscardModal] = useState(false);

	const { error: dataSetError, loading, request: dataSetRequest } = useFetch(
		dataSetGet,
		response => {
			setDataSet(response);
			setSchema(parseSchema(response.schema));
		}
	);

	const backLink = useMemo(() => `/DataSources/DataSet/${dataSet?.uuid}`, [
		dataSet,
	]);

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				...data_manager_breadcrumbs,
				{ text: 'Data Sources', link: '/DataSources' },
				{
					text: `${dataSet?.name}`,
					link: backLink,
				},
				{ text: 'Create New', link: '' },
			]);
		},
		[backLink, dataSet, setBreadcrumbs]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([
				...data_manager_nav_item,
				...data_manager_options,
			]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	useEffect(
		() => {
			if (!dataSet && dataSetUuid) {
				dataSetRequest({ dataSetUuid });
			}
		},
		[dataSet, dataSetRequest, dataSetUuid]
	);

	if (loading) {
		return <BtLoading />;
	}

	if (dataSetError) {
		return (
			<BtError
				title="Retrieval Error"
				description="An error occurred when attempting to retrieve the Data Set"
			/>
		);
	}

	const data = schema ? elementGenerator(schema) : {};

	return (
		<>
			<BtFormContainer
				title="Create New Record"
				subtitle={dataSet?.name}
				ActionButtons={
					<ActionButtonContainer style={{ padding: 0 }}>
						<Button
							color="error"
							disableElevation
							onClick={() => setShowDiscardModal(true)}
							startIcon={<DeleteIcon />}
						>
							Discard
						</Button>
						<Button
							disableElevation
							onClick={() => {
								setBlockLeave(false);

								contextRef.current.createRecord({
									onSuccess: () => history.push(backLink),
									onFailure: () => setBlockLeave(true),
								});
							}}
							startIcon={<AddIcon />}
							variant="contained"
						>
							Create
						</Button>
					</ActionButtonContainer>
				}
			>
				<DataSetEditorContextProvider
					ref={contextRef}
					data={{ values: data }}
					dataSetUuid={dataSetUuid}
					editOnly
					schema={schema}
				>
					<NewDataSetRecordEditor />
				</DataSetEditorContextProvider>
			</BtFormContainer>
			<BtConfirmDialog
				action={() => {
					setBlockLeave(false);
					history.push(backLink);
				}}
				ActionIcon={<DeleteIcon />}
				isDestructive
				prompt="Are you sure you want to discard this record creation? This cannot be undone."
				onClose={() => setShowDiscardModal(false)}
				open={showDiscardModal}
				title="Discard Record Creation"
				verb="Discard"
			/>
			<Prompt
				message={'Leaving this page will discard the record creation.'}
				when={blockLeave && !showDiscardModal}
			/>
		</>
	);
});

NewDataSetRecord.propTypes = {
	match: PropTypes.object.isRequired,
};

export default NewDataSetRecord;
