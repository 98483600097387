import React from 'react';
import { useFormContext } from 'react-hook-form';

export const withFormContextMethods = Element => props => {
	const methods = useFormContext() || { formState: {} };

	return <Element {...methods} {...props} />;
};

export default withFormContextMethods;
