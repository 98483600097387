import React, {
	Fragment,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';

import { Checkbox, Collapse, FormControlLabel, TextField } from '@mui/material';
import { isNil } from 'lodash';

import CollapseContent from './CollapseContent';
import { DEFAULT, MIN_MAX, REQUIRED } from '../utils/constants';
import { NUMBER } from '../../../../utils/commonDataTypes';
import { useDataConfigContext } from '../DataConfigContext';
import useGetSelectionData from '../hooks/useGetSelectionData';

const MAX = 'max',
	MIN = 'min';

export default function ValidationInput({ validationOptions }) {
	const { disabled, historyUuid, updateConfig } = useDataConfigContext();
	const { config, location } = useGetSelectionData();

	const cancelling = useRef(false);
	const maxRef = useRef(config?.max?.value);
	const minRef = useRef(config?.min?.value);

	const [_max, setMax] = useState(config?.max?.value);
	const [_min, setMin] = useState(config?.min?.value);

	useEffect(
		() => {
			const max = config?.max?.value;
			const min = config?.min?.value;

			setMax(max);
			maxRef.current = max;

			setMin(min);
			minRef.current = min;
		},
		[config]
	);

	const rangeNormaliser = useCallback(
		value => (config.type === NUMBER ? +value : Math.round(+value)),
		[config]
	);

	const options = useMemo(
		() =>
			[
				{
					key: 'required',
					label: 'Required',
					optionType: REQUIRED,
				},
				{
					key: MIN,
					counterParts: [MAX],
					fieldType: 'number',
					initialValue: Math.min(1, maxRef.current ?? 1),
					label: 'Minimum',
					normaliser: rangeNormaliser,
					optionType: MIN_MAX,
					refValue: minRef,
					setter: setMin,
					validator: () => {
						if (
							!isNil(maxRef.current) &&
							minRef.current > maxRef.current
						)
							return 'Min must be less than or equal to max';

						if (minRef.current <= 0 && config.type !== NUMBER)
							return 'Minimum must be greater than 0';
					},
					value: _min,
				},
				{
					key: MAX,
					counterParts: [MIN],
					fieldType: 'number',
					initialValue: Math.max(1, _min ?? 1),
					label: 'Maximum',
					normaliser: rangeNormaliser,
					optionType: MIN_MAX,
					refValue: maxRef,
					setter: setMax,
					validator: () => {
						if (
							!isNil(minRef.current) &&
							maxRef.current < minRef.current
						)
							return 'Max must be greater than or equal to min';

						if (maxRef.current <= 0 && config.type !== NUMBER)
							return 'Maximum must be greater than 0';
					},
					value: _max,
				},
			].filter(({ optionType }) =>
				(validationOptions || []).includes(optionType)
			),
		[config, _max, _min, rangeNormaliser, validationOptions]
	);

	// Ensures other counterParts are included in the update if
	// their errors are now supressed after changing this value
	const getCounterPartUpdates = useCallback(
		(counterParts = []) =>
			(counterParts || []).reduce((accumulator, partKey) => {
				const part = options.find(({ key: k }) => k === partKey);

				const isValid = !part.validator();

				if (part && !isNil(part.value) && isValid) {
					return {
						...accumulator,
						[partKey]: { ...config[partKey], value: part.value },
					};
				}

				return accumulator;
			}, {}),
		[config, options]
	);

	const handleInclusionChange = useCallback(
		({
			counterParts,
			fieldType,
			included,
			initialValue,
			key,
			refValue,
			setter,
		}) => {
			if (included) {
				if (refValue) {
					refValue.current = initialValue;
				}

				updateConfig(location, {
					...config,
					...getCounterPartUpdates(counterParts),
					[key]: { value: !fieldType ? true : initialValue },
				});
				setter?.(initialValue);

				return;
			}

			if (refValue) {
				refValue.current = null;
			}

			let configClone = { ...config };

			delete configClone[key];

			updateConfig(location, {
				...configClone,
				...getCounterPartUpdates(counterParts),
			});

			setter?.(null);
		},
		[config, getCounterPartUpdates, location, updateConfig]
	);

	const handleValueChange = useCallback(
		({ counterParts, key, refValue, setter, value }) => {
			if (value === config[key].value) return;

			refValue.current = value;

			setter(value);

			updateConfig(location, {
				...config,
				...getCounterPartUpdates(counterParts),
				[key]: { ...config[key], value },
			});
		},
		[config, getCounterPartUpdates, location, updateConfig]
	);

	return (
		<>
			{options.map(
				(
					{
						counterParts,
						fieldType,
						initialValue,
						key,
						label,
						normaliser,
						refValue,
						setter,
						validator,
						value: optionValue,
					},
					index
				) => {
					const included = !!config[key];

					const error = validator?.();

					return (
						<Fragment key={key}>
							<FormControlLabel
								control={
									<Checkbox
										checked={included}
										disabled={disabled}
										onChange={event =>
											handleInclusionChange({
												counterParts,
												fieldType,
												included: event.target.checked,
												initialValue,
												key,
												refValue,
												setter,
											})
										}
									/>
								}
								label={label}
								style={{
									alignItems: 'center',
									display: 'flex',
									justifyContent: 'flex-start',
								}}
							/>
							{fieldType && (
								<Collapse in={included}>
									<CollapseContent
										hideDivider={
											+(index === options.length - 1)
										}
									>
										<TextField
											key={historyUuid}
											disabled={disabled}
											error={!!error && included}
											fullWidth
											helperText={
												included ? error : undefined
											}
											label={`${label} Value`}
											onBlur={() => {
												if (
													!error &&
													!cancelling.current
												) {
													handleValueChange({
														counterParts,
														key,
														refValue,
														setter,
														value: optionValue,
													});
												}

												cancelling.current = false;
											}}
											onChange={event => {
												const { value } = event.target;

												const normalisedValue = normaliser
													? normaliser(value)
													: value;

												refValue.current = normalisedValue;

												setter(normalisedValue);
											}}
											onKeyDown={event => {
												if (
													event.key === 'Enter' &&
													!error
												) {
													event.target.blur();
												}

												if (event.key === 'Escape') {
													cancelling.current = true;

													const originalValue =
														config[key].value;

													refValue.current = originalValue;

													setter(originalValue);

													event.currentTarget.blur();
												}
											}}
											style={{ marginBottom: '1em' }}
											type={fieldType}
											value={
												optionValue?.toString() ?? ''
											}
											variant="standard"
										/>
									</CollapseContent>
								</Collapse>
							)}
						</Fragment>
					);
				}
			)}
		</>
	);
}

ValidationInput.propTypes = {
	validationOptions: PropTypes.arrayOf(
		PropTypes.oneOf([DEFAULT, MIN_MAX, REQUIRED])
	),
};
