import React, { createContext, useContext, useState } from 'react';
import { Prompt } from 'react-router';

const DataSetContext = createContext();

export const useDataSetContext = () => {
	return useContext(DataSetContext) || {};
};

export const withDataSetContext = WrappedComponent => {
	return props => {
		const [editData, setEditData] = useState({
			isEditing: false,
			recordUuid: null,
		});
		const [sending, setSending] = useState(false);

		return (
			<DataSetContext.Provider
				value={{
					editData,
					sending,
					setEditData,
					setSending,
				}}
			>
				<WrappedComponent {...props} />
				<Prompt
					when={editData.isEditing}
					message="You have unsaved changes in a document, are you sure you want to leave?"
				/>
			</DataSetContext.Provider>
		);
	};
};
