import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	Chip as MuiChip,
	styled,
	Switch,
	Typography,
} from '@mui/material';
import { useTheme } from '@mui/styles';

const QUANTITATIVE = 'quantitative';
const CATEGORICAL = 'categorical';
const BOOLEAN = 'boolean';

const parseQuantitativeFilterValue = value => {
	let valid = null;
	if (typeof value !== 'object') {
		console.log('*** filterValue is not an object');
		valid = false;
	}

	if (value === null) {
		console.log('*** filterValue was null');
		valid = false;
	}

	if (!Object.hasOwn(value, 'min') && !Object.hasOwn(value, 'max')) {
		console.log('*** filterValue missing min and/or max properties');
		valid = false;
	}

	if (valid === false) {
		return '';
	} else {
		return `${value.min || 'min'} - ${value.max || 'max'}`;
	}
};

const parseCategoricalFilterValue = value => {
	let renderString = '';
	if (Array.isArray(value)) {
		value.forEach((value, index) => {
			if (['string', 'number'].includes(typeof value)) {
				if (index > 0) {
					renderString = renderString.concat(', ', value);
				} else {
					renderString = renderString.concat('', value);
				}
			}
		});
	}
	return renderString;
};

export const MiniSwitch = styled(Switch)(({ theme }) => ({
	marginLeft: 5,
	width: 28,
	height: 16,
	padding: 0,
	display: 'flex',
	'&:active': {
		'& .MuiSwitch-thumb': {
			width: 15,
		},
		'& .MuiSwitch-switchBase.Mui-checked': {
			transform: 'translateX(9px)',
		},
	},
	'& .MuiSwitch-switchBase': {
		padding: 2,
		'&.Mui-checked': {
			transform: 'translateX(12px)',
			color: theme.palette.primary,
		},
	},
	'& .MuiSwitch-thumb': {
		boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
		width: 12,
		height: 12,
		borderRadius: 6,
		transition: theme.transitions.create(['width'], {
			duration: 200,
		}),
	},
	'& .MuiSwitch-track': {
		borderRadius: 16 / 2,
		opacity: 1,
		backgroundColor:
			theme.palette.mode === 'dark'
				? 'rgba(255,255,255,.35)'
				: 'rgba(0,0,0,.25)',
		boxSizing: 'border-box',
	},
}));

const Label = memo(function Label({
	filterIsEnabled,
	filterValueString,
	field,
	switchOnChange,
	switchDisabled,
	switchChecked,
}) {
	const THEME = useTheme();
	return (
		<Box
			sx={{
				maxWidth: '100%',
				display: 'flex',
				alignItems: 'center',
				color: filterIsEnabled
					? THEME.palette.text.primary
					: THEME.palette.insightsChipDisabled.contrastText,
				marginRight: switchDisabled ? 0.5 : 0,
			}}
		>
			<Typography
				variant="body2"
				sx={{
					fontWeight: 700,
				}}
			>
				{`${field.slice(0, 19)}${field.length > 20 ? '... :' : ':'}`}
				&nbsp;
			</Typography>
			<Typography
				variant="body2"
				sx={{
					fontStyle: 'italic',
					// width: '100%',
				}}
				noWrap
			>
				{filterValueString}
			</Typography>

			{!switchDisabled && (
				<MiniSwitch
					checked={switchChecked}
					onChange={switchOnChange}
					onClick={event => {
						event.stopPropagation();
					}}
					disabled={switchDisabled}
				/>
			)}
		</Box>
	);
});

const StyledChip = styled(MuiChip)(({ theme }) => ({
	'&&:hover': {
		backgroundColor: theme.palette.action.hover,
	},
}));

const InsightsFilterChip = memo(function InsightsFilterChip({
	containerWidth,
	field,
	filterType,
	filterValue,
	filterIsEnabled,
	onClick,
	showMobileFilterChips,
	switchChecked,
	switchDisabled,
	switchOnChange,
}) {
	const ParsedValue = useMemo(
		() => {
			// validate and parse filter value to user friendly string
			switch (filterType) {
				case QUANTITATIVE:
					const parsedQuantitativeFilterValue = parseQuantitativeFilterValue(
						filterValue
					);
					return parsedQuantitativeFilterValue;
				case CATEGORICAL:
					const parsedCategoricalFilterValue = parseCategoricalFilterValue(
						filterValue
					);
					return parsedCategoricalFilterValue;
				case BOOLEAN:
					if (typeof filterValue === 'boolean') {
						return filterValue === true ? 'True' : 'False';
					} else {
						return '';
					}
				default:
					return '';
			}
		},
		[filterType, filterValue]
	);

	const LabelContent = () => {
		return (
			<Label
				filterIsEnabled={filterIsEnabled}
				filterValueString={ParsedValue}
				field={field}
				switchOnChange={switchOnChange}
				switchDisabled={switchDisabled}
				switchChecked={switchChecked}
			/>
		);
	};

	return (
		containerWidth && (
			<StyledChip
				clickable
				label={<LabelContent />}
				size="small"
				variant={filterIsEnabled ? 'outlined' : 'filled'}
				color={filterIsEnabled ? 'primary' : 'insightsChipDisabled'}
				sx={{
					'& .MuiChip-label': {
						paddingRight: 0.5,
					},
					'& .MuiChip-outlined': {
						border: '2px solid red',
					},
				}}
				style={{
					margin: showMobileFilterChips ? 5 : 3,
					padding: filterIsEnabled ? 0 : 1,
					maxWidth: containerWidth - 50,
				}}
				onClick={onClick ? onClick : null}
			/>
		)
	);
});

InsightsFilterChip.propTypes = {
	containerWidth: PropTypes.number.isRequired,
	field: PropTypes.string,
	filterType: PropTypes.oneOf([CATEGORICAL, QUANTITATIVE, BOOLEAN]),
	filterValue: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.string),
		PropTypes.objectOf(PropTypes.number),
		PropTypes.bool,
	]),
	filterIsEnabled: PropTypes.bool,
	onClick: PropTypes.func,
	showMobileFilterChips: PropTypes.bool,
	switchChecked: PropTypes.bool,
	switchDisabled: PropTypes.bool,
	switchOnChange: PropTypes.func,
};

Label.propTypes = {
	filterIsEnabled: PropTypes.bool,
	filterValueString: PropTypes.string,
	field: PropTypes.string,
	switchOnChange: PropTypes.func,
	switchDisabled: PropTypes.bool,
	switchChecked: PropTypes.bool,
};

export { InsightsFilterChip };
