import { styled } from '@mui/material/styles';
import { Box, ListItemButton } from '@mui/material';

const Container = styled('div')(({ theme }) => ({
	width: '100%',
	'&:not(:last-of-type)': {
		marginBottom: '1.5em',
	},
	[theme.breakpoints.down('xs')]: {
		flexDirection: 'column-reverse',
	},
}));

const HeadingContainer = styled('div')(({ theme }) => ({
	alignItems: 'center',
	position: 'sticky',
	top: 0,
	width: '100%',
	zIndex: 10,
	transform: 'translateY(-1px)',

	'& > h6': {
		width: '100%',
		background: theme.palette.background.paper,
		paddingTop: 1,
	},
}));

const Fade = styled('div')(({ theme }) => ({
	height: 8,
	width: '100%',
	transform: 'translateY(-1px)',
	backgroundImage: 'none',
	background: `linear-gradient(0deg, ${
		theme.palette.background.paper
	}00 0%, ${theme.palette.background.paper} 33%, ${
		theme.palette.background.paper
	} 100%)`,
}));

const IconContainer = styled(Box)(() => ({
	width: '30px',
	display: 'flex',
	justifyContent: 'flex-end',
}));

const ListButton = styled(ListItemButton)(({ selected, theme }) => ({
	borderLeft: selected
		? `3px solid ${theme.palette.primary.main}`
		: `3px solid transparent`,
	backgroundColor: selected ? theme.palette.primary.main + '33' : 'none',
	justifyContent: 'space-between',
	flexWrap: 'wrap',
	transition: theme.transitions.create(['border-left'], {
		duration: '0.15s',
	}),

	'& .editChip': {
		display: selected ? 'inherit' : 'none',
	},

	// '&:hover .editChip': {
	// 	display: added && !editmode ? 'inherit' : 'none',
	// },
}));

const NoItemsContainer = styled('div')(() => ({
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	opacity: 0.8,
}));

export {
	Container,
	HeadingContainer,
	Fade,
	IconContainer,
	ListButton,
	NoItemsContainer,
};
