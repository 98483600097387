import React, { useCallback } from 'react';
import propTypes from 'prop-types';

// cx={cx}
// cy={cy}
// innerRadius={innerRadius}
// outerRadius={outerRadius}
// startAngle={startAngle}
// endAngle={endAngle}
// fill={fill}

export function Sector({
	cx,
	cy,
	innerRadius,
	outerRadius,
	startAngle,
	endAngle,
	fill,
}) {
	const getBarAttributes = useCallback(
		() => {
			const RADIAN = Math.PI / 180;

			const axisStartAngle = -210;
			const axisEndAngle = 30;
			// const axisStartAngle = 180;
			// const axisEndAngle = 0;
			const sectorStartAngle = axisStartAngle + startAngle;
			const sectorEndAngle = axisStartAngle + endAngle;

			// const startAngle = 0;
			// const endAngle = 270;

			const sinStart = Math.sin(RADIAN * sectorStartAngle);
			const cosStart = Math.cos(RADIAN * sectorStartAngle);

			// const sinEnd = Math.sin(RADIAN * axisEndAngle);
			// const cosEnd = Math.cos(RADIAN * axisEndAngle);
			const sinEnd = Math.sin(RADIAN * sectorEndAngle);
			const cosEnd = Math.cos(RADIAN * sectorEndAngle);

			// const sin = Math.sin(RADIAN * (endAngle - 225));
			// const cos = Math.cos(RADIAN * (endAngle - 225));

			// const sinStart = Math.sin(RADIAN * 135);
			// const cosStart = Math.cos(RADIAN * 135);
			const sx1 = cx + outerRadius * cosStart;
			const sy1 = cy + outerRadius * sinStart;

			const sx2 = cx + innerRadius * cosStart;
			const sy2 = cy + innerRadius * sinStart;

			const ex1 = cx + outerRadius * cosEnd;
			const ey1 = cy + outerRadius * sinEnd;

			const ex2 = cx + innerRadius * cosEnd;
			const ey2 = cy + innerRadius * sinEnd;

			const path = `M ${sx1} ${sy1} A ${outerRadius} ${outerRadius} 0 ${
				endAngle - startAngle > 180 ? 1 : 0
			} 1 ${ex1} ${ey1} L ${ex2} ${ey2} A ${innerRadius} ${innerRadius} 0 ${
				endAngle - startAngle > 180 ? 1 : 0
			} 0 ${sx2} ${sy2} Z`;

			return {
				path,
				ex1,
				ey1,
				ex2,
				ey2,
				sx1,
				sy1,
				sx2,
				sy2,
			};
		},
		[startAngle, cx, outerRadius, cy, innerRadius, endAngle]
	);
	const { path, ex1, ey1, ex2, ey2, sx1, sy1, sx2, sy2 } = getBarAttributes();

	return (
		<g>
			<path d={path} fill={'pink'} />
			{/* <line
				x1={sx1}
				y1={sy1}
				x2={sx2}
				y2={sy2}
				stroke="black"
				strokeWidth="1"
			/>
			<line
				x1={ex1}
				y1={ey1}
				x2={ex2}
				y2={ey2}
				stroke="black"
				strokeWidth="1"
			/> */}
		</g>
	);

	// return <path d={path} fill={fill} />;
}
