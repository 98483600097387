const handleGetHistory = async ({
	apiUuidParamPayload,
	getRecordHistoryRequest,
	setError,
}) => {
	// Initialise return value
	let result;
	// console.log(apiUuidParamPayload, getRecordHistoryRequest);
	try {
		// dispatch the request or raise error if lookup has returned null
		if (getRecordHistoryRequest) {
			const history = await getRecordHistoryRequest(apiUuidParamPayload);
			result = history;
		} else {
			throw new Error('invalid getRecordHistoryRequest');
		}
	} catch (error) {
		setError(true);
		console.log(error);
	} finally {
		return result;
	}
};

export { handleGetHistory };
