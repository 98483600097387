const {
	ERROR,
	setOperator,
	evaluateExpression,
	OPERATION_MODE,
} = require('../expression.js');

const operatorKey = '$expr';

setOperator(
	operatorKey,
	/**
	 * @type {ExpressionFunction<unknown, unknown>}
	 */
	(context, args, options) => {
		return evaluateExpression(
			{ ...context, operationMode: OPERATION_MODE.AGGREGATE },
			args
		);
	},

	[OPERATION_MODE.QUERY]
);
