import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Prompt } from 'react-router';

import Comments from '../../../components/generic/comments/Comments';
import { useWorkflowSessionContext } from './contexts/WorkflowSessionContext';
import WorkflowPage from './components/core/WorkflowPage';
import WorkflowPageContextProvider from './contexts/WorkflowPageContext';
import WorkflowSummary from './components/summary/WorkflowSummary';
import useComments from '../../../components/generic/comments/hooks/useComments';
import useCommentFuncs from './processing/hooks/useCommentFuncs';

export default function WorkflowSession({ session }) {
	const {
		canLeave,
		commentAvailability,
		currentPage,
		isFinal,
		setShowWorkflowComments,
		setWorkflowComments,
		showSummary,
		showWorkflowComments,
		workflowComments,
	} = useWorkflowSessionContext();

	const { addComment, deleteComment, editComment } = useComments(
		workflowComments
	);

	const {
		adornment,
		commonPredicate,
		modificationMutator,
		newCommentMessage,
		readOnlyMessage,
	} = useCommentFuncs();

	const handleAddComment = useCallback(
		newComment => addComment(newComment, setWorkflowComments),
		[addComment, setWorkflowComments]
	);

	const handleEditComment = useCallback(
		editedComment => editComment(editedComment, setWorkflowComments),
		[editComment, setWorkflowComments]
	);

	const handleDeleteComment = useCallback(
		commentToDelete => deleteComment(commentToDelete, setWorkflowComments),
		[deleteComment, setWorkflowComments]
	);

	return (
		<>
			{session && (
				<>
					<WorkflowPageContextProvider>
						{!showSummary &&
							currentPage &&
							!isFinal && <WorkflowPage />}
						{(showSummary || isFinal) && <WorkflowSummary />}
					</WorkflowPageContextProvider>
					<Comments
						availability={commentAvailability.workflow}
						comments={
							showWorkflowComments ? workflowComments : null
						}
						isEditablePredicate={commonPredicate}
						markAsNewPredicate={commonPredicate}
						modificationMutator={modificationMutator}
						newCommentMessage={newCommentMessage}
						onAddComment={handleAddComment}
						onClose={() => setShowWorkflowComments(false)}
						onDeleteComment={handleDeleteComment}
						onEditComment={handleEditComment}
						readOnlyMessage={readOnlyMessage}
						renderAdornment={adornment}
					/>
					<Prompt
						when={!canLeave && !isFinal}
						message="Leaving will discard changes to your workflow."
					/>
				</>
			)}
		</>
	);
}

WorkflowSession.propTypes = {
	session: PropTypes.object,
};
