import React, { useEffect, useState } from "react";

import makeStyles from '@mui/styles/makeStyles';
import { Card, CardHeader, CardContent } from "@mui/material";

const styles = makeStyles((theme) => ({
    root: {
        height: '100%',
        width: '100%',
        minWidth: 200,
        '& .MuiTextField-root': {
            margin: '10px 0',
          },
        margin:'1rem',
        padding:'1rem',
    },
    value:{
        margin:'auto',
        padding: '20px 0px',
        fontSize: '5rem',
        textAlign: 'center',
        fontWeight: '700',
	},
}));


export default function ReportCard({data, param_name}) {
    const classes = styles();
    const [currentValue, setCurrentValue] = useState(0.0);

    useEffect(()=>{
        if(data !== undefined){
            if(data.length !== 0){
                var new_value = data[data.length-1][param_name];
                if(new_value !== undefined){
                    if(new_value < 1000) {
                        setCurrentValue(new_value.toFixed(2));
                    } else if(new_value < 1000000) {
                        setCurrentValue((new_value/1000).toFixed(2) + 'k');
                    } else if(new_value < 1000000000) {
                        setCurrentValue((new_value/1000000).toFixed(2) + 'M');
                    } else if(new_value < 1000000000000) {
                        setCurrentValue((new_value/1000000000).toFixed(2) + 'G');
                    }
                }
            }
        }
    },[data, param_name]);

    return(
        <Card variant='outlined'>
            <CardHeader title={param_name} />
            <CardContent>
                <div className={classes.value}>
                    {currentValue}
                </div>
            </CardContent>
        </Card>
    );
}
