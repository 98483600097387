import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
// import { useAppContext, useNavContext } from "../context/ContextManager";
import { useNavContext } from '../../context/ContextManager';
import { useAppContext } from '../../context/ContextManager';

// MUI COMPONENTS
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

// MUI ICONS
import SaveIcon from '@mui/icons-material/SaveAlt';
import CloseIcon from '@mui/icons-material/Close';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

// CUSTOM COMPONENTS
import NotificationDialog from '../../components/dialogs/NotificationDialog';

// Data for landing page selection table and contextual navigation
import IoT_options from './IoTOptions';

import IoT_breadcrumbs from './IoTBreadcrumbs';

import IoT_nav_item from './IoTNavItem';

const styles = makeStyles(theme => ({
	button_row: {},
	root: {
		width: '100%',
		minWidth: 200,
		'& .MuiTextField-root': {
			margin: '10px 0',
		},
	},
	config_paper: {
		padding: 10,
		margin: '10px 0',
	},
	description_text: {
		height: 200,
		color: 'red',
	},
	textField: {
		backgroundColor: '#f5f5f5',
		margin: '0px 0',
		fontSize: '2rem',
	},
	tabPanel: {
		margin: 0,
		padding: 0,
		marginBottom: 16,
		backgroundColor: '#f5f5f5',
		borderRadius: 8,
	},
	tabsAppBar: {
		backgroundColor: '#ffffff',
		boxShadow: 'none',
	},
	actionButton: {
		minWidth: '120px',
	},
	deviceSelect: {
		margin: '16px 0',
		minWidth: 200,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	fileInfoBox: {
		padding: '8px 16px',
	},
	errorText: {
		color: 'red',
	},
}));

function getFileInfo(org_uuid, res_uuid) {
	return new Promise((resolve, reject) => {
		var req_url =
			process.env.REACT_APP_API_SERVER_URL +
			'/v1/file/latest/' +
			res_uuid;

		Auth.currentSession().then(user_session => {
			let user_token = user_session.getAccessToken();
			fetch(req_url, {
				method: 'GET',
				headers: {
					Authorization: user_token.jwtToken,
					Accept: 'application/json',
					'Content-Type': 'application/json',
					org_uuid,
				},
			})
				.then(res => res.json())
				.then(response => {
					// The API will respond with OK if a config with this name is already present
					if (response['result'] === 'OK') {
						resolve(response['file_info']);
					} else {
						alert('Server connection error.');
						reject('');
					}
				})
				.catch(error => {
					console.log(error);
					reject('');
				});
		});
	});
}

export default function FirmwareNewRev({ match }) {
	const classes = styles();
	const [notes, setNotes] = useState('');
	const [version, setVersion] = useState('');
	const history = useHistory();
	const [commsActive, setCommsActive] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [filenameSet, setFilenameSet] = useState(true);
	const [showComplete, setShowComplete] = useState(false);

	const [showError, setShowError] = useState(false);
	const [errorText, setErrorText] = useState('');

	const [fileDetails, setFileDetails] = useState({});

	const [filename, setFilename] = useState('');
	const [filesize, setFilesize] = useState('');
	const [filedate, setFiledate] = useState('');

	const [fileInfo, setFileInfo] = useState({});

	const { setContextualNav } = useNavContext();
	const { setBreadcrumbs } = useNavContext();
	const { appOrg } = useAppContext();

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				...IoT_breadcrumbs,
				{ text: 'Files', link: '/Files' },
				{
					text: fileInfo.filename,
					link: '/Files/' + fileInfo.resource_uuid,
				},
				{ text: 'New Revision', link: '' },
			]);
		},
		[fileInfo.filename, fileInfo.resource_uuid, setBreadcrumbs]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([...IoT_nav_item, ...IoT_options]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	var file_uuid = match.params.id;

	const handleVersionChange = event => {
		setVersion(event.target.value);
	};
	const handleNotesChange = event => {
		setNotes(event.target.value);
	};

	const handleCancel = event => {
		history.goBack();
	};
	const handleComplete = () => {
		history.goBack();
	};

	useEffect(
		() => {
			var req_url =
				process.env.REACT_APP_API_SERVER_URL + '/v1/organisation';

			Auth.currentSession().then(user_session => {
				let user_token = user_session.getAccessToken();
				fetch(req_url, {
					method: 'GET',
					headers: {
						Authorization: user_token.jwtToken,
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Organisation: appOrg.uuid,
					},
				})
					.then(res => res.json())
					.then(response => {
						// The API will respond with OK if a config with this name is already present
						if (response['result'] === 'OK') {
							getFileInfo(appOrg.uuid, file_uuid)
								.then(file_info => {
									console.log(file_info);
									setFileInfo(file_info);
									setIsLoading(false);
								})
								.catch(res => {
									alert('Failed to load dev list');
									setIsLoading(false);
								});
						} else {
							alert('Server connection error.');
							setIsLoading(false);
						}
					})
					.catch(error => {
						console.log(error);
						setIsLoading(false);
					});
			});
		},
		[file_uuid]
	);

	const handleFileSelect = event => {
		console.log(event.target.files);
		if (event.target.files.length !== 0) {
			setFilename(event.target.files[0].name);
			setFilesize(event.target.files[0].size);
			setFiledate(event.target.files[0].lastModifiedDate);

			setFileDetails(event.target.files[0]);
			setFilenameSet(false);
		}
	};

	const handleSave = event => {
		// TODO: do a better check than this
		if (version === '') {
			setShowError(true);
			setErrorText('Please enter a valid version string');
			return;
		}

		setShowError(false);
		setErrorText('');

		var req_url =
			process.env.REACT_APP_API_SERVER_URL +
			'/v1/file/' +
			fileInfo.resource_uuid;
		const formData = new FormData();
		formData.append('file', fileDetails);
		formData.append('version_str', version);
		formData.append('resource_uuid', fileInfo.resource_uuid);
		formData.append('notes', notes);
		formData.append('status', 'active');

		setCommsActive(true);

		Auth.currentSession().then(user_session => {
			let user_token = user_session.getAccessToken();
			fetch(req_url, {
				body: formData,
				method: 'post',
				headers: {
					Authorization: user_token.jwtToken,
					Accept: 'application/json',
					//'Content-Type': 'application/json'
					Organisation: appOrg.uuid,
				},
			})
				.then(response => {
					// handle your response
					console.log('File uploded: ' + response);

					setCommsActive(false);
					setShowComplete(true);
				})
				.catch(error => {
					// handle your error
					console.log('File Upload error: ' + error);
					setShowError(true);
					setErrorText('File upload error');
					setCommsActive(false);
				});
		});
	};

	return (
		<div className={classes.root}>
			{isLoading && (
				<Paper>
					<p>Loading...</p>
					<CircularProgress size={'120px'} />
				</Paper>
			)}

			{!isLoading && (
				<form className={classes.root} noValidate autoComplete="off">
					<Paper className={classes.config_paper}>
						<h4>Upload new Firmware File</h4>

						<Divider />

						<div>
							<Typography variant="h5">
								File Name: {fileInfo.filename}
							</Typography>
							<Typography variant="h5">
								Resource UUID: {fileInfo.resource_uuid}
							</Typography>
							<Typography variant="h5">
								Description: {fileInfo.description}
							</Typography>
							<Typography variant="h5">
								Device Type: {fileInfo.device_type}
							</Typography>
						</div>

						<Divider />

						<div>
							<Button
								variant="outlined"
								startIcon={<InsertDriveFileOutlinedIcon />}
								className={classes.actionButton}
								component="label"
							>
								Select File
								<input
									type="file"
									name="myFile"
									onChange={handleFileSelect}
									style={{ display: 'none' }}
								/>
							</Button>
							{filename && (
								<Box className={classes.fileInfoBox}>
									<Typography variant="subtitle2">
										File Name: {filename}
									</Typography>
									<Typography variant="subtitle2">
										File Size: {filesize}
									</Typography>
									<Typography variant="subtitle2">
										File Date:{' '}
										{new Date(filedate).toLocaleString()}
									</Typography>
								</Box>
							)}
						</div>

						<div>
							<Typography variant="h5">
								Version String:
							</Typography>
							<TextField
								variant="outlined"
								fullWidth
								className={classes.textField}
								onChange={handleVersionChange}
							/>
						</div>

						<div>
							<Typography variant="h5">Notes:</Typography>
							<TextField
								multiline
								variant="outlined"
								fullWidth
								className={classes.textField}
								onChange={handleNotesChange}
							/>
						</div>

						{showError && (
							<Box>
								<Typography
									variant="h6"
									className={classes.errorText}
								>
									Error:
									{errorText}
								</Typography>
							</Box>
						)}

						<Divider />

						<Box
							display="flex"
							flexDirection="row-reverse"
							p={1}
							m={1}
							bgcolor="background.paper"
						>
							<Button
								variant="outlined"
								startIcon={<SaveIcon />}
								onClick={handleSave}
								className={classes.actionButton}
								disabled={filenameSet}
							>
								Save
							</Button>

							<Button
								variant="outlined"
								startIcon={<CloseIcon />}
								onClick={handleCancel}
								className={classes.actionButton}
							>
								Cancel
							</Button>
						</Box>
					</Paper>

					<Backdrop className={classes.backdrop} open={commsActive}>
						<Typography variant="h3">Uploading File...</Typography>
						<CircularProgress color="inherit" />
					</Backdrop>

					<NotificationDialog
						setOpen={showComplete}
						onClose={handleComplete}
						title="File Upload"
						text="The file has be uploaded successfully"
						icon=""
					/>
				</form>
			)}
		</div>
	);
}
