import { apiClient } from '../api_client';

import {
	deviceTypeGetListSchema,
	deviceTypeGetSchema,
} from '../validations/deviceTypeValidation';

//========================================================================================================
async function deviceTypeGetList() {
	const req_url = 'device_type';
	try {
		const response = await apiClient({ method: 'get', url: req_url });
		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				await deviceTypeGetListSchema.validate(response.device_types);
				// TODO: change the data format
				return response.device_types;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function deviceTypeGet({ typeId }) {
	try {
		const req_url = 'device_type/' + typeId;
		const response = await apiClient({ method: 'get', url: req_url });
		console.log('device type id: ' + typeId);
		console.log(response);

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let device_type = await deviceTypeGetSchema.validate(
					response.device_type
				);
				// TODO: change the data format
				return device_type;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

export { deviceTypeGetList, deviceTypeGet };
