const { OPERATION_MODE } = require('../../../expression');
const {
	setOperatorSchemaFn,
	evaluateExpressionSchema,
} = require('../../expression');
const { newDefaultSchema, setArrayType } = require('../../utils');

const operatorKey = '$push';

setOperatorSchemaFn(
	operatorKey,
	(context, args, options) => {
		const newObj = evaluateExpressionSchema(
			{ ...context, operationMode: OPERATION_MODE.AGGREGATE },
			args
		);

		const newArr = newDefaultSchema('array', {
			of: 'object',
		});
		setArrayType(newArr, newObj);

		return newArr;
	},
	[OPERATION_MODE.ACCUMULATE]
);
