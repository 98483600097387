import { useContext, useMemo } from 'react';
import { TileContext } from '../components/TileContext';
import Tile from '../components/Tile';
import { ScreensContext } from '../ScreensContext';

const Content = ({ childTiles }) => {
	const {
		tileData,
		layoutDefinition,
		handleDeleteTile,
		onTileChange,
		onContainerChange,
		screenData,
	} = useContext(TileContext);
	const { cellSize } = useContext(ScreensContext);

	const cellStyle = useMemo(
		() => {
			if (layoutDefinition) {
				const gutters = count => {
					if (count === 1) {
						return 0;
					} else {
						return (count - 1) * 10;
					}
				};

				return {
					width:
						(Math.floor(
							cellSize * layoutDefinition.w +
								gutters(layoutDefinition.w)
						) -
							10 -
							layoutDefinition.w * 10) /
						layoutDefinition.w,
					height:
						(cellSize * layoutDefinition.h +
							gutters(layoutDefinition.h) -
							10 -
							layoutDefinition.h * 10) /
						layoutDefinition.h,
				};
			}
		},
		[layoutDefinition, cellSize]
	);

	return (
		<div
			style={{
				height: '100%',
				width: '100%',
				display: 'flex',
				flexWrap: 'wrap',
				padding: 5,
				overflow: 'scroll',
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
				alignContent: 'flex-start',
			}}
		>
			{cellStyle &&
				(tileData.data.children.length > 0
					? tileData.data.children.map((child, index) => (
							<div
								style={{
									margin: 5,
									...cellStyle,
								}}
								key={index}
							>
								<Tile
									layoutDefinition={layoutDefinition}
									handleDeleteTile={handleDeleteTile}
									onTileChange={onTileChange}
									onContainerChange={onContainerChange}
									component={childTiles[child]}
									screenData={screenData}
								/>
								{/* <TileContent
							component={childTiles[child]}
							tileUuid={child}
						/> */}
							</div>
					  ))
					: null)}
		</div>
	);
};

export default function Container() {
	const { tileData, screenData } = useContext(TileContext);

	const childTiles = useMemo(
		() => {
			const result = {};
			if (tileData?.data.children && screenData) {
				tileData.data.children.forEach(element => {
					result[element] = screenData.tiles[element];
				});
				return result;
			}
		},
		[screenData, tileData.data.children]
	);

	return (
		<div style={{ height: '100%', width: '100%' }}>
			{childTiles && <Content childTiles={childTiles} />}
		</div>
	);
}
