import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material';

import BtLoading from '../../../components/generic/BtLoading';
import {
	DataConfigContextProvider,
	useDataConfigContext,
} from './DataConfigContext';
import DataConfigEditor from './DataConfigEditor';
import DataConfigNode from './DataConfigNode';
import { historyPropType } from './utils/propTypes';

const Layout = styled('div')(({ readonly }) => ({
	display: 'grid',
	gridTemplateColumns: readonly ? 'auto' : 'auto minmax(200px, 340px)',
	paddingBottom: '2em',
	position: 'relative',
}));

const RootConfigNode = () => {
	const { editedConfig } = useDataConfigContext();

	return <DataConfigNode config={editedConfig} isRootNode title="Config" />;
};

const DataConfig = forwardRef(
	(
		{
			config,
			initialConfig,
			readOnly,
			nodeSelectable,
			onNodeSelect,
			...props
		},
		ref
	) => {
		const configRef = useRef();

		useImperativeHandle(
			ref,
			() => ({
				clearHistory: configRef.current.clearHistory,
				config: configRef.current.config,
				generateSchema: configRef.current.generateSchema,
				redo: configRef.current.redo,
				reset: configRef.current.reset,
				save: configRef.current.save,
				undo: configRef.current.undo,
			}),
			[configRef]
		);

		const configPresent = !!(config || initialConfig);

		return (
			<div ref={ref} style={{ minHeight: '100%', width: '100%' }}>
				{!configPresent && <BtLoading />}
				{configPresent && (
					<DataConfigContextProvider
						ref={configRef}
						config={config}
						initialConfig={initialConfig}
						readOnly={readOnly}
						nodeSelectable={nodeSelectable}
						onNodeSelect={onNodeSelect}
						{...props}
					>
						<Layout readonly={+readOnly}>
							<div style={{ overflowX: 'scroll' }}>
								<RootConfigNode />
							</div>
							{!readOnly && (
								<div
									style={{
										maxHeight: '100%',
										position: 'relative',
									}}
								>
									<div
										style={{
											maxHeight: '100%',
											position: 'absolute',
										}}
									>
										<DataConfigEditor />
									</div>
								</div>
							)}
						</Layout>
					</DataConfigContextProvider>
				)}
			</div>
		);
	}
);

DataConfig.displayName = 'DataConfig';

DataConfig.propTypes = {
	config: PropTypes.object,
	disabled: PropTypes.bool,
	history: historyPropType,
	historyPos: PropTypes.number,
	initialConfig: PropTypes.object,
	initialHistory: historyPropType,
	initialHistoryPos: PropTypes.number,
	onChange: PropTypes.func,
	onChangeHistory: PropTypes.func,
	onChangeHistoryPos: PropTypes.func,
	readOnly: PropTypes.bool,
	onNodeSelect: PropTypes.func,
	nodeSelectable: PropTypes.bool,
};

export default DataConfig;
