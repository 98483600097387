import { useCallback, useEffect, useMemo, useState } from 'react';
import React, { memo } from 'react';
import { ArrowRight, PlusMinusVariant } from 'mdi-material-ui';
import SelectPaletteDialog from '../../../../../components/paletteManager/components/SelectPaletteDialog';
import { BtFormContainer } from '../../../../../components/generic/forms';
import { Box } from '@mui/material';
import { PaletteDisplay } from './components/PaletteDisplay';
import {
	initSelectedOptions,
	tagPalettes,
	updatePaletteConfigPalettes,
	updatePaletteConfigTags,
} from './utils/functions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { BtFormContainerStackedActionButtons } from '../../../../../components/generic/forms/BtFormContainerStackedActionButtons';

import { orgDetailsGet } from '../../../../../API/orgDetails';
import BtLoading from '../../../../../components/generic/BtLoading';
import BtError from '../../../../../components/generic/BtError';
import { isEqual } from 'lodash';
import { useInsightCollectionEditContext } from '../../../insightCollectionEditContext/InsightCollectionEditContext';

const InsightsCollectionPaletteManager = memo(
	function InsightsCollectionPaletteManager() {
		const [selectPaletteDialogOpen, setSelectPaletteDialogOpen] = useState(
			false
		);
		const [taggedPalettes, setTaggedPalettes] = useState(null);
		const [paletteOptions, setPaletteOptions] = useState(null);
		const [localPaletteConfig, setLocalPaletteConfig] = useState(null);
		const {
			insightCollectionUuid,
			insightCollection,
			// loading,
			// // setLoading,
			error,
			setError,
			handleDraftUpdate,
		} = useInsightCollectionEditContext();
		const [localLoading, setLocalLoading] = useState(true);

		const paletteConfig = useMemo(() => insightCollection.palette_config, [
			insightCollection,
		]);

		useEffect(
			() => {
				if (!isEqual(paletteConfig, localPaletteConfig)) {
					// console.log(
					// 	'should set local config{paletteConfig',
					// 	{
					// 		paletteConfig,
					// 		localPaletteConfig,
					// 	},
					// 	isEqual(paletteConfig, localPaletteConfig)
					// );
					setLocalPaletteConfig(paletteConfig);
				}
			},
			[localPaletteConfig, paletteConfig]
		);

		const initComponent = useCallback(
			async () => {
				const getOrgPalettes = async () => {
					try {
						const orgDetails = await orgDetailsGet();
						return orgDetails.theme.palettes;
					} catch (error) {
						console.error(error);
						setError(true);
					}
				};

				try {
					// get the org palettes
					const orgPalettes = await getOrgPalettes();
					// setOrgPalettes(_orgPalettes);
					// add the collection tags to the palette
					const _taggedPalettes = tagPalettes(
						localPaletteConfig,
						orgPalettes
					);
					setTaggedPalettes(_taggedPalettes);
					// prepare the options fot eh filter select
					const _selectPaletteOptions = initSelectedOptions(
						orgPalettes,
						localPaletteConfig
					);
					setPaletteOptions(_selectPaletteOptions);

					// console.log({ orgPalettes, paletteConfig });
				} catch (error) {
					setError(true);
					console.error(error);
				} finally {
					setLocalLoading(false);
				}
			},
			[localPaletteConfig, setError]
		);

		useEffect(
			() => {
				if (localPaletteConfig) initComponent();
			},
			[initComponent, localPaletteConfig]
		);

		const history = useHistory();

		const handleLeave = useCallback(
			() => {
				history.push(
					`/InsightCollection/${insightCollectionUuid}/Settings`
				);
			},
			[history, insightCollectionUuid]
		);

		const handleAddRemovePalette = useCallback(
			selectedPalettes => {
				const update = updatePaletteConfigPalettes(
					selectedPalettes,
					localPaletteConfig
				);
				setLocalPaletteConfig(update);
				// onChange({ palette_config: update });
				handleDraftUpdate({ palette_config: update });
			},
			[localPaletteConfig, handleDraftUpdate]
		);

		const handleTagsUpdate = useCallback(
			tagsUpdate => {
				const update = updatePaletteConfigTags(
					localPaletteConfig,
					tagsUpdate
				);
				setLocalPaletteConfig(update);
				// onChange({ palette_config: update });
				handleDraftUpdate({ palette_config: update });
			},
			[localPaletteConfig, handleDraftUpdate]
		);

		const buttonAttr = useMemo(
			() => [
				{
					onClick: () => setSelectPaletteDialogOpen(true),
					variant: 'contained',
					startIcon: <PlusMinusVariant />,
					title: 'Add/remove palettes',
				},
				{
					onClick: handleLeave,
					variant: 'outlined',
					endIcon: <ArrowRight />,
					title: 'Back to settings',
				},
			],
			[handleLeave]
		);

		if (error) return <BtError />;

		return (
			<BtLoading loading={localLoading}>
				<BtFormContainer
					ActionButtons={
						<BtFormContainerStackedActionButtons
							buttonAttr={buttonAttr}
						/>
					}
					title="Collection Colours and Tags"
					maxWidth="md"
				>
					<Box
						sx={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}
					>
						{taggedPalettes?.map(p => (
							<PaletteDisplay
								key={p.uuid}
								onChange={handleTagsUpdate}
								paletteObj={p}
							/>
						))}
					</Box>
					<SelectPaletteDialog
						title="Add or remove palettes from collection"
						options={paletteOptions}
						open={selectPaletteDialogOpen}
						onClose={() => setSelectPaletteDialogOpen(false)}
						// onSelect={theme => handlePaletteChange(theme.palette)}
						onSelect={handleAddRemovePalette}
						message="If a palette is removed form the collection, any tags data will be lost"
						multiple
						verb="confirm"
						nullSelectedMessage="The collection must have at least one palette"
					/>
				</BtFormContainer>
			</BtLoading>
		);
	}
);

// const paletteConfigObject = PropTypes.shape({
// 	colours: PropTypes.arrayOf(
// 		PropTypes.shape({
// 			uuid: PropTypes.string.isRequired,
// 			tags: PropTypes.arrayOf(PropTypes.string).isRequired,
// 		})
// 	).isRequired,
// 	uuid: PropTypes.string.isRequired,
// });

// const orgColourObject = PropTypes.shape({
// 	hex: PropTypes.string.isRequired,
// 	uuid: PropTypes.string.isRequired,
// 	name: PropTypes.string.isRequired,
// });

// const orgPaletteObject = PropTypes.shape({
// 	uuid: PropTypes.string.isRequired,
// 	type: PropTypes.oneOf([INSIGHTS, PLATFORM]).isRequired,
// 	status: PropTypes.oneOf([ACTIVE, ARCHIVED]).isRequired,
// 	name: PropTypes.string.isRequired,
// 	palette: PropTypes.arrayOf(orgColourObject).isRequired,
// });

// InsightsCollectionPaletteManager.propTypes = {
// 	paletteConfig: PropTypes.arrayOf(paletteConfigObject).isRequired,
// 	onChange: PropTypes.func.isRequired,
// 	orgPalettes: PropTypes.arrayOf(orgPaletteObject),
// 	insightCollectionUuid: PropTypes.string.isRequired,
// };

InsightsCollectionPaletteManager.displayName =
	'InsightsCollectionPaletteManager';

export { InsightsCollectionPaletteManager };
