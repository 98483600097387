import React from 'react';
import PropTypes from 'prop-types';

import CancelIcon from '@mui/icons-material/Close';
import { IconButton, styled, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';

const Container = styled('div')(() => ({
	display: 'flex',
	justifyContent: 'space-between',
	width: '100%',
}));

export default function BtDialogHeading({
	closeVerb,
	hideClose,
	onClose,
	subtitle,
	title,
	...props
}) {
	return (
		<Container {...props}>
			<div>
				{title && (
					<>
						{typeof title === 'string' ? (
							<Typography style={{ marginTop: 2 }} variant="h6">
								{title}
							</Typography>
						) : (
							title
						)}
					</>
				)}
				{subtitle && (
					<>
						{typeof subtitle === 'string' ? (
							<Typography variant="subtitle">
								{subtitle}
							</Typography>
						) : (
							subtitle
						)}
					</>
				)}
			</div>
			{!!onClose &&
				!hideClose && (
					<Tooltip
						disableInteractive
						title={_.capitalize(closeVerb)}
						style={{
							alignSelf: 'flex-start',
							transform: 'translate(4px, -4px)',
						}}
					>
						<IconButton onClick={onClose}>
							<CancelIcon />
						</IconButton>
					</Tooltip>
				)}
		</Container>
	);
}

BtDialogHeading.defaultProps = {
	closeVerb: 'Close',
};

BtDialogHeading.propTypes = {
	closeVerb: PropTypes.string,
	hideClose: PropTypes.bool,
	onClose: PropTypes.func,
	subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
