import React, { useState } from 'react';

import { Card, LinearProgress, styled } from '@mui/material';

import {
	a11yProps,
	BtTab,
	BtTabBar,
	BtTabPanel,
	BtTabView,
} from '../../../../../components/generic/BtTabView';
import BtHeightAnimator from '../../../../../components/generic/BtHeightAnimator';
import DataSetJsonView from '../record-list/DataSetJsonView';
import DataSetNode from '../record-editor/DataSetNode';
import { useDataSetContext } from '../../../hocs/withDataSetContext';
import { useDataSetEditorContext } from '../../../contexts/DataSetEditorContext';

const Loading = styled(LinearProgress)(() => ({
	position: 'absolute',
	top: 0,
	width: '100%',
}));

export default function NewDataSetRecordEditor() {
	const { sending } = useDataSetContext();
	const { editedData, schema } = useDataSetEditorContext();

	const [currentTab, setCurrentTab] = useState(0);

	return (
		<div style={{ marginTop: -10 }}>
			<BtTabView>
				<BtTabBar
					currentTab={currentTab}
					onTabChange={(event, selectedTab) =>
						setCurrentTab(selectedTab)
					}
					style={{ marginBottom: '1em' }}
				>
					<BtTab label="Editor" {...a11yProps(0)} />
					<BtTab label="JSON Preview" {...a11yProps(1)} />
				</BtTabBar>
				<Card
					style={{
						position: 'relative',
					}}
					variant="outlined"
				>
					{sending && <Loading />}
					<BtHeightAnimator>
						<div
							style={{
								padding: '1em 0.5em',
							}}
						>
							<BtTabPanel
								currentTab={currentTab}
								index={0}
								style={{ opacity: sending ? 0.5 : 1 }}
							>
								<DataSetNode
									data={editedData.values}
									disabled={sending}
									schema={schema}
								/>
							</BtTabPanel>
							<BtTabPanel currentTab={currentTab} index={1}>
								<DataSetJsonView
									data={editedData.values}
									style={{
										padding: '0 0.5em',
									}}
								/>
							</BtTabPanel>
						</div>
					</BtHeightAnimator>
				</Card>
			</BtTabView>
		</div>
	);
}
