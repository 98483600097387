import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';

export default function BtTableSkeleton({ hasActionButton }) {
	const RowSkeleton = () => (
		<Skeleton
			animation="wave"
			style={{
				height: 24,
				borderRadius: 8,
			}}
			variant="rectangle"
		/>
	);

	return (
		<div style={{ width: '100%' }}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					marginBottom: 5,
					paddingTop: 15,
				}}
			>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<Skeleton
						style={{
							width: '100%',
							maxWidth: 360,
							height: 28,
							borderRadius: 8,
							marginRight: 80,
						}}
						animation="wave"
						variant="rectangle"
					/>
					{hasActionButton && (
						<Skeleton
							style={{
								width: '100%',
								maxWidth: 160,
								height: 36,
								borderRadius: 8,
							}}
							animation="wave"
							variant="rectangle"
						/>
					)}
				</div>
				<Skeleton
					animation="wave"
					variant="rectangle"
					style={{
						width: '60%',
						borderRadius: 8,
						height: 24,
						margin: '35px 0 15px 0',
					}}
				/>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '2.8em',
					padding: '1.6em 0',
				}}
			>
				<RowSkeleton />
				<RowSkeleton />
				<RowSkeleton />
			</div>
		</div>
	);
}

BtTableSkeleton.propTypes = {
	hasActionButton: PropTypes.bool,
};
