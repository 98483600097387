import {
	BtFormBarCodeScanner,
	BtFormCheckbox,
	BtFormDateTimePicker,
	BtFormImageCapture,
	BtFormSelect,
	BtFormSignature,
	BtFormTextField,
} from '../../../../../components/generic/forms';
import { BtImageViewer } from '../../../../../components/generic/BtImageViewer';
import dataTypes, {
	ARRAY,
	BOOLEAN,
	NUMBER,
	STRING,
	TEMPORAL,
} from './workflowDataTypes';
import WorkflowBasicResult from '../../components/summary/WorkflowBasicResult';
import WorkflowImageResult from '../../components/summary/WorkflowImageResult';
import WorkflowLabel from '../../components/core/WorkflowLabel';
import WorkflowPageButton from '../../components/core/WorkflowPageButton';
import WorkflowTemporalResult from '../../components/summary/WorkflowTemporalResult';

import {
	BARCODE,
	CHECKBOX,
	DATE,
	DATETIME,
	IMAGE,
	LABEL,
	MULTI_LINE_TEXT_FIELD,
	NUMBER_FIELD,
	PAGE,
	SELECT,
	SIGNATURE,
	TEXT_FIELD,
	TIME,
} from '../utils/constants';

export const components = {
	[BARCODE]: {
		component: { Component: BtFormBarCodeScanner },
		dataType: dataTypes[STRING],
		summaryComponent: { Component: WorkflowBasicResult },
		validatable: true,
	},
	[CHECKBOX]: {
		component: { Component: BtFormCheckbox },
		dataType: dataTypes[BOOLEAN],
		summaryComponent: { Component: WorkflowBasicResult },
		validatable: true,
	},
	[DATE]: {
		component: {
			Component: BtFormDateTimePicker,
			props: { variant: 'date' },
		},
		dataType: dataTypes[TEMPORAL],
		summaryComponent: {
			Component: WorkflowTemporalResult,
			props: { variant: 'date' },
		},
		supplementalValidation: [
			['yup.typeError', 'A valid date must be specified'],
		],
		validatable: true,
	},
	[DATETIME]: {
		component: {
			Component: BtFormDateTimePicker,
			props: { variant: 'datetime' },
		},
		dataType: dataTypes[TEMPORAL],
		summaryComponent: {
			Component: WorkflowTemporalResult,
			props: { variant: 'datetime' },
		},
		supplementalValidation: [
			['yup.typeError', 'A valid date and time must be specified'],
		],
		validatable: true,
	},
	[IMAGE]: {
		component: { Component: BtFormImageCapture },
		dataType: dataTypes[ARRAY],
		summaryComponent: { Component: BtImageViewer },
		validatable: false, // TODO: can we make this true? validate the amount of images?
	},
	[LABEL]: {
		component: { Component: WorkflowLabel },
		dataType: dataTypes[STRING],
		summaryComponent: { Component: WorkflowBasicResult },
		validatable: false,
	},
	[MULTI_LINE_TEXT_FIELD]: {
		component: {
			Component: BtFormTextField,
			props: { multiline: true },
		},
		dataType: dataTypes[STRING],
		summaryComponent: { Component: WorkflowBasicResult },
		validatable: true,
	},
	[NUMBER_FIELD]: {
		component: { Component: BtFormTextField, props: { type: 'number' } },
		dataType: dataTypes[NUMBER],
		summaryComponent: { Component: WorkflowBasicResult },
		validatable: true,
	},
	[PAGE]: {
		component: { Component: WorkflowPageButton },
		validatable: false,
	},
	[SELECT]: {
		component: {
			Component: BtFormSelect,
			props: { fullWidth: true },
		},
		dataType: dataTypes[STRING],
		summaryComponent: { Component: WorkflowBasicResult },
		validatable: true,
	},
	[SIGNATURE]: {
		component: {
			Component: BtFormSignature,
		},
		dataType: dataTypes[STRING],
		summaryComponent: {
			Component: WorkflowImageResult,
			props: { isSignature: true },
		},
		validatable: true,
	},
	[TEXT_FIELD]: {
		component: { Component: BtFormTextField },
		dataType: dataTypes[STRING],
		summaryComponent: { Component: WorkflowBasicResult },
		validatable: true,
	},
	[TIME]: {
		component: {
			Component: BtFormDateTimePicker,
			props: { variant: 'time' },
		},
		dataType: dataTypes[TEMPORAL],
		summaryComponent: {
			Component: WorkflowTemporalResult,
			props: { variant: 'time' },
		},
		supplementalValidation: [
			['yup.typeError', 'A valid time must be specified'],
		],
		validatable: true,
	},
};

export const componentTypeExists = type =>
	Object.keys(components).includes(type);

export const dataTypeExists = type => Object.keys(dataTypes).includes(type);

export default components;
