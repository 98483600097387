import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useAppContext, useNavContext } from '../../context/ContextManager';

// COMPONENTS
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

// ICONS
import AddCircleIcon from '@mui/icons-material/AddCircle';
// import PermMediaOutlined from '@mui/icons-material/PermMediaOutlined';
// import SettingsIcon from '@mui/icons-material/Settings';
// import MyLocationIcon from '@mui/icons-material/MyLocation';
// import RouterIcon from '@mui/icons-material/Router';

// CUSTOM COMPONENTS
import {
	SelectionTable,
	SelectionTableContainer,
	SelectionTableHead,
	SelectionTableHeadRow,
	SelectionTableBody,
	SelectionTableRow,
	SelectionTableCell,
} from '../../components/generic/BtSelectionTable';
import SectionHeader from '../../components/generic/SectionHeader';

// Data for landing page selection table and contextual navigation
import IoT_options from './IoTOptions';

import IoT_breadcrumbs from './IoTBreadcrumbs';

import IoT_nav_item from './IoTNavItem';

export default function ContainerTaskList() {
	const [taskList, setTaskList] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	// const { appOrg, setAppOrg} = useAppContext();
	const { appOrg } = useAppContext();
	// navContext contextual navigation items
	const { setContextualNav } = useNavContext();

	const history = useHistory();

	// navContext breadcrumbs
	const { setBreadcrumbs } = useNavContext();

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				...IoT_breadcrumbs,
				{ text: 'Container Tasks', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([...IoT_nav_item, IoT_options]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	const handleClickAddTask = () => {
		history.push('/ContainerTaskRevision');
	};

	const onTaskClick = resource_uuid => () => {
		history.push('/ContainerTask/' + resource_uuid);
	};

	useEffect(
		() => {
			var req_url = `${
				process.env.REACT_APP_API_SERVER_URL
			}/v1/container_task`;

			Auth.currentSession().then(user_session => {
				let user_token = user_session.getAccessToken();
				fetch(req_url, {
					method: 'GET',
					headers: {
						Authorization: user_token.jwtToken,
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Organisation: appOrg.uuid,
					},
				})
					.then(res => res.json())
					.then(response => {
						setTaskList(response.task_list);
						setIsLoading(false);
					})
					.catch(error => {
						console.log(error);
						setIsLoading(false);
					});
			});
		},
		[appOrg.uuid]
	);

	return (
		<>
			{isLoading && (
				<Paper>
					<p>Loading...</p>
					<CircularProgress size={'120px'} />
				</Paper>
			)}

			{!isLoading && (
				<Container maxWidth="lg">
					<SectionHeader>Container Tasks</SectionHeader>
					<Button
						variant="outlined"
						startIcon={<AddCircleIcon />}
						onClick={handleClickAddTask}
					>
						Add New Container Task
					</Button>
					<SelectionTableContainer component={Box}>
						<SelectionTable aria-label="Container task table">
							<SelectionTableHead>
								<SelectionTableHeadRow>
									<SelectionTableCell>
										Name
									</SelectionTableCell>
									<SelectionTableCell>
										Revision
									</SelectionTableCell>
									<SelectionTableCell>
										Description
									</SelectionTableCell>
									<SelectionTableCell>
										Last Updated
									</SelectionTableCell>
								</SelectionTableHeadRow>
							</SelectionTableHead>
							<SelectionTableBody>
								{taskList.map((task, index) => (
									<SelectionTableRow
										key={index}
										onClick={onTaskClick(task.uuid)}
										title={task.name}
									>
										<SelectionTableCell
											component="th"
											scope="row"
										>
											{task.name}
										</SelectionTableCell>
										<SelectionTableCell>
											{task.latest_revision}
										</SelectionTableCell>
										<SelectionTableCell>
											{task.description}
										</SelectionTableCell>
										<SelectionTableCell>
											{new Date(
												task.last_timestamp
											).toLocaleString()}
										</SelectionTableCell>
									</SelectionTableRow>
								))}
							</SelectionTableBody>
						</SelectionTable>
					</SelectionTableContainer>
				</Container>
			)}
		</>
	);
}
