import React, {
	useState,
	useEffect,
	useRef,
	useMemo,
	memo,
	useLayoutEffect,
	useCallback,
} from 'react';
import { useTheme } from '@mui/material/styles';
import {
	LineChart,
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip,
	Legend,
	Line,
	ResponsiveContainer,
} from 'recharts';
import PropTypes from 'prop-types';

import { Box } from '@mui/system';
import { yAxisFormatter } from '../../../../components/charts/Utils';
import { defaultAxisLabels } from '../../../../components/charts/Utils';
import {
	chartMargin,
	fontSize,
	formatTimestamp,
	generateTimeSeriesTickValues,
	generateYAxisTickValues,
} from '../../../../components/charts/Utils';
import { CustomLegend } from './Generic/Legend';
import { CustomTooltip } from './Generic/Tooltip.js';
import { has } from 'lodash';
import { VisualisationError } from '../UiComponents/VisualisationError';

export default function LineChartComponent({
	// type,
	data,
	visOptions,
	palette,
}) {
	const [xTicks, setXTicks] = useState([]);
	const [xTickFormat, setXTickFormat] = useState('HH:mm');
	const [xSupplementaryTicks, setXSupplementaryTicks] = useState([]);
	const [xSupplementaryTickFormat, setXSupplementaryTickFormat] = useState(
		''
	);
	const [yTicks, setYTicks] = useState([]);
	const [timestampFormat, setTimestampFormat] = useState('DD/MM/YYYY HH:mm');
	const THEME = useTheme();
	const [showLegend, setShowLegend] = useState(true);
	const [showAxis, setShowAxis] = useState(true);
	const [noData, setNoData] = useState(true);
	const [showDots, setShowDots] = useState(false);
	// const [clickedLineID, setClickedLineId] = useState(null);
	const [clickedLineID, setClickedLineId] = useState([]);
	const [xAxisName, setXAxisName] = useState('start_time');
	const [error, setError] = useState(false);

	// memorize axis label values
	const defaultAxisLabelValues = useMemo(
		() =>
			defaultAxisLabels(
				visOptions?.ordinalAxis?.param?.label || '',
				visOptions?.valuePrimaryAxis?.label || ''
			),
		[visOptions]
	);
	const [yAxisLabel, setYAxisLabel] = useState(
		defaultAxisLabelValues.defaultYAxisLabel
	);
	const [xAxisLabel, setXAxisLabel] = useState(
		defaultAxisLabelValues.defaultXAxisLabel
	);

	// useEffect(() => console.log({ visOptions }), [visOptions]);

	const ref = useRef(null);

	let colours = [];
	colours.push('#5fc1c9');
	colours.push('#22c018');
	colours.push('#e29834');
	colours.push('#9046b6');
	colours.push('#dc564c');
	colours.push('#1c3249');

	useEffect(
		() => {
			if (!has(visOptions, 'ordinalAxis.param.value')) {
				return '';
			} else if (visOptions.ordinalAxis.param.value) {
				setXAxisName(visOptions.ordinalAxis.param.value);
			}
		},
		[visOptions]
	);

	// console.log({ data });

	useEffect(
		() => {
			if (ref.current) {
				if (visOptions?.legend?.disabled) {
					setShowLegend(false);
				} else {
					if (ref.current.offsetWidth < 500 && showLegend) {
						setShowLegend(false);
					} else if (
						ref.current.offsetWidth >= 500 &&
						showLegend === false
					) {
						setShowLegend(true);
					}
				}

				if (ref.current.offsetWidth < 500 && showLegend) {
					setXAxisLabel(false);
					setYAxisLabel(false);
				} else if (
					ref.current.offsetWidth >= 500 &&
					showLegend === false
				) {
					setXAxisLabel(defaultAxisLabelValues.defaultXAxisLabel);
					setYAxisLabel(defaultAxisLabelValues.defaultYAxisLabel);
				}

				if (ref.current.offsetWidth < 300 && showLegend) {
					setShowAxis(false);
				} else if (
					ref.current.offsetWidth >= 300 &&
					showLegend === false
				) {
					setShowAxis(true);
				}
			}
		},
		[ref.current]
	);

	const buildTimeSeries = useCallback(
		() => {
			const start = data[0][xAxisName];
			const end = data[data.length - 1][xAxisName];

			// console.log(start, end, data);

			const {
				ticks,
				supplementaryTicks,
				supplementaryTickFormat,
				timestampFormat,
				tickFormat,
			} = generateTimeSeriesTickValues(start, end);

			// console.log({ ticks, supplementaryTicks, supplementaryTickFormat });
			setXTicks(ticks);
			setXSupplementaryTicks(supplementaryTicks);
			setXSupplementaryTickFormat(supplementaryTickFormat);
			setXTickFormat(tickFormat);
			setTimestampFormat(timestampFormat);
		},
		[data, xAxisName]
	);

	useEffect(
		() => {
			// console.log('change to data', data);

			// Check that we have been passed valid data
			if (data === null || data === undefined) {
				setNoData(true);
			} else if (data.length === 1) {
				setShowDots(true);
				setNoData(false);
				buildTimeSeries();
			} else {
				setShowDots(false);
				setNoData(false);
				buildTimeSeries();
			}
		},
		[buildTimeSeries, data]
	);

	// console.log(data);

	const CustomXAxisTick = memo(function CustomXAxisTick({
		x,
		y,
		// stroke,
		payload,
	}) {
		return (
			<g transform={`translate(${x},${y})`}>
				<text
					x={0}
					y={0}
					dy={16}
					textAnchor="start"
					fill={THEME.palette.chart.tick}
					fontSize={fontSize}
				>
					{formatTimestamp(payload.value, xTickFormat)}
				</text>
				{xSupplementaryTicks?.includes(payload.value) && (
					<text
						x={0}
						y={0}
						dy={32}
						textAnchor="start"
						fill={THEME.palette.chart.tick}
						fontSize={fontSize}
					>
						{formatTimestamp(
							payload.value,
							xSupplementaryTickFormat
						)}
					</text>
				)}
			</g>
		);
	});

	const CustomYAxisTick = memo(function CustomYAxisTick({
		x,
		y,
		// stroke,
		payload,
	}) {
		return (
			<g transform={`translate(${x},${y})`}>
				<text
					x={0}
					y={0}
					dy="0.4em"
					textAnchor="end"
					fill={THEME.palette.chart.tick}
					fontSize={fontSize}
				>
					{yAxisFormatter(payload.value, visOptions)}
				</text>
			</g>
		);
	});

	useLayoutEffect(
		() => {
			if (ref.current) {
				setYTicks(
					generateYAxisTickValues(
						visOptions,
						data,
						ref.current.offsetHeight,
						setError
					)
				);
			}
		},
		[data, visOptions]
	);

	if (error) {
		// const description =
		// 	process.env.NODE_ENV === 'development'
		// 		? `No component defined for ${type}`
		// 		: null;
		return <VisualisationError description="check the console" />;
	}

	// console.log({ data, yTicks });

	return (
		<Box ref={ref} sx={{ height: '100%' }}>
			{!noData && (
				<ResponsiveContainer width={'100%'} height={'100%'}>
					<LineChart
						data={data}
						margin={{
							...chartMargin,
						}}
					>
						<CartesianGrid
							stroke={THEME.palette.chart.gridColor}
							vertical={false}
						/>

						{showAxis && (
							<XAxis
								type="number"
								dataKey={visOptions?.ordinalAxis?.param?.value}
								domain={['dataMin', 'dataMax']}
								ticks={xTicks}
								tick={<CustomXAxisTick />}
								height={
									xSupplementaryTicks?.length > 0 ? 50 : 30
								}
								stroke={THEME.palette.chart.tick}
							/>
						)}

						{showAxis && (
							<YAxis
								label={{
									...yAxisLabel,
									fill: THEME.palette.chart.label,
								}}
								domain={yTicks.domain}
								ticks={yTicks.ticks}
								tick={<CustomYAxisTick />}
								axisLine={false}
								tickLine={false}
								fontSize={fontSize}
								stroke={THEME.palette.chart.tick}
							/>
						)}

						<Tooltip
							wrapperStyle={{ outline: 'none' }}
							content={props => (
								<CustomTooltip
									sortValues
									visOptions={visOptions}
									timestampFormat={timestampFormat}
									{...props}
								/>
							)}
						/>
						{showLegend && (
							<Legend
								content={
									<CustomLegend
										clickable={
											visOptions?.params.length > 1
												? true
												: false
										}
										onChange={setClickedLineId}
										selectedIds={clickedLineID}
										visOptionsParams={visOptions.params}
										containerWidth={ref.current.offsetWidth}
										// {...this.props}
									/>
								}
							/>
						)}

						{(visOptions?.params || []).map((param, index) => (
							<Line
								type="monotone"
								connectNulls={true}
								dataKey={param.value}
								stroke={palette[index]}
								strokeWidth={
									clickedLineID.includes(index) ||
									clickedLineID.length === 0
										? 2
										: 1
								}
								opacity={
									clickedLineID.includes(index) ||
									clickedLineID.length === 0
										? 1
										: 0.3
								}
								key={index}
								dot={showDots}
								// isAnimationActive={false}
								animationDuration={200}
								name={param.value}
							/>
						))}
					</LineChart>
				</ResponsiveContainer>
			)}
		</Box>
	);
}

LineChartComponent.propTypes = {
	data: PropTypes.array.isRequired,
	visOptions: PropTypes.object.isRequired,
	palette: PropTypes.array.isRequired,
};
