import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	Card,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import RetryIcon from '@mui/icons-material/Refresh';
import SadFaceIcon from '@mui/icons-material/SentimentDissatisfied';
import ForbiddenIcon from '@mui/icons-material/DoNotDisturb';
import CloudOffIcon from '@mui/icons-material/CloudOff';

const Container = styled('div')(({ fullscreen, theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
	minHeight: '100%',
	padding: fullscreen ? 40 : 0,

	[theme.breakpoints.down('sm')]: {
		padding: 0,
	},
}));

const TileErrorContainer = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
	minHeight: '100%',
	padding: 5,
}));

const Content = styled(Card)(() => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
	maxWidth: 480,
	margin: '0 auto',
	minHeight: '100%',
	padding: '2em',
	gap: 20,
}));

const ERROR = 'error';
const NOT_FOUND = 'notfound';
const FORBIDDEN = 'forbidden';
const SERVER = 'server';
const PERMISSION = 'permission';
const SUBSCRIPTION = 'subscription';

const XS = 'xs';
const SM = 'sm';
const MD = 'md';
const LG = 'lg';
const DEFAULT = 'default';

export default function BtError({
	action,
	actionIcon,
	actionLabel,
	description,
	fullScreen,
	onClose,
	style,
	title,
	variant,
	tileError,
	size,
}) {
	const theme = useTheme();
	const screenDownSm = useMediaQuery(theme.breakpoints.down('sm'));

	const attributes = useMemo(
		() => {
			if (variant === NOT_FOUND) {
				return {
					title: title ?? 'Not Found',
					description:
						description ?? 'Could not find the requested resource.',
					icon: SadFaceIcon,
				};
			}
			if (variant === FORBIDDEN) {
				return {
					title: title ?? 'Access Denied',
					description:
						description ??
						'You do not have permission to view this content. Please contact your administrator.',
					icon: ForbiddenIcon,
				};
			}
			if (variant === SERVER) {
				return {
					title: title ?? 'Server',
					description:
						description ??
						'You do not have permission to view this content. Please contact your administrator.',
					icon: CloudOffIcon,
				};
			}
			if (variant === PERMISSION) {
				return {
					title: title ?? 'Permissions Error',
					description:
						description ??
						'There seems to be a problem with your permissions.',
					icon: SadFaceIcon,
				};
			}
			if (variant === SUBSCRIPTION) {
				return {
					title: title ?? 'Subscriptions Error',
					description:
						description ??
						'There seems to be a problem with your subscriptions.',
					icon: SadFaceIcon,
				};
			}
			return {
				title: title ?? 'Error',
				description: description ?? 'An error has occurred.',
				icon: ErrorIcon,
			};
		},
		[description, title, variant]
	);

	const Icon = useMemo(() => attributes.icon, [attributes]);

	const iconSize = useMemo(
		() => {
			switch (size) {
				case XS:
					return 30;
				case SM:
					return 60;
				default:
					return 140;
			}
		},
		[size]
	);

	const showIcon = useMemo(
		() => {
			if (size === XS && !action) {
				return true;
			} else if (size === XS && action) {
				return false;
			} else {
				return true;
			}
		},
		[action, size]
	);

	const ErrorBody = () => {
		return (
			<>
				{showIcon && (
					<Icon
						style={{
							fontSize: iconSize,
							color: theme.palette.indicators.error.text,
						}}
					/>
				)}
				{!tileError && (
					<Typography
						align="center"
						variant="h3"
						style={{
							marginBottom: '0.5em',
							color: theme.palette.indicators.error.text,
						}}
					>
						{attributes.title}
					</Typography>
				)}

				{typeof attributes.description === 'string' ? (
					<Typography align="center">
						{attributes.description}
					</Typography>
				) : (
					attributes.description
				)}
				{(action || onClose) && (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							marginTop: size === XS ? '0.5em' : '2em',
							alignItems: 'center',
						}}
					>
						{!!action && (
							<Button
								disableElevation
								onClick={action}
								startIcon={actionIcon}
								variant="outlined"
							>
								{actionLabel}
							</Button>
						)}
						{!!onClose && (
							<Button
								disableElevation
								onClick={onClose}
								startIcon={<CloseIcon />}
								style={{ marginTop: action ? '0.8em' : 0 }}
							>
								Close
							</Button>
						)}
					</div>
				)}
			</>
		);
	};

	if (tileError) {
		return (
			<TileErrorContainer>
				<ErrorBody />
			</TileErrorContainer>
		);
	}

	return (
		<Container fullscreen={+fullScreen} style={style}>
			<Content elevation={screenDownSm || !fullScreen ? 0 : 1}>
				<ErrorBody />
			</Content>
		</Container>
	);
}

BtError.defaultProps = {
	actionIcon: <RetryIcon />,
	actionLabel: 'Retry',
	fullScreen: true,
	variant: 'error',
	size: DEFAULT,
};

BtError.propTypes = {
	action: PropTypes.func,
	actionIcon: PropTypes.node,
	actionLabel: PropTypes.string,
	description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	fullScreen: PropTypes.bool,
	onClose: PropTypes.func,
	size: PropTypes.oneOf([XS, SM, MD, LG, DEFAULT]),
	style: PropTypes.object,
	tileError: PropTypes.bool,
	title: PropTypes.string,
	variant: PropTypes.oneOf([
		ERROR,
		FORBIDDEN,
		NOT_FOUND,
		PERMISSION,
		SERVER,
		SUBSCRIPTION,
	]),
};
