import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import {
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import BtDialog from '../../../components/generic/BtDialog';
import {
	BtForm,
	BtFormActionsContainer,
	BtFormContent,
	BtFormTextField,
} from '../../../components/generic/forms';
import BtFormRadio from '../../../components/generic/forms/BtFormRadio';
import CreateActionButtons from '../common/CreateActionButtons';
import { dataSetAdd, dataStreamAdd, ImageRepoAdd } from '../../../API';

const blankDefaults = {
	name: '',
	description: '',
};

export default function NewDataSourceDialog({
	canCreateDataSet,
	canCreateDataStream,
	canCreateImageRepo,
	existingNames,
	onClose,
	open,
}) {
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();

	const [sending, setSending] = useState(false);

	const schema = useMemo(
		() =>
			yup.object({
				name: yup
					.string()
					.min(3)
					.notOneOf(
						existingNames || [],
						'A data source with this name already exists'
					)
					.required()
					.label('Data Source Name'),
				description: yup.string().label('Data Source Description'),
				type: yup
					.string()
					.default('DataSet')
					.required(),
			}),
		[existingNames]
	);

	const onSubmit = useCallback(
		async (values, reset) => {
			setSending(true);
			try {
				// Switch for the data source type requested
				if (values.type === 'DataSet') {
					const newDataSet = {
						name: values.name,
						description: values.description,
						primaryValueKey: [],
					};
					const { uuid } = await dataSetAdd({
						newDataSet: newDataSet,
					});

					reset(values);
					enqueueSnackbar('Created Data Set', {
						variant: 'success',
					});
					history.push(`/DataSources/DataSet/${uuid}`);
				} else if (values.type === 'DataStream') {
					const newDataStream = {
						name: values.name,
						description: values.description,
					};
					const { uuid } = await dataStreamAdd({
						newDataStream: newDataStream,
					});

					reset(values);
					enqueueSnackbar('Created Data Stream', {
						variant: 'success',
					});
					history.push(`/DataSources/DataStream/${uuid}`);
				} else if (values.type === 'ImageRepo') {
					const newImageRepo = {
						name: values.name,
						description: values.description,
					};
					const { uuid } = await ImageRepoAdd({
						newImageRepo: newImageRepo,
					});

					reset(values);
					enqueueSnackbar('Created Image Repo', {
						variant: 'success',
					});
					history.push(`/DataSources/ImageRepo/${uuid}`);
				} else {
					setSending(false);
					enqueueSnackbar('Error creating data source', {
						variant: 'error',
					});
				}
			} catch (error) {
				console.log(error);
				setSending(false);
				enqueueSnackbar('Failed to create Data Source', {
					variant: 'error',
				});
			}
		},
		[enqueueSnackbar, history]
	);

	return (
		<BtDialog open={open} onClose={onClose} minwidth="sm" fullWidth>
			{sending && (
				<LinearProgress
					style={{
						position: 'absolute',
						top: 0,
						width: '100%',
					}}
				/>
			)}
			<DialogTitle>Create A New Data Source</DialogTitle>
			<BtForm
				onSubmit={onSubmit}
				sending={sending}
				validationSchema={schema}
				defaultValues={blankDefaults}
			>
				<DialogContent>
					<BtFormContent>
						<BtFormTextField name="name" label="Name" autoFocus />
						<BtFormTextField
							name="description"
							label="Description"
							multiline
						/>
						<BtFormRadio
							name="type"
							label="Data Source Type"
							key="FIELD"
							items={[
								...(canCreateDataSet
									? [{ value: 'DataSet', label: 'Data Set' }]
									: []),
								...(canCreateDataStream
									? [
											{
												value: 'DataStream',
												label: 'Data Stream',
											},
									  ]
									: []),
								...(canCreateImageRepo
									? [
											{
												value: 'ImageRepo',
												label: 'Image Repo',
											},
									  ]
									: []),
							]}
						/>
					</BtFormContent>
				</DialogContent>
				<DialogActions>
					<BtFormActionsContainer>
						<CreateActionButtons
							onClose={onClose}
							sending={sending}
						/>
					</BtFormActionsContainer>
				</DialogActions>
			</BtForm>
		</BtDialog>
	);
}

NewDataSourceDialog.propTypes = {
	canCreateDataSet: PropTypes.bool,
	canCreateDataStream: PropTypes.bool,
	canCreateImageRepo: PropTypes.bool,
	existingNames: PropTypes.arrayOf(PropTypes.string),
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};
