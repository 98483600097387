import React from 'react';
import PropTypes from 'prop-types';

import {
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from '@mui/material';

import DataType from './DataType';
import { nonNilTypes } from '../../../utils/commonDataTypes';

export default function DataTypeList({ disabled, onClick }) {
	return (
		<List dense style={{ padding: 0 }}>
			{nonNilTypes.map(type => (
				<ListItemButton
					key={type}
					disabled={disabled}
					onClick={event => onClick(event, type)}
				>
					<ListItemIcon style={{ minWidth: 30 }}>
						<DataType type={type} />
					</ListItemIcon>
					<ListItemText
						primary={type}
						primaryTypographyProps={{
							style: { fontWeight: 'bold' },
						}}
						style={{
							textTransform: 'capitalize',
						}}
					/>
				</ListItemButton>
			))}
		</List>
	);
}

DataTypeList.propTypes = {
	disabled: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
};
