const {
	ERROR,
	setOperator,
	evaluateExpression,
	OPERATION_MODE,
} = require('../expression.js');

/**
 * @type {ExpressionFunction<any, boolean, {operator:string;}>}
 */
function isEqual(context, args, options) {
	const arr = !Array.isArray(args) ? [args] : args;

	const operatorKey = options.operator;

	if (arr.length !== 2) {
		throw new Error(ERROR.INVALID_NUMBER_ARGS(operatorKey, 2, 2));
	}
	const value1 = evaluateExpression(context, arr[0]);

	const value2 = evaluateExpression(context, arr[1]);

	// if we've been passed the same implicit value or the exact same object
	// pointers
	if (value1 === value2) {
		return true;
	}

	if (typeof value1 === 'object') {
		if (
			context.operationMode !== OPERATION_MODE.QUERY &&
			(!value1 || !value2 || typeof value2 !== typeof value1)
		) {
			return false;
		}

		if (value1 === null) {
			return false;
		}

		if (value1 instanceof Date && value2 instanceof Date) {
			return value1.getTime() === value2.getTime();
		}
		if (value1 instanceof Date || value2 instanceof Date) {
			return false;
		}
		if (Array.isArray(value1) && Array.isArray(value2)) {
			if (value1.length !== value2.length) {
				return false;
			}

			for (let index = 0; index < value1.length; index++) {
				if (
					!isEqual(context, [value1[index], value2[index]], options)
				) {
					return false;
				}
			}
			return true;
		}
		if (Array.isArray(value1)) {
			if (context.operationMode === OPERATION_MODE.QUERY) {
				for (let a = 0; a < value1.length; a++) {
					if (isEqual(context, [value1[a], value2], options)) {
						return true;
					}
				}
			}
			return false;
		}

		const k1 = Object.keys(value1);
		const k2 = Object.keys(value2);

		if (!isEqual(context, [k1, k2], options)) {
			// TODO - ordering of keys matters for object comparison
			//        javascript doesn't maintain object property order...
			//        will lead to differing results where keys are numbers
			return false;
		}

		// both are objects both have keys that match
		for (let k = 0; k < k1.length; k++) {
			if (!isEqual(context, [value1[k], value2[k]], options)) {
				return false;
			}
		}
		// we got through all the checks
		return true;
	}

	// symbols and implicit types
	return (value1 ?? null) === (value2 ?? null);
}

setOperator(
	'$eq',
	(context, args, options) =>
		isEqual(
			context,
			args,
			options ? { ...options, operator: '$eq' } : { operator: '$eq' }
		),
	[OPERATION_MODE.AGGREGATE, OPERATION_MODE.QUERY]
);

setOperator(
	'$ne',
	(context, args, options) =>
		!isEqual(
			context,
			args,
			options ? { ...options, operator: '$ne' } : { operator: '$ne' }
		),
	[OPERATION_MODE.AGGREGATE, OPERATION_MODE.QUERY]
);
