import React, { useCallback, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Auth } from 'aws-amplify';
import { useNavContext } from '../context/ContextManager';
import moment from 'moment';

// MUI COMPONENTS
import Paper from '@mui/material/Paper';
import { Typography, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// Data for landing page selection table and contextual navigation
import developer_options from './DeveloperOptions';
import developer_breadcrumbs from './DeveloperBreadcrumbs';
import developer_nav_item from './DeveloperNavItem';

// CUSTOM COMPONENTS
import Chart from '../components/generic/Chart';

// API requests
import { serviceStatsGet, serviceLogGet } from '../API';
import useFetch from '../hooks/useFetch';

const styles = makeStyles(theme => ({
	button_row: {},
	root: {
		width: '100%',
		minWidth: 200,
	},
	config_paper: {
		padding: 10,
	},
	tableHeader: {
		fontWeight: 700,
	},
	tableRow: {
		cursor: 'pointer',
		borderBottom: '1px solid red',
		borderBottomColor: theme.palette.primary.shade_dark,
		'&:hover': {
			backgroundColor: theme.palette.primary.shade_light,
		},
	},
}));

export default function ServiceStats() {
	const classes = styles();
	const [isLoading, setIsLoading] = useState(true);
	const [serviceStats, setServiceStats] = useState([]);
	const [chartData, setChartData] = useState([]);
	const [chartParams, setChartParams] = useState([]);
	const [connectedDevices, setConnectedDevices] = useState(0);
	const [serviceLogs, setServiceLogs] = useState([]);
	const { setContextualNav } = useNavContext();
	const { setBreadcrumbs } = useNavContext();

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				...developer_breadcrumbs,
				{ text: 'Service Stats', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([...developer_nav_item, ...developer_options]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	const loadStats = useCallback(() => {
		const getStats = async () => {
			try {
				const serviceStatsUpdate = await serviceStatsGet();
				setServiceStats(serviceStatsUpdate.service_stats);
				setConnectedDevices(serviceStatsUpdate.connected_devices);
				var new_stats = serviceStatsUpdate.service_stats.map(stats => {
					var hour_data = {};
					//hour_data['start_time'] = stats.time_bucket;
					hour_data['time'] = moment(stats.time_bucket).valueOf();
					hour_data['packets_rx'] = stats.packets_rx;
					hour_data['packets_tx'] = stats.packets_tx;
					hour_data['bytes_rx'] = stats.bytes_rx;
					hour_data['bytes_tx'] = stats.bytes_tx;
					hour_data['con_event'] = stats.con_event;
					return hour_data;
				});
				setChartData(new_stats);
				setIsLoading(false);
			} catch (error) {
				console.log(error);
			}
		};

		getStats();
	}, []);

	const loadLogs = useCallback(() => {
		const getLog = async () => {
			try {
				const serviceLogUpdate = await serviceLogGet();
				setServiceLogs(serviceLogUpdate);
			} catch (error) {
				console.log(error);
			}
		};

		getLog();
	}, []);

	useEffect(
		() => {
			setChartParams([
				'packets_rx',
				'packets_tx',
				'bytes_rx',
				'bytes_tx',
				'con_event',
			]);

			loadStats();
			loadLogs();

			const timer = setInterval(() => {
				loadStats();
				loadLogs();
			}, 5000);
			return () => clearInterval(timer);
		},
		[loadStats, loadLogs]
	);

	return (
		<>
			{isLoading && (
				<Paper>
					<p>Loading...</p>
					<CircularProgress size={'120px'} />
				</Paper>
			)}

			{!isLoading && (
				<>
					<Paper
						elevation={3}
						style={{
							maxWidth: 936,
							margin: 'auto',
							overflow: 'hidden',
						}}
					>
						<Typography variant={'h3'}>
							Service dashboard
						</Typography>

						<Typography variant={'h5'}>
							Connected Devices: {connectedDevices}
						</Typography>

						<TableContainer component={Paper}>
							<Table
								className={classes.table}
								size="small"
								aria-label="a dense table"
							>
								<TableHead>
									<TableRow>
										<TableCell
											className={classes.tableHeader}
										>
											Service
										</TableCell>
										<TableCell
											className={classes.tableHeader}
										>
											Time
										</TableCell>
										<TableCell
											className={classes.tableHeader}
										>
											Connection Events
										</TableCell>
										<TableCell
											className={classes.tableHeader}
										>
											Packets RX
										</TableCell>
										<TableCell
											className={classes.tableHeader}
										>
											Packets TX
										</TableCell>
										<TableCell
											className={classes.tableHeader}
										>
											Bytes RX
										</TableCell>
										<TableCell
											className={classes.tableHeader}
										>
											Bytes TX
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{serviceStats.map((stats, index) => (
										<TableRow
											key={index}
											title={stats.time_bucket}
										>
											<TableCell
												component="th"
												scope="row"
											>
												{stats.service}
											</TableCell>
											<TableCell>
												{new Date(
													stats.time_bucket
												).toLocaleString()}
											</TableCell>
											<TableCell>
												{stats.con_event}
											</TableCell>
											<TableCell>
												{stats.packets_rx}
											</TableCell>
											<TableCell>
												{stats.packets_tx}
											</TableCell>
											<TableCell>
												{stats.bytes_rx}
											</TableCell>
											<TableCell>
												{stats.bytes_tx}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>

					<Box sx={{ height: '500px' }}>
						<Chart
							data={chartData}
							param_names={chartParams}
							xaxis_name="time"
						/>
					</Box>

					<Paper>
						<TableContainer component={Paper}>
							<Table
								className={classes.table}
								size="small"
								aria-label="a dense table"
							>
								<TableHead>
									<TableRow>
										<TableCell
											className={classes.tableHeader}
										>
											Service UUID
										</TableCell>
										<TableCell
											className={classes.tableHeader}
										>
											Time
										</TableCell>
										<TableCell
											className={classes.tableHeader}
										>
											Device UID
										</TableCell>
										<TableCell
											className={classes.tableHeader}
										>
											Event Code
										</TableCell>
										<TableCell
											className={classes.tableHeader}
										>
											Event Message
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{serviceLogs.map((log, index) => (
										<TableRow
											key={index}
											title={log.timestamp}
										>
											<TableCell
												component="th"
												scope="row"
											>
												{log.service_uuid}
											</TableCell>
											<TableCell>
												{new Date(
													log.timestamp
												).toLocaleString()}
											</TableCell>
											<TableCell>
												{log.device_uid}
											</TableCell>
											<TableCell>
												{log.event_code}
											</TableCell>
											<TableCell>{log.message}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>
				</>
			)}
		</>
	);
}
