import React, {
	Children,
	cloneElement,
	useCallback,
	useMemo,
	useState,
} from 'react';
import PropTypes from 'prop-types';

import { Button, IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material';

import CommentsIconButton from '../../../../../components/generic/comments/CommentsIconButton';
import {
	FULL_WIDTH_COMPONENTS,
	NON_COMMENTABLE,
} from '../../processing/utils/constants';
import { useWorkflowGroupContext } from '../../contexts/WorkflowGroupContext';
import { useWorkflowPageContext } from '../../contexts/WorkflowPageContext';
import { useWorkflowSessionContext } from '../../contexts/WorkflowSessionContext';
import WorkflowInfoModal from './WorkflowInfoModal';

const Container = styled('div')(() => ({
	width: '100%',
}));

const Content = styled('div')(({ fullwidth }) => ({
	display: 'flex',
	flexDirection: fullwidth ? 'column' : 'row',
	justifyContent: 'space-between',
	width: '100%',
}));

const OptionsContainer = styled('div')(({ fullwidth, hasoptions }) => ({
	alignItems: 'center',
	display: 'flex',
	marginLeft: hasoptions ? '0.6em' : 0,
	marginTop: fullwidth ? 10 : 0,
}));

const actionButtonStyle = {
	marginRight: 10,
};

const ActionButtonSwitch = ({
	actionButton,
	condensedActionButton,
	fullWidth,
}) => {
	if (fullWidth) {
		return actionButton;
	}

	return condensedActionButton;
};

export default function WorkflowComponentWrapper({
	children,
	component,
	index,
}) {
	const {
		commentAvailability,
		fetchingComponents,
	} = useWorkflowSessionContext();
	const { setComments } = useWorkflowPageContext();

	const groupContext = useWorkflowGroupContext();
	const { count, removeElement, primary, primaryAnswers, primaryLock } =
		groupContext || {};

	const [infoVisible, setInfoVisible] = useState(false);

	const { comments, description, minCount, name, type, uuid, refUuid } =
		component || {};

	const deletable = useMemo(
		() => {
			if (primary && primaryLock && index < primaryAnswers?.length) {
				return false;
			}

			return (count || 1) > (minCount || 1);
		},
		[count, index, minCount, primary, primaryAnswers, primaryLock]
	);

	const hasPrompt = useMemo(
		() => description && !groupContext && type !== 'Page',
		[description, groupContext, type]
	);

	const commentable = useMemo(
		() =>
			!NON_COMMENTABLE.includes(type) &&
			commentAvailability.components !== 'hidden',
		[commentAvailability, type]
	);

	const fullWidth = useMemo(() => FULL_WIDTH_COMPONENTS.includes(type), [
		type,
	]);

	const handleCommentsPress = useCallback(
		() => setComments(refUuid, comments),
		[comments, refUuid, setComments]
	);

	const processedChildren = Children.map(children, child => {
		if ((fetchingComponents || []).includes(uuid)) {
			return cloneElement(child, { fetching: true });
		}

		return child;
	});

	return (
		<>
			<Container>
				<Content fullwidth={+fullWidth}>
					{processedChildren}
					<OptionsContainer
						fullwidth={+fullWidth}
						hasoptions={+(deletable || hasPrompt || commentable)}
					>
						{deletable && (
							<ActionButtonSwitch
								actionButton={
									<Button
										color="error"
										disableElevation
										onClick={() => removeElement(uuid)}
										startIcon={<DeleteIcon />}
										style={actionButtonStyle}
									>
										Delete
									</Button>
								}
								condensedActionButton={
									<Tooltip disableInteractive title="Delete">
										<IconButton
											onClick={() => removeElement(uuid)}
										>
											<DeleteIcon />
										</IconButton>
									</Tooltip>
								}
								fullWidth={fullWidth}
							/>
						)}
						{hasPrompt && (
							<ActionButtonSwitch
								actionButton={
									<Button
										disableElevation
										onClick={() => setInfoVisible(true)}
										startIcon={<InfoIcon />}
										style={actionButtonStyle}
									>
										Info
									</Button>
								}
								condensedActionButton={
									<Tooltip
										disableInteractive
										title="More Info"
									>
										<IconButton
											onClick={() => setInfoVisible(true)}
										>
											<InfoIcon />
										</IconButton>
									</Tooltip>
								}
								fullWidth={fullWidth}
							/>
						)}
						{commentable && (
							<CommentsIconButton
								comments={comments}
								condensed={!fullWidth}
								onClick={handleCommentsPress}
							/>
						)}
					</OptionsContainer>
				</Content>
			</Container>
			<WorkflowInfoModal
				info={description}
				onClose={() => setInfoVisible(false)}
				open={infoVisible}
				title={name}
			/>
		</>
	);
}

WorkflowComponentWrapper.propTypes = {
	children: PropTypes.node.isRequired,
	component: PropTypes.object.isRequired,
	index: PropTypes.number,
};
