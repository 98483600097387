import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReplayIcon from '@mui/icons-material/Replay';
import { useHistory } from 'react-router-dom';
import { v1 as generateUuid } from 'uuid';

import BtError from '../../components/generic/BtError';
import BtLoading from '../../components/generic/BtLoading';
import { useAppContext, useNavContext } from '../../context/ContextManager';
import useFetch from '../../hooks/useFetch';
import workflow_nav_item from './WorkflowsNavItem';
import workflow_options from './WorkflowsOptions';
import WorkflowSession from './session/WorkflowSession';
import { WorkflowSessionContextProvider } from './session/contexts/WorkflowSessionContext';
import { WorkflowStickyContextProvider } from './session/contexts/WorkflowStickyContext';
import {
	workflowSubmissionCreate,
	workflowSubmissionGet,
	workflowTemplateGetVersion,
} from '../../API';

export default function WorkflowSubmission({ match }) {
	const { id } = match.params;

	const history = useHistory();
	const { setBreadcrumbs, setContextualNav } = useNavContext();
	const submissionRequest = useFetch(workflowSubmissionGet).request;
	const templateRequest = useFetch(workflowTemplateGetVersion).request;
	const { userInfo } = useAppContext();

	const [error, setError] = useState();
	const [loading, setLoading] = useState(null);
	const [submission, setSubmission] = useState(null);

	const repeatTemplate = useCallback(
		async () => {
			setLoading(true);

			try {
				const newUuid = generateUuid();

				const newSubmission = {
					uuid: newUuid,
					due_date: null,
					template: {
						uuid: submission.template.uuid,
						version: submission.template.version,
					},
					create_timestamp: Date.now(),
					data: {},
					log: [
						{
							timestamp: Date.now(),
							uuid: generateUuid(),
							issuer_type: 'User',
							issuer_id: userInfo.uuid,
							status: 'InProgress',
						},
					],
					session_data: { tree: null },
				};

				await workflowSubmissionCreate({
					newSubmission: newSubmission,
				});

				setError(null);

				history.replace(`/Workflows/Submissions/${newUuid}`);
			} catch (error) {
				console.error(
					'Creation Error',
					'An error occurred when attempting to create the submission.'
				);

				setError({
					action: () => history.push('/Workflows/Submissions'),
					actionIcon: <ArrowBackIcon />,
					actionLabel: 'Return',
					description:
						'An error occurred when attempting to create a new workflow.',
					title: 'Creation Error',
				});

				setLoading(false);
			}
		},
		[history, submission, userInfo]
	);

	useEffect(
		() => {
			setContextualNav([...workflow_nav_item, ...workflow_options]);

			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	useEffect(
		() => {
			const load = async () => {
				const request = () => load();

				const {
					data: newSubmission,
					ok: subOk,
				} = await submissionRequest({ workflowSubmissionUuid: id });
				// console.log('Submission', newSubmission);
				if (!subOk) {
					setError({
						action: request,
						actionIcon: <ReplayIcon />,
						actionLabel: 'Retry',
						description:
							'An error occurred when attempting to retrieve the workflow submission.',
						title: 'Retrieval Error',
					});

					return;
				}

				const {
					data: newTemplate,
					ok: templateOk,
				} = await templateRequest({
					workflowTemplateUuid: newSubmission.template.uuid,
					version: newSubmission.template.version,
				});

				if (!templateOk) {
					setError({
						action: request,
						actionIcon: <ReplayIcon />,
						actionLabel: 'Retry',
						description:
							'An error occurred when attempting to retrieve the workflow template.',
						title: 'Retrieval Error',
					});

					return;
				}

				setError(null);

				const newSession = {
					...newSubmission,
					template: {
						dataSources: newTemplate.template.dataSources,
						description: newTemplate.description,
						disableSummary: newTemplate.template.disableSummary,
						flow: newTemplate.template.flow,
						pages: newTemplate.template.pages,
						name: newTemplate.name,
						repeatable: newTemplate.template.repeatable,
						uuid: newSubmission.template.uuid,
						version: newSubmission.template.version,
					},
				};

				setSubmission(newSession);

				setLoading(false);
			};

			if (id) {
				setLoading(true);

				load();
			}
		},
		[id, submissionRequest, templateRequest]
	);

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'Workflows', link: '/Workflows' },
				{ text: 'Submissions', link: '/Workflows/Submissions' },
				{ text: submission?.template.name ?? 'Loading...', link: '' },
			]);
		},
		[setBreadcrumbs, submission]
	);

	if (error) {
		const { action, actionIcon, actionLabel, description, title } = error;

		return (
			<BtError
				action={action}
				actionIcon={actionIcon}
				actionLabel={actionLabel}
				description={description}
				title={title}
			/>
		);
	}

	return (
		<>
			<BtLoading loading={loading}>
				{submission && (
					<WorkflowStickyContextProvider>
						<WorkflowSessionContextProvider
							repeatTemplate={repeatTemplate}
							submission={submission}
						>
							<WorkflowSession session={submission} />
						</WorkflowSessionContextProvider>
					</WorkflowStickyContextProvider>
				)}
			</BtLoading>
		</>
	);
}

WorkflowSubmission.propTypes = { match: PropTypes.object };
