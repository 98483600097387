const { OPERATION_MODE } = require('../../expression');
const { getExpressionType, ERROR } = require('../../expression/expression');
const {
	setOperatorSchemaFn,
	evaluateExpressionSchema,
} = require('../expression');
const {
	newDefaultSchema,
	getObjectShape,
	setObjectShape,
} = require('../utils');

const operatorKey = '$mergeObjects';

setOperatorSchemaFn(
	operatorKey,
	(context, args, options) => {
		const newObj = newDefaultSchema('object');
		let shape = getObjectShape(newObj);

		let arr = args;
		let inpIsExpr = false;

		if (!Array.isArray(args)) {
			const arg = evaluateExpressionSchema(context, args);

			if (arg.type !== 'array') {
				throw ERROR.INVALID_OPERATOR_ARGUMENT(
					'$mergeObjects',
					'object as input but input is of type ' + arg.type
				);
			}

			if (!arg.tupleContent) {
				return arg.arrayContent;
			}

			arr = arg.tupleContent;
			inpIsExpr = true;
		}

		arr.forEach((value, idx) => {
			let expr = value;
			if (!inpIsExpr) {
				expr = evaluateExpressionSchema(context, value);
			}

			if (expr.type !== 'object') {
				throw ERROR.INVALID_OPERATOR_ARGUMENT(
					'$mergeObjects',
					'object as input',
					value,
					idx
				);
			}

			if (expr?.objectContent) {
				for (const field in expr.objectContent) {
					if (Object.hasOwnProperty.call(expr.objectContent, field)) {
						shape[field] = expr.objectContent[field];
					}
				}
			}
		});

		setObjectShape(newObj, shape);

		return newObj;
	},
	[OPERATION_MODE.AGGREGATE]
);
