const {
	ERROR,
	setOperator,
	evaluateExpression,
	OPERATION_MODE,
} = require('../expression.js');

const operatorKey = '$and';

setOperator(
	operatorKey,
	/**
	 * @type {ExpressionFunction<unknown[],boolean>}
	 */
	(context, args, options) => {
		if (!Array.isArray(args)) {
			throw new Error(
				ERROR.INVALID_OPERATOR_ARGUMENT(
					operatorKey,
					'an array of expressions'
				)
			);
		}

		const arr = args;
		const match = true;

		for (let pos = 0; pos < arr.length; pos++) {
			const arg = arr[pos];

			/**
			 * @type {any}
			 */
			let value = evaluateExpression(context, arg);

			if (typeof value !== 'boolean') {
				if (value === undefined || value === null) {
					return false;
				}
			}

			if (!value) {
				return false;
			}
		}

		return match;
	},
	[OPERATION_MODE.AGGREGATE, OPERATION_MODE.QUERY]
);
