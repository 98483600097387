import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	CircularProgress,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
} from '@mui/material';
import CreateIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import BtDialog from '../../../components/generic/BtDialog';
import {
	BtForm,
	BtFormActionsContainer,
	BtFormContent,
	BtFormCheckbox,
	BtFormTextField,
	withFormContextMethods,
} from '../../../components/generic/forms';
import { apiManagerAddIpFilter, ipGet } from '../../../API';
import useFetch from '../../../hooks/useFetch';

const ActionButtons = withFormContextMethods(
	({ formState: { isDirty }, onClose, sending }) => {
		return (
			<>
				<Button variant="outlined" onClick={onClose} disabled={sending}>
					Cancel
				</Button>
				<Button
					variant="contained"
					disabled={!isDirty || sending}
					type="submit"
					startIcon={
						sending ? (
							<CircularProgress size={20} />
						) : (
							<CreateIcon />
						)
					}
					disableElevation
				>
					Add IP Address
				</Button>
			</>
		);
	}
);

export default function NewApiIpFilterDialog({
	onClose,
	open,
	apiKeyUuid,
	addFilter,
}) {
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();

	const [sending, setSending] = useState(false);
	const [filter, setFilter] = useState({
		name: '',
		ip: '',
		expires: false,
	});

	const schema = useMemo(
		() =>
			yup.object({
				name: yup
					.string()
					.optional()
					.label('Name'),
				ip: yup
					.string()
					.trim()
					.matches(
						/^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/,
						'IP Address format error'
					)
					.required()
					.label('IP Address'),
				//expire_timstamp: yup.number().default(0),
				expires: yup.boolean().default(false),
			}),
		[]
	);

	const onSubmit = useCallback(
		async (values, reset) => {
			setSending(true);
			try {
				let newIpFilter = {
					ip: values.ip,
					name: values.name,
					expire_timestamp: values.expires
						? Date.now() + 1000 * 60 * 60 * 24
						: 0,
				};

				let ipResponse = await apiManagerAddIpFilter({
					apiKeyUuid: apiKeyUuid,
					newIpFilter: newIpFilter,
				});
				addFilter(ipResponse);
				reset(values);
				enqueueSnackbar('Created API Key', {
					variant: 'success',
				});
				//history.push(`/ApiManager/Edit/${uuid}`);
				setSending(false);
				onClose();
			} catch (error) {
				setSending(false);
				enqueueSnackbar('Failed to add IP filter', {
					variant: 'error',
				});
			}
		},
		[enqueueSnackbar, history, addFilter]
	);

	const { data: userIp, request: requestIp } = useFetch(ipGet);
	useEffect(
		() => {
			requestIp();
		},
		[requestIp]
	);

	const handleUserIpClick = () => {
		setFilter({ ...filter, ip: userIp.replace('::ffff:', '') });
	};
	const handleAllowAllClick = () => {
		setFilter({ ...filter, ip: '0.0.0.0' });
	};

	return (
		<BtDialog open={open} onClose={onClose} minwidth="sm" fullWidth>
			{sending && (
				<LinearProgress
					style={{
						position: 'absolute',
						top: 0,
						width: '100%',
					}}
				/>
			)}
			<DialogTitle>Add IP Address</DialogTitle>
			<BtForm
				onSubmit={onSubmit}
				sending={sending}
				validationSchema={schema}
				defaultValues={filter || {}}
			>
				<DialogContent>
					<Button
						variant="outlined"
						onClick={handleUserIpClick}
						disableElevation
						style={{ margin: '1em' }}
					>
						Current IP Address ({userIp?.replace('::ffff:', '')})
					</Button>
					<Button
						variant="outlined"
						onClick={handleAllowAllClick}
						disableElevation
						style={{ margin: '1em' }}
					>
						Any IP Address
					</Button>
					<BtFormContent>
						<BtFormTextField name="name" label="Name" autoFocus />
						<BtFormTextField
							name="ip"
							label="IP Address"
							multiline
						/>
						<BtFormCheckbox
							name="expires"
							label="Temporary (will expire in 24 hours)"
							style={{ marginRight: '1em' }}
						/>
					</BtFormContent>
				</DialogContent>
				<DialogActions>
					<BtFormActionsContainer>
						<ActionButtons onClose={onClose} sending={sending} />
					</BtFormActionsContainer>
				</DialogActions>
			</BtForm>
		</BtDialog>
	);
}

ActionButtons.propTypes = {
	isDirty: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	sending: PropTypes.bool.isRequired,
};

NewApiIpFilterDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};
