import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { LinearProgress, Typography } from '@mui/material';

export default function BtProgressBar({
    value,
    goal,
    showLabel,
    showGoal,
    isPercentage,
    style,
}) {
    const progress = useMemo(
        () => {
            const { min, floor } = Math;
            return (min(floor(value), goal) / goal) * 100;
        },
        [value, goal]
    );

    const label = useMemo(
        () => {
            if (!showLabel) return;

            let str = `${value}${isPercentage ? '%' : ''}`;
            if (showGoal) {
                str += ` / ${goal}${isPercentage ? '%' : ''}`;
            }

            return str;
        },
        [value, goal, isPercentage, showLabel, showGoal]
    );

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                ...style,
            }}
        >
            {showLabel && <Typography alignSelf="center">{label}</Typography>}
            <LinearProgress variant="determinate" value={progress} />
        </div>
    );
}

BtProgressBar.defaultProps = {
    goal: 100,
    showLabel: true,
    showGoal: true,
};

BtProgressBar.propTypes = {
    value: PropTypes.number.isRequired,
    goal: PropTypes.number,
    showLabel: PropTypes.bool,
    showGoal: PropTypes.bool,
    isPercentage: PropTypes.bool,
    style: PropTypes.object,
};
