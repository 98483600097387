import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { ArrowForward } from '@mui/icons-material';
import { ButtonBase, styled, TextField } from '@mui/material';

import PopoverContent from './PopoverContent';

const Content = styled('div')(() => ({
	alignItems: 'flex-start',
	display: 'flex',
	margin: '4px 16px 8px',
	position: 'relative',
}));

const EnterButton = styled(ButtonBase)(({ theme }) => ({
	alignItems: 'center',
	backgroundColor: theme.palette.primary.main,
	borderRadius: 12,
	display: 'flex',
	height: 20,
	justifyContent: 'center',
	position: 'absolute',
	marginTop: 5,
	right: 0,
	width: 20,
}));

export default function FieldNamePane({
	alreadyInUse,
	disabled,
	fieldName,
	onChangeFieldName,
	onSubmit,
}) {
	const textFieldRef = useRef();
	const [dirty, setDirty] = useState(false);

	const error = useMemo(
		() => {
			if (alreadyInUse) {
				return 'Field name already in use';
			}

			if (dirty && !fieldName.trim()) {
				return 'Enter a valid field name';
			}

			return null;
		},
		[alreadyInUse, dirty, fieldName]
	);

	useEffect(
		() => {
			textFieldRef?.current?.focus();
		},
		[textFieldRef]
	);

	return (
		<PopoverContent disabled={disabled} title="Field Name">
			<Content>
				<TextField
					autoComplete="off"
					autoCorrect="off"
					disabled={disabled}
					error={!!error}
					fullWidth
					helperText={error}
					inputProps={{ style: { paddingRight: 24 } }}
					inputRef={textFieldRef}
					onBlur={() => setDirty(true)}
					onChange={event => onChangeFieldName(event.target.value)}
					onKeyDown={({ key }) => {
						if (key === 'Enter' && fieldName.trim() && !error) {
							onSubmit();
						}
					}}
					placeholder="Enter a field name"
					value={fieldName}
					variant="standard"
				/>
				{fieldName.trim() &&
					!alreadyInUse && (
						<EnterButton
							disabled={disabled}
							focusRipple
							onClick={onSubmit}
						>
							<ArrowForward
								style={{ color: '#fff', fontSize: '16' }}
							/>
						</EnterButton>
					)}
			</Content>
		</PopoverContent>
	);
}

FieldNamePane.propTypes = {
	alreadyInUse: PropTypes.bool,
	disabled: PropTypes.bool,
	fieldName: PropTypes.string.isRequired,
	onChangeFieldName: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
};
