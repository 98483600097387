import { FLAT, GROUPED } from './constants';

const processGrouped = (options, searchTerm, term) => {
	return options.reduce((accumulator, group) => {
		const composeResult = options => [
			...accumulator,
			{ ...group, options },
		];

		const options = group.options;

		if (options.length === 0) return [...accumulator];

		// If search term of group name matches
		if (!searchTerm || group.groupName.toLowerCase().includes(term)) {
			return composeResult(options);
		}

		// Filter down options in the group
		const filteredTargets = options.filter(option => {
			const name = option.name;
			return (name || '').toLowerCase().includes(term);
		});

		// If any options remain include the group...
		if (filteredTargets.length > 0) {
			return composeResult(filteredTargets);
		}

		// ...otherwise drop the group entirely
		return [...accumulator];
	}, []);
};

const processFlat = (options, searchTerm, term) => {
	if (!searchTerm) return options;

	const filteredTargets = options.filter(option => {
		const string = option.title + option.subtitle;
		return (string || '').toLowerCase().includes(term);
	});

	return filteredTargets;
};

export default function filterOptions(searchTerm, options, optionsType) {
	if (![GROUPED, FLAT].includes(optionsType)) {
		console.warn(
			`filterOptions param error, [optionsType] should be one of ${[
				GROUPED,
				FLAT,
			]}, received ${optionsType}`
		);
		return [];
	}

	if (!Array.isArray(options)) {
		console.warn(
			`filterOptions param error, [options] should be an array.`
		);
		return [];
	}

	const term = (searchTerm || '').toLowerCase().trim();

	let result = [];

	if (optionsType === GROUPED) {
		result = processGrouped(options, searchTerm, term);
	}

	if (optionsType === FLAT) {
		result = processFlat(options, searchTerm, term);
	}

	return result;
}
