import React, { useCallback, useMemo } from 'react';
import { useChartsContext } from '../contexts/ChartsContexts';
import { useComponentsContext } from './Chart';
// import { getTextDimensions } from '../Utils';
import { get, min } from 'lodash';
// import { useTheme } from '@mui/styles';
import propTypes from 'prop-types';
import { Sector } from './Sector';
import {
	LINEAR,
	RADIAL,
} from '../../../API/validations/insightVisualizationsValidation';

const resolveEndAngle = (max, value, _min, maxAngle) => {
	if (max < value) {
		return maxAngle;
	}
	return (maxAngle * (value - _min)) / (max - _min);
};

const getOuterRadius = (externalRadius, index, barWidth, barGap) => {
	const outerRadius = externalRadius - [index * (barWidth + barGap)];
	return outerRadius;
};

export function GaugePrimaryAxis({ palette, variant, bands, axisBounds }) {
	const { onMouseOver } = useChartsContext();
	// const theme = useTheme();
	const {
		data,
		radialProperties: { minimumAxisBisection, cx, cy },
	} = useComponentsContext();
	// const [mouseOver, setMouseOver] = useState(false);

	const axisAttributes = useMemo(
		() => {
			if (variant === LINEAR) {
				return {};
			}
			const axisWidth = minimumAxisBisection * 0.3;
			const outerRadius = minimumAxisBisection;
			const innerRadius = outerRadius - axisWidth;
			return {
				axisWidth,
				outerRadius,
				innerRadius,
			};
		},
		[minimumAxisBisection, variant]
	);

	// const sectors = useMemo(

	return (
		<g
			onMouseEnter={() => {
				// setMouseOver(true);
				onMouseOver([true]);
				// console.log('mouse over');
			}}
			onMouseLeave={() => {
				// setMouseOver(false);
				onMouseOver(null);
			}}
		>
			{/* <path
				fill={fill}
				// stroke="blue"
				// strokeWidth={2}
				d={attr.path}
			/> */}
			{variant === RADIAL &&
				sectors.map((sector, index) => (
					<Sector
						key={index}
						cx={cx}
						cy={cy}
						innerRadius={sector.innerRadius}
						outerRadius={sector.outerRadius}
						startAngle={sector.startAngle}
						endAngle={sector.endAngle}
						fill={sector.fill}
					/>
				))}
		</g>
	);
}
