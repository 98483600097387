import { Button, Typography } from '@mui/material';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { ChevronLeft } from '@mui/icons-material';
import { BtFormContainer } from '../../components/generic/forms';

export default function FormContainer({
	maxWidth,
	text,
	buttonText,
	onClick,
	children,
}) {
	const history = useHistory();
	return (
		<BtFormContainer
			title={
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						width: '100%',
					}}
				>
					<Typography variant="h3">{text || ''}</Typography>
					{onClick && (
						<div>
							<Button
								size="large"
								style={{ height: 'auto' }}
								variant="contained"
								disableElevation
								onClick={() => onClick()}
							>
								{buttonText || ''}
							</Button>
						</div>
					)}
				</div>
			}
			maxWidth={maxWidth}
		>
			{children}
		</BtFormContainer>
	);
}

FormContainer.defaultProps = {
	maxWidth: 'sm',
};

FormContainer.propTypes = {
	text: PropTypes.string,
	buttonText: PropTypes.string,
	onClick: PropTypes.func,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};
