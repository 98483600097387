import React, { useEffect, useState } from 'react';
import { useNavContext } from '../../../context/ContextManager';

import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

// Data for landing page selection table and contextual navigation
import insights_options from '../InsightsOptions';
import insights_breadcrumbs from '../InsightsBreadcrumbs';
import insights_nav_item from '../InsightsNavItem';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

export default function Exports() {
	const [isLoading, setIsLoading] = useState(false);
	const { setBreadcrumbs } = useNavContext();
	const { setContextualNav } = useNavContext();

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				...insights_breadcrumbs,
				{ text: 'Reports', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([...insights_nav_item, ...insights_options]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	return (
		<>
			{isLoading && (
				<Container
					maxWidth="lg"
					sx={{ display: 'flex', flexDirection: 'column' }}
				>
					<Typography variant="h3" sx={{ m: 'auto' }}>
						Loading...
					</Typography>
					<CircularProgress sx={{ m: 'auto' }} size={'120px'} />
				</Container>
			)}

			{!isLoading && (
				<Container maxWidth="lg">
					<Typography variant="h3">Insight Exports</Typography>
					<Typography variant="h6">Data Export Templates</Typography>
				</Container>
			)}
		</>
	);
}
