import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import {
	Button,
	ButtonBase,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from '@mui/material';
import { Check } from '@mui/icons-material';
import styled from '@emotion/styled';

import ActionButtonContainer from '../../../../../components/generic/ActionButtonContainer';
import BtDialog from '../../../../../components/generic/BtDialog';
import Color from 'color';

const SelectedIconContainer = styled('div')(({ theme }) => ({
	alignItems: 'center',
	display: 'flex',
	height: 30,
	justifyContent: 'center',
	width: 30,

	'& > *': {
		color: theme.palette.indicators.success.main,
		fontSize: 28,
	},
}));

const StateList = styled('div')(() => ({
	alignItems: 'flex-start',
	display: 'flex',
	flexDirection: 'column',
}));

const StateListItem = styled(ButtonBase)(({ theme }) => ({
	backgroundColor: 'transparent',
	border: 'none',
	borderRadius: 8,
	padding: '0.5em',
	width: '100%',

	transition: theme.transitions.create(['background-color'], {
		duration: '0.2s',
	}),

	'&:hover': {
		backgroundColor: Color(theme.palette.text.solid)
			.alpha(0.04)
			.toString(),
	},

	'&.selected': {
		backgroundColor: Color(theme.palette.text.solid)
			.alpha(0.06)
			.toString(),
	},

	'& > span': {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
	},
}));

const StateItem = ({ selected, state, ...otherProps }) => (
	<StateListItem
		className={selected ? 'selected' : undefined}
		focusRipple
		{...otherProps}
	>
		<span>
			<Typography
				style={{
					justifySelf: 'flex-start',
					marginLeft: 3,
				}}
			>
				{state.text}
			</Typography>

			<SelectedIconContainer>
				{selected && <Check />}
			</SelectedIconContainer>
		</span>
	</StateListItem>
);

export default function WorkflowNextStateDialog({
	onClose,
	onStateSubmit,
	open,
	states,
}) {
	const [selectedState, setSelectedState] = useState();

	const handleClose = useCallback(
		() => {
			setSelectedState(null);

			onClose();
		},
		[onClose]
	);

	return (
		<BtDialog fullWidth maxWidth="xs" onClose={handleClose} open={open}>
			<DialogTitle>
				<Typography style={{ fontWeight: 'bold' }}>
					Select the next workflow state
				</Typography>
			</DialogTitle>
			<DialogContent style={{ paddingBottom: '0.5em' }}>
				<StateList>
					{(states || []).map(state => (
						<StateItem
							key={state.text}
							selected={state === selectedState}
							onClick={() => setSelectedState(state)}
							state={state}
						/>
					))}
				</StateList>
			</DialogContent>
			<DialogActions style={{ padding: 0 }}>
				<ActionButtonContainer>
					<Button
						disableElevation
						onClick={handleClose}
						variant="text"
					>
						Cancel
					</Button>
					<Button
						disabled={!selectedState}
						disableElevation
						onClick={() => {
							onStateSubmit(selectedState);

							handleClose();
						}}
						variant="contained"
					>
						Submit
					</Button>
				</ActionButtonContainer>
			</DialogActions>
		</BtDialog>
	);
}

StateItem.propTypes = {
	selected: PropTypes.bool,
	state: PropTypes.object,
};

WorkflowNextStateDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
	onStateSubmit: PropTypes.func.isRequired,
	open: PropTypes.bool,
	states: PropTypes.arrayOf(PropTypes.object),
};
