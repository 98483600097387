import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, CircularProgress } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

import BtConfirmDialog from '../BtConfirmDialog';
import withFormContextMethods from './withFormContextMethods';

const ActionButtons = memo(
	({
		alwaysAllowDestructive,
		DestructiveIcon,
		destructiveOutlined,
		destructivePrompt,
		destructiveVerb,
		formState: { isDirty },
		loading,
		onDestroy,
		reset,
		sending,
		setOverrideBlocking,
		showDestructivePrompt,
		SubmitIcon,
		submitVerb,
	}) => {
		const [dialogVisible, setDialogVisible] = useState(false);

		return (
			<>
				<Button
					disabled={
						(!isDirty && !alwaysAllowDestructive) ||
						loading ||
						sending
					}
					disableElevation
					onClick={() => {
						if (showDestructivePrompt) {
							setDialogVisible(true);

							setOverrideBlocking(true);
						} else {
							reset();

							onDestroy?.();

							setOverrideBlocking(false);
						}
					}}
					startIcon={DestructiveIcon}
					variant={destructiveOutlined ? 'outlined' : 'text'}
				>
					{destructiveVerb}
				</Button>
				<Button
					disabled={!isDirty || loading || sending}
					disableElevation
					startIcon={
						sending ? <CircularProgress size={20} /> : SubmitIcon
					}
					type="submit"
					variant="contained"
				>
					{sending ? 'Sending' : submitVerb}
				</Button>

				<BtConfirmDialog
					action={() => {
						setDialogVisible(false);

						reset();

						onDestroy?.();

						setOverrideBlocking(false);
					}}
					ActionIcon={DestructiveIcon}
					isDestructive
					onClose={() => setDialogVisible(false)}
					open={dialogVisible}
					prompt={destructivePrompt}
					title={`${destructiveVerb}?`}
					verb={destructiveVerb}
				/>
			</>
		);
	},
	(prevProps, nextProps) =>
		prevProps.formState.isDirty === nextProps.formState.isDirty &&
		prevProps.loading === nextProps.loading &&
		prevProps.sending === nextProps.sending
);

const BtFormActionButtons = withFormContextMethods(ActionButtons);

ActionButtons.defaultProps = {
	DestructiveIcon: <DeleteIcon />,
	destructiveOutlined: true,
	destructivePrompt: "You will lose any changes you've made.",
	destructiveVerb: 'Discard Changes',
	showDestructivePrompt: true,
	SubmitIcon: <SaveIcon />,
	submitVerb: 'Save',
};

ActionButtons.propTypes = {
	alwaysAllowDestructive: PropTypes.bool,
	DestructiveIcon: PropTypes.node,
	destructiveOutlined: PropTypes.bool,
	destructivePrompt: PropTypes.string,
	destructiveVerb: PropTypes.string,
	formState: PropTypes.object,
	loading: PropTypes.bool,
	onDestroy: PropTypes.func,
	reset: PropTypes.func,
	sending: PropTypes.bool,
	setOverrideBlocking: PropTypes.func,
	showDestructivePrompt: PropTypes.bool,
	SubmitIcon: PropTypes.node,
	submitVerb: PropTypes.string,
};

ActionButtons.displayName = 'ActionButtons;';

export default BtFormActionButtons;
