import React, { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import * as yup from 'yup';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

// import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import BtQueryBuilderPropertyFormField from '../BtQueryBuilderPropertyField';
import { baseStagePropTypes } from './base-prop-type';

const stageType = '$sort';

const sortOrderOptions = [
	{ label: 'ascending', value: 'asc' },
	{ label: 'descending', value: 'desc' },
];

const formSchema = yup.object().shape({
	rules: yup.array().of(
		yup.object().shape({
			field: yup.string().required(),
			order: yup
				.string()
				.oneOf(['asc', 'desc'])
				.required(),
		})
	),
});

const defaultField = () => ({
	field: '_id',
	field_type: 'field-path',
	order: sortOrderOptions[0].value,
});

function buildFormValues(queryStage, flatConfigSchema, variables) {
	const values = { rules: [] };

	// TODO - this is inherently bugged.
	// the mongo sort function is an ordered object (ie a Map)
	// but JSON has no concept of an ordered object...
	// so there's no way to reliably encode and decode this object
	// ie: Object.keys(JSON.parse('{"obj":1,"_":2,"1obj":3, "4":4}'))
	//     => 4, obj, _, 1obj
	// API /query endpoints should be setup to handle $sort as an array and
	// transform it to a Map or obj at query time.
	Object.keys(queryStage[stageType]).forEach(k => {
		const n = Number(queryStage[stageType][k]);
		const sortField = k;
		const sortRule = isNaN(n) || n > 0 ? 'asc' : 'desc';

		values.rules.push({
			field: sortField,
			field_type: 'field-path',
			order: sortRule,
		});
	});

	if (values.rules.length === 0) {
		values.rules.push(defaultField());
	}

	return values;
}

function buildQueryStage(fields) {
	const query = {};

	fields?.rules?.forEach(rule => {
		query[rule.field] = rule.order === 'asc' ? 1 : -1;
	});

	return {
		[stageType]: query,
	};
}

const SortFormContent = props => {
	const { disabled, readOnly } = props;
	const { control, getValues, setValue, watch } = useFormContext();

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'rules',
	});

	const handleSortDirectionClick = useCallback(
		index => {
			const current = getValues(`rules.${index}.order`);

			setValue(
				`rules.${index}.order`,
				current === 'desc' ? 'asc' : 'desc',
				{
					shouldTouch: true,
					shouldValidate: true,
					shouldDirty: true,
				}
			);
		},
		[setValue, getValues]
	);

	return (
		<>
			{fields.map((field, index) => {
				const sortOrder = watch(`rules.${index}.order`);
				return (
					<Box key={field.id}>
						<Stack direction="row" spacing={2} alignItems="center">
							<Box flexShrink={1}>
								<Tooltip
									title={
										sortOrder === 'desc'
											? 'Sort descending'
											: 'Sort ascending'
									}
								>
									<span>
										<IconButton
											onClick={() =>
												handleSortDirectionClick(index)
											}
											disabled={disabled || readOnly}
										>
											{sortOrder === 'desc' ? (
												<ArrowDownwardIcon />
											) : (
												<ArrowUpwardIcon />
											)}
										</IconButton>
									</span>
								</Tooltip>
							</Box>
							<Box flexGrow={1} minWidth="300px">
								<BtQueryBuilderPropertyFormField
									key={field.id + 'field'}
									name={`rules.${index}.field`}
									asPath
									isRequired
									disabled={disabled}
									allowedFieldTypes={['field-path']}
									readOnly={readOnly}
								/>
							</Box>

							{!readOnly &&
								fields.length > 1 && (
									<Box>
										<IconButton
											onClick={() => remove(index)}
											disabled={disabled}
										>
											<CloseIcon fontSize="small" />
										</IconButton>
									</Box>
								)}
						</Stack>
						{index < fields.length - 1 && (
							<Divider sx={{ margin: 2 }} />
						)}
					</Box>
				);
			})}
			{!readOnly && (
				<Box sx={{ textAlign: 'center' }}>
					<Button
						onClick={() => append(defaultField())}
						disabled={disabled}
						startIcon={<AddIcon />}
					>
						Add sort rule
					</Button>
				</Box>
			)}
		</>
	);
};

export default {
	formSchema: formSchema,
	FormContent: SortFormContent,
	buildQueryStage: buildQueryStage,
	buildFormValues: buildFormValues,
};

SortFormContent.propTypes = baseStagePropTypes;
