import { Children } from 'react';
import {
	CARTESIAN_CHART_ELEMENTS,
	CARTESIAN_CHART_TYPES,
	GAUGE_CHART,
	RADIAL_BAR,
	RADIAL_BAR_CHART,
	RADIAL_BAR_CHART_ELEMENTS,
	RADIAL_CHART_TYPES,
	STATUS_TIMELINE,
	STATUS_TIMELINE_CHART,
} from '../Constants/constants';
import { rest } from 'lodash';

const AxisSize = 30;
const MarginTop = 10;
const MarginBottom = 10;
const MarginLeft = 10;
const MarginRight = 10;

const DefaultProperties = {
	data: [],
	dataKeys: [],
	timeRange: {
		start: 0,
		end: 0,
	},
	chartDimensions: {
		width: 1000,
		height: 800,
	},
	plotArea: {
		width: 1000,
		height: 800,
		x: 0,
		y: 0,
	},
	margin: {
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
	},
	xAxis: { hasXAxis: false },
	hasTooltip: false,
	tooltipContents: null,
	// yAxis: { hasYAxis: false },
	// legend: { hasLegend: false },
	// tooltip: { hasTooltip: false },
	// brush: { hasBrush: false },
	// zoom: { hasZoom: false },
	// grid: { hasGrid: false },
	// cartesianGrid: { hasCartesianGrid: false },
	// cartesianAxis: { hasCartesianAxis: false },
	// cartesianTooltip: { hasCartesianTooltip: false },
	// cartesianLegend: { hasCartesianLegend: false },
	// cartesianBrush: { hasCartesianBrush: false },
	// cartesianZoom: { hasCartesianZoom: false },
	// cartesianReferenceLine: { hasCartesianReferenceLine: false },
	// cartesianReferenceDot: { hasCartesianReferenceDot: false },
	// cartesianReferenceArea: { hasCartesianReferenceArea: false },
	// cartesianLabel: { hasCartesianLabel: false },
	// cartesianErrorBar: { hasCartesianErrorBar: false },
	// cartesianLine: { hasCartesianLine: false },
	// cartesianArea: { hasCartesianArea: false },
	// cartesianBar: { hasCartesianBar: false },
	// cartesianScatter: { hasCartesianScatter: false },
	// cartesianRadar: { hasCartesianRadar: false },
	// cartesianRadialBar: { hasCartesianRadialBar: false },
	// cartesianPie: { hasCartesianPie: false },
	// cartesianCell: { hasCartesianCell: false },
	// cartesianActiveShape: { hasCartesianActiveShape: false },
	// cartesianLabelList: { hasCartesianLabelList: false },
	// polarGrid: { hasPolarGrid: false },
	// polarAngleAxis: { hasPolarAngleAxis: false },
	// polarRadiusAxis: { hasPolarRadiusAxis: false },
	// polarTooltip: { hasPolarTooltip: false },
	// polarLegend: { hasPolarLegend: false },
	// polarBrush: { hasPolarBrush: false },
	// polarZoom: { hasPolarZoom: false },
	// polarReferenceLine: { hasPolarReferenceLine: false },
	// polarReferenceDot: { hasPolarReferenceDot: false },
	// polarReferenceArea: { hasPolarReferenceArea: false },
	// polarLabel: { hasPolarLabel: false },
	// polarErrorBar: { hasPolarErrorBar: false },
	// polarLine: { hasPolarLine: false },
	// polarArea: { hasPolarArea: false },
	// polarBar: { hasPolarBar: false },
	// polarScatter: { hasPolarScatter: false },
	// polarRadar: { hasPolarRadar: false },
	// polarRadialBar: { hasPolarRadialBar: false },
	// polarPie: { hasPolarPie: false },
	// polarCell: { hasPolarCell: false },
	// polarActiveShape: { hasPolarActiveShape: false },
	// polarLabelList: { hasPolarLabelList: false },
};

export default function useProcessChildren(
	children,
	data,
	chartDimensions,
	type
) {
	var newProperties = { ...DefaultProperties };

	// TODO: Move the following the the constants file
	// const BAR = 'Bar';
	// const LINE = 'Line';
	// const STATUS_TIMELINE = 'StatusTimeline';
	// const CARTESIAN_CHART_ELEMENTS = [BAR, STATUS_TIMELINE];

	const isCartesianChartElement = element =>
		CARTESIAN_CHART_ELEMENTS.includes(element);

	const isRadialChartElement = element =>
		RADIAL_BAR_CHART_ELEMENTS.includes(element);

	console.log('children', children);

	// The following retrieves a list of data keys from the children which are
	// of type Bar or StatusTimeline and thus need space allocated for them
	const polySeriesDataKeys = Children.toArray(children)
		.filter(child => {
			if (CARTESIAN_CHART_TYPES.includes(type)) {
				return isCartesianChartElement(
					child?.type?.displayName || child?.type?.name
				);
			} else if (RADIAL_CHART_TYPES.includes(type)) {
				// console.log('child', child);
				return isRadialChartElement(
					child?.type?.displayName || child?.type?.name
				);
				// return child?.type?.displayName === RADIAL_BAR;
			}
			// return isCartesianChartElement(
			// 	child?.type?.displayName || child?.type?.name
			// );
		})
		.reduce((accumulator, child) => {
			const dataKey = child.props.dataKey;

			if (dataKey && !accumulator.includes(dataKey)) {
				return [...accumulator, dataKey];
			}

			return accumulator;
		}, []);

	// TODO: the following is specific to the StatusTimeline chart and should be
	// expanded to work for other chart types
	// Calculate the time range of the data
	var timeRange = {
		start: null,
		end: null,
	};
	if (data) {
		switch (type) {
			case STATUS_TIMELINE_CHART:
				data.forEach(lineData => {
					(lineData?.statusSegments || []).forEach(segment => {
						if (
							segment.startTime < timeRange.start ||
							!timeRange.start
						) {
							timeRange = {
								...timeRange,
								start: segment.startTime,
							};
						}
						if (segment.endTime > timeRange.end || !timeRange.end) {
							timeRange = {
								...timeRange,
								end: segment.endTime,
							};
						}
					});
				});
				break;
			case RADIAL_BAR_CHART:
				// console.log('data', data);
				break;
			case GAUGE_CHART:
				break;
			default:
				console.log(`no case for ${type}`);
		}
	}

	// Find if there is a tooltip component in the children
	const tooltipComponent = Children.toArray(children).find(
		child =>
			child?.type?.displayName === 'Tooltip' ||
			child?.type?.name === 'Tooltip'
	);

	// Find if there is an XAxis component in the children
	const hasXAxis = Children.toArray(children).find(
		child =>
			child?.type?.displayName === 'XAxis' ||
			child?.type?.name === 'XAxis'
	);
	// Find if there is an YAxis component in the children
	const hasYAxis = Children.toArray(children).find(
		child =>
			child?.type?.displayName === 'YAxis' ||
			child?.type?.name === 'YAxis'
	);

	// // Find if there is a tooltip component in the children
	// const tooltipComponent = Children.toArray(children).find(
	// 	child =>
	// 		child?.type?.displayName === 'Tooltip' ||
	// 		child?.type?.name === 'Tooltip'
	// );

	// Process Legend

	// Calculate the plot area dimensions
	const plotArea = {
		width: hasXAxis
			? chartDimensions.width - AxisSize
			: chartDimensions.width,
		height: hasYAxis
			? chartDimensions.height - AxisSize
			: chartDimensions.height,
		x: hasXAxis ? AxisSize : 0,
		y: 0,
	};

	const margin = {
		top: MarginTop,
		bottom: MarginBottom,
		left: MarginLeft,
		right: MarginRight,
	};

	const radialProperties = {
		cx: chartDimensions.width / 2,
		cy: chartDimensions.height / 2,
		minimumAxisBisection: Math.min(plotArea.width, plotArea.height) / 2,
	};

	// Build the new properties object
	newProperties.dataKeys = polySeriesDataKeys;
	newProperties.data = data;
	newProperties.timeRange = timeRange;
	newProperties.plotArea = plotArea;
	newProperties.margin = margin;
	newProperties.chartDimensions = chartDimensions;
	newProperties.hasTooltip = tooltipComponent ? true : false;
	newProperties.tooltipContent = tooltipComponent?.props?.content
		? tooltipComponent.props.content
		: null;
	newProperties.radialProperties = radialProperties;
	// console.log('newProperties', newProperties);
	return newProperties;
}
