import React from 'react';
import PropTypes from 'prop-types';

import { ArrowBack } from '@mui/icons-material';
import Color from 'color';
import { IconButton, styled, Tooltip, Typography } from '@mui/material';

import { useAppContext } from '../../../../context/ContextManager';

const Container = styled('div')(() => ({
	position: 'relative',
	width: '100%',
}));

const Header = styled('div')(({ sticky, theme }) => ({
	alignItems: 'center',
	backgroundColor: theme.palette.background.card,
	borderBottom: `1px solid ${Color(theme.palette.text.solid)
		.fade(0.96)
		.toString()}`,
	cursor: 'default',
	display: 'flex',
	height: 48,
	padding: '0 16px',
	position: sticky ? 'sticky' : 'block',
	top: 0,
	userSelect: 'none',
	zIndex: 10,
}));

export default function PopoverContent({ children, disabled, onBack, title }) {
	const { showMobile } = useAppContext();

	return (
		<Container>
			<Header sticky={+!showMobile}>
				{onBack && (
					<Tooltip
						disableInteractive
						style={{ transform: 'translateX(-8px)' }}
						title="Back"
					>
						<span>
							<IconButton
								disabled={disabled}
								onClick={onBack}
								size="small"
							>
								<ArrowBack />
							</IconButton>
						</span>
					</Tooltip>
				)}
				<Typography>{title}</Typography>
			</Header>
			<div style={{ padding: '8px 0' }}>{children}</div>
		</Container>
	);
}

PopoverContent.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	disabled: PropTypes.bool.isRequired,
	onBack: PropTypes.func,
	title: PropTypes.string.isRequired,
};
