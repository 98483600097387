import { Pagination, PaginationItem, Typography } from '@mui/material';
import { ArrowLeft, ArrowRight } from 'mdi-material-ui';
import PropTypes from 'prop-types';

export default function PositionSelector({
	screenCount,
	onChange,
	disabled,
	value,
	unavailablePositions,
	label,
}) {
	// console.log({ unavailablePositions, screenCount, value });
	return (
		<div>
			<Typography
				style={{
					marginBottom: 10,
					fontSize: '1.0542rem',
					color: disabled && 'rgba(0, 0, 0, 0.5)',
				}}
			>
				{label}
			</Typography>
			<div
				style={{
					marginBottom: 20,
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Pagination
					// color="standard"
					count={screenCount}
					page={value}
					onChange={onChange}
					// showFirstButton={true}
					// showLastButton={true}
					boundaryCount={0}
					siblingCount={1}
					disabled={disabled}
					// variant="outlined"
					shape="rounded"
					renderItem={item => (
						<PaginationItem
							{...item}
							disabled={
								unavailablePositions.includes(item.page) ||
								(item.type === 'next' &&
									value === screenCount) ||
								(item.type === 'last' &&
									value === screenCount + 1) ||
								disabled
							}
							components={{
								next: (props, page, count) => (
									<ArrowRight {...props} disabled />
									// <ArrowRight {...props} />
								),
								previous: (props, page, count) => (
									<ArrowLeft {...props} disabled />
									// <ArrowLeft {...props} />
								),
							}}
						/>
					)}
				/>
			</div>
		</div>
	);
}

PositionSelector.propTypes = {
	screenCount: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	value: PropTypes.number.isRequired,
	unavailablePositions: PropTypes.arrayOf(PropTypes.number).isRequired,
	label: PropTypes.string.isRequired,
};
