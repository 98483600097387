import React from 'react';
import PropTypes from 'prop-types';

import { Controller, useFormContext } from 'react-hook-form';
import { Skeleton } from '@mui/material';

import BtFilePicker from '../BtFilePicker';
import useFormElRequired from '../../../hooks/useFormElRequired';

export default function BtFormFilePicker({
	disabled,
	name,
	label,
	isRequired,
	...other
}) {
	const {
		control,
		watch,
		loading,
		sending,
		validationSchema,
		formState: { errors },
	} = useFormContext() || { formState: {} };
	const value = watch(name);

	const required = useFormElRequired(isRequired, validationSchema, name);

	if (loading) {
		return (
			<Skeleton
				style={{
					width: '100%',
					borderRadius: 8,
					marginTop: label ? 37 : 0,
				}}
				variant="rectangle"
				animation="wave"
				height={216}
			/>
		);
	}

	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<BtFilePicker
					{...field}
					value={value}
					disabled={loading || sending || disabled}
					label={
						label
							? `${label}${!required ? ' (optional)' : ''}`
							: undefined
					}
					errorText={errors[name]?.message}
					{...other}
				/>
			)}
		/>
	);
}

BtFilePicker.defaultProps = {
	acceptedFiles: null,
	imagesOnly: false,
	maxFileSize: null,
};

BtFormFilePicker.propTypes = {
	acceptedFiles: PropTypes.string,
	disabled: PropTypes.bool,
	isRequired: PropTypes.bool,
	imagesOnly: PropTypes.bool,
	label: PropTypes.string,
	maxFiles: PropTypes.number,
	maxFileSize: PropTypes.number,
	name: PropTypes.string.isRequired,
};
