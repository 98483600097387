import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useRef,
} from 'react';
import PropTypes from 'prop-types';

import { SECTION } from '../processing/utils/constants';
import {
	millisPerUnit,
	temporalDeicticFuncs,
} from '../../../../utils/timeConstants';

const WorkflowStickyContext = createContext();

export const useWorkflowStickyContext = () => {
	const context = useContext(WorkflowStickyContext);

	if (!context) {
		throw new Error(
			'useWorkflowStickyContext was used outside of its Provider'
		);
	}

	return context;
};

const LOCAL_STORAGE_KEY = 'stickyItems';

const GEOGRAPHICAL_DISTANCE = 'geographical_distance';
const INDEFINITE = 'indefinite';
const TEMPORAL_DEICTIC_TERM = 'temporal_deictic_term';
const TIME_DURATION = 'time_duration';

export const WorkflowStickyContextProvider = ({ children }) => {
	const bufferTimeout = useRef();
	const updateBuffer = useRef({});
	const stickyData = useRef({});
	const stickyStore = useRef({});

	const synchronise = useCallback(() => {
		stickyStore.current = {
			...stickyStore.current,
			...updateBuffer.current,
		};

		localStorage.setItem(
			LOCAL_STORAGE_KEY,
			JSON.stringify(stickyStore.current)
		);

		updateBuffer.current = {};
	}, []);

	const clean = useCallback(
		() => {
			let shouldUpdate = false;

			const keys = Object.keys(stickyStore.current);

			keys.forEach(key => {
				const { expiryType, expiryValue } = stickyStore.current[key];

				if (
					(expiryType === TEMPORAL_DEICTIC_TERM ||
						expiryType === TIME_DURATION) &&
					+new Date() > expiryValue
				) {
					delete stickyStore.current[key];
					shouldUpdate = true;
				}
			});

			if (shouldUpdate) {
				synchronise();
			}
		},
		[synchronise]
	);

	const update = useCallback(
		updatedValues => {
			const updatedStickyKeys = Object.keys(updatedValues).filter(key =>
				stickyData.current.uuids.includes(key)
			);

			if (!updatedStickyKeys.length) {
				return;
			}

			const determineExpiryValue = (expiryType, expiryValue) => {
				switch (expiryType) {
					case INDEFINITE: {
						return null;
					}

					case TEMPORAL_DEICTIC_TERM: {
						const now = new Date();

						return +temporalDeicticFuncs[expiryValue]?.(now);
					}

					case TIME_DURATION: {
						const { quantity, unit } = expiryValue;

						return +new Date() + millisPerUnit[unit] * quantity;
					}

					default:
						break;
				}
			};

			const values = updatedStickyKeys.reduce((accumulator, key) => {
				const { expiryType, expiryValue } = stickyData.current.lookup[
					key
				];

				// Web app currently does not handle geos for workflows
				if (expiryType === GEOGRAPHICAL_DISTANCE) {
					return accumulator;
				}

				return {
					...accumulator,
					[key]: {
						expiryType,
						expiryValue: determineExpiryValue(
							expiryType,
							expiryValue
						),
						value: updatedValues[key],
					},
				};
			}, {});

			updateBuffer.current = {
				...updateBuffer.current,
				...values,
			};

			clearTimeout(bufferTimeout.current);
			bufferTimeout.current = setTimeout(synchronise, 1000);
		},
		[synchronise]
	);

	const initialise = useCallback(template => {
		if (!template) {
			throw new Error(
				'Attempted to initialise without supplying a template.'
			);
		}

		const extractStickyData = parent => {
			const { children } = parent;

			return children.reduce((accumulator, child) => {
				const { stickiness, type, uuid } = child;

				if (type === SECTION) {
					return { ...accumulator, ...extractStickyData(child) };
				}

				if (stickiness?.isSticky) {
					const { expiryType, expiryValue } = stickiness;

					return {
						...accumulator,
						[uuid]: {
							expiryType,
							expiryValue,
						},
					};
				}

				return accumulator;
			}, {});
		};

		stickyData.current.lookup = template.pages.reduce(
			(accumulator, page) => ({
				...accumulator,
				...extractStickyData(page),
			}),
			{}
		);
		stickyData.current.uuids = Object.keys(stickyData.current.lookup || {});
	}, []);

	const getStickyValues = useCallback(() => {
		const stored = localStorage.getItem(LOCAL_STORAGE_KEY);

		if (stored) {
			return JSON.parse(stored);
		}

		return {};
	}, []);

	useEffect(
		() => {
			stickyStore.current = getStickyValues();

			clean();
		},
		[clean, getStickyValues]
	);

	return (
		<WorkflowStickyContext.Provider
			value={{ initialise, getStickyValues, update }}
		>
			{children}
		</WorkflowStickyContext.Provider>
	);
};

WorkflowStickyContextProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};
