import React, { memo, useContext, useMemo } from 'react';
import Container from './Container';
import PropTypes from 'prop-types';

import InsightCollectionTile from './InsightCollectionTile';
import InsightTile from './InsightTile';
import ButtonTile from './ButtonTile';
import WorkflowTile from './WorkflowTile';
import TileError from '../components/TileError';
import { TileContext } from '../components/TileContext';
import TileWrapper from '../components/TileWrapper';
import InformationTile from './InformationTile';
import { Typography } from '@mui/material';
import { ScreensContext } from '../ScreensContext';

const Content = ({ variant }) => {
	// console.log({ variant });
	switch (variant) {
		case 'insight_collection':
			return <InsightCollectionTile />;
		case 'insight_visualisation':
			return <InsightTile />;
		case 'container':
			return <Container />;
		case 'button':
			return <ButtonTile />;
		case 'workflow_templates':
			return <WorkflowTile />;
		case 'workflow_submissions':
			return <WorkflowTile />;
		case 'information':
			return <InformationTile />;
		default:
			return null;
	}
};

export default function TileContent() {
	const {
		tileData,
		initializing,
		screenData,
		handleDeleteTile,
		onTileChange,
		onContainerChange,
		tileSize,
		error,
	} = useContext(TileContext);

	return (
		!initializing && (
			<TileWrapper
				onContainerChange={onContainerChange}
				onTileChange={onTileChange}
				handleDeleteTile={handleDeleteTile}
			>
				{/* {tileSize && (
					<TileError>
						<Content variant={tileData.variant} />
					</TileError>
				)} */}

				{tileSize &&
					(error ? (
						<TileError />
					) : (
						<Content variant={tileData.variant} />
					))}
			</TileWrapper>
		)
	);
}
