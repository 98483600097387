import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { Typography } from '@mui/material';
import TilesTextField from '../../../formComponents/TilesTextField';
import { useScreensConfigContext } from '../../ScreenConfigContext';
import PositionSelector from '../../../formComponents/PositionSelector';
import BtColourPicker from '../../../../../BtColourPicker';
import { ScreensContext } from '../../../../ScreensContext';
import BtRedactor from '../../../../../../BtRedactor';

export const screenNameSchema = yup
	.object({
		textField: yup
			.string()
			.required()
			.min(4)
			.max(30)
			.label('Screen name'),
	})
	.required();

export default function EditScreen({ unavailablePositions }) {
	const [isTextFieldValid, setIsTextFieldValid] = useState();
	const {
		setEditNextStepDisabled,
		editScreenParams,
		setEditScreenParams,
		screenCount,
	} = useScreensConfigContext();

	// state for screen position, initialized with screen-type dependent value
	const [screenPosition, setScreenPosition] = useState(
		editScreenParams.existingPosition
	);
	// state for screen name, initialized with screen-type dependent value
	const [textFieldValue, setTextFieldValue] = useState(
		editScreenParams.existingScreenName
	);
	const [color, setColor] = useState(
		editScreenParams.existingBackgroundColor
	);

	const { userPermissions } = useContext(ScreensContext);

	// manage next button state
	useEffect(
		() => {
			if (userPermissions.ScreenEdit) {
				const disableNext =
					!isTextFieldValid ||
					(screenPosition === editScreenParams.existingPosition &&
						color === editScreenParams.existingBackgroundColor &&
						textFieldValue === editScreenParams.existingScreenName);
				setEditNextStepDisabled(disableNext);
			} else {
				const disableNext =
					screenPosition === editScreenParams.existingPosition &&
					color === editScreenParams.existingBackgroundColor &&
					textFieldValue === editScreenParams.existingScreenName;
				setEditNextStepDisabled(disableNext);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[color, isTextFieldValid, screenPosition, textFieldValue]
	);

	// control state for screen position, initialized with screen-type dependent value
	const handleScreenChange = (event, value) => {
		setScreenPosition(value);
	};

	// watch for changes to screen position and update context
	useEffect(
		() => {
			let newParams = { ...editScreenParams };
			newParams.position = screenPosition;
			setEditScreenParams(newParams);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[screenPosition]
	);

	// watch for changes to screen name and update context
	useEffect(
		() => {
			let newParams = { ...editScreenParams };
			newParams.newScreenName = textFieldValue;
			setEditScreenParams(newParams);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[textFieldValue]
	);

	// watch for changes to the color value and update the context
	useEffect(
		() => {
			let newParams = { ...editScreenParams };
			newParams.backgroundColor = color;
			setEditScreenParams(newParams);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[color]
	);

	// Disable color and name options if screen is owned by the organisation
	const optionsDisabled = editScreenParams.ownerType === 'Organisation';

	return (
		<>
			{/* <div
				style={{
					marginTop: 10,
				}}
			> */}
			<div
				style={{
					width: 266,
					marginBottom: 20,
				}}
			>
				{/* <Typography
						style={{
							marginBottom: 10,
							fontSize: '1.0542rem',
							color: optionsDisabled && 'rgba(0, 0, 0, 0.5)',
						}}
					>
						Change background colour
					</Typography>
					<BtColourPicker
						disabled={optionsDisabled}
						errorText={null}
						// label="Change background colour"
						onChange={setColor}
						showNoneOption={true}
						value={color}
					/> */}
			</div>
			<BtRedactor
				requiredPermissionsAll={{ screens: ['ScreenGroupEdit'] }}
			>
				<PositionSelector
					value={screenPosition}
					screenCount={screenCount}
					onChange={handleScreenChange}
					disabled={optionsDisabled}
					unavailablePositions={unavailablePositions}
					label={
						optionsDisabled
							? 'Position is managed by your organisation'
							: 'Select position'
					}
				/>
			</BtRedactor>

			<BtRedactor requiredPermissionsAll={{ screens: ['ScreenEdit'] }}>
				<TilesTextField
					setIsTextFieldValid={setIsTextFieldValid}
					onChange={setTextFieldValue}
					initialValue={textFieldValue || ''}
					schema={screenNameSchema}
					label={
						optionsDisabled
							? 'Name is managed by your organisation'
							: 'Choose a name'
					}
					disabled={optionsDisabled}
				/>
			</BtRedactor>
			{/* </div> */}
		</>
	);
}

EditScreen.propTypes = {
	unavailablePositions: PropTypes.arrayOf(PropTypes.number).isRequired,
};
