import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Skeleton, Typography } from '@mui/material';
import { format } from 'date-fns';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import BtLoading from './BtLoading';
import BtManagedBy from './BtManagedBy';

const Header = styled('div')(({ theme }) => ({
	alignContent: 'center',
	display: 'flex',
	justifyContent: 'space-between',
	marginBottom: '1em',

	[theme.breakpoints.down('md')]: {
		alignItems: 'flex-start',
		flexDirection: 'column',
	},
}));

const StyledSkeleton = styled(Skeleton)(() => ({
	width: '100%',
	height: 20,
	borderRadius: 8,
	marginBottom: '1.5em',
}));

const dateTimeFormat = 'dd MMM yyyy, HH:mm';

export default function BtOverview({
	children,
	createdTimestamp,
	loading,
	managedBy,
	modifiedTimestamp,
	organisationTooltip,
	platformTooltip,
	showDividers,
	style,
	title,
	...props
}) {
	const theme = useTheme();
	const screenDownMd = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<div style={{ marginBottom: '2em', ...style }} {...props}>
			<BtLoading
				loading={loading}
				LoadingComponent={
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							width: '100%',
							marginTop: '1em',
						}}
					>
						<StyledSkeleton
							style={{ maxWidth: 100, height: 24 }}
							animation="wave"
							variant="rectangular"
						/>
						<StyledSkeleton
							style={{ maxWidth: 300 }}
							animation="wave"
							variant="rectangular"
						/>
						<StyledSkeleton
							style={{ maxWidth: 250 }}
							animation="wave"
							variant="rectangular"
						/>
					</div>
				}
			>
				{showDividers && <Divider style={{ margin: '0 0 1em 0' }} />}
				<Header>
					<Typography
						variant="h5"
						style={{
							fontWeight: 'bold',
						}}
					>
						{title}
					</Typography>
					{managedBy && (
						<BtManagedBy
							managedBy={managedBy}
							organisationTooltip={organisationTooltip || ''}
							platformTooltip={platformTooltip || ''}
							tooltipPlacement={
								screenDownMd ? 'bottom-start' : 'bottom-end'
							}
							style={{
								marginTop: screenDownMd ? '0.8em' : 0,
							}}
						/>
					)}
				</Header>
				{children}
				{(!!createdTimestamp || !!modifiedTimestamp) && (
					<Typography
						variant="subtitle2"
						style={{
							opacity: 0.8,
							marginTop: '1em',
						}}
					>
						{(createdTimestamp
							? `Created ${format(
									new Date(createdTimestamp || 0),
									dateTimeFormat
							  )}`
							: '') +
							(createdTimestamp !== modifiedTimestamp
								? (createdTimestamp && modifiedTimestamp
										? ' • '
										: '') +
								  (modifiedTimestamp
										? `Updated ${format(
												new Date(
													modifiedTimestamp || 0
												),
												dateTimeFormat
										  )}`
										: '')
								: '')}
					</Typography>
				)}
				{showDividers && <Divider style={{ margin: '1em 0 0 0' }} />}
			</BtLoading>
		</div>
	);
}

BtOverview.defaultProps = {
	title: 'Overview',
};

const timestampProp = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

BtOverview.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	createdTimestamp: timestampProp,
	loading: PropTypes.bool,
	managedBy: PropTypes.oneOf(['Platform', 'Organisation']),
	modifiedTimestamp: timestampProp,
	organisationTooltip: PropTypes.string,
	platformTooltip: PropTypes.string,
	showDividers: PropTypes.bool,
	style: PropTypes.object,
	title: PropTypes.string,
};
