import React from 'react';

import { Container, Button, Typography } from '@mui/material';

import BTAuthCard from './BTAuthCard';

export default function Error(props) {
	return (
		<BTAuthCard
			heading={
				<Typography align="center">
					Something went wrong, please try to login again.
				</Typography>
			}
			form={
				<Container maxWidth="md">
					<Button
						disableElevation={true}
						fullWidth={true}
						variant="contained"
						onClick={() => {
							props.changeView('Login');
						}}
					>
						Go to Login
					</Button>
				</Container>
			}
		/>
	);
}
