import React, {
	useState,
	useEffect,
	useMemo,
	useContext,
	createContext,
	useRef,
	useCallback,
} from 'react';

import _ from 'lodash';
import { useSocketContext } from '../SocketContext/SocketContext';
import {
	dataViewQueryGet,
	dataViewFilterOptionsGet,
	dataViewFilterOptionsGetFiltered,
} from '../../API';

import PropTypes from 'prop-types';
import { Null } from 'mdi-material-ui';

// =================================================================================
// DataProviderContext
const DataProviderContext = createContext();
const useDataProviderContext = () => {
	const context = useContext(DataProviderContext);
	if (context === undefined) {
		throw new Error(
			'useDataProviderContext was used outside of its Provider'
		);
	}
	return context;
};

// =================================================================================
const DataProvider = ({ children }) => {
	//console.log('DataProvider Render!!');
	const dataSources = useRef([]);
	const dataViews = useRef([]);
	const { subscribe, unsubscribe } = useSocketContext();
	const [filterOptions, setFilterOptions] = useState([]);

	// TODO: The following is temp code that updates all data_sources, we should get a id of the one to update
	const setDataTimeRange = newTimeRange => {
		dataViews.current.forEach(dataView => {
			// console.log(dataViews.current);
			dataView.transforms.forEach(transform => {
				transform.dataUpdateSubs.forEach(dataSub => {
					dataSub.dataUpdate(null, true);
				});
			});

			dataView.timeRange = newTimeRange;
			// Fetch data
			refreshDataView(dataView);
		});
	};

	// =================================================================================
	// TODO: where does the 'dataSourceUuid' come from and do we need it?
	// TODO: this is async but the call does not have an await
	const setDataFilters = async (dataSourceUuid, newDataFilters) => {
		// console.log('setDataFilters', newDataFilters);
		// console.log('newDataFilters', newDataFilters);
		// // console.log('dataSourceUuid', dataSourceUuid);

		// var dataViewFilterUpdates = {};
		// newDataFilters.forEach(dataFilter => {
		// 	if (!dataViewFilterUpdates[dataFilter.dataViewUuid]) {
		// 		dataViewFilterUpdates[dataFilter.dataViewUuid] = {};
		// 	}
		// 	dataViewFilterUpdates[dataFilter.dataViewUuid][dataFilter.field] =
		// 		dataFilter.filterProps;
		// });

		// console.log('dataViewFilterUpdates', dataViewFilterUpdates);
		// console.log('dataViews.current', dataViews.current);

		// // Object.keys(dataViewFilterUpdates).forEach(dataViewKey => {
		// for (const dataViewKey of Object.keys(dataViewFilterUpdates)) {
		const dataView = _.find(dataViews.current, {
			uuid: dataSourceUuid,
		});

		// console.log('dataView', dataView);

		dataView.transforms.forEach(transform => {
			transform.dataUpdateSubs.forEach(dataSub => {
				dataSub.dataUpdate(null, true);
			});
		});

		dataView.filterUpdateSubs.forEach(filterSub => {
			filterSub.filterUpdate(null, null, null, true);
		});

		// Is the filter field a dependency of the data view?
		var filterOptionsUpdateNeeded = false;
		(dataView.filterDependencies || []).forEach(dependency => {
			const newDataFilter = _.find(newDataFilters, { field: dependency });
			const activeFilter = _.find(dataView.activeFilters, {
				field: dependency,
			});

			// console.log('newDataFilter', newDataFilter);
			// console.log(dataView.activeFilters);
			// console.log(activeFilter, newDataFilter);

			// Check if the dependent filter has changed
			if (activeFilter && newDataFilter) {
				if (
					activeFilter.filterValue.length ===
					newDataFilter.filterValue.length
				) {
					// console.log('do compare deep');
					for (var i = 0; i < activeFilter.filterValue.length; ++i) {
						if (
							activeFilter.filterValue[i] !==
							newDataFilter.filterValue[i]
						)
							filterOptionsUpdateNeeded = true;
					}
				} else {
					filterOptionsUpdateNeeded = true;
				}
			}
		});

		// console.log('filterOptionsUpdateNeeded', filterOptionsUpdateNeeded);

		if (filterOptionsUpdateNeeded) {
			const filters = {};

			// console.log(
			// 	'dataView.filterDependencies',
			// 	dataView.filterDependencies
			// );
			// console.log('newDataFilters', newDataFilters);
			for (const filter of dataView.filterDependencies) {
				// If the filter is predefined then we can process it

				const matchingFilter = _.find(newDataFilters, {
					field: filter,
				});
				// console.log('matchingFilter', matchingFilter);
				filters[filter] = matchingFilter.filterValue;
			}

			// console.log(filters);

			if (Object.keys(filters).length > 0) {
				const filterOptionsResult = await dataViewFilterOptionsGetFiltered(
					{
						dataViewUuid: dataView.dataView,
						timeRange: dataView.timeRange,
						filters: filters,
					}
				);

				// console.log('filterOptionsResult', filterOptionsResult);

				// console.log('activeFilters', dataView.activeFilters);

				var updatedActiveFilters = dataView.activeFilters.map(
					activeFilter => {
						var updatedFilter = { ...activeFilter };

						// Do not update dependent filters
						if (
							dataView.filterDependencies.includes(
								activeFilter.field
							)
						) {
							const newFilter = _.find(newDataFilters, {
								field: activeFilter.field,
							});
							updatedFilter.filterValue = [
								...newFilter.filterValue,
							];
						} else {
							// console.log(filterOptionsResult);
							switch (
								filterOptionsResult[activeFilter.field].type
							) {
								case 'categorical':
									if (activeFilter.singleValue) {
										updatedFilter.filterValue = [
											filterOptionsResult[
												activeFilter.field
											].categories[0],
										];
									} else {
										updatedFilter.filterValue = [
											...filterOptionsResult[
												activeFilter.field
											].categories,
										];
									}
									break;

								// TODO: add support for the following
								// case 'quantitative':
								// 	filters[filter] = filterOption.range;
								// 	break;
								// case 'boolean':
								// 	filters[filter] = true;
								// 	break;
							}
						}
						return updatedFilter;
					}
				);

				// console.log('updatedActiveFilters', updatedActiveFilters);

				// var updatedActiveFilters = { ...dataView.activeFilters };
				// dataView.activeFilters.forEach(activeFilter => {
				// 	if (!dataView.filterDependencies.includes(activeFilter.field)) {
				// 		console.log('Update filter', activeFilter);
				// 		console.log(filterOptionsResult[activeFilter]);
				// 			switch (filterOptionsResult[activeFilter].type) {
				// 				case 'categorical':
				// 					if (activeFilter.singleValue) {
				// 						updatedActiveFilters[activeFilter] = [
				// 							filterOptionsResult[activeFilter]
				// 								.categories[0],
				// 						];
				// 					} else {
				// 						updatedActiveFilters[activeFilter] = [
				// 							...filterOptionsResult[activeFilter]
				// 								.categories,
				// 						];
				// 					}
				// 					break;

				// 				// TODO: add support for the following
				// 				// case 'quantitative':
				// 				// 	filters[filter] = filterOption.range;
				// 				// 	break;
				// 				// case 'boolean':
				// 				// 	filters[filter] = true;
				// 				// 	break;
				// 			}

				// 	} else {
				// 		updatedActiveFilters[activeFilter] =
				// 			newDataFilters[activeFilter];
				// 	}
				// });

				// TODO: Update the active filters here before the refreshDataView
				var newFilterOptions = [];
				Object.keys(filterOptionsResult).forEach(
					(filterOptionKey, index) => {
						newFilterOptions.push({
							dataViewUuid: dataSourceUuid,
							id: index,
							label: filterOptionKey,
							name: filterOptionKey,
							...filterOptionsResult[filterOptionKey],
						});
					}
				);

				dataView.filterUpdateSubs.forEach(filterSub => {
					filterSub.filterUpdate(
						newFilterOptions,
						updatedActiveFilters,
						dataView.timeRange,
						true
					);
				});

				dataView.activeFilters = updatedActiveFilters;
				// console.log('new Active Filters', updatedActiveFilters);
			}

			if (dataView) {
				await refreshDataView(dataView);
				dataView.filterUpdateSubs.forEach(filterSub => {
					filterSub.filterUpdate(null, null, null, false);
				});
			} else {
				throw new Error('Could not find data source', dataSourceUuid);
			}
		} else {
			if (dataView) {
				dataView.activeFilters = newDataFilters;

				await refreshDataView(dataView);

				dataView.filterUpdateSubs.forEach(filterSub => {
					filterSub.filterUpdate(null, null, null, false);
				});
			} else {
				throw new Error('Could not find data source', dataSourceUuid);
			}
		}
	};

	// =================================================================================
	// TODO: Change the following to remove stale data views
	useEffect(() => {
		const interval = setInterval(() => {
			//console.log('Garbage collector');

			// console.log(
			// 	'DataProvider mem used',
			// 	bytes(sizeof(dataSources.current))
			// );

			dataSources.current.forEach(dataSource => {
				//console.log('data len', dataSource.data.length);
				//console.log(dataSource);

				if (!dataSource.isUsed) {
					//console.log('Found unused data_source');
					if (Date.now() > dataSource.lastUsedTimestamp + 10000) {
						//console.log('Removing unused data_source');

						// Unsubscribe from data_source updates
						//console.log('unsub from', dataSource.subChannel);
						unsubscribe(dataSource.uuid, dataSource.subChannel);

						// Remove the data_source
						_.remove(dataSources.current, {
							uuid: dataSource.uuid,
						});
					}
				}
			});
		}, 3000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	// =================================================================================
	// This function is called to setup the data sources
	const addDataSource = async (
		instanceId,
		dataViewUuid,
		defaultTimeRange,
		predefinedFilters,
		filterDependencies,
		transforms,
		dataSourceCallback
	) => {
		// console.log('addDataSource', instanceId, dataViewUuid);

		const dataView = _.find(dataViews.current, {
			uuid: instanceId,
		});

		// Check if we already have the data view stored
		if (dataView) {
			console.log('Restoring data view');
			return dataView;
		} else {
			// Get the base filter options
			var filterOptionsResult = await dataViewFilterOptionsGet({
				dataViewUuid: dataViewUuid,
				timeRange: defaultTimeRange,
			});

			// console.log('filterOptionsResult', filterOptionsResult);
			// console.log('filterDependencies', filterDependencies);

			const newFilterOptions = [];

			// console.log({ filterOptionsResult });

			if (filterDependencies.length > 0) {
				const filters = {};
				filterDependencies.forEach(filter => {
					// If the filter is predefined then we can process it
					const predefinedFilter = _.find(predefinedFilters, {
						field: filter,
					});
					if (predefinedFilter && filterOptionsResult[filter]) {
						switch (filterOptionsResult[filter].type) {
							case 'categorical':
								if (predefinedFilter.singleValue) {
									// Set the predefined filter to the first option in the list
									filters[filter] = [
										filterOptionsResult[filter]
											.categories[0],
									];

									newFilterOptions.push({
										dataViewUuid: instanceId,
										id: 0,
										label: filter,
										name: filter,
										...filterOptionsResult[filter],
									});
								}
								break;

							// TODO: add support for the following
							// case 'quantitative':
							// 	filters[filter] = filterOption.range;
							// 	break;
							// case 'boolean':
							// 	filters[filter] = true;
							// 	break;
						}
					}
				});

				// And initial filters available
				if (Object.keys(filters).length > 0) {
					filterOptionsResult = await dataViewFilterOptionsGetFiltered(
						{
							dataViewUuid: dataViewUuid,
							timeRange: defaultTimeRange,
							filters: filters,
						}
					);
				}
			}

			Object.keys(filterOptionsResult).forEach(
				(filterOptionKey, index) => {
					newFilterOptions.push({
						dataViewUuid: instanceId,
						id: index,
						label: filterOptionKey,
						name: filterOptionKey,
						...filterOptionsResult[filterOptionKey],
					});
				}
			);

			// console.log('predefinedFilters', predefinedFilters);
			// console.log(newFilterOptions);

			var newActiveFilters = predefinedFilters.map(predefinedFilter => {
				const filterOption = _.find(newFilterOptions, {
					name: predefinedFilter.field,
				});

				var newFilter = { ...predefinedFilter };
				newFilter.filterEnabled = predefinedFilter?.active || false;

				if (filterOption) {
					if (predefinedFilter.active) {
						switch (filterOption.type) {
							case 'categorical':
								// activeFilters[predefinedFilter.field] =
								// 	filterOption.categories;
								if (predefinedFilter.singleValue) {
									newFilter.filterValue = [
										filterOption.categories[0],
									];
								} else {
									newFilter.filterValue = [
										...filterOption.categories,
									];
								}
								break;
							case 'quantitative':
								newFilter.filterValue = filterOption.range;
								break;
							case 'boolean':
								newFilter.filterValue = true;
								break;
						}
					}
				}

				return newFilter;
			});

			// console.log('newActiveFilters', newActiveFilters);

			const newTransforms = transforms.map(transform => {
				return {
					...transform,
					dataUpdateSubs: [],
					data: null,
					isUsed: false,
					lastUsedTimestamp: null,
					filters: [], // <<== Is this needed?
					timeRange: defaultTimeRange, // <<== Is this needed?
				};
			});

			// console.log('newTransforms', newTransforms);

			const newDataView = {
				uuid: instanceId,
				dataView: dataViewUuid,
				updateCallback: dataSourceCallback, // <<== TODO: should this be at the data view level?
				timeRange: defaultTimeRange,
				filterOptions: newFilterOptions,
				activeFilters: newActiveFilters,
				transforms: newTransforms,
				filterDependencies: filterDependencies,
				predefinedFilters: predefinedFilters,
				filterUpdateSubs: [],
			};

			dataViews.current.push(newDataView);

			// console.log('newDataView', newDataView);

			// TODO: check if there is a filter update listener

			refreshDataView(newDataView);
			return newDataView;
		}
	};

	// =================================================================================
	// TODO: remove the following
	const removeDataSources = dataSources => {
		// Remove the data sources when we are done with them
		// TODO: should we remove the data sources here or should they remain
		// to be reused and then garbage collected when not used for a period?
	};

	// =================================================================================
	const dataLinkRequest = ({
		requesterUuid,
		dataViewUuid,
		transformUuid,
		dataUpdate,
		dataLoadingUpdate,
	}) => {
		try {
			// console.log('dataViews', dataViews.current);
			// console.log('dataViews.current', dataViews.current);
			// console.log('dataViewUuid', dataViewUuid);
			// console.log('transformUuid', transformUuid);
			const thisDataView = _.find(dataViews.current, {
				uuid: dataViewUuid,
			});

			if (!thisDataView) {
				throw new Error('Missing data view');
			} else {
				const thisTransform = _.find(thisDataView.transforms, {
					uuid: transformUuid,
				});

				if (!thisTransform) {
					throw new Error('Missing data view transform');
				} else {
					// console.log(thisDataSource.dataUpdateSubs);
					if (
						_.includes(thisTransform.dataUpdateSubs, {
							requesterUuid: requesterUuid,
						})
					) {
						console.log('Requester already in list');
					}

					thisTransform.dataUpdateSubs.push({
						requesterUuid: requesterUuid,
						dataUpdate: dataUpdate,
					});

					// Flag that this data_source is in use
					thisTransform.isUsed = true;
					thisTransform.lastUsedTimestamp = null;

					// Send the data we already have to the compoent
					dataUpdate(filterData(thisTransform), false);

					// TODO: Check if the data is stale and needs a new get
				}
			}
		} catch (error) {
			console.log(error);
		}
	};

	// =================================================================================
	const filterLinkRequest = ({
		requesterUuid,
		dataViewUuid,
		filterUpdate,
	}) => {
		// console.log('dataViews.current', dataViews.current);

		const thisDataView = _.find(dataViews.current, {
			uuid: dataViewUuid,
		});

		if (!thisDataView) {
			throw new Error('Missing data view');
		} else {
			// console.log(thisDataView.filterUpdateSubs);
			if (
				_.includes(thisDataView.filterUpdateSubs, {
					requesterUuid: requesterUuid,
				})
			) {
				console.log('Requester already in list');
			}

			thisDataView.filterUpdateSubs.push({
				requesterUuid: requesterUuid,
				filterUpdate: filterUpdate,
			});

			// Send the data we already have to the component
			// dataUpdate(filterData(thisTransform));

			// TODO: Check if the data is stale and needs a new get

			filterUpdate(
				thisDataView.filterOptions,
				thisDataView.activeFilters,
				thisDataView.timeRange,
				false
			);
		}
	};

	// =================================================================================
	const dataLinkEnd = (requesterUuid, dataSource) => {
		// console.log('dataLinkEnd', dataSource);
		try {
			const thisDataSource = _.find(dataSources.current, {
				uuid: dataSource,
			});

			if (!thisDataSource) {
				// console.log('Missing data source');
			} else {
				// Remove the subscriber from our list
				_.remove(thisDataSource.dataUpdateSubs, {
					requesterUuid: requesterUuid,
				});

				// If we have no subscribers left then flag the data source as unused
				// and add the timestamp ready for garbage collection
				if (thisDataSource.dataUpdateSubs.length === 0) {
					// console.log('No Subs left');
					thisDataSource.isUsed = false;
					thisDataSource.lastUsedTimestamp = Date.now();
				}
			}
		} catch (error) {
			console.log(error);
		}
	};

	// =================================================================================
	const refreshDataView = useCallback(async dataView => {
		var newData = [];
		var dataSetSchema = null;

		const thisDataView = _.find(dataViews.current, {
			uuid: dataView.uuid,
		});

		for (var i = 0; i < thisDataView.transforms.length; i++) {
			var queryPipeline = [{ $limit: 10000 }];
			try {
				if (thisDataView.transforms[i].pipeline) {
					queryPipeline = [
						...JSON.parse(thisDataView.transforms[i].pipeline),
						...queryPipeline,
					];
				}
			} catch (error) {
				console.log(error);
			}

			// console.log('dataViewQueryGet', dataView.activeFilters);
			var filterQuery = {};
			dataView.activeFilters.forEach(filter => {
				if (filter.filterEnabled) {
					filterQuery[filter.field] = filter.filterValue;
				}
			});
			// console.log('filterQuery', filterQuery);
			const dataSetData = await dataViewQueryGet({
				dataViewUuid: dataView.dataView,
				filters: filterQuery,
				timeRange: dataView.timeRange,
				query: {
					query: queryPipeline,
				},
			});

			// TODO: get the parameters we need only
			newData = dataSetData.map(dataPoint => {
				return {
					...dataPoint,

					// TODO: chenge to configarable time prop
					// timestamp: new Date(dataPoint.Submission_Date).getTime(),
					// start_time: new Date(dataPoint.Submission_Date).getTime(),
				};
			});

			thisDataView.transforms[i].data = newData;

			if (dataView.updateCallback) {
				dataView.updateCallback(
					dataView.uuid,
					newData, // <<=== This is not used
					dataView.timeRange,
					dataView.filterOptions,
					[]
				);
			}

			processData(thisDataView.transforms[i]);
		}
	});

	// =================================================================================
	const filterData = dataSource => {
		var filteredData = dataSource.data;

		(dataSource?.filters || []).forEach(filter => {
			switch (filter.type) {
				case 'quantitative':
					filteredData = _.filter(filteredData, dataPoint => {
						return (
							dataPoint[filter.field] >= filter.filterProps.min &&
							dataPoint[filter.field] <= filter.filterProps.max
						);
					});
					break;
				case 'categorical':
					filteredData = _.filter(filteredData, dataPoint => {
						return filter.filterProps.includes(
							dataPoint[filter.field]
						);
					});
					break;
				case 'boolean':
					filteredData = _.filter(filteredData, dataPoint => {
						return dataPoint[filter.field] === filter.filterProps;
					});
					break;
				default:
					break;
			}
		});

		return filteredData;
	};

	// =================================================================================
	const processData = dataSource => {
		// console.log('dataSource', dataSource);
		const filteredData = filterData(dataSource);

		dataSource.dataUpdateSubs.forEach(dataSub => {
			dataSub.dataUpdate(filteredData, false);
		});
	};

	// =================================================================================
	const getDataSchema = dataSourceUuid => {
		const thisDataSource = _.find(dataSources.current, {
			uuid: dataSourceUuid,
		});

		return thisDataSource?.schema;
	};

	// =================================================================================
	return (
		<DataProviderContext.Provider
			value={{
				addDataSource,
				removeDataSources,
				dataLinkRequest,
				filterLinkRequest,
				dataLinkEnd,
				filterOptions,
				getDataSchema,
				setDataFilters,
				setDataTimeRange,
			}}
		>
			{children}
		</DataProviderContext.Provider>
	);
};

// =================================================================================
function useDataProvider(
	dataSourceUuid,
	dataViewUuid,
	filterDependencies,
	transforms
) {
	const { addDataSource } = useContext(DataProviderContext);
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(true);

	const dataSourceUpdate = () => {
		console.log('Data Source Update');
	};

	useEffect(() => {
		const onLoad = async () => {
			await addDataSource(
				dataSourceUuid,
				dataViewUuid,
				// timeRange,
				// predefinedFilters,
				filterDependencies,
				transforms,
				dataSourceUpdate
			);
		};

		onLoad();
	}, []);

	return { error, loading };
}

// =================================================================================
function useDataProviderFilters() {
	const {
		filterLinkRequest,
		addDataSource,
		setDataFilters,
		setDataTimeRange,
	} = useContext(DataProviderContext);
	const [filterOptions, setFilterOptions] = useState([]);
	const [filtersLoading, setFiltersLoading] = useState(true);
	const [activeFilters, setActiveFilters] = useState(null);
	const [filtersError, setFiltersError] = useState(false);
	const [timeRange, setTimeCurrentRange] = useState(null);
	const [pendingFilterUpdate, setPendingFilterUpdate] = useState(null);

	const filterUpdate = (
		filterOptions,
		activeFilters,
		timeRangeUpdate,
		loadingStatus
	) => {
		if (filterOptions) {
			setFilterOptions(filterOptions);
		}

		if (activeFilters) {
			setActiveFilters(activeFilters);
		}

		if (timeRangeUpdate) {
			setTimeCurrentRange(timeRangeUpdate);
		}

		if (loadingStatus !== null) {
			setFiltersLoading(loadingStatus);

			// NOTE: the following code trigger a filter update which will in turn
			// call this code again which will be before the state update clears the pendingFilterUpdate
			if (loadingStatus && pendingFilterUpdate) {
				setPendingFilterUpdate(null);
				doSetFilters(pendingFilterUpdate);
			}
		}
	};

	const requestFilterSub = useCallback(
		async (data_sources, defaultTimeRange, insightFilters) => {
			data_sources.forEach(dataSourceUuid => {
				filterLinkRequest({
					// requesterUuid: requesterUuid,
					dataViewUuid: dataSourceUuid,
					filterUpdate: filterUpdate,
				});
			});
		},
		[] // TODO: What goes in here? no point being a useCallback
	);

	const doSetFilters = newFilters => {
		const dataViews = _.uniq(
			newFilters.map(newFilter => {
				return newFilter.dataViewUuid;
			})
		);

		for (const dataView of dataViews) {
			setDataFilters(
				dataView,
				_.filter(newFilters, { dataViewUuid: dataView })
			);
		}
	};

	const setFilters = newFilters => {
		if (filtersLoading) {
			setPendingFilterUpdate(newFilters);
		} else {
			doSetFilters(newFilters);
		}
	};

	const setTimeRange = newTimeRange => {
		// TODO: this is not sensetive to the data view but should be
		// for (const dataSourceUuid of Object.keys(newTimeRange)) {
		setDataTimeRange(newTimeRange);
		// }
	};

	return {
		filterOptions,
		filtersLoading,
		activeFilters,
		filtersError,
		requestFilterSub,
		setFilters,
		setTimeRange,
		timeRange,
	};
}

// =================================================================================
function useDataProviderSubscribe(requesterUuid, dataSource) {
	const { dataLinkRequest, dataLinkEnd } = useContext(DataProviderContext);
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	const dataUpdate = (newData, loadingStatus) => {
		// console.log('dataUpdate', newData, loadingStatus);
		if (newData) {
			setData(newData);
		}

		if (loadingStatus !== null) {
			setLoading(loadingStatus);
		}
	};

	useMemo(
		() => {
			if (dataSource) {
				dataLinkRequest({
					requesterUuid: requesterUuid,
					dataViewUuid: dataSource.uuid,
					transformUuid: dataSource.transform,
					dataUpdate: dataUpdate,
				});
			} else {
				setLoading(false);
			}
		},
		[requesterUuid, dataSource]
	);

	useEffect(
		() => {
			// console.log('useDataProvider useEffecrt', requesterUuid);
			return () => {
				// console.log('useDataProvider useEffecrt return', requesterUuid);
				dataLinkEnd(requesterUuid, dataSource);
			};
		},
		[requesterUuid, dataSource]
	);

	return { data, error, loading };
}

export {
	useDataProviderContext,
	DataProvider,
	useDataProvider,
	useDataProviderFilters,
	useDataProviderSubscribe,
};

useDataProvider.propTypes = {
	dataSourceUuid: PropTypes.string,
	dataViewUuid: PropTypes.string,
	timeRange: PropTypes.object,
	predefinedFilters: PropTypes.object,
	filterDependencies: PropTypes.array,
	transforms: PropTypes.array,
};

useDataProviderFilters.propTypes = {
	dataSourceUuid: PropTypes.string,
	dataViewUuid: PropTypes.string,
	timeRange: PropTypes.object,
	predefinedFilters: PropTypes.object,
	filterDependencies: PropTypes.array,
	transforms: PropTypes.array,
};

useDataProviderSubscribe.propTypes = {
	requesterUuid: PropTypes.string,
	dataSource: PropTypes.object,
};
