import { useMemo } from 'react';

import { useAppContext } from '../context/ContextManager';

export default function useGetTagGroupIdByName(groupName) {
	const { appOrg } = useAppContext();

	return useMemo(
		() => {
			const tagGroups = appOrg?.tags;
			const foundGroup = (tagGroups || []).find(
				({ name }) => name === groupName
			);

			return foundGroup ? foundGroup.uuid : null;
		},
		[appOrg, groupName]
	);
}
