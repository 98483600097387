import React, { useState, useCallback } from 'react';
import { Auth } from 'aws-amplify';
import * as yup from 'yup';
import { useAppContext } from '../../context/ContextManager';
import { setAppStoreItem } from '../../utils/appStore';

import { useSnackbar } from 'notistack';

import { Container, Typography, Link, Button } from '@mui/material';

import BTAuthCard from './BTAuthCard';
import {
	BtForm,
	BtFormChangesBlocker,
	BtFormContent,
	BtFormSection,
	BtFormTextField,
	BtAuthFormActionButtons,
	BtFormPasswordField,
} from '../../components/generic/forms';

// Yup schema
const schema = yup.object({
	email: yup
		.string()
		.email()
		.required()
		.label('email'),
	password: yup
		.string()
		.required()
		.label('password'),
	// checkboxValue: yup.boolean().label('Checkbox'),
});

/* 
Default values
*/
const defaultValues = {
	email: '',
	password: '',
	// checkboxValue: false,
};

export default function Login(props) {
	const { enqueueSnackbar } = useSnackbar();
	const [sending, setSending] = useState(false);
	const { userHasAuthenticated, setDevUser, localDevMode } = useAppContext();
	const [failedSignIn, setFailedSignIn] = useState(false);

	// Callback call to change view if 'forgot password' clicked
	function handleClickForgotPasswordLink() {
		props.changeView('ForgotPassword');
	}

	// Clickable forgot password text component
	function ForgotPasswordComponent() {
		return (
			<Typography
				align="center"
				sx={{ cursor: 'pointer' }}
				onClick={() => handleClickForgotPasswordLink()}
				variant="caption"
			>
				Forgot Password?
			</Typography>
		);
	}

	// Text component which is rendered after failed sign in
	function FailedSignInComponent() {
		return (
			<Typography // sx={{ color: 'red' }}
				align="center"
			>
				We didn't recognise your details. Please check your email and
				password or{' '}
				<Link
					component="button"
					variant="body2"
					onClick={() => handleClickForgotPasswordLink()}
					color="inherit"
				>
					reset your password
				</Link>
			</Typography>
		);
	}

	const onSubmit = useCallback(
		async (values, reset) => {
			// These props are passed up to authentication for use in the change password component
			props.setUserPropsCallback({
				user: values.email,
				userToken: values.password,
			});
			try {
				setSending(true);
				await Auth.signIn(values.email, values.password)
					.then(user => {
						if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
							// If cognito requires a new password then the user is routed to change password view
							props.changeSetPasswordResetRequired(true);
							enqueueSnackbar(
								'It looks like your password needs to be changed.',
								{
									variant: 'Warning',
									anchorOrigin: {
										vertical: 'top',
										horizontal: 'center',
									},
								}
							);
						} else {
							// console.log('user authenticated');
							userHasAuthenticated(true);
							props.setAuthLoadingCallback(true);
						}
					})
					.catch(e => {
						console.log(e);
						enqueueSnackbar(
							"We didn't recognise your details. Please check your email and password or reset your password",
							{
								variant: 'error',
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'center',
								},
							}
						);
						setFailedSignIn(true);
						setSending(false);
					});
			} catch (e) {
				// 	// setSending(false);
				console.log(e.message);
			}
		},
		[enqueueSnackbar, props, userHasAuthenticated]
	);

	return (
		<BTAuthCard
			heading={
				failedSignIn ? (
					<FailedSignInComponent />
				) : (
					<Typography align="center">
						Welcome. Please login.
					</Typography>
				)
			}
			form={
				<Container maxWidth="md">
					{localDevMode && (
						<>
							<Button
								variant="contained"
								sx={{ width: '100%', margin: '8px' }}
								onClick={async () => {
									console.log('Login');
									setDevUser(
										'adb1186b-5505-4f45-bb87-10dc0e496f00'
									);
									await setAppStoreItem(
										'DevUser',
										'adb1186b-5505-4f45-bb87-10dc0e496f00'
									);
									userHasAuthenticated(true);
								}}
							>
								Login as Dave Jones (Admin)
							</Button>
							<Button
								variant="contained"
								sx={{ width: '100%', margin: '8px' }}
								onClick={async () => {
									console.log('Login');
									setDevUser(
										'5eff1493-a858-4b11-8286-6dc956c382f3'
									);
									await setAppStoreItem(
										'DevUser',
										'5eff1493-a858-4b11-8286-6dc956c382f3'
									);
									userHasAuthenticated(true);
								}}
							>
								Login As Bill Smith
							</Button>

							<Button
								variant="contained"
								sx={{ width: '100%', margin: '8px' }}
								onClick={async () => {
									console.log('Login');
									setDevUser(
										'42e3d6e6-6969-11ed-a1eb-0242ac120002'
									);
									await setAppStoreItem(
										'DevUser',
										'42e3d6e6-6969-11ed-a1eb-0242ac120002'
									);
									userHasAuthenticated(true);
								}}
							>
								Login As Johnny Richie Donalds
							</Button>
							<Button
								variant="contained"
								sx={{ width: '100%', margin: '8px' }}
								onClick={async () => {
									console.log('Login');
									setDevUser(
										'4a87ed92-6969-11ed-a1eb-0242ac120002'
									);
									await setAppStoreItem(
										'DevUser',
										'4a87ed92-6969-11ed-a1eb-0242ac120002'
									);
									userHasAuthenticated(true);
								}}
							>
								Login As Konnor Harland
							</Button>
							<Button
								variant="contained"
								sx={{ width: '100%', margin: '8px' }}
								onClick={async () => {
									console.log('Login');
									setDevUser(
										'5046c3de-6969-11ed-a1eb-0242ac120002'
									);
									await setAppStoreItem(
										'DevUser',
										'5046c3de-6969-11ed-a1eb-0242ac120002'
									);
									userHasAuthenticated(true);
								}}
							>
								Login As John Slade
							</Button>
						</>
					)}
					{!localDevMode && (
						<BtForm
							validationSchema={schema}
							defaultValues={defaultValues}
							// loading={false}
							sending={sending}
							onSubmit={(values, reset) =>
								onSubmit(values, reset)
							}
						>
							<BtFormSection style={{ margin: '0px' }}>
								<BtFormContent>
									<BtFormTextField
										name="email"
										label="Email"
									/>
									<BtFormPasswordField
										name="password"
										label="Password"
										isRequired={true}
									/>{' '}
									{/* <BtFormCheckbox
									name="checkboxValue"
									label="Remember Me?"
								/> */}
									<BtAuthFormActionButtons
										submitVerb="Login"
										submitPresentVerb="Logging you in.."
									/>
									<BtFormChangesBlocker />
									<ForgotPasswordComponent />
								</BtFormContent>
							</BtFormSection>
						</BtForm>
					)}
				</Container>
			}
		/>
	);
}
