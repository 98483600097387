import React from 'react';
import PropTypes from 'prop-types';

import ErrorIcon from '@mui/icons-material/Error';
import { styled, Tooltip } from '@mui/material';

const ErrorContainer = styled('div')(() => ({
	alignItems: 'center',
	display: 'flex',
	justifyContent: 'center',
}));

const Error = styled('div')(({ theme }) => ({
	alignItems: 'center',
	backgroundColor: '#fff',
	borderRadius: 7.5,
	color: theme.palette.indicators.error.main,
	display: 'flex',
	height: 15,
	justifyContent: 'center',
	marginRight: '0.5em',
	width: 15,
}));

export default function DataSetErrorTooltip({
	children,
	open,
	title,
	verticalOffset,
	...other
}) {
	return (
		<Tooltip
			arrow
			disableInteractive
			open={open}
			PopperProps={{
				modifiers: [
					{
						name: 'offset',
						options: {
							offset: [0, verticalOffset],
						},
					},
				],
			}}
			title={
				<ErrorContainer>
					<Error>
						<ErrorIcon />
					</Error>
					{title}
				</ErrorContainer>
			}
			TransitionProps={{ timeout: 0 }}
			{...other}
		>
			{children}
		</Tooltip>
	);
}

DataSetErrorTooltip.defaultProps = {
	verticalOffset: 0,
};

DataSetErrorTooltip.propTypes = {
	children: PropTypes.node.isRequired,
	open: PropTypes.bool,
	title: PropTypes.string.isRequired,
	verticalOffset: PropTypes.number,
};
