import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useAppContext } from '../../context/ContextManager';
import { Auth } from 'aws-amplify';
import ChangePasswordFrom from '../auth/ChangePasswordForm';
import {
	IconButton,
	InputAdornment,
	Link,
	TextField,
	Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import BtDialog from '../../components/generic/BtDialog';
import ForgotPassword from '../auth/ForgotPassword';
import BtMessage from '../../components/generic/BtMessage';
import { setAppStoreItem } from '../../utils/appStore';

// yup schema for new password
const schema = yup.object({
	current: yup
		.string()
		.min(10, 'Your current password should be at least 10 characters')
		.required('Your current password is required'),
});

export default function UserChangePassword() {
	const {
		userHasAuthenticated,
		setLoadApp,
		setDevUser,
		userInfo,
	} = useAppContext();

	const { enqueueSnackbar } = useSnackbar();
	const [error, setError] = useState(false);
	const [sending, setSending] = useState(false);
	const [forgotPasswordDialogOpen, setForgotPasswordDialogOpen] = useState(
		false
	);
	const [resetRequestSubmitted, setResetRequestSubmitted] = useState(false);
	const handleCloseDialog = useCallback((event, reason) => {
		if (reason !== 'backdropClick') {
			setForgotPasswordDialogOpen(false);
		}
	}, []);

	// React Hook Form
	const { control, watch, reset } = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		defaultValues: { current: '' },
	});

	// Password visibility state
	const [showCurrentPassword, setShowCurrentPassword] = useState(false);

	// Password visibility
	const handleClickShowCurrentPassword = useCallback(() => {
		setShowCurrentPassword(previousState => !previousState);
	}, []);

	useEffect(
		() => {
			if (resetRequestSubmitted) {
				Auth.signOut().then(async () => {
					await setAppStoreItem('DevUser', null);

					setTimeout(() => {
						setForgotPasswordDialogOpen(false);
						setDevUser(null);
						userHasAuthenticated(false);
						setLoadApp(false);
						// await setAppStoreItem('DevUser', null);
						// setAppOrg(null);
						// setUserInfo(null);
					}, 5000);
				});
			}
		},
		[resetRequestSubmitted, setDevUser, setLoadApp, userHasAuthenticated]
	);

	function handleMouseDown(event) {
		event.preventDefault();
	}

	const currentPassword = watch('current');

	// const test = 'success';

	const onSubmit = useCallback(
		async newPassword => {
			setSending(true);

			await Auth.currentAuthenticatedUser()
				.then(user => {
					return Auth.changePassword(
						user,
						currentPassword,
						newPassword
					);
				})
				.then(data => {
					console.log(data);
					setSending(false);
					enqueueSnackbar('Password changed.', {
						variant: 'success',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'center',
						},
					});
					reset(); // reset the form
				})
				.catch(err => {
					console.log(err);
					setSending(false);
					setError(true);
				});
		},
		[currentPassword, enqueueSnackbar, reset]
	);

	return (
		<>
			{error && (
				<BtMessage
					style={{ marginBottom: '1em' }}
					variant="error"
					message={
						<>
							<Typography>
								Something went wrong, please try again or{' '}
								<Link
									component="button"
									variant="body2"
									onClick={() =>
										setForgotPasswordDialogOpen(true)
									}
									color="inherit"
								>
									reset your password
								</Link>
							</Typography>
						</>
					}
				/>
			)}
			<BtDialog
				open={forgotPasswordDialogOpen}
				onClose={handleCloseDialog}
			>
				<ForgotPassword
					setResetRequestSubmitted={setResetRequestSubmitted}
					signingOut={resetRequestSubmitted}
					onClose={handleCloseDialog}
					email={userInfo.email}
					prePopulateForm
				/>
			</BtDialog>

			<Controller
				name="current"
				control={control}
				// autoComplete="new-password"
				autoComplete="password"
				render={({
					field: { ref, ...field },
					fieldState: { error },
				}) => (
					<TextField
						disabled={sending}
						{...field}
						key={2}
						variant="standard"
						fullWidth
						margin="normal"
						label="Confirm Current Password"
						id={field.name}
						type={showCurrentPassword ? 'text' : 'password'}
						// autoComplete="new-password"
						autoComplete="password"
						error={!!error}
						helperText={error?.message}
						InputProps={{
							// autoComplete: 'new-password',
							autoComplete: 'password',
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowCurrentPassword}
										onMouseDown={handleMouseDown}
									>
										{showCurrentPassword ? (
											<VisibilityOff />
										) : (
											<Visibility />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				)}
			/>

			<Typography style={{ marginTop: '1em', marginBottom: '1em' }}>
				Your new password will need to meet the following requirements.
			</Typography>
			<ChangePasswordFrom
				handleFormSubmit={onSubmit}
				sending={sending}
				disableForm={currentPassword?.length < 10}
			/>
		</>
	);
}
