import React, { useEffect, useState } from 'react';
import { Box, IconButton, TextField, Tooltip, Typography } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { BtFormSection } from './forms';

export default function BtKeyValueList({
	title,
	listItems,
	onListItemsUpdate,
	keyId,
	valueId,
}) {
	const handleKeyUpdate = (index, newKey) => {
		var updateList = [...listItems];
		updateList[index][keyId] = newKey;
		onListItemsUpdate(updateList);
	};

	const handleValueUpdate = (index, newValue) => {
		var updateList = [...listItems];
		updateList[index][valueId] = newValue;
		onListItemsUpdate(updateList);
	};

	const handleDeletePair = index => {
		var updateList = [...listItems];
		updateList.splice(index, 1);
		onListItemsUpdate(updateList);
	};

	const handleAddPair = () => {
		var updateList = [...listItems, { [valueId]: '', [keyId]: '' }];
		onListItemsUpdate(updateList);
	};

	return (
		<BtFormSection style={{ marginBottom: 0 }} title={title}>
			<Box style={{ display: 'flex', alignItems: 'center' }}>
				<Typography
					variant="label"
					style={{ padding: '0.5em', flexGrow: 2 }}
				>
					{keyId}
				</Typography>
				<Typography
					variant="label"
					style={{ padding: '0.5em', flexGrow: 2 }}
				>
					{valueId}
				</Typography>

				<Tooltip title="Add Pair" disableInteractive>
					<IconButton
						style={{
							width: 40,
							height: 40,
						}}
						onClick={handleAddPair}
					>
						<AddIcon />
					</IconButton>
				</Tooltip>
			</Box>
			{listItems.map((item, index) => (
				<Box
					key={index}
					style={{ display: 'flex', alignItems: 'center' }}
				>
					<TextField
						style={{ padding: '0.5em', flexGrow: 2 }}
						value={item[keyId]}
						variant="outlined"
						onChange={event => {
							const value = event.target.value;
							handleKeyUpdate(index, value);
						}}
					/>
					<TextField
						style={{ padding: '0.5em', flexGrow: 2 }}
						value={item[valueId]}
						variant="outlined"
						onChange={event => {
							const value = event.target.value;
							handleValueUpdate(index, value);
						}}
					/>

					<Tooltip title="Delete" disableInteractive>
						<IconButton
							style={{
								width: 40,
								height: 40,
							}}
							onClick={() => {
								handleDeletePair(index);
							}}
						>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				</Box>
			))}
		</BtFormSection>
	);
}
