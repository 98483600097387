import React from 'react';

// MUI ICONS
// import Dashboard from '@mui/icons-material/Dashboard';
import InsightsIcon from '@mui/icons-material/Insights';

const insights_nav_item = [
	{
		id: 'Insights',
		text: 'Insights',
		route: '/Insights',
		icon: <InsightsIcon />,
		active: true,
		// requiredPermissionsAll: {
		// 	insights: ['InsightsView'],
		// },
	},
];

export default insights_nav_item;
