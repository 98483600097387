export const stageTypeDefaultQuery = stageType => {
	switch (stageType) {
		case '$sort':
			return { $sort: {} };
		case '$limit':
			return { $limit: 100 };
		case '$skip':
			return { $skip: 0 };
		case '$project':
			return { $project: {} };
		case '$replaceRoot':
			return { $replaceRoot: { newRoot: '' } };
		case '$unwind':
			return { $unwind: '' };
		case '$match':
			return { $match: {} };
		case '$group':
			return { $group: {} };
		default:
			return { $match: {} };
	}
};

export const primitiveValues = [
	'string',
	'number',
	'date',
	'datetime',
	'boolean',
	'object',
	'array',
];

export function replaceDynamicTypedValue(
	fieldName,
	fields,
	variables,
	asPath = false
) {
	const type = fields[fieldName + '_type'];

	if (type === 'variable' && variables) {
		const idx = variables.findIndex(
			v => v.schemaPath === fields[fieldName]
		);

		if (idx > -1) {
			return '#' + variables[idx].key + '#';
		}
	} else if (type === 'field-path') {
		if (!fields[fieldName]) {
			return null;
		}
		if (!asPath) {
			if (fields[fieldName][0] !== '$') {
				return '$' + fields[fieldName];
			}
		}
	} else if (
		type === 'static_object' ||
		type === 'static_array' ||
		type === 'expression'
	) {
		try {
			return JSON.parse(fields[fieldName]);
		} catch (err) {
			return null;
		}
	} else if (type === 'static_date') {
		return { $toDate: fields[fieldName] };
	}

	return fields[fieldName];
}

export function determineValueType(value, flatConfigSchema, asPath, variables) {
	if (value === null) {
		return ['static_null', null];
	}

	if (typeof value !== 'string') {
		if (typeof value === 'object') {
			if (
				!Object.hasOwnProperty.call(value, '$literal') &&
				Object.keys(value)?.[0]?.[0] === '$'
			) {
				return ['expression', JSON.stringify(value)];
			}

			return ['static_object', JSON.stringify(value)];
		} else if (primitiveValues.indexOf(typeof value) > -1) {
			return ['static' + '_' + typeof value, value];
		}

		return ['static_string', value + ''];
	}

	if (
		value[0] === '#' &&
		value[value.length - 1] === '#' &&
		value.length > 2 &&
		variables
	) {
		const idx = variables.findIndex(v => v.key === value.slice(1, -1));
		if (idx > -1) {
			// whole reason for this fn...
			return ['variable', variables[idx].schemaPath];
		}
	}

	if (
		(value[0] === '$' &&
			value.length > 1 &&
			Object.hasOwnProperty.call(flatConfigSchema, value.slice(1))) ||
		(asPath &&
			value[0] !== '$' &&
			Object.hasOwnProperty.call(flatConfigSchema, value))
	) {
		return ['field-path', !asPath ? value.slice(1) : value];
	}

	return ['static_string', value + ''];
}
