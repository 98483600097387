const {
	ERROR,
	setOperator,
	evaluateExpression,
	OPERATION_MODE,
} = require('../../expression.js');

const operatorKey = '$min';

setOperator(
	operatorKey,
	/**
	 * @type {ExpressionAccumulatorFunction<number , number>}
	 */
	(context, args, options) => {
		const arr = !Array.isArray(args) ? [args] : args;

		if (arr.length !== 1) {
			throw new Error(ERROR.INVALID_NUMBER_ARGS(operatorKey, 1, 1));
		}

		const arg = arr[0];

		const acc = context.fieldValues[0] || { value: Infinity };

		const value =
			evaluateExpression(
				{ ...context, operationMode: OPERATION_MODE.AGGREGATE },
				arg
			) ?? null;

		if (typeof value !== 'number') {
			return context.fieldValues.length === 0 ? null : acc.value;
		}

		acc.value = Math.min(acc.value, value);

		context.fieldValues[0] = acc;

		return acc.value;
	},
	[OPERATION_MODE.ACCUMULATE]
);
