import _, { get } from 'lodash';
import moment from 'moment';

/**
 *
 * @param {*} value
 * @param {*} visOptions
 * @returns
 */
const yAxisFormatter = (value, visOptions) => {
	// declare decimal points value
	// if none provided assume 2
	let decimalPoints = null;
	if (Object.hasOwn(visOptions.valuePrimaryAxis, 'decimalPoints')) {
		if (typeof +visOptions.valuePrimaryAxis.decimalPoints === 'number') {
			decimalPoints = +visOptions.valuePrimaryAxis.decimalPoints;
		}
	} else {
		decimalPoints = 2;
	}

	var formattedValue = null;

	// TODO: add SI formatting option
	// TODO: decimal point count setting
	if (value < 1000) {
		formattedValue = value.toFixed(decimalPoints);
	} else if (value < 1000000) {
		formattedValue = (value / 1000).toFixed(decimalPoints) + 'k';
	} else if (value < 1000000000) {
		formattedValue = (value / 1000000).toFixed(decimalPoints) + 'M';
	} else if (value < 1000000000000) {
		formattedValue = (value / 1000000000).toFixed(decimalPoints) + 'G';
	}

	if (visOptions?.valuePrimaryAxis?.prefix) {
		formattedValue = `${
			visOptions.valuePrimaryAxis.prefix
		}${formattedValue}`;
	}

	return [formattedValue];
};

// Used to format tooltip values
const valueFormatter = (value, name, visOptions) => {
	// console.log({ value, name, visOptions });

	const getParam = () => {
		const valueResult = _.find(visOptions.params, { value: name });
		if (valueResult) {
			return valueResult;
		} else {
			return _.find(visOptions.params, { label: name });
		}
	};

	const paramSetting = getParam();

	// try to get the decimal points from the paramSetting
	// if not, try to get it from the visOptions
	// if not, default to 2
	let decimalPoints = 2;
	if (get(paramSetting, 'label_value_decimal_places', null)) {
		decimalPoints = paramSetting.label_value_decimal_places;
	} else if (
		get(visOptions, 'valuePrimaryAxis.label_value_decimal_places', null)
	) {
		decimalPoints = visOptions.valuePrimaryAxis.label_value_decimal_places;
	}

	var formattedValue = null;

	// TODO: add SI formatting option
	// TODO: decimal point count setting
	if (value < 1000) {
		formattedValue = value.toFixed(decimalPoints);
	} else if (value < 1000000) {
		formattedValue = (value / 1000).toFixed(decimalPoints) + 'k';
	} else if (value < 1000000000) {
		formattedValue = (value / 1000000).toFixed(decimalPoints) + 'M';
	} else if (value < 1000000000000) {
		formattedValue = (value / 1000000000).toFixed(decimalPoints) + 'G';
	}

	// first check if the param has a prefix or suffix property,
	// if not check if the visOptions has valuePrimaryAxis it
	if (get(paramSetting, 'prefix', null)) {
		formattedValue = `${paramSetting.prefix || null}${formattedValue}`;
	} else if (get(visOptions, 'valuePrimaryAxis.prefix', null)) {
		formattedValue = `${visOptions.valuePrimaryAxis.prefix ||
			''}${formattedValue}`;
	}
	if (get(paramSetting, 'suffix', null)) {
		formattedValue = `${formattedValue}${paramSetting.suffix || null}`;
	} else if (get(visOptions, 'valuePrimaryAxis.suffix', null)) {
		formattedValue = `${formattedValue}${visOptions.valuePrimaryAxis
			.suffix || ''}`;
	}

	// To support typo in seed data
	// if (paramSetting.prefix) {
	// 	formattedValue = `${paramSetting.prefix}${formattedValue}`;
	// }

	return [formattedValue, paramSetting.label];
};

// TODO - refactor to use date fns?
/**
 * Format the timestamp
 * @param {*} tick the timestamp value to format
 * @param {*} format
 * @returns
 */
const formatTimestamp = (tick, format) => moment(tick).format(format);

export { yAxisFormatter, valueFormatter, formatTimestamp };
