import React, { forwardRef } from 'react';
import { colourSwatchGeometryStyle } from '../../utils/constants';
import { styled } from '@mui/styles';

const resolveCursor = (isDragging, disabled) => {
	if (disabled) {
		return 'auto';
	} else {
		return isDragging ? 'grabbing' : 'pointer';
	}
};

const Swatch = styled('div')(
	({ disabled, dragging, opacity, style, colour }) => ({
		transform: dragging ? 'scale(1.1)' : 'scale(1)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		transformOrigin: '50% 50%',
		opacity: opacity ? '0.5' : '1',
		cursor: resolveCursor(dragging, disabled),
		backgroundColor: colour,
		...colourSwatchGeometryStyle,
		...style,
		'&:hover': {
			transform: !disabled ? 'scale(1.1)' : 'scale(1)',
		},
	})
);

/**
 * @param disabled
 * @param colour
 * @param id
 * @param withOpacity
 * @param isDragging
 * @param style
 * @param children
 * @param props
 */
const Item = forwardRef(
	(
		{
			disabled,
			colour,
			id,
			withOpacity,
			isDragging,
			style,
			children,
			...props
		},
		ref
	) => {
		return (
			<Swatch
				ref={ref}
				disabled={disabled}
				dragging={isDragging ? 'true' : undefined}
				colour={colour}
				opacity={withOpacity ? 'true' : undefined}
				style={style}
				{...props}
			>
				{children}
			</Swatch>
		);
	}
);

export default Item;
