import { apiClient } from './api_client';

import { permissionsGetListSchema } from './validations/permissionsValidation';

//========================================================================================================
export async function permissionsGetList() {
	const req_url = 'permissions';
	try {
		const response = await apiClient({ method: 'get', url: req_url });

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				await permissionsGetListSchema.validate(response.roles);
				return response.permissions;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}
