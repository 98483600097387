import {
	DOES_NOT_EQUAL,
	DOES_NOT_HAVE_FIELD,
	DOES_NOT_INCLUDE,
	ENDS_WITH,
	EQUALS,
	HAS_FIELD,
	HAS_LENGTH_GREATER_THAN,
	HAS_LENGTH_GREATER_THAN_OR_EQUAL_TO,
	HAS_LENGTH_LESS_THAN,
	HAS_LENGTH_LESS_THAN_OR_EQUAL_TO,
	HAS_LENGTH_OF,
	INCLUDES,
	IS_BETWEEN,
	IS_EMPTY,
	IS_GREATER_THAN,
	IS_GREATER_THAN_OR_EQUAL_TO,
	IS_INSIDE_GEO_RADIUS,
	IS_LESS_THAN,
	IS_LESS_THAN_OR_EQUAL_TO,
	IS_NOT_BETWEEN,
	IS_NOT_EMPTY,
	IS_NOT_ONE_OF,
	IS_ONE_OF,
	IS_OUTSIDE_GEO_RADIUS,
	STARTS_WITH,
} from '../matcher/comparativeOperations';

// Data type constants
export const ARRAY = 'array';
export const BOOLEAN = 'boolean';
export const DATE = 'date';
export const GEOLOCATION = 'geolocation';
export const NUMBER = 'number';
export const OBJECT = 'object';
export const STRING = 'string';
export const TEMPORAL = 'temporal';

export const dataTypes = {
	[ARRAY]: {
		type: ARRAY,
		defaultValue: [],
		requiredValidation: [['yup.array']],
		comparitiveOperations: [
			DOES_NOT_INCLUDE,
			HAS_LENGTH_GREATER_THAN,
			HAS_LENGTH_GREATER_THAN_OR_EQUAL_TO,
			HAS_LENGTH_LESS_THAN,
			HAS_LENGTH_LESS_THAN_OR_EQUAL_TO,
			HAS_LENGTH_OF,
			INCLUDES,
			IS_EMPTY,
			IS_NOT_EMPTY,
		],
	},
	[BOOLEAN]: {
		type: BOOLEAN,
		defaultValue: false,
		requiredValidation: [['yup.boolean']],
		comparitiveOperations: [
			DOES_NOT_EQUAL,
			EQUALS,
			IS_NOT_ONE_OF,
			IS_ONE_OF,
		],
	},
	[GEOLOCATION]: {
		type: GEOLOCATION,
		defaultValue: {
			latitude: null,
			longitude: null,
		},
		requiredValidation: [['yup.object']],
		comparitiveOperations: [
			DOES_NOT_EQUAL,
			EQUALS,
			IS_INSIDE_GEO_RADIUS,
			IS_OUTSIDE_GEO_RADIUS,
		],
	},
	[NUMBER]: {
		type: NUMBER,
		defaultValue: 0,
		requiredValidation: [
			['yup.number'],
			['yup.typeError', 'A number must be specified'],
		],
		comparitiveOperations: [
			DOES_NOT_EQUAL,
			EQUALS,
			IS_BETWEEN,
			IS_GREATER_THAN,
			IS_GREATER_THAN_OR_EQUAL_TO,
			IS_LESS_THAN,
			IS_LESS_THAN_OR_EQUAL_TO,
			IS_NOT_BETWEEN,
			IS_NOT_ONE_OF,
			IS_ONE_OF,
		],
	},
	[OBJECT]: {
		type: OBJECT,
		defaultValue: {},
		requiredValidation: [['yup.object']],
		comparitiveOperations: [
			DOES_NOT_EQUAL,
			DOES_NOT_HAVE_FIELD,
			EQUALS,
			HAS_FIELD,
			IS_EMPTY,
			IS_NOT_EMPTY,
		],
	},
	[STRING]: {
		type: STRING,
		defaultValue: '',
		requiredValidation: [['yup.string']],
		comparitiveOperations: [
			DOES_NOT_EQUAL,
			DOES_NOT_INCLUDE,
			ENDS_WITH,
			HAS_LENGTH_GREATER_THAN,
			HAS_LENGTH_GREATER_THAN_OR_EQUAL_TO,
			HAS_LENGTH_LESS_THAN,
			HAS_LENGTH_LESS_THAN_OR_EQUAL_TO,
			HAS_LENGTH_OF,
			INCLUDES,
			IS_EMPTY,
			IS_NOT_EMPTY,
			IS_NOT_ONE_OF,
			IS_ONE_OF,
			STARTS_WITH,
		],
	},
	[TEMPORAL]: {
		type: TEMPORAL,
		defaultValue: () => +new Date(),
		requiredValidation: [['yup.number']],
		comparitiveOperations: [
			DOES_NOT_EQUAL,
			EQUALS,
			IS_BETWEEN,
			IS_GREATER_THAN,
			IS_GREATER_THAN_OR_EQUAL_TO,
			IS_LESS_THAN,
			IS_LESS_THAN_OR_EQUAL_TO,
			IS_NOT_BETWEEN,
		],
	},
};

export default dataTypes;
