import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Controller, useFormContext } from 'react-hook-form';
import { Skeleton } from '@mui/material';

import BtColourPicker from '../BtColourPicker';
import useFormElRequired from '../../../hooks/useFormElRequired';

export default function BtFormColourPicker({
	disabled,
	isRequired,
	label,
	name,
	...other
}) {
	const {
		control,
		watch,
		loading,
		sending,
		setValue,
		validationSchema,
		formState: { errors },
	} = useFormContext() || { formState: {} };
	const value = watch(name);

	const required = useFormElRequired(isRequired, validationSchema, name);

	const handleChange = useCallback(
		value => {
			setValue(name, value, {
				shouldValidate: true,
				shouldDirty: true,
				shouldTouch: true,
			});
		},
		[setValue, name]
	);

	if (loading) {
		return (
			<Skeleton
				animation="wave"
				style={{
					width: '100%',
					maxWidth: 100,
					height: 30,
					borderRadius: 8,
				}}
				variant="rectangular"
			/>
		);
	}

	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<BtColourPicker
					{...field}
					value={value}
					onChange={handleChange}
					disabled={loading || sending || disabled}
					errorText={errors[name]?.message}
					label={
						!!label
							? `${label}${!required ? ' (optional)' : ''}`
							: undefined
					}
					{...other}
				/>
			)}
		/>
	);
}

BtFormColourPicker.propTypes = {
	disabled: PropTypes.bool,
	errorText: PropTypes.string,
	isRequired: PropTypes.bool,
	label: PropTypes.string,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	style: PropTypes.object,
};
