import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	DialogActions,
	DialogContent,
	DialogTitle,
	Input,
	Typography,
} from '@mui/material';
import produce from 'immer';

import BtError from '../../../../components/generic/BtError';
import BtLoading from '../../../../components/generic/BtLoading';
import FilterIcon from '@mui/icons-material/FilterList';
import PermissionMethodGroup from './PermissionMethodsGroup';
import { PermWizardContext } from './PermWizardContext';
import useFetch from '../../../../hooks/useFetch';
import {
	useWizard,
	WizardButtons,
	WizardHeading,
} from '../../../../components/wizard';

const permComparator = (a, b) => a['name']?.localeCompare(b['name']);

export default function PermissionMethods({ getPermissionsList }) {
	const activeRef = useRef(null);
	const contentRef = useRef(null);
	const blockAutoScroll = useRef(null);

	const { addedPerms, data, onClose, permToEdit, setData } = useContext(
		PermWizardContext
	);

	const {
		loading,
		data: permissions,
		request: getPermissions,
		error,
	} = useFetch(getPermissionsList);

	useEffect(
		() => {
			// Get permissions from the API
			if (!permissions) {
				getPermissions();
			}
		},
		[getPermissions, permissions]
	);

	const { goToStep, isFirstStep, isLastStep, nextStep } = useWizard();

	const [searchTerm, setSearchTerm] = useState('');

	useEffect(() => {
		// Auto-scroll to the selected method
		if (
			!blockAutoScroll?.current &&
			!!data.method &&
			!!activeRef?.current &&
			!searchTerm
		) {
			activeRef.current.scrollIntoView({ block: 'center' });
			blockAutoScroll.current = true;
		}
	});

	useEffect(
		() => {
			// Scroll to the top when filtering
			if (searchTerm && contentRef) {
				contentRef.current.scrollTo({ top: 0, left: 0 });
				blockAutoScroll.current = false;
			}
		},
		[searchTerm]
	);

	const filteredPermissions = useMemo(
		() => {
			if (Array.isArray(permissions)) {
				const term = (searchTerm || '').toLowerCase().trim();

				const tempPermissions = permissions.reduce(
					(accumulator, group) => {
						const composePermission = methods => [
							...accumulator,
							{ ...group, methods },
						];

						const methods = group.methods;

						if (methods.length === 0) return [...accumulator];

						// If search term of group name matches
						if (
							!searchTerm ||
							group.name.toLowerCase().includes(term)
						) {
							return composePermission(methods);
						}

						// Filter down methods in the group
						const filteredMethods = methods.filter(method => {
							const { name, description } = method;
							return (
								(name || '').toLowerCase().includes(term) ||
								(description || '').toLowerCase().includes(term)
							);
						});

						// If any methods remain include the group...
						if (filteredMethods.length > 0) {
							return composePermission(filteredMethods);
						}

						// ...otherwise drop the group entirely
						return [...accumulator];
					},
					[]
				);

				return tempPermissions.sort(permComparator);
			}
			return [];
		},
		[permissions, searchTerm]
	);

	const confirmMethod = useCallback(
		(method, group) => {
			// If new method has been selected
			if (data.lastMethodName !== method.name) {
				setData(
					produce(draft => {
						const alreadyAdded = addedPerms
							?.map(({ method }) => method)
							.includes(method.name);

						draft.resources = null;
						draft.lastMethodName = method.name;

						// If selection already added then set data relating to perm for editing...
						if (alreadyAdded) {
							const permission = addedPerms?.find(
								perm => perm.method === method.name
							);
							if (!permission) return;

							const { resources } = permission;
							if (
								resources?.includes('*') ||
								!method.resourceGroup
							) {
								draft.selectAllResources = true;
								draft.editResourceIds = null;
							} else {
								draft.editResourceIds = resources;
								draft.selectAllResources = null;
							}
							draft.editId = permission.uuid;

							return;
						}

						// ...otherwise clear data
						draft.editId = null;
						draft.selectAllResources = null;
						draft.editResourceIds = null;
					})
				);
			}
		},
		[addedPerms, data, setData]
	);

	const handleMethodSelect = useCallback(
		(method, group) => {
			setData(
				produce(draft => {
					draft.method = method;
					draft.group = group;
				})
			);
		},
		[setData]
	);

	// Pre-select the method being edited
	useEffect(
		() => {
			if (permToEdit && permissions) {
				const allMethods = permissions.reduce(
					(accumulator, permission) => {
						const methods = [...permission.methods];
						return [...accumulator, ...methods];
					},
					[]
				);
				const selectedMethod = allMethods.find(
					method => method.name === permToEdit.method
				);
				handleMethodSelect(selectedMethod, permToEdit.group);
				confirmMethod(selectedMethod);
				goToStep(!selectedMethod.resourceGroup ? 2 : 1);
			}
		},
		[
			confirmMethod,
			data,
			goToStep,
			handleMethodSelect,
			permissions,
			permToEdit,
			setData,
		]
	);

	const handleGoForward = useCallback(
		(method, group) => {
			const methodToUse = method || data.method;
			confirmMethod(methodToUse, group);

			// Skip resources selection if method has no resource group
			if (!!methodToUse.resourceGroup) {
				nextStep();
			} else {
				goToStep(2);
			}
		},
		[confirmMethod, data, goToStep, nextStep]
	);

	if (error) {
		return (
			<BtError
				title="Retrieval Error"
				description="An error occurred when attempting to retrieve the list of permissions."
				action={getPermissions}
				onClose={onClose}
				fullScreen={false}
			/>
		);
	}

	return (
		<BtLoading
			loading={loading || !!permToEdit}
			style={{ maxHeight: '400px', height: '100vh' }}
		>
			<DialogTitle>
				<WizardHeading
					onCancelClick={onClose}
					title={
						permToEdit
							? 'Continue or select a replacement permission'
							: 'Select a permission to add'
					}
				>
					<Input
						fullWidth
						placeholder="Filter permissions"
						autoFocus
						startAdornment={
							<FilterIcon style={{ marginRight: '0.2em' }} />
						}
						value={searchTerm}
						onChange={event => setSearchTerm(event.target.value)}
						style={{ margin: '1em 0 0.2em 0' }}
						error={filteredPermissions.length === 0}
					/>
				</WizardHeading>
			</DialogTitle>
			<DialogContent ref={contentRef}>
				{filteredPermissions.map(group => (
					<PermissionMethodGroup
						key={group.id}
						activeRef={activeRef}
						group={group}
						onMethodDoubleClick={(method, group) => {
							blockAutoScroll.current = true;
							handleMethodSelect(method, group);
							handleGoForward(method, group);
						}}
						onMethodSelect={(method, group) => {
							blockAutoScroll.current = true;
							handleMethodSelect(method, group);
						}}
						selectedMethod={data?.method}
					/>
				))}
				{filteredPermissions.length === 0 && (
					<div
						style={{
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							opacity: 0.8,
						}}
					>
						<Typography>
							{'No permissions match your filter'}
						</Typography>
					</div>
				)}
			</DialogContent>
			<DialogActions>
				<WizardButtons
					onCancelClick={onClose}
					canGoForward={!!data.method}
					isFirstStep={isFirstStep}
					isLastStep={isLastStep}
					onNextClick={() => handleGoForward()}
				/>
			</DialogActions>
		</BtLoading>
	);
}
