import {
	// insight routes
	insightCollectionUpdateDetails,
	insightCollectionChangeStatus,
	insightCollectionDraftCommit,
	insightCollectionDraftUpdate,
	insightCollectionGetDraft,
	insightCollectionHistoryGet,
	insightCollectionPublish,
	insightCollectionUpdateDraftLock,
	insightCollectionGetVersion,
	// workflow routes
	workflowTemplateUpdateDetails,
	workflowTemplateChangeStatus,
	workflowTemplateDraftCommit,
	workflowTemplateDraftUpdate,
	workflowTemplateGetDraft,
	workflowTemplateHistoryGet,
	workflowTemplatePublish,
	workflowTemplateUpdateDraftLock,
	workflowTemplateGetVersion,

	// workflowTemplateChangeStatus,
	// workflowTemplateDraftPublish,
	// workflowTemplateHistoryGet,
	// workflowTemplateUpdateDraft,
	// workflowTemplateUpdateDraftLock,
} from '../../../../../API';

import {
	COMMIT_DRAFT_API,
	GET_DRAFT_API,
	GET_VERSION_API,
	LOCK_STATUS_API,
	PUBLISH_RECORD_API,
	RECORD_STATUS_API,
	UPDATE_DRAFT_API,
	UPDATE_RECORD_API,
} from '../constants';

const endpoints = {
	[COMMIT_DRAFT_API]: {
		// automation: 'auto',
		insightCollection: insightCollectionDraftCommit,
		workflowTemplate: workflowTemplateDraftCommit,
	},
	[PUBLISH_RECORD_API]: {
		// automation: 'auto',
		insightCollection: insightCollectionPublish,
		workflowTemplate: workflowTemplatePublish,
	},
	[UPDATE_RECORD_API]: {
		// automation: 'auto',
		insightCollection: insightCollectionUpdateDetails,
		workflowTemplate: workflowTemplateUpdateDetails,
	},
	[UPDATE_DRAFT_API]: {
		// automation: 'auto',
		insightCollection: insightCollectionDraftUpdate,
		workflowTemplate: workflowTemplateDraftUpdate,
	},
	[GET_DRAFT_API]: {
		// automation: 'auto',
		insightCollection: insightCollectionGetDraft,
		workflowTemplate: workflowTemplateGetDraft,
	},
	[GET_VERSION_API]: {
		// automation: 'auto',
		insightCollection: insightCollectionGetVersion,
		workflowTemplate: workflowTemplateGetVersion,
	},
	// below to be checked
	getHistory: {
		// automation: 'auto',
		insightCollection: insightCollectionHistoryGet,
		workflowTemplate: workflowTemplateHistoryGet,
	},
	// publishRecord: {
	// 	// automation: 'auto',
	// 	insightCollection: insightCollectionDraftPublish,
	// 	// workflowTemplate: workflowTemplateDraftPublish,
	// },
	[RECORD_STATUS_API]: {
		// automation: 'auto',
		insightCollection: insightCollectionChangeStatus,
		workflowTemplate: workflowTemplateChangeStatus,
	},
	[LOCK_STATUS_API]: {
		// automation: automationUp,
		insightCollection: insightCollectionUpdateDraftLock,
		workflowTemplate: workflowTemplateUpdateDraftLock,
	},
};

/**
 *
 * @param {*} module options: insightCollection
 * @param {string} endPoint
 * @returns
 */
const lookupEndpoint = (module, endpoint) => {
	// lookup the result
	const result = endpoints[endpoint][module] || undefined;

	// provide console warning if no record
	!result &&
		console.warn(
			`API lookup error. No entry in  table for '${module}' '${endpoint}'`
		);
	return result;
};

export { lookupEndpoint };
