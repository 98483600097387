import { useMemo } from 'react';

import { dateFormatSanitiser } from '../utils/timeUtils';
import { useAppContext } from '../context/ContextManager';

export default function useDateFormat() {
	const { appOrg } = useAppContext();
	const rawDateFormat = appOrg?.regionalSettings?.dateFormat;

	return useMemo(() => dateFormatSanitiser(rawDateFormat), [rawDateFormat]);
}
