import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { IconButton, Tooltip } from '@mui/material';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export default function BtOpenInNew({
	buttonProps,
	name,
	RenderButton,
	size,
	stopPropagation,
	url,
}) {
	const openInNewTab = useCallback(
		() => {
			const fullUrl = `${window.location.protocol}//${
				window.location.host
			}/${url}`;

			const newWindow = window.open(
				fullUrl,
				'_blank',
				'noopener,noreferrer'
			);

			if (newWindow) newWindow.opener = null;
		},
		[url]
	);

	const handleClick = useCallback(
		event => {
			if (stopPropagation) {
				event.stopPropagation();
			}

			openInNewTab();
		},
		[openInNewTab, stopPropagation]
	);

	if (RenderButton) {
		return <RenderButton onClick={handleClick} />;
	}

	return (
		<Tooltip title={name || 'Open in new tab'}>
			<IconButton
				onClick={handleClick}
				size={size || 'small'}
				color="primary"
				{...buttonProps}
			>
				<OpenInNewIcon />
			</IconButton>
		</Tooltip>
	);
}

BtOpenInNew.propTypes = {
	buttonProps: PropTypes.object,
	name: PropTypes.string,
	RenderButton: PropTypes.func,
	size: PropTypes.string,
	stopPropagation: PropTypes.bool,
	url: PropTypes.string.isRequired,
};
