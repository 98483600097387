const demoStatusCardData = [
	{
		name: '15min_periodic',
		start_time: null,
		E1: 33755,
		E2: 4152,
		E3: 18323,
		ETotal: 56230,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 25689,
		E2: 99047,
		E3: 72683,
		ETotal: 197419,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 33065,
		E2: 79468,
		E3: 1509,
		ETotal: 114042,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 85309,
		E2: 69819,
		E3: 19774,
		ETotal: 174902,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 23535,
		E2: 8148,
		E3: 76117,
		ETotal: 107800,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 34465,
		E2: 12706,
		E3: 78244,
		ETotal: 125415,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 48657,
		E2: 71503,
		E3: 53198,
		ETotal: 173358,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 351,
		E2: 64738,
		E3: 39538,
		ETotal: 104627,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 16192,
		E2: 31919,
		E3: 57242,
		ETotal: 105353,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 28684,
		E2: 30794,
		E3: 65262,
		ETotal: 124740,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 25121,
		E2: 53891,
		E3: 7215,
		ETotal: 86227,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 3980,
		E2: 3384,
		E3: 10916,
		ETotal: 18280,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 33892,
		E2: 44656,
		E3: 88313,
		ETotal: 166861,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 61224,
		E2: 67659,
		E3: 8220,
		ETotal: 137103,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 66182,
		E2: 41168,
		E3: 46544,
		ETotal: 153894,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 25179,
		E2: 78617,
		E3: 45306,
		ETotal: 149102,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 23320,
		E2: 61644,
		E3: 84839,
		ETotal: 169803,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 69143,
		E2: 28781,
		E3: 27131,
		ETotal: 125055,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 34489,
		E2: 32814,
		E3: 75558,
		ETotal: 142861,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 82964,
		E2: 21573,
		E3: 3592,
		ETotal: 108129,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 46702,
		E2: 30984,
		E3: 72361,
		ETotal: 150047,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 56731,
		E2: 785,
		E3: 58682,
		ETotal: 116198,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 60623,
		E2: 17600,
		E3: 52962,
		ETotal: 131185,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 58297,
		E2: 53509,
		E3: 32842,
		ETotal: 144648,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 563,
		E2: 74814,
		E3: 82343,
		ETotal: 157720,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 70903,
		E2: 32261,
		E3: 10820,
		ETotal: 113984,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 14231,
		E2: 45296,
		E3: 32314,
		ETotal: 91841,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 87494,
		E2: 35258,
		E3: 82876,
		ETotal: 205628,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 90443,
		E2: 66228,
		E3: 10432,
		ETotal: 167103,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 20751,
		E2: 15176,
		E3: 17047,
		ETotal: 52974,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 74267,
		E2: 97980,
		E3: 49228,
		ETotal: 221475,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 47597,
		E2: 95316,
		E3: 82751,
		ETotal: 225664,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 64933,
		E2: 94845,
		E3: 84891,
		ETotal: 244669,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 75966,
		E2: 20991,
		E3: 75077,
		ETotal: 172034,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 87666,
		E2: 88195,
		E3: 94597,
		ETotal: 270458,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 8422,
		E2: 54340,
		E3: 21131,
		ETotal: 83893,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 39233,
		E2: 70899,
		E3: 57443,
		ETotal: 167575,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 17191,
		E2: 16077,
		E3: 64606,
		ETotal: 97874,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 62447,
		E2: 73807,
		E3: 99793,
		ETotal: 236047,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 14263,
		E2: 9001,
		E3: 128,
		ETotal: 23392,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 99269,
		E2: 54054,
		E3: 10823,
		ETotal: 164146,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 61911,
		E2: 2381,
		E3: 61338,
		ETotal: 125630,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 91301,
		E2: 43946,
		E3: 76739,
		ETotal: 211986,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 24108,
		E2: 45361,
		E3: 1771,
		ETotal: 71240,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 98117,
		E2: 67286,
		E3: 22970,
		ETotal: 188373,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 67037,
		E2: 34771,
		E3: 55734,
		ETotal: 157542,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 35861,
		E2: 10253,
		E3: 99497,
		ETotal: 145611,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 29493,
		E2: 9159,
		E3: 62435,
		ETotal: 101087,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 68858,
		E2: 64554,
		E3: 46334,
		ETotal: 179746,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 72742,
		E2: 11217,
		E3: 74708,
		ETotal: 158667,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 82635,
		E2: 98709,
		E3: 65074,
		ETotal: 246418,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 57544,
		E2: 90009,
		E3: 41302,
		ETotal: 188855,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 62149,
		E2: 71361,
		E3: 29602,
		ETotal: 163112,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 86115,
		E2: 74315,
		E3: 34737,
		ETotal: 195167,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 41582,
		E2: 73320,
		E3: 9353,
		ETotal: 124255,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 31100,
		E2: 28500,
		E3: 33817,
		ETotal: 93417,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 61730,
		E2: 31539,
		E3: 8881,
		ETotal: 102150,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 72369,
		E2: 93145,
		E3: 41051,
		ETotal: 206565,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 50818,
		E2: 48332,
		E3: 80282,
		ETotal: 179432,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 40976,
		E2: 55350,
		E3: 2378,
		ETotal: 98704,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 85733,
		E2: 20993,
		E3: 80845,
		ETotal: 187571,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 96944,
		E2: 55414,
		E3: 92766,
		ETotal: 245124,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 89708,
		E2: 85172,
		E3: 68829,
		ETotal: 243709,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 60878,
		E2: 73481,
		E3: 38806,
		ETotal: 173165,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 4371,
		E2: 80290,
		E3: 75755,
		ETotal: 160416,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 58745,
		E2: 26503,
		E3: 44947,
		ETotal: 130195,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 42255,
		E2: 83574,
		E3: 23682,
		ETotal: 149511,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 39592,
		E2: 78583,
		E3: 31536,
		ETotal: 149711,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 90190,
		E2: 16768,
		E3: 23802,
		ETotal: 130760,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 23414,
		E2: 53361,
		E3: 920,
		ETotal: 77695,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 60300,
		E2: 33814,
		E3: 19621,
		ETotal: 113735,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 50253,
		E2: 35179,
		E3: 97507,
		ETotal: 182939,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 30483,
		E2: 46713,
		E3: 36729,
		ETotal: 113925,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 10018,
		E2: 36531,
		E3: 76685,
		ETotal: 123234,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 67671,
		E2: 32396,
		E3: 94994,
		ETotal: 195061,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 93593,
		E2: 40316,
		E3: 76259,
		ETotal: 210168,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 90556,
		E2: 363,
		E3: 75688,
		ETotal: 166607,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 33633,
		E2: 34161,
		E3: 80648,
		ETotal: 148442,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 80021,
		E2: 77221,
		E3: 91724,
		ETotal: 248966,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 53493,
		E2: 33296,
		E3: 20745,
		ETotal: 107534,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 11446,
		E2: 85591,
		E3: 72963,
		ETotal: 170000,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 35369,
		E2: 30373,
		E3: 2457,
		ETotal: 68199,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 18918,
		E2: 51520,
		E3: 31447,
		ETotal: 101885,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 71790,
		E2: 69658,
		E3: 37599,
		ETotal: 179047,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 63762,
		E2: 37079,
		E3: 40753,
		ETotal: 141594,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 33389,
		E2: 91835,
		E3: 38471,
		ETotal: 163695,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 77623,
		E2: 83346,
		E3: 67119,
		ETotal: 228088,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 5735,
		E2: 86219,
		E3: 56576,
		ETotal: 148530,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 57771,
		E2: 88671,
		E3: 71914,
		ETotal: 218356,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 86793,
		E2: 46759,
		E3: 59378,
		ETotal: 192930,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 41214,
		E2: 37269,
		E3: 37427,
		ETotal: 115910,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 63770,
		E2: 53063,
		E3: 54596,
		ETotal: 171429,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 37865,
		E2: 39700,
		E3: 56782,
		ETotal: 134347,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 93972,
		E2: 91917,
		E3: 40813,
		ETotal: 226702,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 64680,
		E2: 13046,
		E3: 36316,
		ETotal: 114042,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
	{
		name: '15min_periodic',
		start_time: null,
		E1: 15256,
		E2: 91605,
		E3: 54483,
		ETotal: 161344,
		uid: '0123b880f32df086ee',
		timestamp: null,
	},
];

export { demoStatusCardData };
