const { OPERATION_MODE } = require('../../../expression');
const {
	evaluateExpressionSchema,
	setOperatorSchemaFn,
} = require('../../expression');
const { newDefaultSchema } = require('../../utils');

// schema result is the input args
const identityAccumulators = [
	'$max',
	'$min',
	'$first',
	'$last',
	'$mergeObjects',
];

identityAccumulators.forEach((operator) => {
	setOperatorSchemaFn(
		operator,
		(context, args, options) => {
			return evaluateExpressionSchema(
				{ ...context, operationMode: OPERATION_MODE.AGGREGATE },
				args
			);
		},
		[OPERATION_MODE.ACCUMULATE]
	);
});

// schema result is always a number
const numericAccumulators = ['$avg', '$sum', '$count'];

numericAccumulators.forEach((operator) => {
	setOperatorSchemaFn(
		operator,
		(context, args, options) => {
			return newDefaultSchema('number');
		},
		[OPERATION_MODE.ACCUMULATE]
	);
});

// output dependent on input and some process
require('./push.js');
