// ===========================================================================
module.exports.shallowCopy = shallowCopy;
function shallowCopy(item) {
	if (typeof item === 'object' && item !== null) {
		if (item instanceof Date) {
			return new Date(item);
		}
		if (Array.isArray(item)) {
			return [...item];
		}

		return { ...item };
	}

	return item;
}

module.exports.deepCopy = deepCopy;
function deepCopy(item) {
	if (typeof item === 'object' && item !== null) {
		if (item instanceof Date) {
			return new Date(item);
		}
		if (item instanceof RegExp) {
			return new RegExp(item);
		}
		if (Array.isArray(item)) {
			return item.map(deepCopy);
		}

		const newItem = {};
		for (const key in item) {
			if (Object.hasOwnProperty.call(item, key)) {
				newItem[key] = deepCopy(item[key]);
			}
		}
		return newItem;
	}
	return item;
}

// ===========================================================================
module.exports.isDocument = isDocument;
function isDocument(thing) {
	if (
		thing !== null && // null is of type object
		typeof thing === 'object' &&
		!(thing instanceof Date) && // ignore date objects
		!(thing instanceof RegExp) && // ignore regex objects
		!Array.isArray(thing) // ignore arrays
	) {
		return true;
	}

	return false;
}

// ===========================================================================
module.exports.isEqual = isEqual;
function isEqual(a, b) {
	// if we've been passed the same implicit value or the exact same object
	// pointers
	if (a === b) {
		return true;
	}

	if (a && b && typeof a === 'object' && typeof b === 'object') {
		if (a instanceof Date && b instanceof Date) {
			return a.getTime() === b.getTime();
		}
		if (a instanceof Date || b instanceof Date) {
			return false;
		}
		if (a instanceof RegExp && b instanceof RegExp) {
			return RegExp.toString() === RegExp.toString();
		}
		if (a instanceof RegExp || b instanceof RegExp) {
			return false;
		}
		if (Array.isArray(a) && Array.isArray(b)) {
			if (a.length !== b.length) {
				return false;
			}

			for (let index = 0; index < a.length; index++) {
				if (!isEqual(a[index], b[index])) {
					return false;
				}
			}
			return true;
		}

		const k1 = Object.keys(a);
		const k2 = Object.keys(b);

		if (!isEqual(k1, k2)) {
			return false;
		}

		// both are objects both have keys that match
		for (let k = 0; k < k1.length; k++) {
			if (!isEqual(a[k1[k]], b[k1[k]])) {
				return false;
			}
		}
		// we got through all the checks
		return true;
	}

	// symbols and implicit types
	return (a ?? null) === (b ?? null);
}

// ===========================================================================
module.exports.isGeoDocument = isGeoDocument;
function isGeoDocument(thing) {
	// Ensure the type prop exists
	if (!thing?.type) {
		return false;
	}

	// The only supported type is 'Point'
	if (thing.type !== 'Point') {
		return false;
	}

	// Ensure the coordinates prop is set
	if (!thing.coordinates) {
		return false;
	}

	// Coordinates must an array
	if (!Array.isArray(thing.coordinates)) {
		return false;
	}

	// Coordinates length must be 2 for longitude then latitude
	if (thing.coordinates.length !== 2) {
		return false;
	}

	// Longitude must be a number
	if (typeof thing.coordinates[0] !== 'number') {
		return false;
	}

	// Valid longitude values at -180 to 180 inclusive
	if (thing.coordinates[0] < -180 || thing.coordinates[0] > 180) {
		return false;
	}

	// Latitude must be a number
	if (typeof thing.coordinates[1] !== 'number') {
		return false;
	}

	// Valid latitude values at -90 to 90 inclusive
	if (thing.coordinates[1] < -90 || thing.coordinates[1] > 90) {
		return false;
	}
	return true;
}

// ===========================================================================
module.exports.calculateGeoDistance = calculateGeoDistance;
function calculateGeoDistance(coord1, coord2) {
	const { PI, cos, sin, asin, sqrt } = Math;

	// equatorial mean radius of Earth (in km)
	const radius = 6378.137;

	const toRad = (x) => (x * PI) / 180.0;
	const hav = (x) => sin(x / 2) ** 2;

	const coord1Long = toRad(coord1[0]);
	const coord2Long = toRad(coord2[0]);

	const coord1Lat = toRad(coord1[1]);
	const coord2Lat = toRad(coord2[1]);

	const havTheta =
		hav(coord2Long - coord1Long) +
		cos(coord1Long) * cos(coord2Long) * hav(coord2Lat - coord1Lat);

	return 2 * radius * asin(sqrt(havTheta));
}

// =============================================================================
module.exports.dotRemove = dotRemove;
function dotRemove(input, path) {
	const dotIndex = path.indexOf('.');
	let p0 = dotIndex > -1 ? path.substring(0, dotIndex) : path;
	let pN = dotIndex > -1 ? path.substring(dotIndex + 1) : path;

	if (dotIndex > -1) {
		if (Array.isArray(input[p0])) {
			input[p0] = input[p0].map((element) => {
				// TODO: catch is value is not object
				return dotRemove(element, pN);
			});
			return input;
		} else {
			input[p0] = dotRemove(input[p0], pN);
			return input;
		}
	} else {
		delete input[path];
		return input;
	}
}

// =============================================================================
module.exports.dotCopyFromObject = dotCopyFromObject;
function dotCopyFromObject(current, input, path) {
	const dotIndex = path.indexOf('.');
	let p0 = dotIndex > -1 ? path.substring(0, dotIndex) : path;
	let pN = dotIndex > -1 ? path.substring(dotIndex + 1) : path;

	if (dotIndex > -1) {
		if (Array.isArray(input[p0])) {
			if (!current[p0]) {
				current[p0] = [];
			}

			for (const element of input[p0]) {
				if (typeof element === 'object') {
					current[p0].push(dotCopyFromObject({}, element, pN));
				}
			}
			return current;
		} else {
			current[p0] = dotCopyFromObject(current[p0], input[p0], pN);
			return current;
		}
	} else {
		if (input?.[path] === undefined && current === undefined) {
			return {};
		}
		if (input[path] !== undefined) {
			if (current === undefined) {
				return { [path]: input[path] };
			}
			current[path] = input[path];
		}

		return current;
		// return { ...current, [path]: input[path] };
	}
}

// =============================================================================
module.exports.dotAdd = dotAdd;
function dotAdd(current, input, path) {
	const dotIndex = path.indexOf('.');
	let p0 = dotIndex > -1 ? path.substring(0, dotIndex) : path;
	let pN = dotIndex > -1 ? path.substring(dotIndex + 1) : path;

	if (dotIndex > -1) {
		if (p0 === '$') {
			let pnDotIndex = pN.indexOf('.');
			let pN0 = pN.slice(0, pnDotIndex);
			let pNN = pN.slice(pnDotIndex + 1);
			if (pnDotIndex < 0) {
				if (Array.isArray(current)) {
					return [...current, input];
				}
				return [input];
			}

			if (Array.isArray(current)) {
				const newArr = [...current];
				newArr.splice(pN0, 1, dotAdd(current[pN0], input, pNN));
			}

			const newArr = [dotAdd(undefined, input, pNN)];
			return newArr;
		}

		if (isDocument(current)) {
			return { ...current, [p0]: dotAdd(current[p0], input, pN) };
		}

		if (Array.isArray(current)) {
			const elements = [];
			for (let idx = 0; idx < current.length; idx++) {
				const element = current[idx];
				const newElement = dotAdd(element, input, path);
				elements.push(newElement);
			}
			return elements;
		}

		return { [p0]: dotAdd(undefined, input, pN) };
	}

	if (isDocument(current)) {
		return { ...(current || {}), [p0]: input };
	}

	return { [p0]: input };
}

// =============================================================================
module.exports.dotGet = dotGet;
function dotGet(input, path, options) {
	const dotIndex = path.indexOf('.');
	let p0 = dotIndex > -1 ? path.substring(0, dotIndex) : path;
	let pN = dotIndex > -1 ? path.substring(dotIndex + 1) : path;

	if (dotIndex > -1) {
		if (isDocument(input)) {
			return dotGet(input[p0], pN);
		}

		if (Array.isArray(input[p0])) {
			if (options.descendIntoArrays) {
				return input[p0].map((element) => {
					// TODO: catch is value is not object
					return dotGet(element, pN);
				});
			}
			throw 'Unexpected value type - array';
		}
	}

	return input[p0];
}

// =============================================================================
module.exports.dotPathToString = dotPathToString;
function dotPathToString(path) {
	let out = '';

	path.split('.').forEach((p) => {
		if (out.length === 0) {
			out += p;
			return;
		}

		out += p.slice(0, 1).toUpperCase() + p.slice(1);
	});

	return out;
}
