import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';

import { useDebounce } from '../useDebounce';
import {
	useQueryBuilderStageContext,
	useQueryBuilderDispatchContext,
} from '../context';
import { stagesAreEquivalent } from '../processing';
import { BtQueryBuilderStageComponent, definedStages } from './stages';
import BtQueryBuilderStageTypeSelect from './BtQueryBuilderStageTypeSelect';
import BtQueryBuilderStageOutputPreview from './BtQueryBuilderStageOutputRenderedPreview';

export default function BtQueryBuilderStage(props) {
	const {
		getStageResultData,
		disabled: disableInteraction,
		disableCollection,
		readOnly,
		allowRawInput,
		allowedStages,
		collectionRenderMode,
	} = props;

	const {
		id: stageId,
		query,
		queryStr,
		type,
		collectionIsDirty,
		collectionLoading,
		collectionError,
		configSchemaError,
		result,
		error,
		input,
		inputLoading,
		inputError,
		inputReady,
		// resultLoading,
		// resultReady,
	} = useQueryBuilderStageContext();

	const dispatch = useQueryBuilderDispatchContext();

	const stageComponentExists = definedStages.find(s => s.key === type);

	const onFormChange = useCallback(
		newQuery => {
			if (!stagesAreEquivalent(newQuery, query)) {
				dispatch({
					id: stageId,
					action: 'update',
					query: newQuery,
				});
			}
		},
		[stageId, dispatch, query]
	);

	const [rawInputMode, setRawInputMode] = useState(false);
	const [rawInput, setRawInput] = useState(queryStr);

	const { debounce: debounceRawInput, waiting } = useDebounce(1000);

	const handleRawInputChange = useCallback(
		event => {
			// rawInputDebounce.current = true;
			setRawInput(event.target.value);

			debounceRawInput(() => {
				// setRawInputWaiting(false);
				dispatch({
					id: stageId,
					action: 'updateStr',
					queryStr: event.target.value,
				});
			});
		},
		[dispatch, debounceRawInput, stageId]
	);

	useEffect(
		() => {
			setRawInput(queryStr);
		},
		[queryStr]
	);

	const { debounce: debounceQuery } = useDebounce(1000);

	useEffect(
		() => {
			let cancel = null;
			if (
				collectionIsDirty &&
				!collectionLoading &&
				result?.fullQuery &&
				inputReady
			) {
				debounceQuery(() => {
					cancel = getStageResultData(stageId, result.fullQuery);
				});
			}

			if (cancel) {
				return cancel;
			}
		},
		[
			stageId,
			collectionIsDirty,
			collectionLoading,
			result?.fullQuery,
			inputReady,
			debounceQuery,
			getStageResultData,
		]
	);

	return (
		<>
			<Box
				sx={{
					height: '70vh',
					display: 'flex',
					justifyItems: 'space-between',
					flexWrap: 'wrap',
				}}
			>
				<Box
					sx={{
						width: {
							xs: '100%',
							md: '25%',
						},
						height: {
							xs: '300px',
							md: '100%',
						},
					}}
				>
					<BtQueryBuilderStageOutputPreview
						title="Input"
						configSchemaStr={input?.configSchemaStr}
						configSchema={input?.configSchema}
						disableCollection={!!disableCollection}
						collection={input?.collection}
						collectionLoading={inputLoading}
						configSchemaLoading={inputLoading}
						configSchemaError={(inputError || '') + ''}
						collectionError={(inputError || '') + ''}
						collectionRenderMode={collectionRenderMode}
					/>
				</Box>
				<Box
					sx={{
						width: {
							xs: '100%',
							md: '50%',
						},
						height: {
							xs: 'auto',
							md: '100%',
						},
						overflow: {
							xs: 'inherit',
							md: 'auto',
						},
						position: 'relative',
						px: {
							xs: 0,
							md: 2,
						},
					}}
				>
					<Box
						sx={{
							position: 'relative',
							height: '100%',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Box>
							<Typography variant="subtitle1">
								Stage Query
							</Typography>
						</Box>
						<Divider
							sx={{
								marginTop: { xs: 2, md: 6.5 },
								marginBottom: { xs: 1, md: 2 },
							}}
						/>
						<Box
							sx={{
								flex: 1,
								overflow: 'auto',
								px: 2,
							}}
						>
							{/* <pre>
								{JSON.stringify(
									{
										stageId,
										query,
										inputLoading,
										inputReady,
										resultLoading,
										resultReady,
									},
									null,
									2
								)}
							</pre> */}
							{!type ? (
								<BtQueryBuilderStageTypeSelect
									stageId={stageId}
									allowedStages={allowedStages}
								/>
							) : !inputReady && inputLoading ? (
								<>
									<LinearProgress />
								</>
							) : inputReady ? (
								<>
									{allowRawInput &&
										stageComponentExists && (
											<Box>
												<FormControlLabel
													control={
														<Switch
															checked={
																rawInputMode
															}
															onChange={event =>
																setRawInputMode(
																	event.target
																		.checked
																)
															}
														/>
													}
													label="Query Text"
												/>
											</Box>
										)}

									{((rawInputMode && allowRawInput) ||
										!stageComponentExists) &&
										(readOnly ? (
											<>
												<Typography>Stage</Typography>
												<pre
													style={{
														overflowX: 'hidden',
														whiteSpace: 'pre-wrap',
														wordBreak: 'break-word',
													}}
												>
													{result?.queryStr || '{}'}
												</pre>
											</>
										) : (
											<TextField
												fullWidth
												key={`${stageId}.rawInput`}
												multiline
												name={`${stageId}.rawInput`}
												label="Stage"
												onChange={handleRawInputChange}
												disabled={disableInteraction}
												size="small"
												type="text"
												variant="standard"
												value={rawInput}
												error={!!error}
												helperText={
													error ? error + '' : ''
												}
												InputProps={{
													endAdornment: waiting ? (
														<InputAdornment position="end">
															<Box
																style={{
																	display:
																		'flex',
																	marginRight:
																		'0.5em',
																	pointerEvents:
																		'none',
																}}
															>
																<CircularProgress
																	size={20}
																/>
															</Box>
														</InputAdornment>
													) : null,
												}}
											/>
										))}

									{stageComponentExists &&
										(!rawInputMode || !allowRawInput) && (
											<BtQueryBuilderStageComponent
												key={stageId}
												disabled={
													disableInteraction ||
													readOnly
												}
												readOnly={readOnly}
												allowedStages={allowedStages}
												onChange={onFormChange}
											/>
										)}
								</>
							) : (
								<>Oops something went wrong...</>
							)}
						</Box>
					</Box>
				</Box>
				<Box
					sx={{
						width: {
							xs: '100%',
							md: '25%',
						},
						height: {
							xs: '300px',
							md: '100%',
						},
					}}
				>
					<BtQueryBuilderStageOutputPreview
						title="Output"
						configSchemaStr={!type ? null : result?.configSchemaStr}
						configSchema={!type ? null : result?.configSchema}
						configSchemaError={!type ? null : configSchemaError}
						disableCollection={!!disableCollection}
						collectionStr={!type ? null : result?.collectionStr}
						collection={!type ? null : result?.collection}
						collectionLoading={!type ? null : collectionLoading}
						collectionError={!type ? null : collectionError}
						collectionRenderMode={collectionRenderMode}
					/>
				</Box>
			</Box>
		</>
	);
}

BtQueryBuilderStage.propTypes = {
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	allowRawInput: PropTypes.bool,
	allowedStages: PropTypes.arrayOf(PropTypes.string),
	getStageResultData: PropTypes.func,
	disableCollection: PropTypes.bool,
	collectionRenderMode:
		BtQueryBuilderStageOutputPreview.propTypes.collectionRenderMode,
};
