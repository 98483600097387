import { useEffect } from 'react';
import PropTypes from 'prop-types';

export default function useKeyboardUndoRedo({ redo, undo }) {
	useEffect(
		() => {
			const handleKeyPress = ({ ctrlKey, key, metaKey, shiftKey }) => {
				if ((metaKey || ctrlKey) && key === 'z') {
					if (shiftKey) {
						redo?.();

						return;
					}

					undo?.();
				}

				if (ctrlKey && key === 'y') {
					redo?.();
				}
			};

			window.addEventListener('keydown', handleKeyPress);

			return () => {
				window.removeEventListener('keydown', handleKeyPress);
			};
		},
		[redo, undo]
	);
}

useKeyboardUndoRedo.propTypes = {
	redo: PropTypes.func,
	undo: PropTypes.func,
};
