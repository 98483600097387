const { OPERATION_MODE } = require('../../expression');
const {
	setOperatorSchemaFn,
	evaluateExpressionSchema,
} = require('../expression');
const { getType, newDefaultSchema } = require('../utils');

const operatorKey = '$ifNull';

setOperatorSchemaFn(
	operatorKey,
	(context, args, options) => {
		const types = args.map((arg) => evaluateExpressionSchema(context, arg));

		// assuming for simplicity that we're getting back the same type we're
		// putting in if its null

		return types[0];
	},
	[OPERATION_MODE.AGGREGATE]
);
