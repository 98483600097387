import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { Button, Skeleton } from '@mui/material';
import { Add } from '@mui/icons-material';
import BtSelectionTable from '../../../../../components/generic/BtSelectionTable';
import { BtIconButtonGroup } from '../../../../../components/generic/BtIconButtonGroup';
import { mdiContentCopy, mdiDelete } from '@mdi/js';
import { TabPanelTitle } from '../../../../../components/generic/recordManagement/components/generic/TabLayout';
import DataTransformDialog from './DataTransformDialog';
import { findIndex } from 'lodash';
import { useUpdateCollectionDraft } from './utils/useUpdateCollectionDraft';
import v4 from 'uuid/v4';
import { evaluateCopyName } from './utils/utils';

const TableButtons = ({
	disabled,
	index,
	handleRemoveTransform,
	handleCopyTransform,
}) => {
	const attributes = useMemo(
		() => [
			{
				onClick: event => {
					event.stopPropagation();
					handleRemoveTransform(index);
				},
				disabled: disabled,
				icon: mdiDelete,
				tooltip: 'Delete',
				size: 'small',
			},
			{
				onClick: event => {
					event.stopPropagation();
					handleCopyTransform(index);
				},
				disabled: disabled,
				icon: mdiContentCopy,
				tooltip: 'Copy',
				size: 'small',
			},
		],
		[disabled, handleCopyTransform, handleRemoveTransform, index]
	);

	return <BtIconButtonGroup buttonGroupAttributes={attributes} />;
};

const DataTransformsTable = memo(function DataTransformsTable({
	loading,
	dataSource,
	dataSources,
	disabled,
	handleSourceUpdate,
}) {
	const [transformConfigDialogOpen, setTransformConfigDialogOpen] = useState(
		false
	);
	const [selectedIndex, setSelectedIndex] = useState(null);

	const updateCollection = useUpdateCollectionDraft();

	const transforms = useMemo(() => dataSource?.transforms ?? [], [
		dataSource?.transforms,
	]);

	const tableColumns = [
		{ field: 'name', text: 'Name' },
		{ field: 'description', text: 'Description', minBreakpoint: 'sm' },
		{ field: 'actions', text: '' },
	];

	const handleTableClick = useCallback(index => {
		setSelectedIndex(index);
		setTransformConfigDialogOpen(true);
	}, []);

	const handleDataSourcesUpdate = useCallback(
		transformsUpdate => {
			// add the updated transforms to the data source
			const dataSourceUpdate = { ...dataSource };
			dataSourceUpdate.transforms = transformsUpdate;

			// add the updated data source to the data sources
			const dataSourceIndex = findIndex(dataSources, {
				uuid: dataSource.uuid,
			});
			const dataSourcesUpdate = [...dataSources];
			dataSourcesUpdate.splice(dataSourceIndex, 1, dataSourceUpdate);

			const callbacks = {
				success: handleSourceUpdate,
			};

			updateCollection({
				update: { datasources: dataSourcesUpdate },
				callbacks,
			});
		},
		[dataSource, dataSources, handleSourceUpdate, updateCollection]
	);

	const onTransformEdit = useCallback(
		transformUpdate => {
			// update the data source transforms
			const transformIndex = findIndex(transforms, {
				uuid: transformUpdate.uuid,
			});
			const transformsUpdate = [...transforms];
			transformsUpdate.splice(transformIndex, 1, transformUpdate);
			handleDataSourcesUpdate(transformsUpdate);
		},
		[transforms, handleDataSourcesUpdate]
	);

	const handleRemoveTransform = useCallback(
		index => {
			const transformsUpdate = [...transforms];
			transformsUpdate.splice(index, 1);
			handleDataSourcesUpdate(transformsUpdate);
		},
		[transforms, handleDataSourcesUpdate]
	);

	const handleCopyTransform = useCallback(
		index => {
			const newTransform = { ...transforms[index] };
			newTransform.name = evaluateCopyName(transforms, index);
			newTransform.uuid = v4();
			const transformsUpdate = [...transforms, newTransform];
			handleDataSourcesUpdate(transformsUpdate);
		},
		[handleDataSourcesUpdate, transforms]
	);

	const tableData = useMemo(
		() => {
			return transforms.map((transform, index) => {
				return {
					id: index,
					name: transform.name,
					description: transform.description,
					actions: (
						<TableButtons
							handleCopyTransform={handleCopyTransform}
							handleRemoveTransform={handleRemoveTransform}
							index={index}
							disabled={disabled}
						/>
					),
				};
			});
		},
		[transforms, handleCopyTransform, handleRemoveTransform, disabled]
	);

	const selectedTransform = useMemo(
		() => {
			if (selectedIndex !== null) {
				return transforms[selectedIndex];
			} else {
				return {
					uuid: v4(),
					name: '',
					description: '',
					pipeline: '',
				};
			}
		},
		[selectedIndex, transforms]
	);

	if (loading) {
		return (
			<Skeleton animation="wave" style={{ width: '100%', height: 45 }} />
		);
	}

	return (
		<>
			<TabPanelTitle
				title="Data Transforms"
				ButtonComponent={
					<Button
						variant="contained"
						onClick={() => {
							setSelectedIndex(null);
							setTransformConfigDialogOpen(true);
						}}
						startIcon={<Add />}
						disableElevation
						disabled={disabled}
					>
						Add Transform
					</Button>
				}
				style={{ marginTop: '1em' }}
			/>
			<BtSelectionTable
				size="small"
				subject="Transforms"
				columns={tableColumns}
				data={tableData}
				onClick={handleTableClick}
			/>
			<DataTransformDialog
				open={transformConfigDialogOpen}
				onClose={() => {
					setTransformConfigDialogOpen(false);
					setSelectedIndex(null);
				}}
				transform={selectedTransform}
				onTransformEdit={onTransformEdit}
				dataViewUuid={dataSource.dataView}
				label={
					selectedIndex === null
						? 'New Data Transform'
						: 'Edit Data Transform'
				}
				transforms={transforms}
				dataSource={dataSource}
				dataSources={dataSources}
				handleSourceUpdate={handleSourceUpdate}
			/>
		</>
	);
});

DataTransformsTable.propTypes = {
	loading: PropTypes.bool,
	disabled: PropTypes.bool,
	dataSource: PropTypes.object.isRequired,
	dataSources: PropTypes.array.isRequired,
	handleSourceUpdate: PropTypes.func.isRequired,
};

TableButtons.propTypes = {
	disabled: PropTypes.bool,
	index: PropTypes.number,
	handleRemoveTransform: PropTypes.func.isRequired,
	handleCopyTransform: PropTypes.func.isRequired,
};

export { DataTransformsTable };
