import React from 'react';
import PropTypes from 'prop-types';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { TextField, useTheme } from '@mui/material';

import { StaticDatePicker } from '@mui/x-date-pickers';
import { BtTimePicker } from './BtTimePicker';

export const BtTimeDateStaticPicker = ({ value, onChange }) => {
	const theme = useTheme();
	const handleHours = h => {
		onChange(new Date(value).setHours(h));
	};

	const handleMinutes = m => {
		onChange(new Date(value).setMinutes(m, 0, 0));
	};

	const handleDate = date => {
		console.log(date);
		onChange(date.getTime());
	};

	return (
		<div
			style={{
				display: 'flex',
				height: '320px',
				width: '100%',
				marginBottom: 10,
				backgroundColor:
					theme.palette.mode === 'dark'
						? '#212121'
						: theme.palette.background.default,
			}}
		>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<StaticDatePicker
					displayStaticWrapperAs="desktop"
					openTo="day"
					value={value}
					onChange={newValue => {
						handleDate(newValue);
					}}
					renderInput={params => <TextField {...params} />}
				/>
			</LocalizationProvider>
			<BtTimePicker
				handleHours={handleHours}
				handleMinutes={handleMinutes}
				value={new Date(value)}
			/>
		</div>
	);
};

BtTimeDateStaticPicker.propTypes = {
	onChange: PropTypes.func.isRequired,
	value: PropTypes.number,
};
