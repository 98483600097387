import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import * as yup from 'yup';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { BtFormTextField } from '../../../forms';
import BtQueryBuilderPropertyFormField from '../BtQueryBuilderPropertyField';
import { baseStagePropTypes } from './base-prop-type';
import { determineValueType, replaceDynamicTypedValue } from './utils';

const stageType = '$group';

const formSchema = yup.object().shape({
	// _id grouping
	group_bys: yup
		.array()
		.min(1)
		.of(
			yup.object().shape({
				// path for grouping field
				path: yup.string().test({
					name: 'is-required',
					message:
						'Field path is required when more than one group by is set',
					test: (value, context) => {
						// required if more than one group by
						const {
							value: { group_bys },
						} = context.from[1];

						if (group_bys.length > 1) {
							return !!value;
						}

						return true;
					},
				}),
				// field value
				field_type: yup.string().required(),
				field: yup.mixed(),
			})
		),
	// accumulators
	accumulators: yup
		.array()
		.min(1)
		.of(
			yup.object().shape({
				// path for calculated field
				path: yup.string().required(),

				// field value
				field_type: yup.string().required(),
				field: yup.mixed(),
			})
		),
});

const defaultGroupBy = () => ({
	path: '',
	field: null,
	field_type: 'field-path',
});

const defaultAccumulator = () => ({
	path: 'newField',

	// accumulator fields
	field: '{"$count":{}}',
	field_type: 'expression',
});

function buildFormValues(queryStage, flatConfigSchema, variables) {
	const values = {
		group_bys: [],
		accumulators: [],
	};

	const _id = queryStage?.[stageType]._id;

	if (typeof _id !== 'object' || _id === null || Array.isArray(_id)) {
		if (_id !== undefined) {
			let [_idType, _idValue] = determineValueType(
				_id,
				flatConfigSchema,
				false,
				variables
			);

			values.group_bys.push({
				path: '',
				field: _idValue,
				field_type: _idType,
			});
		}
	} else {
		Object.keys(_id).forEach(k => {
			const group_by = _id[k];

			const field = defaultGroupBy();

			const [valueType, value] = determineValueType(
				group_by,
				flatConfigSchema
			);

			return values.group_bys.push({
				...field,
				path: k,
				field: value,
				field_type: valueType,
			});
		});
	}

	Object.keys(queryStage[stageType]).forEach(k => {
		if (k === '_id') {
			// build group by

			return;
		}

		const accumulator = queryStage[stageType][k];

		const field = defaultAccumulator();

		const [valueType, value] = determineValueType(
			accumulator,
			flatConfigSchema,
			false,
			variables
		);

		return values.accumulators.push({
			...field,
			path: k,
			field: value,
			field_type: valueType,
		});
	});

	if (values.group_bys.length === 0) {
		values.group_bys.push(defaultGroupBy());
	}
	if (values.accumulators.length === 0) {
		values.accumulators.push(defaultAccumulator());
	}

	return values;
}

function buildQueryStage(fields, variables) {
	let group_bys = fields?.group_bys || [];

	const query = {};

	if (group_bys?.length === 1 && !group_bys[0].path) {
		query['_id'] = replaceDynamicTypedValue('field', group_bys[0]);
	} else if (!group_bys || group_bys.length === 0) {
		query['_id'] = null;
	} else {
		const _id = {};
		group_bys?.forEach(group_by => {
			_id[group_by.path] = replaceDynamicTypedValue('field', group_by);
		});

		query['_id'] = _id;
	}

	fields?.accumulators?.forEach(accumulator => {
		try {
			query[accumulator.path] = replaceDynamicTypedValue(
				'field',
				accumulator
			);
		} catch (e) {
			// yep
		}
	});

	return {
		[stageType]: query,
	};
}

const GroupFormContent = props => {
	const { control, getValues } = useFormContext();

	const { disabled, readOnly } = props;

	const {
		fields: groupByFields,
		append: groupByAppend,
		remove: groupByRemove,
	} = useFieldArray({
		control,
		name: 'group_bys',
	});

	const {
		fields: accumulatorFields,
		append: accumulatorAppend,
		remove: accumulatorRemove,
	} = useFieldArray({
		control,
		name: 'accumulators',
	});

	return (
		<>
			<>
				<Divider>Group By</Divider>
				{groupByFields.map((field, index) => {
					return (
						<Box key={field.id}>
							<Stack
								direction="row"
								spacing={2}
								justifyContent="space-between"
							>
								<Box width="200px">
									{readOnly ? (
										<>
											<Typography>Path</Typography>
											<Typography>
												{getValues(
													`group_bys.${index}.path`
												) || '** unset **'}
											</Typography>
										</>
									) : (
										<BtFormTextField
											key={field.id + 'path'}
											name={`group_bys.${index}.path`}
											label={'Field Path'}
											isRequired={
												groupByFields.length > 1
											}
											disabled={disabled}
										/>
									)}
								</Box>

								{!readOnly && (
									<Box>
										<IconButton
											onClick={() => groupByRemove(index)}
											disabled={disabled}
										>
											<DeleteIcon />
										</IconButton>
									</Box>
								)}
							</Stack>

							<Stack direction="row" spacing={2}>
								<Box flex={1}>
									<BtQueryBuilderPropertyFormField
										key={field.id + 'field'}
										name={`group_bys.${index}.field`}
										label="field"
										isRequired
										disabled={disabled}
										readOnly={readOnly}
										allowedFieldTypes={[
											'static',
											'field-path',
											'expression',
										]}
									/>
								</Box>
							</Stack>
							{index < groupByFields.length - 1 && (
								<Divider sx={{ margin: 2, mb: 4 }} />
							)}
						</Box>
					);
				})}

				{!readOnly && (
					<Box sx={{ textAlign: 'center', mb: 4 }}>
						<Button
							onClick={() => groupByAppend(defaultGroupBy())}
							disabled={disabled}
							startIcon={<AddIcon />}
						>
							Add Group By
						</Button>
					</Box>
				)}
			</>
			<>
				<Divider>Accumulator</Divider>
				{accumulatorFields.map((field, index) => {
					return (
						<Box key={field.id}>
							<Stack
								direction="row"
								spacing={2}
								justifyContent="space-between"
							>
								<Box width="200px">
									{readOnly ? (
										<>
											<Typography>Path</Typography>
											<Typography>
												{getValues(
													`accumulators.${index}.path`
												) || '** unset **'}
											</Typography>
										</>
									) : (
										<BtFormTextField
											key={field.id + 'path'}
											name={`accumulators.${index}.path`}
											label={'Field Path'}
											isRequired
											disabled={disabled}
										/>
									)}
								</Box>

								{!readOnly && (
									<Box>
										<IconButton
											onClick={() =>
												accumulatorRemove(index)
											}
											disabled={disabled}
										>
											<DeleteIcon />
										</IconButton>
									</Box>
								)}
							</Stack>

							<Stack direction="row" spacing={2}>
								<Box flex={1}>
									<BtQueryBuilderPropertyFormField
										key={field.id + 'field'}
										name={`accumulators.${index}.field`}
										label="field"
										isRequired
										disabled={disabled}
										readOnly={readOnly}
										allowedFieldTypes={['expression']}
										expressionMode="accumulator"
									/>
								</Box>
							</Stack>
							{index < accumulatorFields.length - 1 && (
								<Divider sx={{ margin: 2, mb: 4 }} />
							)}
						</Box>
					);
				})}

				{!readOnly && (
					<Box sx={{ textAlign: 'center' }}>
						<Button
							onClick={() =>
								accumulatorAppend(defaultAccumulator())
							}
							disabled={disabled}
							startIcon={<AddIcon />}
						>
							Add Accumulator
						</Button>
					</Box>
				)}
			</>
		</>
	);
};
export default {
	formSchema: formSchema,
	FormContent: GroupFormContent,
	buildQueryStage: buildQueryStage,
	buildFormValues: buildFormValues,
};

// BtQueryBuilderStageGroup.propTypes = baseStagePropTypes;

GroupFormContent.propTypes = baseStagePropTypes;
