import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';

// MUI COMPONENTS
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
// import Grid from '@mui/material/Grid';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// import TextField from '@mui/material/TextField';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Select from '@mui/material/Select';
// import Checkbox from '@mui/material/Checkbox';

// MUI ICONS
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/SaveAlt';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
// import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

// CUSTOM COMPONENTS
// import {ListTable, ListRow, ListHeader, ListCell} from "./ListTable";

const styles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(2),
		'& .MuiTextField-root': {
			margin: '10px 0',
		},
	},
	dialogTitle: {
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.contrastText,
	},
	dialogContent: {
		minWidth: '600px',
	},
	textField: {
		margin: '0px 0',
		fontSize: '2rem',
	},
	groupBox: {
		border: '1px solid black',
		borderRadius: theme.shape.borderRadius,
		borderColor: theme.palette.primary.border_color,
		margin: '1rem 1rem',
		padding: '1rem 1rem',
	},
	triggerBox: {
		height: '100%',
	},
	paramBox: {
		minWidth: '300px',
	},
	select: {
		minWidth: '200px',
	},
}));

export default function SourceSelectDialog({ setOpen, onClose, source }) {
	const classes = styles();
	const [isLoading, setIsLoading] = useState(true);
	const [sourceType, setSourceType] = useState('DEVICE');
	//const [source, setSource] = useState({});

	const handleSelectClose = () => {
		onClose();
	};

	// This is called only when the dialog is opened
	const handleOnEnter = () => {
		setIsLoading(false);
	};

	const handleCancel = () => {
		onClose();
	};

	const handleSourceSelect = () => {
		onClose(source);
	};

	const handleDeviceSelect = () => {
		console.log('Device Select');
		setSourceType('DEVICE');
	};

	const handleNodeSelect = () => {
		console.log('Node Select');
		setSourceType('NODE');
	};

	return (
		<>
			<Dialog
				fullScreen
				open={setOpen}
				onClose={handleSelectClose}
				className={classes.root}
				TransitionProps={{
					onEnter: handleOnEnter,
				}}
			>
				<DialogTitle
					id="form-dialog-title"
					className={classes.dialogTitle}
				>
					Select Source
				</DialogTitle>
				<DialogContent className={classes.dialogContent}>
					{isLoading && (
						<>
							<p>Loading...</p>
							<CircularProgress size={'120px'} />
						</>
					)}

					<Box>
						<Box display="flex" flexDirection="row">
							<Button
								variant="outlined"
								color="primary"
								onClick={handleDeviceSelect}
								className={classes.button}
								style={{ width: '50%' }}
							>
								DEVICE
							</Button>

							<Button
								variant="outlined"
								color="primary"
								onClick={handleNodeSelect}
								className={classes.button}
								style={{ width: '50%' }}
							>
								ORG NODE
							</Button>
						</Box>
					</Box>

					{!isLoading && (
						<>
							<Typography>{sourceType}</Typography>
						</>
					)}

					<Box>
						<Divider />
						<Box display="flex" flexDirection="row-reverse">
							<Button
								variant="outlined"
								startIcon={<SaveIcon />}
								onClick={handleSourceSelect}
								className={classes.button}
								style={{ width: '100px' }}
							>
								OK
							</Button>

							<Button
								variant="outlined"
								startIcon={<CloseIcon />}
								onClick={handleCancel}
								className={classes.button}
								style={{ width: '100px' }}
							>
								Cancel
							</Button>
						</Box>
					</Box>
				</DialogContent>
			</Dialog>
		</>
	);
}
