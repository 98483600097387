const {
	OPERATION_MODE,
	evaluateExpression,
} = require('../../expression/expression.js');
const { isDocument, isEqual } = require('../../utils.js');
const { setStage, ERROR } = require('../pipeline.js');

const stageKey = '$group';

setStage(
	stageKey,
	/**
	 * @type {PipelineStageFunction<{_id: any}>}
	 */
	(context, args, options) => {
		if (!isDocument(args)) {
			throw new Error(
				ERROR.INVALID_STAGE_ARGUMENT(stageKey, 'document', args)
			);
		}

		if (!Object.hasOwnProperty.call(args, '_id')) {
			throw new Error(
				ERROR.INVALID_STAGE_ARGUMENT(
					stageKey,
					'_id expression to be set'
				)
			);
		}

		if (context.collection.length === 0) {
			return [];
		}

		const { _id, ...newFields } = args;

		// group everything by the id fields
		const accumulator = [];

		for (let c = 0; c < context.collection.length; c++) {
			const current = context.collection[c];

			const _idField =
				evaluateExpression(
					{
						...context,
						operationMode: OPERATION_MODE.AGGREGATE,
						current: current,
					},
					_id
				) ?? null;

			const [fieldIdx, prev] = getExistingValueInAccumulator(
				accumulator,
				_idField
			);

			const prevFieldValues = fieldIdx > -1 ? prev.fieldValues : {};

			const accumulatedFields = evaluateExpression(
				{
					...context,
					current: current,
					operationMode: OPERATION_MODE.ACCUMULATE,
					accumulator: prevFieldValues,
					// fieldValues gets set by the object expression for each
					// field as it is evaluated
					fieldValues: null,
				},
				newFields
			);

			if (fieldIdx === -1) {
				accumulator.push({
					_id: _idField,
					fields: accumulatedFields,
					fieldValues: prevFieldValues,
				});
			} else {
				prev.fields = accumulatedFields;
				accumulator.splice(fieldIdx, 1, prev);
			}
		}

		return accumulator.map((a) => ({ _id: a._id, ...a.fields }));
	}
);

function getExistingValueInAccumulator(accumulator, obj) {
	for (let i = 0; i < accumulator.length; i++) {
		if (isEqual(accumulator[i]._id, obj)) {
			return [i, accumulator[i]];
		}
	}

	return [-1, null];
}
