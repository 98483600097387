import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ArrowBack } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router';

import {
	a11yProps,
	BtTab,
	BtTabBar,
	BtTabPanel,
	BtTabView,
} from '../../../components/generic/BtTabView';
import BtLoading from '../../../components/generic/BtLoading';
import BtError from '../../../components/generic/BtError';
import BtFormContainer from '../../../components/generic/forms/BtFormContainer';
import data_manager_options from '../DataManagerOptions';
import data_manager_breadcrumbs from '../DataManagerBreadcrumbs';
import data_manager_nav_item from '../DataManagerNavItem';
import { dataStreamGet } from '../../../API';
import useAvailableToUser from '../../../hooks/useAvailableToUser';
import useFetch from '../../../hooks/useFetch';
import { useNavContext } from '../../../context/ContextManager';

export default function DataStream({ match }) {
	const checkAvailability = useAvailableToUser();
	const history = useHistory();

	const { setBreadcrumbs } = useNavContext();
	const { setContextualNav } = useNavContext();

	const [currentTab, setCurrentTab] = useState(0);
	const [dataStreamName, setDataStreamName] = useState('');

	const canViewStream = checkAvailability({
		requiredPermissions: {
			dataManager: ['DataStreamView'],
		},
	}).available;

	const canEditStream = checkAvailability({
		requiredPermissions: {
			dataManager: ['DataStreamEdit'],
		},
	}).available;

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				...data_manager_breadcrumbs,
				{ text: 'Data Sources', link: '/DataSources' },
				{ text: `Data Stream: ${dataStreamName}`, link: '' },
			]);
		},
		[dataStreamName, setBreadcrumbs]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([
				...data_manager_nav_item,
				...data_manager_options,
			]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	const dataStreamUuid = match.params.uuid;

	const {
		data: dataStream,
		loading,
		error: dataStreamError,
		request: dataStreamRequest,
	} = useFetch(dataStreamGet);

	useEffect(
		() => {
			if (dataStreamUuid && canViewStream) {
				dataStreamRequest({ dataStreamUuid: dataStreamUuid });
			}
		},
		[dataStreamUuid]
	);

	useEffect(
		() => {
			setDataStreamName(dataStream?.name);
		},
		[dataStream]
	);

	if (dataStreamError) {
		return (
			<BtError
				title="Retrieval Error"
				description="An error occurred when attempting to retrieve the Data Stream"
			/>
		);
	}

	return (
		<>
			<BtTabView>
				<BtFormContainer title={dataStream?.name} maxWidth="lg">
					<BtLoading loading={loading}>
						<BtTabBar
							currentTab={currentTab}
							onTabChange={(event, selectedTab) =>
								setCurrentTab(selectedTab)
							}
							style={{
								marginTop: 10,
								transform: 'translateY(-1px)',
							}}
						>
							<BtTab label="Data" {...a11yProps(0)} />
							{canEditStream && (
								<BtTab label="Config" {...a11yProps(1)} />
							)}
						</BtTabBar>

						<BtTabPanel currentTab={currentTab} index={0}>
							{canViewStream && (
								<Typography variant={'h5'}>
									Data Stream Data
								</Typography>
							)}
							{!canViewStream && (
								<BtError
									action={() => history.push('/DataSources')}
									actionIcon={<ArrowBack />}
									actionLabel="Return"
									description="You do not have permission to view streams."
									title="Forbidden"
								/>
							)}
						</BtTabPanel>

						<BtTabPanel currentTab={currentTab} index={1}>
							<pre>{JSON.stringify(dataStream, null, 2)}</pre>
						</BtTabPanel>
					</BtLoading>
				</BtFormContainer>
			</BtTabView>
		</>
	);
}

DataStream.propTypes = {
	match: PropTypes.object,
};
