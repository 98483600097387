import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';

import BtQueryBuilder from './BtQueryBuilder';
import ActionButtonContainer from '../ActionButtonContainer';
import BtDialog from '../BtDialog';

export default function BtQueryBuilderDialog(props) {
	const { onClose, open, label, value, disabled, ...rest } = props;

	const [queryValue, setQueryValue] = useState(null);

	const handleDialogClose = useCallback(
		reason => {
			if (reason === 'done') {
				if (typeof queryValue === 'string') {
					onClose(queryValue, null, reason);
				} else {
					onClose(queryValue.queryStr, queryValue, reason);
				}
			}

			if (reason === 'cancel') {
				onClose(null, null, 'cancel');
			}
		},
		[onClose, queryValue]
	);

	return (
		<BtDialog
			open={open}
			onClose={handleDialogClose}
			fullWidth
			maxWidth="xl"
			disableEscapeKeyDown
		>
			<DialogTitle id="form-dialog-title">
				Query Builder {label ? ' - ' + label : ''}
			</DialogTitle>
			<DialogContent>
				<BtQueryBuilder
					{...rest}
					disabled={disabled}
					initialQuery={value}
					onChange={setQueryValue}
				/>
			</DialogContent>
			<DialogActions>
				<ActionButtonContainer>
					<Button
						variant={disabled ? 'contained' : 'outlined'}
						startIcon={<CloseIcon />}
						onClick={() => handleDialogClose('cancel')}
						disableElevation
					>
						{disabled ? 'Close' : 'Cancel'}
					</Button>
					{!disabled && (
						<Button
							variant="contained"
							startIcon={<DoneIcon />}
							onClick={() => handleDialogClose('done')}
							disableElevation
						>
							Done
						</Button>
					)}
				</ActionButtonContainer>
			</DialogActions>
		</BtDialog>
	);
}

BtQueryBuilderDialog.propTypes = {
	...BtQueryBuilder.propTypes,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.object),
	]),
	open: PropTypes.bool,
	onClose: PropTypes.func,
	label: PropTypes.string,
	disabled: PropTypes.bool,
};
