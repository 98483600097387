import React, {
	useRef,
	useState,
	useEffect,
	useContext,
	createContext,
} from 'react';
//import { SocketInit } from './Sockets';
import { w3cwebsocket as WebSocket } from 'websocket';

import { v1 as uuid } from 'uuid';
import _ from 'lodash';
import * as yup from 'yup';
import { Auth } from 'aws-amplify';
import { useAppContext } from '../ContextManager';

// SocketContext
const SocketContext = createContext();
const useSocketContext = () => {
	const context = useContext(SocketContext);
	if (context === undefined) {
		throw new Error('useSocketContext was used outside of its Provider');
	}
	return context;
};

const baseMessageSchema = yup
	.object()
	.required()
	.shape({
		messageType: yup
			.string()
			.required()
			.oneOf([
				'authResponse',
				'keepAliveResponse',
				'subscribeResponse',
				'unsubscribeResponse',
				'publishResponse',
				'subscribeData',
			]),
		messageBody: yup
			.object()
			.required()
			.shape({}),
	});

// =====================================================================
const SocketProvider = props => {
	const socket = useRef(null);
	const [socketReady, setSocketReady] = useState(false);
	const [authDone, setAuthDone] = useState(false);
	const [subscriptions, setSubscriptions] = useState({});
	const [connectInterval, setConnetInterval] = useState(null);
	const { loadApp, userInfo, masterLoading } = useAppContext();

	// =====================================================================
	const addSubscriber = (id, channel, callback) => {
		try {
			var subs = subscriptions;
			// Test if the channel is already in the list
			if (subs[channel]) {
				subs[channel].push({ id: id, callback: callback });
				setSubscriptions(subs);
			} else {
				subs[channel] = [{ id: id, callback: callback }];
				setSubscriptions(subs);
			}
		} catch (error) {
			console.log(error);
		}
	};

	// =====================================================================
	const removeSubscirber = (id, channel) => {
		try {
			var subs = subscriptions;
			if (subs[channel]) {
				_.remove(subs[channel], { id: id });
				// If this was the last component listening to this then unsubscribe from the server
				if (subs[channel].length === 0) {
					socket.current.send(
						JSON.stringify({
							auth: 'Auth String',
							messageType: 'unsubscribeRequest',
							messageBody: { channel: channel },
						})
					);
					delete subs[channel];
				}
				setSubscriptions(subs);
			} else {
				console.log('Error cant find sub to remove');
			}
		} catch (error) {
			console.log(error);
		}
	};

	// =====================================================================
	const subscribe = (id, channel, callback) => {
		try {
			// If we are not already listening to this channel send a request to do so
			if (!subscriptions[channel]) {
				// Check that the websocket is open and ready
				if (socket.current.readyState === socket.current.OPEN) {
					// Write out request to the server
					socket.current.send(
						JSON.stringify({
							auth: 'Auth String',
							messageType: 'subscribeRequest',
							messageBody: { channel: channel },
						})
					);
				}
			}
			addSubscriber(id, channel, callback);
		} catch (error) {
			console.log(error);
		}
	};

	// =====================================================================
	const unsubscribe = (id, channel) => {
		removeSubscirber(id, channel);
	};

	// =====================================================================
	const publish = (channel, message) => {
		try {
			// Check that the websocket is open and ready
			if (socket.current.readyState === socket.current.OPEN) {
				// Send the publish request to the server
				socket.current.send(
					JSON.stringify({
						auth: 'Auth String',
						messageType: 'publishRequest',
						messageBody: { channel: channel, message, message },
					})
				);
			}
		} catch (error) {
			console.log(error);
		}
	};

	// =====================================================================
	const sendKeepAlive = () => {
		try {
			// Check that the websocket is open and ready
			if (socket.current.readyState === socket.current.OPEN) {
				// Send the publish request to the server
				socket.current.send(
					JSON.stringify({
						auth: 'Auth String',
						messageType: 'keepAliveRequest',
						messageBody: {},
					})
				);
			}
		} catch (error) {
			console.log(error);
		}
	};

	// =====================================================================
	useEffect(
		() => {
			if (!masterLoading) {
				connect();
			}

			// return () => {
			// 	console.log('WebSocket close');
			// 	socket.current.close();
			// };
		},
		[masterLoading]
	);

	// =====================================================================
	const connect = () => {
		//console.log('WebSocket Connect');

		// Check if we have no socket or the socket is closed
		// https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState
		if (
			!socket.current ||
			(socket.current && socket.current.readyState === 3)
		) {
			var keepAliveInterval = null;

			// Open WebSocket
			socket.current = new WebSocket(process.env.REACT_APP_API_WS_URL);

			// =====================================================================
			socket.current.onopen = async () => {
				// console.log('WS OnOpen');
				setSocketReady(true);

				// console.log('Sending Auth Request');

				try {
					if (socket.current.readyState === socket.current.OPEN) {
						var auth_msg = {};
						//auth_msg['request'] = 'auth';
						//auth_msg['token'] = 'asdgfsad';

						if (
							process.env.REACT_APP_API_SERVER_URL.includes(
								'localhost'
							)
						) {
							auth_msg.auth = 'Test Auth String';
						} else {
							const userSession = await Auth.currentSession();

							// console.log('userSession', userSession);
							const userToken = userSession.getAccessToken();
							// console.log('userToken', userToken);

							auth_msg.auth = userToken.jwtToken;
						}

						auth_msg.messageType = 'authRequest';
						auth_msg.messageBody = { this: 'that' };
						socket.current.send(JSON.stringify(auth_msg));
					}
				} catch (error) {
					console.log(error);
				}
			};

			// =====================================================================
			socket.current.onmessage = async messageData => {
				try {
					let packet = JSON.parse(messageData.data);

					// Validate the base packet schema
					await baseMessageSchema.validate(packet);

					switch (packet.messageType) {
						case 'authResponse':
							// console.log('Auth Response');
							setAuthDone(true);

							keepAliveInterval = setInterval(
								sendKeepAlive,
								50000
							);
							break;
						case 'keepAliveResponse':
							break;
						case 'subscribeResponse':
							console.log('Subscribe Response');
							break;
						case 'unsubscribeResponse':
							console.log('Unsubscribe Response');
							break;
						case 'publishResponse':
							console.log('Publish Response');
							break;
						case 'subscribeData':
							// console.log('subscribeData');
							// console.log(packet);
							// console.log(
							// 	subscriptions[packet.messageBody.channel]
							// );
							if (subscriptions[packet.messageBody.channel]) {
								subscriptions[
									packet.messageBody.channel
								].forEach(sub => {
									sub.callback(
										packet.messageBody.channel,
										packet.messageBody.message
									);
								});
							} else {
								console.log('subs not found');
								throw new Error('Missing sub!');
							}
							break;
						default:
							console.log('Unsupported packet');
							throw new Error('Unsuported WebSocket Packet');
					}

					// if (packet.channel) {
					// 	// Dispatch to listening components
					// 	if (subscriptions[packet.channel]) {
					// 		subscriptions[packet.channel].forEach(sub => {
					// 			sub.callback(packet.message);
					// 		});
					// 	} else {
					// 		console.log('subs not found');
					// 		throw new Error('Missing sub!');
					// 	}
					// } else {
					// 	switch (packet.type) {
					// 		case 'auth_response':
					// 			// console.log('Auth Done');

					// 			break;
					// 		default:
					// 			console.log(
					// 				'Unsupported WS Packet Type: ' + packet.type
					// 			);
					// 			break;
					// 	}
					// }
				} catch (error) {
					console.log(error);
				}
			};

			// =====================================================================
			socket.current.onclose = () => {
				console.log('WS OnClose');
				setSocketReady(false);

				clearInterval(keepAliveInterval);

				// TODO: Can we apply an exponential backoff retry method?
				// Try to reconnect after a short delay
				setTimeout(
					() => {
						connect();
					},
					[1000]
				);
			};

			// =====================================================================
			socket.current.onerror = () => {
				console.log('WS OnError');
				setSocketReady(false);
			};
		}
	};

	// =====================================================================
	return (
		<SocketContext.Provider
			value={{
				socket: socket.current,
				subscribe,
				unsubscribe,
				publish,
				socketReady,
			}}
		>
			{props.children}
		</SocketContext.Provider>
	);
};

// =====================================================================
function useSubscribe(channel, messageCallback) {
	const { subscribe, unsubscribe } = useContext(SocketContext);
	const [message, setMessage] = useState(null);
	const [error, setError] = useState(null);

	const messageUpdate = (channel, newMessage) => {
		setMessage(newMessage);
	};

	useEffect(
		() => {
			const id = uuid();
			if (channel) {
				console.log('=>> Subscribe: ' + channel);
				subscribe(id, channel, messageUpdate);
			}
			return () => {
				console.log('=>> Unsubscribe: ' + channel);
				unsubscribe(id, channel);
			};
		},
		[channel]
	);

	return { message, error };
}

export { SocketProvider, useSocketContext, useSubscribe };
