import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';

import { styled } from '@mui/styles';

const TextButton = styled(Typography)(({ theme }) => ({
	textDecoration: 'none',
	fontWeight: 'bold',
	fontSize: 14,
	'&:hover': {
		textDecoration: 'underline',
		cursor: 'pointer',
		color: theme.palette.primary.dark,
	},
	color: theme.palette.primary.main,
}));

export const BtListButton = ({ onClick, id, label, onMouseOver }) => {
	return (
		<TextButton onMouseOver={onMouseOver} onClick={onClick} id={id}>
			{label}
		</TextButton>
	);
};

BtListButton.propTypes = {
	onClick: PropTypes.func.isRequired,
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	onMouseOver: PropTypes.func,
};
