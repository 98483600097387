import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const LogoContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: 36,
	minWidth: 36,
	height: 36,
	minHeight: 36,
	borderRadius: 8,
	marginRight: 8,
}));

const InitialsContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: 36,
	minWidth: 36,
	height: 36,
	minHeight: 36,
	borderRadius: 18,
	backgroundColor: theme.palette.primary.main + 55,
	marginRight: 8,
}));

export default function BtOrgLogo({ logo, initials, name }) {
	return (
		<>
			{logo && (
				<LogoContainer>
					<img src={`data:image/jpeg;base64,${logo}`} />
				</LogoContainer>
			)}
			{!logo && (
				<InitialsContainer>
					{initials && (
						<Typography>{initials.substring(2, 0)}</Typography>
					)}
					{!initials && (
						<Typography sx={{ fontSize: 10 }}>
							{name.substring(2, 0)}
						</Typography>
					)}
				</InitialsContainer>
			)}
		</>
	);
}
