import { useRecordManagementContext } from '../RecordManagementContext';
import BtLoading from '../../BtLoading';
import BtError from '../../BtError';
import ManagerTabDisplay from './ManagerTabDisplay';
import React from 'react';

export default function RecordManager() {
	const { loading, error } = useRecordManagementContext();

	if (error) {
		return <BtError />;
	}

	return (
		<BtLoading loading={loading}>
			<ManagerTabDisplay />
		</BtLoading>
	);
}
