import {
	COMMIT_DRAFT_API,
	PUBLISH_RECORD_API,
	UPDATE_RECORD_API,
} from '../constants';
import { lookupEndpoint } from '../lookupTables/apiEndpoints';

const handlePublishWithDraft = async ({
	apiUuidParamPayload,
	moduleFullName,
	module,
	recordHistoryGet,
	payload,
}) => {
	// Initialise return value
	const result = {};
	result.variant = 'success';

	try {
		// get the commit request from lookup
		const commitDraftEndpoint = lookupEndpoint(module, COMMIT_DRAFT_API);

		// get the publish request from lookup
		const publishRecordEndpoint = lookupEndpoint(
			module,
			PUBLISH_RECORD_API
		);

		// get the update collection request from lookup
		const updateRecordEndpoint = lookupEndpoint(module, UPDATE_RECORD_API);

		if (
			!publishRecordEndpoint ||
			!updateRecordEndpoint ||
			!commitDraftEndpoint
		) {
			throw new Error('Endpoint not found');
		}

		// commit the draft to the versions array
		await commitDraftEndpoint(apiUuidParamPayload);

		await updateRecordEndpoint({
			...apiUuidParamPayload,
			update: {
				current_version: 0,
			},
		});

		await publishRecordEndpoint(apiUuidParamPayload);

		// Update return value
		result.text = `${moduleFullName} draft committed`;
	} catch (error) {
		console.log(error);
		result.text = `Error committing ${moduleFullName} draft`;
		result.variant = 'error';
	} finally {
		// Reload history data
		recordHistoryGet();
		return result;
	}
};

export { handlePublishWithDraft };
