import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import { Typography, Toolbar } from '@mui/material';
import AppBar from '@mui/material/AppBar'

const styles = makeStyles((theme) => ({
    root:{
        height: '100%',
    },
    header: {
      minHeight: '32px',
      //backgroundColor: theme.palette.primary.shade_main,
      //color: theme.palette.primary.dark,
    },
}));

export default function CustomPaper(props) {
    const classes = styles();
    return(
        // <Paper className={classes.root} square elevation={3}>
        //     <AppBar
        //         color={'secondary'}
        //         position={'static'}
        //         elevation={0}
        //     >
        //         <Toolbar className={classes.header}>
        //             <Typography color={'inherit'} variant={'h5'}>
        //                 {props.title}
        //             </Typography>
        //         </Toolbar>
        //     </AppBar>
        //     <div style={{padding:'1.5rem'}}>
        //         {props.children}
        //     </div>
        // </Paper>

        <Paper className={classes.root}  elevation={3}>
            <AppBar
                sx={{borderRadius: '0.5em'}}
                color={'secondary'}
                position={'static'}
                elevation={0}
            >
                <Toolbar  className={classes.header}>
                    <Typography color={'inherit'} variant={'h5'}>
                        {props.title}
                    </Typography>
                </Toolbar>
            </AppBar>
            <div style={{padding:'1.5rem'}}>
                {props.children}
            </div>
        </Paper>
    );
}

