import { apiClient } from '../api_client';
import { uuidRegex } from '../utils';

import {
	platformAdminOrgListGetSchema,
	platformAdminOrgGetSchema,
	platformAdminOrgCreateResponseSchema,
	platformAdminOrgUpdateResponseSchema,
	platformAdminOrgDeleteResponseSchema,
	platformAdminOrgSubscriptionAddSchema,
	platformAdminOrgSubscriptionUpdateSchema,
	platformAdminUserListGetSchema,
	platformAdminOrgUserGetSchema,
	platformAdminRoleGetListSchema,
	platformAdminRoleAddSchema,
	platformAdminOrgInviteUserSchema,
} from '../validations/platformAdminOrganisationValidation';

//========================================================================================================
async function platformAdminOrganisationGetList() {
	const req_url = 'platform_admin/orgs';
	try {
		const response = await apiClient({ method: 'get', url: req_url });

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let insights = await platformAdminOrgListGetSchema.validate(
					response.organisations
				);
				return insights;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function platformAdminOrganisationGet({ orgUuid }) {
	if (!uuidRegex.test(orgUuid)) {
		throw new Error('Param Error');
	}

	const req_url = `platform_admin/org/${orgUuid}`;
	try {
		const response = await apiClient({ method: 'get', url: req_url });
		console.log(response);
		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let orgDetails = await platformAdminOrgGetSchema.validate(
					response.organisation
				);
				return orgDetails;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function platformAdminOrganisationCreate({ newOrgName }) {
	const req_url = `platform_admin/org`;
	try {
		const response = await apiClient({
			method: 'post',
			url: req_url,
			body: { org_name: newOrgName },
		});

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let orgCreateResponse = await platformAdminOrgCreateResponseSchema.validate(
					response.new_org
				);
				return orgCreateResponse;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function platformAdminOrganisationUpdate({ orgUuid, orgDetails }) {
	const req_url = `platform_admin/org/${orgUuid}`;
	try {
		const response = await apiClient({
			method: 'put',
			url: req_url,
			body: orgDetails,
		});

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object

				return;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function platformAdminOrganisationDelete({ orgUuid }) {
	if (!uuidRegex.test(orgUuid)) {
		throw new Error('Param Error');
	}

	const req_url = `platform_admin/org/${orgUuid}`;
	try {
		const response = await apiClient({ method: 'delete', url: req_url });

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let orgDeleteResponse = await platformAdminOrgDeleteResponseSchema.validate(
					response.deleteResult
				);
				return orgDeleteResponse;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function platformAdminOrganisationAddSubscription({
	orgUuid,
	newSubscription,
}) {
	if (!uuidRegex.test(orgUuid)) {
		throw new Error('Param Error');
	}

	// Validate the subscription details
	try {
		await platformAdminOrgSubscriptionAddSchema.validate(newSubscription);
	} catch (error) {
		console.log(error);
		throw error;
	}

	const req_url = `platform_admin/org/${orgUuid}/subscription`;
	try {
		const response = await apiClient({
			method: 'post',
			url: req_url,
			body: newSubscription,
		});

		if (response) {
			if (response.result !== 'OK') {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function platformAdminOrganisationUpdateSubscription({
	orgUuid,
	subUuid,
	subscriptionUpdate,
}) {
	if (!uuidRegex.test(orgUuid)) {
		throw new Error('Param Error');
	}

	if (!uuidRegex.test(subUuid)) {
		throw new Error('Param Error');
	}

	// Validate the subscription details
	try {
		await platformAdminOrgSubscriptionUpdateSchema.validate(
			subscriptionUpdate
		);
	} catch (error) {
		console.log(error);
		throw error;
	}

	const req_url = `platform_admin/org/${orgUuid}/subscription/${subUuid}`;
	try {
		const response = await apiClient({
			method: 'put',
			url: req_url,
			body: subscriptionUpdate,
		});

		if (response) {
			if (response.result !== 'OK') {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}
//========================================================================================================
async function platformAdminOrganisationDeleteSubscription({
	orgUuid,
	subUuid,
}) {
	if (!uuidRegex.test(orgUuid)) {
		throw new Error('Param Error');
	}

	if (!uuidRegex.test(subUuid)) {
		throw new Error('Param Error');
	}

	const req_url = `platform_admin/org/${orgUuid}/subscription/${subUuid}`;
	try {
		const response = await apiClient({ method: 'delete', url: req_url });

		if (response) {
			if (response.result !== 'OK') {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function platformAdminUserGetList() {
	const req_url = 'platform_admin/users';
	try {
		const response = await apiClient({ method: 'get', url: req_url });

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				const users = await platformAdminUserListGetSchema.validate(
					response.users
				);
				return users;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function platformAdminOrgGetUser({ orgUuid, userUuid }) {
	const req_url = `platform_admin/org/${orgUuid}/user/${userUuid}`;

	if (!uuidRegex.test(userUuid)) {
		throw new Error('Param Error');
	}

	if (!uuidRegex.test(orgUuid)) {
		throw new Error('Param Error');
	}

	try {
		const response = await apiClient({ method: 'get', url: req_url });

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let user = await platformAdminOrgUserGetSchema.validate(
					response.user
				);
				return user;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function platformAdminOrgRoleGetList({ orgUuid }) {
	const req_url = `platform_admin/org/${orgUuid}/role`;
	console.log(req_url);
	try {
		const response = await apiClient({ method: 'get', url: req_url });

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				await platformAdminRoleGetListSchema.validate(response.roles);
				return response.roles;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function platformAdminOrgRoleAddUser({ orgUuid, roleUuid, userUuid }) {
	if (!uuidRegex.test(orgUuid)) {
		throw new Error('Invalid org UUID');
	}

	if (!uuidRegex.test(roleUuid)) {
		throw new Error('Invalid role UUID');
	}

	if (!uuidRegex.test(userUuid)) {
		throw new Error('Invalid user UUID');
	}

	// Validate the orgDetails
	// try {
	// 	// The role update has the same schema are the add
	// 	await platformAdminRoleAddSchema.validate(roleUpdate);
	// } catch (error) {
	// 	throw error;
	// }

	const req_url = `platform_admin/org/${orgUuid}/role/${roleUuid}/user`;

	const response = await apiClient({
		method: 'post',
		url: req_url,
		body: { user_uuid: userUuid },
	});

	if (response) {
		if (response.result === 'OK') {
			return;
		} else {
			throw new Error('Server Error');
		}
	}
}

//========================================================================================================
async function platformAdminOrgRoleDeleteUser({ orgUuid, roleUuid, userUuid }) {
	if (!uuidRegex.test(orgUuid)) {
		throw new Error('Invalid org UUID');
	}

	if (!uuidRegex.test(roleUuid)) {
		throw new Error('Invalid role UUID');
	}

	if (!uuidRegex.test(userUuid)) {
		throw new Error('Invalid user UUID');
	}

	const req_url = `platform_admin/org/${orgUuid}/role/${roleUuid}/user`;

	const response = await apiClient({
		method: 'delete',
		url: req_url,
		body: { user_uuid: userUuid },
	});

	if (response) {
		if (response.result === 'OK') {
			return;
		} else {
			throw new Error('Server Error');
		}
	}
}

//========================================================================================================
async function platformAdminOrgInviteUser({ orgUuid, inviteDetails }) {
	if (!uuidRegex.test(orgUuid)) {
		throw new Error('Invalid org UUID');
	}

	// Validate the invite details
	try {
		await platformAdminOrgInviteUserSchema.validate(inviteDetails);
	} catch (error) {
		console.log(error);
		throw error;
	}

	const req_url = `platform_admin/org/${orgUuid}/user`;

	const response = await apiClient({
		method: 'post',
		url: req_url,
		body: inviteDetails,
	});

	if (response) {
		if (response.result === 'OK') {
			return;
		} else {
			throw new Error('Server Error');
		}
	}
}

//========================================================================================================
async function platformAdminOrgAddUser({ orgUuid, userUuid }) {
	if (!uuidRegex.test(orgUuid)) {
		throw new Error('Invalid org UUID');
	}

	if (!uuidRegex.test(userUuid)) {
		throw new Error('Invalid user UUID');
	}

	const req_url = `platform_admin/org/${orgUuid}/user/${userUuid}`;
	const response = await apiClient({
		method: 'put',
		url: req_url,
	});

	if (response) {
		if (response.result === 'OK') {
			return;
		} else {
			throw new Error('Server Error');
		}
	}
}

export {
	platformAdminOrganisationGetList,
	platformAdminOrganisationGet,
	platformAdminOrganisationCreate,
	platformAdminOrganisationUpdate,
	platformAdminOrganisationDelete,
	platformAdminOrganisationAddSubscription,
	platformAdminOrganisationUpdateSubscription,
	platformAdminOrganisationDeleteSubscription,
	platformAdminUserGetList,
	platformAdminOrgGetUser,
	platformAdminOrgRoleAddUser,
	platformAdminOrgRoleDeleteUser,
	platformAdminOrgRoleGetList,
	platformAdminOrgInviteUser,
	platformAdminOrgAddUser,
};
