import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material';

const ERROR = 'error',
	INFO = 'info',
	SUCCESS = 'success',
	WARNING = 'warning';

const Badge = styled('span')(({ variant, theme }) => ({
	backgroundColor: theme.palette.indicators[variant].main,
	borderRadius: 9,
	color: '#ffffff',
	fontSize: 13,
	fontWeight: 'bold',
	maxHeight: 18,
	minWidth: 18,
	padding: '0 5px',
	pointerEvents: 'none',
	position: 'absolute',
	right: 0,
	top: 2,
	userSelect: 'none',
}));

export default function BtBadge({ count, variant, ...otherProps }) {
	if (!count) return null;

	return (
		<Badge variant={variant} {...otherProps}>
			{count < 100 ? count : '99+'}
		</Badge>
	);
}

BtBadge.defaultProps = {
	variant: INFO,
};

BtBadge.propTypes = {
	count: PropTypes.number,
	variant: PropTypes.oneOf([ERROR, INFO, SUCCESS, WARNING]),
};
