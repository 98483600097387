import { useMediaQuery } from '@mui/material';

export default function useBreakpointValue() {
	// const xs = useMediaQuery(theme => theme.breakpoints.up('xs'));
	// const sm = useMediaQuery(theme => theme.breakpoints.up('sm'));
	// const md = useMediaQuery(theme => theme.breakpoints.up('md'));
	// const lg = useMediaQuery(theme => theme.breakpoints.up('lg'));
	// const xl = useMediaQuery(theme => theme.breakpoints.up('xl'));
	const xs = useMediaQuery(theme => theme.breakpoints.only('xs'));
	const sm = useMediaQuery(theme => theme.breakpoints.only('sm'));
	const md = useMediaQuery(theme => theme.breakpoints.only('md'));
	const lg = useMediaQuery(theme => theme.breakpoints.only('lg'));
	const xl = useMediaQuery(theme => theme.breakpoints.only('xl'));
	switch (true) {
		case xl:
			return 'xl';
		case lg:
			return 'lg';
		case md:
			return 'md';
		case sm:
			return 'sm';
		case xs:
			return 'xs';
		default:
			return 'xxs';
	}
}
