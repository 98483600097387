import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
	DataArray as ArrayIcon,
	PowerSettingsNew as BooleanIcon,
	CalendarToday as DateIcon,
	DoNotDisturb as NullIcon,
	Numbers as NumberIcon,
	DataObject as ObjectIcon,
	TextFields as StringIcon,
	QuestionMark as UndefinedIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material';

import {
	ARRAY,
	BOOLEAN,
	DATE,
	NULL,
	NUMBER,
	OBJECT,
	STRING,
	UNDEFINED,
} from '../../../../../utils/commonDataTypes';

const IconContainer = styled('div')(({ theme, variant }) => ({
	alignItems: 'center',
	backgroundColor: theme.palette.dataTypes[variant],
	borderRadius: 4,
	color: theme.palette.background.default,
	display: 'flex',
	height: 16,
	justifyContent: 'center',
	width: 16,
}));

export const DataSetTypeIcon = forwardRef(({ variant, ...other }, ref) => {
	const Icon = useMemo(
		() => {
			const iconLookup = {
				[ARRAY]: ArrayIcon,
				[BOOLEAN]: BooleanIcon,
				[DATE]: DateIcon,
				[NULL]: NullIcon,
				[NUMBER]: NumberIcon,
				[OBJECT]: ObjectIcon,
				[STRING]: StringIcon,
				[UNDEFINED]: UndefinedIcon,
			};

			return iconLookup[variant];
		},
		[variant]
	);

	return (
		<IconContainer ref={ref} variant={variant} {...other}>
			<Icon style={{ fontSize: 14 }} />
		</IconContainer>
	);
});

DataSetTypeIcon.propTypes = {
	variant: PropTypes.oneOf([
		ARRAY,
		BOOLEAN,
		DATE,
		NULL,
		NUMBER,
		OBJECT,
		STRING,
		UNDEFINED,
	]).isRequired,
};

export default DataSetTypeIcon;
