import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import BtPalette from '../palette/BtPalette';
import SelectPaletteDialog from './components/SelectPaletteDialog';
import {
	buildNewPalette,
	mapPaletteSelectOptions,
	processPalettesUpdate,
} from './utils/functions';
import {
	mdiArchive,
	mdiArchiveCancel,
	mdiClose,
	mdiContentDuplicate,
	mdiCreation,
	mdiEye,
	mdiPencil,
	mdiPencilOff,
} from '@mdi/js';
import BtConfirmDialog from '../generic/BtConfirmDialog';
import GeneratePaletteForm from './components/GeneratePaletteForm';
import PaletteDemoDialog from './components/PaletteDemoDialog';
import {
	EditButtonBox,
	EmptyPalettePlaceHolder,
	PaletteRow,
	PalettesContainer,
	TitleEditContainer,
	PaletteTitleText,
	ComponentTitleBox,
	MobileButtons,
	ComponentContainer,
} from './components/layoutElements';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { Eye, EyeOff, Plus } from 'mdi-material-ui';
import { capitalize } from 'lodash';
import PalettePropertiesDialog from './components/PalettePropertiesDialog';
import {
	ACTIVE,
	ARCHIVED,
	INSIGHTS,
	ORGANISATION,
	PLATFORM,
} from './utils/constants';
import { BtFormContainerStackedActionButtons } from '../generic/forms/BtFormContainerStackedActionButtons';
import { useTheme } from '@mui/styles';
import { BtIconButton } from '../generic/BtIconButton';
import { BtIconButtonGroup } from '../generic/BtIconButtonGroup';

const PaletteManager = memo(function PaletteManager({
	editing,
	palettes,
	onChangePalettes,
	setShowArchived,
	showArchived,
}) {
	const [clonePaletteDialogOpen, setClonePaletteDialogOpen] = useState(false);
	const [paletteDemoOpen, setPaletteDemoOpen] = useState(false);
	const [showGenerateForm, setShowGenerateForm] = useState(false);
	const [clearWarnDialogOpen, setClearWarnDialogOpen] = useState(false);
	const [updatePalettePropsDialog, setUpdatePalettePropsDialog] = useState(
		false
	);
	const [newPaletteDialogOpen, setNewPaletteDialogOpen] = useState(false);
	const [editingIndex, setEditingIndex] = useState(null);
	// const [dndDisabled, setDndDisabled] = useState(false);
	const theme = useTheme();
	const screen_downSm = useMediaQuery(theme.breakpoints.down('sm'));

	const handlePaletteChange = useCallback(
		updates => {
			const update = processPalettesUpdate(
				updates,
				editingIndex,
				palettes
			);
			setShowGenerateForm(false);
			onChangePalettes(update);
		},

		[editingIndex, onChangePalettes, palettes]
	);

	const handleEditPalette = useCallback(
		index => {
			if (editingIndex === index) {
				setShowGenerateForm(false);
				setEditingIndex(null);
			} else {
				setShowGenerateForm(false);
				setEditingIndex(index);
			}
		},
		[editingIndex]
	);

	const handleShowArchived = useCallback(
		() => {
			// if an archived palette is selected for edit,
			// clear the editingIndex value when hiding the archived palettes
			// if (showArchived && palettes[editingIndex]?.status === ARCHIVED)
			// 	setEditingIndex(null);

			setEditingIndex(null);
			setShowArchived(showArchived ? false : true);
		},
		[setShowArchived, showArchived]
	);

	const handleNewPalette = useCallback(
		newPaletteProperties => {
			const newPalette = buildNewPalette(newPaletteProperties);
			const palettesUpdate = [...palettes, newPalette];
			onChangePalettes(palettesUpdate);
		},
		[onChangePalettes, palettes]
	);

	const handleClonePalette = useCallback(
		selectedPalette => {
			const partialUpdate = { palette: selectedPalette.palette };
			const update = processPalettesUpdate(
				partialUpdate,
				editingIndex,
				palettes
			);
			setShowGenerateForm(false);
			onChangePalettes(update);
		},
		[editingIndex, onChangePalettes, palettes]
	);

	const handleArchivePalette = useCallback(
		() => {
			const update = {};
			update.status =
				palettes[editingIndex].status === ARCHIVED ? ACTIVE : ARCHIVED;
			handlePaletteChange(update);
		},
		[editingIndex, handlePaletteChange, palettes]
	);

	// remove empty palettes and shap[e the data for the filterSelect
	const paletteOptions = useMemo(
		() => {
			const nonEmptyPalettes = palettes.filter(
				p => p.palette.length !== 0
			);
			return mapPaletteSelectOptions(nonEmptyPalettes);
		},
		[palettes]
	);

	const evaluateArchived = useCallback(
		() => {
			if (showArchived) {
				return palettes[editingIndex]?.status === ARCHIVED;
			} else {
				return false;
			}
		},
		[editingIndex, palettes, showArchived]
	);

	// 	const filteredPalettes = useMemo(
	// 	() =>
	// 		showArchived
	// 			? palettes
	// 			: palettes?.filter(({ status }) => status === ACTIVE),
	// 	[palettes, showArchived]
	// );

	const standardButtons = useMemo(
		() => [
			// {
			// 	tooltip: 'Change Theme',
			// 	callback: () => setSelectPaletteDialogOpen(true),
			// 	icon: mdiSwapHorizontalBold,
			// 	disabled: false,
			// },
			{
				tooltip: 'Cancel Edit',
				onClick: () => setEditingIndex(null),
				icon: mdiPencilOff,
			},
			{
				tooltip: 'Generate Palette',
				onClick: () => setShowGenerateForm(true),
				icon: mdiCreation,
			},
			{
				tooltip: 'Clear Palette',
				onClick: () => setClearWarnDialogOpen(true),
				icon: mdiClose,
				disabled: palettes[editingIndex]?.palette.length === 0,
			},

			{
				tooltip: 'Clone Palette',
				onClick: () => setClonePaletteDialogOpen(true),
				icon: mdiContentDuplicate,
				disabled: paletteOptions.length === 0,
			},
			{
				tooltip: 'Demonstrate Palette',
				onClick: () => setPaletteDemoOpen(true),
				icon: mdiEye,
				disabled: palettes[editingIndex]?.palette.length === 0,
			},
			{
				tooltip: evaluateArchived()
					? 'Unarchive Palette'
					: 'Archive Palette',
				onClick: handleArchivePalette,
				icon: evaluateArchived() ? mdiArchiveCancel : mdiArchive,
			},
			// {
			// 	tooltip: 'Reorder Theme',
			// 	callback: () => setClonePaletteDialogOpen(true),
			// 	icon: mdiContentDuplicate,
			// 	disabled: !themeIsEditable,
			// },
		],
		[
			editingIndex,
			evaluateArchived,
			handleArchivePalette,
			paletteOptions,
			palettes,
		]
	);

	const isEditing = useCallback(index => index === editingIndex, [
		editingIndex,
	]);

	const title = useMemo(() => `${capitalize(editing)} Palettes`, [editing]);

	const existingNames = useMemo(
		() => {
			const names = palettes.map(({ name }) => name);
			const namesExcludingCurrent = names.filter(
				n => n !== palettes[editingIndex]?.name
			);
			return { names, namesExcludingCurrent };
		},
		[editingIndex, palettes]
	);

	const EditPropertiesButton = () => {
		return (
			<Box sx={{ paddingLeft: '5px' }}>
				<BtIconButton
					size="xs"
					icon={mdiPencil}
					tooltip="Edit Palette Properties"
					onClick={() => setUpdatePalettePropsDialog(true)}
				/>
			</Box>
		);
	};

	const EditPaletteButton = ({ i }) => {
		return (
			<EditButtonBox id="childToShowOnHover">
				<BtIconButton
					tooltip={'Edit'}
					onClick={() => handleEditPalette(i)}
					icon={mdiPencil}
				/>
			</EditButtonBox>
		);
	};

	const buttonAttr = useMemo(
		() => [
			{
				onClick: () => setNewPaletteDialogOpen(true),
				variant: 'contained',
				startIcon: <Plus />,
				title: 'New Palette',
			},
			{
				onClick: handleShowArchived,
				variant: 'outlined',
				startIcon: showArchived ? <EyeOff /> : <Eye />,
				title: showArchived ? 'hide archived' : 'show archived',
			},
		],
		[handleShowArchived, showArchived]
	);

	// useEffect(
	// 	() => {
	// 		console.log(palettes);
	// 	},
	// 	[palettes]
	// );

	const filteredPalettes = useMemo(
		() => {
			if (palettes) {
				return showArchived
					? palettes
					: palettes?.filter(({ status }) => status === ACTIVE);
			}
		},
		[palettes, showArchived]
	);

	return (
		<>
			<ComponentContainer>
				<ComponentTitleBox>
					<Typography variant="h3">{title}</Typography>
					{!screen_downSm && (
						<BtFormContainerStackedActionButtons
							buttonAttr={buttonAttr}
						/>
					)}
				</ComponentTitleBox>

				<PalettesContainer>
					{filteredPalettes.map((palette, i) => (
						<PaletteRow key={palette.uuid} selected={isEditing(i)}>
							<TitleEditContainer>
								<PaletteTitleText selected={isEditing(i)}>
									{palette.name}
								</PaletteTitleText>
								<Typography sx={{ fontStyle: 'italic' }}>
									&nbsp; - {palette.type}
								</Typography>
								{isEditing(i) && <EditPropertiesButton />}
							</TitleEditContainer>
							{isEditing(i) && showGenerateForm ? (
								<GeneratePaletteForm
									handleCancel={() =>
										setShowGenerateForm(false)
									}
									handleGeneratePalette={handlePaletteChange}
								/>
							) : (
								<>
									{palette.palette.length === 0 &&
									!isEditing(i) ? (
										<EmptyPalettePlaceHolder />
									) : (
										<BtPalette
											disabled={!isEditing(i)}
											palette={palette.palette}
											onChange={palette =>
												handlePaletteChange({
													palette: palette,
												})
											}
										/>
									)}

									{isEditing(i) ? (
										<BtIconButtonGroup
											containerStyle={{
												marginTop: '10px',
											}}
											buttonGroupAttributes={
												standardButtons
											}
										/>
									) : (
										<EditPaletteButton i={i} />
									)}
								</>
							)}
						</PaletteRow>
					))}

					{/* clone a palette */}
					<SelectPaletteDialog
						verb="select palette"
						title="Select a palette"
						options={paletteOptions}
						open={clonePaletteDialogOpen}
						onClose={() => setClonePaletteDialogOpen(false)}
						onSelect={handleClonePalette}
						message="Cloning a theme will clear the the current theme which can not be undone"
					/>
					<PaletteDemoDialog
						palette={palettes[editingIndex]}
						open={paletteDemoOpen}
						onClose={() => setPaletteDemoOpen(false)}
					/>
					{/* edit dialog props */}
					<PalettePropertiesDialog
						existingNames={existingNames.namesExcludingCurrent}
						onClose={() => setUpdatePalettePropsDialog(false)}
						onSubmit={handlePaletteChange}
						open={updatePalettePropsDialog}
						paletteName={palettes[editingIndex]?.name || ''}
						paletteType={palettes[editingIndex]?.type || ''}
						title="Update Palette Properties"
					/>
					{/* create a new palette */}
					<PalettePropertiesDialog
						existingNames={existingNames.names}
						onClose={() => setNewPaletteDialogOpen(false)}
						onSubmit={handleNewPalette}
						open={newPaletteDialogOpen}
						title="Create new palette"
					/>
					<BtConfirmDialog
						title="Clear palette"
						prompt="Are you sure you want to clear the palette? This cannot be undone."
						open={clearWarnDialogOpen}
						onClose={() => setClearWarnDialogOpen(false)}
						action={() => handlePaletteChange({ palette: [] })}
						verb="Clear Palette"
					/>
				</PalettesContainer>
			</ComponentContainer>
			{screen_downSm && <MobileButtons buttonAttr={buttonAttr} />}
		</>
	);
});

const paletteItemShape = PropTypes.shape({
	hex: PropTypes.string.isRequired,
	uuid: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
});

const paletteShape = PropTypes.shape({
	name: PropTypes.string.isRequired,
	type: PropTypes.oneOf([PLATFORM, INSIGHTS]).isRequired,
	uuid: PropTypes.string.isRequired,
	palette: PropTypes.arrayOf(paletteItemShape).isRequired,
	status: PropTypes.oneOf([ACTIVE, ARCHIVED]),
});

PaletteManager.propTypes = {
	editing: PropTypes.oneOf([PLATFORM, ORGANISATION]),
	palettes: PropTypes.arrayOf(paletteShape).isRequired,
	onChangePalettes: PropTypes.func.isRequired,
	setShowArchived: PropTypes.func,
	showArchived: PropTypes.bool,
};

PaletteManager.displayName = 'PaletteManager';

export { PaletteManager };
