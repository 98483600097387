import { useCallback } from 'react';

import { Check as TrueIcon, Close as FalseIcon } from '@mui/icons-material';
import Color from 'color';
import { styled } from '@mui/material';

import {
	BOOLEAN,
	DATE,
	NUMBER,
	STRING,
} from '../../../../utils/commonDataTypes';
import { useTimeFormatter } from '../../../../hooks/useTimeFormat';

const BoolContainer = styled('div')(() => ({
	alignItems: 'center',
	display: 'flex',
	minHeight: '100%',
}));

const commonBoolStyles = {
	alignItems: 'center',
	borderRadius: '50%',
	display: 'flex',
	marginRight: 4,

	'& *': {
		fontSize: 16,
	},
};

const False = styled('span')(({ theme }) => ({
	...commonBoolStyles,
	backgroundColor: Color(theme.palette.indicators.error.main)
		.alpha(0.1)
		.toString(),
	color: theme.palette.indicators.error.text,
}));

const True = styled('span')(({ theme }) => ({
	...commonBoolStyles,
	backgroundColor: Color(theme.palette.indicators.success.main)
		.alpha(0.1)
		.toString(),
	color: theme.palette.indicators.success.text,
}));

export function useCellFormatter() {
	const formatDate = useTimeFormatter();

	return useCallback(
		(type, value) => {
			if (!type || type === STRING || type === NUMBER) return value;

			if (type === BOOLEAN) {
				return (
					<BoolContainer title={value ? 'true' : 'false'}>
						{value ? (
							<>
								<True>
									<TrueIcon />
								</True>
								<span>Yes</span>
							</>
						) : (
							<>
								<False>
									<FalseIcon />
								</False>
								<span>No</span>
							</>
						)}
					</BoolContainer>
				);
			}

			if (type === DATE) {
				return formatDate(value, true, true);
			}

			return value;
		},
		[formatDate]
	);
}

export default useCellFormatter;
