import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const Container = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	gap: '20px',
	width: '100%',

	[theme.breakpoints.down('md')]: {
		flexDirection: 'column',
	},
}));

export default function BtFormLateralContainer({ children, ...props }) {
	return <Container {...props}>{children}</Container>;
}

BtFormLateralContainer.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};
