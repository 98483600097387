import { useCallback, useEffect, useMemo, useState } from 'react';
import { orgDetailsGet } from '../../../../../API/orgDetails';
import {
	insightCollectionGet,
	insightCollectionGetDraft,
	insightCollectionGetVersion,
} from '../../../../../API';
import { useParams } from 'react-router';
import { useLocation } from 'react-router';

function useInsightPaletteConfig(collectionUuid) {
	const [loading, setLoading] = useState(true);
	const [palette, setPalette] = useState(null);
	const [collectionPalettes, setCollectionPalettes] = useState(null);
	const [error, setError] = useState(false);
	const { id, version } = useParams();
	const location = useLocation();

	// check the url to see if we are working with the draft
	const isDraft = useMemo(
		() => {
			const checkLocation = () => {
				const pathParts = location.pathname.split('/');

				return ['Edit', 'Pages'].every(check => {
					return pathParts.includes(check);
				});
			};

			return checkLocation();
		},
		[location.pathname]
	);

	const isVersion = useMemo(
		() => {
			if (version === undefined) {
				return false;
			} else {
				return true;
			}
		},
		[version]
	);

	// retrieve the paletteConfig from the back end,
	// using different endpoints depending on if we are viewing:
	// the draft, a version or the current version
	const getPaletteConfig = useCallback(
		async () => {
			let collection;
			try {
				if (isDraft) {
					collection = await insightCollectionGetDraft({
						insightUuid: id,
					});
				} else if (isVersion) {
					collection = await insightCollectionGetVersion({
						insightUuid: id,
						versionIndex: version,
					});
				} else {
					collection = await insightCollectionGet({
						insightUuid: collectionUuid ? collectionUuid : id,
					});
				}

				return collection.palette_config;
			} catch (error) {
				console.error(error);
				setError(true);
			}
		},
		[collectionUuid, id, isDraft, isVersion, version]
	);

	const getOrgTheme = useCallback(async () => {
		let orgDetails;
		try {
			orgDetails = await orgDetailsGet();
			return orgDetails.theme.palettes;
		} catch (error) {
			console.error(error);
			setError(true);
		}
	}, []);

	// const resolveTaggedColours = useCallback((_paletteConfig, orgPalettes) => {
	// 	console.log({ _paletteConfig, orgPalettes });

	// 	const taggedColours = {};

	// 	const taggedColour = { colour: '', tags: [] };

	// 	_paletteConfig.forEach(config => {
	// 		const palette = orgPalettes.find(
	// 			({ uuid }) => config.uuid === uuid
	// 		);
	// 		if (config.colours) {
	// 			config.colours.forEach(colour => {
	// 				console.log({ colour });
	// 				colour.tags.forEach(tag => {
	// 					taggedColours[tag] = palette.palette.find(
	// 						({ uuid }) => uuid === colour.uuid
	// 					).hex;
	// 				});
	// 			});
	// 		}

	// 		console.log({ taggedColours });
	// 	});
	// }, []);

	const getPalette = useCallback(
		async () => {
			// get the org palettes
			const orgPalettes = await getOrgTheme();

			console.log({ orgPalettes });

			let _paletteConfig;
			let _palette;
			// let _taggedColours;
			let _collectionPalettes;

			try {
				// if we have a uuid in the url get the collection and
				// find the first org palette which is defined in the paletteConfig
				// if not, use the default org palette
				if (id) {
					_paletteConfig = await getPaletteConfig();
					// get the palette from the org palettes - this was a temporary solution
					// TODO - this is to be superseded by the collection palettes and paletteConfig being passed into
					// the component then into a resolver function to build the visualisation specific palette
					_palette = orgPalettes
						.find(({ uuid }) => _paletteConfig[0].uuid === uuid)
						.palette.map(({ hex }) => hex);

					// get the collection palettes from the org palettes
					_collectionPalettes = _paletteConfig.map(palette => {
						return orgPalettes.find(
							({ uuid }) => uuid === palette.uuid
						);
					});
					// _taggedColours = resolveTaggedColours(
					// 	_paletteConfig,
					// 	orgPalettes
					// );
				} else {
					_palette = orgPalettes[0].palette.map(({ hex }) => hex);
				}
			} catch (error) {
				_palette = orgPalettes[0].palette.map(({ hex }) => hex);
				console.log(error);
			}

			setCollectionPalettes(_collectionPalettes);
			setPalette(_palette);
			setLoading(false);
		},
		[getOrgTheme, getPaletteConfig, id]
	);

	useEffect(() => getPalette(), [getPalette]);

	return { collectionPalettes, palette, loading, error, getPalette };
}

export default useInsightPaletteConfig;
