const { OPERATION_MODE } = require('../../expression');
const {
	setOperatorSchemaFn,
	evaluateExpressionSchema,
} = require('../expression');
const { getType, newDefaultSchema } = require('../utils');

const operatorKey = '$add';

setOperatorSchemaFn(
	operatorKey,
	(context, args, options) => {
		const types = args.map((arg) =>
			getType(evaluateExpressionSchema(context, arg))
		);

		if (types.find((s) => s === 'date')) {
			return newDefaultSchema('date');
		}

		return newDefaultSchema('number');
	},
	[OPERATION_MODE.AGGREGATE]
);
