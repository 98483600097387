const yup = require('yup');

const userAdminGetListSchema = yup.array().of(
	yup.object({
		uuid: yup
			.string()
			.uuid()
			.required(),
		user_name: yup.string().required(),
		first_name: yup.string().required(),
		last_name: yup.string().required(),
		email: yup
			.string()
			.email()
			.required(),
		initials: yup.string().required(),
		avatar: yup.string().nullable(),
		last_login_timestamp: yup.number().required(),
		status: yup.string().required(),
		tags: yup
			.array()
			.of(
				yup.object({
					tagGroupUuid: yup
						.string()
						.uuid()
						.required(),
					tagValueUuid: yup
						.string()
						.uuid()
						.required(),
					name: yup.string().required(),
				})
			)
			.default([]),
	})
);

const userAdminGetUserSchema = yup.object({
	uuid: yup
		.string()
		.uuid()
		.required(),
	user_name: yup.string().required(),
	first_name: yup.string().required(),
	last_name: yup.string().required(),
	email: yup
		.string()
		.email()
		.required(),
	last_login_timestamp: yup.number().required(),
	status: yup.string().required(),
	tags: yup
		.array()
		.of(
			yup.object({
				tagGroupUuid: yup
					.string()
					.uuid()
					.required(),
				tagValueUuid: yup
					.string()
					.uuid()
					.required(),
				name: yup.string().required(),
			})
		)
		.default([]),
});

const userAdminPostSchema = yup.object().shape({
	email: yup // !!! TODO: change to check email format !!!
		.string()
		.email()
		.required(),
});

module.exports = {
	userAdminGetListSchema,
	userAdminGetUserSchema,
	userAdminPostSchema,
};
