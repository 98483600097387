import _ from 'lodash';

import { LOOSE, STANDARD, STRICT } from '../constants';
import { NUMBER, STRING } from '../../../../utils/commonDataTypes';

const boolStrings = { yes: true, true: true, no: false, false: false };

// Performs fuzziness checks and analyses specific
// differences between two strings.
const analyseLoosely = (stringA = '', stringB = '') => {
	const aLength = stringA.length,
		bLength = stringB.length;

	// Initialise an empty matrix
	const matrix = Array(bLength + 1)
		.fill(null)
		.map(() => Array(aLength + 1).fill(null));

	// Create the initial ascending limit edit distances
	for (let aPos = 0; aPos <= aLength; aPos += 1) {
		matrix[0][aPos] = aPos;
	}

	for (let bPos = 0; bPos <= bLength; bPos += 1) {
		matrix[bPos][0] = bPos;
	}

	// Calculate edit distances and populate the matrix
	const { min } = Math;

	const calculateEditDistances = bPos => {
		for (let aPos = 1; aPos <= aLength; aPos += 1) {
			const indicator = stringA[aPos - 1] === stringB[bPos - 1] ? 0 : 1;

			matrix[bPos][aPos] = min(
				matrix[bPos][aPos - 1] + 1,
				matrix[bPos - 1][aPos] + 1,
				matrix[bPos - 1][aPos - 1] + indicator
			);
		}
	};

	for (let bPos = 1; bPos <= bLength; bPos += 1) {
		calculateEditDistances(bPos);
	}

	// Assess the distance matrix and determine
	// Concurrency (2 or more matching concurrent strings),
	// Distance and the number of Leading and Trailing chars.
	let aPos = aLength,
		bPos = bLength,
		concurrency = false,
		lockedOn = false,
		matchTrack = 0,
		step,
		trailing = 0;

	while (aPos > 0 && bPos > 0) {
		step = Math.min(
			matrix[bPos - 1][aPos - 1],
			matrix[bPos - 1][aPos],
			matrix[bPos][aPos - 1]
		);

		if (matrix[bPos][aPos] === step) {
			aPos--;
			bPos--;
			matchTrack++;
			lockedOn = true;

			if (matchTrack > 1) {
				concurrency = true;
			}
		} else {
			matchTrack = 0;

			if (matrix[bPos - 1][aPos] === step) {
				bPos--;

				if (!lockedOn) {
					trailing++;
				}
			} else if (matrix[bPos][aPos - 1] === step) {
				aPos--;
			} else {
				aPos--;
				bPos--;
			}
		}
	}

	return {
		concurrency,
		distance: matrix[bLength][aLength],
		leading: bPos,
		trailing,
	};
};

// Ensure the term can be comparable with the respective value
const transformTerm = (term, value) => {
	const bool = boolStrings[term];

	if (bool !== undefined) {
		return bool;
	}

	if (typeof value === NUMBER) {
		return Number(term);
	}

	return term;
};

// Trims string values to match partial terms immediately
const transformValue = (value, term) => {
	if (typeof value === STRING) {
		return value.slice(0, term.length);
	}

	return value;
};

// Transforms term and values for neatness
const transformArgs = (term, value) => [
	transformTerm(term, value),
	transformValue(value, term),
];

const normaliseString = string => {
	if (typeof string === STRING) {
		return string.toLowerCase().trim();
	}

	return string;
};

const normaliseStrings = strings => {
	return strings.map(normaliseString);
};

// Functions for determining whether terms and values match
const matchers = {
	[LOOSE]: (term, value, editDistance) => {
		const [normalisedTerm, normalisedValue] = normaliseStrings([
			term,
			value,
		]);

		const _term = transformTerm(normalisedTerm, normalisedValue);

		if (typeof _term === STRING && typeof normalisedValue === STRING) {
			const { concurrency, distance, leading, trailing } = analyseLoosely(
				_term,
				normalisedValue
			);

			return (
				distance - (leading + trailing) <= editDistance &&
				(concurrency || _term.length < 2)
			);
		}

		return _.isEqual(_term, normalisedValue);
	},
	[STANDARD]: (term, value) => {
		const [normalisedTerm, normalisedValue] = normaliseStrings([
			term,
			value,
		]);

		const [_term, _value] = transformArgs(normalisedTerm, normalisedValue);
		return _.isEqual(_term, _value);
	},
	[STRICT]: (term, value) => {
		const [_term, _value] = transformArgs(term, value);

		return _.isEqual(_term, _value);
	},
};

// Runs through all searchable fields to see if any match
const analyseRow = ({ boolCondition, data, editDistance, exactness, term }) => {
	const { field: boolField, value: boolValue } = boolCondition || {};

	if (boolField && data[boolField] === boolValue) {
		return true;
	}

	// Unless using strict, separate each term word into a single
	// search, but unify the results to determine a hit
	const splitTerms =
		exactness === STRICT ? [term] : term.split(' ').filter(t => t !== '');

	return splitTerms.every(splitTerm => {
		return Object.keys(data).some(key => {
			const value = data[key];

			return (
				// Check bool condition
				((key === boolField &&
					!_.isNil(boolValue) &&
					value === boolValue) ||
					(() => {
						// If the value is a string, split on the spaces and perform
						// individual match checks for each word

						if (typeof value === STRING) {
							const splitStrings = value
								.split(' ')
								.filter(t => t !== '');

							return splitStrings.some(string =>
								matchers[exactness](
									splitTerm,
									string,
									editDistance
								)
							);
						}

						// Catch invalid values for matching
						if (isNaN(value)) {
							return false;
						}

						// Otherwise, perform a single general match check
						return matchers[exactness](
							splitTerm,
							value,
							editDistance
						);
					}))()
			);
		});
	});
};

// Finds the field for the column that's indicative of a bool query
const determineBoolField = (term, exactness, columns, editDistance) =>
	columns.find(({ text }) => matchers[exactness](term, text, editDistance))
		?.field;

// Returns an object with the bool value, with an optional field
const determineBoolCondition = (
	term = '',
	exactness,
	columns,
	editDistance
) => {
	const normalisedTerm = exactness === STRICT ? term : normaliseString(term);

	// Has a boolean term been used? If so, return.
	for (const bool of Object.keys(boolStrings)) {
		if (bool === normalisedTerm) {
			return { value: boolStrings[bool] };
		}
	}

	// Establish a compound bool query
	const statusStrings = {
		'do have': true,
		'do not have': false,
		'dont have': false,
		"don't have": false,
		has: true,
		'has no': false,
		have: true,
		'have no': false,
		is: true,
		'is a': true,
		'is an': true,
		'is not': false,
		isnt: false,
		"isn't": false,
		'is not a': false,
		'isnt a': false,
		"isn't a": false,
		'is not an': false,
		'isnt an': false,
		"isn't an": false,
		not: false,
		'!': false,
	};

	// See if any of the bool status string are present.
	// If so, establish column and combine with column field
	let boolCondition = null;
	let currentStatus = '';

	for (const status of Object.keys(statusStrings)) {
		if (
			normalisedTerm.startsWith(status) &&
			normalisedTerm.length > status.length &&
			status.length >= currentStatus.length
		) {
			currentStatus = status;

			const field = determineBoolField(
				normalisedTerm
					.slice(-(normalisedTerm.length - status.length))
					.trim(),
				exactness,
				columns,
				editDistance
			);

			if (field) {
				boolCondition = {
					field,
					value: statusStrings[status],
				};
			}
		}
	}

	// If a compound bool query was established, return it.
	if (boolCondition) return boolCondition;

	// Check of the name of a field was entered, and return
	// a compound bool query with a value of true
	const field = determineBoolField(
		normalisedTerm,
		exactness,
		columns,
		editDistance
	);

	if (field) {
		return { field, value: true };
	}

	// If a field could not be found, then a compound bool
	// query cannot be established - no bool query active
	return null;
};

export default function searchFunc({
	columns,
	data,
	editDistance = 1,
	exactness = STANDARD,
	idKey,
	searchColumn,
	term,
}) {
	const boolCondition =
		term.length > 2
			? determineBoolCondition(term, exactness, columns, editDistance)
			: null;

	// Filters out any rows that are not a hit
	return data.filter(row => {
		const rowData = searchColumn
			? { [searchColumn]: row[searchColumn] }
			: row;

		return analyseRow({
			boolCondition,
			columns,
			data: _.omit(rowData, [idKey]),
			editDistance,
			exactness,
			term,
		});
	});
}
