import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
	buttonClasses,
	List,
	ListItemButton,
	ListItemText,
	Typography,
} from '@mui/material';
import { ScreenDistributionWizardContext } from './ScreenDistributionWizardContext';
import { CheckBold } from 'mdi-material-ui';

const Container = styled('div')(({ theme }) => ({
	width: '100%',
	'&:not(:last-of-type)': {
		marginBottom: '1.5em',
	},
	[theme.breakpoints.down('xs')]: {
		flexDirection: 'column-reverse',
	},
}));

const HeadingContainer = styled('div')(({ theme }) => ({
	alignItems: 'center',
	position: 'sticky',
	top: 0,
	width: '100%',
	zIndex: 10,
	transform: 'translateY(-1px)',

	'& > h6': {
		width: '100%',
		background: theme.palette.background.paper,
		paddingTop: 1,
	},
}));

const Fade = styled('div')(({ theme }) => ({
	height: 8,
	width: '100%',
	transform: 'translateY(-1px)',
	backgroundImage: 'none',
	background: `linear-gradient(0deg, ${
		theme.palette.background.paper
	}00 0%, ${theme.palette.background.paper} 33%, ${
		theme.palette.background.paper
	} 100%)`,
}));

const ListButton = styled(ListItemButton)(
	({ added, editmode, selected, theme }) => ({
		borderLeft: selected
			? `3px solid ${theme.palette.primary.main}`
			: 'none',
		backgroundColor: selected ? theme.palette.primary.main + '33' : 'none',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		transition: theme.transitions.create(['border-left'], {
			duration: '0.15s',
		}),

		'& .editChip': {
			display: added && selected && !editmode ? 'inherit' : 'none',
		},

		'&:hover .editChip': {
			display: added && !editmode ? 'inherit' : 'none',
		},
	})
);

export function ScreenDistributionGroup({
	activeRef,
	type,
	onTargetSelect,
	isSelected,
	disabled,
}) {
	const { data } = useContext(ScreenDistributionWizardContext);

	const sortedTargets = useMemo(
		() =>
			(type?.targets || []).sort((a, b) =>
				a['name'].localeCompare(b['name'])
			),
		[type]
	);

	return (
		<Container>
			<HeadingContainer>
				<Typography
					variant="subtitle2"
					style={{
						fontWeight: 'bold',
					}}
				>
					{type.name}
				</Typography>
				<Fade />
			</HeadingContainer>
			<List
				component="nav"
				aria-label="secondary mailbox folder"
				sx={{
					padding: 0,
					[`&& .${buttonClasses.disabled}`]: {
						opacity: 1,
					},
				}}
			>
				{sortedTargets.map(target => {
					const selected = isSelected(target.uuid, data, type.id);

					return (
						<ListButton
							ref={selected ? activeRef : null}
							disabled={disabled}
							key={target.name}
							selected={selected}
							onClick={() =>
								onTargetSelect?.(target.uuid, type.id)
							}
						>
							<ListItemText
								primary={
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											flexWrap: 'wrap',
										}}
									>
										<Typography
											style={{
												wordBreak: 'break-word',
												opacity: disabled ? 0.8 : 1,
											}}
										>
											{target.name}
										</Typography>
									</div>
								}
							/>
							{selected && (
								<CheckBold fontSize="large" color="primary" />
							)}
						</ListButton>
					);
				})}
			</List>
		</Container>
	);
}

ScreenDistributionGroup.propTypes = {
	activeRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
	]),
	type: PropTypes.object.isRequired,
	onTargetSelect: PropTypes.func.isRequired,
	isSelected: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};
