import PropTypes from 'prop-types';

export const historyPropType = PropTypes.arrayOf(
	PropTypes.shape({
		config: PropTypes.object.isRequired,
		selection: PropTypes.string,
		schema: PropTypes.array,
		updateType: PropTypes.string,
		updateData: PropTypes.object,
		uuid: PropTypes.string.isRequired,
	})
);
