import { useMemo } from 'react';

import { transformAll } from '../../../../../utils/yup-ast';

import components from '../config/workflowComponents';
import { componentTypeExists } from '../config/workflowComponents';
import { injectValidation } from '../utils/validationInjection';

export const useWorkflowValidation = workflowChildren =>
	useMemo(
		() => {
			const componentValidation =
				workflowChildren
					?.filter(({ type }) => components[type]?.validatable)
					?.reduce(
						(
							accumulator,
							{
								hidden,
								name,
								readOnly,
								required,
								type,
								uuid,
								validation,
							}
						) => {
							if (!componentTypeExists(type)) return accumulator;

							if (hidden || readOnly) {
								return {
									...accumulator,
									[uuid]: [
										['yup.mixed'],
										['yup.nullable'],
										['yup.optional'],
									],
								};
							}

							const labelRule = (validation || []).find(val => {
								if (val?.length === 0) return false;

								return val[0] === 'yup.label';
							});

							const valWithLabel = !labelRule
								? [...validation, ['yup.label', name]]
								: validation;

							const injectedVal = injectValidation(
								valWithLabel,
								type,
								required
							);

							return {
								...accumulator,
								[uuid]: injectedVal,
							};
						},
						{}
					) || {};

			return transformAll([
				['yup.object'],
				['yup.shape', componentValidation],
				['yup.required'],
			]);
		},
		[workflowChildren]
	);

export default useWorkflowValidation;
