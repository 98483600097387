import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useThemeContext } from '../../../../../context/ThemeContext';
import { styled } from '@mui/material';
import BtImageDialog from '../../../../../components/generic/BtImageDialog';

const Content = styled('button')(() => ({
	alignSelf: 'flex-start',
	background: 'none',
	border: 'none',
	boxSizing: 'border-box',
	height: 80,
	outline: 'none',
	padding: '0 50px 0 0',
	position: 'relative',
	width: 160,
}));

const PreviewImage = styled('img')(() => ({
	backgroundRepeat: 'no-repeat',
	display: 'block',
	height: '100%',
	objectFit: 'contain',
	width: '100%',
}));

export default function WorkflowImageResult({
	imageProps,
	isSignature,
	value,
	...otherProps
}) {
	const { theme } = useThemeContext();

	const [showViewer, setShowViewer] = useState(false);

	const imageFilter =
		isSignature && theme.palette.mode === 'dark'
			? { filter: 'invert(1)' }
			: {};

	return (
		<>
			<Content onClick={() => setShowViewer(true)} {...otherProps}>
				<PreviewImage src={value} style={imageFilter} {...imageProps} />
			</Content>
			<BtImageDialog
				imageString={value}
				imageStyle={{
					...(theme.palette.mode === 'light'
						? { backgroundColor: '#fff' }
						: {}),
					...imageFilter,
				}}
				onClose={() => setShowViewer(false)}
				open={showViewer}
			/>
		</>
	);
}

WorkflowImageResult.propTypes = {
	imageProps: PropTypes.object,
	isSignature: PropTypes.bool,
	value: PropTypes.string,
};
