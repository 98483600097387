import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { commonRangesData } from '../../../utils/TimeControl/TimeRangeUtilConstants';
import { buildTimeRangeObject } from '../../../utils/TimeControl/TimeRangeUtilFunctions';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import BtFilters from '../../../components/generic/filters/BtFilters';
import { useDataProviderFilters } from '../../../context/DataProvider/DataProvider';
import { styled } from '@mui/styles';
import { Box } from '@mui/material';

const ContainerBox = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	zIndex: 100,
	position: 'sticky',
	top: 65,
}));

const InsightFilters = ({ insightCollection }) => {
	const [insightFilters, insightFiltersSet] = useState([]);
	const [predefinedFilter, setPredefinedFilters] = useState([]);

	// TODO: Could we get the filter bar to give us a default time range?
	// const DATE = new Date();
	// // TODO: why is the held in state??
	// const [defaultTimeRange, setDefaultTimeRange] = useState({
	// 	interval: {
	// 		label: '1 day',
	// 		duration: 1,
	// 		unit: 'days',
	// 		tense: 'last',
	// 		whole: false,
	// 	},
	// 	start: sub(DATE, {
	// 		days: 1,
	// 	}).getTime(),
	// 	end: DATE.getTime(),
	// 	realTime: false,

	// 	// rangeType: 'relative',
	// });

	const {
		filterOptions,
		filtersLoading,
		activeFilters,
		filtersError,
		requestFilterSub,
		setFilters,
		setTimeRange,
		timeRange,
	} = useDataProviderFilters();

	useEffect(
		() => {
			const onLoad = async () => {
				// console.log('insightCollection', insightCollection);

				const timeRangeConfig = _.find(commonRangesData, {
					id: insightCollection.initialTimeRange?.value,
				});
				const defaultTimeRange = await buildTimeRangeObject({
					...timeRangeConfig,
				});
				//setTimeRange(timeRange);

				if (insightCollection.datasources.length > 0) {
					requestFilterSub(
						insightCollection.datasources.map(dataSource => {
							return dataSource.uuid;
						}),
						defaultTimeRange,
						insightFilters
					);
				}
			};

			if (insightCollection) {
				onLoad();

				const newPredefinedFilters = [];
				(insightCollection?.datasources || []).forEach(dataSource => {
					(dataSource?.predefinedFilters || []).forEach(
						predefinedFilter => {
							newPredefinedFilters.push({
								...predefinedFilter,
								dataViewUuid: dataSource.uuid,
							});
						}
					);
				});
				setPredefinedFilters(newPredefinedFilters);
			}
		},
		[insightCollection]
	);

	const handleFilterChange = filterUpdate => {
		// console.log('handleFilterChange', filterUpdate);
		setFilters(filterUpdate);
	};

	const handleTimeRangeChange = timeRangeUpdate => {
		// console.log('setTimeRange', timeRangeUpdate);
		setTimeRange(timeRangeUpdate);
	};

	// useEffect(
	// 	() => {
	// 		if (activeFilters) {
	// 			console.log('activeFilters', activeFilters);
	// 		}
	// 	},
	// 	[activeFilters]
	// );

	return (
		<ContainerBox>
			<BtFilters
				timeRange={timeRange}
				filters={activeFilters}
				filterOptions={filterOptions}
				predefinedFilters={predefinedFilter}
				onChangeFilters={handleFilterChange}
				onChangeTimeRange={handleTimeRangeChange}
				insightsChips
			/>
		</ContainerBox>
	);
};

InsightFilters.propTypes = {
	insightCollection: PropTypes.object,
};

export { InsightFilters };
