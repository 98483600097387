const { OPERATION_MODE } = require('../../expression');
const { evaluateExpressionSchema } = require('../expression');
const { setStageSchemaFn } = require('../pipeline');
const { getObjectShape, newDefaultSchema } = require('../utils');

const stageKey = '$group';

setStageSchemaFn(stageKey, (context, args, options) => {
	const { _id, ...rest } = args;

	const _idObj = evaluateExpressionSchema(
		{ ...context, operationMode: OPERATION_MODE.AGGREGATE },
		_id
	);

	const newObj = evaluateExpressionSchema(
		{ ...context, operationMode: OPERATION_MODE.ACCUMULATE },
		rest
	);

	const newObjShape = getObjectShape(newObj);

	return newDefaultSchema('object', {
		shape: { ...newObjShape, _id: _idObj },
	});
});
