import { useMemo } from 'react';

import { paginationFunc, searchFunc, sortingFunc } from '../funcs';
import { STANDARD } from '../constants';

export const useTableData = ({
	columns = [],
	data = [],
	enablePagination,
	idKey,
	offset,
	pageData,
	pageSize,
	paginationFunc: suppliedPaginationFunc,
	searchableColumns,
	searchColumn,
	searchExactness = STANDARD,
	searchFunc: suppliedSearchFunc,
	searchTerm = '',
	sort,
	sortingFunc: suppliedSortingFunc,
	recordCount,
}) => {
	return useMemo(
		() => {
			// if (pageData) return pageData;

			if (pageData) return { pageData: pageData, recordCount };

			const _paginationFunc = suppliedPaginationFunc ?? paginationFunc;
			const _searchFunc = suppliedSearchFunc ?? searchFunc;
			const _sortingFunc = suppliedSortingFunc ?? sortingFunc;

			const searchResultData = searchTerm
				? _searchFunc({
						columns: searchableColumns,
						data: [...data],
						exactness: searchExactness ?? STANDARD,
						idKey,
						searchColumn,
						term: searchTerm,
				  })
				: [...data];

			let calcedRecordCount = searchResultData.length;

			const sortedData = sort
				? (() => {
						const { order, orderBy } = sort || {};

						const {
							CellContent,
							ordinalValues,
							sortingComparator,
							type,
						} =
							columns.find(({ field }) => field === orderBy) ??
							{};

						return _sortingFunc({
							CellContent,
							comparator: sortingComparator,
							data: searchResultData,
							order,
							orderBy,
							ordinalValues,
							type,
						});
				  })()
				: searchResultData;

			const pagedData = enablePagination
				? _paginationFunc({
						data: searchResultData,
						offset,
						pageSize,
				  })
				: sortedData;

			return { pageData: pagedData, recordCount: calcedRecordCount };
		},
		[
			columns,
			data,
			enablePagination,
			idKey,
			offset,
			pageData,
			pageSize,
			searchableColumns,
			searchColumn,
			searchExactness,
			searchTerm,
			sort,
			suppliedPaginationFunc,
			suppliedSearchFunc,
			suppliedSortingFunc,
			recordCount,
		]
	);
};

export default useTableData;
