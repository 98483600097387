import React from 'react';

// MUI ICONS
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';

const workflows_nav_item = [
	{
		id: 'Workflows',
		text: 'Workflows',
		route: '/Workflows',
		icon: <ViewTimelineIcon />,
		exact: true,
	},
];

export default workflows_nav_item;
