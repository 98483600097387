import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

import { useWatch } from 'react-hook-form';
import { InputController, resolveVisOptionsSchema } from './InputController';

const VIS_OPTIONS = 'vis_options';

export const VisOptionsFormSection = ({ visOption }) => {
	const type = useWatch({ name: 'type' });
	const fields = useMemo(
		() => resolveVisOptionsSchema(type)[visOption].fields,
		[type, visOption]
	);
	// console.log({ visOption, type, fields });
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '10px',
			}}
		>
			{Object.keys(fields).map(fieldKey => (
				<InputController
					key={fieldKey}
					label={fieldKey}
					name={`${VIS_OPTIONS}.${visOption}.${fieldKey}`}
					propDetails={{
						type: fields[fieldKey].type,
						oneOf: fields[fieldKey].oneOf,
					}}
				/>
			))}
		</Box>
	);
};

VisOptionsFormSection.propTypes = {
	visOption: PropTypes.string.isRequired,
};
