import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';
import {
	PieChart,
	Pie,
	ResponsiveContainer,
	Cell,
	Legend,
	Tooltip,
} from 'recharts';
import { CustomLegend } from './Generic/Legend';
import { Box } from '@mui/material';
import useResizeObserver from 'use-resize-observer';
import { PIE_CHART } from '../../InsightPage/visualisationConstants';
import {
	DOUGHNUT,
	TWO_SERIES,
} from '../../../../API/validations/insightVisualizationsValidation';
import { resolveAffixes } from './Utils/affixes';
import { CustomTooltip } from './Generic/Tooltip.js';
import _ from 'lodash';
import { useTheme } from '@mui/styles';
import {
	resolvePercentageString,
	resolveValueString,
} from './Utils/valueDecimalPlaces';

export default function PieChartComponent({ data, visOptions, palette }) {
	const [_data, setData] = useState([]);
	const theme = useTheme();
	const containerRef = useRef(null);

	const { height, width } = useResizeObserver({ ref: containerRef });

	// TODO: combine all these useMemo calls into one...
	const isSmall = useMemo(
		() => {
			// console.log({ width, height });
			if (height && width) {
				return height < 180 || width < 180;
			}
			return false;
		},
		[height, width]
	);

	const isTwoSeries = useMemo(
		() => visOptions?.variant?.type === TWO_SERIES || false,
		[visOptions]
	);

	const primaryChartInnerRadius = useMemo(
		() => {
			if (
				visOptions?.variant?.type === DOUGHNUT ||
				visOptions?.variant?.type === TWO_SERIES
			) {
				return '65%';
			} else {
				return 0;
			}
		},
		[visOptions]
	);

	const displayLegend = useMemo(
		() => {
			if (visOptions?.legend?.disabled === false) {
				return true;
			} else {
				return false;
			}
		},
		[visOptions]
	);

	const displayAbsoluteValue = useMemo(
		() => visOptions?.label?.display_absolute_value || false,
		[visOptions]
	);

	const displayPercentValue = useMemo(
		() => visOptions?.label?.display_percentage_value || false,
		[visOptions]
	);

	const displayNameLabel = useMemo(
		() => {
			return visOptions?.label?.display_name || false;
		},
		[visOptions]
	);

	const showLabels = useMemo(() => !visOptions?.label?.disabled || false, [
		visOptions,
	]);

	useEffect(
		() => {
			const formattedData = visOptions?.params.map(param => {
				return { name: param.label, value: data[0][param.value] };
			});
			setData(formattedData);
		},
		[data, visOptions]
	);

	const RADIAN = Math.PI / 180;

	const renderContainedLabel = ({
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		percent,
		index,
		value,
		payload,
	}) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);

		const absoluteValueString = resolveValueString(
			value,
			index,
			visOptions
		);

		const evaluatePercentValueString = percent => {
			const string = resolvePercentageString(percent * 100, visOptions);

			return displayAbsoluteValue ? `(${string})` : string;
		};

		const valuesString = () => {
			if (displayAbsoluteValue && displayPercentValue) {
				return `${absoluteValueString} ${evaluatePercentValueString(
					percent,
					2
				)}`;
			}
			if (displayAbsoluteValue) {
				return absoluteValueString;
			}
			if (displayPercentValue) {
				return evaluatePercentValueString(percent, 2);
			}
		};

		// TODO improve this text by including some overflow control
		if (value) {
			return (
				<g>
					{displayNameLabel && (
						<text
							x={x}
							y={y}
							dy={-2}
							textAnchor={'middle'}
							fill={theme.palette.getContrastText(palette[index])}
						>
							{payload.name}
						</text>
					)}

					<text
						x={x}
						y={y}
						dy={displayNameLabel ? 16 : 0}
						textAnchor={'middle'}
						fill={theme.palette.getContrastText(palette[index])}
					>
						{valuesString()}
					</text>
				</g>
			);
		}
	};

	// This prepares the data used in the tooltip, as reCharts will only provide
	// data for a single series
	const tooltipData = useMemo(
		() => {
			// get the total sum of all values
			const sum = _data
				.map(({ value }) => ({ value: value ? value : 0 }))
				.reduce((n, { value }) => n + value, 0);

			const result = _data.map(({ name, value }, index) => {
				const item = {};
				item.name = name;
				item.color = palette[index];

				if (value) {
					(item.percentageValue = (value / sum) * 100),
						(item.valueString = resolvePercentageString(
							(value / sum) * 100,
							visOptions
						));
				} else {
					item.percentageValue = 0;
					item.valueString = resolvePercentageString(0, visOptions);
				}
				return item;
			});
			return result;
		},
		[_data, palette, visOptions]
	);

	// console.log(tooltipData);

	return (
		<Box
			ref={containerRef}
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				height: '100%',
			}}
		>
			<ResponsiveContainer width="100%" height="100%">
				<PieChart>
					<Pie
						data={_data}
						cx="50%"
						cy="50%"
						innerRadius={primaryChartInnerRadius}
						outerRadius={'90%'}
						fill="#8884d8"
						dataKey="value"
						label={!isSmall && showLabels && renderContainedLabel}
						labelLine={false}
						stroke="none"
					>
						{(visOptions?.params || []).map((param, index) => (
							<Cell
								index={index}
								fill={palette[index]}
								key={index}
								animationDuration={200}
							/>
						))}
					</Pie>
					{isTwoSeries && (
						<Pie
							data={_data}
							cx="50%"
							cy="50%"
							outerRadius={'63%'}
							fill="#8884d8"
							dataKey="value"
							label={!isSmall && renderContainedLabel}
							labelLine={false}
							stroke="none"
						>
							{(visOptions?.params || []).map((param, index) => (
								<Cell
									index={index}
									fill={palette[index]}
									key={index}
									animationDuration={200}
								/>
							))}
						</Pie>
					)}
					{displayLegend && (
						<Legend
							content={
								<CustomLegend
									chartType={PIE_CHART}
									visOptionsParams={visOptions.params}
									containerWidth={
										containerRef.current?.offsetWidth
									}
								/>
							}
						/>
					)}
					<Tooltip
						wrapperStyle={{ outline: 'none' }}
						content={props => (
							<CustomTooltip
								sortValues
								visOptions={visOptions}
								data={tooltipData}
								{...props}
							/>
						)}
					/>
				</PieChart>
			</ResponsiveContainer>
			{/* </div> */}
		</Box>
	);
}

PieChartComponent.propTypes = {
	data: PropTypes.array.isRequired,
	visOptions: PropTypes.object.isRequired,
	palette: PropTypes.array.isRequired,
};
