import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import * as yup from 'yup';
import { Typography } from '@mui/material';
import { useScreensConfigContext } from '../../ScreenConfigContext';
import PositionSelector from '../../../formComponents/PositionSelector';
import TilesTextField from '../../../formComponents/TilesTextField';
import BtColourPicker from '../../../../../BtColourPicker';
import BtRedactor from '../../../../../../BtRedactor';
import { useContext } from 'react';
import { ScreensContext } from '../../../../ScreensContext';

const schema = yup
	.object({
		textField: yup
			.string()
			.required()
			.min(4)
			.max(30)
			.label('Screen name'),
	})
	.required();

export default function NewScreenOptions({ unavailablePositions }) {
	const [isTextFieldValid, setIsTextFieldValid] = useState();
	const {
		setAddNextStepDisabled,
		addScreenParams,
		setAddScreenParams,
		screenCount,
	} = useScreensConfigContext();
	const { userPermissions } = useContext(ScreensContext);
	// state for screen position, initialized with screen-type dependent value
	const [screenPosition, setScreenPosition] = useState(
		addScreenParams.position
	);
	// state for screen name, initialized with screen-type dependent value
	const [textFieldValue, setTextFieldValue] = useState(
		addScreenParams.newScreenName
	);
	const [color, setColor] = useState(addScreenParams.backgroundColor);

	// watch for changes to screen name and update context
	useEffect(
		() => {
			let newParams = { ...addScreenParams };
			newParams.newScreenName = textFieldValue;
			setAddScreenParams(newParams);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[textFieldValue]
	);

	// control state for screen position
	const handleScreenChange = (event, value) => {
		setScreenPosition(value);
	};

	// watch for changes to screen position and update context
	useEffect(
		() => {
			let newParams = { ...addScreenParams };
			newParams.position = screenPosition;
			setAddScreenParams(newParams);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[screenPosition]
	);

	// manage next button state
	useEffect(
		() => {
			if (
				// addScreenParams.addScreenReference?.ownerType === 'Organisation'
				addScreenParams.type === 'addScreen'
			) {
				setAddNextStepDisabled(false);
			} else if (userPermissions.ScreenCreate) {
				setAddNextStepDisabled(!isTextFieldValid);
			} else {
				setAddNextStepDisabled(false);
			}
			// if (userPermissions.ScreenCreate) {
			// 	setAddNextStepDisabled(!isTextFieldValid);
			// } else {
			// 	setAddNextStepDisabled(false);
			// }
		},
		[
			addScreenParams.type,
			isTextFieldValid,
			setAddNextStepDisabled,
			userPermissions.ScreenCreate,
		]
	);

	// watch for changes to screen color and update context
	useEffect(
		() => {
			let newParams = { ...addScreenParams };
			newParams.backgroundColor = color;
			setAddScreenParams(newParams);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[color]
	);

	const isDisabled = useMemo(
		() => {
			if (!addScreenParams.type === 'blank') {
				if (addScreenParams.addScreenReference) {
					return (
						addScreenParams.addScreenReference?.ownerType ===
						'Organisation'
					);
				}
			}
		},
		[addScreenParams]
	);

	const disableTextField = useMemo(
		() => {
			console.log(addScreenParams.type);

			switch (addScreenParams.type) {
				case 'blank':
					return false;
				case 'copy':
					return false;
				case 'addScreen':
					return true;
				// if (
				// 	addScreenParams.addScreenReference?.ownerType ===
				// 	'Organisation'
				// ) {
				// 	console.log(addScreenParams.type);
				// 	return true;
				// } else {
				// 	return false;
				// }

				default:
					break;
			}
		},
		[addScreenParams]
	);

	console.log({ isDisabled });

	return (
		<>
			<div
				style={{
					width: 266,
					marginBottom: 20,
				}}
			>
				{/* <Typography
					style={{
						// marginBottom: 10,
						fontSize: '1.0542rem',
						// color: optionsDisabled && 'rgba(0, 0, 0, 0.5)',
					}}
				>
					Background colour
				</Typography>
				<BtColourPicker
					// disabled={optionsDisabled}
					errorText={null}
					// label="Change background colour"
					onChange={setColor}
					showNoneOption={true}
					value={color}
				/> */}
			</div>

			<PositionSelector
				value={screenPosition}
				screenCount={screenCount + 1}
				onChange={handleScreenChange}
				disabled={isDisabled}
				unavailablePositions={unavailablePositions}
				label={
					isDisabled
						? 'Position is managed by your organisation'
						: 'Select position'
				}
			/>
			<BtRedactor requiredPermissionsAll={{ screens: ['ScreenCreate'] }}>
				{!disableTextField && (
					<TilesTextField
						disabled={disableTextField}
						setIsTextFieldValid={setIsTextFieldValid}
						onChange={setTextFieldValue}
						initialValue={textFieldValue || ''}
						schema={schema}
						label={
							disableTextField
								? 'Name is managed by your organisation'
								: 'Choose a name'
						}
					/>
				)}
			</BtRedactor>
		</>
	);
}

NewScreenOptions.propTypes = {
	unavailablePositions: PropTypes.arrayOf(PropTypes.number).isRequired,
};
