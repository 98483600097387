const {
	ERROR,
	setOperator,
	evaluateExpression,
	OPERATION_MODE,
} = require('../expression.js');

const operatorKey = '$regex';

setOperator(
	operatorKey,
	/**
	 * @type {ExpressionFunction< any, boolean, { $options: string; }>}
	 */
	(context, args, options) => {
		const arr = !Array.isArray(args) ? [args] : args;

		if (arr.length !== 2) {
			throw new Error(ERROR.INVALID_NUMBER_ARGS(operatorKey, 2, 2));
		}

		const value = evaluateExpression(context, arr[0]);

		const regexThing = arr[1];

		const flags = options.$options;

		const expr = new RegExp(regexThing, flags);

		return expr.test(value);
	},

	[OPERATION_MODE.AGGREGATE, OPERATION_MODE.QUERY]
);
