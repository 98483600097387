import { uuidRegex } from './utils';
import { apiClient } from './api_client';

import {
	userAdminGetListSchema,
	userAdminGetUserSchema,
	userAdminPostSchema,
} from './validations/userAdminValidation';
import serverRequest from './serverRequest';

//========================================================================================================
async function userAdminPost(userData) {
	await userAdminPostSchema.validate(userData);

	console.log(userData);
	try {
		let response = await apiClient({
			method: 'post',
			url: 'user_admin',
			body: userData,
		});

		if (response) {
			if (response.result === 'OK') {
				return response;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function userAdminGetList({ pageData }) {
	return await serverRequest({
		method: 'get',
		url: 'user_admin',
		params: pageData,
		responseSchema: userAdminGetListSchema,
		resDataProp: 'users',
	});
}

//========================================================================================================
async function userAdminGetUser({ userUuid }) {
	if (!uuidRegex.test(userUuid)) {
		throw new Error('Param Error');
	}
	const req_url = `user_admin/${userUuid}`;

	if (!uuidRegex.test(userUuid)) {
		throw new Error('Param Error');
	}

	try {
		const response = await apiClient({ method: 'get', url: req_url });

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let user = await userAdminGetUserSchema.validate(response.user);
				return user;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function userAdminPasswordReset({ userUuid }) {
	if (!uuidRegex.test(userUuid)) {
		throw new Error('Param Error');
	}
	const req_url = `user_admin/${userUuid}/password_reset`;

	if (!uuidRegex.test(userUuid)) {
		throw new Error('Param Error');
	}

	try {
		const response = await apiClient({ method: 'get', url: req_url });

		if (response) {
			if (response.result !== 'OK') {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function userAdminDelete({ userUuid }) {
	if (!uuidRegex.test(userUuid)) {
		throw new Error('Param Error');
	}
	const req_url = `user_admin/${userUuid}`;

	if (!uuidRegex.test(userUuid)) {
		throw new Error('Param Error');
	}

	try {
		const response = await apiClient({ method: 'delete', url: req_url });

		if (response) {
			if (response.result !== 'OK') {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

export {
	userAdminPost,
	userAdminGetList,
	userAdminGetUser,
	userAdminPasswordReset,
	userAdminDelete,
};
