import React, { useState, useCallback, useEffect } from 'react';
import { useNavContext, useAppContext } from '../../context/ContextManager';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import {
	BtForm,
	BtFormActionButtons,
	BtFormActionsContainer,
	BtFormContent,
	BtFormLateralContainer,
	BtFormTextField,
} from '../../components/generic/forms';
import {
	initials_regex,
	uk_mobile_regex,
	user_names_regex,
	user_name_regex,
} from '../../utils/regexLibrary';
import { userPut } from '../../API';
import FormContainer from './FormContainer';
import user_settings_options from './UserSettingsOptions';
import { user_settings_nav_item } from './UserSettingsNavItem';

export default function UpdateDetails() {
	const { setUserInfo } = useAppContext();
	const { userInfo } = useAppContext();
	const { enqueueSnackbar } = useSnackbar();
	const [sending, setSending] = useState(false);
	const { setBreadcrumbs, setContextualNav } = useNavContext();

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'User Settings', link: '/UserSettings' },
				{ text: 'Update Details', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	useEffect(
		() => {
			setContextualNav([
				...user_settings_nav_item,
				...user_settings_options,
			]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	const schema = yup.object({
		firstName: yup
			.string()
			.required()
			.min(2)
			.max(30)
			.matches(user_names_regex.regex, user_names_regex.error_text, {
				excludeEmptyString: true,
			})
			.label('First name'),
		lastName: yup
			.string()
			.min(2)
			.max(30)
			.required()
			.matches(user_names_regex.regex, user_names_regex.error_text, {
				excludeEmptyString: true,
			})
			.label('Last name'),
		userName: yup
			.string()
			.min(3)
			.max(20)
			.required()
			.matches(user_name_regex.regex, user_name_regex.error_text, {
				excludeEmptyString: true,
			})
			.label('Display name'),
		initials: yup
			.string()
			.min(2)
			.max(2)
			.required()
			.matches(initials_regex.regex, initials_regex.error_text, {
				excludeEmptyString: true,
			})
			.label('Initials'),
		// This dummy value is used to trigger the following conditional assessment of the mobile required value in the user record
		dummy: yup.string(),
		mobilePhone: yup
			.string()
			.when('dummy', () => {
				if (!userInfo) {
					return yup.string().required();
				} else if (userInfo.mobile_required === true) {
					return yup
						.string()
						.required()
						.matches(
							uk_mobile_regex.regex,
							uk_mobile_regex.error_text,
							{
								excludeEmptyString: true,
							}
						)
						.label('Mobile number');
				} else {
					return yup
						.string()
						.optional()
						.matches(
							uk_mobile_regex.regex,
							uk_mobile_regex.error_text,
							{
								excludeEmptyString: true,
							}
						)
						.label('Mobile number')
						.nullable();
				}
			})
			.matches(uk_mobile_regex.regex, uk_mobile_regex.error_text, {
				excludeEmptyString: true,
			})
			.label('Mobile number'),
		email: yup
			.string()
			.email()
			.required()
			.label('Email Address, you cannot change this here'),
	});

	const defaultValues = {
		firstName: userInfo.first_name,
		lastName: userInfo.last_name,
		email: userInfo.email,
		userName: userInfo.user_name,
		initials: userInfo.initials,
		mobilePhone: userInfo.mobile_phone,
	};

	const handleSubmit = useCallback(
		async (values, reset) => {
			// Setup the PUT request data with the form values
			const {
				firstName,
				lastName,
				userName,
				initials,
				mobilePhone,
			} = values;

			const user_data = {
				userData: {
					first_name: firstName,
					last_name: lastName,
					user_name: userName,
					initials: initials,
					mobile_phone: mobilePhone,
					status: 'Active',
				},
			};
			if (userInfo.mobile_required) {
				user_data.userData.mobile_phone = mobilePhone;
			}

			console.log({ user_data, userInfo });
			try {
				setSending(true);
				await userPut(user_data);
				reset(values);

				enqueueSnackbar('Details updated', {
					variant: 'success',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
				});
				// This could be set to null to force context manager to trigger user get
				setUserInfo({
					...userInfo,
					status: 'Active',
					first_name: firstName,
					last_name: lastName,
					user_name: userName,
					initials: initials,
					mobile_phone: mobilePhone,
				});
				setSending(false);
				// history.push('/');
			} catch (error) {
				enqueueSnackbar('Something went wrong, please try again.', {
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
				});
				// props.changeView('Login');
				console.error(error);
				setSending(false);
			} finally {
			}
		},
		[enqueueSnackbar, setUserInfo, userInfo]
	);

	const UserDetailsForm = () => {
		return (
			<BtForm
				defaultValues={defaultValues}
				validationSchema={schema}
				sending={sending}
				onSubmit={handleSubmit}
			>
				<BtFormContent>
					<BtFormLateralContainer>
						<BtFormTextField name="firstName" label="First Name" />
						<BtFormTextField name="lastName" label="Surname" />
					</BtFormLateralContainer>
					<BtFormLateralContainer>
						<BtFormTextField name="userName" label="Display Name" />
						<BtFormTextField name="initials" label="Initials" />
					</BtFormLateralContainer>
					<BtFormLateralContainer>
						<BtFormTextField
							name="email"
							label="Email Address, you cannot change this here"
							disabled
						/>
						<BtFormTextField
							name="mobilePhone"
							label="Mobile number"
						/>
					</BtFormLateralContainer>
				</BtFormContent>

				<BtFormActionsContainer>
					<BtFormActionButtons
						alwaysAllowDestructive
						showDestructivePrompt={false}
						// SubmitIcon={<InviteIcon />}
						submitVerb="Update Details"
					/>
				</BtFormActionsContainer>
			</BtForm>
		);
	};
	return (
		<FormContainer text="Update your details">
			<UserDetailsForm />
		</FormContainer>
	);
}
