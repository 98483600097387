import { Box, Typography, styled } from '@mui/material';
import {
	IconContainer,
	ListButton,
} from '../../generic/filterSelect/components/listComponents';
import { CheckBold } from 'mdi-material-ui';

// TODO add breakpoint based width to improve mobile view

const PaletteRow = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
	alignItems: 'center',
	justifyContent: 'space-between',
}));

const PaletteContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexWrap: 'wrap',
	width: '320px',
	[theme.breakpoints.down('sm')]: {
		width: '160px',
	},
}));

const PaletteAndTextContainer = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	gap: '10px',
}));

const size = 40;

const ColourSwatch = styled(Box)(({ colour, rows }) => ({
	height: `${size / rows}px`,
	width: `${size}px`,
	backgroundColor: colour,
}));

const PaletteLabel = styled(Typography)(() => ({
	wordBreak: 'break-word',
	// opacity: disabled ? 0.7 : 1,
	width: '110px',
}));

const PlaceholderContainer = styled(PaletteContainer)(({ theme }) => ({
	height: size,
	border: `dashed 1px ${theme.palette.grey[500]}`,
	alignItems: 'center',
	justifyContent: 'center',
}));

export const PaletteRenderItem = ({
	disabled,
	selected,
	title,
	onClick,
	palette,
}) => {
	return (
		<ListButton disabled={disabled} selected={selected} onClick={onClick}>
			<PaletteRow>
				<PaletteAndTextContainer>
					<PaletteContainer>
						{palette.length > 0 ? (
							palette.map(colour => (
								<ColourSwatch
									rows={Math.ceil(palette.length / 8)}
									key={colour.hex}
									colour={colour.hex}
								/>
							))
						) : (
							<PlaceholderContainer>
								<Typography>No colours in palette</Typography>
							</PlaceholderContainer>
						)}
					</PaletteContainer>

					<PaletteLabel>{title}</PaletteLabel>
				</PaletteAndTextContainer>
				<IconContainer>
					{selected && (
						<CheckBold fontSize="medium" color="primary" />
					)}
				</IconContainer>
			</PaletteRow>
		</ListButton>
	);
};

export { PaletteContainer, ColourSwatch };
