import { useEffect, useState } from 'react';
import BtSelectionTable from '../../components/generic/BtSelectionTable';
import { useAuthContext } from '../../context/AuthContext';
import { useNavContext } from '../../context/ContextManager';
import FormContainer from './FormContainer';
import { user_settings_nav_item } from './UserSettingsNavItem';
import user_settings_options from './UserSettingsOptions';
import _ from 'lodash';
import { BtChipsDisplay } from '../../components/generic/BtChips';

const tableColumns = [
	{ field: 'group', text: 'Group' },
	{ field: 'permissions', text: 'Permissions' },
];

export default function UserPermissions() {
	const { userPermissions } = useAuthContext();
	const [permissions, setPermissions] = useState([]);
	const { setBreadcrumbs, setContextualNav } = useNavContext();

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'User Settings', link: '/UserSettings' },
				{ text: 'Subscriptions', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([
				...user_settings_nav_item,
				...user_settings_options,
			]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	useEffect(
		() => {
			if (userPermissions) {
				const permGroups = Object.keys(userPermissions);
				setPermissions(
					permGroups.map(permGroup => {
						return {
							group: permGroup,
							// methods: userPermissions[permGroup],
							methods: _.uniq(userPermissions[permGroup]),
						};
					})
				);
			}
		},
		[userPermissions]
	);

	// console.log({ permissions, userPermissions });

	return (
		<FormContainer maxWidth="md" text="Your Permissions">
			{/* <pre>{JSON.stringify(permissions, null, 4)}</pre> */}
			<BtSelectionTable
				// title="Your Permissions"
				subject="Permissions"
				columns={tableColumns}
				data={permissions?.map(({ group, methods }) => ({
					group: group,
					permissions: (
						<BtChipsDisplay
							// idKey={}
							value={methods}
							size="small"
							readOnly
							// partOfButton
							// singleLine
						/>
					),
				}))}
			/>
		</FormContainer>
	);
}
