import * as yup from 'yup';

import { organisationTypes } from './selectData';
import { regionSettingsSelectData } from './selectData';

const basicInfo = {
	displayName: yup
		.string()
		.min(3)
		.required()
		.label('Display Name'),
	legalTradingName: yup
		.string()
		.min(3)
		.required()
		.label('Legal/Trading Name'),
	organisationType: yup
		.string()
		.oneOf(organisationTypes.map(({ value }) => value))
		.required()
		.label('Organisation Type'),
	regNo: yup
		.string()
		.matches(/.{10,}/, {
			excludeEmptyString: true,
			message: 'Registration number must be 10 characters',
		})
		.label('Registration Number'),
	isVatRegistered: yup.boolean().label('VAT Reg Status'),
	vatRegNo: yup
		.string()
		.when('isVatRegistered', {
			is: true,
			then: yup
				.string()
				.min(10)
				.required(),
		})
		.label('VAT Registration Number'),
};

const contactInfo = {
	contactName: yup
		.string()
		.min(3)
		.required()
		.label('Contact Name'),
	contactEmail: yup
		.string()
		.email()
		.required()
		.label('Contact Email'),
	telephone: yup
		.string()
		.min(3)
		.required()
		.label('Telephone Number'),
	address: yup
		.string()
		.min(3)
		.required()
		.label('Address'),
	townCity: yup.string().label('Town/City'),
	regionState: yup.string().label('Region/State'),
	postalZipCode: yup
		.string()
		.min(4)
		.required()
		.label('Postal/Zip Code'),
};

const regionalSettings = regionSettingsSelectData.reduce(
	(accumulator, current) => {
		const fieldSchema = yup
			.string()
			.oneOf(current.data.map(({ value }) => value))
			.required()
			.label(current.label);
		return { ...accumulator, [current.field]: fieldSchema };
	},
	{}
);

export const validationSchema = yup.object({
	...basicInfo,
	...contactInfo,
	...regionalSettings,
});
