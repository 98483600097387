import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, Typography, useTheme } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';

const INFO = 'info';
const SUCCESS = 'success';
const WARNING = 'warning';
const ERROR = 'error';

export const BtMessage = forwardRef(
	({ message, showIcon, variant, style }, ref) => {
		const theme = useTheme();

		const attributes = useMemo(
			() => {
				const icons = {
					info: InfoIcon,
					success: SuccessIcon,
					warning: WarningIcon,
					error: ErrorIcon,
				};

				return {
					color: theme.palette.indicators[variant].main,
					Icon: icons[variant],
				};
			},
			[theme, variant]
		);

		return (
			<Card ref={ref} style={style}>
				<div
					style={{
						padding: '1em',
						display: 'flex',
						borderLeft: `5px solid ${attributes.color}`,
						height: '100%',
					}}
				>
					{showIcon && (
						<attributes.Icon
							style={{
								color: attributes.color,
								fontSize: 40,
								marginRight: '0.5em',
							}}
						/>
					)}
					{typeof message === 'string' ? (
						<Typography>{message}</Typography>
					) : (
						<div>{message}</div>
					)}
				</div>
			</Card>
		);
	}
);

BtMessage.defaultProps = {
	variant: INFO,
	showIcon: true,
};

BtMessage.propTypes = {
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
	showIcon: PropTypes.bool,
	style: PropTypes.object,
	variant: PropTypes.oneOf([INFO, SUCCESS, WARNING, ERROR]),
};

BtMessage.displayName = 'BtMessage';

export default BtMessage;
