import { useCallback, useMemo } from 'react';

import components, { componentTypeExists } from '../config/workflowComponents';

import { useWorkflowSessionContext } from '../../contexts/WorkflowSessionContext';
import { useWorkflowStickyContext } from '../../contexts/WorkflowStickyContext';

export const useWorkflowDefaults = workflowChildren => {
	const getDefaultValue = useCallback(type => {
		if (!componentTypeExists(type)) return null;

		const value = components[type].dataType.defaultValue;

		return typeof value === 'function' ? value() : value;
	}, []);

	const {
		answerSourceLookup,
		currentPage,
		persistantAnswers: answers,
	} = useWorkflowSessionContext();

	const { getStickyValues } = useWorkflowStickyContext();
	const sourcedAnswers = answerSourceLookup.latestResponses;
	const stickyValues = useMemo(() => getStickyValues(), [getStickyValues]);

	return useMemo(
		() =>
			workflowChildren?.reduce((accumulator, child) => {
				const {
					defaultValue,
					groupIndex,
					logAnswer,
					type,
					uuid,
				} = child;

				// Some components don't have datatypes, i.e. Sections, PageButtons, etc..
				if (!components[type]?.dataType) {
					return accumulator;
				}

				const sourcedAnswer =
					sourcedAnswers?.[child.answerSource?.uuid]?.[
						groupIndex || 0
					]?.[child.answerSource?.propName];

				const liveAnswer = currentPage.dirtyFields?.includes(uuid)
					? answers[uuid]
					: sourcedAnswer;

				return {
					...accumulator,
					[uuid]:
						liveAnswer ??
						logAnswer ??
						stickyValues[uuid]?.value ??
						defaultValue ??
						getDefaultValue(type),
				};
			}, {}),
		[
			answers,
			currentPage,
			getDefaultValue,
			sourcedAnswers,
			stickyValues,
			workflowChildren,
		]
	);
};

export default useWorkflowDefaults;
