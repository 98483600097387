import React from 'react';

import Color from 'color';
import CommentsDisabledIcon from '@mui/icons-material/CommentsDisabled';
import { styled, Typography } from '@mui/material';

import { useCommentsContext } from './CommentsContext';

const Container = styled('div')(({ theme }) => ({
	alignItems: 'center',
	backgroundColor: Color(theme.palette.text.primary)
		.fade(0.9)
		.toString(),
	borderRadius: 8,
	display: 'flex',
	flexDirection: 'row',
	marginBottom: '0.5em',
	padding: '0.8em 1em',
	position: 'relative',
	width: '100%',
}));

const Icon = styled(CommentsDisabledIcon)(({ theme }) => ({
	color: theme.palette.text.primary,
	fontSize: 28,
	marginRight: '0.5em',
}));

export default function CommentsDisabled() {
	const { readOnlyMessage } = useCommentsContext();

	return (
		<Container>
			<Icon />
			<Typography>
				{readOnlyMessage ?? 'These comments are in read-only mode.'}
			</Typography>
		</Container>
	);
}
