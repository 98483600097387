import { useCallback, useEffect, useRef, useState } from 'react';

import { v4 as generateUuid } from 'uuid';
import _ from 'lodash';

import {
	ADDED,
	COMPOUND_COMPONENTS,
	DELETED,
	NON_COMMENTABLE,
	NOT_ACCESSED,
} from '../utils/constants';
import {
	convertToAnswerTree,
	establishNewLogs,
	getLatestAnswer,
} from '../utils/outputUtils';
import { GROUP, PAGE, SECTION, IMAGE } from '../utils/constants';
import { useWorkflowStickyContext } from '../../contexts/WorkflowStickyContext';

import { objectMatch } from 'mongodb_query';
import { dataSetStoreQueryGet, imageRepoUpload } from '../../../../../API';
import Resizer from 'react-image-file-resizer';

export default function useStateManager() {
	const answers = useRef({});
	const answerSourceLookup = useRef({
		dependentComponents: {},
		latestResponses: {},
	});
	const dataSourcesData = useRef({});
	const dirtyFields = useRef({});
	const initialised = useRef(false);
	const isEdit = useRef(false);
	const pageSectionData = useRef({});
	const persistantAnswers = useRef({});
	const refData = useRef({});

	const {
		initialise: initialiseSticky,
		update: updateSticky,
	} = useWorkflowStickyContext();

	// Session state
	const [isFinal, setIsFinal] = useState(false);
	const [nextStates, setNextStates] = useState(null);
	const [pageLogs] = useState([]);
	const [sessionSubmitting, setSessionSubmitting] = useState(false);
	const [sessionUuid, setSessionUuid] = useState(null);
	const [workflowSubmission, setWorkflowSubmission] = useState(null);

	// Form state
	const [complete, setComplete] = useState(false);
	const [currentPage, setCurrentPage] = useState(null);
	const [fetchingComponents, setFetchingComponents] = useState([]);
	const [navigationStack, setNavigationStack] = useState([]);
	const [showSubmitError, setShowSubmitError] = useState(false);
	const [tree, setTree] = useState(null);
	const [workflowComments, setWorkflowComments] = useState();
	const [workflowTemplate, setWorkflowTemplate] = useState(null);

	useEffect(
		() => {
			if (workflowTemplate && workflowSubmission) {
				try {
					const currentState = _.find(workflowTemplate.flow, {
						state: workflowSubmission.status.status,
					});

					setNextStates(currentState.next);

					setSessionUuid(generateUuid());
				} catch (error) {
					console.error(
						'An error occurred when determining next state.',
						{ workflowSubmission, workflowTemplate }
					);

					throw new Error(
						'An error occurred when determining next state.'
					);
				}
			}
		},
		[workflowSubmission, workflowTemplate]
	);

	const refreshCurrentPage = useCallback(() => {
		setCurrentPage({ ...refData.current.currentPage });

		setTree({ ...refData.current.tree });
	}, []);

	const nodeSearcher = (
		comparator,
		tree = refData.current.tree,
		findMultiple = false
	) => {
		let collatedNodes = [],
			node,
			stack = [];

		stack.push(tree);

		while (stack.length > 0) {
			node = stack.pop();

			if (comparator(node)) {
				if (findMultiple) {
					collatedNodes = [...collatedNodes, node];
				} else {
					return node;
				}
			}

			if (node.children?.length) {
				stack.push(...node.children);
			} else if (node.page) {
				stack.push(...node.page.children);
			}
		}

		if (findMultiple) {
			return collatedNodes;
		}

		return null;
	};

	const searchForNode = useCallback(
		(comparator, tree = refData.current.tree) =>
			nodeSearcher(comparator, tree),
		[]
	);

	const searchForNodes = useCallback(
		(comparator, tree = refData.current.tree) =>
			nodeSearcher(comparator, tree, true),
		[]
	);

	const collatePageSections = useCallback(
		(tree = refData?.current?.tree ?? {}) => {
			const collateNode = (node, pageUuid) => {
				if (node.type === SECTION) {
					node.parentPageUuid = pageUuid;

					const { openConditions } = node;

					if (openConditions) {
						let currentPageData = pageSectionData.current[pageUuid];

						currentPageData = {
							...currentPageData,
							hasConditionalSections: true,
							conditionalSections: [
								...(currentPageData?.conditionalSections || []),
								node,
							],
							depAnswerUuids: [
								...(currentPageData?.depAnswerUuids || []),
								...(node.openConditions.depAnswers || []),
							],
						};

						pageSectionData.current[pageUuid] = currentPageData;
					}
				}

				if (node.children?.length) {
					node.children.forEach(child =>
						collateNode(child, pageUuid)
					);
				} else if (node.page) {
					node.page.children.forEach(child =>
						collateNode(child, node.page.uuid)
					);
				}
			};

			collateNode(tree, tree.uuid);
		},
		[]
	);

	const determineDataSources = useCallback(
		() => {
			const dataSourceComponents = searchForNodes(
				node => !!node.dataSource
			);

			const dataSetComponents = dataSourceComponents.filter(
				({ dataSource }) => dataSource.type === 'dataset'
			);

			dataSourcesData.current.hasDataSources =
				!!dataSetComponents.length ||
				!!refData.current.workflowTemplate.dataSources;

			dataSourcesData.current.dataSetComponents = dataSetComponents;
			dataSourcesData.current.templateDataSources =
				refData.current.workflowTemplate.dataSources;
		},
		[searchForNodes]
	);

	const checkDepAnswerDiff = useCallback(newAnswers => {
		const pageUuid = refData.current.currentPage.uuid;

		const depAnswers = _.pick(
			newAnswers,
			pageSectionData.current[pageUuid]?.depAnswerUuids
		);

		const diff = !_.isEqual(
			depAnswers,
			pageSectionData.current[pageUuid]?.lastDepAnswers || {}
		);

		pageSectionData.current[pageUuid].lastDepAnswers = {
			...(pageSectionData.current[pageUuid]?.lastDepAnswers || {}),
			...newAnswers,
		};

		return diff;
	}, []);

	const getChildPages = useCallback(parent => {
		const children = parent.children || [];

		// Validate immediate child pages
		let childPages = children.filter(child => child.type === PAGE);

		// Validate child pages of immediate groups
		const immediateGroups = children.filter(
			child => child.childType === PAGE
		);

		const splitGroups = immediateGroups.reduce((accumulator, group) => {
			let pages = group.children;

			for (let index = 0; index < pages.length; index++) {
				pages[index].primaryIndex = pages[index].primaryIndex ?? index;
			}

			return [...accumulator, ...pages];
		}, []);

		return [...childPages, ...splitGroups];
	}, []);

	const getChildOpenSections = useCallback(parent => {
		const children = parent.children || [];

		return children.filter(({ open, type }) => type === SECTION && open);
	}, []);

	const analyseTree = useCallback(
		() => {
			const analyseParent = parent => {
				const { status, type, valid } = parent;
				let childrenAreValid = true;
				let sectionsAreValid = true;

				const childPages = getChildPages(parent).map(
					({ page }) => page
				);

				for (let i = 0; i < childPages.length; i++) {
					if (analyseParent(childPages[i]) === false) {
						childrenAreValid = false;
					}
				}

				// Validate open sections
				const openSections = getChildOpenSections(parent);

				for (let i = 0; i < openSections.length; i++) {
					if (!analyseParent(openSections[i])) {
						sectionsAreValid = false;
					}
				}

				if (
					(!childrenAreValid || !sectionsAreValid) &&
					parent.type === PAGE
				) {
					parent.valid = false;
				}

				return (
					(valid || type === SECTION) &&
					childrenAreValid &&
					sectionsAreValid &&
					status !== NOT_ACCESSED
				);
			};

			const isValid = analyseParent(refData.current.tree);

			if (!refData.current.allValid && isValid) {
				setShowSubmitError(false);
			}

			refData.current.allValid = isValid;

			if (isValid !== complete) {
				if (
					!_.isEqual(
						refData.current.currentPage?.answers,
						currentPage?.answers
					)
				) {
					refreshCurrentPage();
				}

				setComplete(isValid);
			}
		},
		[
			currentPage,
			complete,
			getChildOpenSections,
			getChildPages,
			refreshCurrentPage,
		]
	);

	const checkSections = useCallback(
		(newAnswers, pageUuid) => {
			if (!(newAnswers && pageUuid)) return;

			if (checkDepAnswerDiff(newAnswers)) {
				let updateRequired = false;

				const sections =
					pageSectionData.current[pageUuid].conditionalSections || [];

				for (const section of sections) {
					const { open: currentOpen, openConditions } = section;

					const open = objectMatch(
						newAnswers,
						openConditions.operation
					);

					if (open !== currentOpen) {
						section.open = open;

						updateRequired = true;
					}
				}

				if (updateRequired && initialised.current) {
					analyseTree();

					refreshCurrentPage();
				}
			}
		},
		[analyseTree, checkDepAnswerDiff, refreshCurrentPage]
	);

	const handleDataUpdate = useCallback((data, requester, type) => {
		if (type === 'template') {
			// If data has changed, save response against data source uuid
			answerSourceLookup.current.latestResponses[requester.uuid] = data;

			// Get list of dependent components for data source
			const dependentComponents =
				answerSourceLookup.current.dependentComponents[requester.uuid];

			let affectedPages = [];

			// Assess each group index answer
			data.forEach((groupData, index) => {
				// Obtain depenedent components that of the same group index
				const components =
					dependentComponents?.filter(
						({ componentRef }) =>
							componentRef.groupIndex ?? 0 === index
					) || [];

				// Assess changes to every component concerned (across pages)
				components.forEach(({ propName, componentRef, pageRef }) => {
					// Obtain actual page (not page button)
					const page = pageRef.page ?? pageRef;

					const { uuid } = componentRef;

					const prevAnswer = page.answers?.[uuid];

					const { dirtyFields } = page;

					const newAnswer = groupData[propName];

					// If the field is not dirty, and has changed,
					// then note down respective affected pages and
					// update respective pages answers
					if (
						!(dirtyFields || []).includes(uuid) &&
						!_.isEqual(prevAnswer, newAnswer)
					) {
						affectedPages = _.uniq([...affectedPages, pageRef]);

						page.answers = {
							...(page.answers || {}),
							[uuid]: newAnswer,
						};

						if (
							Object.prototype.hasOwnProperty.call(
								persistantAnswers.current,
								uuid
							)
						) {
							answers.current[uuid] = newAnswer;
							persistantAnswers.current[uuid] = newAnswer;
							refData.current.currentPage.answers[
								uuid
							] = newAnswer;
						}
					}
				});
			});

			// Set affected pages to not accessed in order to
			// prompt user to re-enter and reassess the page
			affectedPages.forEach(page => {
				if (!page.isRoot) {
					page.status = NOT_ACCESSED;

					if (page.page) {
						page.page.status = NOT_ACCESSED;
					}
				}
			});

			// Set current answers and trigger re-render
			persistantAnswers.current = {
				...(persistantAnswers.current || {}),
				...(refData.current.currentPage.answers || {}),
			};

			answers.current = {
				...refData.current.currentPage.answers,
				...persistantAnswers.current,
			};

			return true;
		} else if (type === 'component') {
			// TODO: Change the following to be more component agnostic
			if (requester.type === 'Select') {
				// Convert the returned array to the required label/value pairs for a select
				const optionsList = data.map(dataRecord => {
					return {
						value: dataRecord[requester.dataSource.value],
						label: dataRecord[requester.dataSource.label],
					};
				});
				// Clear the current answer for this component
				// TODO: this ids not currently working
				// dataSetComponent.defaultValue = '';
				// persistantAnswers.current[newComponentData.requester] = '';
				// refData.current.currentPage.answers[
				// 	newComponentData.requester
				// ] = '';
				// answers.current[newComponentData.requester] = '';

				// Set the selects options
				requester.options = optionsList;
			} else {
				if (data.length > 0) {
					// !!!!!! TODO: This should not be updating the default value in tree!!!!!
					requester.defaultValue = data[0].value;
					persistantAnswers.current[data.requester.uuid] =
						data[0].value;
					refData.current.currentPage.answers[requester.uuid] =
						data[0].value;
					answers.current[data.requester.uuid] = data[0].value;
				}
			}

			return true;
		}
	}, []);

	const dataSources = useRef({});
	const lastAnswers = useRef({});
	const fatchingComponentList = useRef([]);

	const getData = useCallback(async (currentAnswers, dataSource) => {
		const { dataSourceUuid, requester } = dataSource;

		// Get this data source instance
		var thisDataSource = dataSources.current[dataSourceUuid];

		// Check if we already have this data source
		if (!thisDataSource) {
			thisDataSource = { filters: [] };
		}

		// Get the index of the matching filter, used later for updateing
		var filterIndex = _.findIndex(thisDataSource.filters, filter => {
			// loading.current = false;
			return (
				filter.query === dataSource.query &&
				_.isEqual(filter.variables, dataSource.variables)
			);
		});

		// Get the filter if we have the index or create a new blank filter
		var filter = null;
		if (filterIndex !== -1) {
			filter = thisDataSource.filters[filterIndex];
		} else {
			filter = {
				query: dataSource.query,
				variables: dataSource.variables,
				answers: {},
				result: null,
				lastAnswers: {},
			};
		}

		var varsAvailable = true;
		var newAnswers = {};

		// If the data source uses variables get the matching answers
		if (dataSource.variables) {
			Object.keys(dataSource.variables).forEach(variable => {
				let answer =
					currentAnswers[(dataSource.variables[variable]?.value)];
				if (answer === '' || !answer) {
					// Required question not answered
					varsAvailable = false;
				}
				newAnswers[variable] = answer;
			});
		}

		// If we have all the var required for the data source query
		if (varsAvailable) {
			// Check if we need to make a request or if the stored data is valid
			if (_.isEqual(newAnswers, filter.lastAnswers) && filter.result) {
				// Answers not changed, return the data we already have
				return {
					dataUpdated: false,
					data: filter.result,
					requester: requester,
				};
			} else {
				// Answers changed/first request, get new data

				// Build the quesry for the backend
				let reqQueryObj = dataSource.query;

				try {
					// Check if the query requires variables
					if (
						dataSource.query &&
						dataSource.variables &&
						Object.keys(dataSource.variables).length > 0
					) {
						let queryArray = dataSource.query.split('#');

						const variables = dataSource.variables;

						// Insert answers into queryArray
						const populatedQueryArray = queryArray.map(
							querySection => {
								// Find all sections starting with VAR
								if (querySection.startsWith('VAR')) {
									if (!variables[querySection]) {
										throw new Error('Missing variable');
									} else {
										filter.lastAnswers[
											variables[querySection]
										] =
											currentAnswers[
												variables[querySection].value
											];

										if (variables[querySection]) {
											if (
												currentAnswers[
													variables[querySection]
														.value
												] === ''
											) {
												// loading.current = false;
												throw new Error(
													'Required answer not set'
												);
											}
											// loading.current = false;
											return currentAnswers[
												variables[querySection].value
											];
										} else {
											// loading.current = false;
											throw new Error(
												'Unsupported variable expressing',
												variables[querySection]
											);
										}
									}
								} else {
									// loading.current = false;
									// No sub required, return the string as is
									return querySection;
								}
							}
						);
						reqQueryObj = JSON.parse(populatedQueryArray.join(''));
					}

					if (dataSource.type === 'component') {
						fatchingComponentList.current.push(requester.uuid);
					} else if (dataSource.type === 'template') {
						const dependentComponents =
							answerSourceLookup.current.dependentComponents[
								requester.uuid
							];
						dependentComponents.forEach(dependentComponent => {
							fatchingComponentList.current.push(
								dependentComponent.componentRef.uuid
							);
						});
					}

					setFetchingComponents(fatchingComponentList.current);

					// Get the data from the server
					const newData = await dataSetStoreQueryGet({
						dataSetUuid: dataSource.dataSourceUuid,
						query: { query: reqQueryObj },
					});

					// Update the filter object for caching
					filter.result = newData;
					filter.lastAnswers = newAnswers;

					// If this is a new filter push to the array else update
					if (filterIndex === -1) {
						thisDataSource.filters.push(filter);
					} else {
						thisDataSource.filters[filterIndex] = filter;
					}

					// Update the data source
					dataSources.current = {
						...dataSources.current,
						[dataSource.dataSourceUuid]: thisDataSource,
					};

					return {
						dataUpdated: true,
						data: newData,
						requester: requester,
					};
				} catch (error) {
					console.log(error);
				}
			}
		}

		// loading.current = false;
		return {
			dataUpdated: false,
			data: [],
			requester: requester,
		};
	}, []);

	const checkDataSources = useCallback(
		async newAnswers => {
			const {
				dataSetComponents,
				templateDataSources,
			} = dataSourcesData.current;

			try {
				const dataSourceList = [
					...templateDataSources.map(templateDataSource => ({
						requester: { uuid: templateDataSource.uuid },
						dataSourceUuid: templateDataSource.source_uuid,
						query: templateDataSource.query,
						variables: templateDataSource.variables,
						type: 'template',
					})),
					...dataSetComponents.map(dataSetComponent => ({
						requester: dataSetComponent,
						dataSourceUuid: dataSetComponent.dataSource.uuid,
						query: dataSetComponent.dataSource.query,
						variables: dataSetComponent.dataSource.variables,
						type: 'component',
					})),
				];

				// Only proccess the data sources if the answers have changed
				if (_.isEqual(newAnswers, lastAnswers.current)) {
					return false;
				}
				lastAnswers.current = { ...newAnswers };

				let dataHasUpdated = false;

				fatchingComponentList.current = [];

				const allRes = await Promise.all(
					dataSourceList.map(dataSource => {
						return getData(newAnswers, dataSource);
					})
				);

				allRes.forEach(({ dataUpdated, data, requester }) => {
					const dataSourceType = dataSourceList.find(dataSource => {
						return dataSource.requester.uuid === requester.uuid;
					});

					if (dataUpdated) {
						if (
							handleDataUpdate(
								data,
								requester,
								dataSourceType.type
							)
						) {
							dataHasUpdated = true;
						}
					}
				});

				console.log('dataHasUpdated', dataHasUpdated);

				if (dataHasUpdated) {
					refreshCurrentPage();
					analyseTree();
					setFetchingComponents([]);
				}
			} catch (error) {
				console.log('DataSource Error', error);
			}
		},
		[handleDataUpdate, getData, analyseTree, refreshCurrentPage]
	);

	const onFormStateChange = useCallback(
		async (formState, newAnswers) => {
			const currentAnswers = refData?.current?.currentPage?.answers || {};

			if (!_.isEqual(newAnswers, currentAnswers)) {
				// Determine altered fields
				const alteredFields = currentAnswers
					? Object.entries(newAnswers)
							.filter(
								([key, value]) => currentAnswers[key] !== value
							)
							.map(entry => entry[0])
					: [];

				// If only a single value is changed then add it to the
				// list of dirty fields
				if (alteredFields.length === 1) {
					refData.current.currentPage.dirtyFields = _.uniq([
						...(refData?.current?.currentPage?.dirtyFields || []),
						...alteredFields,
					]);
				}

				setCurrentPage(refData.current.currentPage);

				const pageUuid = refData.current.currentPage.uuid;

				if (
					!initialised.current ||
					Object.keys(newAnswers || {}).length === 0 ||
					pageUuid !== currentPage.uuid
				) {
					return;
				}

				// Check if any sections need to open/close
				if (pageSectionData.current[pageUuid]?.hasConditionalSections) {
					checkSections(newAnswers, pageUuid);
				}
			}

			// Set answers in both ref var and the tree
			answers.current = newAnswers;
			persistantAnswers.current = {
				...persistantAnswers.current,
				...answers.current,
			};
			refData.current.currentPage.answers = newAnswers;

			updateSticky(newAnswers);

			// Check if any datasources need to update
			if (dataSourcesData.current.hasDataSources) {
				await checkDataSources(newAnswers);
			}

			// Analyse entire tree validation if current page valid status changes
			if (refData.current.currentPage.valid !== formState.isValid) {
				refData.current.currentPage.valid = formState.isValid;

				analyseTree();
			}
		},
		[
			analyseTree,
			checkDataSources,
			checkSections,
			currentPage,
			updateSticky,
		]
	);

	const submitPage = () => {
		// If not currently at the root page, go up...
		if (navigationStack.length > 0) {
			const lastNavItem = navigationStack[navigationStack.length - 1];

			refData.current.currentPage = lastNavItem.page;

			answers.current = refData.current.currentPage.answers;
			persistantAnswers.current = {
				...refData.current.currentPage.answers,
			};

			setCurrentPage(refData.current.currentPage);
			setTree(refData.current.tree);

			setNavigationStack(prev => prev.slice(0, -1));

			return;
		}

		setTree(refData.current.tree);

		// If at root, and the workflow has errors/is incomplete
		if (!refData.current.allValid) {
			setShowSubmitError(true);

			return;
		}

		// If at root, and everything is complete and valid
		console.log('Answer Tree', convertToAnswerTree(refData.current.tree));
		console.log(
			'New Page Logs',
			establishNewLogs(pageLogs, refData.current.tree, sessionUuid)
		);
	};

	const addAnswerSourceDependency = useCallback(
		({ answerSource, componentRef, pageRef }) => {
			if (answerSource) {
				const { propName, uuid: sourceUuid } = answerSource;

				// Obtain existing data source object
				let currentSource =
					answerSourceLookup.current.dependentComponents[sourceUuid];

				// If no data source object exists, create one
				if (!currentSource) {
					answerSourceLookup.current.dependentComponents = {
						...answerSourceLookup.current.dependentComponents,
						[sourceUuid]: [],
					};
				}

				// Ensure data source is pointing to an object
				currentSource =
					answerSourceLookup.current.dependentComponents[sourceUuid];

				// Register the new component alongside any other registered
				// components for this particular data source
				answerSourceLookup.current.dependentComponents[sourceUuid] = [
					...currentSource,
					{
						pageRef,
						propName,
						componentRef,
					},
				];
			}
		},
		[]
	);

	const collateAnswerSources = useCallback(
		(tree = refData?.current?.tree ?? {}) => {
			const collateChildAnswerSources = (children = [], parentPage) => {
				for (const child of children) {
					const {
						answerSource,
						children: childChildren,
						repeatable,
						type,
					} = child;

					if (answerSource && !repeatable) {
						addAnswerSourceDependency({
							answerSource,
							componentRef: child,
							pageRef: parentPage,
						});
					}

					if (type === PAGE) {
						collateChildAnswerSources(
							child.page.children,
							child.page
						);
					} else if (childChildren) {
						collateChildAnswerSources(childChildren, parentPage);
					}
				}
			};

			collateChildAnswerSources(tree.children, tree);
		},
		[addAnswerSourceDependency]
	);

	const constructGroup = useCallback(
		(child, logData, parentPage) => {
			const { pageUuid, type, uuid } = child;

			const groupAnswers = logData?.answers[uuid];

			const pageLogData = logData?.pages?.find(
				({ uuid: logPageUuid }) => logPageUuid === uuid
			);

			const pageUuids = (() => {
				if (!pageLogData) return null;

				const { content, grouped } = pageLogData;

				if (grouped) {
					return content
						.filter(
							({ logs }) =>
								logs.length > 0 &&
								logs[logs.length - 1].action !== DELETED
						)
						.map(({ uuid }) => uuid);
				}
			})();

			let primaryCount;
			if (type === PAGE && !pageLogData) {
				primaryCount = refData.current.workflowTemplate.pages
					.find(({ uuid: templateUuid }) => pageUuid === templateUuid)
					?.children?.find(({ primary }) => primary)?.primaryAnswers
					?.length;
			}

			const minCount = Math.max(
				child.minCount || 1,
				pageUuids?.length || 1,
				groupAnswers?.length || 1,
				primaryCount || 1
			);

			const children = [...Array(minCount)].map((item, index) => {
				const { logAnswer, uuid } = (() => {
					if (type === PAGE && pageLogData) {
						const { grouped, uuid } = pageLogData;

						if (grouped && pageUuids?.length > index) {
							return { uuid: pageUuids[index] };
						}

						return { uuid: uuid || generateUuid() };
					}

					if (groupAnswers?.length > index) {
						return {
							uuid: groupAnswers[index].uuid,
							logAnswer: groupAnswers[index].value,
						};
					}

					return { uuid: generateUuid() };
				})();

				const comments = logData.comments[uuid] || [];

				return {
					...child,
					comments,
					groupIndex: index,
					logAnswer: logAnswer,
					refUuid: generateUuid(),
					uuid,
				};
			});

			for (const child of children) {
				const { answerSource } = child;

				if (answerSource) {
					addAnswerSourceDependency({
						answerSource,
						componentRef: child,
						pageRef: parentPage,
					});
				}
			}

			return {
				children,
				childTemplate: child,
				childType: type,
				groupUuid: uuid,
				refUuid: generateUuid(),
				type: GROUP,
				uuid: generateUuid(),
			};
		},
		[addAnswerSourceDependency]
	);

	const constructPage = useCallback(page => {
		const template = refData.current.workflowTemplate.pages.find(
			({ uuid }) => uuid === page.pageUuid
		);

		const children = _.cloneDeep(page.children || template.children || []);

		const {
			defaultAnswers,
			description,
			maxCount,
			minCount,
			name,
			primaryIndex,
			repeatable: repeatablePage,
			...otherPageProps
		} = page;

		const processedDefaultAnswers = (() => {
			let defaultsTemp = defaultAnswers || {};

			const primaryField = children.find(({ primary }) => primary);

			if (primaryField && page.primaryIndex !== -1) {
				const { primaryAnswers, uuid } = primaryField;

				if (primaryIndex < (primaryAnswers || []).length) {
					defaultsTemp[uuid] = primaryAnswers[primaryIndex];
				}
			}

			return defaultsTemp;
		})();

		let pageObj = {
			answers: processedDefaultAnswers,
			children,
			description: description ?? template.description,
			...(repeatablePage ? { maxCount } : {}),
			...(repeatablePage ? { minCount } : {}),
			name: name ?? template.name,
			...(primaryIndex !== undefined ? { primaryIndex } : {}),
			repeatable: repeatablePage,
			uuid: generateUuid(),
			valid: false,
			...otherPageProps,
		};

		return pageObj;
	}, []);

	const constructParent = useCallback(
		({
			parent,
			isRoot = false,
			parentPageLog,
			forwardLinkUuid,
			parentPage,
		}) => {
			isEdit.current = !!pageLogs;

			let currentParentPage =
				isRoot || parent.type === PAGE ? parent : parentPage;

			let constructedParent,
				defaultAnswers = {},
				logData = {
					answers: {},
					comments: {},
					pages: [],
				},
				pageLog = {};

			if (isEdit.current) {
				if (isRoot) {
					pageLog = pageLogs.find(({ isRoot }) => isRoot);
				} else if (parent.type === PAGE) {
					pageLog = pageLogs.find(
						({ forwardLinkUuid, uuid }) =>
							uuid === parent.uuid &&
							(!parent.repeatable ||
								forwardLinkUuid === parent.forwardLinkUuid)
					);
				} else if (parentPageLog) {
					pageLog = parentPageLog;
				}

				const components =
					(pageLog?.components || []).filter(
						({ content, grouped }) => {
							const isDeleted = ({ logs }) =>
								logs?.length === 0 ||
								logs[logs.length - 1].action === DELETED;

							if (grouped) {
								return !content.every(isDeleted);
							}

							return !isDeleted(content);
						}
					) || [];

				logData.answers = (components || [])
					.filter(({ nodeType }) => nodeType === 'input')
					.reduce((accumulator, component) => {
						const { content, grouped, uuid } = component;

						let answers = {};

						if (grouped) {
							let answer = [];

							content.forEach(elementContent => {
								const {
									comments,
									logs,
									uuid: componentUuid,
								} = elementContent;

								logData.comments[componentUuid] = comments;

								const elementAnswer = getLatestAnswer(logs);

								if (elementAnswer !== null) {
									answer = [
										...answer,
										{
											uuid: componentUuid,
											value: elementAnswer,
										},
									];
								}
							});

							answers = { ...answers, [uuid]: answer };
						} else {
							const { comments, logs } = content;

							logData.comments[uuid] = comments;

							const componentAnswer = getLatestAnswer(logs);

							const answer = {
								[uuid]: componentAnswer,
							};

							answers = { ...answers, ...answer };
						}

						return { ...accumulator, ...answers };
					}, {});

				logData.pages = (components || []).filter(
					({ nodeType }) => nodeType === 'page'
				);
			}

			if (parent.type === PAGE) {
				constructedParent = constructPage({
					...parent,
					...(defaultAnswers ? { defaultAnswers } : {}),
					linkUuid: parent.uuid,
					status: isRoot ? ADDED : NOT_ACCESSED,
					...(parent.repeatable
						? {
								primaryIndex: parent.primaryIndex || 0,
						  }
						: {}),
				});
			} else {
				constructedParent = parent;
			}
			console.log({ constructedParent });
			constructedParent.children = (
				constructedParent?.children || []
			).map(child => {
				const {
					maxCount: childMax,
					minCount: childMin,
					repeatable: childRepeatable,
					type,
				} = child;

				const { maxCount, minCount, repeatable } = (() => {
					if (childRepeatable && childMin) {
						return {
							maxCount: childMax,
							minCount: childMin,
							repeatable: childRepeatable,
						};
					}

					if (type === PAGE) {
						const foundPage = refData.current.workflowTemplate.pages.find(
							({ uuid }) => uuid === child.pageUuid
						);

						if (!foundPage) {
							throw new Error(
								'Could not find a required page in the workflow template.'
							);
						}

						const { maxCount, minCount, repeatable } = foundPage;

						return { maxCount, minCount, repeatable };
					}

					return {};
				})();

				if (repeatable && !COMPOUND_COMPONENTS.includes(type)) {
					return constructGroup(
						{
							...child,
							maxCount,
							minCount,
							repeatable,
						},
						logData,
						currentParentPage
					);
				}

				if (NON_COMMENTABLE.includes(type)) {
					return child;
				}

				return {
					...child,
					comments: logData.comments[child.uuid] || [],
					logAnswer: logData.answers[child.uuid],
					refUuid: generateUuid(),
				};
			});

			// for (const child of constructedParent.children) {
			// 	const { answerSource, repeatable } = child;

			// 	if (answerSource && !repeatable) {
			// 		addAnswerSourceDependency({
			// 			answerSource,
			// 			componentRef: child,
			// 			pageRef: currentParentPage,
			// 		});
			// 	}
			// }

			if (
				constructedParent.type === PAGE &&
				constructedParent.repeatable
			) {
				forwardLinkUuid = constructedParent.linkUuid;
			}

			let childPages = getChildPages(constructedParent);

			for (let i = 0; i < childPages.length; i++) {
				childPages[i].page = {
					...constructParent({
						parent: childPages[i],
						isRoot: false,
						parentPageLog: null,
						forwardLinkUuid,
						parentPage: currentParentPage,
					}),
					linkUuid: childPages[i].uuid,
					...(!childPages[i].repeatable ? { forwardLinkUuid } : {}),
				};
			}

			const sections = constructedParent.children.filter(
				({ type }) => type === SECTION
			);

			sections.forEach(section =>
				constructParent({
					parent: section,
					isRoot: false,
					parentPageLog: pageLog,
					forwardLinkUuid,
					parentPage: currentParentPage,
				})
			);

			return constructedParent;
		},
		[constructGroup, constructPage, getChildPages, pageLogs]
	);

	const searchForGroup = useCallback(
		uuid =>
			searchForNode(
				node => node.uuid === uuid,
				refData.current.currentPage
			),
		[searchForNode]
	);

	const updateComments = useCallback(
		(refUuid, comments) => {
			const parent = searchForNode(node => node.refUuid === refUuid);
			parent.comments = comments;

			refreshCurrentPage();
		},
		[refreshCurrentPage, searchForNode]
	);

	const addGroupElement = useCallback(
		groupUuid => {
			const foundGroup = searchForGroup(groupUuid);

			const child = _.cloneDeep(foundGroup.childTemplate);

			if (child.logAnswer) {
				child.logAnswer = null;
			}

			const groupIndex = foundGroup.children.length;

			if (foundGroup.childType === PAGE) {
				const linkUuid = generateUuid();
				const page = {
					...constructParent({
						parent: {
							...child,
							groupIndex,
							primaryIndex: -1,
							uuid: linkUuid,
						},
					}),
					linkUuid,
				};

				foundGroup.children = [
					...foundGroup.children,
					{
						...child,
						groupIndex,
						page,
						primaryIndex: -1,
						uuid: linkUuid,
					},
				];
			} else {
				const constructedChild = {
					...child,
					comments: [],
					groupIndex,
					refUuid: generateUuid(),
					uuid: generateUuid(),
				};

				const { answerSource } = constructedChild;

				// If the added group item has an answer source, it must
				// be registered in the answerSourceLookup
				if (answerSource) {
					addAnswerSourceDependency({
						answerSource,
						componentRef: constructedChild,
						pageRef: refData.current.currentPage,
					});
				}

				foundGroup.children = [
					...foundGroup.children,
					constructedChild,
				];
			}

			analyseTree();

			collatePageSections();

			setTree(refData.current.tree);

			refreshCurrentPage();
		},
		[
			addAnswerSourceDependency,
			analyseTree,
			collatePageSections,
			constructParent,
			refreshCurrentPage,
			searchForGroup,
		]
	);

	const removeGroupElement = useCallback(
		(groupUuid, elementUuid) => {
			const foundGroup = searchForGroup(groupUuid);
			const elementToRemove = foundGroup.children.find(
				group => group.uuid === elementUuid
			);

			// Recursively obtain all child elements with answer sources
			const dependentsToRemove = nodeSearcher(
				({ answerSource }) => !!answerSource,
				elementToRemove,
				true
			);

			// Loop over all registered answer source dependencies and
			// remove any that are, or contain, elements to be deleted
			for (const entry of Object.entries(
				answerSourceLookup.current.dependentComponents
			)) {
				const filtered = entry[1].filter(({ componentRef }) => {
					return !dependentsToRemove.includes(componentRef);
				});

				answerSourceLookup.current.dependentComponents[
					entry[0]
				] = filtered;
			}

			foundGroup.children = foundGroup.children.filter(
				element => element !== elementToRemove
			);

			analyseTree();

			collatePageSections();

			refreshCurrentPage();
		},
		[analyseTree, collatePageSections, searchForGroup, refreshCurrentPage]
	);

	const initialiseWorkflowSession = useCallback(
		session => {
			refData.current.workflowTemplate = session.template;

			if (!session.session_data.tree) {
				const rootPage = session.template.pages.find(
					({ isRoot }) => isRoot
				);

				if (!rootPage) {
					throw new Error(
						'A workflow requires a page to be marked as the root page.'
					);
				}

				const rootNode = constructParent({
					parent: rootPage,
					isRoot: true,
				});

				refData.current.tree = rootNode;
			} else {
				refData.current.tree = { ...session.session_data.tree };
			}

			refData.current.currentPage = refData.current.tree;
			persistantAnswers.current = {
				...refData.current.currentPage.answers,
			};

			if (session.isEditable) {
				setIsFinal(
					!session.template.flow.find(
						({ state }) => state === session.status.status
					)?.next?.length
				);
			} else {
				setIsFinal(true);
			}

			collateAnswerSources();
			collatePageSections();
			determineDataSources();

			setWorkflowComments(session.comments || []);

			setTree(refData.current.tree);
			setCurrentPage(refData.current.currentPage);

			initialiseSticky(refData.current.workflowTemplate);

			setWorkflowTemplate(session.template);
			setWorkflowSubmission(session);
		},
		[
			collateAnswerSources,
			collatePageSections,
			constructParent,
			determineDataSources,
			initialiseSticky,
		]
	);

	const getRootPrimaryAnswer = () => {
		const primaryAnswer = refData.current.tree.children.find(
			({ primary }) => primary
		);
		if (!primaryAnswer) {
			return undefined;
		} else {
			const value = refData.current.tree.answers[primaryAnswer.uuid];
			if (value === '') {
				return undefined;
			} else {
				return value;
			}
		}
	};

	const generateSubmissionUpdate = useCallback(
		sessionLog => ({
			workflowSubmissionUuid: workflowSubmission.uuid,
			submissionUpdate: {
				comments: workflowComments,
				data: convertToAnswerTree(refData.current.tree),
				log: sessionLog,
				primary_answer: getRootPrimaryAnswer(),
				session_data: {
					tree: refData.current.tree,
					pageLogs: establishNewLogs([], refData.current.tree),
				},
			},
		}),
		[workflowComments, workflowSubmission]
	);

	const resizeFile = file =>
		new Promise(resolve => {
			Resizer.imageFileResizer(
				file,
				2560,
				2560,
				'JPEG',
				100,
				0,
				uri => {
					resolve(uri);
				},
				'base64'
			);
		});

	const processAttachmentUpload = async () => {
		const processAttachments = async (children = [], parentPage) => {
			var updatedParentPage = { ...parentPage };
			for (const child of children) {
				const { children: childChildren, repeatable, type } = child;

				if (type === IMAGE) {
					console.log('refData', refData);
					if (repeatable) {
						// for (const file of updatedParentPage.answers[
						// 	child.uuid
						// ]) {
						for (
							let i = 0;
							i < updatedParentPage.answers[child.uuid].length;
							i++
						) {
							let file = updatedParentPage.answers[child.uuid][i];
							console.log(updatedParentPage.answers[child.uuid]);
							console.log(child);

							// Upload Image
							if (file.attachmentUploaded === false) {
								const imageData = await resizeFile(file);
								console.log('imageData', imageData);
								console.log(
									'imageData.length',
									imageData.length
								);

								await imageRepoUpload({
									imageRepo: child.imageRepoUuid,
									imageId: file.id,
									// image: file,
									imageBase64: imageData,
								});
								// file.attachmentUploaded = true;
								updatedParentPage.answers[child.uuid][i] = {
									// attachmentUploaded: true,
									uuid: file.id,
									location: file.location,
									create_timestamp: file.create_timestamp,
									thumbnail: file.thumbnail,
									imageRepo: child.imageRepoUuid,
								};
							}
						}
					} else {
						// Upload Image
						console.log(updatedParentPage.answers[child.uuid]);
						// for (const file of updatedParentPage.answers[
						// 	child.uuid
						// ] || []) {
						for (
							let i = 0;
							i < updatedParentPage.answers[child.uuid].length;
							i++
						) {
							let file = updatedParentPage.answers[child.uuid][i];

							console.log(updatedParentPage.answers[child.uuid]);
							console.log(child);
							if (file.attachmentUploaded === false) {
								const imageData = await resizeFile(file);
								console.log('imageData', imageData);
								console.log(
									'imageData.length',
									imageData.length
								);

								await imageRepoUpload({
									imageRepo: child.imageRepoUuid,
									imageId: file.id,
									// image: file,
									imageBase64: imageData,
								});
								// file.attachmentUploaded = true;
								updatedParentPage.answers[child.uuid][i] = {
									// attachmentUploaded: true,
									uuid: file.id,
									location: file.location,
									create_timestamp: file.create_timestamp,
									thumbnail: file.thumbnail,
									imageRepo: child.imageRepoUuid,
								};
							}
						}
					}
				}

				if (type === PAGE) {
					child.page = await processAttachments(
						child.page.children,
						child.page
					);
				} else if (childChildren) {
					updatedParentPage = await processAttachments(
						childChildren,
						updatedParentPage
					);
				}
			}

			return updatedParentPage;
		};

		const updatedTree = await processAttachments(tree.children, tree);
		console.log('updatedTree', updatedTree);
		setTree(updatedTree);
		refData.current.tree = updatedTree;
	};

	const setPage = useCallback(
		(page, buttonId) => {
			if (buttonId) {
				setNavigationStack(prev => [
					...prev,
					{
						page: refData.current.currentPage,
						referrer: buttonId,
					},
				]);
			}

			refData.current.currentPage = page;
			refData.current.currentPage.status = ADDED;
			answers.current = refData.current.currentPage.answers;
			persistantAnswers.current = {
				...refData.current.currentPage.answers,
			};

			determineDataSources(refData.current.currentPage);

			setTree(refData.current.tree);
			setCurrentPage(refData.current.currentPage);
			setShowSubmitError(false);
		},
		[determineDataSources]
	);

	// No arguments will navigate to the root page
	const navigateToPage = useCallback(
		(page = refData.current.tree, navStack = []) => {
			setNavigationStack(navStack);

			refData.current.currentPage = page;
			setPage(page);
		},
		[setPage]
	);

	/* Useful for debugging */
	useEffect(() =>
		console.log({
			currentPage,
			navigationStack,
			tree,
			workflowSubmission,
			sessionUuid,
		})
	);

	useEffect(
		() => {
			if (Object.keys(tree || {}).length > 0) {
				initialised.current = true;
			}
		},
		[tree]
	);

	const stateManager = {
		addGroupElement,
		answers: answers.current,
		answerSourceLookup: answerSourceLookup.current,
		complete,
		currentPage,
		dirtyFields: dirtyFields.current,
		fetchingComponents,
		generateSubmissionUpdate,
		processAttachmentUpload,
		initialiseWorkflowSession,
		isFinal,
		navigationStack,
		navigateToPage,
		nextStates,
		onFormStateChange,
		submitPage,
		persistantAnswers: persistantAnswers.current,
		removeGroupElement,
		sessionSubmitting,
		sessionUuid,
		setPage,
		setSessionSubmitting,
		setWorkflowComments,
		setWorkflowSubmission,
		setWorkflowTemplate,
		showSubmitError,
		tree,
		updateComments,
		workflow: workflowTemplate,
		workflowComments,
		workflowSubmission,
	};

	return stateManager;
}
