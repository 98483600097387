import React, { useEffect, useMemo, useState } from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/AddCircle';
import DataObjectIcon from '@mui/icons-material/DataObject';
import ImageIcon from '@mui/icons-material/Image';
import StorageIcon from '@mui/icons-material/Storage';

import BtError from '../../../components/generic/BtError';
import BtFormContainer from '../../../components/generic/forms/BtFormContainer';
import BtLoading from '../../../components/generic/BtLoading';
import BtSelectionTable from '../../../components/generic/BtSelectionTable';
import data_manager_breadcrumbs from '../DataManagerBreadcrumbs';
import data_manager_nav_item from '../DataManagerNavItem';
import data_manager_options from '../DataManagerOptions';
import { dataSourceGetList } from '../../../API';
import { INITIAL_SORT } from '../utils/constants';
import { LOOSE } from '../../../components/generic/table-utils/constants';
import NewDataSourceDialog from './NewDataSourceDialog';
import useAvailableToUser from '../../../hooks/useAvailableToUser';
import useFetch from '../../../hooks/useFetch';
import { useNavContext } from '../../../context/ContextManager';

const tableColumns = [
	{ field: 'icon', searchable: false, sortable: false, text: '' },
	{ field: 'name', text: 'Name' },
	{ field: 'description', text: 'Description', minBreakpoint: 'lg' },
	{ field: 'type', text: 'Type', minBreakpoint: 'md' },
];

export default function DataSources() {
	const checkAvailability = useAvailableToUser();
	const { setBreadcrumbs, setContextualNav } = useNavContext();

	const [newDataSourceDialogOpen, setNewDataSourceDialogOpen] = useState(
		false
	);

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				...data_manager_breadcrumbs,
				{ text: 'Data Sources', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([
				...data_manager_nav_item,
				...data_manager_options,
			]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	const {
		data: dataSources,
		loading,
		error,
		request: getDataSources,
	} = useFetch(dataSourceGetList);

	useEffect(
		() => {
			getDataSources();
		},
		[getDataSources]
	);

	const canCreateDataSet = useMemo(
		() =>
			checkAvailability({
				requiredPermissionsAll: {
					dataManager: ['DatasetCreate'],
				},
			}).available,
		[checkAvailability]
	);

	const canCreateDataStream = useMemo(
		() =>
			checkAvailability({
				requiredPermissionsAll: {
					dataManager: ['DataStreamCreate'],
				},
			}).available,
		[checkAvailability]
	);

	const canCreateImageRepo = useMemo(
		() =>
			checkAvailability({
				requiredPermissionsAll: {
					dataManager: ['ImageRepoCreate'],
				},
			}).available,
		[checkAvailability]
	);

	if (error) {
		return (
			<BtError
				action={() => getDataSources()}
				description="An error occurred when attempting to retrieve the data sources."
				title="Retrieval Error"
			/>
		);
	}

	return (
		<>
			<BtFormContainer title="Data Sources" maxWidth="lg">
				<BtLoading loading={loading}>
					{dataSources && (
						<BtSelectionTable
							title={
								<div
									style={{
										display: 'flex',
										flexWrap: 'wrap',
										gap: 10,
										justifyContent: 'space-between',
									}}
								>
									<Typography
										sx={{
											fontWeight: 'bold',
											padding: '4px 0',
										}}
										variant="h5"
									>
										{"Your organisation's Data Sources"}
									</Typography>
									{(canCreateDataSet ||
										canCreateDataStream ||
										canCreateImageRepo) && (
										<Button
											variant="contained"
											onClick={() =>
												setNewDataSourceDialogOpen(true)
											}
											startIcon={<AddIcon />}
											disableElevation
										>
											Add New Data Source
										</Button>
									)}
								</div>
							}
							subject="Data Sources"
							enableGeneralSorting
							enablePagination
							enableSearching
							initialSearchExactness={LOOSE}
							initialSort={INITIAL_SORT}
							debounceSearchInput={false}
							columns={tableColumns}
							data={(dataSources || []).map(
								({ uuid, name, description, type }) => ({
									icon:
										type === 'data_set' ? (
											<StorageIcon />
										) : type === 'image_repo' ? (
											<ImageIcon />
										) : (
											<DataObjectIcon />
										),
									name: name,
									description: description,
									type: type,
									route:
										type === 'data_set'
											? `/DataSources/DataSet/${uuid}`
											: type === 'image_repo'
												? `/DataSources/ImageRepo/${uuid}`
												: `/DataSources/DataStream/${uuid}`,
								})
							)}
						/>
					)}
				</BtLoading>
			</BtFormContainer>
			<NewDataSourceDialog
				canCreateDataSet={canCreateDataSet}
				canCreateDataStream={canCreateDataStream}
				canCreateImageRepo={canCreateImageRepo}
				existingNames={dataSources?.map(({ name }) => name)}
				open={newDataSourceDialogOpen}
				onClose={() => setNewDataSourceDialogOpen(false)}
			/>
		</>
	);
}
