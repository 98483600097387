import {
	// AUTOMATION,
	INSIGHT_COLLECTION,
	WORKFLOW_TEMPLATE,
} from '../constants';

const moduleUuidParam = module => {
	// declare the param options
	const uuidParams = {};
	// uuidParams[AUTOMATION] = '';
	uuidParams[INSIGHT_COLLECTION] = 'insightUuid';
	uuidParams[WORKFLOW_TEMPLATE] = 'workflowTemplateUuid';

	// lookup the result
	const result = uuidParams[module] || undefined;

	// provide console warning if no record
	!result &&
		console.warn(
			`Lookup error. No entry in moduleUuidParam table for '${module}'`
		);
	return result;
};

export { moduleUuidParam };
