import _ from 'lodash';

import { ADDED, COMPOUND_COMPONENTS, DELETED, UPDATED } from './constants';
// import { components as workflowComponents } from '../config/workflowComponents';

export const GROUP = 'Group';
export const PAGE = 'Page';
export const SECTION = 'Section';

export const convertToAnswerTree = tree => {
	const assessParentNode = parent => {
		const node = {};
		const { answers, children } = parent;

		const flattenSections = children => {
			const sectionChildren = children.filter(
				({ open, type }) => type === SECTION && open
			);

			const nonSectionChildren = children.filter(
				({ type }) => type !== SECTION
			);

			const flattened = sectionChildren.reduce(
				(accumulator, { children }) => [
					...accumulator,
					...flattenSections(children),
				],
				[]
			);

			return [...nonSectionChildren, ...flattened];
		};

		const unifiedChildren = flattenSections(children);

		unifiedChildren.forEach(child => {
			const { type, uuid } = child;

			if (type === GROUP) {
				const { children: groupChildren, childType, groupUuid } = child;

				groupChildren.forEach(groupChild => {
					const { uuid, page } = groupChild;

					const answer =
						childType === PAGE
							? assessParentNode(page)
							: answers[uuid];

					node[groupUuid] = [...(node[groupUuid] || []), answer];
				});
			} else if (type === PAGE) {
				node[uuid] = assessParentNode(child.page);
			} else {
				node[uuid] = answers[uuid];
			}
		});

		return node;
	};

	return assessParentNode(tree);
};

export const getLatestAnswer = logs => {
	if (!logs.some(({ action }) => action === DELETED)) {
		const updates = logs.filter(({ action }) => action === UPDATED);

		if (updates.length > 0) {
			const updates = logs.filter(({ action }) => action === UPDATED);

			if (updates.length > 0) {
				return updates[updates.length - 1].value;
			}
		}
	}

	return null;
};

const createPageLog = parent => {
	const { forwardLinkUuid, linkUuid, uuid } = parent;

	return {
		components: [],
		...(forwardLinkUuid ? { forwardLinkUuid } : {}),
		isRoot: !linkUuid,
		uuid: linkUuid ?? uuid,
	};
};

const isNotDeleted = logs =>
	logs?.length > 0 && logs[logs.length - 1].action !== DELETED;

const extractComments = component =>
	component?.comments?.map(
		comment => (({ editable, ...rest }) => rest)(comment) // eslint-disable-line
	) ?? [];

export const establishNewLogs = (oldPageLogs, editTree, sessionUuid) => {
	console.log({ oldPageLogs, editTree });
	let newPageLogs =
		Array.isArray(oldPageLogs) && oldPageLogs.length > 0
			? _.cloneDeep(oldPageLogs)
			: [];
	let openPages = [];

	const deleteAllContent = componentLog => {
		if (componentLog.grouped) {
			componentLog.content.forEach(element => {
				if (isNotDeleted(element.logs)) {
					element.logs = [
						...(element.logs || []),
						{ action: DELETED, sessionUuid },
					];
				}
			});

			return;
		}

		if (isNotDeleted(componentLog.content.logs)) {
			componentLog.content.logs = [
				...(componentLog.content.logs || []),
				{ action: DELETED, sessionUuid },
			];
		}
	};

	const analysePage = page => {
		let pageLog = null;
		let pageData = {
			answers: {},
			activeComponents: [],
		};
		openPages = [...openPages, page.linkUuid ?? page.uuid];

		pageLog = newPageLogs.find(
			({ forwardLinkUuid, uuid }) =>
				(uuid === page.linkUuid || uuid === page.uuid) &&
				forwardLinkUuid === page.forwardLinkUuid
		);

		pageData.answers = page.answers;

		if (!pageLog) {
			newPageLogs = [...newPageLogs, createPageLog(page)];

			pageLog = newPageLogs[newPageLogs.length - 1];
		}

		const desectionedChildren = (() => {
			const extractChildren = children => {
				const inputElements = children.filter(
					({ type }) => type !== SECTION
				);

				const nestedSectionElements = children
					.filter(
						({ open, openConditions, type }) =>
							type === SECTION && (open || !openConditions)
					)
					.reduce(
						(accumulator, section) => [
							...accumulator,
							...extractChildren(section.children),
						],
						[]
					);

				return [...inputElements, ...nestedSectionElements];
			};

			const pageContent = Object.getOwnPropertyDescriptor(page, 'page')
				? page.page
				: page;

			return extractChildren(pageContent.children);
		})();

		desectionedChildren.forEach(component => {
			const {
				children,
				childType,
				groupUuid,
				type,
				uuid: componentUuid,
			} = component;

			pageData.activeComponents = [
				...pageData.activeComponents,
				groupUuid ?? componentUuid,
			];

			const isCompound = COMPOUND_COMPONENTS.includes(type);

			const createContentLog = child => {
				const { repeatable, type, uuid: childUuid } = child;

				const initialAnswer = pageData.answers[childUuid];

				const logs = [
					{
						action: type === PAGE ? ADDED : UPDATED,
						sessionUuid,
						...(type !== PAGE ? { value: initialAnswer } : {}),
					},
				];

				const comments = extractComments(child);

				return {
					...(type !== PAGE ? { comments } : {}),
					logs,
					...(repeatable && !isCompound ? { uuid: childUuid } : {}),
				};
			};

			const createComponentLog = component => {
				let componentLog = {};
				let content;

				if (type === GROUP) {
					componentLog.uuid = groupUuid;

					content = children.map(createContentLog);
				} else {
					content = createContentLog(component);
				}

				return {
					uuid: groupUuid ?? componentUuid,
					nodeType:
						type === PAGE || childType === PAGE ? 'page' : 'input',
					grouped: type === GROUP,
					content: content,
				};
			};

			const foundLog = pageLog.components.find(({ uuid }) => {
				return (
					uuid === component.uuid ||
					uuid === component.linkUuid ||
					uuid === component.groupUuid
				);
			});

			const updateComponentLog = () => {
				if (type === GROUP) {
					let activeGroupElements = [];

					component.children.forEach(groupElement => {
						const foundElementLog = foundLog.content.find(
							({ uuid }) => uuid === groupElement.uuid
						);
						activeGroupElements = [
							...activeGroupElements,
							groupElement.uuid,
						];

						if (foundElementLog) {
							if (groupElement.type !== PAGE) {
								const previousAnswer = getLatestAnswer(
									foundElementLog.logs
								);
								const newAnswer =
									pageData.answers[groupElement.uuid];

								if (!_.isEqual(newAnswer, previousAnswer)) {
									foundElementLog.logs = [
										...(foundElementLog.logs || []),
										{
											action: UPDATED,
											sessionUuid: sessionUuid,
											value: newAnswer,
										},
									];
								}

								const comments = extractComments(groupElement);
								foundElementLog.comments = comments;
							}
						} else {
							foundLog.content = [
								...foundLog.content,
								createContentLog(groupElement),
							];

							const comments = extractComments(groupElement);
							foundLog.content.comments = comments;
						}
					});

					foundLog.content
						.filter(
							({ uuid }) => !activeGroupElements.includes(uuid)
						)
						.forEach(missingContent => {
							if (isNotDeleted(missingContent.logs)) {
								missingContent.logs = [
									...missingContent.logs,
									{
										action: DELETED,
										sessionUuid: sessionUuid,
									},
								];
							}
						});
				} else {
					if (!!foundLog && type !== PAGE) {
						const previousAnswer = getLatestAnswer(
							foundLog.content.logs
						);

						const newAnswer = pageData.answers[component.uuid];

						if (newAnswer !== previousAnswer) {
							foundLog.content.logs = [
								...(foundLog.content.logs || []),
								{
									action: UPDATED,
									sessionUuid: sessionUuid,
									value: newAnswer,
								},
							];
						}
					}

					const comments = extractComments(component);
					foundLog.content.comments = comments;
				}
			};

			if (foundLog) {
				updateComponentLog();
			} else {
				pageLog.components = [
					...pageLog.components,
					createComponentLog(component),
				];
			}
		});

		pageLog.components.forEach(componentLog => {
			if (!pageData.activeComponents.includes(componentLog.uuid)) {
				deleteAllContent(componentLog);
			}
		});

		// Analyse all of the page children nested in the current page
		desectionedChildren
			.filter(child => child.type === PAGE || child.childType === PAGE)
			.forEach(child => {
				if (child.type === GROUP) {
					child.children.forEach(groupChild => {
						analysePage(groupChild.page);
					});

					return;
				}

				analysePage(child.page);
			});
	};

	analysePage(editTree);

	newPageLogs.forEach(pageLog => {
		if (!openPages.includes(pageLog.uuid)) {
			pageLog.components.forEach(deleteAllContent);
		}
	});

	return newPageLogs;
};
