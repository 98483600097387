import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useAppContext, useNavContext } from '../../context/ContextManager';
import _ from 'lodash';

// MUI COMPONENTS
// import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

// MUI ICONS
import AddCircleIcon from '@mui/icons-material/AddCircle';

// CUSTOM COMPONENTS
import {
	ListTable,
	ListRow,
	ListHeader,
	ListCell,
} from '../../components/generic/ListTable';
import {
	SelectionTable,
	SelectionTableContainer,
	SelectionTableHead,
	SelectionTableHeadRow,
	SelectionTableBody,
	SelectionTableRow,
	SelectionTableCell,
} from '../../components/generic/BtSelectionTable';
import SectionHeader from '../../components/generic/SectionHeader';

// Data for landing page selection table and contextual navigation
import IoT_options from './IoTOptions';

import IoT_breadcrumbs from './IoTBreadcrumbs';

import IoT_nav_item from './IoTNavItem';

export default function ContainerTask({ match }) {
	const [task, setTask] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	// const { appOrg, setAppOrg} = useAppContext();
	const { appOrg } = useAppContext();
	const history = useHistory();
	const { setContextualNav } = useNavContext();
	const { setBreadcrumbs } = useNavContext();

	// Set breadcrumbs
	useEffect(
		() => {
			// To use task name
			setBreadcrumbs([
				...IoT_breadcrumbs,
				{ text: 'Container Tasks', link: '/ContainerTaskList' },
				{ text: task.name, link: '' },
			]);
		},
		[task.name, setBreadcrumbs]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([...IoT_nav_item, ...IoT_options]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	// Replaced with const deceleration
	// var task_uuid = match.params.uuid;
	const task_uuid = match.params.uuid;

	const handleClickNewTaskRev = () => {
		history.push(
			`/ContainerTaskNew/${task_uuid}/${task.latest_revision + 1}`
		);
	};

	const onTaskClick = revision => () => {
		history.push(`/ContainerTask/${task_uuid}/${revision}`);
	};

	useEffect(
		() => {
			var req_url = `${
				process.env.REACT_APP_API_SERVER_URL
			}/v1/container_task/${task_uuid}`;

			Auth.currentSession().then(user_session => {
				let user_token = user_session.getAccessToken();
				fetch(req_url, {
					method: 'GET',
					headers: {
						Authorization: user_token.jwtToken,
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Organisation: appOrg.uuid,
					},
				})
					.then(res => res.json())
					.then(response => {
						// Order the revisions in descending order
						let task = response.task;
						task.revisions = _.orderBy(
							response.task.revisions,
							'revision',
							'desc'
						);
						setTask(task);
						setIsLoading(false);
					})
					.catch(error => {
						console.log(error);
						setIsLoading(false);
					});
			});
		},
		[appOrg.uuid, task_uuid]
	);

	return (
		<>
			{isLoading && (
				<Container
					maxWidth="lg"
					sx={{ display: 'flex', flexDirection: 'column' }}
				>
					<Typography variant="h3" sx={{ m: 'auto' }}>
						Loading...
					</Typography>
					<CircularProgress sx={{ m: 'auto' }} size={'120px'} />
				</Container>
			)}

			{!isLoading && (
				<Container maxWidth="lg">
					<SectionHeader>Task: {task.name}</SectionHeader>
					<ListTable>
						<ListRow>
							<ListHeader>Description:</ListHeader>
							<ListCell>{task.description}</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>Latest Revision:</ListHeader>
							<ListCell>{task.latest_revision}</ListCell>
						</ListRow>
					</ListTable>

					<Button
						variant="outlined"
						startIcon={<AddCircleIcon />}
						onClick={handleClickNewTaskRev}
					>
						Add New Task Revision
					</Button>

					<SelectionTableContainer component={Box}>
						<SelectionTable aria-label="Container task revision table">
							<SelectionTableHead>
								<SelectionTableHeadRow>
									<SelectionTableCell>
										Name
									</SelectionTableCell>
									<SelectionTableCell>
										Revision
									</SelectionTableCell>
									<SelectionTableCell>
										Last Updated
									</SelectionTableCell>
									<SelectionTableCell>
										Status
									</SelectionTableCell>
								</SelectionTableHeadRow>
							</SelectionTableHead>
							<SelectionTableBody>
								{task.revisions.map((task_rev, index) => (
									<SelectionTableRow
										key={index}
										onClick={onTaskClick(task_rev.revision)}
										title={task.name}
									>
										<SelectionTableCell
											component="th"
											scope="row"
										>
											{task.name}
										</SelectionTableCell>
										<SelectionTableCell>
											{task_rev.revision}
										</SelectionTableCell>
										<SelectionTableCell>
											{new Date(
												task_rev.timestamp
											).toLocaleString()}
										</SelectionTableCell>
										<SelectionTableCell>
											{task_rev.status}
										</SelectionTableCell>
									</SelectionTableRow>
								))}
							</SelectionTableBody>
						</SelectionTable>
					</SelectionTableContainer>
				</Container>
			)}
		</>
	);
}
