import React, { useEffect, useMemo, useRef } from 'react';
import {
	Cell,
	Legend,
	Pie,
	PieChart,
	RadialBar,
	RadialBarChart,
	ResponsiveContainer,
	Tooltip,
} from 'recharts';
import PropTypes from 'prop-types';

import { CustomLegend } from './Generic/Legend';
import { Box, Typography, useTheme } from '@mui/material';
import { RADIAL_BAR_CHART } from '../../InsightPage/visualisationConstants';
import { CustomTooltip } from './Generic/Tooltip';
import useResizeObserver from 'use-resize-observer';
import { ConsoleNetworkOutline } from 'mdi-material-ui';
import { GaugeChart } from '../../../../components/charts/Charts/GaugeChart';
import { GaugePrimaryAxis } from '../../../../components/charts/Components/GaugePrimaryAxis';
import { LINEAR } from '../../../../API/validations/insightVisualizationsValidation';
// const data = [
// 	{ name: 'A', value: 80, color: '#ff0000' },
// 	{ name: 'B', value: 45, color: '#00ff00' },
// 	{ name: 'C', value: 25, color: '#0000ff' },
// ];

const startAngle = 180;

const endAngle = 0;

const RADIAN = Math.PI / 180;

const needle = (value, data, cx, cy, iR, oR, color) => {
	// console.log(RADIAN);
	let total = 0;
	data.forEach(v => {
		total += v.value;
	});
	const r = 5;
	const ang = 180 * (1 - value / total);
	// const ang = gaugeSweep * (1 - value / total);

	const length = (iR + 2 * oR) / 3;
	// const length = oR;

	const sin = Math.sin(-RADIAN * ang);
	const cos = Math.cos(-RADIAN * ang);

	// console.log({ ang, RADIAN, sin, cos });

	const xba = cx + r * sin;
	const yba = cy - r * cos;
	const xbb = cx - r * sin;
	const ybb = cy + r * cos;
	const xp = cx + length * cos;
	const yp = cy + length * sin;

	return [
		<circle key={1} cx={cx} cy={cy} r={r} fill={color} stroke="none" />,
		// <rect key={1} x={cx} y={cy} width={iR} height={iR} />,
		// <line key={1} x1={cx} y1={cy} x2={xp} y2={yp} stroke="black" />,
		<path
			key={2}
			// d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
			d={`M${xba} ${yba} L${xbb} ${ybb} L${xp} ${yp}  L${xba} ${yba}`}
			stroke="#none"
			fill={color}
		/>,
		<text
			key={3}
			x={cx}
			y={cy + 30}
			// dy={displayNameLabel ? 16 : 0}
			textAnchor={'middle'}
			fill="black"
		>
			{value}
		</text>,
	];
};

// variants - linear and radial
// axes - either primary and secondary(with linear and radial variants) or
//  primaryRadial, secondaryRadial, primaryLinear, secondaryLinear
// needle - either linear or radial

export default function GaugeComponent({ visOptions, palette }) {
	const containerRef = useRef(null);
	const { height, width } = useResizeObserver({ ref: containerRef });
	const theme = useTheme();
	// const oR = useMemo(() => (width ? width / 2 : null), [width]);

	const value = 660000;

	const bands = [
		{
			name: 'A',
			min: 0,
			max: 10,
			color: theme.palette.indicators.success.main,
		},
		{
			name: 'B',
			min: 10,
			max: 20,
			color: theme.palette.indicators.warning.main,
		},
		{
			name: 'C',
			min: 20,
			max: 30,
			color: theme.palette.indicators.error.main,
		},
	];

	const axisBounds = {
		min: 0,
		max: 1000000,
	};

	const oR = useMemo(
		() => {
			const evalLesserAxis = (height, width) => {
				if (height < width) {
					if (width * 0.5 < height) {
						return width * 0.5;
					} else {
						return height;
					}
				}
				if (width < height) {
					if (width * 0.5 < height) {
						return width * 0.5;
					} else {
						return height;
					}
				}
			};

			return width && height ? evalLesserAxis(height, width) * 0.8 : null;
		},
		[height, width]
	);

	const cy = useMemo(() => (height ? height / 2 + oR / 2 : null), [
		height,
		oR,
	]);

	const cx = useMemo(() => (width ? width / 2 : null), [width]);

	const iR = useMemo(() => (oR ? oR * 0.8 : null), [oR]);

	// old code above

	const variant = visOptions.variant;

	return (
		<GaugeChart>
			{/* {[1].map((value, index) => (
				<GaugePrimaryAxis key={index} fill={palette[index]} />
			))} */}
			<GaugePrimaryAxis
				bands={bands}
				axisBounds={axisBounds}
				palette={palette}
				variant={variant}
			/>
		</GaugeChart>
	);

	return (
		<Box
			ref={containerRef}
			sx={{
				width: '100%',
				height: '100%',
				// backgroundColor: 'violet',
			}}
		>
			<ResponsiveContainer width="100%" height="100%">
				<PieChart>
					<Pie
						dataKey="value"
						startAngle={startAngle}
						endAngle={endAngle}
						data={data}
						// Without his offset of 5 the chart doesn't align with it's container
						cx={cx - 5}
						cy={cy - 5}
						// cx="50%"
						// cy="50%"
						innerRadius={iR}
						outerRadius={oR}
						fill="#8884d8"
						stroke="none"
						label
					>
						{data.map((entry, index) => (
							<Cell key={`cell-${index}`} fill={entry.color} />
						))}
					</Pie>
					{/* <Needle
						value={value}
						data={data}
						cx={cx}
						cy={cy}
						iR={iR}
						oR={oR}
						color={palette[0]}
					/> */}
					{needle(value, data, cx, cy, iR, oR, palette[0])}
				</PieChart>
			</ResponsiveContainer>
			{/* <Box>
				<Typography>{value}</Typography>
			</Box> */}
		</Box>
	);
}

GaugeComponent.propTypes = {
	data: PropTypes.array.isRequired,
	visOptions: PropTypes.object.isRequired,
	palette: PropTypes.array.isRequired,
};
