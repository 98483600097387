import React, { useCallback, useMemo } from 'react';
import { useChartsContext } from '../contexts/ChartsContexts';
import { useComponentsContext } from './Chart';
// import { getTextDimensions } from '../Utils';
import { get } from 'lodash';
import { useTheme } from '@mui/styles';
import propTypes from 'prop-types';

const resolveEndAngle = (max, value, _min, maxAngle) => {
	if (max < value) {
		return maxAngle;
	}
	return (maxAngle * (value - _min)) / (max - _min);
};

const getOuterRadius = (externalRadius, index, barWidth, barGap) => {
	const outerRadius = externalRadius - [index * (barWidth + barGap)];
	return outerRadius;
};

export function RadialBar({
	dataKey,
	fill,
	index,
	min,
	max,
	background,
	label,
	maxAngle,
}) {
	const { onMouseOver } = useChartsContext();
	const theme = useTheme();
	const {
		data,
		radialProperties: { minimumAxisBisection, cx, cy },
	} = useComponentsContext();
	// const [mouseOver, setMouseOver] = useState(false);

	// const maxAngle = useMemo(() => (label ? 270 : 359), [label]);

	// TODO: extend the generic 'sector' component to handle drawing this
	const getBarAttributes = useCallback(
		isBackground => {
			// responsive barWidth and barGap
			const barWidth = (minimumAxisBisection / 6) * 0.8;
			const barGap = (minimumAxisBisection / 6) * 0.2;

			// get the value for the current dataKey and index
			const value = get(data, `[${index}][${dataKey}]`, min);

			// ensure that the min value is not greater than the max value
			const _min = min > max ? value : min;

			const RADIAN = Math.PI / 180;

			// calculate the end angle of the bar by determining the percentage of the value
			// once we are over 100% we set the end angle to 359 degrees
			// const endAngle = resolveEndAngle(max, value, _min);
			const endAngle = isBackground
				? maxAngle
				: resolveEndAngle(max, value, _min, maxAngle);

			const sin = Math.sin(RADIAN * (endAngle - 90));
			const cos = Math.cos(RADIAN * (endAngle - 90));

			// calculate the outer and inner radius of the bar
			const outerRadius = getOuterRadius(
				minimumAxisBisection,
				index,
				barWidth,
				barGap
			);
			const innerRadius = outerRadius - barWidth;

			const sx = cx + outerRadius * cos;
			const sy = cy + outerRadius * sin;

			const sx2 = cx + innerRadius * cos;
			const sy2 = cy + innerRadius * sin;

			const path = `M ${cx} ${cy - innerRadius} A ${barWidth /
				2} ${barWidth / 2} 0 1 1 ${cx} ${cy -
				outerRadius} A ${outerRadius} ${outerRadius} 0 ${
				endAngle > 180 ? 1 : 0
			} 1 ${sx} ${sy} A ${barWidth / 2} ${barWidth /
				2} 0 1 1 ${sx2} ${sy2} A ${innerRadius} ${innerRadius} 0 ${
				endAngle > 180 ? 1 : 0
			} 0 ${cx} ${cy - innerRadius} `;

			// coordinates for the label
			const lx = cx - barWidth * 0.9;
			const ly = cy - innerRadius - barWidth / 2;

			return { path, lx, ly, barWidth };
		},
		[minimumAxisBisection, data, index, dataKey, min, max, maxAngle, cx, cy]
	);

	const backgroundBarPath = useMemo(() => getBarAttributes(true).path, [
		getBarAttributes,
	]);

	const barAttr = useMemo(() => getBarAttributes(), [getBarAttributes]);

	return (
		<g
			onMouseEnter={() => {
				// setMouseOver(true);
				onMouseOver([true]);
				// console.log('mouse over');
			}}
			onMouseLeave={() => {
				// setMouseOver(false);
				onMouseOver(null);
			}}
		>
			{background && <path fill={background} d={backgroundBarPath} />}
			<path fill={fill} d={barAttr.path} />
			{label && (
				<text
					fill={theme.palette.text.primary}
					dominantBaseline={'middle'}
					textAnchor={'end'}
					x={barAttr.lx}
					y={barAttr.ly}
					style={{ whiteSpace: 'pre' }}
					fontWeight={400}
					fontSize={Math.min(
						Math.max(barAttr.barWidth * 0.5, 14),
						16
					)}
				>
					{label}
				</text>
			)}
		</g>
	);
}

RadialBar.propTypes = {
	dataKey: propTypes.string.isRequired,
	fill: propTypes.string.isRequired,
	index: propTypes.number.isRequired,
	min: propTypes.number.isRequired,
	max: propTypes.number.isRequired,
	background: propTypes.string,
	label: propTypes.string,
	maxAngle: propTypes.number.isRequired,
};
