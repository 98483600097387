import { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useScreensConfigContext } from '../../ScreenConfigContext';
import { TilesAutocomplete } from '../../../formComponents/TilesAutocomplete';
import NewScreenRadioGroup from '../NewScreenRadioGroup';
import { useAppContext } from '../../../../../../../context/ContextManager';
import { ScreensContext } from '../../../../ScreensContext';
import BtMessage from '../../../../../BtMessage';
import BtRedactor from '../../../../../../BtRedactor';

/**
 *
 * @param {object} addScreenParams
 * @returns string to initialise the screen type checkbox component
 */
const initCheckbox = addScreenParams => {
	return addScreenParams.type === null ? 'blank' : addScreenParams.type;
};

/**
 *
 * @param {object} addScreenParams
 * @param {array} screensToCopyOptions
 * @param {string} type org || user
 * @returns {object} object to initialize the copy screen component
 */
const initCopyScreen = (addScreenParams, options, type) => {
	switch (type) {
		case 'copy':
			return addScreenParams.copyScreenReference === null
				? options[0]
				: options.find(
						option =>
							option.uuid ===
							addScreenParams.copyScreenReference.uuid
				  );

		case 'addScreen':
			return addScreenParams.addScreenReference === null
				? options[0]
				: options.find(
						option =>
							option.uuid ===
							addScreenParams.addScreenReference.uuid
				  );
		default:
			break;
	}
};

export const ScreenType = () => {
	const {
		addScreenParams,
		setAddScreenParams,
		setAddNextStepDisabled,
		addOptions,
		screenCount,
		copyScreenOptions,
	} = useScreensConfigContext();
	const { deviceType } = useAppContext();
	const [checkboxValue, setCheckboxValue] = useState(
		initCheckbox(addScreenParams)
	);
	const [userScreen, setUserScreen] = useState(
		initCopyScreen(addScreenParams, copyScreenOptions, 'copy')
	);
	const [addScreen, setAddScreen] = useState(
		initCopyScreen(addScreenParams, addOptions, 'addScreen')
	);
	const [addScreenError, setAddScreenError] = useState(false);
	const [userScreenError, setUserScreenError] = useState(false);
	const { userPermissions } = useContext(ScreensContext);

	// const userScreenOptions = useMemo(
	// 	() => addOptions.filter(({ ownerType }) => ownerType === 'User'),
	// 	[addOptions]
	// );

	// update the context params when the checkbox value updates
	useEffect(
		() => {
			let newParams = { ...addScreenParams };
			newParams.type = checkboxValue;
			if (checkboxValue === 'copy') {
				newParams.backgroundColor = userScreen
					? userScreen.background_color
					: null;
				newParams.position = screenCount + 1;
				newParams.userScreen = userScreen;
				newParams.copyScreenReference = userScreen;
			}
			if (checkboxValue === 'addScreen') {
				newParams.backgroundColor = addScreen
					? addScreen.background_color
					: null;
				if (addScreen?.existingPosition) {
					newParams.position = addScreen.existingPosition;
				} else if (addScreen?.ownerType === 'user') {
					newParams.position = screenCount + 1;
				} else if (addScreen?.ownerType === 'organisation') {
					newParams.position = 0;
				} else {
					newParams.position = screenCount + 1;
				}

				newParams.addScreenReference = addScreen;
			}
			if (checkboxValue === 'blank') {
				newParams.backgroundColor = null;
				newParams.position = screenCount + 1;
				newParams.existingPosition = screenCount + 1;
				newParams.newScreenName = '';
			}
			setAddScreenParams(newParams);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[checkboxValue]
	);

	useEffect(
		() => {
			if (!userPermissions.ScreenCreate) {
				if (addOptions.length === 0) {
					setAddNextStepDisabled(true);
				}
				setCheckboxValue('addScreen');
				setAddScreen(addOptions[0]);
			}
		},
		[addOptions, setAddNextStepDisabled, userPermissions]
	);

	// update the context params when the user-screen or
	// the org-screen-to-use value updates
	useEffect(
		() => {
			let newParams = { ...addScreenParams };
			newParams.copyScreenReference = userScreen;
			newParams.addScreenReference = addScreen;
			newParams.backgroundColor = userScreen
				? userScreen.background_color
				: null;
			newParams.newScreenName = userScreen
				? userScreen.label + ' copy'
				: null;

			setAddScreenParams(newParams);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[userScreen, addScreen]
	);

	// checkbox control
	const handleChange = event => {
		setCheckboxValue(event.target.value);
	};

	// validation -> if new screen is a copy or an org screen
	// then a value is required from the relevant autocomplete
	useEffect(
		() => {
			const isValid =
				(checkboxValue === 'copy' && userScreen !== null) ||
				(checkboxValue === 'addScreen' && addScreen !== null) ||
				checkboxValue === 'blank';
			setAddNextStepDisabled(!isValid);
		},
		[userScreen, checkboxValue, addScreen, setAddNextStepDisabled]
	);

	// setting error for org screen
	useEffect(
		() => {
			const error = checkboxValue === 'addScreen' && addScreen === null;
			setAddScreenError(error);
		},
		[checkboxValue, addScreen]
	);

	// setting error for user screen
	useEffect(
		() => {
			const error = checkboxValue === 'copy' && userScreen === null;
			setUserScreenError(error);
		},
		[checkboxValue, userScreen]
	);

	return (
		<>
			{/* {userPermissions.screenCreate && ( */}
			<BtRedactor requiredPermissionsAll={{ screens: ['ScreenCreate'] }}>
				<NewScreenRadioGroup
					deviceType={deviceType}
					onChange={handleChange}
					value={checkboxValue}
					isAddDisabled={addOptions.length === 0}
					isCopyDisabled={copyScreenOptions.length === 0}
				/>
			</BtRedactor>

			{/* // )} */}
			<div
				style={{
					height: 70,
					marginTop: 5,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				{checkboxValue === 'copy' && (
					<TilesAutocomplete
						onChange={setUserScreen}
						// hidden={checkboxValue !== 'copy'}
						options={copyScreenOptions}
						value={userScreen}
						label="Select a screen to copy"
						error={userScreenError}
						errorMessage="Screen to copy is required"
					/>
				)}
				{(checkboxValue === 'addScreen' ||
					!userPermissions.ScreenCreate) && (
					<TilesAutocomplete
						onChange={setAddScreen}
						// hidden={checkboxValue !== 'addScreen'}
						// options={addScreenOptions}
						showDescription
						options={addOptions}
						value={addScreen}
						label="Select a screen to add"
						error={addScreenError}
						errorMessage="Screen selection is required"
						disabled={addOptions.length === 0}
					/>
				)}
			</div>
		</>
	);
};
