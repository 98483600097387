import { insightCollectionDraftUpdate } from '../../../../API';

export default async function updateDraft(
	update,
	insightCollectionUuid,
	initialize
) {
	// Initialise return value
	const result = {};
	result.variant = 'success';
	console.log(update);
	try {
		await insightCollectionDraftUpdate({
			insightUuid: insightCollectionUuid,
			draftUpdate: update,
		});
		// Update return value
		result.text = `Draft status updated`;
	} catch (error) {
		console.log(error);
		result.text = `Error changing updating draft`;
		result.variant = 'error';
	}
	// recordHistoryGet();
	initialize();
	return result;
}
