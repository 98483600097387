import { insightCollectionDraftUpdate } from '../../../../../API';

/**
 *
 * @param {object} visualisation
 * @param {object[]} visualisations
 * @returns the visualisations array with the visualisation replacing the entry with matching uuid
 */
const updateVisualisations = (visualisation, visualisations) => {
	const visIndex = visualisations.findIndex(
		({ uuid }) => uuid === visualisation.uuid
	);
	const visualisationsUpdate = visualisations.toSpliced(
		visIndex,
		1,
		visualisation
	);
	return visualisationsUpdate;
};

/**
 * Adds the visualisation to the visualisations array and dispatches the update to the API
 * @param {object} visualisation
 * @param {object[]} visualisations
 * @param {*} id collection uuid
 */
const handleUpdateDraft = async (
	visualisations,
	visualisation,
	id,
	enqueueSnackbar,
	onClose,
	refreshCollectionData
) => {
	const visualisationsUpdate = updateVisualisations(
		visualisation,
		visualisations
	);
	const draftUpdate = { visualisations: visualisationsUpdate };
	await updateDraft(
		draftUpdate,
		id,
		enqueueSnackbar,
		onClose,
		refreshCollectionData
	);
};

/**
 * Makes the API request
 * @param {object} update
 * @param {string} id collection uuid
 */
const updateDraft = async (
	update,
	id,
	enqueueSnackbar,
	onClose,
	refreshCollectionData
) => {
	try {
		await insightCollectionDraftUpdate({
			insightUuid: id,
			draftUpdate: update,
		});

		enqueueSnackbar('Insight updated!', {
			variant: 'success',
		});
		onClose();
		refreshCollectionData();
	} catch (error) {
		if (process.env.NODE_ENV === 'development') {
			console.log(error);
		}
		enqueueSnackbar('Error updating insight', {
			variant: 'error',
		});
	}
};

export { handleUpdateDraft };
