import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';
import {
	AUTOMATION,
	INSIGHT_COLLECTION,
	WORKFLOW_TEMPLATE,
} from './utils/constants';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useAppContext } from '../../../context/ContextManager';
import { handleGetHistory } from './utils/requests/getRecordHistory';
import {
	contextInitializer,
	defaultDraftLockValues,
	defaultStaticContextValues,
} from './utils/initializers/contextInitializer';
import { callbackHandler } from './utils/callbacks/callbackHandler';
import { initDraftUser } from './utils/initializers/initDraftUser';

const RecordManagementContext = createContext(null);

const useRecordManagementContext = () => {
	const context = useContext(RecordManagementContext);
	if (context === undefined) {
		throw new Error(
			'useRecordManagementContext was used outside of its Provider'
		);
	}
	return context;
};

function RecordManagementContextProvider({ recordUuid, module, children }) {
	const [loading, setLoading] = useState(true);
	const [sending, setSending] = useState(false);
	const [error, setError] = useState(false);
	const [draftLockStatus, setDraftLockStatus] = useState(
		defaultDraftLockValues
	);
	const [historyData, setHistoryData] = useState(null);
	const [orgUsers, setOrgUsers] = useState(null);
	const staticValues = useRef(defaultStaticContextValues);
	const { userInfo, setActivityIndicator } = useAppContext();
	const history = useHistory();

	const { enqueueSnackbar } = useSnackbar();
	const [snackbarProps, setSnackbarProps] = useState(null);

	const { appOrg } = useAppContext();

	// Initialisation effect
	useEffect(() => {
		console.log('INIT RECORD MANAGER CONTEXT');
		const init = async () => {
			const result = await contextInitializer({
				module,
				recordUuid,
				userInfo,
				setError,
				appOrg,
			});

			setHistoryData(result.historyData);
			setDraftLockStatus({
				status: result.draftLockStatus,
				lockUser: result.lockUser,
			});
			setOrgUsers(result.orgUsers);

			staticValues.current = {
				getRecordHistoryRequest: result.getRecordHistoryRequest,
				leavePayload: result.leavePayload,
				apiUuidParamPayload: result.apiUuidParamPayload,
				moduleAssets: result.moduleAssets,
			};

			setLoading(false);
		};
		init();
	}, []);

	// function to get the history and initialize required state
	// also handles loading and error states
	const recordHistoryGet = useCallback(
		async () => {
			// console.log('record history refresh');
			// get the latest history
			const result = await handleGetHistory({
				apiUuidParamPayload: staticValues.current.apiUuidParamPayload,
				getRecordHistoryRequest:
					staticValues.current.getRecordHistoryRequest,
				setError,
			});
			// evaluate draft lock status
			const { lockUser, draftLockStatus } = await initDraftUser(
				result.draft.locked_to,
				userInfo,
				setError
			);
			setDraftLockStatus({
				status: draftLockStatus,
				lockUser: lockUser,
			});
			setHistoryData(result);
			setSending(false);
		},
		[userInfo]
	);

	// Function for 'back' button
	const handleLeave = useCallback(
		() => {
			history.push(staticValues.current.leavePayload);
		},
		[history]
	);

	// effect to trigger snackbar
	// this is here so the snackbar shows after
	// sending is done
	useEffect(
		() => {
			if (!sending && snackbarProps) {
				enqueueSnackbar(snackbarProps.message, {
					variant: snackbarProps.variant,
				});
				setSnackbarProps(null);
			}
		},
		[enqueueSnackbar, sending, snackbarProps]
	);

	// Handler for most callbacks
	const handleCallback = useCallback(
		async ({ callback, mode, nextLockUser, payload }) => {
			await callbackHandler({
				callback,
				status: historyData.status,
				apiUuidParamPayload: staticValues.current.apiUuidParamPayload,
				recordHistoryGet,
				moduleFullName:
					staticValues.current.moduleAssets.moduleFullName,
				module,
				setSnackbarProps,
				setSending,
				mode,
				nextLockUser,
				payload,
			});
		},
		[historyData, module, recordHistoryGet]
	);

	// watch the sending state and set the app activity indicator accordingly
	useEffect(
		() => {
			if (sending) {
				setActivityIndicator(true);
			} else {
				setActivityIndicator(false);
			}
		},
		[setActivityIndicator, sending]
	);

	return (
		<RecordManagementContext.Provider
			value={{
				module,
				loading,
				sending,
				historyData,
				error,
				handleLeave,
				moduleAssets: staticValues.current.moduleAssets,
				handleCallback,
				draftLockStatus: draftLockStatus.status,
				lockUser: draftLockStatus.lockUser,
				orgUsers,
				recordUuid,
			}}
		>
			{children}
		</RecordManagementContext.Provider>
	);
}

export { RecordManagementContextProvider, useRecordManagementContext };

RecordManagementContextProvider.propTypes = {
	module: PropTypes.oneOf([INSIGHT_COLLECTION, AUTOMATION, WORKFLOW_TEMPLATE])
		.isRequired,
	recordUuid: PropTypes.string.isRequired,
};
