import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, styled } from '@mui/material';

const ContainerBox = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	height: `calc(100% - 25px)`,
}));

const StyledTypography = styled(Typography)(() => ({
	margin: 'auto',
	height: '100%',
	overflow: 'scroll',
}));

export default function InfoCardComponent({ visOptions }) {
	const content = useMemo(
		() =>
			visOptions.text ||
			'Nothing to show here, edit the insight and ad some text..',
		[visOptions.text]
	);

	return (
		<ContainerBox>
			<StyledTypography variant="h6">{content}</StyledTypography>
		</ContainerBox>
	);
}

InfoCardComponent.propTypes = {
	visOptions: PropTypes.object.isRequired,
};
