import React from 'react';
import { useTheme } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

export const Accordion = props => {
	const theme = useTheme();
	return (
		<MuiAccordion
			disableGutters={true}
			elevation={0}
			square
			sx={{
				border: `1px solid ${theme.palette.divider}`,
				//'&:not(:last-child)': {borderBottom: 0},	TODO: This should be for the parent else the botton boarder of all accordion elements are effected
				'&:before': { display: 'none' },
			}}
			{...props}
		/>
	);
};

export const AccordionSummary = props => {
	const theme = useTheme();
	return (
		<MuiAccordionSummary
			expandIcon={
				<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />
			}
			elevation={0}
			sx={{
				backgroundColor:
					theme.palette.mode === 'dark'
						? 'rgba(255, 255, 255, .05)'
						: 'rgba(0, 0, 0, .03)',
				flexDirection: 'row-reverse',
				'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
					transform: 'rotate(90deg)',
				},
				'& .MuiAccordionSummary-content': {
					marginLeft: theme.spacing(1),
				},
			}}
			{...props}
		/>
	);
};

export const AccordionDetails = props => {
	const theme = useTheme();
	return (
		<MuiAccordionDetails
			elevation={0}
			square="true"
			sx={{
				padding: theme.spacing(2),
				borderTop: '1px solid rgba(0, 0, 0, .125)',
			}}
			{...props}
		/>
	);
};
