import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import {
	ChartTimeline,
	ChartTimelineVariantShimmer,
	Home,
} from 'mdi-material-ui';
import { useCallback, useContext } from 'react';
import BtIconRadio from '../../../../components/generic/BtIconRadio';
import BtMessage from '../../../../components/generic/BtMessage';
import {
	useWizard,
	WizardButtons,
	WizardHeading,
} from '../../../../components/wizard';
import { ScreenDistributionWizardContext } from './ScreenDistributionWizardContext';

export const ScreenGroups = () => {
	const { data, onClose, setData, resetScreenGroups } = useContext(
		ScreenDistributionWizardContext
	);

	const {
		isFirstStep,
		goToStep,
		isLastStep,
		nextStep,
		previousStep,
	} = useWizard();

	const handleChange = useCallback(
		event => {
			const newData = structuredClone(data);
			newData.screenGroup = event.target.value;
			setData(newData);
		},
		[data, setData]
	);

	const handleGoForward = useCallback(
		() => {
			// Skip screen group selection if method is available
			if (data.method === 'assign') {
				nextStep();
			} else {
				goToStep(2);
			}
		},
		[data, goToStep, nextStep]
	);

	const handleGoBack = useCallback(
		() => {
			resetScreenGroups();
			previousStep();
		},
		[previousStep, resetScreenGroups]
	);

	return (
		<>
			<DialogTitle>
				<WizardHeading
					onCancelClick={onClose}
					title="Select Screen Groups for assignment"
				/>
			</DialogTitle>
			<DialogContent>
				<BtMessage
					style={{ marginBottom: '1em' }}
					message="Some users may not have access to all Screen Groups."
				/>
				<BtIconRadio
					value={data.screenGroup}
					onChange={handleChange}
					items={[
						{
							value: 'home',
							label: 'Home',
							icon: <Home />,
						},
						{
							value: 'workflows',
							label: 'Workflows',
							icon: <ChartTimeline />,
						},
						{
							value: 'insights',
							label: 'Insights',
							icon: <ChartTimelineVariantShimmer />,
						},
					]}
					name="select_screen_group"
				/>
			</DialogContent>
			<DialogActions>
				<WizardButtons
					onCancelClick={onClose}
					isFirstStep={isFirstStep}
					isLastStep={isLastStep}
					onNextClick={() => handleGoForward()}
					onPreviousClick={() => handleGoBack()}
				/>
			</DialogActions>
		</>
	);
};
