// Component attributes
export const NON_COMMENTABLE = ['Label', 'Page'];
export const NON_VALIDATABLE = ['Group', 'Page', 'Section'];
export const NON_WRAPPABLE = ['Group', 'Section'];

// Component statuses
export const ADDED = 'added';
export const ARCHIVED = 'archived';
export const DELETED = 'deleted';
export const UPDATED = 'updated';
export const WITHDRAWN = 'withdrawn';

// Validation statuses
export const INVALID = 'invalid';
export const NOT_ACCESSED = 'not_accessed';
export const VALID = 'valid';

// Components
export const BARCODE = 'Barcode';
export const CHECKBOX = 'Checkbox';
export const DATE = 'Date';
export const DATETIME = 'DateTime';
export const GROUP = 'Group';
export const IMAGE = 'Image';
export const LABEL = 'Label';
export const MULTI_LINE_TEXT_FIELD = 'MultiLineTextField';
export const NUMBER_FIELD = 'NumberField';
export const PAGE = 'Page';
export const SECTION = 'Section';
export const SELECT = 'Select';
export const SIGNATURE = 'Signature';
export const TEXT_FIELD = 'TextField';
export const TIME = 'Time';

// Component configurations
export const COMPOUND_COMPONENTS = [IMAGE];
export const FULL_WIDTH_COMPONENTS = [IMAGE, SIGNATURE];
