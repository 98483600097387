import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import BtHeightAnimator from '../../../../components/generic/BtHeightAnimator';

import { ARRAY, OBJECT } from '../../../../utils/commonDataTypes';
import BtPopover from '../../../../components/generic/BtPopover';
import DataTypePane from './DataTypePane';
import FieldNamePane from './FieldNamePane';
import { styled } from '@mui/material';

const PannerContainer = styled('div')(({ count }) => ({
	display: 'grid',
	gridTemplateColumns: `repeat(${count}, 100%)`,
	height: 'auto',
	overflow: 'hidden',
	position: 'relative',
	width: 240,
}));

const Pane = styled('div')(({ focused, step, theme }) => ({
	height: focused ? 'auto' : 0,
	marginLeft: `-${100 * step}%`,
	overflow: 'visible',
	position: 'relative',
	width: '100%',

	transition: theme.transitions.create(['margin'], {
		duration: 250,
	}),
}));

const Panner = ({ panes, step }) => {
	return (
		<PannerContainer count={panes.length}>
			{panes.map((pane, index) => (
				<Pane key={index} focused={+(step === index)} step={step}>
					{pane}
				</Pane>
			))}
		</PannerContainer>
	);
};

const AddField = ({ onAddField, onClose, popoverRef, takenKeys }) => {
	const [step, setStep] = useState(0);

	const [fieldName, setFieldName] = useState('');
	const [arrayCount, setArrayCount] = useState(0);

	const handleSubmit = useCallback(
		type => {
			let arraysProcessed = arrayCount;

			const processContent = contentObject => {
				if (arraysProcessed === 0) {
					return {
						...contentObject,
						type,
						...(type === OBJECT ? { objectContent: {} } : {}),
					};
				}

				arraysProcessed--;

				return { arrayContent: processContent({}), type: ARRAY };
			};

			const content = processContent({});

			onAddField({
				fieldName: fieldName.trim(),
				content,
			});

			onClose();
		},
		[arrayCount, fieldName, onAddField, onClose]
	);

	const onTypeSelect = useCallback(
		type => {
			if (type === ARRAY) {
				setArrayCount(prev => prev + 1);
				setStep(prev => prev + 1);

				return;
			}

			handleSubmit(type);
		},
		[handleSubmit]
	);

	const panes = useMemo(
		() => [
			<FieldNamePane
				key="field-name-pane"
				alreadyInUse={takenKeys.includes(fieldName.trim())}
				disabled={step !== 0}
				fieldName={fieldName}
				onChangeFieldName={newName => setFieldName(newName)}
				onSubmit={() => setStep(1)}
			/>,
			<DataTypePane
				key="data-type-pane"
				disabled={step !== 1}
				onBack={() => {
					setStep(prev => prev - 1);
				}}
				onTypeSelect={onTypeSelect}
				title="Field Type"
			/>,
			...(() => {
				let arraySteps = [];

				for (let count = 0; count < arrayCount; count++) {
					arraySteps = [
						...arraySteps,
						<DataTypePane
							key={`array-pane-${count}`}
							disabled={step !== count + 2}
							onBack={() => {
								setArrayCount(prev => prev - 1);
								setStep(prev => prev - 1);
							}}
							onTypeSelect={onTypeSelect}
							title="Array Content Type"
						/>,
					];
				}

				return arraySteps;
			})(),
			<div key="filler-array-pane" style={{ width: '100%' }} />, // Placeholder for animating array pane
		],
		[arrayCount, fieldName, onTypeSelect, step, takenKeys]
	);

	return (
		<BtHeightAnimator
			onAnimationFinish={() => popoverRef.current.updatePosition()}
		>
			<Panner panes={panes} step={step} />
		</BtHeightAnimator>
	);
};

export default function FieldPopover({
	anchorEl,
	onAddField,
	onClose,
	takenKeys,
}) {
	const popoverRef = useRef();

	return (
		<BtPopover ref={popoverRef} anchorEl={anchorEl} onClose={onClose}>
			<AddField
				onAddField={onAddField}
				onClose={onClose}
				popoverRef={popoverRef}
				takenKeys={takenKeys}
			/>
		</BtPopover>
	);
}

const sharedPropTypes = {
	onAddField: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	takenKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Panner.propTypes = {
	panes: PropTypes.arrayOf(PropTypes.node).isRequired,
	step: PropTypes.number.isRequired,
};

AddField.propTypes = {
	...sharedPropTypes,
	popoverRef: PropTypes.object,
};

FieldPopover.propTypes = {
	...sharedPropTypes,
	anchorEl: PropTypes.any,
};
