import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';

import {
	Chip,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	Input,
	InputAdornment,
	Tooltip,
	Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import FilterIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import Color from 'color';

import { BtChipsDisplay } from './BtChips';
import BtDialog from './BtDialog';
import BtList from './BtList';
import BtNoItems from './BtNoItems';
import BtQuickFilter from './BtQuickFilter';
import { tagApply, tagRemove } from '../../API';
import { tagGroupGet, tagGroupValueSearch } from '../../API/tags.api';
import { useAppContext } from '../../context/ContextManager';
import { useSnackbar } from 'notistack';

import BtLoading from './BtLoading';
import { getSafeColorForBackground } from '../../utils/colourUtils';
import useFetch from '../../hooks/useFetch';
import { useTheme } from '@mui/styles';
import BtResultGroup from './BtResultGroup';

const ARCHIVED = 'Archived';

export default function BtTags({
	initialTags,
	onTagsChange,
	partOfButton,
	readOnly,
	resourceId,
	resourceGroupId,
	singleLine,
	size,
}) {
	const loadedGroup = useRef();
	const filterStatus = useRef({});
	const lastSearch = useRef();

	const addTagApi = useFetch(tagApply);
	const getGroupApi = useFetch(tagGroupGet);
	const removeTagApi = useFetch(tagRemove);
	const { loading: searching, request: performSearch } = useFetch(
		tagGroupValueSearch
	);

	const { appOrg } = useAppContext();
	const { enqueueSnackbar } = useSnackbar();
	const theme = useTheme();

	const [group, setGroup] = useState();
	const [groupData, setGroupData] = useState();
	const [loading, setLoading] = useState(false);
	const [searchResults, setSearchResults] = useState();
	const [searchTerm, setSearchTerm] = useState('');
	const [showDialog, setShowDialog] = useState(false);
	const [tags, setTags] = useState();

	// Maps organisation uuids to tag group colours for quick lookup
	const colourMap = useMemo(
		() =>
			(appOrg?.tags || []).reduce(
				(accumulator, { displayColour, uuid }) => ({
					...accumulator,
					[uuid]: Color(
						theme.palette.colourPicker[displayColour?.toLowerCase()]
					),
				}),
				{}
			),
		[appOrg, theme]
	);

	// Set internal tags initially from tags provided by parent
	useEffect(
		() => {
			if (!tags) {
				setTags(
					(initialTags || []).filter(
						({ tagValueStatus }) => tagValueStatus !== ARCHIVED
					)
				);
			}
		},
		[colourMap, initialTags, tags]
	);

	// Tags with colours to be used for rendering
	const colourTags = useMemo(
		() =>
			tags?.map(tag => ({
				...tag,
				displayColour: colourMap[tag.tagGroupUuid],
			})),
		[colourMap, tags]
	);

	// The values and filter status to be used when a group filter is applied
	const tagGroupValues = useMemo(
		() => {
			// Values already added for the selected group
			const alreadyAdded = (tags || []).map(
				({ tagValueUuid }) => tagValueUuid
			);

			// Selectable values for selected group
			const notAdded = (groupData?.values || []).filter(
				({ uuid }) => !alreadyAdded.includes(uuid)
			);

			// Apply the text filter
			const filtered = notAdded.filter(value =>
				value.name
					.trim()
					.toLowerCase()
					.includes(searchTerm.trim().toLowerCase())
			);

			// Returns object containing values and filter statuses
			return {
				badFilter: notAdded.length > 0 && filtered.length === 0,
				emptyGroup: (groupData?.values || []).length === 0,
				loading: (group && !groupData) || loading,
				nothingToAdd: notAdded.length === 0 && alreadyAdded.length > 0,
				values: filtered,
			};
		},
		[group, groupData, loading, searchTerm, tags]
	);

	// Performs a search in conjunction with debouncing search field input
	useEffect(
		() => {
			const validSearch = !group && searchTerm.trim().length >= 3;

			// If search is invalid, clear results and cache
			if (!validSearch) {
				lastSearch.current = {
					term: null,
					results: null,
				};
				setSearchResults(null);
			}

			setLoading(validSearch);

			const search = async () => {
				if (validSearch) {
					let results;

					if (lastSearch?.current?.term !== searchTerm) {
						// Get search results
						const { ok, data } = await performSearch({
							searchString: searchTerm.trim(),
						});

						// If search fails
						if (!ok) {
							enqueueSnackbar(
								'Something went wrong with the search',
								{ variant: 'error' }
							);

							setSearchResults(null);
							setLoading(false);

							return;
						}

						// Set results and update last search cache
						results = data;
						lastSearch.current = {
							term: searchTerm,
							results,
						};
					} else {
						results = lastSearch?.current?.results;
					}

					const addedUuids = (tags || []).map(
						({ tagValueUuid }) => tagValueUuid
					);

					// Need to generate tag groups containing arrays of tag values
					setSearchResults(
						(results || [])
							.filter(
								// Get only active values that are not already added
								({ groupStatus, valueStatus, valueUuid }) =>
									!addedUuids.includes(valueUuid) &&
									groupStatus !== ARCHIVED &&
									valueStatus !== ARCHIVED
							)
							.reduce(
								(
									accumulator,
									{
										groupName,
										groupStatus,
										groupUuid,
										valueName,
										valueStatus,
										valueUuid,
									}
								) => {
									// Find group for this value, if it exists
									const index = accumulator.findIndex(
										({ uuid }) => uuid === groupUuid
									);

									// If group exists, add the value data
									if (index !== -1) {
										let tempAcc = [...accumulator];
										const values = tempAcc[index].values;
										tempAcc[index].values = [
											...values,
											{
												name: valueName,
												status: valueStatus,
												uuid: valueUuid,
											},
										];

										return tempAcc;
									}

									// If group does not exist, create new group with this value
									return [
										...accumulator,
										{
											uuid: groupUuid,
											name: groupName,
											status: groupStatus,
											colour: colourMap[
												groupUuid
											].toString(),
											values: [
												{
													name: valueName,
													status: valueStatus,
													uuid: valueUuid,
												},
											],
										},
									];
								},
								[]
							)
					);
				}

				setLoading(false);
			};

			// Instantly render results if search is the same as cached...
			if (searchTerm === lastSearch?.current?.term) {
				search();
				return;
			}

			// ...otherwise debounce the response to the search field
			const timeout = setTimeout(search, [380]);

			// Pre-cancel any existing fetch if search is altered
			return () => clearTimeout(timeout);
		},
		[colourMap, enqueueSnackbar, group, performSearch, searchTerm, tags]
	);

	// Respond to a group filter being applied
	useEffect(
		() => {
			(async () => {
				if (loadedGroup.current !== group) {
					loadedGroup.current = group;
					filterStatus.current.groupId = group?.uuid;

					if (!!group) {
						setLoading(true);

						// Get requested group
						const { data, ok } = await getGroupApi.request({
							tagGroupUuid: group.uuid,
						});

						// If group filter fails
						if (!ok) {
							enqueueSnackbar('Failed to get tag group', {
								variant: 'error',
							});

							setLoading(false);
							setGroupData(null);

							return;
						}

						// Ensure the response matches the currently selected group
						if (filterStatus.current.groupId === data.uuid) {
							// Remove archived values from group
							const sanitisedData = {
								...data,
								values: data.values.filter(
									({ status }) => status !== ARCHIVED
								),
							};

							setGroupData(sanitisedData);
							setLoading(false);
						}

						return;
					}

					// Reset group data if group filters are deactivated
					filterStatus.current.groupId = null;
					setGroupData(null);

					return;
				}
			})();
		},
		[enqueueSnackbar, getGroupApi, group]
	);

	// Used to compose a tag for storing in state
	const composeTag = useCallback(
		(tagValue, tagGroup) => {
			let reqGroupId;

			if (tagGroup) {
				reqGroupId = tagGroup.uuid;
			} else if (group) {
				reqGroupId = group.uuid;
			} else {
				throw new Error(
					'A tag group must be specified when applying/removing a tag'
				);
			}

			return {
				tagGroupUuid: reqGroupId,
				tagValueUuid: tagValue.uuid,
				resourceUuid: resourceId,
				resourceGroup: resourceGroupId,
			};
		},
		[group, resourceGroupId, resourceId]
	);

	const handleTagValueAdd = useCallback(
		async (tagValue, tagGroup) => {
			const tagApplyObj = composeTag(tagValue, tagGroup);

			const { ok } = await addTagApi.request({ tagApplyObj });

			if (!ok) {
				enqueueSnackbar('Failed to apply tag', {
					variant: 'error',
				});

				return;
			}

			const stateTag = {
				...tagApplyObj,
				name: tagValue.name,
				organisation: appOrg,
			};

			const newTags = [...tags, stateTag];
			setTags(newTags);
			onTagsChange(newTags);
		},
		[appOrg, addTagApi, composeTag, enqueueSnackbar, onTagsChange, tags]
	);

	const handleTagValueRemove = useCallback(
		async tagValue => {
			const tagRemoveObj = composeTag(
				{ uuid: tagValue.tagValueUuid },
				{ uuid: tagValue.tagGroupUuid }
			);

			const { ok } = await removeTagApi.request({ tagRemoveObj });

			if (!ok) {
				enqueueSnackbar('Failed to remove tag', {
					variant: 'error',
				});

				return;
			}

			const newTags = tags.filter(
				tag => tag.tagValueUuid !== tagRemoveObj.tagValueUuid
			);

			setTags(newTags);
			onTagsChange(newTags);
		},
		[composeTag, enqueueSnackbar, removeTagApi, onTagsChange, tags]
	);

	return (
		<>
			<BtChipsDisplay
				onClick={() => {
					if (!readOnly) {
						setGroup(null);
						setSearchTerm('');
						setSearchResults(null);
						setShowDialog(true);
					}
				}}
				value={colourTags}
				idKey="uuid"
				partOfButton={partOfButton}
				primaryField="name"
				colorKey="displayColour"
				readOnly={readOnly}
				size={size}
				singleLine={singleLine}
			/>
			<BtDialog
				open={showDialog}
				onClose={() => setShowDialog(false)}
				fullWidth
				maxWidth="sm"
			>
				<DialogTitle>
					<div
						style={{
							alignItems: 'center',
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						Edit Tags
						<Tooltip title="Close">
							<IconButton onClick={() => setShowDialog(false)}>
								<CloseIcon />
							</IconButton>
						</Tooltip>
					</div>
					<div style={{ marginTop: '1em' }}>
						<Typography variant="subtitle2">
							Selected Tags
						</Typography>
					</div>
					<div style={{ maxHeight: 260, overflowY: 'auto' }}>
						{(colourTags || []).map(tagValue => {
							const { displayColour: backgroundColor } = tagValue;
							const color = getSafeColorForBackground(
								backgroundColor
							);

							return (
								<Chip
									key={tagValue.tagValueUuid}
									label={tagValue.name}
									onDelete={() =>
										handleTagValueRemove(tagValue)
									}
									style={{
										margin: '0 6px 6px 0',
										backgroundColor,
										color,
									}}
									sx={{
										'& .MuiChip-deleteIcon': {
											color: Color(color)
												.fade(0.1)
												.toString(),
										},
										'& .MuiChip-deleteIcon:hover': {
											color: color,
										},
										'& .MuiChip-deleteIcon:focus': {
											color: color,
										},
									}}
								/>
							);
						})}
						{(colourTags || []).length === 0 && (
							<Typography>None</Typography>
						)}
					</div>
				</DialogTitle>
				<DialogContent>
					<FormControl fullWidth variant="standard">
						<Input
							placeholder={
								group
									? `Filter tags for ${group?.name}`
									: 'Search for a tag (3 characters or more)'
							}
							variant="standard"
							autoFocus
							style={{ marginTop: '0.5em' }}
							value={searchTerm}
							onChange={event =>
								setSearchTerm(event.target.value)
							}
							startAdornment={
								<InputAdornment position="start">
									{group ? <FilterIcon /> : <SearchIcon />}
								</InputAdornment>
							}
							endAdornment={
								searchTerm.length > 0 && (
									<InputAdornment position="end">
										<Tooltip
											title="Clear"
											disableInteractive
										>
											<IconButton
												onClick={() =>
													setSearchTerm('')
												}
												sx={{ height: 24, width: 24 }}
											>
												<ClearIcon
													style={{ fontSize: 18 }}
												/>
											</IconButton>
										</Tooltip>
									</InputAdornment>
								)
							}
						/>
					</FormControl>
					<BtQuickFilter
						idKey="uuid"
						labelKey="name"
						colorKey="displayColour"
						filters={(appOrg?.tags || []).filter(
							({ status }) => status !== ARCHIVED
						)}
						onChange={setGroup}
						value={group}
						nullable
					/>
					{tagGroupValues?.values.length > 0 && (
						<BtList
							items={tagGroupValues.values}
							primaryField="name"
							idKey="uuid"
							style={{ opacity: loading ? 0.6 : 1.0 }}
							disabled={loading}
							onItemClick={handleTagValueAdd}
						/>
					)}
					{group &&
						!tagGroupValues.loading &&
						tagGroupValues?.values.length === 0 && (
							<BtNoItems
								message={(() => {
									const groupName = group?.name;

									if (tagGroupValues.loading) {
										return 'Loading...';
									}
									if (tagGroupValues.emptyGroup) {
										return `${groupName} does not contain any tag values`;
									}
									if (tagGroupValues.nothingToAdd) {
										return `The tag values for ${groupName} have all been added`;
									}
									if (tagGroupValues.badFilter) {
										return `No tag values for ${groupName} match your filter "${searchTerm.trim()}"`;
									}
									return 'No tags to show';
								})()}
							/>
						)}
					{searchResults &&
						!group &&
						searchResults.map(({ colour, name, values, uuid }) => (
							<BtResultGroup
								key={uuid}
								title={name}
								colour={colour}
								results={values}
								primaryField="name"
								onItemClick={tagValue =>
									handleTagValueAdd(tagValue, { uuid })
								}
								loading={loading}
							/>
						))}
					{!searching &&
						!loading &&
						searchTerm.length >= 3 &&
						!group &&
						(searchResults || []).length === 0 && (
							<BtNoItems
								message={`No available tag values matched your search "${searchTerm.trim()}"`}
							/>
						)}
					{!group &&
						searchTerm.length < 3 &&
						!searching && (
							<BtNoItems message="Search for a tag or select a tag group to see results" />
						)}
					{(searching || tagGroupValues.loading || loading) &&
						(tagGroupValues?.values || []).length === 0 &&
						(searchResults || []).length === 0 && (
							<BtLoading style={{ padding: '20px 0' }} />
						)}
				</DialogContent>
			</BtDialog>
		</>
	);
}

BtTags.defaultProps = {
	size: 'medium',
};

BtTags.propTypes = {
	partOfButton: PropTypes.bool,
	onTagsChange: PropTypes.func,
	readOnly: PropTypes.bool,
	resourceId: PropTypes.string,
	resourceGroupId: PropTypes.string,
	singleLine: PropTypes.bool,
	size: PropTypes.oneOf(['small', 'medium']),
	value: PropTypes.array,
};
