import React, { Fragment, useMemo, useRef } from 'react';

import Color from 'color';
import { Button, IconButton, styled, Tooltip, Typography } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';

import { DEFAULT, MIN_MAX, REQUIRED } from './utils/constants';
import DefaultInput from './editor-components/DefaultInput';
import { useDataConfigContext } from './DataConfigContext';
import useKeyboardUndoRedo from '../../../hooks/useKeyboardUndoRedo';
import useGetSelectionData from './hooks/useGetSelectionData';
import useResizeObserver from 'use-resize-observer';

// Not sure wht but this import didn't work
// import {
// 	FieldNameInput,
// 	FieldTypeSelect,
// 	ValidationInput,
// } from './editor-components';
import FieldTypeSelect from './editor-components/FieldTypeSelect';
import FieldNameInput from './editor-components/FieldNameInput';
import ValidationInput from './editor-components/ValidationInput';

const ActionsContainer = styled('div')(({ theme }) => ({
	borderTop: `1px solid ${Color(theme.palette.text.primary)
		.alpha(0.2)
		.toString()}`,
	display: 'flex',
	justifyContent: 'center',
	marginTop: '0.5em',
	paddingTop: '1em',
}));

const Container = styled('div')(() => ({
	height: '100dvh',
	marginLeft: 20,
	position: 'fixed',
	width: 320,
}));

const Content = styled('div')(({ maxHeight, theme }) => ({
	backgroundColor: Color(theme.palette.text.solid)
		.alpha(0.025)
		.toString(),
	borderRadius: 16,
	boxShadow: `0 0 0 1px ${Color(theme.palette.text.solid)
		.alpha(0.2)
		.toString()}`,
	maxHeight: `calc(${maxHeight}px - 1em)`,
	padding: 16,
	overflow: 'scroll',
	right: 0,
	top: 0,
	userSelect: 'none',
	width: '100%',
}));

const Header = styled('div')(
	() => ({
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: '0.66em',
	}),
	[]
);

const typeEditOptions = {
	array: [REQUIRED, MIN_MAX],
	boolean: [REQUIRED, DEFAULT],
	date: [REQUIRED, DEFAULT],
	number: [REQUIRED, DEFAULT, MIN_MAX],
	object: [REQUIRED],
	string: [REQUIRED, DEFAULT, MIN_MAX],
};

export default function DataConfigEditor() {
	const containerRef = useRef();

	const { config, location, parentIsObject } = useGetSelectionData();
	const {
		canRedo,
		canUndo,
		deleteElement,
		disabled,
		redo,
		undo,
	} = useDataConfigContext();

	useKeyboardUndoRedo({ redo, undo });

	const { height } = useResizeObserver({ ref: containerRef });

	const maxHeight = useMemo(
		() => height - containerRef.current?.getBoundingClientRect().y || 0,
		[height]
	);

	const isArrayElement = useMemo(
		() => {
			const locationElements = (location || '').split('.');

			return (
				locationElements[locationElements.length - 1] === 'arrayContent'
			);
		},
		[location]
	);

	return (
		<Container ref={containerRef}>
			<Content maxHeight={maxHeight}>
				<Header>
					<Typography variant="h5">Configuration</Typography>
					<div>
						<Tooltip
							disableInteractive
							style={{ marginRight: '0.5em' }}
							title={!canUndo || disabled ? "Can't Undo" : 'Undo'}
						>
							<span>
								<IconButton
									disabled={!canUndo || disabled}
									onClick={undo}
								>
									<UndoIcon />
								</IconButton>
							</span>
						</Tooltip>
						<Tooltip
							disableInteractive
							title={!canRedo || disabled ? "Can't Redo" : 'Redo'}
						>
							<span>
								<IconButton
									disabled={!canRedo || disabled}
									onClick={redo}
								>
									<RedoIcon />
								</IconButton>
							</span>
						</Tooltip>
					</div>
				</Header>
				{!config && (
					<div style={{ display: 'flex' }}>
						<Typography
							style={{ fontSize: 30, marginRight: '0.5em' }}
						>
							👈
						</Typography>
						<Typography>
							Select a field or an item that you wish to
							configure.
						</Typography>
					</div>
				)}
				{config && (
					<>
						{parentIsObject && <FieldNameInput key={location} />}
						<FieldTypeSelect />
					</>
				)}
				<Fragment key={`${location}-${config?.type}`}>
					{typeEditOptions[(config?.type)]?.includes(DEFAULT) && (
						<DefaultInput />
					)}
					<ValidationInput
						validationOptions={typeEditOptions[(config?.type)]}
					/>
				</Fragment>
				{config &&
					!isArrayElement && (
						<ActionsContainer>
							<Button
								color="error"
								disabled={disabled}
								onClick={() => deleteElement(location)}
								startIcon={<DeleteIcon />}
							>
								Delete
							</Button>
						</ActionsContainer>
					)}
			</Content>
		</Container>
	);
}
