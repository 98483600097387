// This function is used to get the dimensions of a text element in an SVG without making it visable to the user.
// As to get the dimensions of a text element in an SVG, it must be rendered to the DOM this is done in a hidden
// div and then the dimensions are read from the DOM and returned.
export function getTextDimensions(text, size) {
	var span = document.getElementById('sizeTestDiv');
	if (!span) {
		span = document.createElement('span');
		span.setAttribute('style', 'display: none;');
		span.id = 'sizeTestDiv';
		document.body.appendChild(span);
	}
	span.setAttribute(
		'style',
		'position: absolute; visibiliy: hidden; display: block; color: transparent; font-size: ' +
			size
	);
	span.innerText = text;
	var textDims = { width: span.offsetWidth, height: span.offsetHeight };
	span.setAttribute('style', 'display: none;');
	return textDims;
}
