import React, {
	memo,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useTheme } from '@mui/material/styles';
import { ResponsiveContainer } from 'recharts';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
} from 'recharts';
import _ from 'lodash';
import Color from 'color';
import { Box } from '@mui/material';
import { defaultAxisLabels } from '../../../../components/charts/Utils/axisLabels';
import useResize from '../../../../components/bt_vis/utils/useResize';
import {
	chartMargin,
	fontSize,
	generateYAxisTickValues,
	xAxisProps,
	yAxisFormatter,
} from '../../../../components/charts/Utils';
import { CustomLegend } from './Generic/Legend';
import { CustomTooltip } from './Generic/Tooltip.js';

export default function BarChartComponent({ data, visOptions, palette }) {
	const [xTicks, setXTicks] = useState([]);
	const [xTickFormat, setXTickFormat] = useState('HH:mm');
	const [yTicks, setYTicks] = useState([]);

	const [timestampFormat, setTimestampFormat] = useState('DD/MM/YYYY HH:mm');
	const THEME = useTheme();
	const [showLegend, setShowLegend] = useState(true);
	const [showAxis, setShowAxis] = useState(true);
	const [showXTickLines, setShowXTickLines] = useState(true);
	const [orientationVertical, setOrientationVertical] = useState(false);

	// memorize axis label values
	const defaultAxisLabelValues = useMemo(
		() =>
			defaultAxisLabels(
				visOptions?.ordinalAxis?.param?.label || '',
				visOptions?.valuePrimaryAxis?.label || ''
			),
		[visOptions]
	);
	const [yAxisLabel, setYAxisLabel] = useState(
		defaultAxisLabelValues.defaultYAxisLabel
	);
	const [xAxisLabel, setXAxisLabel] = useState(
		defaultAxisLabelValues.defaultXAxisLabel
	);

	// console.log({ data, visOptions });

	const ref = useRef(null);

	const xaxis_name = 'start_time';

	//const timestampFormat = 'DD/MM/YYYY HH:mm';
	//const xTickFormat = 'HH:mm';

	useEffect(
		() => {
			if (ref.current) {
				// TODO: Add logic to hide legend if there are too many values
				if (visOptions?.legend?.disabled) {
					setShowLegend(false);
				} else {
					if (ref.current.offsetWidth < 500 && showLegend) {
						setShowLegend(false);
					} else if (
						ref.current.offsetWidth >= 500 &&
						showLegend === false
					) {
						setShowLegend(true);
					}
				}

				if (ref.current.offsetWidth < 500 && showLegend) {
					setXAxisLabel(false);
					setYAxisLabel(false);
				} else if (
					ref.current.offsetWidth >= 500 &&
					showLegend === false
				) {
					setXAxisLabel(defaultAxisLabelValues.defaultXAxisLabel);
					setYAxisLabel(defaultAxisLabelValues.defaultYAxisLabel);
				}

				if (ref.current.offsetWidth < 300 && showLegend) {
					setShowAxis(false);
				} else if (
					ref.current.offsetWidth >= 300 &&
					showLegend === false
				) {
					setShowAxis(true);
				}
			}
		},
		[ref.current]
	);

	useEffect(
		() => {
			if (visOptions?.ordinalAxis?.orientation === 'vertical') {
				setOrientationVertical(true);
			} else {
				setOrientationVertical(false);
			}
		},
		[visOptions]
	);

	const CustomYAxisTick = memo(function CustomYAxisTick({
		x,
		y,
		// stroke,
		payload,
	}) {
		return (
			<g transform={`translate(${x},${y})`}>
				<text
					x={0}
					y={0}
					dy="0.4em"
					textAnchor="end"
					fill={THEME.palette.chart.tick}
					fontSize={fontSize}
				>
					{yAxisFormatter(payload.value, visOptions)}
				</text>
			</g>
		);
	});

	const CustomXAxisTick = memo(function CustomXAxisTick({
		x,
		y,
		// stroke,
		payload,
	}) {
		const label = useMemo(
			() => {
				if (payload.offset < 20) {
					return null;
				}
				// local copy of tick value
				let localValue = payload.value;
				// console.log(localValue);

				// evaluate the width of the text
				const getTextWidth = text => {
					const element = document.createElement('canvas');
					const context = element.getContext('2d');
					context.font = `${fontSize} sans-serif`;
					return context.measureText(text).width;
				};

				// declare the width
				let localWidth = getTextWidth(localValue);

				// if wider than the available width trim the value until it will fit
				while (localWidth > payload.offset * 2 - 16) {
					localValue = localValue.slice(0, -2);
					localWidth = getTextWidth(localValue + '...');
				}

				// return either the original value or the truncated string with an ellipsis
				if (localValue.length < payload.value.length) {
					return localValue + '...';
				} else {
					return localValue;
				}
			},
			[payload.offset, payload.value]
		);

		return (
			<text
				x={x}
				y={y}
				dy={'1em'}
				fill={THEME.palette.chart.tick}
				fontSize={fontSize}
				textAnchor="middle"
			>
				{label}
			</text>
		);
	});

	// implemented for chart on Viz Dev page
	// observe the container for size changes
	const dimensions = useResize(ref);
	//Calculate and set the Y tick data
	// useEffect(
	// 	() => {
	// 		if (ref.current && visOptions && data) {
	// 			setYTicks(
	// 				generateYAxisTickValues(
	// 					visOptions,
	// 					data,
	// 					ref.current.offsetHeight
	// 				)
	// 			);
	// 		}
	// 	},
	// 	[data, visOptions, dimensions]
	// );

	useLayoutEffect(
		() => {
			if (ref.current) {
				setYTicks(
					generateYAxisTickValues(
						visOptions,
						data,
						ref.current.offsetHeight
					)
				);
			}
		},
		[data, visOptions]
	);

	return (
		<Box ref={ref} sx={{ height: '100%' }}>
			<ResponsiveContainer width="100%" height="100%">
				<BarChart
					width={500}
					height={300}
					// data={data}
					layout={orientationVertical ? 'vertical' : 'horizontal'}
					data={_.sortBy(data, visOptions?.ordinalAxis?.param?.value)}
					margin={{ ...chartMargin }}
					barGap={0}
				>
					<CartesianGrid
						stroke={THEME.palette.chart.gridColor}
						vertical={false}
					/>

					{showAxis && (
						<>
							{orientationVertical && (
								<>
									<YAxis
										type="category"
										dataKey={
											visOptions?.ordinalAxis?.param
												?.value
										}
										domain={['dataMin', 'dataMax']}
									/>

									<XAxis
										type="number"
										tickFormatter={yAxisFormatter}
									/>
								</>
							)}

							{!orientationVertical && (
								<>
									<XAxis
										type="category"
										dataKey={
											visOptions?.ordinalAxis?.param
												?.value
										}
										domain={['dataMin', 'dataMax']}
										interval={0}
										tick={<CustomXAxisTick />}
										tickLine={showXTickLines}
										stroke={THEME.palette.chart.tick}
										{...xAxisProps}
									/>

									<YAxis
										// tickFormatter={value =>
										// 	yAxisFormatter(value, visOptions)
										// }
										label={{
											...yAxisLabel,
											fill: THEME.palette.chart.label,
										}}
										domain={yTicks.domain}
										ticks={yTicks.ticks}
										tick={<CustomYAxisTick />}
										axisLine={false}
										tickLine={false}
										fontSize={fontSize}
										stroke={THEME.palette.chart.tick}
									/>
								</>
							)}
							<Tooltip
								wrapperStyle={{ outline: 'none' }}
								content={props => (
									<CustomTooltip
										visOptions={visOptions}
										timestampFormat={timestampFormat}
										{...props}
									/>
								)}
								cursor={{ fill: THEME.palette.chart.gridColor }}
								// cursor={{ fill: 'transparent' }}
							/>
						</>
					)}

					{showLegend && (
						<Legend
							content={props => (
								<CustomLegend
									visOptionsParams={visOptions?.params}
									containerWidth={ref.current.offsetWidth}
									{...props}
								/>
							)}
						/>
					)}

					{(visOptions?.params || []).map((param, index) => (
						<Bar
							type="monotone"
							dataKey={param.value}
							fill={palette[index]}
							// fill={colourIndex(index, palette)}
							key={index}
							// isAnimationActive={false}
							animationDuration={200}
							// stackId="a"
						/>
					))}
				</BarChart>
			</ResponsiveContainer>
		</Box>
	);
}
