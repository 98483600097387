const yup = require('yup');

const userGetSubscriptionsSchema = yup
	.array()
	.of(
		yup.object({
			name: yup.string().required(),
			status: yup.string().required(),
		})
	)
	.required();

const userPostSchema = yup.object().shape({
	email: yup // !!! TODO: change to check email format !!!
		.string()
		.required(),
});

const userPutSchema = yup.object().shape({
	first_name: yup.string().notRequired(),
	last_name: yup.string().notRequired(),
	user_name: yup.string().notRequired(),
	initials: yup.string().notRequired(),
	phone_no: yup
		.number()
		.notRequired()
		.positive()
		.integer(),
	status: yup.string().notRequired(),
});

const userPutForgotPasswordSchema = yup.object().shape({
	email: yup
		.string()
		.email()
		.required(),
});

module.exports = {
	userGetSubscriptionsSchema,
	userPostSchema,
	userPutSchema,
	userPutForgotPasswordSchema,
};
