import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, ClickAwayListener, Fade, Paper, Popper } from '@mui/material';
import { styled } from '@mui/system';

const StyledPopper = styled(Popper)(({ theme }) => ({
	zIndex: 1,
	// maxWidth: '375px',
	// width: '100%',
	'&[data-popper-placement*="bottom"] .arrow': {
		top: 0,
		left: 0,
		marginTop: '-0.9em',
		width: '3em',
		height: '1em',
		'&::before': {
			borderWidth: '0 1em 1em 1em',
			borderColor: `transparent transparent ${
				// theme.palette.background.paper
				theme.palette.popperArrow.backgroundColor
				// 'red'
			} transparent`,
			// color: 'red',
		},
	},
	'&[data-popper-placement*="top"] .arrow': {
		bottom: 0,
		left: 0,
		marginBottom: '-0.9em',
		width: '3em',
		height: '1em',
		'&::before': {
			borderWidth: '1em 1em 0 1em',
			borderColor: `${
				theme.palette.background.paper
			} transparent transparent transparent`,
		},
	},
	'&[data-popper-placement*="right"] .arrow': {
		left: 0,
		marginLeft: '-0.9em',
		height: '3em',
		width: '1em',
		'&::before': {
			borderWidth: '1em 1em 1em 0',
			borderColor: `transparent ${
				theme.palette.background.paper
			} transparent transparent`,
		},
	},
	'&[data-popper-placement*="left"] .arrow': {
		right: 0,
		marginRight: '-0.9em',
		height: '3em',
		width: '1em',
		'&::before': {
			borderWidth: '1em 0 1em 1em',
			borderColor: `transparent transparent transparent ${
				theme.palette.background.paper
			}`,
		},
	},
}));

const styles = {
	arrow: {
		position: 'absolute',
		fontSize: 7,
		width: '3em',
		height: '3em',
		'&::before': {
			content: '""',
			margin: 'auto',
			display: 'block',
			width: 0,
			height: 0,
			borderStyle: 'solid',
		},
	},
};

const BtPopper = ({
	placement,
	arrow,
	open,
	onClose,
	content,
	children,
	style,
	clickAway,
	variant,
	disablePortal,
	extendOffset,
}) => {
	const [arrowRef, setArrowRef] = useState(null);
	const [childNode, setChildNode] = useState(null);

	const handleEscapePress = useCallback(
		event => {
			if (open) {
				if (event.key === 'Escape') {
					event.returnValue = false;
					onClose();
				}
			}
		},
		[onClose, open]
	);

	useEffect(
		() => {
			document.addEventListener('keydown', handleEscapePress, true);

			return () => {
				document.removeEventListener(
					'keydown',
					handleEscapePress,
					true
				);
			};
		},
		[handleEscapePress]
	);

	return (
		<div>
			{React.cloneElement(children, {
				...children.props,
				ref: setChildNode,
			})}
			{childNode && (
				<StyledPopper
					disablePortal={disablePortal}
					open={open}
					anchorEl={childNode}
					placement={placement}
					transition
					sx={{
						maxWidth: variant === 'menu' ? null : '375px',
						// width: variant === 'menu' ? null : '100%',
						...style,
						zIndex: 1000,
					}}
					modifiers={[
						{
							name: 'preventOverflow',
							enabled: true,
							options: {
								boundariesElement: 'window',
							},
						},
						{
							name: 'arrow',
							enabled: true,
							options: {
								element: arrowRef,
							},
						},
						// {
						// 	name: 'offset',
						// 	options: {
						// 		offset: ({ placement, reference, popper }) => {
						// 			if (placement === 'bottom') {
						// 				return [0, 5];
						// 			} else {
						// 				return [];
						// 			}
						// 		},
						// 	},
						// },
						{
							name: 'offset',
							options: {
								offset: [0, extendOffset ? 10 : 5],
							},
						},
					]}
				>
					{({ TransitionProps }) => (
						<Fade {...TransitionProps} timeout={350}>
							<Paper
								sx={{
									boxShadow: 0,
								}}
							>
								<ClickAwayListener
									onClickAway={onClose}
									mouseEvent={clickAway ? 'onClick' : false}
								>
									<Paper
										sx={{
											boxShadow: 0,
										}}
									>
										{arrow ? (
											<Box
												component="span"
												className="arrow"
												ref={setArrowRef}
												sx={styles.arrow}
											/>
										) : null}
										<Paper
											sx={{
												boxShadow:
													'0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
											}}
										>
											{content}
										</Paper>
									</Paper>
								</ClickAwayListener>
							</Paper>
						</Fade>
					)}
				</StyledPopper>
			)}
		</div>
	);
};

export default BtPopper;

BtPopper.defaultProps = {
	placement: 'top',
	arrow: true,
	clickAway: true,
};

BtPopper.propTypes = {
	// content: PropTypes.array.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func,
	arrow: PropTypes.bool,
	placement: PropTypes.oneOf([
		'auto-end',
		'auto-start',
		'auto',
		'bottom-end',
		'bottom-start',
		'bottom',
		'left-end',
		'left-start',
		'left',
		'right-end',
		'right-start',
		'right',
		'top-end',
		'top-start',
		'top',
	]),
	style: PropTypes.object,
	clickAway: PropTypes.bool,
	variant: PropTypes.oneOf(['menu', 'extended']),
	extendOffset: PropTypes.bool,
};
