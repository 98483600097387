import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

export default function SelectItem({ label, description, additionalText }) {
	return (
		<div style={{ display: 'flex' }}>
			<Typography>{label}</Typography>
			{description && (
				<Typography noWrap fontStyle="italic" style={{ marginLeft: 5 }}>
					{'- ' + description}
				</Typography>
			)}

			{additionalText && (
				<Typography noWrap fontStyle="italic" style={{ marginLeft: 5 }}>
					{'- ' + additionalText}
				</Typography>
			)}
		</div>
	);
}

SelectItem.propTypes = {
	label: PropTypes.string.isRequired,
	description: PropTypes.string,
	additionalText: PropTypes.string,
};
