import BtDialog from '../../../BtDialog';
import PropTypes from 'prop-types';
import { useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material';
import { buttonTypes } from '../../tileConstants';
import ActionButtonContainer from '../../../ActionButtonContainer';
import SelectItem from '../formComponents/SelectItem';
import { TileContext } from '../TileContext';

export const ContainerRemoveTileDialog = ({
	open,
	onClose,
	onContainerChange,
}) => {
	const [value, setValue] = useState('');
	const { tileData, screenData } = useContext(TileContext);

	const handleChange = event => {
		setValue(event.target.value);
	};

	const inputStyle = {
		height: 65,
		// paddingHorizontal: 5,
		marginTop: 5,
	};

	const onDeleteTile = () => {
		// console.log({ value });
		const screenUpdate = structuredClone(screenData);

		// remove the child tile from the children array
		screenUpdate.tiles[tileData.uuid].data.children = screenUpdate.tiles[
			tileData.uuid
		].data.children.filter(child => child !== value);

		// change the container layout to reflect the new number of child tiles
		screenUpdate.tiles[tileData.uuid].data.container_layout = {
			h:
				screenUpdate.tiles[tileData.uuid].data.children.length === 0
					? 1
					: screenUpdate.tiles[tileData.uuid].data.children.length,
			w: 1,
		};

		// delete the tile from the tiles object
		delete screenUpdate.tiles[value];

		onContainerChange({ screenUpdate });
	};

	return (
		<>
			<DialogTitle>Remove a tile from the container</DialogTitle>
			<DialogContent>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						width: 300,
						paddingBottom: 0,
					}}
				>
					<FormControl
						style={inputStyle}
						variant="standard"
						sx={{ minWidth: '100%' }}
					>
						<InputLabel id="Tile-to-remove-select">
							Tile to remove
						</InputLabel>
						<Select
							// sx={{ width: '100%' }}
							labelId="Tile-to-remove-select"
							id="Tile-to-remove-select"
							label="Tile to remove"
							value={value}
							onChange={handleChange}
						>
							<MenuItem value="">
								<em>None</em>
							</MenuItem>
							{tileData.data.children.map(childUuid => (
								<MenuItem key={childUuid} value={childUuid}>
									<SelectItem
										label={
											screenData.tiles[childUuid].data
												.target.label
										}
										description={
											buttonTypes.find(
												item =>
													item.value ===
													screenData.tiles[childUuid]
														.data.button_type
											).label
										}
										// additionalText={type.additionalText}
									/>
								</MenuItem>
							))}
						</Select>
						<FormHelperText>
							Select which tile to remove
						</FormHelperText>
					</FormControl>
				</div>
			</DialogContent>
			<DialogActions>
				<ActionButtonContainer>
					<Button
						color="primary"
						onClick={onClose}
						variant="outlined"
						disableElevation
						// style={{ color: 'white' }}
					>
						Cancel
					</Button>
					<Button // maybe this should be a text button on non mobile??
						disableElevation
						style={{ color: 'white' }}
						variant="contained"
						color="error"
						onClick={onDeleteTile}
						disabled={value === ''}
					>
						remove tile
					</Button>
				</ActionButtonContainer>
			</DialogActions>
		</>
	);
};

ContainerRemoveTileDialog.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
};
