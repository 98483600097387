import React from 'react';

import Dashboard from '@mui/icons-material/Dashboard';
import AssessmentIcon from '@mui/icons-material/Assessment';
import StorageIcon from '@mui/icons-material/Storage';
import SettingsIcon from '@mui/icons-material/Settings';

function insights_edit_options(insightId) {
	const options = [
		{
			id: 'InsightSettings',
			description: 'Insight Collection Settings',
			text: 'Insight Settings',
			// route:
			// 	'/InsightCollection/0dd477b0-487e-11ec-92f1-65831038700a/Edit/Collection',
			route: 'Collection',
			icon: <SettingsIcon />,
			requiredPermissionsAll: {
				insights: ['InsightsEdit'],
			},
		},
		{
			id: 'PageLayout',
			description: 'Insight Page Layout',
			text: 'Page Layout',
			// route:
			// 	'/InsightCollection/0dd477b0-487e-11ec-92f1-65831038700a/Edit/Pages',
			route: 'Pages',
			icon: <Dashboard />,
			requiredPermissionsAll: {
				insights: ['InsightsEdit'],
			},
		},
		{
			id: 'DataSources',
			description: 'Insight Data Sources',
			text: 'Data Sources',
			// route:
			// 	'/InsightCollection/0dd477b0-487e-11ec-92f1-65831038700a/Edit/DataSources',
			route: 'DataSources',
			icon: <StorageIcon />,
			requiredPermissionsAll: {
				insights: ['InsightsEdit'],
			},
		},
		{
			id: 'Tile',
			description: 'Screen Insight Tile',
			text: 'Screen Tile',
			// route:
			// 	'/InsightCollection/0dd477b0-487e-11ec-92f1-65831038700a/Edit/Tile',
			route: 'Tile',
			icon: <AssessmentIcon />,
			requiredPermissionsAll: {
				insights: ['InsightsEdit'],
			},
		},
	];

	return options.map(option => {
		return {
			...option,
			route: `/InsightCollection/${insightId}/Edit/${option.route}`,
		};
	});
}

export default insights_edit_options;
