import * as yup from 'yup';

import {
	visualisationSchema,
	visualisationsSchema,
	tileSchema,
} from './insightVisualizationsValidation.js';

// Partial Schemas
// =============================================================================

const initial_time_range = yup.object().shape({
	real_time: yup
		.boolean()
		.default(false)
		.required(),
	value: yup
		.string()
		.oneOf([
			'today',
			'this_week',
			'last_quarter',
			'this_quarter',
			'last_15_minutes',
			'last_30_minutes',
			'last_1_hour',
			'last_24_hours',
			'last_week',
			'last_month',
			'Last month',
			'last_90_days',
			'last_1_year',
		])
		.required(),
});

const layoutItem = yup.object().shape({
	visualisation: yup
		.string()
		.uuid()
		.required(),
	uuid: yup
		.string()
		.uuid()
		.required(),
	x: yup
		.number()
		.required()
		.min(0)
		.max(16),
	y: yup
		.number()
		.required()
		.min(0),
	w: yup
		.number()
		.required()
		.min(1)
		.max(16),
	h: yup
		.number()
		.required()
		.min(1)
		.max(12),
});

const desktopLayoutItem = yup.object().shape({
	visualisation: yup
		.string()
		.uuid()
		.required(),
	uuid: yup
		.string()
		.uuid()
		.required(),
	x: yup
		.number()
		.required()
		.min(0)
		.max(16),
	y: yup
		.number()
		.required()
		.min(0),
	w: yup
		.number()
		.required()
		.min(1)
		.max(16),
	h: yup
		.number()
		.required()
		.min(1),
});

const tabletAndMobileLayoutItem = yup.object().shape({
	visualisation: yup
		.string()
		.uuid()
		.required(),
	uuid: yup
		.string()
		.uuid()
		.required(),
	x: yup
		.number()
		.required()
		.min(0)
		.max(16),
	y: yup
		.number()
		.required()
		.min(0),
	w: yup
		.number()
		.required()
		.min(1)
		.max(16),
	h: yup
		.number()
		.required()
		.min(1),
});

const notes = yup
	.string()
	.defined()
	.max(300);

const notesPartial = yup.string().max(300);

const pages = yup.array().of(
	yup.object({
		description: yup.string(),
		name: yup.string().required(),
		uuid: yup
			.string()
			.uuid()
			.required(),
		layouts: yup.object().shape({
			desktop: yup
				.array()
				.of(desktopLayoutItem)
				.nullable(),
			tablet: yup
				.array()
				.of(tabletAndMobileLayoutItem)
				.nullable(),
			mobile: yup
				.array()
				.of(tabletAndMobileLayoutItem)
				.nullable(),
		}),
	})
);

const status = yup.string().oneOf(['Unpublished', 'Active', 'Inactive']);

const paletteItem = yup
	.object()
	.noUnknown()
	.shape({
		hex: yup.string().required(),
		uuid: yup
			.string()
			.uuid()
			.required(),
	});

const palette = yup.array().of(paletteItem);

export const dataSource = yup.object({
	dataView: yup
		.string()
		.uuid()
		.nullable(),
	// .required(),
	uuid: yup
		.string()
		.uuid()
		.required(),
	filterDependencies: yup
		.array()
		.of(yup.string())
		.nullable(),
	predefinedFilters: yup
		.array()
		.of(
			yup.object({
				field: yup.string().required(),
				active: yup.boolean().required(),
				name: yup.string().required(),
				required: yup.boolean().required(),
				singleValue: yup.boolean().required(),
			})
		)
		.nullable(),
	transforms: yup
		.array()
		.of(
			yup.object({
				uuid: yup
					.string()
					.uuid()
					.required(),
				name: yup.string().required(),
				description: yup.string().nullable(),
				pipeline: yup.string().required(),
			})
		)
		.nullable(),
	description: yup.string().max(300),
	name: yup
		.string()
		.min(3)
		.max(50)
		.required(),
});

const data_sources = yup
	.array()
	.defined()
	.of(dataSource);

const visDataSourceItem = yup
	.object({
		transform: yup.string().uuid(),
		uuid: yup
			.string()
			.uuid()
			.required(),
		dataOrigin: yup.string(),
	})
	.nullable();

const legend = yup
	.object({
		disabled: yup.boolean(),
	})
	.notRequired()
	.default(undefined);

const ordinalAxis = yup
	.object({
		param: yup.object({
			label: yup.string(),
			value: yup.string(),
			orientation: yup.string().oneOf(['vertical', 'horizontal']),
		}),
	})
	.notRequired()
	.default(undefined);

const params = yup
	.array()
	.of(
		yup.object({
			axis: yup.string(),
			label: yup.string(),
			prefix: yup.string(),
			value: yup.string(),
		})
	)
	.nullable();

const valuePrimaryAxis = yup
	.object({
		axisBounds: yup
			.object({
				min: yup.lazy(
					value =>
						typeof value === 'string'
							? yup
									.string()
									.oneOf(['auto'])
									.required()
							: yup.number().required()
				),
				max: yup.lazy(
					value =>
						typeof value === 'string'
							? yup
									.string()
									.oneOf(['auto'])
									.required()
							: yup.number().required()
				),
			})
			.notRequired()
			.default(undefined),
		label: yup.string().nullable(),
		prefix: yup.string().nullable(),
	})
	.notRequired()
	.default(undefined);

const vis_options = yup
	.object()
	.defined()
	.shape({
		legend: legend,
		ordinalAxis: ordinalAxis,
		params: params,
		valuePrimaryAxis: valuePrimaryAxis,
		text: yup.string(),
	});

const type = yup
	.string()
	.oneOf([
		'BarChart',
		'Gauge',
		'ImageGallery',
		'InfoCard',
		'LineChart',
		'PieChart',
		'StatusCard',
		'Table',
	]);

// const visualisationSchema = yup.object({
// 	datasource: visDataSourceItem,
// 	subtitle: yup
// 		.string()
// 		.min(3)
// 		.max(50)
// 		.required(),
// 	title: yup
// 		.string()
// 		.min(3)
// 		.max(50)
// 		.required(),
// 	type: type.required(),
// 	uuid: yup
// 		.string()
// 		.uuid()
// 		.required(),
// 	vis_options: vis_options,
// });

// const visualisationsSchema = yup.array().of(visualisationSchema);
// // .default([]);

// const version = yup.object({
// 	data_sources: data_sources,
// 	definition_version: yup
// 		.number()
// 		.min(1)
// 		.required(),
// 	draft_base: yup
// 		.number()
// 		.min(0)
// 		.nullable(),
// 	initialTimeRange: initial_time_range.required(),
// 	notes: notes.required(),
// 	pages: pages,
// 	palette: palette,
// 	publish_by: yup
// 		.string()
// 		.uuid()
// 		.required(),
// 	publish_timestamp: yup.number().required(),
// 	// tile: visualisationSchema.nullable(),
// 	visualisations: yup
// 		.array()
// 		.of(visualisationSchema)
// 		.defined(),
// });

const userObject = yup.object().shape({
	uuid: yup
		.string()
		.uuid()
		.required(),
	avatar: yup.string().nullable(),
	email: yup.string().required(),
	first_name: yup.string().required(),
	initials: yup.string().required(),
	last_name: yup.string().required(),
	user_name: yup.string().required(),
});

const historyVersion = yup.object({
	publish_timestamp: yup.number().required(),
	publish_by: userObject.required(),
	notes: notes.nullable(),
});

const historyDraft = yup.object({
	definition_version: yup
		.number()
		.min(1)
		.required(),
	draft_base: yup
		.number()
		.min(0)
		.nullable(),
	has_changes: yup.boolean().required(),
	locked_to: yup
		.string()
		.uuid()
		.nullable(),
	notes: notes.nullable(),
});

// Full Schemas
// =============================================================================

const insightCollectionGetListSchema = yup.array().of(
	yup.object({
		current_version: yup
			.number()
			.min(0)
			.nullable(),
		description: yup.string().required(),
		group: yup
			.string()
			.uuid()
			.required(),
		name: yup.string().required(),
		status: status.required(),
		uuid: yup
			.string()
			.uuid()
			.required(),
	})
);

const insightCollectionGetSchema = yup.object({
	datasources: data_sources,
	definition_version: yup
		.number()
		.min(1)
		.required(),
	draft_base: yup
		.number()
		.min(0)
		.nullable(),
	description: yup.string().required(),
	initialTimeRange: initial_time_range.required(),
	group: yup
		.string()
		.uuid()
		.required(),
	name: yup.string().required(),
	notes: notes.nullable(),
	organisation: yup
		.string()
		.uuid()
		.required(),
	update_timestamp: yup.number().required(),
	uuid: yup
		.string()
		.uuid()
		.required(),
	pages: pages,
	palette: palette,
	publish_by: yup
		.string()
		.uuid()
		.required(),
	publish_timestamp: yup.number().required(),
	status: status.required(),
	tile: tileSchema,
	visualisations: visualisationsSchema,
	palette_config: yup.array().of(
		yup.object({
			uuid: yup
				.string()
				.uuid()
				.required(),
			colours: yup.array().of(
				yup.object({
					uuid: yup
						.string()
						.uuid()
						.required(),
					tags: yup
						.array()
						.of(yup.string().max(20))
						.max(10)
						.required(),
				})
			),
		})
	),
});

const insightCollectionHistoryGetSchema = yup.object({
	current_version: yup
		.number()
		.min(0)
		.nullable(),
	description: yup.string().required(),
	draft: historyDraft.required(),
	group: yup
		.string()
		.uuid()
		.required(),
	organisation: yup
		.string()
		.uuid()
		.required(),
	status: status.required(),
	uuid: yup
		.string()
		.uuid()
		.required(),
	versions: yup
		.array()
		.defined()
		.of(historyVersion),
});

// const insightCollectionHistoryGetSchema = yup.object({
// 	uuid: yup.string().uuid().required(),
// 	status: yup.string().required(),
// 	organisation: yup.string().uuid().required(),
// 	description: yup.string().nullable(),
// 	group: yup.string().uuid().nullable(),
// 	versions: yup
// 		.array()
// 		.nullable()
// 		.of(
// 			yup.object().shape({
// 				publish_timestamp: yup.number().required(),
// 				publish_by: yup.object().shape({
// 					uuid: yup.string().uuid().required(),
// 					avatar: yup.string().nullable(),
// 					email: yup.string().required(),
// 					first_name: yup.string().required(),
// 					initials: yup.string().required(),
// 					last_name: yup.string().required(),
// 					user_name: yup.string().required(),
// 				}),
// 			})
// 		),
// 	current_version: yup.number().required(),
// 	draft: yup.object().shape({
// 		locked_to: yup.string().uuid().nullable(),
// 		definition_version: yup.number().required(),
// 		draft_base: yup.number().required(),
// 		has_changes: yup.boolean().required(),
// 		// notes: yup.string().max(300).nullable(),
// 		notes: notes,
// 	}),
// 	availableTo: yup
// 		.object()
// 		.required()
// 		.shape({
// 			users: yup.array().required().of(yup.string().uuid()),
// 			roles: yup.array().required().of(yup.string().uuid()),
// 			userGroups: yup.array().required().of(yup.string().uuid()),
// 		}),
// });

const insightCollectionComponentListGetSchema = yup.array().of(
	yup.object().shape({
		uuid: yup
			.string()
			.uuid()
			.required(),
		title: yup.string().required(),
		type: type.required(),
		subtitle: yup.string().nullable(),
	})
);

const insightCollectionComponentGetSchema = yup.object().shape({
	datasource: dataSource.nullable(),
	visualisation: visualisationSchema,
});

// const insightCollectionComponentGetSchema = yup.object().shape({
// 	visualisation: yup.object().required().shape({
// 		uuid: yup.string().uuid().required(),
// 		title: yup.string().required(),
// 		subtitle: yup.string().required(),
// 	}),
// 	data_source: yup.object().nullable().shape({}),
// });

const insightCollectionTileGetSchema = yup.object({
	datasource: dataSource.nullable(),
	visualisation: visualisationSchema,
});

// const insightCollectionTileGetSchema = yup.object({
// 	visualisation: yup.object().required().shape({
// 		uuid: yup.string().uuid().required(),
// 		title: yup.string().required(),
// 		subtitle: yup.string().required(),
// 	}),
// 	data_source: yup.object().nullable().shape({}),
// });

const insightCollectionDraftGetSchema = yup.object({
	datasources: data_sources.nullable(),
	definition_version: yup
		.number()
		.min(1)
		.required(),
	description: yup.string().required(),
	draft_base: yup
		.number()
		.min(0)
		.required(),
	has_changes: yup.boolean().required(),
	initialTimeRange: initial_time_range.required(),
	locked_to: userObject.nullable(),
	name: yup.string().required(),
	notes: notes.nullable(),
	organisation: yup
		.string()
		.uuid()
		.required(),
	pages: pages,
	palette: palette,
	tile: tileSchema,
	uuid: yup
		.string()
		.uuid()
		.required(),
	visualisations: visualisationsSchema,
});

// const insightCollectionDraftGetSchema = yup.object({
// 	uuid: yup.string().uuid().required(),
// 	name: yup.string().required(),
// 	description: yup.string().required(),
// 	initial_time_range: initial_time_range.required(),
// });

const insightCollectionPostSchema = yup.object({
	name: yup.string().required(),
	description: yup.string().required(),
	group: yup
		.string()
		.uuid()
		.required(),
});

const insightCollectionsPostResponseSchema = yup.object({
	uuid: yup.string().required(),
});

const insightCollectionStatusChangeSchema = yup.object({
	new_status: yup.string().oneOf(['Active', 'Inactive']),
});

// Fields are not required so as partial updates can be made
const insightCollectionPutSchema = yup
	.object()
	.noUnknown()
	.strict()
	.shape({
		current_version: yup.number().min(0),
		description: yup.string(),
		group: yup.string().uuid(),
		name: yup.string(),
	});

// const tileSchema = visualisationSchema.notRequired().default(undefined);

// Fields are not required so as partial updates can be made
const insightCollectionDraftPutSchema = yup
	.object()
	.noUnknown()
	.shape({
		datasources: yup.array().of(dataSource),
		definition_version: yup.number().min(1),
		draft_base: yup.number().min(0),
		has_changes: yup.boolean(),
		initialTimeRange: initial_time_range.nullable().default(undefined),
		pages: pages,
		palette: palette,
		notes: notesPartial.nullable(),
		tile: tileSchema,
		visualisations: visualisationsSchema,
		palette_config: yup
			.array()
			.of(
				yup.object({
					uuid: yup
						.string()
						.uuid()
						.required(),
					colours: yup.array().of(
						yup.object({
							uuid: yup
								.string()
								.uuid()
								.required(),
							tags: yup
								.array()
								.of(yup.string().max(20))
								.max(10)
								.required(),
						})
					),
				})
			)
			.notRequired(),
	});

export {
	insightCollectionGetListSchema,
	insightCollectionGetSchema,
	insightCollectionHistoryGetSchema,
	insightCollectionComponentListGetSchema,
	insightCollectionComponentGetSchema,
	insightCollectionTileGetSchema,
	insightCollectionDraftGetSchema,
	insightCollectionPostSchema,
	insightCollectionsPostResponseSchema,
	insightCollectionStatusChangeSchema,
	insightCollectionPutSchema,
	insightCollectionDraftPutSchema,
	visualisationSchema,
};
