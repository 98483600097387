import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Auth } from 'aws-amplify';
import { useAppContext } from '../../context/ContextManager';

// COMPONENTS
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/SaveAlt';

import { ListTable, ListRow, ListHeader, ListCell } from '../generic/ListTable';

const styles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(2),
		'& .MuiTextField-root': {
			margin: '10px 0',
		},
		'& .MuiDialog-paperWidthSm': {
			maxWidth: '800px',
		},
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
	},
	dialogTitle: {
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.contrastText,
	},
	dialogContent: {
		padding: '32px',
		margin: 'auto',
		minWidth: '600px',
		width: '800px',
	},
	inputLable: {
		fontSize: '3rem',
		fontWeight: 400,
		textAlign: 'center',
	},
	inputValue: {
		fontSize: '6rem',
		fontWeight: 700,
		textAlign: 'center',
	},
	textActive: {
		color: 'green',
	},
	textInactive: {
		color: 'red',
	},
}));

const FilteringList = [
	{ value: 5, text: '5ms (100Hz)' },
	{ value: 25, text: '25ms (20Hz)' },
	{ value: 100, text: '100ms (5Hz)' },
	{ value: 250, text: '250ms (2Hz)' },
	{ value: 1000, text: '1000ms (0.1Hz)' },
];

export default function DigitalConditioningDialog({
	setOpen,
	onClose,
	values,
	conditioning,
	device_uid,
}) {
	const classes = styles();
	const [commsActive, setCommsActive] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [inputConfig, setInputConfig] = useState({});
	const [inverted, setInverted] = useState(false);
	const { appOrg } = useAppContext();

	const handleSelectConfigClose = () => {
		onClose();
	};

	// This is called when the dialog is opened
	const handleOnEnter = () => {
		// Convert the text to a binary value used by the UI
		if (conditioning.polarity === 'inverted') {
			setInverted(true);
		} else {
			setInverted(false);
		}

		// Check that a value has been set that is valid for our list
		if (
			FilteringList.some(filter => filter.value !== conditioning.filter)
		) {
			conditioning.filter = 5; // Set defult value
		}

		setInputConfig(conditioning);
		//console.log(conditioning);

		setIsLoading(false);
	};

	const handleCancel = () => {
		onClose();
	};

	const handleSave = () => {
		setCommsActive(true);
		var conditioning = {};
		conditioning['input'] = inputConfig.input;
		conditioning['tag'] = inputConfig.tag;
		conditioning['filter'] = inputConfig.filter;
		conditioning['polarity'] = inputConfig.polarity;

		var put_data = {};
		put_data['conditioning'] = conditioning;

		var req_url =
			process.env.REACT_APP_API_SERVER_URL +
			'/v1/device/conditioning/' +
			device_uid;

		Auth.currentSession().then(user_session => {
			let user_token = user_session.getAccessToken();
			fetch(req_url, {
				method: 'PUT',
				headers: {
					Authorization: user_token.jwtToken,
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Organisation: appOrg.uuid,
				},
				body: JSON.stringify(put_data),
			})
				.then(res => res.json())
				.then(response => {
					if (response['result'] !== 'OK') {
						console.log(
							'Failed to set conditioning: ' + response['result']
						);
					} else {
						//console.log("Conditioning update done");
					}
					setCommsActive(false);
					onClose(conditioning);
				})
				.catch(error => {
					console.log(error);
					setCommsActive(false);
				});
		});
	};

	const handleTagChange = event => {
		setInputConfig({ ...inputConfig, tag: event.target.value });
	};

	const handlePolaritySelect = event => {
		setInputConfig({ ...inputConfig, polarity: event.target.value });
		if (event.target.value === 'inverted') {
			setInverted(true);
		} else {
			setInverted(false);
		}
	};

	const handleFilterSelect = event => {
		setInputConfig({ ...inputConfig, filter: event.target.value });
	};

	return (
		<>
			<Dialog
				open={setOpen}
				onClose={handleSelectConfigClose}
				className={classes.root}
				TransitionProps={{
					onEnter: handleOnEnter,
				}}
			>
				<DialogTitle
					id="form-dialog-title"
					className={classes.dialogTitle}
				>
					Digital Conditioning Edit
				</DialogTitle>
				<DialogContent className={classes.dialogContent}>
					{isLoading && (
						<>
							<p>Loading...</p>
							<CircularProgress size={'120px'} />
						</>
					)}

					{!isLoading && (
						<>
							<Grid
								container
								direction="row"
								justifyContent="space-evenly"
								spacing={3}
								alignItems="stretch"
							>
								<Grid item xs={6}>
									<Box flexDirection="column">
										<div className={classes.inputLable}>
											RAW
										</div>
										<div className={classes.inputValue}>
											{values.valueRaw && (
												<div
													className={
														classes.textActive
													}
												>
													HIGH
												</div>
											)}
											{!values.valueRaw && (
												<div
													className={
														classes.textInactive
													}
												>
													LOW
												</div>
											)}
										</div>
									</Box>
								</Grid>

								<Grid item xs={6}>
									<Box flexDirection="column">
										<div className={classes.inputLable}>
											Conditiond
										</div>
										<div className={classes.inputValue}>
											{inverted &&
												values.valueRaw && (
													<div
														className={
															classes.textInactive
														}
													>
														OFF
													</div>
												)}
											{inverted &&
												!values.valueRaw && (
													<div
														className={
															classes.textActive
														}
													>
														ON
													</div>
												)}
											{!inverted &&
												values.valueRaw && (
													<div
														className={
															classes.textActive
														}
													>
														ON
													</div>
												)}
											{!inverted &&
												!values.valueRaw && (
													<div
														className={
															classes.textInactive
														}
													>
														OFF
													</div>
												)}
										</div>
									</Box>
								</Grid>

								<Grid item xs={12}>
									<ListTable>
										<ListRow>
											<ListHeader>Tag:</ListHeader>
											<ListCell>
												<TextField
													variant="outlined"
													fullWidth
													defaultValue={
														conditioning.tag
													}
													className={
														classes.textField
													}
													type="text"
													onChange={handleTagChange}
												/>
											</ListCell>
										</ListRow>
										<ListRow>
											<ListHeader>Polarity:</ListHeader>
											<ListCell>
												<FormControl
													variant="outlined"
													className={
														classes.formControl
													}
												>
													<Select
														labelId="demo-simple-select-outlined-label"
														id="demo-simple-select-outlined"
														value={
															inputConfig.polarity
														}
														className={
															classes.select
														}
														onChange={
															handlePolaritySelect
														}
													>
														<MenuItem value="inverted">
															Inverted
														</MenuItem>
														<MenuItem value="noninverted">
															Non-Inverted
														</MenuItem>
													</Select>
												</FormControl>
											</ListCell>
										</ListRow>
										<ListRow>
											<ListHeader>Filter:</ListHeader>
											<ListCell>
												<FormControl
													variant="outlined"
													className={
														classes.formControl
													}
												>
													<Select
														labelId="demo-simple-select-outlined-label"
														id="demo-simple-select-outlined"
														value={
															inputConfig.filter
														}
														className={
															classes.select
														}
														onChange={
															handleFilterSelect
														}
													>
														{FilteringList.map(
															(filter, index) => (
																<MenuItem
																	value={
																		filter.value
																	}
																	key={index}
																>
																	{
																		filter.text
																	}
																</MenuItem>
															)
														)}
													</Select>
												</FormControl>
											</ListCell>
										</ListRow>
									</ListTable>
								</Grid>
							</Grid>

							<Box>
								<Divider />
								<Box display="flex" flexDirection="row-reverse">
									<Button
										variant="outlined"
										startIcon={<SaveIcon />}
										onClick={handleSave}
										className={classes.button}
									>
										Save
									</Button>

									<Button
										variant="outlined"
										startIcon={<CloseIcon />}
										onClick={handleCancel}
										className={classes.button}
									>
										Cancel
									</Button>
								</Box>
							</Box>
						</>
					)}
				</DialogContent>
			</Dialog>

			<Backdrop className={classes.backdrop} open={commsActive}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	);
}
