import { useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';
import { useLocation, useParams } from 'react-router';
import { insightCollectionDraftUpdate } from '../../../../../../API';

const SUCCESS = 'success';
const ERROR = 'error';

const defaultMessages = {
	success: 'Draft updated',
	error: 'Failed to update Draft',
};
/**
 * A hook that will; update the insight collection with the update,
 * execute any provided callbacks, enqueue snackbar
 */
export function useUpdateCollectionDraft() {
	const { id, version, dataSourceId } = useParams();
	const location = useLocation();
	const { enqueueSnackbar } = useSnackbar();

	const handleCallback = useCallback((callbacks, status, update) => {
		console.log({ callbacks, status, update });
		if (callbacks && Object.hasOwn(callbacks, status)) {
			callbacks[status](update);
		}
	}, []);

	const handleSnackbar = useCallback(
		(messages, status) => {
			let message;
			if (messages && Object.hasOwn(messages, status)) {
				message = messages[status];
				// enqueueSnackbar(messages[status], {
				// 	variant: status,
				// });
			} else {
				message = defaultMessages[status];
			}
			enqueueSnackbar(message, {
				variant: status,
			});
		},
		[enqueueSnackbar]
	);

	const updateCollection = useCallback(
		async ({ messages, callbacks, update, suppressSnackbar }) => {
			try {
				await insightCollectionDraftUpdate({
					insightUuid: id,
					draftUpdate: update,
				});
				handleCallback(callbacks, SUCCESS, update);
				if (!suppressSnackbar) {
					handleSnackbar(messages, SUCCESS);
				}
			} catch (error) {
				console.log(error);
				handleCallback(callbacks, ERROR, update);
				if (!suppressSnackbar) {
					handleSnackbar(messages, ERROR);
				}
			}
		},
		[handleCallback, handleSnackbar, id]
	);

	return updateCollection;
}
