const defaultAxisLabels = (xAxisLabel, yAxisLabel) => {
	return {
		defaultXAxisLabel: {
			value: xAxisLabel,
			angle: -90,
			position: 'insideLeft',
			offset: -5,
		},
		defaultYAxisLabel: {
			value: yAxisLabel,
			angle: -90,
			position: 'insideLeft',
			offset: -5,
		},
	};
};

export { defaultAxisLabels };
