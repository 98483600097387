import { DialogActions, DialogContent, Button } from '@mui/material';
import { useAppContext } from '../../../../../context/ContextManager';
import ActionButtonContainer from '../../../ActionButtonContainer';
import { ScreenTabs } from './components/ScreenTabs';
import { useScreensConfigContext } from './ScreenConfigContext';
import PropTypes from 'prop-types';

export const CancelButton = ({ onClose }) => {
	return (
		<Button
			color="primary"
			onClick={onClose}
			variant="outlined"
			disableElevation
			// style={{ color: 'white' }}
		>
			Cancel
		</Button>
	);
};

export const ActionButton = ({ onClick, label, disabled }) => {
	return (
		<Button // maybe this should be a text button on non mobile??
			disableElevation
			style={{ color: 'white' }}
			variant="contained"
			onClick={onClick}
			disabled={disabled}
		>
			{label}
		</Button>
	);
};

const isFinalStep = (
	currentTab,
	addScreenActiveStep,
	addScreenStepsCount,
	editScreenActiveStep,
	editScreenStepsCount,
	type,
	screenToAddType
) => {
	if (currentTab === 0)
		if (addScreenActiveStep === addScreenStepsCount - 1) {
			return true;
			// } else if (addScreenActiveStep === 0 && type === 'addScreen') {
		} else if (
			addScreenActiveStep === 0 &&
			screenToAddType === 'Organisation'
		) {
			return true;
		} else {
			return false;
		}
	// return addScreenActiveStep === addScreenStepsCount - 1;
	if (currentTab === 1)
		return editScreenActiveStep === editScreenStepsCount - 1;
};

const evalButtonLabel = (
	buttonType,
	currentTab,
	addScreenActiveStep,
	addScreenStepsCount,
	editScreenActiveStep,
	editScreenStepsCount,
	type,
	screenToAddType
) => {
	const finalLabel = {
		0: 'add screen',
		1: 'apply edit',
	};

	switch (buttonType) {
		case 'back':
			return 'back';
		case 'next':
			return isFinalStep(
				currentTab,
				addScreenActiveStep,
				addScreenStepsCount,
				editScreenActiveStep,
				editScreenStepsCount,
				type,
				screenToAddType
			)
				? finalLabel[currentTab]
				: 'next';
		default:
			break;
	}
};

const ActionButtons = ({ onScreenChange, onClose }) => {
	const {
		addScreenActiveStep,
		setAddScreenActiveStep,
		currentTab,
		addScreenStepsCount,
		addScreenParams,
		editScreenActiveStep,
		editScreenStepsCount,
		setEditScreenActiveStep,
		editNextStepDisabled,
		addNextStepDisabled,
		editScreenParams,
	} = useScreensConfigContext();

	const onClickNext = () => {
		const finalAddStep = isFinalStep(
			currentTab,
			addScreenActiveStep,
			addScreenStepsCount,
			editScreenActiveStep,
			editScreenStepsCount,
			addScreenParams.type
		);

		const finalEditStep = isFinalStep(
			currentTab,
			addScreenActiveStep,
			addScreenStepsCount,
			editScreenActiveStep,
			editScreenStepsCount
		);

		if (currentTab === 0 && !finalAddStep) {
			setAddScreenActiveStep(previousState => previousState + 1);
		} else if (currentTab === 0 && finalAddStep) {
			onScreenChange({
				mode: 'add',
				addScreenReference: addScreenParams.addScreenReference,
				copyScreenReference: addScreenParams.copyScreenReference,
				newScreenName: addScreenParams.newScreenName,
				type: addScreenParams.type,
				position: addScreenParams.position,
				backgroundColor: addScreenParams.backgroundColor,
			});
			onClose();
		} else if (currentTab === 1 && !finalEditStep) {
			setEditScreenActiveStep(previousState => previousState + 1);
		} else if (currentTab === 1 && finalEditStep) {
			onScreenChange({
				mode: 'edit',
				//
				screenUuid: editScreenParams.screenUuid,
				newScreenName: editScreenParams.newScreenName,
				// position: editScreenParams.position,
				position:
					editScreenParams.position !== null
						? editScreenParams.position
						: editScreenParams.existingPosition,

				backgroundColor: editScreenParams.backgroundColor,
			});
			onClose();
		}
	};

	const onClickBack = () => {
		if (currentTab === 0) {
			setAddScreenActiveStep(previousState => previousState - 1);
		}
		if (currentTab === 1) {
			setEditScreenActiveStep(previousState => previousState - 1);
		}
	};

	const evalBackDisabled = currentTab => {
		switch (currentTab) {
			case 0:
				return addScreenActiveStep === 0;
			case 1:
				return editScreenActiveStep === 0;
			default:
				break;
		}
	};

	const evalNextDisabled = currentTab => {
		switch (currentTab) {
			case 0:
				return addNextStepDisabled;
			case 1:
				return editNextStepDisabled;
			default:
				break;
		}
	};

	return (
		<>
			<ActionButton
				onClick={onClickBack}
				label={evalButtonLabel(
					'back',
					currentTab,
					addScreenActiveStep,
					addScreenStepsCount,
					editScreenActiveStep,
					editScreenStepsCount
				)}
				disabled={evalBackDisabled(currentTab)}
			/>
			<ActionButton
				onClick={onClickNext}
				label={evalButtonLabel(
					'next',
					currentTab,
					addScreenActiveStep,
					addScreenStepsCount,
					editScreenActiveStep,
					editScreenStepsCount,
					addScreenParams.type,
					addScreenParams.ownerType
				)}
				disabled={evalNextDisabled(currentTab)}
			/>
		</>
	);
};

const ContextualDialogActions = ({ onClose, onScreenChange }) => {
	const { deviceType } = useAppContext();
	return (
		<DialogActions>
			{deviceType === 'mobile' ? (
				<ActionButtonContainer>
					<CancelButton onClose={onClose} />
					<ActionButtons
						onScreenChange={onScreenChange}
						onClose={onClose}
					/>
				</ActionButtonContainer>
			) : (
				<>
					<ActionButtonContainer>
						<CancelButton onClose={onClose} />
					</ActionButtonContainer>
					<ActionButtonContainer>
						<ActionButtons
							onScreenChange={onScreenChange}
							onClose={onClose}
						/>
					</ActionButtonContainer>
				</>
			)}
		</DialogActions>
	);
};

export function ScreenConfigDialogContent({
	onScreenChange,
	onClose,
	unavailablePositions,
	screenCount,
}) {
	const { deviceType } = useAppContext();

	return (
		<>
			<DialogContent
				sx={{
					width: deviceType === 'desktop' ? 600 : '100%',
					paddingBottom: 0,
				}}
			>
				<ScreenTabs
					unavailablePositions={unavailablePositions}
					screenCount={screenCount}
					onClose={onClose}
					onScreenChange={onScreenChange}
				/>
			</DialogContent>
			<ContextualDialogActions
				onClose={onClose}
				onScreenChange={onScreenChange}
			/>
		</>
	);
}

ScreenConfigDialogContent.propTypes = {
	onScreenChange: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	unavailablePositions: PropTypes.arrayOf(PropTypes.number).isRequired,
	screenCount: PropTypes.number.isRequired,
};

ContextualDialogActions.propTypes = {
	onScreenChange: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
};

ActionButtons.propTypes = {
	onScreenChange: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
};

CancelButton.propTypes = {
	onClose: PropTypes.func.isRequired,
};
