const yup = require('yup');

export const dataStreamGetSchema = yup.object({
	uuid: yup
		.string()
		.uuid()
		.required(),
	name: yup.string().default(''),
	description: yup.string().default(''),
	create_timestamp: yup.number().required(),
	modify_timestamp: yup.number().required(),
	create_by: yup
		.string()
		.uuid()
		.required(),
	modify_by: yup
		.string()
		.uuid()
		.required(),
});

export const dataStreamAddSchema = yup.object({
	name: yup.string().default(''),
	description: yup.string().default(''),
});

export const dataStreamAddResponseSchema = yup.object({
	uuid: yup
		.string()
		.uuid()
		.required(),
	name: yup.string().default(''),
	description: yup.string().default(''),
	create_timestamp: yup.number().required(),
	modify_timestamp: yup.number().required(),
	create_by: yup
		.string()
		.uuid()
		.required(),
	modify_by: yup
		.string()
		.uuid()
		.required(),
});
