import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useMemo } from 'react';
import TileContent from '../../content/TileContent';
import BtInfoChip from '../../../BtInfoChip';
import { tileLabels, buttonTypes } from '../../tileConstants';
import NativeScreen from './NativeScreen';
import NativeLayout from './NativeLayout';

export default function NativeConfig({
	screenData,
	onNativeChange,
	onTileChange,
	onContainerChange,
}) {
	const THEME = useTheme();

	const variantsToPurge = useMemo(() => {
		return ['insight_visualisation', 'insight_collection', 'information'];
	}, []);

	const buttonVariantsToPurge = useMemo(() => {
		return ['shortcut', 'data_manager'];
	}, []);

	/**
	 * The native layout is filtered for tiles which are not supported on native
	 */
	const filteredLayout = useMemo(
		() => {
			// variants to purge on native

			const filterTiles = tiles => {
				// const res = {};
				const tilesToRetain = [];

				Object.keys(tiles).forEach(tile => {
					if (tiles[tile].variant === 'button') {
						if (
							!buttonVariantsToPurge.includes(
								tiles[tile].data.button_type
							)
						)
							tilesToRetain.push(tile);
						if (tiles[tile].data.button_type === '')
							tilesToRetain.push(tile);
					} else if (tiles[tile].variant === 'container') {
						let validChildren = [];
						tiles[tile].data.children.forEach(childUuid => {
							if (
								!buttonVariantsToPurge.includes(
									tiles[childUuid].data.button_type
								)
							)
								validChildren.push(tiles[childUuid]);
						});
						// if (validChildren.length > 0) tilesToRetain.push(tile);
						tilesToRetain.push(tile);
					} else if (!variantsToPurge.includes(tiles[tile].variant)) {
						tilesToRetain.push(tile);
					}
				});

				// return res;
				return screenData.layouts.native.filter(layoutItem =>
					tilesToRetain.includes(layoutItem)
				);
			};
			const res = filterTiles(screenData.tiles);
			console.log(
				'filteredLayout:',
				res,
				'original layout:',
				screenData.layouts.native
			);
			return res;
		},
		[buttonVariantsToPurge, screenData, variantsToPurge]
	);

	/**
	 *
	 * @returns Array of info chips
	 */
	const Info = () => {
		return [
			`The following tile types are not currently supported on the mobile app and will not be shown here: 
        ${variantsToPurge.map((variant, index) => {
			return ` ${tileLabels[variant]}`;
		})}, ${buttonVariantsToPurge.map(
				(buttonVariant, index) =>
					` ${
						buttonTypes.find(type => type.value === buttonVariant)
							.label
					} button`
			)}.`,
			'Empty Container tiles are not displayed on the app but will be shown here.',
		].map((item, index) => {
			return (
				<BtInfoChip
					key={index}
					chipStyle={{ paddingTop: 5, paddingBottom: 5 }}
					style={{ marginTop: 10 }}
					textAlign="left"
					text={item}
				/>
			);
		});
	};

	return (
		<NativeScreen
			backgroundColor={
				screenData.background_color?.length > 0
					? screenData.background_color
					: THEME.palette.background.insights
			}
			nativeDisplay={
				<NativeLayout
					filteredLayout={filteredLayout}
					screenData={screenData}
					onNativeChange={onNativeChange}
					onTileChange={onTileChange}
					onContainerChange={onContainerChange}
				/>
			}
			info={<Info />}
		/>
	);
}
