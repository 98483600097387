import {
	AUTOMATION,
	INSIGHT_COLLECTION,
	LOCK_STATUS_API,
	LOCK_TO_CURRENT,
	LOCK_TO_OTHER,
	UNLOCK,
	WORKFLOW_TEMPLATE,
} from '../constants';
import { lookupEndpoint } from '../lookupTables/apiEndpoints';

const changeLockStatus = async ({
	recordHistoryGet,
	moduleFullName,
	mode,
	apiUuidParamPayload,
	nextLockUser,
	module,
}) => {
	const result = {};
	result.variant = 'success';

	// check the parameters
	if (
		!recordHistoryGet ||
		!moduleFullName ||
		!mode ||
		!apiUuidParamPayload ||
		!module ||
		typeof recordHistoryGet !== 'function' ||
		typeof moduleFullName !== 'string' ||
		!Object.values({
			UNLOCK,
			LOCK_TO_CURRENT,
			LOCK_TO_OTHER,
		}).includes(mode) ||
		typeof apiUuidParamPayload !== 'object' ||
		(typeof nextLockUser !== 'string' && mode === LOCK_TO_CURRENT) ||
		(typeof nextLockUser !== 'string' && mode === LOCK_TO_OTHER) ||
		(nextLockUser !== null && mode === UNLOCK) ||
		!Object.values({
			INSIGHT_COLLECTION,
			WORKFLOW_TEMPLATE,
			AUTOMATION,
		}).includes(module)
	) {
		console.warn('changeLockStatus parameter error');
		result.text = 'Error changing lock status';
		result.variant = 'error';
		recordHistoryGet();
		return result;
	}

	// snackbar text
	const successText = {
		lockToCurrent: `${moduleFullName} locked`,
		unlock: `${moduleFullName} lock released`,
		lockToOther: `${moduleFullName} locked to ${nextLockUser?.title}`,
	};
	const errorText = {
		lockToCurrent: `Error locking ${moduleFullName}`,
		unlock: `Error releasing ${moduleFullName} lock`,
		lockToOther: `Error locking ${moduleFullName}`,
	};

	try {
		// lookup the API call
		const changeLockEndpoint = lookupEndpoint(module, LOCK_STATUS_API);

		// make the API call
		await changeLockEndpoint({
			...apiUuidParamPayload,
			// userUuid: nextLockUser?.id,
			userUuid: nextLockUser,
		});

		// update the response
		result.text = successText[mode];
	} catch (error) {
		console.log(error);
		result.text = errorText[mode];
		result.variant = 'error';
	} finally {
		// refresh the context data
		recordHistoryGet();
		return result;
	}
};

export { changeLockStatus };
