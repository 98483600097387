import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import produce from 'immer';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import BtConfirmDialog from '../../../components/generic/BtConfirmDialog';
import {
	BtForm,
	BtFormActionButtons,
	BtFormActionsContainer,
	BtFormContent,
	BtFormTextField,
} from '../../../components/generic/forms';
import BtMessage from '../../../components/generic/BtMessage';
import { roleDelete, roleUpdate } from '../../../API/role.api';
import BtDeleteOptionWrapper from '../../../components/generic/BtDeleteOptionWrapper';

const schema = yup.object({
	name: yup
		.string()
		.min(3)
		.required()
		.label('Role Name'),
	description: yup
		.string()
		.min(3)
		.required()
		.label('Role Description'),
});

export default function RoleDetails({
	role,
	setRole,
	loading,
	platformManaged,
}) {
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();

	const [sending, setSending] = useState(false);
	const [deleteDialog, setDeleteDialog] = useState(false);

	const defaultValues = useMemo(
		() => {
			const { name, description } = role || {};
			return { name, description };
		},
		[role]
	);

	const onSubmit = useCallback(
		async (values, reset) => {
			setSending(true);

			try {
				await roleUpdate({
					roleUuid: role?.uuid,
					roleUpdate: values,
				});

				setRole(
					produce(draft => {
						draft.name = values.name;
						draft.description = values.description;
						draft.modify_timestamp = Date.now();
					})
				);

				reset(values);
			} catch {
				enqueueSnackbar('Failed to update the role details', {
					variant: 'error',
				});
			} finally {
				setSending(false);
			}
		},
		[enqueueSnackbar, role, setRole]
	);

	const handleDeleteRole = useCallback(
		async () => {
			try {
				await roleDelete({ roleUuid: role.uuid });
				enqueueSnackbar('Role deleted');
				history.push('/Roles');
			} catch (error) {
				enqueueSnackbar('Failed to delete role', {
					variant: 'error',
				});
			}
		},
		[enqueueSnackbar, history, role]
	);

	return (
		<>
			<BtForm
				defaultValues={defaultValues || {}}
				validationSchema={schema}
				onSubmit={onSubmit}
				loading={loading}
				sending={sending}
			>
				<BtFormContent>
					{platformManaged && (
						<BtMessage message="This role is managed by the platform, not your organisation. Therefore, you cannot edit the role details." />
					)}
					<BtFormTextField
						name="name"
						label="Name"
						disabled={platformManaged}
					/>
					<BtFormTextField
						name="description"
						label="Description"
						multiline
						maxRows={4}
						disabled={platformManaged}
					/>
					{!platformManaged && (
						<BtDeleteOptionWrapper loading={loading}>
							<Button
								color="error"
								onClick={() => setDeleteDialog(true)}
								startIcon={<DeleteIcon />}
								variant="outlined"
								disableElevation
							>
								Delete Role
							</Button>
							<BtFormActionsContainer
								style={{
									padding: '1em 0',
								}}
							>
								<BtFormActionButtons
									SubmitIcon={<SaveIcon />}
									submitVerb={'Save'}
									destructiveVerb={'Discard changes'}
								/>
							</BtFormActionsContainer>
						</BtDeleteOptionWrapper>
					)}
				</BtFormContent>
			</BtForm>
			<BtConfirmDialog
				action={() => handleDeleteRole()}
				isDestructive
				open={deleteDialog}
				onClose={() => setDeleteDialog(false)}
				title="Delete Role?"
				prompt={`Are you sure you want to delete the ${
					role?.name
				} role? This cannot be undone.`}
				verb="Delete Role"
				ActionIcon={<DeleteIcon />}
			/>
		</>
	);
}

RoleDetails.propTypes = {
	role: PropTypes.object,
	setRole: PropTypes.func.isRequired,
	loading: PropTypes.bool,
	platformManaged: PropTypes.bool,
};
