import PropTypes from 'prop-types';

import { a11yProps, BtTab, BtTabBar, BtTabPanel } from '../../../../BtTabView';
import { useScreensConfigContext } from '../ScreenConfigContext';
import { TilesStepper } from '../../formComponents/TilesStepper';
import { ScreenType } from './newScreenSteps/ScreenType';
import ScreenNameAndPosition from './newScreenSteps/NewScreenOptions';
import { useContext, useEffect } from 'react';
import SelectScreen from './editScreenSteps/SelectScreen';
import EditScreen from './editScreenSteps/EditScreen';
import BtMessage from '../../../../BtMessage';
import { ScreensContext } from '../../../ScreensContext';
import { screenGroupGet } from '../../../../../../API';
import PermsSubsErrorDetailDialog from '../../../../../dialogs/PermsSubsErrorDetailDialog';

const AddScreenTab = ({ unavailablePositions, activeStep, setStepsCount }) => {
	const { userPermissions } = useContext(ScreensContext);
	const {
		addOptions,
		setAddNextStepDisabled,
		screenCount,
	} = useScreensConfigContext();
	const steps = [
		{
			label: 'Screen type',
			component: <ScreenType />,
		},
		{
			label: 'Options',
			component: (
				<ScreenNameAndPosition
					unavailablePositions={unavailablePositions}
				/>
			),
		},
	];

	useEffect(() => {
		setStepsCount(steps.length);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!userPermissions.ScreenCreate && addOptions.length === 0) {
		setAddNextStepDisabled(true);
		return (
			<BtMessage
				style={{ margin: 20 }}
				message="There are no more screens which you can add."
			/>
		);
	}

	if (!userPermissions.ScreenCreate && !userPermissions.ScreenGroupEdit) {
		setAddNextStepDisabled(true);
		return (
			<BtMessage
				variant="error"
				style={{ margin: 20 }}
				message="You do not have the required permissions to create or add screens to the screen group"
			/>
		);
	}

	return screenCount < 9 ? (
		<TilesStepper steps={steps} activeStep={activeStep} />
	) : (
		<div
			style={{
				width: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				padding: 50,
			}}
		>
			<BtMessage message="The maximum number of screen allowed is 9, if you want to add a screen please remove one first." />
		</div>
	);
};

const EditScreenTab = ({
	activeStep,
	setStepsCount,
	unavailablePositions,
	onClose,
	onScreenChange,
}) => {
	const steps = [
		{
			label: 'Select screen',
			component: (
				<SelectScreen
					onClose={onClose}
					onScreenChange={onScreenChange}
				/>
			),
		},
		{
			label: 'Options',
			component: (
				<EditScreen unavailablePositions={unavailablePositions} />
			),
		},
	];

	useEffect(() => {
		setStepsCount(steps.length);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <TilesStepper steps={steps} activeStep={activeStep} />;
};

export const ScreenTabs = ({
	unavailablePositions,
	onClose,
	onScreenChange,
}) => {
	const {
		currentTab,
		setCurrentTab,
		setAddScreenStepsCount,
		setEditScreenActiveCount,
		editScreenActiveStep,
		addScreenActiveStep,
		setAddScreenParams,
		addScreenParams,
		setAddNextStepDisabled,
		screenCount,
		setEditNextStepDisabled,
	} = useScreensConfigContext();
	const { screens } = useContext(ScreensContext);

	useEffect(() => {
		const screenParams = { ...addScreenParams };
		addScreenParams.newScreenName = '';
		addScreenParams.type = 'blank';
		addScreenParams.position = screenCount + 1;

		setAddScreenParams(screenParams);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(
		() => {
			setAddNextStepDisabled(screenCount > 8);
		},
		[screenCount, setAddNextStepDisabled]
	);

	useEffect(
		() => {
			console.log({ screens });
			setEditNextStepDisabled(!screens?.length > 0);
		},
		[screens, setEditNextStepDisabled]
	);

	return (
		<>
			<BtTabBar
				currentTab={currentTab}
				onTabChange={(event, selectedTab) => setCurrentTab(selectedTab)}
				style={{
					height: '5.2rem',
				}}
			>
				<BtTab
					label="Add Screen"
					{...a11yProps(0)}
					key={0}
					sx={{
						marginBottom: 0,
					}}
				/>
				<BtTab
					label="Edit Screen"
					{...a11yProps(1)}
					key={1}
					sx={{
						marginBottom: 0,
					}}
				/>
			</BtTabBar>

			<BtTabPanel currentTab={currentTab} index={0} key={0}>
				<AddScreenTab
					unavailablePositions={unavailablePositions}
					activeStep={addScreenActiveStep}
					setStepsCount={setAddScreenStepsCount}
				/>
			</BtTabPanel>

			<BtTabPanel currentTab={currentTab} index={1} key={1}>
				{screens?.length > 0 ? (
					<EditScreenTab
						activeStep={editScreenActiveStep}
						setStepsCount={setEditScreenActiveCount}
						unavailablePositions={unavailablePositions}
						onClose={onClose}
						onScreenChange={onScreenChange}
					/>
				) : (
					<BtMessage
						style={{ margin: '1em' }}
						message="There are no screens to edit, try adding one!"
					/>
				)}
			</BtTabPanel>
		</>
	);
};

ScreenTabs.propTypes = {
	unavailablePositions: PropTypes.arrayOf(PropTypes.number).isRequired,
};

AddScreenTab.propTypes = {
	unavailablePositions: PropTypes.arrayOf(PropTypes.number).isRequired,
	activeStep: PropTypes.number.isRequired,
	setStepsCount: PropTypes.func.isRequired,
};

EditScreenTab.propTypes = {
	activeStep: PropTypes.number.isRequired,
	setStepsCount: PropTypes.func.isRequired,
};
