import React, { forwardRef, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { darkTheme, lightTheme } from '../../style/muiTheme';
import { styled } from '@mui/material/styles';
import {
	ButtonBase,
	FormControl,
	FormGroup,
	FormHelperText,
	Typography,
} from '@mui/material';
import _ from 'lodash';

import BtColour from './BtColour';
import BtPopover from './BtPopover';

const Container = styled('div')(({ theme }) => ({
	display: 'flex',
	flexWrap: 'wrap',
	flexDirection: 'row',
	justifyContent: 'space-between',
	maxWidth: 300,
	width: '100%',

	[theme.breakpoints.down('sm')]: {
		maxWidth: '100%',
	},
}));

const Button = styled(ButtonBase)(({ theme }) => ({
	alignItems: 'center',
	borderRadius: 8,
	display: 'flex',
	padding: '0.6em 1em',

	transition: theme.transitions.create(['background-color'], {
		duration: '250ms',
	}),

	'&:hover': {
		backgroundColor: theme.palette.grey[400] + 20,
	},
}));

const strArrIntersection = (a, b) =>
	(a || []).filter(str => (b || []).includes(str));

export const BtColourPicker = forwardRef(
	(
		{ disabled, errorText, label, onChange, showNoneOption, style, value },
		ref
	) => {
		const [anchorEl, setAnchorEl] = useState(null);

		const availableColours = useMemo(
			() => {
				const { colourPicker: lightColours } = lightTheme.palette;
				const { colourPicker: darkColours } = darkTheme.palette;

				const intersectedColours = strArrIntersection(
					Object.keys(lightColours),
					Object.keys(darkColours)
				);

				return [
					...(showNoneOption ? [null] : []),
					...intersectedColours,
				];
			},
			[showNoneOption]
		);

		return (
			<div style={style} ref={ref}>
				<FormControl error={!!errorText} disabled={disabled}>
					{label && (
						<Typography
							color={!!errorText ? 'error' : 'default'}
							style={{
								marginBottom: '0.5em',
								opacity: disabled ? 0.7 : 1.0,
							}}
							variant="subtitle2"
						>
							{label}
						</Typography>
					)}
					<Button
						onClick={event => setAnchorEl(event.target)}
						focusRipple
						disabled={disabled}
						style={{ opacity: disabled ? 0.7 : 1.0 }}
					>
						<BtColour
							colour={value}
							size="small"
							style={{ marginRight: '0.8em' }}
							disableInteractive
						/>
						{value ? _.capitalize(value) : 'None'}
					</Button>
					<FormGroup>
						<BtPopover
							anchorEl={anchorEl}
							onClose={() => setAnchorEl(null)}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
						>
							<Container>
								{availableColours.map(colour => (
									<div key={colour} style={{ margin: '1em' }}>
										<BtColour
											colour={colour}
											disabled={disabled}
											selected={
												value?.toLowerCase() ===
													colour?.toLowerCase() ||
												(!value && !colour)
											}
											onClick={() => {
												setAnchorEl(null);
												onChange?.(
													_.capitalize(colour)
												);
											}}
										/>
									</div>
								))}
							</Container>
						</BtPopover>
						{errorText && (
							<FormHelperText style={{ margin: 0 }}>
								{errorText}
							</FormHelperText>
						)}
					</FormGroup>
				</FormControl>
			</div>
		);
	}
);

BtColourPicker.propTypes = {
	disabled: PropTypes.bool,
	errorText: PropTypes.string,
	label: PropTypes.string,
	onChange: PropTypes.func,
	showNoneOption: PropTypes.bool,
	style: PropTypes.object,
	value: PropTypes.string,
};

export default BtColourPicker;
