import { apiClient } from './api_client';
import { uuidRegex } from './utils';

import {
	tagsGetListSchema,
	tagGroupSearchSchema,
	tagsPostSchema,
	tagGroupGetSchema,
	tagsPutSchema,
	tagValuePostSchema,
	tagValuePutSchema,
} from './validations/tagsValidation';

//========================================================================================================
async function tagsGroupGetList() {
	const req_url = 'tags';
	try {
		const response = await apiClient({ method: 'get', url: req_url });

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let tag_groups = await tagsGetListSchema.validate(
					response.tag_groups
				);
				return tag_groups;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function tagGroupValueSearch({ searchString, tagGroupUuid }) {
	// A minimum of 3 chars are needed for a search to lot overload the API
	if (searchString?.length < 3) {
		return [];
	}

	const req_url = 'tags/find';
	try {
		let params = { tagName: searchString };
		if (tagGroupUuid) {
			if (!uuidRegex.test(tagGroupUuid)) {
				throw new Error('Param Error');
			}
			params.tagGroupUuid = tagGroupUuid;
		}
		const response = await apiClient({
			method: 'get',
			url: req_url,
			params: params,
		});

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let tagValues = await tagGroupSearchSchema.validate(
					response.tag_values
				);
				return tagValues;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw error;
	}
}

//========================================================================================================
async function tagGroupAdd({ newTagGroup }) {
	// Validate the newTagGroup details
	try {
		await tagsPostSchema.validate(newTagGroup);
	} catch (error) {
		throw error;
	}

	const req_url = 'tags';
	try {
		const response = await apiClient({
			method: 'post',
			url: req_url,
			body: newTagGroup,
		});

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let tagGroup = await tagGroupGetSchema.validate(
					response.tag_group
				);
				return tagGroup;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw error;
	}
}

//========================================================================================================
async function tagGroupGet({ tagGroupUuid }) {
	if (!uuidRegex.test(tagGroupUuid)) {
		throw new Error('Param Error');
	}

	const req_url = `tags/${tagGroupUuid}`;
	try {
		const response = await apiClient({ method: 'get', url: req_url });

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let tagGroup = await tagGroupGetSchema.validate(
					response.tag_group
				);
				return tagGroup;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function tagGroupUpdate({ tagGroupUuid, tagGroupUpdate }) {
	if (!uuidRegex.test(tagGroupUuid)) {
		throw new Error('Param Error');
	}

	// Validate the tagGroupUpdate details
	try {
		await tagsPutSchema.validate(tagGroupUpdate);
	} catch (error) {
		throw error;
	}

	const req_url = `tags/${tagGroupUuid}`;
	try {
		const response = await apiClient({
			method: 'put',
			url: req_url,
			body: tagGroupUpdate,
		});

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let tagGroup = await tagGroupGetSchema.validate(
					response.tag_group
				);
				return tagGroup;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw error;
	}
}

//========================================================================================================
async function tagGroupAddValue({ tagGroupUuid, newTagValue }) {
	if (!uuidRegex.test(tagGroupUuid)) {
		throw new Error('Param Error');
	}

	// Validate the new tag value details
	try {
		await tagValuePostSchema.validate(newTagValue);
	} catch (error) {
		throw error;
	}

	const req_url = `tags/${tagGroupUuid}`;
	try {
		const response = await apiClient({
			method: 'post',
			url: req_url,
			body: newTagValue,
		});

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let tagGroup = await tagGroupGetSchema.validate(
					response.tag_group
				);
				return tagGroup;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function tagGroupUpdateValue({
	tagGroupUuid,
	tagGroupValueUuid,
	tagValueUpdate,
}) {
	if (!uuidRegex.test(tagGroupUuid)) {
		throw new Error('Param Error');
	}

	if (!uuidRegex.test(tagGroupValueUuid)) {
		throw new Error('Param Error');
	}

	// Validate the tagValueUpdate details
	try {
		await tagValuePutSchema.validate(tagValueUpdate);
	} catch (error) {
		throw error;
	}

	const req_url = `tags/${tagGroupUuid}/${tagGroupValueUuid}`;
	try {
		const response = await apiClient({
			method: 'put',
			url: req_url,
			body: tagValueUpdate,
		});

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let tagGroup = await tagGroupGetSchema.validate(
					response.tag_group
				);
				return tagGroup;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

export {
	tagsGroupGetList,
	tagGroupValueSearch,
	tagGroupGet,
	tagGroupAdd,
	tagGroupUpdate,
	tagGroupAddValue,
	tagGroupUpdateValue,
};
