import React, { memo, useEffect, useState } from 'react';

import {
	StatusTimelineChart,
	StatusTimeline,
	XAxis,
	YAxis,
	Tooltip,
} from '../../../../components/charts';
import { Paper, Typography, Box } from '@mui/material';
import { formatTimestamp } from '../../../../components/charts/Utils';
import { msTimeToStr } from '../../../../utils/timeUtils';
import PropTypes from 'prop-types';

const colours = [
	'#5fc1c9', // blue - [0]
	'#3ac258', // green - [1]
	'#e29834', // orange - [2]
	'#9046b6', // purple - [3]
	'#dc564c', // red - [4]
	'#1c3249', // dark blue - [5]
	'#f2b705', // yellow - [6]
	'#155e1c', // dark green - [7]
	'#78170e', // dark red - [8]
	'#9e4d02', // dark orange - [9]
];

const StatusColours = {
	'Unknown / In Prod': colours[0],
	Running: colours[1],
	'Technical on Machine': colours[2],
	'Awaiting Setter': colours[3],
	'Tool Hanger on M/c': colours[4],
	'Awaiting Tool Hanger': colours[5],
	'Awaiting Machine': colours[6],
	'Awaiting Senior Tech': colours[7],
	'Materials Drying': colours[8],
	'Awaiting First Off Approval': colours[9],
};

const CustomStatusTimeLineTooltip = memo(function CustomTooltip({
	active,
	payload,
	label,
	// visOptions,
	timestampFormat,
	// sortValues,
}) {
	// useEffect(
	// 	() => {
	// 		console.log('CustomTooltip', { payload, active, label });
	// 	},
	// 	[payload, active, label]
	// );

	// console.log('CustomTooltip', { payload, active, label });
	// const title = useMemo(
	// 	() => {
	// 		if (typeof label === 'string') {
	// 			return label;
	// 		}
	// 		if (typeof label === 'number') {
	// 			const formattedTimestamp = formatTimestamp(
	// 				label,
	// 				timestampFormat
	// 			);
	// 			if (formattedTimestamp === 'Invalid date') {
	// 				return null;
	// 			} else {
	// 				return formattedTimestamp;
	// 			}
	// 		}
	// 		return null;
	// 	},
	// 	[label, timestampFormat]
	// );
	const title = label || payload.id;

	const statusColour = StatusColours[payload.status];

	if (active && payload) {
		// sort the values fur user friendly display, if required
		// const sortedPayload = sortValues
		// 	? [...payload].sort((a, b) => b.value - a.value)
		// 	: [...payload];

		return (
			<Paper
				sx={{
					padding: '1rem',
				}}
				elevation={3}
			>
				{title && (
					<Typography
						fontWeight={500}
						variant="body1"
						// sx={{
						// 	marginLeft: '0.5rem',
						// }}
					>
						{title}
					</Typography>
				)}
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: '0.5rem',
						marginTop: '0.5rem',
					}}
				>
					<Typography variant="body2">
						{`Start Time: ${formatTimestamp(
							payload.startTime,
							timestampFormat
						)}`}
					</Typography>
					<Typography variant="body2">
						{`End Time: ${formatTimestamp(
							payload.endTime,
							timestampFormat
						)}`}
					</Typography>
					<Typography variant="body2">
						{`Duration: ${msTimeToStr(
							payload.endTime - payload.startTime
						)}`}
					</Typography>

					<Box style={{ display: 'flex', alignItems: 'center' }}>
						<Typography variant="body2">Status:</Typography>
						<Box
							style={{
								backgroundColor: statusColour,
								width: '100%',
								// height: '1rem',
								borderRadius: '0.5rem',
								paddingLeft: '0.5rem',
								paddingRight: '0.5rem',
								marginLeft: '0.5rem',
								marginRight: '0.5rem',
							}}
						>
							<Typography variant="body2" align="center">
								{payload.status}
							</Typography>
						</Box>
					</Box>
				</Box>

				{/* {sortedPayload.map((item, index) => (
					<Box
						key={`item-${index}`}
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							gap: '1rem',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<CircleMedium style={{ color: item.color }} />
							<Typography variant="body2">
								{`${
									valueFormatter(
										item.value,
										item.name,
										visOptions
									)[1]
								} : `}
							</Typography>
						</Box>
						<Typography variant="body2">
							{
								valueFormatter(
									item.value,
									item.name,
									visOptions
								)[0]
							}
						</Typography>
					</Box>
				))} */}
			</Paper>
		);
	}

	return null;
});

export default function StatusTimelineComponent({ data, visOptions, palette }) {
	const [ids, setIds] = useState([]);
	const [groupedData, setGroupedData] = useState([]);
	const [unqiueStatuses, setUniqueStatuses] = useState([]);

	// Group data by id
	useEffect(
		() => {
			// console.log('visOptions', visOptions);
			// console.log('data', data);
			// console.log('palette', palette);

			const getAsTimestamp = date => {
				if (typeof date === 'number') {
					return date;
				}
				return new Date(date).getTime();
			};

			const newIds = [];
			const newGroupedData = [];
			const newUnqiueStatuses = [];

			data.forEach(lineData => {
				if (!newIds.includes(lineData[visOptions.idField])) {
					newIds.push(lineData[visOptions.idField]);
					newGroupedData.push({
						id: lineData[visOptions.idField],
						statusSegments: [],
					});
				}
			});
			data.forEach(lineData => {
				newGroupedData.forEach(groupedLineData => {
					if (groupedLineData.id === lineData[visOptions.idField]) {
						if (
							!newUnqiueStatuses.includes(
								lineData[visOptions.statusField]
							)
						) {
							newUnqiueStatuses.push(
								lineData[visOptions.statusField]
							);
						}

						groupedLineData.statusSegments.push({
							startTime: getAsTimestamp(
								lineData[visOptions.startTimeField]
							),
							endTime: getAsTimestamp(
								lineData[visOptions.endTimeField]
							),
							status: lineData[visOptions.statusField],
						});
					}
				});
			});

			// Sort the grouped data by id
			newGroupedData.sort((a, b) => {
				if (a.id < b.id) {
					return -1;
				}
				if (a.id > b.id) {
					return 1;
				}
				return 0;
			});

			setIds(newIds);
			setGroupedData(newGroupedData);
			setUniqueStatuses(newUnqiueStatuses);

			// console.log('newGroupedData', newGroupedData);
			// console.log('newIds', newIds);
		},
		[data, visOptions]
	);

	return (
		<StatusTimelineChart data={groupedData}>
			{(groupedData || []).map((lineData, index) => (
				<StatusTimeline key={index} dataKey={lineData.id} />
			))}

			<XAxis dataKey="time" />
			<YAxis />
			<Tooltip
				wrapperStyle={{ outline: 'none' }}
				content={props => (
					<CustomStatusTimeLineTooltip
						// sortValues
						// visOptions={visOptions}
						// timestampFormat={timestampFormat}
						timestampFormat="YYYY-MM-DD HH:mm:ss"
						{...props}
					/>
				)}
			/>
		</StatusTimelineChart>
	);
}

StatusTimelineComponent.PropTypes = {
	data: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			statusSegments: PropTypes.arrayOf(
				PropTypes.shape({
					startTime: PropTypes.number,
					endTime: PropTypes.number,
					status: PropTypes.string,
				})
			),
		})
	),
	visOptions: PropTypes.shape({
		idField: PropTypes.string,
		statusField: PropTypes.string,
		startTimeField: PropTypes.string,
		endTimeField: PropTypes.string,
	}),
	palette: PropTypes.arrayOf(PropTypes.string),
};
