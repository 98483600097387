const { shallowCopy, isDocument } = require('../utils.js');

// =============================================================================
const EXPRESSION = /** @type {const} */ ({
	LITERAL: 'literal',
	EXPRESSION_OBJECT: 'expression-object',
	SYSTEM_VARIABLE: 'system-variable',
	USER_VARIABLE: 'user-variable',
	FIELD_PATH: 'field-path',
	OPERATOR: 'operator',
});
module.exports.EXPRESSION = EXPRESSION;

// =============================================================================
const OPERATION_MODE = /** @type {const} */ ({
	// the read only query language operations
	QUERY: 'query',
	// full aggregation pipeline operations
	AGGREGATE: 'aggregate',
	// accumulator stage operations
	ACCUMULATE: 'accumulate',
});
module.exports.OPERATION_MODE = OPERATION_MODE;

// =============================================================================
const ERROR = {
	FAILED_OPERATION: (operator, reason) =>
		'FAILED OPERATION - ' + operator + ' failed to execute: ' + reason,
	INVALID_OPERATOR_ARGUMENT: (operator, expected_type, received, position) =>
		`INVALID OPERATOR ARGUMENT - ${operator.slice(1)} expected${
			Array.isArray(expected_type)
				? ' one of: ' + JSON.stringify(expected_type)
				: ': ' + expected_type
		}${
			received
				? ', but received:' +
				  ' ' +
				  typeof received +
				  ' - ' +
				  JSON.stringify(received) +
				  ''
				: ''
		}${position ? ' - at position: ' + position : ''}`,
	UNSUPPORTED_QUERY_MODE_OPERATION: (operator) =>
		'UNSUPPORTED OPERATOR - use of operator: ' +
		operator.slice(1) +
		' is not supported in query operations',
	UNSUPPORTED_OPERATOR: (operator) =>
		'UNSUPPORTED OPERATOR - use of unsupported operator: ' +
		operator.slice(1),
	UNDEFINED_VARIABLE: (variable) =>
		'UNDEFINED VARIABLE - use of undefined variable: ' + variable.slice(2),
	INVALID_NUMBER_ARGS: (operator, min, max) =>
		`INVALID NUMBER OF ARGS - ${operator.slice(1)} takes ${
			min && max === undefined
				? 'at least ' + min
				: min === undefined && max
				? 'at most '
				: min === max && min !== undefined
				? 'exactly '
				: 'no '
		}${min ?? max ?? ''} arguments`,
	INVALID_FIELD_NAME_DOT: () =>
		'INVALID FIELD NAME: field names may not contain "."',
};
module.exports.ERROR = ERROR;

// =============================================================================
/*
<expression> => {
    - field paths => "$something.something"
    - system variables => "$$SOMETHING"
    - user variables => "$$sOMETHING"  (must have lower case first letter - see $let)
	- operator expression =>  { "$operator" : [<argument1>, ...] }
            in general the arguments here would be an array, but if it only 
            accepts a single value it would be that value instead, special case
            of this being expressions that have a single object parameter
    - expression objects => { field: <expression> } || [<expression>, ...]
        for query expressions the expression can have multiple operators in one expression
        project expression object => { field: <number | boolean> } (only valid in a project)

    - literals => whatever is left
} 
*/

module.exports.getExpressionType = getExpressionType;
/**
 *
 * @param {any} unTypedExpression
 *
 * @returns { Expression }
 */
function getExpressionType(unTypedExpression) {
	if (
		typeof unTypedExpression === 'string' &&
		unTypedExpression?.[0] === '$'
	) {
		if (unTypedExpression[1] === '$') {
			if (unTypedExpression[2].toUpperCase() === unTypedExpression[2]) {
				return {
					type: EXPRESSION.SYSTEM_VARIABLE,
					variable: unTypedExpression,
				};
			}
			return {
				type: EXPRESSION.USER_VARIABLE,
				variable: unTypedExpression,
			};
		}
		return { type: EXPRESSION.FIELD_PATH, path: unTypedExpression };
	}

	if (Array.isArray(unTypedExpression)) {
		return {
			type: EXPRESSION.EXPRESSION_OBJECT,
			value: unTypedExpression,
		};
	}

	if (isDocument(unTypedExpression)) {
		for (const operator in unTypedExpression) {
			if (Object.hasOwnProperty.call(unTypedExpression, operator)) {
				if (operator[0] === '$') {
					const { [operator]: args, ...options } = unTypedExpression;

					return {
						type: EXPRESSION.OPERATOR,
						operator: operator,
						arguments: args,
						options: options,
					};
				}

				break;
			}
		}

		return {
			type: EXPRESSION.EXPRESSION_OBJECT,
			value: unTypedExpression,
		};
	}

	// otherwise this is a literal
	return { type: EXPRESSION.LITERAL, value: unTypedExpression };
}

// =============================================================================
const operators = {
	[OPERATION_MODE.AGGREGATE]: {},
	[OPERATION_MODE.QUERY]: {},
	[OPERATION_MODE.ACCUMULATE]: {},
};

// =============================================================================
module.exports.setOperator = setOperator;
/**
 * @param {string} key
 * @param {ExpressionFunction } fn
 * @param {ExpressionOperationMode[]} operatorModes
 */
function setOperator(key, fn, operatorModes) {
	(operatorModes || []).forEach((mode) => {
		if (operators[mode][key] !== undefined) {
			console.warn(
				'Overwriting ' + mode + ' operator',
				key,
				operators[mode][key],
				fn
			);
		}

		operators[mode][key] = fn;
	});
}

// =============================================================================
function evaluateExpressionOperation(context, operator, args, options) {
	const { operationMode } = context;

	if (
		!operators[operationMode][operator] &&
		operationMode === OPERATION_MODE.QUERY &&
		operators[OPERATION_MODE.AGGREGATE][operator]
	) {
		throw new Error(ERROR.UNSUPPORTED_QUERY_MODE_OPERATION(operator));
	}

	if (!operators[operationMode][operator]) {
		throw new Error(ERROR.UNSUPPORTED_OPERATOR(operator));
	}

	return operators[operationMode][operator](context, args, options);
}

// =============================================================================
function evaluateFieldPath(context, path, accumulator) {
	const { current } = context;

	const wholePath = path.length > 1 && path[0] === '$' ? path.slice(1) : path;
	const dotIndex = wholePath.indexOf('.');

	const p0 = dotIndex > -1 ? wholePath.slice(0, dotIndex) : wholePath;

	let pN = dotIndex > -1 ? wholePath.slice(dotIndex + 1) : '';
	if (dotIndex < -1) {
		if (accumulator) {
			accumulator.push(shallowCopy(current[wholePath]));
			return;
		}
		return shallowCopy(current[wholePath]);
	}

	if (isDocument(current)) {
		if (pN === '') {
			if (accumulator) {
				accumulator.push(shallowCopy(current[p0]));
				return;
			}

			return shallowCopy(current[p0]);
		}

		if (Object.hasOwnProperty.call(current, p0)) {
			return evaluateFieldPath(
				{ ...context, current: current[p0] },
				pN,
				accumulator
			);
		}

		return;
	}

	if (Array.isArray(current)) {
		if (context.operationMode === OPERATION_MODE.QUERY) {
			const arr = accumulator ?? [];

			for (let k = 0; k < current.length; k++) {
				evaluateFieldPath(
					{
						...context,
						current: current[k],
					},
					wholePath,
					arr
				);
			}

			return arr;
		} else {
			return shallowCopy(current);
		}
	}

	// default undefined
}

// =============================================================================
function evaluateSystemVariable(context, variable) {
	switch (variable) {
		case '$$ROOT':
			return shallowCopy(context.current);
		case '$$NOW':
			// TODO - this should be a value set at start of pipeline and
			//        retained throughout to match mongo implementation
			return context?.now || new Date();
		default:
			throw new Error(ERROR.UNDEFINED_VARIABLE(variable));
	}
}

// =============================================================================
function evaluateUserVariable(context, variable) {
	const vIdx =
		context.userVariables?.findIndex((v) => '$$' + v.key === variable) ??
		-1;

	if (vIdx >= 0) {
		return context.userVariables[vIdx].value;
	}

	throw new Error(ERROR.UNDEFINED_VARIABLE(variable));
}

// =============================================================================
/**
 * @param {ExpressionContext} context
 * @param {ExpressionObject['value']} value
 */
function evaluateExpressionObject(context, value) {
	// [<expression>, ...]
	if (Array.isArray(value)) {
		if (context.operationMode === OPERATION_MODE.AGGREGATE) {
			// evaluate all teh items in the array as expressions
			return value.map((v) => evaluateExpression(context, v));
		} else {
			// treat as literal
			return value;
		}
	}

	// { fieldPath: <expression> }
	if (context.operationMode === OPERATION_MODE.QUERY) {
		const checks = [];

		let regexCheck = null;
		let regexCheckOptions;

		for (const fieldPath in value) {
			if (Object.hasOwnProperty.call(value, fieldPath)) {
				// below is for query operations which create a true/false result
				// based on results of expressions on properties

				const expression = getExpressionType(value[fieldPath]);

				if (expression.type === EXPRESSION.OPERATOR) {
					/* 
						handle things like:
						{
							name: {
								$gt: "H",
								$lt: "I",
								$regex: /^HPL/,
								$options: "i",
							},
							device_type: {$ne:null}
						}
						by placing each operation in the expression into a stage
						of an $and so the result is something like:
						
						$and[
							{$gt: ["$name":"H"]},
							{$lt: ["$name":"I"]},
							{$regex: ["$name":/^HPL/], $options: 'i'},
							{$ne: ["$device_type",null]}
						}
					*/

					for (const expr in value[fieldPath]) {
						if (
							Object.hasOwnProperty.call(value[fieldPath], expr)
						) {
							const check = value[fieldPath][expr];

							if (expr === '$options') {
								// meta operator for regex
								regexCheckOptions = { [expr]: check };
								continue;
							}

							if (expr === '$regex') {
								regexCheck = {
									$regex: ['$' + fieldPath, check],
								};
								continue;
							}

							checks.push({
								[expr]: [
									'$' + fieldPath,
									...(Array.isArray(check) ? check : [check]),
								],
							});
						}
					}

					if (regexCheck) {
						if (regexCheckOptions) {
							regexCheck = {
								...regexCheck,
								...regexCheckOptions,
							};
						}

						checks.push(regexCheck);
						regexCheck = null;
						regexCheckOptions = undefined;
					}
					continue;
				}

				checks.push({
					$eq: ['$' + fieldPath, value[fieldPath]],
				});
			}
		}

		return evaluateExpressionType(context, {
			type: EXPRESSION.OPERATOR,
			operator: '$and',
			arguments: checks,
			options: {},
		});
	}

	// { field: <expression> }

	// outside of read query operations we're going to travel through
	// the expression object and evaluate each property as an expression
	// and assign its value to the property in a new object.
	const newObj = {};

	for (const field in value) {
		if (Object.hasOwnProperty.call(value, field)) {
			if (field.indexOf('.') > -1) {
				throw new Error(ERROR.INVALID_FIELD_NAME_DOT());
			}

			if (context.operationMode === OPERATION_MODE.ACCUMULATE) {
				const fieldValues = context.accumulator[field] || [];

				newObj[field] = evaluateExpression(
					{
						...context,
						fieldValues: fieldValues,
					},
					value[field]
				);

				context.accumulator[field] = fieldValues;
			} else {
				newObj[field] = evaluateExpression(context, value[field]);
			}
		}
	}

	return newObj;
}

// =============================================================================
module.exports.evaluateExpressionType = evaluateExpressionType;
/**
 * @param {ExpressionContext} context
 * @param {Expression} expression
 */
function evaluateExpressionType(context, expression) {
	switch (expression.type) {
		case EXPRESSION.FIELD_PATH:
			return evaluateFieldPath(context, expression.path);
		case EXPRESSION.SYSTEM_VARIABLE:
			return evaluateSystemVariable(context, expression.variable);
		case EXPRESSION.USER_VARIABLE:
			return evaluateUserVariable(context, expression.variable);
		case EXPRESSION.OPERATOR:
			return evaluateExpressionOperation(
				context,
				expression.operator,
				expression.arguments,
				expression.options
			);
		case EXPRESSION.LITERAL:
			return expression.value;
		case EXPRESSION.EXPRESSION_OBJECT:
			return evaluateExpressionObject(context, expression.value);
		default:
			break;
	}
}

// =============================================================================
module.exports.evaluateExpression = evaluateExpression;
/**
 * @param {ExpressionContext} context
 * @param {unknown} unTypedExpression
 */
function evaluateExpression(context, unTypedExpression) {
	const expr = getExpressionType(unTypedExpression);

	return evaluateExpressionType(context, expr);
}

// =============================================================================
module.exports.flattenExpressionForProject = flattenExpressionForProject;
function flattenExpressionForProject(input, parentPath, accumulator) {
	const output = accumulator || {};

	for (const prop in input) {
		if (Object.hasOwnProperty.call(input, prop)) {
			if (prop.indexOf('$') === 0) {
				// leave expressions as they are
				return (output[parentPath] = input);
			}

			const path = (parentPath ? parentPath + '.' : '') + prop;

			if (Array.isArray(input[prop])) {
				for (let i = 0; i < input[prop].length; i++) {
					const k = `${path}.\$.${i}`;
					const v = input[prop][i];
					if (v === null) {
						output[k] = null;
					} else if (typeof v === 'object') {
						output[k] = {};
						flattenExpressionForProject(v, path, output);
					} else {
						output[k] = v;
					}
				}
			} else if (input[prop] === null) {
				// typeof null is "object"
				output[path] = null;
			} else if (typeof input[prop] === 'object') {
				flattenExpressionForProject(input[prop], path, output);
			} else {
				output[path] = input[prop];
			}
		}
	}

	return output;
}
