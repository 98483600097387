const {
	EXPRESSION,
	getExpressionType,
} = require('../../expression/expression.js');

const { setStage, ERROR } = require('../pipeline.js');

const stageKey = '$skip';

setStage(
	stageKey,
	/**
	 * @param {Object} context
	 * @param {Object} args
	 * @param {Object} options
	 *
	 * @return {Array<Object>}
	 */
	(context, args, options) => {
		const expression = getExpressionType(args);

		if (
			expression.type !== EXPRESSION.LITERAL ||
			typeof expression.value !== 'number'
		) {
			throw new Error(ERROR.INVALID_STAGE_ARGUMENT(stageKey, 'number'));
		}

		if (
			expression.value <= 0 ||
			Math.trunc(expression.value) !== expression.value
		) {
			throw new Error(
				ERROR.INVALID_STAGE_ARGUMENT(stageKey, 'positive integer > 0')
			);
		}

		return context.collection.slice(expression.value);
	},
	{ noDataReadRequired: true }
);
