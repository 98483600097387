import {
	COMMIT_DRAFT,
	LOCK_STATUS,
	PUBLISH,
	PUBLISH_WITH_DRAFT,
	RECORD_STATUS,
	SET_CURRENT_PUBLISH,
	UPDATE_DRAFT,
	UPDATE_RECORD,
} from '../constants';
import { changeLockStatus } from '../requests/changeLockStatus';
import { changeRecordStatus } from '../requests/changeStatus';
import { handleChangeDetails } from '../requests/handleChangeDetails';
import { handleCommitDraft } from '../requests/handleCommitDraft';
import { handlePublish } from '../requests/handlePublish';
import { handlePublishWithDraft } from '../requests/handlePublishWithDraft';
import { handleSetCurrentPublish } from '../requests/handleSetCurrentPublish';
import { handleUpdateDraft } from '../requests/handleUpdateDraft';

const callbackHandler = async ({
	callback,
	status,
	apiUuidParamPayload,
	recordHistoryGet,
	moduleFullName,
	module,
	setSnackbarProps,
	setSending,
	mode,
	nextLockUser,
	payload,
}) => {
	// console.log({
	// 	callback,
	// 	status,
	// 	apiUuidParamPayload,
	// 	recordHistoryGet,
	// 	moduleFullName,
	// 	module,
	// 	setSnackbarProps,
	// 	setSending,
	// 	mode,
	// 	nextLockUser,
	// });
	setSending(true);
	let result;
	switch (callback) {
		case RECORD_STATUS:
			result = await changeRecordStatus({
				status,
				apiUuidParamPayload,
				recordHistoryGet,
				moduleFullName,
				module,
			});
			setSnackbarProps({ message: result.text, variant: result.variant });
			break;
		case LOCK_STATUS:
			result = await changeLockStatus({
				recordHistoryGet,
				moduleFullName,
				mode,
				apiUuidParamPayload,
				nextLockUser,
				module,
			});
			setSnackbarProps({ message: result.text, variant: result.variant });
			break;
		case PUBLISH:
			result = await handlePublish({
				apiUuidParamPayload,
				moduleFullName,
				module,
				recordHistoryGet,
			});
			setSnackbarProps({ message: result.text, variant: result.variant });
			break;
		case UPDATE_RECORD:
			result = await handleChangeDetails({
				apiUuidParamPayload,
				moduleFullName,
				module,
				recordHistoryGet,
				payload,
			});
			setSnackbarProps({ message: result.text, variant: result.variant });
			break;
		// Commits the draft to the versions array
		case COMMIT_DRAFT:
			result = await handleCommitDraft({
				apiUuidParamPayload,
				moduleFullName,
				module,
				recordHistoryGet,
				payload,
			});
			setSnackbarProps({ message: result.text, variant: result.variant });
			break;
		// Sets the current_version and publishes the record
		case SET_CURRENT_PUBLISH:
			result = await handleSetCurrentPublish({
				apiUuidParamPayload,
				moduleFullName,
				module,
				recordHistoryGet,
				payload,
			});
			setSnackbarProps({ message: result.text, variant: result.variant });
			break;
		// Commits the draft, sets the current_version and publishes the record
		case PUBLISH_WITH_DRAFT:
			result = await handlePublishWithDraft({
				apiUuidParamPayload,
				moduleFullName,
				module,
				recordHistoryGet,
			});
			setSnackbarProps({ message: result.text, variant: result.variant });
			break;
		// Update the draft, supports passing of partial properties
		case UPDATE_DRAFT:
			result = await handleUpdateDraft({
				apiUuidParamPayload,
				moduleFullName,
				module,
				recordHistoryGet,
				payload,
			});
			setSnackbarProps({ message: result.text, variant: result.variant });
			break;
		default:
			break;
	}
};

export { callbackHandler };
