import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';

import { Button, Typography } from '@mui/material';
import { format, sub } from 'date-fns';
import produce from 'immer';
import RemoveIcon from '@mui/icons-material/PersonRemove';
import LockResetIcon from '@mui/icons-material/LockReset';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import admin_nav_item from '../AdminNavItem';
import admin_options from '../AdminOptions';
import {
	a11yProps,
	BtTab,
	BtTabBar,
	BtTabPanel,
	BtTabView,
} from '../../../components/generic/BtTabView';
import BtError from '../../../components/generic/BtError';
import {
	BtFormContainer,
	BtFormLateralContainer,
} from '../../../components/generic/forms';
import BtListComposer from '../../../components/generic/BtListComposer';
import BtLoading from '../../../components/generic/BtLoading';
import BtTags from '../../../components/generic/BtTags';
import {
	roleAddUser,
	roleDeleteUser,
	roleGetList,
	subscriptionsGetList,
	userAdminPasswordReset,
	userAdminDelete,
	subscriptionAddUser,
	subscriptionRemoveUser,
} from '../../../API';
import useFetch from '../../../hooks/useFetch';
import { useNavContext } from '../../../context/ContextManager';
import { userAdminGetUser } from '../../../API/user_admin';
import BtDetailSection from '../../../components/generic/BtDetailSection';
import BtConfirmDialog from '../../../components/generic/BtConfirmDialog';
import { useAppContext } from '../../../context/ContextManager';
import { useLocation } from 'react-router-dom';
import BtMessage from '../../../components/generic/BtMessage';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const latContainerStyle = { marginBottom: '1em' };

export default function UsersEditor({ match }) {
	const rolesRequested = useRef();
	const subscriptionsRequested = useRef();
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();

	const location = useLocation();
	const locationState = location.state;

	const { appOrg, setActivityIndicator } = useAppContext();

	const resourceGroupId = 'users';

	const [currentTab, setCurrentTab] = useState(0);
	const [deleteDialog, setDeleteDialog] = useState(false);
	const [roles, setRoles] = useState();
	const [subscriptions, setSubscriptions] = useState();
	const [user, setUser] = useState();
	const [removing, setRemoving] = useState(false);

	const [newUser, setNewUser] = useState(false);
	const [tempPassword, setTempPassword] = useState(null);
	const [showTempPassword, setShowTempPassword] = useState(false);

	const userId = useMemo(() => match.params.id, [match]);

	const {
		loading: loadingRoles,
		error: rolesError,
		request: getRoles,
	} = useFetch(roleGetList, rolesResp =>
		setRoles(rolesResp.map(({ uuid, name }) => ({ uuid, name })))
	);

	const {
		loading: loadingUser,
		error: userError,
		request: getUser,
	} = useFetch(userAdminGetUser, setUser);

	const {
		loading: loadingSubscriptions,
		error: subscriptionsError,
		request: getSubscriptions,
	} = useFetch(subscriptionsGetList, subscriptionsResp => {
		setSubscriptions(
			subscriptionsResp
				.filter(subscription => {
					return (
						subscription.assigned < subscription.maxUsers ||
						subscription.users.includes(userId)
					);
				})
				.map(({ uuid, subscription }) => ({ uuid, subscription }))
		);
	});

	// Set contextual navigation items and breadcrumbs
	const { setContextualNav, setBreadcrumbs } = useNavContext();
	useEffect(
		() => {
			setContextualNav([...admin_nav_item, ...admin_options]);
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'Admin', link: '/Admin' },
				{ text: 'Users', link: '/Users' },
				{ text: 'Edit User', link: '' },
			]);
			return () => setContextualNav(null);
		},
		[setContextualNav, setBreadcrumbs]
	);

	useEffect(
		() => {
			if (!roles && !rolesRequested.current) {
				getRoles();
				rolesRequested.current = true;
			}
		},
		[getRoles, roles]
	);

	useEffect(
		() => {
			if (!subscriptions && !subscriptionsRequested.current) {
				getSubscriptions();
				subscriptionsRequested.current = true;
			}
		},
		[getSubscriptions, subscriptions]
	);

	useEffect(
		() => {
			getUser({ userUuid: userId });
		},
		[getUser, userId]
	);

	useEffect(() => setActivityIndicator(removing), [
		setActivityIndicator,
		removing,
	]);

	const loading = useMemo(
		() => loadingRoles || loadingUser || loadingSubscriptions,
		[loadingRoles, loadingUser, loadingSubscriptions]
	);

	const handleRolesUpdate = useCallback(
		(role, updatedRoles) =>
			setUser(
				produce(draft => {
					draft.roles = [...updatedRoles];
				})
			),
		[]
	);

	const handleSubscriptionsUpdate = useCallback(
		(subscription, updatedSubscriptions) =>
			setUser(
				produce(draft => {
					draft.subscriptions = [...updatedSubscriptions];
				})
			),
		[]
	);

	const handleTagsChange = useCallback(newTags => {
		setUser(
			produce(draft => {
				draft.tags = newTags;
			})
		);
	}, []);

	useEffect(
		() => {
			if (locationState?.params) {
				if (locationState.params.newUser) {
					setNewUser(true);
					setTempPassword(locationState.params.tempPassword);
				}
			}
		},
		[locationState]
	);

	const handleCopyPassword = () => {
		navigator.clipboard.writeText(tempPassword);
		enqueueSnackbar('Password copied to clipboard', { variant: 'success' });
	};

	const handlePasswordReset = async () => {
		try {
			await userAdminPasswordReset({
				userUuid: userId,
			});

			enqueueSnackbar('User password reset', { variant: 'success' });
		} catch (error) {
			enqueueSnackbar('There was an error reseting the user password', {
				variant: 'error',
			});
		}
	};

	const handleRemoveUser = async () => {
		setRemoving(true);
		try {
			await userAdminDelete({
				userUuid: userId,
			});

			enqueueSnackbar('User removed', { variant: 'success' });
			history.push('/Users');
		} catch (error) {
			enqueueSnackbar('There was an error removing the user', {
				variant: 'error',
			});
		}
		setRemoving(false);
	};

	if (userError) {
		return (
			<BtError
				title="Retrieval Error"
				description="An error occurred when attempting to retrieve the user's information"
			/>
		);
	}

	return (
		<>
			<BtTabView>
				<BtFormContainer
					title={user?.user_name}
					style={{ maxWidth: 680 }}
					header={
						<BtLoading
							loading={loading || removing}
							LoadingComponent={<div style={{ height: 50 }} />}
						>
							<BtTabBar
								currentTab={currentTab}
								onTabChange={(event, selectedTab) =>
									setCurrentTab(selectedTab)
								}
								style={{
									transform: 'translateY(-1px)',
									marginTop: 10,
								}}
							>
								<BtTab label="Details" {...a11yProps(0)} />
								<BtTab
									label={`Roles (${user?.roles?.length})`}
									{...a11yProps(1)}
								/>
								<BtTab
									label={`Subscriptions (${
										user?.subscriptions?.length
									})`}
									{...a11yProps(2)}
								/>
							</BtTabBar>
						</BtLoading>
					}
				>
					<BtLoading loading={loading || removing}>
						<BtTabPanel currentTab={currentTab} index={0}>
							<BtFormLateralContainer style={latContainerStyle}>
								<BtDetailSection
									label="First Name"
									data={user?.first_name}
								/>
								<BtDetailSection
									label="Last Name"
									data={user?.last_name}
								/>
							</BtFormLateralContainer>
							<BtFormLateralContainer style={latContainerStyle}>
								<BtDetailSection
									label="User Name"
									data={user?.user_name}
								/>
								<BtDetailSection
									label="Initials"
									data={user?.initials}
								/>
							</BtFormLateralContainer>
							<BtFormLateralContainer style={latContainerStyle}>
								<BtDetailSection
									label="Email Address"
									data={user?.email}
								/>
								<BtDetailSection
									label="Last Logged In"
									data={format(
										user?.last_login_timestamp || 0,
										'dd MMM yyyy, hh:mm a'
									)}
								/>
							</BtFormLateralContainer>
							<BtDetailSection
								label="Tags"
								Component={
									<BtTags
										resourceId={user?.uuid}
										resourceGroupId={resourceGroupId}
										onTagsChange={handleTagsChange}
										initialTags={user?.tags}
									/>
								}
							/>
							<Button
								color="error"
								onClick={() => setDeleteDialog(true)}
								startIcon={<RemoveIcon />}
								style={{ marginTop: '1.5em' }}
								variant="outlined"
								disableElevation
							>
								Remove User
							</Button>
							<Button
								color="error"
								onClick={handlePasswordReset}
								startIcon={<LockResetIcon />}
								style={{
									marginTop: '1.5em',
									marginLeft: '1.5em',
								}}
								variant="outlined"
								disableElevation
							>
								Password Reset
							</Button>
							{newUser && (
								<BtMessage
									variant="success"
									message="The user has been added to the organisation. An e-mail inviting to user has been sent."
									style={{ marginTop: '1.5em' }}
								/>
							)}

							{tempPassword && (
								<BtMessage
									variant="info"
									message={
										<>
											<Typography variant="h6">
												Temporary Password
											</Typography>
											<Typography>
												A temporary, one-time password
												has been set for the user.
											</Typography>
											{!showTempPassword && (
												<Button
													variant="outlined"
													onClick={() => {
														setShowTempPassword(
															true
														);
													}}
												>
													Show Password
												</Button>
											)}
											{showTempPassword && (
												<>
													<Button
														variant="outlined"
														onClick={() => {
															setShowTempPassword(
																false
															);
														}}
													>
														Hide Password
													</Button>

													<Typography>
														{`Tempory Password: ${tempPassword}`}
													</Typography>
													<Button
														variant="outlined"
														onClick={
															handleCopyPassword
														}
														startIcon={
															<ContentCopyIcon />
														}
													>
														Copy To Clipboard
													</Button>
												</>
											)}
										</>
									}
									style={{ marginTop: '1.5em' }}
								/>
							)}
						</BtTabPanel>

						<BtTabPanel currentTab={currentTab} index={1}>
							{!rolesError ? (
								<BtListComposer
									allItems={roles}
									confirmDeletion
									defaultItems={user?.roles}
									deleteable
									deleteVerb="Remove"
									primaryField="name"
									subject="roles"
									onItemAdd={role =>
										roleAddUser({
											roleUuid: role.uuid,
											userUuid: userId,
										})
									}
									onItemAddSuccess={handleRolesUpdate}
									onItemDelete={role =>
										roleDeleteUser({
											roleUuid: role.uuid,
											userUuid: userId,
										})
									}
									onItemDeleteSuccess={handleRolesUpdate}
								/>
							) : (
								<BtError
									title="Retrieval Error"
									description="An error occurred when attempting to retrieve the roles for this organisation."
								/>
							)}
						</BtTabPanel>

						<BtTabPanel currentTab={currentTab} index={2}>
							{!subscriptionsError ? (
								<BtListComposer
									allItems={subscriptions}
									confirmDeletion
									defaultItems={user?.subscriptions}
									deleteable
									deleteVerb="Remove"
									primaryField="subscription"
									subject="subscriptions"
									onItemAdd={subscription => {
										subscriptionAddUser({
											subUuid: subscription.uuid,
											userUuid: userId,
										});
									}}
									onItemAddSuccess={handleSubscriptionsUpdate}
									onItemDelete={subscription => {
										subscriptionRemoveUser({
											subUuid: subscription.uuid,
											userUuid: userId,
										});
									}}
									onItemDeleteSuccess={
										handleSubscriptionsUpdate
									}
								/>
							) : (
								<BtError
									title="Retrieval Error"
									description="An error occurred when attempting to retrieve the subscriptions for this organisation."
								/>
							)}
						</BtTabPanel>
					</BtLoading>
				</BtFormContainer>
			</BtTabView>
			<BtConfirmDialog
				action={() => {
					handleRemoveUser();
				}}
				isDestructive
				open={deleteDialog}
				onClose={() => setDeleteDialog(false)}
				title="Remove User?"
				prompt={
					`Are you sure you want to remove ${user?.first_name} ${
						user?.last_name
					}` +
					`${
						appOrg ? ' from ' + appOrg.name : ''
					}? You will need to re-invite them should they ` +
					'require access in future.'
				}
				verb="Remove User"
				ActionIcon={<RemoveIcon />}
			/>
		</>
	);
}

UsersEditor.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
};
