import React, { Children, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import useAvailableToUser from '../hooks/useAvailableToUser';

export default function BtRedactor({
	component: Component,
	render,
	requiredPermissionsAll,
	requiredPermissionsAny,
	validSubscriptionsAny,
	children,
}) {
	const checkAvailability = useAvailableToUser();
	const availability = useMemo(
		() =>
			checkAvailability({
				requiredPermissionsAll,
				requiredPermissionsAny,
				validSubscriptionsAny,
			}),
		[
			checkAvailability,
			requiredPermissionsAll,
			requiredPermissionsAny,
			validSubscriptionsAny,
		]
	);

	const hasChildren = useMemo(() => Children.toArray(children).length > 0, [
		children,
	]);

	// console.log(children);

	const determineRender = useCallback(
		props => {
			if (
				!availability.available ||
				(!Component && !render && !hasChildren)
			) {
				return null;
			}

			if (hasChildren) {
				return <>{children}</>;
			} else if (Component) {
				return <Component {...props} />;
			}
			return render;
		},
		[availability, children, Component, hasChildren, render]
	);

	return determineRender();
}

BtRedactor.propTypes = {
	component: PropTypes.elementType,
	render: PropTypes.node,
	requiredPermissionsAll: PropTypes.object,
	requiredPermissionsAny: PropTypes.object,
	validSubscriptionsAny: PropTypes.arrayOf(PropTypes.string),
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};
