import { Box, Paper, Typography } from '@mui/material';
import { CircleMedium } from 'mdi-material-ui';
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
	formatTimestamp,
	valueFormatter,
} from '../../../../../components/charts/Utils';

const CustomTooltip = memo(function CustomTooltip({
	active,
	payload,
	label,
	visOptions,
	timestampFormat,
	sortValues,
	data,
	props,
}) {
	// console.log({
	// 	active,
	// 	payload,
	// 	label,
	// 	visOptions,
	// 	timestampFormat,
	// 	sortValues,
	// 	data,
	// 	props,
	// });
	const title = useMemo(
		() => {
			if (typeof label === 'string') {
				return label;
			}
			if (typeof label === 'number') {
				const formattedTimestamp = formatTimestamp(
					label,
					timestampFormat
				);
				if (formattedTimestamp === 'Invalid date') {
					return null;
				} else {
					return formattedTimestamp;
				}
			}
			if (payload.label) {
				return payload.label;
			}
			return null;
		},
		[label, payload, timestampFormat]
	);

	// sort the values for user friendly display, if required
	const sortedPayload = useMemo(
		() => {
			// if (payload.data && payload.data.length > 0) {
			// 	return sortValues
			// 		? payload.data.sort((a, b) => b.value - a.value)
			// 		: payload.data;
			// }
			if (data) {
				return sortValues
					? data.toSorted((a, b) => {
							if (a.percentageValue === undefined) {
								return 1;
							}
							if (b.percentageValue === undefined) {
								return -1;
							}
							return (
								Number(b.percentageValue) -
								Number(a.percentageValue)
							);
					  })
					: data;
			} else {
				return sortValues
					? payload.toSorted((a, b) => b.value - a.value)
					: payload;
			}
		},
		[data, payload, sortValues]
	);

	// console.log({ sortedPayload });

	if (active && sortedPayload.length) {
		return (
			<Paper
				sx={{
					padding: '1rem',
					width: 'max-content',
				}}
				elevation={3}
			>
				{title && (
					<Typography
						fontWeight={500}
						variant="body2"
						sx={{
							marginLeft: '0.5rem',
						}}
					>
						{title}
					</Typography>
				)}
				{sortedPayload.map(
					(item, index) =>
						item && (
							<Box
								key={`item-${index}`}
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									gap: '1rem',
								}}
							>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									{item?.color && (
										<CircleMedium
											style={{ color: item?.color }}
										/>
									)}
									{item && (
										<Typography variant="body2">
											{`${
												valueFormatter(
													item.value,
													item.name,
													visOptions
												)[1]
											} : `}
										</Typography>
									)}
								</Box>
								<Typography variant="body2">
									{data
										? item.valueString
										: valueFormatter(
												item.value,
												item.name,
												visOptions
										  )[0]}
								</Typography>
							</Box>
						)
				)}
			</Paper>
		);
	}

	return null;
});

export { CustomTooltip };

CustomTooltip.defaultProps = {
	sortValues: false,
};

CustomTooltip.propTypes = {
	payload: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	visOptionParams: PropTypes.arrayOf(PropTypes.object),
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	visOptions: PropTypes.object,
	timestampFormat: PropTypes.string,
	sortValues: PropTypes.bool,
};
