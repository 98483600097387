import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import Cookies from 'universal-cookie';
import { useHistory, useLocation } from 'react-router-dom';

// COMPONENTS
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import OrgSelectDialog from '../components/dialogs/OrgSelectDialog';

// ICONS
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ConstructionIcon from '@mui/icons-material/Construction';
import HomeIcon from '@mui/icons-material/Home';
import HubIcon from '@mui/icons-material/Hub';
import InsightsIcon from '@mui/icons-material/Insights';
import LogoDevIcon from '@mui/icons-material/LogoDev';
import RouterIcon from '@mui/icons-material/Router';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';

import BtAppBar from './BtAppBar';
import BtNavigation from './BtNavigation';
import BtResponsiveDrawer from './BtResponsiveDrawer';
import { useAppContext, useNavContext } from '../context/ContextManager';
import useNavRedactor from '../hooks/useNavRedactor';
import withMiniDrawerStyles from '../style/MiniDrawer.style';

const defaultNavItems = [
	{ id: 'Home', text: 'Home', route: '/', icon: <HomeIcon />, active: true },
	{
		id: 'Insights',
		text: 'Insights',
		route: '/Insights',
		icon: <InsightsIcon />,
		validSubscriptionsAny: ['Insights Viewer'],
	},
	{
		id: 'Data Manager',
		text: 'Data Manager',
		route: '/DataManager',
		icon: <HubIcon />,
		requiredPermissionsAll: {
			dataManager: ['DatasetView'],
		},
		validSubscriptionsAny: ['Data Manager'],
	},
	{
		id: 'IoT',
		text: 'IoT',
		route: '/IoT',
		icon: <RouterIcon />,
		requiredPermissionsAll: {
			devices: ['DeviceList'],
		},
		validSubscriptionsAny: ['IoT'],
	},
	{
		id: 'Workflows',
		text: 'Workflows',
		route: '/Workflows',
		validSubscriptionsAny: ['Workflow User'],
		icon: <ViewTimelineIcon />,
	},
	{
		id: 'Admin',
		text: 'Admin',
		route: '/Admin',
		icon: <AdminPanelSettingsIcon />,
		requiredPermissionsAny: {
			orgAdmin: [
				'OrgDetailsEdit',
				'SubscriptionList',
				'RolesList',
				'UserList',
				'TagGroupEdit',
			],
			screens: ['ScreenAdminView'],
		},
	},
];

export function getDefaultNavItems() {
	if (process.env.NODE_ENV !== 'development') {
		return defaultNavItems;
	} else {
		return [
			...defaultNavItems,
			{
				id: 'Developer',
				text: 'Developer',
				route: '/Developer',
				icon: <LogoDevIcon />,
			},
			{
				id: 'BeaconAdmin',
				text: 'Beacon Admin',
				route: '/BeaconAdmin',
				validSubscriptionsAny: ['Platform Admin'],
				icon: <ConstructionIcon />,
			},
		];
	}
}

const cookies = new Cookies();

function Layout({ classes, children }) {
	const {
		drawerOpen,
		setCurrentOrgUuid,
		setDrawerOpen,
		setSwipeDrawerOpen,
		swipeDrawerOpen,
	} = useAppContext();
	const history = useHistory();
	const location = useLocation();
	const [orgSelectOpen, setOrgSelectOpen] = useState(false);

	const redactNav = useNavRedactor();

	// contextual navigation items
	const { contextualNav } = useNavContext();

	const navItems = useMemo(
		() => redactNav(contextualNav ? contextualNav : getDefaultNavItems()),
		[contextualNav, redactNav]
	);

	const navClick = link => () => {
		history.push(link);

		if (swipeDrawerOpen) {
			setSwipeDrawerOpen(false);
		}
	};

	setActiveFromRoute(location.pathname);

	function setActiveFromRoute(route) {
		const slashCount = str => str.split('/').length - 1;

		const normalisedRoute = route.replace(/\/$/, '');

		let activeEl;

		for (const navItem of navItems || []) {
			const { route: itemRoute } = navItem;

			navItem.active = false;

			if (itemRoute === '/' && normalisedRoute === '') {
				activeEl = navItem;

				break;
			}

			if (
				normalisedRoute.startsWith(itemRoute) &&
				slashCount(itemRoute) > slashCount(activeEl?.route || '')
			) {
				activeEl = navItem;
			}
		}

		if (activeEl !== undefined) {
			activeEl.active = true;
		}
	}

	function onOrgSelectClose(orgUuid) {
		setOrgSelectOpen(false);
		if (orgUuid) {
			setCurrentOrgUuid(orgUuid);
		}
	}

	useEffect(
		() => {
			// Get the last state of the drawerOpen and restore it
			let cookieDrawerOpen = cookies.get('drawer_open');
			if (cookieDrawerOpen === undefined) {
				// undefined means we could not get the cookie, set the default to open for a new user
				setDrawerOpen(true);
			} else {
				// The cookie value is stored as a string
				if (cookieDrawerOpen === 'false') {
					setDrawerOpen(false);
				} else {
					setDrawerOpen(true);
				}
			}
		},
		[setDrawerOpen]
	);

	const { showMobile } = useAppContext();

	useEffect(
		() => {
			if (swipeDrawerOpen && !showMobile) {
				setSwipeDrawerOpen(false);
			}
		},
		[setDrawerOpen, setSwipeDrawerOpen, showMobile, swipeDrawerOpen]
	);

	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<BtResponsiveDrawer
				drawerOpen={drawerOpen}
				setSwipeDrawerOpen={setSwipeDrawerOpen}
				swipeDrawerOpen={swipeDrawerOpen}
			>
				<BtNavigation
					classes={classes}
					contextualNav={contextualNav}
					drawerOpen={drawerOpen}
					navClick={navClick}
					navItems={navItems}
					setOrgSelectOpen={setOrgSelectOpen}
				/>
			</BtResponsiveDrawer>
			<BtAppBar
				drawerOpen={drawerOpen}
				setDrawerOpen={setDrawerOpen}
				setOrgSelectDialog={setOrgSelectOpen}
				setSwipeDrawerOpen={setSwipeDrawerOpen}
				swipeDrawerOpen={swipeDrawerOpen}
			/>
			<OrgSelectDialog
				onClose={onOrgSelectClose}
				setOpen={orgSelectOpen}
			/>
			<Box
				component="main"
				style={{
					boxSizing: 'border-box',
					marginTop: 64,
					minHeight: 'calc(100vh - 64px)',
					padding: showMobile
						? /\/InsightCollection\//.test(location.pathname) ||
						  /\//g.test(location.pathname)
							? 0
							: '1.5em 1em'
						: /\/InsightCollection\//.test(location.pathname) ||
						  /\//g.test(location.pathname)
							? '0em 0em 0em 0em'
							: '1.5em 3em 3em 3em',
					width: '100vw',
				}}
			>
				{/* main content */}
				{children}
			</Box>
		</Box>
	);
}

Layout.propTypes = {
	classes: PropTypes.object,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

export default withMiniDrawerStyles(Layout);
