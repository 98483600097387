import { apiClient } from './api_client';
import { uuidRegex } from './utils';

//import { apiManagerGetListSchema } from './validations/apiManagerValidation';
import {
	apiManagerGetListSchema,
	apiManagerGetSchema,
	apiManagerGenKeySchema,
	apiManagerAddSchema,
	apiManagerUpdateSchema,
	apiManagerAddResponseSchema,
	apiManagerAddPermissionSchema,
	apiManagerAddPermissionResponseSchema,
	apiManagerEditPermissionSchema,
	apiManagerIpFilterPostSchema,
	apiManagerAddIpFilterSchema,
} from './validations/apiManagerValidation';

import { permissionsGetListSchema } from './validations/permissionsValidation';

//========================================================================================================
export async function apiManagerGetList() {
	const req_url = 'api_manager';
	try {
		const response = await apiClient({ method: 'get', url: req_url });
		console.log(response);
		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				return await apiManagerGetListSchema.validate(response.apis);
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
export async function apiManagerGet({ apiKeyUuid }) {
	const req_url = `api_manager/${apiKeyUuid}`;
	try {
		const response = await apiClient({ method: 'get', url: req_url });
		console.log(response);
		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				return await apiManagerGetSchema.validate(response.api);
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
export async function apiManagerAdd({ newApiKey }) {
	// Validate the orgDetails
	try {
		await apiManagerAddSchema.validate(newApiKey);
	} catch (error) {
		throw error;
	}

	const req_url = 'api_manager';
	try {
		const response = await apiClient({
			method: 'post',
			url: req_url,
			body: newApiKey,
		});

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				await apiManagerAddResponseSchema.validate(response.api);
				return response.api;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw error;
	}
}

//========================================================================================================
export async function apiManagerUpdate({ apiManagerUuid, apiKeyUpdate }) {
	if (!uuidRegex.test(apiManagerUuid)) {
		throw new Error('Param Error');
	}

	// Validate the orgDetails
	try {
		// The role update has the same schema are the add
		await apiManagerUpdateSchema.validate(apiKeyUpdate);
	} catch (error) {
		throw error;
	}

	const req_url = 'api_manager/' + apiManagerUuid;
	try {
		const response = await apiClient({
			method: 'put',
			url: req_url,
			body: apiKeyUpdate,
		});

		if (response) {
			if (response.result === 'OK') {
				return;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw error;
	}
}

//========================================================================================================
export async function apiManagerDelete({ apiKeyUuid }) {
	if (!uuidRegex.test(apiKeyUuid)) {
		throw new Error('Invalid API Key UUID');
	}

	const req_url = `api_manager/${apiKeyUuid}`;
	try {
		const response = await apiClient({
			method: 'delete',
			url: req_url,
		});

		if (response) {
			if (response.result === 'OK') {
				return;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw error;
	}
}

//========================================================================================================
export async function apiManagerGenKey({ apiKeyUuid }) {
	if (!uuidRegex.test(apiKeyUuid)) {
		throw new Error('Invalid API Key UUID');
	}

	const req_url = `api_manager/key/${apiKeyUuid}`;
	try {
		const response = await apiClient({
			method: 'put',
			url: req_url,
		});
		console.log(response);
		if (response) {
			if (response.result === 'OK') {
				return await apiManagerGenKeySchema.validate(response.api_key);
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw error;
	}
}

//========================================================================================================
export async function apiManagerAddPermission({ ownerUuid, newPermission }) {
	if (!uuidRegex.test(ownerUuid)) {
		throw new Error('Invalid API Key UUID');
	}

	// Validate the permission
	try {
		// The API key update has the same schema are the add
		await apiManagerAddPermissionSchema.validate(newPermission);
	} catch (error) {
		throw error;
	}

	const req_url = `api_manager/${ownerUuid}/permission`;
	try {
		const response = await apiClient({
			method: 'post',
			url: req_url,
			body: newPermission,
		});

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				await apiManagerAddPermissionResponseSchema.validate(
					response.permission
				);
				return response.permission;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw error;
	}
}

//========================================================================================================
export async function apiManagerEditPermission({
	ownerUuid,
	permissionUuid,
	newPermission,
}) {
	if (!uuidRegex.test(ownerUuid)) {
		console.log(ownerUuid);
		throw new Error('Invalid API Key UUID');
	}

	if (!uuidRegex.test(permissionUuid)) {
		throw new Error('Invalid permission UUID');
	}

	// Validate the permission
	try {
		// The role update has the same schema are the add
		await apiManagerEditPermissionSchema.validate(newPermission);
	} catch (error) {
		throw error;
	}

	const req_url = `api_manager/${ownerUuid}/permission/${permissionUuid}`;
	try {
		const response = await apiClient({
			method: 'put',
			url: req_url,
			body: newPermission,
		});

		if (response) {
			if (response.result === 'OK') {
				return;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw error;
	}
}

//========================================================================================================
export async function apiManagerDeletePermission({
	apiKeyUuid,
	permissionUuid,
}) {
	if (!uuidRegex.test(apiKeyUuid)) {
		throw new Error('Invalid API Key UUID');
	}

	if (!uuidRegex.test(permissionUuid)) {
		throw new Error('Invalid permission UUID');
	}

	const req_url = `api_manager/${apiKeyUuid}/permission/${permissionUuid}`;
	try {
		const response = await apiClient({
			method: 'delete',
			url: req_url,
		});

		if (response) {
			if (response.result === 'OK') {
				return;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw error;
	}
}

//========================================================================================================
export async function apiManagerPermissionsGetList() {
	const req_url = 'api_manager/permissions';
	try {
		const response = await apiClient({ method: 'get', url: req_url });
		console.log(response);
		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				await permissionsGetListSchema.validate(response.roles);
				return response.permissions;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
export async function apiManagerAddIpFilter({ apiKeyUuid, newIpFilter }) {
	if (!uuidRegex.test(apiKeyUuid)) {
		throw new Error('Invalid API Key UUID: ' + apiKeyUuid);
	}
	console.log(newIpFilter);
	// Validate the IP filter
	try {
		// The API key update has the same schema are the add
		await apiManagerIpFilterPostSchema.validate(newIpFilter);
	} catch (error) {
		console.log(error);
		throw error;
	}

	const req_url = `api_manager/${apiKeyUuid}/ip_filter`;
	try {
		const response = await apiClient({
			method: 'post',
			url: req_url,
			body: newIpFilter,
		});
		console.log(response);
		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				await apiManagerAddIpFilterSchema.validate(response.ip_filter);
				return response.ip_filter;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw error;
	}
}

//========================================================================================================
export async function apiManagerDeleteIpFilter({ apiKeyUuid, ipFilterUuid }) {
	if (!uuidRegex.test(apiKeyUuid)) {
		throw new Error('Invalid API Key UUID');
	}

	if (!uuidRegex.test(ipFilterUuid)) {
		throw new Error('Invalid Ip filter UUID: ' + ipFilterUuid);
	}

	const req_url = `api_manager/${apiKeyUuid}/ip_filter/${ipFilterUuid}`;
	try {
		const response = await apiClient({
			method: 'delete',
			url: req_url,
		});

		if (response) {
			if (response.result === 'OK') {
				return;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw error;
	}
}
