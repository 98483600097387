import React from 'react';
import PropTypes from 'prop-types';

import { ButtonBase } from '@mui/material';

import { styled } from '@mui/styles';

const TimeButton = styled(ButtonBase)(({ theme, selected, size }) => ({
	alignItems: 'center',
	display: 'inline-flex',
	justifyContent: 'center',
	position: 'relative',
	boxSizing: 'border-box',
	outline: '0',
	border: '0',
	cursor: 'pointer',
	verticalAlign: 'middle',
	textDecoration: 'none',
	fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
	fontWeight: selected ? '500' : '400',
	fontSize: '10.542px',
	lineHeight: '1.66',
	letterSpacing: '0.03333em',
	width: size === 'xs' ? '20px' : '36px',
	height: size === 'xs' ? '20px' : '26px',
	borderRadius: size === 'xs' ? '12px' : '8px',
	padding: '0',
	backgroundColor: selected ? theme.palette.primary.main : 'transparent',
	color: selected ? 'white' : theme.text,
	margin: '0px 6px',
	marginRight: '6px',
	marginLeft: '0px',
	'&:hover': {
		backgroundColor: selected
			? theme.palette.primary.dark
			: theme.palette.mode === 'dark'
				? theme.palette.primary.dark
				: theme.palette.action.hover,
	},
}));

const TimeButtonCell = styled('div')(({ theme }) => ({
	display: 'block',
}));

export const BtTimePickerButton = ({
	label,
	onClick,
	onMouseOver,
	selected,
	size,
	value,
}) => {
	return (
		<TimeButtonCell role="cell">
			<TimeButton
				size={size}
				value={value}
				onMouseOver={onMouseOver}
				onClick={onClick}
				selected={selected}
			>
				{label}
			</TimeButton>
		</TimeButtonCell>
	);
};

BtTimePickerButton.propTypes = {
	label: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	onMouseOver: PropTypes.func,
	selected: PropTypes.bool,
	size: PropTypes.string,
	value: PropTypes.number,
};
