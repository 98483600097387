import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/AddCircle';

import admin_nav_item from '../AdminNavItem';
import admin_options from '../AdminOptions';
import BtError from '../../../components/generic/BtError';
import { BtFormContainer } from '../../../components/generic/forms';
import BtLoading from '../../../components/generic/BtLoading';
import BtSelectionTable from '../../../components/generic/BtSelectionTable';
import BtTableSkeleton from '../../../components/generic/BtTableSkeleton';
import NewRoleDialog from './NewRoleDialog';
import { roleGetList } from '../../../API/role.api';
import useFetch from '../../../hooks/useFetch';
import { useNavContext } from '../../../context/ContextManager';

const tableColumns = [
	{ field: 'name', text: 'Role' },
	{ field: 'description', text: 'Description', minBreakpoint: 'lg' },
	{ field: 'userCount', text: 'Assigned Users' },
	{ field: 'managedBy', text: 'Managed By', minBreakpoint: 'md' },
];

export default function Roles() {
	const [roles, setRoles] = useState(null);

	const { loading, error, request: getRoles } = useFetch(
		roleGetList,
		setRoles
	);

	const [newRoleDialog, setNewRoleDialog] = useState(false);

	// Set contextual navigation items and breadcrumbs
	const { setContextualNav, setBreadcrumbs } = useNavContext();
	useEffect(
		() => {
			setContextualNav([...admin_nav_item, ...admin_options]);
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'Admin', link: '/Admin' },
				{ text: 'Roles', link: '' },
			]);
			return () => setContextualNav(null);
		},
		[setContextualNav, setBreadcrumbs]
	);

	// Get roles
	useEffect(
		() => {
			getRoles();
		},
		[getRoles]
	);

	if (error) {
		return (
			<BtError
				title="An Error Occurred"
				description="An error occurred whilst trying to retrieve the roles for the organisation."
				action={getRoles}
			/>
		);
	}

	return (
		<>
			<BtFormContainer title="Roles" maxWidth="lg">
				<BtLoading
					loading={loading}
					LoadingComponent={<BtTableSkeleton hasActionButton />}
				>
					<BtSelectionTable
						title={
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									flexWrap: 'wrap',
									gap: 10,
								}}
							>
								<Typography
									sx={{
										fontWeight: 'bold',
										padding: '4px 0',
									}}
									variant="h5"
								>
									Your organisation's roles
								</Typography>
								<Button
									variant="contained"
									onClick={() => setNewRoleDialog(true)}
									startIcon={<AddIcon />}
									disableElevation
								>
									Create Role
								</Button>
							</div>
						}
						subject="roles"
						columns={tableColumns}
						data={(roles || []).map(role => ({
							...role,
							route: `/Roles/Edit/${role.uuid}`,
						}))}
					/>
				</BtLoading>
			</BtFormContainer>
			<NewRoleDialog
				existingNames={roles?.map(({ name }) => name)}
				open={newRoleDialog}
				onClose={() => setNewRoleDialog(false)}
			/>
		</>
	);
}
