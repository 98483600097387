import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


/*
	Allowed types: 'error', 'warning', 'info' or 'success'
	Duration is a ms value, 0 or undefined = autohide disabled
*/


export default function CustomSnackbar({open, onClose, type, message, duration}) {
	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
	
		onClose();
	};

	return(
		<Snackbar open={open} autoHideDuration={duration} onClose={handleClose} anchorOrigin={{ vertical:'top', horizontal:'center' }}>
			<Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
				{message}
			</Alert>
		</Snackbar>
	);
};
