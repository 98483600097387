import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useNavContext } from '../../../context/ContextManager';
import { v1 as uuidv1 } from 'uuid';
import _ from 'lodash';

import WorkflowTemplateComponentDialog from './WorkflowTemplateComponentDialog';

import {
	Typography,
	Button,
	TextField,
	Container,
	IconButton,
	Box,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Paper,
} from '@mui/material';
import AddIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import BtLoading from '../../../components/generic/BtLoading';
import {
	a11yProps,
	BtTab,
	BtTabBar,
	BtTabPanel,
	BtTabView,
} from '../../../components/generic/BtTabView';

import BtSelectionTable from '../../../components/generic/BtSelectionTable';

import useFetch from '../../../hooks/useFetch';
import { imageRepoGetList, workflowTemplateGet } from '../../../API';

// Data for landing page selection table and contextual navigation
import workflow_options from '../WorkflowsOptions';
import workflow_nav_item from '../WorkflowsNavItem';
import WorkflowTemplatePage from './WorkflowTemplatePage';
import { useTheme } from '@mui/styles';

const tableColumns = [
	{ field: 'type', text: 'Type', minBreakpoint: 'sm' },
	{ field: 'name', text: 'Name' },
	{ field: 'description', text: 'Description', minBreakpoint: 'md' },
	{ field: 'delete', text: 'Delete' },
	{ field: 'up', text: 'Move Up' },
	{ field: 'down', text: 'Move Down' },
];

export default function WorkflowTemplatePages({
	template,
	pages,
	pagesUpdate,
	templateDataSources,
	outputSchema,
}) {
	const { setBreadcrumbs } = useNavContext();
	const { setContextualNav } = useNavContext();
	const [currentTab, setCurrentTab] = useState(0);
	const theme = useTheme();

	const {
		loading: imageReposLoading,
		data: imageRepos,
		error,
		request: fetchImageRepos,
	} = useFetch(imageRepoGetList);

	useEffect(
		() => {
			fetchImageRepos();
		},
		[fetchImageRepos]
	);

	//Boilerplate for future implementation set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([...workflow_nav_item, ...workflow_options]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'Workflows', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	const handleAddPage = () => {
		let tempPages = [
			...pages,
			{
				uuid: uuidv1(),
				name: 'New Page',
				description: '',
				isRoot: false,
				children: [],
			},
		];
		pagesUpdate(tempPages);
	};

	const handleDeletePage = page => {
		const pageIndex = _.findIndex(pages, { uuid: page.uuid });
		let tempPages = [...pages];
		tempPages.splice(pageIndex, 1);
		pagesUpdate(tempPages);
	};

	const handleUpdatePage = page => {
		const pageIndex = _.findIndex(pages, { uuid: page.uuid });
		let tempPages = [...pages];
		tempPages[pageIndex] = page;
		pagesUpdate(tempPages);
	};

	return (
		<>
			{pages && (
				<Paper
					style={{
						padding: 16,
					}}
				>
					<Button
						sx={{ margin: '1rem' }}
						variant="outlined"
						startIcon={<AddIcon />}
						onClick={handleAddPage}
					>
						Add Page
					</Button>
					<BtTabBar
						currentTab={currentTab}
						onTabChange={(event, selectedTab) =>
							setCurrentTab(selectedTab)
						}
						style={{
							transform: 'translateY(-1px)',
							marginTop: 10,
						}}
					>
						{pages.map((page, index) => (
							<BtTab
								label={
									page.isRoot
										? `${page.name} (Root)`
										: page.name
								}
								{...a11yProps(index)}
								key={index}
							/>
						))}
					</BtTabBar>

					{pages.map((page, pageIndex) => (
						<BtTabPanel
							currentTab={currentTab}
							index={pageIndex}
							key={pageIndex}
						>
							<WorkflowTemplatePage
								page={page}
								handleDeletePage={handleDeletePage}
								handleUpdatePage={handleUpdatePage}
								pageList={pages}
								templateDataSources={templateDataSources}
								imageRepos={imageRepos}
								outputSchema={outputSchema}
							/>
						</BtTabPanel>
					))}
				</Paper>
			)}
		</>
	);
}
