import { Box, TextField } from '@mui/material';
import { styled } from '@mui/styles';
import PropTypes from 'prop-types';
import { memo, useEffect, useMemo } from 'react';
import * as yup from 'yup';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

/**
 *
 * @param {*} names
 * @param {*} name
 * @returns The existing names, with the name of the colour currently being edited removed
 */
const evalExistingNames = (names, name) => {
	const filteredNames = [...names];
	filteredNames.splice(names.findIndex(n => n === name), 1);
	return filteredNames;
};

const FormContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	padding: '8px',
	margin: '4px',
	borderRadius: '16px',
	backgroundColor: theme.palette.background.default,
	alignItems: 'flex-end',
}));

const NameForm = memo(function NameForm({
	name,
	names,
	onChange,
	setNameFormIsValid,
}) {
	const schema = useMemo(
		() =>
			yup.object({
				name: yup
					.string()
					.min(3)
					.max(14)
					.notOneOf(
						evalExistingNames(names, name) || [],
						'This name already exists'
					)
					.required()
					.label('Name'),
			}),
		[name, names]
	);

	const { watch, control } = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			name: name,
		},
		mode: 'onChange',
	});

	// trigger parent state update if form validity changes
	const { isValid } = useFormState({ control });
	useEffect(
		() => {
			setNameFormIsValid(isValid);
		},
		[isValid, setNameFormIsValid]
	);

	// pass input value to parent
	const textField = watch('name');
	useEffect(
		() => {
			onChange(textField);
		},
		[onChange, textField]
	);

	return (
		<>
			<FormContainer>
				<Controller
					render={({ field, fieldState: { error } }) => (
						<TextField
							{...field}
							error={!!error}
							variant="standard"
							helperText={error?.message}
							label="Colour name"
							fullWidth
							disabled={false}
						/>
					)}
					name="name"
					control={control}
				/>
			</FormContainer>
		</>
	);
});

NameForm.propTypes = {
	name: PropTypes.string,
	names: PropTypes.arrayOf(PropTypes.string).isRequired,
	onChange: PropTypes.func.isRequired,
	setNameFormIsValid: PropTypes.func.isRequired,
};

NameForm.displayName = 'NameForm';

export { NameForm };
