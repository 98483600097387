import React from 'react';
import PropTypes from 'prop-types';

import {
	AspectRatio,
	Category,
	FilterBAndW,
	Grain,
	GridView,
	TextFields,
} from '@mui/icons-material/';
import { Card, styled, Typography } from '@mui/material';

const FormatDetailContainer = styled('div')(() => ({
	alignItems: 'center',
	display: 'flex',
	margin: '0 1.5em 1em 0',

	'&:last-of-type': {
		marginRight: 0,
	},
}));

const FormatDetail = ({ Icon, label, value }) => {
	return (
		<FormatDetailContainer>
			{Icon}
			<Typography
				style={{ marginLeft: '0.2em' }}
			>{`${label}: ${value}`}</Typography>
		</FormatDetailContainer>
	);
};

export const ImageFormatCard = ({ format }) => {
	const { grayscale, maxWidthHeight, name, watermark } = format;

	return (
		<Card
			style={{ marginBottom: '1em', padding: '1em' }}
			variant="outlined"
		>
			<Typography
				style={{ fontWeight: 'bold', marginBottom: '0.5em' }}
				variant="h6"
			>
				{name}
			</Typography>
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					marginBottom: '-1em',
				}}
			>
				<FormatDetail
					Icon={<AspectRatio />}
					label="Max Width/Height"
					value={maxWidthHeight}
				/>
				<FormatDetail
					Icon={<FilterBAndW />}
					label="Grayscale"
					value={grayscale ? 'Yes' : 'No'}
				/>
				{watermark &&
					Object.keys(watermark).length > 0 && (
						<>
							<FormatDetail
								Icon={<Category />}
								label="Watermark Type"
								value={watermark.type}
							/>
							<FormatDetail
								Icon={<TextFields />}
								label="Watermark Text"
								value={watermark.text}
							/>
							<FormatDetail
								Icon={<Grain />}
								label="Watermark DPI"
								value={watermark.dpi}
							/>
							<FormatDetail
								Icon={<GridView />}
								label="Watermark Tile"
								value={watermark.tile ? 'Yes' : 'No'}
							/>
						</>
					)}
			</div>
		</Card>
	);
};

FormatDetail.propTypes = {
	Icon: PropTypes.element.isRequired,
	label: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

ImageFormatCard.propTypes = {
	format: PropTypes.object.isRequired,
};

export default ImageFormatCard;
