import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Divider, Typography } from '@mui/material';
import {
	Close,
	ContentSave,
	Delete,
	FilterCheck,
	FilterOff,
	FilterRemove,
} from 'mdi-material-ui';
import {
	BtPopperExtendedActions,
	BtPopperExtendedContent,
	BtPopperExtendedTitle,
} from '../../../BtPopperContent';
import { useMemo } from 'react';
import FilterInput from './FilterInput';
import FilterButton from './FilterButton';
import { Box } from '@mui/system';

export const Filter = ({
	editMode,
	filter,
	filters,
	onClose,
	setFilter,
	setFilters,
}) => {
	// Removes the filter for the filters array
	const handleRemoveFilter = useCallback(
		() => {
			const filtersUpdate = filters.filter(
				item => item.name !== filter.name
			);

			setFilters([...filtersUpdate]);
		},
		[filter, filters, setFilters]
	);

	// Sets the filterEnable
	const handleDisableFilter = useCallback(
		() => {
			const filtersUpdate = filters.map(item => {
				if (item.name === filter.name) {
					return {
						...item,
						filterEnabled: !item.filterEnabled,
					};
				}

				return item;
			});

			setFilters([...filtersUpdate]);
		},
		[filter, filters, setFilters]
	);

	const handleSaveFilter = useCallback(
		() => {
			console.log('save filter: ', filter);
		},
		[filter]
	);

	const editSubtitle = useMemo(
		() => {
			if (filter.filterEnabled) {
				return filter.required
					? // <Typography>Active {<em>- Required</em>}</Typography>
					  'Required'
					: 'Enabled';
			}
			return 'Disabled';
		},
		[filter.filterEnabled, filter.required]
	);

	if (editMode) {
		return (
			<>
				<BtPopperExtendedTitle
					title={
						<Box
							sx={{
								width: '100%',
								flexDirection: 'row',
								display: 'flex',
							}}
						>
							<Typography
								noWrap
								style={{ fontSize: 19, fontWeight: 500 }}
							>
								Edit Filter:&nbsp;
							</Typography>
							<Typography
								noWrap
								style={{ fontSize: 19, fontWeight: 400 }}
							>
								{filter.name}
							</Typography>
							{filter.required && (
								<Typography
									style={{
										fontSize: 19,
										fontWeight: 400,
										fontStyle: 'italic',
									}}
								>
									{` (${editSubtitle})`}
								</Typography>
							)}
						</Box>
					}
				/>

				<Divider />
				<BtPopperExtendedContent>
					<Typography variant="h6">
						{filter.singleValue ? 'Value' : 'Values'}
					</Typography>
					<FilterInput
						disabled={!filter.filterEnabled}
						editMode={editMode}
						filter={filter}
						filters={filters}
						setFilters={setFilters}
					/>
				</BtPopperExtendedContent>
				<BtPopperExtendedActions>
					{/* TODO - support saving of filters */}
					{/* <FilterButton
						title="Save filter"
						onClick={handleSaveFilter}
						// disabled={filter.required}
						icon={<ContentSave />}
					/> */}
					<FilterButton
						title="Delete filter"
						onClick={handleRemoveFilter}
						disabled={filter.required}
						icon={<Delete />}
					/>
					<FilterButton
						title={
							filter.filterEnabled
								? 'Disable filter'
								: 'Enable filter'
						}
						onClick={handleDisableFilter}
						disabled={filter.required}
						icon={
							filter.filterEnabled ? (
								<FilterOff />
							) : (
								<FilterCheck />
							)
						}
					/>
					<FilterButton
						title="Close"
						onClick={onClose}
						icon={<Close />}
					/>
				</BtPopperExtendedActions>
			</>
		);
	}

	return (
		<>
			<Typography variant="h6">Values</Typography>
			<FilterInput
				editMode={editMode}
				filter={filter}
				setFilter={setFilter}
			/>
		</>
	);
};

Filter.propTypes = {
	editMode: PropTypes.bool,
	filter: PropTypes.object.isRequired,
	filters: PropTypes.array,
	onClose: PropTypes.func,
	setFilter: PropTypes.func,
	setFilters: PropTypes.func,
};

export default Filter;
