import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, CircularProgress, Tooltip } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import ActionButtonContainer from '../../../../../components/generic/ActionButtonContainer';
import BtConfirmDialog from '../../../../../components/generic/BtConfirmDialog';
import { useDataSetContext } from '../../../hocs/withDataSetContext';

export default function DataSetEditActions({
	disableEscape,
	discardChanges,
	saveChanges,
}) {
	const { sending } = useDataSetContext();

	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [showSaveDialog, setShowSaveDialog] = useState(false);

	useEffect(
		() => {
			const handleKeyPress = ({ key, ctrlKey }) => {
				if (!disableEscape && !showDeleteDialog && key === 'Escape') {
					setShowDeleteDialog(true);
				}

				if (key === 'Enter' && ctrlKey) {
					saveChanges();
				}
			};

			window.addEventListener('keydown', handleKeyPress);

			return () => {
				window.removeEventListener('keydown', handleKeyPress);
			};
		},
		[disableEscape, saveChanges, showDeleteDialog]
	);

	return (
		<>
			<ActionButtonContainer>
				<Button
					color="error"
					disabled={sending}
					disableElevation
					onClick={() => setShowDeleteDialog(true)}
					size="small"
				>
					Discard Changes
				</Button>
				<Tooltip enterDelay={400} title="Quick-Save: Ctrl + Enter">
					<span>
						<Button
							disabled={sending}
							disableElevation
							onClick={() => setShowSaveDialog(true)}
							size="small"
							startIcon={
								sending ? (
									<CircularProgress size={16} />
								) : (
									<SaveIcon />
								)
							}
							variant="contained"
						>
							Save Changes
						</Button>
					</span>
				</Tooltip>
			</ActionButtonContainer>
			<BtConfirmDialog
				action={discardChanges}
				isDestructive
				onClose={() => setShowDeleteDialog(false)}
				open={showDeleteDialog}
				prompt="Are you sure you want to discard any changes? This cannot be undone."
				title="Discard Changes"
				verb="Discard"
			/>
			<BtConfirmDialog
				action={saveChanges}
				ActionIcon={<SaveIcon />}
				onClose={() => setShowSaveDialog(false)}
				open={showSaveDialog}
				prompt="Are you sure you want to overwrite the document with the changes you have made?"
				title="Save Changes"
				verb="Save"
			/>
		</>
	);
}

DataSetEditActions.propTypes = {
	disableEscape: PropTypes.bool,
	discardChanges: PropTypes.func.isRequired,
	saveChanges: PropTypes.func.isRequired,
};
