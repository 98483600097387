import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
} from 'react';

import {
	Checkbox,
	Collapse,
	DialogTitle,
	DialogContent,
	DialogActions,
	Divider,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from '@mui/material';
import produce from 'immer';
import useSize from '@react-hook/size';

import BtError from '../../../../components/generic/BtError';
import BtLoading from '../../../../components/generic/BtLoading';
import BtMessage from '../../../../components/generic/BtMessage';
import { PermWizardContext } from './PermWizardContext';
import { resourceGroupGet } from '../../../../API/resourceGroups';
import useFetch from '../../../../hooks/useFetch';
import {
	useWizard,
	WizardButtons,
	WizardHeading,
} from '../../../../components/wizard';

export default function PermissionResources() {
	const actionsRef = useRef();
	const [, actionsHeight] = useSize(actionsRef);

	const { isFirstStep, isLastStep, nextStep, previousStep } = useWizard();

	const { data, onClose, permToEdit, setData } = useContext(
		PermWizardContext
	);

	const { loading, data: resources, error, request: getResources } = useFetch(
		resourceGroupGet
	);

	useEffect(
		() => {
			// Get resources for selected method from API
			if (!resources && data?.method?.resourceGroup) {
				getResources({
					group: data.method.resourceGroup,
				});
			}
		},
		[getResources, data, resources]
	);

	useEffect(
		() => {
			// Set initial selectAll status if editing
			const { editResourceIds, resources: selectedResources } =
				data || {};
			if (resources && editResourceIds) {
				if (editResourceIds.includes('*')) {
					setData(
						produce(draft => {
							draft.selectAllResources = true;
						})
					);
				}
			}

			// Set initial selected resources if editing
			if (data?.editResourceIds && resources && !selectedResources) {
				setData(
					produce(draft => {
						draft.resources = resources.filter(({ id }) =>
							data.editResourceIds.includes(id)
						);
					})
				);
			}
		},
		[resources, data, setData]
	);

	const selectedIds = useMemo(
		() => (data?.resources || []).map(({ id }) => id),
		[data]
	);

	const handleSelectAllToggle = useCallback(
		() => {
			setData(
				produce(draft => {
					draft.selectAllResources = !draft.selectAllResources;
				})
			);
		},
		[setData]
	);

	const handleResourceToggle = useCallback(
		resource => {
			setData(
				produce(draft => {
					if (selectedIds.includes(resource.id)) {
						draft.resources = draft.resources.filter(
							({ id }) => resource.id !== id
						);
					} else {
						draft.resources = [
							...(draft.resources || []),
							resource,
						];
					}
				})
			);
		},
		[setData, selectedIds]
	);

	const sortedResources = useMemo(
		() =>
			(resources || []).sort((a, b) =>
				a['name'].localeCompare(b['name'])
			),
		[resources]
	);

	return (
		<BtLoading
			loading={loading}
			style={{ maxHeight: '400px', height: '100vh' }}
		>
			{error && (
				<BtError
					title="Retrieval Error"
					description={`An error occurred when attempting to retrieve the list of resources for the ${data
						?.method?.name || 'unknown'} permission.`}
					action={() =>
						getResources({
							group: data.method.resourceGroup.toLowerCase(),
						})
					}
					fullScreen={false}
					style={{
						minHeight: `calc(100% - ${actionsHeight}px)`,
					}}
				/>
			)}

			{!error && (
				<>
					<DialogTitle>
						<WizardHeading
							title="Select resources"
							subtitle={data?.method?.name || ''}
							onCancelClick={onClose}
						/>
					</DialogTitle>
					<DialogContent>
						{!permToEdit &&
							!!data.editId && (
								<BtMessage
									message="This permission has already been added, but you can go ahead and update the selected resources."
									style={{ marginBottom: '1em' }}
								/>
							)}
						<ListItem disablePadding style={{ marginBottom: 8 }}>
							<ListItemButton
								role={undefined}
								onClick={handleSelectAllToggle}
								dense
							>
								<ListItemIcon>
									<Checkbox
										edge="start"
										tabIndex={-1}
										disableRipple
										checked={
											data?.selectAllResources || false
										}
										inputProps={{
											'aria-labelledby':
												'checkbox-select-all',
										}}
									/>
								</ListItemIcon>
								<ListItemText
									id="checkbox-select-all"
									primary="Select all available resources"
								/>
							</ListItemButton>
						</ListItem>
						<Collapse in={!data?.selectAllResources}>
							<Divider />
							<List
								style={{
									width: '100%',
								}}
							>
								{sortedResources.map(resource => {
									const labelId = `checkbox-list-label-${
										resource.id
									}`;

									return (
										<ListItem key={labelId} disablePadding>
											<ListItemButton
												role={undefined}
												onClick={() =>
													handleResourceToggle(
														resource
													)
												}
												dense
												disabled={
													data?.selectAllResources
												}
											>
												<ListItemIcon>
													<Checkbox
														edge="start"
														checked={selectedIds.includes(
															resource.id
														)}
														tabIndex={-1}
														disableRipple
														inputProps={{
															'aria-labelledby': labelId,
														}}
													/>
												</ListItemIcon>
												<ListItemText
													id={labelId}
													primary={resource.name}
												/>
											</ListItemButton>
										</ListItem>
									);
								})}
							</List>
						</Collapse>
					</DialogContent>
				</>
			)}

			<DialogActions ref={actionsRef}>
				<WizardButtons
					canGoForward={
						(data?.resources || []).length > 0 ||
						!!data.selectAllResources
					}
					onCancelClick={onClose}
					onNextClick={nextStep}
					onPreviousClick={previousStep}
					hidePrevious={!!permToEdit}
					isFirstStep={isFirstStep}
					isLastStep={isLastStep}
				/>
			</DialogActions>
		</BtLoading>
	);
}
