import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import {
	Button,
	Autocomplete,
	TextField,
	Divider,
	Typography,
} from '@mui/material';
import { Close, FilterCheck } from 'mdi-material-ui';

import {
	BtPopperExtendedActions,
	BtPopperExtendedContent,
	BtPopperExtendedTitle,
} from '../../BtPopperContent';
import Filter from './filter/Filter';

const prepareFilterList = (filters, filterOptions) => {
	const currentFilterNames = filters.map(filter => filter.field.name);
	const uniqueFilters = filterOptions.filter(
		filterOption => !currentFilterNames.includes(filterOption.name)
	);
	const sortedFilters = uniqueFilters.sort((a, b) =>
		a.label.localeCompare(b.label)
	);
	return sortedFilters;
};

export default function FilterAdd({
	filters,
	filterOptions,
	onClose,
	setFilters,
}) {
	const availableOptions = useMemo(
		() => {
			const preparedFilters = prepareFilterList(filters, filterOptions);
			return preparedFilters;
		},
		[filterOptions, filters]
	);

	const [customName, setCustomName] = useState(''); // Value of custom name, if provided by user
	const [filter, setFilter] = useState({
		// The properties of the filter
		dataSource: null,
		field: null, // to be the field object selected from the fields available in the autocomplete
		value: null, // the value of the applied filter - to be initialized with suitable values then updated as filter is adjusted
		filterValue: null, // the value used to apply the filter
		name: null, // to be set to field name or custom name if provided by user
		uuid: null, // uuid of the filter
		filterEnabled: null, //
	});
	const [inputValue, setInputValue] = useState(''); // Autocomplete field values
	const [value, setValue] = useState(null); // Autocomplete field values

	// TODO - add some protection to catch errors for corner case data
	const parse_field = field => {
		if (field?.type === 'categorical') {
			return Object.fromEntries(
				field.categories.map(category => [category, true])
			);
		} else if (field?.type === 'boolean') {
			return true;
		} else if (field?.type === 'quantitative') {
			return [field.range.min, field.range.max];
		} else {
			return { error: 'error - unexpected data type' };
		}
	};

	const parse_filter_value = field => {
		if (field?.type === 'categorical') {
			return field.categories;
		} else if (field?.type === 'boolean') {
			return true;
		} else if (field?.type === 'quantitative') {
			return { min: field.range.min, max: field.range.max };
		} else {
			return { error: 'error - unexpected data type' };
		}
	};

	// On field select - set the controlled value and initialize filter data
	const onFieldSelect = newValue => {
		setValue(newValue);
		setFilter({
			field: newValue,
			value: parse_field(newValue),
			filterValue: parse_filter_value(newValue),
		});
	};

	// Add filter to filters array and close popper, setting the filter name on the way
	const handleSubmit = () => {
		setFilters([
			...filters,
			{
				...filter,
				name: customName.length > 0 ? customName : filter.field.name,
				filterEnabled: true,
			},
		]);
		setValue(null);
		onClose();
	};

	return (
		<>
			<BtPopperExtendedTitle
				title={
					<Typography style={{ fontSize: 19, fontWeight: 500 }}>
						Add Filter
					</Typography>
				}
			/>

			<Divider />
			<BtPopperExtendedContent>
				<Typography variant="h6">Filter</Typography>
				<Autocomplete
					style={{ marginLeft: 5, marginBottom: '1.5em' }}
					value={value}
					onChange={(event, newValue) => {
						onFieldSelect(newValue);
					}}
					inputValue={inputValue}
					onInputChange={(event, newInputValue) => {
						setInputValue(newInputValue);
					}}
					id="add-filter"
					options={availableOptions}
					// groupBy={option => option.dataViewUuid}
					renderInput={params => (
						<TextField
							autoFocus
							{...params}
							placeholder="Search and Select filters"
							variant="standard"
							color="primary"
						/>
					)}
				/>

				{value && (
					<div
						style={{
							paddingTop:
								filter.field.type === 'quantitative' && '20px',
						}}
					>
						<Filter filter={filter} setFilter={setFilter} />
						{!filter.value.hasOwnProperty('error') && (
							<>
								<Typography
									variant="h6"
									style={{
										marginTop: '1.5em',
									}}
								>
									Filter Name
								</Typography>

								<TextField
									style={{
										marginLeft: 5,
									}}
									fullWidth
									variant="standard"
									placeholder="Choose a custom name for the filter"
									id="CustomName"
									value={customName}
									onChange={event => {
										setCustomName(event.target.value);
									}}
								/>
							</>
						)}
					</div>
				)}
			</BtPopperExtendedContent>

			{/* {!value && <BtPopperExtendedTitle title="Predefined filters" />} */}
			<BtPopperExtendedActions>
				<Button onClick={onClose} startIcon={<Close />}>
					Cancel
				</Button>
				<Button
					disableElevation
					variant="contained"
					onClick={handleSubmit}
					startIcon={<FilterCheck />}
					disabled={!value || filter.value?.hasOwnProperty('error')}
				>
					APPLY
				</Button>
			</BtPopperExtendedActions>
		</>
	);
}

FilterAdd.propTypes = {
	filters: PropTypes.array.isRequired,
	filterOptions: PropTypes.array.isRequired,
	onClose: PropTypes.func.isRequired,
	setFilters: PropTypes.func.isRequired,
};
