import { apiClient } from './api_client';

//========================================================================================================
export async function ipGet() {
	const req_url = 'ip';
	try {
		const response = await apiClient({ method: 'get', url: req_url });
		if (response) {
			if (response.result === 'OK') {
				return response.IPv4;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}
