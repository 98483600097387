import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: 20,
	margin: '0 auto',

	[theme.breakpoints.down('md')]: {
		maxWidth: '100%',
	},
}));

export default function BtFormContent({ children }) {
	return <Root>{children}</Root>;
}

BtFormContent.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};
