export const tableData = [
	{
		uuid: '0',
		firstName: 'John',
		surname: 'Smith',
		admin: false,
		online: true,
		age: 43,
		dateJoined: '2023-01-17T00:00:00.000Z',
		skillLevel: 'competent',
	},
	{
		uuid: '1',
		firstName: 'Alan',
		surname: 'Johnson',
		admin: true,
		online: true,
		age: 27,
		dateJoined: '2023-01-12T00:00:00.000Z',
		skillLevel: 'novice',
	},
	{
		uuid: '2',
		firstName: 'Billy',
		surname: 'Jones',
		admin: false,
		online: false,
		age: 23,
		dateJoined: '2023-01-02T00:00:00.000Z',
		skillLevel: 'novice',
	},
	{
		uuid: '3',
		firstName: 'Johnny',
		surname: 'Donalds',
		admin: true,
		online: false,
		age: 51,
		dateJoined: '2023-01-14T00:00:00.000Z',
		skillLevel: 'masterful',
	},
	{
		uuid: '4',
		firstName: 'Alfred',
		surname: 'Anderson',
		admin: true,
		online: true,
		age: 32,
		dateJoined: '2023-01-05T00:00:00.000Z',
		skillLevel: 'proficient',
	},
	{
		uuid: '5',
		firstName: 'Konnor',
		surname: 'Harland',
		admin: false,
		online: false,
		age: 33,
		dateJoined: '2023-01-05T10:00:00.000Z',
		skillLevel: 'competent',
	},
	{
		uuid: '6',
		firstName: 'Slade',
		surname: 'Hatheway',
		admin: false,
		online: true,
		age: 50,
		dateJoined: '2023-01-25T00:00:00.000Z',
		skillLevel: 'expert',
	},
	{
		uuid: '7',
		firstName: 'Alphonzo',
		surname: 'Winslow',
		admin: false,
		online: true,
		age: 48,
		dateJoined: '2023-01-29T00:00:00.000Z',
		skillLevel: 'proficient',
	},
	{
		uuid: '8',
		firstName: 'Mark',
		surname: 'Grenville',
		admin: true,
		online: true,
		age: 30,
		dateJoined: '2023-01-15T00:00:00.000Z',
		skillLevel: 'novice',
	},
	{
		uuid: '9',
		firstName: 'Christopher',
		surname: 'Love',
		admin: false,
		online: false,
		age: 42,
		dateJoined: '2023-01-18T00:00:00.000Z',
		skillLevel: 'proficient',
	},
	{
		uuid: '10',
		firstName: 'Jane',
		surname: 'Siddall',
		admin: true,
		online: false,
		age: 39,
		dateJoined: '2023-01-14T00:00:00.000Z',
		skillLevel: 'proficient',
	},
	{
		uuid: '11',
		firstName: 'Sherman',
		surname: 'Tittensor',
		admin: false,
		online: true,
		age: 58,
		dateJoined: '2023-01-09T00:00:00.000Z',
		skillLevel: 'masterful',
	},
	{
		uuid: '12',
		firstName: 'Agnes',
		surname: 'Rains',
		admin: false,
		online: true,
		age: 51,
		dateJoined: '2023-01-24T00:00:00.000Z',
		skillLevel: 'proficient',
	},
	{
		uuid: '13',
		firstName: 'Joyce',
		surname: 'McDonald',
		admin: false,
		online: false,
		age: 46,
		dateJoined: '2023-01-25T00:00:00.000Z',
		skillLevel: 'expert',
	},
	{
		uuid: '14',
		firstName: 'Andy',
		surname: 'Knight',
		admin: false,
		online: true,
		age: 34,
		dateJoined: '2023-01-22T00:00:00.000Z',
		skillLevel: 'proficient',
	},
	{
		uuid: '15',
		firstName: 'Jon',
		surname: 'Davis',
		admin: true,
		online: false,
		age: 42,
		dateJoined: '2023-01-02T00:00:00.000Z',
		skillLevel: 'proficient',
	},
];
