import { styled } from '@mui/material';

export default styled('div')(() => ({
	display: 'flex',
	flex: 1,
	flexDirection: 'column',

	'& .editNameDescription': {
		opacity: 0,
	},

	'&:hover .editNameDescription': {
		opacity: 1,
	},

	'&:focus-within .editNameDescription': {
		opacity: 1,
	},
}));
