import React from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';

import * as yup from 'yup';

import BtDialog from '../../../../../components/generic/BtDialog';
import {
	BtForm,
	BtFormActionsContainer,
	BtFormContent,
	BtFormSelect,
	withFormContextMethods,
} from '../../../../../components/generic/forms';
import { Delete } from '@mui/icons-material';

const ActionButtons = withFormContextMethods(
	({ formState: { isDirty }, onClose }) => {
		return (
			<>
				<Button variant="outlined" onClick={onClose}>
					Cancel
				</Button>
				<Button
					variant="contained"
					disabled={!isDirty}
					type="submit"
					startIcon={<Delete />}
					disableElevation
				>
					Delete Page
				</Button>
			</>
		);
	}
);

export default function InsightPageDeleteDialog({
	open,
	onClose,
	pages,
	onPageDelete,
}) {
	const schema = yup.object({
		page: yup
			.string()
			.required()
			.label('Page'),
	});

	const onSubmit = async (values, reset) => {
		onPageDelete(values.page);
		onClose();
		reset();
	};

	return (
		<BtDialog open={open} onClose={onClose} minwidth="sm" fullWidth>
			<DialogTitle>Delete Page</DialogTitle>
			<BtForm
				onSubmit={onSubmit}
				validationSchema={schema}
				defaultValues={null}
			>
				<DialogContent>
					<BtFormContent>
						<BtFormSelect
							label="Select page to delete"
							name="page"
							items={pages}
						/>
					</BtFormContent>
				</DialogContent>
				<DialogActions>
					<BtFormActionsContainer>
						<ActionButtons onClose={onClose} />
					</BtFormActionsContainer>
				</DialogActions>
			</BtForm>
		</BtDialog>
	);
}

ActionButtons.propTypes = {
	isDirty: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
};

InsightPageDeleteDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	pages: PropTypes.array.isRequired,
	onPageDelete: PropTypes.func.isRequired,
};
