import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Box, TextField, styled } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useAppContext } from '../../../../context/ContextManager';
import { useTheme } from '@mui/styles';

const AutoCompleteContainer = styled(Box)(({ theme }) => ({
	height: '54px',
	width: '250px',
	borderBottom: `1px solid ${theme.palette.divider}`,
	display: 'flex',
	backgroundColor: theme.palette.background.default,
}));

// const StyledTextField = styled(TextField)(({ theme, show_mobile }) => ({
// 	width: '230px',
// 	'& .MuiInput-underline': {
// 		// paddingLeft: '15px',
// 		// '&:before': {
// 		// 	borderBottomColor: theme.palette.primary.main, // inactive underline
// 		// 	borderWidth: show_mobile ? '2px' : '0px',
// 		// },
// 		// '&:hover::before': {
// 		// 	borderBottom: `solid 1px ${theme.palette.primary.main}`,
// 		// },

// 		'&:before': {
// 			borderBottomColor: theme.palette.primary.main, // inactive underline
// 			borderWidth: show_mobile ? '2px' : '0px',
// 		},
// 		'&:hover::before': {
// 			// borderBottom: `solid 2px ${theme.palette.primary}`,
// 			borderBottom: `solid 2px pink`,
// 		},
// 	},
// 	'& .MuiAutocomplete-inputRoot': {
// 		paddingBottom: show_mobile ? '16px' : '0px 10px 6px 10px',
// 		height: '54px',
// 	},
// 	// '& .MuiAutocomplete-endAdornment': {
// 	// 	position: 'absolute',
// 	// 	right: '15px',
// 	// 	top: `calc(50% - 18px)`,
// 	// },
// }));

const TabsAutocomplete = memo(function TabsAutocomplete({
	onChange,
	autoCompleteOptions,
}) {
	const [value, setValue] = useState(null);
	const [nextTab, setNextTab] = useState(null);
	const { showMobile } = useAppContext();
	const theme = useTheme();

	useEffect(
		() => {
			if (nextTab !== null) {
				console.log(nextTab);
				onChange(null, nextTab.id);
				setNextTab(null);
				setValue(null);
			}
		},
		[nextTab, onChange]
	);

	return (
		<AutoCompleteContainer>
			<Autocomplete
				blurOnSelect
				clearOnBlur
				clearOnEscape
				value={value}
				onChange={(event, newValue) => {
					setValue(null);
					setNextTab(newValue);
				}}
				onHighlightChange={() => {
					setValue(null);
				}}
				popupIcon={<MenuIcon />}
				id="search-insight-collection"
				options={autoCompleteOptions}
				renderInput={params => (
					// <StyledTextField
					// 	{...params}
					// 	placeholder="search"
					// 	variant="standard"
					// 	color="primary"
					// 	show_mobile={+showMobile}
					// />
					<TextField
						sx={{
							width: '230px',
							'& .MuiInput-underline': {
								'&:before': {
									borderBottomColor:
										theme.palette.primary.main, // inactive underline
									borderWidth: showMobile ? '2px' : '0px',
								},
								'&:hover:': {
									borderBottom: `solid 2px ${
										theme.palette.primary.main
									}`,
								},
							},
							'& .MuiAutocomplete-inputRoot': {
								paddingBottom: showMobile
									? '16px'
									: '0px 10px 6px 10px',
								height: '54px',
							},
						}}
						{...params}
						placeholder="search"
						variant="standard"
						color="primary"
						show_mobile={+showMobile}
					/>
				)}
			/>
		</AutoCompleteContainer>
	);
});

TabsAutocomplete.propTypes = {
	autoCompleteOptions: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
		})
	),
	onChange: PropTypes.func.isRequired,
};

TabsAutocomplete.displayName = 'TabsAutocomplete';

export { TabsAutocomplete };
