import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';

import makeStyles from '@mui/styles/makeStyles';

import Home from '../views/Home';

/**
 * Admin views
 */
import Admin from '../views/Admin/Admin';
import GeneralInfo from '../views/Admin/general-info/GeneralInfo';
import Subscriptions from '../views/Admin/subscriptions/Subscriptions';
import SubscriptionAssignment from '../views/Admin/subscriptions/SubscriptionAssignment';
import Screens from '../views/Admin/screens/Screens';
import ScreenEdit from '../views/Admin/screens/ScreenEdit';
//import Billing from '../views/Admin/billing/Billing';
import Roles from '../views/Admin/roles/Roles';
import RoleEditor from '../views/Admin/roles/RoleEditor';
import Users from '../views/Admin/users/Users';
import UsersEditor from '../views/Admin/users/UsersEditor';
import Tags from '../views/Admin/tags/Tags';
import TagsEditor from '../views/Admin/tags/TagsEditor';
//import Documents from '../views/Admin/documents/Documents';
//import DocumentEditor from '../views/Admin/documents/DocumentEditor';

/**
 * Data Manager Views
 */
import DataManager from '../views/DataManager/DataManager';

/**
 * Data views
 */
import DataSources from '../views/DataManager/DataSources/DataSources';
import DataStream from '../views/DataManager/DataSources/DataStream';
import DataSet from '../views/DataManager/DataSources/data-set/DataSet';
import DataViews from '../views/DataManager/DataViews/DataViews';
import DataViewEditor from '../views/DataManager/DataViews/DataViewEditor';
import NewDataSetRecord from '../views/DataManager/DataSources/data-set/create-record/NewDataSetRecord';
import ImageRepo from '../views/DataManager/DataSources/image-repo/ImageRepo';
import ApiManager from '../views/DataManager/ApiManager/ApiManager';
import ApiManagerEditor from '../views/DataManager/ApiManager/ApiManagerEditor';

/**
 * Insights views
 */
import Insights from '../views/Insights/Insights';
import InsightCollections from '../views/Insights/InsightCollections/InsightCollections';
import InsightCollection from '../views/Insights/InsightCollection/InsightCollection';
// import InsightCollectionSettings from '../views/Insights/Insight/InsightCollectionSettings';
// import InsightCollectionEditCollection from '../views/Insights/Insight/InsightCollectionEdit/CollectionSettings';
// import InsightCollectionEditPages from '../views/Insights/Insight/InsightCollectionEdit/PageEdit';
// import InsightCollectionEditDataSources from '../views/Insights/Insight/InsightCollectionEdit/DataSources';
// import InsightCollectionEditTile from '../views/Insights/Insight/InsightCollectionEdit/EditTile';
import InsightCollectionEditRoute from '../views/Insights/InsightCollection/InsightCollectionEdit/InsightCollectionEditRoute';
import InsightCollectionSettingsRoute from '../views/Insights/InsightCollection/InsightCollectionEdit/InsightCollectionSettingsRoute';
import Alerts from '../views/Insights/Alerts/Alerts';
import InsightExports from '../views/Insights/Exports/Exports';
import InsightCollectionDataSources from '../views/Insights/InsightCollection/InsightCollectionEdit/editDataSources/InsightCollectionDataSources';

/**
 * Config views
 */
import ConfigList from '../views/IoT/DeviceConfigList';
import Config from '../views/IoT/DeviceConfig';
import AddConfig from '../views/IoT/AddDeviceConfig';
import ConfigNewRev from '../views/IoT/DeviceConfigNewRev';

/**
 * Container views
 */
import ContainerTaskList from '../views/IoT/ContainerTaskList.js';
import ContainerTask from '../views/IoT/ContainerTask.js';
import ContainerTaskRevision from '../views/IoT/ContainerTaskRevision.js';
import ContainerTaskNew from '../views/IoT/ContainerTaskNew.js';

/**
 * Device views
 */
import Devices from '../views/IoT/Devices';
import DeviceView from '../views/IoT/DeviceView';

/**
 * Exports views
 */
import Exports from '../views/DataManager/Exports';
import ReporterEdit from '../views/DataManager/ReporterEdit';

/**
 * IoT Views
 */
import IoT from '../views/IoT/IoT';

/**
 * File views
 */
import FilesList from '../views/IoT/FileList';
import AddFirmware from '../views/IoT/AddFirmware';
import FirmwareNewRev from '../views/IoT/FirmwareNewRev';
import FileView from '../views/IoT/FileView';

/**
 * Fleet Admin
 */
import FleetAdmin from '../views/IoT/FleetAdmin.js';

/**
 * Platform Admin views
 */
import BeaconAdmin from '../views/BeaconAdmin/BeaconAdmin';
import BeaconAdminOrganisations from '../views/BeaconAdmin/Organisations';
import BeaconAdminOrganisation from '../views/BeaconAdmin/Organisation';
import OrganisationUserEditor from '../views/BeaconAdmin/OrganisationUserEditor';
import BeaconAdminUsers from '../views/BeaconAdmin/Users';

/**
 * User views
 */
import UserSettings from '../views/UserSettings/UserSettings';
import UpdateDetails from '../views/UserSettings/UpdateDetails';
import Security from '../views/UserSettings/Security';
import PlatformSettings from '../views/UserSettings/PlatformSettings';
import UserPermissions from '../views/UserSettings/UserPermissions';
import UserSubscriptions from '../views/UserSettings/UserSubscriptions';
import About from '../views/UserSettings/About';

/**
 * Utils & Development views
 */
import Developer from '../Development/Developer';
import DeveloperAddDevice from '../Development/DeveloperAddDevice';
import PacketLogger from '../Development/PacketLogger';
import ServiceStats from '../Development/ServiceStats';
import PubSubDev from '../Development/PubSubDev';
import VisDev from '../Development/vis-dev/VisDev';
import UI_Components from '../Development/UI_Components';
import FormDev from '../Development/FormDev';
import AdvancedTableDev from '../Development/advanced-table/AdvancedTableDev';
import QueryBuilderDev from '../Development/query-builder/QueryBuilderDev';
import JsonTools from '../Development/JsonTools';
import BtAuthRoute from '../components/generic/BtAuthRoute';
import BtError from '../components/generic/BtError';
import Logout from '../utils/Logout';

/**
 * Workflow
 */
import Workflows from '../views/Workflows/Workflow';
import WorkflowTemplates from '../views/Workflows/WorkflowTemplates';
import WorkflowTemplateEdit from '../views/Workflows/WorkflowTemplateEdit/WorkflowTemplateEdit.js';
import WorkflowSubmissions from '../views/Workflows/WorkflowSubmissions';
import WorkflowSubmission from '../views/Workflows/WorkflowSubmission';
import { recordManagerRequiredPermissionsAll } from '../components/generic/recordManagement/utils/lookupTables/permissions';
import {
	INSIGHT_COLLECTION,
	WORKFLOW_TEMPLATE,
} from '../components/generic/recordManagement/utils/constants';
import WorkflowTemplateEditRoute from '../views/Workflows/WorkflowTemplateEdit/WorkflowTemplateEditRoute';
import PaletteDev from '../Development/palette/PaletteDev';
import { BtTabsDev } from '../Development/BtTabsDev';
import { InsightCollectionDataSourceEdit } from '../views/Insights/InsightCollection/InsightCollectionEdit/editDataSources/InsightCollectionDataSourceEdit';
//import WorkflowSession from '../views/Workflows/session/WorkflowSession';
// import PaletteDev from '../Development/palette/PaletteDev';
//import WorkflowSession from '../views/Workflows/session/WorkflowSession';

const styles = makeStyles({
	content_holder: {
		color: '#FF0000',
		padding: 0,
	},
});

const devRoutes = [
	{
		path: '/Developer',
		component: Developer,
	},
	{
		path: '/PacketLogger',
		component: PacketLogger,
	},
	{
		path: '/DeveloperAddDevice',
		component: DeveloperAddDevice,
	},
	{
		path: '/ServiceStats',
		component: ServiceStats,
	},
	{
		path: '/PubSubDev',
		component: PubSubDev,
	},
	{
		path: '/VisDev',
		component: VisDev,
	},
	{
		path: '/UIComponents',
		component: UI_Components,
	},
	{
		path: '/FormDev',
		component: FormDev,
	},
	{
		path: '/AdvancedTableDev',
		component: AdvancedTableDev,
	},
	{
		path: '/QueryBuilderDev',
		component: QueryBuilderDev,
	},
	{
		path: '/PaletteDev',
		component: PaletteDev,
	},
	{
		path: '/BtTabs',
		component: BtTabsDev,
	},
	{
		path: '/JsonTools',
		component: JsonTools,
	},
];

export default function Content() {
	const classes = styles();

	const history = useHistory();

	return (
		<Switch>
			{/* Home */}
			<Route exact path="/" component={Home} />

			{/* Admin views */}
			<Route exact path="/Admin" component={Admin} />
			<Route exact path="/GeneralInfo" component={GeneralInfo} />
			<Route exact path="/Subscriptions" component={Subscriptions} />
			<Route
				exact
				path="/Subscriptions/:id"
				component={SubscriptionAssignment}
			/>
			{/*<Route exact path="/Billing" component={Billing} />*/}
			<Route exact path="/Roles" component={Roles} />
			<Route exact path="/Roles/Edit/:id" component={RoleEditor} />
			<Route path="/Roles/Edit" component={RoleEditor} />
			<Route exact path="/Users" component={Users} />
			<Route exact path="/Users/Edit/:id" component={UsersEditor} />
			<Route exact path="/Tags" component={Tags} />
			<Route exact path="/Tags/Edit/:id" component={TagsEditor} />
			{/*<Route exact path="/Documents" component={Documents} />
			<Route exact path="/Documents/Upload" component={DocumentEditor} />
			<Route
				exact
				path="/Documents/Upload/:id"
				component={DocumentEditor}
		    />*/}
			<BtAuthRoute
				exact
				path="/Screens"
				component={Screens}
				requiredPermissionsAll={{ screens: ['ScreenAdminView'] }}
			/>
			<Route
				exact
				path="/Screens/ScreenEdit/:id"
				component={ScreenEdit}
			/>

			{/* Auth views */}
			<Route exact path="/FirstTimeLogin" component={Logout} />
			<Route exact path="/ChangePassword" component={Logout} />

			{/* Config views */}
			<BtAuthRoute exact path="/Config" validSubscriptionsAny={['IoT']}>
				<div className={classes.content_holder}>
					<ConfigList />
				</div>
			</BtAuthRoute>
			<BtAuthRoute
				exact
				path="/Config/AddConfig/:id"
				component={AddConfig}
				validSubscriptionsAny={['IoT']}
			/>
			<BtAuthRoute
				exact
				path="/Config/NewRevision/:id"
				component={ConfigNewRev}
				validSubscriptionsAny={['IoT']}
			/>
			<BtAuthRoute
				exact
				path="/Config/:id"
				component={Config}
				validSubscriptionsAny={['IoT']}
			/>
			{/* Container views */}
			<BtAuthRoute
				exact
				path="/ContainerTaskList"
				component={ContainerTaskList}
				validSubscriptionsAny={['IoT']}
			/>
			<BtAuthRoute
				exact
				path="/ContainerTask/:uuid"
				component={ContainerTask}
				validSubscriptionsAny={['IoT']}
			/>
			<BtAuthRoute
				exact
				path="/ContainerTaskRevision"
				component={ContainerTaskRevision}
				validSubscriptionsAny={['IoT']}
			/>
			{/* TODO
			The below line makes the page not load at all, the above allows page to load but it crashes */}
			{/* <Route exact path='/ContainerTaskRevision/:uuid/:revision' component={ContainerTaskRevision} /> */}
			<BtAuthRoute
				exact
				path="/ContainerTaskNew/:uuid/:revision"
				component={ContainerTaskNew}
				validSubscriptionsAny={['IoT']}
			/>

			{/* Data Manager Views */}
			<BtAuthRoute
				exact
				path="/DataManager"
				component={DataManager}
				requiredPermissionsAll={{ dataManager: ['DatasetView'] }}
				validSubscriptionsAny={['Data Manager']}
			/>
			<BtAuthRoute
				exact
				path="/DataSources"
				component={DataSources}
				requiredPermissionsAll={{ dataManager: ['DatasetView'] }}
				validSubscriptionsAny={['Data Manager']}
			/>
			<BtAuthRoute
				exact
				path="/DataSources/DataStream/:uuid"
				component={DataStream}
				requiredPermissionsAll={{ dataManager: ['DatasetView'] }}
				validSubscriptionsAny={['Data Manager']}
			/>
			<BtAuthRoute
				exact
				path="/DataSources/DataSet/:uuid/CreateNew"
				component={NewDataSetRecord}
				requiredPermissionsAll={{ dataManager: ['DatasetView'] }}
			/>
			<BtAuthRoute
				exact
				path="/DataSources/DataSet/:uuid"
				component={DataSet}
				requiredPermissionsAll={{ dataManager: ['DatasetView'] }}
				validSubscriptionsAny={['Data Manager']}
			/>
			<BtAuthRoute
				exact
				path="/DataSources/ImageRepo/:uuid"
				component={ImageRepo}
				requiredPermissionsAll={{ dataManager: ['ImageRepoView'] }}
				validSubscriptionsAny={['Data Manager']}
			/>
			<BtAuthRoute
				exact
				path="/DataViews"
				component={DataViews}
				requiredPermissionsAll={{ dataManager: ['DataViewView'] }}
				validSubscriptionsAny={['Data Manager']}
			/>
			<BtAuthRoute
				exact
				path="/DataViews/:uuid"
				component={DataViewEditor}
				requiredPermissionsAll={{ dataManager: ['DataViewView'] }}
				validSubscriptionsAny={['Data Manager']}
			/>
			<BtAuthRoute
				exact
				path="/ApiManager"
				component={ApiManager}
				requiredPermissionsAll={{ dataManager: ['ApiManagerView'] }}
				validSubscriptionsAny={['Data Manager']}
			/>
			<BtAuthRoute
				exact
				path="/ApiManager/Edit/:uuid"
				component={ApiManagerEditor}
				requiredPermissionsAll={{ dataManager: ['ApiManagerEdit'] }}
				validSubscriptionsAny={['Data Manager']}
			/>

			{/* Device views */}
			<BtAuthRoute
				exact
				path="/Devices"
				validSubscriptionsAny={['IoT']}
				requiredPermissionsAll={{ devices: ['DeviceList'] }}
				component={Devices}
			/>
			<BtAuthRoute
				exact
				path="/Device/:id"
				component={DeviceView}
				validSubscriptionsAny={['IoT']}
			/>

			{/* Exports views */}
			<Route exact path="/Exports" component={Exports} />
			<Route exact path="/ReporterEdit/:uuid" component={ReporterEdit} />

			{/* File views */}
			<BtAuthRoute
				exact
				path="/AddFirmware"
				component={AddFirmware}
				validSubscriptionsAny={['IoT']}
			/>
			<BtAuthRoute
				exact
				path="/File/NewRevision/:id"
				component={FirmwareNewRev}
				validSubscriptionsAny={['IoT']}
			/>
			<BtAuthRoute
				exact
				path="/Files"
				component={FilesList}
				validSubscriptionsAny={['IoT']}
			/>
			<BtAuthRoute
				exact
				path="/Files/:id"
				component={FileView}
				validSubscriptionsAny={['IoT']}
			/>

			{/* Fleet Admin views */}
			<BtAuthRoute
				exact
				path="/FleetAdmin"
				component={FleetAdmin}
				validSubscriptionsAny={['IoT']}
			/>

			{/* Insights views */}
			<BtAuthRoute
				exact
				path="/Insights"
				component={Insights}
				validSubscriptionsAny={['Insights Viewer']}
			/>
			<BtAuthRoute
				exact
				path="/InsightCollections"
				component={InsightCollections}
				requiredPermissionsAll={{ insights: ['InsightsList'] }}
				validSubscriptionsAny={['Insights Viewer']}
			/>
			<BtAuthRoute
				exact
				path="/InsightCollection/:id"
				// component={InsightCollection}
				component={InsightCollection}
				requiredPermissionsAll={{ insights: ['InsightsView'] }}
				validSubscriptionsAny={['Insights Viewer']}
			/>
			<BtAuthRoute
				exact
				path="/InsightCollection/:id/View/:version"
				component={InsightCollection}
				requiredPermissionsAll={{ insights: ['InsightsView'] }}
				validSubscriptionsAny={['Insights Viewer']}
			/>
			{/* <BtAuthRoute
				// exact
				path="/InsightCollection/:id/Edit"
				component={InsightCollectionEditRoute}
				requiredPermissionsAll={
					recordManagerRequiredPermissionsAll[INSIGHT_COLLECTION]
				}
				validSubscriptionsAny={['Insights Editor']}
			/> */}
			<BtAuthRoute
				exact
				path="/InsightCollection/:id/Edit/Collection"
				component={InsightCollectionEditRoute}
				requiredPermissionsAll={
					recordManagerRequiredPermissionsAll[INSIGHT_COLLECTION]
				}
				validSubscriptionsAny={['Insights Editor']}
			/>
			<BtAuthRoute
				exact
				path="/InsightCollection/:id/Edit/Pages"
				component={InsightCollection}
				requiredPermissionsAll={{ insights: ['InsightsEdit'] }}
				validSubscriptionsAny={['Insights Editor']}
			/>
			<BtAuthRoute
				exact
				path="/InsightCollection/:id/Edit/DataSources"
				component={InsightCollectionDataSources}
				requiredPermissionsAll={{ insights: ['InsightsEdit'] }}
				validSubscriptionsAny={['Insights Editor']}
			/>
			<BtAuthRoute
				exact
				path="/InsightCollection/:id/Edit/DataSources/:dataSourceId"
				component={InsightCollectionDataSourceEdit}
				requiredPermissionsAll={{ insights: ['InsightsEdit'] }}
				validSubscriptionsAny={['Insights Editor']}
			/>
			{/*<BtAuthRoute
				exact
				path="/InsightCollection/:id/Edit/Tile"
				component={InsightCollectionEditRoute}
				requiredPermissionsAll={{ insights: ['InsightsEdit'] }}
				validSubscriptionsAny={['Insights Editor']}
			/> */}
			<BtAuthRoute
				exact
				path="/InsightCollection/:id/Settings"
				// component={InsightCollectionSettings}
				component={InsightCollectionSettingsRoute}
				requiredPermissionsAll={
					recordManagerRequiredPermissionsAll[INSIGHT_COLLECTION]
				}
				// requiredPermissionsAll={{ insights: ['InsightsEdit'] }}
				validSubscriptionsAny={['Insights Editor']}
			/>
			<BtAuthRoute
				exact
				path="/Alerts"
				component={Alerts}
				requiredPermissionsAll={{ insights: ['InsightsAlerts'] }}
				validSubscriptionsAny={['Insights Alerts']}
			/>
			<BtAuthRoute
				exact
				path="/InsightExports"
				component={InsightExports}
				requiredPermissionsAll={{ insights: ['InsightsExportsView'] }}
				validSubscriptionsAny={['Insights Exports']}
			/>

			{/* IoT views */}
			<BtAuthRoute
				exact
				path="/IoT"
				component={IoT}
				validSubscriptionsAny={['IoT']}
			/>

			{/* User views */}
			<Route exact path="/UserSettings" component={UserSettings} />
			<Route exact path="/UpdateDetails" component={UpdateDetails} />
			<Route exact path="/Security" component={Security} />
			<Route
				exact
				path="/PlatformSettings"
				component={PlatformSettings}
			/>
			<Route
				exact
				path="/UserSubscriptions"
				component={UserSubscriptions}
			/>
			<Route exact path="/UserPermissions" component={UserPermissions} />
			<Route exact path="/About" component={About} />

			{/* Workflows */}
			<Route exact path="/Workflows" component={Workflows} />
			<BtAuthRoute
				exact
				path="/Workflows/Templates"
				component={WorkflowTemplates}
				validSubscriptionsAny={['Workflow User', 'Workflow Editor']}
			/>
			{/* <BtAuthRoute
				exact
				path="/Workflows/Templates/:id"
				component={WorkflowTemplate}
				validSubscriptionsAny={['Workflow Editor']}
			/> */}
			{/* <BtAuthRoute
				exact
				path="/Workflows/Templates/:id/View/:version"
				component={WorkflowTemplateView}
				validSubscriptionsAny={['Workflow Editor']}
			/> */}
			<BtAuthRoute
				exact
				path="/Workflows/Templates/:id/Edit"
				component={WorkflowTemplateEdit}
				validSubscriptionsAny={['Workflow Editor']}
			/>
			<BtAuthRoute
				exact
				path="/Workflows/Templates/:id/Settings"
				component={WorkflowTemplateEditRoute}
				requiredPermissionsAll={
					recordManagerRequiredPermissionsAll[WORKFLOW_TEMPLATE]
				}
				validSubscriptionsAny={['Workflow Editor']}
			/>
			<BtAuthRoute
				exact
				path="/Workflows/Submissions"
				component={WorkflowSubmissions}
				validSubscriptionsAny={['Workflow User']}
			/>
			<BtAuthRoute
				exact
				path="/Workflows/Submissions/:id"
				component={WorkflowSubmission}
				validSubscriptionsAny={['Workflow User']}
			/>

			{/* Platform Admin Views */}
			<BtAuthRoute
				exact
				path="/BeaconAdmin"
				component={BeaconAdmin}
				validSubscriptionsAny={['Platform Admin']}
			/>

			<BtAuthRoute
				exact
				path="/BeaconAdmin/Organisations"
				component={BeaconAdminOrganisations}
				requiredPermissionsAll={{ platform: ['OrgView'] }}
				validSubscriptionsAny={['Platform Admin']}
			/>
			<BtAuthRoute
				exact
				path="/BeaconAdmin/Organisation/:uuid"
				component={BeaconAdminOrganisation}
				requiredPermissionsAll={{ platform: ['OrgView'] }}
				validSubscriptionsAny={['Platform Admin']}
			/>
			<BtAuthRoute
				exact
				path="/BeaconAdmin/Organisation/:org_uuid/UserEdit/:user_uuid"
				component={OrganisationUserEditor}
				requiredPermissionsAll={{ platform: ['OrgView'] }}
				validSubscriptionsAny={['Platform Admin']}
			/>
			<BtAuthRoute
				exact
				path="/BeaconAdmin/Users"
				component={BeaconAdminUsers}
				requiredPermissionsAll={{ platform: ['UserView'] }}
				validSubscriptionsAny={['Platform Admin']}
			/>

			{/* Utils */}
			{process.env.NODE_ENV === 'development' &&
				devRoutes.map(route => (
					<Route
						key={route.path}
						exact
						path={route.path}
						component={route.component}
					/>
				))}
			<Route
				exact
				path="/Forbidden"
				render={props => (
					<BtError
						variant="forbidden"
						action={() => history.push('/')}
						actionIcon={<HomeIcon />}
						actionLabel="Go To Home"
						{...props}
					/>
				)}
			/>
			<Route path="*">
				<BtError
					variant="notfound"
					title="Page Not Found"
					description="Sorry, we couldn't find the page you asked for."
					action={() => history.push('/')}
					actionIcon={<HomeIcon />}
					actionLabel="Go To Home"
				/>
			</Route>
		</Switch>
	);
}
