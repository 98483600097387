import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';

import AddIcon from '@mui/icons-material/AddCircle';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ViewIcon from '@mui/icons-material/Visibility';

import {
	Button,
	CircularProgress,
	IconButton,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	styled,
	Tooltip,
	Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import EditIcon from '@mui/icons-material/Edit';

import {
	a11yProps,
	BtTab,
	BtTabBar,
	BtTabPanel,
	BtTabView,
} from '../../../../components/generic/BtTabView';
import BtChangeNameDescription from '../../../../components/generic/BtChangeNameDescription';
import BtConfirmDialog from '../../../../components/generic/BtConfirmDialog';
import BtError from '../../../../components/generic/BtError';
import BtFormContainer from '../../../../components/generic/forms/BtFormContainer';
import BtLoading from '../../../../components/generic/BtLoading';
import BtPopover from '../../../../components/generic/BtPopover';
import BtRedactor from '../../../../components/BtRedactor';
import BtTitleHeader from '../../../../components/generic/BtTitleHeader';
import DataSetConfig from './DataSetConfig';
import DataSetCsvUploadDialog from './DataSetCsvUploadDialog';
import { dataSetGet, dataSetStoreDelete, dataSetUpdate } from '../../../../API';
import DataSetList from './record-list/DataSetList';
import DataSetQuery from './DataSetQuery';
import data_manager_breadcrumbs from '../../DataManagerBreadcrumbs';
import data_manager_nav_item from '../../DataManagerNavItem';
import data_manager_options from '../../DataManagerOptions';
import { IN_DEPTH_CHIPS, TOP_LEVEL_PREVIEW } from '../../utils/constants';
import useAvailableToUser from '../../../../hooks/useAvailableToUser';
import {
	useDataSetContext,
	withDataSetContext,
} from '../../hocs/withDataSetContext';
import useFetch from '../../../../hooks/useFetch';
import { useNavContext } from '../../../../context/ContextManager';

const CenterItems = styled('div')(() => ({
	alignItems: 'center',
	display: 'flex',
}));

const DataHeader = styled('div')(() => ({
	alignItems: 'center',
	display: 'flex',
	justifyContent: 'space-between',
}));

const DATA_SET_VIEW_MODE_STORAGE_KEY = 'dataSetViewMode';

export const DataSet = withDataSetContext(({ match }) => {
	const listRef = useRef();

	const checkAvailability = useAvailableToUser();
	const { editData } = useDataSetContext();
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const { setBreadcrumbs, setContextualNav } = useNavContext();

	const updateDataSet = useFetch(dataSetUpdate).request;

	const [confirmDeleteAllOpen, setConfirmDeleteAllOpen] = useState(false);
	const [csvUploadDialogOpen, setCsvUploadDialogOpen] = useState(false);
	const [currentTab, setCurrentTab] = useState(0);
	const [dataSet, setDataSet] = useState();
	const [editingConfig, setEditingConfig] = useState(false);
	const [refreshing, setRefreshing] = useState(false);
	const [showNameDescModal, setShowNameDescModal] = useState(false);
	const [updating, setUpdating] = useState(false);
	const [viewMode, setViewMode] = useState(
		localStorage.getItem(DATA_SET_VIEW_MODE_STORAGE_KEY) ??
			TOP_LEVEL_PREVIEW
	);
	const [vmPopoverAnchor, setVmPopoverAnchor] = useState();

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				...data_manager_breadcrumbs,
				{ text: 'Data Sources', link: '/DataSources' },
				{
					text: `Data Set: ${dataSet?.name || 'Loading...'}`,
					link: '',
				},
			]);
		},
		[dataSet, setBreadcrumbs]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([
				...data_manager_nav_item,
				...data_manager_options,
			]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	const dataSetUuid = match.params.uuid;

	const { error: dataSetError, loading, request: dataSetRequest } = useFetch(
		dataSetGet,
		setDataSet
	);

	const refreshDataSet = useCallback(
		() => {
			dataSetRequest({ dataSetUuid });
		},
		[dataSetRequest, dataSetUuid]
	);

	useEffect(
		() => {
			refreshDataSet();
		},
		[refreshDataSet]
	);

	const handleCsvDialogClose = () => {
		setCsvUploadDialogOpen(false);
	};

	const canViewRecords = useMemo(
		() =>
			checkAvailability({
				requiredPermissionsAll: {
					dataManager: ['DatasetStoreView'],
				},
			}).available,
		[checkAvailability]
	);

	const canEditConfig = useMemo(
		() =>
			checkAvailability({
				requiredPermissionsAll: {
					dataManager: ['DatasetEdit'],
				},
			}).available,
		[checkAvailability]
	);

	const refreshPage = useCallback(
		() => {
			if (!canViewRecords) return;

			setRefreshing(true);

			listRef.current.refreshPage(() => setRefreshing(false));
		},
		[canViewRecords]
	);

	const handleDeleteAll = useCallback(
		async () => {
			try {
				await dataSetStoreDelete({
					dataSetUuid: dataSet.uuid,
					recordUuid: 'ALL',
				});

				refreshPage();

				enqueueSnackbar('Deleted all records from data set', {
					variant: 'success',
				});
			} catch (error) {
				enqueueSnackbar('There was an error deleteing records', {
					variant: 'error',
				});
			}
		},
		[dataSet, enqueueSnackbar, refreshPage]
	);

	const handleNameDescChange = useCallback(
		async update => {
			const newDataSet = {
				...dataSet,
				...update,
			};

			setUpdating(true);

			const { error, ok } = await updateDataSet({
				dataSetUuid: dataSet.uuid,
				dataSetUpdate: newDataSet,
			});

			setUpdating(false);

			if (!ok) {
				enqueueSnackbar('Failed to update the Data Set', {
					variant: 'error',
				});

				console.error('Failed to update the Data Set', error);

				return;
			}

			setDataSet(newDataSet);

			enqueueSnackbar('Data Set config successfully updated', {
				variant: 'success',
			});
		},
		[dataSet, enqueueSnackbar, updateDataSet]
	);

	if (dataSetError) {
		return (
			<BtError
				title="Retrieval Error"
				description="An error occurred when attempting to retrieve the Data Set"
			/>
		);
	}

	return (
		<>
			<BtTabView>
				<BtFormContainer
					header={
						<BtTabBar
							currentTab={currentTab}
							onTabChange={(event, selectedTab) =>
								setCurrentTab(selectedTab)
							}
						>
							<BtTab
								disabled={editingConfig || updating}
								label="Data"
								{...a11yProps(0)}
							/>
							{canViewRecords && (
								<BtTab
									label="Query"
									{...a11yProps(1)}
									disabled={
										editData?.isEditing ||
										editingConfig ||
										updating
									}
								/>
							)}
							{canEditConfig && (
								<BtTab
									disabled={editData.isEditing}
									label="Config"
									{...a11yProps(2)}
								/>
							)}
						</BtTabBar>
					}
					maxWidth="lg"
					title={
						<BtTitleHeader>
							<CenterItems>
								<Typography variant="h3">
									{dataSet?.name
										? `Data Set: ${dataSet.name}`
										: 'Loading...'}
								</Typography>
								{canEditConfig && (
									<Tooltip
										className="editNameDescription"
										disableInteractive
										style={{ marginLeft: '0.5em' }}
										title="Edit Name and Description"
									>
										<span>
											<IconButton
												disabled={updating}
												onClick={() =>
													setShowNameDescModal(true)
												}
												onMouseUp={event =>
													event.currentTarget.blur()
												}
											>
												<EditIcon />
											</IconButton>
										</span>
									</Tooltip>
								)}
							</CenterItems>
							{dataSet?.description && (
								<CenterItems style={{ marginBottom: '0.5em' }}>
									<Typography>
										{dataSet?.description}
									</Typography>
								</CenterItems>
							)}
						</BtTitleHeader>
					}
				>
					<BtLoading loading={loading}>
						<BtTabPanel currentTab={currentTab} index={0}>
							<DataHeader>
								<div
									style={{
										alignItems: 'center',
										display: 'flex',
									}}
								>
									<BtRedactor
										requiredPermissionsAll={{
											dataManager: ['DatasetStoreCreate'],
										}}
									>
										<Button
											disabled={editData.isEditing}
											disableElevation
											onClick={() =>
												history.push(
													`/DataSources/DataSet/${dataSetUuid}/CreateNew`
												)
											}
											startIcon={<AddIcon />}
											sx={{ marginRight: '8px' }}
											variant="outlined"
										>
											Add Data Record
										</Button>
									</BtRedactor>
									<BtRedactor
										requiredPermissionsAll={{
											dataManager: ['DatasetStoreDelete'],
										}}
									>
										<Button
											disabled={editData.isEditing}
											disableElevation
											onClick={() => {
												setConfirmDeleteAllOpen(true);
											}}
											startIcon={<DeleteIcon />}
											sx={{ marginRight: '8px' }}
											variant="outlined"
										>
											Delete All Records
										</Button>
									</BtRedactor>
									<BtRedactor
										requiredPermissionsAll={{
											dataManager: ['DatasetStoreUpload'],
										}}
									>
										<Button
											disabled={editData.isEditing}
											disableElevation
											onClick={() => {
												setCsvUploadDialogOpen(true);
											}}
											startIcon={<UploadFileIcon />}
											sx={{ marginRight: '8px' }}
											variant="outlined"
										>
											Upload CSV
										</Button>
									</BtRedactor>
									{canViewRecords && (
										<>
											{!refreshing ? (
												<Tooltip
													disabled={
														editData.isEditing
													}
													disableInteractive
													title="Refresh Page"
												>
													<IconButton
														color="primary"
														onClick={refreshPage}
														size="small"
													>
														<RefreshIcon />
													</IconButton>
												</Tooltip>
											) : (
												<CircularProgress
													size={22}
													style={{ marginLeft: 6 }}
												/>
											)}
										</>
									)}
								</div>
								{canViewRecords && (
									<Button
										disableElevation
										onClick={event =>
											setVmPopoverAnchor(
												event.currentTarget
											)
										}
										startIcon={<ViewIcon />}
										variant="text"
									>
										{viewMode.replace('_', ' ')}
									</Button>
								)}
							</DataHeader>
							{canViewRecords && (
								<DataSetList
									ref={listRef}
									dataSet={dataSet}
									dataSetUuid={dataSetUuid}
									viewMode={viewMode}
								/>
							)}
							{!canViewRecords && (
								<BtError
									fullScreen
									description="You do not have permission to view individual records."
									title="Forbidden"
									variant="forbidden"
								/>
							)}
						</BtTabPanel>
						<BtTabPanel currentTab={currentTab} index={1}>
							<DataSetQuery
								dataSet={dataSet}
								dataSetUuid={dataSetUuid}
							/>
						</BtTabPanel>
						<BtTabPanel currentTab={currentTab} index={2}>
							<DataSetConfig
								dataSet={dataSet}
								disabled={updating}
								editing={editingConfig}
								setDataSet={setDataSet}
								setEditing={setEditingConfig}
							/>
						</BtTabPanel>
					</BtLoading>
				</BtFormContainer>
			</BtTabView>

			<DataSetCsvUploadDialog
				dataSetUuid={dataSetUuid}
				onClose={handleCsvDialogClose}
				open={csvUploadDialogOpen}
				refreshPage={refreshPage}
			/>

			<BtConfirmDialog
				title="Delete All Records"
				action={handleDeleteAll}
				ActionIcon={<DeleteIcon />}
				onClose={() => {
					setConfirmDeleteAllOpen(false);
				}}
				isDestructive={true}
				open={confirmDeleteAllOpen}
				prompt={`Are you sure you wish to delete all records in the data set?`}
				verb="Delete"
				textInputConfirm={dataSet?.name}
			/>

			<BtPopover
				anchorEl={vmPopoverAnchor}
				onClose={() => setVmPopoverAnchor(null)}
				anchorOrigin={{
					horizontal: 'right',
					vertical: 'bottom',
				}}
				transformOrigin={{
					horizontal: 'right',
					vertical: 'top',
				}}
			>
				<List dense>
					{[TOP_LEVEL_PREVIEW, IN_DEPTH_CHIPS].map(viewModeItem => {
						const selected = viewMode === viewModeItem;

						return (
							<ListItemButton
								key={viewModeItem}
								onClick={() => {
									localStorage.setItem(
										DATA_SET_VIEW_MODE_STORAGE_KEY,
										viewModeItem
									);
									setViewMode(viewModeItem);
									setVmPopoverAnchor(null);
								}}
								selected={selected}
							>
								<ListItemIcon style={{ minWidth: 32 }}>
									{selected && <CheckIcon />}
								</ListItemIcon>
								<ListItemText
									primary={viewModeItem.replace('_', ' ')}
								/>
							</ListItemButton>
						);
					})}
				</List>
			</BtPopover>
			<BtChangeNameDescription
				description={dataSet?.description}
				name={dataSet?.name}
				onChange={handleNameDescChange}
				onClose={() => setShowNameDescModal(false)}
				open={showNameDescModal}
			/>
		</>
	);
});

DataSet.displayName = 'DataSet';

DataSet.propTypes = {
	match: PropTypes.object.isRequired,
};

export default DataSet;
