const {
	calculateGeoDistance,
	isGeoDocument,
	isDocument,
} = require('../../utils.js');
const {
	ERROR,
	setOperator,
	evaluateExpression,
	OPERATION_MODE,
} = require('../expression.js');

const operatorKey = '$near';

// TODO - near is technically not allowed in a match stage - so this could
//        lead to a divergent behaviour between the js lib and the actual mongo
//        query. Be aware when using near that it is only available in the find
//        operation and not in a pipeline stage.

setOperator(
	operatorKey,
	/**
	 * @type {ExpressionFunction<any, boolean>}
	 */
	function near(context, args) {
		const arr = !Array.isArray(args) ? [args] : args;

		if (arr.length !== 2) {
			throw new Error(ERROR.INVALID_NUMBER_ARGS(operatorKey, 2, 2));
		}
		const value1 = evaluateExpression(context, arr[0]);

		// we're not evaluating an expression for this one.
		// we're expecting a hard coded object in.
		const props = arr[1];

		if (!isDocument(props)) {
			throw new Error(
				ERROR.INVALID_OPERATOR_ARGUMENT(
					operatorKey,
					'expected properties to be a document object',
					props,
					0
				)
			);
		}

		const value2 = props.$geometry;

		if (!isGeoDocument(value1)) {
			return false;
			// throw new Error(
			// 	ERROR.INVALID_OPERATOR_ARGUMENT(
			// 		operatorKey,
			// 		'geo Point document as input',
			// 		value1,
			// 		0
			// 	)
			// );
		}

		if (!isGeoDocument(value2)) {
			throw new Error(
				ERROR.INVALID_OPERATOR_ARGUMENT(
					operatorKey,
					'geo Point document for $geometry property',
					value2
				)
			);
		}

		let minDistance,
			maxDistance = null;

		if (Object.hasOwnProperty.call(props, '$maxDistance')) {
			if (typeof props.$maxDistance !== 'number') {
				throw new Error(
					ERROR.INVALID_OPERATOR_ARGUMENT(
						operatorKey,
						'$maxDistance to be a number if specified',
						props.$maxDistance
					)
				);
			}
			maxDistance = props.$maxDistance;
		}
		if (Object.hasOwnProperty.call(props, '$minDistance')) {
			if (typeof props.$minDistance !== 'number') {
				throw new Error(
					ERROR.INVALID_OPERATOR_ARGUMENT(
						operatorKey,
						'$minDistance to be a number if specified',
						props.$minDistance
					)
				);
			}
			minDistance = props.$minDistance;
		}

		const distance = calculateGeoDistance(
			value1.coordinates,
			value2.coordinates
		);

		if (minDistance !== null && distance < minDistance) {
			return false;
		}

		if (maxDistance !== null && distance > maxDistance) {
			return false;
		}

		return true;
	},
	[OPERATION_MODE.QUERY]
);
