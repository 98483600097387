import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import _ from 'lodash';

import { Prompt } from 'react-router';

import {
	a11yProps,
	BtTab,
	BtTabBar,
	BtTabPanel,
} from '../../../components/generic/BtTabView';

import { BtFormActionsContainer } from '../../../components/generic/forms';

import {
	Typography,
	Container,
	Button,
	TextField,
	Select,
	MenuItem,
	InputLabel,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Paper,
} from '@mui/material';

import SaveIcon from '@mui/icons-material/SaveAlt';

import useFetch from '../../../hooks/useFetch';
import {
	workflowTemplateGetDraft,
	workflowTemplateDraftUpdate,
} from '../../../API';
import BtLoading from '../../../components/generic/BtLoading';
import WorkflowTemplatePages from './WorkflowTemplatePages';
import WorkflowTemplateFlow from './WorkflowTemplateStates';
import WorkflowTemplateDataSources from './WorkflowTemplateDataSources';
// import DataConfig from '../../DataManager/DataConfig/DataConfig';
import BtDataConfigNodeSelect from '../../../components/generic/DataConfigNodeSelect/BtDataConfigNodeSelect';
import DataConfig from '../../../components/generic/DataConfig/DataConfig';

export default function WorkflowTemplateEdit({ match }) {
	const history = useHistory();
	const templateUuid = match.params.id;
	const [currentTab, setCurrentTab] = useState(0);
	const [updated, setUpdated] = useState(false);
	const [pagesUpdate, setPagesUpdate] = useState([]);
	const [flowUpdate, setFlowUpdate] = useState([]);
	const [dataSourcesUpdate, setDataSourcesUpdate] = useState([]);
	const { enqueueSnackbar } = useSnackbar();
	const [templateDetails, setTemplateDetails] = useState({
		initial_state: '',
		user_creatable: true,
		disableSummary: true,
		repeatable: true,
		availableOffline: true,
	});
	const [outputSchema, setOutputSchema] = useState({
		type: 'object',
		objectContent: {},
	});
	const [selectedNode, setSelectedNode] = useState();

	const {
		loading: templateLoading,
		data: template,
		error,
		request: getTemplateDetails,
	} = useFetch(workflowTemplateGetDraft);

	useEffect(
		() => {
			getTemplateDetails({
				workflowTemplateUuid: templateUuid,
			});
		},
		[getTemplateDetails, templateUuid]
	);

	useEffect(
		() => {
			if (template) {
				var rootPage = { uuid: '' };
				if (template.draft.pages?.length) {
					rootPage = _.find(template.draft.pages, {
						isRoot: true,
					});
				}

				try {
					setTemplateDetails({
						rootPage: rootPage.uuid,
						initial_state: template.draft?.initial_state,
						user_creatable: template.draft?.user_creatable,
						disableSummary: template.draft?.disableSummary,
						repeatable: template.draft?.repeatable,
						availableOffline: template.draft?.availableOffline,
					});
					setPagesUpdate(template.draft?.pages ?? []);
					setFlowUpdate(template.draft?.flow ?? []);
					setDataSourcesUpdate(template.draft?.dataSources ?? []);

					updateOutputSchema(template.draft?.pages ?? []);
				} catch (error) {
					console.log(error);
				}
			}
		},
		[template]
	);

	const getObjectSchema = (object, pages) => {
		const objectSchema = {};

		(object || []).forEach(component => {
			objectSchema[component.uuid] = getComponentSchema(component, pages);
		});

		return objectSchema;
	};

	const getComponentSchema = (component, pages) => {
		if (component.type === 'Page') {
			console.log(component);
			const page = pages.find(page => {
				return component.pageUuid === page.uuid;
			});

			if (!page) {
				return {
					type: 'object',
					label: component.name,
					objectContent: {},
				};
			}

			console.log('nested page', page);

			return {
				type: 'object',
				label: component.name,
				objectContent: getObjectSchema(page.children),
			};
		}

		switch (component.type) {
			case 'TextField':
				return { type: 'string', label: component.name };
			case 'NumberField':
				return { type: 'number', label: component.name };
			case 'Select':
				return { type: 'string', label: component.name };
			// case 'Page':
			// 	return {
			// 		type: 'object',
			// 		objectContent: getObjectSchema(
			// 			pages.find(page => {
			// 				return component.pageUuid === page.uuid;
			// 			}).children
			// 		),
			// 	};
			case 'Section':
				return {
					type: 'object',
					label: component.name || '',
					objectContent: getObjectSchema(component.children),
				};
			case 'Checkbox':
				return { type: 'boolean', label: component.name };
			case 'Image':
				return { type: 'string', label: component.name };
			case 'Signature':
				return { type: 'string', label: component.name };
			case 'Barcode':
				return { type: 'string', label: component.name };
			case 'Date':
				return { type: 'string', label: component.name };
			case 'DateTime':
				return { type: 'string', label: component.name };
			case 'Time':
				return { type: 'string', label: component.name };

			default:
				return { type: 'string', label: component.name };
		}
	};

	const updateOutputSchema = pages => {
		var newSchema = { type: 'object', objectContent: {} };

		const rootPage = pages.find(page => {
			return page.isRoot;
		});

		newSchema.objectContent = getObjectSchema(rootPage.children, pages);

		setOutputSchema(newSchema);
	};

	const handlePagesUpdate = pages => {
		updateOutputSchema(pages);

		setPagesUpdate(pages);
		setUpdated(true);
	};

	const handleStatesUpdate = states => {
		setFlowUpdate(states);
		setUpdated(true);
	};

	const handleDataSourcesUpdate = dataSources => {
		setDataSourcesUpdate(dataSources);
		setUpdated(true);
	};

	const handleInitisalStateChange = event => {
		const newInitialState = event.target.value;
		setTemplateDetails({
			...templateDetails,
			initial_state: newInitialState,
		});
		setUpdated(true);
	};

	const handleUserCreatableChange = event => {
		const userCreatable = event.target.checked;
		setTemplateDetails({
			...templateDetails,
			user_creatable: userCreatable,
		});
		setUpdated(true);
	};

	const handleDisableSummeryChange = event => {
		const disableSummary = event.target.checked;
		setTemplateDetails({
			...templateDetails,
			disableSummary: disableSummary,
		});
		setUpdated(true);
	};

	const handleRepeatableChange = event => {
		const repeatable = event.target.checked;
		setTemplateDetails({
			...templateDetails,
			repeatable: repeatable,
		});
		setUpdated(true);
	};

	const handleAvailableOfflineChange = event => {
		const availableOffline = event.target.checked;
		setTemplateDetails({
			...templateDetails,
			availableOffline: availableOffline,
		});
		setUpdated(true);
	};

	const handleRootPageChange = event => {
		const newRootUuid = event.target.value;
		setTemplateDetails({
			...templateDetails,
			rootPage: event.target.value,
		});

		setPagesUpdate(
			pagesUpdate.map(page => {
				page.isRoot = page.uuid === newRootUuid;
				return page;
			})
		);

		setUpdated(true);
	};

	const handleSave = async () => {
		try {
			// TODO: Add schema validation for the below
			let templateUpdate = {
				initial_state: templateDetails.initial_state,
				user_creatable: templateDetails.user_creatable,
				disableSummary: templateDetails.disableSummary,
				repeatable: templateDetails.repeatable,
				availableOffline: templateDetails.availableOffline,
				pages: pagesUpdate,
				flow: flowUpdate,
				dataSources: dataSourcesUpdate,
			};

			console.log(templateUpdate);

			await workflowTemplateDraftUpdate({
				workflowTemplateUuid: templateUuid,
				templateUpdate: templateUpdate,
			});

			setUpdated(false);
			enqueueSnackbar('Draft Template Updated', {
				variant: 'success',
			});

			history.push(`/Workflows/Templates/${templateUuid}/settings`);
		} catch (error) {
			console.log(error);
			enqueueSnackbar('Error Updating Draft Template', {
				variant: 'error',
			});
		}
	};

	return (
		<>
			<BtLoading loading={templateLoading}>
				{template && (
					<Container maxWidth="lg">
						<Container maxWidth="sm">
							<Paper style={{ padding: 16 }}>
								<Typography variant="h3">
									{template.name} - {template.description}
								</Typography>

								<InputLabel id="isRootId">Root Page</InputLabel>
								<Select
									name="rootPage"
									label="Input Type"
									variant="standard"
									value={templateDetails.rootPage}
									onChange={handleRootPageChange}
									labelId="isRootId"
									sx={{ width: '100%' }}
								>
									{(pagesUpdate || []).map((page, index) => (
										<MenuItem value={page.uuid} key={index}>
											{page.name}
										</MenuItem>
									))}
								</Select>

								<InputLabel id="initialStateId">
									Initial State
								</InputLabel>
								<Select
									name="initialState"
									label="Initial State"
									variant="standard"
									value={templateDetails.initial_state}
									onChange={handleInitisalStateChange}
									labelId="initialStateId"
									sx={{ width: '100%' }}
								>
									{(flowUpdate || []).map((flow, index) => (
										<MenuItem
											value={flow.state}
											key={index}
										>
											{flow.state}
										</MenuItem>
									))}
								</Select>

								<FormGroup>
									<FormControlLabel
										control={
											<Checkbox
												checked={
													templateDetails.user_creatable
												}
												onChange={
													handleUserCreatableChange
												}
											/>
										}
										label="Creatable by User"
									/>

									<FormControlLabel
										control={
											<Checkbox
												checked={
													templateDetails.disableSummary
												}
												onChange={
													handleDisableSummeryChange
												}
											/>
										}
										label="Summery View Disabled"
									/>

									<FormControlLabel
										control={
											<Checkbox
												checked={
													templateDetails.repeatable
												}
												onChange={
													handleRepeatableChange
												}
											/>
										}
										label="Repeatable"
									/>

									<FormControlLabel
										control={
											<Checkbox
												checked={
													templateDetails.availableOffline
												}
												onChange={
													handleAvailableOfflineChange
												}
											/>
										}
										label="Available Offline"
									/>
								</FormGroup>
							</Paper>
						</Container>

						<BtTabBar
							currentTab={currentTab}
							onTabChange={(event, selectedTab) =>
								setCurrentTab(selectedTab)
							}
							style={{
								transform: 'translateY(-1px)',
								marginTop: 10,
							}}
						>
							<BtTab label="Pages" {...a11yProps(0)} />
							<BtTab label="States" {...a11yProps(1)} />
							<BtTab label="Data Sources" {...a11yProps(2)} />
							<BtTab label="Output Schema" {...a11yProps(3)} />
							<BtTab label="Node Select Test" {...a11yProps(4)} />
						</BtTabBar>

						<BtTabPanel currentTab={currentTab} index={0}>
							<WorkflowTemplatePages
								template={template}
								pages={pagesUpdate}
								pagesUpdate={handlePagesUpdate}
								templateDataSources={dataSourcesUpdate}
								outputSchema={outputSchema}
							/>
						</BtTabPanel>

						<BtTabPanel currentTab={currentTab} index={1}>
							<WorkflowTemplateFlow
								template={template}
								states={flowUpdate}
								statesUpdate={handleStatesUpdate}
							/>
						</BtTabPanel>

						<BtTabPanel currentTab={currentTab} index={2}>
							<WorkflowTemplateDataSources
								template={template}
								dataSources={dataSourcesUpdate}
								dataSourcesUpdate={handleDataSourcesUpdate}
							/>
						</BtTabPanel>

						<BtTabPanel currentTab={currentTab} index={3}>
							<DataConfig readOnly={true} config={outputSchema} />
						</BtTabPanel>

						<BtTabPanel currentTab={currentTab} index={4}>
							<BtDataConfigNodeSelect
								schema={outputSchema}
								onChange={value => {
									setSelectedNode(value);
								}}
								value={selectedNode}
							/>
							{selectedNode}
						</BtTabPanel>

						<hr />

						<BtFormActionsContainer>
							<Button
								sx={{ margin: '1rem' }}
								variant="contained"
								startIcon={<SaveIcon />}
								onClick={handleSave}
								disabled={!updated}
							>
								Save Draft Template
							</Button>
						</BtFormActionsContainer>

						<Prompt
							when={updated}
							message="Leaving will discard changes to the template."
						/>
					</Container>
				)}
			</BtLoading>
		</>
	);
}
