import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useNavContext } from '../../context/ContextManager';
import { useAppContext } from '../../context/ContextManager';

// STYLES
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';

// COMPONENTS
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';

// ICONS
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

// CUSTOM COMPONENTS
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from '../../components/generic/CustomAccordion';
import {
	ListTable,
	ListRow,
	ListHeader,
	ListCell,
} from '../../components/generic/ListTable';

// Data for landing page selection table and contextual navigation
import IoT_options from './IoTOptions';

import IoT_breadcrumbs from './IoTBreadcrumbs';

import IoT_nav_item from './IoTNavItem';

const styles = makeStyles(theme => ({
	button_row: {},
	root: {
		width: '100%',
		minWidth: 200,
	},
	config_paper: {
		padding: 10,
	},
	expansion_panel: {},
	expansion_panel_summery: {
		backgroundColor: theme.palette.primary.shade_light,
		borderRadius: 8,
		border: '1px solid rgba(0, 0, 0, .25)',
	},
	heading: {
		fontSize: '1.5rem',
		flexBasis: '20%',
		flexShrink: 0,
		fontWeight: 500,
		color: theme.palette.text.secondary,
	},
	secondaryHeading: {
		fontSize: '1.5rem',
		flexGrow: 1,
		fontWeight: 500,
		color: theme.palette.text.secondary,
	},
	thirdHeading: {
		fontSize: '1.5rem',
		flexBasis: '30%',
		flexShrink: 0,
		fontWeight: 500,
		color: theme.palette.text.secondary,
	},
	config_panel: {
		display: 'block',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

export default function FileView({ match }) {
	const classes = styles();
	const [expanded, setExpanded] = useState(false);
	const [fileHistory, setFileHistory] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	//const [commsActive, setCommsActive] = useState(false);
	const history = useHistory();
	const { setContextualNav } = useNavContext();
	const { setBreadcrumbs } = useNavContext();
	const { appOrg } = useAppContext();

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				...IoT_breadcrumbs,
				{ text: 'Files', link: '/Files' },
				{ text: fileHistory.filename, link: '' },
			]);
		},
		[fileHistory.filename, setBreadcrumbs]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([...IoT_nav_item, ...IoT_options]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	// replaced with let deceleration below
	// var resource_uuid = match.params.id;
	let resource_uuid = match.params.id;

	const handleClickAddFileRevOpen = resource_uuid => event => {
		history.push('/File/NewRevision/' + resource_uuid);
	};
	const handleChange = panel => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};
	const handle_delete_file = uuid => event => {
		alert('Deleting Task UUID: ' + uuid);
		/*var req_url = process.env.REACT_APP_API_SERVER_URL + `/v1/config/` + uuid;
        console.log(req_url);
        fetch(
          req_url,
          {
			method: "DELETE",
          }
        )
        .then(res => res.json())
        .then(response => {
			console.log(response)
			if(response['result'] !== "OK") {
				alert("Internal server error.");
				setCommsActive(false);
			} else {
				alert("Configuration Deleted.");
				setCommsActive(false);
				//history.goBack();
			}
        })
        .catch(error => {
			console.log(error)
			alert("Failed to connect to the server.");
			setCommsActive(false);
		});*/
	};

	useEffect(
		() => {
			var req_url =
				process.env.REACT_APP_API_SERVER_URL +
				`/v1/file/history/` +
				resource_uuid;
			Auth.currentSession().then(user_session => {
				let user_token = user_session.getAccessToken();
				fetch(req_url, {
					method: 'GET',
					headers: {
						Authorization: user_token.jwtToken,
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Organisation: appOrg.uuid,
					},
				})
					.then(res => res.json())
					.then(response => {
						console.log(response);
						setFileHistory(response.file_data);
						setIsLoading(false);
					})
					.catch(error => {
						console.log(error);
						setIsLoading(false);
					});
			});
		},
		[resource_uuid]
	);

	return (
		<>
			{isLoading && (
				<Paper>
					<p>Loading...</p>
					<CircularProgress size={'120px'} />
				</Paper>
			)}

			{!isLoading && (
				<Container maxWidth="lg">
					<div className={classes.root}>
						<h1>File History: {fileHistory.filename}</h1>
						<Container>
							<ListTable>
								<ListRow>
									<ListHeader>File Name:</ListHeader>
									<ListCell>{fileHistory.filename}</ListCell>
								</ListRow>
								<ListRow>
									<ListHeader>Device Type:</ListHeader>
									<ListCell>
										{fileHistory.device_type}
									</ListCell>
								</ListRow>
								<ListRow>
									<ListHeader>File Type:</ListHeader>
									<ListCell>{fileHistory.file_type}</ListCell>
								</ListRow>
								<ListRow>
									<ListHeader>Description:</ListHeader>
									<ListCell>
										{fileHistory.description}
									</ListCell>
								</ListRow>
								<ListRow>
									<ListHeader>Resource UUID:</ListHeader>
									<ListCell>
										{fileHistory.resource_uuid}
									</ListCell>
								</ListRow>
							</ListTable>
						</Container>

						<Divider />

						<Container className={classes.button_row}>
							<Button
								variant="outlined"
								startIcon={<AddCircleIcon />}
								onClick={handleClickAddFileRevOpen(
									resource_uuid
								)}
							>
								Add New Revision
							</Button>
						</Container>

						{fileHistory.versions.map((c, index) => (
							<Accordion
								expanded={expanded === 'panel' + index}
								onChange={handleChange('panel' + index)}
							>
								<AccordionSummary
									aria-controls="panel2bh-content"
									id="panel2bh-header"
								>
									<Typography className={classes.heading}>
										{fileHistory.filename}:{c.version_str}
									</Typography>
									<Typography
										className={classes.secondaryHeading}
									>
										{c.notes}
									</Typography>
									<Typography
										className={classes.thirdHeading}
									>
										Last Updated:{' '}
										{new Date(
											c.create_timestamp
										).toLocaleString()}
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									{fileHistory.filename && (
										<>
											<ListTable>
												<ListRow>
													<ListHeader>
														Version:
													</ListHeader>
													<ListCell>
														{c.version_str}
													</ListCell>
												</ListRow>
												<ListRow>
													<ListHeader>
														UUID:
													</ListHeader>
													<ListCell>
														{c.file_uuid}
													</ListCell>
												</ListRow>
												<ListRow>
													<ListHeader>
														Last Updated:
													</ListHeader>
													<ListCell>
														{new Date(
															c.create_timestamp
														).toLocaleString()}
													</ListCell>
												</ListRow>
												<ListRow>
													<ListHeader>
														Size:
													</ListHeader>
													<ListCell>
														{c.size} Bytes
													</ListCell>
												</ListRow>
												<ListRow>
													<ListHeader>
														Notes:
													</ListHeader>
													<ListCell>
														{c.notes}
													</ListCell>
												</ListRow>
											</ListTable>
											<Divider />
											<Button
												variant="outlined"
												startIcon={
													<DeleteOutlineIcon />
												}
												value={c.uuid}
												onClick={handle_delete_file(
													c.uuid
												)}
											>
												Delete Revision
											</Button>
										</>
									)}
								</AccordionDetails>
							</Accordion>
						))}
					</div>
				</Container>
			)}
		</>
	);
}
