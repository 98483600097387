import React from 'react';
import PropTypes from 'prop-types';

import {
	DialogActions,
	DialogContent,
	DialogTitle,
	MenuList,
	MenuItem,
	DialogContentText,
} from '@mui/material';

export const BtPopperMenu = ({ content }) => {
	return (
		<MenuList>
			{content.map(item => (
				<MenuItem key={item.key} onClick={item.onClick}>
					{item.component && item.component}
					{!item.component && <>{item.label}</>}
				</MenuItem>
			))}
		</MenuList>
	);
};

export const BtPopperExtendedTitle = ({ title }) => {
	return <DialogTitle>{title}</DialogTitle>;
};

export const BtPopperExtendedSubTitle = ({ subtitle }) => {
	return typeof subtitle === 'string' ? (
		<DialogContentText sx={{ paddingBottom: '5px' }}>
			{subtitle}
		</DialogContentText>
	) : (
		subtitle
	);
};

export const BtPopperExtendedContent = ({ children, style }) => {
	return <DialogContent sx={{ ...style }}>{children}</DialogContent>;
};

export const BtPopperExtendedActions = ({
	children,
	centerActions,
	...other
}) => {
	return (
		<DialogActions
			sx={{
				display: centerActions && 'flex',
				justifyContent: centerActions && 'center',
			}}
			{...other}
		>
			{children}
		</DialogActions>
	);
};

BtPopperMenu.propTypes = {
	content: PropTypes.arrayOf(PropTypes.object).isRequired,
};

BtPopperExtendedTitle.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

BtPopperExtendedSubTitle.propTypes = {
	subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

BtPopperExtendedContent.propTypes = {
	children: PropTypes.node.isRequired,
};

BtPopperExtendedActions.propTypes = {
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
		.isRequired,
	centerActions: PropTypes.bool,
};
