import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Button, Divider, Tooltip } from '@mui/material';
import { styled } from '@mui/styles';

import { tileVariants, resolveIcon, tileLabels } from '../../tileConstants';
import { ScreensContext } from '../../ScreensContext';
import useAvailableToUser from '../../../../../hooks/useAvailableToUser';
import { tileSubscriptionsPermissions } from '../../tileConstants';

const NewTileContainer = styled('div')(({ theme, style, disabled }) => ({
	backgroundColor: theme.palette.primary.main,
	// height: 47.5,
	// width: 47.5,
	height: 65,
	width: 65,
	borderRadius: 15,
	marginBottom: 5,
	padding: 5,
	'&:hover': {
		backgroundColor: disabled
			? theme.palette.primary.main
			: theme.palette.primary.dark,
	},
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	...style,
}));

export const AddTileControls = ({ onDragStart, onNativeChange }) => {
	const { nativeEditMode, screens, currentScreen } = useContext(
		ScreensContext
	);

	const checkAvailability = useAvailableToUser();

	const permittedTileVariants = useMemo(
		() => {
			const result = {};
			Object.keys(tileVariants).forEach((variant, index) => {
				if (
					checkAvailability({
						requiredPermissionsAll:
							tileSubscriptionsPermissions[variant]
								.permissionsAll || {},
						requiredPermissionsAny:
							tileSubscriptionsPermissions[variant]
								.permissionsAny || {},
						validSubscriptionsAny:
							tileSubscriptionsPermissions[variant]
								.subscriptionsAny || [],
					}).available
				) {
					result[variant] = tileVariants[variant];
				}
			});
			// console.log({ result });
			return result;
		},
		[checkAvailability]
	);

	return (
		<>
			{_.chunk(Object.keys(permittedTileVariants), 1).map(
				(chunk, index) => (
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-start',
							width: '100%',
						}}
						key={index}
					>
						{chunk.map((variant, index) => (
							<Tooltip
								// arrow
								title={tileLabels[variant]}
								key={variant}
							>
								{nativeEditMode ? (
									<NewTileContainer
										key={variant}
										disabled={[
											'insight_visualisation',
											'insight_collection',
										].includes(variant)}
									>
										<Button
											onClick={() =>
												onNativeChange({
													variantToAdd: variant,
													mode: 'addTile',
													currentScreenData:
														screens[currentScreen],
												})
											}
											disabled={[
												'insight_visualisation',
												'insight_collection',
											].includes(variant)}
										>
											{resolveIcon({
												type: variant,
												disabled: [
													'insight_visualisation',
													'insight_collection',
													'information',
												].includes(variant),
												// disabled: true,
											})}
										</Button>
									</NewTileContainer>
								) : (
									<NewTileContainer
										key={variant}
										draggable={true}
										onDragStart={onDragStart(variant)}
									>
										{resolveIcon({
											type: variant,
										})}
									</NewTileContainer>
								)}
							</Tooltip>
						))}
					</div>
				)
			)}
			<Divider
				style={{
					width: '100%',
					marginBottom: 5,
					borderColor: 'white',
				}}
				orientation="horizontal"
			/>
		</>
	);
};

AddTileControls.propTypes = {
	onDragStart: PropTypes.func.isRequired,
	onNativeChange: PropTypes.func.isRequired,
};
