import React, { useCallback } from 'react';

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import DataType from '../../DataTypes/DataType';
import {
	ARRAY,
	nonNilTypes,
	UNDEFINED,
} from '../../../../utils/commonDataTypes';
import { typeConfigDefaults } from '../utils/constants';
import { useDataConfigContext } from '../DataConfigContext';
import useGetSelectionData from '../hooks/useGetSelectionData';

export const FieldTypeSelect = () => {
	const { updateConfig } = useDataConfigContext();
	const { config, disabled, location } = useGetSelectionData();

	const handleTypeChange = useCallback(
		event => {
			updateConfig(location, typeConfigDefaults[event.target.value]);
		},
		[location, updateConfig]
	);

	const handleArrayContentTypeChange = useCallback(
		event => {
			updateConfig(location, {
				...config,
				arrayContent: typeConfigDefaults[event.target.value],
			});
		},
		[config, location, updateConfig]
	);

	return (
		<>
			<FormControl
				disabled={disabled}
				fullWidth
				style={{ marginBottom: '1em' }}
				variant="standard"
			>
				<InputLabel id="data-type-select-label">Data Type</InputLabel>
				<Select
					disabled={disabled}
					labelId="data-type-select-label"
					onChange={handleTypeChange}
					value={!config?.type ? undefined : config.type}
				>
					{nonNilTypes.map(typeOption => (
						<MenuItem key={typeOption} value={typeOption}>
							<div
								style={{
									alignItems: 'center',
									display: 'flex',
								}}
							>
								<DataType
									type={typeOption}
									style={{ marginRight: '0.5em' }}
								/>
								<span
									style={{
										textTransform: 'capitalize',
									}}
								>
									{typeOption}
								</span>
							</div>
						</MenuItem>
					))}
				</Select>
			</FormControl>
			{config?.type === ARRAY && (
				<FormControl
					disabled={disabled}
					fullWidth
					style={{ marginBottom: '1em' }}
					variant="standard"
				>
					<InputLabel id="array-content-data-type-select-label">
						Array Content Data Type
					</InputLabel>
					<Select
						disabled={disabled}
						labelId="array-content-data-type-select-label"
						onChange={handleArrayContentTypeChange}
						value={
							config.arrayContent.type === UNDEFINED
								? undefined
								: config.arrayContent.type
						}
					>
						{nonNilTypes.map(typeOption => (
							<MenuItem key={typeOption} value={typeOption}>
								<div
									style={{
										alignItems: 'center',
										display: 'flex',
									}}
								>
									<DataType
										type={typeOption}
										style={{ marginRight: '0.5em' }}
									/>
									<span
										style={{
											textTransform: 'capitalize',
										}}
									>
										{typeOption}
									</span>
								</div>
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)}
		</>
	);
};

export default FieldTypeSelect;
