import React from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

export default function BtFormSection({ children, style, title, width }) {
	const { loading } = useFormContext() || {};

	return (
		<Box
			style={{
				margin: '0 0 6em 0',
				width: width,
				position: 'relative',
				...style,
			}}
		>
			{title &&
				(loading ? (
					<Skeleton
						animation="wave"
						style={{
							width: '100%',
							maxWidth: 240,
							height: 27.5,
							borderRadius: 8,
							marginBottom: 30,
						}}
						variant="rectangular"
					/>
				) : (
					<>
						{typeof title === 'string' ? (
							<Typography
								variant="h5"
								style={{
									marginBottom: '1em',
									fontWeight: 'bold',
								}}
							>
								{title}
							</Typography>
						) : (
							title
						)}
					</>
				))}
			<>{children}</>
		</Box>
	);
}

BtFormSection.defaultProps = {
	width: '100%',
};

BtFormSection.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};
