import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Auth } from 'aws-amplify';
import { useAppContext } from '../../context/ContextManager';

// COMPONENTS
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';

import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/SaveAlt';

import { ListTable, ListRow, ListHeader, ListCell } from '../generic/ListTable';

const styles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(2),
		'& .MuiTextField-root': {
			margin: '10px 0',
		},
		'& .MuiDialog-paperWidthSm': {
			maxWidth: '800px',
		},
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
	},
	dialogTitle: {
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.contrastText,
	},
	dialogContent: {
		padding: '32px',
		margin: 'auto',
		minWidth: '600px',
		width: '800px',
	},
	inputLable: {
		fontSize: '3rem',
		fontWeight: 400,
		textAlign: 'center',
	},
	inputValue: {
		fontSize: '6rem',
		fontWeight: 700,
		textAlign: 'center',
	},
}));

export default function AnalogueConditioningDialog({
	setOpen,
	onClose,
	values,
	conditioning,
	device_uid,
}) {
	const classes = styles();
	const [commsActive, setCommsActive] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [sourceCurrent, setSourceCurrent] = useState(true);
	const [mValue, setMValue] = useState(0.001);
	const [bValue, setBValue] = useState(0);
	const [conditionedValue, setConditionedValue] = useState(0);
	const { appOrg } = useAppContext();

	const [inputConfig, setInputConfig] = useState({});

	const handleSelectConfigClose = () => {
		onClose();
	};

	const updateValue = (valueI, valueV, m, b) => {
		var new_value = 0;
		if (sourceCurrent === true) {
			new_value = valueI * m + b;
		} else {
			new_value = valueV * m + b;
		}
		setConditionedValue(
			Math.round((new_value + Number.EPSILON) * 1000) / 1000
		);
	};

	// This is called only when the dialog is opened
	const handleOnEnter = () => {
		if (conditioning.source === 'I') {
			setSourceCurrent(true);
		} else {
			setSourceCurrent(false);
		}
		setMValue(conditioning.m);
		setBValue(conditioning.b);

		updateValue(
			values.valueRawI,
			values.valueRawV,
			conditioning.m,
			conditioning.b
		);

		setInputConfig(conditioning);
		console.log(conditioning);

		setIsLoading(false);
	};

	const handleCancel = () => {
		onClose();
	};

	const handleSave = () => {
		setCommsActive(true);
		var conditioning = {};
		conditioning['input'] = inputConfig.input;
		conditioning['tag'] = inputConfig.tag;
		conditioning['source'] = inputConfig.source;
		conditioning['unit'] = inputConfig.unit;
		conditioning['filter'] = inputConfig.filter;
		conditioning['m'] = mValue;
		conditioning['b'] = bValue;

		var put_data = {};
		put_data['conditioning'] = conditioning;

		var req_url =
			process.env.REACT_APP_API_SERVER_URL +
			'/v1/device/conditioning/' +
			device_uid;

		Auth.currentSession().then(user_session => {
			let user_token = user_session.getAccessToken();
			fetch(req_url, {
				method: 'PUT',
				headers: {
					Authorization: user_token.jwtToken,
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Organisation: appOrg.uuid,
				},
				body: JSON.stringify(put_data),
			})
				.then(res => res.json())
				.then(response => {
					if (response['result'] !== 'OK') {
						console.log(
							'Failed to set conditioning: ' + response['result']
						);
					}
					setCommsActive(false);
					onClose(conditioning);
				})
				.catch(error => {
					console.log(error);
					setCommsActive(false);
				});
		});
	};

	const handleTagChange = event => {
		setInputConfig({ ...inputConfig, tag: event.target.value });
	};

	const handleFilterSelect = event => {
		setInputConfig({ ...inputConfig, filter: event.target.value });
	};

	const handleUnitSelect = event => {
		setInputConfig({ ...inputConfig, unit: event.target.value });
	};

	const handleSourceSwitchChange = event => {
		console.log('Source switch: ' + event.target.checked);
		console.log(event.target);
		if (event.target.checked) {
			setInputConfig({ ...inputConfig, source: 'I' });
			setSourceCurrent(true);
		} else {
			setInputConfig({ ...inputConfig, source: 'V' });
			setSourceCurrent(false);
		}

		updateValue(values.valueRawI, values.valueRawV, mValue, bValue);
	};

	const handleMChange = event => {
		var new_m = parseFloat(event.target.value);
		setInputConfig({ ...inputConfig, m: new_m });
		setMValue(new_m);
		updateValue(values.valueRawI, values.valueRawV, new_m, bValue);
	};

	const handleBChange = event => {
		var new_b = parseInt(event.target.value);
		setInputConfig({ ...inputConfig, b: new_b });
		setBValue(new_b);
		updateValue(values.valueRawI, values.valueRawV, mValue, new_b);
	};

	return (
		<>
			<Dialog
				open={setOpen}
				onClose={handleSelectConfigClose}
				className={classes.root}
				TransitionProps={{
					onEnter: handleOnEnter,
				}}
			>
				<DialogTitle
					id="form-dialog-title"
					className={classes.dialogTitle}
				>
					Analogue Conditioning Edit
				</DialogTitle>
				<DialogContent className={classes.dialogContent}>
					{isLoading && (
						<>
							<p>Loading...</p>
							<CircularProgress size={'120px'} />
						</>
					)}

					{!isLoading && (
						<>
							<Grid
								container
								direction="row"
								justifyContent="space-evenly"
								spacing={3}
								alignItems="stretch"
							>
								<Grid item xs={6}>
									<Box flexDirection="column">
										<div className={classes.inputLable}>
											RAW
										</div>
										<div className={classes.inputValue}>
											{values.valueRawV}
											mV
										</div>
										<div className={classes.inputValue}>
											{values.valueRawI}
											uA
										</div>
									</Box>
								</Grid>

								<Grid item xs={6}>
									<Box flexDirection="column">
										<div className={classes.inputLable}>
											Conditioned
										</div>
										<div className={classes.inputValue}>
											{conditionedValue +
												inputConfig.unit}
										</div>
									</Box>
								</Grid>

								<Grid item xs={12}>
									<Divider />
									<ListTable>
										<ListRow>
											<ListHeader>Tag:</ListHeader>
											<ListCell>
												<TextField
													variant="outlined"
													fullWidth
													defaultValue={
														conditioning.tag
													}
													className={
														classes.textField
													}
													type="text"
													onChange={handleTagChange}
												/>
											</ListCell>
										</ListRow>
										<ListRow>
											<ListHeader>
												Input Source:
											</ListHeader>
											<ListCell>
												<Grid
													component="label"
													container
													alignItems="center"
													spacing={1}
												>
													<Grid item>Voltage</Grid>
													<Grid item>
														<Switch
															checked={
																sourceCurrent
															}
															onChange={
																handleSourceSwitchChange
															}
															name="sourceSwitch"
														/>
													</Grid>
													<Grid item>Current</Grid>
												</Grid>
											</ListCell>
										</ListRow>
										{/*<ListRow>
                                        <ListHeader>
                                            Sensor Type:
                                        </ListHeader>
                                        <ListCell>
                                            <FormControl variant="outlined" className={classes.formControl}>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={inputConfig.polarity}
                                                    className={classes.select}
                                                    onChange={handleSensorSelect}
                                                >
                                                    <MenuItem value='pt100'>PT100</MenuItem>
                                                    <MenuItem value='custom'>Custom</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </ListCell>
                                    </ListRow>*/}
										<ListRow>
											<ListHeader>Units:</ListHeader>
											<ListCell>
												<FormControl
													variant="outlined"
													className={
														classes.formControl
													}
												>
													<Select
														labelId="demo-simple-select-outlined-label"
														id="demo-simple-select-outlined"
														value={inputConfig.unit}
														className={
															classes.select
														}
														onChange={
															handleUnitSelect
														}
													>
														<MenuItem value="L">
															L - Liters
														</MenuItem>
														<MenuItem value="C">
															{'\xB0'}C - Degrees
															Celsius
														</MenuItem>
														<MenuItem value="V">
															V - Volts
														</MenuItem>
														<MenuItem value="A">
															A - Amps
														</MenuItem>
													</Select>
												</FormControl>
											</ListCell>
										</ListRow>
										<ListRow>
											<ListHeader>Filter:</ListHeader>
											<ListCell>
												<FormControl
													variant="outlined"
													className={
														classes.formControl
													}
												>
													<Select
														labelId="demo-simple-select-outlined-label"
														id="demo-simple-select-outlined"
														value={
															inputConfig.filter
														}
														className={
															classes.select
														}
														onChange={
															handleFilterSelect
														}
													>
														<MenuItem value="0">
															No Filtering
														</MenuItem>
														<MenuItem value="1">
															1x Filter
														</MenuItem>
														<MenuItem value="2">
															2x Filter
														</MenuItem>
														<MenuItem value="4">
															4x Filter
														</MenuItem>
														<MenuItem value="8">
															8x Filter
														</MenuItem>
													</Select>
												</FormControl>
											</ListCell>
										</ListRow>
									</ListTable>
									<Divider />
									<ListTable>
										<ListRow>
											<ListHeader>
												Siganl Conversion
											</ListHeader>
											<ListCell>Y = MX + B</ListCell>
										</ListRow>
										<ListRow>
											<ListHeader>M:</ListHeader>
											<ListCell>
												<TextField
													variant="outlined"
													fullWidth
													defaultValue={
														conditioning.m
													}
													className={
														classes.textField
													}
													type="number"
													inputProps={{
														min: '0.001',
														max: '1000',
														step: '0.001',
													}}
													onChange={handleMChange}
												/>
											</ListCell>
										</ListRow>
										<ListRow>
											<ListHeader>B:</ListHeader>
											<ListCell>
												<TextField
													variant="outlined"
													fullWidth
													defaultValue={
														conditioning.b
													}
													className={
														classes.textField
													}
													type="number"
													onChange={handleBChange}
												/>
											</ListCell>
										</ListRow>
									</ListTable>
									<Divider />
									<ListTable>
										<ListRow>
											<ListHeader>Calibration</ListHeader>
										</ListRow>
										<ListRow>
											<ListHeader />
											<ListCell>Measured</ListCell>
											<ListCell>Reported</ListCell>
										</ListRow>
										<ListRow>
											<ListHeader>P1:</ListHeader>
											<ListCell>
												<TextField
													variant="outlined"
													fullWidth
													defaultValue={
														conditioning.tag
													}
													className={
														classes.textField
													}
													type="number"
													onChange={handleTagChange}
												/>
											</ListCell>
											<ListCell>
												<TextField
													variant="outlined"
													fullWidth
													defaultValue={
														conditioning.tag
													}
													className={
														classes.textField
													}
													type="number"
													onChange={handleTagChange}
												/>
											</ListCell>
										</ListRow>
										<ListRow>
											<ListHeader>P2:</ListHeader>
											<ListCell>
												<TextField
													variant="outlined"
													fullWidth
													defaultValue={
														conditioning.tag
													}
													className={
														classes.textField
													}
													type="number"
													onChange={handleTagChange}
												/>
											</ListCell>
											<ListCell>
												<TextField
													variant="outlined"
													fullWidth
													defaultValue={
														conditioning.tag
													}
													className={
														classes.textField
													}
													type="number"
													onChange={handleTagChange}
												/>
											</ListCell>
										</ListRow>
									</ListTable>
								</Grid>
							</Grid>

							<Box>
								<Divider />
								<Box display="flex" flexDirection="row-reverse">
									<Button
										variant="outlined"
										startIcon={<SaveIcon />}
										onClick={handleSave}
										className={classes.button}
									>
										Save
									</Button>

									<Button
										variant="outlined"
										startIcon={<CloseIcon />}
										onClick={handleCancel}
										className={classes.button}
									>
										Cancel
									</Button>
								</Box>
							</Box>
						</>
					)}
				</DialogContent>
			</Dialog>

			<Backdrop className={classes.backdrop} open={commsActive}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	);
}
