import React, { useEffect, useState } from 'react';

import { v1 as uuidv1 } from 'uuid';
import useFetch from '../../../hooks/useFetch';
import { dataSetGetList } from '../../../API';
import {
	Paper,
	Button,
	Select,
	MenuItem,
	Box,
	Container,
	Tooltip,
	IconButton,
	CircularProgress,
	Autocomplete,
	TextField,
	ListItem,
	Typography,
} from '@mui/material';

import AddIcon from '@mui/icons-material/AddCircle';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';

import BtLoading from '../../../components/generic/BtLoading';
import { useTheme } from '@mui/material/styles';
import BtQueryField from '../../../components/generic/query-builder/BtQueryField';

import ReactJson from 'react-json-view';
import {
	BtTab,
	BtTabBar,
	BtTabPanel,
	a11yProps,
} from '../../../components/generic/BtTabView';
import { useThemeContext } from '../../../context/ThemeContext';
import BtOpenInNew from '../../../components/generic/BtOpenInNew';

const TemplateDataSource = ({
	dataSets,
	dataSetsLoading,
	dataSource,
	onDataSourceUpdate,
	onDeleteDataSource,
	index,
	onRefreshDataSets,
}) => {
	const THEME = useTheme();
	const [currentTab, setCurrentTab] = useState(0);
	const { themeDark } = useThemeContext();
	const [selectedDataSource, setSelectedDataSource] = useState(null);

	const handleDataSetChange = (event, value) => {
		setSelectedDataSource();

		const dataSourceUpdate = {
			...dataSource,
			source_uuid: value.uuid,
		};
		onDataSourceUpdate(dataSourceUpdate, index);
	};

	const onQueryUpdate = queryUpdate => {
		console.log('onQueryUpdate', queryUpdate);

		const updatedDataSource = {
			...dataSource,
			query: queryUpdate.queryStr,
		};
		onDataSourceUpdate(updatedDataSource, index);
	};

	const onNameChange = event => {
		const updatedDataSource = {
			...dataSource,
			name: event.target.value,
		};
		onDataSourceUpdate(updatedDataSource, index);
	};

	const onJsonEdit = edit => {
		onDataSourceUpdate(edit.updated_src);
	};

	const onJsonAdd = add => {
		onDataSourceUpdate(add.updated_src);
	};

	useEffect(
		() => {
			if (dataSets && dataSource) {
				setSelectedDataSource(
					dataSets.find(dataSet => {
						return dataSet.uuid === dataSource.source_uuid;
					})
				);
			}
		},
		[dataSets, dataSource]
	);

	return (
		<Container maxWidth="sm">
			<Box
				sx={{
					backgroundColor: THEME.palette.background.insights,
					padding: '16px',
					borderRadius: '16px',
					margin: '16px',
				}}
				// onClick={event => {
				// 	event.stopPropagation();
				// }}
			>
				<BtTabBar
					currentTab={currentTab}
					onTabChange={(event, selectedTab) =>
						setCurrentTab(selectedTab)
					}
					style={{
						transform: 'translateY(-1px)',
						marginTop: 10,
					}}
				>
					<BtTab label="Editor" {...a11yProps(0)} />
					<BtTab label="JSON" {...a11yProps(1)} />
				</BtTabBar>

				<BtTabPanel currentTab={currentTab} index={0}>
					<Box sx={{ padding: '16px' }}>
						<TextField
							label="name"
							variant="standard"
							fullWidth
							onChange={onNameChange}
							value={dataSource.name || ''}
						/>

						<Container
							sx={{
								display: 'flex',
								flexDirection: 'row',
								paddingY: '16px',
							}}
						>
							<Autocomplete
								style={{ width: '100%' }}
								options={dataSets || []}
								value={selectedDataSource}
								loading={dataSetsLoading}
								openOnFocus
								onChange={handleDataSetChange}
								getOptionLabel={option => {
									return option.name;
								}}
								renderOption={(options, state) => {
									return (
										<ListItem {...options}>
											<Typography>
												{(() => {
													return state.name;
												})()}
											</Typography>
										</ListItem>
									);
								}}
								renderInput={params => (
									<TextField
										{...params}
										placeholder="Select Data Source"
										variant="standard"
										// disabled={fieldDisabled}
										// autoFocus={autoFocus}
									/>
								)}
								isOptionEqualToValue={(option, value) => {
									return option?.uuid === value?.uuid;
								}}
							/>

							{!dataSetsLoading ? (
								<Tooltip
									disableInteractive
									title="Refresh Data Set List"
								>
									<IconButton
										color="primary"
										onClick={onRefreshDataSets}
										size="small"
									>
										<RefreshIcon />
									</IconButton>
								</Tooltip>
							) : (
								<CircularProgress
									size={22}
									style={{ marginLeft: 6 }}
								/>
							)}

							<BtOpenInNew
								name="Open data sets in new"
								url="DataSources"
							/>
						</Container>

						<BtQueryField
							// {...options}
							// disabled={options.disabled}
							value={dataSource?.query || []}
							type="query"
							onChange={(query, results) =>
								onQueryUpdate(results)
							}
							resourceGroup="datasets"
							resource={dataSource?.source_uuid}
							// allowResourceGroupChange={options.allowResourceGroupChange}
							// allowResourceChange={options.allowResourceChange}
							allowResourceGroupChange={false}
							allowResourceChange={true}
							disableCollection={true}
						/>

						<IconButton onClick={() => onDeleteDataSource(index)}>
							<DeleteIcon />
						</IconButton>
					</Box>
				</BtTabPanel>

				<BtTabPanel currentTab={currentTab} index={1}>
					{/* <pre>{JSON.stringify(component, null, 4)}</pre> */}

					<ReactJson
						src={dataSource}
						style={{
							backgroundColor: 'transparent',
							fontSize: 14,
						}}
						theme={themeDark ? 'chalk' : 'rjv-default'}
						onEdit={onJsonEdit}
						onAdd={onJsonAdd}
					/>
				</BtTabPanel>
			</Box>
		</Container>
	);
};

export default function WorkflowTemplateDataSources({
	template,
	dataSources,
	dataSourcesUpdate,
}) {
	const {
		loading: dataSetsLoading,
		data: dataSets,
		error,
		request: fetchDataSets,
	} = useFetch(dataSetGetList);

	const onAddDataSource = () => {
		let tempDataSources = [
			...dataSources,
			{
				type: 'dataset',
				uuid: uuidv1(),
				source_uuid: '',
				query: '',
				variables: null,
			},
		];

		dataSourcesUpdate(tempDataSources);
	};

	useEffect(
		() => {
			fetchDataSets();
		},
		[fetchDataSets]
	);

	const handleDataSourceUpdate = (updatedDataSource, index) => {
		console.log('handleDataSourceUpdate', updatedDataSource);
		var updatedDataSources = [...dataSources];
		updatedDataSources[index] = updatedDataSource;
		dataSourcesUpdate(updatedDataSources);
	};

	const handleDataSourceDelete = index => {
		var updatedDataSources = [...dataSources];
		updatedDataSources.splice(index, 1);
		dataSourcesUpdate(updatedDataSources);
	};

	return (
		<>
			{template && (
				<Paper style={{ padding: 16 }}>
					<Button
						sx={{ margin: '1rem' }}
						variant="outlined"
						startIcon={<AddIcon />}
						onClick={onAddDataSource}
					>
						Add Data Source
					</Button>

					{dataSources.map((dataSource, index) => (
						<TemplateDataSource
							key={index}
							dataSets={dataSets}
							dataSetsLoading={dataSetsLoading}
							dataSource={dataSource}
							onDataSourceUpdate={handleDataSourceUpdate}
							onDeleteDataSource={handleDataSourceDelete}
							index={index}
							onRefreshDataSets={fetchDataSets}
						/>
					))}
				</Paper>
			)}
		</>
	);
}
