import React, { useEffect } from 'react';
import { useNavContext } from '../context/ContextManager';
import BtScreens from '../components/generic/screens/BtScreens';
import { useAppContext } from '../context/ContextManager';

export default function Home() {
	const { setBreadcrumbs } = useNavContext();
	const { appOrg } = useAppContext();

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([{ text: 'Home', link: '' }]);
		},
		[setBreadcrumbs]
	);

	return (
		<div
			style={{
				minHeight: 'calc(100vh - 64px)',
			}}
		>
			{appOrg.uuid === '64d987b0-981b-11ed-9c38-c5495d501df6' && (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						height: 'calc(100vh - 64px)',
					}}
				>
					<h2>The British Growers account had been suspended.</h2>
					<p>
						Please contact your administrator for more information.
					</p>
				</div>
			)}
			<BtScreens screenGroupKey="home" />
		</div>
	);
}
