import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
	buttonClasses,
	List,
	ListItemButton,
	ListItemText,
	Typography,
} from '@mui/material';

import { PermWizardContext } from './PermWizardContext';

const Container = styled('div')(({ theme }) => ({
	width: '100%',

	'&:not(:last-of-type)': {
		marginBottom: '1.5em',
	},

	[theme.breakpoints.down('xs')]: {
		flexDirection: 'column-reverse',
	},
}));

const HeadingContainer = styled('div')(({ theme }) => ({
	alignItems: 'center',
	position: 'sticky',
	top: 0,
	width: '100%',
	zIndex: 10,
	transform: 'translateY(-1px)',

	'& > h6': {
		width: '100%',
		background: theme.palette.background.paper,
		paddingTop: 1,
	},
}));

const Fade = styled('div')(({ theme }) => ({
	height: 8,
	width: '100%',
	transform: 'translateY(-1px)',
	backgroundImage: 'none',
	background: `linear-gradient(0deg, ${
		theme.palette.background.paper
	}00 0%, ${theme.palette.background.paper} 33%, ${
		theme.palette.background.paper
	} 100%)`,
}));

const ListButton = styled(ListItemButton)(
	({ added, editmode, selected, theme }) => ({
		borderLeft: selected
			? `3px solid ${theme.palette.primary.main}`
			: 'none',
		backgroundColor: selected ? theme.palette.primary.main + '33' : 'none',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		transition: theme.transitions.create(['border-left'], {
			duration: '0.15s',
		}),

		'& .editChip': {
			display: added && selected && !editmode ? 'inherit' : 'none',
		},

		'&:hover .editChip': {
			display: added && !editmode ? 'inherit' : 'none',
		},
	})
);

const AlreadyAdded = styled(Typography)(({ theme }) => ({
	color: theme.palette.indicators.info.text,
	marginLeft: '0.3em',
}));

const EditChip = styled('div')(({ theme }) => ({
	borderRadius: 15,
	backgroundColor: theme.palette.indicators.info.main + 33,
	color: theme.palette.indicators.info.text,
	height: 24,
	display: 'flex',
	alignItems: 'center',
	padding: '0 8px',

	'& *': {
		whiteSpace: 'nowrap',
	},
}));

export default function PermissionMethodsGroup({
	activeRef,
	group,
	onMethodDoubleClick,
	onMethodSelect,
	selectedMethod,
}) {
	const { addedPerms, permToEdit } = useContext(PermWizardContext);

	const sortedMethods = useMemo(
		() =>
			(group?.methods || []).sort((a, b) =>
				a['name'].localeCompare(b['name'])
			),
		[group]
	);

	const isAlreadyAdded = useCallback(
		({ name }) =>
			!!(addedPerms || []).find(
				addedPerm =>
					addedPerm.method === name && addedPerm.group === group.id
			),
		[addedPerms, group]
	);

	return (
		<Container>
			<HeadingContainer>
				<Typography
					variant="subtitle2"
					style={{
						fontWeight: 'bold',
					}}
				>
					{group.name}
				</Typography>
				<Fade />
			</HeadingContainer>
			<List
				component="nav"
				sx={{
					padding: 0,
					[`&& .${buttonClasses.disabled}`]: {
						opacity: 1,
					},
				}}
			>
				{sortedMethods.map(method => {
					const selected =
						selectedMethod && selectedMethod?.name === method.name;
					const alreadyAdded = isAlreadyAdded(method);
					const disabled =
						alreadyAdded && !permToEdit && !method.resourceGroup;

					return (
						<ListButton
							ref={selected ? activeRef : null}
							added={+alreadyAdded}
							editmode={(permToEdit?.method || '').length}
							disabled={disabled}
							key={method.name}
							selected={selected}
							onClick={() => onMethodSelect?.(method, group.id)}
							onDoubleClick={() =>
								onMethodDoubleClick?.(method, group.id)
							}
						>
							<ListItemText
								primary={
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											flexWrap: 'wrap',
										}}
									>
										<Typography
											style={{
												wordBreak: 'break-word',
												opacity: disabled ? 0.8 : 1,
											}}
										>
											{method.name}
										</Typography>
										{alreadyAdded && (
											<AlreadyAdded
												style={{
													opacity: disabled ? 0.8 : 1,
												}}
											>
												{method.name ===
												permToEdit?.method
													? ' • Currently editing'
													: ' • Already added'}
											</AlreadyAdded>
										)}
									</div>
								}
								secondary={
									<Typography
										variant="subtitle2"
										style={{
											opacity: disabled ? 0.7 : 1,
										}}
									>
										{method.description}
									</Typography>
								}
							/>
							<EditChip className="editChip">
								<Typography variant="subtitle2">
									Edit Resources
								</Typography>
							</EditChip>
						</ListButton>
					);
				})}
			</List>
		</Container>
	);
}

PermissionMethodsGroup.propTypes = {
	activeRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
	]),
	group: PropTypes.object.isRequired,
	onMethodDoubleClick: PropTypes.func.isRequired,
	onMethodSelect: PropTypes.func.isRequired,
	selectedMethod: PropTypes.object,
};
