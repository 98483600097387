const {
	ERROR,
	setOperator,
	evaluateExpression,
	OPERATION_MODE,
	getExpressionType,
	EXPRESSION,
	evaluateExpressionType,
} = require('../expression.js');

const operatorKey = '$arrayToObject';

setOperator(
	operatorKey,
	/**
	 * @type {ExpressionFunction<({k: string; v: unknown} | [string, unknown])[][], Object>}
	 */
	(context, args, options) => {
		const expr = getExpressionType(args);

		const arr =
			expr.type === EXPRESSION.FIELD_PATH
				? [evaluateExpressionType(context, expr)]
				: evaluateExpressionType(context, expr);

		if (!Array.isArray(arr[0])) {
			throw new Error(
				ERROR.INVALID_OPERATOR_ARGUMENT(
					operatorKey,
					'argument to resolve to an array',
					arr
				)
			);
		}

		let document = {};
		let isNull = false;

		for (let pos = 0; pos < arr[0].length; pos++) {
			const arg = arr[0][pos];

			/**
			 * @type {any}
			 */
			let value = arg;
			// let value = evaluateExpression(context, arg);

			if (value === undefined || value === null) {
				isNull = true;
				continue;
			}

			if (typeof value !== 'object' && !Array.isArray(value)) {
				throw new Error(
					ERROR.INVALID_OPERATOR_ARGUMENT(
						operatorKey,
						'[array | object]',
						value,
						pos
					)
				);
			}

			if (
				!Array.isArray(value) &&
				!Object.hasOwnProperty.call(value, 'k')
			) {
				throw new Error(
					ERROR.INVALID_OPERATOR_ARGUMENT(
						operatorKey,
						'object must have properties "k" and "v"',
						value,
						pos
					)
				);
			}

			if (!isNull) {
				if (Array.isArray(value)) {
					if (value.length > 0) {
						document[value[0]] = value[1] || null;
					}
					continue;
				}

				document[value.k] = value.v;
			}
		}

		if (isNull) {
			return null;
		}

		return document;
	},
	[OPERATION_MODE.AGGREGATE]
);
