import React, {
	createContext,
	useCallback,
	useEffect,
	useState,
	useContext,
} from 'react';
import PropTypes from 'prop-types';

import { userGetPermissions, userGetSubscriptions } from '../API';
import { useSnackbar } from 'notistack';

const AuthContext = createContext();

const useAuthContext = () => {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error('useAuthContext was used outside of its Provider');
	}
	return context;
};

const AuthContextProvider = ({
	children,
	orgId,
	setEstablishedAccess,
	userId,
}) => {
	const [userPermissions, setUserPermissions] = useState(null);
	const [userSubscriptions, setUserSubscriptions] = useState(null);
	const [permsChecked, setPermsChecked] = useState(false);
	const [subsChecked, setSubsChecked] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const getUserPermissions = useCallback(
		async () => {
			setPermsChecked(false);
			try {
				const response = await userGetPermissions();
				// console.log('Permissions:', response);
				setUserPermissions(response);
			} catch {
				enqueueSnackbar('Could not retrieve your permissions', {
					variant: 'error',
				});
			} finally {
				setPermsChecked(true);
			}
		},
		[enqueueSnackbar]
	);

	const getUserSubscriptions = useCallback(
		async () => {
			setSubsChecked(false);
			try {
				const response = await userGetSubscriptions();
				// console.log('Subscriptions: ', response);
				setUserSubscriptions(response);
			} catch {
				enqueueSnackbar('Could not retrieve your subscriptions', {
					variant: 'error',
				});
			} finally {
				setSubsChecked(true);
			}
		},
		[enqueueSnackbar]
	);

	useEffect(
		() => {
			if (userId && orgId) {
				setEstablishedAccess(false);
				getUserPermissions();
				getUserSubscriptions();
			}
		},
		[
			getUserPermissions,
			getUserSubscriptions,
			orgId,
			setEstablishedAccess,
			userId,
		]
	);

	useEffect(
		() => {
			if (permsChecked && subsChecked) {
				setEstablishedAccess(true);
			}
		},
		[setEstablishedAccess, permsChecked, subsChecked]
	);

	return (
		<AuthContext.Provider value={{ userPermissions, userSubscriptions }}>
			{children}
		</AuthContext.Provider>
	);
};

AuthContextProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	orgId: PropTypes.string,
	setEstablishedAccess: PropTypes.func.isRequired,
	userId: PropTypes.string,
};

export { AuthContext, AuthContextProvider, useAuthContext };
