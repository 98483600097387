import { useTheme } from '@mui/styles';
import { Cog, Delete, MinusBoxOutline, PlusBoxOutline } from 'mdi-material-ui';
import {
	useCallback,
	useContext,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useAppContext } from '../../../../context/ContextManager';
import BtDialog from '../../BtDialog';
import { ScreensContext } from '../ScreensContext';
import PropTypes from 'prop-types';

import {
	TileConfigButton,
	ControlContainer,
} from './controls/ButtonComponents';
import { ContainerAddTileDialog } from './tileConfig/ContainerAddTileDialog';
import { ContainerRemoveTileDialog } from './tileConfig/ContainerRemoveTileDialog';
import { TileConfigDialog } from './tileConfig/TileConfigDialog';
import { TileContext } from './TileContext';
import { useHistory } from 'react-router';

export default function TileWrapper({
	children,
	onTileChange,
	handleDeleteTile,
	onContainerChange,
}) {
	const [mouseIsOver, setMouseIsOver] = useState(false);
	const [showEditDialog, setShowEditDialog] = useState(false);
	const { tileData, error, setTileSize } = useContext(TileContext);
	const { editEnabled, isEditingDisabled } = useContext(ScreensContext);
	const { deviceType } = useAppContext();
	const [
		showContainerRemoveTileDialog,
		setShowContainerRemoveTileDialog,
	] = useState(false);
	const [
		showContainerAddTileDialog,
		setShowContainerAddTileDialog,
	] = useState(false);

	const THEME = useTheme();

	const history = useHistory();

	const handleMouseEnter = useCallback(() => {
		setMouseIsOver(true);
	}, []);

	const handleMouseLeave = useCallback(() => {
		setMouseIsOver(false);
	}, []);

	const ref = useRef(null);

	useLayoutEffect(
		() => {
			setTileSize({
				w: Math.floor(ref.current.getBoundingClientRect().width),
				h: Math.floor(ref.current.getBoundingClientRect().height),
			});
		},
		[ref, setTileSize]
	);

	const configOptions = useMemo(
		() => {
			switch (tileData.variant) {
				case 'workflow_templates':
					return 'none';
				case 'workflow_submissions':
					return 'none';
				case 'container':
					return 'container';
				case 'information':
					return 'none';
				default:
					return 'default';
			}
		},
		[tileData.variant]
	);

	// console.log('render');

	const DefaultButtons = () => {
		return (
			<TileConfigButton
				onClick={() => {
					setShowEditDialog(true);
					setMouseIsOver(false);
				}}
				title="Configure tile"
				icon={<Cog fontSize="small" />}
				styled
			/>
		);
	};

	const ContainerButtons = () => {
		return (
			<>
				<TileConfigButton
					onClick={() => {
						setShowContainerAddTileDialog(true);
						setMouseIsOver(false);
					}}
					title="Add tile to container"
					icon={<PlusBoxOutline fontSize="small" />}
					styled
				/>
				<TileConfigButton
					onClick={() => {
						setShowContainerRemoveTileDialog(true);
						setMouseIsOver(false);
					}}
					title="Remove tile from container"
					icon={<MinusBoxOutline fontSize="small" />}
					styled
				/>
			</>
		);
	};

	const DeleteButton = () => {
		return (
			<TileConfigButton
				onClick={() => handleDeleteTile(tileData.uuid)}
				title={
					configOptions === 'container'
						? 'Remove container'
						: 'Remove tile'
				}
				icon={<Delete fontSize="small" />}
				styled
			/>
		);
	};

	const insightClick = useCallback(
		() => {
			// console.log('insight collection click');
			if (tileData.data.insight_collection !== '')
				history.push(
					`/InsightCollection/${tileData.data.insight_collection}`
				);
		},
		[history, tileData.data.insight_collection]
	);

	const insightVisClick = useCallback(
		() => {
			// console.log('insight visualisation click');
			if (tileData.data.insight_collection !== '')
				history.push(
					`/InsightCollection/${tileData.data.insight_collection}`
				);
		},
		[history, tileData.data.insight_collection]
	);

	const clickable = useMemo(
		() => {
			const isClickable = [
				'insight_collection',
				'insight_visualisation',
			].includes(tileData.variant);
			// console.log(tileData.variant, { isClickable });
			return isClickable;
			// }
		},
		[tileData.variant]
	);

	const handleClick = useCallback(
		() => {
			// console.log({ clickable });

			switch (tileData.variant) {
				case 'insight_collection':
					insightClick();
					break;
				case 'insight_visualisation':
					insightVisClick();
					break;
				default:
					break;
			}
		},
		[insightClick, insightVisClick, tileData.variant]
	);

	const backgroundColor = useMemo(
		() => {
			if (tileData.variant === 'button' && error) {
				return THEME.palette.buttonDisabled;
			} else {
				return THEME.palette.background.paper;
			}
		},
		[
			THEME.palette.background.paper,
			THEME.palette.buttonDisabled,
			error,
			tileData.variant,
		]
	);

	return (
		<>
			<div
				ref={ref}
				style={{
					height: '100%',
					width: '100%',
					borderRadius: 8,
					outline:
						(tileData.variant === 'workflow_templates' ||
							tileData.variant === 'workflow_submissions' ||
							clickable) &&
						mouseIsOver &&
						!error &&
						!editEnabled
							? // ? 'solid 2px #B9E9EB'
							  `solid 2px ${THEME.palette.action.hoverBorder}`
							: undefined,
					backgroundColor: backgroundColor,
					cursor:
						editEnabled && !isEditingDisabled
							? 'grab'
							: !error && clickable
								? 'pointer'
								: 'auto',
				}}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onClick={
					!error && !editEnabled && clickable
						? handleClick
						: undefined
				}
			>
				{children}
				{editEnabled &&
					!isEditingDisabled &&
					(mouseIsOver || deviceType !== 'desktop') && (
						<ControlContainer
							style={{
								position: 'absolute',
								top: -5,
								right: -5,
								flexDirection: 'row',
								zIndex: 2000,
								padding: 2,
							}}
						>
							{configOptions === 'default' && <DefaultButtons />}
							{configOptions === 'container' && (
								<ContainerButtons />
							)}
							<DeleteButton />
						</ControlContainer>
					)}
			</div>
			<BtDialog
				open={showEditDialog}
				onClose={() => setShowEditDialog(false)}
				fullWidth
				maxWidth="sm"
			>
				<TileConfigDialog
					onClose={() => setShowEditDialog(false)}
					onTileChange={onTileChange}
				/>
			</BtDialog>
			<BtDialog
				open={showContainerRemoveTileDialog}
				onClose={() => setShowContainerRemoveTileDialog(false)}
				maxWidth="md"
			>
				<ContainerRemoveTileDialog
					onClose={() => setShowContainerRemoveTileDialog(false)}
					onContainerChange={onContainerChange}
				/>
			</BtDialog>
			<BtDialog
				open={showContainerAddTileDialog}
				onClose={() => setShowContainerAddTileDialog(false)}
				maxWidth="md"
			>
				<ContainerAddTileDialog
					onClose={() => setShowContainerAddTileDialog(false)}
					onContainerChange={onContainerChange}
				/>
			</BtDialog>
		</>
	);
}

TileWrapper.propTypes = {
	handleDeleteTile: PropTypes.func.isRequired,
	onTileChange: PropTypes.func.isRequired,
	onContainerChange: PropTypes.func.isRequired,
};
