import { Plus } from 'mdi-material-ui';
import { useCallback, useContext, useRef, useState } from 'react';
import { useAppContext } from '../../../../../context/ContextManager';
// import useAvailableToUser from '../../../../../hooks/useAvailableToUser';
import BtDialog from '../../../BtDialog';
import BtUserHelp from '../../../userHelp/BtUserHelp';
import { ScreensContext } from '../../ScreensContext';
import { ScreenConfigDialog } from '../screensConfig/ScreenConfigDialog';
import { ControlContainer, StyledIconButton } from './ButtonComponents';

export const GetStarted = ({ onScreenChange }) => {
	const ref = useRef();
	const { deviceType } = useAppContext();
	const [showAddScreenDialog, setShowAddScreenDialog] = useState(false);
	const { screens } = useContext(ScreensContext);

	const handleClose = useCallback((event, reason) => {
		if (reason !== 'backdropClick') {
			setShowAddScreenDialog(false);
		}
	}, []);

	// const userHasPerms = useMemo(() => userPermissions.ScreenGroupEdit)

	return (
		<div
			style={{
				width: 'auto',
				display: 'flex',
				flexDirection: 'column',
				position: 'fixed',
				bottom: deviceType === 'desktop' ? 50 : 20,
				right: deviceType === 'desktop' ? 50 : 20,
			}}
		>
			<BtUserHelp
				placement="left"
				content="Get started by adding your first screen!"
			>
				<span ref={ref}>
					<ControlContainer>
						<StyledIconButton
							onClick={() => setShowAddScreenDialog(true)}
						>
							<Plus />
						</StyledIconButton>
					</ControlContainer>
				</span>
			</BtUserHelp>
			{screens && (
				<BtDialog
					open={showAddScreenDialog}
					onClose={handleClose}
					maxWidth="sm"
				>
					<ScreenConfigDialog
						onClose={handleClose}
						onScreenChange={onScreenChange}
					/>
				</BtDialog>
			)}
		</div>
	);
};
