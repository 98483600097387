import React, { useCallback, useEffect } from 'react';
import BtScreens from '../../../components/generic/screens/BtScreens';
import { useNavContext } from '../../../context/ContextManager';

export default function ScreenEdit({ match }) {
	const { setBreadcrumbs } = useNavContext();

	const screenUuid = match.params.id;

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				// { text: 'Admin', link: '/' },
				{ text: 'Screens', link: '/Screens' },
				{ text: 'ScreensEdit', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	return (
		<div
			style={{
				minHeight: 'calc(100vh - 64px)',
			}}
		>
			<BtScreens screenAdminUuid={screenUuid} />
		</div>
	);
}
