import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
	Button,
	CardActionArea,
	LinearProgress,
	styled,
	Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import {
	a11yProps,
	BtTab,
	BtTabBar,
	BtTabPanel,
	BtTabView,
} from '../../../../../components/generic/BtTabView';
import BtConfirmDialog from '../../../../../components/generic/BtConfirmDialog';
import DataSetJsonView from './DataSetJsonView';
import DataSetNode from '../record-editor/DataSetNode';
import { useDataSetContext } from '../../../hocs/withDataSetContext';
import { useDataSetEditorContext } from '../../../contexts/DataSetEditorContext';
import useAvailableToUser from '../../../../../hooks/useAvailableToUser';
import useTimeFormat from '../../../../../hooks/useTimeFormat';

const ActionContainer = styled('div')(() => ({
	justifyContent: 'flex-end',
	paddingRight: '10',
	width: 100,
}));

const Header = styled('div')(() => ({
	alignItems: 'center',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	padding: '0.8em',
}));

const HeaderTextContainer = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
}));

const Loading = styled(LinearProgress)(() => ({
	position: 'absolute',
	top: 0,
	width: '100%',
}));

const DataSetHeader = ({ dateString }) => {
	const { editedData, editMode } = useDataSetEditorContext();
	const { sending } = useDataSetContext();

	const { uuid, primaryTimeKey } = editedData;

	const timeKey = useTimeFormat(primaryTimeKey, true, true);

	return (
		<div style={{ position: 'relative' }}>
			{sending && <Loading />}
			<Header>
				<HeaderTextContainer>
					<Typography
						style={{
							fontSize: 12,
							fontWeight: 'bold',
							marginBottom: '0.33em',
						}}
					>
						{dateString}
					</Typography>
					<Typography variant="subtitle2">
						Document UUID: {uuid}
					</Typography>
					{timeKey && (
						<Typography variant="subtitle2">
							Primary Time Key: {timeKey}
						</Typography>
					)}
				</HeaderTextContainer>
				{!editMode && (
					<ExpandLessIcon style={{ marginRight: '0.5em' }} />
				)}
			</Header>
		</div>
	);
};

const ActionAreaToggle = ({ dateString, onCollapse }) => {
	const { editMode } = useDataSetEditorContext();

	if (editMode) {
		return <DataSetHeader dateString={dateString} />;
	}

	return (
		<CardActionArea disableRipple onClick={onCollapse}>
			<DataSetHeader dateString={dateString} />
		</CardActionArea>
	);
};

export const DataSetExpanded = ({ dateString, onCollapse }) => {
	const checkAvailability = useAvailableToUser();
	const {
		deleteRecord,
		editedData,
		editMode,
		enterEditMode,
		schema,
	} = useDataSetEditorContext();
	const { editData, sending } = useDataSetContext();

	const [currentTab, setCurrentTab] = useState(0);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);

	const { available: canEdit } = checkAvailability({
		requiredPermissionsAll: {
			dataManager: ['DatasetStoreEdit'],
		},
	});

	const { available: canDelete } = checkAvailability({
		requiredPermissionsAll: {
			dataManager: ['DatasetStoreDelete'],
		},
	});

	const notEditing = !editMode && !editData.isEditing;

	const showEditButton = notEditing && canEdit;
	const showDeleteButton = notEditing && canDelete;

	return (
		<>
			<ActionAreaToggle dateString={dateString} onCollapse={onCollapse} />
			<div
				style={{
					opacity: sending ? 0.5 : 1,
					padding: '0 0.8em 0.8em 0.8em',
				}}
			>
				<BtTabView>
					<BtTabBar
						currentTab={currentTab}
						onTabChange={(event, selectedTab) =>
							setCurrentTab(selectedTab)
						}
						style={{ marginBottom: '1em' }}
					>
						<BtTab label="Editor" {...a11yProps(0)} />
						<BtTab label="Raw JSON" {...a11yProps(1)} />
					</BtTabBar>
					<BtTabPanel currentTab={currentTab} index={0}>
						<div
							style={{
								display: 'grid',
								gridTemplateColumns: 'auto 100px',
							}}
						>
							<div style={{ overflow: 'hidden' }}>
								<DataSetNode
									data={editedData.values}
									disabled={sending || !canEdit}
									schema={schema}
								/>
							</div>
							<ActionContainer>
								{showEditButton && (
									<Button
										disableElevation
										fullWidth
										onClick={enterEditMode}
										startIcon={<EditIcon />}
										style={{
											float: 'right',
											marginBottom: 10,
										}}
										variant="contained"
									>
										Edit
									</Button>
								)}
								{showDeleteButton && (
									<Button
										color="error"
										disableElevation
										fullWidth
										onClick={() =>
											setShowDeleteDialog(true)
										}
										startIcon={<DeleteIcon />}
										style={{
											float: 'right',
										}}
									>
										Delete
									</Button>
								)}
							</ActionContainer>
						</div>
					</BtTabPanel>
					<BtTabPanel currentTab={currentTab} index={1}>
						<DataSetJsonView data={editedData.values} />
					</BtTabPanel>
				</BtTabView>
			</div>
			<BtConfirmDialog
				action={deleteRecord}
				ActionIcon={<DeleteIcon />}
				isDestructive
				onClose={() => setShowDeleteDialog(false)}
				open={showDeleteDialog}
				prompt="Are you sure you want to delete this record? This cannot be undone."
				title="Delete Record"
				verb={'Delete'}
			/>
		</>
	);
};

DataSetHeader.propTypes = {
	dateString: PropTypes.string.isRequired,
};

ActionAreaToggle.propTypes = {
	dateString: PropTypes.string.isRequired,
	onCollapse: PropTypes.func.isRequired,
};

DataSetExpanded.propTypes = {
	dateString: PropTypes.string.isRequired,
	onCollapse: PropTypes.func.isRequired,
};

export default DataSetExpanded;
