import React, { useEffect } from 'react';
import { useNavContext } from '../../../../context/ContextManager';
import insights_edit_options from './InsightCollectionEditNavOptions';
import insights_breadcrumbs from '../../InsightsBreadcrumbs';

import { useInsightCollectionEditContext } from '../../insightCollectionEditContext/InsightCollectionEditContext';

import BtLoading from '../../../../components/generic/BtLoading';
import { InsightsCollectionPaletteManager } from './insightsCollectionPaletteManager/InsightsCollectionPaletteManager';

export default function CollectionEdit() {
	const { setBreadcrumbs, setContextualNav } = useNavContext();
	const {
		insightCollectionUuid,
		insightCollection,
		loading,
	} = useInsightCollectionEditContext();

	// Set breadcrumbs
	useEffect(
		() => {
			if (insightCollection) {
				setBreadcrumbs([
					...insights_breadcrumbs,
					{ text: 'Collections', link: '/InsightCollections' },
					{ text: insightCollection.name, link: '' },
				]);
			} else {
				setBreadcrumbs([
					...insights_breadcrumbs,
					{ text: 'Collections', link: '/InsightCollections' },
				]);
			}
		},
		[setBreadcrumbs, insightCollection]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([...insights_edit_options(insightCollectionUuid)]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav, insightCollectionUuid]
	);

	return (
		<BtLoading loading={loading}>
			{insightCollection && <InsightsCollectionPaletteManager />}
		</BtLoading>
	);
}
