import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ButtonBase, styled } from '@mui/material';
import Color from 'color';
import _ from 'lodash';

const Container = styled('div')(({ theme }) => ({
	backgroundColor: Color(theme.palette.text.solid)
		.fade(0.9)
		.toString(),
	borderRadius: 6,
	display: 'flex',
	padding: 0,
	marginLeft: -2,
	overflow: 'hidden',
}));

const StateButton = styled(ButtonBase)(({ theme }) => ({
	backgroundColor: 'transparent',
	display: 'inline',
	margin: 0,
	padding: '0 8px',

	'&:hover': {
		backgroundColor: Color(theme.palette.text.solid)
			.fade(0.95)
			.toString(),
	},

	'&.selected': {
		backgroundColor: theme.palette.indicators.info.text,
		color: theme.palette.background.default,
	},
}));

export const DataSetBooleanPicker = forwardRef(
	({ defaultValue, disabled, onChange, value, ...other }, ref) => {
		const [_value, setValue] = useState(value ?? defaultValue ?? false);

		useEffect(
			() => {
				if (!_.isNil(value) && value !== _value) {
					setValue(value);
				}
			},
			[value, _value]
		);

		const handleStateClick = useCallback(
			(event, newValue) => {
				if (newValue !== _value) {
					setValue(newValue);
					onChange?.(event, newValue);
				}
			},
			[onChange, _value]
		);

		return (
			<Container ref={ref} {...other}>
				{[false, true].map(state => (
					<StateButton
						key={+state}
						className={_value === state ? 'selected' : null}
						disableTouchRipple
						disabled={disabled}
						focusRipple
						onClick={event => handleStateClick(event, state)}
					>
						{state ? 'true' : 'false'}
					</StateButton>
				))}
			</Container>
		);
	}
);

DataSetBooleanPicker.propTypes = {
	defaultValue: PropTypes.bool,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	value: PropTypes.bool,
};

export default DataSetBooleanPicker;
