import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, IconButton, styled, Tooltip, Typography } from '@mui/material';
import Color from 'color';
import InfoIcon from '@mui/icons-material/Info';
import pluralize from 'pluralize';

import { useWorkflowSessionContext } from '../../contexts/WorkflowSessionContext';
import { WorkflowGroupContextProvider } from '../../contexts/WorkflowGroupContext';
import WorkflowInfoModal from './WorkflowInfoModal';
import WorkflowReadOnly from './WorkflowReadOnly';

const Container = styled('div')(({ theme }) => ({
	backgroundColor: Color(theme.palette.text.primary)
		.fade(0.95)
		.toString(),
	borderRadius: 8,
	padding: '0.8em',
}));

const Heading = styled('div')(() => ({
	alignItems: 'center',
	display: 'flex',
	marginBottom: '0.5em',
	transform: 'translateY(-0.5em)',
}));

export default function WorkflowComponentGroup({ Parser, template }) {
	const { addGroupElement } = useWorkflowSessionContext();

	const [infoVisible, setInfoVisible] = useState(false);

	const components = useMemo(() => template?.children || [], [template]);

	const isEmpty = !components.length > 0;
	const count = useMemo(() => components.length, [components]);

	const { description, hidden, maxCount, name, readOnly } =
		components[0] || {};

	const canAddAnother = useMemo(
		() => {
			if (isEmpty) {
				return true;
			}

			return count < (maxCount ?? Number.POSITIVE_INFINITY);
		},
		[count, isEmpty, maxCount]
	);

	if (hidden) {
		return null;
	}

	if (readOnly) {
		return <WorkflowReadOnly component={template} key={template.uuid} />;
	}

	return (
		<>
			<WorkflowGroupContextProvider count={count} template={template}>
				<Container>
					{!isEmpty &&
						(name || description) && (
							<Heading>
								<Typography style={{ marginRight: '0.3em' }}>
									{(() => {
										const title = name;

										return count === 1
											? title
											: pluralize(title || '');
									})()}
								</Typography>
								{description && (
									<Tooltip
										disableInteractive
										title="More Info"
									>
										<IconButton
											onClick={() => setInfoVisible(true)}
										>
											<InfoIcon />
										</IconButton>
									</Tooltip>
								)}
							</Heading>
						)}
					<Parser
						components={components.map(component => ({
							...component,
							name: null,
						}))}
						Parser={Parser}
					/>
					{canAddAnother && (
						<Button
							onClick={() => addGroupElement(template.uuid)}
							startIcon={<AddCircleIcon />}
							style={{ marginTop: '1em' }}
						>{`Add ${
							pluralize.isPlural(name || '') ? 'more' : 'another'
						}${
							!isEmpty && name && name.length < 20
								? ' ' + name
								: ''
						}`}</Button>
					)}
				</Container>
			</WorkflowGroupContextProvider>
			<WorkflowInfoModal
				info={description}
				onClose={() => setInfoVisible(false)}
				open={infoVisible}
				title={name}
			/>
		</>
	);
}

WorkflowComponentGroup.propTypes = {
	Parser: PropTypes.elementType.isRequired,
	template: PropTypes.object,
};
