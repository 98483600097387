import { Button } from '@mui/material';
import { StatusDisplay, StatusTitle } from '../generic/StatusDisplay';
import {
	BtForm,
	BtFormActionButtons,
	BtFormActionsContainer,
	BtFormChangesBlocker,
	BtFormContent,
	BtFormSelect,
	BtFormTextField,
} from '../../../forms';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecordManagementContext } from '../../RecordManagementContext';
import * as yup from 'yup';
import { useAppContext } from '../../../../../context/ContextManager';
import UploadIcon from '@mui/icons-material/Publish';
import IconResolver from '../../../../../utils/iconResolver';
import _ from 'lodash';
import { groupsPropName } from '../../utils/lookupTables/groupsPropName';
import { UPDATE_RECORD } from '../../utils/constants';

export default function GeneralSettings() {
	const [editing, setEditing] = useState(false);
	const [localSending, setLocalSending] = useState(false);
	const {
		loading,
		historyData,
		handleCallback,
		draftLockStatus,
		module,
	} = useRecordManagementContext();
	const { appOrg, activityIndicator } = useAppContext();

	useEffect(
		() => {
			if (!activityIndicator && localSending) {
				setLocalSending(false);
			}
		},
		// The warning is disabled as we don't want the effect
		// triggered when the local sending value changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[activityIndicator]
	);

	useEffect(
		() => {
			if (draftLockStatus !== 'currentUser') {
				setEditing(false);
			}
		},
		[draftLockStatus]
	);

	const defaultValues = useMemo(
		() => ({
			name: historyData.name,
			description: historyData.description || '',
			group: historyData.group || '',
		}),
		[historyData]
	);

	const groupAttributes = useMemo(
		() => {
			const recordGroup = _.find(appOrg?.[groupsPropName(module)], {
				uuid: historyData.group,
			});

			const iconProps = {
				path: IconResolver(recordGroup.icon),
				size: 2,
				color: recordGroup.colour,
				name: recordGroup.name,
			};

			const moduleGroups = appOrg[groupsPropName(module)].map(group => {
				const item = {};
				item.value = group.uuid;
				item.label = group.name;
				return item;
			});

			return {
				...iconProps,
				moduleGroups,
			};
		},
		[appOrg, historyData, module]
	);

	const schema = useMemo(
		() =>
			yup.object({
				name: yup
					.string()
					.min(3)
					.required()
					.label('Name'),
				description: yup
					.string()
					.min(3)
					.required()
					.label('Description'),
				group: yup
					.string()
					.oneOf(
						appOrg[groupsPropName(module)].map(group => group.uuid)
					)
					.required('A group must be selected')
					.label('Group'),
			}),
		[appOrg, module]
	);

	const EditDetailsButton = () => {
		return (
			<Button
				variant="contained"
				disableElevation
				onClick={() => setEditing(true)}
				disabled={draftLockStatus !== 'currentUser'}
			>
				Edit
			</Button>
		);
	};

	const formAttributes = useMemo(() => {
		return {
			verb: 'Save',
			Icon: <UploadIcon />,
			action: 'Save Changes',
		};
	}, []);

	return (
		<StatusDisplay>
			<StatusTitle
				title="General Settings"
				ButtonComponent={!editing ? EditDetailsButton : null}
			/>
			<BtForm
				validationSchema={schema}
				defaultValues={defaultValues}
				sending={localSending}
				loading={loading}
				onSubmit={(values, reset) => {
					setLocalSending(true);
					handleCallback({
						callback: UPDATE_RECORD,
						payload: values,
					});
					setEditing(false);
				}}
			>
				<BtFormContent>
					<BtFormTextField
						disabled={!editing}
						name="name"
						label="Name"
					/>
					<BtFormTextField
						disabled={!editing}
						name="description"
						label="Description"
					/>
					{/* <div
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: 10,
						}}
					>
						<Typography>Current Group:</Typography>
						<Icon
							path={groupAttributes.path}
							color={groupAttributes.color}
							size={2}
						/>
						<Typography color={groupAttributes.color} variant="h6">
							{groupAttributes.name}
						</Typography>
					</div> */}
					<BtFormSelect
						disabled={!editing}
						name="group"
						label="Group"
						items={groupAttributes.moduleGroups}
					/>
					<BtFormChangesBlocker />
				</BtFormContent>
				<BtFormActionsContainer>
					<BtFormActionButtons
						SubmitIcon={formAttributes.Icon}
						submitVerb={formAttributes.action}
						destructiveVerb={
							formAttributes.verb === 'Edit'
								? 'Discard Changes'
								: 'Discard'
						}
						onDestroy={() => setEditing(false)}
					/>
				</BtFormActionsContainer>
			</BtForm>
		</StatusDisplay>
	);
}
