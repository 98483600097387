import React, { memo, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	CircularProgress,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
} from '@mui/material';
import * as yup from 'yup';
import {
	BtForm,
	BtFormActionsContainer,
	BtFormContent,
	BtFormSelect,
	BtFormTextField,
	withFormContextMethods,
} from '../../../../../components/generic/forms';
import BtDialog from '../../../../../components/generic/BtDialog';
import { Check, Close } from 'mdi-material-ui';
import { useUpdateCollectionDraft } from './utils/useUpdateCollectionDraft';
import { findIndex } from 'lodash';

const ActionButtons = withFormContextMethods(
	({ formState: { isDirty }, onClose, sending }) => {
		return (
			<>
				<Button
					startIcon={<Close />}
					variant="outlined"
					onClick={onClose}
					disabled={sending}
				>
					Cancel
				</Button>
				<Button
					variant="contained"
					disabled={!isDirty || sending}
					type="submit"
					startIcon={
						sending ? <CircularProgress size={20} /> : <Check />
					}
					disableElevation
				>
					Update Data Source
				</Button>
			</>
		);
	}
);

export const DataSourcePropertiesDialog = memo(
	function DataSourcePropertiesDialog({
		dataSource,
		dataSources,
		dataViews,
		handleSourceUpdate,
		onClose,
		open,
	}) {
		const [sending, setSending] = useState(false);
		const updateCollection = useUpdateCollectionDraft();

		const existingNames = useMemo(
			() =>
				dataSources
					?.map(({ name }) => name)
					.filter(name => name !== dataSource.name),
			[dataSource, dataSources]
		);

		const defaultValues = useMemo(
			() => ({
				name: dataSource?.name,
				description: dataSource?.description,
				dataView: dataSource?.dataView,
			}),
			[dataSource]
		);

		const schema = useMemo(
			() =>
				yup.object({
					name: yup
						.string()
						.min(3)
						.max(50)
						.notOneOf(
							existingNames || [],
							'A Data Source with this name already exists'
						)
						.required()
						.label('Data Source Name'),
					description: yup
						.string()
						.max(300)
						.label('Data Source Description'),
					dataView: yup
						.string()
						.label('Data View')
						.nullable(),
				}),
			[existingNames]
		);

		const dataViewItems = useMemo(
			() =>
				dataViews?.map(source => {
					const item = {};
					item.value = source.uuid;
					item.label = source.name;
					return item;
				}) ?? [],
			[dataViews]
		);

		const onSubmit = useCallback(
			values => {
				setSending(true);
				const index = findIndex(dataSources, {
					uuid: dataSource.uuid,
				});
				const sourceUpdate = { ...dataSources[index] };
				sourceUpdate.name = values.name;
				sourceUpdate.description = values.description;
				sourceUpdate.dataView = values.dataView;

				let dataSourcesUpdate = [...dataSources];
				dataSourcesUpdate[index] = sourceUpdate;

				const callbacks = {
					success: update => {
						handleSourceUpdate(update);
						setSending(false);
						onClose();
					},
					error: () => setSending(false),
				};

				updateCollection({
					update: { datasources: dataSourcesUpdate },
					callbacks,
				});
			},
			[
				dataSource,
				dataSources,
				handleSourceUpdate,
				onClose,
				updateCollection,
			]
		);

		return (
			<BtDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
				{sending && (
					<LinearProgress
						style={{
							position: 'absolute',
							top: 0,
							width: '100%',
						}}
					/>
				)}
				<DialogTitle>Edit data source properties</DialogTitle>
				<BtForm
					onSubmit={onSubmit}
					sending={sending}
					validationSchema={schema}
					defaultValues={defaultValues}
				>
					<DialogContent>
						<BtFormContent>
							<BtFormTextField
								disabled={sending}
								name="name"
								label="Name"
							/>
							<BtFormTextField
								disabled={sending}
								name="description"
								label="Description"
								multiline
							/>
							<BtFormSelect
								name="dataView"
								label="Data View"
								disabled={sending}
								items={dataViewItems}
							/>
						</BtFormContent>
					</DialogContent>
					<DialogActions>
						<BtFormActionsContainer>
							<ActionButtons
								onClose={onClose}
								sending={sending}
							/>
						</BtFormActionsContainer>
					</DialogActions>
				</BtForm>
			</BtDialog>
		);
	}
);

ActionButtons.propTypes = {
	isDirty: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	sending: PropTypes.bool.isRequired,
};

DataSourcePropertiesDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	dataSource: PropTypes.object.isRequired,
	dataSources: PropTypes.array.isRequired,
	dataViews: PropTypes.array.isRequired,
	handleSourceUpdate: PropTypes.func.isRequired,
	loading: PropTypes.bool,
};
