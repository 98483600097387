import React, { useCallback, useEffect, useState } from 'react';
import { useNavContext } from '../context/ContextManager';
import developer_nav_item from './DeveloperNavItem';
import developer_options from './DeveloperOptions';
import developer_breadcrumbs from './DeveloperBreadcrumbs';
import {
	Box,
	Divider,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Typography,
	styled,
} from '@mui/material';
import { Flaky } from '@mui/icons-material';
import { BtFormContainer } from '../components/generic/forms';
import { HeatWave } from 'mdi-material-ui';
import { BtTabs } from '../components/generic/tabs/BtTabs';
import { getRandomBaseColour } from '../components/paletteManager/utils/functions';
import { useTheme } from '@mui/styles';

const tabs = [
	{
		content: <Typography>tab1</Typography>,
		// label: 'tab1',
		// value: '1',
		icon: <Flaky />,
	},
	{
		content: <Typography>tab2</Typography>,
		// label: 'tab2',
		// value: '2',
		icon: <Flaky />,
	},
	{
		content: <Typography>tab3</Typography>,
		// label: 'tab3',
		// value: '3',
		icon: <Flaky />,
	},
	// { id: 1, label: 'Tab 1' },
	// { id: 2, label: 'Tab 2' },
	// { id: 3, label: 'Tab 3' },
	// { id: 4, label: 'Tab 4' },
];

// const tabs1 = () => {
// 	return [...Array(10)].map((_, index) => ({
// 		id: `tab${index + 1}`,
// 		label: `Tab ${index + 1}`,
// 		value: `${index + 1}`,
// 		content: `Content ${index + 1}`,
// 		icon: <HeatWave />,
// 	}));
// };

const Content = ({ title }) => {
	const theme = useTheme();
	const colour = getRandomBaseColour();
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				height: '600px',
				width: '100%',
				minHeight: '100%',
				// backgroundColor: colour,
			}}
		>
			<Typography
				// sx={{ color: theme.palette.getContrastText(colour) }}
				variant="h1"
			>
				{title}
			</Typography>
		</Box>
	);
};

export const tabs2 = howMany => {
	return [...Array(howMany || 15)].map((_, index) => ({
		// id: `tab${index + 1}`,
		label: `Tabus maximus namus ${index + 1}`,
		// value: `${index + 1}`,
		content: <Content title={`Content ${index + 1}`} />,
		icon: <HeatWave />,
	}));
};

const Container = styled('div')(() => ({
	display: 'flex',
	overflow: 'auto',
	// '&::-webkit-scrollbar': {
	// 	display: 'none',
	// },
	// '-ms-overflow-style': 'none' /* IE and Edge */,
	// 'scrollbar-width': 'none' /* Firefox */,
}));

export const BtTabsDev = () => {
	const { setContextualNav, setBreadcrumbs } = useNavContext();
	const [localTabs, setLocalTabs] = useState(tabs2());
	const [activeTab, setActiveTab] = useState('1');
	const [activeTab2, setActiveTab2] = useState('1');
	const [localTabs2, setLocalTabs2] = useState(tabs2());

	const [selectValue, setSelectValue] = React.useState('');

	const handleChange = event => {
		setSelectValue(event.target.value);
		setActiveTab2(event.target.value);
	};

	// const handleDragEnd = useCallback(result => console.log({ result }), []);
	const handleTabChange = useCallback(
		payload => console.log('handleTabChange', { payload }),
		[]
	);

	// const handleDragEnd = result => {
	// 	console.log(result);
	// 	if (!result.destination) {
	// 		console.log('missing result.destination');
	// 		return;
	// 	}
	// 	if (result.destination.index === result.source.index) return;

	// 	const newTabs = Array.from(tabs);
	// 	const [removed] = newTabs.splice(result.source.index, 1);
	// 	newTabs.splice(result.destination.index, 0, removed);
	// 	setLocalTabs(newTabs);
	// 	setActiveTab(result.destination.index);
	// };

	const onDragEnd = result => {
		console.log(JSON.stringify(result, 0, null));
		if (!result.destination) return;
		if (result.destination.index === result.source.index) return;

		const newTabs = [...localTabs];
		console.log(newTabs);
		const [removed] = newTabs.splice(result.source.index, 1);
		console.log({ removed });
		console.log(newTabs);

		newTabs.splice(result.destination.index, 0, removed);
		console.log(newTabs);

		setLocalTabs(newTabs);
	};
	useEffect(
		() => {
			console.log(localTabs);
		},
		[localTabs]
	);

	useEffect(
		() => {
			setContextualNav([...developer_nav_item, ...developer_options]);

			setBreadcrumbs([
				...developer_breadcrumbs,
				{ text: 'Bt Tabs Development', link: '' },
			]);

			return () => setContextualNav(null);
		},
		[setContextualNav, setBreadcrumbs]
	);

	return (
		<div
			style={{
				height: '100%',
				minHeight: '100%',
				// backgroundColor: getRandomBaseColour(),
			}}
		>
			{/* <BtFormContainer
				style={{ backgroundColor: getRandomBaseColour() }}
				maxWidth="lg"
				title="BtTabs Development"
			> */}
			{/* <Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					height: '100%',
					width: '100%',
					minHeight: '100%',
					backgroundColor: getRandomBaseColour(),
				}}
			>
				<Typography variant="h1">bob</Typography>
			</Box> */}
			{/* minHeight: '100%', */}
			<BtTabs autoComplete draggable tabs={localTabs2} />

			{/* <Content title="bob" /> */}
			{/* </BtFormContainer> */}
		</div>
	);
};
