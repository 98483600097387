const recordManagerRequiredPermissionsAll = {
	insightCollection: {
		insights: [
			'InsightsEdit',
			'InsightsView',
			'InsightsCreate',
			'InsightsPublish',
		],
	},
	workflowTemplate: {
		workflows: [
			'TemplateList',
			'TemplateRead',
			'TemplateDraftRead',
			'TemplateCreate',
			'TemplateEdit',
			'TemplatePublish',
		],
	},
};

export { recordManagerRequiredPermissionsAll };
