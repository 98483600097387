import React, { useEffect, useMemo } from 'react';

import useFetch from '../../hooks/useFetch';
import { userAdminGetList } from '../../API/user_admin';

import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

import BtUserRow from './BtUserRow';
import BtListComposer from './BtListComposer';
import { ListItem, Skeleton } from '@mui/material';

export default function BtUserList({ currentUsers, onUserListUpdate }) {
	const {
		data: userList,
		error: userError,
		loading: loadingUsers,
		request: getUsers,
	} = useFetch(userAdminGetList);
	useEffect(
		() => {
			if (!userList) {
				getUsers();
			}
		},
		[getUsers, userList]
	);
	const users = useMemo(
		() =>
			(userList || []).filter(({ uuid }) =>
				(currentUsers || []).includes(uuid)
			),
		[currentUsers, userList]
	);

	const addUser = userToAdd => {
		var newUserList = [...currentUsers];
		newUserList.push(userToAdd.uuid);
		onUserListUpdate(newUserList);
	};
	const removeUser = userToRemove => {
		onUserListUpdate(
			[...currentUsers].filter(user => user !== userToRemove.uuid)
		);
	};

	if (loadingUsers) {
		return <Skeleton style={{ width: '100%' }} />;
	}

	return (
		<BtListComposer
			allItems={userList || []}
			confirmDeletion
			deleteable
			deleteVerb="Remove"
			items={users}
			primaryField="user_name"
			DeleteIcon={<PersonRemoveIcon />}
			sortComparator={(a, b) => a?.user_name?.localeCompare(b?.user_name)}
			renderOption={(props, user) => (
				<ListItem
					{...props}
					style={{
						padding: '0 1em',
					}}
				>
					<BtUserRow key={user.uuid} user={user} hideAvatar />
				</ListItem>
			)}
			renderItem={user => <BtUserRow key={user.uuid} user={user} />}
			subject="users"
			onItemAdd={addUser}
			onItemDelete={removeUser}
		/>
	);
}
