import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import { useAppContext } from '../../../../context/ContextManager';
import useFetch from '../../../../hooks/useFetch';
import {
	workflowTemplateGetList,
	workflowSubmissionCreate,
} from '../../../../API';
import _ from 'lodash';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import BtLoading from '../../BtLoading';
import BtSelectionTable from '../../BtSelectionTable';
import AddIcon from '@mui/icons-material/AddCircle';
import IconResolver from '../../../../utils/iconResolver';
import Icon from '@mdi/react';
import BtConfirmDialog from '../../BtConfirmDialog';
import { v4 as uuidV4 } from 'uuid';
import { TileContext } from '../components/TileContext';

const tableColumns = [
	{ field: 'groupIcon', text: 'Group', minBreakpoint: 'sm' },
	{ field: 'group', text: '', minBreakpoint: 'lg' },
	{ field: 'name', text: 'Name & Description', minBreakpoint: 'sm' },
	{ field: 'status', text: 'Status', minBreakpoint: 'md' },
	{ field: 'latest_version', text: 'Version', minBreakpoint: 'lg' },
];

export default function WorkflowTemplatesTile() {
	const { userInfo, appOrg } = useAppContext();
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [newSubTempUuid, setNewSubTempUuid] = useState(null);
	const [newSubTempName, setNewSubTempName] = useState('');
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const { setError, tileSize } = useContext(TileContext);

	const {
		loading: templateListLoading,
		data: templateList,
		error: templateListError,
		request: getTemplateList,
	} = useFetch(workflowTemplateGetList);

	const handleTableClick = useCallback(
		uuid => {
			setConfirmOpen(true);
			setNewSubTempUuid(uuid);
			let template = _.find(templateList, { uuid: uuid });
			setNewSubTempName(template.name);
		},
		[templateList]
	);

	// console.log({ templateList });

	const handleCreateSubmission = useCallback(
		async templateUuid => {
			try {
				let template = _.find(templateList, { uuid: templateUuid });
				const newSubmissionUuid = uuidV4();
				const newSubmission = {
					uuid: newSubmissionUuid,
					due_date: null,
					template: {
						uuid: template.uuid,
						version: template.latest_version,
					},
					create_timestamp: Date.now(),
					data: {},
					log: [
						{
							timestamp: Date.now(),
							uuid: uuidV4(),
							issuer_type: 'User',
							issuer_id: userInfo.uuid,
							status: 'InProgress', // TODO: change this to pick up the correct value
						},
					],
					session_data: { tree: null },
				};
				await workflowSubmissionCreate({
					newSubmission: newSubmission,
				});

				enqueueSnackbar(`Created new Submission '${template.name}'`, {
					variant: 'success',
				});
				history.push(`/Workflows/Submissions/${newSubmissionUuid}`);
			} catch (error) {
				console.log(error);
				enqueueSnackbar('Failed to create Workflow submission', {
					variant: 'error',
				});
			}
		},
		[enqueueSnackbar, history, templateList, userInfo.uuid]
	);

	useEffect(
		() => {
			getTemplateList();
		},
		[getTemplateList]
	);

	useEffect(
		() => {
			if (templateListError) setError({ serverError: templateListError });
		},
		[setError, templateListError]
	);

	return (
		<div style={{ height: '100%', width: '100%' }}>
			<BtLoading loading={templateListLoading}>
				{templateList && (
					<BtSelectionTable
						containerWidth={tileSize.width}
						containerResponsive
						size="small"
						columns={tableColumns}
						onClick={handleTableClick}
						data={(templateList || []).map(
							({
								uuid,
								name,
								description,
								status,
								group,
								latest_version,
							}) => ({
								id: uuid,
								name: (
									<div>
										<Typography
											sx={{ fontWeight: 'bold' }}
											variant="body2"
										>
											{name}
										</Typography>
										<Typography variant="body2">
											{description}
										</Typography>
									</div>
								),
								status: status,
								groupIcon: (
									<Icon
										path={IconResolver(
											_.find(
												appOrg?.workflow_template_groups,
												{ uuid: group }
											).icon
										)}
										size={2}
										color={
											_.find(
												appOrg?.workflow_template_groups,
												{ uuid: group }
											).colour
										}
									/>
								),
								group: (
									<Typography
										variant="body"
										sx={{
											color: _.find(
												appOrg?.workflow_template_groups,
												{ uuid: group }
											).colour,
										}}
									>
										{' '}
										{
											_.find(
												appOrg?.workflow_template_groups,
												{ uuid: group }
											).name
										}
									</Typography>
								),
								latest_version: latest_version,
							})
						)}
					/>
				)}
			</BtLoading>
			<BtConfirmDialog
				title="Create New Submission"
				action={() => {
					handleCreateSubmission(newSubTempUuid);
				}}
				ActionIcon={<AddIcon />}
				onClose={() => {
					setConfirmOpen(false);
				}}
				open={confirmOpen}
				prompt={`Are you sure you wish to create a new '${newSubTempName}' submission?`}
				verb="Create"
			/>
		</div>
	);
}
