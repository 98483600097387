import React, { useState, useEffect, useRef, useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useAppContext, useNavContext } from '../context/ContextManager';
import { useSocketContext } from '../context/SocketContext/SocketContext';

// COMPONENTS
import { Typography, Box, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';

// CUSTOM COMPONENTS
import CustomPaper from '../components/generic/CustomPaper';

// Data for landing page selection table and contextual navigation
import developer_options from './DeveloperOptions';
import developer_breadcrumbs from './DeveloperBreadcrumbs';
import developer_nav_item from './DeveloperNavItem';

// ICONS
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Container } from 'react-bootstrap';
import {
	BtForm,
	BtFormActionsContainer,
	BtFormContainer,
	BtFormContent,
	BtFormLateralContainer,
	BtFormTextField,
} from '../components/generic/forms';
import { yup } from '../utils/yup-ast';
import BtSelectionTable from '../components/generic/BtSelectionTable';
import { DeleteCircle } from 'mdi-material-ui';

//var WebSocket = require('websocket').w3cwebsocket;

const styles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(2),
	},
	toolbarButton: {
		margin: '0px',
		padding: '4px 16px',
		fontSize: '1.5rem',
	},
	jsonEdit: {
		margin: '0px 0',
		fontSize: '2rem',
	},
}));

const publishBlankDefaults = {
	pubChannel: '',
	pubMessage: '',
};

const publishSchema = yup.object().shape({
	pubChannel: yup.string().required(),
	pubMessage: yup.string().required(),
});

const subscribeBlankDefaults = {
	subChannel: '',
};

const subscribeSchema = yup.object().shape({
	subChannel: yup.string().required(),
});

export default function PubSubDev() {
	const classes = styles();
	const [newSubMsg, setNewSubMsg] = useState(null);
	const [subMsgs, setSubMsgs] = useState([]);
	const [subList, setSubList] = useState([]);

	const [subChannel, setSubChannel] = useState('');
	const [subError, setSubError] = useState(false);
	const [subErrorMessage, setSubErrorMessage] = useState('');

	const [pubChannel, setPubChannel] = useState('');
	const [pubMessage, setPubMessage] = useState('');
	const [pubError, setPubError] = useState(false);
	const [pubErrorMessage, setPubErrorMessage] = useState('');

	//const [pubChannel, setPubChannel] = useState('');
	const [jsonError, setJsonError] = useState(false);
	const { subscribe, unsubscribe, socketReady, publish } = useSocketContext();
	const { getOrgFormattedTimestamp } = useAppContext();

	const webSocket = useRef(null);

	// const handleTextChangeSubChannel = event => {
	// 	setSubChannel(event.target.value);
	// };
	// const handleTextChangePubChannel = event => {
	// 	setPubChannel(event.target.value);
	// };
	const handleTextChangePubMessage = event => {
		setPubMessage(event.target.value);
		try {
			// Try and parse the message to test if valid JSON
			JSON.parse(event.target.value);
			setJsonError(false);
		} catch (err) {
			setJsonError(true);
		}
	};

	const { setBreadcrumbs } = useNavContext();
	const { setContextualNav } = useNavContext();

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				...developer_breadcrumbs,
				{ text: 'PubSub', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([...developer_nav_item, ...developer_options]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	useEffect(
		() => {
			if (newSubMsg) {
				console.log('Add msg to list');
				console.log(subMsgs);
				setSubMsgs([
					{ ...newSubMsg, timestamp: Date.now() },
					...subMsgs,
				]);
			}
		},
		[newSubMsg]
	);

	const onSubmitSubscribe = () => {
		try {
			setSubError(false);
			setSubErrorMessage('');
			if (subChannel === '') {
				setSubError(true);
				setSubErrorMessage('A Channel is required');
			} else {
				if (subList.includes(subChannel)) {
					setSubChannel('');
					setSubError(true);
					setSubErrorMessage('Already Subscribed');
				} else {
					subscribe('xxxxxxxxx', subChannel, (channel, message) => {
						const newMessage = {
							channel: channel,
							message: message,
						};
						setNewSubMsg(newMessage);
					});
					setSubList([...subList, subChannel]);
					setSubChannel('');
				}
			}
		} catch (error) {
			setSubError(true);
			setSubErrorMessage(error);
		}
	};

	const handleClickUnsub = sub_chan => () => {
		unsubscribe('xxxxxxxxx', sub_chan);
		const index = subList.indexOf(sub_chan);
		setSubList([...subList.slice(0, index), ...subList.slice(index + 1)]);
	};

	const onSubmitPublish = () => {
		try {
			setPubError(false);
			setPubErrorMessage('');
			if (pubChannel === '') {
				setPubError(true);
				setPubErrorMessage('A Channel is required');
			} else {
				publish(pubChannel, pubMessage);
			}
		} catch (error) {
			setPubError(true);
			setPubErrorMessage(error);
		}
	};

	return (
		<Container>
			{socketReady && (
				<Typography variant="h5">WebSocket Connected</Typography>
			)}
			{!socketReady && (
				<Typography variant="h5">WebSocket Disconnected</Typography>
			)}

			<Container
				style={{
					maxWidth: 720,
					paddingBottom: '50px',
					paddingTop: '50px',
				}}
			>
				<Typography variant="h4">Publish Message</Typography>

				<TextField
					label="Channel Name"
					color="primary"
					error={pubError}
					fullWidth
					helperText={pubErrorMessage}
					onChange={event => {
						setPubChannel(event.target.value);
					}}
					size="small"
					value={pubChannel}
					variant="standard"
				/>
				<TextField
					label="Message (JSON)"
					color="primary"
					error={jsonError}
					fullWidth
					helperText={jsonError && 'Invalid JSON'}
					onChange={handleTextChangePubMessage}
					size="small"
					value={pubMessage}
					variant="standard"
				/>

				<Button
					variant="contained"
					onClick={onSubmitPublish}
					sx={{ margin: '10px' }}
				>
					Publish
				</Button>
			</Container>

			<Container
				style={{
					maxWidth: 720,
					paddingBottom: '50px',
					paddingTop: '50px',
				}}
			>
				<Typography variant="h4">Subscribe To Channel</Typography>
				<TextField
					label="Channel Name"
					color="primary"
					error={subError}
					fullWidth
					helperText={subErrorMessage}
					onChange={event => {
						setSubChannel(event.target.value);
					}}
					size="small"
					value={subChannel}
					variant="standard"
				/>
				<Button
					variant="contained"
					onClick={onSubmitSubscribe}
					sx={{ margin: '10px' }}
				>
					Subscribe
				</Button>
			</Container>

			<BtSelectionTable
				columns={[
					{ field: 'channel', text: 'Channel' },
					{ field: 'unsubscribe', text: 'Unsubscribe' },
				]}
				data={(subList || []).map(sub => ({
					channel: sub,
					unsubscribe: (
						<IconButton onClick={handleClickUnsub(sub)}>
							<DeleteCircle />
						</IconButton>
					),
				}))}
				title="Subscriptions"
			/>

			<BtSelectionTable
				columns={[
					{ field: 'channel', text: 'Channel' },
					{ field: 'message', text: 'Message' },
					{ field: 'timestamp', text: 'Received Time' },
				]}
				data={(subMsgs || []).map(
					({ channel, message, timestamp }) => ({
						channel: channel,
						message: message,
						timestamp: getOrgFormattedTimestamp(timestamp, true),
					})
				)}
				title="Received Messages"
				size="small"
			/>
		</Container>
	);
}
