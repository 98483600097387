// import React, { useState, useEffect, useRef } from "react";
import React, { useEffect } from 'react';
// import { useAppContext } from "../context/ContextManager";
// import { useTheme } from '@mui/material/styles';
import { useNavContext } from '../../context/ContextManager';

// import useMediaQuery from '@mui/material/useMediaQuery';

// import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
// import Divider from '@mui/material/Divider';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import Checkbox from '@mui/material/Checkbox';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
// import Slider from '@mui/material/Slider';
// import TextField from '@mui/material/TextField';
// import ToggleButton from '@mui/material/ToggleButton';
// import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText'
// import Switch from '@mui/material/Switch';
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Button from '@mui/material/Button';

// MUI ICONS
// import Dashboard from '@mui/icons-material/Dashboard';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
// import InboxIcon from '@mui/icons-material/Inbox';
// import DraftsIcon from '@mui/icons-material/Drafts';
// import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
// import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
// import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
// import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

// CUSTOM COMPONENTS
// import { BtTabView, BtTabBar, BtTab, BtTabPanel, a11yProps } from '../components/generic/BtTabView';
// import CustomPaper from '../components/generic/CustomPaper';
// import { Accordion, AccordionSummary, AccordionDetails } from '../components/generic/CustomAccordion';
// import {ListTable, ListRow, ListHeader, ListCell} from "../components/generic/ListTable";

// import { SelectionTable,
// 	SelectionTableContainer,
// 	SelectionTableHead,
// 	SelectionTableHeadRow,
// 	SelectionTableBody,
// 	SelectionTableRow,
// 	SelectionTableCell} from '../components/generic/BtSelectionTable';

// Data for landing page selection table and contextual navigation
import IoT_options from './IoTOptions';

import IoT_breadcrumbs from './IoTBreadcrumbs';

import IoT_nav_item from './IoTNavItem';

export default function FleetAdmin() {
	const { setContextualNav } = useNavContext();
	const { setBreadcrumbs } = useNavContext();

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([...IoT_nav_item, ...IoT_options]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				...IoT_breadcrumbs,
				{ text: 'Fleet Admin', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	return (
		<>
			<Container maxWidth="lg">
				<Typography variant="h3">FleetAdmin</Typography>
			</Container>
		</>
	);
}
