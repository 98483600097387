import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import withFormContextMethods from './withFormContextMethods';
import ActionButtonContainer from '../ActionButtonContainer';

const BtFormActionsContainer = withFormContextMethods(
	({ loading, style, children }) => {
		const theme = useTheme();
		const screen_downSm = useMediaQuery(theme.breakpoints.down('sm'));

		return (
			<ActionButtonContainer style={style}>
				{loading && (
					<>
						<Skeleton
							animation="wave"
							variant="rectangle"
							style={{
								borderRadius: 8,
								width: screen_downSm ? '100%' : 180,
							}}
							height={32}
						/>
						<Skeleton
							animation="wave"
							variant="rectangle"
							style={{
								borderRadius: 8,
								width: screen_downSm ? '100%' : 180,
							}}
							height={32}
						/>
					</>
				)}
				{!loading && children}
			</ActionButtonContainer>
		);
	}
);

BtFormActionsContainer.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

BtFormActionsContainer.displayName = 'FormActionContainer';

export default BtFormActionsContainer;
