import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { isDate } from 'lodash';

import {
	BOOLEAN,
	DATE,
	nonNilTypes,
	NUMBER,
	STRING,
} from '../../../../utils/commonDataTypes';
import BtDateTimePicker from '../../../../components/generic/BtDateTimePicker';
import { ON_CHANGE, ON_ENTER_OR_BLUR } from '../utils/constants';

const commonTextFieldProps = {
	fullWidth: true,
	label: 'Default Value',
	style: { marginBottom: '1em' },
	variant: 'standard',
};

const BooleanInput = ({ onChange, value, ...otherProps }) => (
	<FormControlLabel
		control={
			<Checkbox
				onChange={() => onChange(!value)}
				checked={value}
				{...otherProps}
			/>
		}
		label="Default Boolean Value"
		style={{
			alignItems: 'center',
			display: 'flex',
			justifyContent: 'flex-start',
		}}
	/>
);

const DateInput = ({ value, ...props }) => (
	<BtDateTimePicker
		ampm
		ampmInClock
		fieldProps={{
			fullWidth: true,
			style: { marginBottom: '1em' },
		}}
		style={{ marginBottom: '1em' }}
		variant="datetime"
		{...props}
		value={isDate(value) ? value : new Date(value ?? 0)}
	/>
);

const NumberInput = ({ onChange, value, ...props }) => (
	<TextField
		{...commonTextFieldProps}
		{...props}
		onChange={event => onChange(+event.target.value)}
		type="number"
		value={value === 0 || value ? value : ''}
	/>
);

const StringInput = ({ onChange, ...props }) => (
	<TextField
		{...commonTextFieldProps}
		{...props}
		onChange={event => onChange(event.target.value)}
	/>
);

export default function useDefaultComponents(type) {
	const lookup = useMemo(
		() => ({
			[BOOLEAN]: {
				Component: BooleanInput,
				updateTrigger: ON_CHANGE,
			},
			[DATE]: {
				Component: DateInput,
				updateTrigger: ON_CHANGE,
			},
			[NUMBER]: {
				Component: NumberInput,
				updateTrigger: ON_ENTER_OR_BLUR,
			},
			[STRING]: {
				Component: StringInput,
				updateTrigger: ON_ENTER_OR_BLUR,
			},
		}),
		[]
	);

	return lookup[type];
}

BooleanInput.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.bool,
};

DateInput.propTypes = {
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

NumberInput.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.number,
};

StringInput.propTypes = { onChange: PropTypes.func };

useDefaultComponents.propTypes = {
	type: PropTypes.oneOf(nonNilTypes),
};
