import React, { useState, useEffect } from 'react';
import { Container, Typography } from '@mui/material';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';

import developer_options from './DeveloperOptions';
import developer_breadcrumbs from './DeveloperBreadcrumbs';
import developer_nav_item from './DeveloperNavItem';
import { useNavContext } from '../context/ContextManager';
import {
	BtForm,
	BtFormActionButtons,
	BtFormChangesBlocker,
	BtFormCheckbox,
	BtFormContent,
	BtFormDateTimePicker,
	BtFormFilePicker,
	BtFormLateralContainer,
	BtFormSection,
	BtFormSelect,
	BtFormTextField,
	withFormContextMethods,
} from '../components/generic/forms';
import BtFormDataConfigNodeSelect from '../components/generic/forms/BtFormDataConfigNodeSelect';

/*
Select options should always be an array of objects containing two fields:
- value: number/string
- label: string
*/

const selectOptions = [
	{ value: 1, label: 'One' },
	{ value: 2, label: 'Two' },
	{ value: 3, label: 'Three' },
];

const configSchema = {
	type: 'object',
	objectContent: {
		node1: {
			type: 'string',
			label: 'Node 1',
		},
		node2: {
			type: 'boolean',
			label: 'Node 2',
		},
		node3: {
			type: 'number',
			label: 'Node 3',
		},
		node4: {
			type: 'array',
			arrayContent: {
				type: 'object',
				objectContent: {
					arrObj1: { type: 'number', label: 'Array Object Field 1' },
				},
				label: 'Array Object',
			},
			label: 'Node 4',
		},
	},
};

const configSchemaUnlabelled = {
	type: 'object',
	objectContent: {
		node1: {
			type: 'string',
		},
		node2: {
			type: 'boolean',
		},
		node3: {
			type: 'number',
		},
		node4: {
			type: 'array',
			arrayContent: {
				type: 'object',
				objectContent: {
					arrObj1: { type: 'number' },
				},
			},
		},
	},
};

// All forms should have a Yup schema defined
const schema = yup.object({
	firstName: yup
		.string()
		.required()
		.label('First Name'),
	lastName: yup
		.string()
		.required()
		.label('Last Name'),
	optionalField: yup.string().label('Optional Field'),
	selectValue: yup
		.number()
		.oneOf(selectOptions.map(({ value }) => value))
		.required()
		.label('Select'),
	selectNode: yup.string().required(),
	checkboxValue: yup.boolean().label('Checkbox'),
	conditionalField: yup
		.string()
		.when(
			// <-- conditional validation
			'checkboxValue',
			{
				is: true,
				then: yup.string().required(),
			}
		)
		.label('Conditional Field'),
	images: yup.array().label('Images'),
	dataConfigNodeSelect2: yup.string().required(),
});

/* 
Default values for all fields should be defined.
Default fields can also be stored in state and updated with API response.
Updating the default values passed into the form will automatically cause
the form to reset to those default values.
*/
const defaultValues = {
	firstName: '',
	lastName: '',
	optionalField: '',
	selectValue: null,
	checkboxValue: false,
	conditionalField: '',
	dataConfigNodeSelect: 'node3',
	dataConfigNodeSelect2: '',
	images: [],
};

/*
To conditionally render form elements, the withFormContextMethods HOC can be used
to retrieve form state/functions directly. With this, it is possible to 'watch' certain
form values by name and respond accordingly - as demonstrated below.
*/
const ConditionalFieldRenderer = withFormContextMethods(({ watch }) => {
	const isVisible = watch('checkboxValue'); // Retrieve value of 'checkboxValue'

	if (isVisible) {
		return (
			<BtFormTextField
				name="conditionalField"
				label="This Textfield Conditionally Appears"
			/>
		);
	}

	return null;
});

export default function FormDev() {
	const [sending, setSending] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const [formResult, setFormResult] = useState(null);

	// Set contextual navigation items and breadcrumbs
	const { setContextualNav, setBreadcrumbs } = useNavContext();
	useEffect(
		() => {
			setContextualNav([...developer_nav_item, ...developer_options]);
			setBreadcrumbs([
				...developer_breadcrumbs,
				{ text: 'Forms', link: '' },
			]);
			return () => setContextualNav(null);
		},
		[setContextualNav, setBreadcrumbs]
	);

	return (
		<Container maxWidth="md">
			<Typography variant="h3" style={{ marginBottom: 20 }}>
				Form Dev Demo
			</Typography>
			<BtForm
				validationSchema={schema}
				defaultValues={defaultValues}
				loading={false} // Useful for disabling form whilst fetching default values
				sending={sending} // Useful for disabling form whilst posting user submission
				/*
                The onSubmit prop takes a callback function in which to handle the submission
                of the form. The first argument passed in is an object that contains the values
                within the form. The second argument is a function to reset the form. Normally
                you would want to reset the form to the values as seen below.
                */
				onSubmit={(values, reset) => {
					setSending(true);
					console.log(values);
					setFormResult(values);
					// Would be an async call to backend instead of timeout
					setTimeout(() => {
						reset(values);
						setSending(false);
						enqueueSnackbar('Successfully saved!', {
							variant: 'success',
						});
					}, 3000);
				}}
			>
				<BtFormSection title="Form Card">
					<BtFormContent>
						{/* Lateral container allows elements to sit side-by-side, screen width permitting */}
						<BtFormLateralContainer>
							<BtFormTextField
								name="firstName"
								label="First Name"
							/>
							<BtFormTextField
								name="lastName"
								label="Last Name"
							/>
						</BtFormLateralContainer>

						{/* Optionality automatically read from schema and applied to label */}
						{/* (Not applied to fields subject to conditional validation) */}
						<BtFormTextField
							name="optionalField"
							label="Non-required Field"
						/>

						<BtFormSelect
							name="selectValue"
							label="Select"
							items={selectOptions}
						/>

						<BtFormDateTimePicker
							name="dataTimeValue"
							label="Data Time Picker"
						/>

						<BtFormDataConfigNodeSelect
							name="dataConfigNodeSelect"
							label="Schema Node Select (with labels)"
							configSchema={configSchema}
						/>

						<BtFormDataConfigNodeSelect
							name="dataConfigNodeSelect2"
							label="Schema Node Select"
							configSchema={configSchemaUnlabelled}
						/>

						<BtFormCheckbox
							name="checkboxValue"
							label="Show the conditional field?"
						/>
						<ConditionalFieldRenderer />

						<BtFormFilePicker name="files" label="File Picker" />

						{/* Action buttons for discarding/submitting the form */}
						{/* Selecting the destructive action will automatically revert state */}
						<BtFormActionButtons />

						{/* Including this element blocks navigating away if there are unsaved changes */}
						<BtFormChangesBlocker />
					</BtFormContent>
				</BtFormSection>
			</BtForm>

			{formResult && <pre>{JSON.stringify(formResult, null, 4)}</pre>}
		</Container>
	);
}
