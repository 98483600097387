import { apiClient } from './api_client';
import { uuidRegex } from './utils';

import {
	subscriptionsGetListSchema,
	subscriptionsGetSchema,
} from './validations/subscriptionsValidation';

//========================================================================================================
async function subscriptionsGetList() {
	const req_url = 'subscriptions';
	try {
		const response = await apiClient({ method: 'get', url: req_url });

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let subs = await subscriptionsGetListSchema.validate(
					response.subscriptions
				);
				return subs;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function subscriptionsGet({ subUuid }) {
	if (!uuidRegex.test(subUuid)) {
		throw new Error('Param Error');
	}

	const req_url = `subscriptions/${subUuid}`;
	try {
		const response = await apiClient({ method: 'get', url: req_url });

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let sub = await subscriptionsGetSchema.validate(
					response.subscription
				);
				return sub;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function subscriptionRemoveUser({ subUuid, userUuid }) {
	if (!uuidRegex.test(subUuid)) {
		throw new Error('Param Error');
	}

	if (!uuidRegex.test(userUuid)) {
		throw new Error('Param Error');
	}

	const req_url = `subscriptions/${subUuid}/${userUuid}`;
	try {
		const response = await apiClient({ method: 'delete', url: req_url });

		if (response) {
			if (response.result === 'OK') {
				return;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function subscriptionAddUser({ subUuid, userUuid }) {
	if (!uuidRegex.test(subUuid)) {
		throw new Error('Param Error');
	}

	if (!uuidRegex.test(userUuid)) {
		throw new Error('Param Error');
	}

	const req_url = `subscriptions/${subUuid}/${userUuid}`;
	try {
		const response = await apiClient({ method: 'put', url: req_url });

		if (response) {
			if (response.result === 'OK') {
				return;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

export {
	subscriptionsGetList,
	subscriptionsGet,
	subscriptionRemoveUser,
	subscriptionAddUser,
};
