import React from 'react';

import {
	BtFormContent,
	BtFormLateralContainer,
	BtFormSection,
	BtFormTextField,
} from '../../../components/generic/forms';

export default function SectionContactInfo() {
	return (
		<BtFormSection title="Contact Information">
			<BtFormContent>
				<BtFormTextField name="contactName" label="Contact Name" />
				<BtFormLateralContainer>
					<BtFormTextField
						name="contactEmail"
						label="Contact Email"
					/>
					<BtFormTextField
						name="telephone"
						label="Telephone Number"
					/>
				</BtFormLateralContainer>
				<BtFormTextField
					name="address"
					label="Address"
					multiline
					maxRows={4}
				/>
				<BtFormLateralContainer>
					<BtFormTextField name="townCity" label="Town/City" />
					<BtFormTextField name="regionState" label="Region/State" />
				</BtFormLateralContainer>
				<BtFormTextField name="postalZipCode" label="Postal/Zip Code" />
			</BtFormContent>
		</BtFormSection>
	);
}
