import React, {
	createContext,
	useContext,
	useRef,
	useEffect,
	useState,
} from 'react';
import propTypes from 'prop-types';
import {
	RADIAL_BAR_CHART,
	STATUS_TIMELINE,
	STATUS_TIMELINE_CHART,
} from '../Constants/constants';
import { createLayoutChangePayload } from '../../../views/Insights/InsightPage/utils';

const ChartsContext = createContext({});

export const useChartsContext = () => useContext(ChartsContext);

export default function ChartsProvider({ data, chartType, children }) {
	const [tooltipActive, setTooltipActive] = useState(false);
	const [tooltipLabel, setTooltipLabel] = useState('');
	const [tooltipLocation, setTooltipLocation] = useState({ x: 0, y: 0 });
	const [tooltipData, setTooltipData] = useState({});
	const [mousePos, setMousePos] = useState({});

	// Monitor mouse position
	useEffect(() => {
		const handleMouseMove = event => {
			setMousePos({ x: event.clientX, y: event.clientY });
		};

		window.addEventListener('mousemove', handleMouseMove);

		return () => {
			window.removeEventListener('mousemove', handleMouseMove);
		};
	}, []);

	const useRect = () => {
		const ref = useRef();
		const [rect, setRect] = useState({});

		const set = () =>
			setRect(
				ref && ref.current ? ref.current.getBoundingClientRect() : {}
			);

		const useEffectInEvent = (event, useCapture) => {
			useEffect(
				() => {
					set();
					window.addEventListener(event, set, useCapture);
					return () =>
						window.removeEventListener(event, set, useCapture);
				},
				[event, useCapture]
			);
		};

		useEffectInEvent('resize');
		useEffectInEvent('scroll', true);

		return [rect, ref];
	};

	const [rect, ref] = useRect();
	// Monitor mouse position and update tooltip location if active
	useEffect(
		() => {
			if (rect && mousePos && tooltipActive) {
				setTooltipLocation({
					x: mousePos.x - rect.x,
					y: mousePos.y - rect.y,
				});
				// if (mousePos && tooltipActive) {
				// 	setTooltipLocation({
				// 		x: mousePos.x,
				// 		y: mousePos.y,
				// 	});
			}
		},
		[rect, mousePos, tooltipActive]
		// [mousePos, tooltipActive]
	);

	// // TODO: This only handles data for the StatusTimeline currently - need to update to handle other chart types
	// // Handle mouseover events for data points
	// const onMouseOver = (lineId, segmentIndex) => {
	// 	if (lineId) {
	// 		const line = data.find(line => line.id === lineId);
	// 		setTooltipLabel(line.lineId);
	// 		setTooltipData({
	// 			id: lineId,
	// 			status: line.statusSegments[segmentIndex].status,
	// 			startTime: line.statusSegments[segmentIndex].startTime,
	// 			endTime: line.statusSegments[segmentIndex].endTime,
	// 		});
	// 	}
	// 	setTooltipActive(lineId ? true : false);
	// };

	const onMouseOverStatusTimeline = props => {
		const [lineId, segmentIndex] = props;
		if (lineId) {
			const line = data.find(line => line.id === lineId);
			setTooltipLabel(line.lineId);
			setTooltipData({
				id: lineId,
				status: line.statusSegments[segmentIndex].status,
				startTime: line.statusSegments[segmentIndex].startTime,
				endTime: line.statusSegments[segmentIndex].endTime,
			});
		}
	};

	const onMouseOverRadialBar = props => {
		const [_data] = props;
		if (_data) {
			setTooltipData({ data: _data });
			// setTooltipLabel('hello bob');
		}
	};

	const onMouseOver = props => {
		if (!props) {
			setTooltipActive(false);
			return;
		}
		// console.log('props', props);
		switch (chartType) {
			case STATUS_TIMELINE_CHART:
				onMouseOverStatusTimeline(props);
				break;
			case RADIAL_BAR_CHART:
				// onMouseOverRadialBar(props);
				break;
			default:
				() => {
					console.log(
						`No mouseOverHandler found for chart type: ${chartType}`
					);
				};
				break;
		}
		// setTooltipActive(props ? true : false);
		setTooltipActive(true);
	};

	// useEffect(
	// 	() => {
	// 		console.log('tooltipActive', tooltipActive);
	// 	},
	// 	[tooltipActive]
	// );

	return (
		<div ref={ref} style={{ height: '100%', width: '100%' }}>
			<ChartsContext.Provider
				value={{
					chartType,
					tooltipActive,
					tooltipLabel,
					tooltipLocation,
					tooltipData,
					onMouseOver,
				}}
			>
				{children}
			</ChartsContext.Provider>
		</div>
	);
}

ChartsProvider.defaultProps = {
	chartDefaults: {
		width: 500,
		height: 500,
		margin: {
			top: 10,
			right: 10,
			bottom: 10,
			left: 10,
		},
	},
	children: null,
};

ChartsProvider.propTypes = {
	chartDefaults: propTypes.shape({
		width: propTypes.number,
		height: propTypes.number,
		margin: propTypes.shape({
			top: propTypes.number,
			right: propTypes.number,
			bottom: propTypes.number,
			left: propTypes.number,
		}),
	}),
	children: propTypes.node,
	chartType: propTypes.string.isRequired,
	data: propTypes.arrayOf(propTypes.object).isRequired,
};
