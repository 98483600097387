// const {
// 	evaluateExpression,
// 	OPERATION_MODE,
// } = require('../../expression/expression.js');
const { isDocument } = require('../../utils.js');
const { setStage, ERROR } = require('../pipeline.js');
const _ = require('lodash');

const stageKey = '$sort';

setStage(
	stageKey,
	/**
	 * @type {PipelineStageFunction<{newRoot: any;},{stage:string;}>}
	 */
	function sort(context, args, options) {
		const stageKey = options.stage;

		if (!isDocument(args)) {
			throw new Error(
				ERROR.INVALID_STAGE_ARGUMENT(
					stageKey,
					'a document with properties to sort by',
					args
				)
			);
		}

		const sortProps = Object.keys(args);
		const sortDirections = sortProps.map((prop) => {
			return args[prop] === -1 ? 'desc' : 'asc';
		});

		return _.orderBy(context.collection, sortProps, sortDirections);
	}
);
