import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from '@mui/material';
import BtDialog from './BtDialog';
import { BtFormActionsContainer, BtFormContent } from './forms';

export default function BtChangeNameDescription({
	additionalChildren,
	defaultDescription,
	defaultName,
	description,
	name,
	onChange,
	onClose,
	open,
	title,
}) {
	const [_description, setDescription] = useState(
		description ?? defaultDescription ?? ''
	);
	const [_name, setName] = useState(name ?? defaultName ?? '');

	useEffect(
		() => {
			if (description) setDescription(description);
			if (name) setName(name);
		},
		[description, name]
	);

	return (
		<BtDialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<BtFormContent>
					<TextField
						label="Name"
						onChange={event => {
							setName(event.target.value);
						}}
						value={_name}
						variant="standard"
					/>
					<TextField
						label="Description"
						multiline
						onChange={event => setDescription(event.target.value)}
						value={_description}
						variant="standard"
					/>
					{additionalChildren}
				</BtFormContent>
			</DialogContent>
			<DialogActions>
				<BtFormActionsContainer>
					<Button
						disableElevation
						onClick={onClose}
						variant="outlined"
					>
						Cancel
					</Button>
					<Button
						disableElevation
						onClick={() => {
							onChange({
								description: _description,
								name: _name,
							});

							onClose();
						}}
						variant="contained"
					>
						Submit
					</Button>
				</BtFormActionsContainer>
			</DialogActions>
		</BtDialog>
	);
}

BtChangeNameDescription.defaultProps = {
	additionalChildren: null,
	title: 'Edit Name and Description',
};

BtChangeNameDescription.propTypes = {
	additionalChildren: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	defaultDescription: PropTypes.string,
	defaultName: PropTypes.string,
	description: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func,
	onClose: PropTypes.func,
	open: PropTypes.bool,
	title: PropTypes.string,
};
