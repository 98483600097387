import React, { useCallback, useContext, useMemo } from 'react';
import { Tooltip, Typography, useTheme } from '@mui/material';
import { ChevronLeft, ChevronRight, Cog } from 'mdi-material-ui';

import { useAppContext } from '../../../../../context/ContextManager';
import { ScreensContext } from '../../ScreensContext';
import { ControlContainer, TileConfigButton } from './ButtonComponents';

export const ScreenControls = () => {
	const theme = useTheme();
	const { deviceType } = useAppContext();
	const { adminMode, screens, currentScreen, setCurrentScreen } = useContext(
		ScreensContext
	);

	const handleScreenDecrement = useCallback(
		() => {
			setCurrentScreen(previousState => previousState - 1);
		},
		[setCurrentScreen]
	);

	const handleScreenIncrement = useCallback(
		() => {
			setCurrentScreen(previousState => previousState + 1);
		},
		[setCurrentScreen]
	);

	const ScreenNavButton = ({ variant }) => {
		switch (variant) {
			case 'increment':
				return (
					<TileConfigButton
						onClick={handleScreenIncrement}
						icon={<ChevronRight fontSize="large" />}
						styled
						disabled={currentScreen + 1 >= screenCount}
						style={{ marginLeft: 5 }}
					/>
				);

			case 'decrement':
				return (
					<TileConfigButton
						onClick={handleScreenDecrement}
						disabled={currentScreen === 0}
						icon={<ChevronLeft fontSize="large" />}
						styled
						style={{ marginRight: 5 }}
					/>
				);
			default:
				break;
		}
	};

	const ScreenNav = () => {
		return (
			<>
				<ScreenNavButton variant="decrement" />
				<Tooltip
					title={
						screens[currentScreen]
							? screens[currentScreen].screen_name
							: ''
					}
				>
					<Typography
						variant={deviceType === 'mobile' ? 'h5' : 'h6'}
						style={{
							paddingHorizontal: 2,
							color: 'white',
						}}
					>
						{currentScreen + 1} / {screenCount}
					</Typography>
				</Tooltip>

				{/* <TileConfigButton
					style={{ marginLeft: 5 }}
					styled
					icon={<Cog />}
				/> */}
				<ScreenNavButton variant="increment" />
			</>
		);
	};

	const screenCount = useMemo(() => screens.length, [screens.length]);

	if (adminMode) {
		return (
			<ControlContainer theme={theme} direction="row">
				<div
					style={{
						display: 'flex',
						width: 'auto',
						paddingRight: 10,
						paddingLeft: 10,
						alignItems: 'center',
						height: 30,
					}}
				>
					<Typography
						align="center"
						noWrap
						variant="body1"
						style={{
							color: 'white',
						}}
					>
						{screens[currentScreen].screen_name}
					</Typography>
				</div>
			</ControlContainer>
		);
	} else {
		switch (deviceType) {
			case 'desktop':
				return (
					<ControlContainer theme={theme} direction="row">
						<ScreenNav />
					</ControlContainer>
				);

			default:
				return (
					<ControlContainer theme={theme} direction="column">
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between',
								width: '100%',
							}}
						>
							<ScreenNav />
						</div>
						<div
							style={{
								flexDirection: 'row',
								display: 'flex',
								width: '100%',
								paddingRight: 5,
								paddingLeft: 5,
								justifyContent: 'center',
							}}
						>
							<Typography
								align="center"
								noWrap
								variant="caption"
								style={{
									color: 'white',
								}}
							>
								{screens[currentScreen].screen_name}
							</Typography>
						</div>
					</ControlContainer>
				);
		}
	}
};
