import PropTypes from 'prop-types';

import React, {
	useState,
	useCallback,
	useRef,
	useMemo,
	useEffect,
} from 'react';
import {
	DndContext,
	closestCenter,
	DragOverlay,
	useSensor,
	useSensors,
	PointerSensor,
} from '@dnd-kit/core';
import {
	arrayMove,
	SortableContext,
	rectSortingStrategy,
} from '@dnd-kit/sortable';
import Grid from './components/palette/Grid';
import SortableItem from './components/palette/SortableItem';
import Item from './components/palette/Item';
import {
	Box,
	Button,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Tooltip,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import _ from 'lodash';
import { colourSwatchGeometryStyle } from './utils/constants';
import { v4 as uuid } from 'uuid';
import { ColourPicker } from './components/colourPicker/ColourPicker';
import BtDialog from '../generic/BtDialog';

const localPaletteInitializer = palette => {
	return palette.map(({ hex, uuid, name }) => ({
		hex,
		uuid,
		id: uuid,
		name,
	}));
};

const onChangeDataTransform = data => {
	return data.map(({ uuid, hex, name }) => ({ hex, uuid, name }));
};

const NEW = 'new';
const CHANGE = 'change';

const BtPalette = ({
	palette,
	onChange,
	disabled,
	singleColour,
	disableTags,
}) => {
	const [localPalette, setLocalPalette] = useState(
		localPaletteInitializer(palette)
	);
	const [activeId, setActiveId] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [tempNewColour, setTempNewColour] = useState(null);
	const [newColour, setNewColour] = useState(null);
	const [contrastInfoOpen, setContrastInfoOpen] = useState(false);

	// disable if; the onChange is invalid, the disabled prop is true, the picker is open
	const isDisabled = useMemo(
		() => typeof onChange !== 'function' || disabled || anchorEl !== null,
		[anchorEl, disabled, onChange]
	);

	// reset the localPalette if the palette prop is updated
	useEffect(
		() => {
			setLocalPalette(localPaletteInitializer(palette));
		},
		[palette]
	);

	// restricts the triggering of the drag start,
	// required to allow the onClick to trigger
	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 4,
			},
		})
	);
	// const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

	const tempNewSwatch = useRef(null);

	const displayAddButton = useMemo(
		() => {
			if (isDisabled) {
				return false;
			} else if (singleColour) {
				return !anchorEl && !localPalette.length > 0;
			} else {
				return localPalette.length < 24;
			}
		},
		[anchorEl, isDisabled, localPalette.length, singleColour]
	);

	const handleDragStart = useCallback(event => {
		setActiveId(event.active.id);
	}, []);

	const handleDragEnd = useCallback(
		event => {
			const { active, over } = event;

			if (active.id !== over?.id) {
				const rebuildArray = () => {
					const oldIndex = localPalette.findIndex(
						element => element.id === active.id
					);
					const newIndex = localPalette.findIndex(
						element => element.id === over.id
					);
					const result = arrayMove(localPalette, oldIndex, newIndex);
					return result;
				};

				const newPalette = rebuildArray();

				setLocalPalette(newPalette);
				onChange(onChangeDataTransform(newPalette));
			}

			setActiveId(null);
		},
		[localPalette, onChange]
	);

	const handleDragCancel = useCallback(() => {
		setActiveId(null);
	}, []);

	const handleClick = (event, colour, mode) => {
		// console.log('handleClick', { event, colour, mode });
		if (mode === CHANGE) {
			setAnchorEl(
				anchorEl
					? null
					: {
							element: event.currentTarget,
							id: colour.id,
							mode: mode,
					  }
			);
		} else {
			const newColourUuid = uuid();
			setTempNewColour({
				uuid: newColourUuid,
				id: newColourUuid,
				hex: '#5fc1c9',
				name: `Colour ${localPalette.length + 1}`,
			});
			setAnchorEl(
				anchorEl
					? null
					: {
							element: tempNewSwatch.current,
							mode: mode,
					  }
			);
		}
	};

	// const handleOpenContrastInfo = useCallback(event => {
	// 	event.stopPropagation();
	// 	setContrastInfoOpen(true);
	// }, []);

	const handleCloseContrastInfo = useCallback(() => {
		setContrastInfoOpen(false);
	}, []);

	// onChangeName={newName => {
	//     anchorEl?.mode === CHANGE
	//         ? handleNameUpdate(newName)
	//         : handleNewName(newName);
	// }}

	const handleClose = () => {
		if (anchorEl?.mode === CHANGE) {
			// handleNameUpdate();
			// evaluate if there are any changes to the palette, if yes trigger the onChange
			const changesToDispatch = !_.isEqual(
				localPalette,
				localPaletteInitializer(palette)
			);
			if (changesToDispatch) {
				onChange(onChangeDataTransform(localPalette));
			}
		} else if (anchorEl?.mode === NEW) {
			const newColourObj = {};
			newColourObj.uuid = tempNewColour.uuid;
			newColourObj.id = tempNewColour.uuid;
			newColourObj.hex = tempNewColour.hex;
			newColourObj.name = tempNewColour.name;
			// console.log('store new colour:', newColourObj, tempNewColour);

			// if popover is used in picker the below is required
			// setNewColour(newColourObj);

			// if popover is used in picker the below is not required as the picker fires a function which does these tasks
			setTempNewColour(null);
			const newPalette = [...localPalette, newColourObj];
			setLocalPalette(newPalette);
			onChange(onChangeDataTransform(newPalette));
		}
		setAnchorEl(null);
	};

	const handleUpdate = useCallback(
		update => {
			const paletteUpdate = [...localPalette];

			const colourUpdate = {
				...localPalette.find(colour => colour.uuid === anchorEl.id),
			};

			Object.keys(update).forEach(item => {
				colourUpdate[item] = update[item];
			});

			paletteUpdate.splice(
				localPalette.findIndex(colour => colour.uuid === anchorEl.id),
				1,
				colourUpdate
			);

			// console.log({ paletteUpdate, update });
			// if there are changes to the palette, set the local state
			if (!_.isEqual(paletteUpdate, localPalette)) {
				// console.log('setting local palette');
				setLocalPalette(paletteUpdate);
			}
		},
		[anchorEl, localPalette]
	);

	// dispatches colour update to the generic update handler
	const handleColourUpdate = useCallback(
		newColourHex => {
			handleUpdate({ hex: newColourHex });
		},
		[handleUpdate]
	);

	// // dispatches tag update to the generic update handler
	// const handleTagsUpdate = useCallback(
	// 	newTags => {
	// 		handleUpdate({ tags: newTags });
	// 	},
	// 	[handleUpdate]
	// );

	// // handle changes to a new colour tags
	// // the new colour has local state until committed to the palette
	// const handleNewTags = newTags => {
	// 	const newTempColour = { ...tempNewColour, tags: newTags };
	// 	setTempNewColour(newTempColour);
	// };

	// dispatches tag update to the generic update handler
	const handleNameUpdate = useCallback(
		newName => {
			handleUpdate({ name: newName });
		},
		[handleUpdate]
	);

	// handle changes to a new colour tags
	// the new colour has local state until committed to the palette
	const handleNewName = useCallback(
		newName => {
			const newTempColour = { ...tempNewColour, name: newName };
			setTempNewColour(newTempColour);
		},
		[tempNewColour]
	);

	// handle changes to a new colour
	// the new colour has local state until committed to the palette
	const handleNewColourChange = colour => {
		const newTempColour = { ...tempNewColour, hex: colour };
		setTempNewColour(newTempColour);
	};

	const handleColourAdd = () => {
		if (newColour) {
			setTempNewColour(null);
			const newPalette = [...localPalette, newColour];
			setLocalPalette(newPalette);
			onChange(onChangeDataTransform(newPalette));
			setNewColour(null);
		}
	};

	const handleColourRemove = id => {
		if (anchorEl.mode === NEW) {
			setTempNewColour(null);
			setAnchorEl(null);
		} else {
			const index = localPalette.findIndex(e => id === e.id);
			const update = [...localPalette];
			update.splice(index, 1);
			setAnchorEl(null);
			setLocalPalette(update);
			onChange(update);
		}
	};

	const handleChangeName = useCallback(
		newName => {
			anchorEl?.mode === CHANGE
				? handleNameUpdate(newName)
				: handleNewName(newName);
		},
		[anchorEl, handleNameUpdate, handleNewName]
	);

	return (
		<>
			<DndContext
				sensors={sensors}
				collisionDetection={closestCenter}
				onDragStart={handleDragStart}
				onDragEnd={handleDragEnd}
				onDragCancel={handleDragCancel}
			>
				<SortableContext
					items={localPalette}
					strategy={rectSortingStrategy}
					disabled={isDisabled}
				>
					<Grid>
						{localPalette.map(colour => (
							<Tooltip
								arrow
								placement="top-end"
								key={colour.id}
								title={colour.name}
							>
								<span>
									<SortableItem
										colour={colour.hex}
										id={colour.id}
										onClick={event =>
											!isDisabled &&
											handleClick(event, colour, CHANGE)
										}
										disabled={isDisabled}
									>
										{/* {
									<Tooltip title="contrast warning">
										<Box
											sx={{
												// ...colourSwatchGeometryStyle,
												// clipPath:
												// 	'polygon(20% 0, 100% 0, 100% 80%)',
												// backgroundColor: 'ButtonFace',
												display: 'flex',
												alignItems: 'flex-start',
												justifyContent: 'flex-end',
												padding: '3px',
											}}
										>
											<IconButton
												onClick={handleOpenContrastInfo}
											>
												<ExclamationThick
													sx={{
														color: theme.palette.getContrastText(
															colour.hex
														),
													}}
												/>
											</IconButton>
										</Box>
									</Tooltip>
								} */}
									</SortableItem>
								</span>
							</Tooltip>
						))}
						<Box
							sx={{
								...colourSwatchGeometryStyle,
								backgroundColor: tempNewColour?.hex,
								display: tempNewColour ? 'inline' : 'none',
							}}
							ref={tempNewSwatch}
						/>
						{displayAddButton && (
							<Button
								sx={{
									...colourSwatchGeometryStyle,
								}}
								variant={'outlined'}
								onClick={event => handleClick(event, null, NEW)}
								disabled={isDisabled}
							>
								{<Add />}
							</Button>
						)}
					</Grid>
				</SortableContext>
				<DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
					{activeId ? (
						<Item
							colour={
								localPalette.find(item => item.id === activeId)
									.hex
							}
							id={activeId}
							isDragging
						/>
					) : null}
				</DragOverlay>
			</DndContext>
			<ColourPicker
				onClose={handleClose}
				anchorEl={anchorEl?.element}
				inputColour={
					anchorEl?.mode === CHANGE
						? localPalette.find(
								colour => colour.uuid === anchorEl?.id
						  )?.hex
						: tempNewColour?.hex
				}
				onChangeInputColour={nextColour => {
					anchorEl?.mode === CHANGE
						? handleColourUpdate(nextColour)
						: handleNewColourChange(nextColour);
				}}
				// onCommitChange={handleColourAdd} // used if picker uses popover - TODO, decide on popper or popover and clean up code
				onRemoveColour={() => handleColourRemove(anchorEl.id)}
				name={
					anchorEl?.mode === CHANGE
						? localPalette.find(
								colour => colour.uuid === anchorEl?.id
						  )?.name
						: tempNewColour?.name
				}
				names={localPalette.map(({ name }) => name)}
				newColour={!!tempNewColour}
				onChangeName={handleChangeName}
			/>
			<BtDialog
				maxWidth="xs"
				onClose={handleCloseContrastInfo}
				open={contrastInfoOpen}
			>
				<DialogTitle>Contrast Warning</DialogTitle>
				<DialogContent>
					<DialogContentText>
						This colour does not meet the recommended level of
						contrast against the ***enter theme***
					</DialogContentText>
				</DialogContent>

				<DialogActions>
					<Button onClick={handleCloseContrastInfo}>Close</Button>
				</DialogActions>
			</BtDialog>
		</>
	);
};

const paletteItemShape = PropTypes.shape({
	hex: PropTypes.string.isRequired,
	uuid: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
});

BtPalette.propTypes = {
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	palette: PropTypes.arrayOf(paletteItemShape).isRequired,
	singleColour: PropTypes.bool,
};

export default BtPalette;
