const yup = require('yup');

const subscriptionsGetListSchema = yup.array().of(
	yup.object({
		uuid: yup
			.string()
			.uuid()
			.required(),
		subscription: yup.string().required(),
		description: yup.string().required(),
		type: yup
			.string()
			.required()
			.oneOf(['organisation', 'user']),
		maxUsers: yup.number().required(),
		assigned: yup.number().required(),
		renewalCost: yup.string().nullable(),
		renewalDate: yup.number().required(),
		users: yup
			.array()
			.of(yup.string().uuid())
			.required(),
	})
);

/*
	-- Example response data --
{
	"uuid": "0ea9a31e-c6ef-11ec-9d64-0242ac120002",
    "subscription": "Insights Viewer",
    "description": "View Insights dashboards",
    "type": "user",
    "maxUsers": 5,
    "assigned": 4,
    "renewalCost": "£30",
    "renewalDate": 1682681138,
    "users": [
      "adb1186b-5505-4f45-bb87-10dc0e496f00",
      "5eff1493-a858-4b11-8286-6dc956c382f3",
      "544b99c1-7cdf-4433-a88e-73f71c6422a4",
      "deeed36b-3e6f-43d7-82b4-7689b41cdd13"
    ]
}
*/

const subscriptionsGetSchema = yup.object({
	uuid: yup
		.string()
		.uuid()
		.required(),
	subscription: yup.string().required(),
	description: yup.string().required(),
	type: yup
		.string()
		.required()
		.oneOf(['organisation', 'user']),
	maxUsers: yup.number().required(),
	assigned: yup.number().required(),
	renewalCost: yup.string().nullable(),
	renewalDate: yup.number().required(),
	users: yup
		.array()
		.of(yup.string().uuid())
		.required(),
});

module.exports = {
	subscriptionsGetListSchema,
	subscriptionsGetSchema,
};
