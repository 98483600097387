import { AccountCog } from 'mdi-material-ui';

export const user_settings_nav_item = [
	{
		id: 'UserSettings',
		route: '/UserSettings',
		icon: <AccountCog />,
		text: 'User Settings',
		description: 'View and change your settings',
	},
];
