import { Box, Container, Typography, styled } from '@mui/material';
import React, { memo } from 'react';

const gapSize = '15px';

const gapHalf = '7.5px';

const topMarginStyle = { marginTop: gapSize };

const TabPanelContainer = styled(Container)({
	...topMarginStyle,
	gap: gapSize,
	display: 'flex',
	flexDirection: 'column',
});

const StyledDiv = styled('div')(({ style }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	...style,
}));

const TabPanelTitle = memo(function TabPanelTitle({
	title,
	ButtonComponent,
	style,
}) {
	return (
		<StyledDiv style={style}>
			<Typography variant="h4">{title && title}</Typography>
			{ButtonComponent && ButtonComponent}
		</StyledDiv>
	);
});

const TabLateralContainer = styled(Box)(({ theme, width }) => ({
	display: 'flex',
	flexDirection: 'column',
	rowGap: gapSize,

	[theme.breakpoints.up('sm')]: {
		width: width ? width : '50%',
		padding: `0px ${gapHalf} ${gapHalf} ${gapHalf}`,
	},

	[theme.breakpoints.down('md')]: {
		width: '100%',
		padding: `0px 0px ${gapSize} 0px`,
	},
}));

const TabLateralWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',

	[theme.breakpoints.down('sm')]: {
		flexDirection: 'column',
		flexWrap: 'nowrap',
	},

	[theme.breakpoints.up('xs')]: {
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
}));

const TabBarContainer = styled(Box)(() => ({
	width: '100%',
	display: 'flex',
}));

const TabBarTabsContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	backgroundColor: theme.palette.background.default,
	height: '62px',
	borderBottom: `2px solid ${
		theme.palette.mode === 'dark' ? '#ffffff3a' : '#0000001d'
	}`,
	width: '100%',
}));

const margin = '15px';

const TabBarButtonBoxMobile = styled(Box)({
	padding: `${margin} ${margin} 0px ${margin}`,
});

const TabBarButtonBoxDesktop = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: '62px',
	width: '300px',
	borderBottom: `2px solid ${
		theme.palette.mode === 'dark' ? '#727272' : '#c8c8c8'
	}`,
}));

export {
	TabPanelContainer,
	TabPanelTitle,
	TabLateralContainer,
	TabLateralWrapper,
	TabBarContainer,
	TabBarTabsContainer,
	TabBarButtonBoxMobile,
	TabBarButtonBoxDesktop,
};
