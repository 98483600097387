import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Auth } from 'aws-amplify';
import { useAppContext } from '../../context/ContextManager';

// COMPONENTS
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider, InputLabel } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ListTable, ListRow, ListHeader, ListCell } from '../generic/ListTable';

//import 'date-fns';
//import DateFnsUtils from '@date-io/date-fns';
/*import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';*/
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

const styles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(2),
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
	},
	dialogTitle: {
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.contrastText,
	},
	dialogContent: {
		minWidth: '600px',
		minHeight: '500px',
	},
	selectionText: {
		display: 'inline',
		borderBottom: '1px',
		fontSize: '1.5rem',
		lineHeight: '24px',
	},
	selectionDiv: {
		borderTop: '1px solid red',
		borderTopColor: theme.palette.primary.shade_dark,
		flexGrow: 1,
	},
	selectionDivRow: {
		cursor: 'pointer',
		borderBottom: '1px solid red',
		borderBottomColor: theme.palette.primary.shade_dark,
		'&:hover': {
			backgroundColor: theme.palette.primary.shade_light,
		},
	},
	selectionCheck: {
		margin: 5,
	},
	flexBox: {
		height: '300px',
	},
	textField: {
		backgroundColor: '#f5f5f5',
		margin: '0px 0',
		fontSize: '2rem',
	},
	deploySelect: {
		minWidth: '200px',
	},
	datePicker: {
		marginTop: 40,
	},
}));

function getSteps() {
	return ['Select Firmware', 'Select Version', 'How to deploy', 'Confirm'];
}

// function versionCompare( a, b ) {
//     if ( a.version < b.version ){
//       return 1;
//     }
//     if ( a.version > b.version ){
//       return -1;
//     }
//     return 0;
//   }

export default function SelectConfigDialog({ setOpen, onClose, deviceInfo }) {
	const classes = styles();
	const [isLoading, setIsLoading] = useState(true);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [fileList, setFileList] = useState([]);
	const [fileVersions, setFileVersions] = useState([]);
	const [selectedResourceUUID, setSelectedResourceUUID] = useState('');
	const [selectedFileUUID, setSelectedFileUUID] = useState('');
	const [selectedFilename, setSelectFilename] = useState('');
	const [selectedVersionStr, setSelectedVersionStr] = useState('');
	const [delpomentType, setDelpomentType] = useState('now');
	const [nextDisabled, setNextDisabled] = useState(true);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const { appOrg } = useAppContext();

	const [activeStep, setActiveStep] = useState(0);
	const steps = getSteps();

	const handleSelectConfigClose = () => {
		onClose();
	};

	const onFileClick = (resource_uuid, filename) => event => {
		setSelectedResourceUUID(resource_uuid);
		setSelectFilename(filename);
		setSelectedFileUUID('');
		setNextDisabled(false);
	};

	const onVersionClick = (file_uuid, version_str) => event => {
		setSelectedFileUUID(file_uuid);
		setSelectedVersionStr(version_str);
		setNextDisabled(false);
	};

	const handleChangeDeploment = event => {
		setDelpomentType(event.target.value);
	};

	const handleDateChange = date => {
		setSelectedDate(date);
	};

	// This is called only when the dialog is opened
	const handleOnEnter = () => {
		var req_url =
			process.env.REACT_APP_API_SERVER_URL +
			'/v1/file?file_type=firmware&device_type=' +
			deviceInfo.type.uuid;

		Auth.currentSession().then(user_session => {
			let user_token = user_session.getAccessToken();
			fetch(req_url, {
				method: 'GET',
				headers: {
					Authorization: user_token.jwtToken,
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Organisation: appOrg.uuid,
				},
			})
				.then(res => res.json())
				.then(response => {
					setFileList(response.file_list);
					setIsLoading(false);
				})
				.catch(error => {
					alert('Server Error!');
					setIsLoading(false);
				});
		});
	};

	const handleCancel = () => {
		setActiveStep(0);
		onClose();
	};
	const handleBack = () => {
		setActiveStep(prevActiveStep => prevActiveStep - 1);
		setNextDisabled(false);
	};
	const handleReset = () => {
		setActiveStep(0);
		onClose();
	};
	const handleNext = () => {
		setActiveStep(prevActiveStep => prevActiveStep + 1);

		var current_step = activeStep + 1;

		switch (current_step) {
			case 1:
				setNextDisabled(true);
				setIsLoading(true);
				var req_url =
					process.env.REACT_APP_API_SERVER_URL +
					'/v1/file/history/' +
					selectedResourceUUID;

				Auth.currentSession().then(user_session => {
					let user_token = user_session.getAccessToken();
					fetch(req_url, {
						method: 'GET',
						headers: {
							Authorization: user_token.jwtToken,
							Accept: 'application/json',
							'Content-Type': 'application/json',
							Organisation: appOrg.uuid,
						},
					})
						.then(res => res.json())
						.then(response => {
							setFileVersions(
								response.file_data.versions
									.sort(
										(a, b) =>
											a.create_timestamp >
											b.create_timestamp
												? -1
												: b.create_timestamp >
												  a.create_timestamp
													? 1
													: 0
									)
									.slice(0, 8)
							);
							setIsLoading(false);
						})
						.catch(error => {
							alert('Server Error');
							setIsLoading(false);
						});
				});
				break;

			case 4:
				submitTask();
				break;
			default:
				break;
		}
	};

	const getDeployContent = type => {
		switch (type) {
			case 'now':
				return (
					<Typography variant="subtitle1">
						The new configuration will be deplyed to the device as
						soon as possible.
					</Typography>
				);
			case 'time_delay':
				return (
					<Grid container direction="column" alignItems="center">
						<Grid item>
							<Typography variant="subtitle1">
								The new configuration will be deployed the next
								time the device connects after the time.
							</Typography>
						</Grid>
						<Grid item>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DatePicker
									label="Deployment Date Time"
									format="MM/dd/yyyy HH:mm:ss"
									value={selectedDate}
									onChange={handleDateChange}
									className={classes.datePicker}
								/>
							</LocalizationProvider>
						</Grid>
					</Grid>
				);
			case 'event_delay':
				return <div>event delay</div>;
			default:
				return <div>Type Error</div>;
		}
	};

	const getStepContent = step => {
		switch (step) {
			case 0: // ==== FILE SELECTION ====
				return (
					<div className={classes.selectionDiv}>
						{fileList.map((file, index) => (
							<Grid
								container
								key={index}
								direction="row"
								alignItems="center"
								className={classes.selectionDivRow}
								onClick={onFileClick(
									file.resource_uuid,
									file.filename
								)}
							>
								{selectedResourceUUID !==
									file.resource_uuid && (
									<Grid item xs={1}>
										<RadioButtonUncheckedIcon
											className={classes.selectionCheck}
										/>
									</Grid>
								)}
								{selectedResourceUUID ===
									file.resource_uuid && (
									<Grid item xs={1}>
										<RadioButtonCheckedIcon
											className={classes.selectionCheck}
											color="primary"
										/>
									</Grid>
								)}
								<Grid item xs={11}>
									<Typography
										variant="h6"
										className={classes.selectionText}
									>
										{file.filename}
									</Typography>
								</Grid>
							</Grid>
						))}
					</div>
				);
			case 1: // ==== VERSION SELECTION ====
				return (
					<div className={classes.selectionDiv}>
						{fileVersions.map((version, index) => (
							<Grid
								container
								key={index}
								direction="row"
								alignItems="center"
								className={classes.selectionDivRow}
								onClick={onVersionClick(
									version.file_uuid,
									version.version_str
								)}
							>
								{selectedFileUUID !== version.file_uuid && (
									<Grid item xs={1}>
										<RadioButtonUncheckedIcon
											className={classes.selectionCheck}
										/>
									</Grid>
								)}
								{selectedFileUUID === version.file_uuid && (
									<Grid item xs={1}>
										<RadioButtonCheckedIcon
											className={classes.selectionCheck}
											color="primary"
										/>
									</Grid>
								)}
								<Grid item xs={11}>
									<Typography
										variant="h6"
										className={classes.selectionText}
									>
										{selectedFilename}:{version.version_str}
									</Typography>
								</Grid>
							</Grid>
						))}
					</div>
				);
			case 2:
				return (
					<div>
						<Grid
							container
							direction="row"
							alignItems="center"
							spacing={2}
						>
							<Grid item>
								<Typography variant="h6">
									Deployment Type:
								</Typography>
							</Grid>
							<Grid item>
								<FormControl
									variant="outlined"
									// className={classes.formControl}
								>
									{/* test change */}
									{/* <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={delpomentType}
                                        onChange={handleChangeDeploment}
                                        className={classes.deploySelect}
                                    >
                                        <MenuItem value={'now'}>Update Now</MenuItem>
                                        <MenuItem value={'time_delay'}>Update on Time</MenuItem>
                                        <MenuItem value={'event_delay'}>Update on Event</MenuItem>
                                    </Select> */}
									<InputLabel id="demo-simple-select-label">
										Deployment Type
									</InputLabel>
									<Select
										labelId="demo-simple-select-outlined-label"
										id="demo-simple-select-outlined"
										value={delpomentType}
										label="Deployment Type"
										onChange={handleChangeDeploment}
										// className={classes.deploySelect}
									>
										<MenuItem value={'now'}>
											Update Now
										</MenuItem>
										<MenuItem value={'time_delay'}>
											Update on Time
										</MenuItem>
										<MenuItem value={'event_delay'}>
											Update on Event
										</MenuItem>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
						<Divider />
						{getDeployContent(delpomentType)}
					</div>
				);
			case 3:
				return (
					<div>
						<Typography>
							Confirm you are happy to deploy the firmware.
						</Typography>
						<Divider />
						<ListTable>
							<ListRow>
								<ListHeader>Firmware:</ListHeader>
								<ListCell>{selectedFilename}</ListCell>
							</ListRow>
							<ListRow>
								<ListHeader>Version:</ListHeader>
								<ListCell>{selectedVersionStr}</ListCell>
							</ListRow>
							<ListRow>
								<ListHeader>Deployment Type:</ListHeader>
								<ListCell>
									{delpomentType === 'now' && <>Deploy now</>}
									{delpomentType === 'time_delay' && (
										<>
											Deploy at{' '}
											{selectedDate.toLocaleString()}
										</>
									)}
									{delpomentType === 'event_delay' && (
										<>Deploy at event Restart</>
									)}
								</ListCell>
							</ListRow>
							<ListRow>
								<ListHeader>File UUID:</ListHeader>
								<ListCell>{selectedFileUUID}</ListCell>
							</ListRow>
						</ListTable>
					</div>
				);
			default:
				return <Typography>Unknown step</Typography>;
		}
	};

	const submitTask = () => {
		setIsSubmitting(true);

		var device_update_str = {};
		device_update_str['firmware'] = selectedFileUUID;

		var req_url =
			process.env.REACT_APP_API_SERVER_URL +
			'/v1/device/firmware/' +
			deviceInfo.uid;

		Auth.currentSession().then(user_session => {
			let user_token = user_session.getAccessToken();
			fetch(req_url, {
				method: 'PUT',
				headers: {
					Authorization: user_token.jwtToken,
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Organisation: appOrg.uuid,
				},
				body: JSON.stringify(device_update_str),
			})
				.then(res => res.json())
				.then(response => {
					if (response['result'] !== 'OK') {
					} else {
					}
					setIsLoading(false);
				})
				.catch(error => {
					alert('Server Error!');
					setIsLoading(false);
				});
		});
	};

	return (
		<>
			<Dialog
				open={setOpen}
				onClose={handleSelectConfigClose}
				className={classes.root}
				TransitionProps={{
					onEnter: handleOnEnter,
				}}
			>
				<DialogTitle
					id="form-dialog-title"
					className={classes.dialogTitle}
				>
					Select Device Firmware
				</DialogTitle>
				<DialogContent className={classes.dialogContent}>
					{isLoading && (
						<>
							<p>Loading...</p>
							<CircularProgress size={'120px'} />
						</>
					)}

					{!isLoading && (
						<>
							<Stepper activeStep={activeStep}>
								{steps.map((label, index) => {
									const stepProps = {};
									const labelProps = {};

									return (
										<Step key={label} {...stepProps}>
											<StepLabel {...labelProps}>
												{label}
											</StepLabel>
										</Step>
									);
								})}
							</Stepper>
							<Divider />

							{activeStep === steps.length ? (
								<div>
									{isSubmitting && (
										<>
											<p>Loading...</p>
											<CircularProgress size={'120px'} />
										</>
									)}

									{!isSubmitting && (
										<Box
											display="flex"
											flexDirection="column"
										>
											<Box
												flexGrow={1}
												className={classes.flexBox}
											>
												<Typography
													className={classes.steps}
												>
													New task deployed.
												</Typography>
												<Typography>
													Task UUID: TODO
												</Typography>
											</Box>
											<Box>
												<Divider />
												<Box
													display="flex"
													flexDirection="row-reverse"
												>
													<Button
														variant="outlined"
														startIcon={
															<CloseIcon />
														}
														onClick={handleReset}
														className={
															classes.button
														}
													>
														Close
													</Button>
												</Box>
											</Box>
										</Box>
									)}
								</div>
							) : (
								<Box display="flex" flexDirection="column">
									<Box
										flexGrow={1}
										className={classes.flexBox}
									>
										{getStepContent(activeStep)}
									</Box>
									<Box>
										<Divider />
										<Box
											display="flex"
											flexDirection="row-reverse"
										>
											<Button
												variant="outlined"
												startIcon={<ArrowForwardIcon />}
												disabled={nextDisabled}
												onClick={handleNext}
												className={classes.button}
											>
												{activeStep === steps.length - 1
													? 'Deploy'
													: 'Next'}
											</Button>

											<Button
												variant="outlined"
												startIcon={<ArrowBackIcon />}
												disabled={activeStep === 0}
												onClick={handleBack}
												className={classes.button}
											>
												Back
											</Button>

											<Button
												variant="outlined"
												startIcon={<CloseIcon />}
												onClick={handleCancel}
												className={classes.button}
											>
												Cancel
											</Button>
										</Box>
									</Box>
								</Box>
							)}
						</>
					)}
				</DialogContent>
			</Dialog>
		</>
	);
}
