const { deepCopy } = require('../../utils');
const { setStageSchemaFn } = require('../pipeline');

const identityStages = ['$match', '$sort', '$limit', '$skip'];

identityStages.forEach((stage) => {
	setStageSchemaFn(stage, (context, args, options) => {
		return deepCopy(context.schema);
	});
});

require('./group.js');
require('./project.js');
require('./unwind.js');
require('./replaceRoot.js');
