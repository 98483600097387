const STATUS_TIMELINE_CHART = 'StatusTimelineChart';
const RADIAL_BAR_CHART = 'RadialBarChart';
const GAUGE_CHART = 'GaugeChart';
const ChartTypes = [STATUS_TIMELINE_CHART, RADIAL_BAR_CHART, GAUGE_CHART];

const RADIAL_CHART_TYPES = [RADIAL_BAR_CHART, GAUGE_CHART];
const CARTESIAN_CHART_TYPES = [STATUS_TIMELINE_CHART];

const BAR = 'Bar';
const LINE = 'Line';
const STATUS_TIMELINE = 'StatusTimeline';
const CARTESIAN_CHART_ELEMENTS = [BAR, STATUS_TIMELINE];

const RADIAL_BAR = 'RadialBar';
const GAUGE_PRIMARY_AXIS = 'GaugePrimaryAxis';
const GAUGE_SECONDARY_AXIS = 'GaugeSecondaryAxis';
const SECTOR = 'Sector';
const RADIAL_BAR_CHART_ELEMENTS = [
	RADIAL_BAR,
	GAUGE_PRIMARY_AXIS,
	GAUGE_SECONDARY_AXIS,
	SECTOR,
];

export { BAR, LINE, STATUS_TIMELINE, CARTESIAN_CHART_ELEMENTS };

export { STATUS_TIMELINE_CHART, RADIAL_BAR_CHART, GAUGE_CHART, ChartTypes };

export { RADIAL_BAR, RADIAL_BAR_CHART_ELEMENTS };

export { RADIAL_CHART_TYPES, CARTESIAN_CHART_TYPES };
