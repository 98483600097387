import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import * as yup from 'yup';

import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ActionButtonContainer from '../../../components/generic/ActionButtonContainer';
import BtDialog from '../../../components/generic/BtDialog';
import TilesTextField from '../../../components/generic/screens/components/formComponents/TilesTextField';
import {
	ActionButton,
	CancelButton,
} from '../../../components/generic/screens/components/screensConfig/ScreenConfigDialogContent';

const schema = yup
	.object({
		textField: yup
			.string()
			.required()
			.min(4)
			.max(30)
			.label('Screen name'),
	})
	.required();

export const RenameScreenDialog = ({
	open,
	onClose,
	screen,
	onScreenChange,
}) => {
	const [newScreenName, setNewScreenName] = useState('');
	const [isValid, setIsValid] = useState(false);

	const handleSubmit = useCallback(
		() => {
			onScreenChange({
				mode: 'adminRename',
				screen,
				newScreenName,
			});
			onClose();
		},
		[newScreenName, onClose, onScreenChange, screen]
	);

	return (
		<BtDialog open={open} onClose={onClose} maxWidth="sm">
			<DialogTitle>Rename Screen </DialogTitle>
			<>
				<DialogContent>
					<TilesTextField
						setIsTextFieldValid={setIsValid}
						onChange={setNewScreenName}
						initialValue={screen?.screen_name}
						schema={schema}
						label="Screen name"
					/>
				</DialogContent>
				<DialogActions>
					<ActionButtonContainer>
						<CancelButton onClose={onClose} />
						<ActionButton
							label="confirm"
							disabled={!isValid}
							onClick={handleSubmit}
						/>
					</ActionButtonContainer>
				</DialogActions>
			</>
		</BtDialog>
	);
};

RenameScreenDialog.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	onScreenChange: PropTypes.func.isRequired,
	screen: PropTypes.object.isRequired,
};
