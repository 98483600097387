import React, { useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import DataViewsIcon from '@mui/icons-material/Pageview';
import DataSetsIcon from '@mui/icons-material/Storage';

import useOverflowedElementCount from '../../../../hooks/useOverflowedElementCount';
import { getStageType } from '../processing';
import { useQueryBuilderDataContext } from '../context';

function getQueryStages(query) {
	let queryObj = query || [];
	if (typeof query === 'string') {
		queryObj = JSON.parse(query);
	}

	return queryObj.map(s => getStageType(s));
}

export default function BtQueryPreview(props) {
	const containerRef = useRef();

	const { resource, resourceGroup } = useQueryBuilderDataContext();

	const {
		assessOverflow: recheckOverflow,
		count: overflowCount,
		topRowWidth: moreIndicatorPos,
		firstHiddenIndex,
	} = useOverflowedElementCount(containerRef, true);

	const hasHidden = useMemo(() => +(overflowCount > 0), [overflowCount]);

	const { query, onStageClick } = props;

	const queryData = useMemo(
		() => {
			try {
				return {
					stages: getQueryStages(query),
				};
			} catch (e) {
				return {
					error: e,
				};
			}
		},
		[query]
	);

	useEffect(
		() => {
			recheckOverflow();
		},
		[recheckOverflow, queryData]
	);

	return (
		<>
			<Button
				onClick={() => onStageClick(0)}
				variant="outlined"
				startIcon={<GolfCourseIcon />}
				size="small"
			>
				<Tooltip title="Open the query builder">
					<div
						style={{
							width: '100%',
							position: 'relative',
							// marginLeft: -3,
							// overflow: 'hidden',
							paddingLeft: '1rem',
						}}
					>
						{resourceGroup && (
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-start',
									alignItems: 'center',
									component: 'span',
									flexWrap: 'wrap',
									overflow: 'hidden',
									boxSizing: 'borderBox',
									marginBottom: '1px',
								}}
							>
								{resourceGroup.key === 'data_views' ? (
									<DataViewsIcon
										fontSize="small"
										sx={{ mr: '1rem' }}
									/>
								) : (
									<DataSetsIcon
										fontSize="small"
										sx={{ mr: '1rem' }}
									/>
								)}
								<Typography variant="button" component="span">
									{resourceGroup.name}
								</Typography>
								<ChevronRightIcon />
								{resource && (
									<Typography
										variant="button"
										component="span"
									>
										{resource.name}
									</Typography>
								)}
							</div>
						)}
						<div
							ref={containerRef}
							style={{
								maxHeight: '25px',
								display: 'flex',
								justifyContent: 'flex-start',
								alignItems: 'center',
								component: 'span',
								flexWrap: 'wrap',
								overflow: 'hidden',
								boxSizing: 'borderBox',
								marginBottom: '1px',
								paddingRight: hasHidden ? 26 : 0,
							}}
						>
							{queryData?.error ? (
								<Typography variant="button" component="span">
									Error in query
								</Typography>
							) : queryData?.stages?.length === 0 ? (
								<Typography variant="button" component="span">
									No stages
								</Typography>
							) : (
								queryData?.stages
									.slice(0, 50)
									.map((stageType, index) => (
										<Box
											key={index}
											sx={{
												display: 'flex',
												justifyContent: 'flex-start',
												alignItems: 'center',
											}}
										>
											<Box
												key={index}
												sx={{ paddingX: 0 }}
											>
												{/* <Button key={index} onClick={() => onStageClick(index)}> */}
												<Typography
													variant="button"
													component="span"
												>
													{stageType}
												</Typography>
												{/* </Button> */}
											</Box>
											{index !==
												queryData.stages.length - 1 && (
												<ChevronRightIcon />
											)}
										</Box>
									))
							)}
						</div>
						{queryData?.stages &&
							firstHiddenIndex > 0 && (
								<Tooltip
									title={`Showing ${(queryData?.stages
										?.length || 0) -
										firstHiddenIndex} of ${queryData?.stages
										?.length ||
										0} stages.\nOpen the query builder to view all stages.`}
									disableInteractive
								>
									<Box
										sx={{
											position: 'absolute',
											right: 0,
											bottom: 0,
											paddingBottom: 0.5,
										}}
									>
										{(queryData?.stages?.length || 0) < 50
											? `+${(queryData?.stages?.length ||
													0) - firstHiddenIndex}`
											: '...'}
									</Box>
								</Tooltip>
							)}
					</div>
				</Tooltip>
			</Button>
		</>
	);
}

BtQueryPreview.propTypes = {
	query: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.object),
	]),
	onStageClick: PropTypes.func,
};
