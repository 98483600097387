import React, { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { has, isEqual } from 'lodash';

const EditFormContext = createContext(null);

const useEditFormContext = () => {
	const context = useContext(EditFormContext);
	if (context === undefined) {
		throw new Error('EditFormContext was used outside of its Provider');
	}
	return context;
};

const buildSelectOptions = optionsSourceArray => {
	return optionsSourceArray.map(({ uuid, name }) => ({
		value: uuid,
		label: name,
	}));
};

// const TransformController = withFormContextMethods(({watch, setValue}))
const findItem = (array, itemUuid) => {
	return array.find(({ uuid }) => uuid === itemUuid);
};

const initTransformOptions = dataSources => {
	if (dataSources) {
		// const currentDataSource = findItem(dataSources, dataSource);
		// return buildSelectOptions(currentDataSource.transforms);

		const transformOptions = {};
		dataSources.forEach(
			source =>
				(transformOptions[source.uuid] = buildSelectOptions(
					source.transforms
				))
		);
		return transformOptions;
	} else {
		return [];
	}
};

function EditFormContextProvider({
	component,
	children,
	collection,
	palette,
	paletteConfig,
	collectionPalettes,
}) {
	const [insightCollection, setInsightCollection] = useState(collection);
	// const [palette, setPalette] = useState(null)
	// const [transform, setTransform] = useState(null);
	const [configSchema, setConfigSchema] = useState(null);
	const [defaultValues, setDefaultValues] = useState(component);
	// const [liveComponent, setLiveComponent] = useState({ ...component });
	const [dataSample, setDataSample] = useState(null);

	const dataSources = useMemo(() => insightCollection?.datasources ?? [], [
		insightCollection?.datasources,
	]);

	const transformOptions = useMemo(() => initTransformOptions(dataSources), [
		dataSources,
	]);

	// console.log({
	// 	palette,
	// 	paletteConfig,
	// 	collectionPalettes,
	// });

	// console.log({
	// 	dataSources,
	// 	configSchema,
	// 	transformOptions,
	// });

	const configSchemas = useMemo(
		() => {
			const _configSchemas = {};
			dataSources.forEach(source => {
				const dataSourceConfigSchemas = {};
				source.transforms.forEach(
					transform =>
						(dataSourceConfigSchemas[transform.uuid] = has(
							transform,
							'configSchema'
						)
							? transform.configSchema
							: null)
				);
				_configSchemas[source.uuid] = dataSourceConfigSchemas;
			});
			return _configSchemas;
		},
		[dataSources]
	);

	return (
		<EditFormContext.Provider
			value={{
				insightCollection,
				setInsightCollection,
				palette,
				configSchema,
				setConfigSchema,
				dataSources,
				defaultValues,
				setDefaultValues,
				transformOptions,
				configSchemas,
				dataSample,
				setDataSample,
				paletteConfig,
				collectionPalettes,
				// liveComponent,
				// setLiveComponent,
			}}
		>
			{children}
		</EditFormContext.Provider>
	);
}

EditFormContextProvider.propTypes = {
	children: PropTypes.node,
	component: PropTypes.object,
	collection: PropTypes.object,
	palette: PropTypes.array,
};

export { EditFormContextProvider, useEditFormContext };
