import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Controller, useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';

import useFormElRequired from '../../../hooks/useFormElRequired';

export default function BtFormTextField({
	disabled,
	fetching,
	isRequired,
	label,
	name,
	type,
	helperText,
	...other
}) {
	const {
		control,
		watch,
		loading,
		sending,
		setValue,
		trigger,
		validationSchema,
		getFieldState,
	} = useFormContext() || { formState: {} };

	const fieldState = getFieldState(name);

	const value = watch(name) || '';

	const hasError = useMemo(() => !!fieldState?.error, [fieldState?.error]);

	const handleChange = useCallback(
		event => {
			const value = event.target.value;

			if (hasError) {
				trigger(name);
			}

			setValue(name, type === 'number' ? +value : value, {
				shouldTouch: true,
				shouldDirty: true,
			});
		},
		[hasError, name, setValue, trigger, type]
	);

	const required = useFormElRequired(isRequired, validationSchema, name);

	if (loading) {
		return (
			<Skeleton animation="wave" style={{ width: '100%', height: 45 }} />
		);
	}

	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<TextField
					label={
						label
							? `${label}${!required ? ' (optional)' : ''}`
							: undefined
					}
					{...field}
					color="primary"
					disabled={loading || sending || disabled || fetching}
					error={hasError}
					fullWidth
					helperText={fieldState?.error?.message || helperText}
					InputProps={{
						endAdornment: fetching ? (
							<InputAdornment position="end">
								<Box
									style={{
										display: 'flex',
										marginRight: '0.5em',
										pointerEvents: 'none',
									}}
								>
									<CircularProgress size={20} />
								</Box>
							</InputAdornment>
						) : null,
					}}
					onChange={handleChange}
					size="small"
					type={type}
					value={value}
					variant="standard"
					{...other}
				/>
			)}
		/>
	);
}

BtFormTextField.propTypes = {
	disabled: PropTypes.bool,
	fetching: PropTypes.bool,
	isRequired: PropTypes.bool,
	label: PropTypes.string,
	name: PropTypes.string.isRequired,
	type: PropTypes.string,
	helperText: PropTypes.node,
};
