const yup = require('yup');

const permissionsGetListSchema = yup.array().of(
	yup.object({
		id: yup
			.string()
			.uuid()
			.required(),
		name: yup.string().required(),
		methods: yup
			.object({
				name: yup.string().required(),
				description: yup.string().required(),
				resourceGroup: yup
					.string()
					.required()
					.nullable(),
			})
			.required(),
	})
);

module.exports = {
	permissionsGetListSchema,
};
