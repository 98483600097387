import React, {
	createContext,
	forwardRef,
	useCallback,
	useContext,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';

import dot from 'dot-object';
import { v4 as generateUuid } from 'uuid';
import _ from 'lodash';

import { encodeSchema, parseSchema } from '../../../utils/yup-ast';
import { historyPropType } from './utils/propTypes';

const UPDATE = 'update',
	CONFIG_UPDATE = 'configUpdate',
	FIELD_ADDITION = 'fieldAddition',
	FIELD_DELETION = 'fieldDeletion',
	FIELD_RENAME = 'fieldRename';

const DataConfigContext = createContext();

export const useDataConfigContext = () => {
	const context = useContext(DataConfigContext);

	if (context === undefined) {
		throw new Error(
			'useDataConfigContext was used outside of its provider.'
		);
	}

	return context;
};

export const DataConfigContextProvider = forwardRef(
	(
		{
			config: externalConfig,
			children,
			disabled,
			history: externalHistory,
			historyPos: externalHistoryPos,
			initialConfig,
			initialHistory,
			initialHistoryPos,
			onChange,
			onChangeHistory,
			onChangeHistoryPos,
			readOnly,
			nodeSelectable,
			onNodeSelect,
			selectedNode,
		},
		ref
	) => {
		const scrollToSelected = useRef(false);

		const [history, setHistory] = useState(() => {
			const initHistory = externalHistory ?? initialHistory;

			if ((initHistory || []).length === 0) {
				return [
					{
						config: _.cloneDeep(
							externalConfig ?? initialConfig ?? {}
						),
						uuid: generateUuid(),
					},
				];
			}

			return initHistory;
		});

		const [historyPos, setHistoryPos] = useState(
			externalHistoryPos ?? initialHistoryPos ?? 0
		);

		const _history = useMemo(() => externalHistory ?? history, [
			externalHistory,
			history,
		]);

		const _historyPos = useMemo(() => externalHistoryPos ?? historyPos, [
			externalHistoryPos,
			historyPos,
		]);

		const [editedConfig, setEditedConfig] = useState(() => {
			const historyTemp = history ?? initialHistory;
			if (initialHistory) {
				return historyTemp[historyPos ?? initialHistoryPos].config;
			}

			return _.cloneDeep(externalConfig ?? initialConfig ?? {});
		});
		const [_initialConfig, setInitialConfig] = useState(
			_.cloneDeep(externalConfig ?? initialConfig ?? {})
		);
		const [seedUuid, setSeedUuid] = useState(generateUuid());
		const [selection, setSelection] = useState(selectedNode);

		const canRedo = useMemo(
			() => _historyPos < (_history?.length || 0) - 1,
			[_history, _historyPos]
		);

		const canUndo = useMemo(() => (_historyPos || 0) > 0, [_historyPos]);

		const historyUuid = useMemo(() => _history?.[_historyPos || 0]?.uuid, [
			_history,
			_historyPos,
		]);

		const updateData = useCallback((newData, hardUpdate) => {
			if (hardUpdate) {
				setSeedUuid(generateUuid());

				setEditedConfig(() => ({
					..._.cloneDeep({ ...newData }),
				}));

				return;
			}

			setEditedConfig(() => ({
				...newData,
			}));
		}, []);

		const clearHistory = useCallback(
			config => {
				setHistory([
					{
						config: config ?? externalConfig ?? initialConfig,
						uuid: generateUuid(),
					},
				]);

				setHistoryPos(0);
			},
			[externalConfig, initialConfig]
		);

		const discardChanges = useCallback(
			() => {
				const newConfig = _.cloneDeep({ ..._initialConfig });

				setEditedConfig(newConfig);

				clearHistory(newConfig);
			},
			[clearHistory, _initialConfig]
		);

		const saveChanges = useCallback(
			() => {
				const newData = _.cloneDeep({
					...(externalConfig ?? editedConfig),
				});

				setEditedConfig(newData);

				setInitialConfig(newData);

				clearHistory(newData);
			},
			[clearHistory, editedConfig, externalConfig]
		);

		const appendHistory = useCallback(
			({ config, selection, schema, updateType, updateData }) => {
				const newHistory = [
					..._history.slice(0, _historyPos + 1),
					{
						config,
						schema,
						selection,
						updateType,
						updateData,
						uuid: generateUuid(),
					},
				];

				setHistory(newHistory);
				onChangeHistory?.(newHistory);

				const newPos = newHistory.length - 1;
				setHistoryPos(newPos);
				onChangeHistoryPos?.(newPos);
			},
			[_history, _historyPos, onChangeHistory, onChangeHistoryPos]
		);

		const reduceSchema = useCallback(schema => {
			let result = {
				type: schema.type,
			};

			if (schema.required) {
				result.required = schema.required;
			}

			if (schema.default) {
				result.default = schema.default;
			}

			if (schema.min) {
				result.min = schema.min;
			}

			if (schema.max) {
				result.max = schema.max;
			}

			if (schema.type === 'object') {
				result.objectContent = {};
				const childKeys = Object.keys(schema.objectContent);

				childKeys.forEach(propKey => {
					if (!schema.objectContent[propKey].deleted) {
						result.objectContent[propKey] = reduceSchema(
							schema.objectContent[propKey]
						);
					}
				});
			} else if (schema.type === 'array') {
				if (!schema.arrayContent.deleted) {
					result.arrayContent = reduceSchema(schema.arrayContent);
				}
			}

			return result;
		}, []);

		const processUpdate = useCallback(
			(config, newSelection, updateType, updateData) => {
				const reducedSchema = reduceSchema(config);
				const schema = encodeSchema(reducedSchema);

				appendHistory({
					config,
					schema,
					selection: newSelection,
					updateType,
					updateData,
				});

				setEditedConfig(config);

				setSelection(newSelection);

				onChange?.({ config, schema });
			},
			[appendHistory, onChange, reduceSchema]
		);

		const update = useCallback(
			(location, update, additive) => {
				const clone = _.cloneDeep({ ...editedConfig });

				dot.str(location, update, clone);

				processUpdate(
					clone,
					location,
					additive ? FIELD_ADDITION : UPDATE,
					{
						location,
					}
				);
			},
			[editedConfig, processUpdate]
		);

		const renameField = useCallback(
			({ parentLocation, oldField, newField }) => {
				let clone = _.cloneDeep(editedConfig);
				const newLocation = `${parentLocation}.${newField}`;

				dot.move(`${parentLocation}.${oldField}`, newLocation, clone);

				processUpdate(clone, newLocation, FIELD_RENAME, {
					newLocation,
					oldLocation: `${parentLocation}.${oldField}`,
				});
			},
			[editedConfig, processUpdate]
		);

		const deleteElement = useCallback(
			location => {
				let clone = _.cloneDeep(editedConfig);

				dot.delete(location, clone);

				processUpdate(clone, null, FIELD_DELETION, { location });
			},
			[editedConfig, processUpdate]
		);

		const updateConfig = useCallback(
			(location, updatedConfig) => {
				let clone = _.cloneDeep({ ...editedConfig });

				dot.set(location, updatedConfig, clone);

				processUpdate(clone, location, CONFIG_UPDATE, { location });
			},
			[editedConfig, processUpdate]
		);

		const select = useCallback(
			newSelection => {
				setSelection(newSelection);
				if (onNodeSelect) {
					onNodeSelect(newSelection);
				}
			},
			[onNodeSelect]
		);

		const traverseHistory = useCallback(
			(newPosition, newSelection) => {
				document.activeElement?.blur();

				setHistoryPos(newPosition);
				onChangeHistoryPos?.(newPosition);

				const { config, schema } = _history[newPosition];

				setEditedConfig(_history[newPosition].config);
				onChange?.({ config, schema, historyPos: newPosition });

				setSelection(newSelection);
			},
			[_history, onChange, onChangeHistoryPos]
		);

		const redo = useCallback(
			() => {
				if (canRedo) {
					const newPos = _historyPos + 1;

					const newSelection = (() => {
						if (_history[newPos].updateType === FIELD_DELETION) {
							return null;
						}

						return _history[newPos].selection;
					})();

					traverseHistory(newPos, newSelection);
				}
			},
			[canRedo, _history, _historyPos, traverseHistory]
		);

		const undo = useCallback(
			() => {
				if (canUndo) {
					document.activeElement?.blur();

					const newPos = _historyPos - 1;

					const newSelection = (() => {
						const updateType = _history[_historyPos].updateType;

						if (updateType === FIELD_ADDITION) {
							return null;
						}

						if (updateType === FIELD_DELETION) {
							return _history[_historyPos].updateData.location;
						}

						if (updateType === FIELD_RENAME) {
							return _history[_historyPos].updateData.oldLocation;
						}

						return _history[_historyPos].selection;
					})();

					traverseHistory(newPos, newSelection);
				}
			},
			[canUndo, _history, _historyPos, traverseHistory]
		);

		const getConfig = useCallback(() => editedConfig, [editedConfig]);

		useImperativeHandle(
			ref,
			() => ({
				clearHistory,
				config: getConfig,
				generateSchema: () => parseSchema(getConfig()),
				redo,
				reset: discardChanges,
				save: saveChanges,
				undo,
			}),
			[clearHistory, discardChanges, getConfig, redo, saveChanges, undo]
		);

		return (
			<DataConfigContext.Provider
				ref={ref}
				value={{
					canRedo,
					canUndo,
					deleteElement,
					disabled,
					discardChanges,
					editedConfig: externalConfig ?? editedConfig,
					historyUuid,
					initialConfig: _initialConfig,
					readOnly,
					nodeSelectable,
					redo,
					renameField,
					saveChanges,
					scrollToSelected,
					seedUuid,
					select,
					selection,
					undo,
					updateConfig,
					updateData,
					update,
				}}
			>
				{children}
			</DataConfigContext.Provider>
		);
	}
);

DataConfigContextProvider.displayName = 'DataConfigContext';

DataConfigContextProvider.propTypes = {
	config: PropTypes.object,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	disabled: PropTypes.bool,
	history: historyPropType,
	historyPos: PropTypes.number,
	initialConfig: PropTypes.object,
	initialHistory: historyPropType,
	initialHistoryPos: PropTypes.number,
	nodeSelectable: PropTypes.bool,
	onChange: PropTypes.func,
	onChangeHistory: PropTypes.func,
	onChangeHistoryPos: PropTypes.func,
	onNodeSelect: PropTypes.func,
	readOnly: PropTypes.bool,
	selectedNode: PropTypes.string,
};
