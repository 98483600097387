import React, { useEffect } from 'react';

import { Auth } from 'aws-amplify';

import { useAppContext } from '../context/ContextManager';

export default function Logout() {
	const {
		setUserInfo,
		setAppOrg,
		userHasAuthenticated,
		setLoadApp,
	} = useAppContext();

	useEffect(() => {
		try {
			Auth.signOut().then(() => {
				setUserInfo(null);
				setAppOrg(null);
				userHasAuthenticated(false);
				setLoadApp(false);
				console.log('Logged out');
			});
		} catch (error) {
			console.log('Logged out failed', error);
		}
	});

	return <></>;
}
