import React, {
	useCallback,
	useEffect,
	useMemo,
	// useRef,
	useState,
} from 'react';

import PropTypes from 'prop-types';
import { alpha } from '@mui/material';
import { RadialBarChart } from '../../../../components/charts/Charts';
import { RadialBar } from '../../../../components/charts/Components/RadialBar';
import { useTheme } from '@mui/styles';
import { resolveValueStringExplicit } from './Utils/valueDecimalPlaces';
import { Tooltip } from '../../../../components/charts';
import { CustomTooltip } from './Generic/Tooltip';
import {
	ABSOLUTE,
	NONE,
	PERCENTAGE,
	PROGRESS,
} from '../../../../API/validations/insightVisualizationsValidation';
import { attachAffixesToValue } from './Utils/affixes';

export default function RadialBarChartComponent({ data, visOptions, palette }) {
	// const ref = useRef(null);
	const [_data, setData] = useState([]);

	const theme = useTheme();

	// console.log({ data, visOptions, palette });

	useEffect(
		() => {
			const formattedData = visOptions?.params?.slice(0, 5).map(param => {
				return {
					name: param.label,
					value: isNaN(data[0][param.value])
						? 0
						: data[0][param.value],
					min: param.min,
					max: param.max,
				};
			});
			setData(formattedData);
		},
		[data, visOptions]
	);

	const resolveBackground = useCallback(
		colour => {
			try {
				if (visOptions?.background?.disabled === true) {
					return null;
				}

				if (visOptions?.background?.use_series_colour === true) {
					return alpha(colour, 0.1);
				} else {
					return theme.palette.background.insights;
				}
			} catch (error) {
				console.log({ error });
				return null;
			}
			// if (visOptions?.background?.disabled === true) {
			// 	return null;
			// }

			// if (visOptions?.background?.use_series_colour === true) {
			// 	return alpha(colour, 0.2);
			// } else {
			// 	return theme.palette.background.insights;
			// }
		},
		[theme, visOptions]
	);

	const resolveLabelValueType = (
		label_type,
		prefix,
		suffix,
		label_value_decimal_places,
		value,
		max
	) => {
		switch (label_type) {
			case ABSOLUTE:
				return resolveValueStringExplicit(
					value,
					prefix,
					suffix,
					label_value_decimal_places
				);
			case PERCENTAGE:
				return resolveValueStringExplicit(
					(value / max) * 100,
					'',
					'%',
					label_value_decimal_places
				);
			case PROGRESS:
				return attachAffixesToValue(
					`${value.toFixed(0)}/${max.toFixed(0)}`,
					suffix,
					prefix
				);
			default:
				return '';
		}
	};

	const resolveLabel = (param, value) => {
		try {
			const name = param.label;
			const valueString = resolveLabelValueType(
				param.label_type,
				param.prefix,
				param.suffix,
				param.label_value_decimal_places,
				value,
				param.max
			);
			const result = `${name}${valueString && ': '}${valueString}`;
			return result;
		} catch (error) {
			console.log({ error });
			return '';
		}
		// console.log({ param, value });
		// const name = param.label;
		// const valueString = resolveLabelValueType(
		// 	param.label_type,
		// 	param.prefix,
		// 	param.suffix,
		// 	param.label_value_decimal_places,
		// 	value,
		// 	param.max
		// );
		// const result = `${name}${valueString && ': '}${valueString}`;
		// // console.log({ result });
		// return result;
	};

	const resolveTooltipDataItem = useCallback(
		(param, value, index) => {
			if (!param) return;
			const name = param?.label || '';
			let valueString = resolveLabelValueType(
				param.label_type,
				param.prefix,
				param.suffix,
				param.label_value_decimal_places,
				value,
				param.max
			);
			if (!valueString)
				valueString = resolveLabelValueType(
					PERCENTAGE,
					param.prefix,
					param.suffix,
					param.label_value_decimal_places,
					value,
					param.max
				);
			return {
				name,
				valueString,
				percentageValue: (value / param.max) * 100,
				color: palette[index],
			};
		},
		[palette]
	);

	const tooltipData = useMemo(
		() => {
			if (_data && visOptions.params) {
				return _data.map(({ value }, index) =>
					resolveTooltipDataItem(
						visOptions.params[index],
						value,
						index
					)
				);
			}
		},
		[_data, resolveTooltipDataItem, visOptions.params]
	);

	const hasLabels = useMemo(
		() => !visOptions.params.every(({ label_type }) => label_type === NONE),
		[visOptions]
	);

	// TODO: fix this use of 359 degrees, 360 causes problems
	// using the generic 'sector' component to draw the bars might be best but it might need extending to handle this
	const maxAngle = useMemo(() => (hasLabels ? 270 : 359), [hasLabels]);

	return (
		<RadialBarChart data={_data}>
			{_data &&
				(_data || []).map((dataItem, index) => (
					<RadialBar
						key={dataItem.name}
						dataKey="value"
						fill={palette[index]}
						index={index}
						min={dataItem.min || 0}
						max={dataItem.max || 10}
						background={resolveBackground(palette[index])}
						// label={resolveLabelString(
						// 	dataItem.name,
						// 	dataItem.value,
						// 	index,
						// 	dataItem.max
						// )}
						label={
							hasLabels &&
							resolveLabel(
								visOptions?.params[index],
								dataItem.value
							)
						}
						// label={hasLabels && resolveLabel()}
						maxAngle={maxAngle}
					/>
				))}

			<Tooltip
				wrapperStyle={{ outline: 'none' }}
				content={props => (
					<CustomTooltip
						// sortValues
						visOptions={visOptions}
						data={tooltipData}
						{...props}
					/>
				)}
			/>
		</RadialBarChart>
	);
}

RadialBarChartComponent.propTypes = {
	data: PropTypes.array.isRequired,
	visOptions: PropTypes.object.isRequired,
	palette: PropTypes.array.isRequired,
};
