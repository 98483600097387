import {
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import { Reload } from 'mdi-material-ui';
import { useCallback, useEffect, useState } from 'react';
import { screenListGet } from '../../../API';
import { screenAdminListGet } from '../../../API/screen_admin.api';
import ActionButtonContainer from '../../../components/generic/ActionButtonContainer';
import BtError from '../../../components/generic/BtError';
import BtLoading from '../../../components/generic/BtLoading';
import { TilesAutocomplete } from '../../../components/generic/screens/components/formComponents/TilesAutocomplete';
import TilesTextField from '../../../components/generic/screens/components/formComponents/TilesTextField';
import { screenNameSchema } from '../../../components/generic/screens/components/screensConfig/components/editScreenSteps/EditScreen';
import NewScreenRadioGroup from '../../../components/generic/screens/components/screensConfig/components/NewScreenRadioGroup';
import {
	ActionButton,
	CancelButton,
} from '../../../components/generic/screens/components/screensConfig/ScreenConfigDialogContent';
import { useAppContext } from '../../../context/ContextManager';
import useFetch from '../../../hooks/useFetch';

const NewScreenOptions = ({
	deviceType,
	checkboxValue,
	setCheckboxValue,
	screenName,
	setScreenName,
	setIsScreenNameValid,
	screen,
	setScreen,
	screenOptions,
	screenError,
	setScreenError,
	resetForm,
	setResetForm,
}) => {
	// checkbox control
	const handleCheckboxChange = event => {
		setCheckboxValue(event.target.value);
		switch (event.target.value) {
			case 'copy':
				setScreenName(screenOptions[0].label + ' copy');
				break;
			default:
				setResetForm(true);
				break;
		}
	};

	useEffect(
		() => {
			if (screen === null) setResetForm(true);
		},
		[screen, setResetForm]
	);

	// setting screen value error
	useEffect(
		() => {
			let error = false;
			switch (checkboxValue) {
				case 'copy':
					error = screen === null;
					break;
				default:
					break;
			}
			setScreenError(error);
		},
		[checkboxValue, screen, setScreenError]
	);

	useEffect(
		() => {
			if (screenOptions.length === 0) {
				setScreenName('New Screen');
			} else {
				setScreenName(screenOptions[0].label + ' copy');
			}
		},
		[screen, screenOptions, setScreenName]
	);

	return (
		<>
			<NewScreenRadioGroup
				deviceType={deviceType}
				onChange={handleCheckboxChange}
				value={checkboxValue}
				isCopyDisabled={!screenOptions || screenOptions.length === 0}
				adminMode
			/>

			{screenOptions !== null &&
				checkboxValue !== 'blank' && (
					<div style={{ height: 70, marginTop: 5, marginBottom: 10 }}>
						<TilesAutocomplete
							onChange={setScreen}
							options={screenOptions}
							value={screen}
							label="Select screen to copy"
							error={screenError}
							errorMessage="Screen to copy is required"
							showDescription
						/>
					</div>
				)}

			<TilesTextField
				setIsTextFieldValid={setIsScreenNameValid}
				onChange={setScreenName}
				initialValue={screenName}
				schema={screenNameSchema}
				label="Choose a name for the screen"
				disabled={false}
				resetForm={resetForm}
				setResetForm={setResetForm}
			/>
		</>
	);
};

const autocompleteOptions = options => {
	return options.map((option, index) => ({
		label: option.screen_name,
		id: index,
		uuid: option.uuid,
		description: option.ownerType,
	}));
};

export const NewScreenDialog = ({ handleSubmit, onClose }) => {
	const { deviceType, appOrg } = useAppContext();
	const [checkboxValue, setCheckboxValue] = useState('blank');
	const [screenName, setScreenName] = useState('');
	const [isScreenNameValid, setIsScreenNameValid] = useState(false);
	const [screen, setScreen] = useState();
	const [screenOptions, setScreenOptions] = useState(null);
	const [screenError, setScreenError] = useState();
	const [resetForm, setResetForm] = useState(false);

	const {
		loading: orgScreensLoading,
		data: orgScreensList,
		error: orgScreensError,
		request: fetchOrgScreenList,
	} = useFetch(screenAdminListGet);

	// get the org screens from back end
	useEffect(
		() => {
			fetchOrgScreenList();
		},
		[fetchOrgScreenList]
	);

	const {
		loading: userScreensLoading,
		data: userScreensList,
		error: userScreensError,
		request: getUserScreens,
	} = useFetch(screenListGet);

	useEffect(
		() => {
			getUserScreens({ ownerFilter: 'User' });
		},
		[getUserScreens]
	);

	useEffect(
		() => {
			if (userScreensList && orgScreensList) {
				// build the options for copying a screen
				const options = autocompleteOptions([
					// ...userScreensList,
					...[],
					...orgScreensList,
				]);
				setScreenOptions(options);
				setScreen(options[0] || null);
			}
		},
		[userScreensList, orgScreensList, userScreensLoading, orgScreensLoading]
	);

	const handleClose = useCallback(
		() => {
			setCheckboxValue('blank');
			setResetForm(true);
			onClose();
		},
		[onClose]
	);

	const onSubmit = useCallback(
		() => {
			handleSubmit({
				mode: checkboxValue,
				screenToCopyUuid: screen?.uuid || null,
				newScreenName: screenName,
				appOrgUuid: appOrg.uuid,
			});
			onClose();
		},
		[appOrg, checkboxValue, handleSubmit, onClose, screen, screenName]
	);

	const retry = useCallback(
		() => {
			fetchOrgScreenList();
			getUserScreens({ ownerFilter: 'User' });
		},
		[fetchOrgScreenList, getUserScreens]
	);

	return (
		<BtLoading loading={!screenOptions}>
			{userScreensError || orgScreensError ? (
				// {/* {1 ? ( */}
				<BtError
					title="Retrieval Error"
					description={
						<>
							<Typography>
								An error occurred whilst trying to retrieve the
								required data.
							</Typography>
						</>
					}
					action={retry}
					actionLabel="Retry"
					actionIcon={<Reload />}
					fullScreen
					onClose={onClose}
				/>
			) : (
				<>
					<DialogTitle>Add New Screen</DialogTitle>

					<DialogContent
						style={{
							width: 600,
							paddingTop: 20,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<NewScreenOptions
							deviceType={deviceType}
							checkboxValue={checkboxValue}
							setCheckboxValue={setCheckboxValue}
							screenName={screenName}
							setScreenName={setScreenName}
							isScreenNameValid={isScreenNameValid}
							setIsScreenNameValid={setIsScreenNameValid}
							screen={screen}
							setScreen={setScreen}
							screenOptions={screenOptions || []}
							setScreenOptions={setScreenOptions}
							screenError={screenError}
							setScreenError={setScreenError}
							resetForm={resetForm}
							setResetForm={setResetForm}
						/>
					</DialogContent>

					<DialogActions>
						<ActionButtonContainer>
							<CancelButton
								onClose={reason => {
									handleClose(reason);
								}}
							/>
							<ActionButton
								label="add screen"
								disabled={
									screenName === '' || !isScreenNameValid
								}
								onClick={onSubmit}
							/>
						</ActionButtonContainer>
					</DialogActions>
				</>
			)}
		</BtLoading>
	);
};

NewScreenDialog.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
};

NewScreenOptions.propTypes = {
	deviceType: PropTypes.string.isRequired,
	checkboxValue: PropTypes.string.isRequired,
	setCheckboxValue: PropTypes.func.isRequired,
	screenName: PropTypes.string.isRequired,
	setScreenName: PropTypes.func.isRequired,
	setIsScreenNameValid: PropTypes.func.isRequired,
	screen: PropTypes.object,
	setScreen: PropTypes.func.isRequired,
	screenOptions: PropTypes.array.isRequired,
	setScreenOptions: PropTypes.func.isRequired,
	screenError: PropTypes.bool,
	setScreenError: PropTypes.func.isRequired,
	resetForm: PropTypes.bool,
	setResetForm: PropTypes.func.isRequired,
};
