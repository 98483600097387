import React, { useCallback, useMemo } from 'react';

import { useAppContext } from '../../../../../context/ContextManager';
import { useWorkflowSessionContext } from '../../contexts/WorkflowSessionContext';
import WorkflowCommentAdornment from '../../components/core/WorkflowCommentAdornment';

export default function useCommentFuncs() {
	const {
		isFinal,
		sessionUuid,
		workflowSubmission,
	} = useWorkflowSessionContext();

	const { userInfo } = useAppContext();

	const adornment = useCallback(
		({ status }) => <WorkflowCommentAdornment status={status} />,
		[]
	);

	const commonPredicate = useCallback(
		comment =>
			comment.sessionUuid === sessionUuid &&
			comment.user.uuid === userInfo.uuid,
		[sessionUuid, userInfo]
	);

	const modificationMutator = useCallback(
		() => ({ sessionUuid, status: workflowSubmission.status.status }),
		[sessionUuid, workflowSubmission]
	);

	const newCommentMessage = 'Added during this session';

	const readOnlyMessage = useMemo(
		() =>
			isFinal
				? 'You cannot add comments to a non-editable workflow.'
				: undefined,
		[isFinal]
	);

	return {
		adornment,
		commonPredicate,
		modificationMutator,
		newCommentMessage,
		readOnlyMessage,
	};
}
