import _ from 'lodash';
import { commonRangesData } from '../../../../components/generic/filters/components/time/utils/timeRangeConstants';
import { buildTimeRangeObject } from '../../../../utils/TimeControl/TimeRangeUtilFunctions';

export default async function contextInitialiser(
	getDraftRequest,
	insightCollectionUuid,
	setError,
	userInfo
	// dataSources,
	// setDataSources,
	// addDataSource,
	// removeDataSources
) {
	const result = {};

	const getDraft = async () => {
		try {
			const draft = await getDraftRequest();
			return draft;
		} catch (error) {
			setError(true);
			return null;
		}
	};

	result.draft = await getDraft();

	// // ===

	// const timeRangeConfig = _.find(commonRangesData, {
	// 	id: result.draft.initial_time_range?.value,
	// });
	// const defaultTimeRange = await buildTimeRangeObject({
	// 	...timeRangeConfig,
	// });

	// // setContextDefaultTimeRange(defaultTimeRange);

	// // console.log('DATA SORUCES', collection.data_sources);

	// // Store the data_sources in locla state to be used for removing
	// setDataSources(result.draft.data_sources);

	// // not sure what this is/was for or if required..
	// const handleDataSourceUpdate = () => {
	// 	return null;
	// };

	// for (const dataSource of result.draft.data_sources) {
	// 	// Add the Data View instance to the DataProvider
	// 	const dataView = await addDataSource(
	// 		dataSource.uuid,
	// 		dataSource.dataView,
	// 		defaultTimeRange,
	// 		dataSource.predefined_filters,
	// 		dataSource.filter_dependencies,
	// 		dataSource.transforms,
	// 		handleDataSourceUpdate
	// 	);
	// }

	// // ===

	result.draftLockedToCurrentUser =
		result.draft.locked_to.uuid === userInfo.uuid;

	return result;
}
