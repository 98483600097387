/* eslint no-useless-escape: 0 */

import React, { useState, useRef, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { v1 as uuidv1 } from 'uuid';

// MUI COMPONENTS
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Checkbox, DialogActions, IconButton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

// MUI ICONS
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/SaveAlt';
import AddCircleIcon from '@mui/icons-material/AddCircle';

// CUSTOM COMPONENTS
import BootstrapDialogTitle from './BootstrapDialogTitle';
import SectionHeader from '../generic/SectionHeader';
import CustomSnackbar from '../generic/CustomSnackbar';

// TODO: should we have this in it's own file to be reused or in a utils file?
// This custom hook is used to prevent stale state with the dialog
function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}

export default function ContainerTaskEditDialog({
	setOpen,
	onClose,
	container,
	volumes,
}) {
	const theme = useTheme();
	const prevVisibility = usePrevious(setOpen);
	const [isLoading, setIsLoading] = useState(true);
	// const [ enabled, setEnabled ] = useState(true);
	// const { appOrg, setAppOrg} = useAppContext();

	const [name, setName] = useState('');
	const [image, setImage] = useState('');

	const [
		registryAuthenticationChecked,
		setRegistryAuthenticationChecked,
	] = useState(false);
	// const [ registryAuthentication, setRegistryAuthentication ] = useState({});

	const [noVolumes, setNoVolumes] = useState(true);

	const [memSoftLimit, setMemSoftLimit] = useState(0);
	const [command, setCommand] = useState('');
	const [links, setLinks] = useState([]);
	const [envVars, setEnvVars] = useState([]);
	const [mountPoints, setMountPoints] = useState([]);
	const [portMappings, setPortMappings] = useState([]);
	const [dockerConfig, setDockerConfig] = useState('');

	const [nameError, setNameError] = useState(false);
	const [nameHelper, setNameHelper] = useState('');

	const [iamgeError, setImageError] = useState(false);
	const [imageHelper, setImageHelper] = useState('');

	const [memSoftLimitError, setMemSoftLimitError] = useState(false);
	const [memSoftLimitHelper, setMemSoftLimitHelper] = useState('');

	// Snackbar control values
	const [errorOpen, setErrorOpen] = useState(false);
	const onErrorClose = () => {
		setErrorOpen(false);
	};
	const [snackMessage, setSnackMessage] = useState('');

	const handleClose = () => {
		onClose('', '');
	};
	// const onDeviceClick = (name) => () => {onClose(name);}

	const validateForm = () => {
		console.log('Validate form');
		var valid = true;

		// Validate the container name
		const nameRegExp = /^([A-Z]|[a-z]|[0-9]|[-_]){4,40}$/; // Allowed chars: a-z, A-Z, 0-9, -, _ must be length 4-40
		if (name === '') {
			setNameError(true);
			setNameHelper('Name value is required');
			valid = false;
		} else if (!nameRegExp.test(name)) {
			setNameError(true);
			setNameHelper('Name value must contain olny a-z, A-Z, 0-9, -, _');
			valid = false;
		} else {
			setNameError(false);
			setNameHelper('');
		}

		// Validate the container image
		const imageRegExp = /[^\w_\/\-:]/; // Allowed chars: a-z, A-Z, 0-9, -, _, / must be length 4-60
		if (image === '') {
			setImageError(true);
			setImageHelper('Value is required');
			valid = false;
		} else if ((image.length < 4) | (image.length > 60)) {
			setImageError(true);
			setImageHelper('Length must be between 2-20');
			valid = false;
		} else if (imageRegExp.test(image)) {
			setImageError(true);
			setImageHelper('Value must contain olny a-z, A-Z, 0-9, -, _, :, /');
			valid = false;
		} else {
			setImageError(false);
			setImageHelper('');
		}

		// Validate the memory limit
		//if((parseInt(memSoftLimit) < 32) || (parseInt(memSoftLimit) > (64*10241))) {
		if (memSoftLimit < 32 || memSoftLimit > 64 * 1024) {
			setMemSoftLimitError(true);
			setMemSoftLimitHelper('Valid range is 32-65536');
			valid = false;
		} else {
			setMemSoftLimitError(false);
			setMemSoftLimitHelper('');
		}

		// Validate the Links
		const linkRegExp = /[^\w_\-]/; // Allowed chars: a-z, A-Z, 0-9, -, _ must be length 2-20
		setLinks(
			links.map(link => {
				// Validate the link name
				if (link.link === '') {
					// If a link is blank then it will get removed before upload
					link.error = false;
					link.error_text = '';
				} else if (link.link.length < 2 || link.link.length > 20) {
					link.error = true;
					link.error_text = 'Length must be between 2-20';
					valid = false;
				} else if (linkRegExp.test(link.link)) {
					link.error = true;
					link.error_text =
						'Name value must contain olny a-z, A-Z, 0-9, -, _ and be between 2 and 20 characters';
					valid = false;
				} else {
					link.error = false;
					link.error_text = '';
				}
				return link;
			})
		);

		// Validate the Port Mappings
		setPortMappings(
			portMappings.map(portMapping => {
				// Validate the container port
				if (
					portMapping.container_port < 1 ||
					portMapping.container_port >= 64 * 1024
				) {
					portMapping.container_error = true;
					portMapping.container_error_text =
						'Valid port range is 1-65535';
					valid = false;
				} else {
					portMapping.container_error = false;
					portMapping.container_error_text = '';
				}

				// Validate the host port
				if (
					portMapping.host_port < 1 ||
					portMapping.host_port >= 64 * 1024
				) {
					portMapping.host_error = true;
					portMapping.host_error_text = 'Valid port range is 1-65535';
					valid = false;
				} else {
					portMapping.host_error = false;
					portMapping.host_error_text = '';
				}

				return portMapping;
			})
		);

		// Validate the environment variables
		const envNameRegExp = /[^\w_]/; // Allowed chars: a-z, A-Z, 0-9, _ must be length 3-60 and not start with a number
		const envValueRegExp = /[^\w_\/\-:]/; // Allowed chars: a-z, A-Z, 0-9, _ must be length 3-60 and not start with a number
		setEnvVars(
			envVars.map(envVar => {
				// Validate the name
				if (envVar.name === '') {
					envVar.name_error = true;
					envVar.name_error_text = 'Name is required';
					valid = false;
				} else if (
					(envVar.name.length < 3) |
					(envVar.name.length > 60)
				) {
					envVar.name_error = true;
					envVar.name_error_text = 'Length must be between 3-60';
					valid = false;
				} else if (envNameRegExp.test(envVar.name)) {
					envVar.name_error = true;
					envVar.name_error_text =
						'Name must only contain a-z, A-Z, 0-9, _ and cannot start with a number';
					valid = false;
				} else {
					envVar.name_error = false;
					envVar.name_error_text = '';
				}

				// Validate the value
				if (envVar.value === '') {
					envVar.value_error = true;
					envVar.value_error_text = 'Value is required';
					valid = false;
				} else if (
					(envVar.value.length < 1) |
					(envVar.value.length > 60)
				) {
					envVar.value_error = true;
					envVar.value_error_text = 'Length must be between 3-60';
					valid = false;
				} else if (envValueRegExp.test(envVar.value)) {
					envVar.value_error = true;
					envVar.value_error_text =
						'Value must only contain a-z, A-Z, 0-9, _, - or /';
					valid = false;
				} else {
					envVar.value_error = false;
					envVar.value_error_text = '';
				}

				return envVar;
			})
		);

		// Validate the mount points
		const mountPointPathRegExp = /[^\w_\/\-]/; // Allowed chars: a-z, A-Z, 0-9, _, -, / must be length 3-60
		setMountPoints(
			mountPoints.map(mountPoint => {
				// Validate the container path
				if (mountPoint.container_path === '') {
					mountPoint.error = true;
					mountPoint.error_text = 'Container path is required';
					valid = false;
				} else if (
					(mountPoint.container_path.length < 3) |
					(mountPoint.container_path.length > 60)
				) {
					mountPoint.error = true;
					mountPoint.error_text = 'Path must be between 3-60';
					valid = false;
				} else if (
					mountPointPathRegExp.test(mountPoint.container_path)
				) {
					mountPoint.error = true;
					mountPoint.error_text =
						'Path must only contain a-z, A-Z, 0-9, _, -, /';
					valid = false;
				} else {
					mountPoint.error = false;
					mountPoint.error_text = '';
				}

				return mountPoint;
			})
		);

		return valid;
	};

	const onBlur = event => {
		validateForm();
	};

	const handleUpdate = () => {
		//console.log("Add dialog: " + dashboardName);
		console.log('Update Container: ' + name);

		let formIsValid = validateForm();

		if (formIsValid) {
			console.log('Save New Revision');

			container.name = name;
			container.image = image;
			if (registryAuthenticationChecked === true) {
				container.registry_authentication = true;
			} else {
				container.registry_authentication = null;
			}
			container.memory_soft_limit = memSoftLimit;
			container.command = command;
			container.docker_config = dockerConfig;
			container.links = links.map(link => {
				return link.link;
			});
			container.mount_points = mountPoints.map(mountPoint => {
				return {
					read_only: mountPoint.read_only,
					container_path: mountPoint.container_path,
					source_volume: mountPoint.source_volume,
				};
			});
			container.port_mappings = portMappings.map(portMapping => {
				return {
					host_port: portMapping.host_port,
					protocol: portMapping.protocol,
					container_port: portMapping.container_port,
				};
			});
			container.env_vars = envVars.map(envVar => {
				return {
					name: envVar.name,
					value: envVar.value,
				};
			});

			/*let updatedTask = {...task};
			updatedTask.name = name;
			updatedTask.description = description;
			updatedTask.revision = parseInt(task_revision);
			updatedTask.volumes = volumes.map((volume) => {return({name:volume.name, source_path:volume.source_path, type:volume.type, name_error_text:"", source_path_error_text:""})});

			console.log(updatedTask);*/

			handleClose();
		} else {
			setSnackMessage('Field Error, please check what you have entered');
			setErrorOpen(true);
		}

		/*let new_dashboard = {name:dashboardName}

		var req_url = process.env.REACT_APP_API_SERVER_URL + `/v1/dashboard`;

		Auth.currentSession()
		.then((user_session) => {
			let user_token = user_session.getAccessToken();
			fetch(
				req_url,
				{
					method: "POST",
					headers: {
						'Authorization': user_token.jwtToken,
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'Organisation': appOrg.uuid,
					},
					body: JSON.stringify(new_dashboard)
				}
			)
			.then(res => res.json())
			.then(response => {
				console.log(response)
				setIsLoading(false);
			})
			.catch(error => {
				console.log(error);
				setIsLoading(false);
			});
		});*/
	};

	/*const handleEnabledChange = event => {
		setEnabled(event.target.checked);
	};*/

	const handleAddPortMapping = () => {
		setPortMappings([
			...portMappings,
			{
				container_port: '3',
				host_port: '5',
				protocol: 'TCP',
				uuid: uuidv1(),
				container_error: false,
				container_error_text: '',
				host_error: false,
				host_error_text: '',
			},
		]);
	};
	const handleRemovePortMapping = index => () => {
		let newPortMappings = [...portMappings];
		newPortMappings.splice(index, 1);
		setPortMappings(newPortMappings);
	};
	const handleContainerPortChange = event => {
		setPortMappings(
			portMappings.map(portMapping => {
				if (portMapping.uuid === event.target.id) {
					portMapping.container_port = parseInt(event.target.value);
				}
				return portMapping;
			})
		);
	};
	const handleHostPortChange = event => {
		setPortMappings(
			portMappings.map(portMapping => {
				if (portMapping.uuid === event.target.id) {
					portMapping.host_port = parseInt(event.target.value);
				}
				return portMapping;
			})
		);
	};
	const handlePortMappingTypeChange = uuid => event => {
		setPortMappings(
			portMappings.map(portMapping => {
				if (portMapping.uuid === uuid) {
					portMapping.protocol = event.target.value;
				}
				return portMapping;
			})
		);
	};

	const handleAddLink = () => {
		setLinks([
			...links,
			{ link: '', uuid: uuidv1(), error: false, error_text: '' },
		]);
	};
	const handleRemoveLink = index => () => {
		let newLinks = [...links];
		newLinks.splice(index, 1);
		setLinks(newLinks);
	};
	const handlelinkChange = event => {
		setLinks(
			links.map(link => {
				if (link.uuid === event.target.id) {
					link.link = event.target.value;
				}
				return link;
			})
		);
	};

	const handleAddEnvVar = () => {
		setEnvVars([
			...envVars,
			{
				name: '',
				value: '',
				uuid: uuidv1(),
				name_error: false,
				name_error_text: '',
				value_error: false,
				value_error_text: '',
			},
		]);
	};
	const handleRemoveEnvVar = index => () => {
		let newEnvVars = [...envVars];
		newEnvVars.splice(index, 1);
		setEnvVars(newEnvVars);
	};
	const handleEnvVarNameChange = event => {
		setEnvVars(
			envVars.map(envVar => {
				if (envVar.uuid === event.target.id) {
					envVar.name = event.target.value;
				}
				return envVar;
			})
		);
	};
	const handleEnvVarValueChange = event => {
		setEnvVars(
			envVars.map(envVar => {
				if (envVar.uuid === event.target.id) {
					envVar.value = event.target.value;
				}
				return envVar;
			})
		);
	};

	const handleAddMountPoint = () => {
		setMountPoints([
			...mountPoints,
			{
				source_volume: volumes[0].name,
				container_path: '',
				read_only: false,
				uuid: uuidv1(),
				error: false,
				error_text: '',
			},
		]);
	};
	const handleRemoveMountPoint = index => () => {
		let newMountPoints = [...mountPoints];
		newMountPoints.splice(index, 1);
		setMountPoints(newMountPoints);
	};
	const setMountROChecked = (uuid, checked) => {
		setMountPoints(
			mountPoints.map(mountPoint => {
				if (mountPoint.uuid === uuid) {
					mountPoint.read_only = checked;
				}
				return mountPoint;
			})
		);
	};
	const handleMountPointPathChange = event => {
		setMountPoints(
			mountPoints.map(mountPoint => {
				if (mountPoint.uuid === event.target.id) {
					mountPoint.container_path = event.target.value;
				}
				return mountPoint;
			})
		);
	};
	const handleMountPointVolumeChange = uuid => event => {
		setMountPoints(
			mountPoints.map(mountPoint => {
				if (mountPoint.uuid === uuid) {
					mountPoint.source_volume = event.target.value;
				}
				return mountPoint;
			})
		);
	};

	useEffect(
		() => {
			// If it is now open, but was previously not open
			if (setOpen && !prevVisibility) {
				setName(container.name);
				setImage(container.image);

				//setRegistryAuthentication(container.registry_authentication);
				if (container.registry_authentication === null) {
					setRegistryAuthenticationChecked(false);
				} else {
					setRegistryAuthenticationChecked(true);
				}

				setMemSoftLimit(container.memory_soft_limit);
				setCommand(container.command);
				setDockerConfig(container.docker_config);

				// We need a unique key for React to track our objects so add a UUID value
				let links = [...container.links];
				links = links.map(link => {
					return {
						link: link,
						uuid: uuidv1(),
						error: false,
						error_text: '',
					};
				});
				setLinks(links);

				// We need a unique key for React to track our objects so add a UUID value
				let mount_points = [...container.mount_points];
				mount_points = mount_points.map(mount_point => {
					return {
						...mount_point,
						uuid: uuidv1(),
						error: false,
						error_text: '',
					};
				});
				setMountPoints(mount_points);

				// We need a unique key for React to track our objects so add a UUID value
				let port_mappings = [...container.port_mappings];
				port_mappings = port_mappings.map(port_mapping => {
					return {
						...port_mapping,
						uuid: uuidv1(),
						container_error: false,
						container_error_text: '',
						host_error: false,
						host_error_text: '',
					};
				});
				setPortMappings(port_mappings);

				// We need a unique key for React to track our objects so add a UUID value
				let env_vars = [...container.environment];
				env_vars = env_vars.map(env_var => {
					return {
						...env_var,
						uuid: uuidv1(),
						name_error: false,
						name_error_text: '',
						value_error: false,
						value_error_text: '',
					};
				});
				setEnvVars(env_vars);

				// Check if there are any volumes provided
				if (volumes.length === 0) {
					setNoVolumes(true);
				} else {
					setNoVolumes(false);
				}
			}
		},
		[setOpen, prevVisibility, container, volumes]
	);

	// TODO: Do we need to use this??
	const handleOnEnter = () => {
		setIsLoading(false);
	};

	return (
		<>
			<Dialog
				fullScreen
				sx={{ padding: theme.spacing(2) }}
				open={setOpen}
				onClose={handleClose}
				TransitionProps={{
					onEnter: handleOnEnter,
				}}
			>
				<BootstrapDialogTitle id="form-dialog-title" onClose={onClose}>
					Edit Container
				</BootstrapDialogTitle>
				<DialogContent
					sx={{
						minWidth: '600px',
						backgroundColor: 'background.default',
					}}
				>
					{isLoading && (
						<>
							<p>Loading...</p>
							<CircularProgress size={'120px'} />
						</>
					)}

					{!isLoading && (
						<Box
							display="flex"
							flexDirection="column"
							sx={{ minHeight: '400px' }}
						>
							<Box sx={{ flexGrow: 1 }}>
								<FormGroup sx={{ m: 2 }}>
									<SectionHeader>Basic Details</SectionHeader>
									<TextField
										variant="outlined"
										sx={{ maxWidth: 400 }}
										label="Name"
										size="small"
										error={nameError}
										helperText={nameHelper}
										defaultValue={name}
										onChange={event => {
											setName(event.target.value);
										}}
										onBlur={onBlur}
										required
									/>

									<TextField
										variant="outlined"
										fullWidth
										label="Image"
										size="small"
										error={iamgeError}
										helperText={imageHelper}
										defaultValue={image}
										onChange={event => {
											setImage(event.target.value);
										}}
										onBlur={onBlur}
										required
									/>

									<FormControlLabel
										control={
											<Checkbox
												checked={
													registryAuthenticationChecked
												}
												onChange={event => {
													setRegistryAuthenticationChecked(
														event.target.checked
													);
												}}
												color="primary"
												inputProps={{
													'aria-label': 'controlled',
												}}
											/>
										}
										label="Private repository authentication"
									/>

									{registryAuthenticationChecked && (
										<>
											{/* TODO: Change the following to a 'Select' for a login and password set and stored elsewhere */}
											<TextField
												variant="outlined"
												fullWidth
												label="Auth Secret"
												size="small"
												defaultValue={image}
												onChange={event => {
													setImage(
														event.target.value
													);
												}}
												onBlur={onBlur}
											/>
										</>
									)}

									<SectionHeader variant="h5">
										Memory
									</SectionHeader>
									<TextField
										variant="outlined"
										sx={{ maxWidth: 400 }}
										label="Soft Limit"
										size="small"
										error={memSoftLimitError}
										helperText={memSoftLimitHelper}
										type="number"
										InputProps={{
											inputProps: {
												min: 32,
												max: 64 * 1024,
											},
										}}
										defaultValue={memSoftLimit}
										onChange={event => {
											setMemSoftLimit(
												parseInt(event.target.value)
											);
										}}
										onBlur={onBlur}
									/>

									{/*setCommand(container.command);
			setDockerConfig(container.docker_config);*/}

									<SectionHeader variant="h5">
										Links
									</SectionHeader>
									<TableContainer
										component={Box}
										sx={{ width: 600 }}
									>
										<Table>
											<TableBody>
												{links.map((link, index) => (
													<TableRow key={link.uuid}>
														<TableCell>
															<TextField
																variant="outlined"
																sx={{
																	maxWidth: 400,
																}}
																label="Link"
																size="small"
																error={
																	link.error
																}
																helperText={
																	link.error_text
																}
																defaultValue={
																	link.link
																}
																id={link.uuid}
																onChange={
																	handlelinkChange
																}
																onBlur={onBlur}
															/>
														</TableCell>
														<TableCell>
															<IconButton
																variant="text"
																onClick={handleRemoveLink(
																	index
																)}
															>
																<CloseIcon />
															</IconButton>
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
										<Button
											variant="text"
											startIcon={<AddCircleIcon />}
											onClick={handleAddLink}
										>
											Add Link
										</Button>
									</TableContainer>

									<SectionHeader>Port Mappings</SectionHeader>
									<TableContainer
										component={Box}
										sx={{ width: 600 }}
									>
										<Table>
											<TableBody>
												{portMappings.map(
													(portMapping, index) => (
														<TableRow
															key={
																portMapping.uuid
															}
														>
															<TableCell>
																<TextField
																	variant="outlined"
																	fullWidth
																	label="Container Port"
																	size="small"
																	id={
																		portMapping.uuid
																	}
																	error={
																		portMapping.container_error
																	}
																	helperText={
																		portMapping.container_error_text
																	}
																	type="number"
																	defaultValue={
																		portMapping.container_port
																	}
																	onChange={
																		handleContainerPortChange
																	}
																	onBlur={
																		onBlur
																	}
																/>
															</TableCell>
															<TableCell>
																<TextField
																	variant="outlined"
																	fullWidth
																	label="Host Port"
																	size="small"
																	id={
																		portMapping.uuid
																	}
																	error={
																		portMapping.host_error
																	}
																	helperText={
																		portMapping.host_error_text
																	}
																	type="number"
																	defaultValue={
																		portMapping.host_port
																	}
																	onChange={
																		handleHostPortChange
																	}
																	onBlur={
																		onBlur
																	}
																/>
															</TableCell>
															<TableCell>
																<FormControl
																	fullWidth
																>
																	<InputLabel id="demo-simple-select-label">
																		Protocol
																	</InputLabel>
																	<Select
																		labelId="demo-simple-select-label"
																		id={
																			portMapping.uuid
																		}
																		value={
																			portMapping.protocol
																		}
																		label="Protocol"
																		size="small"
																		onChange={handlePortMappingTypeChange(
																			portMapping.uuid
																		)}
																	>
																		<MenuItem
																			value={
																				'TCP'
																			}
																		>
																			TCP
																		</MenuItem>
																		<MenuItem
																			value={
																				'UDP'
																			}
																		>
																			UDP
																		</MenuItem>
																	</Select>
																</FormControl>
															</TableCell>
															<TableCell>
																<IconButton
																	variant="text"
																	onClick={handleRemovePortMapping(
																		index
																	)}
																>
																	<CloseIcon />
																</IconButton>
															</TableCell>
														</TableRow>
													)
												)}
											</TableBody>
										</Table>
										<Button
											variant="text"
											startIcon={<AddCircleIcon />}
											onClick={handleAddPortMapping}
										>
											Add Port Mapping
										</Button>
									</TableContainer>

									<SectionHeader>
										Enviroment Variables
									</SectionHeader>
									<TableContainer
										component={Box}
										sx={{ width: 600 }}
									>
										<Table>
											<TableBody>
												{envVars.map(
													(env_var, index) => (
														<TableRow
															key={env_var.uuid}
														>
															<TableCell>
																<TextField
																	variant="outlined"
																	fullWidth
																	label="Name"
																	size="small"
																	id={
																		env_var.uuid
																	}
																	error={
																		env_var.name_error
																	}
																	helperText={
																		env_var.name_error_text
																	}
																	defaultValue={
																		env_var.name
																	}
																	onChange={
																		handleEnvVarNameChange
																	}
																	onBlur={
																		onBlur
																	}
																/>
															</TableCell>
															<TableCell>
																<TextField
																	variant="outlined"
																	fullWidth
																	label="Value"
																	size="small"
																	id={
																		env_var.uuid
																	}
																	error={
																		env_var.value_error
																	}
																	helperText={
																		env_var.value_error_text
																	}
																	defaultValue={
																		env_var.value
																	}
																	onChange={
																		handleEnvVarValueChange
																	}
																	onBlur={
																		onBlur
																	}
																/>
															</TableCell>
															<TableCell>
																<IconButton
																	variant="text"
																	onClick={handleRemoveEnvVar(
																		index
																	)}
																>
																	<CloseIcon />
																</IconButton>
															</TableCell>
														</TableRow>
													)
												)}
											</TableBody>
										</Table>
										<Button
											variant="text"
											startIcon={<AddCircleIcon />}
											onClick={handleAddEnvVar}
										>
											Add Environments Variable
										</Button>
									</TableContainer>

									<SectionHeader>Mount Points</SectionHeader>
									<TableContainer
										component={Box}
										sx={{ width: 600 }}
									>
										<Table>
											<TableBody>
												{mountPoints.map(
													(mountPoint, index) => (
														<TableRow
															key={
																mountPoint.uuid
															}
														>
															<TableCell>
																<FormControl
																	fullWidth
																>
																	<InputLabel id="demo-simple-select-label">
																		Source
																		Volume
																	</InputLabel>
																	<Select
																		labelId="demo-simple-select-label"
																		id={
																			mountPoint.uuid
																		}
																		value={
																			mountPoint.source_volume
																		}
																		label="Source Volume"
																		size="small"
																		onChange={handleMountPointVolumeChange(
																			mountPoint.uuid
																		)}
																	>
																		{volumes.map(
																			(
																				volume,
																				index
																			) => (
																				<MenuItem
																					value={
																						volume.name
																					}
																					key={
																						index
																					}
																				>
																					{
																						volume.name
																					}
																				</MenuItem>
																			)
																		)}
																	</Select>
																</FormControl>
															</TableCell>
															<TableCell>
																<TextField
																	variant="outlined"
																	fullWidth
																	label="Container Path"
																	size="small"
																	id={
																		mountPoint.uuid
																	}
																	error={
																		mountPoint.error
																	}
																	helperText={
																		mountPoint.error_text
																	}
																	defaultValue={
																		mountPoint.container_path
																	}
																	onChange={
																		handleMountPointPathChange
																	}
																	onBlur={
																		onBlur
																	}
																/>
															</TableCell>
															<TableCell>
																<FormControlLabel
																	control={
																		<Checkbox
																			checked={
																				mountPoint.read_only
																			}
																			onChange={event => {
																				setMountROChecked(
																					mountPoint.uuid,
																					event
																						.target
																						.checked
																				);
																			}}
																			color="primary"
																			inputProps={{
																				'aria-label':
																					'controlled',
																			}}
																		/>
																	}
																	label="Read Only"
																/>
															</TableCell>
															<TableCell>
																<IconButton
																	variant="text"
																	onClick={handleRemoveMountPoint(
																		index
																	)}
																>
																	<CloseIcon />
																</IconButton>
															</TableCell>
														</TableRow>
													)
												)}
											</TableBody>
										</Table>
										<Button
											variant="text"
											disabled={noVolumes}
											startIcon={<AddCircleIcon />}
											onClick={handleAddMountPoint}
										>
											Add Mount Point
										</Button>
									</TableContainer>
								</FormGroup>
							</Box>
						</Box>
					)}
				</DialogContent>
				<DialogActions>
					<Button
						variant="outlined"
						startIcon={<CloseIcon />}
						onClick={handleClose}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						startIcon={<SaveIcon />}
						onClick={handleUpdate}
					>
						Update
					</Button>
				</DialogActions>
			</Dialog>

			<CustomSnackbar
				open={errorOpen}
				onClose={onErrorClose}
				message={snackMessage}
				type={'error'}
				duration={4000}
			/>
		</>
	);
}
