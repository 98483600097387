const yup = require('yup');

const deviceGetListSchema = yup.array().of(
	yup.object({
		uid: yup
			.string()
			.length(18)
			.required(),
		name: yup.string().required(),
		description: yup.string().required(),
		type: yup
			.string()
			.uuid()
			.required(),
		stats: yup
			.object({
				con_time: yup.number().default(0),
				connected: yup.boolean().default(false),
				stats: yup.object({
					boot_str: yup.string().default('-'),
					con_count: yup.number().default(0),
					pkt_drop: yup.number().default(0),
					rssi: yup.number().default(0),
					rtt: yup.number().default(0),
					uptime: yup.number().default(0),
				}),
			})
			.nullable(),
	})
);

const deviceGetSchema = yup.object({
	uid: yup
		.string()
		.length(18)
		.required(),
	name: yup.string().required(),
	description: yup.string().required(),
	type: yup
		.string()
		.uuid()
		.required(),
	stats: yup
		.object({
			con_time: yup.number().default(0),
			connected: yup.boolean().default(false),
			stats: yup
				.object({
					boot_str: yup.string().default('-'),
					con_count: yup.number().default(0),
					pkt_drop: yup.number().default(0),
					rssi: yup.number().default(0),
					rtt: yup.number().default(0),
					uptime: yup.number().default(0),
				})
				.nullable(),
		})
		.nullable(),
	config: yup
		.string()
		.uuid()
		.required(),
	firmware: yup
		.string()
		.uuid()
		.required(),
	organisation: yup
		.string()
		.uuid()
		.required(),
	server: yup
		.object({
			address: yup.string().required(),
			port: yup.number().required(),
		})
		.required(),
	conditioning: yup
		.object({
			uuid: yup
				.string()
				.uuid()
				.required(),
			modify_timestamp: yup.number().required(),
			data: yup.object({}).required(),
		})
		.required(),
	reported: yup.object({
		firmware: yup.object({
			uuid: yup.string().uuid(),
			timestatmp: yup.number(),
		}),
	}),
	status: yup.string().required(),
});

module.exports = {
	deviceGetListSchema,
	deviceGetSchema,
};
