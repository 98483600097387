import { ANOTHER_USER, CURRENT_USER, UNLOCKED } from '../constants';
import { getUserDetails } from '../requests/getUserDetails';

const initDraftUser = async (lockedTo, userInfo, setError) => {
	let lockUser = {};
	let draftLockStatus = null;
	if (!lockedTo) {
		draftLockStatus = UNLOCKED;
	} else if (lockedTo === userInfo.uuid) {
		lockUser = userInfo;
		draftLockStatus = CURRENT_USER;
	} else {
		lockUser = await getUserDetails(lockedTo, setError);
		draftLockStatus = ANOTHER_USER;
	}
	return { lockUser, draftLockStatus };
};

export { initDraftUser };
