import { Launch, Rocket } from '@mui/icons-material';
import HubIcon from '@mui/icons-material/Hub';
import _ from 'lodash';

import {
	ButtonCursor,
	ChartPie,
	ChartTimeline,
	ChartTimelineVariantShimmer,
	FolderOutline,
	Information,
	RocketLaunchOutline,
} from 'mdi-material-ui';

const genericTileData = {
	variant: '',
	title: '',
	subtitle: '',
	data: {},
};

export const tileBreakpoints = width => {
	// console.log(width);
	if (width < 240) {
		return 'xs';
	} else if (_.inRange(width, 241, 261)) {
		return 'sm';
	} else if (_.inRange(width, 261, 400)) {
		return 'md';
	} else if (_.inRange(width, 401, 520)) {
		return 'lg';
	} else if (_.inRange(width, 521, 800)) {
		return 'xl';
	} else return 'xxl';
};

export const buttonTypes = [
	{
		value: 'start_workflow',
		label: 'Start Workflow',
		icon: <RocketLaunchOutline />,
		requiredPermissionsAll: {
			workflows: ['TemplateList', 'SubmissionCreate'],
		},
		validSubscriptionsAny: ['Workflow User'],
	},
	{
		value: 'shortcut',
		label: 'Shortcut',
		icon: <Launch />,
		requiredPermissionsAll: {},
		validSubscriptionsAny: [],
	},
	{
		value: 'data_manager',
		label: 'Data Manager',
		icon: <HubIcon />,
		requiredPermissionsAll: {
			dataManager: ['DatasetView', 'DataStreamView', 'ImageRepoView'],
		},
		validSubscriptionsAny: ['Data Manager'],
	},
];

export const buttonPermissionsAll = {
	data_manager: {
		dataManager: ['DatasetView', 'DataStreamView', 'ImageRepoView'],
	},
	start_workflow: { workflows: ['TemplateList', 'SubmissionCreate'] },
};

const adminShortcuts = [
	{
		value: '/GeneralInfo',
		label: 'Admin - General Information',
	},
	{
		value: '/Subscriptions',
		label: 'Admin - Subscriptions',
	},
	{
		value: '/Roles',
		label: 'Admin - Roles',
	},
	{
		value: '/Users',
		label: 'Admin - Users',
	},
	{
		value: '/Tags',
		label: 'Admin - Tags',
	},
];

export const buttonShortcuts = [
	...adminShortcuts,
	{
		value: '/DataSources',
		label: 'Data Sources',
	},
];

export const dataManagerConstants = {
	data_set: {
		label: 'Data Set',
		url: '/DataSet/',
	},
	data_stream: {
		label: 'Data Stream',
		url: '/DataStream/',
	},
	image_repo: {
		label: 'Image Repository',
		url: '/ImageRepo/',
	},
};

export const tileLabels = {
	container: 'Container',
	insight_collection: 'Insight collection',
	insight_visualisation: 'Insight visualisation',
	button: 'Button',
	workflow: 'Workflow',
	workflow_templates: 'Workflow templates',
	workflow_submissions: 'Workflow submissions',
	information: 'Information',
};

export const resolveIcon = ({ type, disabled }) => {
	const style = disabled
		? { color: 'rgba(0, 0, 0, 0.26)' }
		: { color: 'white' };
	// const style = { color: 'white' };

	switch (type) {
		case 'container':
			return <FolderOutline style={style} />;
		case 'insight_collection':
			return <ChartTimelineVariantShimmer style={style} />;
		case 'insight_visualisation':
			return <ChartPie style={style} />;
		case 'workflow':
			return <ChartTimeline style={style} />;
		case 'workflow_templates':
			return <ChartTimeline style={style} />;
		case 'workflow_submissions':
			return <ChartTimeline style={style} />;
		case 'button':
			return <ButtonCursor style={style} />;
		case 'information':
			return <Information style={style} />;
		default:
			return null;
	}
};

export const tileSubscriptionsPermissions = {
	container: {
		permissionsAll: {},
		subscriptionsAny: [],
	},
	insight_collection: {
		permissionsAll: { insights: ['InsightsView'] },
		// subscriptionsAny: ['Insights Viewer','Insights Editor'],
		subscriptionsAny: ['Insights Viewer'],
	},
	insight_visualisation: {
		permissionsAll: { insights: ['InsightsView'] },
		subscriptionsAny: ['Insights Viewer'],
	},
	// TODO - needs to support the sub variants...
	button: {
		permissionsAll: {},
		subscriptionsAny: [],
	},
	workflow_templates: {
		permissionsAll: { workflows: ['TemplateList'] },
		subscriptionsAny: ['Workflow User'],
	},
	workflow_submissions: {
		permissionsAll: { workflows: ['SubmissionList'] },
		subscriptionsAny: ['Workflow User'],
	},
	information: {
		permissionsAll: {},
		subscriptionsAny: [],
	},
};

export const tileVariants = {
	container: {
		...genericTileData,
		variant: 'container',
		title: 'Container',
		data: {
			...genericTileData.data,
			screen_group: '',
			screen_uuid: '',
			children: [],
			container_layout: {
				h: 1,
				w: 1,
			},
		},
	},
	insight_collection: {
		...genericTileData,
		variant: 'insight_collection',
		title: 'Insight collection',
		subtitle: null,
		data: {
			...genericTileData.data,
			insight_collection: '',
			// content_key: 'insight_collections',
		},
	},
	insight_visualisation: {
		...genericTileData,
		variant: 'insight_visualisation',
		title: 'Insight visualisation',
		subtitle: null,
		data: {
			...genericTileData.data,
			insight_collection: '',
			insight_component: '',
			// content_key: 'insight_visualisation',
		},
	},
	workflow_templates: {
		...genericTileData,
		variant: 'workflow_templates',
		title: 'Workflow Templates',
		data: {
			...genericTileData.data,
		},
	},
	workflow_submissions: {
		...genericTileData,
		variant: 'workflow_submissions',
		title: 'Workflow Submissions',
		data: {
			...genericTileData.data,
		},
	},
	button: {
		...genericTileData,
		variant: 'button',
		title: 'New Button',
		subtitle: '',
		data: {
			button_type: '',
			target: {
				label: '',
				value: '',
			},
		},
	},
	information: {
		...genericTileData,
		variant: 'information',
		title: '',
		subtitle: '',
		data: {
			items: [],
		},
	},
};

export const insightVisualisationTypes = [
	{ type: 'PieChart', text: 'Pie' },
	{ type: 'LineChart', text: 'Line' },
	{ type: 'StatusCard', text: 'Status' },
	{ type: 'BarChart', text: 'Bar' },
	{ type: 'Table', text: 'Table' },
];
