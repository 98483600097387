import components, { componentTypeExists } from '../config/workflowComponents';

export const injectValidation = (validation, componentType, required) => {
	if (!componentTypeExists(componentType)) return validation;

	const componentReqVal =
		components[componentType].dataType.requiredValidation;

	const supplementalValidation =
		components[componentType].supplementalValidation || [];

	const requiredVal = [
		...componentReqVal,
		...supplementalValidation,
		...(required ? [['yup.required']] : []),
	];

	let tempVal = [...validation] || [];

	requiredVal.forEach((reqValClause, index) => {
		if (
			!validation.find(val => {
				if (val?.length === 0) return false;
				return val[0] === reqValClause[0];
			})
		) {
			tempVal.splice(index, 0, reqValClause);
		}
	});

	return tempVal;
};
