const yup = require('yup');

const roleGetListSchema = yup.array().of(
	yup.object({
		uuid: yup
			.string()
			.uuid()
			.required(),
		name: yup.string().required(),
		description: yup.string().required(),
		userCount: yup.number().required(),
	})
);

const roleGetSchema = yup.object({
	uuid: yup
		.string()
		.uuid()
		.required(),
	name: yup.string().required(),
	description: yup.string().required(),
	users: yup
		.array()
		.of(yup.string().uuid())
		.required(),
	managedBy: yup.string().required(),
	permissions: yup.array().of(
		yup
			.object({
				uuid: yup
					.string()
					.uuid()
					.required(),
				group: yup.string().required(),
				method: yup.string().required(),
				resource: yup.array().of(yup.string().required()),
			})
			.required()
	),
});

const roleAddSchema = yup.object({
	name: yup
		.string()
		.min(3)
		.max(30)
		.required(),
	description: yup
		.string()
		.max(256)
		.required(),
});

const roleAddResponseSchema = yup.object({
	name: yup
		.string()
		.min(3)
		.max(30)
		.required(),
	description: yup
		.string()
		.max(256)
		.required(),
	userCount: yup.number(),
	managedBy: yup.string('Organisation'),
});

const roleAddPermissionSchema = yup.object({
	group: yup
		.string()
		.min(3)
		.max(32)
		.required(),
	method: yup
		.string()
		.max(32)
		.required(),
	resources: yup
		.array()
		.of(yup.string().required())
		.nullable(),
});

const roleAddPermissionResponseSchema = yup.object({
	uuid: yup
		.string()
		.uuid()
		.required(),
});

const roleEditPermissionSchema = yup.object({
	resources: yup
		.array()
		.of(yup.string().required())
		.nullable(),
});

module.exports = {
	roleGetListSchema,
	roleGetSchema,
	roleAddSchema,
	roleAddResponseSchema,
	roleAddPermissionSchema,
	roleAddPermissionResponseSchema,
	roleEditPermissionSchema,
};
