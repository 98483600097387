const yup = require('yup');

export const dataViewGetListSchema = yup.array().of(
	yup
		.object({
			uuid: yup
				.string()
				.uuid()
				.required(),
			name: yup.string().default(''),
			description: yup.string().default(''),
			create_timestamp: yup.number().required(),
			modify_timestamp: yup.number().required(),
			create_by: yup
				.string()
				.uuid()
				.required(),
			modify_by: yup
				.string()
				.uuid()
				.required(),
		})
		.nullable()
);

export const dataViewGetSchema = yup
	.object({
		uuid: yup
			.string()
			.uuid()
			.required(),
		name: yup.string().default(''),
		description: yup.string().default(''),
		create_timestamp: yup.number().required(),
		modify_timestamp: yup.number().required(),
		create_by: yup
			.string()
			.uuid()
			.required(),
		modify_by: yup
			.string()
			.uuid()
			.required(),
		schema: yup.array(),
		sources: yup.array().of(
			yup.object().shape({
				type: yup
					.string()
					.required()
					.oneOf(['dataSet', 'channelData']),
				uuid: yup.string().nullable(),
			})
		),
	})
	.nullable();

export const dataViewPostSchema = yup.object({
	name: yup.string().required(''),
	description: yup.string().default(''),
	// schema: yup
	// 	.array()
	// 	.of(
	// 		yup.object({
	// 			name: yup.string().required(),
	// 			type: yup.string().required(),
	// 			validation: yup.array().of(yup.array()).required(),
	// 		})
	// 	)
	// 	.nullable(),
});
