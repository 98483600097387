import React, { useState } from 'react';

// COMPONENTS
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/SaveAlt';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { ListTable, ListRow, ListHeader, ListCell } from '../generic/ListTable';

export default function ConfigEditChannelDialog({
	setOpen,
	onClose,
	channelData,
	selectedChannel,
	deviceDef,
}) {
	const [isLoading, setIsLoading] = useState(true);
	const [enableEndTrigger, setEnableEndTrigger] = useState(false);

	const [channel, setChannel] = useState([]); // Holds a version of the channel we are editing
	const [channelArray, setChannelArray] = useState([]);
	//const [channelIndex, setChannelIndex] = useState({});

	const [digitalInputs, setDigitalInputs] = useState([]);
	const [analogueInputs, setAnalogueInputs] = useState([]);
	const [relayOutputs, setRelayOutputs] = useState([]);
	const [emInst, setEmInst] = useState([]);
	const [emTotal, setEmTotal] = useState([]);

	const [paramFunc, setParamFunc] = useState({});

	const handleSelectConfigClose = () => {
		onClose();
	};

	const handleTriggerSelect = start_end => event => {
		var trigger = {};
		switch (event.target.value) {
			case 'edge_r':
				trigger['type'] = 'edge_r';
				trigger['input'] = 'D0';
				trigger['count'] = 10;
				break;

			case 'edge_f':
				trigger['type'] = 'edge_f';
				trigger['input'] = 'D0';
				trigger['count'] = 10;
				break;

			/*case "edge_c":
				var trigger = {};
				trigger['type'] = "edge_c";
				trigger['input'] = "D0";
				trigger['count'] = 10;
				break;*/

			case 'periodic':
				trigger['type'] = 'periodic';
				trigger['time_unit'] = 's';
				trigger['count'] = 10;
				trigger['auto_reload'] = true;
				break;

			// TODO: MAKE THIS HANDLE IT CORRECTLY
			case 'schedlued':
				trigger['type'] = 'schedlued';
				trigger['time_unit'] = 's';
				trigger['count'] = 10;
				break;

			case 'value_l':
				trigger['type'] = 'value_l';
				trigger['input'] = 'D0';
				trigger['threshold_low'] = 10;
				break;

			case 'value_h':
				trigger['type'] = 'value_h';
				trigger['input'] = 'D0';
				trigger['threshold_high'] = 80;
				break;

			case 'value_ri':
				trigger['type'] = 'value_ri';
				trigger['input'] = 'D0';
				trigger['threshold_low'] = 10;
				trigger['threshold_high'] = 80;
				break;

			case 'value_ro':
				trigger['type'] = 'value_ro';
				trigger['input'] = 'D0';
				trigger['threshold_low'] = 10;
				trigger['threshold_high'] = 80;
				break;

			default:
				return;
		}

		if (start_end === 'start') {
			setChannel({ ...channel, trigger_start: trigger });
		} else {
			setChannel({ ...channel, trigger_end: trigger });
		}
	};

	const handleAddParam = () => {
		var new_param = {};
		new_param['name'] = 'Not set';
		new_param['input'] = deviceDef.channels[0].value;
		new_param['function'] = 'trig_val';
		setChannel({ ...channel, params: [...channel.params, new_param] });
	};

	const handleDeleteParam = index => event => {
		var new_params = [...channel.params];
		new_params.splice(index, 1);
		setChannel({ ...channel, params: new_params });
	};

	const handleParamNameChange = index => event => {
		var new_params = [...channel.params];
		new_params.splice(index, 1, {
			...channel.params[index],
			name: event.target.value,
		});
		setChannel({ ...channel, params: new_params });
	};

	const handleParamInputChange = index => event => {
		var new_params = [...channel.params];
		new_params.splice(index, 1, {
			...channel.params[index],
			input: event.target.value,
		});
		setChannel({ ...channel, params: new_params });
	};

	const handleParamFunctionChange = index => event => {
		var new_params = [...channel.params];
		new_params.splice(index, 1, {
			...channel.params[index],
			function: event.target.value,
		});
		setChannel({ ...channel, params: new_params });
	};

	// This is called only when the dialog is opened
	const handleOnEnter = () => {
		try {
			//setChannelIndex(selectedChannel);
			setChannelArray(channelData);

			console.log(channelData);
			console.log(deviceDef);

			// Proccess the device definition
			var digital = [];
			var analogue = [];
			var relay = [];
			var em_inst = [];
			var em_total = [];
			deviceDef.channels.forEach(channel => {
				switch (channel.type) {
					case 'digital input':
						digital.push(channel);
						break;
					case 'analogue input':
						analogue.push(channel);
						break;
					case 'relay output':
						relay.push(channel);
						break;
					case 'em inst':
						em_inst.push(channel);
						break;
					case 'em total':
						em_total.push(channel);
						break;
					default:
						break;
				}
			});
			setDigitalInputs(digital);
			setAnalogueInputs(analogue);
			setRelayOutputs(relay);
			setEmInst(em_inst);
			setEmTotal(em_total);

			var param_func = {};
			param_func['trig_val'] = digital.concat(analogue, em_inst);
			param_func['count_r'] = digital;
			param_func['count_f'] = digital;
			param_func['max'] = analogue.concat(em_inst);
			param_func['min'] = analogue.concat(em_inst);
			param_func['ave'] = analogue.concat(em_inst);
			param_func['integral'] = analogue.concat(em_inst);
			param_func['accumulator'] = em_total;
			setParamFunc(param_func);

			// Check if this is a new channel or an edit
			if (selectedChannel >= channelData.length) {
				// Create a default channel
				var new_chan = {};
				new_chan.name = 'No name';

				new_chan.trigger_start = {};
				new_chan.trigger_start.type = 'periodic';
				new_chan.trigger_start.time_unit = 'm';
				new_chan.trigger_start.count = 10;
				new_chan.trigger_start.auto_reload = true;

				// new_chan['trigger_end'] = {};
				// new_chan['trigger_end']['type'] = 'edge_f';
				// new_chan['trigger_end']['input'] = 'D0';
				// new_chan['trigger_start']['count'] = 10;

				new_chan['params'] = [];
				setChannel(new_chan);
			} else {
				var chan = channelData[selectedChannel];
				if (chan.trigger_end !== undefined) {
					setEnableEndTrigger(true);
				} else {
					/*chan.trigger_end = {};
					chan.trigger_end['type'] = 'edge_f';
					chan.trigger_end['input'] = 'D0';
					chan.trigger_end['count'] = 10;*/
				}
				setChannel(chan);
			}
		} catch (error) {
			console.log(error);
		}
		setIsLoading(false);
	};

	const handleNameChange = event => {
		setChannel({ ...channel, name: event.target.value });
	};

	const handleTriggerInputSelect = start_end => event => {
		if (start_end === 'start') {
			setChannel({
				...channel,
				trigger_start: {
					...channel.trigger_start,
					input: event.target.value,
				},
			});
		} else {
			setChannel({
				...channel,
				trigger_end: {
					...channel.trigger_end,
					input: event.target.value,
				},
			});
		}
	};

	const handleTriggerTimeUnitSelect = start_end => event => {
		if (start_end === 'start') {
			setChannel({
				...channel,
				trigger_start: {
					...channel.trigger_start,
					time_unit: event.target.value,
				},
			});
		} else {
			setChannel({
				...channel,
				trigger_end: {
					...channel.trigger_end,
					time_unit: event.target.value,
				},
			});
		}
	};

	const handleAutoReloadChange = start_end => event => {
		if (start_end === 'start') {
			setChannel({
				...channel,
				trigger_start: {
					...channel.trigger_start,
					auto_reload: event.target.checked,
				},
			});
		} else {
			setChannel({
				...channel,
				trigger_end: {
					...channel.trigger_end,
					auto_reload: event.target.checked,
				},
			});
		}
	};

	const handleTriggerCountChange = start_end => event => {
		if (start_end === 'start') {
			setChannel({
				...channel,
				trigger_start: {
					...channel.trigger_start,
					count: parseInt(event.target.value),
				},
			});
		} else {
			setChannel({
				...channel,
				trigger_end: {
					...channel.trigger_end,
					count: parseInt(event.target.value),
				},
			});
		}
	};

	const handleTriggerThresLowChange = start_end => event => {
		if (start_end === 'start') {
			setChannel({
				...channel,
				trigger_start: {
					...channel.trigger_start,
					threshold_low: parseInt(event.target.value),
				},
			});
		} else {
			setChannel({
				...channel,
				trigger_end: {
					...channel.trigger_end,
					threshold_low: parseInt(event.target.value),
				},
			});
		}
	};

	const handleTriggerThresHighChange = start_end => event => {
		if (start_end === 'start') {
			setChannel({
				...channel,
				trigger_start: {
					...channel.trigger_start,
					threshold_high: parseInt(event.target.value),
				},
			});
		} else {
			setChannel({
				...channel,
				trigger_end: {
					...channel.trigger_end,
					threshold_high: parseInt(event.target.value),
				},
			});
		}
	};

	const handleEnableEndTriggerChange = event => {
		var chan = channel;
		if (event.target.checked) {
			chan.trigger_end = {};
			chan.trigger_end['type'] = 'edge_f';
			chan.trigger_end['input'] = 'D0';
		} else {
			delete chan.trigger_end;
		}
		setChannel(chan);
		setEnableEndTrigger(!enableEndTrigger);
	};

	const handleCancel = () => {
		onClose();
	};

	const handleSave = () => {
		var chan = channel;
		if (!enableEndTrigger) {
			delete chan.trigger_end;
		}

		channelArray.splice(selectedChannel, 1, chan);
		onClose(channelArray);
	};

	const triggerType = (trigger, start_end) => {
		switch (trigger.type) {
			case 'edge_r':
				return (
					<ListTable>
						<ListRow>
							<ListHeader>Input:</ListHeader>
							<ListCell>
								<FormControl variant="standard">
									<Select
										labelId="demo-simple-select-standard-label"
										id="demo-simple-select-standard"
										value={trigger.input}
										onChange={handleTriggerInputSelect(
											start_end
										)}
									>
										{digitalInputs.map((input, index) => (
											<MenuItem
												value={input.value}
												key={index}
											>
												{input.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>Count Value:</ListHeader>
							<ListCell>
								<TextField
									variant="standard"
									fullWidth
									defaultValue={trigger.count}
									type="number"
									onChange={handleTriggerCountChange(
										start_end
									)}
								/>
							</ListCell>
						</ListRow>
					</ListTable>
				);

			case 'edge_f':
				return (
					<ListTable>
						<ListRow>
							<ListHeader>Input:</ListHeader>
							<ListCell>
								<FormControl variant="standard">
									<Select
										labelId="demo-simple-select-standard-label"
										id="demo-simple-select-standard"
										value={trigger.input}
										onChange={handleTriggerInputSelect(
											start_end
										)}
									>
										{digitalInputs.map((input, index) => (
											<MenuItem
												value={input.value}
												key={index}
											>
												{input.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>Count Value:</ListHeader>
							<ListCell>
								<TextField
									variant="standard"
									fullWidth
									defaultValue={trigger.count}
									type="number"
									onChange={handleTriggerCountChange(
										start_end
									)}
								/>
							</ListCell>
						</ListRow>
					</ListTable>
				);

			/*case "edge_c":
				return(
					<ListTable>
						<ListRow>
							<ListHeader>Input:</ListHeader>
							<ListCell>
								<FormControl variant="standard" className={classes.formControl}>
									<Select
										labelId="demo-simple-select-standard-label"
										id="demo-simple-select-standard"
										value={trigger.input}
										className={classes.select}
										onChange={handleTriggerInputSelect(start_end)}
									>
										{digitalInputs.map((input,index)=>(
											<MenuItem value={input.value} key={index}>{input.name}</MenuItem>    
										))}
									</Select>
								</FormControl>
							</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>Count Value:</ListHeader>
							<ListCell>
								<TextField
									variant="standard"
									fullWidth
									defaultValue={trigger.count}
									className={classes.textField}
									onChange={handleTriggerCountChange(start_end)}
								/>
							</ListCell>
						</ListRow>
					</ListTable>
				);*/

			case 'periodic':
				return (
					<ListTable>
						<ListRow>
							<ListHeader>Time Unit:</ListHeader>
							<ListCell>
								<FormControl variant="standard">
									<Select
										labelId="demo-simple-select-standard-label"
										id="demo-simple-select-standard"
										value={trigger.time_unit}
										type="number"
										onChange={handleTriggerTimeUnitSelect(
											start_end
										)}
									>
										<MenuItem value="s">Seconds</MenuItem>
										<MenuItem value="m">Minutes</MenuItem>
										<MenuItem value="h">Hours</MenuItem>
									</Select>
								</FormControl>
							</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>Time:</ListHeader>
							<ListCell>
								<TextField
									variant="standard"
									fullWidth
									defaultValue={trigger.count}
									onChange={handleTriggerCountChange(
										start_end
									)}
								/>
							</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>Auto Reload:</ListHeader>
							<ListCell>
								<FormControlLabel
									control={
										<Checkbox
											checked={trigger.auto_reload}
											onChange={handleAutoReloadChange(
												start_end
											)}
											name="auto_reload_periodic"
											color="primary"
										/>
									}
									label="Auto Reload"
								/>
							</ListCell>
						</ListRow>
					</ListTable>
				);

			case 'scheduled':
				return (
					<ListTable>
						<ListRow>
							<h2>ADD CODE FOR THIS</h2>
							<ListHeader>Time Unit:</ListHeader>
							<ListCell>
								<FormControl variant="standard">
									<Select
										labelId="demo-simple-select-standard-label"
										id="demo-simple-select-standard"
										value={trigger.time_uint}
										onChange={handleTriggerTimeUnitSelect(
											start_end
										)}
									>
										<MenuItem value="s">Seconds</MenuItem>
										<MenuItem value="m">Minutes</MenuItem>
										<MenuItem value="h">Hours</MenuItem>
									</Select>
								</FormControl>
							</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>Time:</ListHeader>
							<ListCell>
								<TextField
									variant="standard"
									fullWidth
									defaultValue={trigger.count}
									type="number"
									onChange={handleTriggerCountChange(
										start_end
									)}
								/>
							</ListCell>
						</ListRow>
					</ListTable>
				);

			case 'value_l':
				return (
					<ListTable>
						<ListRow>
							<ListHeader>Input:</ListHeader>
							<ListCell>
								<FormControl variant="standard">
									<Select
										labelId="demo-simple-select-standard-label"
										id="demo-simple-select-standard"
										value={trigger.input}
										onChange={handleTriggerInputSelect(
											start_end
										)}
									>
										{analogueInputs.map((input, index) => (
											<MenuItem
												value={input.value}
												key={index}
											>
												{input.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>Low Threshold:</ListHeader>
							<ListCell>
								<TextField
									variant="standard"
									fullWidth
									defaultValue={trigger.threshold_low}
									type="number"
									onChange={handleTriggerThresLowChange(
										start_end
									)}
								/>
							</ListCell>
						</ListRow>
					</ListTable>
				);

			case 'value_h':
				return (
					<ListTable>
						<ListRow>
							<ListHeader>Input:</ListHeader>
							<ListCell>
								<FormControl variant="standard">
									<Select
										labelId="demo-simple-select-standard-label"
										id="demo-simple-select-standard"
										value={trigger.input}
										onChange={handleTriggerInputSelect(
											start_end
										)}
									>
										{analogueInputs.map((input, index) => (
											<MenuItem
												value={input.value}
												key={index}
											>
												{input.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>High Threshold:</ListHeader>
							<ListCell>
								<TextField
									variant="standard"
									fullWidth
									defaultValue={trigger.high_thershold}
									type="number"
									onChange={handleTriggerThresHighChange(
										start_end
									)}
								/>
							</ListCell>
						</ListRow>
					</ListTable>
				);

			case 'value_ri':
				return (
					<ListTable>
						<ListRow>
							<ListHeader>Input:</ListHeader>
							<ListCell>
								<FormControl variant="standard">
									<Select
										labelId="demo-simple-select-standard-label"
										id="demo-simple-select-standard"
										value={trigger.input}
										onChange={handleTriggerInputSelect(
											start_end
										)}
									>
										{analogueInputs.map((input, index) => (
											<MenuItem
												value={input.value}
												key={index}
											>
												{input.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>Low Threshold:</ListHeader>
							<ListCell>
								<TextField
									variant="standard"
									fullWidth
									defaultValue={trigger.threshold_low}
									type="number"
									onChange={handleTriggerThresLowChange(
										start_end
									)}
								/>
							</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>High Threshold:</ListHeader>
							<ListCell>
								<TextField
									variant="standard"
									fullWidth
									defaultValue={trigger.threshold_high}
									type="number"
									onChange={handleTriggerThresHighChange(
										start_end
									)}
								/>
							</ListCell>
						</ListRow>
					</ListTable>
				);
			case 'value_ro':
				return (
					<ListTable>
						<ListRow>
							<ListHeader>Input:</ListHeader>
							<ListCell>
								<FormControl variant="standard">
									<Select
										labelId="demo-simple-select-standard-label"
										id="demo-simple-select-standard"
										value={trigger.input}
										onChange={handleTriggerInputSelect(
											start_end
										)}
									>
										{analogueInputs.map((input, index) => (
											<MenuItem
												value={input.value}
												key={index}
											>
												{input.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>Low Threshold:</ListHeader>
							<ListCell>
								<TextField
									variant="standard"
									fullWidth
									defaultValue={trigger.threshold_low}
									type="number"
									onChange={handleTriggerThresLowChange(
										start_end
									)}
								/>
							</ListCell>
						</ListRow>
						<ListRow>
							<ListHeader>High Threshold:</ListHeader>
							<ListCell>
								<TextField
									variant="standard"
									fullWidth
									defaultValue={trigger.threshold_high}
									type="number"
									onChange={handleTriggerThresHighChange(
										start_end
									)}
								/>
							</ListCell>
						</ListRow>
					</ListTable>
				);
			default:
				return;
		}
	};

	const inputsMenu = param_function => {
		switch (param_function) {
			case 'trig_val':
				return paramFunc['trig_val'].map((input, index) => (
					<MenuItem value={input.value} key={index}>
						{input.name}
					</MenuItem>
				));
			case 'count_r':
				return paramFunc['count_r'].map((input, index) => (
					<MenuItem value={input.value} key={index}>
						{input.name}
					</MenuItem>
				));
			case 'count_f':
				return paramFunc['count_f'].map((input, index) => (
					<MenuItem value={input.value} key={index}>
						{input.name}
					</MenuItem>
				));
			case 'max':
				return paramFunc['max'].map((input, index) => (
					<MenuItem value={input.value} key={index}>
						{input.name}
					</MenuItem>
				));
			case 'min':
				return paramFunc['min'].map((input, index) => (
					<MenuItem value={input.value} key={index}>
						{input.name}
					</MenuItem>
				));
			case 'ave':
				return paramFunc['ave'].map((input, index) => (
					<MenuItem value={input.value} key={index}>
						{input.name}
					</MenuItem>
				));
			case 'integral':
				return paramFunc['integral'].map((input, index) => (
					<MenuItem value={input.value} key={index}>
						{input.name}
					</MenuItem>
				));
			case 'accumulator':
				return paramFunc['accumulator'].map((input, index) => (
					<MenuItem value={input.value} key={index}>
						{input.name}
					</MenuItem>
				));
			default:
				return;
		}
	};

	return (
		<>
			<Dialog
				fullScreen
				open={setOpen}
				onClose={handleSelectConfigClose}
				TransitionProps={{
					onEnter: handleOnEnter,
				}}
			>
				<DialogTitle id="form-dialog-title">Edit Channel</DialogTitle>
				<DialogContent>
					{isLoading && (
						<>
							<p>Loading...</p>
							<CircularProgress size={'120px'} />
						</>
					)}

					{!isLoading && (
						<Box display="flex" flexDirection="column">
							<Box flexGrow={1}>
								<Typography variant="h6">
									Channel Name:
								</Typography>
								<TextField
									variant="standard"
									fullWidth
									defaultValue={channel.name}
									onChange={handleNameChange}
								/>

								<Divider />

								<Typography variant="h6">Triggers:</Typography>
								<Grid
									container
									alignItems="stretch"
									spacing={3}
								>
									<Grid item xs={12} sm={6}>
										<Box>
											<Typography variant="h6">
												Start Trigger:
											</Typography>
											<Divider />
											<ListTable>
												<ListRow>
													<ListHeader>
														Trigger Type:
													</ListHeader>
													<ListCell>
														<FormControl variant="standard">
															<Select
																labelId="demo-simple-select-standard-label"
																id="demo-simple-select-standard"
																value={
																	channel
																		.trigger_start
																		.type
																}
																onChange={handleTriggerSelect(
																	'start'
																)}
															>
																<MenuItem value="periodic">
																	Periodic
																</MenuItem>
																<MenuItem value="scheduled">
																	Scheduled
																</MenuItem>
																<MenuItem value="edge_r">
																	Rising Edge
																</MenuItem>
																<MenuItem value="edge_f">
																	Falling Edge
																</MenuItem>
																<MenuItem value="value_l">
																	Below
																	Threshold
																</MenuItem>
																<MenuItem value="value_h">
																	Above
																	Threshold
																</MenuItem>
																<MenuItem value="value_ri">
																	In Range
																</MenuItem>
																<MenuItem value="value_ro">
																	Out of Range
																</MenuItem>
															</Select>
														</FormControl>
													</ListCell>
												</ListRow>
											</ListTable>

											<Divider />
											{triggerType(
												channel.trigger_start,
												'start'
											)}
										</Box>
									</Grid>

									<Grid item xs={12} sm={6}>
										<Box>
											<Typography variant="h6">
												End Trigger:
											</Typography>
											<Divider />
											<FormControlLabel
												control={
													<Checkbox
														checked={
															enableEndTrigger
														}
														onChange={
															handleEnableEndTriggerChange
														}
														name="EndTrigger"
														color="primary"
													/>
												}
												label="Use end trigger"
											/>
											{enableEndTrigger && (
												<>
													<ListTable>
														<ListRow>
															<ListHeader>
																Trigger Type:
															</ListHeader>
															<ListCell>
																<FormControl variant="standard">
																	<Select
																		labelId="demo-simple-select-standard-label"
																		id="demo-simple-select-standard"
																		value={
																			channel
																				.trigger_end
																				.type
																		}
																		onChange={handleTriggerSelect(
																			'end'
																		)}
																	>
																		<MenuItem value="periodic">
																			Periodic
																		</MenuItem>
																		<MenuItem value="scheduled">
																			Scheduled
																		</MenuItem>
																		<MenuItem value="edge_r">
																			Rising
																			Edge
																		</MenuItem>
																		<MenuItem value="edge_f">
																			Falling
																			Edge
																		</MenuItem>
																		<MenuItem value="value_l">
																			Below
																			Threshold
																		</MenuItem>
																		<MenuItem value="value_h">
																			Above
																			Threshold
																		</MenuItem>
																		<MenuItem value="value_ri">
																			In
																			Range
																		</MenuItem>
																		<MenuItem value="value_ro">
																			Out
																			of
																			Range
																		</MenuItem>
																	</Select>
																</FormControl>
															</ListCell>
														</ListRow>
													</ListTable>

													<Divider />
													{triggerType(
														channel.trigger_end,
														'end'
													)}
												</>
											)}
										</Box>
									</Grid>
								</Grid>

								<Divider />

								<Typography variant="h6">
									Parameters:
								</Typography>
								<Grid container>
									{channel.params.map((param, index) => (
										<Grid
											item
											xs={12}
											sm={6}
											md={4}
											xl={3}
											key={index}
										>
											<Box>
												<ListTable>
													<ListRow>
														<ListHeader>
															Name:
														</ListHeader>
														<ListCell>
															<TextField
																variant="standard"
																fullWidth
																defaultValue={
																	param.name
																}
																onChange={handleParamNameChange(
																	index
																)}
															/>
														</ListCell>
													</ListRow>
													<ListRow>
														<ListHeader>
															Function:
														</ListHeader>
														<ListCell>
															<FormControl variant="standard">
																<Select
																	labelId="demo-simple-select-standard-label"
																	id="demo-simple-select-standard"
																	value={
																		param.function
																	}
																	onChange={handleParamFunctionChange(
																		index
																	)}
																>
																	<MenuItem value="trig_val">
																		Value at
																		trigger
																	</MenuItem>
																	<MenuItem value="count_r">
																		Count
																		Rising
																		Edges
																	</MenuItem>
																	<MenuItem value="count_f">
																		Count
																		Falling
																		Edges
																	</MenuItem>
																	<MenuItem value="max">
																		Maximum
																		Value
																	</MenuItem>
																	<MenuItem value="min">
																		Minimum
																		Value
																	</MenuItem>
																	<MenuItem value="ave">
																		Average
																		Value
																	</MenuItem>
																	<MenuItem value="integral">
																		Integral
																		of Value
																	</MenuItem>
																	<MenuItem value="accumulator">
																		Total
																		During
																		Period
																	</MenuItem>
																</Select>
															</FormControl>
														</ListCell>
													</ListRow>
													<ListRow>
														<ListHeader>
															Input:
														</ListHeader>
														<ListCell>
															<FormControl variant="standard">
																<Select
																	labelId="demo-simple-select-standard-label"
																	id="demo-simple-select-standard"
																	value={
																		param.input
																	}
																	onChange={handleParamInputChange(
																		index
																	)}
																>
																	{inputsMenu(
																		param.function
																	)}
																	{/*<MenuItem value='D0'>Digital 0</MenuItem>
                                                            <MenuItem value='D1'>Digital 1</MenuItem>
                                                            <MenuItem value='D2'>Digital 2</MenuItem>
                                                            <MenuItem value='D3'>Digital 3</MenuItem>
                                                            <MenuItem value='A0'>Analogue 0</MenuItem>
                                                            <MenuItem value='A1'>Analogue 1</MenuItem>
                                                            <MenuItem value='A2'>Analogue 2</MenuItem>
                            <MenuItem value='A3'>Analogue 3</MenuItem>*/}
																</Select>
															</FormControl>
														</ListCell>
													</ListRow>
												</ListTable>

												<Button
													variant="standard"
													startIcon={
														<DeleteOutlineIcon />
													}
													onClick={handleDeleteParam(
														index
													)}
												>
													Delete Parameter
												</Button>
											</Box>
										</Grid>
									))}
								</Grid>
							</Box>

							<Button
								variant="standard"
								startIcon={<AddCircleIcon />}
								onClick={handleAddParam}
							>
								Add Parameter
							</Button>

							<Box>
								<Divider />
								<Box display="flex" flexDirection="row-reverse">
									<Button
										variant="standard"
										startIcon={<SaveIcon />}
										onClick={handleSave}
									>
										Save
									</Button>

									<Button
										variant="standard"
										startIcon={<CloseIcon />}
										onClick={handleCancel}
									>
										Cancel
									</Button>
								</Box>
							</Box>
						</Box>
					)}
				</DialogContent>
			</Dialog>
		</>
	);
}
