import React, { useEffect, useMemo } from 'react';
import { useNavContext } from '../../context/ContextManager';
import insights_options from './InsightsOptions';
import insights_nav_item from './InsightsNavItem';
import useNavRedactor from '../../hooks/useNavRedactor';
import BtScreens from '../../components/generic/screens/BtScreens';

export default function Insights() {
	const { setContextualNav } = useNavContext();
	const { setBreadcrumbs } = useNavContext();
	const redactNav = useNavRedactor();

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'Insights', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	const redactedInsightsOptions = useMemo(() => redactNav(insights_options), [
		redactNav,
	]);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([
				...insights_nav_item,
				...redactedInsightsOptions,
			]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav, redactedInsightsOptions]
	);

	return <BtScreens screenGroupKey="insights" />;
}
