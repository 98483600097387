const yup = require('yup');

export const dataSourceGetListSchema = yup.array().of(
	yup.object({
		uuid: yup
			.string()
			.uuid()
			.required(),
		name: yup.string().default(''),
		description: yup.string().default(''),
		create_timestamp: yup.number().default(0),
		modify_timestamp: yup.number().default(0),
        type: yup.string().required()
	})
);