import { useMemo } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { RecordManagementContextProvider } from '../../../components/generic/recordManagement/RecordManagementContext';
import { WORKFLOW_TEMPLATE } from '../../../components/generic/recordManagement/utils/constants';
import BtAuthRoute from '../../../components/generic/BtAuthRoute';
import RecordManager from '../../../components/generic/recordManagement/components/RecordManager';
import { recordManagerRequiredPermissionsAll } from '../../../components/generic/recordManagement/utils/lookupTables/permissions';
import WorkflowTemplateEdit from './WorkflowTemplateEdit';

export default function WorkflowTemplateEditRoute({ match }) {
	const templateId = useMemo(() => match.params.id, [match]);
	const module = WORKFLOW_TEMPLATE;
	const requiredPermissionsAll = recordManagerRequiredPermissionsAll[module];

	return (
		<Switch>
			<>
				<RecordManagementContextProvider
					module={WORKFLOW_TEMPLATE}
					// module={AUTOMATION}
					recordUuid={templateId}
				>
					<BtAuthRoute
						exact
						path="/Workflows/Templates/:id/Settings"
						component={RecordManager}
						requiredPermissionsAll={requiredPermissionsAll}
						validSubscriptionsAny={['Workflow Editor']}
					/>
				</RecordManagementContextProvider>
				<BtAuthRoute
					exact
					path="/Workflows/Templates/:id/Edit"
					component={WorkflowTemplateEdit}
					validSubscriptionsAny={['Workflow Editor']}
				/>
				{/* <InsightCollectionEditContextProvider
					insightCollectionUuid={insightId}
					editMode={true}
				>
					<BtAuthRoute
						exact
						path="/InsightCollection/:id/Edit/Collection"
						component={InsightCollectionEditCollection}
						requiredPermissionsAll={{ insights: ['InsightsEdit'] }}
						validSubscriptionsAny={['Insights Editor']}
					/>
					<BtAuthRoute
						exact
						path="/InsightCollection/:id/Edit/Pages"
						component={InsightCollectionEditPages}
						requiredPermissionsAll={{ insights: ['InsightsEdit'] }}
						validSubscriptionsAny={['Insights Editor']}
					/>
					<BtAuthRoute
						exact
						path="/InsightCollection/:id/Edit/DataSources"
						component={InsightCollectionEditDataSources}
						requiredPermissionsAll={{ insights: ['InsightsEdit'] }}
						validSubscriptionsAny={['Insights Editor']}
					/>
					<BtAuthRoute
						exact
						path="/InsightCollection/:id/Edit/Tile"
						component={InsightCollectionEditTile}
						requiredPermissionsAll={{ insights: ['InsightsEdit'] }}
						validSubscriptionsAny={['Insights Editor']}
					/>
					<BtAuthRoute
						exact
						path="/InsightCollection/:id/Settings"
						component={InsightCollectionSettingsEdit}
						requiredPermissionsAll={{ insights: ['InsightsEdit'] }}
						validSubscriptionsAny={['Insights Editor']}
					/>
				</InsightCollectionEditContextProvider> */}
			</>
		</Switch>

		//
	);
}
