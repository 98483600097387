import React from 'react';
import * as yup from 'yup';

import BtQueryBuilderPropertyFormField from '../BtQueryBuilderPropertyField';
import { determineValueType, replaceDynamicTypedValue } from './utils';
import { baseStagePropTypes } from './base-prop-type';

const formSchema = yup.object().shape({
	limit_type: yup.string().oneOf(['static_number', 'variable']),
	limit: yup.mixed().when(['limit_type'], {
		is: limit_type => limit_type === 'variable',
		then: yup.string().required(),
		otherwise: yup
			.number()
			.required()
			.min(1)
			.integer(),
	}),
});

const stageType = '$limit';

function buildFormValues(queryStage, flatConfigSchema, variables) {
	const n = parseInt(queryStage[stageType], 10);
	const [limitType, limitValue] = determineValueType(
		queryStage[stageType],
		{},
		false,
		variables
	);

	//console.log(limitType, limitValue);

	return {
		limit: limitType === 'static_number' ? n : limitValue,
		limit_type: limitType,
	};
}

function buildQueryStage(fields, variables) {
	return {
		[stageType]: replaceDynamicTypedValue('limit', fields, variables),
	};
}

function LimitFormContent(props) {
	const { disabled, readOnly } = props;

	return (
		<BtQueryBuilderPropertyFormField
			label="Limit"
			name="limit"
			type="number"
			allowedFieldTypes={['variable', 'static']}
			isRequired
			disabled={disabled}
			readOnly={readOnly}
		/>
	);
}

export default {
	formSchema: formSchema,
	FormContent: LimitFormContent,
	buildQueryStage: buildQueryStage,
	buildFormValues: buildFormValues,
};

LimitFormContent.propTypes = baseStagePropTypes;
