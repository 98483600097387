import React from 'react';

import {
	DialogContent,
	DialogTitle,
	Button,
	DialogActions,
} from '@mui/material';

import BtDialog from '../../../components/generic/BtDialog';
import ActionButtonContainer from '../../../components/generic/ActionButtonContainer';

export default function TermsDialog({ open, onClose }) {
	return (
		<BtDialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<DialogTitle>Terms of Use</DialogTitle>
			<DialogContent>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
				lorem neque, aliquam vel tristique scelerisque, viverra vitae
				velit. Sed commodo erat id pellentesque consectetur. Vestibulum
				rutrum eleifend mauris ac fermentum. Pellentesque ut iaculis
				ante. Suspendisse eu ipsum eros. Fusce faucibus, lectus sed
				ultricies convallis, quam felis scelerisque magna, sed tincidunt
				leo nunc non tellus. Mauris tempor aliquet porta. Integer
				volutpat id elit quis pulvinar. Mauris consequat, nibh ac
				tristique lobortis, tortor enim convallis massa, nec tempor
				nulla dolor non nibh. Nulla facilisi. Aenean molestie, tortor et
				fermentum porttitor, nibh massa facilisis orci, ac eleifend mi
				nulla ac quam. Morbi condimentum varius lacus, nec tempus nunc
				congue vitae. Donec molestie nunc libero, at pretium sapien
				viverra et. Fusce mattis rutrum eros, ut rutrum turpis semper
				quis. Quisque venenatis nisi sed nibh dignissim, nec elementum
				nulla laoreet. Phasellus aliquam neque eget ligula bibendum
				maximus. Nam sollicitudin consectetur dolor aliquam consequat.
				Aliquam in nisl massa. Sed in lacus ligula. Nulla ultricies
				porta mollis. Sed magna dolor, consectetur ut metus sit amet,
				pharetra tincidunt libero. Sed hendrerit, ante quis convallis
				maximus, neque risus cursus risus, eget euismod neque elit vel
				massa. Quisque lacinia pharetra mauris. Nunc non felis est. Ut
				ex quam, vestibulum et imperdiet ut, sagittis vel ipsum. Aliquam
				aliquam est vitae diam finibus lacinia. Suspendisse eget ex in
				libero pellentesque auctor non quis dolor. Nam tellus neque,
				varius eu volutpat at, convallis at velit. Vivamus at malesuada
				arcu. Nam ac libero magna. Mauris neque quam, vulputate at ex
				in, vehicula pharetra justo. Vestibulum ante ipsum primis in
				faucibus orci luctus et ultrices posuere cubilia curae;
				Suspendisse at malesuada ipsum, eget consequat velit. Praesent
				at vehicula justo. Lorem ipsum dolor sit amet, consectetur
				adipiscing elit. Proin ut dui fringilla, consectetur neque quis,
				pretium mi.
			</DialogContent>
			<DialogActions>
				<ActionButtonContainer>
					<Button
						onClick={onClose}
						variant="contained"
						disableElevation
						// startIcon={<SaveIcon />}
					>
						Close
					</Button>
				</ActionButtonContainer>
			</DialogActions>
		</BtDialog>
	);
}
