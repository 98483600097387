import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	CircularProgress,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
} from '@mui/material';
import CreateIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import BtDialog from '../../../components/generic/BtDialog';
import {
	BtForm,
	BtFormActionsContainer,
	BtFormContent,
	BtFormTextField,
	withFormContextMethods,
} from '../../../components/generic/forms';
import { apiManagerAdd } from '../../../API';

const blankDefaults = {
	name: '',
	description: '',
};

const ActionButtons = withFormContextMethods(
	({ formState: { isDirty }, onClose, sending }) => {
		return (
			<>
				<Button variant="outlined" onClick={onClose} disabled={sending}>
					Cancel
				</Button>
				<Button
					variant="contained"
					disabled={!isDirty || sending}
					type="submit"
					startIcon={
						sending ? (
							<CircularProgress size={20} />
						) : (
							<CreateIcon />
						)
					}
					disableElevation
				>
					Create API Key
				</Button>
			</>
		);
	}
);

export default function NewApiDialog({ existingNames, onClose, open }) {
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();

	const [sending, setSending] = useState(false);

	const schema = useMemo(
		() =>
			yup.object({
				name: yup
					.string()
					.min(3)
					.notOneOf(
						existingNames || [],
						'An API key with this name already exists'
					)
					.required()
					.label('API Key Name'),
				description: yup
					.string()
					.min(3)
					.required()
					.label('API Key Description'),
			}),
		[existingNames]
	);

	const onSubmit = useCallback(
		async (values, reset) => {
			setSending(true);
			try {
				const newApiKey = {
					name: values.name,
					description: values.description,
					enabled: false,
				};
				const { uuid } = await apiManagerAdd({ newApiKey: newApiKey });
				reset(values);
				enqueueSnackbar('Created API Key', {
					variant: 'success',
				});
				history.push(`/ApiManager/Edit/${uuid}`);
			} catch (error) {
				setSending(false);
				enqueueSnackbar('Failed to create API Key', {
					variant: 'error',
				});
			}
		},
		[enqueueSnackbar, history]
	);

	return (
		<BtDialog open={open} onClose={onClose} minwidth="sm" fullWidth>
			{sending && (
				<LinearProgress
					style={{
						position: 'absolute',
						top: 0,
						width: '100%',
					}}
				/>
			)}
			<DialogTitle>Create New API Key</DialogTitle>
			<BtForm
				onSubmit={onSubmit}
				sending={sending}
				validationSchema={schema}
				defaultValues={blankDefaults}
			>
				<DialogContent>
					<BtFormContent>
						<BtFormTextField name="name" label="Name" autoFocus />
						<BtFormTextField
							name="description"
							label="Description"
							multiline
						/>
					</BtFormContent>
				</DialogContent>
				<DialogActions>
					<BtFormActionsContainer>
						<ActionButtons onClose={onClose} sending={sending} />
					</BtFormActionsContainer>
				</DialogActions>
			</BtForm>
		</BtDialog>
	);
}

ActionButtons.propTypes = {
	isDirty: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	sending: PropTypes.bool.isRequired,
};

NewApiDialog.propTypes = {
	existingNames: PropTypes.arrayOf(PropTypes.string),
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};
