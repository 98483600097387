import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, ListItem, Typography, Skeleton } from '@mui/material';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { useSnackbar } from 'notistack';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

import admin_nav_item from '../AdminNavItem';
import admin_options from '../AdminOptions';
import BtError from '../../../components/generic/BtError';
import {
	BtFormContainer,
	BtFormSection,
} from '../../../components/generic/forms';
import BtListComposer from '../../../components/generic/BtListComposer';
import BtUserRow from '../../../components/generic/BtUserRow';
import {
	subscriptionAddUser,
	subscriptionsGet,
	subscriptionRemoveUser,
} from '../../../API/subscriptions.api';
import useFetch from '../../../hooks/useFetch';
import { userAdminGetList } from '../../../API/user_admin';
import { useNavContext } from '../../../context/ContextManager';

export default function SubscriptionAssignment({ match }) {
	const subscriptionId = useMemo(() => match?.params?.id, [match.params.id]);
	const { enqueueSnackbar } = useSnackbar();

	const [addedUserIds, setAddedUserIds] = useState([]);
	const [title, setTitle] = useState('');
	const [userLimit, setUserLimit] = useState(0);

	// Set contextual navigation items and breadcrumbs
	const { setContextualNav, setBreadcrumbs } = useNavContext();
	useEffect(
		() => {
			setContextualNav([...admin_nav_item, ...admin_options]);
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'Admin', link: '/Admin' },
				{ text: 'Subscriptions', link: '/Subscriptions' },
				{ text: 'Assigness', link: '' },
			]);
			return () => setContextualNav(null);
		},
		[setContextualNav, setBreadcrumbs]
	);

	// Sets the subtitle and pre-populates added users
	const setTitleAndAddedUsers = useCallback(subscription => {
		setTitle(subscription.subscription);
		setAddedUserIds([...subscription.users]);
		setUserLimit(subscription.maxUsers);
	}, []);

	// Fetch subscription info
	const {
		error: subError,
		loading: loadingSub,
		request: getSubscription,
	} = useFetch(subscriptionsGet, setTitleAndAddedUsers);

	// Fetch applicable users
	const {
		data: userList,
		error: userError,
		loading: loadingUsers,
		request: getUsers,
	} = useFetch(userAdminGetList);

	// Get subscription info and applicable users
	useEffect(
		() => {
			if (subscriptionId) {
				getSubscription({ subUuid: subscriptionId });
			}
			getUsers();
		},
		[getSubscription, getUsers, subscriptionId]
	);

	const addUser = useCallback(
		async ({ uuid: newUserId }) => {
			setAddedUserIds(prev => [...prev, newUserId]);

			try {
				await subscriptionAddUser({
					subUuid: subscriptionId,
					userUuid: newUserId,
				});
			} catch {
				setAddedUserIds(prev =>
					prev.filter(addedUserId => addedUserId !== newUserId)
				);
				enqueueSnackbar('Could not add user', {
					variant: 'error',
				});
			}
		},
		[enqueueSnackbar, subscriptionId]
	);

	const removeUser = useCallback(
		async ({ uuid: userIdToRemove }) => {
			setAddedUserIds(prev =>
				prev.filter(addedUserId => addedUserId !== userIdToRemove)
			);

			try {
				await subscriptionRemoveUser({
					subUuid: subscriptionId,
					userUuid: userIdToRemove,
				});
			} catch {
				setAddedUserIds(prev => [...prev, userIdToRemove]);
				enqueueSnackbar('Could not remove user', {
					variant: 'error',
				});
			}
		},
		[enqueueSnackbar, subscriptionId]
	);

	const loading = useMemo(() => loadingSub || loadingUsers, [
		loadingSub,
		loadingUsers,
	]);

	const remainingAssignments = useMemo(
		() => userLimit - (addedUserIds || []).length,
		[addedUserIds, userLimit]
	);

	if (subError || userError) {
		return (
			<BtError
				variant="error"
				title={'Retrieval Error'}
				description={`An error occurred when attempting to retrieve the ${
					subError ? 'subscription' : 'applicable users'
				}.`}
				action={
					subError
						? () => getSubscription({ subUuid: subscriptionId })
						: getUsers
				}
			/>
		);
	}

	return (
		<BtFormContainer style={{ maxWidth: 640 }} title={title}>
			<BtFormSection title={loading ? '' : 'Users'}>
				{loading && (
					<div style={{ marginTop: '2em' }}>
						<Skeleton
							animation="wave"
							style={{
								width: '100%',
								maxWidth: 80,
								borderRadius: 8,
								marginBottom: '2em',
							}}
							variant="rectangle"
							height={30}
						/>
						<Skeleton
							animation="wave"
							style={{
								width: '50%',
								borderRadius: 8,
								marginBottom: '1.5em',
							}}
							variant="rectangle"
						/>
						<Skeleton
							animation="wave"
							style={{
								width: '100%',
								borderRadius: 8,
							}}
							variant="rectangle"
							height={39}
						/>
						<Skeleton
							style={{
								width: '100%',
								height: 200,
								marginTop: '2em',
								borderRadius: 8,
							}}
							animation="wave"
							variant="rectangular"
						/>
					</div>
				)}
				{!loading && (
					<>
						<div style={{ margin: '0.2em 0' }}>
							<Box
								style={{
									display: 'flex',
									marginTop: '0.5em',
								}}
							>
								{!remainingAssignments && (
									<WarningRoundedIcon
										fontSize="small"
										color="warning"
										style={{ marginRight: '0.1em' }}
									/>
								)}
								<Typography variant="subtitle2">
									{Math.max(remainingAssignments, 0)
										? `${remainingAssignments} more user assignment slot${
												remainingAssignments !== 1
													? 's'
													: ''
										  } available`
										: 'You have reached your user assignment limit for this subscription'}
								</Typography>
							</Box>
						</div>
						<BtListComposer
							allItems={userList || []}
							confirmDeletion
							deleteable
							deleteVerb="Remove"
							items={(userList || []).filter(user =>
								addedUserIds.includes(user.uuid)
							)}
							primaryField="user_name"
							DeleteIcon={<PersonRemoveIcon />}
							sortComparator={(a, b) =>
								a?.user_name?.localeCompare(b?.user_name)
							}
							maxItems={userLimit}
							renderOption={(props, user) => (
								<ListItem
									{...props}
									style={{
										padding: '0 1em',
									}}
								>
									<BtUserRow
										key={user.uuid}
										user={user}
										hideAvatar
									/>
								</ListItem>
							)}
							renderItem={user => (
								<BtUserRow key={user.uuid} user={user} />
							)}
							subject="users"
							onItemAdd={addUser}
							onItemDelete={removeUser}
						/>
					</>
				)}
			</BtFormSection>
		</BtFormContainer>
	);
}
