import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useAppContext } from '../../../context/ContextManager';
import BtTileLayout from './BtTileLayout';
import PropTypes from 'prop-types';
import { v4 as uuidV4 } from 'uuid';
import useFetch from '../../../hooks/useFetch';
import { screenGroupGet, screenUpdate } from '../../../API';
import BtLoading from '../BtLoading';
import {
	addScreen,
	editScreen,
	removeScreen,
	renameScreen,
} from './functions/screenConfig';
import _ from 'lodash';
import {
	nativeAddTile,
	nativeLayoutUpdate,
	nativeRemoveTile,
	updateScreen,
	updateScreenTile,
} from './functions/tileConfig';
import BtError from '../BtError';
import { Typography } from '@mui/material';
import {
	screenAdminGet,
	screenAdminUpdate,
} from '../../../API/screen_admin.api';
import ScreenPlaceholder from './components/ScreenPlaceholder';
import { ScreensContext } from './ScreensContext';

export const BtTileLayouts = () => {
	const { deviceType, appOrg, userInfo } = useAppContext();
	const [showAddMenu, setShowAddMenu] = useState(false);
	const [screensRefreshPending, setScreensRefreshPending] = useState(false);

	const {
		screenGroupKey,
		screenAdminUuid,
		adminMode,
		screens,
		setScreens,
		currentScreen,
		loading,
		setLoading,
	} = useContext(ScreensContext);

	const {
		loading: screenGroupLoading,
		data: screenGroup,
		error: screenGroupError,
		request: fetchScreenGroup,
	} = useFetch(screenGroupGet);

	const {
		loading: fetchScreenAdminLoading,
		data: adminScreen,
		error: fetchScreenAdminError,
		request: fetchScreenAdmin,
	} = useFetch(screenAdminGet);

	useEffect(
		() => {
			if (screenAdminUuid) {
				fetchScreenAdmin({ screenUuid: screenAdminUuid });
			} else {
				fetchScreenGroup({ screenGroup: screenGroupKey });
			}
		},
		[fetchScreenAdmin, fetchScreenGroup, screenAdminUuid, screenGroupKey]
	);

	// useEffect(
	// 	() => {
	// 		fetchScreenList({ ownerFilter: 'User' });
	// 	},
	// 	[fetchScreenList]
	// );

	// useEffect(
	// 	() => {
	// 		if (screenAdminUuid) fetchScreen({ screenUuid: screenAdminUuid });
	// 	},
	// 	[fetchScreen, screenAdminUuid]
	// );

	// useEffect(() => console.log({ loading }), [loading]);

	// useEffect(() => console.log({ fetchScreenAdminLoading }), [
	// 	fetchScreenAdminLoading,
	// ]);

	// useEffect(() => console.log({ screensRefreshPending }), [
	// 	screensRefreshPending,
	// ]);

	// useEffect(() => console.log({ screenListError }), [screenListError]);

	// useEffect(() => console.log({ containerDimensions }), [
	// 	containerDimensions,
	// ]);

	useEffect(
		() => {
			switch (adminMode) {
				case true:
					setLoading(fetchScreenAdminLoading);
					break;
				case false:
					setLoading(screenGroupLoading);
					break;
				default:
					break;
			}
		},
		[
			adminMode,
			fetchScreenAdminLoading,
			screenGroupLoading,
			screensRefreshPending,
			setLoading,
		]
	);

	// useEffect(
	// 	() => {
	// 		if (adminScreen) console.log({ adminScreen });
	// 	},
	// 	[fetchScreenAdminLoading, adminScreen]
	// );

	// Respond to changes to the tiles supplied by the API
	useEffect(
		() => {
			// console.log({ adminMode });

			if (!loading) {
				switch (adminMode) {
					case true:
						if (adminScreen) {
							// console.log('admin screen edit');
							let newScreens = [];
							newScreens.push(adminScreen);
							setScreens(newScreens);
						}
						break;
					case false:
						if (screenGroup) {
							// console.log('Tiles update', screenGroup);
							setScreens(screenGroup);
						}
						break;
					default:
						break;
				}
			}
		},
		[adminMode, adminScreen, loading, screenGroup, setScreens]
	);

	/**
	 * @param layout - RGL layout update array for a single device type of a single screen
	 * @param index - the index of the screen to which the layout belongs
	 * @param newTile - a tile data object defining a newly added tile
	 * @param newTileUuid - the uuid of the newly added tile
	 */
	const onLayoutChange = useCallback(
		async (layout, index, newTile, newTileUuid) => {
			try {
				// console.log({ layout, index, newTile, newTileUuid });

				// console.log({ newTile });
				// setScreensRefreshPending(true);

				// transform the layout data as per back end
				const layoutUpdate = layout.map(layoutItem => ({
					tile_uuid: layoutItem.i, // uuid of the tile content
					uuid: newTile
						? uuidV4()
						: screens[index].layouts[deviceType].find(
								({ tile_uuid }) => tile_uuid === layoutItem.i
						  ).uuid, // uuid of the layout item

					x: layoutItem.x,
					y: layoutItem.y,
					w: layoutItem.w,
					h: layoutItem.h,
				}));

				// exit if there is no actual change to the layout
				if (_.isEqual(layoutUpdate, screens[index].layouts[deviceType]))
					return;

				// setScreensRefreshPending(true);

				// clone the user's tiles data and add the new layout
				let newScreenGroup = structuredClone(screens);
				newScreenGroup[index].layouts[deviceType] = layoutUpdate;

				//if there is a new tile add to tiles and non-current layouts
				if (newTile) {
					// add the tile
					newScreenGroup[index].tiles[newTileUuid] = newTile;

					// add the tile to the non-current layout
					Object.keys(newScreenGroup[index].layouts).forEach(
						layoutKey => {
							if (layoutKey === 'native') {
								newScreenGroup[index].layouts[layoutKey].push(
									newTileUuid
								);
							} else if (layoutKey !== deviceType) {
								newScreenGroup[index].layouts[layoutKey].push({
									tile_uuid: newTileUuid,
									uuid: uuidV4(),
									x: 0,
									y: 0,
									w: 1,
									h: 1,
								});
							}
						}
					);
				}

				// update the component state
				setScreens(newScreenGroup);

				// update the backend
				if (adminMode) {
					await screenAdminUpdate({
						screenUuid: newScreenGroup[index].uuid,
						screenUpdate: newScreenGroup[index],
					});
				} else {
					await screenUpdate({
						screenUuid: newScreenGroup[index].uuid,
						screenUpdate: newScreenGroup[index],
					});
				}
			} catch (error) {
				console.log({ error });
			}
		},
		[adminMode, deviceType, screens, setScreens]
	);

	const onNativeChange = useCallback(
		({
			mode,
			newScreen,
			tileToRemove,
			currentScreenData,
			variantToAdd,
		}) => {
			setScreensRefreshPending(true);
			switch (mode) {
				case 'layoutUpdate':
					nativeLayoutUpdate({
						adminMode,
						newScreen,
						setScreensRefreshPending,
						setScreens,
						screens,
					});
					break;
				case 'removeTile':
					nativeRemoveTile({
						currentScreenData,
						tileToRemove,
						adminMode,
						setScreensRefreshPending,
						setScreens,
						screens,
					});
					break;
				case 'addTile':
					nativeAddTile({
						currentScreenData,
						variantToAdd,
						adminMode,
						setScreensRefreshPending,
						setScreens,
						screens,
					});
					break;
				default:
					break;
			}
		},
		[adminMode, screens]
	);

	const onDeleteTile = useCallback(
		async (layouts, index, tileToRemoveUuid) => {
			let newScreenGroup = structuredClone(screens);

			const layoutsUpdate = {};

			Object.keys(layouts).forEach(layoutKey => {
				if (layoutKey === 'native') {
					layoutsUpdate[layoutKey] = layouts[layoutKey];
					// layoutsUpdate[layoutKey] = layouts[layoutKey];
				} else {
					layoutsUpdate[layoutKey] = layouts[layoutKey].map(
						layoutItem => ({
							tile_uuid: layoutItem.i,
							uuid: screens[index].layouts[layoutKey].find(
								({ tile_uuid }) => tile_uuid === layoutItem.i
							).uuid, // uuid of the layout item
							x: layoutItem.x,
							y: layoutItem.y,
							w: layoutItem.w,
							h: layoutItem.h,
						})
					);
				}
			});

			const tilesUuids = Object.keys(screens[index].tiles).filter(
				key => key !== tileToRemoveUuid
			);

			const tilesUpdate = {};

			tilesUuids.forEach(tileUuid => {
				tilesUpdate[tileUuid] = screens[index].tiles[tileUuid];
			});

			const tileToRemoveData =
				newScreenGroup[index].tiles[tileToRemoveUuid].variant;
			if (tileToRemoveData.variant === 'container') {
				tileToRemoveData.data.children.forEach(child => {
					delete tilesUpdate[child];
				});
			}

			newScreenGroup[index].layouts = layoutsUpdate;
			newScreenGroup[index].tiles = tilesUpdate;

			// update the component state
			setScreens(newScreenGroup);

			// // update the backend
			// await screenUpdate({
			// 	screenUuid: newScreenGroup[index].uuid,
			// 	screenUpdate: newScreenGroup[index],
			// });

			// update the backend
			if (adminMode) {
				await screenAdminUpdate({
					screenUuid: newScreenGroup[index].uuid,
					screenUpdate: newScreenGroup[index],
				});
			} else {
				await screenUpdate({
					screenUuid: newScreenGroup[index].uuid,
					screenUpdate: newScreenGroup[index],
				});
			}
		},
		[screens]
	);

	/**
	 * Handle changes to screen group. eg add/delete/move a screen
	 */
	const onScreenChange = useCallback(
		params => {
			// setting flag to stop component rendering the layouts
			// required to prevent the component crashing in the case of screen deletion
			setScreensRefreshPending(true);
			console.log({ params });
			switch (params.mode) {
				case 'add':
					addScreen({
						screenType: params.type,
						newScreenName: params.newScreenName,
						position: params.position,
						copyScreenReference: params.copyScreenReference,
						addScreenReference: params.addScreenReference,
						setScreens,
						appOrgUuid: appOrg.uuid,
						userInfo,
						screens,
						screenGroupKey,
						setScreensRefreshPending,
						backgroundColor: params.backgroundColor,
					});
					break;
				case 'edit':
					editScreen({
						screens,
						screenGroupKey,
						setScreens,
						appOrgUuid: appOrg.uuid,
						screenUuid: params.screenUuid,
						newScreenName: params.newScreenName,
						position: params.position,
						backgroundColor: params.backgroundColor,
						setScreensRefreshPending,
					});
					break;
				case 'remove':
					removeScreen({
						screens,
						screenGroupKey,
						setScreens,
						screenUuid: params.screenUuid,
						appOrgUuid: appOrg.uuid,
						setScreensRefreshPending,
					});
					break;
				case 'adminRename':
					renameScreen({
						screen: params.screen,
						newScreenName: params.newScreenName,
						setScreensRefreshPending,
						setScreens,
					});
					break;
				default:
					break;
			}
		},
		[appOrg.uuid, screenGroupKey, screens, userInfo]
	);

	/**
	 * handle changes to tile config. eg button target, insight collection
	 */
	const onTileChange = useCallback(
		({ screenUuid, tileUpdate }) => {
			setScreensRefreshPending(true);
			updateScreenTile({
				screens,
				screenUuid,
				tileUpdate,
				setScreensRefreshPending,
				setScreens,
				currentScreen,
				adminMode,
			});
		},
		[adminMode, currentScreen, screens, setScreens]
	);

	/**
	 * handle changes to container children
	 */
	const onContainerChange = useCallback(
		({ screenUpdate }) => {
			setScreensRefreshPending(true);
			updateScreen({
				screens,
				newScreen: screenUpdate,
				setScreensRefreshPending,
				setScreens,
				currentScreen,
				adminMode,
			});
		},
		[currentScreen, screens]
	);

	const showPlaceholder = useMemo(
		() => {
			if (!loading && !adminMode)
				if (screenGroupError) {
					return screenGroupError;
				} else if (screens) {
					return screens.length === 0;
				} else {
					return true;
				}
		},
		[adminMode, loading, screenGroupError, screens]
	);

	if (adminMode && fetchScreenAdminError) {
		return (
			<BtError
				title="Retrieval Error"
				description={
					<>
						<Typography>
							An error occurred whilst trying to retrieve the
							screen.
						</Typography>
					</>
				}
				action={() => fetchScreenAdmin({ screenUuid: screenAdminUuid })}
			/>
		);
	}

	if (showPlaceholder) {
		return (
			<ScreenPlaceholder
				onScreenChange={onScreenChange}
				screenGroupError={screenGroupError}
			/>
		);
	}

	return (
		<BtLoading style={{ height: '100%' }} loading={loading}>
			{screens &&
				!screensRefreshPending && (
					<>
						{screens.map((screen, index) => (
							<BtTileLayout
								components={screen.tiles}
								screenData={screen}
								key={screen.uuid}
								hidden={index !== currentScreen}
								onLayoutChange={onLayoutChange}
								// containerDimensions={containerDimensions}
								showAddMenu={showAddMenu}
								setShowAddMenu={setShowAddMenu}
								onScreenChange={onScreenChange}
								onTileChange={onTileChange}
								onDeleteTile={onDeleteTile}
								onContainerChange={onContainerChange}
								onNativeChange={onNativeChange}
							/>
						))}
					</>
				)}
		</BtLoading>
	);
};

BtTileLayouts.propTypes = {
	admin: PropTypes.bool,
	screenGroupKey: PropTypes.oneOf(['home']),
	screenAdminUuid: PropTypes.string,
};
