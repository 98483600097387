import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Controller, useFormContext } from 'react-hook-form';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';

import DollarIcon from '@mui/icons-material/AttachMoney';
import HashIcon from '@mui/icons-material/Tag';
import AbcIcon from '@mui/icons-material/Abc';
import DataObjectIcon from '@mui/icons-material/DataObject';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import {
	useQueryBuilderStageContext,
	useQueryBuilderVariableContext,
} from '../context';

import DataType from '../../DataTypes/DataType';
import { Checkbox, TextField, Tooltip } from '@mui/material';
import BtDataConfigNodeSelect from '../../DataConfigNodeSelect/BtDataConfigNodeSelect';
import { primitiveValues } from './stages/utils';
import { BtQueryBuilderExpressionField } from './BtQueryBuilderExpressionField';

// import useFormElRequired from 'hooks/useFormElRequired';

const TypeSelectIcon = props => {
	const { type, ...rest } = props;

	switch (type) {
		case 'field-path':
			return <DollarIcon {...rest} />;
		case 'static':
			return <AbcIcon {...rest} />;
		case 'variable':
			return <HashIcon {...rest} />;
		case 'expression':
			return <DataObjectIcon {...rest} />;
	}

	return <></>;
};

const typeSelectOptions = [
	{
		key: 'static',
		title: 'Static value',
	},
	{
		key: 'field-path',
		title: 'Field Path',
	},
	{
		key: 'variable',
		title: 'Variable',
	},
	{
		key: 'expression',
		title: 'Expression',
	},
];

function DataConfigTypeValue({ type }) {
	// const _type = type.slice(0, 6) === 'static' ? type.slice(7) : type;

	return (
		<Box display="flex" flexDirection="row" alignItems="center">
			<DataType type={type} />
			<Typography
				style={{
					textTransform: 'capitalize',
					whiteSpace: 'nowrap',
					marginLeft: 6,
				}}
			>
				{type}
			</Typography>
		</Box>
	);
}

export function BtQueryBuilderPropertyField(props) {
	const {
		//typeSelected,

		//
		typeValue: _typeValue,
		// onTypeChange,

		//
		fieldValue: _fieldValue,
		// onFieldValueChange,
		//

		//
		onChange,
		//

		allowedFieldTypes: _allowedFieldTypes,
		//

		disabled,
		readOnly,
		required,
		label,
		type,
		name,
		id,
		error,
		helperText,

		asPath: _asPath,

		expressionMode,
		expressionDepth,

		...fieldInputProps
		//typeInputProps,
	} = props;

	const {
		input: { configSchema },
	} = useQueryBuilderStageContext();

	const {
		variables,
		configSchema: variableConfigSchema,
	} = useQueryBuilderVariableContext();

	const [values, setValues] = useState({
		type: _typeValue,
		field: _fieldValue,
	});

	const typeSelected = useMemo(() => (onChange ? _typeValue : values.type), [
		values.type,
		_typeValue,
		onChange,
	]);

	const fieldValue = useMemo(() => (onChange ? _fieldValue : values.field), [
		values.field,
		_fieldValue,
		onChange,
	]);

	const typeSelectedData = useMemo(
		() =>
			(typeSelected
				? typeSelectOptions.find(
						o => o.key === typeSelected.split('_')[0]
				  ) || null
				: null) || { key: null, title: 'Select a type...' },
		[typeSelected]
	);

	const allowedFieldTypes = useMemo(
		() => {
			const allowed = {
				static: true,
				variable: !!variables,
				'field-path': true,
				expression: true,
			};

			if (_allowedFieldTypes) {
				allowed.static = !allowed.static
					? false
					: _allowedFieldTypes.indexOf('static') > -1;
				allowed['field-path'] = !allowed['field-path']
					? false
					: _allowedFieldTypes.indexOf('field-path') > -1;
				allowed.variable = !allowed.variable
					? false
					: _allowedFieldTypes.indexOf('variable') > -1;
				allowed.expression = !allowed.expression
					? false
					: _allowedFieldTypes.indexOf('expression') > -1;
			}

			return Object.keys(allowed)
				.filter(k => allowed[k])
				.map(k => typeSelectOptions.find(t => t.key === k));
		},
		[variables, _allowedFieldTypes]
	);

	const valuesRef = useRef({ type: typeSelected || null, field: fieldValue });

	const handleChange = useCallback(
		(value, reason) => {
			let val = value;
			let newFieldValue = null;

			if (reason === 'type') {
				if (valuesRef.current.type === value) {
					return;
				}

				if (value === 'static') {
					// when user switches from expression/variable/field path to static input,

					// if the input is restricted to one type, default to that type
					if (type) {
						val += '_' + type;
					}
					// default to static_string input
					else {
						val += '_string';
					}
				}

				let fieldValue = valuesRef.current.field;
				fieldValue =
					valuesRef.current.type?.slice(0, 6) === 'static'
						? fieldValue
						: null;

				if (val === 'static_string') {
					newFieldValue = fieldValue === null ? '' : '' + fieldValue;
				}
				if (val === 'static_boolean') {
					newFieldValue =
						fieldValue === 'false'
							? false
							: fieldValue === '0'
								? false
								: !!fieldValue;
				}
				if (val === 'static_number') {
					newFieldValue = !isNaN(Number(fieldValue))
						? Number(fieldValue)
						: 0;
				}
				if (val === 'static_object') {
					if (fieldValue === null) {
						newFieldValue = '{}';
					} else {
						try {
							JSON.parse(fieldValue);
							// parses!
							newFieldValue = fieldValue;
						} catch (err) {
							// doesn't parse...
							newFieldValue = '{}';
						}
					}
				}
				if (val === 'static_date') {
					if (fieldValue === null) {
						newFieldValue = new Date().toISOString().split('T')[0];
					} else {
						try {
							newFieldValue = new Date(fieldValue);
							newFieldValue =
								newFieldValue.getTime() === 0
									? new Date().toISOString()
									: newFieldValue.toISOString();
						} catch (err) {
							newFieldValue = new Date()
								.toISOString()
								.split('T')[0];
						}
					}
				}

				valuesRef.current.type = val;
				valuesRef.current.field = newFieldValue;
			} else {
				valuesRef.current.field = val;
			}

			if (onChange) {
				onChange(
					{
						...valuesRef.current,
					},
					reason
				);
			} else {
				setValues({
					...valuesRef.current,
				});
			}
		},
		[onChange, type]
	);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const menuOpen = Boolean(anchorEl);
	const handleMenuOpen = useCallback(event => {
		setAnchorEl(event.currentTarget);
	}, []);
	const handleMenuClose = useCallback(() => {
		setAnchorEl(null);
	}, []);

	const handleMenuSelect = useCallback(
		key => {
			handleMenuClose();
			handleChange(key, 'type');
		},
		[handleChange, handleMenuClose]
	);

	return (
		<Box>
			{label && (
				<Box>
					<Typography variant="label">{label}</Typography>
				</Box>
			)}
			<Stack direction="row" spacing={1} alignItems="flex-start">
				{!readOnly &&
					allowedFieldTypes.length > 1 && (
						<Box flexShrink={1} alignItems="center" display="flex">
							{/* <Select
								variant="standard"
								onChange={event =>
									handleMenuSelect(event.target.value)
								}
								value={typeSelectedData.key}
								disabled={disabled || readOnly}
								label={label}
								dense
								// renderValue={() => (
								// 	<TypeSelectIcon
								// 		type={typeSelectedData.key}
								// 		fontSize="small"
								// 	/>
								// )}
							>
								{allowedFieldTypes.map(opt => (
									<MenuItem
										value={opt.key}
										key={opt.key}
									>
										<Box
											direction="row"
											display="flex"
											alignItems="center"
										>
											<TypeSelectIcon
												type={opt.key}
												fontSize="small"
												sx={{ mr: '1rem' }}
											/>
											{opt.title}
										</Box>
									</MenuItem>
								))}
							</Select> */}
							<Box
								size="small"
								disabled={disabled || readOnly}
								id={id + 'type-select'}
								aria-controls={
									menuOpen ? id + 'type-menu' : undefined
								}
								aria-haspopup="true"
								aria-expanded={menuOpen ? 'true' : undefined}
								onClick={handleMenuOpen}
								variant="outlined"
								// endIcon={
								// 	menuOpen ? (
								// 		<ArrowDropUpIcon fontSize="small" />
								// 	) : (
								// 		<ArrowDropDownIcon fontSize="small" />
								// 	)
								// }
								component={
									typeSelectedData.key !== null
										? IconButton
										: Button
								}
								color="primary.main"
							>
								{typeSelectedData.key !== null ? (
									<TypeSelectIcon
										type={typeSelectedData.key}
									/>
								) : (
									typeSelectedData.title
								)}
							</Box>
							<Menu
								id={id + 'type-menu'}
								onClose={handleMenuClose}
								anchorEl={anchorEl}
								MenuListProps={{
									'aria-labelledby': id + 'type-select',
								}}
								open={menuOpen}
								transformOrigin={{
									horizontal: 'left',
									vertical: 'top',
								}}
								anchorOrigin={{
									horizontal: 'left',
									vertical: 'bottom',
								}}
							>
								{allowedFieldTypes.map(opt => {
									return (
										<MenuItem
											key={opt.key}
											onClick={() =>
												handleMenuSelect(opt.key)
											}
											selected={
												opt.key === typeSelectedData.key
											}
										>
											<TypeSelectIcon
												type={opt.key}
												sx={{ mr: 2 }}
											/>
											{opt.title}
										</MenuItem>
									);
								})}
							</Menu>
						</Box>
					)}

				{(readOnly || allowedFieldTypes.length === 1) && (
					<Tooltip title={typeSelectedData.title}>
						<Box
							component="span"
							flexShrink={1}
							alignItems="center"
							display="flex"
							sx={{
								marginTop: '3px',
							}}
						>
							<TypeSelectIcon
								type={typeSelectedData.key}
								fontSize="small"
								sx={{ margin: '5px' }}
								color="text.disabled"
							/>
						</Box>
					</Tooltip>
				)}

				{typeSelected && (
					<Box sx={{ overflow: 'hidden', flexGrow: 1 }}>
						{typeSelected === 'field-path' ||
						typeSelected === 'variable' ? (
							<BtDataConfigNodeSelect
								key={typeSelected}
								name={name}
								disabled={readOnly || disabled}
								value={fieldValue}
								schema={
									typeSelected === 'variable'
										? variableConfigSchema
										: configSchema
								}
								onChange={handleChange}
								fullWidth
								error={!!error}
								helperText={helperText}
								{...fieldInputProps || {}}
							/>
						) : typeSelected === 'expression' ? (
							<>
								{readOnly ? (
									<Typography>{fieldValue}</Typography>
								) : (
									<BtQueryBuilderExpressionField
										// variant="standard"
										name={name}
										// type={'text'}
										// multiline
										required={required}
										disabled={disabled}
										error={!!error}
										// helperText={
										// 	helperText ||
										// 	'This should be a JSON object e.g {"$concat":["prefix-","$field"]}'
										// }
										value={fieldValue || ''}
										onChange={({
											expressionStr,
											expression,
										}) => {
											handleChange(expressionStr);
										}}
										mode={
											expressionDepth > 0
												? 'operator'
												: expressionMode
										}
										depth={expressionDepth || 0}
										{...fieldInputProps || {}}
									/>
								)}
							</>
						) : (
							<Stack
								direction="row"
								spacing={2}
								alignItems={'flex-start'}
							>
								{readOnly ? (
									<Box>
										<Typography>
											{typeSelected}: {fieldValue}
										</Typography>
									</Box>
								) : (
									<>
										{!type ? (
											<Box
												flexShrink={1}
												maxWidth="200px"
											>
												<Select
													variant="standard"
													label="Value Type"
													onChange={event =>
														handleChange(
															event.target.value,
															'type'
														)
													}
													value={typeSelected}
													disabled={disabled}
													fullWidth
													sx={{
														marginTop: '3px',
													}}
													// {...typeInputProps || {}}
												>
													<MenuItem value="static_string">
														<DataConfigTypeValue type="string" />
													</MenuItem>
													<MenuItem value="static_number">
														<DataConfigTypeValue type="number" />
													</MenuItem>
													<MenuItem value="static_boolean">
														<DataConfigTypeValue type="boolean" />
													</MenuItem>
													<MenuItem value="static_date">
														<DataConfigTypeValue type="date" />
													</MenuItem>
													<MenuItem value="static_object">
														<DataConfigTypeValue type="object" />
													</MenuItem>
													{/* <MenuItem value="static_object">
															<ConfigSchemaTypeValue type="array" />
														</MenuItem> */}
													<MenuItem value="static_null">
														<DataConfigTypeValue type="null" />
													</MenuItem>
												</Select>
											</Box>
										) : (
											<Box
												sx={{
													marginTop: '5px',
												}}
											>
												<DataConfigTypeValue
													type={type}
												/>
											</Box>
										)}
										<Box minWidth="50%" flexGrow={1}>
											{typeSelected === 'static_null' ? (
												<></>
											) : typeSelected ===
											'static_boolean' ? (
												<Checkbox
													variant="standard"
													name={name}
													disabled={
														disabled || readOnly
													}
													size="small"
													label=""
													onChange={event =>
														handleChange(
															event.target.checked
														)
													}
													checked={!!fieldValue}
													{...fieldInputProps || {}}
												/>
											) : (
												<TextField
													variant="standard"
													// label={label || ''}
													name={name}
													type={
														type ??
														(typeSelected.split(
															'_'
														)?.[1] ||
															'text')
													}
													multiline={
														type ===
															'static_object' ||
														typeSelected ===
															'static_object'
													}
													required={required}
													disabled={
														disabled || readOnly
													}
													fullWidth
													sx={{ mt: 0.5 }}
													onChange={event =>
														handleChange(
															event.target.value
														)
													}
													value={fieldValue}
													error={!!error}
													helperText={helperText}
													{...fieldInputProps || {}}
												/>
											)}
										</Box>
									</>
								)}
							</Stack>
						)}
					</Box>
				)}
			</Stack>
		</Box>
	);
}

export default function BtQueryBuilderPropertyFormField(props) {
	const {
		// allowedFieldTypes: _allowedFieldTypes,
		name,
		disabled,
		type,
		// label,
		readOnly,
		isRequired,
		...rest
	} = props;

	const {
		setValue,
		getValues,
		control,
		watch,
		getFieldState,
	} = useFormContext();

	const typeSelected = watch(name + '_type') || null;
	const fieldValue = watch(name) || null;

	const fieldState = getFieldState(name);

	const handleChange = useCallback(
		(value, field) => {
			const { type: typeValue, field: fieldValue } = value;

			const t = getValues(name + '_type');

			const f = getValues(name);

			if (field === 'type' && typeValue === t) {
				return;
			}

			if (field === 'type' && typeValue !== t) {
				setValue(name + '_type', typeValue, {
					shouldValidate: true,
					shouldDirty: true,
					shouldTouch: true,
				});
			}

			if (fieldValue !== f) {
				setValue(name, fieldValue, {
					shouldValidate: true,
					shouldDirty: true,
					shouldTouch: true,
				});
			}
		},
		[setValue, name, getValues]
	);

	return (
		<>
			<Controller
				key={name + '_type'}
				name={name + '_type'}
				control={control}
				render={({ field: typeField }) => (
					<BtQueryBuilderPropertyField
						id={typeField.id}
						name={name}
						disabled={disabled}
						readOnly={readOnly}
						required={isRequired}
						error={fieldState?.error}
						helperText={
							fieldState?.error
								? fieldState.error.message
								: rest.helperText
						}
						onChange={handleChange}
						type={type}
						typeValue={typeSelected}
						fieldValue={fieldValue}
						{...rest}
					/>
				)}
			/>
		</>
	);
}

TypeSelectIcon.propTypes = {
	type: PropTypes.oneOf(['field-path', 'static', 'variable', 'expression']),
};
BtQueryBuilderPropertyField.propTypes = {
	type: PropTypes.oneOf(primitiveValues),
	disabled: PropTypes.bool,
	fetching: PropTypes.bool,
	isRequired: PropTypes.bool,
	label: PropTypes.string,
	name: PropTypes.string,
	allowedConfigSchemaTypes: PropTypes.arrayOf(PropTypes.string),
	allowedFieldTypes: PropTypes.arrayOf(
		PropTypes.oneOf(['static', 'field-path', 'variable', 'expression'])
	),
	// allowEmptyString: PropTypes.bool,
	asPath: PropTypes.bool,
	readOnly: PropTypes.bool,
	onChange: PropTypes.func,

	expressionMode: PropTypes.oneOf(['match', 'operator', 'accumulator']),
	expressionDepth: PropTypes.number,
};

BtQueryBuilderPropertyFormField.propTypes = {
	...BtQueryBuilderPropertyField.propTypes,
};

DataConfigTypeValue.propTypes = {
	type: DataType.propTypes.type,
};
