import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import ReactJson from 'react-json-view';
import {
	Box,
	Collapse,
	IconButton,
	Tooltip,
	Typography,
	styled,
} from '@mui/material';
import { TabPanelContainer } from '../../../../../components/generic/recordManagement/components/generic/TabLayout';
import { useNavContext } from '../../../../../context/ContextManager';
import { useLocation, useParams } from 'react-router';
import {
	addDataSourcesToCollection,
	extractDataSource,
	getData,
} from './utils/utils';
import BtLoading from '../../../../../components/generic/BtLoading';
import insights_edit_options from '../InsightCollectionEditNavOptions';
import insights_breadcrumbs from '../../../InsightsBreadcrumbs';
import BtError from '../../../../../components/generic/BtError';
import { DataTransformsTable } from './DataTransformsTable';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { findIndex } from 'lodash';
import { useUpdateCollectionDraft } from './utils/useUpdateCollectionDraft';
import BtTitleHeader from '../../../../../components/generic/BtTitleHeader';
import { Edit } from '@mui/icons-material';
import { DataSourcePropertiesDialog } from './DataSourcePropertiesDialog';

const CenterItems = styled('div')(() => ({
	alignItems: 'center',
	display: 'flex',
	marginBottom: '0.5em',
}));

const PropertiesText = ({ tooltip, variant, text, children }) => {
	return (
		<CenterItems>
			<Tooltip arrow placement="left" title={tooltip}>
				<Typography variant={variant}>{text}</Typography>
			</Tooltip>
			{children}
		</CenterItems>
	);
};

const InsightCollectionDataSourceEdit = memo(
	function InsightCollectionDataSourceEdit() {
		const { setBreadcrumbs, setContextualNav } = useNavContext();
		const [dataViews, setDataViews] = useState([]);
		const { id, version, dataSourceId } = useParams();
		const location = useLocation();
		const [loading, setLoading] = useState(true);
		const [error, setError] = useState(false);
		const [insightCollection, setInsightCollection] = useState(null);
		const [showJson, setShowJson] = useState(false);
		const updateCollection = useUpdateCollectionDraft();
		const [sending, setSending] = useState(false);
		const [showNameDescModal, setShowNameDescModal] = useState(false);

		const currentDataSource = useMemo(
			() => extractDataSource(insightCollection, dataSourceId),
			[dataSourceId, insightCollection]
		);

		// Set breadcrumbs
		useEffect(
			() => {
				if (insightCollection) {
					setBreadcrumbs([
						...insights_breadcrumbs,
						{ text: 'Collections', link: '/InsightCollections' },
						{
							text: insightCollection.name,
							link: `/InsightCollection/${
								insightCollection.uuid
							}`,
						},
						{
							text: 'Data Sources',
							link: `/InsightCollection/${
								insightCollection.uuid
							}/Edit/DataSources`,
						},
						{
							text: `${currentDataSource.name}`,
							link: '',
						},
					]);
				} else {
					setBreadcrumbs([
						...insights_breadcrumbs,
						{ text: 'Collections', link: '/InsightCollections' },
					]);
				}
			},
			[setBreadcrumbs, insightCollection, currentDataSource]
		);

		// set Contextual navigation items
		useEffect(
			() => {
				//setContextualNav([...insights_nav_item, ...insights_edit_options]);
				setContextualNav([...insights_edit_options(id)]);
				return () => {
					setContextualNav(null);
				};
			},
			[setContextualNav, id]
		);

		const loadData = useCallback(
			async () => {
				const {
					collection,
					dataViewList,
					error: dataError,
					loading: dataLoading,
				} = await getData(id, location, version);

				if (!dataError && !dataLoading) {
					setInsightCollection(collection);
					setDataViews(dataViewList);
					setLoading(false);
				}
				if (dataError) {
					setLoading(false);
					setError(true);
				}
			},
			[id, location, version]
		);

		useEffect(
			() => {
				loadData();
			},
			[loadData]
		);

		const dataSources = useMemo(
			() => insightCollection?.datasources ?? [],
			[insightCollection]
		);

		const handleSourceUpdate = useCallback(
			update => {
				console.log({ update });
				const collectionUpdate = addDataSourcesToCollection(
					insightCollection,
					update
				);
				setInsightCollection(collectionUpdate);
				loadData();
			},
			[insightCollection, loadData]
		);

		const handleJsonUpdate = useCallback(
			jsonUpdate => {
				const index = findIndex(dataSources, {
					uuid: currentDataSource.uuid,
				});

				let dataSourcesUpdate = [...dataSources];
				dataSourcesUpdate[index] = jsonUpdate.updated_src;

				const callbacks = {
					success: update => {
						handleSourceUpdate(update);
						setSending(false);
					},
					error: () => setSending(false),
				};

				updateCollection({
					update: { datasources: dataSourcesUpdate },
					callbacks,
				});
			},
			[
				currentDataSource,
				dataSources,
				handleSourceUpdate,
				updateCollection,
			]
		);

		if (error) {
			return (
				<BtError
					title="Retrieval Error"
					description="An error occurred when attempting to retrieve the data"
				/>
			);
		}

		return (
			<BtLoading
				style={{ width: '100%', height: '100%' }}
				showLogo
				fullScreen
				loading={loading}
			>
				<TabPanelContainer maxWidth="md">
					<BtTitleHeader>
						<PropertiesText
							tooltip="Data source name"
							variant="h3"
							text={
								currentDataSource?.name
									? // ? `Data Source: ${currentDataSource?.name}`
									  currentDataSource?.name
									: 'Loading...'
							}
						>
							<Tooltip
								className="editNameDescription"
								disableInteractive
								style={{ marginLeft: '0.5em' }}
								title="Edit Name, Description and Data Set"
							>
								<IconButton
									onClick={() => setShowNameDescModal(true)}
									onMouseUp={event =>
										event.currentTarget.blur()
									}
								>
									<Edit />
								</IconButton>
							</Tooltip>
						</PropertiesText>
						{currentDataSource?.description && (
							<PropertiesText
								tooltip="Description"
								variant="body"
								text={currentDataSource?.description}
							/>
						)}
						{currentDataSource?.dataView && (
							<PropertiesText
								tooltip="Data View"
								variant="body"
								text={
									dataViews.find(
										({ uuid }) =>
											uuid === currentDataSource?.dataView
									)?.name
								}
							/>
						)}
					</BtTitleHeader>
					<DataTransformsTable
						loading={loading}
						dataSource={currentDataSource}
						dataSources={dataSources}
						handleSourceUpdate={handleSourceUpdate}
						disabled={sending}
					/>
					<Box
						sx={{
							display: 'flex',
							gap: '10px',
							marginTop: '1em',
							alignItems: 'center',
						}}
					>
						<Typography variant="h4">JSON editor </Typography>
						<Tooltip title={showJson ? 'Hide JSON' : 'Show JSON'}>
							<IconButton onClick={() => setShowJson(t => !t)}>
								<ExpandMoreIcon
									sx={{
										transform: showJson
											? 'rotate( -180deg )'
											: '',
										transition: 'transform 200ms ease',
									}}
									fontSize="large"
								/>
							</IconButton>
						</Tooltip>
					</Box>
					<Collapse in={showJson}>
						<ReactJson
							src={currentDataSource}
							onEdit={handleJsonUpdate}
							onAdd={handleJsonUpdate}
							onDelete={handleJsonUpdate}
							collapsed={1}
							quotesOnKeys={false}
						/>
					</Collapse>
					<DataSourcePropertiesDialog
						dataSource={currentDataSource}
						dataSources={dataSources}
						dataViews={dataViews}
						handleSourceUpdate={handleSourceUpdate}
						loading={loading}
						onClose={() => setShowNameDescModal(false)}
						open={showNameDescModal}
					/>
				</TabPanelContainer>
			</BtLoading>
		);
	}
);

PropertiesText.propTypes = {
	tooltip: PropTypes.string,
	variant: PropTypes.string,
	text: PropTypes.string,
	children: PropTypes.element,
};

export { InsightCollectionDataSourceEdit };
