const { shallowCopy, deepCopy, isEqual } = require('../utils');

const MAX_INFER_ARRAY_ITEMS = 10;

// =============================================================================
// module.exports.getArrayShape = getArrayShape;
/**
 * @returns {Object}
 */
// function getArrayShape(schema) {
// 	return schema?.arrayContent?.objectContent;
// }

// =============================================================================
module.exports.setArrayType = setArrayType;
/**
 * @returns {Object}
 */
function setArrayType(schema, typeSchema) {
	schema.arrayContent = typeSchema;
	return schema;
}

// =============================================================================
module.exports.getArrayOf = getArrayOf;
/**
 * @returns {Object}
 */
function getArrayOf(schema) {
	return schema?.arrayContent;
}

// =============================================================================
module.exports.getObjectShape = getObjectShape;
/**
 * @returns {Object}
 */
function getObjectShape(schema) {
	return schema?.objectContent;
}

// =============================================================================
module.exports.setObjectShape = setObjectShape;
/**
 * @returns {Object}
 */
function setObjectShape(schema, shape) {
	schema.objectContent = shape;
	return getObjectShape(schema);
}

// =============================================================================
module.exports.getType = getType;
/**
 * @returns {string}
 */
function getType(schema) {
	return schema?.type;
}

// =============================================================================
module.exports.newDefaultSchema = newDefaultSchema;
/**
 * @returns {Object}
 */
function newDefaultSchema(type, options) {
	let baseObj = { type: type };

	if (type === 'array') {
		if (options?.of) {
			baseObj = {
				...baseObj,
				arrayContent: newDefaultSchema(options.of),
			};
		}
	}

	if (type === 'object') {
		baseObj = { ...baseObj, objectContent: options?.shape || {} };
	}

	return baseObj;
}

// =============================================================================
module.exports.isSchemaEqual = isSchemaEqual;
function isSchemaEqual(a, b) {
	if (a === b) {
		return true;
	}

	const aType = getType(a);
	const bType = getType(b);

	if (aType !== bType) {
		return;
	}

	if ((a.required || false) !== (b.required || false)) {
		return;
	}

	if (!isEqual(a.default || null, b.default || null)) {
		return;
	}

	if ((a.min || null) !== (b.min || null)) {
		return;
	}
	if ((a.max || null) !== (b.max || null)) {
		return;
	}
	if ((a.length || null) !== (b.length || null)) {
		return;
	}
	if ((a.moreThan || null) !== (b.moreThan || null)) {
		return;
	}
	if ((a.lessThan || null) !== (b.lessThan || null)) {
		return;
	}
	if ((a.positive || false) !== (b.positive || false)) {
		return;
	}
	if ((a.negative || false) !== (b.negative || false)) {
		return;
	}
	if ((a.integer || false) !== (b.integer || false)) {
		return;
	}
	if ((a.uuid || false) !== (b.uuid || false)) {
		return;
	}
	if ((a.url || false) !== (b.url || false)) {
		return;
	}
	if ((a.email || false) !== (b.email || false)) {
		return;
	}
	if ((a.lowercase || false) !== (b.lowercase || false)) {
		return;
	}
	if ((a.uppercase || false) !== (b.uppercase || false)) {
		return;
	}
	if ((a.nullable || false) !== (b.nullable || false)) {
		return;
	}

	if (aType === 'array') {
		if (a.tupleContent && b.tupleContent) {
			if (a.tupleContent.length !== b.tupleContent.length) {
				return false;
			}

			for (let index = 0; index < a.tupleContent.length; index++) {
				if (
					!isSchemaEqual(a.tupleContent[index], b.tupleContent[index])
				) {
					return;
				}
			}

			return true;
		}

		if (a.tupleContent || b.tupleContent) {
			const tuple = a.tupleContent || b.tupleContent;
			const arr =
				(a.tupleContent ? b.arrayContent : a.arrayContent) ||
				newDefaultSchema('object');

			for (let index = 0; index < tuple.length; index++) {
				if (!isSchemaEqual(tuple[index], arr)) {
					return;
				}
			}

			return true;
		}

		return isSchemaEqual(
			a.arrayContent || newDefaultSchema('object'),
			b.arrayContent || newDefaultSchema('object')
		);
	}

	if (aType === 'object') {
		// technically the sort order matters for mongo, but not for JS / JSON
		const aProps = Object.keys(a.objectContent || {}).sort();
		const bProps = Object.keys(b.objectContent || {}).sort();

		if (aProps.length !== bProps.length) {
			return false;
		}

		for (let index = 0; index < aProps.length; index++) {
			const prop = aProps[index];
			if (prop !== bProps[index]) {
				return false;
			}

			if (!isSchemaEqual(a.objectContent[prop], b.objectContent[prop])) {
				return false;
			}
		}

		return true;
	}

	return true;
}

// =============================================================================
module.exports.inferSchema = inferSchema;
function inferSchema(thing) {
	if (thing === null || thing === undefined) {
		return newDefaultSchema('object');
	} else if (typeof thing === 'string') {
		return newDefaultSchema('string');
	} else if (typeof thing === 'number') {
		return newDefaultSchema('number');
	} else if (typeof thing === 'boolean') {
		return newDefaultSchema('boolean');
	} else if (typeof thing === 'object') {
		if (thing instanceof Date) {
			return newDefaultSchema('date');
		} else if (Array.isArray(thing)) {
			const newObj = newDefaultSchema('array');
			if (thing.length > 0) {
				const firstItemSchema = inferSchema(thing[0]);
				setArrayType(newObj, firstItemSchema);

				const tupleContent = [firstItemSchema];

				for (
					let index = 1;
					index < thing.length && index < MAX_INFER_ARRAY_ITEMS;
					index++
				) {
					const nextItemType = inferSchema(thing[index]);

					tupleContent.push(nextItemType);
				}

				if (
					isSchemaEqual(
						{ type: 'array', tupleContent: tupleContent },
						{ type: 'array', arrayContent: firstItemSchema }
					)
				) {
					return newObj;
				}

				newObj.tupleContent = tupleContent;

				return newObj;
			} else {
				setArrayType(newObj, newDefaultSchema('object'));
			}

			return newObj;
		} else {
			const shape = {};

			for (const field in thing) {
				if (Object.hasOwnProperty.call(thing, field)) {
					shape[field] = inferSchema(thing[field]);
				}
			}

			return newDefaultSchema('object', { shape: shape });
		}
	}
}

// =============================================================================
module.exports.dotGetInSchema = dotGetInSchema;
function dotGetInSchema(schema, location, arraysTraversed = 0) {
	const schemaType = getType(schema);

	const dotIndex = location.indexOf('.');

	const p0 = dotIndex > -1 ? location.slice(0, dotIndex) : location;
	const pN = dotIndex > -1 ? location.slice(dotIndex + 1) : undefined;

	if (schemaType === 'array') {
		const arrayOf = getArrayOf(schema);

		if (arrayOf) {
			const itemType = getType(arrayOf);
			if (itemType === 'object') {
				const shape = getObjectShape(arrayOf);

				if (dotIndex < 0) {
					if (arraysTraversed > 0) {
						// we're currently traversing a second array
						const newArr = newDefaultSchema('array', {
							of: 'object',
						});
						const of = getArrayOf(newArr);
						setObjectShape(of, shape[location]);

						return newArr;
					}
					return shape[location];
				}

				return dotGetInSchema(shape[p0], pN, arraysTraversed + 1);
			}
		}
	} else if (schemaType === 'object') {
		const shape = getObjectShape(schema);
		if (dotIndex < 0) {
			if (arraysTraversed > 1) {
				return newDefaultSchema('object', { shape: shape[location] });
			}
			return shape[location];
		}

		return dotGetInSchema(shape[p0], pN, arraysTraversed);
	}
}

// =============================================================================
module.exports.dotSetInSchema = dotSetInSchema;
function dotSetInSchema(
	schema,
	insertSchema,
	location,
	setOptions = {},
	level = 0
) {
	const dotIndex = location.indexOf('.');

	const p0 = dotIndex > -1 ? location.slice(0, dotIndex) : location;
	const pN = dotIndex > -1 ? location.slice(dotIndex + 1) : undefined;

	let sourceSchema = schema;

	if (!schema && setOptions?.createStructure) {
		sourceSchema = newDefaultSchema('object');
	}

	let schemaType = getType(sourceSchema);

	// insert replace existing
	if (
		setOptions?.overwrite &&
		schemaType !== 'object' &&
		schemaType !== 'array'
	) {
		sourceSchema = newDefaultSchema('object', { shape: {} });
		schemaType = 'object';
	}

	if (p0 === '$') {
		let pnDotIndex = pN.indexOf('.');
		// let pN0 = pN.slice(0, pnDotIndex);
		let pNN = pN.slice(pnDotIndex + 1);
		if (pnDotIndex < 0) {
			if (schemaType === 'array') {
				let arrayOf = getArrayOf(sourceSchema);
				setArrayType(arrayOf, insertSchema);
				return sourceSchema;
			}

			sourceSchema = newDefaultSchema('array');
			setArrayType(sourceSchema, insertSchema);

			return sourceSchema;
		}

		if (schemaType === 'array') {
			let arrayOf = getArrayOf(sourceSchema);
			setArrayType(
				sourceSchema,
				dotSetInSchema(arrayOf, inferSchema, pNN, setOptions, level)
			);
			return sourceSchema;
		}

		const newArr = newDefaultSchema('array');
		setArrayType(newArr, insertSchema);
		return newArr;
	}

	if (schemaType === 'array') {
		let arrayOf = getArrayOf(sourceSchema);

		if (setOptions?.createStructure && !arrayOf) {
			arrayOf = newDefaultSchema('object');
			sourceSchema = newDefaultSchema('array', { of: 'object' });
			setArrayType(arrayOf);
		}

		if (arrayOf) {
			const itemType = getType(arrayOf);

			if (itemType === 'object') {
				const shape = getObjectShape(arrayOf);

				let newShape = null;
				if (dotIndex < 0) {
					newShape = {
						...shape,
						[location]: insertSchema,
					};
				} else {
					newShape = {
						...shape,
						[p0]: dotSetInSchema(
							shape[p0],
							insertSchema,
							pN,
							setOptions,
							level + 1
						),
					};
				}

				setObjectShape(arrayOf, newShape);
				return sourceSchema;
			}
		}
	} else if (schemaType === 'object') {
		const shape = getObjectShape(sourceSchema);
		let newShape = null;
		if (dotIndex < 0) {
			newShape = {
				...shape,
				[location]: insertSchema,
			};
		} else {
			newShape = {
				...shape,
				[p0]: dotSetInSchema(
					shape[p0],
					insertSchema,
					pN,
					setOptions,
					level + 1
				),
			};
		}

		setObjectShape(sourceSchema, newShape);

		return sourceSchema;
	}

	if (dotIndex < 0) {
		// overwrite whatever we have with an object as its a fixed type
		return newDefaultSchema('object', {
			shape: { [location]: insertSchema },
		});
	}

	// overwrite whatever we have with an object as its a flat type and continue
	return newDefaultSchema('object', {
		shape: {
			[p0]: dotSetInSchema(undefined, insertSchema, pN, setOptions),
		},
	});
}

// =============================================================================
module.exports.dotRemoveFromSchema = dotRemoveFromSchema;
function dotRemoveFromSchema(schema, location, level = 0) {
	const dotIndex = location.indexOf('.');

	const p0 = dotIndex > -1 ? location.slice(0, dotIndex) : location;
	const pN = dotIndex > -1 ? location.slice(dotIndex + 1) : undefined;

	const schemaType = getType(schema);

	if (schemaType === 'array') {
		// our schema defines an array of something
		const arrayOf = getArrayOf(schema);

		if (arrayOf) {
			const itemType = getType(arrayOf);

			// we have an array of objects, so we can safely exclude the
			// property we want to exclude
			if (itemType === 'object') {
				const shape = getObjectShape(arrayOf);

				let newShape = null;

				if (shape) {
					if (dotIndex < 0) {
						// we're on the last part, exclude the property and
						// return the new schema object with the altered shape
						const { [location]: _exclude, ...rest } = shape;
						newShape = rest;
					} else if (p0 && pN) {
						// we're not on the last part, recurse into the prop
						// of this that we want to change
						const { [p0]: nextSchema, ...rest } = shape;
						newShape = {
							...rest,
							[p0]: dotRemoveFromSchema(
								nextSchema,
								pN,
								level + 1
							),
						};
					}

					if (newShape) {
						setObjectShape(arrayOf, newShape);
						return schema;
					}
				}
			}

			// we have an array with a type that does not have properties
			// so we will get back an empty array of nulls types

			setArrayType(newDefaultSchema('object'));

			return schema;
		}
	} else if (schemaType === 'object') {
		const shape = getObjectShape(schema);

		let newShape = null;

		if (shape) {
			if (dotIndex < 0) {
				// we're at the last part, return the new shape with the
				// excluded prop
				const { [location]: _exclude, ...rest } = shape;

				newShape = rest;
			} else if (p0 && pN) {
				// we're not at the last part, descend into the proper and return
				// the altered shape of the prop
				const { [p0]: nextSchema, ...rest } = shape;

				newShape = {
					...rest,
					[p0]: dotRemoveFromSchema(nextSchema, pN, level + 1),
				};
			}

			if (newShape) {
				// put the new shape into the object
				setObjectShape(schema, newShape);

				return schema;
			}
		}
	}

	// couldn't exclude the prop for some reason, returned the unaltered schema
	return shallowCopy(schema);
}

// =============================================================================
module.exports.dotCopyToSchema = dotCopyToSchema;
function dotCopyToSchema(
	schema,
	sourceSchema,
	location,
	ifNull = null,
	level = 0
) {
	const dotIndex = location.indexOf('.');

	const p0 = dotIndex > -1 ? location.slice(0, dotIndex) : location;
	const pN = dotIndex > -1 ? location.slice(dotIndex + 1) : undefined;

	const srcType = getType(sourceSchema || {});
	let targetType = getType(schema || {});

	const clearProps = !schema || targetType !== srcType;
	const newSchema = !clearProps
		? schema
		: newDefaultSchema(srcType || 'object');

	targetType = getType(newSchema);

	if (srcType === 'object') {
		const srcShape = getObjectShape(sourceSchema) || {};

		let newShape = null;

		if (clearProps || targetType === undefined) {
			newShape = {};
		} else {
			newShape = getObjectShape(newSchema);
		}

		if (dotIndex < 0) {
			if (srcShape[p0]) {
				newShape[p0] = deepCopy(srcShape[p0]);
			} else if (ifNull) {
				// default to null
				newShape[p0] = ifNull;
			}
		} else if (p0 && pN) {
			const resultSchema = dotCopyToSchema(
				newShape[p0] || null,
				srcShape[p0],
				pN,
				ifNull,
				level + 1
			);

			if (resultSchema) {
				newShape[p0] = resultSchema;
			} else if (ifNull) {
				// default to null
				newShape[p0] = ifNull;
			}
		}

		// put the new shape into the schema
		setObjectShape(newSchema, newShape);

		return newSchema;
	} else if (srcType === 'array') {
		// our schema defines an array of something
		const srcArrayOf =
			getArrayOf(sourceSchema) || newDefaultSchema('object');

		const srcItemType = getType(srcArrayOf);

		let targetArrayOf = getArrayOf(newSchema);

		if (getType(targetArrayOf || {}) !== srcItemType) {
			targetArrayOf = newDefaultSchema(srcItemType);
		}

		// we have an array of objects, so we can safely exclude the
		// property we want to exclude
		if (srcItemType === 'object') {
			const srcShape = getObjectShape(srcArrayOf) || {};
			const newShape = getObjectShape(targetArrayOf);

			if (dotIndex < 0) {
				if (srcShape[location]) {
					newShape[location] = deepCopy(srcShape[location]);
				}
			} else if (p0 && pN) {
				const resultSchema = dotCopyToSchema(
					srcShape[p0],
					newShape[p0] || {},
					pN,
					ifNull,
					level + 1
				);

				if (resultSchema) {
					newShape[p0] = resultSchema;
				}
			}

			// put the new shape into the schema
			setObjectShape(targetArrayOf, newShape);
			setArrayType(newSchema, targetArrayOf);

			return newSchema;
		}
	}

	// return undefined - no definition for the prop exists in the sourceSchema
}

// =============================================================================
module.exports.flattenSchema = flattenSchema;
function flattenSchema(schema, currentKey = '', accumulator = {}) {
	const type = getType(schema);

	if (type === 'array') {
		const arrayOf = getArrayOf(schema);

		const aType = getType(arrayOf);

		if (aType === 'object') {
			const shape = getObjectShape(arrayOf);

			for (const prop in shape) {
				if (Object.hasOwnProperty.call(shape, prop)) {
					accumulator[(currentKey ? currentKey + '.' : '') + prop] =
						shape[prop];

					flattenSchema(
						shape[prop],
						(currentKey ? currentKey + '.' : '') + prop,
						accumulator
					);
				}
			}
		} else {
			// if (aType === KEYS.ARRAY) {
			//   mongo allows one dot to traverse into an array and refer to its
			//   elements, but not multiple. so there's no way to path down into
			//   the >1 array via a path. so return as we cant create or use this
			//   this path.
			accumulator[currentKey] = schema;
			return accumulator;
		}
	} else if (type === 'object') {
		const shape = getObjectShape(schema);

		for (const prop in shape) {
			if (Object.hasOwnProperty.call(shape, prop)) {
				if (!shape[prop]?.dynamic) {
					accumulator[(currentKey ? currentKey + '.' : '') + prop] =
						shape[prop];

					flattenSchema(
						shape[prop],
						(currentKey ? currentKey + '.' : '') + prop,
						accumulator
					);
				}
			}
		}
	}

	return accumulator;
}
