import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useNavContext } from '../../context/ContextManager';
import { useAppContext } from '../../context/ContextManager';

// STYLES
import makeStyles from '@mui/styles/makeStyles';

// COMPONENTS
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

// ICONS
import AddCircleIcon from '@mui/icons-material/AddCircle';

// CUSTOM COMPONENTS
import {
	SelectionTable,
	SelectionTableContainer,
	SelectionTableHead,
	SelectionTableHeadRow,
	SelectionTableBody,
	SelectionTableRow,
	SelectionTableCell,
} from '../../components/generic/BtSelectionTable';

// Data for landing page selection table and contextual navigation
import IoT_options from './IoTOptions';
import IoT_breadcrumbs from './IoTBreadcrumbs';
import IoT_nav_item from './IoTNavItem';

const styles = makeStyles(theme => ({
	button_row: {},
	root: {
		width: '100%',
		minWidth: 200,
	},
	config_paper: {
		padding: 10,
	},
	tableHeader: {
		fontWeight: 700,
	},
	tableRow: {
		cursor: 'pointer',
		borderBottom: '1px solid red',
		borderBottomColor: theme.palette.primary.shade_dark,
		'&:hover': {
			backgroundColor: theme.palette.primary.shade_light,
		},
	},
}));

export default function FilesView() {
	const classes = styles();
	const [page] = useState(1);
	const [fileList, setFileList] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const history = useHistory();
	const { setBreadcrumbs } = useNavContext();
	const { setContextualNav } = useNavContext();
	const { appOrg } = useAppContext();

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([...IoT_nav_item, ...IoT_options]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([...IoT_breadcrumbs, { text: 'Files', link: '' }]);
		},
		[setBreadcrumbs]
	);

	const handleClickAddFile = () => {
		history.push('/AddFirmware');
	};

	const onFileClick = resource_uuid => () => {
		history.push('/Files/' + resource_uuid);
	};

	useEffect(
		() => {
			var req_url = process.env.REACT_APP_API_SERVER_URL + `/v1/file`;

			Auth.currentSession().then(user_session => {
				let user_token = user_session.getAccessToken();
				fetch(req_url, {
					method: 'GET',
					headers: {
						Authorization: user_token.jwtToken,
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Organisation: appOrg.uuid,
					},
				})
					.then(res => res.json())
					.then(response => {
						setFileList(response.file_list);
						setIsLoading(false);
					})
					.catch(error => {
						console.log(error);
						setIsLoading(false);
					});
			});
		},
		[page]
	);

	return (
		<>
			<Container className={classes.button_row}>
				<Button
					variant="outlined"
					startIcon={<AddCircleIcon />}
					onClick={handleClickAddFile}
				>
					Add New File
				</Button>
			</Container>
			{isLoading && (
				<Paper>
					<p>Loading...</p>
					<CircularProgress size={'120px'} />
				</Paper>
			)}

			{!isLoading && (
				<Container maxWidth="lg">
					<SelectionTableContainer component={Box}>
						<SelectionTable aria-label="a dense table">
							<SelectionTableHead>
								<SelectionTableHeadRow>
									<SelectionTableCell>
										File Name
									</SelectionTableCell>
									<SelectionTableCell>
										Version
									</SelectionTableCell>
									<SelectionTableCell>
										Description
									</SelectionTableCell>
									<SelectionTableCell>
										Last Updated
									</SelectionTableCell>
									<SelectionTableCell>
										File Type
									</SelectionTableCell>
								</SelectionTableHeadRow>
							</SelectionTableHead>
							<SelectionTableBody>
								{fileList.map((c, index) => (
									<SelectionTableRow
										key={index}
										onClick={onFileClick(c.resource_uuid)}
										title={c.filename}
									>
										<SelectionTableCell
											component="th"
											scope="row"
										>
											{c.filename}
										</SelectionTableCell>
										<SelectionTableCell>
											{c.last_version_str}
										</SelectionTableCell>
										<SelectionTableCell>
											{c.description}
										</SelectionTableCell>
										<SelectionTableCell>
											{new Date(
												c.last_timestamp
											).toLocaleString()}
										</SelectionTableCell>
										<SelectionTableCell>
											{c.file_type}
										</SelectionTableCell>
									</SelectionTableRow>
								))}
							</SelectionTableBody>
						</SelectionTable>
					</SelectionTableContainer>
				</Container>
			)}
		</>
	);
}
