import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

export default function ImageGalleryComponent({ data, visOptions }) {
	console.log({ data, visOptions });
	return (
		<Box style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
			<Typography variant="h6" style={{ margin: 'auto' }}>
				Image Gallery component awaiting development!
			</Typography>
		</Box>
	);
}

ImageGalleryComponent.propTypes = {
	// data,
	visOptions: PropTypes.object.isRequired,
};
