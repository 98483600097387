import React, { memo } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Tab, Tabs, Typography, tabsClasses } from '@mui/material';

const StyledTabs = styled(Tabs)(({ theme }) => ({
	backgroundColor: 'transparent',
	position: 'relative',
	// transform: 'translateY(2px)',

	[`& .${tabsClasses.scrollButtons}`]: {
		height: '54px',
		opacity: 1,
		// position: 'fixed',
		color: theme => `${theme.palette.secondary.text} !important`,
		width: 48,

		'&.Mui-disabled': { display: 'none' },

		// '&:first-of-type': {
		// 	background: `linear-gradient(90deg, ${
		// 		theme.palette.background.default
		// 	}ff 0%, ${theme.palette.background.default}dd 80%, ${
		// 		theme.palette.background.default
		// 	}00 100%)`,
		// 	// left: 0,
		// 	zIndex: 1,
		// },

		// '&:last-of-type': {
		// 	background: `linear-gradient(270deg, ${
		// 		theme.palette.background.default
		// 	}ff 0%, ${theme.palette.background.default}dd 80%, ${
		// 		theme.palette.background.default
		// 	}00 100%)`,
		// 	// right: 0,
		// },
	},
}));

const StyledTab = styled(Tab)(({ theme }) => ({
	height: '54px',
	textTransform: 'none',
	// minWidth: 100,
	maxWidth: '200px',
	fontWeight: theme.typography.fontWeightRegular,
	fontSize: '1.5rem',
	// marginBottom: '2px',
	padding: '13px 12px 11px 12px',
	borderTopLeftRadius: '4px',
	borderTopRightRadius: '4px',
	// borderBottom: `2px solid ${
	// 	theme.palette.mode === 'dark' ? '#ffffff3a' : '#0000001d'
	// }`,
	cursor: 'pointer',
	'&:hover': {
		backgroundColor: theme.palette.action.hover,
		opacity: 1,
	},

	'&$selected': {
		fontWeight: theme.typography.fontWeightMedium,
	},

	'&:focus': {
		color: theme.palette.primary.shade_light,
	},
	minHeight: 0,
	// transform: 'translateY(2px)',
	boxSizing: 'border-box',
}));

const DraggableTab = memo(function DraggableTab(props) {
	if (props.draggable) {
		return (
			<Draggable
				draggableId={`${props.index}`}
				index={props.index}
				disableInteractiveElementBlocking
			>
				{provided => (
					<div
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						ref={provided.innerRef}
					>
						{React.cloneElement(props.child, {
							...props,
							...provided.dragHandleProps,
							style: { cursor: 'inherit' },
						})}
					</div>
				)}
			</Draggable>
		);
	} else {
		return (
			<div>
				{React.cloneElement(props.child, {
					...props,
					style: { cursor: 'pointer' },
				})}
			</div>
		);
	}
});

const TabList = memo(function TabList({
	draggable,
	droppableProvided,
	handleChange,
	value,
	tabs,
}) {
	return (
		<StyledTabs
			onChange={handleChange}
			aria-label="Draggable Tabs"
			variant="scrollable"
			// variant="fullWidth"
			value={value}
			// scrollButtons={false}
			scrollButtons="auto"
			// visibleScrollbar
		>
			{tabs.map((tab, index) => {
				const child = (
					<StyledTab
						iconPosition="start"
						icon={tab.icon}
						// label={tab.label}
						label={
							<Typography noWrap maxWidth="100%">
								{tab.label}
							</Typography>
						}
						value={tab.value}
						key={index}
					/>
				);

				return (
					<DraggableTab
						// label={tab.label}
						label={
							<Typography noWrap maxWidth="100%">
								{tab.label}
							</Typography>
						}
						value={tab.value}
						index={index}
						icon={tab.icon}
						iconPosition="start"
						key={index}
						child={child}
						draggable={draggable}
					/>
				);
			})}
			{droppableProvided ? droppableProvided.placeholder : null}
			{/* </Tabs> */}
		</StyledTabs>
	);
});

const DraggableTabs = memo(function DraggableTabs({
	onDragEnd,
	draggable,
	handleChange,
	value,
	tabs,
}) {
	return (
		<DragDropContext onDragEnd={onDragEnd}>
			{/* <div style={{ display: 'flex', overflow: 'auto' }}> */}
			<Droppable droppableId="Tabs" direction="horizontal">
				{provided => (
					<div
						ref={provided.innerRef}
						{...provided.droppableProps}
						{...provided.dragHandleProps}
					>
						<TabList
							draggable={draggable}
							droppableProvided={provided}
							handleChange={handleChange}
							value={value}
							tabs={tabs}
						/>
						{/* {_renderTabList(provided)} */}
					</div>
				)}
			</Droppable>
			{/* </div> */}
		</DragDropContext>
	);
});

const tabShape = PropTypes.shape({
	content: PropTypes.element.isRequired,
	label: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	icon: PropTypes.element,
});

DraggableTab.propTypes = {
	child: PropTypes.element,
	draggable: PropTypes.bool,
	fullWidth: PropTypes.bool,
	icon: PropTypes.element,
	iconPosition: PropTypes.oneOf(['start', 'top', 'end', 'bottom']),
	index: PropTypes.number,
	indicator: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	onChange: PropTypes.func,
	selected: PropTypes.bool,
	selectionFollowsFocus: PropTypes.bool,
	textColor: PropTypes.oneOf(['inherit', 'primary', 'secondary']),
	value: PropTypes.string,
};

TabList.propTypes = {
	draggable: PropTypes.bool,
	droppableProvided: PropTypes.shape({
		droppableProps: PropTypes.object,
		innerRef: PropTypes.func,
		placeholder: PropTypes.object,
	}),
	handleChange: PropTypes.func.isRequired,
	tabs: PropTypes.arrayOf(tabShape),
	value: PropTypes.string.isRequired,
};

DraggableTabs.propTypes = {
	draggable: PropTypes.bool,
	handleChange: PropTypes.func.isRequired,
	onDragEnd: PropTypes.func.isRequired,
	tabs: PropTypes.arrayOf(tabShape),
	value: PropTypes.string.isRequired,
};

DraggableTab.displayName = 'DraggableTab';
TabList.displayName = 'TabList';
DraggableTabs.displayName = 'DraggableTabs';

export { DraggableTabs };
