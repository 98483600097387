import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	Button,
	DialogContent,
	DialogTitle,
	FormHelperText,
} from '@mui/material';
import BtDialog from '../../generic/BtDialog';
import BtFilterSelect from '../../generic/filterSelect/BtFilterSelect';
import { PaletteRenderItem } from './PaletteRenderItem';
import ActionButtonContainer from '../../generic/ActionButtonContainer';
import BtMessage from '../../generic/BtMessage';
import { unMapSelectedOption } from '../utils/functions';

export default function SelectPaletteDialog({
	onClose,
	open,
	onSelect,
	message,
	multiple,
	options,
	title,
	verb,
	nullSelectedMessage,
}) {
	const [selectedPalette, setSelectedPalette] = useState('initial');

	// console.log({ selectedPalette });

	// The error flag, set to true if nothing is selected
	const error = useMemo(() => selectedPalette === null, [selectedPalette]);

	// Process the filterSelect output according to if we receive an array or object,
	// if the select is multiple we will receive an array and will need to strip
	// 'selected = false' entries from the local state to ensure the nothing selected error
	// gets shown
	const handleSelection = useCallback(filterSelectPayload => {
		if (Array.isArray(filterSelectPayload)) {
			const filteredPayload = filterSelectPayload.filter(
				({ selected }) => selected
			);
			setSelectedPalette(
				filteredPayload.length > 0 ? filteredPayload : null
			);
		} else {
			setSelectedPalette(filterSelectPayload);
		}
	}, []);

	// Transform the payload back to it's original form,
	// supporting different structures for flat multiple or single select
	const handleResult = useCallback(
		selectResult => {
			if (multiple) {
				return selectResult
					.filter(r => r.selected)
					.map(p => unMapSelectedOption(p));
			} else {
				return unMapSelectedOption(selectResult);
			}
		},
		[multiple]
	);

	// emit the onSelect at onClick
	// the onSelect payload is transformed prior to dispatch
	const handleSelect = useCallback(
		() => {
			onClose();
			const result = handleResult(selectedPalette);
			onSelect(result);
		},
		[handleResult, onClose, onSelect, selectedPalette]
	);

	return (
		<BtDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
			<DialogTitle>{title}</DialogTitle>
			{message && (
				<DialogContent>
					<BtMessage variant="warning" message={message} />
				</DialogContent>
			)}
			<BtFilterSelect
				onChange={handleSelection}
				disableFilter
				options={options}
				RenderItem={PaletteRenderItem}
				dense
				multiple={multiple}
			/>

			{error && (
				<Box sx={{ margin: '0px 24px' }}>
					<FormHelperText error>{nullSelectedMessage}</FormHelperText>
				</Box>
			)}

			<ActionButtonContainer>
				<Button onClick={onClose}>cancel</Button>
				<Button
					onClick={handleSelect}
					disabled={error}
					variant="contained"
					disableElevation
				>
					{verb}
				</Button>
			</ActionButtonContainer>
		</BtDialog>
	);
}

SelectPaletteDialog.defaultProps = {
	nullSelectedMessage: 'A selection is required',
};

SelectPaletteDialog.propTypes = {
	onSelect: PropTypes.func.isRequired,
	message: PropTypes.string,
	multiple: PropTypes.bool,
	options: PropTypes.arrayOf(PropTypes.object),
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	title: PropTypes.string,
	verb: PropTypes.string,
	nullSelectedMessage: PropTypes.string,
};

SelectPaletteDialog.displayName = 'SelectPaletteDialog';
