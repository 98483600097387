import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from '@mui/material';
import { styled } from '@mui/styles';
import { useMemo } from 'react';
import ActionButtonContainer from '../generic/ActionButtonContainer';

const SubscriptionsList = styled('ul')(() => ({
	margin: '0 0 1em 0',
	paddingLeft: '1em',
}));

const EXPIRED = 'expired';
const MISSING = 'missing';

const SubscriptionErrors = ({ subscriptions, variant }) => {
	const title = useMemo(
		() => {
			if (variant === EXPIRED) {
				return `The following subscription${
					subscriptions.length > 1 ? 's have' : ' has'
				} expired:`;
			}

			return `You are missing the following subscription${
				subscriptions.length > 1 ? 's' : ''
			}:`;
		},
		[subscriptions, variant]
	);

	return (
		<>
			<Typography>{title}</Typography>
			<SubscriptionsList>
				{subscriptions.map(subscription => (
					<li key={subscription}>{subscription}</li>
				))}
			</SubscriptionsList>
		</>
	);
};

const PermissionErrors = ({ permissions, anyOf }) => {
	const title = useMemo(
		() => {
			if (anyOf) {
				return `You are missing one of the following permissions${
					permissions.length > 1 ? 's' : ''
				}:`;
			}
			return `You are missing the following permissions${
				permissions.length > 1 ? 's' : ''
			}:`;
		},
		[anyOf, permissions.length]
	);

	return (
		<>
			<Typography>{title}</Typography>
			{Object.keys(permissions).map(permGroup => (
				<div key={permGroup}>
					<Typography style={{ textTransform: 'capitalize' }}>
						{permGroup}
					</Typography>
					<SubscriptionsList>
						{permissions[permGroup].map(permission => (
							<li key={permission}>{permission}</li>
						))}
					</SubscriptionsList>
				</div>
			))}
		</>
	);
};

export default function PermsSubsErrorDetailDialog({
	error,
	onClose,
	descriptionOnly,
}) {
	const Description = () => {
		if (error?.missingSubscriptions || error?.expiredSubscriptions) {
			return (
				<div>
					<Typography style={{ marginBottom: '2em' }}>
						You cannot view this content as there are one or more
						issues with your subscriptions, as detailed below.
					</Typography>
					{error.missingSubscriptions && (
						<SubscriptionErrors
							subscriptions={error.missingSubscriptions}
							variant={MISSING}
						/>
					)}
					{error.expiredSubscriptions && (
						<SubscriptionErrors
							subscriptions={error.expiredSubscriptions}
							variant={EXPIRED}
						/>
					)}
				</div>
			);
		}

		if (error?.missingPermissionsAll) {
			return (
				<div>
					<Typography style={{ marginBottom: '2em' }}>
						You cannot view this content as there are one or more
						issues with your permissions, as detailed below.
					</Typography>
					{error.missingPermissionsAll && (
						<PermissionErrors
							permissions={error.missingPermissionsAll}
						/>
					)}
				</div>
			);
		}
		if (error?.missingPermissionsAny) {
			return (
				<div>
					<Typography style={{ marginBottom: '2em' }}>
						You cannot view this content as there are one or more
						issues with your permissions, as detailed below.
					</Typography>
					{error.missingPermissionsAny && (
						<PermissionErrors
							anyOf
							permissions={error.missingPermissionsAll}
						/>
					)}
				</div>
			);
		}
		if (error?.serverError) {
			return (
				<div>
					<Typography style={{ marginBottom: '2em' }}>
						We're not sure what happened but something went wrong
						whilst retrieving the required information.
						{/* {JSON.stringify(error, null, 2)} */}
					</Typography>
				</div>
			);
		}
		return null;
	};

	const title = useMemo(
		() => {
			if (error?.missingSubscriptions && error?.expiredSubscriptions) {
				return 'Missing and expired subscriptions';
			} else if (error?.missingSubscriptions) {
				return 'Missing subscriptions';
			} else if (error?.expiredSubscriptions) {
				return 'Expired subscriptions';
			} else if (
				error?.missingPermissionsAny ||
				error?.missingPermissionsAll
			) {
				return 'Missing permissions';
			} else if (error?.serverError) {
				return 'Server error';
			} else return '';
		},
		[error]
	);

	if (descriptionOnly) {
		return <Description />;
	}

	return (
		<>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<Description />
			</DialogContent>
			<DialogActions>
				<ActionButtonContainer>
					<Button onClick={onClose} variant="outlined">
						Close
					</Button>
				</ActionButtonContainer>
			</DialogActions>
		</>
	);
}
