import React, { Children, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

import HomeIcon from '@mui/icons-material/Home';
import SubscriptionsIcon from '@mui/icons-material/EventRepeat';

import BtError from './BtError';
import useAvailableToUser from '../../hooks/useAvailableToUser';

const EXPIRED = 'expired';
const MISSING = 'missing';

const SubscriptionsList = styled('ul')(() => ({
	margin: '0 0 1em 0',
	paddingLeft: '1em',
}));

const SubscriptionErrors = ({ subscriptions, variant }) => {
	const title = useMemo(
		() => {
			if (variant === EXPIRED) {
				return `The following subscription${
					subscriptions.length > 1 ? 's have' : ' has'
				} expired:`;
			}

			return `You are missing the following subscription${
				subscriptions.length > 1 ? 's' : ''
			}:`;
		},
		[subscriptions, variant]
	);

	return (
		<>
			<Typography>{title}</Typography>
			<SubscriptionsList>
				{subscriptions.map(subscription => (
					<li key={subscription}>{subscription}</li>
				))}
			</SubscriptionsList>
		</>
	);
};

export default function BtAuthRoute({
	component: Component,
	path,
	render,
	requiredPermissionsAll,
	requiredPermissionsAny,
	validSubscriptionsAny,
	children,
	...rest
}) {
	const history = useHistory();
	const checkAvailability = useAvailableToUser();

	const availability = useMemo(
		() =>
			checkAvailability({
				requiredPermissionsAll,
				requiredPermissionsAny,
				validSubscriptionsAny,
			}),
		[
			checkAvailability,
			requiredPermissionsAll,
			requiredPermissionsAny,
			validSubscriptionsAny,
		]
	);

	const hasChildren = useMemo(() => Children.toArray(children).length > 0, [
		children,
	]);

	const errorAttributes = useMemo(
		() => {
			const {
				available,
				expiredSubscriptions,
				missingPermissionsAll,
				missingPermissionsAny,
				missingSubscriptions,
			} = availability;
			if (available) return null;

			if (missingPermissionsAll || missingPermissionsAny) {
				return {
					variant: 'forbidden',
					action: () => history.push('/'),
					actionIcon: <HomeIcon />,
					actionLabel: 'Go To Home',
				};
			}

			return {
				title: 'Subscription Error',
				description: (
					<div>
						<Typography style={{ marginBottom: '2em' }}>
							You cannot view this content as there are one or
							more issues with your subscriptions, as detailed
							below.
						</Typography>
						{missingSubscriptions && (
							<SubscriptionErrors
								subscriptions={missingSubscriptions}
								variant={MISSING}
							/>
						)}
						{expiredSubscriptions && (
							<SubscriptionErrors
								subscriptions={expiredSubscriptions}
								variant={EXPIRED}
							/>
						)}
					</div>
				),
				variant: 'notfound',
				action: () => history.push('/Subscriptions'),
				actionIcon: <SubscriptionsIcon />,
				actionLabel: 'Review Subscriptions',
			};
		},
		[availability, history]
	);

	const determineRender = useCallback(
		props => {
			if (!Component && !render && !hasChildren) {
				return null;
			}

			if (availability.available) {
				if (hasChildren) {
					return children;
				} else if (Component) {
					return <Component {...props} />;
				}
				return render;
			}

			return (
				<BtError
					title={errorAttributes?.title}
					description={errorAttributes?.description}
					variant={errorAttributes.variant}
					action={errorAttributes.action}
					actionIcon={errorAttributes.actionIcon}
					actionLabel={errorAttributes.actionLabel}
				/>
			);
		},
		[
			availability,
			children,
			Component,
			errorAttributes,
			hasChildren,
			render,
		]
	);

	return <Route path={path} render={determineRender} {...rest} />;
}

SubscriptionErrors.propTypes = {
	subscriptions: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
	variant: PropTypes.oneOf([EXPIRED, MISSING]).isRequired,
};

BtAuthRoute.propTypes = {
	component: PropTypes.elementType,
	path: PropTypes.string.isRequired,
	render: PropTypes.node,
	requiredPermissionsAll: PropTypes.object,
	requiredPermissionsAny: PropTypes.object,
	validSubscriptionsAny: PropTypes.arrayOf(PropTypes.string),
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};
