import React, { useState } from 'react';
import BtPopper from '../BtPopper';
import { BtPopperExtendedTitle } from '../BtPopperContent';

export default function BtUserHelp({ children, content, subtitle, placement }) {
	const [open, setOpen] = useState(true);
	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const onClose = () => {
		return null;
	};

	return (
		<BtPopper
			clickAway={false}
			placement={placement}
			// arrow
			open={open}
			onClose={onClose}
			content={
				typeof content === 'string' ? (
					<BtPopperExtendedTitle title={content} />
				) : (
					content
				)
			}
			variant="extended"
		>
			{children}
			{/* {React.cloneElement(children, {
				...children.props,
				// ref: setChildNode,
			})} */}
		</BtPopper>
	);
}
