import {
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	Typography,
	FormControl,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
	insightCollectionComponentListGet,
	insightCollectionsGetList,
} from '../../../../../../API';
import useFetch from '../../../../../../hooks/useFetch';
import BtLoading from '../../../../BtLoading';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import SelectItem from '../../formComponents/SelectItem';
import { useTileConfigContext } from '../TileConfigDialogContext';
import { inputStyle } from './inputStyle';

export default function InsightConfig() {
	const {
		tileUpdateParams,
		setTileUpdateParams,
		existingTileData,
		setIsFormValid,
		error,
		setError,
	} = useTileConfigContext();
	const [collectionOptions, setCollectionOptions] = useState([]);
	const [insightOptions, setInsightOptions] = useState([]);
	const [isFormReady, setIsFormReady] = useState(false);

	// get a list of available collections
	const {
		data: insightCollections,
		error: insightCollectionsError,
		loading: insightCollectionsLoading,
		request: insightCollectionsRequest,
	} = useFetch(insightCollectionsGetList);

	useEffect(
		() => {
			insightCollectionsRequest();
		},
		[insightCollectionsRequest]
	);

	// get a list of available components
	const {
		data: insightComponentList,
		error: insightComponentListError,
		loading: insightComponentListLoading,
		request: insightComponentListRequest,
	} = useFetch(insightCollectionComponentListGet);

	useEffect(
		() => {
			if (insightCollectionsError) {
				if (error) {
					const errorClone = structuredClone(error);
					errorClone[
						insightCollectionsError
					] = insightCollectionsError;
					setError(errorClone);
				} else {
					setError(insightCollectionsError);
				}
			} else if (insightComponentListError) {
				if (error) {
					const errorClone = structuredClone(error);
					errorClone[
						insightComponentListError
					] = insightComponentListError;
					setError(errorClone);
				} else {
					setError(insightComponentListError);
				}
			}
		},
		[insightComponentListError, error, setError, insightCollectionsError]
	);

	// once the collections are loaded -
	// - prep the select data
	// - initialize the select value
	// - carry out component list get request
	useEffect(
		() => {
			if (insightCollections) {
				const collections = insightCollections.map(template => ({
					value: template.uuid,
					label: template.name,
					description: template.description,
				}));
				//save these to state for later use
				setCollectionOptions(collections);

				setValue(
					'collection',
					existingTileData.data.insight_collection
				);

				if (existingTileData.data.insight_collection !== '') {
					insightComponentListRequest({
						insightUuid: existingTileData.data.insight_collection,
					});
				}

				setIsFormReady(true);
			}
		},
		[insightCollections]
	);

	//validation schema
	const schema = yup
		.object({
			collection: yup
				.string()
				.required()
				// .oneOf([buttonTypes.map(type => type.value)])
				.label('Collection'),
			insight: yup
				.string()
				.required()
				// .oneOf([buttonTypes.map(type => type.value)])
				.label('Insight'),
		})
		.required();

	//react hook form
	const {
		watch,
		control,
		setValue,
		formState: { isDirty, errors, isValid },
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: { collection: '', insight: '' },
		mode: 'onChange',
	});

	// watch the collection value for changes and -
	// - update the tile update data
	// - reset the insight value
	// - make new request for available insight components
	const collectionValue = watch('collection');
	useEffect(
		() => {
			if (isFormReady && isDirty) {
				const tileUpdate = structuredClone(tileUpdateParams);
				tileUpdate.data.insight_collection = collectionValue;
				tileUpdate.title = collectionOptions.find(
					collection => collection.value === collectionValue
				).label;
				setTileUpdateParams(tileUpdate);

				setValue('insight', '', {
					// shouldValidate: true,
					// shouldDirty: true,
					// shouldTouch: true,
				});

				insightComponentListRequest({
					insightUuid: collectionValue,
				});
			}
		},
		[collectionValue]
	);

	// watch the insight value and update tile update data accordingly
	const insightValue = watch('insight');
	useEffect(
		() => {
			if (isFormReady && isDirty && insightValue !== '') {
				const tileUpdate = structuredClone(tileUpdateParams);
				tileUpdate.data.insight_component = insightValue;
				tileUpdate.data.subtitle = insightOptions.find(
					option => option.value === insightValue
				).description;
				setTileUpdateParams(tileUpdate);
			}
		},
		[insightValue]
	);

	// as the component list changes prep the component select data
	// if the form is not dirty and there is an existing component value we set the select value accordingly
	useEffect(
		() => {
			if (insightComponentList) {
				const insights = insightComponentList.map(insight => ({
					value: insight.uuid,
					label: insight.title,
					subtitle: insight.subtitle,
					type: insight.type,
				}));
				//save these to state for later use
				setInsightOptions(insights);

				if (
					!isDirty &&
					existingTileData.data.insight_component !== ''
				) {
					setValue(
						'insight',
						existingTileData.data.insight_component
					);
				}
			}
		},
		[insightComponentListLoading]
	);

	// validating the values
	useEffect(
		() => {
			if (isValid && isDirty) {
				if (
					existingTileData.data.insight_collection ===
						collectionValue &&
					existingTileData.data.insight_visualization ===
						insightValue &&
					existingTileData.title ===
						collectionOptions.find(
							collection => collection.uuid === collectionValue
						).label
					// existingTileData.data.button_type ===
					// 	collectionOptions.find(
					// 		collection => collection.uuid === collectionValue
					// 	).description
				) {
					setIsFormValid(false);
				} else {
					setIsFormValid(isValid && isDirty);
				}
			} else {
				setIsFormValid(isValid && isDirty);
			}
		},
		[
			collectionOptions,
			collectionValue,
			existingTileData.data.insight_collection,
			existingTileData.data.insight_visualization,
			existingTileData.title,
			insightValue,
			isDirty,
			isValid,
			setIsFormValid,
		]
	);

	return (
		<BtLoading loading={insightCollectionsLoading || !insightCollections}>
			{isFormReady && (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						// paddingLeft: 10,
						// paddingRight: 10,
					}}
				>
					<Controller
						name="collection"
						// defaultValue={level}
						control={control}
						render={({ field, fieldState: { error } }) => (
							<FormControl
								variant="standard"
								// sx={{ m: 1, minWidth: 120 }}
								style={inputStyle}
								error={!!error}
								// disabled={buttonTypeValue === ''}
							>
								<InputLabel id="collection-label">
									Select insight collection
								</InputLabel>
								<Select {...field}>
									{collectionOptions.map(type => (
										<MenuItem
											key={type.value}
											value={type.value}
										>
											<SelectItem
												label={type.label}
												description={type.description}
											/>
										</MenuItem>
									))}
								</Select>
								{error && (
									<FormHelperText>
										{error.message}
									</FormHelperText>
								)}
							</FormControl>
						)}
					/>

					<Controller
						name="insight"
						// defaultValue={level}
						control={control}
						render={({ field, fieldState: { error } }) => (
							<FormControl
								variant="standard"
								// sx={{ m: 1, minWidth: 120 }}
								style={inputStyle}
								error={!!error}
								disabled={collectionValue === ''}
							>
								<InputLabel id="insight-label">
									Select insight
								</InputLabel>
								<Select {...field}>
									{insightOptions.map(type => (
										<MenuItem
											key={type.value}
											value={type.value}
										>
											<SelectItem
												label={type.label}
												description={type.subtitle}
												additionalText={type.type}
											/>
										</MenuItem>
									))}
								</Select>
								{error && (
									<FormHelperText>
										{error.message}
									</FormHelperText>
								)}
							</FormControl>
						)}
					/>
				</div>
			)}
		</BtLoading>
	);
}
