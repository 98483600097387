import {
	Box,
	Divider,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	Switch,
	Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppContext, useNavContext } from '../../context/ContextManager';
import { useThemeContext } from '../../context/ThemeContext';
import FormContainer from './FormContainer';
import { user_settings_nav_item } from './UserSettingsNavItem';
import user_settings_options from './UserSettingsOptions';
import BtIconRadio from '../../components/generic/BtIconRadio';
import { AlphaACircleOutline, Circle, CircleOutline } from 'mdi-material-ui';

export default function PlatformSettings() {
	const {
		setThemeDark,
		themeMode,
		setThemeMode,
		prefersDarkMode,
	} = useThemeContext();
	const [themeValue, setThemeValue] = useState(themeMode);
	const { setBreadcrumbs, setContextualNav } = useNavContext();

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'User Settings', link: '/UserSettings' },
				{ text: 'Platform Settings', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([
				...user_settings_nav_item,
				...user_settings_options,
			]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	const { forceDesktopMode, setForceDesktopMode } = useAppContext();
	const [forceDesktopChecked, setForceDesktopChecked] = useState(
		forceDesktopMode
	);

	const handleThemeChange = event => {
		setThemeValue(event.target.value);
		console.log(event.target.value);

		switch (event.target.value) {
			case 'light':
				localStorage.setItem('preferred-theme', 'light');
				setThemeMode('light');
				setThemeDark(false);
				break;
			case 'dark':
				localStorage.setItem('preferred-theme', 'dark');
				setThemeMode('dark');
				setThemeDark(true);
				break;
			case 'auto':
				localStorage.setItem('preferred-theme', 'auto');
				setThemeMode('auto');
				setThemeDark(prefersDarkMode ? true : false);
				break;
			default:
				break;
		}
	};

	const handleForceDesktopModeChange = event => {
		setForceDesktopChecked(event.target.checked);
		if (forceDesktopMode) {
			localStorage.setItem('desktopMode', 'auto');
			setForceDesktopMode(false);
		} else {
			localStorage.setItem('desktopMode', 'forced');
			setForceDesktopMode(true);
		}
	};

	const Item = ({
		title,
		value,
		onChange,
		label,
		helperText,
		control,
		iconRadioItems,
	}) => {
		return (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<Typography>{title}</Typography>
				<Divider />
				<div style={{ display: 'flex' }}>
					<Box sx={{ flexGrow: 0 }}>
						{control === 'switch' ? (
							<FormGroup>
								<FormControlLabel
									control={
										<Switch
											checked={value}
											onChange={onChange}
											color="primary"
											inputProps={{
												'aria-label': 'controlled',
											}}
										/>
									}
									label={label}
								/>
								<FormHelperText>
									{helperText || ' '}
								</FormHelperText>
							</FormGroup>
						) : (
							<BtIconRadio
								value={value}
								onChange={onChange}
								items={iconRadioItems}
								name={label}
							/>
						)}
					</Box>
				</div>
			</div>
		);
	};

	const settings = [
		{
			title: 'Appearance',
			value: themeValue,
			onChange: handleThemeChange,
			label: 'Dark / Light',
			control: 'radio',
			iconRadioItems: [
				{
					value: 'light',
					label: 'Light',
					icon: <CircleOutline />,
					disabled: false,
				},
				{
					value: 'dark',
					label: 'Dark',
					icon: <Circle />,
					disabled: false,
				},
				{
					value: 'auto',
					label: 'Auto',
					icon: <AlphaACircleOutline />,
					disabled: false,
				},
			],
			// helperText
		},
		{
			title: 'Device Detection',
			value: forceDesktopChecked,
			onChange: handleForceDesktopModeChange,
			label: 'Auto Detect / Force Desktop Mode',
			helperText:
				'Useful if you want to view the desktop style app on a tablet device, not recommended for use on mobile phones.',
			control: 'switch',
		},
	];

	return (
		<FormContainer text="Platform Settings">
			<div style={{ display: 'flex', flexDirection: 'column', gap: 30 }}>
				{settings.map(setting => (
					<Item key={setting.label} {...setting} />
				))}
			</div>
		</FormContainer>
	);
}
