import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const CommentsContext = createContext();

export const useCommentsContext = () => {
	const context = useContext(CommentsContext);

	if (context === undefined) {
		throw new Error('useCommentsContext was used outside of its Provider');
	}

	return context;
};

export const CommentsContextProvider = ({ children, ...props }) => (
	<CommentsContext.Provider
		value={{
			...props,
		}}
	>
		{children}
	</CommentsContext.Provider>
);

CommentsContextProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};
