import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Controller, useFormContext } from 'react-hook-form';
import { Skeleton } from '@mui/material';

import BtDateTimePicker from '../BtDateTimePicker';
import useFormElRequired from '../../../hooks/useFormElRequired';

export default function BtFormDateTimePicker({
	disabled,
	fieldProps,
	isRequired,
	label,
	name,
	variant,
	...other
}) {
	const {
		control,
		formState: { errors },
		loading,
		sending,
		setValue,
		trigger,
		validationSchema,
		watch,
	} = useFormContext() || { formState: {} };

	const value = watch(name) || '';

	const handleChange = useCallback(
		newValue => {
			if (errors?.[name]) {
				trigger(name);
			}

			setValue(name, newValue, {
				shouldTouch: true,
				shouldDirty: true,
			});
		},
		[errors, name, setValue, trigger]
	);

	const required = useFormElRequired(isRequired, validationSchema, name);

	if (loading) {
		return (
			<Skeleton animation="wave" style={{ width: '100%', height: 45 }} />
		);
	}

	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<BtDateTimePicker
					label={
						!!label
							? `${label}${!required ? ' (optional)' : ''}`
							: undefined
					}
					{...field}
					onChange={handleChange}
					value={value}
					variant={variant}
					fieldProps={{
						disabled: loading || sending || disabled,
						error: !!errors[name],
						fullWidth: true,
						helperText: errors[name]?.message,
						...fieldProps,
					}}
					{...other}
				/>
			)}
		/>
	);
}

BtFormDateTimePicker.propTypes = {
	disabled: PropTypes.bool,
	fieldProps: PropTypes.object,
	isRequired: PropTypes.bool,
	label: PropTypes.string,
	name: PropTypes.string,
};
