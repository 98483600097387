import { attachAffixesToValue, resolveAffixes } from './affixes';

const decimalPlacesStringToNumber = decimalPlaces => {
	switch (decimalPlaces) {
		case 'none':
			return 0;
		case '0.0':
			return 1;
		case '0.00':
			return 2;
		case '0.000':
			return 3;
		case '0.0000':
			return 4;
		default:
			return 0;
	}
};

// TODO: we should be able to remove this function, it was used to catch missing props
const resolveDecimalPlaces = (value, decimalPlaces) => {
	try {
		if (Math.sign(decimalPlaces) === -1) {
			return value.toFixed(0);
		}
		return value.toFixed(decimalPlaces || 0);
	} catch (error) {
		return null;
	}
};

const resolvePercentageString = (value, visOptions) => {
	const result = resolveDecimalPlaces(
		value,
		visOptions?.label?.percentage_decimal_places || 0
	);
	return `${result}%`;
};

// TODO: remove this function and replace with resolveValueStringExplicit?
const resolveValueString = (value, index, visOptions) => {
	const valueStr = resolveDecimalPlaces(
		value,
		visOptions?.label?.absolute_decimal_places || 0
	);
	const affixedValueStr = resolveAffixes(valueStr, visOptions, index);
	return affixedValueStr;
};

const resolveValueStringExplicit = (value, prefix, suffix, decimalPlaces) => {
	// console.log({ value, prefix, suffix, decimalPlaces });
	const valueStr = resolveDecimalPlaces(
		value,
		decimalPlacesStringToNumber(decimalPlaces) || 0
	);
	const affixedValueStr = attachAffixesToValue(valueStr, suffix, prefix);
	return affixedValueStr;
};

export {
	resolvePercentageString,
	resolveValueString,
	resolveValueStringExplicit,
	decimalPlacesStringToNumber,
};
