import React, { useEffect, useState } from 'react';
import { useNavContext } from '../../../context/ContextManager';
import _ from 'lodash';
import { format } from 'date-fns';

// MUI COMPONENTS
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

// MUI Icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/AddCircle';

// CUSTOM COMPONENTS
import BtLoading from '../../../components/generic/BtLoading';
import BtSelectionTable from '../../../components/generic/BtSelectionTable';
import BtFormContainer from '../../../components/generic/forms/BtFormContainer';

import NewApiKeyDialog from './NewApiDialog';

import useFetch from '../../../hooks/useFetch';
import { apiManagerGetList } from '../../../API';

// Data for landing page selection table and contextual navigation
import data_manager_options from '../DataManagerOptions';

import data_manager_breadcrumbs from '../DataManagerBreadcrumbs';

import data_manager_nav_item from '../DataManagerNavItem';

const tableColumns = [
	{ field: 'name', text: 'Role' },
	{ field: 'description', text: 'Description', minBreakpoint: 'lg' },
	{ field: 'modify_timestamp', text: 'Modified', minBreakpoint: 'lg' },
	{ field: 'expire_timestamp', text: 'Expires', minBreakpoint: 'md' },
	{ field: 'permissionsCount', text: 'Permissions', minBreakpoint: 'md' },
	{ field: 'enabled', text: 'Enabled' },
];

export default function ApiManager() {
	const [newApiKeyDialogOpen, setNewApiKeyDialogOpen] = useState(false);
	const [editApi, setEditApi] = useState({});
	const { setBreadcrumbs } = useNavContext();
	const { setContextualNav } = useNavContext();

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				...data_manager_breadcrumbs,
				{ text: 'API Manager', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([
				...data_manager_nav_item,
				...data_manager_options,
			]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	const {
		data: apiKeys,
		loading,
		error,
		request: apiManagerRequest,
	} = useFetch(apiManagerGetList);

	useEffect(() => {
		apiManagerRequest();
	}, []);

	return (
		<>
			<BtFormContainer title="API Keys" maxWidth="lg">
				<BtLoading loading={loading}>
					{apiKeys && (
						<BtSelectionTable
							title={
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										flexWrap: 'wrap',
										gap: 10,
									}}
								>
									<Typography
										sx={{
											fontWeight: 'bold',
											padding: '4px 0',
										}}
										variant="h5"
									>
										Your organisation's API keys
									</Typography>
									<Button
										variant="contained"
										onClick={() =>
											setNewApiKeyDialogOpen(true)
										}
										startIcon={<AddIcon />}
										disableElevation
									>
										Add New API Key
									</Button>
								</div>
							}
							subject="api_keys"
							columns={tableColumns}
							data={(apiKeys || []).map(
								({
									uuid,
									name,
									description,
									modify_timestamp,
									expire_timestamp,
									permissionsCount,
									enabled,
								}) => ({
									name: name,
									description: description,
									modify_timestamp: format(
										new Date(modify_timestamp || 0),
										'dd MMM yyyy'
									),
									expire_timestamp:
										expire_timestamp === 0
											? '-'
											: format(
													new Date(
														expire_timestamp || 0
													),
													'dd MMM yyyy'
											  ),
									permissionsCount: permissionsCount,
									enabled: enabled ? (
										<CheckCircleIcon color="success" />
									) : (
										<CancelIcon color="warning" />
									),
									route: `/ApiManager/Edit/${uuid}`,
								})
							)}
						/>
					)}
				</BtLoading>
			</BtFormContainer>
			<NewApiKeyDialog
				existingNames={apiKeys?.map(({ name }) => name)}
				open={newApiKeyDialogOpen}
				onClose={() => setNewApiKeyDialogOpen(false)}
			/>
		</>
	);
}
