import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
	CellphoneCheck,
	CellphoneCog,
	Close,
	Pencil,
	PencilOff,
	Plus,
	SquareEditOutline,
} from 'mdi-material-ui';
import { ScreensContext } from '../../ScreensContext';
import { ControlContainer, TileConfigButton } from './ButtonComponents';
import { AddTileControls } from './AddTileControls';

export const EditControls = ({
	handleClickEdit,
	showAddMenu,
	handleClickAdd,
	onDragStart,
	onNativeChange,
	setShowAddScreenDialog,
	setShowRenameScreenDialog,
}) => {
	const {
		adminMode,
		editEnabled,
		isEditingDisabled,
		nativeEditMode,
		setNativeEditMode,
		userPermissions,
	} = useContext(ScreensContext);

	const handleNativeLayout = useCallback(
		() => {
			setNativeEditMode(previousState => !previousState);
		},
		[setNativeEditMode]
	);

	const EnableEditButton = ({ style }) => {
		return (
			<TileConfigButton
				onClick={handleClickEdit}
				title={editEnabled ? 'Finish editing' : 'Edit screen'}
				icon={editEnabled ? <PencilOff /> : <Pencil />}
				styled
				style={style}
			/>
		);
	};

	const DefaultEditControls = () => {
		return (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						marginBottom: editEnabled && !isEditingDisabled ? 5 : 0,
					}}
				>
					{editEnabled &&
						!isEditingDisabled && (
							<>
								<TileConfigButton
									onClick={handleNativeLayout}
									style={{ marginRight: 5 }}
									title={
										nativeEditMode
											? 'Close App view'
											: 'Edit mobile App layout'
									}
									icon={
										nativeEditMode ? (
											<CellphoneCheck />
										) : (
											<CellphoneCog />
										)
									}
									styled
								/>
								<TileConfigButton
									onClick={handleClickAdd}
									disabled={isEditingDisabled}
									// style={{ marginRight: 5 }}
									title={
										showAddMenu
											? 'Close tile tray'
											: 'Add tiles'
									}
									icon={showAddMenu ? <Close /> : <Plus />}
									styled
									// style={style}
								/>
							</>
						)}
				</div>

				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					{editEnabled &&
						(adminMode ? (
							<TileConfigButton
								title="Rename screen"
								onClick={() => setShowRenameScreenDialog(true)}
								icon={<SquareEditOutline />}
								styled
								style={{ marginRight: 5 }}
							/>
						) : (
							<TileConfigButton
								onClick={() => setShowAddScreenDialog(true)}
								title="Configure screens"
								icon={<SquareEditOutline />}
								styled
								style={{ marginRight: 5 }}
							/>
						))}

					<EnableEditButton />
				</div>
			</div>
		);
	};

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'flex-end',
				paddingBottom: 10,
			}}
		>
			<ControlContainer
				style={{
					// width: editEnabled ? 100 : 'auto'
					width: 'auto',
					display: 'flex',
					alignItems: 'flex-end',
				}}
			>
				{showAddMenu &&
					// editEnabled &&
					!isEditingDisabled && (
						<AddTileControls
							onNativeChange={onNativeChange}
							onDragStart={onDragStart}
						/>
					)}
				<DefaultEditControls />
			</ControlContainer>
		</div>
	);
};

EditControls.propTypes = {
	handleClickEdit: PropTypes.func.isRequired,
	showAddMenu: PropTypes.bool,
	handleClickAdd: PropTypes.func.isRequired,
	onDragStart: PropTypes.func.isRequired,
	onNativeChange: PropTypes.func.isRequired,
	setShowAddScreenDialog: PropTypes.func.isRequired,
	setShowRenameScreenDialog: PropTypes.func.isRequired,
};
