import { useEffect } from 'react';
import BtSelectionTable from '../../components/generic/BtSelectionTable';
import { useAuthContext } from '../../context/AuthContext';
import { useNavContext } from '../../context/ContextManager';
import FormContainer from './FormContainer';
import { user_settings_nav_item } from './UserSettingsNavItem';
import user_settings_options from './UserSettingsOptions';

const tableColumns = [
	{ field: 'name', text: 'Name' },
	{ field: 'status', text: 'Status' },
];

export default function UserSubscriptions() {
	const { userSubscriptions } = useAuthContext();
	const { setBreadcrumbs, setContextualNav } = useNavContext();

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'User Settings', link: '/UserSettings' },
				{ text: 'Subscriptions', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([
				...user_settings_nav_item,
				...user_settings_options,
			]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	return (
		<FormContainer text="Your Subscriptions">
			<BtSelectionTable
				// title="Your Subscriptions"
				subject="Subscriptions"
				columns={tableColumns}
				data={userSubscriptions?.map(({ name, status }) => ({
					name,
					status,
				}))}
			/>
		</FormContainer>
	);
}
