import React, { useEffect, useMemo } from 'react';
import { Typography, Link } from '@mui/material';
import { format } from 'date-fns';

import admin_nav_item from '../AdminNavItem';
import admin_options from '../AdminOptions';
import BtError from '../../../components/generic/BtError';
import { BtFormContainer } from '../../../components/generic/forms';
import BtInfoChip from '../../../components/generic/BtInfoChip';
import BtLoading from '../../../components/generic/BtLoading';
import BtMessage from '../../../components/generic/BtMessage';
import BtProgressBar from '../../../components/generic/BtProgressBar';
import BtSelectionTable from '../../../components/generic/BtSelectionTable';
import { subscriptionsGetList } from '../../../API/subscriptions.api';
import BtTableSkeleton from '../../../components/generic/BtTableSkeleton';
import useFetch from '../../../hooks/useFetch';
import { useNavContext } from '../../../context/ContextManager';

const tableColumns = [
	{ field: 'subscription', text: 'Subscription' },
	{ field: 'description', text: 'Description', minBreakpoint: 'lg' },
	// { field: 'renewalCost', text: 'Renewal Cost', minBreakpoint: 'md' },
	{ field: 'renewalDate', text: 'Renewal Date', minBreakpoint: 'md' },
	{ field: 'assignment', text: 'Assignment' },
];

export default function Subscriptions() {
	const {
		error,
		loading,
		data: subscriptionData,
		request: getSubscriptions,
	} = useFetch(subscriptionsGetList);

	// Set contextual navigation items and breadcrumbs
	const { setContextualNav, setBreadcrumbs } = useNavContext();
	useEffect(
		() => {
			setContextualNav([...admin_nav_item, ...admin_options]);
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'Admin', link: '/Admin' },
				{ text: 'Subscriptions', link: '' },
			]);
			return () => setContextualNav(null);
		},
		[setContextualNav, setBreadcrumbs]
	);

	useEffect(
		() => {
			if (!subscriptionData) {
				getSubscriptions();
			}
		},
		[getSubscriptions, subscriptionData]
	);

	const subscriptions = useMemo(
		() =>
			(subscriptionData || []).map(
				({
					uuid,
					subscription,
					description,
					renewalCost,
					renewalDate,
					assigned,
					maxUsers,
					type,
				}) => ({
					subscription,
					description,
					renewalCost,
					renewalDate: format(
						new Date(renewalDate || 0),
						'dd MMM yyyy'
					),
					assignment:
						type === 'user' ? (
							<BtProgressBar value={assigned} goal={maxUsers} />
						) : (
							<BtInfoChip text="Everyone" showIcon={false} />
						),
					route: type === 'user' ? `/Subscriptions/${uuid}` : null,
				})
			),
		[subscriptionData]
	);

	if (error) {
		return (
			<BtError
				variant="error"
				title="Retrieval Error"
				description="An error occurred when attempting to retrieve the subscriptions."
			/>
		);
	}

	return (
		<BtFormContainer title="Subscriptions" maxWidth="lg">
			<BtLoading loading={loading} LoadingComponent={<BtTableSkeleton />}>
				<BtSelectionTable
					title="Your organisation's subscriptions"
					subject="subscriptions"
					columns={tableColumns}
					data={subscriptions || []}
				/>
			</BtLoading>
			{!loading && (
				<BtMessage
					style={{ marginTop: '3em' }}
					message={
						<Typography>
							Beacon Technology subscriptions automatically renew
							at the renewal date until they are cancelled. There
							is a 1 month notice period required for your
							cancellation. Contact{' '}
							<Link
								sx={{
									cursor: 'pointer',
									color: theme =>
										theme.palette.secondary.text,
								}}
							>
								Beacon Technology Support
							</Link>{' '}
							if you want to change your subscriptions.
						</Typography>
					}
				/>
			)}
		</BtFormContainer>
	);
}
