import { useMemo } from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';

import { XS, SM, MD, LG, XL } from '../style/muiTheme';

export default function useBreakpointStatuses() {
	const theme = useTheme();
	const smStatus = !useMediaQuery(theme.breakpoints.down(SM));
	const mdStatus = !useMediaQuery(theme.breakpoints.down(MD));
	const lgStatus = !useMediaQuery(theme.breakpoints.down(LG));
	const xlStatus = !useMediaQuery(theme.breakpoints.down(XL));

	return useMemo(
		() => ({
			[XS]: true,
			[SM]: smStatus,
			[MD]: mdStatus,
			[LG]: lgStatus,
			[XL]: xlStatus,
		}),
		[smStatus, mdStatus, lgStatus, xlStatus]
	);
}
