import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	IconButton,
	Tooltip,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import produce from 'immer';
import { useSnackbar } from 'notistack';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import BtConfirmDialog from '../../../components/generic/BtConfirmDialog';
import BtDialog from '../../../components/generic/BtDialog';
import BtMessage from '../../../components/generic/BtMessage';
import BtSelectionTable from '../../../components/generic/BtSelectionTable';
import PermissionWizard from './permissions-wizard/PermissionWizard';
import {
	roleDeletePermission,
	roleAddPermission,
	roleEditPermission,
	permissionsGetList,
} from '../../../API';

export default function RolePermissions({
	platformManaged,
	role,
	roleId,
	setRole,
}) {
	const { enqueueSnackbar } = useSnackbar();

	const theme = useTheme();
	const screenDownSm = useMediaQuery(theme.breakpoints.down('sm'));

	const tableColumns = useMemo(
		() => [
			{ field: 'method', text: 'Permission' },
			{ field: 'group', text: 'Group' },
			{ field: 'resourceCount', text: 'Resources' },
			...(!platformManaged ? [{ field: 'actions', text: '' }] : []),
		],
		[platformManaged]
	);

	const [permToDelete, setPermToDelete] = useState(null);
	const [deleteDialog, setDeleteDialog] = useState(false);
	const [permDialog, setPermDialog] = useState(false);
	const [permToEdit, setPermToEdit] = useState(null);

	const deletePermission = useCallback(
		async permToDelete => {
			const { permissions } = role || {};
			const newPerms = permissions.filter(
				({ uuid }) => uuid !== permToDelete.uuid
			);
			setRole(
				produce(draft => {
					draft.permissions = newPerms;
				})
			);

			try {
				await roleDeletePermission({
					roleUuid: role?.uuid,
					permissionUuid: permToDelete.uuid,
				});
				setRole(
					produce(draft => {
						draft.modify_timestamp = Date.now();
					})
				);
				enqueueSnackbar(`Permission deleted`);
			} catch {
				setRole(
					produce(draft => {
						draft.permissions = [
							...draft.permissions,
							permToDelete,
						];
					})
				);
				enqueueSnackbar(
					`Could not delete permission ${
						permToDelete.method
					} from role`,
					{
						variant: 'error',
					}
				);
			}
		},
		[enqueueSnackbar, role, setRole]
	);

	const sortedPermissions = useMemo(
		() =>
			([...role?.permissions] || []).sort((a, b) =>
				a['method'].localeCompare(b['method'])
			),
		[role]
	);

	return (
		<>
			{platformManaged && (
				<BtMessage
					message="This role is managed by the platform, not your organisation. Therefore, you cannot edit the role permissions."
					style={{ marginBottom: '1em' }}
				/>
			)}
			{!platformManaged && (
				<Button
					variant="contained"
					onClick={() => {
						setPermToEdit(null);
						setPermDialog(true);
					}}
					startIcon={<AddIcon />}
					disableElevation
					style={{
						marginBottom: '1em',
						width: screenDownSm ? '100%' : 'auto',
					}}
				>
					Add Permission
				</Button>
			)}
			{sortedPermissions.length > 0 && (
				<BtSelectionTable
					columns={tableColumns}
					data={sortedPermissions.map(permission => ({
						...permission,
						resourceCount: (() => {
							const { resources } = permission || {};
							if (resources?.includes('*')) {
								return 'All Available';
							}
							return (resources || []).length;
						})(),
						...(!platformManaged && {
							actions: (
								<div
									style={{
										display: 'flex',
										justifyContent: 'flex-end',
										gap: 10,
									}}
								>
									<Tooltip
										title={'Edit Resources'}
										disableInteractive
									>
										<IconButton
											onClick={() => {
												setPermToEdit(permission);
												setPermDialog(true);
											}}
										>
											<EditIcon />
										</IconButton>
									</Tooltip>
									<Tooltip title="Delete" disableInteractive>
										<IconButton
											color="error"
											onClick={() => {
												setPermToDelete(permission);
												setDeleteDialog(true);
											}}
										>
											<DeleteIcon />
										</IconButton>
									</Tooltip>
								</div>
							),
						}),
					}))}
				/>
			)}
			<BtConfirmDialog
				action={() => deletePermission(permToDelete)}
				isDestructive
				open={deleteDialog}
				onClose={() => setDeleteDialog(false)}
				title="Delete Permission?"
				prompt={`Are you sure you want to delete the ${
					permToDelete?.method
				} permission? This cannot be undone.`}
				verb="Delete Permission"
				ActionIcon={<DeleteIcon />}
			/>
			<BtDialog
				open={permDialog}
				onClose={(event, reason) => {
					if (reason !== 'backdropClick') {
						setPermDialog(false);
					}
				}}
				minwidth="md"
				style={{
					maxHeight: screenDownSm ? '100%' : 720,
					margin: 'auto',
				}}
				fullWidth
			>
				<PermissionWizard
					addedPerms={role?.permissions || []}
					ownerId={roleId}
					onClose={() => setPermDialog(false)}
					permToEdit={permToEdit}
					setOwner={setRole}
					getPermissionsList={permissionsGetList}
					editPermission={roleEditPermission}
					addPermission={roleAddPermission}
				/>
			</BtDialog>
		</>
	);
}

RolePermissions.propTypes = {
	platformManaged: PropTypes.bool,
	role: PropTypes.object,
	roleId: PropTypes.string,
	setRole: PropTypes.func.isRequired,
};
