import { useEffect, useState } from 'react';

export function useChartDimensions(ref) {
	const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

	useEffect(
		() => {
			const element = ref.current;
			const resizeObserver = new ResizeObserver(entries => {
				if (!Array.isArray(entries)) return;
				if (!entries.length) return;

				const entry = entries[0];

				if (
					dimensions.width !== entry.contentRect.width ||
					dimensions.height !== entry.contentRect.height
				) {
					setDimensions({
						width: entry.contentRect.width,
						height: entry.contentRect.height,
					});
				}
			});
			resizeObserver.observe(element);

			return () => resizeObserver.unobserve(element);
		},
		[ref, dimensions.width, dimensions.height]
	);

	return dimensions;
}
