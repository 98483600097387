async function getAppStoreItem(key) {
	try {
		const value = localStorage.getItem(key);
		if (value) {
			return value;
		}
	} catch (error) {
		console.log(error);
	}
}

async function setAppStoreItem(key, value) {
	try {
		const result = localStorage.setItem(key, value);
		if (result) {
			console.log({ value });
		}
	} catch (error) {
		console.log(error);
	}
}

export { getAppStoreItem, setAppStoreItem };
