const { isDocument } = require('../../utils.js');
const {
	timeUnits,
	weekdays,
	weekdaysShort,
	mongoZeroDate,
} = require('../../utils_date.js');
const {
	ERROR,
	setOperator,
	evaluateExpression,
	OPERATION_MODE,
} = require('../expression.js');

const operatorKey = '$dateTrunc';

const allowedProperties = [
	'date',
	'unit',
	'binSize',
	'timezone',
	'startOfWeek',
];
const allowedUnits = timeUnits;
const refDate = mongoZeroDate;

setOperator(
	operatorKey,
	/**
	 * @type { ExpressionFunction<{
	 *   date?: Date;
	 *   unit?: string;
	 *   binSize?: number;
	 *   startOfWeek?: string;
	 *   timezone?: string;
	 *  }, Date> }
	 */
	(context, args, options) => {
		if (!isDocument(args)) {
			throw new Error(
				ERROR.INVALID_OPERATOR_ARGUMENT(
					operatorKey,
					'object as its argument'
				)
			);
		}

		const unknownKeys = Object.keys(args).filter(
			(k) => !allowedProperties.includes(k)
		);

		if (unknownKeys.length) {
			throw new Error(
				ERROR.INVALID_OPERATOR_ARGUMENT(
					operatorKey,
					'object with keys ' + allowedProperties
				)
			);
		}

		if (args.date === undefined) {
			throw new Error(
				ERROR.INVALID_OPERATOR_ARGUMENT(
					operatorKey,
					'"date" parameter to be set'
				)
			);
		}

		/**
		 * @type {any}
		 */
		const date = evaluateExpression(context, args.date);

		if (
			date !== null &&
			!(date instanceof Date || typeof date === 'number')
		) {
			throw new Error(
				ERROR.INVALID_OPERATOR_ARGUMENT(
					operatorKey,
					'date must be a Date',
					date
				)
			);
			// TODO - should this support non date types?
			//		  e.g strings and numbers that can be coerced to dates
		}

		/**
		 * @type {any}
		 */
		const unit = evaluateExpression(context, args.unit);

		if (args.unit === undefined) {
			throw new Error(
				ERROR.INVALID_OPERATOR_ARGUMENT(
					operatorKey,
					'"unit" parameter to be set'
				)
			);
		}

		if (
			unit !== null &&
			!(typeof unit === 'string' && allowedUnits.includes(unit))
		) {
			throw new Error(
				ERROR.INVALID_OPERATOR_ARGUMENT(
					operatorKey,
					'unit value to be one of ' + allowedProperties,
					unit
				)
			);
		}

		/**
		 * @type {any}
		 */
		let binSize = evaluateExpression(context, args.binSize);

		if (
			binSize !== undefined &&
			binSize !== null &&
			!(typeof binSize === 'number' && binSize > 0)
		) {
			throw new Error(
				ERROR.INVALID_OPERATOR_ARGUMENT(
					operatorKey,
					'binSize must evaluate to a positive non-zero number',
					unit
				)
			);
		}

		binSize = binSize ?? 1;

		/**
		 * @type {any}
		 */
		let timezone = evaluateExpression(context, args.timezone);

		if (
			timezone !== undefined &&
			timezone !== null &&
			!(typeof timezone === 'string')
		) {
			throw new Error(
				ERROR.INVALID_OPERATOR_ARGUMENT(
					operatorKey,
					'timezone to evaluate to a string, either a UTC Offset or an Olson Timezone Identifier',
					timezone
				)
			);
			// TODO - validate timezone string...
			// https://www.mongodb.com/docs/manual/reference/operator/aggregation/dateTrunc/#std-label-dateTrunc-timezone
		}

		timezone = timezone ?? 'UTC';

		/**
		 * @type {any}
		 */
		let startOfWeek = evaluateExpression(context, args.startOfWeek);

		if (
			startOfWeek !== undefined &&
			startOfWeek !== null &&
			!(
				typeof startOfWeek === 'string' &&
				(weekdays.includes(startOfWeek.toLowerCase()) ||
					weekdaysShort.includes(startOfWeek.toLowerCase()))
			)
		) {
			throw new Error(
				ERROR.INVALID_OPERATOR_ARGUMENT(
					operatorKey,
					'timezone to evaluate to a string, either a UTC Offset or an Olson Timezone Identifier',
					timezone
				)
			);
			// TODO - validate timezone string...
			// https://www.mongodb.com/docs/manual/reference/operator/aggregation/dateTrunc/#std-label-dateTrunc-timezone
		}

		if (unit === 'week' && startOfWeek === null) {
			return null;
		}

		startOfWeek = startOfWeek ?? 'sunday';

		if (timezone === null || binSize === null) {
			return null;
		}

		const d = new Date(date);

		switch (unit) {
			case 'year': {
				d.setUTCMonth(0);
				d.setUTCDate(1);
				d.setUTCHours(0, 0, 0, 0);

				if (binSize && binSize > 1) {
					const year = d.getUTCFullYear();

					const yearsSinceRefDate = year - refDate.getUTCFullYear();

					const diffFromBinYear = yearsSinceRefDate % binSize;

					if (yearsSinceRefDate >= 0) {
						d.setUTCFullYear(year - diffFromBinYear);
					} else {
						d.setUTCFullYear(year - (binSize + diffFromBinYear));
					}
				}

				break;
			}
			case 'month': {
				d.setUTCDate(1);
				d.setUTCHours(0, 0, 0, 0);

				if (binSize && binSize > 1) {
					const month = d.getUTCMonth();
					const year = d.getUTCFullYear();

					const yearsSinceRefDate = year - refDate.getUTCFullYear();

					const refMonthNumber = yearsSinceRefDate * 12 + month;

					const diffFromBinMonth = refMonthNumber % binSize;

					if (yearsSinceRefDate >= 0) {
						d.setUTCMonth(month - diffFromBinMonth);
					} else {
						d.setUTCMonth(month - (binSize + diffFromBinMonth));
					}
				}

				break;
			}
			case 'quarter': {
				d.setUTCHours(0, 0, 0, 0);

				const m = d.getUTCMonth();

				let q = 0;

				// oct -> dec
				if (m >= 9) {
					d.setUTCMonth(9, 1);
					q = 3;
				}
				// jul -> sept
				else if (m >= 6) {
					d.setUTCMonth(6, 1);
					q = 2;
				}
				// apr - jun
				else if (m >= 3) {
					d.setUTCMonth(3, 1);
					q = 1;
				}
				// jan - mar
				else {
					d.setUTCMonth(0, 1);
				}

				if (binSize && binSize > 1) {
					const year = d.getUTCFullYear();

					const yearsSinceRefDate = year - refDate.getUTCFullYear();

					const refQuarterNumber = yearsSinceRefDate * 4 + q;

					const diffFromBinQuarter = refQuarterNumber % binSize;

					if (yearsSinceRefDate >= 0) {
						d.setUTCMonth(d.getUTCMonth() - 3 * diffFromBinQuarter);
					} else {
						d.setUTCMonth(
							d.getUTCMonth() - 3 * (binSize + diffFromBinQuarter)
						);
					}
				}

				break;
			}
			case 'week':
				d.setUTCHours(0, 0, 0, 0);
				const day = d.getUTCDay();
				const zeroDay = weekdaysShort.indexOf(
					startOfWeek.toLowerCase().slice(0, 3)
				);
				const diff = day - zeroDay;

				if (diff >= 0) {
					d.setUTCDate(d.getUTCDate() - diff);
				} else {
					d.setUTCDate(d.getUTCDate() - (7 + diff));
				}

				if (binSize && binSize > 1) {
					const startDay = refDate.getUTCDay();
					const weekCountStart = new Date(refDate);

					// assuming refDate is a 1st day of a year.
					if (startDay > zeroDay) {
						weekCountStart.setUTCDate(1 + zeroDay + (7 - startDay));
					} else {
						weekCountStart.setUTCDate(1 + zeroDay - startDay);
					}

					const weekMs = 1000 * 60 * 60 * 24 * 7;

					binTimePeriodOnFixedUnitSize(
						binSize,
						weekMs,
						d,
						weekCountStart
					);
				}

				break;
			case 'day': {
				d.setUTCHours(0, 0, 0, 0);

				if (binSize && binSize > 1) {
					const dayMs = 1000 * 60 * 60 * 24;

					binTimePeriodOnFixedUnitSize(binSize, dayMs, d);
				}
				break;
			}
			case 'hour': {
				const hour = d.getUTCHours();
				d.setUTCHours(hour, 0, 0, 0);

				if (binSize && binSize > 1) {
					const hourMs = 1000 * 60 * 60;

					binTimePeriodOnFixedUnitSize(binSize, hourMs, d);
				}
				break;
			}
			case 'minute': {
				const minute = d.getUTCMinutes();
				d.setUTCMinutes(minute, 0, 0);

				if (binSize && binSize > 1) {
					const minuteMs = 1000 * 60;

					binTimePeriodOnFixedUnitSize(binSize, minuteMs, d);
				}
				break;
			}
			case 'second': {
				const second = d.getUTCSeconds();
				d.setUTCSeconds(second, 0);

				if (binSize && binSize > 1) {
					const secMs = 1000;
					binTimePeriodOnFixedUnitSize(binSize, secMs, d);
				}

				break;
			}
		}

		return d;
	},
	[OPERATION_MODE.AGGREGATE]
);

function binTimePeriodOnFixedUnitSize(
	binSize,
	unitMs,
	date,
	zeroDate = refDate
) {
	const unitDiffFromRef = (date.getTime() - zeroDate.getTime()) / unitMs;

	const unitDiffFromBin = unitDiffFromRef % binSize;

	if (unitDiffFromRef >= 0) {
		date.setTime(
			zeroDate.getTime() + (unitDiffFromRef - unitDiffFromBin) * unitMs
		);
	} else {
		date.setTime(
			zeroDate.getTime() +
				(unitDiffFromRef - unitDiffFromBin - binSize) * unitMs
		);
	}
}
