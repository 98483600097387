const { isDocument } = require('../../utils.js');
const {
	ERROR,
	setOperator,
	evaluateExpression,
	OPERATION_MODE,
} = require('../expression.js');

const allowedToValues = [
	'date',
	'bool',
	'string',
	'int',
	'double',
	'decimal',
	'long',
];

/**
 * @param {string} str
 */
function stringTo(str, to) {
	if (to === 'string') {
		return str;
	}

	if (to === 'int' || to === 'decimal' || to === 'long' || to === 'double') {
		let n = Number(str);
		if (to === 'int' || to === 'long') {
			// TODO - converting to a long - currently will be a truncated int
			const int = parseInt(str, 10);

			if (isNaN(n) || int !== n) {
				n = NaN;
			} else {
				n = int;
			}
		}

		if (isNaN(n)) {
			throw (
				'failed to parse number "' +
				str.slice(0, 10) +
				(str.length > 10 ? '...' : '') +
				'"'
			);
		}

		// TODO - technically this should fail for non decimal number string
		//        representations

		return n;
	}

	if (to === 'date') {
		if (isNaN(Date.parse(str))) {
			throw (
				'failed to parse date "' +
				str.slice(0, 10) +
				(str.length > 10 ? '...' : '') +
				'"'
			);
		}
		return new Date(str);
	}

	if (to === 'bool') {
		return true;
	}

	return null;
}

/**
 * @param {number} num
 */
function numberTo(num, to) {
	if (to === 'decimal' || to === 'double') {
		// these are allt he same in JS
		return num;
	}

	if (to === 'int' || to === 'long') {
		return Math.trunc(num);
	}

	if (to === 'string') {
		if (Object.is(num, -0)) {
			return '-0';
		}
		return `${num}`;
	}

	if (to === 'date') {
		// TODO - technically should fail on int to date, but JS doesn't have
		//        concept of an int vs a long/double
		return new Date(num);
	}

	if (to === 'bool') {
		return num !== 0;
	}

	return null;
}

/**
 * @param {boolean} bool
 */
function booleanTo(bool, to) {
	if (to === 'bool') {
		return bool;
	}

	if (to === 'int' || to === 'decimal' || to === 'long' || to === 'double') {
		// these are allt he same in JS
		return bool ? 1 : 0;
	}

	if (to === 'string') {
		return bool ? 'true' : 'false';
	}

	if (to === 'date') {
		throw 'unsupported conversion from bool to date';
	}

	return null;
}

/**
 * @param {Date} date
 */
function dateTo(date, to) {
	if (to === 'date') {
		return new Date(date);
	}

	if (to === 'int') {
		throw 'unsupported conversion from date to int';
	}

	if (to === 'decimal' || to === 'long' || to === 'double') {
		return date.getTime();
	}

	if (to === 'string') {
		return date.toISOString();
	}
	if (to === 'bool') {
		return true;
	}

	return null;
}

function convert(context, args, options, operatorKey) {
	if (!isDocument(args)) {
		throw new Error(
			ERROR.INVALID_OPERATOR_ARGUMENT(
				operatorKey,
				'object as its argument'
			)
		);
	}

	const hasInput = Object.hasOwnProperty.call(args, 'input');
	const hasTo = Object.hasOwnProperty.call(args, 'to');

	if (!hasInput || !hasTo) {
		throw new Error(
			ERROR.INVALID_OPERATOR_ARGUMENT(
				operatorKey,
				'"input" and "to" properties to be set'
			)
		);
	}

	const { input, to, onError, onNull } = args;

	if (!allowedToValues.includes(to)) {
		throw new Error(
			ERROR.FAILED_OPERATION(operatorKey, 'unknown type name: ' + to)
		);
	}

	/**
	 * @type {any}
	 */
	let value = evaluateExpression(context, input);

	try {
		if (isDocument(value)) {
			throw 'attempting to convert document to ' + to;
		}

		if (Array.isArray(value)) {
			throw 'attempting to convert an array to ' + to;
		}

		let out = null;

		if (value instanceof Date) {
			out = dateTo(value, to);
		} else if (typeof value === 'number') {
			out = numberTo(value, to);
		} else if (typeof value === 'string') {
			out = stringTo(value, to);
		} else if (typeof value === 'boolean') {
			out = booleanTo(value, to);
		}

		// guess its got to be nullish at this point

		if (out === null && Object.hasOwnProperty.call(args, 'onNull')) {
			return onNull;
		}

		return out;
	} catch (err) {
		if (Object.hasOwnProperty.call(args, 'onError')) {
			return onError;
		}

		throw new Error(
			ERROR.FAILED_OPERATION(operatorKey, err + ', with no onError value')
		);
	}
}
/**
 * @type {ExpressionOperationMode[]}
 */
const operationModes = [OPERATION_MODE.AGGREGATE, OPERATION_MODE.QUERY];

setOperator(
	'$convert',
	(...props) => convert(...props, '$convert'),
	operationModes
);

setOperator(
	'$toString',
	(context, args, options) =>
		convert(context, { input: args, to: 'string' }, options),
	operationModes
);

setOperator(
	'$toBool',
	(context, args, options) =>
		convert(context, { input: args, to: 'bool' }, options),
	operationModes
);

setOperator(
	'$toDate',
	(context, args, options) =>
		convert(context, { input: args, to: 'date' }, options),
	operationModes
);

setOperator(
	'$toInt',
	(context, args, options) =>
		convert(context, { input: args, to: 'int' }, options),
	operationModes
);

setOperator(
	'$toLong',
	(context, args, options) =>
		convert(context, { input: args, to: 'long' }, options),
	operationModes
);

setOperator(
	'$toDouble',
	(context, args, options) =>
		convert(context, { input: args, to: 'double' }, options),
	operationModes
);

setOperator(
	'$toDecimal',
	(context, args, options) =>
		convert(context, { input: args, to: 'decimal' }, options),
	operationModes
);
