import React, { useEffect, useMemo } from 'react';

import useFetch from '../../hooks/useFetch';
import { apiManagerGetList } from '../../API';

import KeyOffIcon from '@mui/icons-material/KeyOff';

import BtListComposer from './BtListComposer';
import { Skeleton } from '@mui/material';

export default function BtApiKeyList({ currentApiKeys, onApiKeyListUpdate }) {
	const {
		data: apiKeyList,
		error: apiKeyError,
		loading: loadingApiKeys,
		request: getApiKeys,
	} = useFetch(apiManagerGetList);
	useEffect(
		() => {
			if (!apiKeyList) {
				getApiKeys();
			}
		},
		[getApiKeys, apiKeyList]
	);
	useEffect(
		() => {
			if (apiKeyList) {
				console.log(apiKeyList);
			}
		},
		[apiKeyList]
	);
	const apiKeys = useMemo(
		() =>
			(apiKeyList || []).filter(({ uuid }) =>
				(currentApiKeys || []).includes(uuid)
			),
		[currentApiKeys, apiKeyList]
	);

	const addApiKey = apiKeyToAdd => {
		try {
			console.log('add api key', apiKeyToAdd);
			var newApiKeyList = [...currentApiKeys];
			newApiKeyList.push(apiKeyToAdd.uuid);
			console.log(newApiKeyList);
			onApiKeyListUpdate(newApiKeyList);
		} catch (error) {
			console.log(error);
		}
	};
	const removeApiKey = apiKeyToRemove => {
		onApiKeyListUpdate(
			[...currentApiKeys].filter(apiKey => apiKey !== apiKeyToRemove.uuid)
		);
	};

	if (loadingApiKeys) {
		return <Skeleton style={{ width: '100%' }} />;
	}

	return (
		<BtListComposer
			allItems={apiKeyList || []}
			confirmDeletion
			deleteable
			deleteVerb="Remove"
			items={apiKeys}
			primaryField="name"
			DeleteIcon={<KeyOffIcon />}
			sortComparator={(a, b) => a?.name?.localeCompare(b?.name)}
			subject="api keys"
			onItemAdd={addApiKey}
			onItemDelete={removeApiKey}
		/>
	);
}
