import { apiClient } from './api_client';
import { uuidRegex } from './utils';

import {
	userGetSubscriptionsSchema,
	userPostSchema,
	userPutForgotPasswordSchema,
	userPutSchema,
} from './validations/userValidation';

//========================================================================================================
async function userGet() {
	const req_url = 'user';

	let userData = await apiClient({
		method: 'get',
		url: req_url,
	});

	if (userData) {
		if (userData.result === 'OK') {
			if (userData.user !== undefined) {
				return userData;
			}
		} else {
			throw new Error('Server Error');
		}
	}
}

//========================================================================================================
async function userGetById({ userUuid }) {
	if (!uuidRegex.test(userUuid)) {
		throw new Error('Param Error');
	}
	const req_url = `user/${userUuid}`;
	let userData = await apiClient({
		method: 'get',
		url: req_url,
	});

	if (userData) {
		if (userData.result === 'OK') {
			if (userData.user !== undefined) {
				return userData.user;
			}
		} else {
			throw new Error('Server Error');
		}
	}
}

//========================================================================================================
async function userGetPermissions() {
	const req_url = 'user/permissions';

	try {
		let userData = await apiClient({ method: 'get', url: req_url });

		if (userData) {
			if (userData.result === 'OK') {
				if (userData.permissions !== undefined) {
					return userData.permissions;
				}
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function userGetSubscriptions() {
	const req_url = 'user/subscriptions';

	try {
		let userSubscriptions = await apiClient({
			method: 'get',
			url: req_url,
		});

		if (userSubscriptions) {
			if (userSubscriptions.result === 'OK') {
				await userGetSubscriptionsSchema.validate(
					userSubscriptions.subscriptions
				);
				return userSubscriptions.subscriptions;
			} else {
				return [];
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function userPost({ userData }) {
	try {
		await userPostSchema.validate(userData);
	} catch (error) {
		throw error;
	}

	try {
		let apiRes = await apiClient({
			method: 'post',
			url: 'user',
			body: userData,
		});

		if (apiRes) {
			if (apiRes.result === 'OK') {
				return;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function userPut({ userData }) {
	try {
		await userPutSchema.validate(userData);
	} catch (error) {
		throw error;
	}
	const req_url = 'user';
	let apiRes = await apiClient({
		method: 'put',
		url: req_url,
		body: userData,
	});

	try {
		if (apiRes) {
			if (apiRes.result === 'OK') {
				return;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function userPutForgotPassword(userData) {
	try {
		await userPutForgotPasswordSchema.validate(userData);
	} catch (error) {
		throw error;
	}
	try {
		let apiRes = await apiClient({
			method: 'put',
			url: 'user/forgot_password',
			body: userData,
		});

		if (apiRes) {
			if (apiRes.result === 'OK') {
				// console.log(apiRes);
				return apiRes;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

export {
	userGet,
	userGetById,
	userGetPermissions,
	userGetSubscriptions,
	userPost,
	userPut,
	userPutForgotPassword,
};
