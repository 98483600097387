import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import {
	BtForm,
	BtFormContent,
	BtFormTextField,
} from '../../../components/generic/forms';
import BtQueryBuilder from '../../../components/generic/query-builder/BtQueryBuilder';
import useQueryBuilderDemoData from './useQueryBuilderDemoData';

import * as yup from 'yup';

const formSchema = yup.object().shape({
	collection: yup.string().required(),
	configSchema: yup.string().required(),
	query: yup.string().required(),
});

export default function StandaloneStatic(props) {
	const { options } = props;

	const defaultValues = useQueryBuilderDemoData();
	const { configSchema, collection, query } = defaultValues;

	const [inputs, setInputs] = useState({ collection, query, configSchema });
	const [outputQuery, setOutputQuery] = useState(query);

	return (
		<>
			<BtForm
				validationSchema={formSchema}
				defaultValues={defaultValues}
				// loading={false}
				// sending={sending}

				onFormStateChange={(formState, values) => {
					if (
						values.collection !== inputs.collection ||
						values.query !== inputs.query ||
						values.configSchema !== inputs.configSchema
					) {
						const newValues = {
							collection: values.collection || inputs.collection,
							query: values.query || inputs.query,
							configSchema:
								values.configSchema || inputs.configSchema,
						};

						setInputs(newValues);
					}
				}}
			>
				<BtFormContent>
					<BtFormTextField
						multiline
						name="collection"
						label="Input Collection"
					/>
					<BtFormTextField
						multiline
						name="query"
						label="Input Query"
					/>
					<BtFormTextField
						multiline
						name="configSchema"
						label="Input ConfigSchema"
					/>
				</BtFormContent>
			</BtForm>

			<Divider sx={{ mx: 5, my: 3 }} />

			<Box>
				<Typography>Query</Typography>
				<pre
					style={{
						overflowX: 'hidden',
						whiteSpace: 'pre-wrap',
						wordBreak: 'break-word',
					}}
				>
					{outputQuery}
				</pre>
			</Box>

			<Divider sx={{ mx: 5, my: 3 }} />

			<BtQueryBuilder
				initialQuery={inputs.query}
				initialInputs={inputs}
				onChange={result => {
					console.log(result);
					const {
						query,
						queryStr,
						configSchema,
						configSchemaStr,
						error,
					} = result;
					setOutputQuery(queryStr);
				}}
				//config
				{...options}
			/>
		</>
	);
}
