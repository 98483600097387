import React, { memo } from 'react';
import BtError from '../../../../components/generic/BtError';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { VIS_LIST_TYPES } from '../../InsightPage/visualisationConstants';

const VisualisationError = memo(function VisualisationError({ description }) {
	return (
		<div
			style={{
				width: '100%',
				height: '100%',
			}}
		>
			<BtError
				title="Visualisation Error"
				description={
					<>
						<Typography>{description}</Typography>
					</>
				}
			/>
		</div>
	);
});

VisualisationError.displayName = 'VisualisationError';

VisualisationError.defaultProps = {
	description:
		'An error occurred whilst trying to retrieve the visualisation.',
};

VisualisationError.propTypes = {
	description: PropTypes.string,
};

export { VisualisationError };
