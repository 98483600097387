// =============================================================================
const timeUnits = [
	'year',
	'quarter',
	'week',
	'month',
	'day',
	'hour',
	'minute',
	'second',
];
module.exports.timeUnits = timeUnits;

// =============================================================================
// ordering to match JS sunday = 0th day
const weekdays = [
	'sunday', // 0
	'monday', // 1
	'tuesday', // 2
	'wednesday', // 3
	'thursday', // 4
	'friday', // 5
	'saturday', // 6
];
module.exports.weekdays = weekdays;

// =============================================================================
const weekdaysShort = weekdays.map((d) => d.slice(0, 3));
module.exports.weekdaysShort = weekdaysShort;

// =============================================================================
const mongoZeroDate = new Date('2000-01-01T00:00:00.000Z');
module.exports.mongoZeroDate = mongoZeroDate;
