import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useNavContext } from '../../context/ContextManager';

import Box from '@mui/material/Box';

import { BtFormContainer } from '../../components/generic/forms';
import {
	SelectionTable,
	SelectionTableBody,
	SelectionTableCell,
	SelectionTableContainer,
	SelectionTableRow,
} from '../../components/generic/BtSelectionTable';
import useNavRedactor from '../../hooks/useNavRedactor';

// Data for landing page selection table and contextual navigation
import data_manager_nav_item from './DataManagerNavItem';
import data_manager_options from './DataManagerOptions';

export default function Insights() {
	const history = useHistory();

	const { setContextualNav } = useNavContext();
	const { setBreadcrumbs } = useNavContext();
	const redactNav = useNavRedactor();

	const redactedDataManagerOptions = useMemo(
		() => redactNav(data_manager_options),
		[redactNav]
	);

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'Data Manager', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([
				...data_manager_nav_item,
				...redactedDataManagerOptions,
			]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav, redactedDataManagerOptions]
	);

	const onClickUtil = route => () => {
		history.push(route);
	};

	return (
		<BtFormContainer maxWidth="md" title="Data Manager">
			<SelectionTableContainer component={Box}>
				<SelectionTable
					sx={{ minWidth: 650 }}
					aria-label="simple table"
				>
					<SelectionTableBody>
						{redactedDataManagerOptions.map((util, index) => (
							<SelectionTableRow
								onClick={onClickUtil(util.route)}
								key={index}
							>
								<SelectionTableCell>
									{util.icon}
								</SelectionTableCell>
								<SelectionTableCell>
									{util.text}
								</SelectionTableCell>
								<SelectionTableCell>
									{util.description}
								</SelectionTableCell>
							</SelectionTableRow>
						))}
					</SelectionTableBody>
				</SelectionTable>
			</SelectionTableContainer>
		</BtFormContainer>
	);
}
