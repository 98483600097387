import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Auth } from 'aws-amplify';

// COMPONENTS
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import TextField from '@mui/material/TextField';

import 'date-fns';

import SaveIcon from '@mui/icons-material/SaveAlt';
import CloseIcon from '@mui/icons-material/Close';

const styles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(2),
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
	},
	dialogTitle: {
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.contrastText,
	},
	dialogContent: {
		minWidth: '600px',
	},
	dialogText: {
		padding: theme.spacing(2),
		textAlign: 'center',
	},
}));

export default function AddReporterDialog({ setOpen, onClose }) {
	const classes = styles();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const [name, setName] = useState('');
	const [uid, setUid] = useState('');
	const [channel, setChannel] = useState('');
	const [url, setUrl] = useState('');

	const handleNameChange = event => {
		setName(event.target.value);
	};
	const handleDeviceChange = event => {
		setUid(event.target.value);
	};
	const handleChannelChange = event => {
		setChannel(event.target.value);
	};
	const handleUrlChange = event => {
		setUrl(event.target.value);
	};

	const handleSave = () => {
		setIsSubmitting(true);

		var add_reporter_str = {};
		add_reporter_str['uid'] = uid;
		add_reporter_str['name'] = name;
		add_reporter_str['channel'] = channel;
		add_reporter_str['url'] = url;

		var req_url = process.env.REACT_APP_API_SERVER_URL + '/v1/reporter';

		Auth.currentSession().then(user_session => {
			let user_token = user_session.getAccessToken();
			fetch(req_url, {
				method: 'POST',
				headers: {
					Authorization: user_token.jwtToken,
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(add_reporter_str),
			})
				.then(res => res.json())
				.then(response => {
					if (response['result'] !== 'OK') {
						alert('Failed to add reporter');
					} else {
						onClose(true);
					}
					setIsSubmitting(false);
				})
				.catch(error => {
					alert('Server Error!');
					setIsSubmitting(false);
				});
		});
	};

	const handleCancel = () => {
		onClose(false);
	};

	return (
		<Dialog open={setOpen} onClose={handleCancel} className={classes.root}>
			<DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
				Add New Reporter
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				<form className={classes.root} noValidate autoComplete="off">
					<div>
						<Typography variant="h5">Name:</Typography>
						<TextField
							variant="outlined"
							fullWidth
							className={classes.textField}
							onChange={handleNameChange}
						/>
					</div>

					<div>
						<Typography variant="h5">Device UID:</Typography>
						<TextField
							variant="outlined"
							fullWidth
							className={classes.textField}
							onChange={handleDeviceChange}
						/>
					</div>

					<div>
						<Typography variant="h5">Channel:</Typography>
						<TextField
							variant="outlined"
							fullWidth
							className={classes.textField}
							onChange={handleChannelChange}
						/>
					</div>

					<div>
						<Typography variant="h5">URL:</Typography>
						<TextField
							variant="outlined"
							fullWidth
							className={classes.textField}
							onChange={handleUrlChange}
						/>
					</div>

					<Divider />

					<Box
						display="flex"
						flexDirection="row-reverse"
						p={1}
						m={1}
						bgcolor="background.paper"
					>
						<Button
							variant="outlined"
							startIcon={<SaveIcon />}
							onClick={handleSave}
							className={classes.actionButton}
						>
							Save
						</Button>
						<Button
							variant="outlined"
							startIcon={<CloseIcon />}
							onClick={handleCancel}
							className={classes.actionButton}
						>
							Cancel
						</Button>
					</Box>

					<Backdrop className={classes.backdrop} open={isSubmitting}>
						<CircularProgress color="inherit" />
					</Backdrop>
				</form>
			</DialogContent>
		</Dialog>
	);
}
