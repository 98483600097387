import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	Skeleton,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

export default function BtFormCheckbox({
	disabled,
	falseValue,
	label,
	name,
	style,
	trueValue,
	...props
}) {
	const {
		control,
		setValue,
		loading,
		sending,
		watch,
		formState: { errors },
	} = useFormContext() || { formState: {} };
	const value = watch(name || false);
	const hasError = !!errors[name];

	const checked = useMemo(
		() => {
			if (falseValue !== undefined && trueValue !== undefined) {
				return value === trueValue;
			}

			return value;
		},
		[falseValue, trueValue, value]
	);

	const handleChange = useCallback(
		event => {
			const establishValue = () => {
				const checked = event.target.checked;

				if (falseValue !== undefined && trueValue !== undefined) {
					return checked ? trueValue : falseValue;
				}

				return checked;
			};

			setValue(name, establishValue(), {
				shouldValidate: true,
				shouldDirty: true,
				shouldTouch: true,
			});
		},
		[falseValue, name, setValue, trueValue]
	);

	const isDisabled = useMemo(() => loading || sending || disabled, [
		loading,
		sending,
		disabled,
	]);

	if (loading) {
		return (
			<div
				style={{
					alignItems: 'center',
					display: 'flex',
					width: '100%',
				}}
			>
				<Skeleton
					variant="circular"
					animation="wave"
					width={30}
					height={30}
					style={{ marginRight: 8, minWidth: 30, minHeight: 30 }}
				/>
				<Skeleton
					animation="wave"
					height={50}
					style={{ maxWidth: 200, width: '100%' }}
				/>
			</div>
		);
	}

	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<FormControl
					required
					error={hasError}
					disabled={isDisabled}
					style={style}
				>
					<FormGroup>
						<FormControlLabel
							style={{ margin: 0, userSelect: 'none' }}
							control={
								<Checkbox
									{...field}
									checked={checked || false}
									onChange={handleChange}
									disabled={isDisabled}
									{...props}
								/>
							}
							label={label}
						/>
						{hasError && (
							<FormHelperText>
								{errors[name]?.message}
							</FormHelperText>
						)}
					</FormGroup>
				</FormControl>
			)}
		/>
	);
}

BtFormCheckbox.propTypes = {
	disabled: PropTypes.bool,
	falseValue: PropTypes.any,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	style: PropTypes.object,
	trueValue: PropTypes.any,
};
