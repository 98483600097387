import _ from 'lodash';
import { useCallback, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

export default function useAvailableToUser() {
	const { userPermissions, userSubscriptions } = useContext(AuthContext);

	const getMissingPermissions = useCallback(
		requiredPermissionsAll => {
			let missingPermissions = {};
			if (!requiredPermissionsAll) {
				return missingPermissions;
			}

			// Ensure user permissions contains every method for every group
			Object.keys(requiredPermissionsAll).forEach(group => {
				const methods = requiredPermissionsAll[group];

				// Ensure methods are extrapolated from group
				if (!Array.isArray(methods)) {
					if (process.env.NODE_ENV === 'development') {
						console.error(
							'requiredPermissionsAll should be an object containing permission groups and respective arrays of permission method names.'
						);
					}
				}

				// Check if user permissions contains group concerned
				if (!Object.keys(userPermissions || {}).includes(group)) {
					missingPermissions = {
						...missingPermissions,
						[group]: [...requiredPermissionsAll[group]],
					};
					return;
				}

				// Ensure user permissions includes every required method for this group
				methods.forEach(method => {
					if (!userPermissions[group].includes(method)) {
						missingPermissions = {
							...missingPermissions,
							[group]: [
								...(missingPermissions[group] || []),
								method,
							],
						};
					}
				});
			});
			//console.log(userPermissions, missingPermissions);
			return missingPermissions;
		},
		[userPermissions]
	);

	const assessSubscriptions = useCallback(
		validSubscriptionsAny => {
			let missingSubscriptions = [];
			let expiredSubscriptions = [];
			let validSubscriptionMatch = false;

			// If no valid subscriptions are passed then skip
			if (!validSubscriptionsAny) {
				validSubscriptionMatch = true;
				return {
					validSubscriptionMatch,
					missingSubscriptions,
					expiredSubscriptions,
				};
			}

			if (!Array.isArray(validSubscriptionsAny)) {
				if (process.env.NODE_ENV === 'development') {
					console.error(
						'validSubscriptionsAny should be an array of subscription names.'
					);
				}
			}

			// If no subscriptions a set then no subscription is need to allow
			if (validSubscriptionsAny.length === 0) {
				validSubscriptionMatch = true;
			} else {
				validSubscriptionsAny.forEach(validSubscription => {
					// Try and find the valid subscription in the users subscription list
					const subscription = (userSubscriptions || []).find(
						({ name }) => name === validSubscription
					);

					// Does the user have this subscription
					if (subscription) {
						// And has it not expired
						if (subscription.status === 'expired') {
							expiredSubscriptions = [
								...expiredSubscriptions,
								validSubscription,
							];
						} else {
							validSubscriptionMatch = true;
						}
					} else {
						missingSubscriptions = [
							...missingSubscriptions,
							validSubscription,
						];
					}
				});
			}
			//console.log('validSubscriptionMatch', validSubscriptionMatch);
			return {
				validSubscriptionMatch,
				missingSubscriptions,
				expiredSubscriptions,
			};
		},
		[userSubscriptions]
	);

	return useCallback(
		props => {
			if (props.requiredPermissionsAll && props.requiredPermissionsAny) {
				if (
					Object.keys(props.requiredPermissionsAll).length > 0 &&
					Object.keys(props.requiredPermissionsAny).length > 0
				) {
					if (process.env.NODE_ENV === 'development') {
						console.error(
							'Both requiredPermissionsAny AND requiredPermissionsAny have been passed to the useAvailableToUser hook, requiredPermissionsAny will be ignored.',
							props
						);
					}
				}
			}

			// extract the passed props
			const {
				requiredPermissionsAll,
				requiredPermissionsAny,
				validSubscriptionsAny,
			} = props || {};

			// assess the subs
			const {
				validSubscriptionMatch,
				missingSubscriptions,
				expiredSubscriptions,
			} = assessSubscriptions(validSubscriptionsAny);
			const hasMissingSubscriptions = missingSubscriptions.length > 0;
			const hasExpiredSubscriptions = expiredSubscriptions.length > 0;

			let available = null;

			// build result, adding any missing or expired subscriptions
			let result = {
				...(hasExpiredSubscriptions ? { expiredSubscriptions } : {}),
				...(hasMissingSubscriptions ? { missingSubscriptions } : {}),
			};

			if (props.requiredPermissionsAll) {
				// get the missing permissions
				const missingPermissionsAll = getMissingPermissions(
					requiredPermissionsAll
				);

				const hasMissingPermissionsAll =
					Object.keys(missingPermissionsAll).length > 0;

				available = !(
					hasMissingPermissionsAll || !validSubscriptionMatch
				);

				result = {
					...result,
					...(hasMissingPermissionsAll
						? { missingPermissionsAll }
						: {}),
				};
			} else if (props.requiredPermissionsAny) {
				// get the missing permissions
				const missingPermissionsAny = getMissingPermissions(
					requiredPermissionsAny
				);

				const hasRequiredPermissionsAny = !_.isEqual(
					missingPermissionsAny,
					requiredPermissionsAny
				);

				available = !(
					!hasRequiredPermissionsAny || !validSubscriptionMatch
				);

				result = {
					...result,
					...(!hasRequiredPermissionsAny
						? { missingPermissionsAny }
						: {}),
				};
			} else {
				available = validSubscriptionMatch;
			}

			// console.log({ props, result, available });
			return {
				...result,
				available,
			};
		},
		[assessSubscriptions, getMissingPermissions]
	);
}
