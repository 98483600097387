import {
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	Typography,
	FormControl,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { insightCollectionsGetList } from '../../../../../../API';
import useFetch from '../../../../../../hooks/useFetch';
import BtLoading from '../../../../BtLoading';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import SelectItem from '../../formComponents/SelectItem';
import { useTileConfigContext } from '../TileConfigDialogContext';
import { inputStyle } from './inputStyle';
import BtError from '../../../../BtError';

export default function InsightCollectionConfig() {
	const {
		tileUpdateParams,
		setTileUpdateParams,
		existingTileData,
		setIsFormValid,
		setError,
	} = useTileConfigContext();
	const [collectionOptions, setCollectionOptions] = useState([]);
	const [isFormReady, setIsFormReady] = useState(false);

	const {
		data: insightCollections,
		error: insightCollectionsError,
		loading: insightCollectionsLoading,
		request: insightCollectionsRequest,
	} = useFetch(insightCollectionsGetList);

	useEffect(
		() => {
			// console.log('make the request');
			insightCollectionsRequest();
		},
		[insightCollectionsRequest]
	);

	useEffect(
		() => {
			if ({ insightCollectionsError }) {
				console.log({ insightCollectionsError });
				setError(insightCollectionsError);
			}
		},
		[insightCollectionsError, setError]
	);

	// useEffect(
	// 	() => {
	// 		console.log({ insightCollectionsLoading });
	// 	},
	// 	[insightCollectionsLoading]
	// );

	useEffect(
		() => {
			if (insightCollections) {
				console.log({ insightCollections });
				const collections = insightCollections.map(template => ({
					value: template.uuid,
					label: template.name,
					description: template.description,
				}));
				//save these to state for later use
				setCollectionOptions(collections);

				// set the collections select value with the existing tile data
				setValue(
					'collection',
					existingTileData.data.insight_collection
				);

				setIsFormReady(true);
			}
		},
		[insightCollections]
	);

	//validation schema
	const schema = yup
		.object({
			collection: yup
				.string()
				.required()
				// .oneOf([buttonTypes.map(type => type.value)])
				.label('Collection'),
		})
		.required();

	//react hook form
	const {
		watch,
		control,
		setValue,
		formState: { isDirty, errors, isValid },
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: { collection: '' },
		mode: 'onChange',
	});

	const collectionValue = watch('collection');
	useEffect(
		() => {
			if (isFormReady && isDirty) {
				const valueProps = collectionOptions.find(
					collection => collection.value === collectionValue
				);
				const tileUpdate = structuredClone(tileUpdateParams);
				tileUpdate.data.insight_collection = collectionValue;
				tileUpdate.title = valueProps.label;
				tileUpdate.subtitle = valueProps.description;
				// tileUpdate.subtitle = collectionOptions.find(
				// 	collection => collection.value === collectionValue
				// ).description;
				setTileUpdateParams(tileUpdate);
			}
		},
		[collectionValue]
	);

	useEffect(
		() => {
			if (isValid && isDirty) {
				if (
					existingTileData.data.insight_collection ===
						collectionValue &&
					existingTileData.title ===
						collectionOptions.find(
							collection => collection.value === collectionValue
						).label
					// existingTileData.data.button_type ===
					// 	collectionOptions.find(
					// 		collection => collection.uuid === collectionValue
					// 	).description
				) {
					setIsFormValid(false);
				} else {
					setIsFormValid(isValid && isDirty);
				}
			} else {
				setIsFormValid(isValid && isDirty);
			}
		},
		[
			isValid,
			isDirty,
			existingTileData.data.insight_collection,
			existingTileData.data.button_type,
			existingTileData.title,
			collectionValue,
			collectionOptions,
			setIsFormValid,
		]
	);

	return (
		<BtLoading loading={insightCollectionsLoading}>
			{/* {insightCollectionsError ? ( 
			// 	<BtError fullScreen={false} />
			// ) : (*/}
			{isFormReady && (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						// paddingLeft: 10,
						// paddingRight: 10,
					}}
				>
					<Controller
						name="collection"
						// defaultValue={level}
						control={control}
						render={({ field, fieldState: { error } }) => (
							<FormControl
								variant="standard"
								// sx={{ m: 1, minWidth: 120 }}
								style={inputStyle}
								error={!!error}
								// disabled={buttonTypeValue === ''}
							>
								<InputLabel id="collection-label">
									Select insight collection
								</InputLabel>
								<Select {...field}>
									{collectionOptions.map(type => (
										<MenuItem
											key={type.value}
											value={type.value}
										>
											<SelectItem
												label={type.label}
												description={type.description}
											/>
										</MenuItem>
									))}
								</Select>
								{error && (
									<FormHelperText>
										{error.message}
									</FormHelperText>
								)}
							</FormControl>
						)}
					/>
				</div>
			)}
			{/* // )}} */}
		</BtLoading>
	);
}
