import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@mui/material';

const FilterButton = ({ title, onClick, disabled, icon }) => {
	return (
		<Tooltip arrow title={title}>
			<span>
				<IconButton onClick={onClick} disabled={disabled}>
					{icon}
				</IconButton>
			</span>
		</Tooltip>
	);
};

FilterButton.propTypes = {
	title: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	icon: PropTypes.node.isRequired,
};

export default FilterButton;
