import { apiClient } from './api_client';
import { uuidRegex } from './utils';

import {
	tagPostSchema,
	tagGetAppliedListSchema,
} from './validations/tagValidation';

//========================================================================================================
//  tagApplyObj = {
//      tagGroupUuid
//      tagValueUuid
//      resourceUuid
//      resourceGroup
//  }
async function tagApply({ tagApplyObj }) {
	// Validate the newTagGroup details
	try {
		await tagPostSchema.validate(tagApplyObj);
	} catch (error) {
		throw error;
	}

	const req_url = 'tag';
	try {
		const response = await apiClient({
			method: 'post',
			url: req_url,
			body: tagApplyObj,
		});

		if (response) {
			if (response.result === 'OK') {
				return;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
//  tagRemoveObj = {
//      tagGroupUuid
//      tagValueUuid
//      resourceUuid
//      resourceGroup
//  }
async function tagRemove({ tagRemoveObj }) {
	// Validate the newTagGroup details
	try {
		await tagPostSchema.validate(tagRemoveObj);
	} catch (error) {
		throw error;
	}

	const req_url = 'tag';
	try {
		const response = await apiClient({
			method: 'delete',
			url: req_url,
			body: tagRemoveObj,
		});

		if (response) {
			if (response.result === 'OK') {
				return;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
// Get the resources tagged with this value
// resourceGroup: optional resource group filter
async function tagGetAppliedList({ tagValueUuid, resourceGroup }) {
	if (!uuidRegex.test(tagValueUuid)) {
		throw new Error('Param Error');
	}

	const req_url = `tag/${tagValueUuid}`;
	try {
		let params = null;
		if (resourceGroup) {
			params = { resourceGroup: resourceGroup };
		}

		const response = await apiClient({
			method: 'get',
			url: req_url,
			params: params,
		});

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let tagList = await tagGetAppliedListSchema.validate(
					response.tags
				);
				return tagList;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

export { tagApply, tagRemove, tagGetAppliedList };
