import React, {
	createContext,
	useCallback,
	useContext,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';

import Comments from '../../../../components/generic/comments/Comments';
import useCommentFuncs from '../processing/hooks/useCommentFuncs';
import useComments from '../../../../components/generic/comments/hooks/useComments';
import { useWorkflowSessionContext } from './WorkflowSessionContext';

const WorkflowPageContext = createContext();

export const useWorkflowPageContext = () => useContext(WorkflowPageContext);

export default function WorkflowPageContextProvider({ children }) {
	const commentsParentUuid = useRef();
	const openSections = useRef([]);
	const scrollElementId = useRef();

	const {
		commentAvailability,
		currentPage,
		updateComments,
	} = useWorkflowSessionContext();

	const [comments, commentsSet] = useState();

	const { addComment, deleteComment, editComment } = useComments(comments);
	const {
		adornment,
		commonPredicate,
		modificationMutator,
		newCommentMessage,
		readOnlyMessage,
	} = useCommentFuncs();

	const setComments = useCallback((parentUuid, comments) => {
		if (!parentUuid) {
			commentsParentUuid.current = null;
			commentsSet(null);

			return;
		}

		commentsParentUuid.current = parentUuid;
		commentsSet(comments);
	}, []);

	const handleAddComment = useCallback(
		newComment => {
			addComment(newComment, result => {
				updateComments(commentsParentUuid.current, result);

				commentsSet(result);
			});
		},
		[addComment, updateComments]
	);

	const handleEditComment = useCallback(
		editedComment => {
			editComment(editedComment, result => {
				updateComments(commentsParentUuid.current, result);

				commentsSet(result);
			});
		},
		[editComment, updateComments]
	);

	const handleDeleteComment = useCallback(
		commentToDelete => {
			deleteComment(commentToDelete, result => {
				updateComments(commentsParentUuid.current, result);

				commentsSet(result);
			});
		},
		[deleteComment, updateComments]
	);

	return (
		<WorkflowPageContext.Provider
			value={{
				addComment,
				comments,
				currentPage,
				deleteComment,
				editComment,
				openSections,
				scrollElementId,
				setComments,
			}}
		>
			{children}
			<Comments
				availability={commentAvailability.components}
				comments={comments}
				isEditablePredicate={commonPredicate}
				markAsNewPredicate={commonPredicate}
				modificationMutator={modificationMutator}
				newCommentMessage={newCommentMessage}
				onAddComment={handleAddComment}
				onClose={() => commentsSet(null)}
				onDeleteComment={handleDeleteComment}
				onEditComment={handleEditComment}
				readOnlyMessage={readOnlyMessage}
				renderAdornment={adornment}
			/>
		</WorkflowPageContext.Provider>
	);
}

WorkflowPageContextProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};
