import React, { useEffect, useState } from 'react';
import { useChartsContext } from '../contexts/ChartsContexts';
import { useComponentsContext } from '../Components/Chart';
import { getTextDimensions } from '../Utils';

const colours = [
	'#5fc1c9', // blue - [0]
	'#3ac258', // green - [1]
	'#e29834', // orange - [2]
	'#9046b6', // purple - [3]
	'#dc564c', // red - [4]
	'#1c3249', // dark blue - [5]
	'#f2b705', // yellow - [6]
	'#155e1c', // dark green - [7]
	'#78170e', // dark red - [8]
	'#9e4d02', // dark orange - [9]
];

const StatusColours = {
	'Unknown / In Prod': colours[0],
	Running: colours[1],
	'Technical on Machine': colours[2],
	'Awaiting Setter': colours[3],
	'Tool Hanger on M/c': colours[4],
	'Awaiting Tool Hanger': colours[5],
	'Awaiting Machine': colours[6],
	'Awaiting Senior Tech': colours[7],
	'Materials Drying': colours[8],
	'Awaiting First Off Approval': colours[9],
};

export function StatusTimeline({ dataKey }) {
	const { onMouseOver } = useChartsContext();

	const { plotArea, dataKeys, data, timeRange } = useComponentsContext();

	const [lineIndex, setLineIndex] = useState(0);
	const [lineData, setLineData] = useState(null);
	useEffect(
		() => {
			if (data && dataKey) {
				setLineData(data.find(line => line.id === dataKey));
				setLineIndex(data.findIndex(line => line.id === dataKey));
			}
		},
		[data, dataKey]
	);

	const [segments, setSegments] = useState(null);
	const [mouseOver, setMouseOver] = useState(false);

	const barPadding = 4;

	const segmentHeight = plotArea.height / dataKeys.length;

	const segmentVertOffset = segmentHeight * lineIndex;

	useEffect(
		() => {
			if (lineData && plotArea?.width && plotArea?.height) {
				const segments = lineData.statusSegments.map(
					(segment, index) => {
						const scale =
							(timeRange.end - timeRange.start) / plotArea.width;
						const segmentWidth =
							(segment.endTime - segment.startTime) / scale;
						const segmentOffset =
							(segment.startTime - timeRange.start) / scale;

						const segmentColour = StatusColours[segment.status];
						const segmentCentre = segmentOffset + segmentWidth / 2;

						const showText =
							getTextDimensions(segment.status, 12).width <
							segmentWidth;

						return (
							<g
								key={index}
								onMouseEnter={() => {
									setMouseOver(true);
									onMouseOver([lineData.id, index]);
								}}
								onMouseLeave={() => {
									setMouseOver(false);
									onMouseOver(null);
								}}
							>
								<rect
									x={segmentOffset}
									y={barPadding / 2}
									width={segmentWidth}
									height={segmentHeight - barPadding}
									fill={segmentColour}
								/>
								{mouseOver && (
									<rect
										x={segmentOffset}
										y={barPadding / 2}
										width={segmentWidth}
										height={segmentHeight}
										fill="#00000044"
									/>
								)}
								{showText && (
									<text
										x={segmentCentre}
										y={segmentHeight / 2}
										fill="white"
										dominantBaseline="middle"
										textAnchor="middle"
									>
										{segment.status}
									</text>
								)}
							</g>
						);
					}
				);
				setSegments(segments);
			}
		},
		[
			lineData,
			plotArea?.width,
			plotArea?.height,
			timeRange,
			mouseOver,
			dataKeys,
			lineIndex,
			segmentHeight,
			onMouseOver,
		]
	);

	return (
		<g transform={`translate(${plotArea.x} ${segmentVertOffset})`}>
			{(segments || []).map(segment => segment)}
		</g>
	);
}
