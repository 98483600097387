import React from 'react';

import {
	DialogContent,
	DialogTitle,
	Button,
	DialogActions,
} from '@mui/material';

import BtDialog from '../../../components/generic/BtDialog';
import ActionButtonContainer from '../../../components/generic/ActionButtonContainer';

export default function PrivacyDialog({ onClose, open }) {
	return (
		<BtDialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<DialogTitle>Privacy</DialogTitle>
			<DialogContent>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
				lorem neque, aliquam vel tristique scelerisque, viverra vitae
				velit. Sed commodo erat id pellentesque consectetur. Vestibulum
				rutrum eleifend mauris ac fermentum. Pellentesque ut iaculis
				ante. Suspendisse eu ipsum eros. Fusce faucibus, lectus sed
				ultricies convallis, quam felis scelerisque magna, sed tincidunt
				leo nunc non tellus. Mauris tempor aliquet porta. Integer
				volutpat id elit quis pulvinar. Mauris consequat, nibh ac
				tristique lobortis, tortor enim convallis massa, nec tempor
				nulla dolor non nibh. Nulla facilisi. Aenean molestie, tortor et
			</DialogContent>
			<DialogActions>
				<ActionButtonContainer>
					<Button
						onClick={onClose}
						variant="contained"
						disableElevation
					>
						Close
					</Button>
				</ActionButtonContainer>
			</DialogActions>
		</BtDialog>
	);
}
