import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Controller, useFormContext } from 'react-hook-form';
import {
	FormControl,
	FormHelperText,
	Radio,
	RadioGroup,
	FormControlLabel,
	Skeleton,
	FormLabel,
} from '@mui/material';

import useFormElRequired from '../../../hooks/useFormElRequired';

export default function BtFormRadio({
	disabled,
	isRequired,
	items,
	label,
	name,
	...other
}) {
	const {
		control,
		setValue,
		watch,
		loading,
		sending,
		validationSchema,
		formState: { errors },
	} = useFormContext() || { formState: {} };
	const radioValue = watch(name);
	const hasError = !!errors[name];

	const required = useFormElRequired(isRequired, validationSchema, name);

	const handleChange = useCallback(
		event => {
			setValue(name, event.target.value, {
				shouldValidate: true,
				shouldDirty: true,
				shouldTouch: true,
			});
		},
		[setValue, name]
	);

	const isDisabled = useMemo(() => loading || sending || disabled, [
		loading,
		sending,
		disabled,
	]);

	if (loading) {
		return (
			<Skeleton animation="wave" style={{ width: '100%', height: 45 }} />
		);
	}

	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<FormControl
					variant="standard"
					disabled={isDisabled}
					error={hasError}
				>
					<FormLabel>
						{!!label
							? `${label}${!required ? ' (optional)' : ''}`
							: undefined}
					</FormLabel>
					<RadioGroup
						value={radioValue || ''}
						onChange={handleChange}
					>
						{items?.map(item => (
							<FormControlLabel
								key={item.value}
								//value={item.value}
								disabled={isDisabled}
								{...other}
								control={
									<Radio
										{...field}
										color="primary"
										size="small"
										value={item.value}
									/>
								}
								label={item?.label}
							/>
						))}
					</RadioGroup>

					{/* {label && <InputLabel id={id}>{label}</InputLabel>}
					<Select
						{...field}
						labelId={id}
						variant="standard"
						size="small"
						label={
							!!label
								? `${label}${!required ? ' (optional)' : ''}`
								: undefined
						}
						color="primary"
						value={radioValue || ''}
						onChange={handleChange}
						disabled={isDisabled}
						{...other}
					>
						<MenuItem value="">
							<em>None</em>
						</MenuItem>
						{items?.map(item => (
							<MenuItem key={item.value} value={item.value}>
								{item.label}
							</MenuItem>
						))}
					</Select> */}
					{hasError && (
						<FormHelperText>{errors[name]?.message}</FormHelperText>
					)}
				</FormControl>
			)}
		/>
	);
}

BtFormRadio.propTypes = {
	disabled: PropTypes.bool,
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
				.isRequired,
			label: PropTypes.string.isRequired,
		})
	).isRequired,
	isRequired: PropTypes.bool,
};
