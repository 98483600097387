import { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';

import {
	Typography,
	Box,
	Button,
	IconButton,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TableContainer,
	Container,
	Tooltip,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';

import { InputBase, styled } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CodeOffIcon from '@mui/icons-material/CodeOff';
import CodeIcon from '@mui/icons-material/Code';
import InfoIcon from '@mui/icons-material/Info';

import BtPagination from '../../../../components/generic/BtPagination';

import Color from 'color';
import { dataSetStoreGet, dataSetStoreQueryGet } from '../../../../API';
import { parseSchema } from '../../../../utils/yup-ast';
import BtError from '../../../../components/generic/BtError';

const Input = styled(InputBase)(({ theme }) => ({
	backgroundColor: Color(theme.palette.text.solid)
		.fade(0.92)
		.toString(),
	borderRadius: '6px',
	height: '32px',
	marginLeft: -4,
	padding: '0 4px',
	cursor: 'pointer',
}));

function FieldNode({ selectableFieldTypes, nodeName, schema, onClick }) {
	const theme = useTheme();
	const propSelectable = selectableFieldTypes.includes(schema.type);

	if (schema.type === 'object') {
		const keys = Object.keys(schema.objectContent);
		return (
			<>
				{keys.map(key => (
					<FieldNode
						selectableFieldTypes={selectableFieldTypes}
						nodeName={key}
						key={key}
						schema={schema.objectContent[key]}
						onClick={childString => {
							if (childString) {
								onClick(key + '.' + childString);
							} else {
								onClick(key);
							}
						}}
					/>
				))}
			</>
		);
	} else if (schema.type === 'array') {
		if (schema.arrayContent.type === 'object') {
			return (
				<Box>
					{/* <Box>{nodeName}</Box> */}
					{propSelectable && (
						<Box
							sx={{
								cursor: 'pointer',
								':hover': {
									backgroundColor: theme.palette.action.hover,
								},
							}}
							onClick={() => {
								onClick(null);
							}}
						>
							<span>{nodeName}</span>
						</Box>
					)}
					{!propSelectable && <Box>{nodeName}</Box>}

					<Box sx={{ paddingLeft: '16px' }}>
						<FieldNode
							selectableFieldTypes={selectableFieldTypes}
							nodeName={nodeName}
							schema={schema.arrayContent}
							onClick={childString => {
								onClick(childString);
							}}
						/>
					</Box>
				</Box>
			);
		}
	}

	return (
		<>
			{propSelectable && (
				<Box
					sx={{
						cursor: 'pointer',
						':hover': {
							backgroundColor: theme.palette.action.hover,
						},
					}}
					onClick={() => {
						onClick(null);
					}}
				>
					<span>{nodeName}</span>
				</Box>
			)}
			{!propSelectable && <Box>{nodeName}</Box>}
		</>
	);
}

function FieldFinder({
	schema,
	selectableFieldTypes,
	fieldString,
	onFieldUpdate,
}) {
	const theme = useTheme();
	const [focused, setFocused] = useState(false);
	const [fieldArray, setFieldArray] = useState([]);

	useEffect(
		() => {
			const newFieldArray = fieldString.split('.');
			console.log('newFieldArray', newFieldArray);
			setFieldArray(newFieldArray);
		},
		[fieldString]
	);

	const onFieldSelect = newFieldString => {
		console.log(newFieldString);
		onFieldUpdate(newFieldString);
		setFocused(false);
	};

	if (!focused) {
		return (
			<Box
				sx={{
					backgroundColor: Color(theme.palette.text.solid)
						.fade(0.92)
						.toString(),
					borderRadius: '6px',
					height: '32px',
					marginLeft: 0,
					padding: '0 4px',
					cursor: 'pointer',
					minWidth: '100px',
					alignContent: 'center',
				}}
			>
				<Typography
					sx={{ widht: '100%', height: '100%' }}
					onClick={() => {
						setFocused(true);
					}}
				>
					{fieldString}
				</Typography>
			</Box>
		);
	} else {
		return (
			<Box
				sx={{
					backgroundColor: Color(theme.palette.text.solid)
						.fade(0.92)
						.toString(),
					borderRadius: '6px',
					marginLeft: 0,
					padding: '0 4px',
					minWidth: '100px',
				}}
			>
				<FieldNode
					nodeName={null}
					selectableFieldTypes={selectableFieldTypes}
					schema={schema}
					onClick={onFieldSelect}
				/>
			</Box>
		);
	}
}

function QueryOptions({ opType, schema, optionsUpdate }) {
	switch (opType) {
		case '$match':
			return (
				<MatchOptions schema={schema} optionsUpdate={optionsUpdate} />
			);
		case '$unwind':
			return (
				<UnwindOptions schema={schema} optionsUpdate={optionsUpdate} />
			);
		case '$replaceRoot':
			return (
				<ReplaceRootOptions
					schema={schema}
					optionsUpdate={optionsUpdate}
				/>
			);
		case '$project':
			return (
				<ProjectOptions schema={schema} optionsUpdate={optionsUpdate} />
			);
		default:
			return <Box sx={{ flexGrow: 1, padding: '0 4px' }} />;
	}
}

function notWrappedIn(string, char, secondaryChar) {
	if (string[0] !== char) {
		return `Missing beginning '${char}'`;
	}
	if (secondaryChar) {
		if (string[string.length - 1] !== secondaryChar) {
			return `Missing ending '${secondaryChar}'`;
		}
	} else {
		if (string[string.length - 1] !== char) {
			return `Missing ending '${char}'`;
		}
	}
	return null;
}

function inQuotationMarks(string) {
	const notInSingles = notWrappedIn(string, "'");
	const notInDoubles = notWrappedIn(string, '"');
	if (notInSingles && notInDoubles) {
		return false;
	}
	return true;
}

function stripQuotes(string) {
	if (string[0] === '"' && string[string.length - 1] === '"') {
		return string.substring(1, string.length - 1);
	}

	if (string[0] === "'" && string[string.length - 1] === "'") {
		return string.substring(1, string.length - 1);
	}

	return string;
}

function MatchOptions({ schema, optionsUpdate }) {
	const theme = useTheme();
	const [error, setError] = useState(false);
	const [errorString, setErrorString] = useState('');

	const handleValueUpdate = event => {
		const newValue = event.target.value;

		try {
			var query = {};

			//Check first and last char a {}
			const outerBraceMissing = notWrappedIn(newValue, '{', '}');
			if (outerBraceMissing) {
				throw new Error(outerBraceMissing);
			}

			const trimmedString = newValue
				.substring(1, newValue.length - 1)
				.trim();

			var inputString = trimmedString;
			var keyValuePairs = [];
			while (inputString.length > 0) {
				// Process key
				var key = '';
				if (inputString[0] === '"') {
					inputString = inputString.substring(1);
					key = inputString
						.substring(0, inputString.indexOf('"'))
						.trim();
					inputString = inputString
						.substring(inputString.indexOf('"') + 1)
						.trim();
				} else if (inputString[0] === "'") {
					inputString = inputString.substring(1);
					key = inputString
						.substring(0, inputString.indexOf("'"))
						.trim();
					inputString = inputString
						.substring(inputString.indexOf("'") + 1)
						.trim();
				} else {
					key = inputString
						.substring(0, inputString.indexOf(':'))
						.trim();

					if (key.includes('.')) {
						throw new Error(
							`Found prop name ${key} with a '.', if you're accessing an embedded document add quotation marks`
						);
					}

					inputString = inputString
						.substring(inputString.indexOf(':'))
						.trim();
				}

				//Remove the ':'
				inputString = inputString.substring(1);

				var value = '';
				var valueType = null;
				if (inputString[0] === '"') {
					inputString = inputString.substring(1);
					value = inputString
						.substring(0, inputString.indexOf('"'))
						.trim();
					inputString = inputString
						.substring(inputString.indexOf('"') + 2)
						.trim();
					valueType = 'string';
				} else if (inputString[0] === "'") {
					inputString = inputString.substring(1);
					value = inputString
						.substring(0, inputString.indexOf("'"))
						.trim();
					inputString = inputString
						.substring(inputString.indexOf("'") + 2)
						.trim();
					valueType = 'string';
				} else {
					if (inputString.indexOf(',') < 0) {
						value = inputString.trim();
						inputString = '';
					} else {
						value = inputString
							.substring(0, inputString.indexOf(','))
							.trim();
						inputString = inputString
							.substring(inputString.indexOf(',') + 1)
							.trim();
					}

					if (value === 'true') {
						valueType = 'boolean';
						value = true;
					} else if (value === 'false') {
						valueType = 'boolean';
						value = false;
					} else {
						value = parseInt(value);
						if (isNaN(value)) {
							throw new Error(
								'Value error, neither boolean or number but no quotation marks'
							);
						}
						valueType = 'number';
					}
				}

				// console.log('KEY:', key);
				// console.log('VALUE:', value);
				// console.log(inputString);

				keyValuePairs.push([key, value]);
			}

			// Split the string on the first ':'
			const stringArray = trimmedString.split(/:(.*)/s);
			stringArray.pop(); // Remove the value at the end

			keyValuePairs.forEach(keyValue => {
				if (keyValue[0][0] === '$') {
					throw new Error('Operators not allowd as root prop');
				}

				// Expect only one extra value
				if (keyValue.length > 2) {
					throw new Error('Too many values given');
				}

				// if (
				// 	!inQuotationMarks(keyValue[0]) &&
				// 	keyValue[0].includes('.')
				// ) {
				// 	throw new Error(
				// 		`Found prop name ${
				// 			keyValue[0]
				// 		} with a '.', if you're accessing an embedded document add quotation marks`
				// 	);
				// }

				query[stripQuotes(keyValue[0])] = keyValue[1];
			});

			setError(false);
			setErrorString('');
			optionsUpdate(query);
		} catch (error) {
			setError(true);
			setErrorString(error.message);
		}
	};

	return (
		<Box sx={{ flexGrow: 1, padding: '0 4px' }}>
			<Input
				sx={{ width: '100%' }}
				defaultValue={"{prop:'value'}"}
				onChange={handleValueUpdate}
				//disabled={disabled}
				error={true}
			/>
			{error && (
				<Typography
					variant="body2"
					sx={{ color: theme.palette.indicators.error.main }}
				>
					{errorString}
				</Typography>
			)}
		</Box>
	);
}

function ProjectOptions({ schema, optionsUpdate }) {
	const theme = useTheme();
	const [error, setError] = useState(false);
	const [errorString, setErrorString] = useState('');

	const handleValueUpdate = event => {
		const newValue = event.target.value;

		try {
			var query = {};

			//Check first and last char a {}
			const outerBraceMissing = notWrappedIn(newValue, '{', '}');
			if (outerBraceMissing) {
				throw new Error(outerBraceMissing);
			}

			const trimmedString = newValue
				.substring(1, newValue.length - 1)
				.trim();

			var projectionInclusive = null;
			var inputString = trimmedString;
			var keyValuePairs = [];
			while (inputString.length > 0) {
				// Process key
				var key = '';
				if (inputString[0] === '"') {
					inputString = inputString.substring(1);
					key = inputString
						.substring(0, inputString.indexOf('"'))
						.trim();
					inputString = inputString
						.substring(inputString.indexOf('"') + 1)
						.trim();
				} else if (inputString[0] === "'") {
					inputString = inputString.substring(1);
					key = inputString
						.substring(0, inputString.indexOf("'"))
						.trim();
					inputString = inputString
						.substring(inputString.indexOf("'") + 1)
						.trim();
				} else {
					key = inputString
						.substring(0, inputString.indexOf(':'))
						.trim();

					if (key.includes('.')) {
						throw new Error(
							`Found prop name ${key} with a '.', if you're accessing an embedded document add quotation marks`
						);
					}

					inputString = inputString
						.substring(inputString.indexOf(':'))
						.trim();
				}

				//Remove the ':'
				inputString = inputString.substring(1);

				var value = '';
				if (inputString[0] === '"') {
					throw new Error('Project values must be a 1 or 0');
				} else if (inputString[0] === "'") {
					throw new Error('Project values must be a 1 or 0');
				} else {
					if (inputString.indexOf(',') < 0) {
						value = inputString.trim();
						inputString = '';
					} else {
						value = inputString
							.substring(0, inputString.indexOf(','))
							.trim();
						inputString = inputString
							.substring(inputString.indexOf(',') + 1)
							.trim();
					}

					value = parseInt(value);
					if (isNaN(value)) {
						throw new Error('Project values must be a 1 or 0');
					}
					console.log('VALUE', value);
					if (value !== 1 && value !== 0) {
						throw new Error('Project values must be a 1 or 0!!!');
					}
				}

				if (projectionInclusive === null) {
					projectionInclusive = value === 1;
				} else {
					if (projectionInclusive || value === 0) {
						throw new Error(
							'A projection must be either fully inclusive or exclusive'
						);
					}
				}

				// console.log('KEY:', key);
				// console.log('VALUE:', value);
				// console.log(inputString);

				keyValuePairs.push([key, value]);
			}

			// Split the string on the first ':'
			const stringArray = trimmedString.split(/:(.*)/s);
			stringArray.pop(); // Remove the value at the end

			keyValuePairs.forEach(keyValue => {
				if (keyValue[0][0] === '$') {
					throw new Error('Operators not allowd as root prop');
				}

				// Expect only one extra value
				if (keyValue.length > 2) {
					throw new Error('Too many values given');
				}

				// if (
				// 	!inQuotationMarks(keyValue[0]) &&
				// 	keyValue[0].includes('.')
				// ) {
				// 	throw new Error(
				// 		`Found prop name ${
				// 			keyValue[0]
				// 		} with a '.', if you're accessing an embedded document add quotation marks`
				// 	);
				// }

				query[stripQuotes(keyValue[0])] = keyValue[1];
			});

			setError(false);
			setErrorString('');
			optionsUpdate(query);
		} catch (error) {
			setError(true);
			setErrorString(error.message);
		}
	};

	return (
		<Box sx={{ flexGrow: 1, padding: '0 4px' }}>
			<Input
				sx={{ width: '100%' }}
				defaultValue={"{prop:'value'}"}
				onChange={handleValueUpdate}
				//disabled={disabled}
				error={true}
			/>
			{error && (
				<Typography
					variant="body2"
					sx={{ color: theme.palette.indicators.error.main }}
				>
					{errorString}
				</Typography>
			)}
		</Box>
	);
}

function UnwindOptions({ schema, optionsUpdate }) {
	const theme = useTheme();
	const [error, setError] = useState(false);
	const [errorString, setErrorString] = useState('');

	const handleValueUpdate = event => {
		const newValue = event.target.value;

		// The field needs to be prefixed with '$'
		optionsUpdate('$' + newValue);
	};

	return (
		<>
			<Box sx={{ flexGrow: 1, padding: '0 4px' }}>
				<Input
					sx={{ width: '100%' }}
					defaultValue={'array_name'}
					onChange={handleValueUpdate}
					//disabled={disabled}
					error={true}
				/>
				{error && (
					<Typography
						variant="body2"
						sx={{ color: theme.palette.indicators.error.main }}
					>
						{errorString}
					</Typography>
				)}
			</Box>
		</>
	);
}

function ReplaceRootOptions({ schema, optionsUpdate }) {
	const theme = useTheme();
	const [error, setError] = useState(false);
	const [errorString, setErrorString] = useState('');

	const handleValueUpdate = event => {
		const newValue = event.target.value;

		// The field needs to be prefixed with '$'
		optionsUpdate({ newRoot: '$' + newValue });
	};

	return (
		<>
			<Box sx={{ flexGrow: 1, padding: '0 4px' }}>
				<Input
					sx={{ width: '100%' }}
					defaultValue={'object_name'}
					onChange={handleValueUpdate}
					//disabled={disabled}
					error={true}
				/>
				{error && (
					<Typography
						variant="body2"
						sx={{ color: theme.palette.indicators.error.main }}
					>
						{errorString}
					</Typography>
				)}
			</Box>
		</>
	);
}

function QueryStage({
	schema,
	queryStage,
	removeStage,
	onUpdateStage,
	disabled,
	pipelineIndex,
}) {
	const theme = useTheme();
	const [queryType, setQueryType] = useState(null);
	const [fieldPath, setFieldPath] = useState('');
	const [value, setValue] = useState('');
	const [options, setOptions] = useState(null);
	const [stageDisabled, setStageDisabled] = useState(false);

	useEffect(
		() => {
			console.log(queryType, fieldPath, value);
			if (queryType) {
				if (stageDisabled) {
					onUpdateStage(pipelineIndex, null);
				} else {
					var newQuery = {};
					newQuery[queryType] = options;
					onUpdateStage(pipelineIndex, newQuery);
				}
			}
		},
		[queryType, options, stageDisabled]
	);

	const handleQueryTypeChange = event => {
		const newQueryType = event.target.value;
		setQueryType(newQueryType);
	};

	const onFieldUpdate = newFieldPath => {
		setFieldPath(newFieldPath);
	};

	const handleValueUpdate = event => {
		const newValue = event.target.value;
		setValue(newValue);
	};

	const handleOptionsUpdate = options => {
		setOptions(options);
	};

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				width: '100%',
				padding: '0 4px',
			}}
		>
			<Box
				sx={{ flexGrow: 0, padding: '4px 0' }}
				component="th"
				scope="row"
				align="center"
			>
				<Tooltip title="Delete Stage">
					<IconButton
						size="small"
						onClick={() => {
							removeStage(pipelineIndex);
						}}
						disabled={disabled}
						sx={{
							':hover': {
								color: theme.palette.indicators.error.main,
							},
						}}
					>
						<CancelIcon />
					</IconButton>
				</Tooltip>
			</Box>
			<Box
				sx={{ flexGrow: 0, padding: '4px 0' }}
				component="th"
				scope="row"
				align="center"
			>
				<Tooltip title="Disable Stage">
					<IconButton
						size="small"
						onClick={() => {
							setStageDisabled(!stageDisabled);
						}}
						disabled={disabled}
						sx={{
							':hover': {
								color: theme.palette.indicators.warning.main,
							},
						}}
					>
						{stageDisabled && <CodeIcon />}
						{!stageDisabled && <CodeOffIcon />}
					</IconButton>
				</Tooltip>
			</Box>
			<Box sx={{ flexGrow: 0, padding: '0 4px' }}>
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={queryType ? queryType : ''}
					label="Age"
					onChange={handleQueryTypeChange}
					variant="standard"
					//sx={{ width: '100%' }}
					sx={{ minWidth: '200px' }}
					disabled={disabled}
				>
					<MenuItem value={'$match'}>Match Value</MenuItem>
					<MenuItem value={'$project'}>Project Props</MenuItem>
					<MenuItem value={'$unwind'}>Unwind Array</MenuItem>
					<MenuItem value={'$replaceRoot'}>
						Replace Root Document
					</MenuItem>
					<MenuItem value={'$limit'}>Limit Document Count</MenuItem>
					<MenuItem value={'$sort'}>Sort By Value</MenuItem>
				</Select>
			</Box>
			<QueryOptions
				schema={schema}
				opType={queryType}
				optionsUpdate={handleOptionsUpdate}
			/>
			{/* <Box
				sx={{ flexGrow: 0, padding: '4px 0' }}
				component="th"
				scope="row"
				align="center"
			>
				<Tooltip title="Delete Stage">
					<IconButton
						size="small"
						onClick={() => {
							removeStage(pipelineIndex);
						}}
						disabled={disabled}
						sx={{
							':hover': {
								color: theme.palette.indicators.error.main,
							},
						}}
					>
						<InfoIcon />
					</IconButton>
				</Tooltip>
			</Box> */}
		</Box>
	);
}

export default function DataSetQuery({ dataSet, dataSetUuid }) {
	const [query, setQuery] = useState(null);

	const [dataOffset, setDataOffset] = useState(0);
	const [dataSetRecords, setDataSetRecords] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [apiError, setApiError] = useState(false);
	const [pageSize, setPageSize] = useState(10);
	const [recordCount, setRecordCount] = useState(0);
	const [schema, setSchema] = useState(null);
	const disabled = false;

	const [queryPipleline, setQueryPipeline] = useState([]);
	const [trimmedPipeline, setTrimmedPipeline] = useState([]);

	useEffect(
		() => {
			if (dataSet) {
				// Parse the dataset schema
				setSchema(parseSchema(dataSet.schema));

				// Set an initial empty query
				setQueryPipeline([{ $match: {} }]);
				// setQueryPipeline([
				// 	{ $unwind: 'retailers' },
				// 	{ $unwind: 'store_catagories' },
				// ]);
			}
		},
		[dataSet]
	);

	const loadData = useCallback(
		async (newQuery, onFinish) => {
			// const newDataRecords = await dataSetStoreGet({
			// 	dataSetUuid: dataSetUuid,
			// 	offset: dataOffset,
			// 	limit: pageSize,
			// });

			// const newDataRecords = await dataSetStoreQueryGet({
			// 	dataSetUuid: dataSetUuid,
			// 	query: { query: [{ $match: { 'First Name': 'Paul' } }] },
			// });

			console.log(newQuery);

			try {
				const newDataRecords = await dataSetStoreQueryGet({
					dataSetUuid: dataSetUuid,
					query: { query: newQuery },
				});

				//console.log(newDataRecords);

				//setDataSetRecords(newDataRecords.records);
				setDataSetRecords(newDataRecords);
				setRecordCount(newDataRecords.totalRecordCount);
				setApiError(false);
			} catch (error) {
				setApiError(true);
			}
			onFinish?.();
		},
		[dataOffset, dataSetUuid, pageSize]
	);

	useEffect(
		() => {
			if (dataSetUuid) {
				setIsLoading(true);
			}
		},
		[dataOffset, dataSetUuid, pageSize, loadData]
	);

	useEffect(
		() => {
			if (queryPipleline) {
				setIsLoading(true);

				const newTrimmedQuery = _.filter(queryPipleline, stage => {
					return stage !== null;
				});

				setTrimmedPipeline(newTrimmedQuery);
				loadData(newTrimmedQuery, () => setIsLoading(false));
			}
		},
		[queryPipleline]
	);

	const removeStage = pipelineIndex => {
		const updatedPipeline = [...queryPipleline];
		updatedPipeline.splice(pipelineIndex, 1);
		setQueryPipeline(updatedPipeline);
	};

	const handleAddStage = () => {
		setQueryPipeline([...queryPipleline, { op: null }]);
	};

	const onStageUpdate = (index, updatedQueryStage) => {
		var updatedPipeline = [...queryPipleline];
		updatedPipeline[index] = updatedQueryStage;
		setQueryPipeline(updatedPipeline);
	};

	return (
		<>
			<Typography variant="h4">Data Set Query Builder</Typography>

			{queryPipleline.map((queryStage, index) => (
				<QueryStage
					schema={schema}
					pipelineIndex={index}
					key={index}
					queryStage={queryStage}
					removeStage={removeStage}
					onUpdateStage={onStageUpdate}
					disabled={disabled}
				/>
			))}

			<Button
				variant="outlined"
				onClick={handleAddStage}
				disabled={disabled}
			>
				Add Stage
			</Button>

			<Box>
				<span>{JSON.stringify(trimmedPipeline, null, 4)}</span>
			</Box>

			{/* <pre>{JSON.stringify(schema, null, 4)}</pre> */}

			{apiError && (
				<BtError
					title="Server Error"
					description="There was an error executing the query pipeline. Please check the query pipeline."
					variant={'server'}
				/>
			)}

			{!apiError && (
				<>
					{dataSetRecords && (
						<>
							{dataSetRecords.map((record, index) => (
								<Box key={index}>
									<pre>{JSON.stringify(record, null, 4)}</pre>
								</Box>
							))}
						</>
					)}
					<BtPagination
						dataOffset={dataOffset}
						pageSize={pageSize}
						pageSizeOptions={[5, 10, 25, 50]}
						onOffsetUpdate={setDataOffset}
						onPageSizeUpdate={newSize => {
							setPageSize(newSize);
						}}
						recordCount={recordCount}
					/>
				</>
			)}
		</>
	);
}
