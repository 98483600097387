import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import useBreakpointStatuses from '../../../../hooks/useBreakpointStatuses';

export const useTableLayout = ({ columns, containerResponsive, ref }) => {
	const breakpointStatuses = useBreakpointStatuses();
	const [containerBreakpoints, setContainerBreakpoints] = useState();
	const [showNumberOnly, setShowNumberOnly] = useState(false);

	const initContainerBreakpoints = useCallback(
		() => {
			if (ref.current) {
				if (ref.current.offsetWidth < 245) {
					setShowNumberOnly(true);
				} else if (_.inRange(ref.current.offsetWidth, 246, 300)) {
					setContainerBreakpoints({
						sx: true,
						sm: true,
						md: false,
						lg: false,
						xl: false,
					});
				} else if (_.inRange(ref.current.offsetWidth, 301, 520)) {
					setContainerBreakpoints({
						sx: true,
						sm: true,
						md: true,
						lg: false,
						xl: false,
					});
				} else {
					setContainerBreakpoints({
						sx: true,
						sm: true,
						md: true,
						lg: true,
						xl: false,
					});
				}
			}
		},
		[ref]
	);

	useEffect(
		() => {
			if (containerResponsive) {
				initContainerBreakpoints();
			}
		},
		[containerResponsive, initContainerBreakpoints]
	);

	const redactedColumns = useMemo(
		() => {
			if (containerResponsive && showNumberOnly) return;

			const brStatuses =
				containerResponsive && containerBreakpoints
					? containerBreakpoints
					: breakpointStatuses;

			return (columns || []).filter(
				({ minBreakpoint }) =>
					!minBreakpoint || brStatuses[minBreakpoint]
			);
		},
		[
			breakpointStatuses,
			columns,
			containerBreakpoints,
			containerResponsive,
			showNumberOnly,
		]
	);

	return {
		breakpointStatuses,
		containerBreakpoints,
		redactedColumns,
		showNumberOnly,
	};
};

export default useTableLayout;
