const { isDocument } = require('../../../utils.js');
const {
	ERROR,
	setOperator,
	evaluateExpression,
	OPERATION_MODE,
} = require('../../expression.js');

/**
 * @type {ExpressionAccumulatorFunction<number , number>}
 */
function operation(context, args, options) {
	const arr = !Array.isArray(args) ? [args] : args;

	if (arr.length !== 1) {
		throw new Error(ERROR.INVALID_NUMBER_ARGS('$sum', 1, 1));
	}

	const arg = arr[0];

	let acc = context.fieldValues[0] || 0;

	const value =
		evaluateExpression(
			{ ...context, operationMode: OPERATION_MODE.AGGREGATE },
			arg
		) ?? 0;

	if (typeof value !== 'number') {
		return acc;
	}

	acc += value;

	context.fieldValues[0] = acc;

	return acc;
}

setOperator('$sum', operation, [OPERATION_MODE.ACCUMULATE]);

setOperator(
	'$count',
	/**
	 * @type {ExpressionAccumulatorFunction<number , number>}
	 */
	(context, args, options) => {
		if (!isDocument(args) && Object.keys(args).length === 0) {
			throw new Error(
				ERROR.INVALID_OPERATOR_ARGUMENT(
					'$count',
					'to be passed an empty object as its only argument'
				)
			);
		}

		return operation(context, 1, options);
	},
	[OPERATION_MODE.ACCUMULATE]
);
