import { apiClient } from '../api_client';

import { deviceTypeGetListSchema } from '../validations/deviceTypeValidation';

//========================================================================================================
async function channelDataGet({ deviceId, channelName, startTime, endTime }) {
	try {
		const req_url = 'channel_data/' + deviceId;
		const params = {
			channel: channelName,
			start_time: startTime,
			end_time: endTime,
		};

		const response = await apiClient({
			method: 'get',
			url: req_url,
			params: params,
		});
		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				await deviceTypeGetListSchema.validate(response.device_types);
				// TODO: change the data format
				return response.data_records;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

export { channelDataGet };
