import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { InsightComponent } from './InsightComponent';
import { visualisationSchema } from '../../../../API/validations/insightVisualizationsValidation';
import { Box, IconButton, Tooltip, Typography, styled } from '@mui/material';
import {
	ContainerBox,
	EditButtons,
	HiddenRx,
	TitleBox,
	TitleWithTooltip,
	VisibleRx,
	VisualisationContainer,
} from './components';
import { mdiFullscreen } from '@mdi/js';
import { BtIconButton, XS } from '../../../../components/generic/BtIconButton';
import BtPopper from '../../../../components/generic/BtPopper';
import { BtPopperMenu } from '../../../../components/generic/BtPopperContent';
import { FileDownload } from '@mui/icons-material';
import { exportComponentAsPNG } from 'react-component-export-image';
import { resolveVisualisationPalette } from '../VisComponents/Utils/resolveVisualisationPalette';

const ComponentContainerBox = styled(Box)(({ theme, editing }) => ({
	backgroundColor: theme.palette.background.paper,
	borderRadius: 8,
	height: '100%',
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'column',
	padding: '10px',

	'&:hover': {
		// backgroundColor: theme.palette.primary.main + '10',
		outline: editing && `solid 2px ${theme.palette.action.hoverBorder}`,
		cursor: editing && 'grab',
	},

	'&:hover #insightComponentButtons': {
		// visibility: !disabled && 'visible',
		visibility: 'visible',
	},
}));

const handleCsvClick = (visOptions, componentData, component) => {
	var params = visOptions.params.map(param => {
		return param.value;
	});

	if (visOptions?.ordinalAxis?.param) {
		params = [visOptions.ordinalAxis.param.value, ...params];
	}

	let headerLine = visOptions?.ordinalAxis?.param?.label || ''; //'start_time';
	for (const param of visOptions.params) {
		if (headerLine !== '') {
			headerLine = headerLine + ',';
		}
		headerLine = headerLine + param.label;
	}
	headerLine = headerLine + `\r\n`;

	var csvData = componentData.map(dataPoint => {
		// TODO: Change the start time value here to the correct param
		let newLine = ''; //dataPoint.start_time;

		for (const param of params) {
			// If not first param add a ','
			if (newLine !== '') {
				newLine = newLine + ',';
			}

			// If a values exists add it and remove any commas
			if (dataPoint[param] !== undefined) {
				if (typeof dataPoint[param] === 'string') {
					newLine = newLine + dataPoint[param].replace(',', '');
				} else {
					newLine = newLine + dataPoint[param];
				}
			}
		}
		return `${newLine}\r\n`;
	});

	// Add the header line to the beginning
	csvData.unshift(headerLine);

	const element = document.createElement('a');
	const file = new Blob(csvData, { type: 'text/plain' });
	element.href = URL.createObjectURL(file);
	element.download = `${component.title}.csv`;
	document.body.appendChild(element); // Required for this to work in FireFox
	element.click();
};
const InsightComponentContainer = ({
	// const InsightComponent = ({
	component,
	onRemoveItem,
	onFullscreenClick,
	onEditClick,
	hideTitle,
	editEnabled,
	exportEnabled,
	palette,
	setDataSample,
	paletteConfig,
	collectionPalettes,
}) => {
	const componentRef = useRef();
	const [componentData, setComponentData] = useState(null);
	const [visOptions, setVisOptions] = useState(null);
	const [showDataRx, setShowDataRx] = useState(false);

	const [exportPopperOpen, setExportPopperOpen] = useState(false);

	const visualisationPalette = useMemo(
		() =>
			resolveVisualisationPalette(
				visOptions?.params,
				palette,
				paletteConfig,
				collectionPalettes,
				component.palette?.uuid
			),
		[collectionPalettes, component, palette, paletteConfig, visOptions]
	);

	const showEditButtons = useMemo(
		() => onEditClick && onRemoveItem && editEnabled,
		[editEnabled, onEditClick, onRemoveItem]
	);
	const showExportButton = useMemo(() => exportEnabled && !editEnabled, [
		exportEnabled,
		editEnabled,
	]);
	const showFullscreenButton = useMemo(
		() => onFullscreenClick && !editEnabled,
		[onFullscreenClick, editEnabled]
	);

	const isValid = useMemo(
		() => {
			const isValidTest = visualisationSchema.isValidSync(component);

			try {
				// console.log(
				// 	'--> component definition is valid <---',
				// 	visualisationSchema.validateSync(component),
				// 	{ isValidTest }
				// );
				visualisationSchema.validateSync(component);
			} catch (error) {
				// console.log(error);
				console.log('--> component definition is NOT valid <---', {
					component,
					isValidTest,
					error,
				});
			}

			return isValidTest;
		},
		[component]
	);

	// useEffect(() => console.log({ component }), [component]);

	if (!isValid) {
		return (
			<ContainerBox>
				<Typography>Error</Typography>
			</ContainerBox>
		);
	}

	const Buttons = () => {
		return (
			<Box
				sx={{
					visibility: 'hidden',
					display: 'flex',
				}}
				id="insightComponentButtons"
			>
				{showEditButtons && (
					<EditButtons
						onClickEdit={onEditClick(component.uuid)}
						onClickDelete={onRemoveItem(component.uuid)}
					/>
				)}
				{showExportButton && (
					<BtPopper
						content={
							<BtPopperMenu
								content={[
									{
										label: 'Download CSV',
										onClick: () =>
											handleCsvClick(
												visOptions,
												componentData,
												component
											),
										key: 'download-csv',
									},
									{
										label: 'Download as PNG',
										onClick: () => {
											exportComponentAsPNG(componentRef);
										},
										key: 'download-png',
									},
								]}
							/>
						}
						variant="menu"
						placement="bottom-end"
						onClose={() => {
							setExportPopperOpen(false);
						}}
						open={exportPopperOpen}
					>
						<Tooltip title="Export">
							<IconButton
								aria-label="export"
								size="small"
								style={{ flexGrow: 0 }}
								onClick={() => {
									setExportPopperOpen(true);
								}}
							>
								<FileDownload fontSize="inherit" />
							</IconButton>
						</Tooltip>
					</BtPopper>
				)}
				{showFullscreenButton && (
					<BtIconButton
						size={XS}
						tooltip="Fullscreen"
						onClick={onFullscreenClick(component.uuid)}
						icon={mdiFullscreen}
					/>
				)}
			</Box>
		);
	};

	return (
		<ComponentContainerBox editing={+editEnabled}>
			<Box sx={{ position: 'absolute', right: 8, top: 8 }}>
				{showDataRx ? <VisibleRx /> : <HiddenRx />}
			</Box>

			<TitleBox>
				{!hideTitle && (
					<TitleWithTooltip
						title={component.title}
						subtitle={component.subtitle}
					/>
				)}
				<Buttons />
			</TitleBox>
			<VisualisationContainer ref={componentRef}>
				<InsightComponent
					component={component}
					componentData={componentData}
					palette={visualisationPalette}
					setComponentData={setComponentData}
					setVisOptions={setVisOptions}
					setShowDataRx={setShowDataRx}
					visOptions={visOptions}
					setDataSample={setDataSample}
				/>
			</VisualisationContainer>
		</ComponentContainerBox>
	);
};

InsightComponentContainer.propTypes = {
	component: PropTypes.object.isRequired,
	onRemoveItem: PropTypes.func,
	onFullscreenClick: PropTypes.func,
	onEditClick: PropTypes.func,
	hideTitle: PropTypes.bool,
	editEnabled: PropTypes.bool,
	exportEnabled: PropTypes.bool,
	palette: PropTypes.array.isRequired,
	setDataSample: PropTypes.func,
	paletteConfig: PropTypes.array,
	collectionPalettes: PropTypes.array,
};

export { InsightComponentContainer };
