import { Input } from '@mui/material';
import FilterIcon from '@mui/icons-material/FilterList';
import { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export const BtFilterSelectInput = memo(
	({ error, disabled, onChange, placeholderText }) => {
		const [searchTerm, setSearchTerm] = useState('');

		useEffect(
			() => {
				onChange(searchTerm);
			},
			[onChange, searchTerm]
		);

		return (
			<Input
				fullWidth
				placeholder={placeholderText}
				autoFocus
				startAdornment={<FilterIcon style={{ marginRight: '0.2em' }} />}
				value={searchTerm}
				onChange={event => setSearchTerm(event.target.value)}
				style={{ margin: '1em 0 0.2em 0' }}
				// error={filteredTargetTypes.length === 0}
				// disabled={sending}
				error={error}
				disabled={disabled}
			/>
		);
	}
);

BtFilterSelectInput.propTypes = {
	placeholderText: PropTypes.string,
};

BtFilterSelectInput.defaultProps = {
	placeholderText: 'Filter Options',
};
