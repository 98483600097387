// Orders
export const ASC = 'asc';
export const DESC = 'desc';

// Table Settings
export const DEFAULT_PAGE_SIZES = [5, 10, 25, 50];

// Exactness
export const LOOSE = 'loose';
export const STANDARD = 'standard';
export const STRICT = 'strict';
