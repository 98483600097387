import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { useNavContext } from '../../context/ContextManager';
import { useSnackbar } from 'notistack';

// Data for landing page selection table and contextual navigation
import beacon_admin_options from './BeaconAdminOptions';
import beacon_admin_nav_item from './BeaconAdminNavItem';
import beacon_admin_breadcrumbs from './BeaconAdminBreadcrumbs';

import useFetch from '../../hooks/useFetch';
import useNavRedactor from '../../hooks/useNavRedactor';

import AddIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { BtFormContainer } from '../../components/generic/forms';
import BtLoading from '../../components/generic/BtLoading';
import BtSelectionTable from '../../components/generic/BtSelectionTable';
import BtTableSkeleton from '../../components/generic/BtTableSkeleton';
import { IconButton } from '@mui/material';
import { Delete } from 'mdi-material-ui';

import { platformAdminUserGetList } from '../../API';

const tableColumns = [
	{ field: 'status', text: 'Status' },
	{ field: 'user_name', text: 'User Name' },
	{ field: 'email', text: 'e-mail' },
	{ field: 'last_login_timestamp', text: 'Last Log In' },
];

export default function BeaconAdminUsers() {
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	// navContext contextual navigation items
	const { setContextualNav } = useNavContext();
	// navContext breadcrumbs
	const { setBreadcrumbs } = useNavContext();
	const redactNav = useNavRedactor();

	const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
	const [orgToRemove, setOrgToRemove] = useState(null);

	const [newOrganisationDialog, setNewOrganisationDialog] = useState(false);

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				...beacon_admin_breadcrumbs,
				{ text: 'Organisations', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	const redactedInsightsOptions = useMemo(
		() => redactNav(beacon_admin_options),
		[redactNav]
	);

	//Boilerplate for future implementation set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([
				...beacon_admin_nav_item,
				...redactedInsightsOptions,
			]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	const { loading, data: userList, error, request: getUserList } = useFetch(
		platformAdminUserGetList
	);

	useEffect(
		() => {
			getUserList();
		},
		[getUserList]
	);

	return (
		<>
			<BtFormContainer title="Platform Users" maxWidth="lg">
				<BtLoading
					loading={loading}
					LoadingComponent={<BtTableSkeleton hasActionButton />}
				>
					<BtSelectionTable
						title={
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									flexWrap: 'wrap',
									gap: 10,
								}}
							>
								<Typography
									sx={{
										fontWeight: 'bold',
										padding: '4px 0',
									}}
									variant="h5"
								>
									Platform Users
								</Typography>
								{/* <Button
									variant="contained"
									color="secondary"
									onClick={() =>
										setNewOrganisationDialog(true)
									}
									startIcon={<AddIcon />}
									disableElevation
								>
									Create Organisation
								</Button> */}
							</div>
						}
						subject="collections"
						columns={tableColumns}
						data={(userList || []).map(
							({
								uuid,
								status,
								user_name,
								email,
								last_login_timestamp,
							}) => ({
								uuid: uuid,
								status: status,
								user_name: user_name,
								email: email,
								last_login_timestamp: format(
									new Date(last_login_timestamp || 0),
									'dd MMM yyyy, hh:mm a'
								),

								//route: `/BeaconAdmin/Organisation/${uuid}`,
							})
						)}
					/>
				</BtLoading>
			</BtFormContainer>
		</>
	);
}
