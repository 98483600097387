import { mapPaletteSelectOptions } from '../../../../../../components/paletteManager/utils/functions';

/**
 * Gets the tags from the tag data and adds to the palette definition
 * @param {*} paletteArray the array of colour objects defined as 'palette' within palette definition
 * @param {*} tagData the tag data defined in the collection palette
 * @returns
 */
const injectTags = (paletteArray, tagData) => {
	const result = paletteArray.map(colourObj => {
		const colour = { ...colourObj };
		colour.tags =
			tagData.colours.find(({ uuid }) => uuid === colourObj.uuid)?.tags ||
			[];

		return colour;
	});
	return result;
};

/**
 * Adds tags from the paletteConfig array and adds them to the orgPalettes array
 * @param {*} paletteConfig
 * @param {*} orgPalettes
 * @returns
 */
const tagPalettes = (paletteConfig, orgPalettes) => {
	// extract the palette uuids
	const collectionPalettesUuids = paletteConfig.map(({ uuid }) => uuid);

	// merge the palette and tags data
	const taggedPalettes = collectionPalettesUuids.map(pUuid => {
		// get the palette
		const paletteObj = {
			...orgPalettes.find(({ uuid }) => uuid === pUuid),
		};
		// pass the required data to injectTags function
		paletteObj.palette = injectTags(
			paletteObj.palette,
			paletteConfig.find(({ uuid }) => uuid === pUuid)
		);
		return paletteObj;
	});

	return taggedPalettes;
};

const initSelectedOptions = (orgPalettes, paletteConfig) => {
	const mappedOptions = mapPaletteSelectOptions(orgPalettes);

	const collectionPalettesUuids = paletteConfig.map(({ uuid }) => uuid);

	const result = mappedOptions.map(o => {
		const option = { ...o };
		option.selected = collectionPalettesUuids.includes(o.id) || false;
		return option;
	});
	return result;
};

/**
 * Updates the paletteConfig array with the provided tags update
 * @param {*} paletteConfig
 * @param {*} tagsUpdate
 * @returns
 */
const updatePaletteConfigTags = (paletteConfig, tagsUpdate) => {
	// get the index of the palette to update
	const configIndexToUpdate = paletteConfig.findIndex(
		({ uuid }) => uuid === tagsUpdate.paletteUuid
	);
	// get the palette config to update
	const configPaletteUpdate = {
		...paletteConfig[configIndexToUpdate],
	};

	// get the colour index to update
	const colourIndexToUpdate = configPaletteUpdate.colours.findIndex(
		({ uuid }) => uuid === tagsUpdate.update.uuid
	);

	let coloursUpdate = [...configPaletteUpdate.colours];
	// update the colour object with the new tags
	// if no index is found the tag is new so we push rather than splice the update
	if (colourIndexToUpdate === -1) {
		coloursUpdate.push(tagsUpdate.update);
	} else {
		coloursUpdate.splice(colourIndexToUpdate, 1, tagsUpdate.update);
	}

	// update the colours array
	configPaletteUpdate.colours = coloursUpdate;

	// update the config array with updated palette
	const configUpdate = [...paletteConfig];
	configUpdate.splice(configIndexToUpdate, 1, configPaletteUpdate);

	return configUpdate;
};

/**
 * Updates the paletteConfig array with palettes added/removed as per
 * selectedPalettes
 * @param {*} selectedPalettes
 * @param {*} paletteConfig
 * @returns
 */
const updatePaletteConfigPalettes = (selectedPalettes, paletteConfig) => {
	// uuids of all the collection palettes after selection
	const nextPalettesUuids = selectedPalettes.map(p => p.uuid);

	// strips any palettes not required
	const removePalettes = (uuidsToRetain, config) => {
		return config.filter(p => uuidsToRetain.includes(p.uuid));
	};

	// adds palettes as required
	const addPalettes = (configUpdate, nextPalettesUuids) => {
		let newItems = [];

		nextPalettesUuids.forEach(paletteUuid => {
			if (!configUpdate.map(p => p.uuid).includes(paletteUuid))
				newItems.push({ uuid: paletteUuid, colours: [] });
		});

		return [...configUpdate, ...newItems];
	};

	// first, remove palettes
	let configUpdate = removePalettes(nextPalettesUuids, paletteConfig);

	// if done return, otherwise add the required palettes
	if (selectedPalettes.length !== configUpdate.length) {
		configUpdate = addPalettes(configUpdate, nextPalettesUuids);
	}
	return configUpdate;
};

export {
	tagPalettes,
	initSelectedOptions,
	updatePaletteConfigTags,
	updatePaletteConfigPalettes,
};
