import { styled } from '@mui/material';
import { Box } from '@mui/system';

const OpenInBox = styled(Box)(() => ({
	width: '42px',
	display: 'flex',
	alignItems: 'center',
}));

const InputWithExtrasBox = styled(Box)(() => ({
	width: '100%',
	display: 'flex',
	justifyContent: 'space-between',
	// gap: '20px',
}));

export { OpenInBox, InputWithExtrasBox };
