import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { memo } from 'react';
import { styled } from '@mui/styles';
import { BtIconButton, MEDIUM, SMALL, XS } from './BtIconButton';

const BtIconButtonContainer = styled(Box)(({ style }) => ({
	display: 'flex',
	gap: '5px',
	...style,
}));

/**
 * @params buttonGroupAttributes, containerStyle
 */
const BtIconButtonGroup = memo(function ButtonGroup({
	buttonGroupAttributes,
	containerStyle,
}) {
	return (
		<BtIconButtonContainer style={containerStyle}>
			{buttonGroupAttributes.map(button => (
				<BtIconButton key={`${button.tooltip}-button`} {...button} />
			))}
		</BtIconButtonContainer>
	);
});

const buttonShape = PropTypes.shape({
	color: PropTypes.string,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	icon: PropTypes.string.isRequired,
	size: PropTypes.oneOf([XS, SMALL, MEDIUM]),
	style: PropTypes.object,
	tooltip: PropTypes.string,
	type: PropTypes.oneOf(['submit']),
});

BtIconButtonGroup.propTypes = {
	buttonGroupAttributes: PropTypes.arrayOf(buttonShape).isRequired,
	containerStyle: PropTypes.object,
};

export { BtIconButtonGroup, BtIconButtonContainer };
