import { useCallback } from 'react';

export default function useComments(comments) {
	const addComment = useCallback(
		(newComment, callback) => {
			const newComments = [...comments, newComment];

			callback(newComments);
		},
		[comments]
	);

	const deleteComment = useCallback(
		(commentToDelete, callback) => {
			const { uuid: commentUuid } = commentToDelete;

			const reducedComments = comments.filter(
				({ uuid }) => uuid !== commentUuid
			);

			callback(reducedComments);
		},
		[comments]
	);

	const editComment = useCallback(
		(editedComment, callback) => {
			const { uuid: commentUuid } = editedComment;
			console.log('COMMENT UUID', commentUuid);

			let commentsTemp = [...comments];

			const index = commentsTemp.findIndex(
				({ uuid }) => uuid === commentUuid
			);

			if (index === -1) {
				throw new Error(
					'Attempted to update a comment that does not exist.'
				);
			}

			commentsTemp[index] = editedComment;

			callback(commentsTemp);
		},
		[comments]
	);

	return { addComment, deleteComment, editComment };
}
