import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';
import UploadIcon from '@mui/icons-material/UploadRounded';
import { useSnackbar } from 'notistack';
import { v4 as generateUuid } from 'uuid';

import {
	BtFormActionButtons,
	BtFormFilePicker,
	BtFormTextField,
	BtForm,
	BtFormActionsContainer,
} from '../../../../components/generic/forms';
import BtDialog from '../../../../components/generic/BtDialog';
import { imageRepoUpload } from '../../../../API';
import useFetch from '../../../../hooks/useFetch';
import { yup } from '../../../../utils/yup-ast';

const defaultValues = {
	name: '',
	image: [],
};

export default function ImageAddDialog({ onAdd, onClose, open, repo }) {
	const { enqueueSnackbar } = useSnackbar();

	const uploadApi = useFetch(imageRepoUpload);

	const [sending, setSending] = useState(false);

	const validationSchema = yup.object({
		name: yup
			.string()
			.min(1)
			.required()
			.label('Name'),
		image: yup
			.array()
			.length(1, 'You must select an image')
			.required('You must select an image')
			.label('Image'),
	});

	const onSubmit = useCallback(
		async values => {
			setSending(true);

			const { ok } = await uploadApi.request({
				imageRepo: repo.uuid,
				imageId: generateUuid(),
				image: values.image[0],
			});

			setSending(false);

			if (!ok) {
				enqueueSnackbar('Failed to upload the image', {
					variant: 'error',
				});

				return;
			}

			onAdd?.();
		},
		[enqueueSnackbar, onAdd, repo, uploadApi]
	);

	return (
		<BtDialog onClose={onClose} open={open}>
			<BtForm
				defaultValues={defaultValues}
				onSubmit={onSubmit}
				sending={sending}
				validationSchema={validationSchema}
			>
				<div
					style={{
						padding: '1em 1.5em',
					}}
				>
					<Typography style={{ marginBottom: '1em' }} variant="h5">
						Upload an Image
					</Typography>
					<BtFormTextField
						label="Name"
						style={{ marginBottom: '1em' }}
						name="name"
					/>
					<BtFormFilePicker
						imagesOnly
						label="Select an Image"
						maxFiles={1}
						maxFileSize={52428800}
						name="image"
					/>
					<BtFormActionsContainer style={{ marginTop: '1em' }}>
						<BtFormActionButtons
							alwaysAllowDestructive
							DestructiveIcon={null}
							destructiveVerb="Cancel"
							onDestroy={onClose}
							showDestructivePrompt={false}
							SubmitIcon={<UploadIcon />}
							submitVerb="Upload"
						/>
					</BtFormActionsContainer>
				</div>
			</BtForm>
		</BtDialog>
	);
}

ImageAddDialog.propTypes = {
	onAdd: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool,
	repo: PropTypes.object,
};
