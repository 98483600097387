import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
} from 'react';
import PropTypes from 'prop-types';

import { dataSourceGetList } from '../../../API';
import useFetch from '../../../hooks/useFetch';

const DataViewEditorContext = createContext();

export const useDataViewEditorContext = () => {
	const context = useContext(DataViewEditorContext);

	if (!context) {
		throw new Error(
			'useDataViewEditorContext was used outside of its provider.'
		);
	}

	return context;
};

export const DataViewEditorContextProvider = ({ children }) => {
	const {
		data: dataSources,
		error: dataSourcesError,
		loading: loadingDataSources,
		request: getDataSources,
	} = useFetch(dataSourceGetList);

	const refreshDataSources = useCallback(
		() => {
			getDataSources();
		},
		[getDataSources]
	);

	useEffect(
		() => {
			getDataSources();
		},
		[getDataSources]
	);

	return (
		<DataViewEditorContext.Provider
			value={{
				dataSources,
				dataSourcesError,
				loadingDataSources,
				refreshDataSources,
			}}
		>
			{children}
		</DataViewEditorContext.Provider>
	);
};

export const withDataViewEditorContext = WrappedComponent => {
	const Provider = props => (
		<DataViewEditorContextProvider>
			<WrappedComponent {...props} />
		</DataViewEditorContextProvider>
	);

	return Provider;
};

DataViewEditorContextProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};
