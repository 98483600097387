import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
// import PropTypes from 'prop-types';
import {
	BtFormContent,
	BtFormSelect,
	BtFormTextField,
	withFormContextMethods,
} from '../../../../../../../components/generic/forms';
import {
	INFO_CARD,
	VIS_LIST_SELECT_OPTIONS,
} from '../../../../../InsightPage/visualisationConstants';
import PropTypes from 'prop-types';
import { useEditFormContext } from '../../EditFormContext';
import BtFormDataConfigNodeSelect from '../../../../../../../components/generic/forms/BtFormDataConfigNodeSelect';
import { isEqual } from 'lodash';
import { Box, Button, Typography, styled } from '@mui/material';
import BtOpenInNew from '../../../../../../../components/generic/BtOpenInNew';
import { useParams } from 'react-router';
import { useWatch } from 'react-hook-form';
import { visualisationSchema } from '../../../../../../../API/validations/insightVisualizationsValidation';
import CloneVisualisationSection from '../CloneVisualisationSection';
import { InputWithExtrasBox, OpenInBox } from './generic';

const buildSelectOptions = optionsSourceArray => {
	return optionsSourceArray.map(({ uuid, name }) => ({
		value: uuid,
		label: name,
	}));
};

const buildPaletteSelectOptions = optionsSourceArray => {
	return optionsSourceArray.map(({ uuid, name, palette }) => ({
		value: uuid,
		label: (
			<Box
				key={uuid}
				sx={{
					display: 'flex',
				}}
			>
				<Typography sx={{ minWidth: '100px', marginRight: '10px' }}>
					{name}
				</Typography>
				<Box>
					{palette.map(({ hex }) => (
						<Box
							key={hex}
							sx={{
								display: 'inline-block',
								width: '20px',
								height: '20px',
								backgroundColor: hex,
								// margin: '0 5px',
							}}
						/>
					))}
				</Box>
			</Box>
		),
	}));
};

const NodeSelectField = ({ transform, dataSource }) => {
	const { configSchemas } = useEditFormContext();

	const configSchema = useMemo(() => configSchemas[dataSource][transform], [
		configSchemas,
		dataSource,
		transform,
	]);

	// useEffect(
	// 	() =>
	// 		console.log(
	// 			{
	// 				configSchemas,
	// 				transform,
	// 				dataSource,
	// 			},
	// 			'configSchema',
	// 			configSchemas[dataSource][transform]
	// 		),
	// 	[configSchemas, dataSource, transform]
	// );

	if (!dataSource || !transform) {
		return (
			<Typography>
				{
					<em>
						Select data source and transform to view
						DataConfigNodeSelect...
					</em>
				}
			</Typography>
		);
	}
	if (!configSchema) {
		return (
			<Typography>{<em>No config schema available...</em>}</Typography>
		);
	}

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Typography variant="caption">Data Origin(optional)</Typography>
			<BtFormDataConfigNodeSelect
				configSchema={configSchema}
				name={'datasource.dataOrigin'}
			/>
		</Box>
	);
};

const TransformField = ({ dataSource, transform }) => {
	const { transformOptions } = useEditFormContext();
	const { id } = useParams();

	const items = useMemo(
		() => {
			if (dataSource) {
				return transformOptions[dataSource];
			} else {
				return [];
			}
		},
		[dataSource, transformOptions]
	);

	if (!dataSource) {
		return (
			<Typography>
				{<em>Select data source to view transforms...</em>}
			</Typography>
		);
	}
	if (
		transformOptions[dataSource].length > 0 &&
		(items.map(({ value }) => value).includes(transform) || !transform)
	) {
		return (
			<>
				<InputWithExtrasBox>
					<BtFormSelect
						label="Data Transform"
						name={`datasource.transform`}
						items={items}
						fullWidth
					/>
					<OpenInBox>
						<BtOpenInNew
							buttonProps={{
								color: undefined,
							}}
							name="View and edit data source transforms (opens in new tab)"
							size="medium"
							stopPropagation
							url={`InsightCollection/${id}/Edit/DataSources/${dataSource}`}
						/>
					</OpenInBox>
				</InputWithExtrasBox>
				<NodeSelectField
					dataSource={dataSource}
					transform={transform}
				/>
			</>
		);
	}
	return null;
};

const DataSourceController = withFormContextMethods(
	({ watch, getValues, reset }) => {
		const { dataSources } = useEditFormContext();
		const type = watch('type');
		const { id } = useParams();
		const prevTransform = useRef(getValues('datasource.transform'));
		const prevDataSource = useRef(getValues('datasource.uuid'));

		const dataSource = useWatch({
			name: 'datasource.uuid',
		});

		const transform = useWatch({
			name: 'datasource.transform',
		});

		useEffect(
			() => {
				if (!isEqual(prevDataSource.current, dataSource)) {
					// console.log('the DATA SOURCE has changed');
					prevDataSource.current = dataSource;
					const newValues = getValues();
					newValues.datasource.transform = '';
					reset(newValues, { keepDefaultValues: true });
				} else if (!isEqual(prevTransform.current, transform)) {
					// console.log('the TRANSFORM has changed');
					prevTransform.current = transform;
					const newValues = getValues();
					newValues.datasource.dataOrigin = '';
					reset(newValues, { keepDefaultValues: true });
				} else {
					// console.log(
					// 	'the DATA SOURCE and TRANSFORM have NOT changed'
					// );
				}
			},
			[dataSource, getValues, reset, transform]
		);

		useEffect(
			() => {
				const latestValues = getValues();
				const validatedLatestValues = visualisationSchema.validateSync(
					latestValues
				);
				// console.log('type changed', validatedLatestValues);
				reset(validatedLatestValues);
			},
			[getValues, reset, type]
		);

		if (type === INFO_CARD) {
			return null;
		} else {
			return (
				<>
					<InputWithExtrasBox>
						<BtFormSelect
							fullWidth
							label="Data Source"
							name={`datasource.uuid`}
							items={buildSelectOptions(dataSources)}
						/>
						<OpenInBox>
							<BtOpenInNew
								buttonProps={{
									color: undefined,
								}}
								name="View and edit collection data sources (opens in new tab)"
								size="medium"
								stopPropagation
								url={`InsightCollection/${id}/Edit/DataSources`}
							/>
						</OpenInBox>
					</InputWithExtrasBox>
					<TransformField
						dataSource={dataSource}
						transform={transform}
					/>
				</>
			);
		}
	}
);

export const PropertiesForm = memo(function PropertiesForm() {
	const { collectionPalettes } = useEditFormContext();
	const { id } = useParams();

	// console.log('collectionPalettes', collectionPalettes);
	return (
		<BtFormContent>
			<BtFormTextField name="title" label="Title" variant="standard" />
			<BtFormTextField
				name="subtitle"
				label="Subtitle"
				variant="standard"
			/>
			{/* <InputWithExtrasBox> */}
			<BtFormSelect
				hideNone
				name="type"
				label="Type"
				items={VIS_LIST_SELECT_OPTIONS}
				fullWidth
			/>
			{/* TODO - add support to clear current tags when palette changes */}
			<InputWithExtrasBox>
				<BtFormSelect
					disabled={!collectionPalettes.length}
					fullWidth
					label="Visualisation Palette"
					name="]palette.uuid"
					items={buildPaletteSelectOptions(collectionPalettes)}
				/>
				<OpenInBox>
					<BtOpenInNew
						buttonProps={{
							color: undefined,
						}}
						name="View and edit collection palettes (opens in new tab)"
						size="medium"
						stopPropagation
						url={`InsightCollection/${id}/Edit/Collection`}
					/>
				</OpenInBox>
			</InputWithExtrasBox>
			{/* Add a button to toggle definition cleanse on type change?..
			</InputWithExtrasBox> */}
			<DataSourceController />
			<CloneVisualisationSection />
		</BtFormContent>
	);
});

TransformField.propTypes = {
	dataSource: PropTypes.string,
	transform: PropTypes.string,
};

NodeSelectField.propTypes = {
	dataSource: PropTypes.string,
	transform: PropTypes.string,
};
