import { Skeleton } from '@mui/material';
import { AlertCircle } from 'mdi-material-ui';

export const BarSkeleton = ({ animation }) => {
	return (
		<div
			style={{
				height: '90%',
				maxWidth: 180,
				minWidth: 20,
				display: 'flex',
				flexDirection: 'column',
				gap: 10,
				alignItems: 'center',
				justifyContent: 'flex-end',
			}}
		>
			<div
				style={{
					height: '100%',
					width: '100%',
					display: 'flex',
					gap: 10,
					justifyContent: 'center',
					alignItems: 'flex-end',
				}}
			>
				{['30%', '90%', '60%'].map(bar => (
					<Skeleton
						key={bar}
						variant="rectangular"
						width={50}
						height={bar}
						animation={animation}
					/>
				))}
			</div>

			<Skeleton
				animation={animation}
				variant="rectangular"
				height={10}
				width="100%"
			/>
			{/* <AlertCircle
				style={{
					opacity: 0.8,
					position: 'absolute',
					display: 'flex',
					justifySelf: 'center',
					bottom: '40%',
				}}
			/> */}
		</div>
	);
};
