import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';

import BtDialog from '../../../../../components/generic/BtDialog';
import BtDialogHeading from '../../../../../components/generic/BtDialogHeading';

export const WorkflowInfoModal = ({ info, onClose, open, title, ...props }) => (
	<BtDialog fullWidth maxWidth="sm" onClose={onClose} open={open} {...props}>
		<div style={{ padding: '1em 1em 1.5em 1em' }}>
			<BtDialogHeading
				onClose={onClose}
				style={{ marginBottom: '0.5em' }}
				title={title}
			/>
			<Typography>{info}</Typography>
		</div>
	</BtDialog>
);

WorkflowInfoModal.propTypes = {
	info: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool,
	title: PropTypes.string,
};

export default WorkflowInfoModal;
