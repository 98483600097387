import { Typography, useTheme } from '@mui/material';
import { Cog, Delete, MinusBoxOutline, PlusBoxOutline } from 'mdi-material-ui';
import { useContext, useMemo, useState } from 'react';
import BtDialog from '../../../BtDialog';
import { tileLabels } from '../../tileConstants';
import { TileConfigButton } from '../controls/ButtonComponents';
import { ContainerAddTileDialog } from '../tileConfig/ContainerAddTileDialog';
import { ContainerRemoveTileDialog } from '../tileConfig/ContainerRemoveTileDialog';
import { TileConfigDialog } from '../tileConfig/TileConfigDialog';
import { TileContext } from '../TileContext';

export const NativeTileRenderItem = () => {
	const [showEditDialog, setShowEditDialog] = useState(false);
	const THEME = useTheme();
	const [
		showContainerRemoveTileDialog,
		setShowContainerRemoveTileDialog,
	] = useState(false);
	const [
		showContainerAddTileDialog,
		setShowContainerAddTileDialog,
	] = useState(false);
	const [
		showContainerEditTileDialog,
		setShowContainerEditTileDialog,
	] = useState(false);
	const {
		tileData,
		handleDeleteTile,
		onTileChange,
		screenData,
		onContainerChange,
	} = useContext(TileContext);

	const configOptions = useMemo(
		() => {
			switch (tileData.variant) {
				case 'workflow_templates':
					return 'none';
				case 'workflow_submissions':
					return 'none';
				case 'container':
					return 'container';
				default:
					return 'none';
			}
		},
		[tileData.variant]
	);

	const TileControls = () => {
		return (
			<div style={{ display: 'flex' }}>
				{configOptions === 'default' && (
					<TileConfigButton
						onClick={() => setShowEditDialog(true)}
						title="Configure tile"
						icon={<Cog fontSize="small" />}
					/>
				)}

				{configOptions === 'container' && (
					<>
						<TileConfigButton
							onClick={() => setShowContainerAddTileDialog(true)}
							title="Add tile to container"
							icon={<PlusBoxOutline fontSize="small" />}
						/>
						<TileConfigButton
							onClick={() =>
								setShowContainerRemoveTileDialog(true)
							}
							title="Remove tile from container"
							icon={<MinusBoxOutline fontSize="small" />}
							disabled={tileData.data.children.length === 0}
						/>
					</>
				)}
				<TileConfigButton
					onClick={handleDeleteTile(tileData.uuid)}
					title="Remove tile"
					icon={<Delete fontSize="small" />}
				/>
			</div>
		);
	};

	const ChildTiles = () => {
		return (
			<ChildrenContainer>
				{tileData.data.children.length === 0 ? (
					<Typography>
						<em>Empty</em>
					</Typography>
				) : (
					tileData.data.children.map(
						childUuid =>
							screenData.tiles[childUuid].data.button_type !==
							'start_workflow' ? (
								<Typography key={childUuid}>
									<em>Unsupported button</em>
								</Typography>
							) : (
								<Typography key={childUuid}>
									{screenData.tiles[childUuid].title}
								</Typography>
							)
					)
				)}
			</ChildrenContainer>
		);
	};

	const ChildrenContainer = ({ children }) => {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					marginLeft: 10,
				}}
			>
				{children}
			</div>
		);
	};

	return (
		<>
			<div
				style={{
					paddingTop: 5,
					paddingBottom: 5,
					paddingRight: 10,
					paddingLeft: 10,
					margin: 7,
					borderRadius: 5,
					backgroundColor: THEME.palette.background.paperContrast,
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<div style={{ display: 'flex' }}>
						{/* {resolveIcon({
							type: tile.variant,
							disabled: true,
						})} */}
						<div style={{ marginLeft: 5, overflow: 'clip' }}>
							<Typography>
								{tileLabels[tileData.variant]}
							</Typography>
						</div>
					</div>
					<TileControls />
				</div>
				{tileData.variant === 'container' && <ChildTiles />}
				{tileData.variant === 'button' && (
					<ChildrenContainer>
						<Typography>
							{tileData.data.target.value === '' ? (
								<em>{tileData.title}</em>
							) : (
								tileData.title
							)}
						</Typography>
					</ChildrenContainer>
				)}
			</div>
			<BtDialog
				open={showEditDialog}
				onClose={() => setShowEditDialog(false)}
			>
				<TileConfigDialog
					onClose={() => setShowEditDialog(false)}
					tileData={tileData}
					onTileChange={onTileChange}
					screenUuid={screenData.uuid}
					nativeMode={true}
				/>
			</BtDialog>

			{configOptions === 'container' && (
				<>
					<BtDialog
						open={showContainerRemoveTileDialog}
						onClose={() => setShowContainerRemoveTileDialog(false)}
					>
						<ContainerRemoveTileDialog
							onClose={() =>
								setShowContainerRemoveTileDialog(false)
							}
							tileData={tileData}
							// onTileChange={onTileChange}
							// screenUuid={screenData.uuid}
							screenData={screenData}
							onContainerChange={onContainerChange}
						/>
					</BtDialog>
					<BtDialog
						open={showContainerAddTileDialog}
						onClose={() => setShowContainerAddTileDialog(false)}
					>
						<ContainerAddTileDialog
							onClose={() => setShowContainerAddTileDialog(false)}
							tileData={tileData}
							// onTileChange={onTileChange}
							// screenUuid={screenData.uuid}
							screenData={screenData}
							onContainerChange={onContainerChange}
							nativeMode={true}
						/>
					</BtDialog>
				</>
			)}
		</>
	);
};
