import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Auth } from 'aws-amplify';
import { useAppContext } from '../../context/ContextManager';

// COMPONENTS
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/SaveAlt';

import { ListTable, ListRow, ListHeader, ListCell } from '../generic/ListTable';

const styles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(2),
		'& .MuiTextField-root': {
			margin: '10px 0',
		},
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
	},
	dialogTitle: {
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.contrastText,
	},
	dialogContent: {
		minWidth: '600px',
	},
	selectionText: {
		display: 'inline',
		borderBottom: '1px',
		fontSize: '1.5rem',
		lineHeight: '24px',
	},
	selectionDiv: {
		borderTop: '1px solid red',
		borderTopColor: theme.palette.primary.shade_dark,
		flexGrow: 1,
	},
	selectionDivRow: {
		cursor: 'pointer',
		borderBottom: '1px solid red',
		borderBottomColor: theme.palette.primary.shade_dark,
		'&:hover': {
			backgroundColor: theme.palette.primary.shade_light,
		},
	},
	selectionCheck: {
		margin: 5,
	},
	textField: {
		backgroundColor: '#f5f5f5',
		margin: '0px 0',
		fontSize: '2rem',
	},
	deploySelect: {
		minWidth: '200px',
	},
	datePicker: {
		marginTop: 40,
	},
	nodeBreadcrumb: {
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.light,
		display: 'inline',
		padding: '4px 16px',

		margin: 4,
		height: 16,
		borderRadius: 16,
	},
	nodeRouteText: {
		margin: 'auto',
	},
}));

//export default function OrgNodeDialog({setOpen, onClose, orgData, selectedNode}) {
export default function EmConditioningDialog({
	setOpen,
	onClose,
	values,
	conditioning,
	device_uid,
}) {
	const classes = styles();
	const [commsActive, setCommsActive] = useState(false);
	//const [nodeData, setNodeData] = useState([]);
	//const [nodeRoute, setNodeRoute] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [inputConfig, setInputConfig] = useState({});
	const { appOrg } = useAppContext();

	//] = useState(0);
	//const handleTabChange = (event, newTabValue) => {setCurrentTab(newTabValue);};

	const handleSelectConfigClose = () => {
		onClose();
	};

	// This is called only when the dialog is opened
	const handleOnEnter = () => {
		setInputConfig(conditioning);
		console.log(conditioning);

		setIsLoading(false);
	};

	const handleCancel = () => {
		onClose();
	};

	const handleSave = () => {
		setCommsActive(true);
		var conditioning = {};
		conditioning['input'] = inputConfig.input;
		conditioning['ct_ratio'] = inputConfig.ct_ratio;

		var put_data = {};
		put_data['conditioning'] = conditioning;

		var req_url =
			process.env.REACT_APP_API_SERVER_URL +
			'/v1/device/conditioning/' +
			device_uid;

		Auth.currentSession().then(user_session => {
			let user_token = user_session.getAccessToken();
			fetch(req_url, {
				method: 'PUT',
				headers: {
					Authorization: user_token.jwtToken,
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Organisation: appOrg.uuid,
				},
				body: JSON.stringify(put_data),
			})
				.then(res => res.json())
				.then(response => {
					if (response['result'] !== 'OK') {
						console.log(
							'Failed to set conditioning: ' + response['result']
						);
					} else {
						//console.log("Conditioning update done");
					}
					setCommsActive(false);
					onClose(conditioning);
				})
				.catch(error => {
					console.log(error);
					setCommsActive(false);
				});
		});
	};

	const handleCTSelect = event => {
		setInputConfig({
			...inputConfig,
			ct_ratio: parseInt(event.target.value),
		});
	};

	return (
		<>
			<Dialog
				open={setOpen}
				onClose={handleSelectConfigClose}
				className={classes.root}
				TransitionProps={{
					onEnter: handleOnEnter,
				}}
			>
				<DialogTitle
					id="form-dialog-title"
					className={classes.dialogTitle}
				>
					Energy Monitor Conditioning Edit
				</DialogTitle>
				<DialogContent className={classes.dialogContent}>
					{isLoading && (
						<>
							<p>Loading...</p>
							<CircularProgress size={'120px'} />
						</>
					)}

					{!isLoading && (
						<>
							<Grid
								container
								direction="row"
								justifyContent="space-evenly"
								spacing={3}
								alignItems="stretch"
							>
								<Grid item xs={12}>
									<ListTable>
										<ListRow>
											<ListHeader>CT Ratio:</ListHeader>
											<ListCell>
												<FormControl
													variant="outlined"
													className={
														classes.formControl
													}
												>
													<Select
														labelId="demo-simple-select-outlined-label"
														id="demo-simple-select-outlined"
														value={inputConfig.ct_ratio.toString()}
														className={
															classes.select
														}
														onChange={
															handleCTSelect
														}
													>
														<MenuItem value="30">
															30A
														</MenuItem>
														<MenuItem value="50">
															50A
														</MenuItem>
														<MenuItem value="60">
															60A
														</MenuItem>
														<MenuItem value="80">
															80A
														</MenuItem>
														<MenuItem value="90">
															90A
														</MenuItem>
														<MenuItem value="120">
															120A
														</MenuItem>
													</Select>
												</FormControl>
											</ListCell>
										</ListRow>
									</ListTable>
								</Grid>
							</Grid>

							<Box>
								<Divider />
								<Box display="flex" flexDirection="row-reverse">
									<Button
										variant="outlined"
										startIcon={<SaveIcon />}
										onClick={handleSave}
										className={classes.button}
									>
										Save
									</Button>

									<Button
										variant="outlined"
										startIcon={<CloseIcon />}
										onClick={handleCancel}
										className={classes.button}
									>
										Cancel
									</Button>
								</Box>
							</Box>
						</>
					)}
				</DialogContent>
			</Dialog>

			<Backdrop className={classes.backdrop} open={commsActive}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	);
}
