import React from 'react';

// import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export function ListTable(props) {
	return (
		<TableContainer component="div" sx={{ border: '0' }}>
			<Table
				sx={{
					fontSize: [
						'1.5rem',
						'1.5rem',
						'1.5rem',
						'1.5rem',
						'1.5rem',
					],
					width: '100%',
				}}
			>
				<tbody>{props.children}</tbody>
			</Table>
		</TableContainer>
	);
}

export function ListRow(props) {
	return <TableRow>{props.children}</TableRow>;
}

export function ListHeader(props) {
	return (
		<TableCell
			sx={{
				fontWeight: '700',
				whiteSpace: 'nowrap',
				padding: '0px 8px',
				border: '0',
				fontSize: ['1.5rem', '1.5rem', '1.5rem', '1.5rem', '1.5rem'],
			}}
		>
			{props.children}
		</TableCell>
	);
}

export function ListBody(props) {
	return (
		<TableBody sx={{ whiteSpace: 'nowrap' }}>{props.children}</TableBody>
	);
}

export function ListCell(props) {
	return (
		<TableCell
			sx={{
				width: '100%',
				padding: '0px 8px',
				border: '0',
				fontSize: ['1.5rem', '1.5rem', '1.5rem', '1.5rem', '1.5rem'],
			}}
		>
			{props.children}
		</TableCell>
	);
}
