import React, { Fragment, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import BtLoading from './BtLoading';
import BtNoItems from './BtNoItems';
import {
	Button,
	Divider,
	IconButton,
	List,
	ListItem,
	Skeleton,
	Tooltip,
	Typography,
} from '@mui/material';
import pluralize from 'pluralize';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';
import uuid from 'uuid/v4';

import BtConfirmDialog from './BtConfirmDialog';
import useUsingObjects from '../../hooks/useUsingObjects';

export default function BtList({
	confirmDeletion,
	deleteable,
	DeleteIcon,
	deleteVerb,
	disabled,
	items,
	loading,
	onItemClick,
	onItemDelete,
	primaryField,
	renderItem,
	showDeleteIcon,
	sortComparator,
	style,
	subject,
}) {
	const theme = useTheme();
	const screen_downSm = useMediaQuery(theme.breakpoints.down('sm'));
	const usingObjects = useUsingObjects(items);

	const [deleteDialog, setDeleteDialog] = useState(false);
	const [itemToDelete, setItemToDelete] = useState(null);

	const sortedItems = useMemo(
		() => {
			if (!Array.isArray(items) || (items || []).length === 0) return [];

			if (sortComparator) {
				return items?.sort(sortComparator);
			}

			if (usingObjects) {
				if (primaryField) {
					return [...items].sort((a, b) =>
						a[primaryField]?.localeCompare(b[primaryField])
					);
				}
				return items;
			}

			return items.sort((a, b) => a.localeCompare(b));
		},
		[items, sortComparator, primaryField, usingObjects]
	);

	const handleDeleteActionClick = item => {
		if (confirmDeletion) {
			setItemToDelete(item);
			setDeleteDialog(true);
			return;
		}
		if (onItemDelete) {
			onItemDelete(item);
		}
	};

	return (
		<>
			<BtLoading
				loading={loading}
				LoadingComponent={
					<>
						<Skeleton style={{ width: '100%' }} />
						<Skeleton style={{ width: '100%' }} />
						<Skeleton style={{ width: '100%' }} />
					</>
				}
			>
				<List style={style}>
					{sortedItems.length === 0 && (
						<BtNoItems subject={subject} />
					)}
					{sortedItems.map((item, index) => (
						<Fragment key={uuid()}>
							<ListItem
								style={{
									width: '100%',
								}}
								onClick={() => {
									if (!disabled) onItemClick?.(item);
								}}
								button={!!onItemClick && !disabled}
							>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										width: '100%',
									}}
								>
									<div
										style={{
											position: 'relative',
											overflow: 'hidden',
											width: '100%',
											maxWidth: '100%',
											flexShrink: 1,
										}}
									>
										{!!renderItem &&
											renderItem(item, index)}
										{!renderItem && (
											<Typography>
												{(() => {
													if (usingObjects) {
														if (primaryField) {
															return item[
																primaryField
															];
														}
														return 'Item';
													}
													return item;
												})()}
											</Typography>
										)}
									</div>
									{deleteable && (
										<div>
											{screen_downSm ? (
												<Tooltip
													title={deleteVerb}
													disableInteractive
												>
													<IconButton
														color="error"
														onClick={() =>
															handleDeleteActionClick(
																item
															)
														}
														disabled={disabled}
														style={{
															float: 'right',
														}}
													>
														{DeleteIcon}
													</IconButton>
												</Tooltip>
											) : (
												<Button
													color="error"
													variant="text"
													onClick={() =>
														handleDeleteActionClick(
															item
														)
													}
													disabled={disabled}
													startIcon={
														showDeleteIcon
															? DeleteIcon
															: undefined
													}
													style={{
														float: 'right',
													}}
												>
													{deleteVerb}
												</Button>
											)}
										</div>
									)}
								</div>
							</ListItem>
							<Divider />
						</Fragment>
					))}
				</List>
			</BtLoading>
			<BtConfirmDialog
				action={() => {
					if (onItemDelete) {
						onItemDelete(itemToDelete);
					}
				}}
				title={`${deleteVerb} ${
					subject ? pluralize.singular(subject) : 'item'
				}?`}
				prompt={`Are you sure you want to ${(
					deleteVerb || ''
				).toLowerCase()} ${
					subject ? pluralize.singular(subject) : 'item'
				}${
					itemToDelete && usingObjects && primaryField
						? ` ${itemToDelete[primaryField]}`
						: ''
				}?`}
				ActionIcon={DeleteIcon}
				isDestructive
				open={deleteDialog}
				onClose={() => setDeleteDialog(false)}
				subject={subject ? subject : 'item'}
				verb="Remove"
			/>
		</>
	);
}

BtList.defaultProps = {
	deleteVerb: 'Delete',
	loading: false,
	showDeleteIcon: true,
};

BtList.propTypes = {
	confirmDeletion: PropTypes.bool,
	deleteable: PropTypes.bool,
	DeleteIcon: PropTypes.node,
	deleteVerb: PropTypes.string,
	disabled: PropTypes.bool,
	items: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.string, PropTypes.object])
	),
	loading: PropTypes.bool,
	onItemClick: PropTypes.func,
	onItemDelete: PropTypes.func,
	primaryField: PropTypes.string,
	renderItem: PropTypes.func,
	showDeleteIcon: PropTypes.bool,
	sortComparator: PropTypes.func,
	style: PropTypes.object,
	subject: PropTypes.string,
};
