import { IconButton, styled, Tooltip } from '@mui/material';
// import { StyledIconButton } from './Controls';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

export function TileConfigButton({
	onClick,
	title,
	icon,
	arrow,
	styled,
	disabled,
	style,
}) {
	const withTooltip = useMemo(() => !!title, [title]);

	const Wrapper = ({ children }) => {
		return withTooltip ? (
			<Tooltip title={title} arrow={arrow}>
				{children}
			</Tooltip>
		) : (
			<>{children}</>
		);
	};

	return (
		<Wrapper>
			{styled ? (
				<span>
					<StyledIconButton
						onClick={onClick}
						onTouchEnd={onClick}
						disabled={disabled}
						style={style}
					>
						{icon}
					</StyledIconButton>
				</span>
			) : (
				<span>
					<IconButton
						onClick={onClick}
						onTouchEnd={onClick}
						disabled={disabled}
						style={style}
					>
						{icon}
					</IconButton>
				</span>
			)}
		</Wrapper>
	);
}

export const ControlContainer = styled('div')(
	({ style, theme, direction = 'column' }) => ({
		display: 'flex',
		flexDirection: direction,
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: 5,
		// width: 110,
		// height: 40,
		borderRadius: 20,
		backgroundColor: theme.palette.primary.light,
		...style,
	})
);

export const StyledIconButton = styled(IconButton)(
	({ theme, style, small }) => ({
		height: small ? 25 : 30,
		width: small ? 25 : 30,
		color: 'white',
		backgroundColor: theme.palette.primary.light,
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
		},
		'&:disabled': {
			// color: 'white',
		},
		...style,
	})
);

export const ControlButton = React.forwardRef(function ControlButton(
	props,
	ref
) {
	return <StyledIconButton {...props} ref={ref} />;
});

TileConfigButton.defaultProps = {
	arrow: true,
	styled: false,
};

TileConfigButton.propTypes = {
	onClick: PropTypes.func.isRequired,
	title: PropTypes.string,
	icon: PropTypes.node.isRequired,
	arrow: PropTypes.bool,
	styled: PropTypes.bool,
	disabled: PropTypes.bool,
	style: PropTypes.object,
};
