import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import useFetch from '../../../../hooks/useFetch';
import { insightCollectionComponentGet } from '../../../../API';
import InsightContent from './InsightContent';
import { TileContext } from '../components/TileContext';

export default function InsightTile() {
	const { tileData, setError } = useContext(TileContext);
	const {
		data: insightTile,
		error: insightTileError,
		loading: insightTileLoading,
		request: insightTileRequest,
	} = useFetch(insightCollectionComponentGet);

	useEffect(
		() => {
			insightTileRequest({
				insightUuid: tileData.data.insight_collection,
				componentUuid: tileData.data.insight_component,
			});
		},
		[insightTileRequest, tileData]
	);

	useEffect(
		() => {
			if (insightTileError) setError({ serverError: true });
		},
		[insightTileError, setError]
	);

	// useEffect(
	// 	() => {
	// 		if (!insightTileLoading)
	// 			if (
	// 				!insightTileError &&
	// 				tileData?.data.insight_collection !== '' &&
	// 				tileData?.data.insight_component !== ''
	// 			) {
	// 				setClickDisabled && setClickDisabled(false);
	// 				// } else if (insightTileError) {
	// 				// 	setError({ serverError: true });
	// 				// } else {
	// 				// 	setError({ insightTileConfigRequired: true });
	// 			}
	// 	},
	// 	[
	// 		insightTileError,
	// 		insightTileLoading,
	// 		setClickDisabled,
	// 		tileData.data.insight_component,
	// 		tileData.data.insight_collection,
	// 		setError,
	// 	]
	// );

	return (
		insightTile && (
			<InsightContent
				// clickDisabled={clickDisabled}
				tileVariant={tileData.variant}
				title={tileData.title}
				subtitle={tileData.data.subtitle}
				loading={insightTileLoading}
				insightTile={insightTile}
			/>
		)
	);
}

// InsightTile.propTypes = {
// 	setClickDisabled: PropTypes.func.isRequired,
// 	clickDisabled: PropTypes.bool,
// };
