import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import {
	LineChart,
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip,
	Legend,
	Line,
	//ComposedChart,
	//Area,
	//Bar,
	ResponsiveContainer,
} from 'recharts';

import moment from 'moment';
import { generateXTicks } from '../../utils/generateXTicks';
import { Box } from '@mui/system';

const formatTimestamp = (tick, format) => moment(tick).format(format);

export default function Chart({ data, param_names, xaxis_name }) {
	//const Chart = ({data, param_names}) => {
	//const [startTime, setStartTime] = useState(0);
	//const [endTime, setEndTime] = useState(0);
	const [xTicks, setXTicks] = useState([]);
	const [xTickFormat, setXTickFormat] = useState('HH:mm');
	const [timestampFormat, setTimestampFormat] = useState('DD/MM/YYYY HH:mm');
	const THEME = useTheme();
	const [showLegend, setShowLegend] = useState(true);
	const [showAxis, setShowAxis] = useState(true);
	const ref = useRef(null);

	let colours = [];
	colours.push('#5fc1c9');
	colours.push('#22c018');
	colours.push('#e29834');
	colours.push('#9046b6');
	colours.push('#dc564c');
	colours.push('#1c3249');

	//const timestampFormat = 'DD/MM/YYYY HH:mm';
	//const xTickFormat = "Do MMM 'YY";

	//const xTickFormat = 'HH:mm';
	//const timestampFormat = 'HH:mm';

	useEffect(
		() => {
			if (ref.current) {
				if (ref.current.offsetWidth < 500 && showLegend) {
					setShowLegend(false);
				} else if (
					ref.current.offsetWidth >= 500 &&
					showLegend === false
				) {
					setShowLegend(true);
				}

				if (ref.current.offsetWidth < 300 && showLegend) {
					setShowAxis(false);
				} else if (
					ref.current.offsetWidth >= 300 &&
					showLegend === false
				) {
					setShowAxis(true);
				}
			}
		},
		[ref.current]
	);

	// useEffect(
	// 	() => {
	// 		function handleResize() {
	// 			if (window.innerWidth < 800 && showLegend) {
	// 				setShowLegend(false);
	// 			} else if (window.innerWidth >= 800 && showLegend === false) {
	// 				setShowLegend(true);
	// 			}
	// 		}

	// 		window.addEventListener('resize', handleResize);
	// 		handleResize();
	// 		return () => window.removeEventListener('resize', handleResize);
	// 	},
	// 	[showLegend]
	// );

	useEffect(
		() => {
			const second = 1000;
			const minute = 60 * second;
			const hour = 60 * minute;
			const day = 24 * hour;
			const week = 7 * day;

			// Check that we have been passed valid data
			if (data === null || data.length === 0) {
			} else {
				const start = data[0][xaxis_name];
				const end = data[data.length - 1][xaxis_name];

				const timeSpan = end - start;
				var spacing = { every: 60, period: 'second' };
				if (timeSpan <= minute) {
					spacing = { every: 10, period: 'second' };
					setTimestampFormat('DD/MM/YYYY HH:mm:ss');
					setXTickFormat('HH:mm:ss');
				} else if (timeSpan <= hour) {
					spacing = { every: 1, period: 'minute' };
					setTimestampFormat('DD/MM/YYYY HH:mm:ss');
					setXTickFormat('HH:mm');
				} else if (timeSpan <= day) {
					spacing = { every: 30, period: 'minute' };
					setTimestampFormat('DD/MM/YYYY HH:mm');
					setXTickFormat('HH:mm');
				}

				setXTicks(
					generateXTicks({
						startTime: start,
						endTime: end,
						spacing: spacing,
					})
				);
			}
		},
		[data, xaxis_name]
	);

	const yAxisFormatter = tick => {
		if (tick < 1000) {
			return Math.floor(tick);
		} else if (tick < 1000000) {
			return Math.floor(tick / 1000) + 'k';
		} else if (tick < 1000000000) {
			return Math.floor(tick / 1000000) + 'M';
		} else if (tick < 1000000000000) {
			return Math.floor(tick / 1000000000) + 'G';
		}
	};

	const valueFormatter = tick => {
		if (tick < 1000) {
			return tick.toFixed(2);
		} else if (tick < 1000000) {
			return (tick / 1000).toFixed(2) + 'k';
		} else if (tick < 1000000000) {
			return (tick / 1000000).toFixed(2) + 'M';
		} else if (tick < 1000000000000) {
			return (tick / 1000000000).toFixed(2) + 'G';
		}
	};

	return (
		<Box ref={ref} sx={{ height: '100%' }}>
			<ResponsiveContainer
				width={'100%'}
				height={'100%'}
				style={{ margin: 'auto' }}
			>
				<LineChart
					style={{ margin: 'auto' }}
					data={data}
					// layout="vertical"
				>
					<CartesianGrid strokeDasharray="3 3" />
					{showAxis && (
						<XAxis
							type="number"
							dataKey={xaxis_name}
							domain={['dataMin', 'dataMax']}
							tickFormatter={tick =>
								formatTimestamp(tick, xTickFormat)
							}
							ticks={xTicks}
						/>
					)}

					{showAxis && (
						<YAxis tickFormatter={tick => yAxisFormatter(tick)} />
					)}

					{showAxis && (
						<Tooltip
							labelFormatter={tick =>
								formatTimestamp(tick, timestampFormat)
							}
							formatter={value => valueFormatter(value)}
							contentStyle={{
								backgroundColor: THEME.palette.background.paper,
							}}
						/>
					)}

					{showLegend && <Legend />}
					{param_names.map((param, index) => (
						<Line
							type="monotone"
							dataKey={param}
							stroke={colours[index]}
							key={index}
							dot={false}
							isAnimationActive={false}
						/>
					))}
				</LineChart>
			</ResponsiveContainer>
		</Box>
	);
}
