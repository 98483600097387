import { BarSkeleton } from './BarSkeleton';
import React from 'react';

export default function InsightLoading() {
	return (
		<div
			style={{
				paddingLeft: 20,
				paddingRight: 20,
				paddingBottom: 20,
				paddingTop: 20,
				height: '100%',
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'flex-end',
			}}
		>
			<BarSkeleton animation="pulse" />
		</div>
	);
}
