import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { IconButton, styled, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { v4 as generateUuid } from 'uuid';
import _ from 'lodash';

import EditIcon from '@mui/icons-material/Edit';

import {
	a11yProps,
	BtTab,
	BtTabBar,
	BtTabPanel,
	BtTabView,
} from '../../../components/generic/BtTabView';
import ActionButtonContainer from '../../../components/generic/ActionButtonContainer';
import BtChangeNameDescription from '../../../components/generic/BtChangeNameDescription';
import BtError from '../../../components/generic/BtError';
import {
	BtForm,
	BtFormActionButtons,
	BtFormChangesBlocker,
	BtFormChangesIndicator,
	BtFormContainer,
	withFormContextMethods,
} from '../../../components/generic/forms';
import BtLoading from '../../../components/generic/BtLoading';
import BtTitleHeader from '../../../components/generic/BtTitleHeader';
import data_manager_breadcrumbs from '../DataManagerBreadcrumbs';
import data_manager_nav_item from '../DataManagerNavItem';
import data_manager_options from '../DataManagerOptions';
import DataViewConfig from './DataViewConfig';
import {
	dataViewGet,
	dataViewUpdate,
} from '../../../API/DataManager/data_views';
import { dataViewGetSchema } from '../../../API/validations/dataViewsValidation';
import DataViewSources from './DataViewSources';
import { parseSchema } from '../../../utils/yup-ast';
import { useAppContext, useNavContext } from '../../../context/ContextManager';
import useAvailableToUser from '../../../hooks/useAvailableToUser';
import {
	useDataViewEditorContext,
	withDataViewEditorContext,
} from './DataViewEditorContext';
import useFetch from '../../../hooks/useFetch';

const CenterItems = styled('div')(() => ({
	alignItems: 'center',
	display: 'flex',
}));

const FormHeader = withFormContextMethods(
	({ canEditDataView, setValue, watch }) => {
		const description = watch('description');
		const name = watch('name');

		const [showNameDescModal, setShowNameDescModal] = useState(false);

		const handleChange = useCallback(
			({ description: newDescription, name: newName }) => {
				if (newDescription) {
					setValue('description', newDescription, {
						shouldValidate: true,
						shouldDirty: true,
						shouldTouch: true,
					});
				}

				if (newName) {
					setValue('name', newName, {
						shouldValidate: true,
						shouldDirty: true,
						shouldTouch: true,
					});
				}
			},
			[setValue]
		);

		return (
			<>
				<BtTitleHeader>
					<CenterItems>
						<Typography variant="h3">
							{name || 'Loading...'}
						</Typography>
						<BtFormChangesIndicator
							field="name"
							style={{ marginLeft: '0.2em' }}
						/>
						{canEditDataView && (
							<Tooltip
								className="editNameDescription"
								disableInteractive
								style={{ marginLeft: '0.5em' }}
								title="Edit Name and Description"
							>
								<IconButton
									onClick={() => setShowNameDescModal(true)}
									onMouseUp={event =>
										event.currentTarget.blur()
									}
								>
									<EditIcon />
								</IconButton>
							</Tooltip>
						)}
					</CenterItems>
					{description && (
						<CenterItems style={{ marginBottom: '0.5em' }}>
							<Typography>{description}</Typography>
							<BtFormChangesIndicator
								field="description"
								style={{ marginLeft: '0.2em' }}
							/>
						</CenterItems>
					)}
				</BtTitleHeader>
				<BtChangeNameDescription
					description={description}
					name={name}
					onChange={handleChange}
					onClose={() => setShowNameDescModal(false)}
					open={showNameDescModal}
				/>
			</>
		);
	},
	[]
);

export const DataViewEditor = withDataViewEditorContext(
	({ createNew, match }) => {
		const checkAvailability = useAvailableToUser();
		const { dataSourcesError } = useDataViewEditorContext();
		const { enqueueSnackbar } = useSnackbar();
		const { setActivityIndicator } = useAppContext();
		const { setBreadcrumbs, setContextualNav } = useNavContext();

		const [configHistory, setConfigHistory] = useState();
		const [configHistoryPos, setConfigHistoryPos] = useState();
		const [currentTab, setCurrentTab] = useState(0);
		const [dataViewError, setDataViewError] = useState(false);
		const [initialDataView, setInitialDataView] = useState();
		const [loadingDataView, setLoadingDataView] = useState(false);
		const [sending, setSending] = useState(false);

		const getDataView = useFetch(dataViewGet).request;
		const updateApi = useFetch(dataViewUpdate).request;

		const canEditDataView = useMemo(
			() =>
				checkAvailability({
					requiredPermissionsAll: {
						dataManager: ['DataViewEdit'],
					},
				}).available,
			[checkAvailability]
		);

		const editUuid = useMemo(
			() => {
				if (!createNew) {
					return match.params.uuid;
				}
			},
			[createNew, match]
		);

		useEffect(
			() => {
				(async () => {
					if (editUuid) {
						setLoadingDataView(true);

						const { data, ok } = await getDataView({
							dataViewUuid: editUuid,
						});

						setLoadingDataView(false);

						if (!ok) {
							setDataViewError(true);

							enqueueSnackbar('Failed to get the data view', {
								variant: 'error',
							});

							return;
						}

						const { config, schema } = data;

						let clone = { ...data };

						if (!config && schema) {
							const parsedConfig = parseSchema(schema);

							clone.config = parsedConfig;
						}

						setInitialDataView(clone);
					}
				})();
			},
			[editUuid, enqueueSnackbar, getDataView]
		);

		// Set breadcrumbs
		useEffect(
			() => {
				setBreadcrumbs([
					...data_manager_breadcrumbs,
					{ text: 'Data Views', link: '/DataViews' },
					{
						link: '',
						text: loadingDataView
							? 'Loading...'
							: initialDataView?.name,
					},
				]);
			},
			[initialDataView, loadingDataView, setBreadcrumbs]
		);

		// set Contextual navigation items
		useEffect(
			() => {
				setContextualNav([
					...data_manager_nav_item,
					...data_manager_options,
				]);

				return () => {
					setContextualNav(null);
				};
			},
			[setContextualNav]
		);

		const resetHistory = useCallback(
			() => {
				setConfigHistory([
					{
						config: _.cloneDeep({ ...initialDataView.config }),
						uuid: generateUuid(),
					},
				]);

				setConfigHistoryPos(0);
			},
			[initialDataView]
		);

		const handleSubmit = useCallback(
			async (values, reset) => {
				setActivityIndicator(true);
				setSending(true);

				const { ok } = await updateApi({
					dataViewUuid: editUuid,
					dataViewUpdate: values,
				});

				setActivityIndicator(false);
				setSending(false);

				if (!ok) {
					console.error('Failed to update data view.');

					enqueueSnackbar('Failed to update data view', {
						variant: 'error',
					});

					return;
				}

				reset(values);

				setInitialDataView(values);

				resetHistory();

				enqueueSnackbar('Data view updated', { variant: 'success' });
			},
			[
				editUuid,
				enqueueSnackbar,
				resetHistory,
				setActivityIndicator,
				updateApi,
			]
		);

		const handleReset = useCallback(() => resetHistory(), [resetHistory]);

		if (dataViewError) {
			return (
				<BtError
					action={() => getDataView({ dataViewUuid: editUuid })}
					description={`An error occurred when attempting to retrieve the data ${
						dataSourcesError ? 'sources' : 'view'
					}.`}
					title="Retrieval Error"
				/>
			);
		}

		return (
			<BtForm
				defaultValues={initialDataView}
				onSubmit={handleSubmit}
				sending={sending}
				style={{
					display: 'flex',
					minHeight: '100%',
					position: 'relative',
					width: '100%',
				}}
				validationSchema={dataViewGetSchema}
			>
				<BtTabView
					style={{
						minHeight: '100%',
						position: 'relative',
						width: '100%',
					}}
				>
					<BtFormContainer
						actionsContainerProps={{
							style: {
								alignItems: 'flex-start',
								marginTop: '0.5em',
							},
						}}
						maxWidth="lg"
						style={{
							display: 'flex',
							flexDirection: 'column',
							minHeight: '100%',
							position: 'relative',
						}}
						title={<FormHeader canEditDataView={canEditDataView} />}
						ActionButtons={
							canEditDataView ? (
								<ActionButtonContainer
									style={{
										padding: 0,
									}}
								>
									<BtFormActionButtons
										onDestroy={handleReset}
									/>
								</ActionButtonContainer>
							) : null
						}
						header={
							<>
								<BtTabBar
									currentTab={currentTab}
									onTabChange={(event, selectedTab) =>
										setCurrentTab(selectedTab)
									}
								>
									<BtTab
										label={
											<CenterItems>
												<span>Sources</span>
												<BtFormChangesIndicator
													field="sources"
													style={{
														marginLeft: '0.2em',
													}}
												/>
											</CenterItems>
										}
										{...a11yProps(0)}
									/>
									<BtTab
										label={
											<CenterItems>
												<span>Config</span>
												<BtFormChangesIndicator
													field="config"
													style={{
														marginLeft: '0.2em',
													}}
												/>
											</CenterItems>
										}
										{...a11yProps(1)}
									/>
								</BtTabBar>
							</>
						}
					>
						<BtLoading loading={loadingDataView}>
							<BtTabPanel currentTab={currentTab} index={0}>
								<DataViewSources
									canEditDataView={canEditDataView}
								/>
							</BtTabPanel>
							<BtTabPanel
								currentTab={currentTab}
								index={1}
								style={{ minHeight: '100%' }}
							>
								<DataViewConfig
									canEditDataView={canEditDataView}
									history={configHistory}
									historyPos={configHistoryPos}
									initialSchema={initialDataView?.schema}
									setHistory={setConfigHistory}
									setHistoryPos={setConfigHistoryPos}
								/>
							</BtTabPanel>
						</BtLoading>
					</BtFormContainer>
				</BtTabView>
				<BtFormChangesBlocker />
			</BtForm>
		);
	}
);

DataViewEditor.propTypes = {
	createNew: PropTypes.bool,
	match: PropTypes.object,
};

export default DataViewEditor;
