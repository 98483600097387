import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import BtConfirmDialog from '../BtConfirmDialog';
import withFormContextMethods from './withFormContextMethods';

const BtAuthFormActionButtons = withFormContextMethods(
	memo(
		({
			reset,
			loading,
			sending,
			formState: { isDirty, errors },
			setOverrideBlocking,
			submitVerb,
			SubmitIcon,
			submitPresentVerb,
			DestructiveIcon,
			destructiveOutlined,
			destructivePrompt,
			destructiveVerb,
			showDestructivePrompt,
			onDestroy,
		}) => {
			const [dialogVisible, setDialogVisible] = useState(false);

			return (
				<>
					<Button
						disabled={!isDirty || loading || sending || !errors}
						variant="contained"
						type="submit"
						startIcon={sending && <CircularProgress size={20} />}
						disableElevation
					>
						{sending ? submitPresentVerb : submitVerb}
					</Button>

					<BtConfirmDialog
						action={() => {
							setDialogVisible(false);
							reset();
							if (onDestroy) onDestroy();
							setOverrideBlocking(false);
						}}
						ActionIcon={DestructiveIcon}
						isDestructive
						onClose={() => setDialogVisible(false)}
						open={dialogVisible}
						prompt={destructivePrompt}
						title={`${destructiveVerb}?`}
						verb={destructiveVerb}
					/>
				</>
			);
		},
		(prevProps, nextProps) =>
			prevProps.formState.isDirty === nextProps.formState.isDirty &&
			prevProps.loading === nextProps.loading &&
			prevProps.sending === nextProps.sending
	)
);

BtAuthFormActionButtons.defaultProps = {
	// DestructiveIcon: <DeleteIcon />,
	// destructiveOutlined: true,
	destructivePrompt: "You will lose any changes you've made.",
	destructiveVerb: 'Discard Changes',
	showDestructivePrompt: true,
	SubmitIcon: SaveIcon,
	submitVerb: 'Submit',
	submitPresentVerb: 'Sending',
};

BtAuthFormActionButtons.propTypes = {
	// destructiveIcon: PropTypes.node,
	// destructiveOutlined: PropTypes.bool,
	destructivePrompt: PropTypes.string,
	destructiveVerb: PropTypes.string,
	onDestroy: PropTypes.func,
	showDestructiveDialog: PropTypes.bool,
	SubmitIcon: PropTypes.elementType,
	submitVerb: PropTypes.string,
	submitPresentVerb: PropTypes.string,
};

BtAuthFormActionButtons.displayName = 'AuthFormActionButtons';

export default BtAuthFormActionButtons;
