import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { AccountArrowLeftOutline, AccountPlusOutline } from 'mdi-material-ui';
import { useCallback, useContext, useRef } from 'react';
import BtIconRadio from '../../../../components/generic/BtIconRadio';
import BtMessage from '../../../../components/generic/BtMessage';
import {
	useWizard,
	WizardButtons,
	WizardHeading,
} from '../../../../components/wizard';
import { ScreenDistributionWizardContext } from './ScreenDistributionWizardContext';

export const DistributionMethod = () => {
	const contentRef = useRef(null);
	const { data, onClose, setData } = useContext(
		ScreenDistributionWizardContext
	);

	const { goToStep, isFirstStep, isLastStep, nextStep } = useWizard();

	const handleChange = useCallback(
		event => {
			const newData = structuredClone(data);
			newData.method = event.target.value;
			setData(newData);
		},
		[data, setData]
	);

	const handleGoForward = useCallback(
		() => {
			// Skip screen group selection if method is available
			if (data.method === 'assignTo') {
				nextStep();
			} else {
				goToStep(2);
			}
		},
		[data, goToStep, nextStep]
	);

	return (
		<>
			<DialogTitle>
				<WizardHeading
					onCancelClick={onClose}
					title="Select distribution method"
				/>
			</DialogTitle>
			<DialogContent ref={contentRef}>
				<BtMessage
					style={{ marginBottom: '1em' }}
					message="Assigning a screen allows you to specify which screen groups the screen is displayed in.  Making a screen available gives the user the choice to display the screen."
				/>
				<BtIconRadio
					value={data.method}
					onChange={handleChange}
					items={[
						{
							value: 'assignTo',
							label: 'Assign',
							icon: <AccountArrowLeftOutline />,
						},

						{
							value: 'availableTo',
							label: 'Make available',
							icon: <AccountPlusOutline />,
						},
					]}
					name="select_distribution_method"
				/>
			</DialogContent>
			<DialogActions>
				<WizardButtons
					onCancelClick={onClose}
					canGoForward={!!data.method}
					isFirstStep={isFirstStep}
					isLastStep={isLastStep}
					onNextClick={() => handleGoForward()}
				/>
			</DialogActions>
		</>
	);
};
