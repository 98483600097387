export default function serverUrl() {
	if (process.env.REACT_APP_API_SERVER_URL.includes('localhost')) {
		return process.env.REACT_APP_API_SERVER_URL.replace(
			'localhost',
			window.location.host.substring(0, window.location.host.length - 5)
		);
	} else {
		return process.env.REACT_APP_API_SERVER_URL;
	}
}
