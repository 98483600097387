import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs, { tabsClasses } from '@mui/material/Tabs';

const Container = styled('div')(({ theme }) => ({
	borderBottom: `2px solid ${
		theme.palette.mode === 'dark' ? '#ffffff3a' : '#0000001d'
	}`,
	boxSizing: 'border-box',
	marginBottom: 2,
}));

export function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

function TabPanel(props) {
	const { children, value, index, style, ...other } = props;

	return (
		<div
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box
					sx={{
						margin: 0,
						backgroundColor: theme =>
							theme.palette.background.paper,
					}}
					style={style}
				>
					{children}
				</Box>
			)}
		</div>
	);
}

export function BtTab(props) {
	return (
		<Tab
			{...props}
			style={{
				height: '48px',
			}}
			sx={{
				textTransform: 'none',
				minWidth: 100,
				fontWeight: theme => theme.typography.fontWeightRegular,
				fontSize: '1.5rem',
				marginBottom: '2px',
				padding: '13px 12px 11px 12px',
				borderTopLeftRadius: '4px',
				borderTopRightRadius: '4px',

				'&:hover': {
					backgroundColor: theme => theme.palette.action.hover,
					opacity: 1,
				},

				'&$selected': {
					fontWeight: theme => theme.typography.fontWeightMedium,
				},

				'&:focus': {
					color: theme => theme.palette.primary.shade_light,
				},
				minHeight: 0,
			}}
		/>
	);
}

export function BtTabView({ children, style, ...other }) {
	return (
		<div style={{ backgroundColor: 'transparent', ...style }} {...other}>
			{children}
		</div>
	);
}

export function BtTabPanel({ children, index, currentTab, style }) {
	return (
		<TabPanel
			value={currentTab}
			index={index}
			style={{ backgroundColor: 'transparent', ...style }}
		>
			{children}
		</TabPanel>
	);
}

export function BtTabBar({ currentTab, onTabChange, style, children }) {
	const theme = useTheme();

	const backgroundColor = useMemo(
		() => style?.backgroundColor ?? theme.palette.background.default,
		[style, theme]
	);

	return (
		<Container style={style}>
			<Tabs
				value={currentTab}
				onChange={onTabChange}
				aria-label="config edit"
				variant="scrollable"
				scrollButtons="auto"
				// allowScrollButtonsMobile
				sx={{
					backgroundColor: 'transparent',
					position: 'relative',
					transform: 'translateY(2px)',

					[`& .${tabsClasses.scrollButtons}`]: {
						height: 48,
						opacity: 1,
						position: 'fixed',
						color: theme =>
							`${theme.palette.secondary.text} !important`,
						width: 48,

						'&.Mui-disabled': { display: 'none' },

						'&:first-of-type': {
							background: `linear-gradient(90deg, ${backgroundColor}ff 0%, ${backgroundColor}dd 80%, ${backgroundColor}00 100%)`,
							left: 0,
							zIndex: 1,
						},

						'&:last-of-type': {
							background: `linear-gradient(270deg, ${backgroundColor}ff 0%, ${backgroundColor}dd 80%, ${backgroundColor}00 100%)`,
							right: 0,
						},
					},
				}}
			>
				{children}
			</Tabs>
		</Container>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

BtTabPanel.propTypes = {
	children: PropTypes.node,
	currentTab: PropTypes.any.isRequired,
	index: PropTypes.any.isRequired,
};

BtTabView.propTypes = { children: PropTypes.node, style: PropTypes.object };

BtTabBar.propTypes = {
	children: PropTypes.node,
	currentTab: PropTypes.any.isRequired,
	onTabChange: PropTypes.func.isRequired,
};
