import { Menu, IconButton, MenuItem, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import React, { memo, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useRecordManagementContext } from '../../RecordManagementContext';
import BtSelectionTable from '../../../BtSelectionTable';
import BtUserRow from '../../../BtUserRow';
import useBreakpointValue from '../../../../../hooks/useBreakpointValue';
import { Menu as MenuIcon } from '@mui/icons-material';
import { CheckBold } from 'mdi-material-ui';
import { moduleUrl } from '../../utils/lookupTables/moduleAssets';
import { TabPanelContainer, TabPanelTitle } from '../generic/TabLayout';
import {
	AUTOMATION,
	INSIGHT_COLLECTION,
	UPDATE_DRAFT,
	UPDATE_RECORD,
	WORKFLOW_TEMPLATE,
} from '../../utils/constants';

export const VersionsTable = memo(
	({ historyData, recordUuid, module, handleCallback }) => {
		const breakpoint = useBreakpointValue();
		const [clickedVersion, setClickedVersion] = useState(null);

		const [anchorEl, setAnchorEl] = useState(null);
		const open = Boolean(anchorEl);
		const handleOpenMenu = (event, version) => {
			event.stopPropagation();
			setClickedVersion(version);
			setAnchorEl(event.currentTarget);
		};

		const handleCloseMenu = useCallback(event => {
			setAnchorEl(null);
			setClickedVersion(null);
		}, []);

		const handleSetCurrentVersion = useCallback(
			event => {
				event.stopPropagation();
				handleCallback({
					callback: UPDATE_RECORD,
					payload: { current_version: clickedVersion },
				});
				handleCloseMenu();
			},
			[clickedVersion, handleCallback, handleCloseMenu]
		);

		const handleNewDraft = useCallback(
			event => {
				event.stopPropagation();
				handleCallback({
					callback: UPDATE_DRAFT,
					payload: { draft_base: clickedVersion },
				});
				handleCloseMenu();
			},
			[clickedVersion, handleCallback, handleCloseMenu]
		);

		const viewVersionRoute = useMemo(
			() => moduleUrl(module, 'viewVersion', recordUuid),
			[module, recordUuid]
		);

		const menuItems = useMemo(
			() => [
				{
					label: 'Set as current version',
					callback: handleSetCurrentVersion,
				},
				{
					label: 'New draft from version',
					callback: handleNewDraft,
				},
				// {
				// 	label: 'Publish draft',
				// 	callback: handleCloseMenu,
				// },
			],
			[handleNewDraft, handleSetCurrentVersion]
		);

		const versionHistory = useMemo(
			() => {
				// var to return
				let versionInfo;

				// add current property to version
				versionInfo = historyData?.versions.map((version, index) => {
					return {
						...version,
						current: index === historyData.current_version,
						index,
					};
				});

				return versionInfo;
			},
			[historyData]
		);

		// const handleEdit = uuid => event => {
		// 	event.stopPropagation();
		// 	// history.push(`/Workflow/Template/${uuid}`);
		// 	// history.push(`/Workflow/Template/${uuid}/settings`);

		// 	// setVersionOptionsClick
		// 	// open popover
		// };

		return (
			<>
				<BtSelectionTable
					// title={'Published Versions'}
					size="small"
					columns={[
						{ field: 'version', text: 'Version' },
						{
							field: 'publishedBy',
							text: 'Published By',
							minBreakpoint: 'md',
						},
						{
							field: 'publishDate',
							text: 'Publish Date',
							// minBreakpoint: 'sm',
						},
						{
							field: 'notes',
							text: 'Notes',
							minBreakpoint: 'sm',
						},
						{
							field: 'options',
							text: 'Options',
							// minBreakpoint: 'sm',
						},
						// {
						// 	field: 'currentVersion',
						// 	text: 'Current Version',
						// 	// minBreakpoint: 'sm',
						// },
					]}
					data={(versionHistory || [])
						.toReversed()
						.map(
							({
								publish_timestamp,
								publish_by,
								index,
								notes,
								current,
							}) => ({
								version: current ? (
									<Tooltip title="This is the current version">
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												gap: '5px',
											}}
										>
											{index + 1}
											<CheckBold
												fontSize="small"
												color="success"
											/>
										</div>
									</Tooltip>
								) : (
									index + 1
								),
								publishedBy:
									breakpoint === 'xs' ? (
										publish_by.user_name
									) : (
										<BtUserRow user={publish_by} />
									),
								publishDate: format(
									new Date(publish_timestamp || 0),
									'dd MMM yyyy, hh:mm a'
								),
								notes: notes,
								// route: `/insights/${historyData.uuid}/View/${index}`,
								route: viewVersionRoute + index,
								options: (
									<IconButton
										key={index}
										size="small"
										onClick={event =>
											handleOpenMenu(event, index)
										}
									>
										<MenuIcon />
									</IconButton>
								),
								// currentVersion:
								// 	index === historyData.current_version ? (
								// 		<CheckBold fontSize="large" color="primary" />
								// 	) : null,
							})
						)}
				/>
				<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={handleCloseMenu}
					MenuListProps={{
						'aria-labelledby': 'basic-button',
					}}
				>
					{menuItems.map(item => (
						<MenuItem onClick={item.callback} key={item.label}>
							{item.label}
						</MenuItem>
					))}
				</Menu>
			</>
		);
	}
);

const VersionControl = () => {
	const {
		historyData,
		recordUuid,
		module,
		handleCallback,
	} = useRecordManagementContext();
	return (
		<TabPanelContainer>
			<TabPanelTitle title="Version History" />
			<VersionsTable
				historyData={historyData}
				recordUuid={recordUuid}
				module={module}
				handleCallback={handleCallback}
			/>
		</TabPanelContainer>
	);
};

VersionsTable.prototypes = {
	historyData: PropTypes.object.isRequired,
	recordUuid: PropTypes.string.isRequired,
	module: PropTypes.oneOf([INSIGHT_COLLECTION, AUTOMATION, WORKFLOW_TEMPLATE])
		.isRequired,
	handleCallback: PropTypes.func.isRequired,
};

VersionsTable.displayName = 'VersionsTable';

export { VersionControl };
