import React, { useState, useEffect } from 'react';
import { useNavContext } from '../../context/ContextManager';

// API
import { devicesGetList } from '../../API';

// COMPONENTS
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';

import { useSnackbar } from 'notistack';

// CUSTOM COMPONENTS
import BtSelectionTable from '../../components/generic/BtSelectionTable';

// Data for landing page selection table and contextual navigation
import IoT_options from './IoTOptions';
import IoT_breadcrumbs from './IoTBreadcrumbs';
import IoT_nav_item from './IoTNavItem';

export default function Devices() {
	const [isLoading, setIsLoading] = useState(true);
	const [devices, setDevices] = useState([]);
	const [noDevices, setNoDevices] = useState(true);
	const { setBreadcrumbs } = useNavContext();
	const { setContextualNav } = useNavContext();
	const { enqueueSnackbar } = useSnackbar();

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([...IoT_breadcrumbs, { text: 'Devices', link: '' }]);
		},
		[setBreadcrumbs]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([...IoT_nav_item, ...IoT_options]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	useEffect(
		() => {
			const loadDevices = async () => {
				setIsLoading(true);
				try {
					let devices = await devicesGetList();

					// Add a route for the selection table to work
					devices = devices.map(device => {
						return {
							...device,
							route: '/device/' + device.uid,
						};
					});

					setDevices(devices);
					if (devices.length !== 0) {
						setNoDevices(false);
					} else {
						setNoDevices(true);
					}
				} catch (error) {
					console.log(error);
					enqueueSnackbar('Server connection error', {
						variant: 'error',
					});
				}
				setIsLoading(false);
			};

			loadDevices();
		},
		[enqueueSnackbar]
	);

	return (
		<>
			{isLoading && (
				<Container
					maxWidth="lg"
					sx={{ display: 'flex', flexDirection: 'column' }}
				>
					<Typography variant="h3" sx={{ m: 'auto' }}>
						Loading...
					</Typography>
					<CircularProgress sx={{ m: 'auto' }} size={'120px'} />
				</Container>
			)}

			{!isLoading && (
				<Container maxWidth="lg">
					{!noDevices && (
						<BtSelectionTable
							columns={[
								{ text: 'Device UID', field: 'uid' },
								{ text: 'Device Type', field: 'type' },
								{ text: 'Name', field: 'name' },
								{ text: 'Status', field: 'status' },
								{ text: 'Description', field: 'description' },
								{ text: 'RSSI', field: 'rssi' },
								{ text: 'RTT', field: 'rtt' },
								{ text: 'PKT Drop', field: 'pkt_drop' },
								{ text: 'Con Count', field: 'con_count' },
								{ text: 'Boot', field: 'boot_str' },
								{ text: 'Uptime', field: 'uptime' },
							]}
							data={devices}
						/>
					)}
					{noDevices && (
						<Typography variant="h3" sx={{ m: 'auto' }}>
							No Devices
						</Typography>
					)}
				</Container>
			)}
		</>
	);
}
