import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AddIcon from '@mui/icons-material/AddCircleOutline';
import Color from 'color';
import {
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	styled,
	Tooltip,
} from '@mui/material';

import BtPopover from '../../../../../components/generic/BtPopover';

const AddButton = styled('button')(({ theme }) => ({
	alignItems: 'center',
	alignSelf: 'center',
	backgroundColor: theme.palette.indicators.success.main,
	border: 'none',
	borderRadius: 9,
	display: 'flex',
	height: 18,
	justifyContent: 'center',
	padding: 0,
	width: 18,
	margin: 0,

	'&:hover': {
		backgroundColor: Color(theme.palette.indicators.success.main)
			.darken(0.1)
			.toString(),
	},

	'&:active': {
		backgroundColor: Color(theme.palette.indicators.success.main)
			.darken(0.15)
			.toString(),
	},

	'& line': {
		strokeWidth: 2,
	},
}));

export default function AddField({ fields, onAddField }) {
	const [addFieldAnchor, setAddFieldAnchor] = useState();

	return (
		<>
			<Tooltip
				disableInteractive
				style={{ marginRight: 5 }}
				title={`Add Field${(fields || []).length > 1 ? 's' : ''}`}
			>
				<AddButton
					onClick={({ currentTarget }) =>
						setAddFieldAnchor(currentTarget)
					}
				>
					<span style={{ height: 18, width: 18 }}>
						<svg height="18" width="18">
							<line
								x1="2"
								y1="9"
								x2="16"
								y2="9"
								stroke="#ffffff"
							/>
							<line
								x1="9"
								y1="2"
								x2="9"
								y2="16"
								stroke="#ffffff"
							/>
						</svg>
					</span>
				</AddButton>
			</Tooltip>
			<BtPopover
				anchorEl={addFieldAnchor}
				onClose={() => setAddFieldAnchor(null)}
				anchorOrigin={{
					horizontal: 'center',
					vertical: 'top',
				}}
				transformOrigin={{
					horizontal: 'center',
					vertical: 'top',
				}}
			>
				{(fields || []).length > 0 && (
					<List dense>
						{fields.map(field => (
							<ListItemButton
								key={field}
								onClick={() => onAddField(field)}
							>
								<ListItemIcon style={{ minWidth: 32 }}>
									<AddIcon />
								</ListItemIcon>
								<ListItemText primary={field} />
							</ListItemButton>
						))}
					</List>
				)}
			</BtPopover>
		</>
	);
}

AddField.propTypes = {
	fields: PropTypes.arrayOf(PropTypes.string),
	onAddField: PropTypes.func.isRequired,
};
