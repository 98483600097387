import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	FormControl,
	TextField,
} from '@mui/material';
import _ from 'lodash';

import {
	dataSourceGetList,
	workflowSubmissionGetList,
	workflowTemplateGetList,
} from '../../../../../../API';
import useFetch from '../../../../../../hooks/useFetch';

import {
	buttonShortcuts,
	buttonTypes,
	dataManagerConstants,
	tileVariants,
} from '../../../tileConstants';
import { useTileConfigContext } from '../TileConfigDialogContext';
import SelectItem from '../../formComponents/SelectItem';
import BtIconRadio from '../../../../BtIconRadio';
import { useContext } from 'react';
import { ScreensContext } from '../../../ScreensContext';
import { TileContext } from '../../TileContext';

const initTargets = (type, templateOptions, dataSourceOptions) => {
	// console.log({ type, templateOptions });
	switch (type) {
		case '':
			return [];
		case 'start_workflow':
			return templateOptions;
		case 'shortcut':
			return buttonShortcuts;
		case 'data_manager':
			return dataSourceOptions;
		default:
			return [];
	}
};

export default function ContainerButtonConfig({
	childTileProps,
	setChildTileProps,
	setIsFormValid,
	isNewTile,
}) {
	const [templateOptions, setTemplateOptions] = useState();
	const [dataSourceOptions, setDataSourceOptions] = useState();
	const [targets, setTargets] = useState();
	const [formReady, setFormReady] = useState(false);

	const { nativeEditMode, userButtonPerms } = useContext(ScreensContext);

	const { setError, error } = useContext(TileContext);

	//initial default form values
	const defaultValues = {
		button_type: 'shortcut',
		target: '',
		button_label: '',
	};

	//workflow template API request
	const {
		loading: templateListLoading,
		data: templateList,
		error: templateListError,
		request: getTemplateList,
	} = useFetch(workflowTemplateGetList);

	//trigger the template request
	useEffect(
		() => {
			if (userButtonPerms.start_workflow.available) getTemplateList();
		},
		[getTemplateList, userButtonPerms.start_workflow.available]
	);

	// data sources API request
	const {
		loading: dataSourceListLoading,
		data: dataSourceList,
		error: DataSourceListError,
		request: getDataSourceList,
	} = useFetch(dataSourceGetList);

	//trigger the data sources request
	useEffect(
		() => {
			if (userButtonPerms.data_manager.available) getDataSourceList();
		},
		[getDataSourceList, userButtonPerms.data_manager.available]
	);

	// console.log({ dataSourceList });

	// useEffect(
	// 	() => {
	// 		setNewButton(childTileProps);
	// 	},
	// 	[childTileProps]
	// );

	//validation schema
	const schema = yup
		.object({
			button_type: yup
				.string()
				.required()
				// .oneOf([buttonTypes.map(type => type.value)])
				.label('Button type'),
			target: yup
				.string()
				.required()
				// .oneOf([targets.map(type => type.value)])
				.label('Target'),
			button_label: yup
				.string()
				.required()
				.min(3)
				.max(20)
				.label('Button label'),
		})
		.required();

	//react hook form
	const {
		watch,
		control,
		setValue,
		formState: { isDirty, errors, isValid },
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: defaultValues,
		mode: 'onChange',
	});

	// catch the errors
	useEffect(
		() => {
			if (templateListError) {
				if (error) {
					const errorClone = structuredClone(error);
					errorClone[templateListError] = templateListError;
					setError(errorClone);
				} else {
					setError(templateListError);
				}
			} else if (DataSourceListError) {
				if (error) {
					const errorClone = structuredClone(error);
					errorClone[DataSourceListError] = DataSourceListError;
					setError(errorClone);
				} else {
					setError(DataSourceListError);
				}
			}
		},
		[DataSourceListError, error, setError, templateListError]
	);

	//initialize the target select options
	useEffect(
		() => {
			if (templateOptions && dataSourceOptions) {
				//set the target options - conditional on button's previous value
				setTargets(
					initTargets(
						childTileProps.data.button_type,
						templateOptions,
						dataSourceOptions
					)
				);

				// set the values of the form fields with previous values
				// this is done after setting the target select options so that the select value matches one of the options
				// console.log({ childTileProps });
				setValue('button_label', childTileProps.title);
				setValue('button_type', childTileProps.data.button_type);
				setValue('target', childTileProps.data.target.value);

				// set the form ready flag to true
				setFormReady(true);
			}
		},
		[templateOptions, dataSourceOptions]
	);

	//initialize the workflow template select options
	useEffect(
		() => {
			if (!userButtonPerms.start_workflow.available) {
				setTemplateOptions([]);
			} else {
				if (templateList) {
					//once we have the workflow templates data prepare templates select options
					const templates = templateList.map(template => ({
						value: template.uuid,
						label: template.name,
						description: template.description,
					}));
					//save these to state for later use
					setTemplateOptions(templates);
				}
			}
		},
		[
			templateList,
			templateListLoading,
			userButtonPerms.start_workflow.available,
		]
	);

	//initialize the data source select options
	useEffect(
		() => {
			if (!userButtonPerms.data_manager.available) {
				setDataSourceOptions([]);
			} else {
				if (dataSourceList) {
					//once we have the workflow templates data prepare templates select options
					const sources = dataSourceList.map(source => ({
						value: source.uuid,
						label: source.name,
						description: source.type,
						additionalText: source.description,
					}));
					//save these to state for later use
					setDataSourceOptions(sources);
				}
			}
		},
		[
			dataSourceList,
			dataSourceListLoading,
			userButtonPerms.data_manager.available,
		]
	);

	// watch the button type value for changes
	const buttonTypeValue = watch('button_type');
	useEffect(
		() => {
			// console.log({ buttonTypeValue, isDirty });
			// if (isDirty || buttonTypeValue === 'start_workflow') {
			//update the tile update data with current button type value
			const tileUpdate = structuredClone(childTileProps);
			tileUpdate.data.button_type = buttonTypeValue;
			setChildTileProps(tileUpdate);
			console.log({ buttonTypeValue, templateOptions });
			// set the target select options and form value as per button_type value
			switch (buttonTypeValue) {
				case 'start_workflow':
					setValue('target', '');
					setTargets(templateOptions);
					break;
				case 'shortcut':
					setValue('target', '');
					setTargets(buttonShortcuts);
					break;
				case 'data_manager':
					setValue('target', '');
					setTargets(dataSourceOptions);
					break;
				default:
					break;
			}
			// }
		},
		[buttonTypeValue]
	);

	//watch the target value
	const targetValue = watch('target');
	useEffect(
		() => {
			if (formReady && isDirty) {
				//update the tile update data with current button target value
				const tileUpdate = structuredClone(childTileProps);
				//setting target.label to an empty string if target value is empty
				if (targetValue === '') {
					tileUpdate.data.target = {
						value: targetValue,
						label: '',
					};
				} else {
					tileUpdate.data.target = {
						value: targetValue,
						label: targets.find(item => item.value === targetValue)
							.label,
					};
				}
				setChildTileProps(tileUpdate);

				// initialize the button label value
				if (targetValue.length > 0) {
					switch (buttonTypeValue) {
						case 'start_workflow':
							setValue(
								'button_label',
								`Launch ${
									_.find(targets, { value: targetValue })
										.label
								}`,
								// '',
								{ shouldValidate: true }
							);
							break;
						case 'shortcut':
							setValue(
								'button_label',
								// '',
								`${
									_.find(targets, { value: targetValue })
										.label
								}`,
								{ shouldValidate: true }
							);
							break;
						case 'data_manager':
							setValue(
								'button_label',
								`${
									_.find(targets, { value: targetValue })
										.label
								}`,
								{ shouldValidate: true }
							);
							break;
						default:
							setValue('button_label', '');
							break;
					}
				} else {
					setValue('button_label', '');
				}
			}
		},
		[targetValue]
	);

	const labelValue = watch('button_label');
	useEffect(
		() => {
			//update the tile update data with current label value
			// console.log('label change', labelValue);
			const tileUpdate = structuredClone(childTileProps);
			tileUpdate.title = labelValue;
			setChildTileProps(tileUpdate);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[labelValue]
	);

	// useEffect(
	// 	() => {
	// 		console.log({ targets });
	// 	},
	// 	[targets]
	// );

	// useEffect(
	// 	() => {
	// 		console.log({ childTileProps });
	// 	},
	// 	[childTileProps]
	// );

	// useEffect(
	// 	() => {
	// 		console.log({ errors, isValid });
	// 	},
	// 	[errors, isValid]
	// );

	const inputStyle = {
		height: 65,
		// paddingHorizontal: 5,
		marginTop: 5,
	};

	const handleChange = useCallback(
		event => {
			setValue('button_type', event.target.value, {
				shouldValidate: true,
				shouldDirty: true,
				shouldTouch: true,
			});
		},
		[setValue]
	);

	// useEffect(
	// 	() => {
	// 		console.log({ isValid });
	// 	},
	// 	[isValid]
	// );

	useEffect(
		() => {
			if (isNewTile) {
				setIsFormValid(isValid);
			} else {
				if (isValid && isDirty) {
					if (
						childTileProps.title === labelValue &&
						childTileProps.data.target.value === targetValue &&
						childTileProps.data.button_type === buttonTypeValue
					) {
						setIsFormValid(false);
					} else {
						setIsFormValid(isValid && isDirty);
					}
				} else {
					setIsFormValid(isValid && isDirty);
				}
			}
		},
		[
			isValid,
			isDirty,
			labelValue,
			targetValue,
			buttonTypeValue,
			childTileProps,
			setIsFormValid,
			isNewTile,
		]
	);
	// console.log({ nativeEditMode });
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				// paddingLeft: 10,
				// paddingRight: 10,
			}}
		>
			{formReady && (
				<>
					<BtIconRadio
						value={watch('button_type')}
						onChange={handleChange}
						items={buttonTypes
							.filter(
								buttonType =>
									userButtonPerms[buttonType.value].available
							)
							.map(type => ({
								...type,
								disabled:
									(type.value === 'data_manager' &&
										(dataSourceList || []).length === 0) ||
									(['data_manager', 'shortcut'].includes(
										type.value
									) &&
										nativeEditMode),
								label:
									type.value === 'data_manager' &&
									(dataSourceList || []).length === 0
										? 'No data sources available'
										: type.label,
							}))}
						name="button_type"
						label="Select button type"
					/>
					<Controller
						name="target"
						// defaultValue={level}
						control={control}
						render={({ field, fieldState: { error } }) => (
							<FormControl
								variant="standard"
								// sx={{ m: 1, minWidth: 120 }}
								style={inputStyle}
								error={!!error}
								disabled={buttonTypeValue === ''}
							>
								<InputLabel id="target-label">
									Target
								</InputLabel>
								<Select {...field}>
									{targets.map(type => (
										<MenuItem
											key={type.value}
											value={type.value}
										>
											<SelectItem
												label={type.label}
												description={type.description}
												additionalText={
													type.additionalText
												}
											/>
										</MenuItem>
									))}
								</Select>
								{error && (
									<FormHelperText>
										{error.message}
									</FormHelperText>
								)}
							</FormControl>
						)}
					/>
					<Controller
						render={({ field, fieldState: { error } }) => (
							<TextField
								{...field}
								style={inputStyle}
								error={!!error}
								variant="standard"
								helperText={error?.message}
								label="Button label"
								disabled={targetValue === ''}
							/>
						)}
						name="button_label"
						control={control}
					/>
				</>
			)}
		</div>
	);
}
