const yup = require('yup');

export const dataSetGetListSchema = yup.array().of(
	yup
		.object({
			uuid: yup
				.string()
				.uuid()
				.required(),
			name: yup.string().default(''),
			description: yup.string().default(''),
			create_timestamp: yup.number().required(),
			modify_timestamp: yup.number().required(),
			create_by: yup
				.string()
				.uuid()
				.required(),
			modify_by: yup
				.string()
				.uuid()
				.required(),
		})
		.nullable()
);

export const dataSetGetSchema = yup.object({
	uuid: yup
		.string()
		.uuid()
		.required(),
	name: yup.string().default(''),
	description: yup.string().default(''),
	create_timestamp: yup.number().required(),
	modify_timestamp: yup.number().required(),
	create_by: yup
		.string()
		.uuid()
		.required(),
	modify_by: yup
		.string()
		.uuid()
		.required(),
});

export const dataSetAddSchema = yup.object({
	name: yup.string().default(''),
	description: yup.string().default(''),
	primaryTimeKey: yup.string().nullable(),
	primaryValueKey: yup
		.array()
		.required()
		.of(yup.string()),
});

export const dataSetAddResponseSchema = yup.object({
	uuid: yup
		.string()
		.uuid()
		.required(),
	name: yup.string().default(''),
	description: yup.string().default(''),
	create_timestamp: yup.number().required(),
	modify_timestamp: yup.number().required(),
	create_by: yup
		.string()
		.uuid()
		.required(),
	modify_by: yup
		.string()
		.uuid()
		.required(),
});

export const dataSetUpdateSchema = yup.object({
	name: yup.string().required(),
	description: yup.string().default(''),
	schema: yup.array().of(yup.array()),
	config: yup.object(),
});
