import React from 'react';

const Grid = ({ children, columns, gap = '10px' }) => {
	return (
		<div
			// style={{
			// 	display: 'grid',
			// 	gridTemplateColumns: `repeat(${columns}, 1fr)`,
			// 	gridGap: 10,
			// 	maxWidth: '800px',
			// 	margin: '100px auto',
			// }}
			style={{
				display: 'flex',
				flexWrap: 'wrap',
				gap: gap,
				maxWidth: '800px',
			}}
		>
			{children}
		</div>
	);
};

export default Grid;
