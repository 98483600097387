import React, { useCallback, useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';
import { Box, Tooltip, IconButton, Divider } from '@mui/material';
import { styled, useTheme } from '@mui/styles';
import { useAppContext } from '../../../../context/ContextManager';
import {
	Close,
	Pencil,
	Plus,
	FileEditOutline,
	FilePlusOutline,
	FileRemoveOutline,
} from 'mdi-material-ui';
import DesktopIcon from '@mui/icons-material/DesktopMac';
import TabletIcon from '@mui/icons-material/TabletMac';
import PhoneIcon from '@mui/icons-material/PhoneIphone';

import Icon from '@mdi/react';
import InsightPageDialog from '../UiComponents/Dialogs/InsightPageDialog';
import { VIS_LIST } from '../../InsightPage/visualisationConstants';
import InsightPageDeleteDialog from '../UiComponents/Dialogs/InsightPageDeleteDialog';

const ControlContainer = styled('div')(
	({ style, theme, direction = 'column' }) => ({
		display: 'flex',
		flexDirection: direction,
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: 5,
		// width: 110,
		// height: 40,
		borderRadius: 20,
		backgroundColor: theme.palette.primary.light,
		...style,
	})
);

const StyledIconButton = styled(IconButton)(({ theme, style, small }) => ({
	height: small ? 25 : 30,
	width: small ? 25 : 30,
	color: 'white',
	backgroundColor: theme.palette.primary.light,
	'&:hover': {
		backgroundColor: theme.palette.primary.dark,
	},
	'&:disabled': {
		// color: 'white',
	},
	...style,
}));

const NewTileContainer = styled('div')(({ theme, style }) => ({
	backgroundColor: theme.palette.primary.main,
	// height: 47.5,
	// width: 47.5,
	// height: 64,
	// width: 64,
	height: 65,
	width: 65,
	borderRadius: 15,
	marginBottom: 5,
	padding: 5,
	'&:hover': {
		backgroundColor: theme.palette.primary.dark,
	},
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	...style,
}));

const ControlButton = React.forwardRef((props, ref) => {
	return <StyledIconButton {...props} ref={ref} />;
});

function EditMenu({
	onDragStart,
	onAddPage,
	onEditPage,
	onDeletePage,
	noPages,
}) {
	const [showAddMenu, setShowAddMenu] = useState(false);

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'flex-end',
				paddingBottom: 10,
			}}
		>
			<ControlContainer
				style={{
					// width: editEnabled ? 100 : 'auto'
					width: 'auto',
					display: 'flex',
					alignItems: 'flex-end',
				}}
			>
				{showAddMenu && (
					<>
						{_.chunk(VIS_LIST, 2).map((chunk, index) => (
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'flex-start',
									width: '100%',
								}}
								key={index}
							>
								{chunk.map((variant, index) => (
									<Tooltip
										arrow
										title={variant.name}
										key={variant.name}
									>
										<NewTileContainer
											style={{
												marginRight:
													index === 0 ? 5 : 0,
											}}
											key={variant.name}
											draggable={true}
											onDragStart={onDragStart(
												variant.type
											)}
										>
											{/* <IconResolver
													type={VIS_LIST[variant].type}
												/> */}

											<Icon
												path={variant.icon}
												size={1.5}
												color="white"
											/>
										</NewTileContainer>
									</Tooltip>
								))}
							</div>
						))}
						<Divider
							style={{
								width: '100%',
								marginBottom: 5,
								borderColor: 'white',
							}}
							orientation="horizontal"
						/>
					</>
				)}
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						gap: '5px',
					}}
				>
					{/* <span> */}
					<Tooltip title="Add Component" arrow>
						<span>
							<ControlButton
								disabled={noPages}
								onClick={() => {
									setShowAddMenu(!showAddMenu);
								}}
							>
								{showAddMenu ? <Close /> : <Plus />}
							</ControlButton>
						</span>
					</Tooltip>
					{/* </span> */}

					<Tooltip title="Configure Page" arrow>
						<span>
							<ControlButton
								onClick={onEditPage}
								disabled={noPages}
							>
								<FileEditOutline />
							</ControlButton>
						</span>
					</Tooltip>

					<Tooltip title="Add Page" arrow>
						<span>
							<ControlButton onClick={onAddPage}>
								<FilePlusOutline />
							</ControlButton>
						</span>
					</Tooltip>
					<Tooltip title="Delete Page" arrow>
						<span>
							<ControlButton
								disabled={noPages}
								onClick={onDeletePage}
							>
								<FileRemoveOutline />
							</ControlButton>
						</span>
					</Tooltip>
				</div>
			</ControlContainer>
		</div>
	);
}

export default function EditControls({
	pageProperties,
	onAddPage,
	onDragStart,
	onPageEdit,
	onPageDelete,
	noPages,
	pages,
}) {
	const theme = useTheme();
	const [showPageControls, setShowPageControls] = useState(false);
	const [showPageDialog, setShowPageDialog] = useState(false);
	// const [showCollectionDialog, setShowCollectionDialog] = useState(false);
	const [showDisplaySwitch, setShowDisplaySwitch] = useState(false);
	const [editPageDetails, setEditPageDetails] = useState(null);
	const [showPageDeleteDialog, setShowPageDeleteDialog] = useState(false);

	const { deviceType } = useAppContext();

	const handleMouseLeave = () => {
		setShowPageControls(false);
	};

	const handleEditPage = () => {
		// const currentPage = _.find(pages, { uuid: currentPageId });
		setEditPageDetails({
			// uuid: currentPage.uuid,
			name: pageProperties.name,
			description: pageProperties.description,
		});
		setShowPageDialog(true);
	};

	const pageDeleteOptions = useMemo(
		() => {
			return pages
				? pages.map(({ uuid, name }) => ({ label: name, value: uuid }))
				: [];
		},
		[pages]
	);

	return (
		<div
			style={{
				width: 'auto',
				display: 'flex',
				flexDirection: 'column',
				position: 'fixed',
				bottom: deviceType === 'desktop' ? 50 : 20,
				right: deviceType === 'desktop' ? 50 : 20,
			}}
			onMouseLeave={handleMouseLeave}
		>
			{showPageControls && (
				<EditMenu
					onDragStart={onDragStart}
					onAddPage={() => {
						setEditPageDetails(null);
						setShowPageDialog(true);
					}}
					onEditPage={handleEditPage}
					onDeletePage={() => setShowPageDeleteDialog(true)}
					noPages={noPages}
				/>
			)}
			<div>
				<Tooltip title="Page Settings" arrow>
					<IconButton
						sx={{
							display: 'flex',
							float: 'right',
							alignItems: 'center',
							justifyContent: 'space-between',
							backgroundColor: theme.palette.primary.light,
							'&:hover': {
								backgroundColor: theme.palette.primary.dark,
							},
						}}
						onClick={() => {
							setShowPageControls(!showPageControls);
						}}
					>
						<Pencil fontSize="large" style={{ color: 'white' }} />
					</IconButton>
				</Tooltip>
			</div>
			<div style={{ paddingTop: '10px' }}>
				<Tooltip title="Display Type" arrow>
					<IconButton
						sx={{
							display: 'flex',
							float: 'right',
							alignItems: 'center',
							justifyContent: 'space-between',
							backgroundColor: theme.palette.primary.light,
							'&:hover': {
								backgroundColor: theme.palette.primary.dark,
							},
						}}
						onClick={() => {
							setShowDisplaySwitch(!showDisplaySwitch);
						}}
					>
						<DesktopIcon
							fontSize="large"
							style={{ color: 'white' }}
						/>
					</IconButton>
				</Tooltip>
			</div>
			<InsightPageDialog
				open={showPageDialog}
				onClose={() => {
					setShowPageDialog(false);
				}}
				currentPageDetails={editPageDetails}
				onPageAdd={onAddPage}
				onPageEdit={onPageEdit}
			/>
			<InsightPageDeleteDialog
				open={showPageDeleteDialog}
				onClose={() => {
					setShowPageDeleteDialog(false);
				}}
				pages={pageDeleteOptions}
				onPageDelete={onPageDelete}
			/>
		</div>
	);
}

EditMenu.propTypes = {
	onAddPage: PropTypes.func.isRequired,
	onDragStart: PropTypes.func.isRequired,
	onEditPage: PropTypes.func.isRequired,
	onDeletePage: PropTypes.func.isRequired,
	noPages: PropTypes.bool,
};

EditControls.propTypes = {
	pageProperties: PropTypes.shape({
		name: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
	}).isRequired,
	onAddPage: PropTypes.func.isRequired,
	onDragStart: PropTypes.func.isRequired,
	onPageEdit: PropTypes.func.isRequired,
	onPageDelete: PropTypes.func.isRequired,
	noPages: PropTypes.bool,
	pages: PropTypes.array.isRequired,
};
