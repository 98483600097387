import React, { useCallback, useEffect } from 'react';
import { useNavContext, useAppContext } from '../../context/ContextManager';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router';
import BtSelectionTable from '../../components/generic/BtSelectionTable';
import FormContainer from './FormContainer';
import user_settings_options from './UserSettingsOptions';
import { user_settings_nav_item } from './UserSettingsNavItem';
import { setAppStoreItem } from '../../utils/appStore';

const tableColumns = [
	{ field: 'icon', text: '' },
	{ field: 'text', text: '' },
	{ field: 'description', text: '', minBreakpoint: 'sm' },
];

export default function UserSettings() {
	const history = useHistory();

	const { userHasAuthenticated, setLoadApp, setDevUser } = useAppContext();

	// navContext breadcrumbs
	const { setBreadcrumbs, setContextualNav } = useNavContext();

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'User Settings', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([
				...user_settings_nav_item,
				...user_settings_options,
			]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	const handleLogoutClick = useCallback(
		() => {
			Auth.signOut().then(async () => {
				setDevUser(null);
				userHasAuthenticated(false);
				setLoadApp(false);
				await setAppStoreItem('DevUser', null);
				// setAppOrg(null);
				// setUserInfo(null);
			});
		},
		[setDevUser, setLoadApp, userHasAuthenticated]
	);

	const onClickRow = useCallback(
		id => {
			history.push(`${id}`);
		},
		[history]
	);

	return (
		<FormContainer
			text="User Settings"
			buttonText="logout"
			onClick={handleLogoutClick}
			// maxWidth="md"
		>
			<BtSelectionTable
				subject="User Settings"
				columns={tableColumns}
				onClick={onClickRow}
				data={user_settings_options.map(
					({ id, text, icon, route, description }) => ({
						id: route,
						text,
						icon,
						description,
					})
				)}
			/>
		</FormContainer>
	);
}
