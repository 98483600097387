import { blue, green, grey } from '@mui/material/colors';
import { createTheme, darken, responsiveFontSizes } from '@mui/material/styles';

let baseTheme = createTheme({
	components: {
		MuiTableCell: {
			styleOverrides: {
				root: {
					borderBottom: '0px',
				},
			},
		},
		MuiDrawer: {
			styleOverrides: {
				paper: {
					border: '0px',
					backgroundColor: 'transparent',
				},
			},
		},
		MuiTypography: {
			styleOverrides: {
				h3: {
					fontWeight: 'bold',
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					// borderRadius: '0.5em',
				},
			},
		},
		MuiCardHeader: {
			styleOverrides: {
				title: {
					fontWeight: '700',
				},
			},
		},
	},

	typography: {
		/*htmlFontSize:10,*/
		fontSize: 20,
		useNextVariants: true,
	},
	shape: {
		borderRadius: 8,
	},
	overrides: {
		MuiContainer: {
			root: {
				marginTop: '2rem',
				//marginRight:0,
			},
		},
		MuiFormControl: {
			root: {
				margin: '1rem 0rem',
			},
		},
		MuiTable: {},
		MuiTableCell: {
			root: {
				padding: '0 1rem',
				border: '0',
			},
		},
	},

	recordStatus: {
		color: {
			Unpublished: blue,
			Active: green,
			Inactive: grey,
		},
		shade: {
			background: 100,
			icon: 700,
			text: 900,
		},
	},

	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1280,
			xl: 1920,
		},
	},
});

baseTheme = responsiveFontSizes(baseTheme);

const secondaryText = {
	light: '#42878c',
	dark: '#7fd3cf',
};

const light_colors = {
	line_color: '#dfdfdf',
	hover: 'rgba(95,193,201,0.11)',
	active_item: 'rgba(122,122,122,0.2)',
	overlay: '#ffffff',
	background: '#ffffff',
};

const light_border = '1px solid';

const dark_colors = {
	background: '#121212',
	overlay: 'rgba(125,125,125,0.1)',
	header_overlay: 'rgba(255,255,255,0.12)',
	hover: '#1e3132',
	primary: '#5fc1c9', // blue
	secondary: '#23c97e', // green
	text_icons: '#f9f9f9',
};

const darkTheme = createTheme({
	...baseTheme,
	palette: {
		mode: 'dark',
		primary: {
			main: '#5fc1c9', // blue
			contrastText: '#fff',
		},
		secondary: {
			// main: '#5c8ec1',
			main: '#23c97e', // green
			text: secondaryText.dark,
		},
		alternate_1: {
			// main: 'transparent',
			main: '#236ec9',
			light: '#4f8bd3',
			dark: '#184d8c',
		},
		alternate_2: {
			// main: 'transparent',
			main: '#c92b23',
			light: '#d3554f',
			dark: '#8c1e18',
		},
		background: {
			default: dark_colors.background,
			paper: baseTheme.palette.grey[900],
			paperContrast: baseTheme.palette.grey[900],
			card: dark_colors.overlay,
			insights: dark_colors.background,
		},
		text: {
			primary: '#f9f9f9',
			secondary: 'rgba(255, 255, 255, 0.7)',
			solid: '#ffffff',
			disabled: 'rgba(255, 255, 255, 0.5)',
			hint: 'rgba(255, 255, 255, 0.5)',
			icon: '#f9f9f9',
			red: 'rgba(255,69,0,1)',
			green: 'rgba(50,172,93,1)',
			blue: 'rgba(90,140,230,1)',
			purple: 'rgba(180,64,180,1)',
		},
		action: {
			hover: '#1e3132',
			selected: '#AD55A6',
			hoverBorder: '#2A949B',
		},
		// divider: 'rgba(0,0,0,0.0)',
		divider: baseTheme.palette.grey[800],

		insightCollectionDivider: 'rgba(255, 255, 255, 0.227)',
		// Used by `getContrastText()` to maximize the contrast between the background and the text.
		contrastThreshold: 3,
		// Used by the functions below to shift a color's luminance by approximately two indexes within its tonal palette.
		// E.g., shift from Red 500 to Red 300 or Red 700.
		tonalOffset: 0.2,
		indicators: {
			info: {
				main: '#2196f3',
				text: '#349ef4',
			},
			success: {
				main: '#43a047',
				text: '#47a94c',
			},
			warning: {
				main: '#ff9800',
				text: '#ff9800',
				shade: '#663d00',
			},
			error: {
				main: '#d32f2f',
				text: '#e27878',
			},
		},
		insightsChipDisabled: {
			main: baseTheme.palette.grey[800],
			contrastText: baseTheme.palette.grey[400],
		},
		solidErrorHighlight: '#2a0909',
		colourPicker: {
			blue: '#0CAEF2',
			green: '#12DB00',
			purple: '#9207EB',
			red: '#E82A0C',
			yellow: '#F2BD0C',
		},
		insightsChips: {
			backgroundColor: '#5fc1c955',
			hover: 'rgba(94,193,201,0.5)',
			active: 'rgba(94,193,201,0.1)',
		},
		popperArrow: {
			backgroundColor: '#2c2c2c',
		},
		dataTypes: {
			array: '#f53da8',
			boolean: '#ffad0a',
			date: '#8873ff',
			datetime: '#8873ff',
			null: '#777777',
			number: '#34d58a',
			object: '#fa4a32',
			string: '#66a1ff',
			time: '#8873ff',
			undefined: '#777777',
		},
		highlight: {
			info: '#2196f320',
			success: '#43a04720',
			warning: '#ff980020',
			error: '#d32f2f20',
		},
		orange: '#e8760c',
		purple: '#8a34f4',
		chart: {
			gridColor: '#F5F5F510',
			// tick: '#f9f9f9',
			tick: baseTheme.palette.grey[500],
			label: baseTheme.palette.grey[400],
		},
		comment: '#3f3f3f',
		buttonDisabled: '#3c3c3c',
		exactness: {
			strict: '#ff294d',
			standard: '#f242ff',
			loose: '#a361ff',
		},
		lines: '#353535',
	},
	components: {
		MuiTableContainer: {
			styleOverrides: {
				root: {
					// backgroundColor: dark_colors.grey900,
					// backgroundColor: dark_colors.overlay,
					// border: '0px',
					// borderRadius: '0.5em',
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: { border: 'none' },
				head: {
					fontWeight: 'bold',
				},
				body: {
					borderBottom: '1px solid #7775',
				},
			},
		},
		MuiTableBody: {
			styleOverrides: {
				root: {
					borderTop: '1px solid #7775',
				},
			},
		},
		// MuiTableHead: {
		// 	styleOverrides: {
		// 		root: {
		// 			backgroundColor: dark_colors.header_overlay,
		// 		},
		// 	},
		// },
		// MuiTableFooter: {
		// 	styleOverrides: {
		// 		root: {
		// 			backgroundColor: dark_colors.header_overlay,
		// 		}
		// 	}
		// },
		MuiDrawer: {
			styleOverrides: {
				paper: {
					border: '0px',
					backgroundColor: dark_colors.background,
				},
			},
		},
		MuiDialog: {
			styleOverrides: {
				paper: {
					backgroundImage: 'none',
				},
			},
		},
		MuiCard: {
			styleOverrides: {
				root: {
					backgroundColor: dark_colors.overlay,
					border: '0px',
				},
			},
		},
		MuiCardHeader: {
			styleOverrides: {
				root: {
					backgroundColor: dark_colors.header_overlay,
					border: '0px',
				},
			},
		},
		MuiCardActions: {
			styleOverrides: {
				root: {
					justifyContent: 'center',
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					color: '#ffffffce',

					'&.Mui-selected': {
						color: `${secondaryText.dark}`,
					},

					'&:hover': {
						backgroundColor: 'rgba(255, 255, 255, 0.08) !important',
					},
				},
			},
		},
		MuiToggleButton: {
			styleOverrides: {
				root: {
					borderWidth: '0px',
					margin: '0px 0px 0px 0px',
					borderColor: dark_colors.overlay,
					backgroundColor: dark_colors.overlay,
					'&:hover': {
						backgroundColor: '#1e3132',
					},
					'&:focus': {
						background: dark_colors.header_overlay,
						border: '0px',
						'&:hover': {
							backgroundColor: '#1e3132',
						},
					},
					'&.Mui-disabled': {
						borderWidth: '0px',
					},
					'&.Mui-selected': {
						background: dark_colors.header_overlay,
						'&:hover': {
							backgroundColor: '#1e3132',
						},
					},
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					'&.Mui-focused': {
						color: '#23c97e',
					},
				},
			},
		},
		MuiToggleButtonGroup: {
			styleOverrides: {
				root: {
					borderWidth: '1px',
					borderColor: '#23c97e',
				},
			},
		},
		MuiInput: {
			styleOverrides: {
				root: {
					'&.Mui-focused': {
						'&.Mui-focused fieldset': {},
					},
					'&:hover ': {
						// backgroundColor: dark_colors.hover,
						'&:hover fieldset': {},
					},
				},
				underline: {
					'&:before': {
						// borderBottomColor: '#121212', // inactive underline
					},
					'&:after': {
						// borderBottomColor: dark_colors.secondary, // green underline on focus
					},
				},
				input: {
					// -------------------------
					// -------------------------
					// TODO - check if this kebab case is supported - see error in console
					// -------------------------
					// -------------------------
					// Required to control colour of input when browser autofill is used
					'&:-webkit-autofill': {
						WebkitBoxShadow: '0 0 0 1000px #242424 inset',
						WebkitTextFillColor: dark_colors.text_icons,
						// '-webkit-box-shadow': '0 0 0 1000px #242424 inset',
						// '-webkit-text-fill-color': dark_colors.text_icons,
					},
				},
			},
		},
		MuiPaginationItem: {
			styleOverrides: {
				root: {
					'&.Mui-selected': {
						backgroundColor: baseTheme.palette.grey[800],
						'&:hover': {
							backgroundColor: dark_colors.header_overlay,
						},
					},
				},
			},
		},
	},
});

const lightTheme = createTheme({
	...baseTheme,

	palette: {
		mode: 'light',
		primary: {
			// main: '#5fc1c9',
			// main: '#43868c',
			main: '#50a4aa',
			contrastText: '#fff',
		},
		secondary: {
			// buttons only?
			main: '#23c97e',
			// main: '#23c97e',
			text: secondaryText.light,
		},
		alternate_1: {
			// main: 'transparent',
			main: '#236ec9',
			light: '#4f8bd3',
			dark: '#184d8c',
		},
		alternate_2: {
			// main: 'transparent',
			main: '#c92b23',
			light: '#d3554f',
			dark: '#8c1e18',
		},
		background: {
			default: light_colors.background,
			// navbar and header
			// paper:'#f5f8fb',
			paper: light_colors.background,
			paperContrast: 'rgba(230,230,230,1)',
			card: light_colors.overlay,
			// insights: 'rgba(000, 000, 000, 0.03)',
			insights: '#f9f9f9',
		},
		text: {
			// primary: '#666f76',
			primary: 'rgba(77,77,77,1)',
			secondary: 'rgba(000, 000, 000, 0.7)',
			solid: '#000000',
			disabled: 'rgba(0, 0, 0, 0.5)',
			hint: 'rgba(0, 0, 0, 0.5)',
			red: 'rgba(255,69,0,1)',
			green: 'rgba(30,132,73,1)',
			blue: 'rgba(70,110,200,1)',
			purple: 'rgba(140,32,140,1)',
		},
		action: {
			hover: 'rgba(95,193,201,0.11)',
			hoverBorder: '#B9E9EB',
		},
		divider: light_colors.line_color,
		insightCollectionDivider: light_colors.line_color,
		popperArrow: {
			backgroundColor: '#ffffff',
		},
		dataTypes: {
			array: '#e069aC',
			boolean: '#f5aa14',
			date: '#725ee0',
			datetime: '#725ee0',
			null: '#777777',
			number: '#4eba87',
			object: '#ec515b',
			string: '#538ae0',
			time: '#725ee0',
			undefined: '#777777',
		},

		// divider: 'rgba(255,0,0,1)',
		// Used by `getContrastText()` to maximize the contrast between the background and the text.
		contrastThreshold: 3,
		// Used by the functions below to shift a color's luminance by approximately two indexes within its tonal palette.
		// E.g., shift from Red 500 to Red 300 or Red 700.
		tonalOffset: 0.2,
		indicators: {
			info: {
				main: '#2196f3',
				text: '#08599b',
			},
			success: {
				main: '#43a047',
				text: '#2a652a',
			},
			warning: {
				main: '#ff9800',
				text: '#804d00',
				shade: '#ffe0b3',
			},
			error: {
				main: '#d32f2f',
				text: '#a82424',
			},
		},
		insightsChipDisabled: {
			main: baseTheme.palette.grey[200],
			contrastText: baseTheme.palette.grey[700],
			switchTrack: baseTheme.palette.grey[400],
		},
		insightsChips: {
			backgroundColor: '#5fc1c955',
			hover: 'rgba(94,193,201,0.5)',
			active: 'rgba(94,193,201,0.1)',
		},
		solidErrorHighlight: '#fceeee',
		colourPicker: {
			blue: '#0CAEF2',
			green: '#12DB00',
			purple: '#9207EB',
			red: '#E82A0C',
			yellow: '#F2BD0C',
		},
		highlight: {
			info: '#2196f320',
			success: '#43a04720',
			warning: '#ff980020',
			error: '#d32f2f20',
		},
		orange: '#e9770c',
		purple: '#910ce9',
		chart: {
			gridColor: baseTheme.palette.grey[200],
			tick: baseTheme.palette.grey[600],
			label: baseTheme.palette.grey[800],
		},
		comment: '#f6f6f6',
		buttonDisabled: '#e0e0e0',
		exactness: {
			strict: '#e80c32',
			standard: '#cd00db',
			loose: '#6300eb',
		},
		lines: '#d9d9d9',
	},
	components: {
		MuiTableContainer: {
			styleOverrides: {
				root: {
					// border: light_border,
					// borderColor: light_colors.line_color,
					// borderRadius: '0.5em',
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: { border: 'none' },
				head: {
					fontWeight: 'bold',
				},
				body: {
					borderBottom: '1px solid #7773',
				},
			},
		},
		MuiTableBody: {
			styleOverrides: {
				root: {
					borderTop: '1px solid #7773',
				},
			},
		},
		MuiTableHead: {
			styleOverrides: {
				root: {},
			},
		},
		MuiDrawer: {
			styleOverrides: {
				paper: {
					border: '0px',
				},
			},
		},
		MuiCardHeader: {
			styleOverrides: {
				root: {
					borderBottom: light_border,
					borderColor: light_colors.line_color,
				},
			},
		},
		MuiCardActions: {
			styleOverrides: {
				root: {
					justifyContent: 'center',
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					color: '#000a',
					'&.Mui-selected': {
						color: `${secondaryText.light}`,
					},

					'&:hover': {
						backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
					},
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					'&.Mui-focused': {
						// change to blue input
						// color: '#23c97e',
					},
				},
			},
		},
		MuiToggleButton: {
			styleOverrides: {
				root: {
					'&:hover': {
						backgroundColor: light_colors.hover,
					},
					'&:focus': {
						background: light_colors.active_item,
						'&:hover': {
							backgroundColor: light_colors.hover,
						},
					},
					'&.Mui-selected': {
						background: light_colors.active_item,
						'&:hover': {
							backgroundColor: light_colors.hover,
						},
					},
				},
			},
		},
		MuiInput: {
			styleOverrides: {
				root: {
					'&.Mui-focused': {
						'&.Mui-focused fieldset': {},
					},
					'&:hover ': {
						// backgroundColor: dark_colors.hover,
						'&:hover fieldset': {},
					},
				},
				underline: {
					'&:before': {
						borderBottomColor: '#121212', // inactive underline
						// borderWidth: '0px',
					},
					'&:after': {
						// change to blue input
						// borderBottomColor: dark_colors.secondary, // green underline on focus
					},
				},
				input: {
					// Required to control colour of input when browser autofill is used
					'&:-webkit-autofill': {
						// '-webkit-box-shadow': '0 0 0 100px #ffffff inset',
						WebkitBoxShadow: '0 0 0 100px #ffffff inset',
					},
				},
			},
		},
	},
});

// Constants
export const XS = 'sx';
export const SM = 'sm';
export const MD = 'md';
export const LG = 'lg';
export const XL = 'xl';
export { light_colors, dark_colors };

export { darkTheme, lightTheme };
