import { useContext, useMemo } from 'react';

import { WizardContext } from './withWizard';
import { WizardInternalContext } from './wizard';

export default function useWizard() {
	const hocContext = useContext(WizardContext);
	const internalContext = useContext(WizardInternalContext);

	const context = useMemo(() => hocContext ?? internalContext, [
		hocContext,
		internalContext,
	]);

	if (!context && process.env.NODE_ENV === 'development') {
		throw Error('Wrap your step with `Wizard`');
	} else {
		return context;
	}
}
