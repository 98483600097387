import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Checkbox, Collapse, FormControlLabel } from '@mui/material';
import _ from 'lodash';

import CollapseContent from './CollapseContent';
import { getTypeDefault } from '../utils/utils';
import { ON_CHANGE, ON_ENTER_OR_BLUR } from '../utils/constants';
import { useDataConfigContext } from '../DataConfigContext';
import useDefaultComponents from '../hooks/useDefaultComponents';
import useGetSelectionData from '../hooks/useGetSelectionData';

export default function DefaultInput() {
	const cancelling = useRef(false);

	const { disabled, historyUuid, updateConfig } = useDataConfigContext();
	const { config, location } = useGetSelectionData();

	const [value, setValue] = useState(config?.default?.value);

	useEffect(
		() => {
			const val = config?.default?.value;

			setValue(val);
		},
		[config]
	);

	const handleInclusionChange = useCallback(
		included => {
			if (included) {
				const initDefaultValue = getTypeDefault(config?.type);

				updateConfig(location, {
					...config,
					default: { value: initDefaultValue },
				});

				setValue(initDefaultValue);

				return;
			}

			let configClone = { ...config };

			delete configClone.default;

			updateConfig(location, { ...configClone });

			setValue(null);
		},
		[config, location, updateConfig]
	);

	const handleValueChange = useCallback(
		newValue => {
			if (_.isEqual(config.default.value, newValue ?? value)) return;

			updateConfig(location, {
				...config,
				default: { ...config.default, value: newValue ?? value },
			});
		},
		[config, location, updateConfig, value]
	);

	const included = !!config?.default;

	const { Component, updateTrigger } = useDefaultComponents(config?.type);

	return (
		<>
			<FormControlLabel
				control={
					<Checkbox
						checked={included}
						onChange={event =>
							handleInclusionChange(event.target.checked)
						}
					/>
				}
				label="Default Value"
				style={{
					alignItems: 'center',
					display: 'flex',
					justifyContent: 'flex-start',
				}}
			/>
			<Collapse in={included && !!Component}>
				<CollapseContent>
					<Component
						key={
							updateTrigger === ON_ENTER_OR_BLUR
								? historyUuid
								: undefined
						}
						disabled={disabled}
						onBlur={() => {
							if (
								!cancelling.current &&
								updateTrigger === ON_ENTER_OR_BLUR
							) {
								handleValueChange();
							}

							cancelling.current = false;
						}}
						onChange={newValue => {
							setValue(newValue);

							if (updateTrigger === ON_CHANGE) {
								handleValueChange(newValue);
							}
						}}
						onKeyDown={event => {
							if (event.key === 'Enter') {
								event.target.blur();
							}

							if (event.key === 'Escape') {
								cancelling.current = true;

								event.currentTarget.blur();

								setValue(config.default.value);
							}
						}}
						value={value || getTypeDefault(config?.type)}
					/>
				</CollapseContent>
			</Collapse>
		</>
	);
}
