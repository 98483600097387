import { ASC } from '../../../components/generic/table-utils/constants';

// Data source view modes
export const IN_DEPTH_CHIPS = 'In-Depth_Chips';
export const TOP_LEVEL_PREVIEW = 'Top-Level_Preview';

// Table defaults
export const INITIAL_SORT = {
	order: ASC,
	orderBy: 'name',
};

// Misc
export const NUMBER_OF_PREVIEW_ITEMS = 8;

export const SOURCE_TYPE_URL_ITEM_LOOKUP = {
	data_set: 'DataSet',
	data_stream: 'DataStream',
	image_repo: 'ImageRepo',
};
