import { apiClient } from './api_client';
// import { uuidRegex } from './utils';

import {
	imageReposGetListSchema,
	imageRepoGetSchema,
	imageRepoPostSchema,
	imageRepoPostResponseSchema,
	imageRepoPutSchema,
	imageRepoUploadSchema,
} from './validations/imageRepoValidation';

//========================================================================================================
async function imageRepoGetList() {
	const req_url = `image_repo`;
	const response = await apiClient({
		method: 'get',
		url: req_url,
	});

	if (response) {
		if (response.result === 'OK') {
			return await imageReposGetListSchema.validate(response.image_repos);
		} else {
			throw new Error('Server Error');
		}
	}
}

//========================================================================================================
async function imageRepoGet({ imageRepo }) {
	const req_url = `image_repo/${imageRepo}`;
	const response = await apiClient({
		method: 'get',
		url: req_url,
	});

	if (response) {
		if (response.result === 'OK') {
			return await imageRepoGetSchema.validate(response.image_repo);
		} else {
			throw new Error('Server Error');
		}
	}
}

//========================================================================================================
async function imageRepoImagesGet({ imageRepo }) {
	const req_url = `image_repo_image/${imageRepo}`;
	const response = await apiClient({
		method: 'get',
		url: req_url,
	});

	if (response) {
		if (response.result === 'OK') {
			return response.images;
		} else {
			throw new Error('Server Error');
		}
	}
}

//========================================================================================================
export async function ImageRepoAdd({ newImageRepo }) {
	// Validate the new Data Set
	console.log(newImageRepo);
	await imageRepoPostSchema.validate(newImageRepo);

	const req_url = 'image_repo';
	const response = await apiClient({
		method: 'post',
		url: req_url,
		body: newImageRepo,
	});

	if (response) {
		if (response.result === 'OK') {
			// Validate the returned data object
			await imageRepoPostResponseSchema.validate(response.image_repo);
			return response.image_repo;
		} else {
			throw new Error('Server Error');
		}
	}
}

//========================================================================================================
export async function imageRepoUpdate({ imageRepoUuid, imageRepoUpdate }) {
	// Validate the new Data Set
	await imageRepoPutSchema.validate(imageRepoUpdate);

	const req_url = `image_repo/${imageRepoUuid}`;
	const response = await apiClient({
		method: 'put',
		url: req_url,
		body: imageRepoUpdate,
	});

	if (response) {
		if (response.result === 'OK') {
			return;
		} else {
			throw new Error('Server Error');
		}
	}
}

//========================================================================================================
async function imageRepoGetImage({ imageRepoUuid, imageUuid, formatUuid }) {
	// TODO: ADD PARM VALIDATION
	const req_url = `image_repo_image/${imageRepoUuid}/${imageUuid}/${formatUuid}`;
	console.log('get image', req_url);
	const response = await apiClient({
		method: 'get',
		url: req_url,
	});
	//console.log(response);
	if (response) {
		return response;
	}
}

//========================================================================================================
async function imageRepoUpload({ imageRepo, imageId, image, imageBase64 }) {
	// Validate the new submission data
	// try {
	// 	await workflowImageUploadSchema.validate(imageData);
	// } catch (error) {
	// 	throw error;
	// }
	console.log(image);
	const req_url = `image_repo_image/${imageRepo}`;
	var formData = new FormData();
	if (image) {
		formData.append('image', image);
	}
	if (imageBase64) {
		formData.append('image', DataURIToBlob(imageBase64));
	}
	formData.append('create_timestamp', Date.now());
	formData.append('uuid', imageId);

	const response = await apiClient({
		method: 'post',
		url: req_url,
		//body: image,
		body: formData,
		headers: { 'Content-Type': 'multipart/form-data' },
	});

	if (response) {
		if (response.result !== 'OK') {
			throw new Error('Server Error');
		}
	}
}

function DataURIToBlob(dataURI) {
	const splitDataURI = dataURI.split(',');
	const byteString =
		splitDataURI[0].indexOf('base64') >= 0
			? atob(splitDataURI[1])
			: decodeURI(splitDataURI[1]);
	const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

	const ia = new Uint8Array(byteString.length);
	for (let i = 0; i < byteString.length; i++)
		ia[i] = byteString.charCodeAt(i);

	return new Blob([ia], { type: mimeString });
}

export {
	imageRepoGetList,
	imageRepoGetImage,
	imageRepoImagesGet,
	imageRepoGet,
	imageRepoUpload,
};
