import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { QueryBuilderVariableContext } from '.';
import { flattenSchema } from 'mongodb_query/src';

function getVariableDefaultValue(variableSchema) {
	if (Object.hasOwnProperty.call('default', variableSchema)) {
		return variableSchema.default.value;
	}

	switch (variableSchema.type) {
		case 'string':
			return 'text';
		case 'number':
			return 1;
		case 'boolean':
			return true;
		case 'object':
			return {};
		case 'array':
			return [];
		case 'datetime':
			return new Date();
		case 'date':
			return new Date();
	}

	return null;
}

export default function BtQueryBuilderVariableProvider(props) {
	const { variables: _assignedValues, configSchema, children } = props;

	const availableVariables = useMemo(() => flattenSchema(configSchema), [
		configSchema,
	]);

	const updateVariableValues = useCallback(
		() => {
			if (!_assignedValues) {
				return null;
			}
			const filled = [];

			const assigned_keys = {};
			let max_key = 0;

			Object.keys(_assignedValues).forEach(k => {
				const path = _assignedValues[k].value;

				assigned_keys[path] = 1;

				// extract the number from #VAR10101#
				const var_num = Number(k.slice(3));
				if (var_num > max_key) {
					max_key = var_num;
				}

				const variableConfigSchema = availableVariables[path] || {
					type: 'string',
				};

				filled.push({
					configSchema: variableConfigSchema,
					key: k,
					label: variableConfigSchema.label || k,
					value: getVariableDefaultValue(variableConfigSchema),
					schemaPath: path,
				});
			});

			Object.keys(availableVariables).forEach(path => {
				if (!assigned_keys[path]) {
					const varName = 'VAR' + ++max_key;

					const variableConfigSchema = availableVariables[path] || {
						type: 'string',
					};

					filled.push({
						configSchema: variableConfigSchema,
						key: varName,
						label: variableConfigSchema.label || varName,
						value: getVariableDefaultValue(variableConfigSchema),
						schemaPath: path,
					});
				}
			});

			return filled.length ? filled : null;
		},
		[_assignedValues, availableVariables]
	);

	const variableValues = useMemo(updateVariableValues, [
		updateVariableValues,
		_assignedValues,
		availableVariables,
	]);

	return (
		<QueryBuilderVariableContext.Provider
			value={{
				variables: variableValues,
				// onChange: handleVariableChange,
				variableContextActive: true,
				configSchema: configSchema,
				availableVariables: availableVariables,
			}}
		>
			{children}
		</QueryBuilderVariableContext.Provider>
	);
}

BtQueryBuilderVariableProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	configSchema: PropTypes.object,
	variables: PropTypes.object,
	// variables: PropTypes.arrayOf(
	// 	PropTypes.shape({
	// 		// configSchema: PropTypes.object.isRequired,
	// 		// label: PropTypes.string,
	// 		// key: PropTypes.string.isRequired,
	// 		// defaultValue: PropTypes.any,
	// 		value: PropTypes.string,
	// 	})
	// ),
};
