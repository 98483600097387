import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import * as yup from 'yup';

import { Check } from 'mdi-material-ui';
import {
	BtForm,
	BtFormActionsContainer,
	BtFormContent,
	BtFormTextField,
	withFormContextMethods,
} from '../../../../../../components/generic/forms';
import BtDialog from '../../../../../../components/generic/BtDialog';

const ActionButtons = withFormContextMethods(
	({ formState: { isDirty }, onClose, verb }) => {
		return (
			<>
				<Button variant="outlined" onClick={onClose}>
					Cancel
				</Button>
				<Button
					variant="contained"
					disabled={!isDirty}
					type="submit"
					startIcon={<Check />}
					disableElevation
				>
					{verb}
				</Button>
			</>
		);
	}
);

export default function TagEditDialog({
	existingTags,
	onClose,
	onSubmit,
	open,
	tag,
	title,
}) {
	const handleSubmit = useCallback(
		(values, reset) => {
			onSubmit(values.tag);
			onClose();
		},
		[onClose, onSubmit]
	);

	const schema = yup.object({
		tag: yup
			.string()
			.min(1)
			.max(20)
			.notOneOf(existingTags || [], 'This tag already exists')
			.required()
			.label('Tag'),
	});

	const defaultValues = useMemo(
		() => ({
			tag: tag,
		}),
		[tag]
	);

	return (
		<BtDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
			<DialogTitle>{title}</DialogTitle>
			<BtForm
				onSubmit={handleSubmit}
				validationSchema={schema}
				defaultValues={defaultValues}
			>
				<DialogContent>
					<BtFormContent>
						<BtFormTextField name="tag" label="Tag" autoFocus />
					</BtFormContent>
				</DialogContent>
				<DialogActions>
					<BtFormActionsContainer>
						<ActionButtons verb="Confirm" onClose={onClose} />
					</BtFormActionsContainer>
				</DialogActions>
			</BtForm>
		</BtDialog>
	);
}

ActionButtons.propTypes = {
	isDirty: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	verb: PropTypes.string.isRequired,
};

TagEditDialog.propTypes = {
	// existingNames: PropTypes.arrayOf(PropTypes.string),
	// onClose: PropTypes.func.isRequired,
	// onSubmit: PropTypes.func.isRequired,
	// open: PropTypes.bool.isRequired,
	// paletteName: PropTypes.string,
	// paletteType: PropTypes.string,
	// title: PropTypes.string.isRequired,
};

TagEditDialog.displayName = 'TagEditDialog';
