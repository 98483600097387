import React, { useState, useEffect, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useNavContext } from '../context/ContextManager';

// MUI COMPONENTS
import Box from '@mui/material/Box';
import { Typography, Container } from '@mui/material';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

// CUSTOM COMPONENTS
import CustomPaper from '../components/generic/CustomPaper';

// Data for landing page selection table and contextual navigation
import developer_options from './DeveloperOptions';
import developer_breadcrumbs from './DeveloperBreadcrumbs';
import developer_nav_item from './DeveloperNavItem';

// ICONS
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

// var replaced with const
const WebSocket = require('websocket').w3cwebsocket;

const styles = makeStyles(theme => ({
	root: {
		width: '100%',
		height: '100%',
		minWidth: 200,
	},
	toolbarButton: {
		margin: '0px',
		padding: '4px 16px',
		fontSize: '1.5rem',
	},
}));

// const packetColors = {
//     auth: "red",
//     app: "green",
// }

const packetStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		border: '1px solid',
		padding: '1rem',
		marginBottom: '-1px',
		'&:hover': {
			backgroundColor: theme.palette.primary.light,
		},
	},
	appHeader: {
		display: 'flex',
		flexDirection: 'row',
		border: 'none',
		padding: 0,
		margin: 0,
	},
	portAuth: {
		backgroundColor: '#cebdff',
		height: '100%',
	},
	portSys: {
		backgroundColor: '#ffd8bd',
		height: '100%',
	},
	portApp: {
		backgroundColor: '#d3ffbd',
		height: '100%',
	},
	portFT: {
		backgroundColor: '#bdfffa',
		height: '100%',
	},
	portUnknown: {
		backgroundColor: '#FFFFFF',
		height: '100%',
	},
}));

function ToolbarDivider() {
	return (
		<div
			style={{
				backgroundColor: 'white',
				width: '1px',
				height: '32px',
				opacity: '50%',
			}}
		/>
	);
}

function AppHeader({ app_header }) {
	const classes = packetStyles();
	return (
		<Box className={classes.appHeader}>
			<Box width="25%">{app_header.seq}</Box>
			<Box width="25%">{app_header.port}</Box>
			<Box width="25%">{app_header.payload_length}</Box>
			<Box width="25%">{app_header.op_code}</Box>
		</Box>
	);
}

function Packet({ packet, index }) {
	const classes = packetStyles();

	var packet_type_app = packet.flags & 0x01 ? true : false;
	var transmit = packet.direction === 'TX' ? true : false;
	var ack = packet.flags & 0x80 ? true : false;
	var datetime = new Date(packet.timestamp);

	var app_header = {};
	var port_style = classes.portUnknown;
	if (packet_type_app === true && packet.payload.data !== undefined) {
		app_header.seq = packet.payload.data[0];
		app_header.port = packet.payload.data[2];
		app_header.payload_length =
			packet.payload.data[4] + packet.payload.data[3] * 256;
		app_header.op_code = packet.payload.data[5];

		switch (app_header.port) {
			case 100:
				port_style = classes.portSys;
				break;
			case 101:
				port_style = classes.portApp;
				break;
			case 102:
				port_style = classes.portFT;
				break;
			default:
				break;
		}
	} else {
		port_style = classes.portAuth;
	}

	return (
		<Box className={[classes.root, port_style]}>
			<Box width="5%">{index}</Box>
			<Box width="15%">
				{datetime.toLocaleString()}.{datetime.getMilliseconds()}
			</Box>
			<Box width="5%">
				{transmit && (
					<ArrowDownwardIcon
						style={{ color: 'red' }}
						fontSize="small"
					/>
				)}
				{!transmit && (
					<ArrowUpwardIcon
						style={{ color: 'green' }}
						fontSize="small"
					/>
				)}
			</Box>
			<Box width="10%">{packet.uid}</Box>
			<Box width="10%">
				{packet.flags}
				{ack && <>(ACK)</>}
			</Box>
			<Box width="55%">
				{!packet_type_app && <>AUTH</>}
				{packet_type_app && <AppHeader app_header={app_header} />}
			</Box>
		</Box>
	);
}

export default function PacketLogger() {
	const classes = styles();
	const [logging, setLogging] = useState(false);
	const [logData, setLogData] = useState([]);
	const { setContextualNav } = useNavContext();
	const { setBreadcrumbs } = useNavContext();

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([...developer_nav_item, ...developer_options]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	// Set breadcrumbs
	useEffect(() => {
		setBreadcrumbs([
			...developer_breadcrumbs,
			{ text: 'Packet Logger', link: '' },
		]);
	});

	const webSocket = useRef(null);

	const closeWS = () => {
		console.log('Stop the logging');
		if (webSocket.current != null) {
			var pub = {};
			pub['request'] = 'publish';
			pub['topic'] = 'SYS/PKT_LOG';
			var pub_msg = {};
			pub_msg['cmd'] = 'stop_log';
			pub_msg['filter'] = '*';
			pub['message'] = pub_msg;
			webSocket.current.send(JSON.stringify(pub));

			var unsub_msg = {};
			unsub_msg['request'] = 'unsubscribe';
			unsub_msg['topic'] = 'SVR/112233445566/PKT_LOG';
			webSocket.current.send(JSON.stringify(unsub_msg));
		}
	};

	useEffect(() => {
		return function cleanup() {
			closeWS();
		};
	}, []);

	const handleStartStop = () => {
		if (logging === true) {
			console.log('Stop Logging');
			closeWS();
			setLogging(false);
		} else {
			console.log('Start Logging');

			webSocket.current = new WebSocket(process.env.REACT_APP_API_WS_URL);
			webSocket.current.onopen = () => {
				console.log('WebSocket Client Connected');

				var sub_msg = {};
				sub_msg['request'] = 'subscribe';
				sub_msg['topic'] = 'SVR/112233445566/PKT_LOG';
				webSocket.current.send(JSON.stringify(sub_msg));

				var pub = {};
				pub['request'] = 'publish';
				pub['topic'] = 'SYS/PKT_LOG';
				var pub_msg = {};
				pub_msg['cmd'] = 'start_log';
				pub_msg['filter'] = '*';
				pub['message'] = pub_msg;
				webSocket.current.send(JSON.stringify(pub));
			};
			webSocket.current.onmessage = message => {
				console.log('message recived');
				var new_pkt = JSON.parse(message.data);
				console.log(new_pkt);
				setLogData(logData => [...logData, new_pkt]);
			};

			setLogging(true);
		}
	};

	const handleClear = () => {
		console.log('Clear');
		setLogging(logData => []);
	};

	const handleFilter = () => {
		console.log('Filter');
	};

	return (
		<>
			<Container className={classes.root} style={{ padding: '1rem' }}>
				<CustomPaper className={classes.root} title="Packet Logger">
					<AppBar position="static">
						<Toolbar>
							<Button
								className={classes.toolbarButton}
								color="inherit"
								onClick={handleStartStop}
							>
								{!logging && <>Start Logging</>}
								{logging && <>Stop Logging</>}
							</Button>
							<ToolbarDivider />
							<Button
								className={classes.toolbarButton}
								color="inherit"
								onClick={handleClear}
							>
								Clear Log
							</Button>
							<ToolbarDivider />
							<Button
								className={classes.toolbarButton}
								color="inherit"
								onClick={handleFilter}
							>
								Filter
							</Button>
						</Toolbar>
					</AppBar>

					<Typography variant="h6">
						Packets Logged: {logData.length}
					</Typography>

					{logData.map((packet, index) => (
						<Packet packet={packet} index={index} key={index} />
					))}
				</CustomPaper>
			</Container>
		</>
	);
}
