import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';

import {
	pipelineSchema as transformPipelineSchema,
	pipeline as processPipeline,
} from 'mongodb_query/src';

import BtQueryBuilderStageOutputPreview from './BtQueryBuilderStageOutputRenderedPreview';
import { useDebounce } from '../useDebounce';
import { useQueryBuilderDispatchContext } from '../context';
import { emptyConfigSchema } from '../processing';

export default function BtQueryBuilderFullQueryTextEditor(props) {
	const {
		initialQuery: _initialQuery,
		initialConfigSchema: _initialConfigSchema,
		initialCollection: _initialCollection,
		disableCollection,
		onDataQuery,
		disabled,
		collectionRenderMode,
	} = props;

	const [initialQuery] = useState(_initialQuery);
	const [initialCollection] = useState(_initialCollection);
	const [initialConfigSchema] = useState(_initialConfigSchema);

	const dispatch = useQueryBuilderDispatchContext();

	const startCollection = useMemo(
		() => {
			if (typeof initialCollection === 'string') {
				try {
					return JSON.parse(initialCollection);
				} catch (e) {
					return [];
				}
			} else {
				return initialCollection;
			}
		},
		[initialCollection]
	);

	const startCollectionStr = useMemo(
		() => {
			if (typeof initialCollection !== 'string') {
				try {
					return JSON.stringify(initialCollection);
				} catch (e) {
					return '[]';
				}
			} else {
				return initialCollection;
			}
		},
		[initialCollection]
	);

	const startConfigSchema = useMemo(
		() => {
			if (typeof initialConfigSchema === 'string') {
				try {
					return JSON.parse(initialConfigSchema);
				} catch (e) {
					return emptyConfigSchema();
				}
			} else {
				return initialConfigSchema;
			}
		},
		[initialConfigSchema]
	);

	const startConfigSchemaStr = useMemo(
		() => {
			if (typeof initialConfigSchema !== 'string') {
				try {
					return JSON.stringify(initialConfigSchema);
				} catch (e) {
					return '{}';
				}
			} else {
				return initialConfigSchema;
			}
		},
		[initialConfigSchema]
	);

	const [collection, setCollection] = useState();
	const [collectionError, setCollectionError] = useState();
	const [collectionLoading, setCollectionLoading] = useState();
	const [configSchema, setConfigSchema] = useState(emptyConfigSchema());
	const [configSchemaError, setConfigSchemaError] = useState();
	const [configSchemaLoading, setConfigSchemaLoading] = useState();

	const [rawInput, setRawInput] = useState(
		typeof initialQuery === 'string'
			? initialQuery
			: initialQuery && JSON.stringify(initialQuery)
	);

	const [rawInputError, setRawInputError] = useState();

	const { debounce, waiting } = useDebounce(1000);

	const allowAsyncDispatch = useRef(true);

	const updateData = useCallback(
		(
			queryObj,
			startCollection,
			startConfigSchema,
			updateStageState = false
		) => {
			try {
				const query =
					typeof queryObj === 'string'
						? JSON.parse(queryObj)
						: queryObj;

				if (updateStageState) {
					dispatch({
						action: 'init',
						query: query,
					});
				}
				if (!disableCollection) {
					setCollectionError();
					setCollectionLoading(true);
				}

				setConfigSchemaError();
				setConfigSchemaLoading(true);

				if (!disableCollection) {
					let promise = null;
					if (onDataQuery) {
						promise = onDataQuery(query);
					} else {
						promise = processPipeline(startCollection, query);
					}

					promise
						.then(result => setCollection(result))
						.catch(err => setCollectionError(err))
						.finally(() => setCollectionLoading(false));
				}

				transformPipelineSchema(startConfigSchema, query)
					.then(configSchema => setConfigSchema(configSchema))
					.catch(err => setConfigSchemaError(err))
					.finally(() => setConfigSchemaLoading());
			} catch (err) {
				setRawInputError(err);
			}
		},
		[onDataQuery, dispatch, disableCollection]
	);

	const handleRawInputChange = useCallback(
		event => {
			const value = event.target.value;

			setRawInput(value);
			setRawInputError();

			debounce(() => {
				updateData(value, startCollection, startConfigSchema, true);
			});
		},
		[debounce, updateData, startCollection, startConfigSchema]
	);

	const configSchemaStr = useMemo(() => JSON.stringify(configSchema), [
		configSchema,
	]);
	const collectionStr = useMemo(() => JSON.stringify(collection), [
		collection,
	]);

	useEffect(
		() => {
			updateData(initialQuery, startCollection, startConfigSchema, false);
		},
		[initialQuery, startCollection, startConfigSchema, updateData]
	);

	useEffect(() => {
		return () => {
			allowAsyncDispatch.current = false;
		};
	}, []);

	return (
		<Box
			sx={{
				height: '70vh',
				display: 'flex',
				justifyItems: 'space-between',
				flexWrap: 'wrap',
			}}
		>
			<Box
				sx={{
					width: {
						xs: '100%',
						md: '25%',
					},
					height: {
						xs: '300px',
						md: '100%',
					},
				}}
			>
				<BtQueryBuilderStageOutputPreview
					title="Input"
					disableCollection={!!disableCollection}
					collection={startCollection}
					collectionStr={startCollectionStr}
					configSchema={startConfigSchema}
					configSchemaStr={startConfigSchemaStr}
					collectionRenderMode={collectionRenderMode}
				/>
			</Box>
			<Box
				sx={{
					width: {
						xs: '100%',
						md: '50%',
					},
					height: {
						xs: 'auto',
						md: '100%',
					},
					overflow: {
						xs: 'inherit',
						md: 'auto',
					},
					position: 'relative',
					px: {
						xs: 0,
						md: 2,
					},
				}}
			>
				<Box
					sx={{
						position: 'relative',
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Box>
						<Typography variant="subtitle1">Query</Typography>
					</Box>
					<Divider
						sx={{
							marginTop: { xs: 2, md: 6.5 },
							marginBottom: { xs: 1, md: 2 },
						}}
					/>
					<Box
						sx={{
							flex: 1,
							overflow: 'auto',

							px: { md: 2 },
						}}
					>
						<Box sx={{ overflow: 'auto' }}>
							<TextField
								fullWidth
								multiline
								key={`rawInput`}
								name={`rawInput`}
								onChange={handleRawInputChange}
								disabled={disabled}
								size="small"
								type="text"
								variant="standard"
								value={rawInput}
								error={!!rawInputError}
								helperText={
									rawInputError ? rawInputError + '' : ''
								}
								label="Pipeline"
								InputProps={{
									endAdornment: waiting ? (
										<InputAdornment position="end">
											<Box
												style={{
													display: 'flex',
													marginRight: '0.5em',
													pointerEvents: 'none',
												}}
											>
												<CircularProgress size={20} />
											</Box>
										</InputAdornment>
									) : null,
								}}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box
				sx={{
					width: {
						xs: '100%',
						md: '25%',
					},
					height: {
						xs: '300px',
						md: '100%',
					},
				}}
			>
				<BtQueryBuilderStageOutputPreview
					title="Output"
					disableCollection={!!disableCollection}
					collection={collection}
					collectionStr={collectionStr}
					collectionLoading={collectionLoading}
					collectionError={collectionError}
					configSchema={configSchema}
					configSchemaStr={configSchemaStr}
					configSchemaError={configSchemaError}
					configSchemaLoading={configSchemaLoading}
				/>
			</Box>
		</Box>
	);
}

BtQueryBuilderFullQueryTextEditor.propTypes = {
	initialQuery: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.object),
	]).isRequired,
	initialConfigSchema: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
	disableCollection: PropTypes.bool,
	initialCollection: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.object),
	]),
	disabled: PropTypes.bool,
	onDataQuery: PropTypes.func,
	collectionRenderMode:
		BtQueryBuilderStageOutputPreview.propTypes.collectionRenderMode,
};
