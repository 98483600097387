import React from 'react';
import PropTypes from 'prop-types';

import BtBadge from '../BtBadge';
import { Button, IconButton, styled, Tooltip } from '@mui/material';
import CommentIcon from '@mui/icons-material/ModeComment';

const CommentButtonContainer = styled('div')(() => ({
	height: 36,
	position: 'relative',
	width: 36,
}));

export default function CommentsIconButton({ comments, condensed, onClick }) {
	if (condensed) {
		return (
			<CommentButtonContainer>
				<Tooltip disableInteractive title="Comments">
					<IconButton onClick={onClick}>
						<CommentIcon />
						<BtBadge count={comments?.length} />
					</IconButton>
				</Tooltip>
			</CommentButtonContainer>
		);
	}

	return (
		<Button
			disableElevation
			onClick={onClick}
			startIcon={<CommentIcon />}
		>{`Comments (${comments?.length || 0})`}</Button>
	);
}

CommentsIconButton.propTypes = {
	comments: PropTypes.arrayOf(PropTypes.object).isRequired,
	condensed: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
};
