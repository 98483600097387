const yup = require('yup');

export const imageFormat = yup.object().shape({
	uuid: yup
		.string()
		.uuid()
		.required(),
	name: yup.string().required(),
	maxWidthHeight: yup
		.number()
		.min(1)
		.required(),
	grayscale: yup.boolean(),
	watermark: yup
		.object()
		.nullable()
		.shape({
			type: yup.string(),
			text: yup.string(),
			dpi: yup.number(),
			tile: yup.boolean(),
		}),
});

const imageRepo = yup.object().shape({
	uuid: yup
		.string()
		.uuid()
		.required(),
	name: yup.string().required(),
	description: yup.string().required(),
	formats: yup
		.array()
		.required()
		.of(imageFormat),
});

const imageReposGetListSchema = yup
	.array()
	.required()
	.of(imageRepo);

const imageRepoGetSchema = imageRepo;

const imageRepoPostSchema = yup
	.object()
	.required()
	.shape({
		name: yup.string().required(),
		description: yup.string().required(),
	});

const imageRepoPostResponseSchema = imageRepo;

const imageRepoPutSchema = yup
	.object()
	.required()
	.shape({
		name: yup.string().required(),
		description: yup.string().required(),
		formats: yup
			.array()
			.required()
			.of(imageFormat),
	});

const imageRepoUploadSchema = yup.object({
	name: yup.string().required(),
});

export {
	imageReposGetListSchema,
	imageRepoGetSchema,
	imageRepoPostSchema,
	imageRepoPostResponseSchema,
	imageRepoPutSchema,
	imageRepoUploadSchema,
};
