import React, { useState, useCallback } from 'react';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';

import { useAppContext } from '../../context/ContextManager';
import { useSnackbar } from 'notistack';

import { userPut } from '../../API';
import { displayNameAndInitials } from '../../utils/userNameUtils';

import { Container, Typography } from '@mui/material';

import BTAuthCard from './BTAuthCard';
import {
	BtForm,
	BtFormChangesBlocker,
	BtFormContent,
	BtFormSection,
	BtFormTextField,
	BtFormActionButtonsNeverDisabled,
} from '../../components/generic/forms';
import {
	uk_mobile_regex,
	user_names_regex,
	user_name_regex,
	initials_regex,
} from '../../utils/regexLibrary';

export default function AccountSetup(props) {
	const [isSending, setIsSending] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const { userInfo, setUserInfo, setLoadApp } = useAppContext();
	const history = useHistory();

	const schema = yup.object({
		first_name: yup
			.string()
			.min(2)
			.max(30)
			.required()
			.matches(user_names_regex.regex, user_names_regex.error_text, {
				excludeEmptyString: true,
			})
			.label('First name'),
		last_name: yup
			.string()
			.min(2)
			.max(30)
			.required()
			.matches(user_names_regex.regex, user_names_regex.error_text, {
				excludeEmptyString: true,
			})
			.label('Last name'),
		user_name: yup
			.string()
			.min(3)
			.max(20)
			.required()
			.matches(user_name_regex.regex, user_name_regex.error_text, {
				excludeEmptyString: true,
			})
			.label('Display name'),
		initials: yup
			.string()
			.min(2)
			.max(2)
			.required()
			.matches(initials_regex.regex, initials_regex.error_text, {
				excludeEmptyString: true,
			})
			.label('Initials'),
		// This dummy value is used to trigger the following conditional assessment of the mobile required value in the user record
		dummy: yup.string(),
		mobile_phone: yup
			.string()
			.when('dummy', () => {
				if (!userInfo) {
					return yup.string().required();
				} else if (userInfo.mobile_required === true) {
					return yup.string().required();
				} else {
					return yup.string().optional();
				}
			})
			.matches(uk_mobile_regex.regex, uk_mobile_regex.error_text, {
				excludeEmptyString: true,
			})
			.label('Mobile number'),
	});

	//  This is not strictly required as this account setup view can only be accessed if the userInfo.status value has been read.
	const defaultValues = {
		first_name: userInfo.first_name,
		last_name: userInfo.last_name,
		// The user_name and initials values are created
		// by passing first and last names into the external
		// function displayNameAndInitials
		user_name: displayNameAndInitials({
			first_name: userInfo.first_name,
			last_name: userInfo.last_name,
		}).user_name,
		initials: displayNameAndInitials({
			first_name: userInfo.first_name,
			last_name: userInfo.last_name,
		}).initials,
		mobile_phone: userInfo.mobile_phone,
	};

	const onSubmit = useCallback(
		async (values, reset) => {
			// Setup the PUT request data with the form values
			const user_data = {
				userData: {
					status: 'Active',
					first_name: values.first_name,
					last_name: values.last_name,
					user_name: values.user_name,
					initials: values.initials,
					mobile_phone: values.mobile_phone,
				},
			};
			try {
				setIsSending(true);
				await userPut(user_data);
				reset(values);

				enqueueSnackbar('Successfully saved', {
					variant: 'success',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
				});
				// This could be set to null to force context manager to trigger user get
				setUserInfo({
					...userInfo,
					status: 'Active',
					first_name: values.first_name,
					last_name: values.last_name,
					user_name: values.user_name,
					initials: values.initials,
					mobile_phone: values.mobile_phone,
				});
				setIsSending(false);
				setLoadApp(true);
				history.push('/');
			} catch (error) {
				enqueueSnackbar(
					'Something went wrong, please try to login again.',
					{
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'center',
						},
					}
				);
				props.changeView('Login');
				console.error(error);
			} finally {
			}
		},
		[enqueueSnackbar, history, props, setLoadApp, setUserInfo, userInfo]
	);

	return (
		<BTAuthCard
			heading={
				<Typography align="center">
					Welcome to the Beacon Technology platform. You are
					registering to use this platform with the email address{' '}
					{userInfo ? userInfo.email : ''}. Before you can access the
					platform, please provide the following information.
				</Typography>
			}
			form={
				<Container maxWidth="md">
					<BtForm
						validationSchema={schema}
						defaultValues={defaultValues}
						// loading={loading}
						sending={isSending}
						onSubmit={onSubmit}
					>
						<BtFormSection style={{ margin: '0px' }}>
							<BtFormContent>
								<BtFormTextField
									name="first_name"
									label="First Name"
								/>
								<BtFormTextField
									name="last_name"
									label="Last Name"
								/>
								<BtFormTextField
									name="user_name"
									label="Display name"
								/>
								<BtFormTextField
									name="initials"
									label="Initials"
								/>
								<BtFormTextField
									name="mobile_phone"
									label="Mobile number"
								/>
								<BtFormActionButtonsNeverDisabled
									submitVerb="submit"
									submitPresentVerb="Sending.."
								/>

								<BtFormChangesBlocker />
							</BtFormContent>
						</BtFormSection>
					</BtForm>
				</Container>
			}
		/>
	);
}
