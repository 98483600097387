import { useAppContext } from '../context/ContextManager';

import { format } from 'date-fns';
import { useCallback } from 'react';

export const useTimeFormatter = () => {
	const { appOrg } = useAppContext();

	const formatter = useCallback(
		(timestamp, includeTime = false, includeDate = true) => {
			if (!timestamp) {
				return '';
			}

			let dateTimeFormat = includeDate
				? `${appOrg?.regionalSettings?.dateFormat}${
						includeTime ? ', ' : ''
				  }`
				: '';

			if (includeTime) {
				dateTimeFormat = dateTimeFormat += 'hh:mm a';
			}

			return format(new Date(timestamp || 0), dateTimeFormat);
		},
		[appOrg]
	);

	return formatter;
};

export default function useTimeFormat(
	timestamp,
	includeTime = false,
	includeDate = true
) {
	const format = useTimeFormatter();

	return format(timestamp, includeTime, includeDate);
}
