import React, { useEffect, useState, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
//import { Auth } from 'aws-amplify';
import { useAppContext, useNavContext } from '../../context/ContextManager';

import { deviceTypeGet } from '../../API/IoT/device_types.api';
import { deviceGet } from '../../API/IoT/device.api';
import { configVersionGet } from '../../API/IoT/config.api';
import { firmwareVersionGet } from '../../API/IoT/firmware.api';

// MUI COMPONENTS
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import {
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Typography,
} from '@mui/material';

// CUSTOM COMPONENTS
import DeviceHeader from '../../components/DeviceComponents/DeviceHeader';
import {
	ListTable,
	ListRow,
	ListHeader,
	ListCell,
} from '../../components/generic/ListTable';
import SelectConfigDialog from '../../components/dialogs/SelectConfigDialog';
import SelectFirmwareDialog from '../../components/dialogs/SelectFirmwareDialog';

import BtLoading from '../../components/generic/BtLoading';

import DigitalDialog from '../../components/dialogs/DigitalConditioningDialog';
import AnalogueDialog from '../../components/dialogs/AnalogueConditioningDialog';
import EmDialog from '../../components/dialogs/EmConditioningDialog';

import DeviceStats from '../../components/DeviceComponents/DeviceStats';
import DeviceAdminPanel from '../../components/DeviceComponents/DeviceAdminPanel';
import ChannelDataCollection from '../../components/DeviceComponents/ChannelDataCollection';

import {
	BtTabView,
	BtTabBar,
	BtTab,
	BtTabPanel,
	a11yProps,
} from '../../components/generic/BtTabView';

// ICONS
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';

// Data for landing page selection table and contextual navigation
import IoT_options from './IoTOptions';
import IoT_breadcrumbs from './IoTBreadcrumbs';
import IoT_nav_item from './IoTNavItem';
import DeviceInputs from '../../components/DeviceComponents/DeviceInputs';

var WebSocket = require('websocket').w3cwebsocket;

const styles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(2),
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
	},
	inputValue: {
		fontSize: '4rem',
		fontWeight: 700,
		textAlign: 'center',
	},
}));

function LinearProgressWithLabel(props) {
	return (
		<Box display="flex" alignItems="center">
			<Box width="100%" mr={1}>
				<LinearProgress variant="determinate" {...props} />
			</Box>
			<Box minWidth={35}>
				<Typography variant="h6" color="textSecondary">{`${Math.round(
					props.value
				)}%`}</Typography>
			</Box>
		</Box>
	);
}

export default function DeviceView({ match }) {
	const classes = styles();
	const [isLoading, setIsLoading] = useState(true);
	const [deviceInfo, setDeviceInfo] = useState(null);
	const [configData, setConfigData] = useState([]);
	const [firmwareData, setFirmwareData] = useState([]);
	//const [taskData, setTaskData] = useState([]);
	const [selectConfigOpen, setSelectConfigOpen] = useState(false);
	const [selectFirmwareOpen, setSelectFirmwareOpen] = useState(false);
	const [websocketVal, setWebsocketVal] = useState('Not Set');
	const [progress, setProgress] = useState(0);
	const webSocket = useRef(null);
	const [viewConditioned, setViewConditioned] = useState(true);
	const { appOrg } = useAppContext();

	const [currentConditioning, setCurrentConditioning] = useState({});
	const [currentValues, setCurrentValues] = useState({});

	const [digitalEditOpen, setDigitalEditOpen] = useState(false);
	const [analogueEditOpen, setAnalogueEditOpen] = useState(false);
	const [emEditOpen, setEmEditOpen] = useState(false);

	const [digitalConditioning, setDigitalConditioning] = useState({});
	const [analogueConditioning, setAnalogueConditioning] = useState({});
	const [emConditioning, setEmConditioning] = useState([]);

	const [inRTMode, setInRTMode] = useState(false);
	const [updateRT, setUpdateRT] = useState([]);

	const [conStats, setConStats] = useState({});

	const [digitalValues, setDigitalValues] = useState({});
	const [analogueValues, setAnalogueValues] = useState({});
	const [emValues, setEmValues] = useState([]);

	const [currentTab, setCurrentTab] = useState(0);
	const handleTabChange = (event, newTabValue) => {
		setCurrentTab(newTabValue);
	};

	const [newChannelData, setNewChannelData] = useState({});
	const [channelConfig, setChannelConfig] = useState([]);

	const [deviceTypeGP_4A4D, setDeviceTypeGP_4A4D] = useState(false);
	const [deviceTypeGP_12D, setDeviceTypeGP_12D] = useState(false);
	const [deviceTypeEM, setDeviceTypeEM] = useState(false);
	const [deviceTypeBRIIV, setDeviceTypeBRIIV] = useState(false);
	const [deviceTypeBRIIV_MK2, setDeviceTypeBRIIV_MK2] = useState(false);
	const { setBreadcrumbs } = useNavContext();
	const { setContextualNav } = useNavContext();

	var device_id = match.params.id;

	//, message => {
	//	console.log('Message Recived', message);
	//webSocket.current = new WebSocket(
	// 	process.env.REACT_APP_API_WS_URL
	// );
	// webSocket.current.onopen = () => {
	// 	var sub_msg = {};
	// 	sub_msg['request'] = 'subscribe';
	// 	sub_msg['topic'] = 'SVR/' + device_info.uid + '/*';
	// 	webSocket.current.send(JSON.stringify(sub_msg));
	// };

	// webSocket.current.onmessage = message => {
	// 	setWebsocketVal(message.data);
	// 	var msg_data = JSON.parse(message.data);

	// 	console.log('webSocket onMessage', msg_data);
	// 	//console.log('msg channel: ' + msg_data.channel);

	// 	try {
	// 		let packet = JSON.parse(msg_data.message);
	// 		//console.log(packet);

	// 		if (packet.type === 'task_update') {
	// 			setProgress(
	// 				(packet.progress / packet.filesize) *
	// 					100
	// 			);
	// 		} else if (packet.type === 'channel_data') {
	// 			// TODO: Find a way to make the following work
	// 			setNewChannelData(packet.data);
	// 		} else if (packet.type === 'rt_data') {
	// 			if (packet.data !== undefined) {
	// 				setUpdateRT(packet.data);
	// 			}
	// 		} else if (packet.type === 'con_stats') {
	// 			updateConStats(packet.data);
	// 		}
	// 	} catch (err) {
	// 		console.log(err);
	// 	}
	//});

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				...IoT_breadcrumbs,
				{ text: 'Devices', link: '/Devices' },
				//{ text: deviceInfo['name'], link: '' },
				{ text: device_id, link: '' },
			]);
		},
		[device_id, setBreadcrumbs]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([...IoT_nav_item, ...IoT_options]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	const handleSelectConfigDialogOpen = () => {
		setSelectConfigOpen(true);
	};
	const handleSelectConfigDialogClose = () => {
		setSelectConfigOpen(false);
	};

	const handleSelectFirmwareDialogOpen = () => {
		setSelectFirmwareOpen(true);
	};
	const handleSelectFirmwareDialogClose = () => {
		setSelectFirmwareOpen(false);
	};

	const handleDigitalEditDialogClose = new_data => {
		if (new_data !== undefined) {
			switch (new_data.input) {
				case 'D0':
					setDigitalConditioning({
						...digitalConditioning,
						D0: new_data,
					});
					break;
				case 'D1':
					setDigitalConditioning({
						...digitalConditioning,
						D1: new_data,
					});
					break;
				case 'D2':
					setDigitalConditioning({
						...digitalConditioning,
						D2: new_data,
					});
					break;
				case 'D3':
					setDigitalConditioning({
						...digitalConditioning,
						D3: new_data,
					});
					break;
				case 'D4':
					setDigitalConditioning({
						...digitalConditioning,
						D4: new_data,
					});
					break;
				case 'D5':
					setDigitalConditioning({
						...digitalConditioning,
						D5: new_data,
					});
					break;
				case 'D6':
					setDigitalConditioning({
						...digitalConditioning,
						D6: new_data,
					});
					break;
				case 'D7':
					setDigitalConditioning({
						...digitalConditioning,
						D7: new_data,
					});
					break;
				case 'D8':
					setDigitalConditioning({
						...digitalConditioning,
						D8: new_data,
					});
					break;
				case 'D9':
					setDigitalConditioning({
						...digitalConditioning,
						D9: new_data,
					});
					break;
				case 'D10':
					setDigitalConditioning({
						...digitalConditioning,
						D10: new_data,
					});
					break;
				case 'D11':
					setDigitalConditioning({
						...digitalConditioning,
						D11: new_data,
					});
					break;
				default:
					break;
			}
		}
		setDigitalEditOpen(false);
	};
	const handleAnalogueEditDialogClose = new_data => {
		if (new_data !== undefined) {
			switch (new_data.input) {
				case 'A0':
					setAnalogueConditioning({
						...analogueConditioning,
						A0: new_data,
					});
					break;
				case 'A1':
					setAnalogueConditioning({
						...analogueConditioning,
						A1: new_data,
					});
					break;
				case 'A2':
					setAnalogueConditioning({
						...analogueConditioning,
						A2: new_data,
					});
					break;
				case 'A3':
					setAnalogueConditioning({
						...analogueConditioning,
						A3: new_data,
					});
					break;
				default:
					break;
			}
		}
		setAnalogueEditOpen(false);
	};
	const handleEmEditDialogClose = new_data => {
		/*if(new_data !== undefined){
			var input = new_data.input;
			
			var temp = analogueConditioning;
			console.log(temp);
			console.log({...temp, A0:new_data});

			delete new_data.input;
			switch(input)
			{
				case "A0":
					setAnalogueConditioning({...analogueConditioning, A0:new_data});
					break;
				case "A1":
					setAnalogueConditioning({...analogueConditioning, A1:new_data});
					break;
				case "A2":
					setAnalogueConditioning({...analogueConditioning, A2:new_data});
					break;
				case "A3":
					setAnalogueConditioning({...analogueConditioning, A3:new_data});
					break;
			}
		}*/
		setEmEditOpen(false);
	};

	const handleDigitalEdit = (input, values) => event => {
		setCurrentConditioning(input);
		setCurrentValues(values);
		setDigitalEditOpen(true);
	};

	const handleAnalogueEdit = (input, values) => event => {
		setCurrentConditioning(input);
		setCurrentValues(values);
		setAnalogueEditOpen(true);
	};

	const handleEmEdit = (input, values) => event => {
		setCurrentConditioning(input);
		setCurrentValues(values);
		setEmEditOpen(true);
	};

	const handleRawSwitchChange = event => {
		if (event.target.checked) {
			setViewConditioned(true);
		} else {
			setViewConditioned(false);
		}
	};

	const handleRTSwitchChange = event => {
		var rt_msg = {};
		rt_msg['request'] = 'publish';
		rt_msg['message'] = {};
		rt_msg['topic'] = 'DEV/' + deviceInfo.uid + '/RT';
		//rt_msg['uid'] = deviceInfo.uid;
		if (event.target.checked) {
			setInRTMode(true);
			rt_msg.message = JSON.stringify({ enable: true });
			//rt_msg.message['enable'] = true;
		} else {
			setInRTMode(false);
			rt_msg.message = JSON.stringify({ enable: false });
			//rt_msg.message['enable'] = false;
		}
		console.log('WS Send: ' + JSON.stringify(rt_msg));
		webSocket.current.send(JSON.stringify(rt_msg));
	};

	/*async function getFromServer(rest_url) {
		console.log(rest_url);
		if (appOrg) {
			var req_url = process.env.REACT_APP_API_SERVER_URL + rest_url;
			return new Promise((resolve, reject) => {
				Auth.currentSession().then(user_session => {
					let user_token = user_session.getAccessToken();
					fetch(req_url, {
						method: 'GET',
						headers: {
							Authorization: user_token.jwtToken,
							Accept: 'application/json',
							'Content-Type': 'application/json',
							Organisation: appOrg.uuid,
						},
					})
						.then(res => res.json())
						.then(response => {
							if (response['result'] === 'OK') {
								resolve(response);
							} else {
								reject('Server record not found');
							}
						})
						.catch(error => reject(error));
				});
			});
		}
	}*/

	/*const getChannelData = useCallback(
		channel_name => {
			if (appOrg) {
				var s_time = Date.now();
				s_time = s_time - 60 * 60 * 24 * 1000;
				var req_url = new URL(
					process.env.REACT_APP_API_SERVER_URL +
						'/v1/channel_data/' +
						device_id
				);
				var params = { channel: channel_name, start_time: s_time };
				req_url.search = new URLSearchParams(params).toString();

				//console.log(req_url);
				//var req_url = process.env.REACT_APP_API_SERVER_URL + '/v1/channel_data/' + device_id;
				return new Promise((resolve, reject) => {
					Auth.currentSession().then(user_session => {
						let user_token = user_session.getAccessToken();
						fetch(req_url, {
							method: 'GET',
							headers: {
								Authorization: user_token.jwtToken,
								Accept: 'application/json',
								'Content-Type': 'application/json',
								Organisation: appOrg.uuid,
							},
						})
							.then(res => res.json())
							.then(response => {
								if (response['result'] === 'OK') {
									resolve(response.data_records);
								} else {
									reject('Server record not found');
								}
							})
							.catch(error => reject(error));
					});
				});
			}
		},
		[device_id, appOrg]
	);*/

	useEffect(
		() => {
			try {
				console.log('newChannelData', newChannelData);
				// var channel_update = channelData.map(channel => {
				// 	if (channel.name === newChannelData.name) {
				// 		let new_data_point = newChannelData;
				// 		delete new_data_point.name;
				// 		return Object.assign({}, channel, {
				// 			data: [...channel.data, new_data_point],
				// 		});
				// 	}
				// 	return channel;
				// });

				// setChannelData(channel_update);
			} catch (err) {
				console.log('error adding new data');
			}
		},
		[newChannelData]
	);

	const digitalInput = (input, values, conditioned) => {
		if (conditioned === false) {
			return (
				<Card variant="outlined">
					<CardHeader title={input.input + ' - ' + input.tag} />
					<CardContent>
						<div className={classes.inputValue}>
							{values.valueRaw && <>HIGH</>}
							{!values.valueRaw && <>LOW</>}
						</div>
					</CardContent>
					<CardActions>
						<Button
							variant="outlined"
							startIcon={<EditIcon />}
							onClick={handleDigitalEdit(input, values)}
							className={classes.actionButton}
						>
							Edit
						</Button>
					</CardActions>
				</Card>
			);
		} else {
			return (
				<Card variant="outlined">
					<CardHeader title={input.input + ' - ' + input.tag} />
					<CardContent>
						<div className={classes.inputValue}>
							{values.valueConditioned && <>ON</>}
							{!values.valueConditioned && <>OFF</>}
						</div>
					</CardContent>
					<CardActions>
						<Button
							variant="outlined"
							startIcon={<EditIcon />}
							onClick={handleDigitalEdit(input, values)}
							className={classes.actionButton}
						>
							Edit
						</Button>
					</CardActions>
				</Card>
			);
		}
	};

	const analogueInput = (input, values, conditioned) => {
		if (conditioned === false) {
			return (
				<Card variant="outlined">
					<CardHeader title={input.input + ' - ' + input.tag} />
					<CardContent>
						<div className={classes.inputValue}>
							{values.valueRawV / 1000 + 'V'}
						</div>
						<div className={classes.inputValue}>
							{values.valueRawI / 1000 + 'mA'}
						</div>
					</CardContent>
					<CardActions>
						<Button
							variant="outlined"
							startIcon={<EditIcon />}
							onClick={handleAnalogueEdit(input, values)}
							className={classes.actionButton}
						>
							Edit
						</Button>
					</CardActions>
				</Card>
			);
		} else {
			return (
				<Card variant="outlined">
					<CardHeader title={input.input + ' - ' + input.tag} />
					<CardContent>
						<div className={classes.inputValue}>
							{values.valueConditioned + input.unit}
						</div>
					</CardContent>
					<CardActions>
						<Button
							variant="outlined"
							startIcon={<EditIcon />}
							onClick={handleAnalogueEdit(input, values)}
							className={classes.actionButton}
						>
							Edit
						</Button>
					</CardActions>
				</Card>
			);
		}
	};

	const emInput = (input, values) => {
		return (
			<Card variant="outlined">
				<CardHeader title={input.input} />
				<CardContent>
					<div className={classes.inputValue}>{values.value}A</div>
				</CardContent>
				<CardActions>
					<Button
						variant="outlined"
						startIcon={<EditIcon />}
						onClick={handleEmEdit(input, values)}
						className={classes.actionButton}
					>
						Edit
					</Button>
				</CardActions>
			</Card>
		);
	};

	useEffect(
		() => {
			//var d_values = { ...digitalValues };
			//var a_values = { ...analogueValues };
			var d_values = {};
			var a_values = {};

			console.log(updateRT);

			/*updateRT.forEach(input => {
				switch (input.input) {
					case 'D0':
						//d_values.splice(0, 1, {...d_values[0], valueRaw:input.valueRaw, valueConditioned:input.valueConditioned});
						d_values['D0'].valueRaw = input.valueRaw;
						d_values['D0'].valueConditioned =
							input.valueConditioned;
						break;
					case 'D1':
						//d_values.splice(1, 1, {...d_values[1], valueRaw:input.valueRaw, valueConditioned:input.valueConditioned});
						d_values['D1'].valueRaw = input.valueRaw;
						d_values['D1'].valueConditioned =
							input.valueConditioned;
						break;
					case 'D2':
						//d_values.splice(2, 1, {...d_values[2], valueRaw:input.valueRaw, valueConditioned:input.valueConditioned});
						d_values['D2'].valueRaw = input.valueRaw;
						d_values['D2'].valueConditioned =
							input.valueConditioned;
						break;
					case 'D3':
						//d_values.splice(3, 1, {...d_values[3], valueRaw:input.valueRaw, valueConditioned:input.valueConditioned});
						d_values['D3'].valueRaw = input.valueRaw;
						d_values['D3'].valueConditioned =
							input.valueConditioned;
						break;
					case 'D4':
						//d_values.splice(0, 1, {...d_values[0], valueRaw:input.valueRaw, valueConditioned:input.valueConditioned});
						d_values['D4'].valueRaw = input.valueRaw;
						d_values['D4'].valueConditioned =
							input.valueConditioned;
						break;
					case 'D5':
						//d_values.splice(1, 1, {...d_values[1], valueRaw:input.valueRaw, valueConditioned:input.valueConditioned});
						d_values['D5'].valueRaw = input.valueRaw;
						d_values['D5'].valueConditioned =
							input.valueConditioned;
						break;
					case 'D6':
						//d_values.splice(2, 1, {...d_values[2], valueRaw:input.valueRaw, valueConditioned:input.valueConditioned});
						d_values['D6'].valueRaw = input.valueRaw;
						d_values['D6'].valueConditioned =
							input.valueConditioned;
						break;
					case 'D7':
						//d_values.splice(3, 1, {...d_values[3], valueRaw:input.valueRaw, valueConditioned:input.valueConditioned});
						d_values['D7'].valueRaw = input.valueRaw;
						d_values['D7'].valueConditioned =
							input.valueConditioned;
						break;
					case 'D8':
						//d_values.splice(0, 1, {...d_values[0], valueRaw:input.valueRaw, valueConditioned:input.valueConditioned});
						d_values['D8'].valueRaw = input.valueRaw;
						d_values['D8'].valueConditioned =
							input.valueConditioned;
						break;
					case 'D9':
						//d_values.splice(1, 1, {...d_values[1], valueRaw:input.valueRaw, valueConditioned:input.valueConditioned});
						d_values['D9'].valueRaw = input.valueRaw;
						d_values['D9'].valueConditioned =
							input.valueConditioned;
						break;
					case 'D10':
						//d_values.splice(2, 1, {...d_values[2], valueRaw:input.valueRaw, valueConditioned:input.valueConditioned});
						d_values['D10'].valueRaw = input.valueRaw;
						d_values['D10'].valueConditioned =
							input.valueConditioned;
						break;
					case 'D11':
						//d_values.splice(3, 1, {...d_values[3], valueRaw:input.valueRaw, valueConditioned:input.valueConditioned});
						d_values['D11'].valueRaw = input.valueRaw;
						d_values['D11'].valueConditioned =
							input.valueConditioned;
						break;

					case 'A0':
						//a_values.splice(0, 1, {...a_values[0], valueRawV:input.valueRawV, valueRawI:input.valueRawI, valueConditioned:input.valueConditioned});
						a_values.A0.valueRawV = input.valueRawV;
						a_values.A0.valueRawI = input.valueRawI;
						a_values.A0.valueConditioned = input.valueConditioned;
						break;
					case 'A1':
						//a_values.splice(1, 1, {...a_values[1], valueRawV:input.valueRawV, valueRawI:input.valueRawI, valueConditioned:input.valueConditioned});
						a_values['A1'].valueRawV = input.valueRawV;
						a_values['A1'].valueRawI = input.valueRawI;
						a_values['A1'].valueConditioned =
							input.valueConditioned;
						break;
					case 'A2':
						//a_values.splice(2, 1, {...a_values[2], valueRawV:input.valueRawV, valueRawI:input.valueRawI, valueConditioned:input.valueConditioned});
						a_values['A2'].valueRawV = input.valueRawV;
						a_values['A2'].valueRawI = input.valueRawI;
						a_values['A2'].valueConditioned =
							input.valueConditioned;
						break;
					case 'A3':
						//a_values.splice(3, 1, {...a_values[3], valueRawV:input.valueRawV, valueRawI:input.valueRawI, valueConditioned:input.valueConditioned});
						a_values['A3'].valueRawV = input.valueRawV;
						a_values['A3'].valueRawI = input.valueRawI;
						a_values['A3'].valueConditioned =
							input.valueConditioned;
						break;
					default:
						break;
				}
			});
			setDigitalValues(d_values);
			setAnalogueValues(a_values);*/
		},
		[updateRT]
	);

	const updateConStats = new_con_stats => {
		//console.log(new_con_stats);

		let con_stats_update = {};

		/*if(response.devices[index].stats.connected !== undefined) {
				con_stats_update['status'] = response.devices[index].stats.connected;
			} else {
				con_stats_update['status'] = false;
			}*/

		try {
			if (new_con_stats.rssi !== undefined) {
				con_stats_update['rssi'] = new_con_stats.rssi + ' dbm';
			} else {
				con_stats_update['rssi'] = '-';
			}
		} catch {
			con_stats_update['rssi'] = '-';
		}

		try {
			if (new_con_stats.rtt !== undefined) {
				con_stats_update['rtt'] = new_con_stats.rtt + ' ms';
			} else {
				con_stats_update['rtt'] = '-';
			}
		} catch {
			con_stats_update['rtt'] = '-';
		}

		try {
			if (new_con_stats.pkt_drop !== undefined) {
				con_stats_update['pkt_drop'] = new_con_stats.pkt_drop;
			} else {
				con_stats_update['pkt_drop'] = '-';
			}
		} catch {
			con_stats_update['pkt_drop'] = '-';
		}

		try {
			if (new_con_stats.con_count !== undefined) {
				con_stats_update['con_count'] = new_con_stats.con_count;
			} else {
				con_stats_update['con_count'] = '-';
			}
		} catch {
			con_stats_update['con_count'] = '-';
		}

		try {
			if (new_con_stats.boot_str !== undefined) {
				con_stats_update['boot_str'] = new_con_stats.boot_str;
			} else {
				con_stats_update['boot_str'] = '-';
			}
		} catch {
			con_stats_update['boot_str'] = '-';
		}

		try {
			if (new_con_stats.uptime !== undefined) {
				let uptime = new_con_stats.uptime;
				if (typeof uptime !== 'number') {
					con_stats_update['uptime'] = '-';
				} else {
					// TODO: Move to util lib
					let days = Math.floor(uptime / (24 * 60 * 60 * 1000));
					uptime -= days * (24 * 60 * 60 * 1000);

					let hours = Math.floor(uptime / (60 * 60 * 1000));
					uptime -= hours * (60 * 60 * 1000);

					let minutes = Math.floor(uptime / (60 * 1000));
					uptime -= minutes * (60 * 1000);

					let seconds = Math.floor(uptime / 1000);
					uptime -= seconds * 1000;

					let uptime_str = '';
					if (days > 0) {
						uptime_str = uptime_str + `${days}d `;
					}
					if (hours > 0) {
						uptime_str = uptime_str + `${hours}h `;
					}
					if (minutes > 0) {
						uptime_str = uptime_str + `${minutes}m `;
					}
					if (seconds > 0) {
						uptime_str = uptime_str + `${seconds}s `;
					}
					con_stats_update['uptime'] = uptime_str;
				}
			} else {
				con_stats_update['uptime'] = '-';
			}
		} catch {
			con_stats_update['uptime'] = '-';
		}

		setConStats(con_stats_update);
	};

	/*useEffect(
		() => {
			const onConfigUpdate = async () => {
				if (configData !== undefined) {
					if (configData.channels !== undefined) {
						var new_channels = [];
						for (const channel of configData.channels) {
							var new_channel = {};
							new_channel.name = channel.name;
							new_channel.params = channel.params.map(param => {
								return param.name;
							});
							new_channel.data = getChannelData(channel.name);

							if (new_channel.data.length > 360) {
								new_channel.data = new_channel.data.splice(
									new_channel.data.length - 180
								);
							}
							new_channels.push(new_channel);
						}

						console.log(new_channels);
						setChannelData(new_channels);
					}
				}
			};

			onConfigUpdate();
		},
		[configData, getChannelData]
	);*/

	//const client = new W3CWebSocket(process.env.REACT_APP_API_WS_URL);
	useEffect(
		() => {
			var device_uid = '';
			(async () => {
				if (appOrg) {
					//var device_uid = '';
					try {
						// The devcie info is used below, due to the way react state does not update straight away we need to store it in a var
						let device_info = await deviceGet({
							deviceId: device_id,
						});

						device_info.type = await deviceTypeGet({
							typeId: device_info.type,
						});

						// Catch if a device does not have a reported obj
						if (device_info.reported === undefined) {
							device_info.reported = {};
						}
						console.log(device_info);
						setDeviceInfo(device_info);

						// TODO: what should we do if this fails?
						try {
							updateConStats(device_info.stats.stats);
						} catch (err) {}

						device_uid = device_info.uid;
						let devHasConfig = false;

						if (device_info.type.device_code === 'GPDH-4A4D') {
							setDeviceTypeGP_4A4D(true);
							devHasConfig = true;
						} else if (
							device_info.type.device_code === 'GPDH-12D'
						) {
							setDeviceTypeGP_12D(true);
							devHasConfig = true;
						} else if (device_info.type.device_code === 'EM-3P') {
							setDeviceTypeEM(true);
							devHasConfig = true;
						} else if (device_info.type.device_code === 'BRIIV') {
							setDeviceTypeBRIIV(true);
						} else if (
							device_info.type.device_code === 'BRIIVMK2'
						) {
							setDeviceTypeBRIIV_MK2(true);
						} else {
							console.log(
								'Unknown device type: ' +
									device_info.type.device_code
							);
						}

						// Get the Config for devices that support it
						if (
							// deviceTypeGP_4A4D ||
							// deviceTypeGP_12D ||
							// deviceTypeEM
							devHasConfig
						) {
							var config_data = await configVersionGet({
								configId: device_info.config,
							});
							console.log(config_data);

							setConfigData(config_data);

							let channels = config_data.channels.map(channel => {
								let params = channel.params.map(param => {
									return param.name;
								});
								return { name: channel.name, params: params };
							});
							setChannelConfig(channels);
						}

						/*setTaskData(
							(await getFromServer(
								'/v1/task/' +
									device_info.uid +
									'?status=pending'
							)).task_data
						);*/

						try {
							var firmware_info = await firmwareVersionGet({
								firmwareId: device_info.firmware,
							});
							setFirmwareData(firmware_info);
						} catch (error) {
							console.log(error);
						}

						var d_base = {};
						d_base['tag'] = 'Digital Input';
						d_base['polarity'] = 'inverted';
						d_base['filter'] = '5';

						var di = [];
						d_base['input'] = 'D0';
						di['D0'] = { ...d_base };
						d_base['input'] = 'D1';
						di['D1'] = { ...d_base };
						d_base['input'] = 'D2';
						di['D2'] = { ...d_base };
						d_base['input'] = 'D3';
						di['D3'] = { ...d_base };
						d_base['input'] = 'D4';
						di['D4'] = { ...d_base };
						d_base['input'] = 'D5';
						di['D5'] = { ...d_base };
						d_base['input'] = 'D6';
						di['D6'] = { ...d_base };
						d_base['input'] = 'D7';
						di['D7'] = { ...d_base };
						d_base['input'] = 'D8';
						di['D8'] = { ...d_base };
						d_base['input'] = 'D9';
						di['D9'] = { ...d_base };
						d_base['input'] = 'D10';
						di['D10'] = { ...d_base };
						d_base['input'] = 'D11';
						di['D11'] = { ...d_base };

						if (device_info.conditioning !== undefined) {
							if (device_info.conditioning.data !== undefined) {
								if (
									device_info.conditioning.data.D0 !==
									undefined
								) {
									di['D0']['tag'] =
										device_info.conditioning.data.D0.tag;
									di['D0']['polarity'] =
										device_info.conditioning.data.D0.polarity;
									di['D0']['filter'] =
										device_info.conditioning.data.D0.filter;
								}
								if (
									device_info.conditioning.data.D1 !==
									undefined
								) {
									di['D1']['tag'] =
										device_info.conditioning.data.D1.tag;
									di['D1']['polarity'] =
										device_info.conditioning.data.D1.polarity;
									di['D1']['filter'] =
										device_info.conditioning.data.D1.filter;
								}
								if (
									device_info.conditioning.data.D2 !==
									undefined
								) {
									di['D2']['tag'] =
										device_info.conditioning.data.D2.tag;
									di['D2']['polarity'] =
										device_info.conditioning.data.D2.polarity;
									di['D2']['filter'] =
										device_info.conditioning.data.D2.filter;
								}
								if (
									device_info.conditioning.data.D3 !==
									undefined
								) {
									di['D3']['tag'] =
										device_info.conditioning.data.D3.tag;
									di['D3']['polarity'] =
										device_info.conditioning.data.D3.polarity;
									di['D3']['filter'] =
										device_info.conditioning.data.D3.filter;
								}
								if (
									device_info.conditioning.data.D4 !==
									undefined
								) {
									di['D4']['tag'] =
										device_info.conditioning.data.D4.tag;
									di['D4']['polarity'] =
										device_info.conditioning.data.D4.polarity;
									di['D4']['filter'] =
										device_info.conditioning.data.D4.filter;
								}
								if (
									device_info.conditioning.data.D5 !==
									undefined
								) {
									di['D5']['tag'] =
										device_info.conditioning.data.D5.tag;
									di['D5']['polarity'] =
										device_info.conditioning.data.D5.polarity;
									di['D5']['filter'] =
										device_info.conditioning.data.D5.filter;
								}
								if (
									device_info.conditioning.data.D6 !==
									undefined
								) {
									di['D6']['tag'] =
										device_info.conditioning.data.D6.tag;
									di['D6']['polarity'] =
										device_info.conditioning.data.D6.polarity;
									di['D6']['filter'] =
										device_info.conditioning.data.D6.filter;
								}
								if (
									device_info.conditioning.data.D7 !==
									undefined
								) {
									di['D7']['tag'] =
										device_info.conditioning.data.D7.tag;
									di['D7']['polarity'] =
										device_info.conditioning.data.D7.polarity;
									di['D7']['filter'] =
										device_info.conditioning.data.D7.filter;
								}
								if (
									device_info.conditioning.data.D8 !==
									undefined
								) {
									di['D8']['tag'] =
										device_info.conditioning.data.D8.tag;
									di['D8']['polarity'] =
										device_info.conditioning.data.D8.polarity;
									di['D8']['filter'] =
										device_info.conditioning.data.D8.filter;
								}
								if (
									device_info.conditioning.data.D9 !==
									undefined
								) {
									di['D9']['tag'] =
										device_info.conditioning.data.D9.tag;
									di['D9']['polarity'] =
										device_info.conditioning.data.D9.polarity;
									di['D9']['filter'] =
										device_info.conditioning.data.D9.filter;
								}
								if (
									device_info.conditioning.data.D10 !==
									undefined
								) {
									di['D10']['tag'] =
										device_info.conditioning.data.D10.tag;
									di['D10']['polarity'] =
										device_info.conditioning.data.D10.polarity;
									di['D10']['filter'] =
										device_info.conditioning.data.D10.filter;
								}
								if (
									device_info.conditioning.data.D11 !==
									undefined
								) {
									di['D11']['tag'] =
										device_info.conditioning.data.D11.tag;
									di['D11']['polarity'] =
										device_info.conditioning.data.D11.polarity;
									di['D11']['filter'] =
										device_info.conditioning.data.D11.filter;
								}
							}
						}
						setDigitalConditioning(di);

						var dv = {};
						dv['D0'] = { valueRaw: false, valueConditioned: false };
						dv['D1'] = { valueRaw: false, valueConditioned: false };
						dv['D2'] = { valueRaw: false, valueConditioned: false };
						dv['D3'] = { valueRaw: false, valueConditioned: false };
						dv['D4'] = { valueRaw: false, valueConditioned: false };
						dv['D5'] = { valueRaw: false, valueConditioned: false };
						dv['D6'] = { valueRaw: false, valueConditioned: false };
						dv['D7'] = { valueRaw: false, valueConditioned: false };
						dv['D8'] = { valueRaw: false, valueConditioned: false };
						dv['D9'] = { valueRaw: false, valueConditioned: false };
						dv['D10'] = {
							valueRaw: false,
							valueConditioned: false,
						};
						dv['D11'] = {
							valueRaw: false,
							valueConditioned: false,
						};
						setDigitalValues(dv);

						var a_base = {};
						a_base['tag'] = 'Ananlogue Input';
						a_base['unit'] = 'V';
						a_base['filter'] = '0';
						a_base['source'] = 'V';
						a_base['m'] = 0.001;
						a_base['b'] = 0;

						var ai = [];
						a_base['input'] = 'A0';
						ai['A0'] = { ...a_base };
						a_base['input'] = 'A1';
						ai['A1'] = { ...a_base };
						a_base['input'] = 'A2';
						ai['A2'] = { ...a_base };
						a_base['input'] = 'A3';
						ai['A3'] = { ...a_base };

						if (device_info.conditioning !== undefined) {
							if (device_info.conditioning.data !== undefined) {
								if (
									device_info.conditioning.data.A0 !==
									undefined
								) {
									ai['A0']['tag'] =
										device_info.conditioning.data.A0.tag;
									ai['A0']['unit'] =
										device_info.conditioning.data.A0.unit;
									ai['A0']['filter'] =
										device_info.conditioning.data.A0.filter;
									ai['A0']['source'] =
										device_info.conditioning.data.A0.source;
									ai['A0']['m'] =
										device_info.conditioning.data.A0.m;
									ai['A0']['b'] =
										device_info.conditioning.data.A0.b;
								}
								if (
									device_info.conditioning.data.A1 !==
									undefined
								) {
									ai['A1']['tag'] =
										device_info.conditioning.data.A1.tag;
									ai['A1']['unit'] =
										device_info.conditioning.data.A1.unit;
									ai['A1']['filter'] =
										device_info.conditioning.data.A1.filter;
									ai['A1']['source'] =
										device_info.conditioning.data.A1.source;
									ai['A1']['m'] =
										device_info.conditioning.data.A1.m;
									ai['A1']['b'] =
										device_info.conditioning.data.A1.b;
								}
								if (
									device_info.conditioning.data.A2 !==
									undefined
								) {
									ai['A2']['tag'] =
										device_info.conditioning.data.A2.tag;
									ai['A2']['unit'] =
										device_info.conditioning.data.A2.unit;
									ai['A2']['filter'] =
										device_info.conditioning.data.A2.filter;
									ai['A2']['source'] =
										device_info.conditioning.data.A2.source;
									ai['A2']['m'] =
										device_info.conditioning.data.A2.m;
									ai['A2']['b'] =
										device_info.conditioning.data.A2.b;
								}
								if (
									device_info.conditioning.data.A3 !==
									undefined
								) {
									ai['A3']['tag'] =
										device_info.conditioning.data.A3.tag;
									ai['A3']['unit'] =
										device_info.conditioning.data.A3.unit;
									ai['A3']['filter'] =
										device_info.conditioning.data.A3.filter;
									ai['A3']['source'] =
										device_info.conditioning.data.A3.source;
									ai['A3']['m'] =
										device_info.conditioning.data.A3.m;
									ai['A3']['b'] =
										device_info.conditioning.data.A3.b;
								}
							}
						}
						setAnalogueConditioning(ai);

						var av = {};
						av['A0'] = {
							valueRawV: 0,
							valueRawI: 0,
							valueConditioned: 0,
						};
						av['A1'] = {
							valueRawV: 0,
							valueRawI: 0,
							valueConditioned: 0,
						};
						av['A2'] = {
							valueRawV: 0,
							valueRawI: 0,
							valueConditioned: 0,
						};
						av['A3'] = {
							valueRawV: 0,
							valueRawI: 0,
							valueConditioned: 0,
						};
						setAnalogueValues(av);

						a_base['valueRawV'] = 11220;
						a_base['valueRawI'] = 15000;
						a_base['valueConditioned'] = 11.22;

						var em_base = {};
						em_base['ct_ratio'] = 30;

						var em_input = [];
						em_base['input'] = 'P1';
						em_input['P1'] = { ...em_base };
						em_base['input'] = 'P2';
						em_input['P2'] = { ...em_base };
						em_base['input'] = 'P3';
						em_input['P3'] = { ...em_base };

						if (device_info.conditioning !== undefined) {
							if (device_info.conditioning.data !== undefined) {
								if (
									device_info.conditioning.data.P1 !==
									undefined
								) {
									em_input['P1']['ct_ratio'] =
										device_info.conditioning.data.P1.ct_ratio;
								}
								if (
									device_info.conditioning.data.P2 !==
									undefined
								) {
									em_input['P2']['ct_ratio'] =
										device_info.conditioning.data.P2.ct_ratio;
								}
								if (
									device_info.conditioning.data.P3 !==
									undefined
								) {
									em_input['P3']['ct_ratio'] =
										device_info.conditioning.data.P3.ct_ratio;
								}
							}
						}
						setEmConditioning(em_input);

						var emv = {};
						emv['P1'] = { value: 0 };
						emv['P2'] = { value: 0 };
						emv['P3'] = { value: 0 };
						setEmValues(emv);

						// webSocket.current = new WebSocket(
						// 	process.env.REACT_APP_API_WS_URL
						// );
						// webSocket.current.onopen = () => {
						// 	var sub_msg = {};
						// 	sub_msg['request'] = 'subscribe';
						// 	sub_msg['topic'] = 'SVR/' + device_info.uid + '/*';
						// 	webSocket.current.send(JSON.stringify(sub_msg));
						// };

						// webSocket.current.onmessage = message => {
						// 	setWebsocketVal(message.data);
						// 	var msg_data = JSON.parse(message.data);

						// 	console.log('webSocket onMessage', msg_data);
						// 	//console.log('msg channel: ' + msg_data.channel);

						// 	try {
						// 		let packet = JSON.parse(msg_data.message);
						// 		//console.log(packet);

						// 		if (packet.type === 'task_update') {
						// 			setProgress(
						// 				(packet.progress / packet.filesize) *
						// 					100
						// 			);
						// 		} else if (packet.type === 'channel_data') {
						// 			// TODO: Find a way to make the following work
						// 			setNewChannelData(packet.data);
						// 		} else if (packet.type === 'rt_data') {
						// 			if (packet.data !== undefined) {
						// 				setUpdateRT(packet.data);
						// 			}
						// 		} else if (packet.type === 'con_stats') {
						// 			updateConStats(packet.data);
						// 		}
						// 	} catch (err) {
						// 		console.log(err);
						// 	}
						// };
						console.log(' LOAD DONE');
						setIsLoading(false);
					} catch (e) {
						console.log(e);
					}
					//setIsLoading(false);
				} else {
					console.log('NO ORG ID!');
				}
			})();

			return function cleanup() {
				//console.log('send unsub');
				if (webSocket.current != null) {
					var sub_msg = {};
					sub_msg['request'] = 'unsubscribe';
					var topics = [];
					topics.push('SVR/' + device_uid + '/*');
					sub_msg['topics'] = topics;
					webSocket.current.send(JSON.stringify(sub_msg));

					//if(inRTMode === true) {
					var rt_msg = {};
					rt_msg['request'] = 'real_time';
					rt_msg['uid'] = device_uid;
					rt_msg['enable'] = false;
					webSocket.current.send(JSON.stringify(rt_msg));
					//}

					webSocket.current.close();
				}
			};
		},
		[device_id, appOrg]
	);

	return (
		<BtLoading loading={isLoading} showLogo={true}>
			{!isLoading && (
				<>
					<div className={classes.root}>
						<Grid
							container
							direction="row"
							justifyContent="space-evenly"
							spacing={3}
							alignItems="stretch"
						>
							<Grid item xs={12}>
								<BtTabView>
									<BtTabBar
										currentTab={currentTab}
										onTabChange={handleTabChange}
									>
										<BtTab
											label="Data Channels"
											{...a11yProps(0)}
										/>
										<BtTab
											label="Inputs"
											{...a11yProps(1)}
										/>
										<BtTab
											label="Settings"
											{...a11yProps(2)}
										/>
										<BtTab
											label="Statistics"
											{...a11yProps(3)}
										/>
										<BtTab
											label="Admin"
											{...a11yProps(4)}
										/>
									</BtTabBar>

									<BtTabPanel
										currentTab={currentTab}
										index={0}
									>
										<ChannelDataCollection
											deviceId={device_id}
											channelConfig={channelConfig}
										/>
									</BtTabPanel>

									<BtTabPanel
										currentTab={currentTab}
										index={1}
									>
										<DeviceInputs
											deviceType={
												deviceInfo.type.device_code
											}
											inputValues={updateRT}
											enableRT={handleRTSwitchChange}
											inRT={inRTMode}
										/>
									</BtTabPanel>

									<BtTabPanel
										currentTab={currentTab}
										index={2}
									>
										<Grid
											container
											spacing={3}
											alignItems="stretch"
										>
											<Grid item xs={6}>
												<Card variant="outlined">
													<CardHeader
														title={
															'Device: ' +
															deviceInfo['uid']
														}
													/>
													<CardContent>
														<DeviceHeader
															className={
																classes.paper
															}
															dev_info={
																deviceInfo
															}
														/>
													</CardContent>
												</Card>
											</Grid>

											<Grid item xs={6}>
												<Card variant="outlined">
													<CardHeader
														title={'Device Status'}
													/>
													<CardContent>
														<ListTable>
															<ListRow>
																<ListHeader>
																	Status:
																</ListHeader>
																<ListCell>
																	Not Set
																</ListCell>
															</ListRow>
															<ListRow>
																<ListHeader>
																	Last
																	Reconnect:
																</ListHeader>
																<ListCell>
																	Not Set
																</ListCell>
															</ListRow>

															<ListRow>
																<ListHeader>
																	RSSI:
																</ListHeader>
																<ListCell>
																	{
																		conStats.rssi
																	}
																</ListCell>
															</ListRow>
															<ListRow>
																<ListHeader>
																	RTT:
																</ListHeader>
																<ListCell>
																	{
																		conStats.rtt
																	}
																</ListCell>
															</ListRow>
															<ListRow>
																<ListHeader>
																	PKT Drop:
																</ListHeader>
																<ListCell>
																	{
																		conStats.pkt_drop
																	}
																</ListCell>
															</ListRow>
															<ListRow>
																<ListHeader>
																	Con Count:
																</ListHeader>
																<ListCell>
																	{
																		conStats.con_count
																	}
																</ListCell>
															</ListRow>
															<ListRow>
																<ListHeader>
																	Boot:
																</ListHeader>
																<ListCell>
																	{
																		conStats.boot_str
																	}
																</ListCell>
															</ListRow>
															<ListRow>
																<ListHeader>
																	Uptime:
																</ListHeader>
																<ListCell>
																	{
																		conStats.uptime
																	}
																</ListCell>
															</ListRow>
														</ListTable>
													</CardContent>
												</Card>
											</Grid>
											{deviceTypeGP_4A4D && (
												<Grid
													container
													spacing={3}
													alignItems="stretch"
												>
													<Grid item xs={12}>
														<Typography component="div">
															<Grid
																component="label"
																container
																alignItems="center"
																spacing={1}
															>
																<Grid item>
																	Raw
																</Grid>
																<Grid item>
																	<Switch
																		checked={
																			viewConditioned
																		}
																		onChange={
																			handleRawSwitchChange
																		}
																		name="SawConditioned"
																	/>
																</Grid>
																<Grid item>
																	Conditioned
																</Grid>
															</Grid>
														</Typography>
													</Grid>

													<Grid item xs={3}>
														{digitalInput(
															digitalConditioning.D0,
															digitalValues['D0'],
															viewConditioned
														)}
													</Grid>

													<Grid item xs={3}>
														{digitalInput(
															digitalConditioning.D1,
															digitalValues['D1'],
															viewConditioned
														)}
													</Grid>

													<Grid item xs={3}>
														{digitalInput(
															digitalConditioning.D2,
															digitalValues['D2'],
															viewConditioned
														)}
													</Grid>

													<Grid item xs={3}>
														{digitalInput(
															digitalConditioning.D3,
															digitalValues['D3'],
															viewConditioned
														)}
													</Grid>

													<Grid item xs={3}>
														{analogueInput(
															analogueConditioning.A0,
															analogueValues[
																'A0'
															],
															viewConditioned
														)}
													</Grid>

													<Grid item xs={3}>
														{analogueInput(
															analogueConditioning.A1,
															analogueValues[
																'A1'
															],
															viewConditioned
														)}
													</Grid>

													<Grid item xs={3}>
														{analogueInput(
															analogueConditioning.A2,
															analogueValues[
																'A2'
															],
															viewConditioned
														)}
													</Grid>

													<Grid item xs={3}>
														{analogueInput(
															analogueConditioning.A3,
															analogueValues[
																'A3'
															],
															viewConditioned
														)}
													</Grid>
												</Grid>
											)}

											{deviceTypeGP_12D && (
												<Grid
													container
													spacing={3}
													alignItems="stretch"
												>
													<Grid item xs={12}>
														<Typography component="div">
															<Grid
																component="label"
																container
																alignItems="center"
																spacing={1}
															>
																<Grid item>
																	Raw
																</Grid>
																<Grid item>
																	<Switch
																		checked={
																			viewConditioned
																		}
																		onChange={
																			handleRawSwitchChange
																		}
																		name="SawConditioned"
																	/>
																</Grid>
																<Grid item>
																	Conditioned
																</Grid>
															</Grid>
														</Typography>
													</Grid>

													<Grid item xs={3}>
														{digitalInput(
															digitalConditioning.D0,
															digitalValues['D0'],
															viewConditioned
														)}
													</Grid>

													<Grid item xs={3}>
														{digitalInput(
															digitalConditioning.D1,
															digitalValues['D1'],
															viewConditioned
														)}
													</Grid>

													<Grid item xs={3}>
														{digitalInput(
															digitalConditioning.D2,
															digitalValues['D2'],
															viewConditioned
														)}
													</Grid>

													<Grid item xs={3}>
														{digitalInput(
															digitalConditioning.D3,
															digitalValues['D3'],
															viewConditioned
														)}
													</Grid>

													<Grid item xs={3}>
														{digitalInput(
															digitalConditioning.D4,
															digitalValues['D4'],
															viewConditioned
														)}
													</Grid>

													<Grid item xs={3}>
														{digitalInput(
															digitalConditioning.D5,
															digitalValues['D5'],
															viewConditioned
														)}
													</Grid>

													<Grid item xs={3}>
														{digitalInput(
															digitalConditioning.D6,
															digitalValues['D6'],
															viewConditioned
														)}
													</Grid>

													<Grid item xs={3}>
														{digitalInput(
															digitalConditioning.D7,
															digitalValues['D7'],
															viewConditioned
														)}
													</Grid>

													<Grid item xs={3}>
														{digitalInput(
															digitalConditioning.D8,
															digitalValues['D8'],
															viewConditioned
														)}
													</Grid>

													<Grid item xs={3}>
														{digitalInput(
															digitalConditioning.D9,
															digitalValues['D9'],
															viewConditioned
														)}
													</Grid>

													<Grid item xs={3}>
														{digitalInput(
															digitalConditioning.D10,
															digitalValues[
																'D10'
															],
															viewConditioned
														)}
													</Grid>

													<Grid item xs={3}>
														{digitalInput(
															digitalConditioning.D11,
															digitalValues[
																'D11'
															],
															viewConditioned
														)}
													</Grid>
												</Grid>
											)}

											{deviceTypeEM && (
												<Grid
													container
													spacing={3}
													alignItems="stretch"
												>
													<Grid item xs={4}>
														{emInput(
															emConditioning.P1,
															emValues['P1']
														)}
													</Grid>

													<Grid item xs={4}>
														{emInput(
															emConditioning.P2,
															emValues['P2']
														)}
													</Grid>

													<Grid item xs={4}>
														{emInput(
															emConditioning.P3,
															emValues['P3']
														)}
													</Grid>
												</Grid>
											)}
											<Grid item xs={6}>
												<Card variant="outlined">
													<CardHeader
														title={'Configuration'}
													/>
													<CardContent>
														{!configData && (
															<Typography variant="h6">
																No Configuration
																Set
															</Typography>
														)}

														{configData && (
															<ListTable>
																<ListRow>
																	<ListHeader>
																		Name:
																	</ListHeader>
																	<ListCell>
																		{
																			configData.name
																		}
																	</ListCell>
																</ListRow>
																<ListRow>
																	<ListHeader>
																		Version:
																	</ListHeader>
																	<ListCell>
																		{
																			configData.version
																		}
																	</ListCell>
																</ListRow>
																<ListRow>
																	<ListHeader>
																		UUID:
																	</ListHeader>
																	<ListCell>
																		{
																			configData.uuid
																		}
																	</ListCell>
																</ListRow>
																<ListRow>
																	<ListHeader>
																		Description:
																	</ListHeader>
																	<ListCell>
																		{
																			configData.description
																		}
																	</ListCell>
																</ListRow>
																<ListRow>
																	<ListHeader>
																		Created:
																	</ListHeader>
																	<ListCell>
																		{new Date(
																			configData.create_timestamp
																		).toLocaleString()}
																	</ListCell>
																</ListRow>
															</ListTable>
														)}
													</CardContent>
													<CardActions>
														<Button
															variant="outlined"
															startIcon={
																<AddCircleIcon />
															}
															onClick={
																handleSelectConfigDialogOpen
															}
														>
															Select Configuration
														</Button>
														{/*<div><pre>{configData && JSON.stringify(configData.channels, null, 2)}</pre></div>*/}
													</CardActions>
												</Card>
											</Grid>

											<Grid item xs={6}>
												<Card variant="outlined">
													<CardHeader
														title={'Firmware'}
													/>
													<CardContent>
														{!firmwareData && (
															<Typography variant="h6">
																No Firmware Set
															</Typography>
														)}
														{firmwareData && (
															<ListTable>
																<ListRow>
																	<ListHeader>
																		File
																		Name:
																	</ListHeader>
																	<ListCell>
																		{
																			firmwareData.filename
																		}
																	</ListCell>
																</ListRow>
																<ListRow>
																	<ListHeader>
																		Version:
																	</ListHeader>
																	<ListCell>
																		{
																			firmwareData.version_str
																		}
																	</ListCell>
																</ListRow>
																<ListRow>
																	<ListHeader>
																		File
																		UUID:
																	</ListHeader>
																	<ListCell>
																		{
																			firmwareData.file_uuid
																		}
																	</ListCell>
																</ListRow>
																<ListRow>
																	<ListHeader>
																		Description:
																	</ListHeader>
																	<ListCell>
																		{
																			firmwareData.description
																		}
																	</ListCell>
																</ListRow>
																<ListRow>
																	<ListHeader>
																		File
																		Size:
																	</ListHeader>
																	<ListCell>
																		{
																			firmwareData.size
																		}{' '}
																		Bytes
																	</ListCell>
																</ListRow>
																<ListRow>
																	<ListHeader>
																		Created:
																	</ListHeader>
																	<ListCell>
																		{new Date(
																			firmwareData.create_timestamp
																		).toLocaleString()}
																	</ListCell>
																</ListRow>
																{/*deviceInfo
																	.reported
																	.firmware && (
																	<>
																		<ListRow
																		>
																			<ListHeader
																			>
																				Reported
																				UUID:
																			</ListHeader>
																			<ListCell
																			>
																				{
																					deviceInfo
																						.reported
																						.firmware
																						.uuid
																				}
																			</ListCell>
																		</ListRow>
																		<ListRow
																		>
																			<ListHeader
																			>
																				Updated
																				Time:
																			</ListHeader>
																			<ListCell
																			>
																				{
																					deviceInfo
																						.reported
																						.firmware
																						.timestamp
																				}
																			</ListCell>
																		</ListRow>
																	</>
																			)*/}
															</ListTable>
														)}
													</CardContent>
													<CardActions>
														<Button
															variant="outlined"
															startIcon={
																<AddCircleIcon />
															}
															onClick={
																handleSelectFirmwareDialogOpen
															}
														>
															Deploy Firmware
														</Button>
													</CardActions>
												</Card>
											</Grid>
										</Grid>

										<SelectConfigDialog
											setOpen={selectConfigOpen}
											onClose={
												handleSelectConfigDialogClose
											}
											deviceInfo={deviceInfo}
										/>
										<SelectFirmwareDialog
											setOpen={selectFirmwareOpen}
											onClose={
												handleSelectFirmwareDialogClose
											}
											deviceInfo={deviceInfo}
										/>
									</BtTabPanel>

									<BtTabPanel
										currentTab={currentTab}
										index={3}
									>
										<DeviceStats
											dev_id={deviceInfo['uid']}
										/>
										{/*<CustomPaper title={"Statistics"}>
                                <Typography variant='h5'>Device Statistics</Typography>
                                        </CustomPaper>*/}
									</BtTabPanel>

									<BtTabPanel
										currentTab={currentTab}
										index={4}
									>
										<Card variant="outlined">
											<CardHeader title="WS Test" />
											<CardContent>
												<Typography variant="h6">
													TEST VAL: {websocketVal}
												</Typography>
												<LinearProgressWithLabel
													value={progress}
												/>
											</CardContent>
										</Card>

										<DeviceAdminPanel
											dev_id={deviceInfo['uid']}
										/>
									</BtTabPanel>
								</BtTabView>
							</Grid>
						</Grid>
					</div>

					<DigitalDialog
						setOpen={digitalEditOpen}
						onClose={handleDigitalEditDialogClose}
						values={currentValues}
						conditioning={currentConditioning}
						device_uid={device_id}
					/>
					<AnalogueDialog
						setOpen={analogueEditOpen}
						onClose={handleAnalogueEditDialogClose}
						values={currentValues}
						conditioning={currentConditioning}
						device_uid={device_id}
					/>
					<EmDialog
						setOpen={emEditOpen}
						onClose={handleEmEditDialogClose}
						values={currentValues}
						conditioning={currentConditioning}
						device_uid={device_id}
					/>
				</>
			)}
		</BtLoading>
	);
}
