import { memo, useCallback, useMemo, useState } from 'react';
import { Archive, CheckCircle, Publish, Shape } from 'mdi-material-ui';
import {
	StatusDisplay,
	StatusRow,
	StatusTitle,
} from '../generic/StatusDisplay';
import { useRecordManagementContext } from '../../RecordManagementContext';
import PublishIcon from '@mui/icons-material/Publish';
import PropTypes from 'prop-types';

import {
	DialogActions,
	DialogContent,
	DialogTitle,
	useTheme,
	Button,
	DialogContentText,
} from '@mui/material';
import {
	UNPUBLISHED,
	ACTIVE,
	INACTIVE,
	RECORD_STATUS,
	PUBLISH,
	SET_CURRENT_PUBLISH,
	PUBLISH_WITH_DRAFT,
} from '../../utils/constants';
import BtDialog from '../../../BtDialog';
import ActionButtonContainer from '../../../ActionButtonContainer';
import { format } from 'date-fns';
import BtFilterSelect from '../../../filterSelect/BtFilterSelect';

const OK = 'ok';
const NO_VERSIONS = 'noVersions';
const NO_CURRENT_VERSION = 'NoCurrentVersion';

export const RecordStatusComponent = memo(
	({ historyData, moduleAssets, handleCallback, sending }) => {
		const theme = useTheme();
		const [publishDialogOpen, setPublishDialogOpen] = useState(false);
		const [selectedVersion, setSelectedVersion] = useState(null);

		const handleVersionSelect = selected => {
			console.log(selected);
			if (!selected) {
				setSelectedVersion(null);
			} else if (selected.id === 'draft') {
				setSelectedVersion('draft');
			} else {
				setSelectedVersion(Number(selected.id));
			}
		};

		const versionHistory = useMemo(
			() => {
				const versionData = historyData?.versions.map(
					(version, index) => {
						const item = {};
						item.id = index.toString();
						item.title = `Version ${index + 1}`;
						item.subtitle = `Committed by ${
							version.publish_by.user_name
						} at ${format(
							new Date(version.publish_timestamp || 0),
							'dd/MM/yy, hh:mm a'
						)}`;
						return item;
					}
				);
				return [
					{ id: 'draft', title: 'Use the draft' },
					...versionData.reverse(),
				];
			},
			[historyData]
		);

		const publishAttributes = useMemo(
			() => {
				// evaluate if the record is ready to be published
				// to be published it needs: current_version !== null && versions.length > 0
				const evalPublishAttributes = () => {
					if (historyData.current_version !== null) {
						return {
							status: OK,
						};
					} else if (historyData.versions.length === 0) {
						return {
							status: NO_VERSIONS,
						};
					} else {
						return {
							status: NO_CURRENT_VERSION,
						};
					}
				};

				const payload = evalPublishAttributes();
				return payload;
			},
			[historyData]
		);

		const handleStatusChangeClick = useCallback(
			() => {
				if ([ACTIVE, INACTIVE].includes(historyData.status)) {
					handleCallback({ callback: RECORD_STATUS });
				} else if (publishAttributes.status === OK) {
					handleCallback({ callback: PUBLISH });
				} else {
					setPublishDialogOpen(true);
				}
			},
			[handleCallback, historyData, publishAttributes]
		);

		const handleDialogPublish = useCallback(
			() => {
				// If there is no current version defined
				// we will set the current version to one
				// the existing versions or use the draft after committing it
				if (publishAttributes.status === NO_CURRENT_VERSION) {
					// If the user has selected to use the draft
					setPublishDialogOpen(false);
					if (selectedVersion === 'draft') {
						handleCallback({
							callback: PUBLISH_WITH_DRAFT,
						});
						// if the user has selected a version
					} else {
						handleCallback({
							callback: SET_CURRENT_PUBLISH,
							payload: {
								newCurrentVersion: selectedVersion,
							},
						});
					}
					// If there are no versions: commit the draft,
					// set it to current_version and publish record
				} else {
					setPublishDialogOpen(false);
					handleCallback({
						callback: PUBLISH_WITH_DRAFT,
					});
				}
			},
			[handleCallback, publishAttributes, selectedVersion]
		);

		const rowAttributes = useMemo(
			() => {
				if (historyData) {
					const lowerCaseTitle = moduleAssets.moduleTitle.toLowerCase();

					const variant = historyData?.status;

					const statusLabel = {
						[UNPUBLISHED]: 'Unpublished',
						[ACTIVE]: 'Active',
						[INACTIVE]: 'Archived',
					};

					const statusText = {
						[UNPUBLISHED]: `This ${lowerCaseTitle} will not be available until it is published`,
						[ACTIVE]: `This ${lowerCaseTitle} will be visible to users who are in the distribution list`,
						[INACTIVE]: `This ${lowerCaseTitle} will not be visible but will still be available`,
					};

					const icons = {
						[UNPUBLISHED]: Shape,
						[ACTIVE]: CheckCircle,
						[INACTIVE]: Archive,
					};

					const buttonIcon = {
						[UNPUBLISHED]: Publish,
						[ACTIVE]: Archive,
						[INACTIVE]: CheckCircle,
					};

					const buttonText = {
						[UNPUBLISHED]: 'Publish now',
						[ACTIVE]: 'Archive',
						[INACTIVE]: 'Make active',
					};

					const result = {};
					result.iconColour =
						theme.recordStatus.color[variant][
							theme.recordStatus.shade.icon
						];
					result.backgroundColour =
						theme.recordStatus.color[variant][
							theme.recordStatus.shade.background
						];
					result.textColour =
						theme.recordStatus.color[variant][
							theme.recordStatus.shade.text
						];
					result.Icon = icons[variant];
					result.statusLabel = statusLabel[variant];
					result.statusText = statusText[variant];
					result.ButtonIcon = buttonIcon[variant];
					result.buttonText = buttonText[variant];

					return result;
				}
			},
			[historyData, moduleAssets, theme]
		);

		return (
			<StatusDisplay
				backgroundColour={rowAttributes.backgroundColour}
				textColour={rowAttributes.textColour}
			>
				<StatusTitle title={`${moduleAssets.moduleTitle} status`} />
				<StatusRow
					icon={
						<rowAttributes.Icon
							sx={{
								marginRight: '10px',
								fontSize: '30px',
								color: rowAttributes.iconColour,
							}}
						/>
					}
					label={rowAttributes.statusLabel}
					text={rowAttributes.statusText}
					onClick={handleStatusChangeClick}
					buttonText={rowAttributes.buttonText}
					buttonDisabled={sending}
				/>
				<BtDialog maxWidth="xs" open={publishDialogOpen}>
					<DialogTitle>Publish Options</DialogTitle>

					{publishAttributes.status === NO_CURRENT_VERSION && (
						<>
							<DialogContentText
								style={{
									wordWrap: 'break-word',
									padding: '0px 24px',
								}}
							>
								{`There is no current version set.
                            In order to proceed, select a version or draft to set as current.`}
							</DialogContentText>
							<BtFilterSelect
								onChange={handleVersionSelect}
								dense
								options={versionHistory}
							/>
						</>
					)}

					{publishAttributes.status === NO_VERSIONS && (
						<DialogContent>
							{`As there are no saved versions, the draft will be committed and set as the current version.\n
                                                        Alternatively, cancel and carry out these steps manually.`}
						</DialogContent>
					)}

					<DialogActions>
						<ActionButtonContainer>
							<Button
								disableElevation
								onClick={() => setPublishDialogOpen(false)}
								variant="outlined"
							>
								Cancel
							</Button>
							<Button
								disabled={
									publishAttributes.status ===
										NO_CURRENT_VERSION &&
									selectedVersion === null
								}
								disableElevation
								startIcon={<PublishIcon />}
								onClick={handleDialogPublish}
								variant="contained"
							>
								Publish
							</Button>
						</ActionButtonContainer>
					</DialogActions>
				</BtDialog>
			</StatusDisplay>
		);
	}
);

export const RecordStatus = () => {
	const {
		historyData,
		moduleAssets,
		sending,
		handleCallback,
	} = useRecordManagementContext();
	return (
		historyData && (
			<RecordStatusComponent
				historyData={historyData}
				moduleAssets={moduleAssets}
				sending={sending}
				handleCallback={handleCallback}
			/>
		)
	);
};

RecordStatusComponent.propTypes = {
	historyData: PropTypes.object.isRequired,
	moduleAssets: PropTypes.object.isRequired,
	handleCallback: PropTypes.func.isRequired,
	sending: PropTypes.bool,
};

RecordStatusComponent.displayName = 'RecordStatusComponent';
RecordStatus.displayName = 'RecordStatus';
