// Structured for use with yup
// The object contains the regex to test and the corresponding error message
const password_regex = {
	regex: /^(?=.*\d)(?=.*\S)(?=.*[!@#$%^.\-&*[\]{}()?"/\\,><';:|_~`+=])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{10,35}$/gm,
	error_text: 'Password must meet all the requirements',
};
const no_spaces_regex = {
	regex: /.*\S.*/,
	error_text: 'Spaces are not allowed',
};
// for user's first and last names
const user_names_regex = {
	regex: /^([A-Za-z]+([ ]?[A-Za-z]?['-]?[A-Za-z]+)*)$/,
	error_text: 'Letters and single Spaces or Hyphens only please',
};
const uk_mobile_regex = {
	regex: /^07([\d]{3})[(\D\s)]?[\d]{3}[(\D\s)]?[\d]{3}$/,
	error_text: 'Please enter a valid UK mobile number beginning 07',
};
const user_name_regex = {
	regex: /^([A-Za-z]+([ ]?[A-Za-z]?['-]?[A-Za-z]+)*)$/,
	error_text: 'A display name should contain only letters, spaces or hyphens',
};
const initials_regex = {
	regex: /^[A-Z\s]+$/,
	error_text: 'Format must be AB',
};

export {
	password_regex,
	no_spaces_regex,
	user_names_regex,
	uk_mobile_regex,
	user_name_regex,
	initials_regex,
};
