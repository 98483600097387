const { OPERATION_MODE } = require('../../expression');
const {
	getExpressionType,
	EXPRESSION,
	ERROR,
} = require('../../expression/expression');
const { isDocument, dotPathToString, deepCopy } = require('../../utils');
const {
	setOperatorSchemaFn,
	evaluateExpressionSchema,
} = require('../expression');
const {
	newDefaultSchema,
	getType,
	getArrayOf,
	setObjectShape,
} = require('../utils');

const operatorKey = '$arrayToObject';

setOperatorSchemaFn(
	operatorKey,
	(context, args, options) => {
		const shape = {};
		const newObj = newDefaultSchema('object', { shape: shape });
		const expr = getExpressionType(args);

		if (expr.type === EXPRESSION.EXPRESSION_OBJECT) {
			args[0].forEach((arg, idx) => {
				let kExpr = null;
				let v = null;
				if (isDocument(arg)) {
					kExpr = getExpressionType(arg['k']);
					v = evaluateExpressionSchema(context, arg['v']);
				} else {
					kExpr = getExpressionType(arg[0]);
					v = evaluateExpressionSchema(context, arg[1]);
				}

				if (kExpr.type === EXPRESSION.LITERAL) {
					shape[kExpr.value + ''] = v;
					return;
				}

				v.dynamic = true;

				if (kExpr.type === EXPRESSION.FIELD_PATH) {
					shape['_' + dotPathToString(kExpr.path.slice(1))] = v;
				} else {
					context.dynamicKey = context.dynamicKey || -1;
					context.dynamicKey++;

					shape['_string' + context.dynamicKey] = v;
				}
			});

			return newObj;
		}

		const arr = evaluateExpressionSchema(context, args);
		if (getType(arr) !== 'array') {
			throw new Error(
				ERROR.INVALID_OPERATOR_ARGUMENT(
					operatorKey,
					'argument to resolve to an array'
				)
			);
		}

		const arrayOf = getArrayOf(arr);
		const aType = getType(arrayOf);

		if (aType !== 'object' && aType !== 'array') {
			throw new Error(
				ERROR.INVALID_OPERATOR_ARGUMENT(
					operatorKey,
					'argument to resolve to an array of objects with k,v keys, or an array of k,v tuples'
				)
			);
		}

		let vSchema = null;
		let kName = '';
		if (aType === 'object') {
			vSchema = arrayOf.objectContent?.v;

			if (expr.type === EXPRESSION.FIELD_PATH) {
				kName = '_' + dotPathToString(expr.path.slice(1));
			}
		} else if (aType === 'array') {
			if (arrayOf?.tupleContent?.length >= 2) {
				vSchema = arrayOf.tupleContent[1] || null;
			} else {
				vSchema = arrayOf.arrayContent || null;
			}
		}

		if (vSchema === null) {
			vSchema = newDefaultSchema('object');
		}

		if (kName === '') {
			context.dynamicKey = context.dynamicKey || -1;
			context.dynamicKey++;
			kName = '_string' + context.dynamicKey++;
		}

		const s = deepCopy(vSchema);
		s.dynamic = true;

		setObjectShape(newObj, {
			[kName]: s,
		});

		return newObj;
	},
	[OPERATION_MODE.AGGREGATE]
);
