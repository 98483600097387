const {
	ERROR,
	setOperator,
	evaluateExpression,
	OPERATION_MODE,
} = require('../expression.js');

const OPERATORS = {
	DIVIDE: '$divide',
	MULTIPLY: '$multiply',
	POW: '$pow',
	ABS: '$abs',
	CEIL: '$ceil',
	FLOOR: '$floor',
	TRUNC: '$trunc',
};

// TODO::

// $exp
// $ln
// $log
// $log10
// $mod
// $round
// $sqrt

// trig functions
// $sin
// $cos
// $tan
// $asin
// $acos
// $atan
// $atan2
// $asinh
// $acosh
// $atanh
// $sinh
// $cosh
// $tanh
// $degreesToRadians
// $radiansToDegrees

/**
 * @typedef {{
 * 	maxArgs?: number;
 *  minArgs?: number;
 * }} NumericalOperatorOptions
 */
/**
 * @type {{[keys:string]:NumericalOperatorOptions}}
 */
const operator_options = {
	[OPERATORS.DIVIDE]: {
		maxArgs: 2,
		minArgs: 2,
	},
	[OPERATORS.POW]: {
		maxArgs: 2,
		minArgs: 2,
	},
	[OPERATORS.ABS]: {
		maxArgs: 1,
	},
	[OPERATORS.FLOOR]: {
		maxArgs: 1,
	},
	[OPERATORS.CEIL]: {
		maxArgs: 1,
	},
	[OPERATORS.TRUNC]: {
		minArgs: 1,
		maxArgs: 2,
	},
};

/**
 * @type {ExpressionFunction<number, number, {operator:string;}>}
 */
function operation(context, args, options) {
	const arr = !Array.isArray(args) ? [args] : args;

	/**
	 * @type {number}
	 */
	let accumulator = 0;
	let isNull = false;

	const operatorOpt = operator_options[options.operator] || {};
	if (
		arr.length < (operatorOpt.minArgs || 1) ||
		arr.length > (operatorOpt.maxArgs || Infinity)
	) {
		throw new Error(
			ERROR.INVALID_NUMBER_ARGS(
				options.operator,
				operatorOpt.minArgs || 1,
				operatorOpt.maxArgs
			)
		);
	}

	for (let pos = 0; pos < arr.length; pos++) {
		const arg = arr[pos];

		/**
		 * @type {any}
		 */
		const value = evaluateExpression(context, arg);

		if (typeof value !== 'number') {
			if (value === undefined || value === null) {
				//isNull = true;
				// continue so the error throws for other non-numeric inputs...
				// continue;

				return null;
			}

			throw new Error(
				ERROR.INVALID_OPERATOR_ARGUMENT(
					options.operator,
					'number',
					value,
					pos
				)
			);
		}

		switch (options.operator) {
			case OPERATORS.ABS:
				return Math.abs(value);
			case OPERATORS.CEIL:
				return Math.ceil(value);
			case OPERATORS.FLOOR:
				return Math.floor(value);
			case OPERATORS.TRUNC:
				if (arr.length > 1) {
					if (pos === 0) {
						accumulator = value;
						break;
					}
					if (value < -20 || value > 100) {
						throw 'trunc cannot take a place count less than -20 or greater than 100';
					}
					const dp = Math.pow(10, value);
					return Math.trunc(accumulator * dp) / dp;
				}
				return Math.trunc(value);
			case OPERATORS.DIVIDE:
				if (pos === 0) {
					accumulator = value;
				} else {
					return accumulator / value;
				}
			case OPERATORS.POW:
				if (pos === 0) {
					accumulator = value;
				} else if (accumulator === 0 && value < 0) {
					throw 'pow cannot take a base of 0 and a negative exponent';
				} else {
					return Math.pow(accumulator, value);
				}
			case OPERATORS.MULTIPLY:
				if (pos === 0) {
					accumulator = value;
				} else {
					accumulator *= value;
				}
				break;
		}
	}

	if (isNull) {
		return null;
	}

	return accumulator;
}

Object.keys(OPERATORS).forEach((operator) => {
	const key = OPERATORS[operator];
	setOperator(
		key,
		(context, args, options) =>
			operation(
				context,
				args,
				options ? { ...options, operator: key } : { operator: key }
			),
		[OPERATION_MODE.AGGREGATE]
	);
});
