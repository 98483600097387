import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { v1 as uuidv1 } from 'uuid';
import _ from 'lodash';

import { useTheme } from '@mui/material/styles';

import WorkflowTemplateComponentDialog from './WorkflowTemplateComponentDialog';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { pipelineSchema } from 'mongodb_query/src';

import {
	Typography,
	Button,
	TextField,
	Container,
	IconButton,
	Box,
	Select,
	MenuItem,
	InputLabel,
	Paper,
} from '@mui/material';
import AddIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { BtFormLateralContainer } from '../../../components/generic/forms';
import BtDetailSection from '../../../components/generic/BtDetailSection';
import BtKeyValueList from '../../../components/generic/BtKeyValueList';
import BtConfirmDialog from '../../../components/generic/BtConfirmDialog';
import BtQueryField from '../../../components/generic/query-builder/BtQueryField';
import BtDataConfigNodeSelect from '../../../components/generic/DataConfigNodeSelect/BtDataConfigNodeSelect';
import BtDataSourceSelect from '../../../components/generic/BtDataSourceSelect';
import { parseSchema } from '../../../utils/yup-ast';

function WorkflowComponentDetails({
	component,
	pageList,
	onComponentUpdate,
	outputSchema,
}) {
	const [dataSourceValueOptions, setDataSourceValueOptions] = useState([]);
	const [dataSourceSchema, setDataSourceSchema] = useState(null);
	const [selectedDataSource, setSelectedDataSource] = useState({
		collection: [],
		configSchema: { type: 'object', objectContent: {} },
	});

	const onSectionChildrenUpdate = update => {
		console.log('onSelectComponentUpdate', component, update);
		const updatedComponent = { ...component, children: update };
		console.log(updatedComponent);
		onComponentUpdate(updatedComponent);
	};

	const onSelectListUpdate = update => {
		console.log('onSelectListUpdate', component, update);
		const updatedComponent = {
			...component,
			options: update.map(option => {
				return { value: option.key, label: option.value };
			}),
		};
		console.log(updatedComponent);
		onComponentUpdate(updatedComponent);
	};

	const onSelectDataSourceQueryUpdate = queryUpdate => {
		console.log('onSelectDataSourceQueryUpdate', queryUpdate);

		const updatedComponent = {
			...component,
			dataSource: {
				...component.dataSource,
				query: queryUpdate.queryStr,
				type: 'dataset',
				filter: '',
				sort: '',
				variables: queryUpdate.variables,
			},
		};
		onComponentUpdate(updatedComponent);

		// console.log('queryUpdate?.configSchema', queryUpdate?.configSchema);
		setDataSourceSchema(queryUpdate?.configSchema);

		const newOptions = Object.keys(
			queryUpdate?.configSchema?.objectContent || {}
		);
		// console.log(newOptions);
		setDataSourceValueOptions(newOptions);
	};

	const onDataSourceValueChange = value => {
		const updatedComponent = {
			...component,
			dataSource: {
				...component.dataSource,
				value: value,
				label: value,

				type: 'dataset',
				filter: '',
				sort: '',
				variables: null,
			},
		};
		onComponentUpdate(updatedComponent);
	};

	const onDataSourceChange = useCallback(
		async dataSource => {
			console.log('onDataSourceChange', dataSource);

			const newDataSource = {
				collection: [],
				configSchema: parseSchema(dataSource.schema),
			};
			console.log(newDataSource);
			setSelectedDataSource(newDataSource);

			console.log(
				'component?.dataSource?.query',
				component?.dataSource?.query
			);
			pipelineSchema(
				newDataSource.configSchema,
				JSON.parse(component?.dataSource?.query || '[]') || []
			)
				.then(schema => {
					setDataSourceSchema(schema);
					console.log(schema);
				})
				.catch(err => {
					console.log(err);
				});

			if (component.dataSource?.uuid !== dataSource.uuid) {
				const updatedComponent = {
					...component,
					dataSource: {
						value: '',
						label: '',

						type: 'dataset',
						filter: '',
						sort: '',
						variables: null,
						...component.dataSource,
						uuid: dataSource.uuid,
					},
				};
				console.log(updatedComponent);
				onComponentUpdate(updatedComponent);
			}
		},
		[component, onComponentUpdate]
	);

	const _sectionOpenConditionProp = useMemo(
		() => {
			if (component?.openConditions?.depAnswers?.length > 0) {
				return component?.openConditions?.depAnswers[0];
			}
			return null;
		},
		[component]
	);

	const _sectionOpenConditionValue = useMemo(
		() => {
			if (component?.openConditions?.depAnswers?.length > 0) {
				return component?.openConditions?.operation[
					component.openConditions.depAnswers[0]
				];
			}
			return null;
		},
		[component]
	);

	const onSectionOpenConditionPropChange = propName => {
		console.log('onSectionOpenConditionPropChange', propName);

		const updatedComponent = {
			...component,
			openConditions: {
				operation: {
					[propName]: '',
				},
				depAnswers: [propName],
			},
		};

		onComponentUpdate(updatedComponent);
	};

	const onSectionOpenConditionValueChange = valueName => {
		console.log('onSectionOpenConditionValueChange', valueName);

		const updatedComponent = {
			...component,
			openConditions: {
				operation: {
					[_sectionOpenConditionProp]: valueName,
				},
				depAnswers: [_sectionOpenConditionProp],
			},
		};

		onComponentUpdate(updatedComponent);
	};

	switch (component.type) {
		case 'Section':
			return (
				<>
					<Container maxWidth="sm" sx={{ paddingBottom: '8px' }}>
						<Typography variant="h5">
							Section Open Conditions
						</Typography>

						<BtDataConfigNodeSelect
							onChange={onSectionOpenConditionPropChange}
							// value={component?.openConditions?.operation || ''}
							value={_sectionOpenConditionProp}
							schema={outputSchema}
						/>

						<TextField
							onChange={event => {
								onSectionOpenConditionValueChange(
									event.target.value
								);
							}}
							value={_sectionOpenConditionValue || ''}
						/>
					</Container>

					<Box sx={{ paddingBottom: '8px' }}>
						<Typography variant="h5">Section Components</Typography>
						<WorkflowComponentList
							components={component.children || []}
							onComponentListUpdate={onSectionChildrenUpdate}
							pageList={pageList}
							id={component.uuid}
							outputSchema={outputSchema}
						/>
					</Box>
				</>
			);

		case 'Select':
			if (component.optionSource === 'dataset') {
				return (
					<Container maxWidth="sm" sx={{ paddingBottom: '8px' }}>
						<Typography>Select Options Dataset</Typography>

						<BtDataSourceSelect
							retriveDataSourceDetails={true}
							onChange={onDataSourceChange}
							dataSourceUuid={component?.dataSource?.uuid}
						/>

						<BtQueryField
							// {...options}
							// disabled={options.disabled}
							value={component?.dataSource?.query || []}
							type="query"
							onChange={(query, results) =>
								onSelectDataSourceQueryUpdate(results)
							}
							allowStageRawInput
							// resourceGroup="datasets"
							// // resource={component?.dataSource?.uuid}
							// resource={selectedDataSource?.uuid || ''}

							// allowResourceGroupChange={false}
							// allowResourceChange={false}
							disableCollection={true}
							onDataQuery={() => {
								console.log('onDataQuery');
							}}
							initialInputs={selectedDataSource}
							variables={{ ...component?.dataSource?.variables }}
							variableConfigSchema={outputSchema}
						/>

						<BtDataConfigNodeSelect
							onChange={onDataSourceValueChange}
							value={component?.dataSource?.value || ''}
							schema={dataSourceSchema}
						/>
					</Container>
				);
			} else if (component.optionSource === 'list') {
				return (
					<Container maxWidth="sm" sx={{ paddingBottom: '8px' }}>
						<BtKeyValueList
							title="Select Options"
							listItems={(component.options || []).map(
								option => ({
									key: option.value,
									value: option.label,
								})
							)}
							onListItemsUpdate={onSelectListUpdate}
							keyId="key"
							valueId="value"
						/>
					</Container>
				);
			} else {
				return (
					<Box sx={{ paddingBottom: '8px' }}>
						<Typography>Select Options</Typography>
						<pre>{JSON.stringify(component, null, 2)}</pre>
					</Box>
				);
			}
		case 'Checkbox':
		case 'Image':
		case 'Barcode':
		case 'Page':
		case 'TextField':
		case 'NumberField':
		case 'Date':
		case 'DateTime':
		case 'Time':
		default:
			return null;
	}

	return null;
}

function WorkflowComponent({
	component,
	handleComponentClick,
	handleDeleteComponent,
	handleComponentUpdate,
	pageList,
	outputSchema,
}) {
	const THEME = useTheme();
	return (
		<Box
			sx={{
				minHeight: '50px',
				backgroundColor: THEME.palette.background.paper,
				borderRadius: '8px',
				paddingLeft: '8px',
				paddingRight: '8px',
				margin: '8px',
			}}
		>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					width: '100%',
				}}
			>
				<Typography sx={{ flexGrow: 1 }} variant="h5">
					{component.type}
				</Typography>
				<IconButton
					sx={{ grow: 0 }}
					onClick={handleComponentClick(component.uuid)}
				>
					<EditIcon />
				</IconButton>
				<IconButton
					sx={{ grow: 0 }}
					onClick={handleDeleteComponent(component.uuid)}
				>
					<DeleteIcon />
				</IconButton>
			</div>

			<BtFormLateralContainer
				sx={{
					marginBottom: '1em',
				}}
			>
				<BtDetailSection label="Name" data={component.name} />
				<BtDetailSection
					label="Description"
					data={component.description}
				/>
			</BtFormLateralContainer>

			<WorkflowComponentDetails
				component={component}
				pageList={pageList}
				onComponentUpdate={handleComponentUpdate}
				outputSchema={outputSchema}
			/>
		</Box>
	);
}

function WorkflowComponentList({
	id,
	components,
	onComponentListUpdate,
	pageList,
	templateDataSources,
	imageRepos,
	outputSchema,
}) {
	const THEME = useTheme();
	const [openComponentDialog, setOpenComponentDialog] = useState(false);
	const [editComponent, setEditComponent] = useState(null);
	const [editComponentIndex, setEditComponentIndex] = useState(null);

	const handleDeleteComponent = componentUuid => event => {
		// if (page.children[componentIndex].uuid === primaryField) {
		// 	console.log('Removing primary field');
		// 	setPrimaryField(null);
		// }
		const componentIndex = _.findIndex(components, {
			uuid: componentUuid,
		});

		let newComponentList = [...components];
		newComponentList.splice(componentIndex, 1);
		onComponentListUpdate(newComponentList);
	};

	const handleComponentUpdate = component => {
		console.log('component update', component);
		const componentIndex = _.findIndex(components, {
			uuid: component.uuid,
		});
		let newComponentList = [...components];
		newComponentList[componentIndex] = component;
		onComponentListUpdate(newComponentList);
	};

	const onDragEnd = dragResult => {
		if (!dragResult.destination) {
			return;
		}
		let newComponentList = [...components];
		const [removed] = newComponentList.splice(dragResult.source.index, 1);
		newComponentList.splice(dragResult.destination.index, 0, removed);
		onComponentListUpdate(newComponentList);
	};

	const handleComponentClick = componentUuid => event => {
		// console.log('handleComponentClick', componentUuid);
		const componentIndex = _.findIndex(components, {
			uuid: componentUuid,
		});
		setEditComponentIndex(componentIndex);
		setEditComponent(components[componentIndex]);
		setOpenComponentDialog(true);
		//event.stopPropagation();
	};

	const handleAddComponent = event => {
		// console.log('handleAddComponent');
		let newComponentList = [...components];
		newComponentList.push({
			uuid: uuidv1(),
			type: 'TextField',
			name: 'New Input',
			description: 'A new input',
			validation: [],
		});
		onComponentListUpdate(newComponentList);
		event.stopPropagation();
	};

	return (
		<Box
			sx={{
				backgroundColor: THEME.palette.background.insights,
				paddingTop: '2px',
				borderRadius: '16px',
			}}
			onClick={event => {
				event.stopPropagation();
			}}
		>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId={id}>
					{provided => (
						<div
							{...provided.droppableProps}
							ref={provided.innerRef}
						>
							{components.map((child, index) => (
								<Draggable
									draggableId={child.uuid}
									index={index}
									key={child.uuid}
								>
									{provided => (
										<div
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
										>
											<WorkflowComponent
												id={child.uuid}
												component={child}
												handleComponentClick={
													handleComponentClick
												}
												handleDeleteComponent={
													handleDeleteComponent
												}
												pageList={pageList}
												handleComponentUpdate={
													handleComponentUpdate
												}
												outputSchema={outputSchema}
											/>
										</div>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>

			<Box display="flex" justifyContent="center" alignItems="center">
				<Button
					sx={{ margin: '1rem' }}
					variant="outlined"
					startIcon={<AddIcon />}
					onClick={handleAddComponent}
				>
					Add Component
				</Button>
			</Box>

			<WorkflowTemplateComponentDialog
				open={openComponentDialog}
				onClose={() => {
					setOpenComponentDialog(false);
				}}
				component={editComponent}
				componentUpdate={handleComponentUpdate}
				componentIndex={editComponentIndex}
				pageList={pageList}
				templateDataSources={templateDataSources}
				imageRepos={imageRepos}
			/>
		</Box>
	);
}

export default function WorkflowTemplatePage({
	page,
	handleDeletePage,
	handleUpdatePage,
	pageList,
	templateDataSources,
	imageRepos,
	outputSchema,
}) {
	const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
	const [primaryField, setPrimaryField] = useState('not set');
	const [primaryFieldOptions, setPrimaryFieldOptions] = useState([
		{
			value: 'not set',
			label: 'No Primary Value',
		},
	]);
	const theme = useTheme();

	useEffect(
		() => {
			if (page) {
				//console.log(page);

				const primaryFieldComp = page.children.find(
					({ primary }) => primary
				);

				var newPrimaryFieldOptions = [
					{
						value: 'not set',
						label: 'No Primary Value',
					},
				];

				page.children.forEach(component => {
					//console.log(component);
					if (
						!component.repeatable &&
						(component.type === 'TextField' ||
							component.type === 'Select' ||
							component.type === 'Label')
					) {
						newPrimaryFieldOptions.push({
							value: component.uuid,
							label: component.name,
						});
					}
				});

				//console.log('newPrimaryFieldOptions', newPrimaryFieldOptions);
				setPrimaryFieldOptions(newPrimaryFieldOptions);

				if (primaryFieldComp) {
					setPrimaryField(primaryFieldComp.uuid);
				} else {
					setPrimaryField('not set');
				}
			}
		},
		[page]
	);

	const handleNameChange = newValue => {
		handleUpdatePage({ ...page, name: newValue });
	};

	const handleDescriptionChange = newValue => {
		handleUpdatePage({ ...page, description: newValue });
	};

	const handleComponentUpdate = component => {
		console.log(component);
		const componentIndex = _.findIndex(page.children, {
			uuid: component.uuid,
		});
		let tempPage = { ...page };
		tempPage.children[componentIndex] = component;
		handleUpdatePage(tempPage);
	};

	const handlePrimaryFieldChange = componentId => {
		let tempPage = { ...page };

		tempPage.children.forEach(component => {
			if (component.uuid === componentId) {
				component.primary = true;
			} else {
				delete component.primary;
			}
		});

		handleUpdatePage(tempPage);
	};

	const onComponentListUpdate = componentListUpdate => {
		const tempPage = { ...page, children: componentListUpdate };
		handleUpdatePage(tempPage);
	};

	return (
		<>
			{page && (
				<Paper
					style={{
						padding: 16,
						backgroundColor: theme.palette.background.insights,
					}}
				>
					<Container maxWidth="sm">
						<Paper style={{ padding: 16 }}>
							<TextField
								variant="standard"
								size="small"
								color="primary"
								fullWidth
								label={'Page Name'}
								value={page.name}
								onChange={event => {
									handleNameChange(event.target.value);
								}}
							/>

							<TextField
								variant="standard"
								size="small"
								color="primary"
								fullWidth
								label={'Page Description'}
								value={page.description}
								onChange={event => {
									handleDescriptionChange(event.target.value);
								}}
							/>

							<InputLabel id="primaryId">
								Primary Field
							</InputLabel>
							<Select
								name="rootPage"
								label="Input Type"
								variant="standard"
								sx={{ width: '100%' }}
								value={primaryField}
								onChange={event =>
									handlePrimaryFieldChange(event.target.value)
								}
								labelId="primaryId"
							>
								{primaryFieldOptions.map(({ value, label }) => (
									<MenuItem value={value} key={value}>
										{label}
									</MenuItem>
								))}
							</Select>

							<Button
								sx={{ margin: '1rem' }}
								variant="outlined"
								startIcon={<DeleteIcon />}
								onClick={() => {
									setConfirmDeleteOpen(true);
								}}
							>
								Delete Page
							</Button>
						</Paper>
					</Container>

					<Container maxWidth="md" sx={{ paddingTop: '30px' }}>
						<Typography
							sx={{
								fontWeight: 'bold',
								padding: '4px 0',
							}}
							variant="h5"
						>
							Components
						</Typography>
						<WorkflowComponentList
							components={page.children}
							onComponentListUpdate={onComponentListUpdate}
							pageList={pageList}
							id={page.uuid}
							templateDataSources={templateDataSources}
							imageRepos={imageRepos}
							outputSchema={outputSchema}
						/>
					</Container>

					<BtConfirmDialog
						title="Delete page"
						action={() => {
							handleDeletePage(page);
						}}
						ActionIcon={<DeleteIcon />}
						onClose={() => {
							setConfirmDeleteOpen(false);
						}}
						open={confirmDeleteOpen}
						prompt={`Are you sure you wish to delete page '${
							page.name
						}'?`}
						verb="Delete"
					/>
				</Paper>
			)}
		</>
	);
}
