import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import {
	ArrowRight,
	KeyboardArrowLeft,
	KeyboardArrowRight,
} from '@mui/icons-material';
import { Button, ButtonGroup, Tooltip } from '@mui/material';
import { sub } from 'date-fns';

import BtPopper from '../../../BtPopper';
import {
	buildTimeRangeObject,
	formatTimeDateString,
} from './utils/timeRangeFunctions';
import TimeRangePicker from './TimeRangePicker';
import { useAppContext } from '../../../../../context/ContextManager';
import { useTheme } from '@mui/styles';
import useBreakpointValue from '../../../../../hooks/useBreakpointValue';

const DATE = new Date();

export default function TimeControls({ defaultTimeRange, onChange, style }) {
	const { showMobile, appOrg } = useAppContext();
	const breakpoint = useBreakpointValue();

	const [previewRange, setPreviewRange] = useState({
		interval: {
			label: '1 day',
			duration: 1,
			unit: 'days',
			tense: 'last',
			whole: false,
		},
		start: sub(DATE, {
			days: 1,
		}).getTime(),
		end: DATE.getTime(),
		realTime: false,
	});

	const [timeRange, setTimeRange] = useState({
		interval: {
			label: '1 day',
			duration: 1,
			unit: 'days',
			tense: 'last',
			whole: false,
		},
		start: sub(DATE, {
			days: 1,
		}).getTime(),
		end: DATE.getTime(),
		realTime: false,
	});

	// used to disable popper click away, for example when using mui select component
	const [popperClickAway, setPopperClickAway] = useState(true);
	const [showRangeLabel, setShowRangeLabel] = useState(false);
	const [timeControlOpen, setTimeControlOpen] = useState(false);

	const dateTimeString = formatTimeDateString({
		dateFormat: appOrg.regionalSettings.dateFormat,
		start: timeRange?.start,
		end: timeRange?.end,
		whole: timeRange?.interval?.whole,
	});

	const updateTimeRange = newTimeRange => {
		setTimeRange(newTimeRange);

		onChange?.(newTimeRange);
	};

	const handleTimeShiftClick = async function(direction) {
		const newTimeRange = await buildTimeRangeObject({
			tense: timeRange.interval.tense,
			duration: timeRange.interval.duration,
			unit: timeRange.interval.unit,
			whole: timeRange.interval.whole,
			shift: true,
			shiftData: {
				direction: direction,
				start: timeRange.start,
				end: timeRange.end,
			},
			rangeType: 'relative',
			firstWeekDay: appOrg.regionalSettings.firstWeekDay,
			realTime: false,
		});

		if (newTimeRange) {
			updateTimeRange(newTimeRange);
		}
	};

	const endButtonStyle = {
		textTransform: 'none',
		borderRadius: 12,
		padding: '0px',
		margin: '0px',
		width: showMobile ? '50px' : '40px',
	};
	const BUTTON_SIZE = showMobile ? 'small' : 'medium';

	// Time range popper control
	const handleTimeControlClose = () => {
		popperClickAway && setTimeControlOpen(false);
	};

	const handleTimeControlOpen = () => {
		setTimeControlOpen(true);
		setPreviewRange({ ...timeRange });
	};

	useEffect(
		() => {
			if (defaultTimeRange) {
				setTimeRange(defaultTimeRange);
			}
		},
		[defaultTimeRange]
	);

	const popperStyle = useMemo(
		() => {
			if (showMobile) {
				switch (breakpoint) {
					case 'xs':
						return {
							width: '100vw',
						};
					default:
						return {
							maxWidth: '418px',
						};
				}
			} else {
				return {
					maxWidth: '418px',
				};
			}
		},
		[breakpoint, showMobile]
	);

	return (
		<>
			<BtPopper
				content={
					<TimeRangePicker
						setPopperClickAway={setPopperClickAway}
						popperClickAway={popperClickAway}
						onClose={handleTimeControlClose}
						timeControlOpen={timeControlOpen}
						timeRange={timeRange}
						setTimeRange={updateTimeRange}
						previewRange={previewRange}
						setPreviewRange={setPreviewRange}
					/>
				}
				onClose={handleTimeControlClose}
				open={timeControlOpen}
				placement="bottom-start"
				// style={{ maxWidth: '418px' }}
				style={{ ...popperStyle }}
				variant="extended"
			>
				<ButtonGroup
					aria-label="time filter controls"
					color="primary"
					disableElevation
					onMouseOver={() => !showMobile && setShowRangeLabel(true)}
					onMouseOut={() => !showMobile && setShowRangeLabel(false)}
					style={{
						height: showMobile ? 30 : 24,
						width: showMobile ? 'calc(100% - 48px)' : '420px',
						paddingLeft: showMobile ? 7 : 0,
						paddingTop: showMobile ? 5 : 0,
						marginRight: showMobile ? 5 : 0,
						...style,
					}}
					size={BUTTON_SIZE}
					variant="outlined"
					disableRipple={showMobile ? true : false}
				>
					<Tooltip arrow placement="top" title="Previous">
						<Button
							aria-label="previous time interval"
							onClick={() => handleTimeShiftClick('previous')}
							sx={endButtonStyle}
						>
							<KeyboardArrowLeft fontSize={BUTTON_SIZE} />
						</Button>
					</Tooltip>

					<Tooltip arrow placement="top" title="Open time control">
						<Button
							aria-label="time interval settings"
							onClick={() => handleTimeControlOpen()}
							style={{
								minWidth: showMobile
									? 'calc(100vw - 161px)'
									: '340px',
							}}
							color="primary"
						>
							{!showRangeLabel || !timeRange.interval.label ? (
								timeRange.interval.label
							) : (
								<>
									{dateTimeString.start}
									<ArrowRight
										style={{ pointerEvents: 'none' }}
									/>
									{dateTimeString.end}
								</>
							)}
						</Button>
					</Tooltip>

					<Tooltip arrow placement="top" title="Next">
						<Button
							aria-label="next time interval"
							onClick={() => handleTimeShiftClick('next')}
							sx={endButtonStyle}
						>
							<KeyboardArrowRight fontSize={BUTTON_SIZE} />
						</Button>
					</Tooltip>
				</ButtonGroup>
			</BtPopper>
		</>
	);
}

TimeControls.propTypes = {
	defaultTimeRange: PropTypes.object,
	onChange: PropTypes.func.isRequired,
	style: PropTypes.object,
};
