import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
	useDataProviderSubscribe,
	useDataProviderContext,
} from '../../../../context/DataProvider/DataProvider';
import _ from 'lodash';
import InsightLoading from '../VisComponents/StatusIndicators/InsightLoading';
import InsightNoData from '../VisComponents/StatusIndicators/InsightNoData';

import { VIS_LIST_PROPERTIES } from '../../InsightPage/visualisationConstants';
import { VisualisationError } from './VisualisationError';
import PropTypes from 'prop-types';

export const InsightComponent = ({
	component,
	palette,
	componentData,
	setComponentData,
	visOptions,
	setVisOptions,
	setShowDataRx,
	setDataSample,
}) => {
	const { getDataSchema } = useDataProviderContext();
	const [dataSourceSchema, setDataSourceSchema] = useState(null);

	const { data, error, loading } = useDataProviderSubscribe(
		component?.uuid,
		component?.datasource
	);

	// useEffect(() => console.log(component), [component]);

	// useEffect(() => console.log({ visOptions }), [visOptions]);

	useEffect(
		() => {
			if (data) {
				// console.log('New Data', data);
				// console.log(component);

				// Check if there is a data modifier required
				if (component.dataModifier) {
					console.log(`${component.type} has dataModifier`);
					try {
						const modifier = JSON.parse(component.dataModifier);
						// console.log(modifier);

						const modifierKeys = Object.keys(modifier);
						// TODO: we currently only handle one modifier prop
						switch (modifierKeys[0]) {
							case '$group':
								const groupingField = modifier['$group'].by;
								if (!groupingField) {
									throw new Error('Missing grouping "by"');
								}

								// const pipline = [
								// 	{
								// 		$match: {
								// 			$or: [
								// 				{
								// 					data_set:
								// 						'337e94e6-86eb-490b-aa97-d345fc869e05',
								// 				},
								// 				{
								// 					data_set:
								// 						'74421123-e741-4cfe-930a-d40a94c15939',
								// 				},
								// 			],
								// 		},
								// 	},
								// 	{
								// 		$replaceRoot: {
								// 			newRoot: '$values',
								// 		},
								// 	},
								// 	{
								// 		$group: {
								// 			_id: {
								// 				Product_Group: '$Product_Group',
								// 				Retailer: '$Retailer',
								// 			},
								// 			avgPrice: {
								// 				$avg: '$Price',
								// 			},
								// 		},
								// 	},
								// 	{
								// 		$project: {
								// 			Product_Group: '$_id.Product_Group',
								// 			'a.k': '$_id.Retailer',
								// 			'a.v': '$avgPrice',
								// 		},
								// 	},
								// 	{
								// 		$group: {
								// 			_id: '$Product_Group',
								// 			Retailers: {
								// 				$push: '$a',
								// 			},
								// 		},
								// 	},
								// 	{
								// 		$project: {
								// 			ProductGroup: '$_id',
								// 			Retailers: {
								// 				$arrayToObject: '$Retailers',
								// 			},
								// 		},
								// 	},
								// 	{
								// 		$replaceRoot: {
								// 			newRoot: {
								// 				$mergeObjects: [
								// 					'$Retailers',
								// 					'$$ROOT',
								// 				],
								// 			},
								// 		},
								// 	},
								// 	{
								// 		$project: {
								// 			_id: 0,
								// 			Retailers: 0,
								// 		},
								// 	},
								// ];

								// test = {
								// 	$multiGroup: {
								// 		primary: '$Product_Group',
								// 		secondary: '$Retailer',
								// 	},
								// };

								// Remove the 'by' field
								delete modifier['$group'].by;

								// Get the output fields
								// const groupingKeys = Object.keys(
								// 	modifier['$group']
								// );

								// TODO: we only handle using data fields with leading '$'
								const groupBy = groupingField.substring(1);

								// const test = _.groupBy(data, dataPoint => {
								// 	return dataPoint[groupBy];
								// });
								// const test = _.groupBy(data, dataPoint => {
								// 	return dataPoint.Retailer;
								// });

								//

								//

								//

								// console.log('groupBy', groupBy);
								const groupingValues = _.uniq(
									data.map(dataPoint => {
										return dataPoint[groupBy];
									})
								);

								// console.log('Group data by', groupingValues);

								var dataOutput = [];

								groupingValues.forEach(groupingValue => {
									const reducedData = _.filter(
										data,
										dataPoint => {
											return (
												dataPoint[groupBy] ===
												groupingValue
											);
										}
									);

									// console.log(
									// 	'reducedData',
									// 	groupingValue,
									// 	reducedData
									// );

									// const groupingValues = _.uniq(
									// 	data.map(reducedData => {
									// 		return reducedData['Retailer'];
									// 	})
									// );

									const groupingValues = _.groupBy(
										reducedData,
										dataPoint => {
											return dataPoint['Retailer'];
										}
									);

									//const aveVals = _.meanBy(groupingValues)

									// console.log(groupingValues);

									var groupedData = {};
									Object.keys(groupingValues).forEach(
										groupingValue => {
											groupedData[
												groupingValue
											] = _.meanBy(
												groupingValues[groupingValue],
												'Price'
											);
										}
									);

									dataOutput.push({
										ProductGroup: groupingValue,
										...groupedData,
									});

									// groupingKeys.forEach(groupingKey => {
									// 	console.log('groupingKey', groupingKey);
									// 	const groupingOp = Object.keys(
									// 		modifier['$group'][groupingKey]
									// 	)[0];
									// 	switch (groupingOp) {
									// 		case '$avg':
									// 			console.log('Do average');
									// 			break;
									// 		case '$min':
									// 			console.log('Do min');
									// 			break;
									// 		case '$max':
									// 			console.log('Do max');
									// 			break;
									// 		case '$first':
									// 			console.log('Do first');
									// 			break;
									// 		default:
									// 			console.log(
									// 				`Unspported grouping OP ${groupingOp}`
									// 			);
									// 			break;
									// 	}
									// });
								});

								// console.log('dataOutput', dataOutput);
								setComponentData(dataOutput);

								break;
							default:
								throw new Error('Unsupported modifier');
						}
					} catch (error) {
						// console.log(error);
						setComponentData(data);
					}
				} else {
					// console.log('data', data);

					// console.log(component.data_source.data_origin);

					if (component.datasource) {
						// console.log(`${component.type} has datasource`);

						var filteredData = [];
						if (component.datasource.dataOrigin) {
							filteredData = data.map(dataPoint => {
								// console.log(
								// 	component?.vis_options?.ordinalAxis
								// );
								return {
									start_time: dataPoint.start_time,
									[component?.vis_options?.ordinalAxis?.param
										.value]: dataPoint[
										(component?.vis_options?.ordinalAxis
											?.param.value)
									],
									...dataPoint[
										component.datasource.dataOrigin
									],
								};
							});
						} else {
							filteredData = [...data];
						}

						// console.log('filteredData', filteredData);

						// If set list of serise
						if (component.vis_options?.params) {
							setVisOptions({ ...component.vis_options });
						} else {
							// Auto detect serise
							var params = [];
							// console.log(
							// 	component.vis_options.ordinalAxis.param.value
							// );
							// console.log(filteredData);

							filteredData.forEach(dataPoint => {
								Object.keys(dataPoint).forEach(param => {
									if (
										param !== 'Submission_Date' &&
										param !== 'start_time' &&
										param !== 'timestamp' &&
										param !==
											component.vis_options.ordinalAxis
												?.param.value
									) {
										if (!params.includes(param)) {
											params.push(param);
										}
									}
								});
							});

							// console.log('params', params);

							params = params.map(param => {
								return {
									label: param,
									value: param,
									axis: 'primary',
									// prefix: '£',
								};
							});

							setVisOptions({
								...component.vis_options,
								params: params,
							});
						}
						// console.log('filteredData', filteredData);
						setComponentData(filteredData);
					} else {
						console.log(`${component.type} has NO datasource`);
					}
				}
			}
		},
		[component, data]
	);

	useEffect(
		() => {
			// console.log(component);
			if (component?.datasource) {
				setDataSourceSchema(getDataSchema(component?.datasource));
			}
		},
		[component]
	);

	useEffect(
		() => {
			if (data) {
				//console.log(data);
				setShowDataRx(true);
				const timeout = setTimeout(() => {
					setShowDataRx(false);
				}, 250);
				return () => {
					clearTimeout(timeout);
				};
			}
		},
		[data]
	);

	const usesData = useMemo(
		() => ['StatusCard', 'InfoCard', 'Svg'].includes(component.type),
		[component]
	);

	const Component = useMemo(
		() => VIS_LIST_PROPERTIES[component.type].component,
		[component]
	);

	const componentAttr = useMemo(
		() => {
			// *** improve this, the data and  component?.vis_options should be depreciated ***
			const _data = usesData ? data : componentData;
			const _visOptions = usesData ? component?.vis_options : visOptions;
			// console.log(
			// 	`${component.title} (${component.type}) data: ${JSON.stringify(
			// 		_data,
			// 		null,
			// 		4
			// 	)}`
			// );
			return { _data, _visOptions };
		},
		[component, componentData, data, usesData, visOptions]
	);

	useEffect(
		() => {
			if (setDataSample) {
				// console.log('should update data sample');
				setDataSample(componentAttr._data);
			}
		},
		[componentAttr, setDataSample, visOptions]
	);

	// useEffect(() => console.log({ componentAttr }), [componentAttr]);

	const type = useMemo(() => component.type, [component]);

	// const VisualisationComponent = ({ type }) => {
	// catch missing visualisation definition
	if (!Object.hasOwn(VIS_LIST_PROPERTIES, type)) {
		console.log('missing type');
		return <VisualisationError type={type} />;
	}

	// catch missing component definition
	if (!Object.hasOwn(VIS_LIST_PROPERTIES[type], 'component')) {
		const description =
			process.env.NODE_ENV === 'development'
				? `No component defined for ${type}`
				: null;
		return <VisualisationError description={description} />;
	}

	if (type !== 'InfoCard') {
		if (!componentData) {
			// console.log('should return loader');
			return <InsightLoading />;
		} else if (componentData.length === 0) {
			// console.log('should return nodata');
			return <InsightNoData />;
		}
	}

	return (
		<Component
			data={componentAttr._data}
			// visOptions={componentAttr._visOptions}
			visOptions={usesData ? component?.vis_options : visOptions}
			dataSchema={dataSourceSchema}
			palette={palette}
		/>
	);

	// switch (type) {
	// 	case LINE_CHART:
	// 		return (
	// 			<LineChartComponent
	// 				data={componentAttr._data}
	// 				visOptions={componentAttr._visOptions}
	// 				dataSchema={dataSourceSchema}
	// 				palette={palette}
	// 			/>
	// 		);
	// 	case PIE_CHART:
	// 		return (
	// 			<PieChartComponent
	// 				data={componentAttr._data}
	// 				visOptions={componentAttr._visOptions}
	// 				dataSchema={dataSourceSchema}
	// 				palette={palette}
	// 			/>
	// 		);
	// 	default:
	// 		return null;
	// }

	// };

	// return <VisualisationComponent type={component.type} />;

	// const visualisationComponent = useCallback(
	// 	type => {
	// 		// catch missing visualisation definition
	// 		if (!Object.hasOwn(VIS_LIST_PROPERTIES, type)) {
	// 			console.log('missing type');
	// 			return <VisualisationError type={type} />;
	// 		}

	// 		// catch missing component definition
	// 		if (!Object.hasOwn(VIS_LIST_PROPERTIES[type], 'component')) {
	// 			const description =
	// 				process.env.NODE_ENV === 'development'
	// 					? `No component defined for ${type}`
	// 					: null;
	// 			return <VisualisationError description={description} />;
	// 		}

	// 		if (type !== 'InfoCard') {
	// 			if (!componentData) {
	// 				// console.log('should return loader');
	// 				return <InsightLoading />;
	// 			} else if (componentData.length === 0) {
	// 				// console.log('should return nodata');
	// 				return <InsightNoData />;
	// 			}
	// 		}

	// 		return (
	// 			<Component
	// 				data={componentAttr._data}
	// 				visOptions={componentAttr._visOptions}
	// 				dataSchema={dataSourceSchema}
	// 				palette={palette}
	// 			/>
	// 		);
	// 	},
	// 	[Component, componentAttr, componentData, dataSourceSchema, palette]
	// );

	// return visualisationComponent(component.type);
};

InsightComponent.propTypes = {
	component: PropTypes.object.isRequired,
	componentData: PropTypes.array,
	palette: PropTypes.array,
	setComponentData: PropTypes.func.isRequired,
	setVisOptions: PropTypes.func.isRequired,
	setShowDataRx: PropTypes.func.isRequired,
	visOptions: PropTypes.object,
	setDataSample: PropTypes.func,
};
