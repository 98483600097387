import { Button } from '@mui/material';
import _ from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import {
	dataSourceGetList,
	workflowSubmissionCreate,
	workflowTemplateGetList,
} from '../../../../API';
import useFetch from '../../../../hooks/useFetch';
import BtConfirmDialog from '../../BtConfirmDialog';
import AddIcon from '@mui/icons-material/AddCircle';
import { v4 as uuidV4 } from 'uuid';
import { useAppContext } from '../../../../context/ContextManager';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { dataManagerConstants } from '../tileConstants';
import { ScreensContext } from '../ScreensContext';
import { TileContext } from '../components/TileContext';

export default function ButtonTile() {
	const [newSubmissionConfirmOpen, setNewSubmissionConfirmOpen] = useState(
		false
	);
	const { userInfo } = useAppContext();
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const [dataManagerTarget, setDataManagerTarget] = useState(null);
	const { editEnabled } = useContext(ScreensContext);
	const { tileData, setError } = useContext(TileContext);
	const [isInitializing, setIsInitializing] = useState(true);

	//workflow template API request
	const {
		// loading: templateListLoading,
		data: templateList,
		error: templateListError,
		request: getTemplateList,
	} = useFetch(workflowTemplateGetList);

	// data sources API request
	const {
		// loading: dataSourceListLoading,
		data: dataSourceList,
		error: dataSourceListError,
		request: getDataSourceList,
	} = useFetch(dataSourceGetList);

	// trigger any requests required
	useEffect(
		() => {
			switch (tileData.data.button_type) {
				case 'start_workflow':
					getTemplateList();
					break;
				case 'data_manager':
					getDataSourceList();
					break;
				default:
					break;
			}
		},
		[getDataSourceList, getTemplateList, tileData.data.button_type]
	);

	// set tile error state
	useEffect(
		() => {
			if (templateListError) setError({ serverError: templateListError });
		},
		[setError, templateListError]
	);
	useEffect(
		() => {
			if (dataSourceListError)
				setError({ serverError: dataSourceListError });
		},
		[setError, dataSourceListError]
	);

	// once the backend data is loaded,
	// verify that the onClick target is valid, then set the error and init values accordingly
	useEffect(
		() => {
			switch (tileData.data.button_type) {
				case 'start_workflow':
					if (templateList) {
						const checkTemplateTarget = _.findIndex(templateList, {
							uuid: tileData.data.target.value,
						});
						if (checkTemplateTarget === -1) {
							setError({ buttonTileConfigRequired: true });
						} else {
							setIsInitializing(false);
						}
					}
					break;
				case 'data_manager':
					if (dataSourceList) {
						const checkDataTarget = _.find(dataSourceList, {
							uuid: tileData.data.target.value,
						});
						console.log({ checkDataTarget });
						setDataManagerTarget(checkDataTarget);
						if (checkDataTarget === undefined) {
							setError({ buttonTileConfigRequired: true });
						} else {
							setIsInitializing(false);
						}
					}
					break;
				case 'shortcut':
					setIsInitializing(false);
					break;
				default:
					setError({ buttonTileConfigRequired: true });
					setIsInitializing(false);
					break;
			}
		},
		[dataSourceList, setError, templateList, tileData]
	);

	// switchable onClick handler
	const handleClick = useCallback(
		component => {
			switch (component.data.button_type) {
				case 'start_workflow':
					setNewSubmissionConfirmOpen(true);
					break;
				case 'data_manager':
					history.push(
						'/DataSources' +
							dataManagerConstants[dataManagerTarget.type].url +
							dataManagerTarget.uuid
					);
					break;
				case 'shortcut':
					history.push(component.data.target.value);
					break;
				default:
					console.log(component);
					break;
			}
		},
		[dataManagerTarget, history]
	);

	// workflow submission creation
	const handleCreateSubmission = useCallback(
		async templateUuid => {
			try {
				let template = _.find(templateList, { uuid: templateUuid });
				const newSubmissionUuid = uuidV4();
				const newSubmission = {
					uuid: newSubmissionUuid,
					due_date: null,
					template: {
						uuid: template.uuid,
						version: template.current_version,
					},
					create_timestamp: Date.now(),
					data: {},
					log: [
						{
							timestamp: Date.now(),
							uuid: uuidV4(),
							issuer_type: 'User',
							issuer_id: userInfo.uuid,
							status: 'InProgress', // TODO: change this to pick up the correct value
						},
					],
					session_data: { tree: null },
				};
				//console.log(newSubmission);
				await workflowSubmissionCreate({
					newSubmission: newSubmission,
				});

				enqueueSnackbar(`Created new Submission '${template.name}'`, {
					variant: 'success',
				});
				history.push(`/Workflows/Submissions/${newSubmissionUuid}`);
			} catch (error) {
				console.log(error);
				// setSending(false);
				enqueueSnackbar('Failed to create Workflow submission', {
					variant: 'error',
				});
			}
		},
		[enqueueSnackbar, history, templateList, userInfo.uuid]
	);

	// console.log(`render ${tileData.data.button_type}`);

	return (
		!isInitializing && (
			// <BtLoading loading={templateListLoading || sending}>
			<>
				<Button
					// startIcon={
					// 	buttonTypes.find(
					// 		type => type.value === component.data.button_type
					// 	).icon
					// }
					style={{
						color: 'white',
						height: '100%',
						width: '100%',
					}}
					disableElevation
					variant="contained"
					// disabled={editEnabled || !isOnClickValid}
					disabled={editEnabled}
					onClick={() => handleClick(tileData)}
				>
					{tileData.title}
				</Button>
				<BtConfirmDialog
					title="Create New Submission"
					action={() => {
						handleCreateSubmission(tileData.data.target.value);
					}}
					ActionIcon={<AddIcon />}
					onClose={() => {
						setNewSubmissionConfirmOpen(false);
					}}
					open={newSubmissionConfirmOpen}
					prompt={`Are you sure you wish to create a new '${
						tileData.data.target.label
					}' submission?`}
					verb="Create"
				/>
			</>
			// </BtLoading>
		)
	);
}
