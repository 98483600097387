import { useCallback } from 'react';

import ErrorIcon from '@mui/icons-material/Error';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import BtDetailSnackbar from '../../../../../components/generic/BtDetailSnackbar';

const ErrorContainer = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
	gap: 10,
}));

export const useMissingComponentsError = () => {
	const { enqueueSnackbar } = useSnackbar();

	return useCallback(
		missingComponentTypes => {
			const errorMessage = `Form component${
				missingComponentTypes?.length > 1 ? 's' : ''
			} missing`;

			enqueueSnackbar(errorMessage, {
				content: (key, message) => (
					<BtDetailSnackbar
						id={key}
						Icon={<ErrorIcon />}
						message={message}
						variant="error"
					>
						<ErrorContainer>
							{missingComponentTypes.map(
								(missingComponent, index) => (
									<div key={index}>
										<Typography>
											{`• ${missingComponent}`}
										</Typography>
									</div>
								)
							)}
						</ErrorContainer>
					</BtDetailSnackbar>
				),
				persist: true,
			});
		},
		[enqueueSnackbar]
	);
};

export default useMissingComponentsError;
