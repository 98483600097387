const {
	ERROR,
	setOperator,
	evaluateExpression,
	OPERATION_MODE,
} = require('../expression.js');

const operatorKey = '$add';

setOperator(
	operatorKey,
	/**
	 * @type {ExpressionFunction<number | Date, number | Date>}
	 */
	(context, args, options) => {
		const arr = !Array.isArray(args) ? [args] : args;

		/**
		 * @type {number | Date}
		 */
		let total = 0;
		let isNull = false;

		for (let pos = 0; pos < arr.length; pos++) {
			const arg = arr[pos];

			/**
			 * @type {any}
			 */
			let value = evaluateExpression(context, arg);

			if (typeof value !== 'number' && !(value instanceof Date)) {
				if (value === undefined || value === null) {
					isNull = true;
					continue;
				}

				throw new Error(
					ERROR.INVALID_OPERATOR_ARGUMENT(
						operatorKey,
						'[number | date]',
						value,
						pos
					)
				);
			}

			if (total instanceof Date) {
				if (!(value instanceof Date)) {
					const tempDate = new Date(total);
					tempDate.setTime(total.getTime() + value);
					total = tempDate;
				} else {
					throw new Error(
						ERROR.INVALID_OPERATOR_ARGUMENT(
							operatorKey,
							'at most one date argument',
							value,
							pos
						)
					);
				}
			} else if (value instanceof Date) {
				const tempDate = new Date(value);
				tempDate.setTime(value.getTime() + total);
				total = tempDate;
			} else {
				total += value;
			}
		}

		if (isNull) {
			return null;
		}

		return total;
	},
	[OPERATION_MODE.AGGREGATE]
);
