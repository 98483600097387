const yup = require('yup');

const deviceTypeGetListSchema = yup.array().of(
	yup.object({
		uuid: yup
			.string()
			.uuid()
			.required(),
		device_name: yup.string().required(),
		device_code: yup.string().required(),
		description: yup.string().required(),
		create_timestamp: yup.number().required(),
	})
);

const deviceTypeGetSchema = yup.object({
	uuid: yup
		.string()
		.uuid()
		.required(),
	device_name: yup.string().required(),
	device_code: yup.string().required(),
	description: yup.string().required(),
	create_timestamp: yup.number().required(),
});

module.exports = {
	deviceTypeGetListSchema,
	deviceTypeGetSchema,
};
