import { apiClient } from '../api_client';
import {
	configVersionGetSchema,
	configHistoryGetSchema,
	configLatestGetSchema,
} from '../validations/configValidation';

//========================================================================================================
async function configVersionGet({ configId }) {
	try {
		const req_url = 'config/version/' + configId;
		const response = await apiClient({ method: 'get', url: req_url });
		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let config = await configVersionGetSchema.validate(
					response.config_data
				);
				return config;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function configHistoryGet({ configId }) {
	try {
		const req_url = 'config/history/' + configId;
		const response = await apiClient({ method: 'get', url: req_url });
		console.log(response);
		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let config = await configHistoryGetSchema.validate(
					response.config_data
				);
				return config;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function configLatestGet({ configId }) {
	try {
		const req_url = 'config/latest/' + configId;
		const response = await apiClient({ method: 'get', url: req_url });
		console.log(response);
		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let config = await configLatestGetSchema.validate(
					response.config_data
				);
				return config;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

export { configVersionGet, configHistoryGet, configLatestGet };
