import React, { forwardRef, useMemo } from 'react';

import {
	DataArray as ArrayIcon,
	PowerSettingsNew as BooleanIcon,
	CalendarToday as DateIcon,
	DoNotDisturb as NullIcon,
	Numbers as NumberIcon,
	DataObject as ObjectIcon,
	TextFields as StringIcon,
	QuestionMark as UndefinedIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material';
import { type as typePropType } from './propTypes';

import {
	ARRAY,
	BOOLEAN,
	DATE,
	NULL,
	NUMBER,
	OBJECT,
	STRING,
	UNDEFINED,
} from '../../../utils/commonDataTypes';

const IconContainer = styled('div')(({ theme, variant }) => ({
	alignItems: 'center',
	backgroundColor: theme.palette.dataTypes[variant],
	borderRadius: 4,
	color: theme.palette.background.default,
	display: 'flex',
	height: 16,
	justifyContent: 'center',
	width: 16,
}));

export const DataSetTypeIcon = forwardRef(({ type, ...other }, ref) => {
	const Icon = useMemo(
		() => {
			const iconLookup = {
				[ARRAY]: ArrayIcon,
				[BOOLEAN]: BooleanIcon,
				[DATE]: DateIcon,
				[NULL]: NullIcon,
				[NUMBER]: NumberIcon,
				[OBJECT]: ObjectIcon,
				[STRING]: StringIcon,
				[UNDEFINED]: UndefinedIcon,
			};

			return iconLookup[type];
		},
		[type]
	);

	return (
		<IconContainer ref={ref} variant={type} {...other}>
			<Icon style={{ fontSize: 14 }} />
		</IconContainer>
	);
});

DataSetTypeIcon.propTypes = {
	type: typePropType,
};

export default DataSetTypeIcon;
