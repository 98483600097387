import {
	Box,
	Button,
	Chip,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	IconButton,
	Menu,
	MenuItem,
	Switch,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import {
	AccountHardHat,
	Autorenew,
	Check,
	CheckBold,
	Publish,
	ViewDashboardEdit,
} from 'mdi-material-ui';
import { memo, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { StatusRow } from '../generic/StatusDisplay';
import { useRecordManagementContext } from '../../RecordManagementContext';
import { moduleUrl } from '../../utils/lookupTables/moduleAssets';
import {
	COMMIT_DRAFT,
	CURRENT_USER,
	UPDATE_DRAFT,
} from '../../utils/constants';
import PublishIcon from '@mui/icons-material/Publish';
import BtDialog from '../../../BtDialog';
import ActionButtonContainer from '../../../ActionButtonContainer';
import BtFilterSelect from '../../../filterSelect/BtFilterSelect';
import { format } from 'date-fns';
import BtInfoChip from '../../../BtInfoChip';
import { Edit } from '@mui/icons-material';

const DraftChanges = memo(({ has_changes }) => {
	const { chipText, tooltipText, chipVariant } = useMemo(
		() => {
			if (has_changes) {
				return {
					chipText: 'Has Changes',
					tooltipText:
						'The draft has changes compared to the draft base',
					chipVariant: 'info',
				};
			} else {
				return {
					chipText: 'No Changes',
					tooltipText:
						'The draft has no changes compared to the draft base',
					chipVariant: 'success',
				};
			}
		},
		[has_changes]
	);

	return (
		<div>
			<BtInfoChip
				chipStyle={{ width: 'max-content' }}
				text={chipText}
				tooltip={tooltipText}
				variant={chipVariant}
			/>
		</div>
	);
});

const DraftBase = memo(({ draft_base }) => {
	const { chipText, tooltipText, chipVariant } = useMemo(
		() => {
			if (draft_base === null) {
				return {
					chipText: 'Base: New Collection',
					tooltipText:
						'The draft is not based on a version, its base is the new collection default draft',
					chipVariant: 'info',
				};
			} else {
				return {
					// chipText: `Version ${draft_base + 1}`,
					chipText: `Base: v${draft_base + 1}`,
					tooltipText: `The draft is based on Version ${draft_base +
						1}`,
					chipVariant: 'warning',
				};
			}
		},
		[draft_base]
	);

	return (
		<div>
			<BtInfoChip
				chipStyle={{ width: 'max-content' }}
				text={chipText}
				tooltip={tooltipText}
				variant={chipVariant}
				showIcon={true}
				Icon={<AccountHardHat />}
			/>
		</div>
	);
});

const CurrentVersion = memo(({ current_version }) => {
	const { chipText, tooltipText, chipVariant } = useMemo(
		() => {
			if (current_version === null) {
				return {
					// chipText: 'No version set',
					chipText: `Current: not set`,
					tooltipText: 'There is no current version set',
					chipVariant: 'warning',
				};
			} else {
				return {
					// chipText: `Version ${draft_base + 1}`,
					chipText: `Current: v${current_version + 1}`,
					tooltipText: `The current version is Version ${current_version +
						1}`,
					chipVariant: 'info',
				};
			}
		},
		[current_version]
	);

	return (
		<div>
			<BtInfoChip
				chipStyle={{ width: 'max-content' }}
				text={chipText}
				tooltip={tooltipText}
				variant={chipVariant}
				// showIcon={true}
				// Icon={<AccountHardHat />}
			/>
		</div>
	);
});

export const DraftChangesStatus = () => {
	const history = useHistory();
	const {
		recordUuid,
		module,
		draftLockStatus,
		handleCallback,
		sending,
		historyData,
	} = useRecordManagementContext();
	const [confirmCommitOpen, setConfirmCommitOpen] = useState(false);
	const [commitAndMakeCurrent, setCommitAndMakeCurrent] = useState(true);
	const [resetDraftDialogOpen, setResetDraftDialogOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleOpenMenu = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	const [selectedVersion, setSelectedVersion] = useState(null);

	const handleVersionSelect = selected => {
		console.log(selected);
		if (!selected) {
			setSelectedVersion(null);
		} else {
			setSelectedVersion(Number(selected.id));
		}
	};

	const versionHistory = useMemo(
		() => {
			const versionData = historyData?.versions.map((version, index) => {
				const item = {};
				item.id = index.toString();
				item.title = `Version ${index + 1}`;
				item.subtitle = `Committed by ${
					version.publish_by.user_name
				} at ${format(
					new Date(version.publish_timestamp || 0),
					'dd/MM/yy, hh:mm a'
				)}`;
				return item;
			});
			return versionData.reverse();
		},
		[historyData]
	);

	const currentUserCanEdit = useMemo(() => draftLockStatus === CURRENT_USER, [
		draftLockStatus,
	]);

	const handleEditClick = useCallback(
		() => {
			handleCloseMenu();
			const url = moduleUrl(module, 'edit', recordUuid);
			if (url) history.push(url);
		},
		[history, module, recordUuid]
	);

	const handleCommitClick = useCallback(() => {
		handleCloseMenu();
		setConfirmCommitOpen(true);
	}, []);

	const handleCommit = useCallback(
		() => {
			setConfirmCommitOpen(false);
			handleCallback({
				callback: COMMIT_DRAFT,
				payload: {
					makeCommitCurrent: commitAndMakeCurrent,
					newCurrentVersion: historyData.versions.length,
				},
			});
		},
		[commitAndMakeCurrent, handleCallback, historyData.versions.length]
	);

	const handleNewDraft = useCallback(
		() => {
			handleCallback({
				callback: UPDATE_DRAFT,
				payload: { draft_base: selectedVersion },
			});
			setResetDraftDialogOpen(false);
		},
		[handleCallback, selectedVersion]
	);

	const handleResetClick = useCallback(() => {
		handleCloseMenu();
		setResetDraftDialogOpen(true);
	}, []);

	const menuItems = useMemo(
		() => [
			{
				label: 'Edit draft',
				callback: handleEditClick,
			},
			{
				label: 'Reset draft',
				callback: handleResetClick,
			},
			{
				label: 'Commit draft',
				callback: handleCommitClick,
			},
		],
		[handleCommitClick, handleEditClick, handleResetClick]
	);

	// const label = useMemo(
	// 	() => {
	// 		// if (draftHasChanges) {
	// 		// 	return 'The draft has changes';
	// 		// } else {
	// 		// 	return 'The current version is up to date with the draft';
	// 		// }
	// 		return `draft_has_changes: ${
	// 			historyData.draft.has_changes
	// 		} draft_base: ${historyData.draft.draft_base + 1}`;
	// 	},
	// 	[historyData]
	// );

	// const text = useMemo(
	// 	() => {
	// 		if (draftHasChanges && currentUserCanEdit) {
	// 			return 'The draft can be published, saved or you can continue editing';
	// 		} else if (draftHasChanges && !currentUserCanEdit) {
	// 			return 'To view or edit the draft it needs to be locked to you';
	// 		} else {
	// 			return null;
	// 		}
	// 	},
	// 	[currentUserCanEdit, draftHasChanges]
	// );

	const DraftStatus = () => {
		return (
			<Box
				sx={{
					display: 'flex',
					gap: 1,
					width: '100%',
					flexWrap: 'wrap',
					paddingBottom: '10px',
					paddingTop: '5px',
				}}
			>
				<DraftChanges has_changes={historyData.draft.has_changes} />
				<DraftBase draft_base={historyData.draft.draft_base} />
				<CurrentVersion current_version={historyData.current_version} />
			</Box>
		);
	};

	// const DraftButtons = () => {
	// 	return (
	// 		<Box sx={{}}>
	// 			<Tooltip title="Edit Draft" placement="right">
	// 				<IconButton>
	// 					<Edit />
	// 				</IconButton>
	// 			</Tooltip>
	// 			<Tooltip title="Reset Draft" placement="right">
	// 				<IconButton>
	// 					<Autorenew />
	// 				</IconButton>
	// 			</Tooltip>
	// 			<Tooltip title="Commit Draft" placement="right">
	// 				<IconButton>
	// 					<PublishIcon />
	// 				</IconButton>
	// 			</Tooltip>
	// 		</Box>
	// 	);
	// };

	return (
		<>
			<StatusRow
				icon={
					<ViewDashboardEdit
						sx={{
							marginRight: '10px',
							fontSize: '30px',
						}}
					/>
				}
				label="Status"
				// text={text}
				onClick={handleOpenMenu}
				buttonText="options"
				buttonDisabled={!currentUserCanEdit || sending}
				// buttonComponent={<DraftButtons />}
				bodyComponent={<DraftStatus />}
			/>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleCloseMenu}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				{menuItems.map(item => (
					<MenuItem onClick={item.callback} key={item.label}>
						{item.label}
					</MenuItem>
				))}
			</Menu>

			<BtDialog open={confirmCommitOpen}>
				<DialogTitle>Commit draft to version</DialogTitle>
				<DialogContent>
					<FormControlLabel
						label="Do you want to make make the committed version current?"
						control={
							<Switch
								checked={commitAndMakeCurrent}
								onChange={event => {
									setCommitAndMakeCurrent(
										event.target.checked
									);
								}}
							/>
						}
					/>
				</DialogContent>
				<DialogActions>
					<ActionButtonContainer>
						<Button
							disableElevation
							onClick={() => setConfirmCommitOpen(false)}
							variant="outlined"
						>
							Cancel
						</Button>
						<Button
							disableElevation
							startIcon={<PublishIcon />}
							onClick={handleCommit}
							variant="contained"
						>
							Commit
						</Button>
					</ActionButtonContainer>
				</DialogActions>
			</BtDialog>
			<BtDialog maxWidth="xs" open={resetDraftDialogOpen}>
				<BtFilterSelect
					title="Select a version to use as as the draft base"
					onChange={handleVersionSelect}
					dense
					options={versionHistory}
				/>

				<DialogActions>
					<ActionButtonContainer>
						<Button
							disableElevation
							onClick={() => setResetDraftDialogOpen(false)}
							variant="outlined"
						>
							Cancel
						</Button>
						<Button
							disabled={selectedVersion === null}
							disableElevation
							startIcon={<Autorenew />}
							onClick={handleNewDraft}
							variant="contained"
						>
							Reset
						</Button>
					</ActionButtonContainer>
				</DialogActions>
			</BtDialog>
		</>
	);
};
