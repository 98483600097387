import { useMemo } from 'react';
import { Switch } from 'react-router-dom';

import BtAuthRoute from '../../../../components/generic/BtAuthRoute';
import { INSIGHT_COLLECTION } from '../../../../components/generic/recordManagement/utils/constants';
import { RecordManagementContextProvider } from '../../../../components/generic/recordManagement/RecordManagementContext';
import RecordManager from '../../../../components/generic/recordManagement/components/RecordManager';
import { recordManagerRequiredPermissionsAll } from '../../../../components/generic/recordManagement/utils/lookupTables/permissions';

export default function InsightCollectionSettingsRoute({ match }) {
	const insightId = useMemo(() => match.params.id, [match]);
	const module = INSIGHT_COLLECTION;
	const requiredPermissionsAll = recordManagerRequiredPermissionsAll[module];

	return (
		<Switch>
			<RecordManagementContextProvider
				module={module}
				// module={AUTOMATION}
				recordUuid={insightId}
			>
				<BtAuthRoute
					exact
					path="/InsightCollection/:id/Settings"
					component={RecordManager}
					requiredPermissionsAll={requiredPermissionsAll}
					validSubscriptionsAny={['Insights Editor']}
				/>
			</RecordManagementContextProvider>
		</Switch>
	);
}
