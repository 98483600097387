import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	IconButton,
	Tooltip,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import produce from 'immer';
import { useSnackbar } from 'notistack';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import BtConfirmDialog from '../../../components/generic/BtConfirmDialog';
import BtDialog from '../../../components/generic/BtDialog';
import BtMessage from '../../../components/generic/BtMessage';
import BtSelectionTable from '../../../components/generic/BtSelectionTable';
//import PermissionWizard from './permissions-wizard/PermissionWizard';
import PermissionWizard from '../../Admin/roles/permissions-wizard/PermissionWizard';
import {
	apiManagerDeletePermission,
	apiManagerAddPermission,
	apiManagerEditPermission,
	apiManagerPermissionsGetList,
} from '../../../API';

export default function ApiKeyPermissions({ apiKey, apiKeyId, setApiKey }) {
	const { enqueueSnackbar } = useSnackbar();

	const theme = useTheme();
	const screenDowmSm = useMediaQuery(theme.breakpoints.down('sm'));

	const tableColumns = useMemo(
		() => [
			{ field: 'method', text: 'Permission' },
			{ field: 'group', text: 'Group' },
			{ field: 'resourceCount', text: 'Resources' },
			{ field: 'actions', text: '' },
		],
		[]
	);

	const [permToDelete, setPermToDelete] = useState(null);
	const [deleteDialog, setDeleteDialog] = useState(false);
	const [permDialog, setPermDialog] = useState(false);
	const [permToEdit, setPermToEdit] = useState(null);

	const deletePermission = useCallback(
		async permToDelete => {
			const { permissions } = apiKey || {};
			const newPerms = permissions.filter(
				({ uuid }) => uuid !== permToDelete.uuid
			);
			setApiKey(
				produce(draft => {
					draft.permissions = newPerms;
				})
			);

			try {
				await apiManagerDeletePermission({
					apiKeyUuid: apiKey?.uuid,
					permissionUuid: permToDelete.uuid,
				});
				setApiKey(
					produce(draft => {
						draft.modify_timestamp = Date.now();
					})
				);
				enqueueSnackbar(`Permission deleted`);
			} catch {
				setApiKey(
					produce(draft => {
						draft.permissions = [
							...draft.permissions,
							permToDelete,
						];
					})
				);
				enqueueSnackbar(
					`Could not delete permission ${
						permToDelete.method
					} from API key`,
					{
						variant: 'error',
					}
				);
			}
		},
		[enqueueSnackbar, apiKey, setApiKey]
	);

	const sortedPermissions = useMemo(
		() =>
			([...apiKey?.permissions] || []).sort((a, b) =>
				a['method'].localeCompare(b['method'])
			),
		[apiKey]
	);

	return (
		<>
			<Button
				variant="contained"
				onClick={() => {
					setPermToEdit(null);
					setPermDialog(true);
				}}
				startIcon={<AddIcon />}
				disableElevation
				style={{
					marginBottom: '1em',
					width: screenDowmSm ? '100%' : 'auto',
				}}
			>
				Add Permission
			</Button>

			{sortedPermissions.length > 0 && (
				<BtSelectionTable
					columns={tableColumns}
					data={sortedPermissions.map(permission => ({
						...permission,
						resourceCount: (() => {
							const { resources } = permission || {};
							if (resources?.includes('*')) {
								return 'All Available';
							}
							return (resources || []).length;
						})(),
						actions: (
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
									gap: 10,
								}}
							>
								<Tooltip
									title={'Edit Resources'}
									disableInteractive
								>
									<IconButton
										onClick={() => {
											setPermToEdit(permission);
											setPermDialog(true);
										}}
									>
										<EditIcon />
									</IconButton>
								</Tooltip>
								<Tooltip title="Delete" disableInteractive>
									<IconButton
										color="error"
										onClick={() => {
											setPermToDelete(permission);
											setDeleteDialog(true);
										}}
									>
										<DeleteIcon />
									</IconButton>
								</Tooltip>
							</div>
						),
					}))}
				/>
			)}
			<BtConfirmDialog
				action={() => deletePermission(permToDelete)}
				isDestructive
				open={deleteDialog}
				onClose={() => setDeleteDialog(false)}
				title="Delete Permission?"
				prompt={`Are you sure you want to delete the ${
					permToDelete?.method
				} permission? This cannot be undone.`}
				verb="Delete Permission"
				ActionIcon={<DeleteIcon />}
			/>
			<BtDialog
				open={permDialog}
				onClose={(event, reason) => {
					if (reason !== 'backdropClick') {
						setPermDialog(false);
					}
				}}
				minwidth="md"
				style={{
					maxHeight: screenDowmSm ? '100%' : 720,
					margin: 'auto',
				}}
				fullWidth
			>
				<PermissionWizard
					addedPerms={apiKey?.permissions || []}
					ownerId={apiKeyId}
					onClose={() => setPermDialog(false)}
					permToEdit={permToEdit}
					setOwner={setApiKey}
					getPermissionsList={apiManagerPermissionsGetList}
					editPermission={apiManagerEditPermission}
					addPermission={apiManagerAddPermission}
				/>
			</BtDialog>
		</>
	);
}

ApiKeyPermissions.propTypes = {
	apiKey: PropTypes.object,
	apiKeyId: PropTypes.string,
	setApiKey: PropTypes.func.isRequired,
};
