// Module ref
const INSIGHT_COLLECTION = 'insightCollection';
const AUTOMATION = 'automation';
const WORKFLOW_TEMPLATE = 'workflowTemplate';

// Status ref
const ACTIVE = 'Active';
const INACTIVE = 'Inactive';
const UNPUBLISHED = 'Unpublished';

// Lock status ref
const UNLOCKED = 'unlocked';
const CURRENT_USER = 'currentUser';
const ANOTHER_USER = 'anotherUser';

// Lock user status ref
const LOCK_TO_CURRENT = 'lockToCurrent';
const UNLOCK = 'unlock';
const LOCK_TO_OTHER = 'lockToOther';

// API ref
const RECORD_STATUS_API = 'changeRecordStatus';
const LOCK_STATUS_API = 'changeLockStatus';
const PUBLISH_RECORD_API = 'publishRecord';
const UPDATE_RECORD_API = 'updateRecord';
const COMMIT_DRAFT_API = 'commitDraft';
const UPDATE_DRAFT_API = 'updateDraft';
const GET_DRAFT_API = 'getDraft';
const GET_VERSION_API = 'getVersion';

// Callback and API ref
const RECORD_STATUS = 'recordStatus';
const LOCK_STATUS = 'lockStatus';
const PUBLISH = 'publish';
const UPDATE_RECORD = 'updateRecord';
const COMMIT_DRAFT = 'commitDraft';
const SET_CURRENT_PUBLISH = 'setCurrentAndPublish';
const PUBLISH_WITH_DRAFT = 'publishWithDraft';
const UPDATE_DRAFT = 'updateDraft';

export {
	// Module ref
	INSIGHT_COLLECTION,
	AUTOMATION,
	WORKFLOW_TEMPLATE,
	// Status ref
	ACTIVE,
	INACTIVE,
	UNPUBLISHED,
	// Lock status ref
	UNLOCKED,
	CURRENT_USER,
	ANOTHER_USER,
	// Lock user status ref
	LOCK_TO_CURRENT,
	UNLOCK,
	LOCK_TO_OTHER,
	// API ref
	PUBLISH_RECORD_API,
	COMMIT_DRAFT_API,
	UPDATE_RECORD_API,
	LOCK_STATUS_API,
	RECORD_STATUS_API,
	UPDATE_DRAFT_API,
	GET_DRAFT_API,
	GET_VERSION_API,
	// Callback and API ref
	RECORD_STATUS,
	LOCK_STATUS,
	PUBLISH,
	UPDATE_RECORD,
	COMMIT_DRAFT,
	SET_CURRENT_PUBLISH,
	PUBLISH_WITH_DRAFT,
	UPDATE_DRAFT,
};
