import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useMemo,
	useState,
} from 'react';
import PropTypes from 'prop-types';

import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export const BtForm = forwardRef(
	(
		{
			children,
			defaultValues,
			loading,
			onFormStateChange,
			onSubmit,
			sending,
			validationSchema,
			...other
		},
		ref
	) => {
		const [overrideBlocking, setOverrideBlocking] = useState(false);
		const methods = useForm({
			defaultValues,
			mode: 'onTouched',
			resolver: yupResolver(validationSchema),
		});

		useImperativeHandle(ref, () => ({
			submit: methods.handleSubmit(onSubmit),
			trigger: fields => methods.trigger(fields),
		}));

		useEffect(
			() => {
				// console.log('form resetting');
				methods.reset(defaultValues || {});
			},
			[defaultValues, methods]
		);

		useEffect(
			() => {
				if (onFormStateChange) {
					const answers = methods.getValues();
					// console.log('onFormStateChange');
					onFormStateChange(methods.formState, answers);
				}
			},
			[methods, methods.formState, onFormStateChange]
		);

		return (
			<FormProvider
				{...methods}
				loading={loading}
				onSubmit={onSubmit}
				overrideBlocking={overrideBlocking}
				sending={sending}
				setOverrideBlocking={setOverrideBlocking}
				validationSchema={validationSchema}
			>
				<form
					ref={ref}
					disabled={loading || sending}
					onSubmit={event => {
						event.preventDefault();

						methods.handleSubmit(event =>
							onSubmit?.(event, methods.reset)
						)();
					}}
					{...other}
				>
					{children}
				</form>
			</FormProvider>
		);
	}
);

BtForm.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	defaultValues: PropTypes.object,
	loading: PropTypes.bool,
	onFormStateChange: PropTypes.func,
	onSubmit: PropTypes.func,
	sending: PropTypes.bool,
	validationSchema: PropTypes.object.isRequired,
};

BtForm.displayName = 'BtForm';

export default BtForm;
