import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Collapse, Tooltip, IconButton, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

export const VisOptionsFormSectionWrapper = ({
	children,
	title,
	initialOpenState,
}) => {
	const [showSection, setShowSection] = useState(initialOpenState ?? true);
	return (
		<Box sx={{ marginBottom: '20px' }}>
			<Box
				sx={{
					display: 'flex',
					// gap: '10px',
					// marginTop: '1em',
					alignItems: 'center',
				}}
			>
				<Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
				<Tooltip
					placement="right"
					title={showSection ? 'Hide' : 'Show'}
				>
					<IconButton
						size="small"
						onClick={() => setShowSection(t => !t)}
					>
						<ExpandMore
							sx={{
								transform: showSection
									? 'rotate( -180deg )'
									: '',
								transition: 'transform 200ms ease',
							}}
							fontSize="small"
						/>
					</IconButton>
				</Tooltip>
			</Box>
			<Collapse in={showSection}>
				<Box sx={{ padding: '0 10px 0 10px' }}>{children} </Box>
			</Collapse>
		</Box>
	);
};

VisOptionsFormSectionWrapper.propTypes = {
	children: PropTypes.node,
	title: PropTypes.string,
	initialOpenState: PropTypes.bool,
};
