import React from 'react';
import PropTypes from 'prop-types';

import { styled, Tooltip } from '@mui/material';
import { useFormContext } from 'react-hook-form';

const Indicator = styled('div')(({ theme }) => ({
	alignItems: 'center',
	display: 'flex',
	height: 16,
	justifyContent: 'center',
	width: 16,

	'& > span': {
		backgroundColor: theme.palette.indicators.warning.main,
		borderRadius: 4,
		height: 8,
		width: 8,
	},
}));

export default function BtFormChangesIndicator({ field, ...other }) {
	const {
		formState: { dirtyFields },
	} = useFormContext() || { formState: {} };

	const show = dirtyFields[field];

	return (
		<>
			{show && (
				<Tooltip
					disableInteractive
					title="There are unsaved changes"
					{...other}
				>
					<Indicator>
						<span />
					</Indicator>
				</Tooltip>
			)}
		</>
	);
}

BtFormChangesIndicator.propTypes = {
	field: PropTypes.string.isRequired,
};
