import { useMemo } from 'react';

export default function useFormElRequired(isRequired, validationSchema, name) {
	return useMemo(
		() =>
			isRequired === undefined && !!validationSchema?.fields
				? validationSchema?.fields[name]?.exclusiveTests?.required ||
				  validationSchema?.fields[name]?.exclusiveTests.min ||
				  validationSchema?.fields[name]?.deps?.length > 0 ||
				  false
				: !!isRequired,
		[isRequired, validationSchema, name]
	);
}
