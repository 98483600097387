import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';
import { Prompt } from 'react-router';
import { useSnackbar } from 'notistack';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

import BtConfirmDialog from '../../../../components/generic/BtConfirmDialog';
import DataConfig from '../../../../components/generic/DataConfig/DataConfig';
import { dataSetUpdate } from '../../../../API';
import { parseSchema } from '../../../../utils/yup-ast';
import useFetch from '../../../../hooks/useFetch';

export default function DataSetConfig({
	dataSet,
	editing,
	setDataSet,
	setEditing,
}) {
	const configRef = useRef();

	const updateDataSet = useFetch(dataSetUpdate).request;
	const { enqueueSnackbar } = useSnackbar();

	const getInitialConfig = useCallback(
		() => {
			const { config: initialConfig, schema } = dataSet;

			if (!initialConfig && schema) {
				return { config: parseSchema(schema), schema };
			}

			return { config: initialConfig, schema } || {};
		},
		[dataSet]
	);

	const [sending, setSending] = useState(false);
	const [showDiscardModal, setShowDiscardModal] = useState(false);
	const [value, setValue] = useState(getInitialConfig);

	const handleSubmit = useCallback(
		async () => {
			const newDataSet = {
				...dataSet,
				...value,
			};

			setSending(true);

			const { error, ok } = await updateDataSet({
				dataSetUuid: dataSet.uuid,
				dataSetUpdate: newDataSet,
			});

			setSending(false);

			if (!ok) {
				enqueueSnackbar('Failed to update the Data Set', {
					variant: 'error',
				});

				console.error('Failed to update the Data Set', error);

				return;
			}

			setDataSet(newDataSet);

			configRef.current.clearHistory(newDataSet.config);

			setEditing(false);

			enqueueSnackbar('Data Set config successfully updated', {
				variant: 'success',
			});
		},
		[dataSet, enqueueSnackbar, setDataSet, setEditing, updateDataSet, value]
	);

	const handleDiscard = useCallback(
		() => {
			const initialConfig = getInitialConfig();

			setValue(initialConfig);

			configRef.current.clearHistory(initialConfig.config);

			setEditing(false);
		},
		[getInitialConfig, setEditing]
	);

	return (
		<>
			<div>
				{editing ? (
					<>
						<Button
							disableElevation
							onClick={handleSubmit}
							startIcon={<SaveIcon />}
							style={{ marginRight: '1em' }}
							variant="contained"
						>
							Save Changes
						</Button>
						<Button
							color="error"
							disableElevation
							onClick={() => setShowDiscardModal(true)}
							startIcon={<DeleteIcon />}
						>
							Discard Changes
						</Button>
					</>
				) : (
					<Button
						key={'editButton'}
						disableElevation
						onClick={() => setEditing(true)}
						startIcon={<EditIcon />}
						variant="outlined"
					>
						Edit Config
					</Button>
				)}
			</div>
			<div style={{ display: 'flex', marginTop: '1em', width: '100%' }}>
				<DataConfig
					ref={configRef}
					config={value.config}
					disabled={sending}
					onChange={setValue}
					readOnly={!editing}
				/>
			</div>
			<BtConfirmDialog
				action={handleDiscard}
				ActionIcon={<DeleteIcon />}
				isDestructive
				prompt="Are you sure you want to discard this record creation? This cannot be undone."
				onClose={() => setShowDiscardModal(false)}
				open={showDiscardModal}
				title="Discard Record Creation"
				verb="Discard"
			/>
			<Prompt
				message="You have unsaved changes, are you sure you want to leave?"
				when={editing}
			/>
		</>
	);
}

DataSetConfig.propTypes = {
	dataSet: PropTypes.shape({
		config: PropTypes.object,
		description: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		schema: PropTypes.array.isRequired,
		uuid: PropTypes.string.isRequired,
	}),
	editing: PropTypes.bool,
	setDataSet: PropTypes.func,
	setEditing: PropTypes.func,
};
