import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';

import {
	ButtonBase,
	IconButton,
	styled,
	Tooltip,
	Typography,
} from '@mui/material';
import {
	Close,
	Download,
	RestartAlt,
	Refresh,
	ZoomIn,
	ZoomOut,
} from '@mui/icons-material';
import { exportComponentAsPNG } from 'react-component-export-image';

import BtDialog from './BtDialog';
import BtError from './BtError';
import BtLoading from './BtLoading';
import { formatFormatter } from '../../views/DataManager/DataSources/image-repo/utils';
import { imageRepoGet, imageRepoGetImage } from '../../API';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import useFetch from '../../hooks/useFetch';

const commonMenuProps = {
	alignItems: 'center',
	boxSizing: 'border-box',
	display: 'flex',
	overflow: 'hidden',
	padding: '1em 1.5em',
	position: 'absolute',
	width: '100%',
};

const CentrallyAligned = styled('div')(() => ({
	alignItems: 'center',
	display: 'flex',
}));

const Container = styled('div')(() => ({
	backgroundColor: '#000000d0',
	height: '100vh',
	overflow: 'hidden',
	position: 'relative',
	width: '100vw',
}));

const Footer = styled('div')(() => ({
	...commonMenuProps,
	background: `linear-gradient(180deg, #0000 0%, #0002 33%, #0007 100%)`,
	bottom: 0,
	flexWrap: 'wrap',
	justifyContent: 'center',
}));

const FormatButton = styled(ButtonBase)(({ theme }) => ({
	backgroundColor: '#333',
	borderRadius: 16,
	color: '#fff',
	height: 32,
	margin: '0 0.5em 0.5em 0',
	outline: 'none',
	outlineOffset: 0,
	padding: '0 1em',
	textTransform: 'capitalize',

	transition: theme.transitions.create(['outline-offset'], {
		duration: '0.15s',
	}),

	'&:last-of-type': {
		marginRight: 0,
	},

	'&.selected': {
		backgroundColor: '#08599b',
		outline: '1px solid #08599b',
		outlineOffset: 2,
	},
}));

const Header = styled('div')(() => ({
	...commonMenuProps,
	background: `linear-gradient(0deg, #0000 0%, #0002 33%, #0007 100%)`,
	justifyContent: 'space-between',
	top: 0,
}));

const ImageContainer = styled('div')(() => ({
	height: '100%',
	width: '100%',
}));

const StyledIconButton = styled(IconButton)(() => ({
	backgroundColor: '#0006',
	color: '#fff',

	'&:hover': {
		backgroundColor: '#7776',
	},

	'&.contained': {
		backgroundColor: '#f7f7f7',
		boxShadow: '0 0 1px #000a',
		color: '#000',
		margin: 1,
	},

	'&.contained:hover': {
		backgroundColor: '#fff',
	},

	'&.contained:active': {
		backgroundColor: '#e7e7e7',
	},
}));

const Title = styled(Typography)(() => ({
	backgroundColor: '#0005',
	borderRadius: 6,
	color: '#fff',
	overflow: 'hidden',
	padding: '2px 8px',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	textShadow: '0 0 1px #000',
}));

const TransformContent = styled('div')(() => ({
	alignItems: 'center',
	display: 'flex',
	height: '100vh',
	justifyContent: 'center',
	width: '100vw',
}));

const ActionButton = ({
	contained,
	disabled,
	Icon,
	onClick,
	title,
	...other
}) => (
	<Tooltip title={disabled ? '' : title} {...other}>
		<StyledIconButton
			className={contained ? 'contained' : undefined}
			disabled={disabled}
			onClick={onClick}
		>
			{Icon}
		</StyledIconButton>
	</Tooltip>
);

export const ImageSource = {
	IMAGE_REPO: 'repo',
	LOCAL: 'local',
	BASE64: 'base64',
};

const Viewer = ({
	imageSource,
	formatUuid,
	imageUuid,
	imageString,
	imageStyle,
	imageRepoUuid,
	imageFile,
	imageFileName,

	onClose,
	open,
	visibleFormats,
}) => {
	const imageRef = useRef();
	const transformerRef = useRef();

	const clickWithin = useRef();

	const [_image, setImage] = useState(imageString);
	const [loadedFormatUuid, setLoadedFormatUuid] = useState();
	const [loadedImageUuid, setLoadedImageUuid] = useState();
	const [loadingImage, setLoadingImage] = useState(false);
	const [repo, setRepo] = useState();
	const [scale, setScale] = useState();
	const [targetImageUuid, setTargetImageUuid] = useState(imageUuid);
	const [targetFormatUuid, setTargetFormatUuid] = useState(formatUuid);

	const calculateScale = useCallback(
		() => {
			transformerRef?.current?.centerView(undefined, 0);

			if (repo && targetFormatUuid) {
				const { innerHeight, innerWidth } = window;
				const { maxWidthHeight } =
					repo.formats.find(
						({ uuid }) => uuid === targetFormatUuid
					) ??
					repo.formats[0] ??
					{};

				const viewportLimit = Math.min(
					Math.max(innerHeight - 140, 1),
					innerWidth
				);

				if (maxWidthHeight > viewportLimit) {
					setScale(viewportLimit / (maxWidthHeight || 1));

					return;
				}

				setScale(1);
			}
		},
		[repo, targetFormatUuid]
	);

	const { error: imageError, request: getImage } = useFetch(
		imageRepoGetImage,
		response => {
			setImage(response);
			setLoadedFormatUuid(targetFormatUuid);
			setLoadedImageUuid(targetImageUuid);
			calculateScale();

			setLoadingImage(true);
			transformerRef?.current?.resetTransform(0);
		}
	);

	const {
		error: repoError,
		loading: loadingRepo,
		request: getImageRepo,
	} = useFetch(imageRepoGet, async response => {
		console.log('repo loaded');
		setRepo(response);

		const imageId = imageUuid;
		const formatId = formatUuid ?? response.formats[0].uuid;

		console.log({ imageId, formatId });
		if (imageId && formatId) {
			setTargetImageUuid(imageId);
			setTargetFormatUuid(formatId);
		}
	});

	useEffect(
		() => {
			if (formatUuid) {
				setTargetFormatUuid(formatUuid);
			}
		},
		[formatUuid]
	);

	useEffect(
		() => {
			const uuid = imageUuid;

			if (targetImageUuid !== imageUuid) {
				setTargetImageUuid(uuid);
			}
		},
		[imageUuid, targetImageUuid]
	);

	useEffect(
		() => {
			(async () => {
				console.log({ loadingRepo, open, imageRepoUuid });
				if (!loadingRepo && open && imageRepoUuid) {
					console.log({ imageSource });
					if (imageSource === ImageSource.IMAGE_REPO) {
						const initialiseFetch = () => {
							setImage(null);
						};
						console.log('check repo uuid');
						console.log({ repo, imageRepoUuid });
						if (repo?.uuid !== imageRepoUuid) {
							initialiseFetch();
							console.log('getImageRepo', imageRepoUuid);
							await getImageRepo({
								imageRepo: imageRepoUuid,
							});
						}
					}
				}
			})();
		},
		[
			imageSource,
			getImageRepo,
			imageRepoUuid,
			imageFile,
			loadingRepo,
			open,
			repo,
		]
	);

	useEffect(
		() => {
			if (imageSource === ImageSource.LOCAL) {
				if (imageFile) {
					console.log('image load', imageFile);
					setImage(URL.createObjectURL(imageFile));
				}
			}
		},
		[imageFile, setImage, imageSource]
	);

	useEffect(
		() => {
			(async () => {
				console.log({
					loadingImage,
					repo,
					targetFormatUuid,
					targetImageUuid,
					// (targetFormatUuid !== loadedFormatUuid ||
					// 	targetImageUuid !== loadedImageUuid)
				});
				if (
					!loadingImage &&
					repo &&
					targetFormatUuid &&
					targetImageUuid &&
					(targetFormatUuid !== loadedFormatUuid ||
						targetImageUuid !== loadedImageUuid)
				) {
					setLoadingImage(true);
					console.log('getImage');
					await getImage({
						imageRepoUuid: imageRepoUuid,
						formatUuid: targetFormatUuid,
						imageUuid: targetImageUuid,
					});

					setLoadingImage(false);
				}
			})();
		},
		[
			imageRepoUuid,
			loadedFormatUuid,
			loadedImageUuid,
			loadingImage,
			targetFormatUuid,
			targetImageUuid,
			getImage,
			repo,
		]
	);

	useEffect(
		() => {
			window.addEventListener('resize', calculateScale);

			return () => window.removeEventListener('resize', calculateScale);
		},
		[calculateScale]
	);

	const loading = loadingImage || loadingRepo;
	const error = imageError || repoError;

	const formatsToShow = useMemo(
		() => {
			if (!repo) return [];

			const { formats } = repo;

			if ((visibleFormats || []).length > 0) {
				return formats.filter(({ uuid }) =>
					visibleFormats.includes(uuid)
				);
			}

			return formats || [];
		},
		[visibleFormats, repo]
	);

	return (
		<>
			{error && (
				<BtError
					action={() =>
						getImage({ formatUuid, imageUuid: imageUuid })
					}
					actionIcon={<Refresh />}
					actionLabel="Retry"
					description={
						repoError
							? 'An error occurred when attempting to access the repository for the image.'
							: 'An error occurred when attempting to load the image.'
					}
					title={'Could Not Load Image'}
				/>
			)}
			{loading && <BtLoading />}
			{_image &&
				!loading && (
					<ImageContainer>
						<TransformWrapper
							ref={transformerRef}
							centerOnInit
							maxScale={12}
							style={{ height: '100vh', width: '100vw' }}
						>
							<TransformComponent>
								<TransformContent
									onMouseDown={event => {
										if (!clickWithin.current) {
											event.stopPropagation();
										}
									}}
									onClick={onClose}
									onMouseUp={() =>
										(clickWithin.current = false)
									}
								>
									{_image && (
										<img
											ref={imageRef}
											alt={'Image'}
											onClick={event =>
												event.stopPropagation()
											}
											onMouseDown={() =>
												(clickWithin.current = true)
											}
											src={
												imageSource ===
												ImageSource.IMAGE_REPO
													? `data:image/jpeg;base64,${_image}`
													: _image

												// imageString
												// ? _image
												// : `data:image/jpeg;base64,${_image}`
											}
											style={{
												...(imageStyle || {}),
												imageRendering:
													'-webkit-optimize-contrast',
												pointerEvents: 'all',
												transform: `scale(${scale}) translateZ(0)`,
											}}
										/>
									)}
								</TransformContent>
							</TransformComponent>
						</TransformWrapper>
					</ImageContainer>
				)}
			{formatsToShow.length > 1 && (
				<Footer>
					{formatsToShow.map(({ name, uuid }) => (
						<FormatButton
							key={uuid}
							className={
								targetFormatUuid === uuid
									? 'selected'
									: undefined
							}
							focusRipple
							onClick={() => setTargetFormatUuid(uuid)}
						>
							{formatFormatter(name)}
						</FormatButton>
					))}
				</Footer>
			)}
			<Header>
				<CentrallyAligned style={{ overflow: 'hidden' }}>
					<ActionButton
						contained
						disableTouchRipple
						Icon={<Close />}
						onClick={onClose}
						style={{ marginRight: '1em' }}
						title="Close"
					/>
					<Title variant="h5">{imageFileName ?? ''}</Title>
				</CentrallyAligned>
				<CentrallyAligned>
					<ActionButton
						Icon={<ZoomIn />}
						onClick={() => transformerRef.current.zoomIn()}
						style={{ marginRight: '0.5em' }}
						title="Zoom In"
					/>
					<ActionButton
						Icon={<ZoomOut />}
						onClick={() => transformerRef.current.zoomOut()}
						style={{ marginRight: '0.5em' }}
						title="Zoom Out"
					/>
					<ActionButton
						Icon={<RestartAlt />}
						onClick={() => transformerRef.current.resetTransform()}
						style={{ marginRight: '0.5em' }}
						title="Reset"
					/>
					<ActionButton
						Icon={<Download />}
						onClick={() => {
							if (_image) {
								exportComponentAsPNG(imageRef, {
									fileName: imageFileName || 'Image',
								});
							}
						}}
						title="Download"
					/>
				</CentrallyAligned>
			</Header>
		</>
	);
};

export default function BtImageDialog({ onClose, open, ...other }) {
	const PaperComponent = useMemo(
		() => ({ children }) => <Container>{children}</Container>,
		[]
	);

	return (
		<BtDialog
			BackdropComponent={null}
			fullScreen
			onClose={onClose}
			open={open}
			PaperComponent={PaperComponent}
		>
			<Viewer open={open} onClose={onClose} {...other} />
		</BtDialog>
	);
}

const propsTypes = {
	imageSource: PropTypes.string,
	formatUuid: PropTypes.string,
	imageUuid: PropTypes.string,
	imageRepoUuid: PropTypes.string,
	imageFileName: PropTypes.string,
	imageString: PropTypes.string,
	imageStyle: PropTypes.object,
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	visibleFormats: PropTypes.arrayOf(PropTypes.string),
};

ActionButton.propTypes = {
	contained: PropTypes.bool,
	disabled: PropTypes.bool,
	Icon: PropTypes.element,
	onClick: PropTypes.func,
	title: PropTypes.string,
};

Viewer.propTypes = propsTypes;
BtImageDialog.propTypes = propsTypes;
