import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import {
	ButtonBase,
	CircularProgress,
	Divider,
	InputAdornment,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	styled,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import {
	Check as CheckIcon,
	Circle as CircleIcon,
	Close as CloseIcon,
	FilterList as FilterIcon,
	MyLocation as TargetIcon,
	Search as SearchIcon,
} from '@mui/icons-material';
import Color from 'color';

import BtNoItems from '../../BtNoItems';
import BtPopover from '../../BtPopover';
import { LOOSE, STANDARD, STRICT } from '../constants';
import { useAppContext } from '../../../../context/ContextManager';
import useBreakpointStatuses from '../../../../hooks/useBreakpointStatuses';

const ClearButton = styled('button')(({ theme }) => ({
	background: 'none',
	border: 'none',
	color: theme.palette.indicators.info.text,
	cursor: 'pointer',
	padding: 'none',
	textDecoration: 'none',

	'&:hover': {
		color: theme.palette.indicators.info.text,
		textDecoration: 'underline',
		textDecorationColor: theme.palette.indicators.info.text,
	},
}));

const ClearSearchButton = styled(ButtonBase)(({ theme }) => ({
	alignItems: 'center',
	backgroundColor: Color(theme.palette.text.solid)
		.fade(0.95)
		.toString(),
	borderRadius: 9,
	display: 'flex',
	height: 18,
	justifyContent: 'center',
	marginRight: 2,
	width: 18,

	'&:hover': {
		backgroundColor: Color(theme.palette.text.solid)
			.fade(0.9)
			.toString(),
	},

	'&:active': {
		backgroundColor: Color(theme.palette.text.solid)
			.fade(0.85)
			.toString(),
	},
}));

const Container = styled('div')(({ theme }) => ({
	alignItems: 'center',
	backgroundColor: Color(theme.palette.text.solid)
		.fade(0.96)
		.toString(),
	borderRadius: 6,
	display: 'grid',
	gridTemplateColumns: 'auto max-content',
	overflow: 'hidden',
	position: 'relative',
}));

const ExactnessChip = styled('span')(({ exactness, theme }) => ({
	alignItems: 'center',
	backgroundColor: theme.palette.exactness[exactness],
	borderRadius: 8,
	color: theme.palette.background.default,
	display: 'flex',
	height: 16,
	padding: '5px 6px 4px',
	textTransform: 'capitalize',
}));

const ExactnessIndicator = styled('div')(({ exactness, theme }) => ({
	alignItems: 'center',
	display: 'flex',
	justifyContent: 'center',
	position: 'relative',

	'@keyframes spin': {
		'100%': {
			transform: 'rotate(360deg)',
		},
	},

	'& .exactness_outline': {
		height: 20,
		opacity: 0,
		position: 'absolute',
		transform: 'scale(0)',
		width: 20,

		transition: theme.transitions.create(['opacity', 'transform'], {
			duration: 150,
		}),

		'&.selected': {
			opacity: 1,
			transform: 'scale(1)',
		},

		'& svg': {
			animation: 'spin 6s linear infinite',
			height: 20,
			width: 20,
		},

		'& circle': {
			fill: 'none',
			stroke: theme.palette.exactness[exactness],
			strokeDasharray: '14.1375 4.7125',
			strokeWidth: 1,
		},
	},
}));

const ExactnessDot = styled(CircleIcon)(({ exactness, theme }) => ({
	color: theme.palette.exactness[exactness],
	height: 12,
	width: 12,
}));

const OptionButton = styled(ButtonBase)(({ contained, theme }) => ({
	backgroundColor: Color(theme.palette.text.solid)
		.fade(contained ? 0.98 : 1)
		.toString(),
	borderLeft: contained
		? `1px solid ${Color(theme.palette.text.solid)
				.fade(0.98)
				.toString()}`
		: 'none',
	display: 'flex',
	minHeight: '100%',
	padding: '0 8px',

	transition: theme.transitions.create(['background-color'], {
		duration: 150,
	}),

	'&:hover': {
		backgroundColor: Color(theme.palette.text.solid)
			.fade(contained ? 0.92 : 0.96)
			.toString(),
	},
}));

const MenuHeader = styled('div')(({ sticky, theme }) => ({
	alignItems: 'center',
	backgroundColor: theme.palette.background.card,
	borderBottom: `1px solid ${Color(theme.palette.text.solid)
		.fade(0.96)
		.toString()}`,
	cursor: 'default',
	display: 'flex',
	height: 48,
	padding: '0 16px',
	position: sticky ? 'sticky' : 'block',
	top: 0,
	userSelect: 'none',
	zIndex: 10,
}));

const SelectedIcon = styled(CheckIcon)(({ theme }) => ({
	color: theme.palette.indicators.success.main,
}));

const exactnessDescriptions = {
	[LOOSE]:
		'Is not case-sensitive, ignores whitespace and allows for minor spelling mistakes. Slowest performance.',
	[STANDARD]: 'Is not case-sensitive and whitespace characters are ignored.',
	[STRICT]:
		'Is case-sensitive and whitespace charaters are factored in. Fastest performance.',
};

export default function TableSearch({
	debounceInput,
	debounceTimeMs,
	disableExactnessOptions,
	disableSearchColumnOptions,
	exactness,
	hideExactness,
	hideSearchColumn,
	onColumnChange,
	onExactnessChange,
	onTermChange,
	searchableFields,
	searchColumn,
	searchTerm,
}) {
	const optionChange = useRef(false);
	const searchInputRef = useRef();

	const breakpointStatuses = useBreakpointStatuses();
	const { showMobile } = useAppContext();

	const [columnFilterTerm, setColumnFilterTerm] = useState('');
	const [columnTargetAnchor, setColumnTargetAnchor] = useState();
	const [exactnessAnchor, setExactnessAnchor] = useState();
	const [loading, setLoading] = useState(false);
	const [tempTerm, setTempTerm] = useState(searchTerm ?? '');

	const filteredFields = useMemo(
		() =>
			(searchableFields || []).filter(({ text }) =>
				(text || '')
					.toLowerCase()
					.trim()
					.includes((columnFilterTerm || '').toLowerCase().trim())
			),
		[columnFilterTerm, searchableFields]
	);

	const currentSearchColumn = useMemo(
		() => {
			return searchColumn
				? (searchableFields || []).find(
						({ field }) => searchColumn === field
				  ).text ?? '???'
				: 'All Columns';
		},
		[searchColumn, searchableFields]
	);

	useEffect(
		() => {
			let timeout;

			if (debounceInput) {
				if (!tempTerm) {
					setLoading(false);

					setTempTerm('');

					onTermChange('');
				} else {
					setLoading(true);

					timeout = setTimeout(
						() => {
							setLoading(false);

							onTermChange(tempTerm);
						},
						[debounceTimeMs]
					);
				}
			}

			return () => timeout && clearTimeout(timeout);
		},
		[debounceInput, debounceTimeMs, onTermChange, tempTerm]
	);

	useEffect(
		() => {
			if (optionChange.current) {
				searchInputRef.current.focus();
			}
		},
		[exactness, searchColumn]
	);

	return (
		<>
			<Container style={{ margin: '1em 0 0' }}>
				<TextField
					autoComplete="off"
					fullWidth
					InputProps={{
						disableUnderline: true,
						startAdornment: (
							<InputAdornment
								position="start"
								style={{
									marginLeft: 5,
									pointerEvents: 'none',
									width: 22,
								}}
							>
								{loading ? (
									<CircularProgress size={20} />
								) : (
									<SearchIcon
										color={
											searchTerm ? 'primary' : 'inherit'
										}
									/>
								)}
							</InputAdornment>
						),
					}}
					inputRef={searchInputRef}
					onChange={event => {
						const value = event.target.value;

						if (debounceInput) {
							setTempTerm(value);

							return;
						}

						onTermChange(value);
					}}
					placeholder="Search"
					style={{ borderBottom: 'none' }}
					value={tempTerm || searchTerm || ''}
					variant="standard"
				/>
				<div
					style={{
						display: 'flex',
						minHeight: '100%',
					}}
				>
					<div
						style={{
							alignItems: 'center',
							display: 'flex',
							marginRight: hideExactness ? 8 : 2,
						}}
					>
						{(searchTerm || tempTerm) && (
							<Tooltip disableInteractive title="Clear Search">
								<ClearSearchButton
									disableTouchRipple
									focusRipple
									onClick={() => {
										setTempTerm('');

										onTermChange('');
									}}
								>
									<CloseIcon
										style={{
											fontSize: 16,
										}}
									/>
								</ClearSearchButton>
							</Tooltip>
						)}
					</div>

					{!hideExactness && (
						<OptionButton
							disabled={disableExactnessOptions}
							disableTouchRipple
							focusRipple
							onClick={event =>
								setExactnessAnchor(event.currentTarget)
							}
						>
							{showMobile ? (
								<ExactnessDot
									exactness={exactness}
									style={{ margin: '0 8px' }}
								/>
							) : (
								<ExactnessChip exactness={exactness}>
									<SearchIcon
										style={{ fontSize: 12, marginRight: 2 }}
									/>
									{exactness}
								</ExactnessChip>
							)}
						</OptionButton>
					)}

					{!hideSearchColumn && (
						<OptionButton
							contained={1}
							disabled={disableSearchColumnOptions}
							disableTouchRipple
							focusRipple
							onClick={event =>
								setColumnTargetAnchor(event.currentTarget)
							}
							style={{
								padding: showMobile ? '0 4px' : '0 8px',
							}}
						>
							<div
								style={{
									alignItems: 'center',
									display: 'flex',
									maxWidth: (() => {
										if (!breakpointStatuses.sm) return 110;

										if (!breakpointStatuses.md) return 150;

										return 250;
									})(),
								}}
							>
								<TargetIcon
									style={{
										fontSize: 18,
										marginRight: showMobile ? 3 : 6,
									}}
								/>
								<Typography
									style={{
										overflow: 'hidden',
										paddingRight: 2,
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
									}}
								>
									{currentSearchColumn}
								</Typography>
							</div>
						</OptionButton>
					)}
				</div>
			</Container>

			<BtPopover
				anchorEl={exactnessAnchor}
				onClose={() => setExactnessAnchor(null)}
			>
				<div style={{ position: 'relative' }}>
					<MenuHeader sticky={+!showMobile}>
						<SearchIcon style={{ marginRight: 8 }} />
						<Typography>Search Exactness</Typography>
					</MenuHeader>

					<List
						dense
						style={{
							padding: 0,
							maxWidth: showMobile ? '100%' : 300,
						}}
					>
						{[STRICT, STANDARD, LOOSE].map(exactnessMode => {
							const selected = exactnessMode === exactness;

							return (
								<ListItemButton
									key={exactnessMode}
									onClick={() => {
										setExactnessAnchor(null);

										onExactnessChange(exactnessMode);

										optionChange.current = true;
									}}
									selected={selected}
								>
									<ListItemIcon style={{ minWidth: 29 }}>
										<ExactnessIndicator
											exactness={exactnessMode}
										>
											<ExactnessDot
												exactness={exactnessMode}
											/>
											<div
												className={`exactness_outline${
													selected ? ' selected' : ''
												}`}
											>
												<svg>
													<circle
														cx={10}
														cy={10}
														r="9"
													/>
												</svg>
											</div>
										</ExactnessIndicator>
									</ListItemIcon>
									<ListItemText
										primary={exactnessMode}
										primaryTypographyProps={{
											style: {
												fontWeight: 'bold',
												textTransform: 'capitalize',
											},
										}}
										secondary={
											exactnessDescriptions[exactnessMode]
										}
									/>
								</ListItemButton>
							);
						})}
					</List>
				</div>
			</BtPopover>

			<BtPopover
				anchorEl={columnTargetAnchor}
				onClose={() => {
					setColumnTargetAnchor(null);

					setColumnFilterTerm('');
				}}
			>
				<div
					style={{
						maxHeight: showMobile ? 'inherit' : 400,
						maxWidth: showMobile ? '100%' : 300,
						width: '100vw',
					}}
				>
					<MenuHeader
						sticky={+!showMobile}
						style={{ marginBottom: 8 }}
					>
						<TargetIcon style={{ marginRight: 8 }} />
						<Typography>Target Column</Typography>
					</MenuHeader>

					{searchColumn && (
						<div style={{ padding: '4px 16px 8px' }}>
							<Typography variant="subtitle2">
								Currently searching:
							</Typography>
							<div
								style={{
									alignItem: 'center',
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<Typography
									style={{
										display: 'inline',
										fontWeight: 'bold',
									}}
								>
									{currentSearchColumn}
								</Typography>
								<ClearButton
									onClick={() => {
										setColumnTargetAnchor(null);

										onColumnChange(null);

										optionChange.current = true;
									}}
								>
									Clear
								</ClearButton>
							</div>
							<Divider style={{ margin: '12px 0 4px' }} />
						</div>
					)}
					<TextField
						autoComplete="off"
						fullWidth
						InputProps={{
							startAdornment: (
								<InputAdornment
									position="start"
									style={{
										marginRight: '8px',
										pointerEvents: 'none',
									}}
								>
									<FilterIcon />
								</InputAdornment>
							),
							endAdornment: columnFilterTerm && (
								<InputAdornment position="end">
									<Tooltip
										disableInteractive
										title="Clear Filter"
									>
										<ClearSearchButton
											disableTouchRipple
											focusRipple
											onClick={() => {
												setColumnFilterTerm('');
											}}
										>
											<CloseIcon
												style={{ fontSize: 16 }}
											/>
										</ClearSearchButton>
									</Tooltip>
								</InputAdornment>
							),
						}}
						onChange={event =>
							setColumnFilterTerm(event.target.value)
						}
						placeholder="Filter columns"
						style={{
							borderBottom: 'none',
							padding: '0 16px',
						}}
						value={columnFilterTerm}
						variant="standard"
					/>

					<List
						dense
						style={{
							maxWidth: showMobile ? '100%' : 300,
						}}
					>
						{filteredFields.map(searchableField => {
							const { field, text } = searchableField;
							const selected = field === searchColumn;

							return (
								<ListItemButton
									key={field}
									onClick={() => {
										setColumnTargetAnchor(null);

										setColumnFilterTerm('');

										onColumnChange(selected ? null : field);

										optionChange.current = true;
									}}
									selected={selected}
								>
									<ListItemIcon style={{ minWidth: 29 }}>
										{selected && <SelectedIcon />}
									</ListItemIcon>
									<ListItemText
										primary={text}
										primaryTypographyProps={{
											style: {
												fontWeight: 'bold',
												textTransform: 'capitalize',
											},
										}}
									/>
								</ListItemButton>
							);
						})}
					</List>

					{!(filteredFields || []).length && (
						<BtNoItems
							message={
								columnFilterTerm
									? 'No columns match your filter'
									: 'No columns to select'
							}
							style={{ height: 'inherit', marginBottom: 16 }}
							typographyProps={{ style: { fontSize: 14 } }}
						/>
					)}
				</div>
			</BtPopover>
		</>
	);
}

TableSearch.defaultProps = {
	debounceInput: true,
	debounceTimeMs: 350,
	exactness: STANDARD,
};

TableSearch.propTypes = {
	debounceInput: PropTypes.bool,
	debounceTimeMs: PropTypes.number,
	disableExactnessOptions: PropTypes.bool,
	disableSearchColumnOptions: PropTypes.bool,
	exactness: PropTypes.oneOf([LOOSE, STANDARD, STRICT]).isRequired,
	hideExactness: PropTypes.bool,
	hideSearchColumn: PropTypes.bool,
	onColumnChange: PropTypes.func.isRequired,
	onExactnessChange: PropTypes.func.isRequired,
	onTermChange: PropTypes.func.isRequired,
	searchableFields: PropTypes.arrayOf(
		PropTypes.shape({
			field: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		})
	).isRequired,
	searchColumn: PropTypes.string,
	searchTerm: PropTypes.string.isRequired,
};
