import _ from 'lodash';

import { stageTypeDefaultQuery } from './components/stages/utils.js';
import { flattenSchema, pipelineSchema } from 'mongodb_query/src';

export const emptyConfigSchema = () => ({ type: 'object', objectContent: {} });

const getDefaultValues = () => ({
	collection: [],
	configSchema: emptyConfigSchema(),
	query: [],
});

export function getStageType(query) {
	return Object.keys(query).filter(p => p[0] === '$')[0] || '';
}

export const stagesAreEquivalent = (s1, s2) => {
	return _.isEqual(s1, s2);
};

export function queryBuilderReducerInit(data) {
	let { configSchema, collection, query } = getDefaultValues();

	if (data.configSchema) {
		try {
			if (typeof data.configSchema === 'string') {
				configSchema = JSON.parse(data.configSchema);
			} else if (typeof data.configSchema === 'object') {
				configSchema = data.configSchema;
			}
		} catch (e) {
			// we just leave it as the default
		}
	}

	if (data.collection) {
		try {
			if (typeof data.collection === 'string') {
				collection = JSON.parse(data.collection);
			} else if (Array.isArray(data.collection)) {
				collection = data.collection;
			}
		} catch (e) {
			// we just leave it as the default
		}
	}

	if (data.query) {
		try {
			if (typeof data.query === 'string') {
				query = JSON.parse(data.query);
			} else if (Array.isArray(data.query)) {
				query = data.query;
			}
		} catch (e) {
			// we just leave it as the default
		}
	}

	const stages = {};
	const stageOrder = [];

	if (Array.isArray(query)) {
		query.forEach((v, k) => {
			stages[k] = {
				id: k,
				query: v,
				queryStr: JSON.stringify(v),
				deleted: false,
				error: null,
				type: getStageType(v),
			};
			stageOrder.push(k);
		});
	}

	const initState = queryBuilderReducer(
		{
			inputCollection: collection,
			inputConfigSchema: configSchema,
			flatInputConfigSchema: flattenSchema(configSchema),
			inputCollectionStr: JSON.stringify(collection),
			inputConfigSchemaStr: JSON.stringify(configSchema),
			stages: stages,
			stageOrder: stageOrder,
			nextId: stageOrder.length,
			disableCollection: !!data.disableCollection,
		},
		{ action: 'recalc' }
	);

	return initState;
}

// export function queryBuilderReducer(state, data) {
// 	const result = queryBuilderReducerProcessing(state, data);

// 	console.log(
// 		'received: ' +
// 			(data.id !== undefined ? '(from ' + data.id + ') ' : '') +
// 			data.action,
// 		data,
// 		state,
// 		result
// 	);

// 	return result;
// }
// function queryBuilderReducerProcessing(state, data) {
export function queryBuilderReducer(state, data) {
	const { id: stageId, action, ...actionData } = data;

	if (action === 'init') {
		return queryBuilderReducerInit({
			collection: state.inputCollection,
			configSchema: state.inputConfigSchema,
			query: state?.result?.query,
			disableCollection: state.disableCollection,
			// overwrite these if they are passed into the dispatch actionData
			...actionData,
		});
	}

	const {
		inputCollection,
		flatInputConfigSchema,
		inputConfigSchema,
		stages,
		nextId,
		stageOrder,
	} = state;

	const newStageOrder = [...stageOrder];
	const newStages = { ...stages };

	let stagePos = newStageOrder.findIndex(s => s === stageId);

	if (!stagePos && !action === 'add') {
		return state;
	}

	const stage =
		action.indexOf('add') === 0
			? {
					query: actionData.type
						? stageTypeDefaultQuery(actionData.type)
						: {},
					id: nextId,
					type: actionData.type || '',
			  }
			: { ...stages[stageId] };

	let addedElement = false;
	let reOrdered = true;
	let dataIsOutdated = false;

	switch (action) {
		case 'recalc': {
			if (newStageOrder.length < 0) {
				return state;
			}
			stagePos = 0;
			dataIsOutdated = true;
			break;
		}
		case 'addAfter':
		case 'addBefore':
		case 'add': {
			let position = actionData.position;
			if (action === 'addBefore') {
				position = stagePos;
			} else if (action === 'addAfter') {
				position = stagePos + 1;
			}

			if (position > -1 && position < newStageOrder.length) {
				stagePos = position;
				newStageOrder.splice(position, 0, nextId);
			} else {
				stagePos = newStageOrder.length;
				newStageOrder.push(nextId);
			}

			newStages[nextId] = stage;
			addedElement = true;
			reOrdered = true;
			break;
		}
		case 'duplicate': {
			const queryStr = JSON.stringify(stage.query);
			const dupedStage = {
				id: nextId,
				type: stage.type,
				query: JSON.parse(queryStr),
				queryStr: queryStr,
			};
			newStageOrder.splice(stagePos + 1, 0, nextId);

			newStages[nextId] = dupedStage;
			stagePos = stagePos + 1;
			addedElement = true;
			reOrdered = true;
			dataIsOutdated = true;
			break;
		}

		case 'clearType': {
			stage.oldType = stage.type;
			stage.type = '';

			newStages[stageId] = stage;
			break;
		}
		case 'setType': {
			// if the user has not changed back to the same type
			if (!stage.query || stage.oldType !== actionData.type) {
				stage.query = stageTypeDefaultQuery(actionData.type);
				stage.queryStr = JSON.stringify(stage.query);
				// collectionIsDirty = true;
			}

			stage.type = actionData.type;
			delete stage.oldType;

			newStages[stageId] = stage;
			dataIsOutdated = true;
			break;
		}
		case 'update': {
			stage.query = actionData.query;
			stage.queryStr = JSON.stringify(stage.query);
			delete stage.error;

			newStages[stageId] = stage;
			dataIsOutdated = true;
			break;
		}
		case 'updateStr':
			stage.queryStr = actionData.queryStr;
			delete stage.error;

			try {
				stage.query = JSON.parse(stage.queryStr);
			} catch (e) {
				stage.error = e;
				newStages[stageId] = stage;
				return { ...state, stages: newStages };
			}

			newStages[stageId] = stage;
			dataIsOutdated = true;
			break;
		// case 'deleteUndo': {
		// 	delete stage.deleted;

		// 	newStages[stageId] = stage;
		// 	dataIsOutdated = true;
		// 	break;
		// }
		// case 'deleteDisable':
		// 	stage.deleted = true;

		// 	newStages[stageId] = stage;
		// 	dataIsOutdated = true;
		// 	break;
		case 'deleteDelete':
			if (stagePos < 0 || stagePos >= newStageOrder.length) {
				return state;
			}

			// if (!newStages[stageId].deleted) {
			dataIsOutdated = true;
			// }

			delete newStages[stageId];

			newStageOrder.splice(stagePos, 1);

			// if (!dataIsOutdated) {
			// 	return {
			// 		...state,
			// 		stages: newStages,
			// 		stageOrder: newStageOrder,
			// 	};
			// }
			break;

		case 'moveTo': {
			if (stagePos < 0 || actionData.position === stagePos) {
				return state;
			}

			const a = newStageOrder[stagePos];
			newStageOrder.splice(stagePos, 1);

			if (actionData.position > newStageOrder.length) {
				newStageOrder.push(a);
			} else {
				newStageOrder.splice(
					actionData.position > stagePos
						? actionData.position - 1
						: actionData.position,
					0,
					a
				);
			}

			stagePos = Math.min(actionData.position, stagePos);
			reOrdered = true;
			dataIsOutdated = true;
			break;
		}
		case 'moveUp': {
			if (stagePos < 0) {
				return state;
			}
			const a = newStageOrder[stagePos - 1];
			newStageOrder[stagePos - 1] = stageId;
			newStageOrder[stagePos] = a;

			stagePos--;
			reOrdered = true;
			dataIsOutdated = true;
			break;
		}
		case 'moveDown': {
			if (stagePos > newStages.length - 1) {
				return state;
			}
			const a = newStageOrder[stagePos + 1];
			newStageOrder[stagePos + 1] = stageId;
			newStageOrder[stagePos] = a;

			reOrdered = true;
			dataIsOutdated = true;
			break;
		}
		// async collection data stages
		case 'collectionRequested': {
			stage.collectionLoading = true;
			delete stage.collectionError;
			delete stage.collectionIsDirty;

			newStages[stageId] = stage;

			return {
				...state,
				stages: newStages,
			};
		}
		case 'collectionLoaded': {
			delete stage.collectionLoading;
			delete stage.collectionError;

			stage.collection = actionData.collection || [];
			if (actionData.error) {
				stage.collectionError = actionData.error;
			}

			newStages[stageId] = stage;
			break;
		}
		case 'configSchemaRequested': {
			stage.configSchemaLoading = true;
			delete stage.configSchemaError;
			delete stage.configSchemaIsDirty;

			newStages[stageId] = stage;

			return {
				...state,
				stages: newStages,
			};
		}
		case 'configSchemaLoaded': {
			delete stage.configSchemaLoading;
			delete stage.configSchemaError;

			stage.configSchema = actionData.configSchema || emptyConfigSchema();
			if (actionData.error) {
				stage.configSchemaError = actionData.error;
			}

			newStages[stageId] = stage;
			break;
		}
		default:
			return state;
	}

	/*
		// stage loading stages
		collectionLoading
		configSchemaLoading
		inputLoading
		resultLoading

		// stage error stages
		collectionError
		configSchemaError

		// stage needs loading
		collectionIsDirty
		configSchemaIsDirty

		// ready
		inputReady
		resultReady
	*/

	let lastValidPos = -1;
	let finalResult = null;

	for (let pos = 0; pos < newStageOrder.length; pos++) {
		const stageId = newStageOrder[pos];
		const stage = { ...newStages[stageId] };

		if (stage.type === '' && !stage.oldType) {
			continue;
		}

		const prevStage =
			pos > 0 ? newStages[newStageOrder[lastValidPos]] : null;
		lastValidPos = pos;

		finalResult = stage;

		if (pos < stagePos) {
			// this stage is not affected by the action
			continue;
		}

		if (prevStage) {
			// input is the previous stage

			if (prevStage.resultReady) {
				const inputC = prevStage.result.collection;
				const inputCS = prevStage.result.configSchema;

				stage.inputReady = true;
				stage.inputLoading = false;

				stage.input = { ...stage.input, stageId: prevStage.id };

				if (stage.input?.collection !== inputC) {
					stage.input = {
						...(stage.input || {}),
						collection: inputC,
						collectionStr: JSON.stringify(inputC),
					};
				}

				if (stage.input?.configSchema !== inputCS) {
					stage.input = {
						...(stage.input || {}),
						configSchema: inputCS,
						flatConfigSchema: flattenSchema(inputCS),
						configSchemaStr: JSON.stringify(inputCS),
					};
				}

				stage.inputError = false;
			} else {
				stage.inputReady = false;
				stage.inputLoading = prevStage.resultLoading;
				stage.inputError =
					prevStage.configSchemaError || prevStage.collectionError;
			}
		} else {
			stage.input = {
				collection: inputCollection,
				collectionStr: state.inputCollectionStr,
				configSchema: inputConfigSchema,
				flatConfigSchema: flatInputConfigSchema,
				configSchemaStr: state.inputConfigSchemaStr,
			};

			stage.inputReady = true;
			stage.inputLoading = false;
		}

		if (dataIsOutdated) {
			// mark stage for refresh
			stage.collectionIsDirty = true;
			stage.configSchemaIsDirty = true;
		}

		stage.resultReady =
			(state.disableCollection ||
				(stage.collection && !stage.collectionIsDirty)) &&
			stage.configSchema &&
			!stage.configSchemaIsDirty;
		true;
		stage.resultLoading =
			(!state.disableCollection && stage.collectionLoading) ||
			stage.configSchemaLoading ||
			false;

		if (stage.collection && stage.result?.collection !== stage.collection) {
			stage.result = {
				...(stage.result || {}),
				collection: stage.collection,
				collectionStr: JSON.stringify(stage.collection),
			};
		}

		if (
			(stage.configSchema &&
				stage.result?.configSchema !== stage.configSchema) ||
			stagePos < pos
		) {
			stage.result = {
				...(stage.result || {}),
				configSchema: stage.configSchema,
				configSchemaStr: JSON.stringify(stage.configSchema),
			};
		}

		if (stage.result?.query !== stage.query || stagePos < pos) {
			stage.result = {
				...(stage.result || {}),
				query: stage.query,
				queryStr: stage.queryStr,
				fullQuery: prevStage
					? prevStage.result.fullQuery.concat([stage.query])
					: [stage.query],
			};
		}

		newStages[stageId] = stage;
	}

	const fullResult = {
		...state.result,
	};

	// empty query
	if (!finalResult) {
		fullResult.configSchema = inputConfigSchema;
		fullResult.configSchemaStr = state.inputConfigSchemaStr;
		fullResult.query = [];
		fullResult.queryStr = '[]';
		fullResult.variables = {};
	} else if (
		fullResult.query !== finalResult?.result?.fullQuery ||
		fullResult.configSchema !== finalResult?.result?.configSchema
	) {
		fullResult.query = finalResult?.result?.fullQuery || [];
		fullResult.queryStr = finalResult?.result?.fullQuery
			? JSON.stringify(finalResult.result.fullQuery)
			: '[]';
		fullResult.configSchema = finalResult?.result?.configSchema
			? finalResult.result.configSchema
			: null;
		fullResult.configSchemaStr = finalResult?.result?.configSchemaStr
			? finalResult.result.configSchemaStr
			: fullResult.configSchema
				? JSON.stringify(fullResult.configSchema)
				: null;
	}

	return {
		...state,
		nextId: addedElement ? nextId + 1 : nextId,
		stageOrder: reOrdered ? newStageOrder : stageOrder,
		stages: newStages,
		result: fullResult,
	};
}

export function traverseAndReplaceVariables(obj, variables) {
	if (!obj || !variables?.length) {
		return obj;
		// null has typeof 'object'
	}

	if (typeof obj === 'string') {
		if (obj.length > 0 && obj[0] === '#' && variables) {
			let idx = variables.findIndex(v => obj.slice(1, -1) === v.key);
			if (idx > -1) {
				return variables[idx].value;
			}
		}

		return obj;
	}

	if (Array.isArray(obj)) {
		return obj.map(e => traverseAndReplaceVariables(e, variables));
	}

	if (typeof obj === 'object' && !(obj instanceof Date)) {
		const o = { ...obj };

		for (const key in o) {
			if (Object.hasOwnProperty.call(o, key)) {
				o[key] = traverseAndReplaceVariables(o[key], variables);
			}
		}

		return o;
	}

	return obj;
}

export async function handleConfigSchemaTransform(
	configSchema,
	query,
	variables
) {
	if (variables) {
		// $$lowercase is the syntax for a user variables
		// replace the variable throughout the query with it, then when running
		// the configSchema transform we can pass the same variables map as the transform
		// user variables options.

		const q = traverseAndReplaceVariables(
			query,
			variables.map(v => ({
				...v,
				value: '$$var' + v.key,
			}))
		);

		const userVariables = {
			variables: variables.map(v => ({
				schema: v.configSchema,
				key: 'var' + v.key,
			})),
		};

		return await pipelineSchema(configSchema, q, userVariables);
	}

	return await pipelineSchema(configSchema, query);
}

export function getVariablesInUse(obj, variables, inUseVariables = {}) {
	if (!obj || !variables?.length) {
		return inUseVariables;
		// null has typeof 'object'
	}

	if (typeof obj === 'string') {
		if (obj.length > 0 && obj[0] === '#' && variables) {
			let idx = variables.findIndex(v => obj.slice(1, -1) === v.key);
			if (idx > -1) {
				inUseVariables[variables[idx].key] = true;
			}
		}

		return inUseVariables;
	}

	if (Array.isArray(obj)) {
		obj.map(e => getVariablesInUse(e, variables, inUseVariables));

		return inUseVariables;
	}

	if (typeof obj === 'object' && !(obj instanceof Date)) {
		const o = { ...obj };

		for (const key in o) {
			if (Object.hasOwnProperty.call(o, key)) {
				getVariablesInUse(o[key], variables, inUseVariables);
			}
		}
		return inUseVariables;
	}

	return inUseVariables;
}
