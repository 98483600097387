import { styled } from '@mui/material';
import Color from 'color';

const CollapseContent = styled('div')(({ hideDivider, theme }) => ({
	marginBottom: hideDivider ? 0 : '1em',
	padding: '0.5em 0',

	'&': {
		borderBottom: hideDivider
			? '1px solid transparent'
			: `1px solid ${Color(theme.palette.text.primary)
					.alpha(0.2)
					.toString()}`,
	},
}));

export default CollapseContent;
