import PropTypes from 'prop-types';
import { TileContextProvider } from '../TileContext';
import { NativeTileRenderItem } from './NativeTileRenderItem';

export default function NativeTile({
	tile,
	handleDeleteTile,
	onTileChange,
	screenData,
	onContainerChange,
}) {
	return (
		<TileContextProvider
			tileData={tile}
			screenData={screenData}
			handleDeleteTile={handleDeleteTile}
			onTileChange={onTileChange}
			onContainerChange={onContainerChange}
		>
			<NativeTileRenderItem />
		</TileContextProvider>
	);
}

NativeTile.propTypes = {
	tile: PropTypes.object.isRequired,
	handleDeleteTile: PropTypes.func.isRequired,
	onTileChange: PropTypes.func.isRequired,
	onContainerChange: PropTypes.func.isRequired,
	screenData: PropTypes.object.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};
