import { setMinutes } from 'date-fns';
import _ from 'lodash';

export const commonRangesData = [
	{
		id: 'today',
		label: 'Today',
		tense: 'this',
		duration: 1,
		unit: 'days',
		whole: true,
	},
	{
		id: 'this_week',
		label: 'This week',
		tense: 'this',
		duration: 1,
		unit: 'weeks',
		whole: false,
	},
	{
		id: 'last_quarter',
		label: 'Last quarter',
		tense: 'last',
		duration: 1,
		unit: 'quarters',
		whole: true,
	},
	{
		id: 'this_quarter',
		label: 'This quarter',
		tense: 'this',
		duration: 1,
		unit: 'quarters',
		whole: false,
	},
	{
		id: 'last_15_minutes',
		label: 'Last 15 minutes',
		tense: 'last',
		duration: 15,
		unit: 'minutes',
		whole: false,
	},
	{
		id: 'last_30_minutes',
		label: 'Last 30 minutes',
		tense: 'last',
		duration: 30,
		unit: 'minutes',
		whole: false,
	},
	{
		id: 'last_1_hour',
		label: 'Last 1 hour',
		tense: 'last',
		duration: 1,
		unit: 'hours',
		whole: false,
	},
	{
		id: 'last_24_hours',
		label: 'Last 24 hours',
		tense: 'last',
		duration: 24,
		unit: 'hours',
		whole: false,
	},
	{
		id: 'last_week',
		label: 'Last week',
		tense: 'last',
		duration: 1,
		unit: 'weeks',
		whole: true,
	},
	{
		id: 'last_month',
		label: 'Last month',
		tense: 'last',
		duration: 1,
		unit: 'months',
		whole: true,
	},
	{
		id: 'last_90_days',
		label: 'Last 90 days',
		tense: 'last',
		duration: 90,
		unit: 'days',
		whole: false,
	},
	{
		id: 'last_1_year',
		label: 'Last 1 year',
		tense: 'last',
		duration: 1,
		unit: 'years',
		whole: false,
	},
	// quarters
];

export const timeUnits = [
	{ id: 's', label: 'second', labelPlural: 'seconds' },
	{ id: 'm', label: 'minute', labelPlural: 'minutes' },
	{ id: 'h', label: 'hour', labelPlural: 'hours' },
	{ id: 'd', label: 'day', labelPlural: 'days' },
	{ id: 'w', label: 'week', labelPlural: 'weeks' },
	{ id: 'M', label: 'month', labelPlural: 'months' },
	{ id: 'q', label: 'quarters', labelPlural: 'quarters' },
	{ id: 'y', label: 'year', labelPlural: 'years' },
];

export const timeTenses = [
	{ id: 'last', label: 'last' },
	{ id: 'next', label: 'next' },
	{ id: 'this', label: 'this' },
];

export const weekdays = {
	SUNDAY: 0,
	MONDAY: 1,
	TUESDAY: 2,
	WEDNESDAY: 3,
	THURSDAY: 4,
	FRIDAY: 5,
	SATURDAY: 6,
};

export const timeFormat = 'HH:mm';

export const timePickerUnits = {
	// h: _.range(0, 24),
	// m: _.range(0, 60, 5),
	// s: [],
	minute_resolution: 5,
	m: [
		{ value: 0, label: '00' },
		{ value: 5, label: '05' },
		{ value: 10, label: '10' },
		{ value: 15, label: '15' },
		{ value: 20, label: '20' },
		{ value: 25, label: '25' },
		{ value: 30, label: '30' },
		{ value: 35, label: '35' },
		{ value: 40, label: '40' },
		{ value: 45, label: '45' },
		{ value: 50, label: '50' },
		{ value: 55, label: '55' },
	],
	h: [
		{ value: 0, label: '00' },
		{ value: 1, label: '01' },
		{ value: 2, label: '02' },
		{ value: 3, label: '03' },
		{ value: 4, label: '04' },
		{ value: 5, label: '05' },
		{ value: 6, label: '06' },
		{ value: 7, label: '07' },
		{ value: 8, label: '08' },
		{ value: 9, label: '09' },
		{ value: 10, label: '10' },
		{ value: 11, label: '11' },
		{ value: 12, label: '12' },
		{ value: 13, label: '13' },
		{ value: 14, label: '14' },
		{ value: 15, label: '15' },
		{ value: 16, label: '16' },
		{ value: 17, label: '17' },
		{ value: 18, label: '18' },
		{ value: 19, label: '19' },
		{ value: 20, label: '20' },
		{ value: 21, label: '21' },
		{ value: 22, label: '22' },
		{ value: 23, label: '23' },
	],
};
