import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import ButtonBase from '@mui/material/ButtonBase';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { dotGetInSchema } from 'mongodb_query/src/schema/utils';

import DataConfig from '../DataConfig/DataConfig';

import dot from 'dot-object';
import DataType from '../DataTypes/DataType';
import { FormControl, FormHelperText, styled } from '@mui/material';
import { BoxCutterOff } from 'mdi-material-ui';

export default function BtDataConfigNodeSelect(props) {
	const {
		label,
		schema,
		onChange,
		value,
		onFocus,
		onBlur,
		disabled,
		helperText,
		fullWidth,
		error,
	} = props;

	const [selectedLocation, setSelectedLocation] = useState(null);
	const [selectedNode, setSelectedNode] = useState(value || null);

	const [selectedSchemaItem, setSelectedSchemaItem] = useState(
		(value && dotGetInSchema(schema, value)) || null
	);

	useEffect(
		() => {
			const addNodeString = (currentString, newNode) => {
				let newString = '';
				if (currentString !== '') {
					newString = currentString + '.';
				}
				return newString + newNode;
			};

			if (value && schema) {
				let currentNode = schema;
				let newLocation = '';

				const nodes = value.split('.');

				nodes.forEach(node => {
					if (currentNode.type === 'object') {
						newLocation = addNodeString(
							newLocation,
							'objectContent'
						);
					} else if (currentNode.type === 'array') {
						newLocation = addNodeString(
							newLocation,
							'arrayContent'
						);
					}
					newLocation = addNodeString(newLocation, node);
				});

				setSelectedLocation(newLocation);
			}
		},
		[value, schema]
	);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [minWidth, setMinWidth] = React.useState(null);

	const [focused, setFocused] = React.useState(null);

	const handleFocus = event => {
		setFocused(true);
		onFocus?.(event);
	};

	const handleBlur = event => {
		if (!open) {
			setFocused(false);
			onBlur?.(event);
		}
	};

	const handleClick = event => {
		setMinWidth(event.currentTarget.offsetWidth);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = event => {
		anchorEl.focus();
		setAnchorEl(null);
	};

	const onNodeSelect = newSelection => {
		handleClose();

		const schemaNode = dot.pick(newSelection, schema);

		let outputString = newSelection
			.replaceAll('objectContent.', '')
			.replaceAll('arrayContent.', '');

		setSelectedNode(outputString);

		setSelectedSchemaItem(schemaNode);

		if (onChange) {
			let outputString = newSelection
				.replaceAll('objectContent.', '')
				.replaceAll('arrayContent.', '');
			onChange(outputString);
		}
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<FormControl
			disabled={disabled}
			error={!!error}
			style={fullWidth ? { width: '100%' } : null}
			variant="standard"
			sx={{ overflow: 'hidden' }}
		>
			{label && (
				<DCNLabel
					shrink={!!(open || selectedNode || focused)}
					id={id}
					focused={focused}
				>
					{label}
				</DCNLabel>
			)}

			<InputBase
				variant="standard"
				slots={{ root: RenderValue }}
				aria-describedby={id}
				onClick={handleClick}
				disabled={!schema}
				// non-standard props
				selectedNode={selectedNode}
				selectedSchemaItem={selectedSchemaItem}
				hasLabel={!!label}
				menuOpen={open}
				hasFocus={focused}
				// className="MuiInputBase-root MuiInput-root"
				slotProps={{
					root: {
						onFocus: handleFocus,
						onBlur: handleBlur,
					},
				}}
			/>

			{!!schema && (
				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
				>
					<Box
						sx={{
							maxHeight: '600px',
							pt: 1,
							minWidth: minWidth,
						}}
					>
						<DataConfig
							readOnly={true}
							config={schema}
							onNodeSelect={onNodeSelect}
							nodeSelectable={true}
							selectedNode={selectedLocation}
						/>
					</Box>
				</Popover>
			)}
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	);
}

const DCNLabel = styled(InputLabel, {
	shouldForwardProp: prop => prop !== 'focused',
})(({ theme, focused, error }) => ({
	...(focused &&
		!error && {
			color: theme.palette.primary.main,
		}),
}));

const DCNSInput = styled(Box, {
	shouldForwardProp: prop => prop !== 'hasLabel' && prop !== 'hasFocus',
})(({ theme, open, variant, hasLabel, hasFocus }) => ({
	boxSizing: 'content-box',
	position: 'relative',
	width: '100%',
	margin: 0,
	marginTop: hasLabel ? '16px' : 0,

	whiteSpace: 'nowrap',
	overflow: 'hidden',

	minHeight: '1em',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-start',
	flexDirection: 'row',

	//marginTop: label ? 2 : 0,

	...((!variant || variant === 'standard') && {
		...(hasFocus && {
			backgroundColor:
				theme.mode === 'dark'
					? 'rgba(255, 255, 255, 0.05)'
					: 'rgba(0, 0, 0, 0.05)',
		}),
		'&.Mui-focusVisible': {
			backgroundColor:
				theme.mode === 'dark'
					? 'rgba(255, 255, 255, 0.05)'
					: 'rgba(0, 0, 0, 0.05)',
		},
		'&:before': {
			borderBottomWidth: '1px',
			borderBottomStyle: 'solid',
			borderBottomColor: theme.palette.text.secondary,
			left: 0,
			bottom: 0,
			content: '"\\00a0"',
			position: 'absolute',
			right: 0,
			transition:
				'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
			pointerEvents: 'none',
			boxSizing: 'border-box',
		},

		'&:after': {
			borderBottomWidth: '2px',
			borderBottomStyle: 'solid',
			borderBottomColor: theme.palette.primary.main,
			left: 0,
			bottom: 0,
			content: '"\\00a0"',
			position: 'absolute',
			right: 0,
			transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
			pointerEvents: 'none',
			transform:
				open || hasFocus ? 'scaleX(1) translateX(0)' : 'scaleX(0)',
			boxSizing: 'border-box',
		},
		'&.Mui-focusVisible:after': {
			transform: 'scaleX(1) translateX(0)',
		},
		'&:hover:before': {
			borderBottomWidth: '2px',
		},
		'&.Mui-error:before, &.Mui-error:after': {
			borderBottomColor: theme.palette.error.main,
		},
		'&:not(.Mui-disabled, .Mui-error, .Mui-focusVisible):hover:after': {
			borderBottomColor: theme.palette.text.primary,
		},
		...(open && {
			backgroundColor: 'rgba(255, 255, 255, 0.05)',
		}),
	}),
}));

const RenderValue = forwardRef(function RenderValue(props, ref) {
	const { menuOpen, selectedNode, selectedSchemaItem, ...rest } = props;

	return (
		<DCNSInput
			component={ButtonBase}
			disableRipple
			tabIndex={0}
			ref={ref}
			open={menuOpen}
			className={menuOpen ? 'Mui-focusVisible' : ''}
			{...rest}
		>
			<Box
				sx={{
					paddingTop: '4px',
					paddingBottom: '5px',
					paddingRight: '24px',
					minWidth: '16px',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'flex-start',
				}}
			>
				{selectedNode && (
					<DataType type={selectedSchemaItem?.type || 'null'} />
				)}

				<Typography
					sx={{
						textTransform: selectedSchemaItem?.label
							? 'capitalize'
							: 'inherit',
						whiteSpace: 'nowrap',
						marginLeft: '6px',
						textOverflow: 'ellipsis',
						overflow: 'hidden',
					}}
					component="div"
				>
					{selectedSchemaItem?.label || selectedNode || <>&nbsp;</>}
				</Typography>
			</Box>

			<Box
				sx={{
					mt: '-2px',
					mr: '-2px',
					height: '1em',
					width: '1em',
					top: 'calc(50% - 0.5em)',
					right: 0,
					position: 'absolute',
					flexShrink: 1,
					display: 'inline-block',
				}}
				color="text.secondary"
				component={menuOpen ? ArrowDropUpIcon : ArrowDropDownIcon}
			/>
		</DCNSInput>
	);
});

RenderValue.propTypes = {
	selectedSchemaItem: PropTypes.shape({
		type: PropTypes.string,
		label: PropTypes.node,
	}),
	selectedNode: PropTypes.string,
	menuOpen: PropTypes.bool,
	hasLabel: PropTypes.bool,
};

BtDataConfigNodeSelect.propTypes = {
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	schema: PropTypes.object,
	label: PropTypes.node,
	onChange: PropTypes.func,
	value: PropTypes.string,
	variant: PropTypes.string,
	disabled: PropTypes.bool,
	fullWidth: PropTypes.bool,
	error: PropTypes.bool,
	helperText: PropTypes.node,

	// fetching: PropTypes.bool,
	// isRequired: PropTypes.bool,
	// label: PropTypes.string,
};
