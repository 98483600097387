import React from 'react';
import * as yup from 'yup';

import BtQueryBuilderPropertyFormField from '../BtQueryBuilderPropertyField';
import { baseStagePropTypes } from './base-prop-type';
import { determineValueType, replaceDynamicTypedValue } from './utils';

const stageType = '$unwind';

const formSchema = yup.object().shape({
	path: yup.string().required(),
	path_type: yup.string('field-path'),
	includeArrayIndex: yup.string().nullable(),
});

function buildFormValues(queryStage, flatConfigSchema, variables) {
	const path =
		typeof queryStage[stageType] === 'string'
			? queryStage[stageType]
			: queryStage[stageType]?.path;

	const [_st_type, st_value] = determineValueType(path, flatConfigSchema);

	let values = {
		path: st_value ?? null,
		path_type: 'field-path',
		includeArrayIndex:
			typeof queryStage[stageType] !== 'string'
				? queryStage[stageType]?.includeArrayIndex || null
				: null,
		preserveNullAndEmptyArrays:
			typeof queryStage[stageType] !== 'string'
				? queryStage[stageType]?.includeArrayIndex || null
				: null,
		preserveNullAndEmptyArrays_type: 'static_string',
	};

	return {
		...values,
		preserveNullAndEmptyArrays_type:
			values.preserveNullAndEmptyArrays === null
				? 'static_null'
				: 'static_boolean',
		includeArrayIndex:
			values.includeArrayIndex === null ? 'static_null' : 'static_string',
	};
}

function buildQueryStage(fields) {
	return {
		[stageType]: replaceDynamicTypedValue('path', fields),
		// [stageType]: {
		// 	path: fields.path + '',
		// 	includeArrayIndex:
		// 		typeof fields.includeArrayIndex === 'string'
		// 			? fields.includeArrayIndex
		// 			: null,
		// },
	};
}

function UnwindFormContent(props) {
	const { disabled, readOnly } = props;

	return (
		<>
			<BtQueryBuilderPropertyFormField
				name="path"
				allowedConfigSchemaTypes={['array']}
				label="Target"
				isRequired
				disabled={disabled}
				allowedFieldTypes={['field-path']}
				readOnly={readOnly}
			/>
			{/* <BtQueryBuilderPropertyField
				name="includeArrayIndex"
				label="Set Array Index as"
				asPath
				flatConfigSchema={configSchema}
				disabled={disabled}
			/> */}
		</>
	);
}

export default {
	formSchema: formSchema,
	FormContent: UnwindFormContent,
	buildQueryStage: buildQueryStage,
	buildFormValues: buildFormValues,
};

UnwindFormContent.propTypes = baseStagePropTypes;
