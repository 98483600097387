import React from 'react';
import { ContextManagerProvider } from './context/ContextManager';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
// import './style/App.css';

// THEME
import { ThemeContextProvider } from './context/ThemeContext';

import CssBaseline from '@mui/material/CssBaseline';

import MainPage from './AppStructureComponents/MainPage';
import ScrollToTop from './components/generic/ScrollToTop';

export default function App() {
	return (
		<div>
			<Router>
				<ThemeContextProvider>
					<SnackbarProvider maxSnack={1} autoHideDuration={4000}>
						<CssBaseline />
						<ContextManagerProvider>
							<ScrollToTop />
							<MainPage />
						</ContextManagerProvider>
					</SnackbarProvider>
				</ThemeContextProvider>
			</Router>
		</div>
	);
}
