import React, { useEffect, useMemo, useState } from 'react';

import admin_nav_item from '../AdminNavItem';
import admin_options from '../AdminOptions';
import {
	a11yProps,
	BtTab,
	BtTabBar,
	BtTabPanel,
	BtTabView,
} from '../../../components/generic/BtTabView';
import BtError from '../../../components/generic/BtError';
import { BtFormContainer } from '../../../components/generic/forms';
import BtLoading from '../../../components/generic/BtLoading';
import BtOverview from '../../../components/generic/BtOverview';
import RoleDetails from './RoleDetails';
import { roleGet } from '../../../API/role.api';
import RolePermissions from './RolePermissions';
import RoleUsers from './RoleUsers';
import Typography from '@mui/material/Typography';
import useFetch from '../../../hooks/useFetch';
import { useNavContext } from '../../../context/ContextManager';
import { userAdminGetList } from '../../../API/user_admin';
import BtManagedBy from '../../../components/generic/BtManagedBy';

export default function RoleEditor({ match }) {
	const [currentTab, setCurrentTab] = useState(0);
	const [role, setRole] = useState(null);

	const roleId = useMemo(() => match.params.id, [match]);

	const {
		loading: loadingRole,
		error: roleError,
		request: getRole,
	} = useFetch(roleGet, setRole);
	const {
		data: userList,
		error: userError,
		loading: loadingUsers,
		request: getUsers,
	} = useFetch(userAdminGetList);

	useEffect(
		() => {
			if (roleId && !role) {
				getRole({ roleUuid: roleId });
			}
		},
		[getRole, role, roleId]
	);

	useEffect(
		() => {
			if (!userList) {
				getUsers();
			}
		},
		[getUsers, userList]
	);

	const platformManaged = useMemo(() => role?.managedBy === 'Platform', [
		role,
	]);

	// Set contextual navigation items and breadcrumbs
	const { setContextualNav, setBreadcrumbs } = useNavContext();
	useEffect(
		() => {
			setContextualNav([...admin_nav_item, ...admin_options]);
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'Admin', link: '/Admin' },
				{ text: 'Roles', link: '/Roles' },
				{ text: 'Edit Role', link: '' },
			]);
			return () => setContextualNav(null);
		},
		[setContextualNav, setBreadcrumbs]
	);

	if (!!roleError) {
		return (
			<BtError
				title="Retrieval Error"
				description={
					<>
						<Typography>
							An error occurred whilst trying to retrieve the
							role.
						</Typography>
						<Typography>{`(role id: ${roleId})`}</Typography>
					</>
				}
				action={() => getRole({ roleUuid: roleId })}
			/>
		);
	}

	return (
		<BtTabView>
			<BtFormContainer
				title={
					<div
						style={{
							alignItems: 'center',
							display: 'flex',
							flexWrap: 'wrap',
							justifyContent: 'space-between',
							width: '100%',
						}}
					>
						<Typography variant="h3">
							{role?.name || null}
						</Typography>
						{role?.managedBy && (
							<BtManagedBy
								managedBy={role?.managedBy}
								platformTooltip="You can only manage the user assignment for this role"
								organisationTooltip="You are free to edit all aspects of this role"
								style={{ margin: '4px 0' }}
							/>
						)}
					</div>
				}
				header={
					<BtLoading
						loading={loadingRole || loadingUsers}
						LoadingComponent={<div style={{ height: 50 }} />}
					>
						<BtTabBar
							currentTab={currentTab}
							onTabChange={(event, selectedTab) =>
								setCurrentTab(selectedTab)
							}
							style={{
								transform: 'translateY(-1px)',
								marginTop: 10,
							}}
						>
							<BtTab label="Details" {...a11yProps(0)} />
							<BtTab
								label={`Users${
									(role?.users || []).length > 0
										? ` (${role.users.length})`
										: ''
								}`}
								{...a11yProps(1)}
							/>
							<BtTab
								label={`Permissions${
									(role?.permissions || []).length > 0
										? ` (${role.permissions.length})`
										: ''
								}`}
								{...a11yProps(2)}
							/>
						</BtTabBar>
					</BtLoading>
				}
				style={{ maxWidth: 800 }}
			>
				<BtTabPanel currentTab={currentTab} index={0}>
					<BtOverview
						loading={loadingRole || loadingUsers}
						createdTimestamp={role?.create_timestamp}
						modifiedTimestamp={role?.modify_timestamp}
					>
						<div
							style={{
								display: 'flex',
								flexWrap: 'wrap',
							}}
						>
							<Typography
								variant="subtitle"
								style={{ marginRight: '2em' }}
							>
								{`${role?.users?.length || 0} user${
									role?.users?.length !== 1 ? 's' : ''
								} assigned`}
							</Typography>
							<Typography variant="subtitle">
								{`${role?.permissions?.length || 0} permission${
									role?.permissions?.length !== 1 ? 's' : ''
								} applied`}
							</Typography>
						</div>
					</BtOverview>
					<RoleDetails
						role={role}
						setRole={setRole}
						loading={loadingRole}
						platformManaged={platformManaged}
					/>
				</BtTabPanel>
				<BtTabPanel currentTab={currentTab} index={1}>
					<RoleUsers
						getUsers={getUsers}
						role={role}
						setRole={setRole}
						error={userError}
						userList={userList}
					/>
				</BtTabPanel>
				<BtTabPanel currentTab={currentTab} index={2}>
					<RolePermissions
						platformManaged={platformManaged}
						role={role}
						setRole={setRole}
						roleId={roleId}
					/>
				</BtTabPanel>
			</BtFormContainer>
		</BtTabView>
	);
}
