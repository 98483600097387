import React from 'react';

// import HomeIcon from '@mui/icons-material/Home';
// import TimelineIcon from '@mui/icons-material/Timeline';
import Dashboard from '@mui/icons-material/Dashboard';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import AssessmentIcon from '@mui/icons-material/Assessment';

const insights_options = [
	{
		id: 'Collections',
		description: 'Collections',
		text: 'Collections',
		route: '/InsightCollections',
		icon: <Dashboard />,
		// requiredPermissionsAll: {
		// 	insights: ['InsightsList'],
		// },
	},
	{
		id: 'Alerts',
		description: 'Alerts and Notifications',
		text: 'Alerts',
		route: '/Alerts',
		icon: <AnnouncementIcon />,
		requiredPermissionsAll: {
			insights: ['InsightsAlerts'],
		},
	},
	{
		id: 'Exports',
		description: 'Data Export Reports',
		text: 'Exports',
		route: '/InsightExports',
		icon: <AssessmentIcon />,
		requiredPermissionsAll: {
			insights: ['InsightsExportsView'],
		},
	},
];

export default insights_options;
