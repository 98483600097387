const yup = require('yup');

const organisationGetSchema = yup.object({
	uuid: yup
		.string()
		.uuid()
		.required(''),
	name: yup.string().default(''),
	initials: yup.string().default(''),
	tags: yup
		.array()
		.of(
			yup.object({
				uuid: yup
					.string()
					.uuid()
					.required(),
				name: yup.string().required(),
				displayColour: yup.string().required(),
				status: yup.string().required(),
			})
		)
		.required(),
	logo32: yup.string().nullable(),
});

const organisationGetListSchema = yup.array().of(
	yup.object({
		name: yup.string().default(''),
		uuid: yup
			.string()
			.uuid()
			.required(),
		logo32: yup.string().default(null),
		initials: yup.string().default('xyz'),
	})
);

module.exports = {
	organisationGetSchema,
	organisationGetListSchema,
};
