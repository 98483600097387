import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Skeleton from '@mui/material/Skeleton';

import BtQueryBuilderDialog from './BtQueryBuilderDialog';
import BtQueryPreview from './components/BtQueryPreview';
import { BtQueryBuilderDataProvider } from './context';
import BtQueryBuilderVariableProvider from './context/BtQueryBuilderVariableProvider';

export default function BtQueryField(props) {
	const {
		disabled,
		fetching,
		isRequired,
		label,
		type,
		defaultValue,
		value,
		inputQuery,
		onChange,
		loading,
		helperText,
		error,
		color,
		// data context props
		resourceGroup,
		resource,
		allowResourceGroupChange,
		allowResourceChange,

		// variable fields
		variables,
		variableConfigSchema,

		// everything else is for the builder
		...other
	} = props;

	const [builderOpen, setBuilderOpen] = useState(false);

	const [queryResults, setQueryResults] = useState({});
	const query =
		inputQuery ?? value ?? defaultValue ?? queryResults?.query ?? '[]';

	const handleChange = useCallback(
		(queryStr, result, reason) => {
			setBuilderOpen(false);

			if (reason !== 'cancel') {
				onChange(type ? result[type] : queryStr, result, reason);
				setQueryResults(result);
			}
		},
		[type, onChange, setBuilderOpen]
	);

	if (loading) {
		return (
			<Skeleton animation="wave" style={{ width: '100%', height: 45 }} />
		);
	}

	return (
		<BtQueryBuilderVariableProvider
			variables={variables}
			configSchema={variableConfigSchema}
		>
			<BtQueryBuilderDataProvider
				resourceGroup={resourceGroup}
				resource={resource}
				allowResourceGroupChange={allowResourceGroupChange}
				allowResourceChange={allowResourceChange}
			>
				<FormControl fullWidth error={!!error} color={color}>
					<FormLabel>
						{(label ? label : 'Query Field') +
							(!isRequired ? ' (optional)' : '')}
					</FormLabel>

					<BtQueryPreview
						query={query}
						onStageClick={() => setBuilderOpen(true)}
						onBlur={other.onBlur}
					/>

					<FormHelperText>{helperText}</FormHelperText>
				</FormControl>

				<BtQueryBuilderDialog
					onClose={handleChange}
					open={builderOpen}
					value={inputQuery ?? value}
					label={
						(label ? label : 'Query Field') +
						(!isRequired ? ' (optional)' : '')
					}
					disabled={loading || disabled || fetching}
					{...other}
				/>
			</BtQueryBuilderDataProvider>
		</BtQueryBuilderVariableProvider>
	);
}

const queryValuePropTypes = PropTypes.oneOfType([
	PropTypes.string,
	PropTypes.arrayOf(PropTypes.object),
]);

BtQueryField.propTypes = {
	...BtQueryBuilderDialog.propTypes,
	variables: BtQueryBuilderVariableProvider.propTypes.variables,
	variableConfigSchema: BtQueryBuilderVariableProvider.propTypes.configSchema,
	value: queryValuePropTypes,
	inputQuery: queryValuePropTypes,
	defaultValue: queryValuePropTypes,
	label: PropTypes.string,
	disabled: PropTypes.bool,
	fetching: PropTypes.bool,
	isRequired: PropTypes.bool,
	type: PropTypes.oneOf([
		'query',
		'queryStr',
		'configSchema',
		'configSchemaStr',
	]),
	onChange: PropTypes.func,
	loading: PropTypes.bool,
	helperText: PropTypes.string,
	error: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
		PropTypes.symbol,
	]),
	color: PropTypes.string,
};
