import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import * as yup from 'yup';

import BtDialog from '../../generic/BtDialog';
import {
	BtForm,
	BtFormActionsContainer,
	BtFormContent,
	BtFormSelect,
	BtFormTextField,
	withFormContextMethods,
} from '../../generic/forms';
import { Check } from 'mdi-material-ui';
import { capitalize } from 'lodash';
import { INSIGHTS, PLATFORM } from '../utils/constants';

const types = [INSIGHTS, PLATFORM];

const typesItems = types.map(type => {
	const item = {};
	item.value = type;
	item.label = capitalize(type);
	return item;
});

const ActionButtons = withFormContextMethods(
	({ formState: { isDirty }, onClose, verb }) => {
		return (
			<>
				<Button variant="outlined" onClick={onClose}>
					Cancel
				</Button>
				<Button
					variant="contained"
					disabled={!isDirty}
					type="submit"
					startIcon={<Check />}
					disableElevation
				>
					{verb}
				</Button>
			</>
		);
	}
);

export default function PalettePropertiesDialog({
	existingNames,
	onClose,
	onSubmit,
	open,
	paletteName,
	paletteType,
	title,
}) {
	const handleSubmit = useCallback(
		(values, reset) => {
			onSubmit(values);
			onClose();
		},
		[onClose, onSubmit]
	);

	const schema = yup.object({
		name: yup
			.string()
			.min(3)
			.max(20)
			.notOneOf(
				existingNames || [],
				'A palette with this name already exists'
			)
			.required()
			.label('Theme Name'),
		type: yup
			.string()
			.oneOf(types)
			.required()
			.label('Theme Type'),
	});

	const defaultValues = useMemo(
		() => ({
			name: paletteName ? paletteName : '',
			type: paletteType ? paletteType : INSIGHTS,
		}),
		[paletteName, paletteType]
	);

	return (
		<BtDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
			<DialogTitle>{title}</DialogTitle>
			<BtForm
				onSubmit={handleSubmit}
				validationSchema={schema}
				defaultValues={defaultValues}
			>
				<DialogContent>
					<BtFormContent>
						<BtFormTextField name="name" label="Name" autoFocus />
						<BtFormSelect
							name="type"
							label="Palette type"
							items={typesItems}
						/>
					</BtFormContent>
				</DialogContent>
				<DialogActions>
					<BtFormActionsContainer>
						<ActionButtons verb="Confirm" onClose={onClose} />
					</BtFormActionsContainer>
				</DialogActions>
			</BtForm>
		</BtDialog>
	);
}

ActionButtons.propTypes = {
	isDirty: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	verb: PropTypes.string.isRequired,
};

PalettePropertiesDialog.propTypes = {
	existingNames: PropTypes.arrayOf(PropTypes.string),
	onClose: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	paletteName: PropTypes.string,
	paletteType: PropTypes.string,
	title: PropTypes.string.isRequired,
};

PalettePropertiesDialog.displayName = 'PalettePropertiesDialog';
