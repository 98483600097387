import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
	Button,
	CircularProgress,
	IconButton,
	Tooltip,
	Typography,
} from '@mui/material';

import AddIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

import BtConfirmDialog from '../../../components/generic/BtConfirmDialog';
import BtError from '../../../components/generic/BtError';
import { BtFormContainer } from '../../../components/generic/forms';
import BtLoading from '../../../components/generic/BtLoading';
import BtSelectionTable from '../../../components/generic/BtSelectionTable';
import data_manager_breadcrumbs from '../DataManagerBreadcrumbs';
import data_manager_nav_item from '../DataManagerNavItem';
import data_manager_options from '../DataManagerOptions';
import {
	dataViewDelete,
	dataViewGetList,
} from '../../../API/DataManager/data_views';
import { INITIAL_SORT } from '../utils/constants';
import { LOOSE } from '../../../components/generic/table-utils/constants';
import NewDataViewDialog from './NewDataViewDialog';
import TableActionContainer from '../../../components/generic/table-utils/components/TableActionContainer';
import useAvailableToUser from '../../../hooks/useAvailableToUser';
import useFetch from '../../../hooks/useFetch';
import { useNavContext } from '../../../context/ContextManager';
import { useSnackbar } from 'notistack';

export default function DataViews() {
	const checkAvailability = useAvailableToUser();
	const { enqueueSnackbar } = useSnackbar();
	const { setBreadcrumbs, setContextualNav } = useNavContext();

	const [dataViews, setDataViews] = useState([]);
	const [deletingItem, setDeletingItem] = useState(false);
	const [itemToDelete, setItemToDelete] = useState();
	const [showCreateDialog, setShowCreateDialog] = useState(false);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);

	const deleteItem = useFetch(dataViewDelete).request;

	const canCreateDataView = useMemo(
		() =>
			checkAvailability({
				requiredPermissionsAll: {
					dataManager: ['DataViewCreate'],
				},
			}).available,
		[checkAvailability]
	);

	const canDeleteDataView = useMemo(
		() =>
			checkAvailability({
				requiredPermissionsAll: {
					dataManager: ['DataViewDelete'],
				},
			}).available,
		[checkAvailability]
	);

	const columns = useMemo(
		() => [
			{
				field: 'name',
				text: 'Name',
			},
			{
				field: 'description',
				text: 'Description',
			},
			...(canDeleteDataView
				? [
						{
							CellContent: (cell, item) => (
								<TableActionContainer>
									{deletingItem &&
									itemToDelete?.uuid === item?.uuid ? (
										<CircularProgress size={20} />
									) : (
										<Tooltip
											disableInteractive
											title="Delete"
										>
											<IconButton
												color="error"
												onClick={event => {
													event.stopPropagation();

													setItemToDelete(item);

													setShowDeleteDialog(true);
												}}
											>
												<DeleteIcon />
											</IconButton>
										</Tooltip>
									)}
								</TableActionContainer>
							),
							field: 'delete',
							sortable: false,
						},
				  ]
				: []),
		],
		[canDeleteDataView, deletingItem, itemToDelete]
	);

	const handleDeletion = useCallback(
		async uuid => {
			setDeletingItem(true);

			const { error, ok } = await deleteItem({ dataViewUuid: uuid });

			setDeletingItem(false);

			if (!ok) {
				enqueueSnackbar('Failed to delete Data Source', {
					variant: 'error',
				});

				console.error('Failed to delete Data Source', error);

				return;
			}

			enqueueSnackbar('Data View was successfully deleted');

			setDataViews(prev =>
				prev.filter(({ uuid: itemUuid }) => itemUuid !== uuid)
			);
		},
		[deleteItem, enqueueSnackbar]
	);

	const dataViewSetter = useCallback(data => {
		setDataViews(
			data.map(data => ({ ...data, route: `/DataViews/${data.uuid}` }))
		);
	}, []);

	const { error: listError, loading, request: getDataViews } = useFetch(
		dataViewGetList,
		dataViewSetter
	);

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				...data_manager_breadcrumbs,
				{ text: 'Data Views', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([
				...data_manager_nav_item,
				...data_manager_options,
			]);

			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	useEffect(
		() => {
			getDataViews();
		},
		[getDataViews]
	);

	if (listError) {
		return (
			<BtError
				action={() => getDataViews()}
				description="An error occurred when attempting to retrieve the data views."
				title="Retrieval Error"
			/>
		);
	}

	return (
		<BtFormContainer maxWidth="lg" title="Data Views">
			<BtLoading loading={loading}>
				<BtSelectionTable
					columns={columns}
					data={dataViews}
					debounceSearchInput={false}
					disableRowPredicate={item => {
						return (
							deletingItem && item?.uuid === itemToDelete?.uuid
						);
					}}
					enableGeneralSorting
					enablePagination
					enableSearching
					initialSort={INITIAL_SORT}
					initialSearchExactness={LOOSE}
					title={
						<div
							style={{
								display: 'flex',
								flexWrap: 'wrap',
								gap: 10,
								justifyContent: 'space-between',
							}}
						>
							<Typography
								sx={{
									fontWeight: 'bold',
									padding: '4px 0',
								}}
								variant="h5"
							>
								{"Your organisation's Data Views"}
							</Typography>
							{canCreateDataView && (
								<Button
									disableElevation
									onClick={() => setShowCreateDialog(true)}
									startIcon={<AddIcon />}
									variant="contained"
								>
									Add New Data View
								</Button>
							)}
						</div>
					}
				/>
			</BtLoading>
			<NewDataViewDialog
				existingNames={dataViews?.map(({ name }) => name)}
				open={showCreateDialog}
				onClose={() => setShowCreateDialog(false)}
			/>
			<BtConfirmDialog
				action={() => handleDeletion(itemToDelete.uuid)}
				ActionIcon={<DeleteIcon />}
				isDestructive
				onClose={() => setShowDeleteDialog(false)}
				open={showDeleteDialog}
				prompt={`Are you sure you want to delete the ${
					itemToDelete?.name
				} data view? This action cannot be undone.`}
				title="Data View Deletion"
				verb="Delete"
			/>
		</BtFormContainer>
	);
}
