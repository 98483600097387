import { getTime, startOfDay, subDays } from 'date-fns';
import _ from 'lodash';

const phonics = [
	'Alfa',
	'Bravo',
	'Charlie',
	'Delta',
	'Echo',
	'Foxtrot',
	'Golf',
	'Hotel',
	'India',
	'Juliett',
	'Kilo',
	'Lima',
	'Mike',
	'November',
	'Oscar',
	'Papa',
	'Quebec',
	'Romeo',
	'Sierra',
	'Tango',
	'Uniform',
	'Victor',
	'Whiskey',
	'X-ray',
	'Yankee',
	'Zulu',
];

const getRandomItemFromArray = array => {
	return array[Math.floor(Math.random() * array.length)];
};

const buildLineVisOptions = categories => {
	const result = {};

	const params = categories.map(value => {
		const param = {};
		param.label = value;
		param.value = `value_${value}`;
		param.axis = 'primary';
		return param;
	});

	result.params = params;

	result.ordinalAxis = {
		param: {
			label: 'timestamp',
			value: 'start_time',
		},
	};

	result.valuePrimaryAxis = {
		label: 'Value',
		// prefix: '£',
		axis_bounds: {
			min: 'auto',
			max: 'auto',
		},
		decimalPoints: 2,
	};
	result.legend = {
		disabled: false,
	};

	return result;
};

const buildLineData = (categories, dataPoints = 10) => {
	const rangeEnd = getTime(startOfDay(new Date()));

	const timeSeries = _.range(0, dataPoints)
		.map(item => getTime(subDays(rangeEnd, item)))
		.sort((a, b) => a - b);

	const result = timeSeries.map(timeStamp => {
		const dataObj = {};
		dataObj.start_time = timeStamp;
		categories.forEach(category => {
			const key = `value_${category}`;
			dataObj[key] = _.round(
				_.random(category.length * 3, category.length * 3.7)
			);
		});
		return dataObj;
	});
	return result;
	// console.log(result);
};

const buildGroupedBarVisOptions = subCategories => {
	const result = {};

	const params = subCategories.map(value => {
		const param = {};
		param.label = value;
		param.value = `value_${value}`;
		param.axis = 'primary';
		return param;
	});

	result.params = params;

	result.ordinalAxis = {
		param: {
			label: 'category',
			value: 'category',
		},
	};

	result.valuePrimaryAxis = {
		label: 'Value',
		// prefix: '£',
		axis_bounds: {
			min: 'auto',
			max: 'auto',
		},
		decimalPoints: 2,
	};
	result.legend = {
		disabled: false,
	};

	return result;
};

const buildGroupBarData = (categories, subCategories) => {
	const result = categories.map(category => {
		const dataObj = {};
		dataObj.category = category;
		subCategories.forEach(subCategory => {
			const key = `value_${subCategory}`;
			dataObj[key] = _.round(
				_.random(subCategory.length * 3, subCategory.length * 5)
			);
		});

		return dataObj;
	});

	// const result = timeSeries.map(timeStamp => {
	// 	const dataObj = {};
	// 	dataObj.timestamp = timeStamp;
	// 	categories.forEach(category => {
	// 		const key = `value_${category}`;
	// 		dataObj[key] = _.round(
	// 			_.random(category.length * 3, category.length * 5)
	// 		);
	// 	});
	// 	return dataObj;
	// });
	return result;
	// console.log(result);
};

export {
	buildLineVisOptions,
	phonics,
	buildLineData,
	buildGroupBarData,
	buildGroupedBarVisOptions,
	getRandomItemFromArray,
};
