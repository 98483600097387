import React, { memo, useMemo } from 'react';
import {
	Box,
	LinearProgress,
	Tooltip,
	Typography,
	styled,
} from '@mui/material';
import PropTypes from 'prop-types';
import { mdiDelete, mdiPencil } from '@mdi/js';
import { BtIconButtonGroup } from '../../../../components/generic/BtIconButtonGroup';
import { XS } from '../../../../components/generic/BtIconButton';

const HiddenRx = styled(Box)(() => ({
	width: '8px',
	height: '8px',
	borderRadius: '4px',
}));

const VisibleRx = styled(HiddenRx)(() => ({
	backgroundColor: '#00ff00',
}));

const StyledLinerProgress = styled(LinearProgress)(() => ({
	width: '100%',
	borderTopLeftRadius: 10,
	borderTopRightRadius: 10,
	position: 'absolute',
	top: 0,
}));

const ContainerBox = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.background.paper,
	borderRadius: 8,
	height: '100%',
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'column',
	padding: '10px',
}));

const TitleBox = styled(Box)(() => ({
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	flexDirection: 'row',
	height: '30px',
}));

const TitleWithTooltip = memo(function TitleWithTooltip({ title, subtitle }) {
	return (
		<Tooltip title={subtitle}>
			<Typography noWrap variant="h6">
				{title}
			</Typography>
		</Tooltip>
	);
});

const VisualisationContainer = styled(Box)(() => ({
	height: '100%',
	width: '100%',
}));

const EditButtons = memo(function EditButtons({ onClickEdit, onClickDelete }) {
	const attributes = useMemo(
		() => [
			{
				onClick: onClickEdit,
				icon: mdiPencil,
				size: XS,
				tooltip: 'Edit',
			},
			{
				onClick: onClickDelete,
				icon: mdiDelete,
				size: XS,
				tooltip: 'Delete',
			},
		],
		[onClickDelete, onClickEdit]
	);

	return (
		<BtIconButtonGroup
			containerStyle={{ margin: 0 }}
			buttonGroupAttributes={attributes}
		/>
	);
});

TitleWithTooltip.displayName = 'TitleWithTooltip';
TitleWithTooltip.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
};

EditButtons.displayName = 'EditButtons';
EditButtons.propTypes = {
	onClickEdit: PropTypes.func.isRequired,
	onClickDelete: PropTypes.func.isRequired,
};

export {
	HiddenRx,
	VisibleRx,
	StyledLinerProgress,
	ContainerBox,
	TitleBox,
	TitleWithTooltip,
	VisualisationContainer,
	EditButtons,
};
