import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Button, Typography, useTheme } from '@mui/material';

import RefreshIcon from '@mui/icons-material/Refresh';
import SourcesIcon from '@mui/icons-material/Storage';

import BtDialog from '../../../components/generic/BtDialog';
import BtDialogHeading from '../../../components/generic/BtDialogHeading';
import BtLoading from '../../../components/generic/BtLoading';
import BtOpenInNew from '../../../components/generic/BtOpenInNew';
import BtSelectionTable from '../../../components/generic/BtSelectionTable';
import { dataSources } from './props';
import { INITIAL_SORT, SOURCE_TYPE_URL_ITEM_LOOKUP } from '../utils/constants';
import TableActionContainer from '../../../components/generic/table-utils/components/TableActionContainer';
import { useDataViewEditorContext } from './DataViewEditorContext';

const columns = [
	{ field: 'name', text: 'Name' },
	{ field: 'description', text: 'Description', minBreakpoint: 'lg' },
	{ field: 'type', text: 'Type', minBreakpoint: 'md' },
	{
		text: '',
		searchable: false,
		sortable: false,
		CellContent: (cellData, rowData) => (
			<TableActionContainer>
				<BtOpenInNew
					buttonProps={{
						color: undefined,
					}}
					name="View source"
					size="medium"
					stopPropagation
					url={`DataSources/${
						SOURCE_TYPE_URL_ITEM_LOOKUP[rowData.type]
					}/${rowData.uuid}`}
				/>
			</TableActionContainer>
		),
	},
];

export default function DataViewAddSourcesDialog({
	availableSources,
	onAddSource,
	onClose,
	open,
}) {
	const {
		loadingDataSources,
		refreshDataSources,
	} = useDataViewEditorContext();
	const theme = useTheme();

	const SourcesButton = useCallback(
		({ ...props }) => (
			<Button startIcon={<SourcesIcon />} {...props}>
				View Sources
			</Button>
		),
		[]
	);

	return (
		<BtDialog maxWidth="md" onClose={onClose} open={open}>
			<div
				style={{
					height: '100dvh',
					maxHeight: 600,
					maxWidth: 800,
					padding: '1em',
					overflow: 'hidden',
					width: '100dvw',
				}}
			>
				<BtDialogHeading
					onClose={onClose}
					title={
						<div
							style={{
								alignItems: 'center',
								display: 'flex',
								flexWrap: 'wrap',
							}}
						>
							<Typography
								style={{ marginRight: '1em' }}
								variant="h6"
							>
								Available Sources
							</Typography>
							<Button
								disabled={loadingDataSources}
								onClick={refreshDataSources}
								startIcon={<RefreshIcon />}
								style={{ marginRight: '1em' }}
							>
								Refresh
							</Button>
							<BtOpenInNew
								RenderButton={SourcesButton}
								url="DataSources"
							/>
						</div>
					}
				/>
				{loadingDataSources && <BtLoading />}
				{!loadingDataSources && (
					<BtSelectionTable
						columns={columns}
						data={availableSources}
						debounceSearchInput={false}
						enableGeneralSorting
						enablePagination
						enableSearching
						hideSearchColumn
						hideSearchExactness
						initialSearchExactness="loose"
						initialSort={INITIAL_SORT}
						onClick={onAddSource}
						paginationBackgroundColor={
							theme.palette.background.paper
						}
						tableContainerStyle={{ paddingBottom: '2em' }}
					/>
				)}
			</div>
		</BtDialog>
	);
}

DataViewAddSourcesDialog.propTypes = {
	availableSources: dataSources,
	onAddSource: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
};
