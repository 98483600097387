import React, { useState, useCallback, useMemo } from 'react';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';

import { userPutForgotPassword } from '../../API';

import { Container, Button, Typography } from '@mui/material';

import BTAuthCard from './BTAuthCard';
import {
	BtForm,
	BtFormChangesBlocker,
	BtFormContent,
	BtFormSection,
	BtFormTextField,
	BtAuthFormActionButtons,
} from '../../components/generic/forms';

// Yup schema
const schema = yup.object({
	email: yup
		.string()
		.email()
		.required()
		.label('Email'),
});

export default function ForgotPassword(props) {
	const { enqueueSnackbar } = useSnackbar();
	const [sending, setSending] = useState(false);
	const [messageSent, setMessageSent] = useState(false);

	const onSubmit = useCallback(
		async (values, reset) => {
			// This API route accepts only the email of the user who
			// requires a password reset
			const reqData = { email: values.email };
			setSending(true);
			try {
				// if (props.setResetRequestSubmitted) {
				// 	props.setResetRequestSubmitted(true);
				// }
				// // request(reqData);
				// setMessageSent(true);
				// reset(values);

				const response = await userPutForgotPassword(reqData);
				console.log(response);
				if (response.result === 'OK') {
					enqueueSnackbar('Success!', {
						variant: 'success',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'center',
						},
					});
					if (props.setResetRequestSubmitted) {
						props.setResetRequestSubmitted(true);
					}
					// request(reqData);
					setMessageSent(true);
					reset && reset(values);
				}
			} catch (error) {
				enqueueSnackbar('Failed to send, please try again', {
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
				});
				console.error(error);
			} finally {
				setSending(false);
			}
		},
		[enqueueSnackbar, props]
	);

	/* 
    Default values
    */
	const defaultValues = useMemo(
		() => (props.email ? { email: props.email } : { email: '' }),
		[props.email]
	);

	const heading = useMemo(
		() => {
			// console.log(props);
			if (messageSent) {
				return props.prePopulateForm ? (
					<Typography align="center">
						Please check your email and use the link provided.{' '}
						{props.signingOut ? (
							<em>Signing you out...</em>
						) : (
							'You can close this tab.'
						)}
					</Typography>
				) : (
					<Typography align="center">
						If your email is recognised we will have sent you a link
						to reset your password. Please check your email and use
						the link provided.{' '}
						{props.signingOut ? (
							<em>Signing you out...</em>
						) : (
							'You can close this tab.'
						)}
					</Typography>
				);
			}
			if (props.prePopulateForm) {
				return (
					<div
						style={{
							gap: 5,
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Typography align="center">
							A temporary password will be sent to the following
							email address
						</Typography>
						<Typography align="center">
							<em>{props.email}</em>
						</Typography>
						<Typography align="center">
							<b>You will be signed out</b>
						</Typography>
					</div>
				);
			}
			return (
				<Typography align="center">
					Enter your email address below. If your email is recognised
					we will send you a link to reset your password.
				</Typography>
			);
		},
		[messageSent, props]
	);

	return (
		<BTAuthCard
			// heading={
			// 	<>
			// 		{messageSent ? (
			// 			<Typography align="center">
			// 				If your email is recognised we will have sent you a
			// 				link to reset your password. Please check your email
			// 				and use the link provided.{' '}
			// 				{props.signingOut ? (
			// 					<em>Signing you out...</em>
			// 				) : (
			// 					'You can close this tab.'
			// 				)}
			// 			</Typography>
			// 		) : (
			// 			<Typography align="center">
			// 				Enter your email address below. If your email is
			// 				recognised we will send you a link to reset your
			// 				password.
			// 			</Typography>
			// 		)}
			// 	</>
			// }
			heading={heading}
			form={
				<Container maxWidth="md">
					<BtForm
						validationSchema={schema}
						defaultValues={defaultValues}
						loading={false}
						sending={sending}
						onSubmit={(values, reset) => onSubmit(values, reset)}
					>
						<BtFormSection style={{ margin: '0px' }}>
							{messageSent &&
								props.changeView && (
									<>
										<Button
											disableElevation={true}
											fullWidth={true}
											variant="contained"
											onClick={() => {
												props.changeView &&
													props.changeView('Login');
											}}
										>
											Go to Login
										</Button>
									</>
								)}
							{!messageSent &&
								!props.prePopulateForm && (
									<BtFormContent>
										<BtFormTextField
											name="email"
											label="Email"
										/>
										<BtAuthFormActionButtons
											submitVerb="Send Link"
											submitPresentVerb="Sending Link.."
										/>
										<BtFormChangesBlocker />
									</BtFormContent>
								)}
						</BtFormSection>
					</BtForm>
					{props.prePopulateForm && (
						<Button
							variant="contained"
							disableElevation
							style={{ width: '100%', marginTop: '1em' }}
							disabled={sending || props.signingOut}
							onClick={() => onSubmit({ email: props.email })}
						>
							{sending ? 'Sending Link' : 'Send Link'}
						</Button>
					)}
					{props.onClose && (
						<Button
							variant="outlined"
							disableElevation
							style={{ width: '100%', marginTop: '1em' }}
							disabled={sending || props.signingOut}
							onClick={props.onClose}
						>
							Close
						</Button>
					)}
				</Container>
			}
		/>
	);
}
