import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@mui/material';
import { memo } from 'react';
import Icon from '@mdi/react';

export const XS = 'xs';
export const SMALL = 'small';
export const MEDIUM = 'medium';

const sizes = {
	[XS]: '15px',
	[SMALL]: '20px',
	[MEDIUM]: '24px',
};

/**
 * @param onClick
 * @param disabled
 * @param icon
 * @param size
 * @param tooltip
 * @param type - to support form context pass 'submit'
 *
 */
const BtIconButton = memo(function BtIconButton({
	onClick,
	disabled,
	icon,
	size,
	tooltip,
	type,
	color,
	style,
}) {
	const iconSize = useMemo(() => sizes[size], [size]);
	const buttonSize = useMemo(() => (size === MEDIUM ? MEDIUM : SMALL), [
		size,
	]);

	return (
		<Tooltip title={tooltip}>
			<span>
				<IconButton
					size={buttonSize}
					type={type}
					disabled={disabled}
					onClick={onClick}
					sx={style}
				>
					<Icon path={icon} size={iconSize} color={color} />
				</IconButton>
			</span>
		</Tooltip>
	);
});

BtIconButton.defaultProps = {
	size: MEDIUM,
};

BtIconButton.propTypes = {
	color: PropTypes.string,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	icon: PropTypes.string.isRequired,
	size: PropTypes.oneOf([XS, SMALL, MEDIUM]),
	style: PropTypes.object,
	tooltip: PropTypes.string,
	type: PropTypes.oneOf(['submit']),
};

export { BtIconButton };
