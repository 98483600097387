import { apiClient } from './api_client';
import serverRequest from './serverRequest';
import { uuidRegex } from './utils';

import {
	workflowSubmissionGetListSchema,
	workflowSubmissionGetSchema,
	workflowSubmissionAddSchema,
	workflowSubmissionPutSchema,
} from './validations/workflowSubmissionValidation';

//========================================================================================================
async function workflowSubmissionGetList({ pageData }) {
	return await serverRequest({
		method: 'get',
		url: 'workflow/submission',
		params: pageData,
		responseSchema: workflowSubmissionGetListSchema,
		resDataProp: 'workflow_submissions',
	});
}

//========================================================================================================
async function workflowSubmissionGet({ workflowSubmissionUuid }) {
	if (!uuidRegex.test(workflowSubmissionUuid)) {
		throw new Error('Param Error');
	}

	const req_url = `workflow/submission/${workflowSubmissionUuid}`;
	try {
		const response = await apiClient({
			method: 'get',
			url: req_url,
		});

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				return await workflowSubmissionGetSchema.validate(
					response.workflow_submission
				);
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function workflowSubmissionCreate({ newSubmission }) {
	// Validate the new submission data

	await workflowSubmissionAddSchema.validate(newSubmission);

	const req_url = 'workflow/submission';

	const response = await apiClient({
		method: 'post',
		url: req_url,
		body: newSubmission,
	});

	if (response) {
		if (response.result === 'OK') {
			// Validate the returned data object
			// let newSubmission = await workflowSubmissionGetSchema.validate(
			// 	response.workflow_submission
			// );
			return newSubmission;
		} else {
			throw new Error('Server Error');
		}
	}
}

//========================================================================================================
async function workflowSubmissionUpdate({
	workflowSubmissionUuid,
	submissionUpdate,
}) {
	if (!uuidRegex.test(workflowSubmissionUuid)) {
		throw new Error('Param Error');
	}

	const req_url = `workflow/submission/${workflowSubmissionUuid}`;

	const response = await apiClient({
		method: 'put',
		url: req_url,
		body: submissionUpdate,
	});

	if (response) {
		if (!response.result === 'OK') {
			throw new Error('Server Error');
		}
	}
}

//========================================================================================================
async function workflowSubmissionDelete({ workflowSubmissionUuid }) {
	if (!uuidRegex.test(workflowSubmissionUuid)) {
		throw new Error('Param Error');
	}

	const req_url = `workflow/submission/${workflowSubmissionUuid}`;

	const response = await apiClient({
		method: 'delete',
		url: req_url,
	});

	if (response) {
		if (!response.result === 'OK') {
			throw new Error('Server Error');
		}
	}
}

export {
	workflowSubmissionGetList,
	workflowSubmissionGet,
	workflowSubmissionCreate,
	workflowSubmissionUpdate,
	workflowSubmissionDelete,
};
