import _ from 'lodash';

/**
 * Here we check to see if a passed array could be a prefix notation function.
 * @param {array} item - Item to be checked.
 * @param {any} item.functionName - We'll check this, and perhaps it's a prefix function name.
 * @returns {boolean} True if we are actually looking at prefix notation.
 */
function isPrefixNotation([functionName]) {
	if (functionName instanceof Array) {
		if (isPrefixNotation(functionName)) return true;
	}

	if (typeof functionName !== 'string') return false;
	if (functionName.indexOf('yup.') < 0) return false;

	return true;
}

/**
 * Searches for {substring} in {string}.
 * If found, returns the {string}, sliced after substring.
 *
 * @param {string} string - String to be sliced.
 * @param {string} substring - String to search for.
 * @returns {string|null} Null if no match found.
 */
function getSubString(string, substring) {
	if (!string) return null;

	const testedIndex = string.indexOf(substring);

	if (testedIndex > -1) {
		return string.slice(testedIndex + substring.length);
	}

	return null;
}

// Transform a 'yup.' prefixed array
function transformArray(schemaNode) {
	const nodeType = getSubString(schemaNode[0][0], 'yup.');

	if (nodeType === 'object') {
		let nodeProps = { type: 'object' };
		nodeProps = { ...nodeProps, ...extraxtProps('object', schemaNode) };
		return nodeProps;
	} else if (nodeType === 'array') {
		let nodeProps = { type: 'array' };
		nodeProps = { ...nodeProps, ...extraxtProps('array', schemaNode) };
		return nodeProps;
	} else if (nodeType === 'number') {
		let nodeProps = { type: 'number' };
		nodeProps = { ...nodeProps, ...extraxtProps('number', schemaNode) };
		return nodeProps;
	} else if (nodeType === 'string') {
		let nodeProps = { type: 'string' };
		nodeProps = { ...nodeProps, ...extraxtProps('string', schemaNode) };
		return nodeProps;
	} else if (nodeType === 'boolean') {
		let nodeProps = { type: 'boolean' };
		nodeProps = { ...nodeProps, ...extraxtProps('boolean', schemaNode) };
		return nodeProps;
	} else if (nodeType === 'date') {
		let nodeProps = { type: 'date' };
		nodeProps = { ...nodeProps, ...extraxtProps('date', schemaNode) };
		return nodeProps;
	}
}

// Transform a 'yup.' prefixed prop
function transformProp(dataType, propNode) {
	const nodeType = getSubString(propNode[0], 'yup.');

	if (nodeType === 'required') {
		if (propNode.length < 1) {
			throw new Error('prop requires more parameters');
		}

		let propObj = { value: true };
		if (propNode[1]) propObj.message = propNode[1];
		return { required: propObj };
	} else if (nodeType === 'default') {
		if (propNode.length < 1) {
			throw new Error('prop "default" requires more parameters');
		}

		let propObj = {};

		if (dataType === 'number') {
			if (typeof propNode[1] === 'number') {
				propObj = { value: propNode[1] };
			} else {
				throw new Error('default value must be a number');
			}
		} else if (dataType === 'string') {
			if (typeof propNode[1] === 'string') {
				propObj = { value: propNode[1] };
			} else {
				throw new Error('default value must be a string');
			}
		} else if (dataType === 'boolean') {
			if (typeof propNode[1] === 'boolean') {
				propObj = { value: propNode[1] };
			} else {
				throw new Error('default value must be a boolean');
			}
		} else if (dataType === 'date') {
			if (typeof propNode[1] === 'string') {
				propObj = { value: propNode[1] };
			} else {
				throw new Error('default value must be an ISO date');
			}
		} else {
			throw new Error(
				'default value parsing for this type not supported'
			);
		}
		return { default: propObj };
	} else if (nodeType === 'min') {
		if (propNode.length < 1) {
			throw new Error('prop "min" requires more parameters');
		}

		let propObj = {};
		if (typeof propNode[1] === 'number') {
			propObj = { value: propNode[1] };
		} else {
			throw new Error('min value must be a number');
		}
		if (propNode[2]) propObj.message = propNode[2];
		return { min: propObj };
	} else if (nodeType === 'max') {
		if (propNode.length < 1) {
			throw new Error('prop "max" requires more parameters');
		}
		let propObj = {};
		if (typeof propNode[1] === 'number') {
			propObj = { value: propNode[1] };
		} else {
			throw new Error('max value must be a number');
		}
		if (propNode[2]) propObj.message = propNode[2];
		return { max: propObj };
	} else if (nodeType === 'uuid') {
		if (propNode.length < 1) {
			throw new Error('prop requires more parameters');
		}

		let propObj = { value: true };
		if (propNode[1]) propObj.message = propNode[1];
		return { uuid: propObj };
	} else if (nodeType === 'of') {
		// if (typeof propNode[1] !== 'array') {
		// 	throw new Error('max value must be an array');
		// }

		return {
			arrayContent: parseSchema(propNode[1]), // TODO: parseSchema or transformArray
		}; // TODO: could arrayContent and ObjectContent be the same?
	} else if (nodeType === 'shape') {
		// if (typeof propNode[1] !== 'array') {
		// 	throw new Error('max value must be an array');
		// }

		const keys = Object.keys(propNode[1]);
		let objProps = {};
		keys.forEach(key => {
			objProps[key] = parseSchema(propNode[1][key]);
		});
		return { objectContent: objProps }; // TODO: could arrayContent and ObjectContent be the same?
	} else {
		console.log(propNode);
		throw new Error('unsupported prop');
	}
}

function extraxtProps(nodeType, propsArray) {
	let nodeProps = {};
	propsArray.slice(1).forEach(item => {
		if (isPrefixNotation(item)) {
			nodeProps = { ...nodeProps, ...transformProp(nodeType, item) };
		}
	});
	return nodeProps;
}

export function parseSchema(schemaArray) {
	if (schemaArray instanceof Array) {
		if (isPrefixNotation(schemaArray)) {
			return transformArray(schemaArray);
		}

		//return <>{schemaArray.map(i => SchemaNode(i))}</>;
	} else if (schemaArray instanceof Object) {
		//return <SchemaNodeObject schemaNode={schemaArray} />;
	}
}

export function encodeSchema(schema) {
	let result = [];

	switch (schema.type) {
		case 'object':
			result.push(['yup.object']);

			let objectProps = {};
			const childKeys = Object.keys(schema.objectContent);

			childKeys.forEach(propKey => {
				objectProps[propKey] = encodeSchema(
					schema.objectContent[propKey]
				);
			});
			result.push(['yup.shape', objectProps]);
			break;
		case 'array':
			result.push(['yup.array']);

			result.push(['yup.of', encodeSchema(schema.arrayContent)]);
			break;
		case 'string':
			result.push(['yup.string']);
			break;
		case 'number':
			result.push(['yup.number']);
			break;
		case 'boolean':
			result.push(['yup.boolean']);
			break;
		case 'date':
			result.push(['yup.date']);
			break;
		case 'datetime':
			result.push(['yup.datetime']);
			break;
		case 'time':
			result.push(['yup.time']);
			break;
		default:
			throw new Error('Unsupported type');
	}

	if (schema.min) {
		result.push(encodeMin(schema));
	}
	if (schema.max) {
		result.push(encodeMax(schema));
	}
	if (schema.default) {
		result.push(encodeDefault(schema));
	}
	if (schema.required) {
		result.push(encodeRequired(schema));
	}
	return result;
}

function encodeMin(schema) {
	if (schema.min) {
		let result = [];
		result.push('yup.min');

		if (!schema.min.value) {
			throw new Error('Min value missing');
		}

		if (typeof schema.min.value !== 'number') {
			throw new Error('Min value not a number');
		}

		result.push(schema.min.value);
		if (schema.min.message) {
			result.push(schema.min.message);
		}
		return result;
	}
	return null;
}

function encodeMax(schema) {
	if (schema.max) {
		let result = [];
		result.push('yup.max');

		if (!schema.max.value) {
			throw new Error('Max value missing');
		}

		if (typeof schema.max.value !== 'number') {
			throw new Error('Max value not a number');
		}

		result.push(schema.max.value);
		if (schema.max.message) {
			result.push(schema.max.message);
		}
		return result;
	}
	return null;
}

function encodeDefault(schema) {
	if (schema.default) {
		let result = [];
		result.push('yup.default');

		if (_.isNil(schema.default.value)) {
			throw new Error('Default value missing');
		}

		if (schema.type === 'number') {
			if (typeof schema.default.value !== 'number') {
				throw new Error('Default value not a number');
			}
		} else if (schema.type === 'string') {
			if (typeof schema.default.value !== 'string') {
				throw new Error('Default value not a string');
			}
		} else if (schema.type === 'boolean') {
			if (typeof schema.default.value !== 'boolean') {
				throw new Error('Default value not a boolean');
			}
		} else if (schema.type === 'date') {
			if (typeof schema.default.value !== 'string') {
				throw new Error('Default value not an ISO date string');
			}
		} else {
			throw new Error('Default data type error');
		}

		result.push(schema.default.value);
		return result;
	}
	return null;
}

function encodeRequired(schema) {
	if (schema.required) {
		if (schema.required.value !== true) {
			return null;
		}
		let result = [];
		result.push('yup.required');
		if (schema.required.message) {
			result.push(schema.required.message);
		}
		return result;
	}
	return null;
}
