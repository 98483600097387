import { apiClient } from './api_client';
import { uuidRegex } from './utils';

import {
	insightCollectionGetListSchema,
	insightCollectionGetSchema,
	insightCollectionHistoryGetSchema,
	insightCollectionComponentListGetSchema,
	insightCollectionComponentGetSchema,
	insightCollectionTileGetSchema,
	insightCollectionDraftGetSchema,
	insightCollectionPostSchema,
	insightCollectionsPostResponseSchema,
	insightCollectionStatusChangeSchema,
	insightCollectionPutSchema,
	insightCollectionDraftPutSchema,
} from './validations/insightCollectionsValidation';

//========================================================================================================
async function insightCollectionsGetList() {
	const req_url = 'insights';
	try {
		const response = await apiClient({ method: 'get', url: req_url });

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let insights = await insightCollectionGetListSchema.validate(
					response.insight_collections
				);
				return insights;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function insightCollectionGet({ insightUuid }) {
	if (!uuidRegex.test(insightUuid)) {
		throw new Error('Param Error');
	}

	const req_url = `insights/${insightUuid}`;
	try {
		const response = await apiClient({ method: 'get', url: req_url });

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let insight = await insightCollectionGetSchema.validate(
					response.insight_collection
				);
				return insight;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function insightCollectionCreate({ newCollection }) {
	const req_url = `insights`;
	try {
		const response = await apiClient({
			method: 'post',
			url: req_url,
			body: newCollection,
		});

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let insight = await insightCollectionsPostResponseSchema.validate(
					response.insight_collection
				);
				return insight;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function insightCollectionComponentListGet({ insightUuid }) {
	if (!uuidRegex.test(insightUuid)) {
		throw new Error('Param Error');
	}

	const req_url = `insights/components/${insightUuid}`;
	try {
		const response = await apiClient({ method: 'get', url: req_url });
		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let insightComponents = await insightCollectionComponentListGetSchema.validate(
					response.insight_components
				);
				return insightComponents;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function insightCollectionComponentGet({ insightUuid, componentUuid }) {
	if (!uuidRegex.test(insightUuid)) {
		throw new Error('Param Error');
	}

	if (!uuidRegex.test(componentUuid)) {
		throw new Error('Param Error');
	}

	const req_url = `insights/component/${insightUuid}/${componentUuid}`;
	try {
		const response = await apiClient({ method: 'get', url: req_url });
		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let insightComponent = await insightCollectionComponentGetSchema.validate(
					response.insight_component
				);
				return insightComponent;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function insightCollectionTileGet({ insightUuid }) {
	if (!uuidRegex.test(insightUuid)) {
		throw new Error('Param Error');
	}

	const req_url = `insights/tile/${insightUuid}`;
	try {
		const response = await apiClient({ method: 'get', url: req_url });
		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let insightTile = await insightCollectionTileGetSchema.validate(
					response.insight_tile
				);
				return insightTile;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function insightCollectionGetDraft({ insightUuid }) {
	if (!uuidRegex.test(insightUuid)) {
		throw new Error('Param Error');
	}
	// console.log('GET DRAFT');
	const req_url = `insights/draft/${insightUuid}`;
	try {
		const response = await apiClient({ method: 'get', url: req_url });
		// console.log(response.insight_draft);
		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let insight = await insightCollectionDraftGetSchema.validate(
					response.insight_draft
				);
				return insight;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function insightCollectionGetVersion({ insightUuid, versionIndex }) {
	if (!uuidRegex.test(insightUuid)) {
		throw new Error('Param Error');
	}

	const req_url = `insights/${insightUuid}/${versionIndex}`;
	try {
		const response = await apiClient({
			method: 'get',
			url: req_url,
		});

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let version = await insightCollectionGetSchema.validate(
					response.version
				);
				return version;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function insightCollectionHistoryGet({ insightUuid }) {
	if (!uuidRegex.test(insightUuid)) {
		throw new Error('Param Error');
	}

	const req_url = `insights/${insightUuid}/history`;
	try {
		const response = await apiClient({
			method: 'get',
			url: req_url,
		});

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				return await insightCollectionHistoryGetSchema.validate(
					response.insight_collection
				);
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function insightCollectionUpdateDetails({ insightUuid, update }) {
	console.log(insightUuid, update);
	if (!uuidRegex.test(insightUuid)) {
		throw new Error('Param Error');
	}

	const req_url = `insights/${insightUuid}/collection/update`;

	try {
		const validCollectionUpdate = await insightCollectionPutSchema.validate(
			update
		);

		const response = await apiClient({
			method: 'put',
			url: req_url,
			body: { ...validCollectionUpdate },
		});

		if (response) {
			if (!response.result === 'OK') {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function insightCollectionChangeStatus({ insightUuid, newStatus }) {
	if (!uuidRegex.test(insightUuid)) {
		throw new Error('Param Error');
	}

	if (newStatus !== 'Active' && newStatus !== 'Inactive') {
		throw new Error('Param Error');
	}

	const req_url = `insights/${insightUuid}/status`;
	try {
		const response = await apiClient({
			method: 'put',
			url: req_url,
			body: { new_status: newStatus },
		});

		if (response) {
			if (!response.result === 'OK') {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function insightCollectionUpdateDraftLock({ insightUuid, userUuid }) {
	if (!uuidRegex.test(insightUuid)) {
		throw new Error('Param Error');
	}

	if (!uuidRegex.test(userUuid) && userUuid != null) {
		throw new Error('Param Error');
	}

	const req_url = `insights/${insightUuid}/draft/lock`;
	try {
		const response = await apiClient({
			method: 'put',
			url: req_url,
			body: { userUuid: userUuid },
		});

		if (response) {
			if (!response.result === 'OK') {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function insightCollectionDraftCommit({ insightUuid }) {
	if (!uuidRegex.test(insightUuid)) {
		throw new Error('Param Error');
	}

	const req_url = `insights/${insightUuid}/draft/commit`;
	try {
		const response = await apiClient({
			method: 'put',
			url: req_url,
		});

		if (response) {
			if (!response.result === 'OK') {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function insightCollectionDraftPublish({ insightUuid }) {
	if (!uuidRegex.test(insightUuid)) {
		throw new Error('Param Error');
	}

	const req_url = `insights/${insightUuid}/draft/publish`;
	try {
		const response = await apiClient({
			method: 'put',
			url: req_url,
		});

		if (response) {
			if (!response.result === 'OK') {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function insightCollectionPublish({ insightUuid }) {
	if (!uuidRegex.test(insightUuid)) {
		throw new Error('Param Error');
	}

	const req_url = `insights/${insightUuid}/collection/publish`;
	try {
		const response = await apiClient({
			method: 'put',
			url: req_url,
		});

		if (response) {
			if (!response.result === 'OK') {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function insightCollectionDraftUpdate({ insightUuid, draftUpdate }) {
	if (!uuidRegex.test(insightUuid)) {
		throw new Error('Param Error');
	}
	// console.log({ draftUpdate });

	try {
		const validatedDraftUpdate = await insightCollectionDraftPutSchema.validate(
			draftUpdate
		);
		console.log({ insightUuid, draftUpdate });
		const req_url = `insights/${insightUuid}/draft`;

		try {
			const response = await apiClient({
				method: 'put',
				url: req_url,
				body: validatedDraftUpdate,
			});

			if (response) {
				if (!response.result === 'OK') {
					throw new Error('Server Error');
				}
			}
		} catch (error) {
			console.log(error);
			throw new Error('Response Error');
		}
	} catch (error) {
		console.log(error);
		throw new Error('Param Error');
	}
}

export {
	insightCollectionsGetList,
	insightCollectionGet,
	insightCollectionHistoryGet,
	insightCollectionChangeStatus,
	insightCollectionCreate,
	insightCollectionComponentListGet,
	insightCollectionComponentGet,
	insightCollectionTileGet,
	insightCollectionGetDraft,
	insightCollectionUpdateDraftLock,
	insightCollectionDraftPublish,
	insightCollectionDraftUpdate,
	insightCollectionUpdateDetails,
	insightCollectionPublish,
	insightCollectionDraftCommit,
	insightCollectionGetVersion,
};
