const resolveVisualisationPalette = (
	params,
	palette,
	paletteConfig,
	collectionPalettes,
	visualisationPaletteUuid
) => {
	// console.log({
	// 	params,
	// 	palette,
	// 	paletteConfig,
	// 	collectionPalettes,
	// 	visualisationPalette,
	// });
	if (!params) return [];
	let result;
	try {
		const visualisationPalette = collectionPalettes.find(
			p => p.uuid === visualisationPaletteUuid
		);
		const visualisationPaletteConfig = paletteConfig.find(
			({ uuid }) => uuid === visualisationPaletteUuid
		);

		const resolveTaggedColours = () => {
			let tags = [];
			visualisationPaletteConfig.colours.forEach(colour => {
				colour.tags.forEach(tag => {
					tags.push({
						tag: tag,
						hex: visualisationPalette.palette.find(
							p => p.uuid === colour.uuid
						).hex,
					});
				});
			});
			return tags;
		};

		let taggedColours = resolveTaggedColours();

		let nonTaggedColours = [...visualisationPalette.palette];

		let spareColours = [...visualisationPalette.palette];

		// console.log({ taggedColours, nonTaggedColours, spareColours });

		result = params.map(param => {
			const taggedColour = taggedColours.find(
				taggedColour => taggedColour.tag === param.colour_tag
			);
			if (taggedColour) {
				spareColours = spareColours.filter(
					spareColour => spareColour.uuid !== taggedColour.uuid
				);
				return taggedColour.hex;
			} else {
				if (nonTaggedColours.length === 0) {
					nonTaggedColours = [...spareColours];
				}
				const nonTaggedColour = nonTaggedColours[0];
				nonTaggedColours = nonTaggedColours.slice(1);
				return nonTaggedColour.hex;
			}
		});
	} catch (error) {
		// if for some reason the visualisation palette is not found, just use the palette
		// console.log(error);
		result = [];
		let colours = [...palette];
		while (result.length < params.length) {
			if (colours.length === 0) {
				colours = [...palette];
			}
			result.push(colours.splice(0, 1)[0]);
		}
	}

	return result;
};

export { resolveVisualisationPalette };
