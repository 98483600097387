import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

// CUSTOM COMPONENTS
import {
	SelectionTable,
	SelectionTableContainer,
	SelectionTableBody,
	SelectionTableRow,
	SelectionTableCell,
} from '../../components/generic/BtSelectionTable';
import { useNavContext } from '../../context/ContextManager';

// Data for landing page selection table and contextual navigation
import beacon_admin_options from './BeaconAdminOptions';
import beacon_admin_nav_item from './BeaconAdminNavItem';
import { BtFormContainer } from '../../components/generic/forms';
import BtInfoChip from '../../components/generic/BtInfoChip';
import useNavRedactor from '../../hooks/useNavRedactor';

const DescriptionContainer = styled('div')(({ theme }) => ({
	alignItems: 'center',
	display: 'flex',
	gap: 10,
	justifyContent: 'space-between',

	[theme.breakpoints.down('md')]: {
		alignItems: 'flex-start',
		flexDirection: 'column',
	},
}));

export default function BeaconAdmin() {
	const history = useHistory();
	// navContext contextual navigation items
	const { setContextualNav } = useNavContext();
	// navContext breadcrumbs
	const { setBreadcrumbs } = useNavContext();

	const redactNav = useNavRedactor();

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'BeaconAdmin', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	const redactedBeaconAdminOptions = useMemo(
		() => redactNav(beacon_admin_options),
		[redactNav]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([
				...beacon_admin_nav_item,
				...redactedBeaconAdminOptions,
			]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav, redactedBeaconAdminOptions]
	);

	const onClickUtil = route => () => {
		history.push(route);
	};

	return (
		<BtFormContainer title="Admin" maxWidth="md">
			<SelectionTableContainer component={Box}>
				<SelectionTable aria-label="simple table">
					<SelectionTableBody>
						{redactedBeaconAdminOptions.map((util, index) => (
							<SelectionTableRow
								onClick={onClickUtil(util.route)}
								key={index}
							>
								<SelectionTableCell>
									{util.icon}
								</SelectionTableCell>
								<SelectionTableCell>
									{util.text}
								</SelectionTableCell>
								<SelectionTableCell>
									<DescriptionContainer>
										{util.description}
										{util.subscriptionExpired && (
											<BtInfoChip
												text="Subscription Expired"
												variant="warning"
											/>
										)}
									</DescriptionContainer>
								</SelectionTableCell>
							</SelectionTableRow>
						))}
					</SelectionTableBody>
				</SelectionTable>
			</SelectionTableContainer>
		</BtFormContainer>
	);
}
