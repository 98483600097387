import React from 'react';

import TemplateIcon from '@mui/icons-material/Description';
import SubmissionsIcon from '@mui/icons-material/LibraryBooks';

const workflows_options = [
	{
		id: 'WorkflowsTemplates',
		description: 'Workflows Templates',
		text: 'Templates',
		route: '/Workflows/Templates',
		icon: <TemplateIcon />,
		requiredPermissionsAll: {
			workflows: ['TemplateList'],
		},
		validSubscriptionsAny: ['Workflow User'],
	},
	{
		id: 'WorkflowsSubmissions',
		description: 'Workflows Submissions',
		text: 'Submissions',
		route: '/Workflows/Submissions',
		icon: <SubmissionsIcon />,
		requiredPermissionsAll: {
			workflows: ['SubmissionList'],
		},
		validSubscriptionsAny: ['Workflow User'],
	},
];

export default workflows_options;
