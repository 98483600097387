const { OPERATION_MODE } = require('../expression');
const {
	getExpressionType,
	EXPRESSION,
	ERROR,
} = require('../expression/expression');
const { deepCopy } = require('../utils');
const {
	newDefaultSchema,
	getType,
	getObjectShape,
	getArrayOf,
	inferSchema,
	setArrayType,
	isSchemaEqual,
} = require('./utils');

const operators = {
	[OPERATION_MODE.ACCUMULATE]: {},
	[OPERATION_MODE.AGGREGATE]: {},
	//[OPERATION_MODE.QUERY]: {}, // Query operations always return the same schema
};

module.exports.setOperatorSchemaFn = setOperatorSchemaFn;
function setOperatorSchemaFn(key, fn, operatorModes) {
	(operatorModes || []).forEach((mode) => {
		if (operators[mode][key] !== undefined) {
			console.warn(
				'Overwriting ' + mode + ' operator',
				key,
				operators[mode][key],
				fn
			);
		}

		operators[mode][key] = fn;
	});
}

module.exports.evaluateExpressionSchema = evaluateExpression;
function evaluateExpression(context, unTypedExpression) {
	if (context.operationMode === OPERATION_MODE.QUERY) {
		// query operation will just filter the results
		return context.schema;
	}

	const expression = getExpressionType(unTypedExpression);

	switch (expression.type) {
		case EXPRESSION.FIELD_PATH:
			return evaluateFieldPath(context, expression.path);
		case EXPRESSION.SYSTEM_VARIABLE:
			return evaluateSystemVariable(context, expression.variable);
		case EXPRESSION.USER_VARIABLE:
			return evaluateUserVariable(context, expression.variable);
		case EXPRESSION.OPERATOR:
			return evaluateExpressionOperation(
				context,
				expression.operator,
				expression.arguments,
				expression.options
			);
		case EXPRESSION.LITERAL:
			return inferSchema(expression.value);
		case EXPRESSION.EXPRESSION_OBJECT:
			return evaluateExpressionObject(context, expression.value);
		default:
			break;
	}
}

function evaluateSystemVariable(context, variable) {
	switch (variable) {
		case '$$ROOT':
			return context.schema;
		case '$$NOW':
			return newDefaultSchema('date');
		default:
			throw new Error(ERROR.UNDEFINED_VARIABLE(variable));
	}
}

function evaluateUserVariable(context, variable) {
	const vIdx =
		context.userVariables?.findIndex((v) => '$$' + v.key === variable) ??
		-1;

	if (vIdx >= 0) {
		return context.userVariables[vIdx].schema;
	}

	throw new Error(ERROR.UNDEFINED_VARIABLE(variable));
}

function evaluateFieldPath(context, path) {
	const { schema } = context;

	const wholePath = path.length > 1 && path[0] === '$' ? path.slice(1) : path;
	const dotIndex = wholePath.indexOf('.');

	const p0 = dotIndex > -1 ? wholePath.slice(0, dotIndex) : wholePath;

	let pN = dotIndex > -1 ? wholePath.slice(dotIndex + 1) : '';

	const type = getType(schema);

	if (type === 'object') {
		const shape = getObjectShape(schema);

		if (pN === '') {
			return deepCopy(shape[p0]);
		}

		if (Object.hasOwnProperty.call(shape, p0)) {
			return evaluateFieldPath({ ...context, schema: shape[p0] }, pN);
		}

		return;
	}

	if (type === 'array') {
		const arrayOf = getArrayOf(schema);

		if (getType(arrayOf) === 'object') {
			// const shape = getObjectShape(arrayOf);

			const newArr = newDefaultSchema('array', arrayOf);

			const itemType = evaluateFieldPath(
				{
					...context,
					schema: arrayOf,
				},
				wholePath
			);

			setArrayType(newArr, itemType);

			return newArr;
		}
	}

	// default undefined
	return newDefaultSchema('object');
}

function evaluateExpressionObject(context, value) {
	if (Array.isArray(value)) {
		if (context.operationMode === OPERATION_MODE.AGGREGATE) {
			const newArr = newDefaultSchema('array');

			if (value.length > 0) {
				const arrayOf = evaluateExpression(context, value[0]);
				setArrayType(newArr, arrayOf);

				const tupleContent = [arrayOf];

				for (
					let index = 1;
					index < value.length && index < 20;
					index++
				) {
					const nextItemType = evaluateExpression(
						context,
						value[index]
					);

					tupleContent.push(nextItemType);
				}

				if (
					isSchemaEqual(
						{ type: 'array', tupleContent: tupleContent },
						{ type: 'array', arrayContent: arrayOf }
					)
				) {
					return newArr;
				}

				newArr.tupleContent = tupleContent;

				return newArr;
			}

			setArrayType(newArr, newDefaultSchema('object'));

			return newArr;
		}

		// otherwise treat as literal
		return inferSchema(value);
	}

	// outside of read query operations we're going to travel through
	// the expression object and evaluate each property as an expression
	// and assign its value to the property in a new object.
	const newObj = newDefaultSchema('object');

	const shape = getObjectShape(newObj);

	for (const field in value) {
		if (Object.hasOwnProperty.call(value, field)) {
			if (field.indexOf('.') > -1) {
				throw new Error(ERROR.INVALID_FIELD_NAME_DOT());
			}

			shape[field] = evaluateExpression(context, value[field]);
		}
	}

	return newObj;
}

function evaluateExpressionOperation(context, operator, args, options) {
	const { operationMode } = context;

	if (!operators[operationMode][operator]) {
		throw new Error(ERROR.UNSUPPORTED_OPERATOR(operator));
	}

	return operators[operationMode][operator](context, args, options);
}
