import React from 'react';

import CallMadeIcon from '@mui/icons-material/CallMade';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DataViewsIcon from '@mui/icons-material/Pageview';
import StorageIcon from '@mui/icons-material/Storage';

const data_manager_options = [
	{
		id: 'Data Sources',
		description: 'Manage your data sources',
		text: 'Data Sources',
		route: '/DataSources',
		icon: <StorageIcon />,
		requiredPermissionsAll: {
			dataManager: ['DatasetView'],
		},
	},
	{
		id: 'Data Views',
		description: 'Manage your data views and assign data sources',
		text: 'Data Views',
		route: '/DataViews',
		icon: <DataViewsIcon />,
	},
	{
		id: 'API',
		text: 'API',
		description: 'Manage your API keys',
		route: '/ApiManager',
		icon: <CloudUploadIcon />,
		requiredPermissionsAll: {
			dataManager: ['DatasetView'],
		},
	},
	{
		id: 'Exports',
		description: 'Manage your data exports',
		text: 'Exports',
		route: '/Exports',
		icon: <CallMadeIcon />,
		requiredPermissionsAll: {
			dataManager: ['DatasetEdit'],
		},
	},
];

export default data_manager_options;
