import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useAppContext } from '../../context/ContextManager';

import { useNavContext } from '../../context/ContextManager';

import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from '../../components/generic/CustomAccordion';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';

import {
	ListTable,
	ListRow,
	ListHeader,
	ListCell,
} from '../../components/generic/ListTable';
import ConfigChannels from '../../components/DeviceComponents/ConfigChannels';

import { configHistoryGet, deviceTypeGet } from '../../API';

// Data for landing page selection table and contextual navigation
import IoT_options from './IoTOptions';

import IoT_breadcrumbs from './IoTBreadcrumbs';

import IoT_nav_item from './IoTNavItem';

const styles = makeStyles(theme => ({
	button_row: {},
	root: {
		width: '100%',
		minWidth: 200,
	},
	config_paper: {
		padding: 10,
	},
	expansion_panel: {},
	expansion_panel_summery: {
		backgroundColor: theme.palette.primary.shade_light,
		borderRadius: 8,
		border: '1px solid rgba(0, 0, 0, .25)',
	},
	heading: {
		fontSize: '1.5rem',
		flexBasis: '20%',
		flexShrink: 0,
		fontWeight: 500,
		color: theme.palette.text.secondary,
	},
	secondaryHeading: {
		fontSize: '1.5rem',
		flexGrow: 1,
		fontWeight: 500,
		color: theme.palette.text.secondary,
	},
	thirdHeading: {
		fontSize: '1.5rem',
		flexBasis: '30%',
		flexShrink: 0,
		fontWeight: 500,
		color: theme.palette.text.secondary,
	},
	config_panel: {
		display: 'block',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

export default function DeviceConfig({ match }) {
	const classes = styles();
	const [expanded, setExpanded] = useState(false);
	const [configHistory, setConfigHistory] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const history = useHistory();
	const { appOrg } = useAppContext();
	const [deviceDef, setDeviceDef] = useState({});

	var config_id = match.params.id;

	const handleClickAddConfigRevOpen = config_id => event => {
		history.push('/config/NewRevision/' + config_id);
	};
	const handleChange = panel => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	useEffect(
		() => {
			const onLoad = async () => {
				try {
					let config_data = await configHistoryGet({
						configId: config_id,
					});
					console.log(config_data);
					var latest_ver = 0;
					config_data.versions.forEach(config => {
						if (config.version > latest_ver)
							latest_ver = config.version;
					});

					config_data.versions.sort(
						(a, b) =>
							a.version > b.version
								? -1
								: b.version > a.version
									? 1
									: 0
					);

					setConfigHistory(config_data);

					let devDetails = await deviceTypeGet({
						typeId: config_data.device_type,
					});
					console.log(devDetails);
					setDeviceDef(devDetails);
				} catch (error) {
					console.log(error);
				}
				setIsLoading(false);
			};

			onLoad();
		},
		[config_id]
	);

	// useEffect(
	// 	() => {
	// 		var req_url =
	// 			process.env.REACT_APP_API_SERVER_URL +
	// 			`/v1/config/history/` +
	// 			config_id;
	// 		Auth.currentSession().then(user_session => {
	// 			let user_token = user_session.getAccessToken();
	// 			fetch(req_url, {
	// 				method: 'GET',
	// 				headers: {
	// 					Authorization: user_token.jwtToken,
	// 					Accept: 'application/json',
	// 					'Content-Type': 'application/json',
	// 					Organisation: appOrg.uuid,
	// 				},
	// 			})
	// 				.then(res => res.json())
	// 				.then(response => {
	// 					//console.log(response);
	// 					var latest_ver = 0;
	// 					response.config_data.versions.forEach(config => {
	// 						if (config.version > latest_ver)
	// 							latest_ver = config.version;
	// 					});

	// 					response.config_data.versions.sort(
	// 						(a, b) =>
	// 							a.version > b.version
	// 								? -1
	// 								: b.version > a.version
	// 									? 1
	// 									: 0
	// 					);

	// 					setConfigHistory(response.config_data);
	// 					setIsLoading(false);
	// 				})
	// 				.catch(error => console.log(error));
	// 		});
	// 	},
	// 	[config_id]
	// );

	const { setContextualNav } = useNavContext();
	const { setBreadcrumbs } = useNavContext();

	useEffect(
		() => {
			setBreadcrumbs([
				...IoT_breadcrumbs,
				{ text: 'Config', link: '/Config' },
				{ text: configHistory.name, link: '' },
			]);
		},
		[setBreadcrumbs, configHistory.name]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([...IoT_nav_item, ...IoT_options]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	return (
		<>
			{isLoading && (
				<Paper>
					<p>Loading...</p>
					<CircularProgress size={'120px'} />
				</Paper>
			)}

			{!isLoading && (
				<Paper
					title={'Device Configuration'}
					className={classes.config_paper}
				>
					<div className={classes.root}>
						<h1>Device Config: {configHistory.name}</h1>
						<Container>
							<ListTable>
								<ListRow>
									<ListHeader>Device Type:</ListHeader>
									<ListCell>
										{configHistory.device_type}
									</ListCell>
								</ListRow>
								<ListRow>
									<ListHeader>Description:</ListHeader>
									<ListCell>
										{configHistory.description}
									</ListCell>
								</ListRow>
								<ListRow>
									<ListHeader>Config UUID:</ListHeader>
									<ListCell>{configHistory.uuid}</ListCell>
								</ListRow>
							</ListTable>
						</Container>

						<Divider />
						<Container className={classes.button_row}>
							<Button
								variant="outlined"
								startIcon={<AddCircleIcon />}
								onClick={handleClickAddConfigRevOpen(config_id)}
							>
								Add New Revision
							</Button>
						</Container>
						{configHistory.versions.map((c, index) => (
							<Accordion
								expanded={expanded === 'panel' + index}
								key={index}
								onChange={handleChange('panel' + index)}
							>
								<AccordionSummary
									aria-controls="panel2bh-content"
									id="panel2bh-header"
								>
									<Typography className={classes.heading}>
										{configHistory.name}:{c.version}
									</Typography>
									<Typography
										className={classes.secondaryHeading}
									>
										{configHistory.description}
									</Typography>
									<Typography
										className={classes.thirdHeading}
									>
										Last Updated:{' '}
										{new Date(
											c.create_timestamp
										).toLocaleString()}
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									{configHistory.name && (
										<>
											<ListTable>
												<ListRow>
													<ListHeader>
														Version UUID:
													</ListHeader>
													<ListCell>
														{c.uuid}
													</ListCell>
												</ListRow>
												<ListRow>
													<ListHeader>
														Last Updated:
													</ListHeader>
													<ListCell>
														{new Date(
															c.create_timestamp
														).toLocaleString()}
													</ListCell>
												</ListRow>
											</ListTable>

											<ConfigChannels
												config={c.channels}
												editable={false}
												deviceDef={deviceDef}
											/>
										</>
									)}
								</AccordionDetails>
							</Accordion>
						))}
					</div>
				</Paper>
			)}
		</>
	);
}
