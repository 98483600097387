import React from 'react';
import PropTypes from 'prop-types';

export const WorkflowSection = ({ Parser, template }) => {
	const hasConditions = !!template?.openConditions;
	const { open } = template;

	if (hasConditions && !open) {
		return null;
	}

	return (
		<div style={{ width: '100%' }}>
			<Parser
				key={template.uuid}
				components={template.children}
				Parser={Parser}
			/>
		</div>
	);
};

WorkflowSection.propTypes = {
	Parser: PropTypes.elementType.isRequired,
	template: PropTypes.object.isRequired,
};

export default WorkflowSection;
