import React, { useEffect, useMemo, useState } from 'react';
import { useNavContext } from '../../../context/ContextManager';
import useFetch from '../../../hooks/useFetch';

import AddIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { BtFormContainer } from '../../../components/generic/forms';
import BtLoading from '../../../components/generic/BtLoading';
import BtSelectionTable from '../../../components/generic/BtSelectionTable';
import BtTableSkeleton from '../../../components/generic/BtTableSkeleton';
import NewInsightCollectionDialog from './NewInsightCollectionDialog';
import BtRedactor from '../../../components/BtRedactor';

import { insightCollectionsGetList } from '../../../API';

// Data for landing page selection table and contextual navigation
import insights_options from '../InsightsOptions';
import insights_breadcrumbs from '../InsightsBreadcrumbs';
import insights_nav_item from '../InsightsNavItem';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { UNPUBLISHED } from '../../../components/generic/recordManagement/utils/constants';
import { IconButton } from '@mui/material';
import { Settings } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import useAvailableToUser from '../../../hooks/useAvailableToUser';

export default function InsightCollections() {
	const { setBreadcrumbs } = useNavContext();
	const { setContextualNav } = useNavContext();
	const history = useHistory();
	const checkAvailability = useAvailableToUser();

	const [newCollectionDialog, setNewCollectionDialog] = useState(false);

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				...insights_breadcrumbs,
				{ text: 'Collections', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	const tableColumns = useMemo(
		() => {
			const userCanEdit = checkAvailability({
				requiredPermissionsAll: {
					insights: ['InsightsEdit'],
				},
			}).available;
			const defaultTableFields = [
				{ field: 'name', text: 'Insight Name' },
				{
					field: 'description',
					text: 'Insight Description',
					minBreakpoint: 'md',
				},
				// { field: 'current_version', text: 'Current Versions', minBreakpoint: 'sm' },
				{ field: 'status', text: 'Status' },
			];
			if (userCanEdit) {
				return [
					...defaultTableFields,
					{ field: 'settings', text: 'Settings' },
				];
			} else return defaultTableFields;
		},
		[checkAvailability]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([...insights_nav_item, ...insights_options]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	const {
		loading,
		data: insightCollections,
		error,
		request: getInsightList,
	} = useFetch(insightCollectionsGetList);

	useEffect(
		() => {
			getInsightList();
		},
		[getInsightList]
	);

	const handleEdit = uuid => event => {
		event.stopPropagation();

		history.push(`/InsightCollection/${uuid}/Settings`);
	};

	return (
		<>
			<BtFormContainer title="Insight Collections" maxWidth="lg">
				<BtLoading
					loading={loading}
					LoadingComponent={<BtTableSkeleton hasActionButton />}
				>
					<BtSelectionTable
						title={
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									flexWrap: 'wrap',
									gap: 10,
								}}
							>
								<Typography
									sx={{
										fontWeight: 'bold',
										padding: '4px 0',
									}}
									variant="h5"
								>
									{`Your organisation's Insight Collections`}
								</Typography>
								<BtRedactor
									requiredPermissionsAll={{
										insights: ['InsightsEdit'],
									}}
									validSubscriptionsAny={['Insights Editor']}
								>
									<Button
										variant="contained"
										onClick={() =>
											setNewCollectionDialog(true)
										}
										startIcon={<AddIcon />}
										disableElevation
									>
										Create Collection
									</Button>
								</BtRedactor>
							</div>
						}
						subject="collections"
						columns={tableColumns}
						data={(insightCollections || []).map(collection => ({
							...collection,
							route:
								collection.status === UNPUBLISHED
									? `/InsightCollection/${
											collection.uuid
									  }/settings`
									: `/InsightCollection/${collection.uuid}`,
							settings: (
								<IconButton
									onClick={handleEdit(collection.uuid)}
									size="small"
								>
									<Settings />
								</IconButton>
							),
						}))}
					/>
				</BtLoading>
			</BtFormContainer>
			<NewInsightCollectionDialog
				existingNames={insightCollections?.map(({ name }) => name)}
				open={newCollectionDialog}
				onClose={() => setNewCollectionDialog(false)}
			/>
		</>
	);
}
