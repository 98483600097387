import React, { createContext, useContext, useEffect, useRef } from 'react';
import ChartsProvider from '../contexts/ChartsContexts';
import { ChartContainer } from '../Components/ChartContainer';
import propTypes from 'prop-types';
import useProcessChildren from '../hooks/useProcessChildren';
import { useChartDimensions } from '../hooks/useChartDimensions';

const ComponentsContext = createContext();
export const useComponentsContext = () => useContext(ComponentsContext);

export function Chart({ data, type, children }) {
	const chartRef = useRef(null);
	const chartDimensions = useChartDimensions(chartRef);
	const chartProperties = useProcessChildren(
		children,
		data,
		chartDimensions,
		type
	);

	useEffect(
		() => {
			console.log({ data, type, children });
		},
		[data, type, children]
	);
	return (
		<ChartsProvider data={data} chartType={type}>
			<div
				ref={chartRef}
				style={{ height: '100%', width: '100%', cursor: 'pointer' }}
			>
				<ComponentsContext.Provider value={chartProperties}>
					<ChartContainer>{children}</ChartContainer>
				</ComponentsContext.Provider>
			</div>
		</ChartsProvider>
	);
}

ChartContainer.defaultProps = {
	children: null,
	data: [],
	type: 'line',
};

ChartContainer.propTypes = {
	data: propTypes.array, // TODO: add shape validation for data depending on chart type
	type: propTypes.string,
	children: propTypes.node,
};
