import React, {
	useState,
	useEffect,
	createContext,
	useContext,
	useCallback,
	useMemo,
	useRef,
} from 'react';
import { Auth } from 'aws-amplify';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { userGet, organisationGet } from '../API';
import { AuthContextProvider } from './AuthContext';
import { SocketProvider } from './SocketContext/SocketContext';

import BtError from '../components/generic/BtError';
import { SettingsCellSharp } from '@mui/icons-material';

import { format } from 'date-fns';
import { DataProvider } from './DataProvider/DataProvider';

import { getAppStoreItem } from '../utils/appStore';

// Main app context for infrequent changes like user
const AppContext = createContext(null);

// Nav app context for navigation changes
const NavContext = createContext(null);

const useAppContext = () => {
	const context = useContext(AppContext);
	if (context === undefined) {
		throw new Error('useAppContext was used outside of its Provider');
	}
	return context;
};

const useNavContext = () => {
	const context = useContext(NavContext);
	if (context === undefined) {
		throw new Error('useNavContext was used outside of its Provider');
	}
	return context;
};

function ContextManagerProvider({ children }) {
	const [localDevMode, setLocalDevMode] = useState(false);
	const [devUser, setDevUser] = useState(null);
	const [loadApp, setLoadApp] = useState(true);
	const [refreshUser, setRefreshUser] = useState(true);
	const [isAuthenticated, userHasAuthenticated] = useState(false);
	const [userInfo, setUserInfo] = useState(null);
	const [appOrg, setAppOrg] = useState(null);
	const [currentOrgUuid, setCurrentOrgUuid] = useState(null);
	const [activityIndicator, setActivityIndicator] = useState(false);
	const [establishedAccess, setEstablishedAccess] = useState(false);
	const [userError, setUserError] = useState(false);
	const [currentPage, setCurrentPage] = useState(null);
	const [showMobile, setShowMobile] = useState(false);
	const [forceDesktopMode, setForceDesktopMode] = useState(false);
	const [breadcrumbs, setBreadcrumbs] = useState([
		{
			text: 'Home',
			link: '',
		},
	]);
	const [contextualNav, setContextualNav] = useState(null);
	const [deviceType, setDeviceType] = useState(null);
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [swipeDrawerOpen, setSwipeDrawerOpen] = useState(false);

	// const timeout = ms => {
	// 	return new Promise(resolve => setTimeout(resolve, ms));
	// };

	// Set the device type
	useEffect(() => {
		// console.log('setDeviceType');
		if (isMobileOnly) {
			setDeviceType('mobile');
		} else if (isTablet) {
			setDeviceType('tablet');
		} else {
			setDeviceType('desktop');
		}
	}, []);

	// Set appOrg
	// Either get current org from localStorage or if localStorage value is null
	// then set to the first org in user's org list
	// useRef prevents execution on initial render
	const initialRender = useRef(true);
	useEffect(
		() => {
			if (initialRender.current) {
				initialRender.current = false;
			} else {
				if (isAuthenticated && userInfo) {
					try {
						const org_uuid = localStorage.getItem('OrgUuid');

						if (!org_uuid) {
							try {
								setCurrentOrgUuid(
									userInfo.organisations[0].uuid
								);
							} catch (error) {
								console.log(error.message);
							}
						} else {
							// setAppOrg({
							// 	name: org_name,
							// 	uuid: org_uuid,
							// });
							setCurrentOrgUuid(org_uuid);
						}
					} catch (error) {
						setUserError(true);
						console.log(error);
					} finally {
						// console.log(appOrg, currentOrgUuid);
					}
				}
			}
		},
		[isAuthenticated, userInfo]
	);

	const getUserInfo = useCallback(
		async () => {
			setUserInfo(null);

			// Get the user data from the API and add to context
			try {
				setUserError(false);

				var cognitoUser = {};
				if (
					process.env.REACT_APP_API_SERVER_URL.includes('localhost')
				) {
					setLocalDevMode(true);
					const testUser = await getAppStoreItem('DevUser');
					if (devUser) {
						cognitoUser = {
							username: devUser,
						};
					} else if (testUser) {
						cognitoUser = {
							username: testUser,
						};
					} else {
						throw new Error('Dev User Not Set');
					}
				} else {
					cognitoUser = await Auth.currentAuthenticatedUser({
						bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
					});
				}

				// Load the user's info and organisations from the API
				const user_data = await userGet();

				// Terminates the loader
				setRefreshUser(false);

				userHasAuthenticated(true);

				// Add the details to the user info in context
				setUserInfo({
					uuid: cognitoUser.username,
					first_name: user_data.user.first_name,
					last_name: user_data.user.last_name,
					email: user_data.user.email,
					initials: user_data.user.initials,
					user_name: user_data.user.user_name,
					mobile_required: user_data.user.mobile_required,
					mobile_phone: user_data.user.mobile_phone,
					status: user_data.user.status,
					organisations: user_data.organisations,
				});
			} catch (error) {
				setRefreshUser(false);
				setLoadApp(false);
			}
			setCurrentPage('Home');
		},
		[devUser]
	);

	// Watches if the user is authenticated
	// If the state changes then a call is made to execute user GET request
	useEffect(
		() => {
			try {
				getUserInfo();
			} catch (error) {
				console.log(error);
			}
		},
		[getUserInfo, isAuthenticated]
	);

	// Downloads and stores the currently selected organisations details
	useEffect(
		() => {
			const loadOrg = async () => {
				if (currentOrgUuid) {
					// Set the new value in local storage
					localStorage.setItem('OrgUuid', currentOrgUuid);
					try {
						let org = await organisationGet(currentOrgUuid);
						setAppOrg(org);
					} catch (error) {
						localStorage.removeItem('OrgUuid', null);
					}
				}
			};
			loadOrg();
		},
		[currentOrgUuid]
	);

	// Get the force desktop mode flag from local storage
	const theme = useTheme();
	const screen_downSm = useMediaQuery(theme.breakpoints.down('sm'));
	useEffect(() => {
		let storedValue = localStorage.getItem('desktopMode');
		if (storedValue === 'forced') {
			setForceDesktopMode(true);
		} else {
			setForceDesktopMode(false);
		}
	});

	useEffect(
		() => {
			// If the screen size is small ignore the users request for desktop
			if (screen_downSm) {
				setShowMobile(true);
			} else {
				// If the screen is not small check the users setting
				if (forceDesktopMode) {
					setShowMobile(false);
				} else {
					// User setting is auto so set it based on what the browser type is
					if (isMobile) {
						setShowMobile(true);
					} else {
						setShowMobile(false);
					}
				}
			}
		},
		[screen_downSm, forceDesktopMode]
	);

	const getOrgFormattedTimestamp = (timestamp, includeTime) => {
		if (!timestamp) {
			return '';
		}

		var dateTimeFormat = 'dd/MM/yyyy'; // Set an intial format value
		try {
			dateTimeFormat = appOrg.regionalSettings.dateFormat;
		} catch (error) {
			throw new Error('Error getting org timeformat');
		}

		if (includeTime) {
			dateTimeFormat = dateTimeFormat.concat(', hh:mm a');
		}

		const formattedDate = format(new Date(timestamp || 0), dateTimeFormat);

		return formattedDate;
	};

	// Display app loading spinner until ready
	const masterLoading = useMemo(
		() => {
			if (loadApp) {
				return !(
					isAuthenticated &&
					userInfo &&
					appOrg &&
					establishedAccess &&
					deviceType
				);
				// Flag to show loader if the app is retrieving the user details
			} else if (refreshUser) {
				return true;
			}
			return false;
		},
		[
			loadApp,
			refreshUser,
			isAuthenticated,
			userInfo,
			appOrg,
			establishedAccess,
			deviceType,
		]
	);

	if (userError) {
		return (
			<BtError
				variant="error"
				title={'Account Error'}
				description={`Sorry, we were unable to retrieve your user information. Please contact your administrator.`}
				action={getUserInfo}
				style={{ minHeight: '100vh' }}
			/>
		);
	}

	return (
		<AppContext.Provider
			value={{
				isAuthenticated,
				userHasAuthenticated,
				userInfo,
				setUserInfo,
				appOrg,
				setAppOrg,
				currentOrgUuid,
				setCurrentOrgUuid,
				activityIndicator,
				setActivityIndicator,
				masterLoading,
				loadApp,
				setLoadApp,
				setUserError,
				forceDesktopMode,
				setForceDesktopMode,
				showMobile,
				localDevMode,
				setDevUser,
				getOrgFormattedTimestamp,
				deviceType,
				drawerOpen,
				setDrawerOpen,
				swipeDrawerOpen,
				setSwipeDrawerOpen,
			}}
		>
			<SocketProvider>
				<DataProvider>
					<AuthContextProvider
						orgId={appOrg?.uuid}
						setEstablishedAccess={setEstablishedAccess}
						userId={userInfo?.uuid}
					>
						<NavContext.Provider
							value={{
								currentPage,
								setCurrentPage,
								breadcrumbs,
								setBreadcrumbs,
								contextualNav,
								setContextualNav,
							}}
						>
							{children}
						</NavContext.Provider>
					</AuthContextProvider>
				</DataProvider>
			</SocketProvider>
		</AppContext.Provider>
	);
}

export { ContextManagerProvider, AppContext, useAppContext, useNavContext };
