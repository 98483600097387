import { apiClient } from '../api_client';

import {
	dataStreamGetSchema,
	dataStreamAddSchema,
	dataStreamAddResponseSchema,
} from '../validations/dataStreamValidation';

//========================================================================================================
export async function dataStreamGet({ dataStreamUuid }) {
	const req_url = `data_stream/${dataStreamUuid}`;
	try {
		const response = await apiClient({ method: 'get', url: req_url });
		console.log(response);
		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				return await dataStreamGetSchema.validate(response.data_stream);
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
export async function dataStreamAdd({ newDataStream }) {
	// Validate the new Data Set
	try {
		await dataStreamAddSchema.validate(newDataStream);
	} catch (error) {
		throw error;
	}

	const req_url = 'data_stream';
	try {
		const response = await apiClient({
			method: 'post',
			url: req_url,
			body: newDataStream,
		});

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				await dataStreamAddResponseSchema.validate(response.data_set);
				return response.data_set;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw error;
	}
}
