import React, { useEffect, useMemo, useState, useRef } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Icon from '@mdi/react';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { Box, IconButton, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import _ from 'lodash';

import BtConfirmDialog from '../../components/generic/BtConfirmDialog';
import BtFormContainer from '../../components/generic/forms/BtFormContainer';
import IconResolver from '../../utils/iconResolver';
import { useNavContext, useAppContext } from '../../context/ContextManager';
import workflow_nav_item from './WorkflowsNavItem';
import workflow_options from './WorkflowsOptions';
import { workflowSubmissionGetList, workflowSubmissionDelete } from '../../API';

import useAvailableToUser from '../../hooks/useAvailableToUser';
import BtRemoteTable from '../../components/generic/BtRemoteTable';

export default function Workflows() {
	const { appOrg } = useAppContext();
	const checkAvailability = useAvailableToUser();
	const { enqueueSnackbar } = useSnackbar();
	const { setContextualNav } = useNavContext();
	const { setBreadcrumbs } = useNavContext();
	const tableRef = useRef();

	const [deleteDialog, setDeleteDialog] = useState(false);
	const [deleteUuid, setDeleteUuid] = useState(null);

	const history = useHistory();

	// Create a columns list including the delete if the user has access
	const redactedTableColumns = useMemo(
		() => {
			const tableColumns = [
				{
					field: 'templateGroup',
					searchable: false,
					text: 'Group',
					minBreakpoint: 'md',
					CellContent: (item, row) => {
						return (
							<Box>
								<div>
									<Icon
										path={IconResolver(
											_.find(
												appOrg?.workflow_template_groups,
												{
													uuid: row.template?.group,
												}
											)?.icon
										)}
										size={1.5}
										color={
											_.find(
												appOrg?.workflow_template_groups,
												{
													uuid: row.template?.group,
												}
											)?.colour
										}
									/>
								</div>
								<div>
									<Typography
										variant="body"
										sx={{
											color: _.find(
												appOrg?.workflow_template_groups,
												{ uuid: row.template?.group }
											).colour,
										}}
									>
										{' '}
										{
											_.find(
												appOrg?.workflow_template_groups,
												{
													uuid: row.template?.group,
												}
											).name
										}{' '}
									</Typography>
								</div>
							</Box>
						);
					},
				},
				{
					field: 'primary_answer',
					text: 'Title',
					minBreakpoint: 'sm',
					CellContent: (item, row) => row.primary_answer || '-',
				},
				{
					field: 'nameDescription',
					text: 'Name & Description',
					minBreakpoint: 'sm',
					CellContent: (item, row) => (
						<div>
							<Typography
								sx={{ fontWeight: 'bold' }}
								variant="body2"
							>
								{row.template.name}
							</Typography>
							<Typography variant="body2">
								{row.template.description || '-'}
							</Typography>
						</div>
					),
				},
				{
					field: 'created',
					text: 'Created',
					minBreakpoint: 'md',
					CellContent: (item, row) => (
						<Box>
							<div>{row.create_user?.user_name}</div>
							<div>
								{format(
									new Date(row.create_timestamp || 0),
									'dd MMM yyyy, hh:mm a'
								)}
							</div>
						</Box>
					),
				},
				{
					field: 'status',
					text: 'Status',
					minBreakpoint: 'sm',
					CellContent: (item, row) => row.status.status,
				},
				{
					field: 'editable',
					text: 'Editor',
					minBreakpoint: 'md',
					CellContent: (item, row) =>
						row.isEditable ? <EditIcon /> : <VisibilityIcon />,
				},
				{
					field: 'dueDate',
					text: 'Due Date',
					minBreakpoint: 'sm',
					CellContent: (item, row) =>
						row.due_date
							? format(
									new Date(row.create_timestamp || 0),
									'dd MMM yyyy, hh:mm a'
							  )
							: '-',
				},
			];

			if (
				checkAvailability({
					requiredPermissionsAll: {
						workflows: ['SubmissionDelete'],
					},
				}).available
			) {
				return [
					...tableColumns,
					{
						field: 'delete',
						text: 'Delete',
						minBreakpoint: 'md',
						CellContent: (item, row) =>
							row.isDeletable ? (
								<IconButton
									onClick={onDeleteClick(row.uuid)}
									size="small"
								>
									<DeleteIcon />
								</IconButton>
							) : (
								''
							),
					},
				];
			} else {
				return tableColumns;
			}
		},
		[checkAvailability, appOrg.workflow_template_groups]
	);

	//Boilerplate for future implementation set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([...workflow_nav_item, ...workflow_options]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'Workflows', link: '/Workflows' },
				{ text: 'Submissions', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	const onDeleteClick = uuid => async event => {
		event.stopPropagation();

		setDeleteUuid(uuid);
		setDeleteDialog(true);
	};

	const handleDelete = async () => {
		try {
			await workflowSubmissionDelete({
				workflowSubmissionUuid: deleteUuid,
			});

			enqueueSnackbar('Submission deleted', {
				variant: 'success',
			});

			tableRef.current.triggerTableRefresh();
		} catch (error) {
			enqueueSnackbar('Failed to delete submission', {
				variant: 'error',
			});
		}
	};

	const onSubmissionClick = ({ uuid }) => {
		history.push(`/Workflows/Submissions/${uuid}`);
	};

	return (
		<>
			<BtFormContainer
				maxWidth="lg"
				style={{ marginBottom: '2em' }}
				title="Workflow Submissions"
			>
				<BtRemoteTable
					title={
						<Typography
							sx={{
								fontWeight: 'bold',
								padding: '4px 0',
							}}
							variant="h5"
						>
							Your Workflow Submissions
						</Typography>
					}
					apiRequest={workflowSubmissionGetList}
					// apiRequestProps={}
					columns={redactedTableColumns}
					enableSearching
					enableGeneralSorting
					enablePagination
					idField="uuid"
					stickyHeader
					subject="Workflow Submissions"
					onClick={onSubmissionClick}
					tableId="workflow_submissions"
					ref={tableRef}
				/>
			</BtFormContainer>

			<BtConfirmDialog
				action={handleDelete}
				isDestructive
				open={deleteDialog}
				onClose={() => setDeleteDialog(false)}
				title="Delete Submission?"
				prompt={
					'Are you sure you want to delete this Workflow Submission?'
				}
				verb="Delete Submission"
				ActionIcon={<DeleteIcon />}
			/>
		</>
	);
}
