import React from 'react';
// import PropTypes from 'prop-types';

import { Box, Typography } from '@mui/material';
import {
	BtFormCheckbox,
	BtFormTextField,
	withFormContextMethods,
} from '../../../../../../../components/generic/forms';
import { ParamsForm } from './ParamsForm';
import _, { has } from 'lodash';
import {
	VALUE_PRIMARY_AXIS,
	VALUE_SECONDARY_AXIS,
	ValueAxisForm,
} from './ValueAxisForm';
import { ORDINAL_AXIS, OrdinalAxisForm } from './OrdinalAxisForm';
import { VisOptionsFormSectionWrapper } from './VisOptionsFormSectionWrapper';
import { VisOptionsFormSection } from './VisOptionsFormSection';

export const VisOptionsForm = () => {
	const formComponentGetter = visOptionKey => {
		const isVisOptionKeyValid = has(visOptionsFormComponents, visOptionKey);
		if (!isVisOptionKeyValid) {
			return (
				<Typography>
					{<em>{`no form component defined for ${visOptionKey}`}</em>}
				</Typography>
			);
		} else {
			return visOptionsFormComponents[visOptionKey];
		}
	};

	const visOptionsFormComponents = {
		params: <ParamsForm />,

		legend: (
			<BtFormCheckbox
				label="Disable Legend"
				name={`vis_options.legend.disabled`}
			/>
		),
		[VALUE_PRIMARY_AXIS]: <ValueAxisForm axis={VALUE_PRIMARY_AXIS} />,
		[VALUE_SECONDARY_AXIS]: <ValueAxisForm axis={VALUE_SECONDARY_AXIS} />,
		ordinalAxis: <OrdinalAxisForm />,
		text: (
			<BtFormTextField
				multiline
				name="vis_options.text"
				label="Information Text"
				variant="standard"
			/>
		),
		label: <VisOptionsFormSection visOption="label" />,
	};

	const VisOptionFormComponent = ({ visOptionKey }) => {
		// console.log(visOptionKey);
		switch (visOptionKey) {
			case 'params':
				return <ParamsForm />;
			case VALUE_PRIMARY_AXIS:
				return <ValueAxisForm axis={VALUE_PRIMARY_AXIS} />;
			case VALUE_SECONDARY_AXIS:
				return <ValueAxisForm axis={VALUE_SECONDARY_AXIS} />;
			case 'text':
				return (
					<BtFormTextField
						multiline
						name="vis_options.text"
						label="Information Text"
						variant="standard"
					/>
				);
			case ORDINAL_AXIS:
				return <OrdinalAxisForm />;
			default:
				// console.log(`default case for ${visOptionKey}`);
				// return null;
				return <VisOptionsFormSection visOption={visOptionKey} />;
		}
		// return formComponentGetter(visOptionKey);
	};

	const VisTypeOptionsController = withFormContextMethods(({ getValues }) => {
		const visOptions = getValues('vis_options');

		return (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					// gap: '10px',
				}}
			>
				{/* <Typography>vis_options:</Typography> */}
				{Object.keys(visOptions)
					.toSorted()
					.map(optionKey => (
						<VisOptionsFormSectionWrapper
							title={optionKey}
							key={optionKey}
						>
							<VisOptionFormComponent visOptionKey={optionKey} />
						</VisOptionsFormSectionWrapper>
					))}
			</Box>
		);
	});

	return (
		<>
			<VisTypeOptionsController />
		</>
	);
};

VisOptionsForm.propTypes = {};
