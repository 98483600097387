import React, { useEffect, useState, useMemo } from 'react';
import { v1 as uuidv1 } from 'uuid';
import _ from 'lodash';
import { styled } from '@mui/material/styles';

import { useTheme } from '@mui/material/styles';

import WorkflowTemplateComponentDialog from './WorkflowTemplateComponentDialog';

// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {
	Typography,
	Button,
	TextField,
	Container,
	IconButton,
	Box,
	Select,
	MenuItem,
	InputLabel,
	ListItem,
	DialogContent,
	DialogTitle,
	DialogActions,
	Paper,
} from '@mui/material';

import AddIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/SaveAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';

import BtDetailSection from '../../../components/generic/BtDetailSection';
import BtListComposer from '../../../components/generic/BtListComposer';
import BtUserRow from '../../../components/generic/BtUserRow';
import BtUserList from '../../../components/generic/BtUserList';
import BtUserGroupList from '../../../components/generic/BtUserGroupList';
import BtConfirmDialog from '../../../components/generic/BtConfirmDialog';

import useFetch from '../../../hooks/useFetch';
import { userAdminGetList } from '../../../API/user_admin';
import produce from 'immer';
import BtSelectionTable from '../../../components/generic/BtSelectionTable';

import * as yup from 'yup';
import BtDialog from '../../../components/generic/BtDialog';

import {
	BtForm,
	BtFormActionsContainer,
	BtFormContent,
	BtFormCheckbox,
	BtFormTextField,
	BtFormSelect,
	withFormContextMethods,
	BtFormLateralContainer,
	BtFormSection,
} from '../../../components/generic/forms';
import { BtTabs } from '../../../components/generic/tabs/BtTabs';
import BtApiKeyList from '../../../components/generic/BtApiKeyList';

const AllStates = [
	{ value: 'Draft', label: 'Draft' },
	{ value: 'NotStarted', label: 'Not Started' },
	{ value: 'Assigned', label: 'Assigned' },
	{ value: 'InProgress', label: 'In Progress' },
	{ value: 'Submitted', label: 'Submitted' },
	{ value: 'Validation', label: 'Validation' },
	{ value: 'Review', label: 'Review' },
	{ value: 'Rejected', label: 'Rejected' },
	{ value: 'Deleted', label: 'Deleted' },
	{ value: 'Completed', label: 'Completed' },
];

function EditNextStateDialog({
	dialogOpen,
	onClose,
	availableStates,
	handleAddState,
	currentNextState,
}) {
	const schema = yup.object({
		state: yup.string().required(),
		text: yup.string(),
		//default: yup.boolean().required(),
		confirm: yup.boolean().required(),
	});

	const [newState, setNewState] = useState({
		nextState: '',
		text: '',
		confirm: false,
	});

	useEffect(
		() => {
			if (currentNextState) {
				setNewState({
					...currentNextState,
				});
			} else if (availableStates) {
				console.log(availableStates);
				setNewState({
					state: availableStates[0]?.value,
					text: availableStates[0]?.label,
					confirm: false,
				}); // Set the initial state
			}
		},
		[availableStates, currentNextState]
	);

	const onSubmit = formData => {
		handleAddState({
			...formData,
			default: currentNextState?.default || false,
		});
		onClose();
	};

	return (
		<BtDialog open={dialogOpen} onClose={onClose} minwidth="sm" fullWidth>
			{currentNextState ? (
				<DialogTitle>Edit State</DialogTitle>
			) : (
				<DialogTitle>Add State</DialogTitle>
			)}

			<BtForm
				onSubmit={onSubmit}
				// sending={sending}
				validationSchema={schema}
				defaultValues={newState || {}}
			>
				<DialogContent>
					<BtFormContent>
						{currentNextState ? (
							<Typography variant="body">
								Next State: {newState.state}
							</Typography>
						) : (
							<BtFormSelect
								name="state"
								label="Next State"
								items={availableStates || []}
							/>
						)}
						<BtFormTextField
							name="text"
							label="User Message"
							autoFocus
						/>

						<BtFormCheckbox
							name="confirm"
							label="Require Confirmation on changing to this state"
							style={{ marginRight: '1em' }}
						/>
					</BtFormContent>
				</DialogContent>
				<DialogActions>
					<BtFormActionsContainer>
						<Button variant="outlined" onClick={onClose}>
							Cancel
						</Button>
						<Button
							variant="contained"
							type="submit"
							startIcon={<SaveIcon />}
							disableElevation
						>
							Apply Changes
						</Button>
					</BtFormActionsContainer>
				</DialogActions>
			</BtForm>
		</BtDialog>
	);
}

function StateAccessEditor({ accessType, accessObj, onUpdate }) {
	const onViewerUserListUpdate = newUserList => {
		onUpdate({ ...accessObj, users: newUserList });
	};

	const onViewerUserGroupListUpdate = newUserGroupList => {
		onUpdate({ ...accessObj, groups: newUserGroupList });
	};

	const onViewerApiKeyListUpdate = newApiKeyList => {
		console.log('onViewerApiKeyListUpdate', newApiKeyList);
		onUpdate({ ...accessObj, apiKeys: newApiKeyList });
	};

	const addViewerVariableOption = addVarOption => {
		const update = { ...accessObj };
		update.variables.push(addVarOption.value);
		onUpdate(update);
	};
	const removeViewerVariableOption = removeVarOption => {
		const update = { ...accessObj };
		update.variables = update.variables.filter(
			varOption => varOption !== removeVarOption
		);
		onUpdate(update);
	};

	const varOptions = [
		{ value: 'CreateUser', label: 'Creating User' },
		{ value: 'CreateLineManager', label: 'Line Manager' },
		{ value: 'AssignedUser', label: 'Assigned User' },
	];

	return (
		<BtFormSection>
			<Typography variant="h5">{accessType}</Typography>

			<Typography variant="subtitle">Users</Typography>
			<BtUserList
				currentUsers={accessObj?.users}
				onUserListUpdate={onViewerUserListUpdate}
			/>

			<Typography variant="subtitle">User Groups</Typography>
			<BtUserGroupList
				currentUserGroups={accessObj?.groups}
				onUserGroupListUpdate={onViewerUserGroupListUpdate}
			/>

			<Typography variant="subtitle">Service API</Typography>
			<BtApiKeyList
				currentApiKeys={accessObj?.apiKeys}
				onApiKeyListUpdate={onViewerApiKeyListUpdate}
			/>

			<Typography variant="subtitle">Dynamic Users</Typography>
			<BtListComposer
				allItems={varOptions}
				confirmDeletion
				deleteable
				deleteVerb="Remove"
				items={accessObj?.variables}
				primaryField="value"
				DeleteIcon={<DeleteIcon />}
				sortComparator={(a, b) => a?.label?.localeCompare(b?.label)}
				subject="user group"
				onItemAdd={addViewerVariableOption}
				onItemDelete={removeViewerVariableOption}
			/>
		</BtFormSection>
	);
}

export default function WorkflowTemplateState({
	state,
	handleDeleteState,
	handleUpdateState,
	handleStateChange,
	stateList,
}) {
	const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
	const [editStateDialogOpen, setEditStateDialogOpen] = useState(false);
	const [availableNextStates, setAvailableNextStates] = useState([]);
	const [editNextState, setEditNextState] = useState(null);
	const [defaultNextState, setDefaultNextState] = useState('');
	const theme = useTheme();

	useEffect(
		() => {
			const defaultNext = _.find(state.next, { default: true });
			console.log(defaultNext);
			defaultNext
				? setDefaultNextState(defaultNext.state)
				: setDefaultNextState('');
		},
		[state]
	);

	const handleEditNext = nextState => event => {
		const tempAvailableNextStates = _.filter(AllStates, stateOption => {
			return !_.find(state.next, { state: stateOption.value });
		});

		nextState
			? setEditNextState(_.find(state.next, { state: nextState }))
			: setEditNextState(null);

		setAvailableNextStates(tempAvailableNextStates);
		setEditStateDialogOpen(true);
	};

	const handleDeleteNext = nextStateDelete => event => {
		const updatedState = produce(state, draft => {
			draft.next.splice(
				_.findIndex(draft.next, {
					state: nextStateDelete,
				}),
				1
			);

			if (!_.find(draft.next, { default: true })) {
				if (draft.next.length === 0) {
					setDefaultNextState('');
				} else {
					setDefaultNextState(draft.next[0].state);
					draft.next[0].default = true;
				}
			}
		});

		handleUpdateState(updatedState);
	};

	const handleAddEditNextDone = nextState => {
		try {
			// Check if this is an edit of an add
			const nextIndex = _.findIndex(state.next, {
				state: nextState.state,
			});

			if (nextIndex >= 0) {
				handleUpdateState(
					produce(state, draft => {
						draft.next[nextIndex] = nextState;
					})
				);
			} else {
				handleUpdateState(
					produce(state, draft => {
						draft.next.push(nextState);
					})
				);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleDefaultNextState = newDefaultNextState => {
		setDefaultNextState(newDefaultNextState);

		handleUpdateState(
			produce(state, draft => {
				draft.next.forEach(nextState => {
					nextState.state === newDefaultNextState
						? (nextState.default = true)
						: (nextState.default = false);
				});
			})
		);
	};

	return (
		<>
			{state && (
				<Container
					maxWidth="lg"
					style={{
						backgroundColor: theme.palette.background.insights,
						paddingBottom: 16,
					}}
				>
					<Button
						sx={{ margin: '1rem' }}
						variant="outlined"
						startIcon={<DeleteIcon />}
						onClick={() => {
							setConfirmDeleteOpen(true);
						}}
					>
						Delete State
					</Button>

					<Typography variant="h3">{state.state}</Typography>
					<LayoutContainer>
						<Paper style={{ width: '50%', padding: 16 }}>
							<BtFormSection>
								<Typography variant="h5">
									Next States
								</Typography>

								<Button
									sx={{ margin: '1rem' }}
									variant="outlined"
									startIcon={<AddIcon />}
									onClick={handleEditNext(null)}
								>
									Add Next State
								</Button>

								<BtSelectionTable
									columns={[
										{ field: 'state', text: 'State' },
										{ field: 'text', text: 'Text' },
										{ field: 'edit', text: 'Edit' },
										{ field: 'delete', text: 'Delete' },
									]}
									data={(state.next || []).map(
										({ state, text }) => {
											return {
												id: state,
												state: state,
												text: text,
												edit: (
													<IconButton
														size="small"
														onClick={handleEditNext(
															state
														)}
													>
														<EditIcon />
													</IconButton>
												),
												delete: (
													<IconButton
														size="small"
														onClick={handleDeleteNext(
															state
														)}
													>
														<DeleteIcon />
													</IconButton>
												),
											};
										}
									)}
								/>

								<InputLabel id="defaultNextId">
									Default Next State
								</InputLabel>
								<Select
									name="rootPage"
									label="Input Type"
									variant="standard"
									sx={{ width: '100%' }}
									value={defaultNextState}
									onChange={event =>
										handleDefaultNextState(
											event.target.value
										)
									}
									labelId="defaultNextId"
								>
									{(state.next || []).map(({ state }) => (
										<MenuItem value={state} key={state}>
											{state}
										</MenuItem>
									))}
								</Select>
							</BtFormSection>
						</Paper>

						<Paper style={{ width: '50%', padding: 16 }}>
							<BtTabs
								top={true}
								tabs={[
									{
										label: 'Viewers',
										value: '1',
										id: 'Viewers',
										icon: <VisibilityIcon />,
										content: (
											<StateAccessEditor
												accessType="Viewers"
												accessObj={state?.viewers}
												onUpdate={updatedViewers => {
													handleUpdateState({
														...state,
														viewers: updatedViewers,
													});
												}}
											/>
										),
									},
									{
										label: 'Editors',
										value: '2',
										id: 'Editors',
										icon: <EditIcon />,
										content: (
											<StateAccessEditor
												accessType="Editors"
												accessObj={state?.editors}
												onUpdate={updatedEditors => {
													handleUpdateState({
														...state,
														editors: updatedEditors,
													});
												}}
											/>
										),
									},
									{
										label: 'Deleters',
										value: '3',
										id: 'Deleters',
										icon: <DeleteIcon />,
										content: (
											<StateAccessEditor
												accessType="Deleters"
												accessObj={state?.deleters}
												onUpdate={updatedDeleters => {
													handleUpdateState({
														...state,
														deleters: updatedDeleters,
													});
												}}
											/>
										),
									},
								]}
							/>
						</Paper>
					</LayoutContainer>

					<EditNextStateDialog
						dialogOpen={editStateDialogOpen}
						onClose={() => {
							setEditStateDialogOpen(false);
						}}
						availableStates={availableNextStates}
						handleAddState={handleAddEditNextDone}
						currentNextState={editNextState}
					/>
				</Container>
			)}

			<BtConfirmDialog
				title="Delete state"
				action={() => {
					handleDeleteState(state);
				}}
				ActionIcon={<DeleteIcon />}
				onClose={() => {
					setConfirmDeleteOpen(false);
				}}
				open={confirmDeleteOpen}
				prompt={`Are you sure you wish to delete state '${
					state.state
				}'?`}
				verb="Delete"
			/>
		</>
	);
}

const LayoutContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	gap: '20px',
	width: '100%',
	flexDirection: 'row',
}));
