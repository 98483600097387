const yup = require('yup');

const palette = yup.object({
	name: yup.string().required(),
	type: yup
		.string()
		.oneOf(['platform', 'insights'])
		.required(),
	uuid: yup
		.string()
		.uuid()
		.required(),
	palette: yup
		.array()
		.of(
			yup.object({
				hex: yup.string().required(),
				uuid: yup
					.string()
					.uuid()
					.required(),
				name: yup.string().required(),
			})
		)
		.required(),
	status: yup
		.string()
		.oneOf(['active', 'archived'])
		.required(),
});

const orgDetailsGetSchema = yup.object({
	generalInfo: yup.object({
		displayName: yup.string().default(''),
		legalTradingName: yup.string().default(''),
		organisationType: yup.string().default(''),
		regNo: yup.string().default(''),
		isVatRegistered: yup.bool().default(false),
		vatRegNo: yup.string().default(''),
	}),
	contactInfo: yup.object({
		contactName: yup.string().default(''),
		contactEmail: yup.string().default(''),
		telephone: yup.string().default(''),
		address: yup.string().default(''),
		townCity: yup.string().default(''),
		regionState: yup.string().default(''),
		postalZipCode: yup.string().default(''),
	}),
	regionalSettings: yup.object({
		currency: yup
			.string()
			.min(3)
			.max(6)
			.default('GBP'),
		dateFormat: yup
			.string()
			.min(3)
			.max(20)
			.default('dd/mm/yyyy'),
		firstWeekDay: yup
			.string()
			.min(3)
			.max(20)
			.default('MONDAY'),
		numberFormat: yup
			.string()
			.min(3)
			.max(20)
			.default('1,000,000.00'),
	}),
	theme: yup.object({
		palettes: yup.array().of(palette),
	}),
});

const orgDetailsPutSchema = yup.object({
	generalInfo: yup.object({
		displayName: yup
			.string()
			.min(3)
			.max(30)
			.optional(),
		legalTradingName: yup
			.string()
			.min(3)
			.max(60)
			.optional(),
		organisationType: yup
			.string()
			.min(3)
			.max(20)
			.optional(),
		regNo: yup
			.string()
			.max(20)
			.optional(),
		isVatRegistered: yup.bool().optional(),
		vatRegNo: yup.string().when('isVatRegistered', {
			is: true,
			then: yup
				.string()
				.min(10)
				.max(10),
		}),
	}),
	contactInfo: yup.object({
		contactName: yup
			.string()
			.min(3)
			.max(30)
			.optional(),
		contactEmail: yup
			.string()
			.email()
			.optional(),
		telephone: yup
			.string()
			.min(3)
			.max(20)
			.optional(),
		address: yup
			.string()
			.min(3)
			.max(20)
			.optional(),
		townCity: yup
			.string()
			.min(3)
			.max(20)
			.optional(),
		regionState: yup
			.string()
			.min(2)
			.max(20)
			.optional(),
		postalZipCode: yup
			.string()
			.min(4)
			.max(10)
			.optional(),
	}),
	regionalSettings: yup.object({
		currency: yup
			.string()
			.min(3)
			.max(6)
			.optional(),
		dateFormat: yup
			.string()
			.min(3)
			.max(20)
			.optional(),
		firstWeekDay: yup
			.string()
			.min(3)
			.max(20)
			.optional(),
		numberFormat: yup
			.string()
			.min(3)
			.max(20)
			.optional(),
	}),
	theme: yup
		.object({
			palettes: yup.array().of(palette),
		})
		.optional(),
});

module.exports = {
	orgDetailsGetSchema,
	orgDetailsPutSchema,
};
