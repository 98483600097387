import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Box, CircularProgress, FormLabel, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

export default function WorkflowLabel({
	fetching,
	label,
	name,
	placeholder,
	value,
}) {
	const { watch } = useFormContext() || { formState: {} };

	const formValue = watch(name);

	const valueToRender = useMemo(() => formValue || value || '', [
		formValue,
		value,
	]);

	if (!(label || valueToRender || placeholder)) {
		return null;
	}

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			{!!label && (
				<FormLabel
					style={{ marginTop: '0.5em' }}
				>{`${label}`}</FormLabel>
			)}
			{!fetching && (
				<>
					{!!valueToRender && (
						<Typography>{`${valueToRender}`}</Typography>
					)}
					{!valueToRender &&
						!!placeholder && (
							<Typography
								style={{ opacity: 0.85 }}
							>{`${placeholder}`}</Typography>
						)}
				</>
			)}
			{fetching && (
				<Box
					style={{
						display: 'flex',
					}}
				>
					<CircularProgress size={20} />
				</Box>
			)}
		</div>
	);
}

WorkflowLabel.propTypes = {
	fetching: PropTypes.bool,
	label: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.string,
};
