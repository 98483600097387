import React from 'react';
import PropTypes from 'prop-types';

import { Dialog } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function BtDialog({ children, ...props }) {
	const theme = useTheme();
	const isFullscreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Dialog fullScreen={isFullscreen} {...props}>
			{children}
		</Dialog>
	);
}

BtDialog.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};
