import React, { useEffect, useState, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';

import {
	Typography,
	Tooltip,
	IconButton,
	Zoom,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import { Dialog } from '@mui/material';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { InsightComponentContainer } from '../InsightComponentContainer';
import { useSnackbar } from 'notistack';

import { v4 as uuidV4 } from 'uuid';

import 'react-reflex/styles.css';
import InsightComponentEdit from '../../EditComponents/insightComponentEdit/InsightComponentEdit';

const Transition = React.forwardRef(function Transition(props, ref) {
	//return <Slide direction="up" ref={ref} {...props} />;
	return <Zoom ref={ref} {...props} />;
});

export default function BtInsightComponentModal({
	onClose,
	open,
	component,
	inEditMode,
	onComponentUpdate,
	refreshCollectionData,
	palette,
	paletteConfig,
	collectionPalettes,
}) {
	const THEME = useTheme();
	const [componetText, setComponentText] = useState('');
	const [sending, setSending] = useState(false);
	const [dialogComponent, setDialogComponent] = useState(null);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(
		() => {
			setComponentText(JSON.stringify(component, null, 2));
			setDialogComponent({ ...component, uuid: uuidV4() });
		},
		[component]
	);

	const onSubmit = useCallback(
		async (values, reset) => {
			setSending(true);
			try {
				reset(values);

				const updatedComponent = { ...values };
				onComponentUpdate(updatedComponent);
				// enqueueSnackbar('Created Organisation', {
				// 	variant: 'success',
				// });
				// history.push(`/BeaconAdmin/Organisation/${uuid}`);
				onClose();
			} catch (error) {
				console.log(error);
				setSending(false);
				enqueueSnackbar('Failed to update component', {
					variant: 'error',
				});
			}
			setSending(false);
		},
		[enqueueSnackbar]
	);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth={false}
			transitionDuration={300}
			TransitionComponent={Transition}
			PaperProps={{
				sx: {
					height: '90vh',
				},
			}}
		>
			{component && (
				<DialogTitle>
					<div
						style={{
							width: '100%',
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'row',
							justifyContent: 'space-between',
						}}
					>
						<Tooltip title={component.subtitle}>
							<Typography variant="h5">
								{component.title}
							</Typography>
						</Tooltip>

						<Tooltip title="Exit Fullscreen">
							<IconButton
								aria-label="exit fullscreen"
								size="medium"
								style={{ flexGrow: 0 }}
								onClick={onClose}
							>
								<FullscreenExitIcon fontSize="inherit" />
							</IconButton>
						</Tooltip>
					</div>
				</DialogTitle>
			)}

			{!inEditMode && (
				<DialogContent sx={{ height: 'calc(100vh - 100px)' }}>
					{component && (
						<div
							style={{
								height: '100%',
								display: 'flex',
								flexDirection: 'column',
								backgroundColor: THEME.palette.background.paper,
								borderRadius: 8,
							}}
						>
							{dialogComponent && (
								<div
									style={{
										height: '100%',
										width: '100%',
									}}
								>
									<InsightComponentContainer
										component={dialogComponent}
										hideTitle
										editEnabled={inEditMode}
										palette={palette}
									/>
								</div>
							)}
						</div>
					)}
				</DialogContent>
			)}

			{inEditMode && (
				<InsightComponentEdit
					component={component}
					onClose={onClose}
					palette={palette}
					refreshCollectionData={refreshCollectionData}
					onComponentUpdate={onComponentUpdate}
					paletteConfig={paletteConfig}
					collectionPalettes={collectionPalettes}
				/>
			)}
		</Dialog>
	);
}
