import React, { useEffect, useState, useRef } from 'react';
import { Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/AddCircle';

import admin_nav_item from '../AdminNavItem';
import admin_options from '../AdminOptions';
import BtColour from '../../../components/generic/BtColour';
import BtError from '../../../components/generic/BtError';
import BtLoading from '../../../components/generic/BtLoading';
import { BtFormContainer } from '../../../components/generic/forms';
import BtSelectionTable from '../../../components/generic/BtSelectionTable';
import BtTableSkeleton from '../../../components/generic/BtTableSkeleton';
import NewTagsGroupDialog from './NewTagsGroupDialog';
import { tagsGroupGetList } from '../../../API/tags.api';
import useFetch from '../../../hooks/useFetch';
import { useNavContext } from '../../../context/ContextManager';

const tableColumns = [
	{ field: 'name', text: 'Tag Group' },
	{ field: 'description', text: 'Description', minBreakpoint: 'lg' },
	{ field: 'managedBy', text: 'Manage By', minBreakpoint: 'md' },
	{ field: 'displayColour', text: 'Colour', minBreakpoint: 'md' },
	{ field: 'status', text: 'Status' },
];

export default function Tags() {
	const tagsRequested = useRef();

	const [newGroupDialog, setNewGroupDialog] = useState(false);

	const {
		loading,
		error: groupsError,
		data: tagGroups,
		request: getTagGroups,
	} = useFetch(tagsGroupGetList);

	useEffect(
		() => {
			if (!tagsRequested.current) {
				getTagGroups();
			}
			tagsRequested.current = true;
		},
		[getTagGroups]
	);

	// Set contextual navigation items and breadcrumbs
	const { setContextualNav, setBreadcrumbs } = useNavContext();
	useEffect(
		() => {
			setContextualNav([...admin_nav_item, ...admin_options]);
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'Admin', link: '/Admin' },
				{ text: 'Tags', link: '' },
			]);
			return () => setContextualNav(null);
		},
		[setContextualNav, setBreadcrumbs]
	);

	if (groupsError) {
		return (
			<BtError
				title="Retrieval Error"
				description="An error occurred when attempting to retrieve the tag groups"
				action={getTagGroups}
				variant="error"
			/>
		);
	}

	return (
		<>
			<BtFormContainer title="Tag Groups" maxWidth="lg">
				<BtLoading
					loading={loading}
					LoadingComponent={<BtTableSkeleton hasActionButton />}
				>
					<BtSelectionTable
						title={
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									flexWrap: 'wrap',
									gap: 10,
								}}
							>
								<Typography
									sx={{
										fontWeight: 'bold',
										padding: '4px 0',
									}}
									variant="h5"
								>
									Your organisation's tag groups
								</Typography>
								<Button
									variant="contained"
									onClick={() => setNewGroupDialog(true)}
									startIcon={<AddIcon />}
									disableElevation
								>
									Add a Group
								</Button>
							</div>
						}
						subject="tag groups"
						columns={tableColumns}
						data={(tagGroups || []).map(tagGroup => ({
							...tagGroup,
							displayColour: (
								<div
									style={{
										alignItems: 'center',
										display: 'flex',
									}}
								>
									<BtColour
										style={{ marginRight: '0.8em' }}
										colour={tagGroup.displayColour}
										size="small"
										disableInteractive
									/>
									<span>{tagGroup.displayColour}</span>
								</div>
							),
							route: `/Tags/Edit/${tagGroup.uuid}`,
						}))}
					/>
				</BtLoading>
			</BtFormContainer>
			<NewTagsGroupDialog
				existingNames={tagGroups?.map(({ name }) => name)}
				open={newGroupDialog}
				onClose={() => setNewGroupDialog(false)}
			/>
		</>
	);
}
