import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Color from 'color';
import { styled, Typography } from '@mui/material';

import { useThemeContext } from '../../../../../context/ThemeContext';
import workflowStatusAttributes from '../../processing/utils/workflowStatusAttributes';

const Container = styled('div')(({ color, theme }) => ({
	alignItems: 'center',
	backgroundColor: Color(color)
		[theme.palette.mode === 'light' ? 'lighten' : 'darken'](0.7)
		.alpha(0.5)
		.toString(),
	borderRadius: 10,
	display: 'flex',
	flexDirection: 'row',
	height: 20,
	padding: '0 5px',
}));

export default function WorkflowCommentAdornment({ status }) {
	const { theme } = useThemeContext();

	const attributes = useMemo(
		() => {
			const data = workflowStatusAttributes[status];

			const neutralColor = { main: '#777777', text: '#777777' };

			if (data) {
				return {
					...data,
					color:
						theme.palette.indicators[
							(workflowStatusAttributes[status]?.color)
						] ?? neutralColor,
				};
			}

			return { color: neutralColor, status };
		},
		[status, theme]
	);

	return (
		<Container color={attributes.color.main}>
			<Typography
				color={attributes.color.text}
				style={{ fontWeight: 'bold' }}
				variant="subtitle2"
			>
				{attributes.status}
			</Typography>
		</Container>
	);
}

WorkflowCommentAdornment.propTypes = {
	status: PropTypes.string,
};
