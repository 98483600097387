import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Controller, useFormContext } from 'react-hook-form';
import {
	Box,
	CircularProgress,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	Skeleton,
} from '@mui/material';
import { v4 as uuid } from 'uuid';

import useFormElRequired from '../../../hooks/useFormElRequired';

export default function BtFormSelect({
	disabled,
	fetching,
	fullWidth,
	isRequired,
	items,
	label,
	hideNone,
	name,
	...other
}) {
	const id = useMemo(() => uuid(), []);

	const {
		control,
		setValue,
		watch,
		loading,
		sending,
		validationSchema,
		formState: { errors },
	} = useFormContext() || { formState: {} };
	const selectValue = watch(name);
	const hasError = !!errors[name];

	const required = useFormElRequired(isRequired, validationSchema, name);

	const handleChange = useCallback(
		event => {
			setValue(name, event.target.value, {
				shouldValidate: true,
				shouldDirty: true,
				shouldTouch: true,
			});
		},
		[setValue, name]
	);

	const isDisabled = useMemo(() => loading || sending || disabled, [
		loading,
		sending,
		disabled,
	]);

	if (loading) {
		return (
			<Skeleton animation="wave" style={{ width: '100%', height: 45 }} />
		);
	}

	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<FormControl
					disabled={isDisabled}
					error={hasError}
					style={fullWidth ? { width: '100%' } : null}
					variant="standard"
				>
					{label && <InputLabel id={id}>{label}</InputLabel>}
					<Select
						{...field}
						color="primary"
						disabled={isDisabled || !items?.length || fetching}
						endAdornment={
							fetching && (
								<Box
									style={{
										display: 'flex',
										marginRight: '1.5em',
										pointerEvents: 'none',
									}}
								>
									<CircularProgress size={20} />
								</Box>
							)
						}
						fullWidth
						label={
							label
								? `${label}${!required ? ' (optional)' : ''}`
								: undefined
						}
						labelId={id}
						size="small"
						onChange={handleChange}
						value={selectValue || ''}
						variant="standard"
						{...other}
					>
						{!hideNone && (
							<MenuItem value="">
								<em>None</em>
							</MenuItem>
						)}
						{items?.map((item, index) => (
							<MenuItem
								key={index + '#' + item.value}
								value={item.value}
							>
								{item.label}
							</MenuItem>
						))}
					</Select>
					{hasError && (
						<FormHelperText>{errors[name]?.message}</FormHelperText>
					)}
				</FormControl>
			)}
		/>
	);
}

BtFormSelect.propTypes = {
	disabled: PropTypes.bool,
	fetching: PropTypes.bool,
	fullWidth: PropTypes.bool,
	isRequired: PropTypes.bool,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
				.isRequired,
			label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
		})
	).isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
};
