import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import OrgIcon from '@mui/icons-material/HomeWork';
import BtOrgLogo from '../components/generic/BtOrgLogo';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

const OrgButton = styled('button')(({ theme }) => ({
	width: 'calc(100% - 2em)',
	boxSizing: 'border-box',
	backgroundColor: theme.palette.primary.main + '20',
	borderRadius: 8,
	border: 'none',
	outline: 'none',
	margin: '1.5em 1em',

	'&:hover': {
		outline: 'none',
		backgroundColor: '#77777728',
	},

	'&:focus': {
		outline: `2px solid ${theme.palette.primary.main}`,
		backgroundColor: '#77777700',
	},

	'&:active': {
		outline: 'none',
		backgroundColor: '#77777755',
	},
}));

const Contents = styled('span')(() => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '0.5em',
	height: 50,
}));

const Detail = styled('div')(() => ({
	display: 'flex',
	alignItems: 'center',
	gap: '1em',
}));

const IconContainer = styled('div')(({ theme }) => ({
	width: 36,
	height: 36,
	borderRadius: 18,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: theme.palette.mode === 'light' ? '#00000010' : '#ffffff22',
}));

const TextContainer = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
}));

export default function BtOrgButton({ organisation, ...props }) {
	return (
		<OrgButton
			{...props}
			aria-label="Select Organisation"
			title="Select Organisation"
		>
			<Contents>
				<Detail>
					{/*<IconContainer>
						<OrgIcon
							style={{
								fontSize: 18,
							}}
						/>
						</IconContainer>*/}
					<BtOrgLogo
						logo={organisation.logo32}
						initials={organisation.initials}
						name={organisation.user_name}
					/>
					<TextContainer>
						<Typography
							style={{
								fontWeight: 'bold',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								maxWidth: 130,
							}}
						>
							{organisation?.name || 'Unknown Organisation'}
						</Typography>
						<Typography
							variant="subtitle2"
							style={{ opacity: 0.7 }}
						>
							Organisation
						</Typography>
					</TextContainer>
				</Detail>
				<UnfoldMoreIcon />
			</Contents>
		</OrgButton>
	);
}
