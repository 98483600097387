import { Notes } from '@mui/icons-material';
import {
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
} from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import BtDialog from '../../../BtDialog';
import {
	BtForm,
	BtFormActionsContainer,
	BtFormContent,
	BtFormLateralContainer,
	BtFormTextField,
	withFormContextMethods,
} from '../../../forms';
import EditIcon from '@mui/icons-material/Edit';
import * as yup from 'yup';
import { useRecordManagementContext } from '../../RecordManagementContext';
import { StatusRow } from '../generic/StatusDisplay';
import { UPDATE_DRAFT } from '../../utils/constants';

const schema = yup.object({
	notes: yup.string().max(300),
});

const ActionButtons = withFormContextMethods(
	({ formState: { isDirty }, onClose, sending }) => {
		return (
			<>
				<Button variant="outlined" onClick={onClose} disabled={sending}>
					Cancel
				</Button>
				<Button
					variant="contained"
					type="submit"
					disabled={!isDirty || sending}
					startIcon={<EditIcon />}
					disableElevation
				>
					Apply Changes
				</Button>
			</>
		);
	}
);

export const DraftNotes = () => {
	const [notesEditOpen, setNotesEditOpen] = useState(false);
	const {
		sending,
		handleCallback,
		historyData,
		draftLockStatus,
	} = useRecordManagementContext();

	const handleOpenNotesEdit = useCallback(() => {
		setNotesEditOpen(true);
	}, []);

	const handleCloseNotesEdit = useCallback(() => {
		setNotesEditOpen(false);
	}, []);

	const text = useMemo(
		() => {
			if ((historyData.draft.notes || []).length > 0) {
				return historyData.draft.notes;
			} else {
				return 'There are no notes to display...';
			}
		},
		[historyData]
	);

	const currentUserCanEdit = useMemo(
		() => draftLockStatus === 'currentUser',
		[draftLockStatus]
	);

	const handleSubmit = useCallback(
		values => {
			handleCallback({
				callback: UPDATE_DRAFT,
				payload: { notes: values.notes },
			});
			setNotesEditOpen(false);
		},
		[handleCallback]
	);

	return (
		<>
			<StatusRow
				icon={
					<Notes
						sx={{
							marginRight: '10px',
							fontSize: '30px',
						}}
					/>
				}
				label={'Draft notes'}
				text={text}
				onClick={handleOpenNotesEdit}
				buttonText="edit notes"
				buttonDisabled={!currentUserCanEdit || sending}
			/>

			<BtDialog open={notesEditOpen} onClose={handleCloseNotesEdit}>
				<DialogTitle>{'Edit the draft notes'}</DialogTitle>
				<BtForm
					defaultValues={{ notes: historyData.draft.notes }}
					onSubmit={handleSubmit}
					validationSchema={schema}
				>
					<DialogContent>
						<BtFormContent>
							<BtFormLateralContainer>
								<BtFormTextField
									name="notes"
									label="Draft Notes"
									multiline
								/>
							</BtFormLateralContainer>
						</BtFormContent>
					</DialogContent>

					<DialogActions>
						<BtFormActionsContainer>
							{/* TODO - add clear functionality */}
							<ActionButtons onClose={handleCloseNotesEdit} />
						</BtFormActionsContainer>
					</DialogActions>
				</BtForm>
			</BtDialog>
		</>
	);
};
