import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, styled } from '@mui/material';
import useResize from '../../../../components/bt_vis/utils/useResize';
import { resolveAffixes } from './Utils/affixes';

const ContainerBox = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	height: '100%',
	width: '100%',
}));

const StyledTypography = styled(Typography)(({ size, colour }) => ({
	fontWeight: 600,
	fontSize: size,
	overflow: 'hidden',
	color: colour,
}));

export default function StatusCardComponent({ data, visOptions, palette }) {
	const [statusString, setStatusString] = useState('-');
	const ref = useRef();
	const [fontSize, setFontSize] = useState(15);

	const { width, height } = useResize(ref);
	// console.log({ data });
	const formatValue = value => {
		if (value < 1000) {
			return Math.floor(value);
		} else if (value < 1000000) {
			return Math.floor(value / 1000) + 'k';
		} else if (value < 1000000000) {
			return Math.floor(value / 1000000) + 'M';
		} else if (value < 1000000000000) {
			return Math.floor(value / 1000000000) + 'G';
		}
	};

	// *** Will require some improvement ***
	useEffect(
		() => {
			if (data.length > 0) {
				// checks that the affix can be used
				// const checkValid = value => {
				// 	return ['string', 'number'].includes(typeof value);
				// };

				// if defined, returns the affixes, otherwise returns empty strings
				// const getAffixes = visOptions => {
				// 	const affixes = { suffix: '', prefix: '' };
				// 	// check that we have some params in the array
				// 	if (
				// 		// has(visOptions, 'params') &&
				// 		// visOptions.params.length > 0

				// 		visOptions.params !== null
				// 	) {
				// 		// try to retrieve each affix
				// 		['prefix', 'suffix'].map(affix => {
				// 			if (
				// 				has(visOptions.params[0], affix) &&
				// 				checkValid(visOptions.params[0].affix)
				// 			) {
				// 				affixes[affix] = visOptions.params[0].affix;
				// 			}
				// 		});
				// 	}
				// 	return affixes;
				// };

				// const { prefix, suffix } = getAffixes(visOptions);

				// const attachAffixes = (valueString, suffix, prefix) => {
				// 	return `${prefix}${valueString}${suffix}`;
				// };

				// const resolvedString = resolveAffixes(valueString, )

				// if possible, returns the data value with affixes added, otherwise returns error message
				const getValueString = (data, visOptions) => {
					const dataEntry = data[data.length - 1];
					const paramsEntry =
						// has(visOptions, 'params') &&
						// visOptions.params.length > 0
						// 	? visOptions.params[0]
						// 	: undefined;
						visOptions.params !== null
							? visOptions.params[0]
							: undefined;

					// If we have a params entry and value property, return the value, otherwise return undefined
					const dataValueKey =
						paramsEntry && Object.hasOwn(paramsEntry, 'value')
							? paramsEntry.value
							: undefined;
					// If we have managed to retrieve a value, format and return it, otherwise return a message
					if (dataValueKey) {
						const valueString = formatValue(
							dataEntry[dataValueKey]
						);
						return resolveAffixes(valueString, visOptions, 0);
					} else {
						return 'no `params.value` or no matching value in data';
					}
				};

				const string = getValueString(data, visOptions);

				setStatusString(string);
			}
		},
		[data, visOptions]
	);

	// evaluate the width of the text
	const getTextWidth = useCallback((text, _fontSize) => {
		// this is required to counteract the small margin of error of the returned width value
		const errorMargin = 0.224;
		const element = document.createElement('canvas');
		const context = element.getContext('2d');
		context.font = `${_fontSize}px sans-serif`;
		return context.measureText(text).width + _fontSize * errorMargin;
	}, []);

	useEffect(
		() => {
			if (ref.current) {
				const yPadding = 30;
				const heightFactor = 1.25625;

				// initialise the smallest font size
				let tempFontSize = 15;

				// evaluate the text width and height
				let localWidth = getTextWidth(statusString, tempFontSize);
				let localHeight = tempFontSize * heightFactor;

				// increase the font size until it fills the available space
				while (
					localWidth < ref.current.offsetWidth &&
					localHeight < ref.current.offsetHeight - yPadding
				) {
					// increase the font size
					tempFontSize += 5;

					// update the width and height
					localWidth = getTextWidth(statusString, tempFontSize);
					localHeight = tempFontSize * heightFactor;

					// check if we have found the max size and update state font size once we have
					if (
						localWidth > ref.current.offsetWidth ||
						localHeight > ref.current.offsetHeight - yPadding
					) {
						setFontSize(tempFontSize - 5);
						break;
					}
				}
			}
		},
		[getTextWidth, statusString, width, height]
	);

	return (
		<ContainerBox ref={ref}>
			<StyledTypography
				noWrap
				colour={palette[0]}
				size={fontSize}
				style={{
					fontSize: fontSize,
				}}
			>
				{statusString}
			</StyledTypography>
		</ContainerBox>
	);
}

StatusCardComponent.propTypes = {
	data: PropTypes.array.isRequired,
	visOptions: PropTypes.object.isRequired,
	palette: PropTypes.arrayOf(PropTypes.string).isRequired,
};
