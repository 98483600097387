/**
 * handle selection and deselection of options
 * @param {array} data - the unfiltered options data, can be grouped or flat but be sure to pass grouped if passing groupId and flat if not
 * @param {string} optionId - the id of the selected option
 * @param {boolean} multiple - if true, the number of options with selected value of true can be greater than one
 * @param {string} groupId - the groupId of the selected option, if passed it is assumed that the data is grouped
 * @returns
 */
export default function handleSelectItem(data, optionId, multiple, groupId) {
	let singleSelectedOption = null;

	// function to handle options processing
	const processOptions = options => {
		return options.map(option => {
			if (option.id === optionId) {
				let tempOption = { ...option };
				tempOption.selected = !option.selected;
				if (tempOption.selected) {
					singleSelectedOption = tempOption;
				}
				return tempOption;
			} else {
				if (multiple) {
					// if multiple select then return the option as is
					return option;
				} else {
					// otherwise set the selected value to false
					const tempOption = { ...option };
					tempOption.selected = false;
					return tempOption;
				}
			}
		});
	};

	let optionsResult = [];

	if (groupId) {
		// handle grouped data
		optionsResult = data.map(group => {
			if (group.id === groupId) {
				const tempGroup = { ...group };
				tempGroup.options = processOptions(group.options);
				return tempGroup;
			} else {
				if (multiple) {
					// if multiple select then return the group as is
					return group;
				} else {
					// otherwise set all selected values to false
					const tempGroup = { ...group };
					tempGroup.options = processOptions(group.options);
					return tempGroup;
				}
			}
		});
	} else if (!groupId) {
		// handle flat data
		optionsResult = processOptions(data);
	}
	const result = {};
	result.options = optionsResult;
	result.singleSelectedOption = singleSelectedOption;

	return result;
}
