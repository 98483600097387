import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import { CircularProgress, Grow, styled, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import BackIcon from '@mui/icons-material/ArrowBack';
import ForwardIcon from '@mui/icons-material/ArrowForward';

import BtMessage from '../../../../../components/generic/BtMessage';
import { useWorkflowPageContext } from '../../contexts/WorkflowPageContext';
import { useWorkflowSessionContext } from '../../contexts/WorkflowSessionContext';
import WorkflowActionButton from './WorkflowActionButton';
import WorkflowActionContainer from './WorkflowActionContainer';

const ActionContainer = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	bottom: 0,
	margin: '1em 0 0 -1em',
	marginLeft: '-1em',
	padding: '0 1em 1em',
	position: 'sticky',
	width: 'calc(100% + 2em)',
}));

const Fade = styled('div')(({ theme }) => ({
	background: `linear-gradient(180deg, ${
		theme.palette.background.default
	}00 0%, ${theme.palette.background.default} 33%, ${
		theme.palette.background.default
	} 100%)`,
	height: '1em',
	position: 'absolute',
	transform: 'translate(-1em, calc(-1em + 0.5px))',
	width: 'calc(100% + 2em)',
}));

const StatusMessage = memo(
	({ complete, isRoot, showSubmitError }) => {
		if (!isRoot) return null;

		return (
			<>
				{showSubmitError && (
					<Grow key="grow1" in={showSubmitError}>
						<BtMessage
							message={
								<>
									<Typography variant="h5">
										Cannot Proceed
									</Typography>
									<Typography>
										You have not completed all of the pages.
									</Typography>
								</>
							}
							style={{ margin: '2em 0 1em' }}
							variant="error"
						/>
					</Grow>
				)}
				{complete && (
					<Grow key="grow2" in={complete}>
						<BtMessage
							message={
								<>
									<Typography variant="h5">
										Complete
									</Typography>
									<Typography>
										All of the answers are valid for all of
										the pages.
									</Typography>
								</>
							}
							style={{ margin: '2em 0 1em' }}
							variant="success"
						/>
					</Grow>
				)}
			</>
		);
	},
	(prevProps, newProps) =>
		prevProps.complete === newProps.complete &&
		prevProps.isRoot === newProps.isRoot &&
		prevProps.showSubmitError === newProps.showSubmitError
);

StatusMessage.displayName = 'StatusMessage';

export default function WorkflowActions() {
	const {
		complete,
		disableSummary,
		navigationStack,
		handlePageSubmit,
		sessionSubmitting,
		showSubmitError,
		success,
	} = useWorkflowSessionContext();
	const { scrollElementId } = useWorkflowPageContext();

	const { trigger } = useFormContext() || {};

	const isRoot = navigationStack.length === 0;

	const attributes = useMemo(
		() => {
			if (!isRoot) return { label: 'Return', startIcon: <BackIcon /> };

			if (disableSummary)
				return {
					label: 'Submit',
					startIcon: sessionSubmitting ? (
						<CircularProgress size={20} />
					) : (
						undefined
					),
				};

			return { label: 'Summary', endIcon: <ForwardIcon /> };
		},
		[disableSummary, isRoot, sessionSubmitting]
	);

	return (
		<ActionContainer>
			<Fade />
			<StatusMessage
				complete={complete}
				isRoot={isRoot}
				showSubmitError={showSubmitError}
			/>
			<WorkflowActionContainer style={{ margin: 0 }}>
				<WorkflowActionButton
					disabled={sessionSubmitting || success}
					disableElevation
					onClick={() => {
						if (!isRoot) {
							scrollElementId.current =
								navigationStack[
									navigationStack.length - 1
								].referrer;
						}

						handlePageSubmit();
						trigger();
					}}
					endIcon={attributes.endIcon}
					startIcon={attributes.startIcon}
					style={{ margin: '1em' }}
					variant="contained"
				>
					{attributes.label}
				</WorkflowActionButton>
			</WorkflowActionContainer>
		</ActionContainer>
	);
}

StatusMessage.propTypes = {
	complete: PropTypes.bool,
	isRoot: PropTypes.bool,
	showSubmitError: PropTypes.bool,
};

WorkflowActions.propTypes = {
	pagingData: PropTypes.object,
};
