import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
	IconButton,
	Tooltip,
	Badge,
	Fab,
	Button,
	Switch,
	Typography,
} from '@mui/material';
import { FilterPlus, Filter, ChevronDoubleUp } from 'mdi-material-ui';
import { styled, useTheme } from '@mui/material/styles';

import BtPopper from '../BtPopper';
import FilterChips from './components/FilterChips';
import FilterAdd from './components/FilterAdd';
import TimeControls from './components/time/TimeControls';
import { useAppContext } from '../../../context/ContextManager';
import { Box } from '@mui/system';

const TimeControlsContainer = styled('div')(({ showMobile }) => ({
	alignItems: showMobile ? 'flex-start' : 'center',
	display: 'flex',
	paddingLeft: 5,
	transform: !showMobile && 'translateY(3px)',
	width: showMobile ? '100%' : '400px',
}));

const MobileContainer = styled('div')(() => ({
	display: 'flex',
	width: '100%',
}));

const MobileContentContainer = styled('div')(() => ({
	display: 'flex',
	// width: '100%',
	width: 'calc(100% - 48px)',
}));

const MobileIconsContainer = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
	width: 48,
}));

const iconButtonStyle = {
	alignItems: 'center',
	display: 'flex',
	height: 30,
	justifyItems: 'center',
	marginLeft: 1,
	padding: 3,
	transform: 'translateX(-7px)',
	width: 30,
};

export default function BtFilters({
	timeRange,
	filters,
	filterOptions,
	predefinedFilters,
	onChangeFilters,
	onChangeTimeRange,
	devTimeControlNext,
	insightsChips,
}) {
	const { showMobile } = useAppContext();

	const [expand, setExpand] = useState(false);
	// const [_filters, setFilters] = useState(filters ?? initialFilters);
	const [_filters, setFilters] = useState([]);
	const [popperOpen, setPopperOpen] = useState(false);
	const [showMobileFilterChips, setShowMobileFilterChips] = useState(false);
	const theme = useTheme();

	const handleOpenMobFilterChips = useCallback(() => {
		setShowMobileFilterChips(prev => !prev);
	}, []);

	const handleFilterPopperOpen = useCallback(() => {
		setPopperOpen(prev => !prev);
	}, []);

	const handleFilterPopperClose = useCallback(() => {
		setPopperOpen(false);
	}, []);

	const handleFiltersUpdate = useCallback(
		updatedFilters => {
			setFilters(updatedFilters);

			var newFilters = updatedFilters.map(filter => {
				return {
					...filter,
					dataViewUuid: filter.field.dataViewUuid,

					field: filter.field.name,
				};
			});
			onChangeFilters?.(newFilters);
		},
		[onChangeFilters]
	);

	// console.log({
	// 	theProps: {
	// 		timeRange,
	// 		filters,
	// 		filterOptions,
	// 		predefinedFilters,
	// 		onChangeFilters,
	// 		onChangeTimeRange,
	// 	},
	// });

	useEffect(
		() => {
			if (filters) {
				// console.log('ActiveFilters', filters);
				// console.log('set filters from data provider', filters);
				// console.log('filterOptions', filterOptions);
				// console.log('predefinedFilters', predefinedFilters);
				// setFilters(filters);

				var newFilters = [];
				for (const filter of filters) {
					const matchedFilterOption = _.find(filterOptions, {
						name: filter.field,
					});

					// console.log('matchedFilterOption', matchedFilterOption);

					if (matchedFilterOption) {
						var newFilter = {
							field: matchedFilterOption,
							filterEnabled: filter.active || true,
							name: filter.name,
							filterValue: undefined,
							required: filter.required || false,
						};
						switch (matchedFilterOption.type) {
							case 'categorical':
								newFilter.singleValue =
									filter.singleValue || false;

								newFilter.filterValue = filter.filterValue;
								// // inject mock filter values
								// newFilter.filterValue = [
								// 	...filter.filterValue,
								// 	..._.range(1, 30).map(i =>
								// 		(Math.random() + 1)
								// 			.toString(36)
								// 			.substring(7)
								// 	),
								// ];

								break;
							case 'quantitative':
								// TODO: handle rstoring the following
								newFilter.filterValue =
									matchedFilterOption.range;

								break;
							case 'boolean':
								newFilter.filterValue = true;
								break;
							default:
								throw new Error(
									'Unsupported filter type',
									matchedFilterOption.type
								);
						}

						newFilters.push(newFilter);
					} else {
						// console.log('_filters', _filters);
						newFilters.push(
							_.find(_filters, existingFilter => {
								return (
									existingFilter.field.name === filter.field
								);
							})
						);
					}
				}
				// console.log('newFilters', newFilters);
				setFilters(newFilters);
			}
		},
		[filters]
	);

	return (
		<div
			style={{
				backgroundColor: theme.palette.background.default,
				display: 'flex',
				height: 'auto',
				paddingBottom: showMobile ? 0 : 0,
				paddingLeft: showMobile ? (showMobileFilterChips ? 0 : 8) : 10,
				paddingRight: showMobile ? 0 : 10,
				paddingTop: 5,
				width: showMobile ? 'calc(100% - 8px)' : '100%',
				maxWidth: showMobile ? 'calc(100% - 8px)' : '100%',
			}}
		>
			{!showMobile && (
				<>
					<div
						style={{
							alignItems: 'flex-start',
							display: 'flex',
							maxWidth: '450px',
							minWidth: '450px',
						}}
					>
						<BtPopper
							content={
								<FilterAdd
									filters={_filters}
									filterOptions={filterOptions}
									onClose={handleFilterPopperClose}
									setFilters={handleFiltersUpdate}
								/>
							}
							onClose={handleFilterPopperClose}
							open={popperOpen}
							placement="bottom-start"
							// placement="bottom"
							style={{
								maxWidth: `calc(100vw *.5)`,
								minWidth: `calc(100vw *.3)`,
							}}
						>
							<Tooltip arrow placement="top" title="Add Filter">
								<IconButton
									onClick={handleFilterPopperOpen}
									sx={{
										...iconButtonStyle,
										marginLeft: 0,
										padding: 0,
										transform: 0,
									}}
								>
									<FilterPlus />
								</IconButton>
							</Tooltip>
						</BtPopper>

						<TimeControlsContainer>
							<TimeControls
								defaultTimeRange={timeRange}
								onChange={onChangeTimeRange}
								devTimeControlNext={devTimeControlNext}
								// devTimeControlNext
							/>
						</TimeControlsContainer>
					</div>

					<FilterChips
						expand={expand}
						filters={_filters}
						primaryField="name"
						setExpand={setExpand}
						setFilters={handleFiltersUpdate}
						singleLine={true}
						insightsChips={insightsChips}
					/>
				</>
			)}

			{showMobile && (
				<MobileContainer>
					<MobileContentContainer>
						{showMobileFilterChips ? (
							<FilterChips
								expand={expand}
								primaryField="name"
								setExpand={setExpand}
								setFilters={handleFiltersUpdate}
								showMobileFilterChips={showMobileFilterChips}
								singleLine={false}
								filters={_filters}
								insightsChips={insightsChips}
							/>
						) : (
							<TimeControls
								defaultTimeRange={timeRange}
								onChange={onChangeTimeRange}
							/>
						)}
					</MobileContentContainer>
					<MobileIconsContainer>
						<IconButton
							onClick={handleOpenMobFilterChips}
							sx={{ ...iconButtonStyle }}
						>
							{showMobileFilterChips ? (
								<ChevronDoubleUp
									sx={{ marginRight: showMobile ? 0.5 : 0 }}
								/>
							) : (
								<Badge
									badgeContent={
										filters?.length < 10
											? `${filters.length}`
											: '...'
									}
									color="primary"
								>
									<Filter />
								</Badge>
							)}
						</IconButton>
						{showMobileFilterChips && (
							<BtPopper
								content={
									<FilterAdd
										filters={_filters}
										filterOptions={filterOptions}
										onClose={handleFilterPopperClose}
										setFilters={handleFiltersUpdate}
									/>
								}
								onClose={handleFilterPopperClose}
								open={popperOpen}
								placement="bottom-start"
								style={{
									maxWidth: '375px',
									width: '100%',
								}}
							>
								<IconButton
									onClick={handleFilterPopperOpen}
									sx={{ ...iconButtonStyle }}
								>
									<FilterPlus />
								</IconButton>
							</BtPopper>
						)}
					</MobileIconsContainer>
				</MobileContainer>
			)}
		</div>
	);
}

const filtersPropType = PropTypes.arrayOf(PropTypes.object);

BtFilters.propTypes = {
	timeRange: PropTypes.object,
	// filters: filtersPropType,
	filterOptions: PropTypes.array.isRequired,
	initialFilters: filtersPropType,
	onChangeFilters: PropTypes.func,
	onChangeTimeRange: PropTypes.func,
	insightsChips: PropTypes.bool,
};
