import Color from 'color';
import { dark_colors, light_colors } from '../style/muiTheme';
import { getContrastRatio } from '@mui/material';

const PASS = 'pass';
const FAIL = 'fail';

const getSafeColorForBackground = backgroundColour => {
	return backgroundColour && Color(backgroundColour).isLight()
		? '#000000'
		: '#ffffff';
};

const platformContrastThreshold = 1.8;

const evalContrastRatios = (
	colour,
	returnSingle,
	darkBackground = dark_colors.background,
	lightBackground = light_colors.background,
	contrastThreshold = platformContrastThreshold
) => {
	if (!/^#?([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/.test(colour)) {
		console.warn(
			`util function 'evalContrastRatios' received colour parameter that has invalid format or is missing, should be hex colour string, received: ${colour}`
		);

		return {};
	}
	const evalStatus = (ratio, threshold) => {
		return threshold < ratio ? PASS : FAIL;
	};

	const result = {};
	['dark', 'light'].forEach(theme => {
		const contrastRatio = getContrastRatio(
			theme === 'dark' ? darkBackground : lightBackground,
			colour
		);
		result[theme] = evalStatus(contrastRatio, contrastThreshold);

		// console.log(`${theme} contrastRatio: ${contrastRatio}`);
	});

	if (returnSingle) {
		const singleResult = Object.values(result).includes(FAIL) ? FAIL : PASS;
		return singleResult;
	} else {
		return result;
	}
};

const getContrastRatios = (
	colour,
	darkBackground = dark_colors.background,
	lightBackground = light_colors.background
) => {
	if (!/^#?([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/.test(colour)) {
		console.warn(
			`util function 'evalContrastRatios' received colour parameter that has invalid format or is missing, should be hex colour string, received: ${colour}`
		);

		return {};
	}

	const result = {};

	['dark', 'light'].forEach(theme => {
		const contrastRatio = getContrastRatio(
			theme === 'dark' ? darkBackground : lightBackground,
			colour
		);
		result[theme] = Number(contrastRatio.toFixed(2));

		// console.log(`${theme} contrastRatio: ${contrastRatio}`);
	});
	return result;
};

export {
	getSafeColorForBackground,
	evalContrastRatios,
	getContrastRatios,
	PASS,
	FAIL,
};
