import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip, Typography } from '@mui/material';

import DataSetTypeIcon from './DataSetTypeIcon';
import {
	ARRAY,
	BOOLEAN,
	DATE,
	NULL,
	NUMBER,
	OBJECT,
	STRING,
	UNDEFINED,
} from '../../../../../utils/commonDataTypes';

const variantData = {
	[ARRAY]: {
		description:
			'An array is a data structure that contains a list of elements of a particular type. Elements in an array are "indexed", meaning they are ordered and are referred to by a sequential number starting at 0.',
		title: 'Array',
	},
	[BOOLEAN]: {
		description:
			'A Boolean is a value that can only have one of two possible values: true or false.',
		title: 'Boolean',
	},
	[DATE]: {
		description:
			'A date is a value that refers to a specific calendar day.',
		title: 'Date',
	},
	[NULL]: {
		description:
			'A null variable is type-neutral and has no referenced value.',
		title: 'Null',
	},
	[NUMBER]: {
		description:
			'A numeric value that can either be positive or negative, and an integer or a decimal value.',
		title: 'Number',
	},
	[OBJECT]: {
		description:
			'An object is a data structure that contains a group of named values. The name for a value is called a key, and in tandem, they are often referred to as key/value pairs.',
		title: 'Object',
	},
	[STRING]: {
		description:
			'A string is essentially textual data. It is traditionally referred to as such as it is a string of characters chained together.',
		title: 'String',
	},
	[UNDEFINED]: {
		description:
			'An undefined variable is one that has not been assigned a value.',
		title: 'Undefined',
	},
};

const Content = ({ variant }) => {
	const { description, title } = variantData[variant];

	return (
		<div style={{ maxWidth: 300 }}>
			<div style={{ alignItems: 'center', display: 'flex' }}>
				<DataSetTypeIcon style={{ marginRight: 5 }} variant={variant} />
				<Typography style={{ fontWeight: 'bold' }}>{title}</Typography>
			</div>
			<Typography>{description}</Typography>
		</div>
	);
};

export const DataSetType = ({ variant, ...other }) => (
	<Tooltip
		disableInteractive
		enterDelay={500}
		title={<Content variant={variant} />}
	>
		<DataSetTypeIcon variant={variant} {...other} />
	</Tooltip>
);
const variantPropType = PropTypes.oneOf([
	ARRAY,
	BOOLEAN,
	DATE,
	NULL,
	NUMBER,
	OBJECT,
	STRING,
]).isRequired;

Content.propTypes = {
	variant: variantPropType,
};

DataSetType.propTypes = {
	variant: variantPropType,
};

export default DataSetType;
