import React from 'react';

import { useTheme } from '@mui/material/styles';

import useMediaQuery from '@mui/material/useMediaQuery';
// import useSize from '@react-hook/size';

import { Card, CardContent } from '@mui/material';

import { Box } from '@mui/system';

export default function BTAuthCard(props) {
	const theme = useTheme();
	const screen_downSm = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Card
			sx={
				{
					width: screen_downSm ? '100%' : '350px',
					height: screen_downSm ? '100%' : 'auto',
					// height: screen_downSm ? '100%' : '600px',
				}
				// height: screen_downSm ? '100%' : '520px',
				// height: screen_downSm ? '100%' : 'auto',
			}
			variant={screen_downSm ? '' : 'outlined'}
		>
			<CardContent sx={{ py: '0px', px: '0px' }}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						margin: 'auto',
					}}
				>
					{/***************  BT Logo  ***************/}
					<Box sx={{ margin: 'auto', padding: '0px' }}>
						<img
							height="150px"
							alt={'logo'}
							className={'login-logo'}
							src={
								process.env.PUBLIC_URL +
								'/' +
								'Logo_BlueText_200x200.png'
							}
						/>
					</Box>
				</Box>
			</CardContent>
			{props.heading && (
				<CardContent
					sx={{
						minHeight: props.headingHeightOverride
							? 'auto'
							: '80px',
						pt: screen_downSm ? '16px' : '0px',
						pb: '16px',
						px: screen_downSm ? '16px' : '24px',
					}}
				>
					{props.heading}
				</CardContent>
			)}
			{props.subHeading && (
				<CardContent
					sx={{ py: '0px', px: screen_downSm ? '16px' : '24px' }}
				>
					{props.subHeading}
				</CardContent>
			)}
			<CardContent
				sx={{
					padding: '0px',
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					margin: 'auto',
				}}
			>
				{props.form}
			</CardContent>
		</Card>
	);
}
