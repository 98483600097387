import React, {
	createContext,
	forwardRef,
	useCallback,
	useContext,
	useEffect,
	useImperativeHandle,
	useState,
} from 'react';
import PropTypes from 'prop-types';

import { v4 as generateUuid } from 'uuid';
import _ from 'lodash';

import {
	dataSetStoreDelete,
	dataSetStoreInsert,
	dataSetStoreUpdate,
} from '../../../API';
import { useDataSetContext } from '../hocs/withDataSetContext';
import useFetch from '../../../hooks/useFetch';
import { useSnackbar } from 'notistack';

const DataSetEditorContext = createContext();

export const useDataSetEditorContext = () => {
	const context = useContext(DataSetEditorContext);

	if (context === undefined) {
		throw new Error(
			'useDataSetEditorContext was used outside of its Provider'
		);
	}

	return context;
};

export const DataSetEditorContextProvider = forwardRef(
	({ children, data, dataSetUuid, editOnly, loadData, schema }, ref) => {
		const { editData, setEditData, setSending } = useDataSetContext();

		const [editedData, setEditedData] = useState(_.cloneDeep(data));
		const [editMode, setEditMode] = useState(!!editOnly);
		const [initialData, setInitialData] = useState(_.cloneDeep(data));
		const [seedUuid, setSeedUuid] = useState(generateUuid());
		const [updated, setUpdated] = useState(false);

		const createApi = useFetch(dataSetStoreInsert);
		const deleteApi = useFetch(dataSetStoreDelete);
		const updateApi = useFetch(dataSetStoreUpdate);

		const { enqueueSnackbar } = useSnackbar();

		const updateData = useCallback((newData, hardUpdate) => {
			if (hardUpdate) {
				setSeedUuid(generateUuid());

				setEditedData(prev => ({
					...prev,
					updated_timestamp: +new Date(),
					values: _.cloneDeep({ ...newData }),
				}));

				return;
			}

			setEditedData(prev => ({
				...prev,
				updated_timestamp: +new Date(),
				values: newData,
			}));
		}, []);

		const enterEditMode = useCallback(
			() => {
				setEditMode(true);
				setEditData({ isEditing: true, recordUuid: data.uuid });
			},
			[data, setEditData]
		);

		useEffect(
			() => {
				if (!editData && editMode) {
					enterEditMode();
				}
			},
			[editData, editMode, enterEditMode]
		);

		const exitEditMode = useCallback(
			() => {
				setEditMode(false);
				setEditData({ isEditing: false, recordUuid: null });
			},
			[setEditData]
		);

		const discardChanges = useCallback(
			() => {
				setEditedData(_.cloneDeep(initialData));
				exitEditMode();
			},
			[exitEditMode, initialData]
		);

		const saveChanges = useCallback(
			async () => {
				setSending(true);
				const updateTime = +new Date();

				const { ok } = await updateApi.request({
					dataSetUuid,
					recordUuid: editedData.uuid,
					updatedRecord: editedData.values,
				});

				setSending(false);

				if (!ok) {
					enqueueSnackbar('Failed to update the record.', {
						variant: 'error',
					});

					return;
				}

				enqueueSnackbar('Successfully updated the record.', {
					variant: 'success',
				});

				const newData = _.cloneDeep({
					...editedData,
					update_timestamp: updateTime,
					approxUpdate: true,
				});
				setEditedData(newData);
				setInitialData(newData);
				setUpdated(true);

				exitEditMode();
			},
			[
				dataSetUuid,
				editedData,
				enqueueSnackbar,
				exitEditMode,
				setSending,
				updateApi,
			]
		);

		const createRecord = useCallback(
			async ({ onSuccess, onFailure }) => {
				setSending(true);

				const { ok } = await createApi.request({
					dataSetUuid,
					newRecord: editedData.values,
				});

				setSending(false);

				if (!ok) {
					enqueueSnackbar('Failed to create the record.', {
						variant: 'error',
					});

					onFailure?.();

					return;
				}

				onSuccess?.();
			},
			[createApi, dataSetUuid, editedData, enqueueSnackbar, setSending]
		);

		const deleteRecord = useCallback(
			async () => {
				setSending(true);

				const { ok } = await deleteApi.request({
					dataSetUuid,
					recordUuid: data.uuid,
				});

				setSending(false);

				if (!ok) {
					enqueueSnackbar('Failed to update the record.', {
						variant: 'error',
					});

					return;
				}

				enqueueSnackbar('Successfully deleted the record.');

				loadData();
			},
			[
				data,
				dataSetUuid,
				deleteApi,
				enqueueSnackbar,
				loadData,
				setSending,
			]
		);

		useImperativeHandle(ref, () => ({
			discardChanges: () => {
				discardChanges();
			},
			saveChanges: () => {
				saveChanges();
			},
			createRecord: ({ onSuccess, onFailure }) => {
				createRecord({ onSuccess, onFailure });
			},
		}));

		return (
			<DataSetEditorContext.Provider
				ref={ref}
				value={{
					deleteRecord,
					discardChanges,
					editedData,
					editMode,
					enterEditMode,
					initialData,
					schema,
					seedUuid,
					updated,
					updateData,
				}}
			>
				{children}
			</DataSetEditorContext.Provider>
		);
	}
);

DataSetEditorContextProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	data: PropTypes.arrayOf(PropTypes.object).isRequired,
	dataSetUuid: PropTypes.string.isRequired,
	editOnly: PropTypes.bool,
	loadData: PropTypes.func.isRequired,
	schema: PropTypes.object.isRequired,
};
