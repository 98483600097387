import _ from 'lodash';

import { ARRAY, NULL, UNDEFINED } from '../../../utils/commonDataTypes';

export default function determineType(data, schema) {
	if (Array.isArray(data)) return ARRAY;

	if (schema?.type) return schema.type;

	if (_.isNil(data)) {
		return data === undefined ? UNDEFINED : NULL;
	}

	return typeof data;
}
