import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Typography,
} from '@mui/material';

import ActionButtonContainer from './ActionButtonContainer';
import BtDialog from './BtDialog';

export default function BtConfirmDialog({
	action,
	ActionIcon,
	cancelAction,
	cancelVerb,
	isDestructive,
	onClose,
	open,
	prompt,
	textInputConfirm,
	title,
	verb,
}) {
	const [confirmString, setConfirmString] = useState('');

	const handleClose = useCallback(
		() => {
			onClose();

			cancelAction?.();

			setConfirmString('');
		},
		[cancelAction, onClose]
	);

	return (
		<BtDialog fullWidth maxWidth="xs" open={open} onClose={handleClose}>
			{title && <DialogTitle>{title}</DialogTitle>}
			{prompt && (
				<DialogContent>
					<Typography>{prompt}</Typography>
					{textInputConfirm && (
						<div style={{ marginTop: '1em' }}>
							<Typography>
								{`Please type "${textInputConfirm}"
								(case-sensitive) into the text field below if
								you wish to proceed.`}
							</Typography>
							<TextField
								fullWidth
								onChange={event =>
									setConfirmString(event.currentTarget.value)
								}
								placeholder="Enter confirmation phrase"
								style={{ marginTop: '1em' }}
								value={confirmString}
								variant="standard"
							/>
						</div>
					)}
				</DialogContent>
			)}
			<DialogActions>
				<ActionButtonContainer>
					<Button
						disableElevation
						onClick={() => {
							handleClose();
						}}
						variant="outlined"
					>
						{cancelVerb ?? 'Cancel'}
					</Button>
					<Button
						color={isDestructive ? 'error' : 'primary'}
						disabled={
							textInputConfirm &&
							textInputConfirm !== confirmString
						}
						disableElevation
						startIcon={ActionIcon ? ActionIcon : null}
						onClick={() => {
							onClose();

							action();

							setConfirmString('');
						}}
						variant="contained"
					>
						{verb}
					</Button>
				</ActionButtonContainer>
			</DialogActions>
		</BtDialog>
	);
}

BtConfirmDialog.propTypes = {
	action: PropTypes.func.isRequired,
	ActionIcon: PropTypes.node,
	cancelAction: PropTypes.func,
	cancelVerb: PropTypes.string,
	isDestructive: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	prompt: PropTypes.string.isRequired,
	textInputConfirm: PropTypes.string,
	title: PropTypes.string.isRequired,
	verb: PropTypes.string.isRequired,
};
