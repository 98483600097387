import { styled } from '@mui/material';

export default styled('div')(() => ({
	alignItems: 'center',
	display: 'flex',
	height: 37.5,
	justifyContent: 'flex-end',
	marginLeft: 'auto',
	width: 37.5,
}));
