import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import {
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import BtDialog from '../../../components/generic/BtDialog';
import {
	BtForm,
	BtFormActionsContainer,
	BtFormContent,
	BtFormTextField,
} from '../../../components/generic/forms';
import CreateActionButtons from '../common/CreateActionButtons';
import { dataViewCreate } from '../../../API/DataManager/data_views';
import useFetch from '../../../hooks/useFetch';

const blankDefaults = {
	description: '',
	name: '',
};

export default function NewDataViewDialog({ existingNames, onClose, open }) {
	const createDataView = useFetch(dataViewCreate).request;
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();

	const [sending, setSending] = useState(false);

	const schema = useMemo(
		() =>
			yup.object({
				name: yup
					.string()
					.min(3)
					.notOneOf(
						existingNames || [],
						'A data source with this name already exists'
					)
					.required()
					.label('Data Source Name'),
				description: yup.string().label('Data Source Description'),
			}),
		[existingNames]
	);

	const onSubmit = useCallback(
		async (values, reset) => {
			reset(values);

			setSending(true);

			const { data, error, ok } = await createDataView({
				newDataView: values,
			});

			setSending(false);

			if (!ok) {
				enqueueSnackbar('Failed to create Data Source', {
					variant: 'error',
				});

				console.error('Failed to create Data Source', error);

				return;
			}

			enqueueSnackbar('Data View created', {
				variant: 'success',
			});

			history.push(`/DataViews/${data.uuid}`);
		},
		[createDataView, enqueueSnackbar, history]
	);

	return (
		<BtDialog fullWidth minwidth="sm" onClose={onClose} open={open}>
			{sending && (
				<LinearProgress
					style={{
						position: 'absolute',
						top: 0,
						width: '100%',
					}}
				/>
			)}
			<DialogTitle>Create A New Data View</DialogTitle>
			<BtForm
				defaultValues={blankDefaults}
				onSubmit={onSubmit}
				sending={sending}
				validationSchema={schema}
			>
				<DialogContent>
					<BtFormContent>
						<BtFormTextField autoFocus label="Name" name="name" />
						<BtFormTextField
							label="Description"
							multiline
							name="description"
						/>
					</BtFormContent>
				</DialogContent>
				<DialogActions>
					<BtFormActionsContainer>
						<CreateActionButtons
							onClose={onClose}
							sending={sending}
						/>
					</BtFormActionsContainer>
				</DialogActions>
			</BtForm>
		</BtDialog>
	);
}

NewDataViewDialog.propTypes = {
	existingNames: PropTypes.arrayOf(PropTypes.string),
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
};
