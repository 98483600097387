import { BtTileLayouts } from './BtTileLayouts';
import { ScreensContextProvider } from './ScreensContext';

export default function BtScreens({ screenGroupKey, screenAdminUuid }) {
	return (
		<ScreensContextProvider
			screenGroupKey={screenGroupKey}
			screenAdminUuid={screenAdminUuid}
		>
			<BtTileLayouts />
		</ScreensContextProvider>
	);
}
