import { useCallback } from 'react';

import components, { componentTypeExists } from '../config/workflowComponents';

export const useWorkflowComponents = () =>
	useCallback(componentType => {
		if (!componentTypeExists(componentType)) {
			if (process.env.NODE_ENV === 'development') {
				console.error(
					`Could not find workflow form element of type ${componentType}`
				);
			}

			return { Component: null };
		}

		return components[componentType].component;
	}, []);

export default useWorkflowComponents;
