import React from 'react';
import { Link, Typography } from '@mui/material';
import _ from 'lodash';
import { CircleMedium, Consolidate } from 'mdi-material-ui';
import { memo, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useOverflowedElementCount from '../../../../../hooks/useOverflowedElementCount';
import { useTheme } from '@mui/styles';
import { useMemo } from 'react';
import { VIS_LIST_TYPES } from '../../../InsightPage/visualisationConstants';

const LegendItem = memo(function LegendItem({
	handleClick,
	selectedIds,
	index,
	label,
	visibility,
	colour,
	isClickable,
}) {
	const THEME = useTheme();
	const opacity = useMemo(
		() => {
			if (
				!isClickable ||
				selectedIds?.includes(index) ||
				selectedIds?.length === 0
			) {
				return 1;
			} else {
				return 0.5;
			}
		},
		[index, isClickable, selectedIds]
	);

	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				marginRight: '1rem',
				height: 21,
				visibility: visibility,
				cursor: isClickable ? 'pointer' : 'auto',
			}}
			onClick={e => {
				isClickable && handleClick(index);
			}}
		>
			<div
				style={{
					height: 6,
					width: 6,
					borderRadius: 3,
					backgroundColor: colour,
					marginRight: 5,
				}}
			/>
			{/* <CircleMedium style={{ color: colour }} /> */}
			{isClickable ? (
				<Link
					variant="body2"
					underline="hover"
					sx={{
						fontWeight: selectedIds?.includes(index)
							? 'bolder'
							: 'normal',
						color: THEME.palette.chart.label,
						opacity: opacity,
					}}
				>
					{label}
				</Link>
			) : (
				<Typography
					variant="body2"
					sx={{
						color: THEME.palette.chart.label,
					}}
				>
					{label}
				</Typography>
			)}
		</div>
	);
});

const CustomLegend = memo(function CustomLegend({
	chartType,
	payload,
	visOptionsParams,
	onChange,
	selectedIds,
	clickable,
}) {
	const outerRef = useRef();
	const { count: overflowCount } = useOverflowedElementCount(outerRef);

	// useEffect(() => console.log({ visOptionsParams, payload }), [
	// 	payload,
	// 	visOptionsParams,
	// ]);

	useEffect(
		() =>
			overflowCount &&
			console.log(
				`!!! ${chartType} Legend has been hidden due to overflow !!!`
			),
		[chartType, overflowCount]
	);

	// check if we have been passed
	// the props that we need
	// for clickable functionality
	const isClickable = useMemo(
		() => {
			if (
				onChange === undefined ||
				selectedIds === undefined ||
				!clickable
			) {
				return false;
			} else {
				return true;
			}
		},
		[clickable, onChange, selectedIds]
	);

	useEffect(
		() => {
			if (process.env.NODE_ENV === 'development') {
				if (clickable && onChange === undefined) {
					console.error(
						'Legend clickable prop is "true", but onChange function has not been passed, onChange is a required prop if clickable'
					);
				}
				if (clickable && selectedIds === undefined) {
					console.error(
						'Legend clickable prop is "true", but selectedIds array has not been passed, selectedIds is a required prop if clickable'
					);
				}
			}
		},
		[clickable, onChange, selectedIds]
	);

	const handleClick = useCallback(
		id => {
			if (selectedIds.includes(id)) {
				onChange(selectedIds.filter(item => item !== id));
				return;
			} else {
				onChange([...selectedIds, id]);
			}
		},
		[onChange, selectedIds]
	);

	// if all categories are selected
	// the selection is cleared by setting the
	// state to empty array
	useEffect(
		() => {
			if (selectedIds?.length === payload?.length) {
				onChange([]);
			}
		},
		[onChange, payload?.length, selectedIds?.length]
	);

	const resolveLabel = useCallback(
		entry => {
			try {
				const label = _.find(visOptionsParams, {
					value: entry.value,
				}).label;
				return label;
			} catch (error) {
				// console.log(error);
				// console.log(
				// 	`Custom chart legend could not resolve label in ${
				// 		chartType ? chartType : 'undefined chart type'
				// 	}, 'entry.value' has been used instead`,
				// 	'entry: ',
				// 	entry
				// );
				return entry.value;
			}
		},
		[chartType, visOptionsParams]
	);

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				width: '100%',
				marginRight: 50,
			}}
		>
			<div
				ref={outerRef}
				style={{
					display: 'flex',
					overflow: 'hidden',
					flexWrap: 'wrap',
					height: 21,
					alignItems: 'flex-start',
				}}
			>
				{payload.map((entry, index) => (
					<LegendItem
						key={`item-${index}`}
						handleClick={handleClick}
						selectedIds={selectedIds}
						index={index}
						label={resolveLabel(entry)}
						visibility={overflowCount ? 'hidden' : 'visible'}
						colour={entry.color}
						isClickable={isClickable}
					/>
				))}
				{/* {!highlightingDisabled && (
					<ButtonBase
						sx={{ height: 20, width: 20, borderRadius: 10 }}
					>
						<Close />
					</ButtonBase>
				)} */}
			</div>
		</div>
	);
});

export { CustomLegend };

CustomLegend.propTypes = {
	payload: PropTypes.arrayOf(PropTypes.object),
	visOptionsParams: PropTypes.arrayOf(PropTypes.object),
	onChange: PropTypes.func,
	selectedIds: PropTypes.array,
	clickable: PropTypes.bool,
	// chartType: PropTypes.oneOf(VIS_LIST_TYPES),
	// chartType: PropTypes.string.oneOf(VIS_LIST_TYPES).isRequired,
};

LegendItem.propTypes = {
	selectedIds: PropTypes.array,
	isClickable: PropTypes.bool,
	handleClick: PropTypes.func,
	index: PropTypes.number.isRequired,
	label: PropTypes.string.isRequired,
	visibility: PropTypes.oneOf(['hidden', 'visible']),
	colour: PropTypes.string.isRequired,
};
