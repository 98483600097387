import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
	Box,
	Button,
	CircularProgress,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
} from '@mui/material';
import { CardMultipleOutline, CardPlusOutline } from 'mdi-material-ui';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import * as yup from 'yup';

import BtDialog from '../../../../components/generic/BtDialog';
import {
	BtForm,
	BtFormActionsContainer,
	BtFormContent,
	BtFormFilePicker,
	withFormContextMethods,
} from '../../../../components/generic/forms';
import BtFormIconRadio from '../../../../components/generic/forms/BtFormIconRadio';
import BtMessage from '../../../../components/generic/BtMessage';
import { dataSetStoreUpload } from '../../../../API';

const blankDefaults = { uploadMode: 'append' };

const schema = yup.object({
	csvFile: yup
		.array()
		.required()
		.min(1)
		.max(1),
	uploadMode: yup.string().required(),
});

const ActionButtons = withFormContextMethods(
	({ formState: { isDirty }, onClose, sending }) => {
		return (
			<>
				<Button
					disabled={sending}
					disableElevation
					onClick={onClose}
					variant="outlined"
				>
					Cancel
				</Button>
				<Button
					disabled={!isDirty || sending}
					disableElevation
					startIcon={
						sending ? (
							<CircularProgress size={20} />
						) : (
							<UploadFileIcon />
						)
					}
					type="submit"
					variant="contained"
				>
					Upload File
				</Button>
			</>
		);
	}
);

export default function DataSetCsvUploadDialog({
	dataSetUuid,
	onClose,
	open,
	refreshPage,
}) {
	const [error, setError] = useState(null);
	const [sending, setSending] = useState(false);

	const [uploadDone, setUploadDone] = useState(false);
	const [uploadResult, setUploadResult] = useState('');

	const onSubmit = async (values, reset) => {
		setSending(true);
		setError(null);

		try {
			const recordsAdded = await dataSetStoreUpload({
				csvFile: values.csvFile[0],
				dataSetUuid: dataSetUuid,
				replace: values.uploadMode === 'replace' ? true : false,
				uploadMode: values.uploadMode,
			});

			setUploadResult(
				`Upload Successful. Added ${recordsAdded} new records`
			);

			reset();
			setUploadDone(true);
			refreshPage();
		} catch (error) {
			setError(
				'There was an error processing the CSV file. ' +
					error.message +
					'.'
			);
		} finally {
			setSending(false);
		}
	};

	const handleClose = () => {
		setError(null);
		onClose();
	};

	return (
		<BtDialog open={open} onClose={handleClose} minwidth="sm" fullWidth>
			{sending && (
				<LinearProgress
					style={{
						position: 'absolute',
						top: 0,
						width: '100%',
					}}
				/>
			)}
			{dataSetUuid && (
				<>
					<DialogTitle>CSV Data Upload</DialogTitle>
					{!uploadDone && (
						<BtForm
							onSubmit={onSubmit}
							sending={sending}
							validationSchema={schema}
							defaultValues={blankDefaults}
						>
							<DialogContent>
								{error && (
									<Box sx={{ paddingBottom: '36px' }}>
										<BtMessage
											variant="error"
											message={error}
										/>
									</Box>
								)}
								<BtFormContent>
									<BtFormIconRadio
										name="uploadMode"
										label="Upload Mode"
										items={[
											{
												icon: <CardMultipleOutline />,
												label: 'Append to Current Data',
												value: 'append',
											},
											{
												icon: <CardPlusOutline />,
												label: 'Replace Current Data',
												value: 'replace',
											},
										]}
									/>
									<BtFormFilePicker
										label="CSV File"
										maxFiles={1}
										maxFileSize={5242880}
										name="csvFile"
									/>
								</BtFormContent>
							</DialogContent>
							<DialogActions>
								<BtFormActionsContainer>
									<ActionButtons
										onClose={handleClose}
										sending={sending}
									/>
								</BtFormActionsContainer>
							</DialogActions>
						</BtForm>
					)}

					{uploadDone && (
						<>
							<DialogContent>
								<Box>
									<BtMessage
										message={uploadResult}
										variant="success"
									/>
								</Box>
							</DialogContent>
							<DialogActions>
								<BtFormActionsContainer>
									<Button
										disableElevation
										onClick={() => {
											setUploadDone();
											handleClose();
										}}
										variant="outlined"
									>
										Close
									</Button>
								</BtFormActionsContainer>
							</DialogActions>
						</>
					)}
				</>
			)}
		</BtDialog>
	);
}

ActionButtons.propTypes = {
	onClose: PropTypes.func.isRequired,
	sending: PropTypes.bool,
};

DataSetCsvUploadDialog.propTypes = {
	dataSetUuid: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool,
	refreshPage: PropTypes.func.isRequired,
};
