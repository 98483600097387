import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	Autocomplete,
	ListItem,
	Typography,
	TextField,
	Tooltip,
	IconButton,
	CircularProgress,
} from '@mui/material';

import RefreshIcon from '@mui/icons-material/Refresh';
import BtOpenInNew from './BtOpenInNew';
import useFetch from '../../hooks/useFetch';
import { dataSetGetList, dataSetGet } from '../../API';
import { da } from 'date-fns/locale';

export default function BtDataSourceSelect({
	retriveDataSourceDetails,
	onChange,
	value,
	dataSourceUuid,
}) {
	const [selectedDataSource, setSelectedDataSource] = useState(null);

	const {
		loading: dataSourceListLoading,
		data: dataSourceList,
		error,
		request: fetchDataSources,
	} = useFetch(dataSetGetList);

	const {
		loading: dataSourceLoading,
		data: dataSource,
		error: dataSourceError,
		request: fetchDataSource,
	} = useFetch(dataSetGet);

	const handleDataSetChange = useCallback(
		(event, newValue) => {
			console.log('handleDataSetChange', newValue);
			setSelectedDataSource(newValue);

			if (retriveDataSourceDetails) {
				fetchDataSource({ dataSetUuid: newValue.uuid });
			} else {
				if (onChange) {
					onChange(newValue.uuid);
				}
			}
		},
		[retriveDataSourceDetails, fetchDataSource, onChange]
	);

	const _dataSourceUuid = useMemo(
		() => {
			return dataSourceUuid;
		},
		[dataSourceUuid]
	);

	const onRefreshDataSets = () => {
		fetchDataSources();
	};

	useEffect(
		() => {
			if (
				_dataSourceUuid &&
				dataSourceList &&
				dataSourceList.length > 0
			) {
				// fetchDataSource({ dataSetUuid: _dataSourceUuid });
				const selectedDataSource = dataSourceList.find(dataSource => {
					return dataSource.uuid === _dataSourceUuid;
				});
				// handleDataSetChange(null, selectedDataSource);

				setSelectedDataSource(selectedDataSource);

				if (retriveDataSourceDetails) {
					fetchDataSource({ dataSetUuid: selectedDataSource.uuid });
				}
			}
		},
		[
			_dataSourceUuid,
			dataSourceList,
			fetchDataSource,
			retriveDataSourceDetails,
		]
	);

	useEffect(
		() => {
			fetchDataSources();
		},
		[fetchDataSources]
	);

	useEffect(
		() => {
			if (dataSource) {
				console.log('dataSource', dataSource);
				if (onChange) {
					onChange(dataSource);
				}
			}
		},
		[dataSource]
	);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				paddingY: '16px',
			}}
		>
			<Autocomplete
				style={{ width: '100%' }}
				options={dataSourceList || []}
				value={selectedDataSource}
				loading={dataSourceListLoading}
				openOnFocus
				onChange={handleDataSetChange}
				getOptionLabel={option => {
					return option.name;
				}}
				renderOption={(options, state) => {
					return (
						<ListItem {...options}>
							<Typography>
								{(() => {
									return state.name;
								})()}
							</Typography>
						</ListItem>
					);
				}}
				renderInput={params => (
					<TextField
						{...params}
						placeholder="Select Data Source"
						variant="standard"
						// disabled={fieldDisabled}
						// autoFocus={autoFocus}
					/>
				)}
				isOptionEqualToValue={(option, value) => {
					return option?.uuid === value?.uuid;
				}}
			/>

			{!dataSourceListLoading ? (
				<Tooltip disableInteractive title="Refresh Data Set List">
					<IconButton
						color="primary"
						onClick={onRefreshDataSets}
						size="small"
					>
						<RefreshIcon />
					</IconButton>
				</Tooltip>
			) : (
				<CircularProgress size={22} style={{ marginLeft: 6 }} />
			)}

			<BtOpenInNew name="Open data sets in new" url="DataSources" />
		</Box>
	);
}

BtDataSourceSelect.propTypes = {
	retriveDataSourceDetails: PropTypes.bool,
	onChange: PropTypes.func,
	value: PropTypes.string,
	dataSourceUuid: PropTypes.string,
};
