import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { BtFormLateralContainer } from '../../forms';
import { useQueryBuilderDataContext } from '../context';

export default function BtQueryBuilderResourcePicker(props) {
	const { disabled } = props;

	const {
		allowResourceChange,
		allowResourceGroupChange,
		// onDataQuery,
		resourceGroups,
		onResourceGroupChange,
		resourceGroup,
		resources,
		onResourceChange,
		loadingResources,
		resource,
		resourceUuid,
	} = useQueryBuilderDataContext();

	return (
		<BtFormLateralContainer>
			<Box width={{ xs: 1, md: 0.5 }} sx={{ mb: 1 }}>
				{allowResourceGroupChange ? (
					<FormControl variant="standard" fullWidth>
						<InputLabel>Resource Type</InputLabel>
						<Select
							value={resourceGroup?.key || ''}
							onChange={event =>
								onResourceGroupChange(event.target.value || '')
							}
							disabled={disabled}
						>
							<MenuItem value="">
								<em>None</em>
							</MenuItem>
							{resourceGroups.map(rg => (
								<MenuItem value={rg.key} key={rg.key}>
									{rg.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				) : (
					<Box>
						<Typography variant="subtitle1">
							Resource Group
						</Typography>
						<Typography
							sx={{ opacity: resourceGroup?.name ? 1 : 0.85 }}
						>
							{resourceGroup?.name || 'None selected'}
						</Typography>
					</Box>
				)}
			</Box>

			<Box width={{ xs: 1, md: 0.5 }} sx={{ mb: 1 }}>
				{allowResourceGroupChange || allowResourceChange ? (
					<FormControl variant="standard" fullWidth>
						<InputLabel>Resource</InputLabel>
						<Select
							onChange={event =>
								onResourceChange(event.target.value || '')
							}
							disabled={!resources || disabled}
							endAdornment={
								loadingResources ? (
									<InputAdornment position="end">
										<Box
											style={{
												display: 'flex',
												marginRight: '0.5em',
												pointerEvents: 'none',
											}}
										>
											<CircularProgress size={20} />
										</Box>
									</InputAdornment>
								) : null
							}
							value={resourceUuid || ''}
						>
							<MenuItem value="">
								<em>None</em>
							</MenuItem>
							{!loadingResources &&
								resources &&
								resources.map(r => (
									<MenuItem key={r.uuid} value={r.uuid}>
										{r.name}
									</MenuItem>
								))}
						</Select>
					</FormControl>
				) : (
					<Box>
						<Typography variant="subtitle1">Resource</Typography>
						<Typography sx={{ opacity: resource?.name ? 1 : 0.85 }}>
							{resource?.name || 'None selected'}
						</Typography>
					</Box>
				)}
			</Box>
		</BtFormLateralContainer>
	);
}

BtQueryBuilderResourcePicker.propTypes = {
	disabled: PropTypes.bool,
};
