import { Typography } from '@mui/material';
import { useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router';
import { getDefaultNavItems } from '../../../../AppStructureComponents/Layout';
import { useNavContext } from '../../../../context/ContextManager';
import useNavRedactor from '../../../../hooks/useNavRedactor';
import BtRedactor from '../../../BtRedactor';
import BtError from '../../BtError';
import BtSelectionTable from '../../BtSelectionTable';
import { BtFormContainer } from '../../forms';
import { ScreensContext } from '../ScreensContext';
import { GetStarted } from './controls/GetStarted';

const tableColumns = [
	{ field: 'icon', text: '', minBreakpoint: 'sm' },
	{ field: 'text', text: '' },
];

const locationLabels = {
	home: 'Home',
	workflows: 'Workflows',
	insights: 'Insights',
};

export default function ScreenPlaceholder({
	screenGroupError,
	onScreenChange,
}) {
	const history = useHistory();
	const { contextualNav } = useNavContext();
	const { screenGroupKey } = useContext(ScreensContext);

	const redactNav = useNavRedactor();

	const navItems = useMemo(
		() => redactNav(contextualNav ? contextualNav : getDefaultNavItems()),
		[contextualNav, redactNav]
	);

	const navRoutes = useMemo(
		() => {
			const result = {};
			navItems.forEach(({ id, route }) => {
				result[id] = route;
			});
			return result;
		},
		[navItems]
	);

	const onClickRow = useCallback(
		id => {
			history.push(`${navRoutes[id]}`);
		},
		[history, navRoutes]
	);

	const refreshPage = () => {
		history.go(0);
	};

	// useEffect(
	// 	() => {
	// 		setScreens([]);
	// 	},
	// 	[setScreens]
	// );

	if (screenGroupError) {
		return (
			<div
				style={{
					height: 'calc(100vh - 64px)',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<BtError
					title="Retrieval Error"
					description={
						<>
							<Typography>
								An error occurred whilst trying to retrieve the
								screens.
							</Typography>
						</>
					}
					action={() => refreshPage()}
				/>
			</div>
		);
	}

	return (
		<div
			style={{
				height: 'calc(100vh - 64px)',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				// justifyContent: 'center',
			}}
		>
			<BtFormContainer
				title={locationLabels[screenGroupKey]}
				maxWidth="md"
			>
				<BtSelectionTable
					subject="Screens"
					columns={tableColumns}
					onClick={onClickRow}
					data={navItems.map(({ id, text, route, icon, active }) => ({
						id,
						text,
						route,
						icon,
						active,
					}))}
				/>
			</BtFormContainer>
			{/* TODO - improve UX by providing BtMessage of required permissions and disabling the button instead of redacting */}
			<BtRedactor
				requiredPermissionsAll={{
					screens: ['ScreenGroupEdit', 'ScreenCreate'],
				}}
			>
				<GetStarted onScreenChange={onScreenChange} />
			</BtRedactor>
		</div>
	);
}
