const ORGANISATION = 'organisation';
const COLLECTION = 'collection';
const PLATFORM = 'platform';
const INSIGHTS = 'insights';
const ACTIVE = 'active';
const ARCHIVED = 'archived';

const contrastSafeBaseColours = [
	'#f44336',
	'#e91e63',
	'#9c27b0',
	'#673ab7',
	'#3f51b5',
	'#2196f3',
	'#03a9f4',
	'#00bcd4',
	'#009688',
	'#4caf50',
	'#8bc34a',
	'#ff9800',
	'#ff5722',
];

export {
	COLLECTION,
	ORGANISATION,
	PLATFORM,
	INSIGHTS,
	ACTIVE,
	ARCHIVED,
	contrastSafeBaseColours,
};
