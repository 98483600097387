import React from 'react';
import PropTypes from 'prop-types';

import { useFormContext } from 'react-hook-form';
import WorkflowAnswer from '../summary/WorkflowAnswer';

export default function WorkflowReadOnly({ component }) {
	const { watch } = useFormContext();

	return (
		<WorkflowAnswer
			answers={watch()}
			child={component}
			style={{ margin: 0 }}
		/>
	);
}

WorkflowReadOnly.propTypes = {
	component: PropTypes.object.isRequired,
};
