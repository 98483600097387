import React from 'react';
import { Box, Chip } from '@mui/material';
import { styled } from '@mui/styles';
import PropTypes from 'prop-types';
import { memo, useCallback, useMemo, useState } from 'react';
import { mdiPlusCircleOutline } from '@mdi/js';
import TagEditDialog from './TagEditDialog';
import {
	BtIconButton,
	XS,
} from '../../../../../../components/generic/BtIconButton';

const TagsContainer = styled(Box)(() => ({
	display: 'flex',
	flexWrap: 'wrap',
	alignItems: 'center',
	gap: '5px',
}));

const ADD = 'add';
const EDIT = 'edit';
const NEW = 'new';

const ColourTags = memo(function Tags({ tags, onChange, uuid }) {
	const [editTagIndex, setEditTagIndex] = useState(null);
	const [editTagDialogOpen, setEditTagDialogOpen] = useState(false);

	const handleSubmit = useCallback(
		(tag, mode) => {
			const newTags = [...tags];
			if (mode === ADD) {
				newTags.push(tag);
			} else {
				newTags.splice(editTagIndex, 1, tag);
			}
			onChange({
				tags: newTags,
				uuid: uuid,
			});
		},
		[editTagIndex, onChange, tags, uuid]
	);

	const handleDeleteTag = useCallback(
		tagIndex => {
			const newTags = [...tags];
			newTags.splice(tagIndex, 1);
			onChange({
				tags: newTags,
				uuid: uuid,
			});
		},
		[onChange, tags, uuid]
	);

	// const open = useMemo(() => Boolean(editTagIndex !== null), [editTagIndex]);

	const handleTagClick = useCallback(tagIndex => {
		setEditTagIndex(tagIndex);
		setEditTagDialogOpen(true);
	}, []);

	const handleCloseEditDialog = useCallback(() => {
		setEditTagDialogOpen(false);
		// setEditTagIndex(null);
	}, []);

	const tagDialogAttr = useMemo(
		() => {
			if (editTagIndex !== null) {
				const newTag = editTagIndex === NEW;

				const title = newTag ? 'Add new tag' : 'EditTag';
				const existingTags = newTag
					? tags
					: tags.filter(tag => tag !== tags[editTagIndex]);
				const tag = newTag ? '' : tags[editTagIndex];
				const onSubmit = tag => handleSubmit(tag, newTag ? ADD : EDIT);
				return {
					title,
					existingTags,
					tag,
					onSubmit,
				};
			}
		},
		[editTagIndex, handleSubmit, tags]
	);

	return (
		<TagsContainer>
			{tags.length > 0 ? (
				tags.map((name, index) => (
					<Chip
						variant="outlined"
						size="small"
						label={name}
						key={name}
						onDelete={() => handleDeleteTag(index)}
						onClick={() => handleTagClick(index)}
					/>
				))
			) : (
				<Chip size="small" label="No tags" />
			)}
			{tags.length < 10 && (
				<BtIconButton
					onClick={() => handleTagClick(NEW)}
					// disabled
					icon={mdiPlusCircleOutline}
					size={XS}
					tooltip="Add tag"
				/>
			)}

			<TagEditDialog
				existingTags={tagDialogAttr?.existingTags}
				onClose={handleCloseEditDialog}
				onSubmit={tagDialogAttr?.onSubmit}
				open={editTagDialogOpen}
				tag={tagDialogAttr?.tag}
				title={tagDialogAttr?.title}
			/>
		</TagsContainer>
	);
});

ColourTags.propTypes = {
	tags: PropTypes.arrayOf(PropTypes.string),
	onChange: PropTypes.func.isRequired,
	uuid: PropTypes.string.isRequired,
};

ColourTags.displayName = 'ColourTags';

export { ColourTags };
