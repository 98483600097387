import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import pluralize from 'pluralize';
import { useSnackbar } from 'notistack';

import BtList from './BtList';
import BtMultiAdd from './BtMultiAdd';

export default function BtListComposer({
	allItems,
	confirmDeletion,
	deleteable,
	DeleteIcon,
	deleteVerb,
	disabled,
	idKey,
	defaultItems,
	items,
	loading,
	maxItems,
	onItemAdd,
	onItemAddFail,
	onItemAddSuccess,
	onItemDelete,
	onItemDeleteFail,
	onItemDeleteSuccess,
	primaryField,
	renderItem,
	renderOption,
	showDeleteIcon,
	sortComparator,
	style,
	subject,
}) {
	const [_items, setItems] = useState(null);

	const { enqueueSnackbar } = useSnackbar();

	useEffect(
		() => {
			if (defaultItems && !_items) {
				setItems(defaultItems);
			}
		},
		[defaultItems, _items]
	);

	useEffect(() => setItems(items), [items]);

	const maxedOut = useMemo(() => (_items || []).length === maxItems, [
		_items,
		maxItems,
	]);

	const addItem = useCallback(item => setItems(prev => [...prev, item]), []);

	const deleteItem = useCallback(
		item =>
			setItems(prev =>
				prev.filter(
					existingItem =>
						idKey
							? existingItem[idKey] !== item[idKey]
							: existingItem !== item
				)
			),
		[idKey]
	);

	const handleItemAdd = async item => {
		const newItems = [...(_items || []), item];
		if (items === undefined) {
			setItems(newItems);
		}

		try {
			await onItemAdd?.(item, newItems);

			if (onItemAddSuccess) {
				onItemAddSuccess(item, newItems);
			}
		} catch {
			if (items === undefined) {
				deleteItem(item);
			}

			if (onItemAddFail) {
				onItemAddFail(item, _items);
			}

			enqueueSnackbar(
				`Could not add ${
					subject ? pluralize.singular(subject) : 'item'
				}`,
				{
					variant: 'error',
				}
			);
		}
	};

	const handleItemDelete = async item => {
		const newItems = _items.filter(
			existingItem =>
				idKey
					? existingItem[idKey] !== item[idKey]
					: existingItem !== item
		);
		if (items === undefined) {
			setItems(newItems);
		}

		try {
			await onItemDelete?.(item, newItems);

			if (onItemDeleteSuccess) {
				onItemAddSuccess(item, newItems);
			}
		} catch {
			if (items === undefined) {
				addItem(item);
			}

			if (onItemDeleteFail) {
				onItemDeleteFail(item, _items);
			}

			enqueueSnackbar(
				`Could not delete ${
					subject ? pluralize.singular(subject) : 'item'
				}`,
				{
					variant: 'error',
				}
			);
		}
	};

	return (
		<div style={{ width: '100%', ...style }}>
			<BtMultiAdd
				addedItems={_items}
				disabled={disabled || maxedOut}
				immediateMode
				idKey={idKey}
				items={allItems || []}
				loading={loading}
				onAddedChange={items => handleItemAdd(items)}
				placeholder={`Search for ${
					subject ? pluralize.plural(subject) : 'items'
				} to add...`}
				primaryField={primaryField}
				renderOption={renderOption}
				sortComparator={sortComparator}
				subject={subject}
			/>
			<BtList
				confirmDeletion={confirmDeletion}
				deleteable={deleteable}
				DeleteIcon={DeleteIcon}
				deleteVerb={deleteVerb}
				disabled={disabled}
				items={_items || []}
				loading={loading}
				onItemDelete={handleItemDelete}
				primaryField={primaryField}
				showDeleteIcon={showDeleteIcon}
				sortComparator={sortComparator}
				subject={subject}
				renderItem={renderItem}
			/>
		</div>
	);
}

const itemsType = PropTypes.arrayOf(
	PropTypes.oneOfType([PropTypes.string, PropTypes.object])
);

const editFuncType = PropTypes.oneOfType([
	PropTypes.func,
	PropTypes.instanceOf(Promise),
]);

BtListComposer.defaultProps = {
	DeleteIcon: <DeleteIcon />,
	showDeleteIcon: true,
	subject: 'Item',
};

BtListComposer.propTypes = {
	allItems: itemsType,
	confirmDeletion: PropTypes.bool,
	deleteable: PropTypes.bool,
	DeleteIcon: PropTypes.node,
	deleteVerb: PropTypes.string,
	disabled: PropTypes.bool,
	idKey: PropTypes.string,
	initialItems: itemsType,
	loading: PropTypes.bool,
	maxItems: PropTypes.number,
	onItemAdd: editFuncType,
	onItemAddFail: PropTypes.func,
	onItemAddSuccess: PropTypes.func,
	onItemDelete: editFuncType,
	onItemDeleteFail: PropTypes.func,
	onItemDeleteSuccess: PropTypes.func,
	primaryField: PropTypes.string,
	renderItem: PropTypes.func,
	renderOption: PropTypes.func,
	sortComparator: PropTypes.func,
	showDeleteIcon: PropTypes.bool,
	style: PropTypes.object,
	subject: PropTypes.string,
};
