import React from 'react';
import { Divider, Typography } from "@mui/material";

export default function SectionHeader({children}) {
	return(
		<>
			<Typography variant="h5" sx={{mt:'2rem', ml:'1rem'}}>{children}</Typography>
			<Divider sx={{mt:0}}/>
		</>
	);
}
