const yup = require('yup');

// const screenLayoutItem = yup.object().shape({
// 	tile_uuid: yup
// 		.string()
// 		.uuid()
// 		.required(),
// 	uuid: yup
// 		.string()
// 		.uuid()
// 		.required(),
// 	x: yup
// 		.number()
// 		.required()
// 		.min(0)
// 		.max(12),
// 	y: yup
// 		.number()
// 		.required()
// 		.min(0),
// 	w: yup
// 		.number()
// 		.required()
// 		.min(1)
// 		.max(12), // TODO: check the min/max values for all x,y,w,h
// 	h: yup
// 		.number()
// 		.required()
// 		.min(1)
// 		.max(12),
// });

// const screenGetListSchema = yup.array().of(
// 	yup.object().shape({
// 		uuid: yup
// 			.string()
// 			.uuid()
// 			.required(),
// 		screen_name: yup.string().required(),
// 		ownerType: yup.string().required(),
// 		userOwnerId: yup
// 			.string()
// 			.uuid()
// 			.nullable(),
// 		organisation: yup
// 			.string()
// 			.uuid()
// 			.required(),
// 	})
// );

// const screenGetSchema = yup.object().shape({
// 	uuid: yup
// 		.string()
// 		.uuid()
// 		.required(),
// 	organisation: yup
// 		.string()
// 		.uuid()
// 		.required(),
// 	ownerType: yup.string().required(),
// 	userOwnerId: yup
// 		.string()
// 		.uuid()
// 		.nullable(),
// 	screen_name: yup.string().required(),
// 	layouts: yup.object().shape({
// 		desktop: yup.array().of(screenLayoutItem),
// 		tablet: yup.array().of(screenLayoutItem),
// 		mobile: yup.array().of(screenLayoutItem),
// 		native: yup.array().of(yup.string().uuid()),
// 	}),
// 	tiles: yup.object().required(),
// });

// const screenGroupGetSchema = yup.array().of(
// 	yup.object().shape({
// 		uuid: yup
// 			.string()
// 			.uuid()
// 			.required(),
// 		managedBy: yup.string().required(),
// 		organisation: yup
// 			.string()
// 			.uuid()
// 			.required(),
// 		ownerType: yup.string().required(),
// 		userOwnerId: yup
// 			.string()
// 			.uuid()
// 			.nullable(),
// 		screen_name: yup.string().required(),
// 		layouts: yup.object().shape({
// 			desktop: yup.array().of(screenLayoutItem),
// 		}),
// 		tiles: yup.object().required(),
// 	})
// );

// const screenGroupPutSchema = yup.object().shape({
// 	screens: yup.array().of(
// 		yup.object().shape({
// 			screen_uuid: yup
// 				.string()
// 				.uuid()
// 				.required(),
// 			managedBy: yup
// 				.string()
// 				.oneOf(['User', 'Organisation'])
// 				.required(),
// 		})
// 	),
// });

// const screenPostSchema = yup
// 	.object()
// 	.shape({
// 		uuid: yup
// 			.string()
// 			.uuid()
// 			.required(),
// 		screen_name: yup.string().required(),
// 		ownerType: yup.string().required(),
// 		organisation: yup
// 			.string()
// 			.uuid()
// 			.required(),
// 		layouts: yup.object().shape({
// 			desktop: yup.array().of(screenLayoutItem),
// 			tablet: yup.array().of(screenLayoutItem),
// 			mobile: yup.array().of(screenLayoutItem),
// 			native: yup.array().of(yup.string().uuid()),
// 		}),
// 		tiles: yup.object().required(),
// 	})
// 	.noUnknown();

// const screenPutSchema = yup.object().shape({
// 	screen_name: yup.string().required(),
// 	layouts: yup.object().shape({
// 		desktop: yup.array().of(screenLayoutItem),
// 		tablet: yup.array().of(screenLayoutItem),
// 		mobile: yup.array().of(screenLayoutItem),
// 		native: yup.array().of(yup.string().uuid()),
// 	}),
// 	tiles: yup.object().required(),
// });

const assignToSchema = yup
	.object()
	.required()
	.shape({
		users: yup
			.array()
			.required()
			.of(yup.string().uuid()),
		roles: yup
			.array()
			.required()
			.of(yup.string().uuid()),
		userGroups: yup
			.array()
			.required()
			.of(yup.string().uuid()),
	})
	.noUnknown();

const screenGroupSchema = yup
	.string()
	.required()
	.oneOf(['home', 'workflows', 'insights']);

const screenLayoutItem = yup.object().shape({
	tile_uuid: yup
		.string()
		.uuid()
		.required(),
	uuid: yup
		.string()
		.uuid()
		.required(),
	x: yup
		.number()
		.required()
		.min(0)
		.max(16),
	y: yup
		.number()
		.required()
		.min(0),
	w: yup
		.number()
		.required()
		.min(1)
		.max(16), // TODO: check the min/max values for all x,y,w,h
	h: yup
		.number()
		.required()
		.min(1)
		.max(16),
});

const screenGetListSchema = yup.array().of(
	yup.object().shape({
		uuid: yup
			.string()
			.uuid()
			.required(),
		screen_name: yup.string().required(),
		ownerType: yup.string().required(),
		userOwnerId: yup
			.string()
			.uuid()
			.nullable(),
		organisation: yup
			.string()
			.uuid()
			.required(),
	})
);

const screenGetSchema = yup.object().shape({
	uuid: yup
		.string()
		.uuid()
		.required(),
	organisation: yup
		.string()
		.uuid()
		.required(),
	ownerType: yup.string().required(),
	userOwnerId: yup
		.string()
		.uuid()
		.nullable(),
	screen_name: yup.string().required(),
	layouts: yup.object().shape({
		desktop: yup.array().of(screenLayoutItem),
		tablet: yup.array().of(screenLayoutItem),
		mobile: yup.array().of(screenLayoutItem),
		native: yup.array().of(yup.string().uuid()),
	}),
	tiles: yup.object().required(),
	background_color: yup.string().nullable(),
});

const screenAdminGetSchema = yup.object().shape({
	uuid: yup
		.string()
		.uuid()
		.required(),
	organisation: yup
		.string()
		.uuid()
		.required(),
	ownerType: yup.string().required(),
	userOwnerId: yup
		.string()
		.uuid()
		.nullable(),
	screen_name: yup.string().required(),
	layouts: yup.object().shape({
		desktop: yup.array().of(screenLayoutItem),
		tablet: yup.array().of(screenLayoutItem),
		mobile: yup.array().of(screenLayoutItem),
		native: yup.array().of(yup.string().uuid()),
	}),
	tiles: yup.object().required(),
	availableTo: yup
		.object()
		.required()
		.shape({
			users: yup
				.array()
				.required()
				.of(yup.string().uuid()),
			roles: yup
				.array()
				.required()
				.of(yup.string().uuid()),
			userGroups: yup
				.array()
				.required()
				.of(yup.string().uuid()),
		}),
	assignTo: yup
		.array()
		.required()
		.of(
			yup
				.object()
				.required()
				.shape({
					users: yup
						.array()
						.required()
						.of(yup.string().uuid()),
					roles: yup
						.array()
						.required()
						.of(yup.string().uuid()),
					userGroups: yup
						.array()
						.required()
						.of(yup.string().uuid()),
					screen_group: yup.string().required(),
				})
		),
	background_color: yup.string().nullable(),
});

const screenGroupGetSchema = yup.array().of(
	yup.object().shape({
		uuid: yup
			.string()
			.uuid()
			.required(),
		managedBy: yup.string().required(),
		organisation: yup
			.string()
			.uuid()
			.required(),
		ownerType: yup.string().required(),
		userOwnerId: yup
			.string()
			.uuid()
			.nullable(),
		screen_name: yup.string().required(),
		layouts: yup.object().shape({
			desktop: yup.array().of(screenLayoutItem),
			tablet: yup.array().of(screenLayoutItem),
			mobile: yup.array().of(screenLayoutItem),
			native: yup.array().of(yup.string().uuid()),
		}),
		tiles: yup.object().required(),
		background_color: yup.string().nullable(),
	})
);

const screenGroupPutSchema = yup.object().shape({
	screens: yup.array().of(
		yup.object().shape({
			screen_uuid: yup
				.string()
				.uuid()
				.required(),
			managedBy: yup
				.string()
				.oneOf(['User', 'Organisation'])
				.required(),
		})
	),
});

const screenPostSchema = yup
	.object()
	.shape({
		uuid: yup
			.string()
			.uuid()
			.required(),
		screen_name: yup.string().required(),
		ownerType: yup.string().required(),
		organisation: yup
			.string()
			.uuid()
			.required(),
		layouts: yup.object().shape({
			desktop: yup.array().of(screenLayoutItem),
			tablet: yup.array().of(screenLayoutItem),
			mobile: yup.array().of(screenLayoutItem),
			native: yup.array().of(yup.string().uuid()),
		}),
		tiles: yup.object().required(),
		background_color: yup.string().nullable(),
	})
	.noUnknown();

const screenPutSchema = yup
	.object()
	.shape({
		screen_name: yup.string().required(),
		layouts: yup.object().shape({
			desktop: yup.array().of(screenLayoutItem),
			tablet: yup.array().of(screenLayoutItem),
			mobile: yup.array().of(screenLayoutItem),
			native: yup.array().of(yup.string().uuid()),
		}),
		tiles: yup.object().required(),
		background_color: yup.string().nullable(),
	})
	.noUnknown();

const screenAssignPutSchema = yup
	.object()
	.shape({
		screenGroup: yup.string().required(),
		assignTo: yup
			.object()
			.required()
			.shape({
				users: yup
					.array()
					.required()
					.of(yup.string().uuid()),
				roles: yup
					.array()
					.required()
					.of(yup.string().uuid()),
				userGroups: yup
					.array()
					.required()
					.of(yup.string().uuid()),
			})
			.noUnknown(),
	})
	.noUnknown();

const screenAvailablePutSchema = yup
	.object()
	.shape({
		availableTo: yup
			.object()
			.required()
			.shape({
				users: yup
					.array()
					.required()
					.of(yup.string().uuid()),
				roles: yup
					.array()
					.required()
					.of(yup.string().uuid()),
				userGroups: yup
					.array()
					.required()
					.of(yup.string().uuid()),
			})
			.noUnknown(),
	})
	.noUnknown();

module.exports = {
	// screenGetListSchema,
	// screenGetSchema,
	// screenGroupGetSchema,
	// screenPostSchema,
	// screenPutSchema,
	// screenGroupPutSchema,
	screenGetListSchema,
	screenGetSchema,
	screenAdminGetSchema,
	screenGroupGetSchema,
	screenGroupPutSchema,
	screenPostSchema,
	screenPutSchema,
	screenAssignPutSchema,
	screenAvailablePutSchema,
	assignToSchema,
	screenGroupSchema,
};
