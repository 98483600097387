import { ButtonBase, styled } from '@mui/material';
import Color from 'color';

export default styled(ButtonBase)(({ theme }) => ({
	backgroundColor: Color(theme.palette.grey['500'])
		.fade(0.8)
		.toString(),
	borderRadius: 6,
	margin: '4px 0 0 10px',
	padding: 5,

	transition: theme.transitions.create(['background-color'], {
		duration: '0.2s',
	}),

	'&:hover': {
		backgroundColor: Color(theme.palette.grey['500'])
			.fade(0.7)
			.toString(),
	},
}));
