const { getExpressionType, EXPRESSION } = require('../expression/expression');
const { ERROR } = require('../pipeline/pipeline');

const stages = {};

module.exports.setStageSchemaFn = setStageSchemaFn;
function setStageSchemaFn(key, fn, options) {
	if (stages[key] !== undefined) {
		console.warn('Overwriting stage', key, stages[key], fn);
	}

	stages[key] = fn;
}

function evaluateStageSchema(context, stage) {
	const expression = getExpressionType(stage);

	if (expression.type !== EXPRESSION.OPERATOR) {
		throw new Error(ERROR.UNSUPPORTED_STAGE(expression.type));
	}

	if (!stages[expression.operator]) {
		throw new Error(ERROR.UNSUPPORTED_STAGE(expression.operator));
	}

	return stages[expression.operator](
		context,
		expression.arguments,
		expression.options
	);
}

module.exports.evaluatePipelineSchema = evaluatePipelineSchema;
async function evaluatePipelineSchema(schema, pipeline, options) {
	const context = { schema: schema, userVariables: options?.variables || [] };

	for (let index = 0; index < pipeline.length; index++) {
		const stage = pipeline[index];

		context.schema = evaluateStageSchema(context, stage);
	}

	return context.schema;
}
