import {
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
	useTheme,
} from '@mui/material';
import { styled } from '@mui/styles';
import {
	CardMultipleOutline,
	CardOutline,
	CardPlusOutline,
} from 'mdi-material-ui';
import PropTypes from 'prop-types';

const ScreenRadioDefault = styled('div')(({ theme }) => ({
	borderRadius: 8,
	width: 80,
	height: 60,
	borderColor: theme.palette.primary.light,
	borderWidth: 1,
	borderStyle: 'solid',
	backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : '#f5f8fa',
	'input:hover ~ &': {
		backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
	},
	'input:disabled ~ &': {
		boxShadow: 'none',
		background:
			theme.palette.mode === 'dark'
				? 'rgba(57,75,89,.5)'
				: 'rgba(206,217,224,.5)',
		borderColor: theme.palette.divider,
	},
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	color: theme.palette.text.icon,
}));

const ScreenRadioChecked = styled(ScreenRadioDefault)(({ theme }) => ({
	borderColor: theme.palette.primary.main,
	borderWidth: 2,
	borderStyle: 'solid',
	'input:hover ~ &': {
		borderColor: theme.palette.primary.main,
	},
}));

function ScreenRadio(props) {
	const theme = useTheme();
	return (
		<Radio
			sx={{
				'&:hover': {
					bgcolor: 'transparent',
				},
			}}
			disableRipple
			color="default"
			checkedIcon={
				<ScreenRadioChecked theme={theme}>
					{props.children}
				</ScreenRadioChecked>
			}
			icon={
				<ScreenRadioDefault theme={theme}>
					{props.children}
				</ScreenRadioDefault>
			}
			{...props}
		/>
	);
}

export default function NewScreenRadioGroup({
	value,
	onChange,
	isAddDisabled,
	isCopyDisabled,
	deviceType,
	adminMode,
}) {
	return (
		<RadioGroup
			aria-labelledby="new-screen-type-radio-group"
			name="new-screen-type-radio-group"
			value={value}
			onChange={onChange}
			row={true}
		>
			<FormControlLabel
				sx={{ width: 130 }}
				labelPlacement="bottom"
				value="blank"
				control={
					<ScreenRadio>
						<CardOutline />
					</ScreenRadio>
				}
				label="Blank"
			/>

			{!adminMode && (
				<>
					<FormControlLabel
						sx={{ width: 130 }}
						labelPlacement="bottom"
						value="copy"
						control={
							<ScreenRadio>
								<CardMultipleOutline />
							</ScreenRadio>
						}
						label={
							<Typography align="center">
								{deviceType === 'desktop'
									? 'Copy one of your existing screens'
									: 'Copy'}
							</Typography>
						}
						disabled={isCopyDisabled}
					/>

					<FormControlLabel
						sx={{ width: 130 }}
						labelPlacement="bottom"
						value="addScreen"
						control={
							<ScreenRadio>
								<CardPlusOutline />

								{/* <OfficeBuildingPlusOutline /> */}
							</ScreenRadio>
						}
						label={
							<Typography align="center">
								{deviceType === 'desktop'
									? 'Add an existing screen'
									: 'Add'}
							</Typography>
						}
						disabled={isAddDisabled}
					/>
				</>
			)}

			{adminMode && (
				<FormControlLabel
					sx={{ width: 130 }}
					labelPlacement="bottom"
					value="copy"
					control={
						<ScreenRadio>
							<CardMultipleOutline />
						</ScreenRadio>
					}
					label={
						<Typography align="center">
							{deviceType === 'desktop'
								? 'Copy an existing screen'
								: 'Copy'}
						</Typography>
					}
					disabled={isCopyDisabled}
				/>
			)}
		</RadioGroup>
	);
}

NewScreenRadioGroup.propTypes = {
	value: PropTypes.oneOf(['copy', 'blank', 'addScreen']).isRequired,
	onChange: PropTypes.func.isRequired,
	isAddDisabled: PropTypes.bool,
	deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
	adminMode: PropTypes.bool,
};

ScreenRadio.propTypes = {
	props: PropTypes.object,
};
