import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useNavContext } from '../../../context/ContextManager';
import * as yup from 'yup';
import { v1 as uuidv1 } from 'uuid';
import _ from 'lodash';

import {
	Box,
	Typography,
	Button,
	CircularProgress,
	TextField,
	DialogTitle,
	DialogContent,
	DialogActions,
	Select,
	MenuItem,
	Paper,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/SaveAlt';

import BtLoading from '../../../components/generic/BtLoading';
import {
	a11yProps,
	BtTab,
	BtTabBar,
	BtTabPanel,
	BtTabView,
} from '../../../components/generic/BtTabView';
import {
	BtForm,
	BtFormActionsContainer,
	BtFormContent,
	BtFormCheckbox,
	BtFormTextField,
	BtFormSelect,
	withFormContextMethods,
} from '../../../components/generic/forms';

import AddIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

import useFetch from '../../../hooks/useFetch';
import { workflowTemplateGet } from '../../../API';

// Data for landing page selection table and contextual navigation
import workflow_options from '../WorkflowsOptions';
import workflow_nav_item from '../WorkflowsNavItem';
import WorkflowTemplateState from './WorkflowTemplateState';
import BtDialog from '../../../components/generic/BtDialog';

function AddStateDialog({
	dialogOpen,
	onClose,
	availableStates,
	handleAddState,
}) {
	const [newState, setNewState] = useState('');

	useEffect(
		() => {
			if (availableStates) {
				setNewState(availableStates[0]?.value); // Set the iuntial state
			}
		},
		[availableStates]
	);

	const onAddState = () => {
		handleAddState(newState);
		onClose();
	};

	return (
		<BtDialog open={dialogOpen} onClose={onClose} minwidth="sm" fullWidth>
			<DialogTitle>Add State</DialogTitle>
			<DialogContent>
				<Select
					name="StateName"
					label="State"
					variant="standard"
					sx={{ width: '100%' }}
					value={newState}
					onChange={event => setNewState(event.target.value)}
					labelId="primaryId"
				>
					{availableStates.map(stateOption => (
						<MenuItem
							key={stateOption.value}
							value={stateOption.value}
						>
							{stateOption.label}
						</MenuItem>
					))}
				</Select>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} variant="outlined">
					Cancel
				</Button>
				<Button onClick={onAddState} variant="contained">
					Add State
				</Button>
			</DialogActions>
		</BtDialog>
	);
}

const AllStates = [
	{ value: 'Draft', label: 'Draft' },
	{ value: 'NotStarted', label: 'Not Started' },
	{ value: 'Assigned', label: 'Assigned' },
	{ value: 'InProgress', label: 'In Progress' },
	{ value: 'Submitted', label: 'Submitted' },
	{ value: 'Validation', label: 'Validation' },
	{ value: 'Review', label: 'Review' },
	{ value: 'Rejected', label: 'Rejected' },
	{ value: 'Deleted', label: 'Deleted' },
	{ value: 'Completed', label: 'Completed' },
];

export default function WorkflowTemplateStates({
	template,
	states,
	statesUpdate,
}) {
	const history = useHistory();
	const { setBreadcrumbs } = useNavContext();
	const { setContextualNav } = useNavContext();
	const [currentTab, setCurrentTab] = useState(0);

	const [addStateDialogOpen, setAddStateDialogOpen] = useState(false);
	const [availableStates, setAvailableStates] = useState([]);

	// const schema = yup.object({
	// 	next: yup.array().of(
	// 		yup.object().shape({
	// 			state: yup
	// 				.string()
	// 				.min(3)
	// 				.required()
	// 				.label('State Name'),
	// 		})
	// 	),
	// });

	const schema = yup.object({
		next: yup
			.string()
			.test('JSON', 'Invalid JSON', value => {
				try {
					JSON.parse(value);
					return true;
				} catch {
					return false;
				}
			})
			.required()
			.label('Next States'),
		viewers: yup
			.string()
			.test('JSON', 'Invalid JSON', value => {
				try {
					JSON.parse(value);
					return true;
				} catch {
					return false;
				}
			})
			.required()
			.label('Viewers'),
		editors: yup
			.string()
			.test('JSON', 'Invalid JSON', value => {
				try {
					JSON.parse(value);
					return true;
				} catch {
					return false;
				}
			})
			.required()
			.label('Editors'),
	});

	//Boilerplate for future implementation set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([...workflow_nav_item, ...workflow_options]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'Workflows', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	const onAddState = () => {
		var newAvailableStates = [];
		for (var i = 0; i < AllStates.length; i++) {
			if (!_.find(states, { state: AllStates[i].value })) {
				newAvailableStates.push(AllStates[i]);
			}
		}

		setAvailableStates(newAvailableStates);
		setAddStateDialogOpen(true);
	};

	const handleAddState = newState => {
		let tempStates = [
			...states,
			{
				state: newState,
				next: [],
				viewers: { users: [], groups: [], variables: [] },
				editors: { users: [], groups: [], variables: [] },
				deleters: { users: [], groups: [], variables: [] },
			},
		];
		setCurrentTab(states.length);
		statesUpdate(tempStates);
	};

	const handleDeleteState = state => {
		const stateIndex = _.findIndex(states, { state: state.state });
		let tempStates = [...states];
		tempStates.splice(stateIndex, 1);
		statesUpdate(tempStates);

		if (currentTab >= states.length - 1) {
			setCurrentTab(states.length - 2);
		}
	};

	const handleUpdateState = state => {
		const stateIndex = _.findIndex(states, { state: state.state });
		let tempStates = [...states];
		tempStates[stateIndex] = state;
		statesUpdate(tempStates);
	};

	const handleStateChange = (oldState, newState) => {
		const stateIndex = _.findIndex(states, { state: oldState });
		let tempStates = [...states];
		tempStates[stateIndex].state = newState;
		statesUpdate(tempStates);
	};

	return (
		<>
			{template && (
				<Paper style={{ padding: 16 }}>
					<Button
						sx={{ margin: '1rem' }}
						variant="outlined"
						startIcon={<AddIcon />}
						onClick={onAddState}
					>
						Add State
					</Button>

					<BtTabBar
						currentTab={currentTab}
						onTabChange={(event, selectedTab) =>
							setCurrentTab(selectedTab)
						}
						style={{
							transform: 'translateY(-1px)',
							marginTop: 10,
						}}
					>
						{states.map((state, index) => (
							<BtTab
								label={state.state}
								{...a11yProps(index)}
								key={index}
							/>
						))}
					</BtTabBar>

					{states.map((state, index) => (
						<BtTabPanel
							currentTab={currentTab}
							index={index}
							key={index}
						>
							<WorkflowTemplateState
								state={state}
								handleDeleteState={handleDeleteState}
								handleUpdateState={handleUpdateState}
								handleStateChange={handleStateChange}
								stateList={states}
							/>
						</BtTabPanel>
					))}

					<AddStateDialog
						dialogOpen={addStateDialogOpen}
						onClose={() => {
							setAddStateDialogOpen(false);
						}}
						availableStates={availableStates}
						handleAddState={handleAddState}
					/>
				</Paper>
			)}
		</>
	);
}
