import React, {
	useState,
	useMemo,
	useEffect,
	forwardRef,
	useImperativeHandle,
	useCallback,
} from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import {
	Autocomplete,
	Box,
	Button,
	CircularProgress,
	ListItem,
	TextField,
	Typography,
} from '@mui/material';
import pluralize from 'pluralize';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import useUsingObjects from '../../hooks/useUsingObjects';

const BtMultiAdd = forwardRef(
	(
		{
			ActionIcon,
			addedItems,
			autocompleteStyle,
			autoFocus,
			creatable,
			disabled,
			primaryField,
			defaultAdded,
			idKey,
			immediateMode,
			itemLimit,
			items,
			loading,
			onAddedChange,
			onSelectionChange,
			onSubmit,
			outlinedButton,
			placeholder,
			renderOption,
			retrievingOptions,
			sortComparator,
			style,
			subject,
			...other
		},
		ref
	) => {
		const theme = useTheme();
		const screenSmall = useMediaQuery(theme.breakpoints.down('sm'));
		const usingObjects = useUsingObjects(items);

		const [selectedItems, setSelectedItems] = useState([]);
		const [_addedItems, setAddedItems] = useState([]);

		const addItems = useCallback(
			values => {
				const newItems = immediateMode ? values : selectedItems;
				const itemsToAdd = [
					...(_addedItems ?? addedItems),
					...newItems,
				].sort(sortComparator);

				setAddedItems(itemsToAdd);
				if (onAddedChange) {
					onAddedChange(newItems[0], itemsToAdd);
				}

				if (!immediateMode) {
					setSelectedItems([]);
					if (onSelectionChange) {
						onSelectionChange([]);
					}
				}

				if (onSubmit) {
					onSubmit(selectedItems, itemsToAdd);
				}
			},
			[
				_addedItems,
				addedItems,
				onAddedChange,
				onSelectionChange,
				onSubmit,
				selectedItems,
				sortComparator,
				immediateMode,
			]
		);

		useEffect(
			() => {
				if (defaultAdded) {
					setAddedItems(defaultAdded);
				}
			},
			[defaultAdded, immediateMode]
		);

		useEffect(
			() => {
				if (addedItems) {
					setAddedItems(addedItems);
				}
			},
			[addedItems, immediateMode]
		);

		useImperativeHandle(ref, () => ({
			addedItems: _addedItems,
			selectedItems: selectableItems,
			clear: () => {
				setSelectedItems([]);
				if (onSelectionChange) {
					onSelectionChange([]);
				}
			},
		}));

		const selectableItems = useMemo(
			() => {
				return (items || [])
					.filter(
						item =>
							!(_addedItems || [])
								.map(
									addedItem =>
										usingObjects
											? addedItem[idKey]
											: addedItem
								)
								.includes(usingObjects ? item[idKey] : item)
					)
					.sort(sortComparator);
			},
			[items, _addedItems, idKey, sortComparator, usingObjects]
		);

		const fieldDisabled = useMemo(
			() =>
				itemLimit
					? _addedItems?.length === itemLimit
					: false || loading || disabled,
			[itemLimit, _addedItems, loading, disabled]
		);

		return (
			<Box
				ref={ref}
				style={{
					display: 'flex',
					width: '100%',
					flexDirection: screenSmall ? 'column' : 'row',
					gap: 20,
					alignItems: screenSmall ? 'stretch' : 'flex-start',
					...style,
				}}
			>
				<Box
					style={{
						display: 'flex',
						width: '100%',
						justifyContent: 'flex-end',
						flexDirection: 'column',
						minHeight: 39,
					}}
				>
					<Autocomplete
						multiple
						style={{ width: '100%', ...autocompleteStyle }}
						options={selectableItems}
						freeSolo={creatable}
						value={selectedItems}
						loading={retrievingOptions}
						disabled={fieldDisabled}
						openOnFocus
						onChange={(event, value) => {
							const action = () => {
								if (immediateMode) {
									addItems(value);
									return;
								}
								setSelectedItems(value);
								if (onSelectionChange) {
									onSelectionChange(value);
								}
							};

							if (
								itemLimit &&
								(value || []).length <=
									itemLimit - (_addedItems || []).length
							) {
								action();
								return;
							}
							action();
						}}
						getOptionLabel={option =>
							(() => {
								if (usingObjects) {
									if (primaryField) {
										return option[primaryField];
									}
									return 'Item';
								}
								return option;
							})()
						}
						renderOption={(options, state) => {
							if (renderOption === undefined) {
								return (
									<ListItem {...options}>
										<Typography>
											{(() => {
												if (usingObjects) {
													if (primaryField) {
														return state[
															primaryField
														];
													}
													return 'Item';
												}
												return state;
											})()}
										</Typography>
									</ListItem>
								);
							}
							return renderOption(options, state);
						}}
						renderInput={params => (
							<TextField
								{...params}
								placeholder={placeholder}
								variant="standard"
								disabled={fieldDisabled}
								autoFocus={autoFocus}
							/>
						)}
						{...other}
					/>
				</Box>
				{!immediateMode && (
					<Button
						variant={outlinedButton ? 'outlined' : 'contained'}
						disabled={
							(selectedItems || []).length === 0 ||
							loading ||
							disabled
						}
						startIcon={
							loading ? (
								<CircularProgress size={20} />
							) : (
								ActionIcon
							)
						}
						onClick={addItems}
						style={{ whiteSpace: 'nowrap', padding: '0.6em 2em' }}
						disableElevation
					>
						{loading ? (
							<>
								<CircularProgress
									size={20}
									style={{ marginRight: 10 }}
								/>
								<Typography>
									{`Adding ${
										selectedItems?.length !== 1
											? pluralize.plural(subject)
											: pluralize.singular(subject)
									}...`}
								</Typography>
							</>
						) : (
							`Add ${
								selectedItems?.length !== 1
									? pluralize.plural(subject)
									: pluralize.singular(subject)
							}`
						)}
					</Button>
				)}
			</Box>
		);
	}
);

const itemsType = PropTypes.arrayOf(
	PropTypes.oneOfType([PropTypes.string, PropTypes.object])
);

BtMultiAdd.defaultProps = {
	ActionIcon: <AddIcon />,
	creatable: false,
	disabled: false,
	idKey: 'uuid',
	immediateMode: false,
	loading: false,
	placeholder: 'Search for items to add...',
	primaryField: 'uuid',
	subject: 'Item',
};

BtMultiAdd.propTypes = {
	ActionIcon: PropTypes.node,
	addedItems: itemsType,
	autocompleteStyle: PropTypes.object,
	autoFocus: PropTypes.bool,
	creatable: PropTypes.bool,
	defaultValue: itemsType,
	disabled: PropTypes.bool,
	idKey: PropTypes.string,
	immediateMode: PropTypes.bool,
	itemLimit: PropTypes.number,
	items: itemsType,
	loading: PropTypes.bool,
	onAddedChange: PropTypes.func,
	onSelectionChange: PropTypes.func,
	onSubmit: PropTypes.func,
	outlinedButton: PropTypes.bool,
	placeholder: PropTypes.string,
	primaryField: PropTypes.string,
	renderOption: PropTypes.func,
	retrievingOptions: PropTypes.bool,
	sortComparator: PropTypes.func,
	style: PropTypes.object,
	subject: PropTypes.string,
};

BtMultiAdd.displayName = 'BtAutocomplete';

export default BtMultiAdd;
