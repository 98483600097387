import BtDialog from '../../../BtDialog';
import { ScreensConfigContextProvider } from './ScreenConfigContext';
import { ScreenConfigDialogContent } from './ScreenConfigDialogContent';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import useFetch from '../../../../../hooks/useFetch';
import { screenListGet } from '../../../../../API';
import BtLoading from '../../../BtLoading';
import { ScreensContext } from '../../ScreensContext';
import BtError from '../../../BtError';
import { Typography } from '@mui/material';
import { Close, Reload } from 'mdi-material-ui';
import { set } from 'dot-object';

/**
 * Returns object array for autocomplete component
 * @param {object[]} options the user screen group || available org screens
 * @returns {object[]}
 */
export const autocompleteOptions = (options, screenGroup) => {
	const evalExistingPos = screenUuid => {
		const index = _.findIndex(screenGroup, { uuid: screenUuid });
		switch (index) {
			case -1:
				return null;
			default:
				return index + 1;
		}
	};

	return options.map((option, index) => ({
		label: option.screen_name,
		id: index,
		// position: index,
		uuid: option.uuid,
		// managedBy Organisation || User
		// ownerType Organisation || User
		//
		disabled:
			option.managedBy === 'Organisation' &&
			option.ownerType === 'Organisation',
		managedBy: option.managedBy,
		ownerType: option.ownerType,
		existingPosition: evalExistingPos(option.uuid),
		backgroundColor: option.background_color,
		description: option.ownerType,
	}));
};

/**
 *
 * @param {object[]} screenGroup
 * @returns {number[]} array of positions used by org screens
 */
const unavailablePositions = screenGroup => {
	let unavailable = [];
	screenGroup.forEach((screen, index) => {
		if (screen.ownerType === 'Organisation') unavailable.push(index + 1);
	});
	return unavailable;
};

export const ScreenConfigDialog = ({ open, onClose, onScreenChange }) => {
	// const [userScreenOptions, setUserScreenOptions] = useState(null);
	const { screens } = useContext(ScreensContext);
	const [addScreenOptions, setAddScreenOptions] = useState(null);
	const [copyScreenOptions, setCopyScreenOptions] = useState(null);

	const {
		loading: userScreenListLoading,
		data: userScreenList,
		error: userScreenListError,
		request: fetchUserScreenList,
	} = useFetch(screenListGet);

	useEffect(
		() => {
			fetchUserScreenList({ ownerFilter: 'User' });
		},
		[fetchUserScreenList]
	);

	const {
		loading: orgScreenListLoading,
		data: orgScreenList,
		error: orgScreenListError,
		request: fetchOrgScreenList,
	} = useFetch(screenListGet);

	useEffect(
		() => {
			fetchOrgScreenList({ ownerFilter: 'Organisation' });
		},
		[fetchOrgScreenList]
	);

	// prep the screen-to-edit autocomplete data
	const screenToEditOptions = useMemo(
		() => autocompleteOptions(screens, screens),
		[screens]
	);

	useEffect(
		() => {
			if (userScreenList && orgScreenList) {
				// build the options for adding a screen
				const filteredScreens = [
					...userScreenList,
					...orgScreenList,
				].filter(
					({ uuid }) =>
						!screens.map(({ uuid }) => uuid).includes(uuid)
				);
				const addOptions = autocompleteOptions(
					filteredScreens,
					screens
				);
				setAddScreenOptions(addOptions);
				// build the options for copying a screen

				const copyOptions = autocompleteOptions(
					userScreenList,
					screens
				);
				setCopyScreenOptions(copyOptions);
			}
		},
		[
			orgScreenList,
			screens,
			userScreenList,
			orgScreenListLoading,
			userScreenListLoading,
		]
	);

	const retry = useCallback(
		() => {
			fetchOrgScreenList({ ownerFilter: 'Organisation' });
			fetchUserScreenList({ ownerFilter: 'User' });
		},
		[fetchOrgScreenList, fetchUserScreenList]
	);

	return (
		<>
			<BtLoading loading={userScreenListLoading || orgScreenListLoading}>
				{userScreenListError || orgScreenListError ? (
					<BtError
						title="Retrieval Error"
						description={
							<>
								<Typography>
									An error occurred whilst trying to retrieve
									the required data.
								</Typography>
							</>
						}
						action={retry}
						actionLabel="Retry"
						actionIcon={<Reload />}
						fullScreen
						onClose={onClose}
					/>
				) : (
					addScreenOptions && (
						<ScreensConfigContextProvider
							screenGroup={screens}
							screenCount={screens.length}
							addScreenOptions={addScreenOptions}
							screenToEditOptions={screenToEditOptions}
							copyScreenOptions={copyScreenOptions}
						>
							<ScreenConfigDialogContent
								onScreenChange={onScreenChange}
								onClose={onClose}
								unavailablePositions={unavailablePositions(
									screens
								)}
								screenCount={screens.length}
								screenGroup={screens}
							/>
						</ScreensConfigContextProvider>
					)
				)}
			</BtLoading>
		</>
		// </BtDialog>
	);
};

ScreenConfigDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
	onScreenChange: PropTypes.func.isRequired,
};
