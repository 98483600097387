const yup = require('yup');

export const apiManagerGetListSchema = yup.array().of(
	yup.object({
		uuid: yup
			.string()
			.uuid()
			.required(),
		name: yup.string().default(''),
		description: yup.string().default(''),
		create_timestamp: yup.number().default(0),
		userCount: yup.number().default(0),
		modify_timestamp: yup.number().default(0),
		expire_timestamp: yup.number().default(0),
		generate_timestamp: yup.number().default(0),
		permissionsCount: yup.number().default(0),
		status: yup.string().default(''),
		prefix: yup.string().default(''),
	})
);

export const apiManagerGetSchema = yup.object({
	uuid: yup
		.string()
		.uuid()
		.required(),
	name: yup.string().default(''),
	description: yup.string().default(''),
	create_timestamp: yup.number().default(0),
	userCount: yup.number().default(0),
	modify_timestamp: yup.number().default(0),
	expire_timestamp: yup.number().default(0),
	generate_timestamp: yup.number().default(0),
	permissions: yup.array().of(
		yup
			.object({
				uuid: yup
					.string()
					.uuid()
					.required(),
				group: yup.string().required(),
				method: yup.string().required(),
				resource: yup.array().of(yup.string().required()),
			})
			.required()
	),
	status: yup.string().default(''),
	prefix: yup.string().default(''),
	ip_list: yup
		.array()
		.of(
			yup
				.object({
					uuid: yup
						.string()
						.uuid()
						.required(),
					name: yup.string().required(),
					ip: yup.string().required(),
					expire_timestamp: yup.number().nullable(),
				})
				.nullable()
		)
		.nullable(),
});

export const apiManagerGenKeySchema = yup.object({
	prefix: yup.string().default(''),
	key: yup.string().default(''),
	generate_timestamp: yup.number().default(0),
});

export const apiManagerAddSchema = yup.object({
	name: yup
		.string()
		.min(3)
		.max(30)
		.required(),
	description: yup
		.string()
		.max(256)
		.required(),
});

export const apiManagerUpdateSchema = yup.object({
	name: yup
		.string()
		.min(3)
		.max(30)
		.optional(),
	description: yup
		.string()
		.max(256)
		.optional(),
	enabled: yup.boolean().optional(),
});

export const apiManagerAddResponseSchema = yup.object({
	name: yup
		.string()
		.min(3)
		.max(30)
		.required(),
	description: yup
		.string()
		.max(256)
		.required(),
});

export const apiManagerAddPermissionSchema = yup.object({
	group: yup
		.string()
		.min(3)
		.max(32)
		.required(),
	method: yup
		.string()
		.max(32)
		.required(),
	resources: yup
		.array()
		.of(yup.string().required())
		.nullable(),
});

export const apiManagerAddPermissionResponseSchema = yup.object({
	uuid: yup
		.string()
		.uuid()
		.required(),
});

export const apiManagerEditPermissionSchema = yup.object({
	resources: yup
		.array()
		.of(yup.string().required())
		.nullable(),
});

export const apiManagerIpFilterPostSchema = yup.object({
	name: yup.string().nullable(),
	ip: yup
		.string()
		.matches(
			/^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/
		)
		.required(''),
	expire_timestamp: yup.number().nullable(),
});

export const apiManagerAddIpFilterSchema = yup.object({
	uuid: yup
		.string()
		.uuid()
		.required(),
	name: yup.string().nullable(),
	ip: yup
		.string()
		.matches(
			/^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/
		)
		.required(''),
	expire_timestamp: yup.number().nullable(),
});
