import { apiClient } from './api_client';

import {
	organisationGetSchema,
	organisationGetListSchema,
} from './validations/organisationValidation';

//========================================================================================================
// async function organisationGetList() {
// 	const req_url = 'organisation';
// 	try {
// 		const response = await apiClient({ method: 'get', url: req_url });

// 		if (response) {
// 			if (response.result === 'OK') {
// 				// Validate the returned data object
// 				await orgDetailsGetSchema.validate(response.orgDetails);
// 				return response.orgDetails;
// 			} else {
// 				throw new Error('Server Error');
// 			}
// 		}
// 	} catch (error) {
// 		throw new Error('Response Error');
// 	}
// }

//========================================================================================================
async function organisationGet() {
	const req_url = 'organisation';
	try {
		const response = await apiClient({ method: 'get', url: req_url });
		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let organisation = await organisationGetSchema.validate(
					response.organisation
				);
				return organisation;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function organisationGetById({ orgUuid }) {
	const req_url = `organisation/${orgUuid}`;
	try {
		const response = await apiClient({ method: 'get', url: req_url });
		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let organisation = await organisationGetSchema.validate(
					response.organisation
				);
				return organisation;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

export { organisationGet, organisationGetById };
