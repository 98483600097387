import React, { useEffect, useState, useCallback, useRef } from 'react';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import PropTypes from 'prop-types';
import RGL, { WidthProvider } from 'react-grid-layout';
import useBreakpointValue from '../../../hooks/useBreakpointValue';
import _, { cloneDeep, isEqual } from 'lodash';
import { useTheme } from '@mui/material/styles';
import { useMobileOrientation } from 'react-device-detect';
import { InsightComponentContainer } from '../Components/UiComponents/InsightComponentContainer';
import BtInsightComponentModal from '../Components/UiComponents/Dialogs/BtInsightComponentDialog';
import BtLoading from '../../../components/generic/BtLoading';
import { useAppContext } from '../../../context/ContextManager';
import v4 from 'uuid/v4';
import {
	createAddVisPayload,
	createLayoutChangePayload,
	evaluateColumns,
} from './utils';
import { LAYOUT_TYPES } from './visualisationConstants';
import { visualisationSchema } from '../../../API/validations/insightVisualizationsValidation';
import { Consolidate } from 'mdi-material-ui';
// import { InsightComponent } from '../Components/UiComponents/InsightComponentContainer';

export default function InsightPage({
	editEnabled,
	handlePageUpdate,
	insightPage,
	onEditSave,
	onEditQuit,
	palette,
	paletteConfig,
	collectionPalettes,
	visualisations,
	refreshCollectionData,
}) {
	const [widgetList, setWidgetList] = useState([]);
	// const { editWidth, insightCollection } = useInsightCollectionEditContext();
	const [layouts, setLayouts] = useState(null);
	const [showFullscreen, setShowFullscreen] = useState(false);
	const [selectedComponent, setSelectedComponent] = useState(null);
	const [modalEditEnabled, setModalEditEnabled] = useState(false);
	const [gridLayoutWidth, setGridLayoutWidth] = useState('100%');
	const theme = useTheme();

	const editWidth = 'Desktop';

	const ReactGridLayout = WidthProvider(RGL);

	const breakpoint = useBreakpointValue();

	// number of columns in the grid - responsive
	const [columns, setColumns] = useState();
	// the height of the grid row - responsive
	const [rowHeight, setRowHeight] = useState(100);
	// flag to control layout rendering
	const [gridReady, setGridReady] = useState(false);

	// context calls
	const { deviceType } = useAppContext();

	// Hooks
	// ref on the out div of the layout - to obtain size and resize events
	const layoutContainer = useRef();
	// returns the device orientation when it changes
	const { isLandscape } = useMobileOrientation();
	// console.log('====>>> RENDER <<<====');

	/**
	 * Returns the row height required for an approximate square grid cell
	 */
	const evaluateRowHeight = useCallback((columnCount, containerWidth) => {
		// console.log('evaluateRowHeight');
		const gridPadding = columnCount * 10 + 10;

		const tileWidth = (containerWidth - gridPadding) / columnCount;

		return tileWidth;
	}, []);

	const initializeLayouts = useCallback(
		() => {
			// console.log('initializeLayouts');
			if (!insightPage.layouts) {
				setLayouts({ lg: [] });
				setWidgetList([]);
			} else {
				var desktop_layout = insightPage.layouts.desktop.map(item => {
					let layout_item = {
						i: item.uuid,
						visualisation: item.visualisation,
						x: item.x,
						y: item.y,
						w: item.w,
						h: item.h,
					};

					return layout_item;
				});
				var tablet_layout = insightPage.layouts.tablet.map(item => {
					let layout_item = {
						i: item.uuid,
						visualisation: item.visualisation,
						x: item.x,
						y: item.y,
						w: item.w,
						h: item.h,
					};

					return layout_item;
				});
				var mobile_layout = insightPage.layouts.mobile.map(item => {
					let layout_item = {
						i: item.uuid,
						visualisation: item.visualisation,
						x: item.x,
						y: item.y,
						w: item.w,
						h: item.h,
					};

					return layout_item;
				});

				return {
					desktop: [...desktop_layout],
					tablet: [...tablet_layout],
					mobile: [...mobile_layout],
				};
			}
		},
		[insightPage]
	);

	useEffect(
		() => {
			// console.log('insightPage', insightPage);

			const newLayouts = initializeLayouts();
			setLayouts(newLayouts);

			let newVisualisationList = newLayouts.desktop.map(component => {
				let visComp = _.find(visualisations, {
					uuid: component.visualisation,
				});
				// TODO - improve this to ensure all properties are copied when the schema is updated
				let newVis = {};
				newVis.uuid = visComp.uuid;
				newVis.type = visComp.type;
				//newVis.layout = visComp.layout;
				newVis.datasource = visComp.datasource;
				newVis.title = visComp.title;
				newVis.subtitle = visComp.subtitle;
				newVis.vis_options = visComp.vis_options;
				newVis.palette = visComp.palette;
				if (visComp.dataModifier) {
					newVis.dataModifier = visComp.dataModifier;
				}

				//newVis.editmode = false; // Is this component curretly selected for edit
				return newVis;
			});
			setWidgetList(newVisualisationList);
		},
		[insightPage, initializeLayouts, setLayouts, visualisations]
	);

	const onLayoutChange = useCallback(
		layout => {
			const pageUpdate = createLayoutChangePayload(
				insightPage,
				editWidth,
				layout
			);

			const noChanges = isEqual(pageUpdate, insightPage);

			// console.log({ pageUpdate, insightPage, noChanges });

			if (handlePageUpdate && !noChanges && editEnabled) {
				// console.log('@onLayoutChange insightPage', {
				// 	layout,
				// 	pageUpdate,
				// });
				handlePageUpdate(pageUpdate);
			}
		},
		[editEnabled, handlePageUpdate, insightPage]
	);

	// When the layout container dimensions change, the column count and row height is re-evaluated
	useEffect(
		() => {
			if (layouts && deviceType) {
				// console.log('container size change');
				setGridReady(false);
				setColumns(null);
				setRowHeight(null);

				const newColumns = evaluateColumns(
					deviceType,
					breakpoint,
					isLandscape
				);
				const newRowHeight = evaluateRowHeight(
					newColumns,
					layoutContainer.current.parentElement.offsetWidth
				);

				setColumns(newColumns);
				setRowHeight(newRowHeight);
				if (widgetList) {
					setGridReady(true);
				}
			}
		},
		[
			breakpoint,
			deviceType,
			evaluateRowHeight,
			isLandscape,
			layouts,
			widgetList,
		]
	);

	const saveDashboard = async new_widget_list => {
		console.log('SAVE INSIGHT!');
	};

	const onBreakpointChange = breakpoint => {
		console.log('Breakpoint change: ' + breakpoint);
	};

	const onDragStart = type => event => {
		// This is a hack for firefox
		// Firefox requires some kind of initialization which we can do by adding this attribute @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
		event.dataTransfer.setData('dragData', type);

		//console.log('onDragStart');
	};

	// const handleNewLayout = useCallback(
	// 	layout => {
	// 		console.log('handleNewLayout');
	// 		//console.log({ layout });
	// 		/*const layoutUpdate = layout.map(layoutItem => ({
	// 			tile_uuid: layoutItem.i, // uuid of the tile content
	// 			uuid: tiles[appOrg.uuid][screenId][screenNumber].layouts[
	// 				deviceType
	// 			].find(({ tile_uuid }) => tile_uuid === layoutItem.i).uuid, // uuid of the layout item
	// 			x: layoutItem.x,
	// 			y: layoutItem.y,
	// 			w: layoutItem.w,
	// 			h: layoutItem.h,
	// 		}));

	// 		let newTiles = tiles;
	// 		newTiles[appOrg.uuid][screenId][screenNumber].layouts[
	// 			deviceType
	// 		] = layoutUpdate;
	// 		// console.log({ newTiles });

	// 		setTiles(newTiles);

	// 		const deviceLayout = layoutUpdate.map(item => {
	// 			let layout_item = {
	// 				i: item.tile_uuid,
	// 				tile: item.uuid,
	// 				x: item.x,
	// 				y: item.y,
	// 				w: item.w,
	// 				h: item.h,
	// 			};
	// 			return layout_item;
	// 		});
	// 		let gridLayouts = layouts;
	// 		gridLayouts[deviceType] = deviceLayout;

	// 		setLayouts(gridLayouts);*/
	// 		// setGridReady(false);
	// 		// setLayouts(null);
	// 	},
	// 	[
	// 		appOrg.uuid,
	// 		deviceType,
	// 		layouts,
	// 		//screenId,
	// 		//screenNumber,
	// 		setTiles,
	// 		tiles,
	// 	]
	// );

	const onDrop = useCallback(
		(layout, layoutItem, event) => {
			let item_type = event.dataTransfer.getData('dragData');
			let new_uuid = v4();
			layoutItem.i = new_uuid; // Give the new item a unique id/

			const newVis = visualisationSchema.validateSync({
				uuid: new_uuid,
				type: item_type,
				title: item_type,
				subtitle: item_type,
			});
			// const newVis = {
			// 	uuid: new_uuid,
			// 	type: item_type,
			// 	datasource: null,
			// 	title: item_type,
			// 	subtitle: item_type,
			// 	vis_options: { params: null },
			// };
			console.log({ newVis });
			let new_widgets = [...widgetList, newVis];

			setWidgetList(new_widgets);
			setLayouts({
				desktop: [...layout],
				tablet: [...layout],
				mobile: [...layout],
			});

			if (handlePageUpdate) {
				const [pageUpdate, visualisationsUpdate] = createAddVisPayload(
					insightPage,
					visualisations,
					newVis,
					layoutItem,
					layout
				);
				handlePageUpdate(pageUpdate, visualisationsUpdate);
			}
		},
		[handlePageUpdate, insightPage, visualisations, widgetList]
	);

	const onRemoveItem = useCallback(
		componentUuid => () => {
			// remove the visualization from local state
			const widgetListUpdate = widgetList.filter(
				({ uuid }) => uuid !== componentUuid
			);

			// remove the layout entries for the removed visualization
			const layoutsUpdate = {};
			LAYOUT_TYPES.forEach(type => {
				layoutsUpdate[type] = layouts[type].filter(
					({ i }) => i !== componentUuid
				);
			});

			// remove the visualization from backend array
			const visualisationsUpdate = visualisations.filter(
				({ uuid }) => uuid !== componentUuid
			);

			// remove the backend layout entries for the removed visualization
			const pageUpdate = cloneDeep(insightPage);
			LAYOUT_TYPES.forEach(type => {
				pageUpdate.layouts[type] = insightPage.layouts[type].filter(
					({ uuid }) => uuid !== componentUuid
				);
			});

			// dispatch our updates to state and the backend
			setWidgetList(widgetListUpdate);
			setLayouts(layoutsUpdate);
			handlePageUpdate(pageUpdate, visualisationsUpdate);
		},
		[handlePageUpdate, insightPage, layouts, visualisations, widgetList]
	);

	const onComponentUpdate = updatedComponent => {
		// console.log('onComponentUpdate');
		const widgetIndex = _.findIndex(widgetList, {
			uuid: updatedComponent.uuid,
		});

		const newWidgetList = [...widgetList];
		newWidgetList[widgetIndex] = updatedComponent;
		setWidgetList(newWidgetList);
	};

	const onEditClick = widget_id => () => {
		// console.log(widget_id);
		setModalEditEnabled(true);
		setShowFullscreen(true);
		// TODO: can we change the following to a _.find?
		setSelectedComponent(_.find(widgetList, { uuid: widget_id }));
	};

	const onFullscreenClick = widget_id => () => {
		setModalEditEnabled(false);
		setShowFullscreen(true);
		setSelectedComponent(_.find(widgetList, { uuid: widget_id }));
	};

	const onFullscreenClose = () => {
		setShowFullscreen(false);
	};

	useEffect(
		() => {
			if (!editEnabled) {
				setGridLayoutWidth('100%');
			} else {
				switch (editWidth) {
					default:
					case 'Desktop':
						setGridLayoutWidth('100%');
						break;
					case 'Tablet':
						setGridLayoutWidth(theme.breakpoints.values.md + 'px');
						break;
					case 'Mobile':
						setGridLayoutWidth(theme.breakpoints.values.sm + 'px');
						break;
				}
			}
		},
		[
			editEnabled,
			editWidth,
			theme.breakpoints.values.md,
			theme.breakpoints.values.sm,
		]
	);

	/**
	 * handles the tile resize event -
	 * - TODO optional aspect ratio and min max locks
	 * - passes new layout to handleNewLayout which transforms the data and saves it to state
	 */
	// const handleResize = useCallback(
	// 	// aspect ratio lock borrowed in it's entirety from -
	// 	// https://github.com/react-grid-layout/react-grid-layout/issues/267

	// 	(layout, oldLayoutItem, layoutItem, placeholder) => {
	// 		const heightDiff = layoutItem.h - oldLayoutItem.h;
	// 		const widthDiff = layoutItem.w - oldLayoutItem.w;
	// 		const changeCoef = oldLayoutItem.w / oldLayoutItem.h;
	// 		if (Math.abs(heightDiff) < Math.abs(widthDiff)) {
	// 			layoutItem.h = layoutItem.w / changeCoef;
	// 			placeholder.h = layoutItem.w / changeCoef;
	// 		} else {
	// 			layoutItem.w = layoutItem.h * changeCoef;
	// 			placeholder.w = layoutItem.h * changeCoef;
	// 		}

	// 		handleNewLayout(layout);
	// 	},

	// 	[handleNewLayout]
	// );

	return (
		<div
			ref={layoutContainer}
			style={{
				width: '100%',
				margin: 'auto',
				height: '100%',
				// minHeight: '1000px',
				minHeight: 'calc(100vh - 149px)',
				backgroundColor: theme.palette.background.insights,
			}}
		>
			<BtLoading loading={!gridReady}>
				{gridReady && (
					<div
						style={{
							minHeight: 'calc(100vh - 149px)',
						}}
					>
						<ReactGridLayout
							style={{
								minHeight: 'calc(100vh - 149px)',
							}}
							resizeHandles={['se']}
							width={'100%'}
							layout={layouts[deviceType]}
							cols={columns}
							rowHeight={rowHeight}
							margin={[10, 10]}
							className="layout"
							isDroppable={editEnabled}
							isDraggable={editEnabled}
							isResizable={editEnabled}
							// onResize={handleResize}
							// onDragStop={handleNewLayout}
							measureBeforeMount={true}
							compactType={null}
							preventCollision={true}
							onLayoutChange={onLayoutChange}
							onDrop={onDrop}
							useCSSTransforms={false}
						>
							{widgetList.map(component => (
								<div key={component.uuid}>
									{/* <InsightComponent
										key={component.uuid}
										component={component}
										onRemoveItem={onRemoveItem}
										onFullscreenClick={onFullscreenClick}
										onEditClick={onEditClick}
										editEnabled={editEnabled}
										exportEnabled={true}
										palette={palette}
									/> */}
									<InsightComponentContainer
										key={component.uuid}
										component={component}
										onRemoveItem={onRemoveItem}
										onFullscreenClick={onFullscreenClick}
										onEditClick={onEditClick}
										editEnabled={editEnabled}
										exportEnabled={true}
										palette={palette}
										paletteConfig={paletteConfig}
										collectionPalettes={collectionPalettes}
									/>
								</div>
							))}
						</ReactGridLayout>

						<BtInsightComponentModal
							open={showFullscreen}
							onClose={onFullscreenClose}
							component={selectedComponent}
							inEditMode={modalEditEnabled}
							onComponentUpdate={onComponentUpdate}
							refreshCollectionData={refreshCollectionData}
							palette={palette}
							paletteConfig={paletteConfig}
							collectionPalettes={collectionPalettes}
						/>
					</div>
				)}
			</BtLoading>
		</div>
	);
}

const layoutShape = PropTypes.shape({
	visualisation: PropTypes.string.isRequired,
	uuid: PropTypes.string.isRequired,
	x: PropTypes.number.isRequired,
	y: PropTypes.number.isRequired,
	h: PropTypes.number.isRequired,
	w: PropTypes.number.isRequired,
});

const visualisationShape = PropTypes.shape({
	uuid: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	datasource: PropTypes.shape({
		uuid: PropTypes.string.isRequired,
		transform: PropTypes.string,
		dataOrigin: PropTypes.string,
	}),
	vis_options: PropTypes.object,
});

InsightPage.propTypes = {
	editEnabled: PropTypes.bool,
	handlePageUpdate: PropTypes.func,
	insightPage: PropTypes.shape({
		description: PropTypes.string,
		layouts: PropTypes.shape({
			desktop: PropTypes.arrayOf(layoutShape),
			tablet: PropTypes.arrayOf(layoutShape),
			mobile: PropTypes.arrayOf(layoutShape),
		}).isRequired,
		name: PropTypes.string.isRequired,
		uuid: PropTypes.string.isRequired,
	}).isRequired,
	// onEditSave,
	// onEditQuit,
	palette: PropTypes.arrayOf(PropTypes.string).isRequired,
	visualisations: PropTypes.arrayOf(visualisationShape).isRequired,
};
