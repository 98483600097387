import React, {
	useState,
	createContext,
	useContext,
	useEffect,
	useCallback,
} from 'react';
import {
	insightCollectionGet,
	insightCollectionGetDraft,
	insightCollectionDraftUpdate,
	insightCollectionHistoryGet,
	userGetById,
} from '../../../API';
import { useAppContext } from '../../../context/ContextManager';
import { useDataProviderContext } from '../../../context/DataProvider/DataProvider';
import useFetch from '../../../hooks/useFetch';
import contextInitialiser from './utils/contextInitialiser';
import { updateIn } from 'immutable';
import updateDraft from './utils/updateDraft';
import { useSnackbar } from 'notistack';

const InsightCollectionEditContext = createContext(null);

const useInsightCollectionEditContext = () => {
	const context = useContext(InsightCollectionEditContext);
	if (context === undefined) {
		throw new Error(
			'useInsightCollectionEditContext was used outside of its Provider'
		);
	}
	return context;
};

function InsightCollectionEditContextProvider({
	insightCollectionUuid,
	// editMode,
	children,
}) {
	const [insightCollection, setInsightCollection] = useState(null);
	// const [dataSources, setDataSources] = useState([]);

	const [userCanEdit, setUserCanEdit] = useState(null);
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(true);
	const { enqueueSnackbar } = useSnackbar();

	const { userInfo, setActivityIndicator } = useAppContext();
	// const {
	// 	addDataSource,
	// 	removeDataSources,
	// 	setTimeRange,
	// 	setDataFilters,
	// } = useDataProviderContext();

	const getDraftRequest = useCallback(
		async () => {
			try {
				let collection;
				collection = await insightCollectionGetDraft({
					insightUuid: insightCollectionUuid,
				});
				return collection;
			} catch (error) {
				console.log(error);
			}
		},
		[insightCollectionUuid]
	);

	const initialize = useCallback(
		async () => {
			if (!loading) setLoading(true);
			const initData = await contextInitialiser(
				getDraftRequest,
				insightCollectionUuid,
				setError,
				userInfo
				// dataSources,
				// setDataSources,
				// addDataSource,
				// removeDataSources
			);

			// const initValues = await initData();

			// console.log(initValues);
			setInsightCollection(initData.draft);
			setUserCanEdit(initData.draftLockedToCurrentUser);
			setLoading(false);
		},
		[getDraftRequest, insightCollectionUuid, loading, userInfo]
	);

	useEffect(() => {
		initialize();
	}, []);

	// useEffect(
	// 	() => {
	// 		console.log(insightCollection);
	// 	},
	// 	[insightCollection]
	// );

	// const updateInsightCollection = async updatedCollection => {
	// 	// setInsightCollection(updatedCollection);

	// 	await insightCollectionDraftUpdate({
	// 		insightUuid: insightCollectionUuid,
	// 		draftUpdate: updatedCollection,
	// 	});
	// };

	const updateInsightCollection = () => {
		throw new Error(
			'call to depreciated function "updateInsightCollection"'
		);
	};

	const handleDraftUpdate = useCallback(
		async update => {
			setActivityIndicator(true);
			const result = await updateDraft(
				update,
				insightCollectionUuid,
				initialize
			);
			enqueueSnackbar(result.text, {
				variant: result.variant,
			});
			setActivityIndicator(false);
		},
		[
			enqueueSnackbar,
			initialize,
			insightCollectionUuid,
			setActivityIndicator,
		]
	);

	return (
		<InsightCollectionEditContext.Provider
			value={{
				insightCollectionUuid,
				insightCollection,
				updateInsightCollection,
				handleDraftUpdate,
				loading,
				setLoading,
				error,
				setError,
			}}
		>
			{children}
		</InsightCollectionEditContext.Provider>
	);
}

export {
	InsightCollectionEditContextProvider,
	useInsightCollectionEditContext,
};
