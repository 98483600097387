import React from 'react';

import BTAuthCard from './BTAuthCard';
import { Typography } from '@mui/material';

export default function Disabled() {
	return (
		<BTAuthCard
			heading={
				<Typography align="center">
					It seems that your account is disabled, please contact your
					administrator.
				</Typography>
			}
		/>
	);
}
