import React from 'react';

// import HomeIcon from '@mui/icons-material/Home';
import RouterIcon from '@mui/icons-material/Router';
import TimelineIcon from '@mui/icons-material/Timeline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Dashboard from '@mui/icons-material/Dashboard';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import TableChartIcon from '@mui/icons-material/TableChart';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import CodeIcon from '@mui/icons-material/Code';
import { PaletteSwatch } from 'mdi-material-ui';

const developer_options = [
	{
		id: 'PacketLog',
		text: 'Packet Logger',
		description: 'IoT UDP packet logger',
		route: '/PacketLogger',
		icon: <RouterIcon />,
		active: true,
	},
	{
		id: 'Dev Add Device',
		text: 'Dev Add Device',
		description: 'Device onboarding',
		route: '/DeveloperAddDevice',
		icon: <AddCircleIcon />,
	},
	{
		id: 'PubSub',
		text: 'PubSub',
		description: 'Publish/Subscribe test interface',
		route: '/PubSubDev',
		icon: <PublishedWithChangesIcon />,
	},
	{
		id: 'ServiceStats',
		text: 'Service Stats',
		description: 'Service level statistic report',
		route: '/ServiceStats',
		icon: <QueryStatsIcon />,
	},
	{
		id: 'VisDev',
		text: 'Vis Dev',
		description: 'Visualisation development area',
		route: '/VisDev',
		icon: <TimelineIcon />,
	},
	{
		id: 'UIComponents',
		text: 'UI Components',
		description: 'User interface controls showcase',
		route: '/UIComponents',
		icon: <Dashboard />,
	},
	{
		id: 'Forms',
		text: 'Forms',
		description: 'Form features demo',
		route: '/FormDev',
		icon: <NoteAltIcon />,
	},
	{
		id: 'AdvancedTableDev',
		text: 'Advanced Table',
		description:
			'Demonstrates advanced features such as paging, filtering, sorting and querying.',
		route: '/AdvancedTableDev',
		icon: <TableChartIcon />,
	},
	{
		id: 'PaletteDev',
		text: 'Palette',
		description: 'Edit the org palettes',
		route: '/PaletteDev',
		icon: <PaletteSwatch />,
	},
	{
		id: 'QueryBuilderDev',
		text: 'Query Builder',
		description: 'Query builder showcase',
		route: '/QueryBuilderDev',
		icon: <GolfCourseIcon />,
	},
	{
		id: 'JsonTools',
		text: 'JSON Tools',
		description: 'JSON helper tools',
		route: '/JsonTools',
		icon: <CodeIcon />,
	},
];

export default developer_options;
