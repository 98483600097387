import { ACTIVE, INACTIVE, UNPUBLISHED } from '../constants';

const moduleAssetTable = {
	insightCollection: {
		moduleFullName: 'Insight Collection',
		moduleTitle: 'Collection',
		moduleBackText: 'Back to Collection',
	},
	automation: {
		moduleFullName: 'Automation',
		moduleTitle: 'Automation',
	},
	workflowTemplate: {
		moduleFullName: 'Workflow Template',
		moduleTitle: 'Template',
		moduleBackText: 'Back to Templates',
	},
};

const requiredTextProperties = [
	'moduleFullName',
	'moduleTitle',
	'moduleBackText',
];

/**
 * Validates the lookup value
 * @param {*} result lookup result
 * @param {*} requiredProperties array of required properties
 * @returns true if valid, false if otherwise
 */
const evaluateResult = (result, requiredProperties) => {
	return requiredProperties.every(key => key in result);
};

const lookupModuleAssets = module => {
	// lookup the value
	const result = moduleAssetTable[module] || null;

	const isResultValid = evaluateResult(result, requiredTextProperties);

	// log warning if null
	(!result || !isResultValid) &&
		console.warn('no matching record in table, params:', module);

	return isResultValid ? result : null;
};

const nextStatus = currentStatus => {
	const statusTable = {};
	statusTable[UNPUBLISHED] = ACTIVE;
	statusTable[ACTIVE] = INACTIVE;
	statusTable[INACTIVE] = ACTIVE;
	return statusTable[currentStatus];
};

const moduleUrl = (module, path, recordUuid) => {
	const editUrl = {
		// automation: ,
		insightCollection: `/InsightCollection/${recordUuid}/Edit/Collection`,
		workflowTemplate: `/Workflows/Templates/${recordUuid}/Edit`,
	};

	const backUrl = {
		// automation: ,
		insightCollection: `/InsightCollection/${recordUuid}`,
		workflowTemplate: `/Workflows/Templates`,
	};

	const viewVersionUrl = {
		// automation: ,
		insightCollection: `/InsightCollection/${recordUuid}/View/`,
		// workflowTemplate: `/Workflows/Templates/${recordUuid}`,
	};

	const getUrl = () => {
		switch (path) {
			case 'edit':
				return editUrl[module];
			case 'backToRecord':
				return backUrl[module];
			case 'viewVersion':
				return viewVersionUrl[module];
			default:
				return null;
		}
	};

	const url = getUrl();

	// log warning if null
	!url &&
		console.warn('no matching record in table, params:', {
			module,
			path,
			recordUuid,
		});

	return url ? url : null;
};

export { nextStatus, lookupModuleAssets, moduleUrl };
