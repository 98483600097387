import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Controller, useFormContext } from 'react-hook-form';
import { IconButton, InputAdornment, Skeleton, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import useFormElRequired from '../../../hooks/useFormElRequired';

export default function BtFormPasswordField({
	name,
	label,
	multiline,
	isRequired,
	...other
}) {
	const {
		control,
		watch,
		loading,
		sending,
		validationSchema,
		formState: { errors },
	} = useFormContext() || { formState: { formState: {} } };
	const value = watch(name);

	// Password visibility state
	const [showPassword, setShowPassword] = useState(false);

	// Password visibility
	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDown = event => {
		event.preventDefault();
	};

	const required = useFormElRequired(isRequired, validationSchema, name);

	if (loading) {
		return (
			<Skeleton animation="wave" style={{ width: '100%', height: 45 }} />
		);
	}

	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<TextField
					variant="standard"
					size="small"
					label={
						!!label
							? `${label}${!required ? ' (optional)' : ''}`
							: undefined
					}
					color="primary"
					fullWidth
					{...field}
					disabled={loading || sending}
					value={value}
					error={!!errors[name]}
					helperText={errors[name]?.message}
					{...other}
					type={showPassword ? 'text' : 'password'}
					autoComplete="password"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDown}
								>
									{showPassword ? (
										<VisibilityOff />
									) : (
										<Visibility />
									)}
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			)}
		/>
	);
}

BtFormPasswordField.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	isRequired: PropTypes.bool,
};
