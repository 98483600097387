import React, { createContext, useState } from 'react';

export const WizardContext = createContext(null);

const withWizard = Component => props => {
	const [values, setValues] = useState();

	return (
		<WizardContext.Provider value={{ ...values, setValues }}>
			<Component {...props} />
		</WizardContext.Provider>
	);
};

export default withWizard;
