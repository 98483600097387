import {
	mdiWarehouse,
	mdiFactory,
	mdiFinance,
	mdiAccountGroupOutline,
	mdiStore,
	mdiAlertOutline,
	mdiHammerWrench,
	mdiAccountTie,
} from '@mdi/js';

export default function ImageResolver(iconName) {
	switch (iconName) {
		case 'Factory':
			return mdiFactory;
		case 'Warehouse':
			return mdiWarehouse;
		case 'Finance':
			return mdiFinance;
		case 'People':
			return mdiAccountGroupOutline;
		case 'Store':
			return mdiStore;
		case 'Maintenance':
			return mdiHammerWrench;
		case 'AccountTie':
			return mdiAccountTie;
		default:
			return mdiAlertOutline;
	}
}
