function displayNameAndInitials(props) {
	function capitalize(name) {
		return name.charAt(0).toUpperCase() + name.slice(1);
	}

	const user_name =
		capitalize(props.first_name.split(' ')[0].split('-')[0]) +
		' ' +
		capitalize(
			props.last_name
				.split(' ')
				.pop()
				.split('-')
				.pop()
		);

	return {
		user_name: user_name,
		initials: user_name.split(' ')[0][0] + user_name.split(' ')[1][0],
	};
}

export { displayNameAndInitials };
