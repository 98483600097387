import React, { useEffect, useState } from 'react';
import { useNavContext } from '../../context/ContextManager';

// import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Box, Button, Typography } from '@mui/material';

// Data for landing page selection table and contextual navigation
import workflow_options from './WorkflowsOptions';

import workflow_breadcrumbs from './WorkflowsBreadcrumbs';

import workflow_nav_item from './WorkflowsNavItem';
import BtScreens from '../../components/generic/screens/BtScreens';

export default function Workflows() {
	const { setContextualNav } = useNavContext();
	const { setBreadcrumbs } = useNavContext();

	//Boilerplate for future implementation set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([...workflow_nav_item, ...workflow_options]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'Workflows', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	return (
		<BtScreens screenGroupKey="workflows" />
		// <>
		// 	<Container maxWidth="lg" />
		// </>
	);
}
