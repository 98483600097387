import React, { memo, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	CircularProgress,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
} from '@mui/material';
import * as yup from 'yup';
import {
	BtForm,
	BtFormActionsContainer,
	BtFormContent,
	BtFormSelect,
	BtFormTextField,
	withFormContextMethods,
} from '../../../../../components/generic/forms';
import BtDialog from '../../../../../components/generic/BtDialog';
import { PlusCircle } from 'mdi-material-ui';
import v4 from 'uuid/v4';
import { useUpdateCollectionDraft } from './utils/useUpdateCollectionDraft';

const blankDefaults = {
	name: '',
	description: '',
	dataView: null,
};

const ActionButtons = withFormContextMethods(
	({ formState: { isDirty }, onClose, sending }) => {
		return (
			<>
				<Button variant="outlined" onClick={onClose} disabled={sending}>
					Cancel
				</Button>
				<Button
					variant="contained"
					disabled={!isDirty || sending}
					type="submit"
					startIcon={
						sending ? (
							<CircularProgress size={20} />
						) : (
							<PlusCircle />
						)
					}
					disableElevation
				>
					Add Data Source
				</Button>
			</>
		);
	}
);

export const AddDataSourceDialog = memo(function AddDataSourceDialog({
	dataViews,
	dataSources,
	onClose,
	open,
	setDataSources,
}) {
	const [sending, setSending] = useState(false);
	const updateCollection = useUpdateCollectionDraft();

	const existingNames = useMemo(() => dataSources?.map(({ name }) => name), [
		dataSources,
	]);

	const schema = useMemo(
		() =>
			yup.object({
				name: yup
					.string()
					.min(3)
					.max(50)
					.notOneOf(
						existingNames || [],
						'A Data Source with this name already exists'
					)
					.required()
					.label('Data Source Name'),
				description: yup
					.string()
					.max(300)
					.label('Data Source Description'),
				dataView: yup
					.string()
					// .uuid()
					// .oneOf(appOrg.insight_groups.map(group => group.uuid))
					.label('Data View')
					.nullable(),
			}),
		[existingNames]
	);

	const messages = useMemo(
		() => ({
			success: 'Data Source Added',
			error: 'Failed to Add Data Source',
		}),
		[]
	);

	const callbacks = useMemo(
		() => ({
			success: update => {
				setDataSources(update.datasources);
				onClose();
				setSending(false);
			},
			error: setSending(false),
		}),
		[onClose, setDataSources]
	);

	const dataViewItems = useMemo(
		() =>
			dataViews.map(source => {
				const item = {};
				item.value = source.uuid;
				item.label = source.name;
				return item;
			}),
		[dataViews]
	);

	const onSubmit = useCallback(
		(values, reset) => {
			setSending(true);
			console.log('Should create data source with values', values);
			const newDataSource = {};
			newDataSource.name = values.name;
			newDataSource.description = values.description;
			newDataSource.dataView =
				values.dataView?.length === 0 ? null : values.dataView;
			newDataSource.uuid = v4();
			newDataSource.filterDependencies = [];
			newDataSource.predefinedFilters = [];
			newDataSource.transforms = [];
			const dataSourcesUpdate = [...dataSources, newDataSource];

			updateCollection({
				update: { datasources: dataSourcesUpdate },
				messages,
				callbacks,
			});
		},
		[callbacks, dataSources, messages, updateCollection]
	);

	return (
		<BtDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
			{sending && (
				<LinearProgress
					style={{
						position: 'absolute',
						top: 0,
						width: '100%',
					}}
				/>
			)}
			<DialogTitle>Add data source</DialogTitle>
			<BtForm
				onSubmit={onSubmit}
				sending={sending}
				validationSchema={schema}
				defaultValues={blankDefaults}
			>
				<DialogContent>
					<BtFormContent>
						<BtFormTextField name="name" label="Name" autoFocus />
						<BtFormTextField
							name="description"
							label="Description"
							multiline
						/>
						<BtFormSelect
							name="dataView"
							label="Data View"
							items={dataViewItems}
						/>
					</BtFormContent>
				</DialogContent>
				<DialogActions>
					<BtFormActionsContainer>
						<ActionButtons onClose={onClose} sending={sending} />
					</BtFormActionsContainer>
				</DialogActions>
			</BtForm>
		</BtDialog>
	);
});

ActionButtons.propTypes = {
	isDirty: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	sending: PropTypes.bool.isRequired,
};

AddDataSourceDialog.propTypes = {
	dataViews: PropTypes.array,
	dataSources: PropTypes.array,
	insightUuid: PropTypes.string.isRequired,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	setDataSources: PropTypes.func.isRequired,
};
