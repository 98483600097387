import { Autocomplete, MenuItem, TextField } from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';
import SelectItem from './SelectItem';

export const TilesAutocomplete = ({
	disabled,
	hidden,
	errorMessage,
	error,
	onChange,
	options,
	label,
	value,
	optionDisableEnabled,
	showDescription,
}) => {
	const [inputValue, setInputValue] = useState('');

	return (
		<Autocomplete
			style={{ display: hidden ? 'none' : 'inline' }}
			disabled={disabled}
			clearOnBlur
			clearOnEscape
			value={value}
			onChange={(event, newValue) => {
				onChange(newValue);
			}}
			inputValue={inputValue}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue);
			}}
			autoHighlight
			id={label.replaceAll(' ', '_')}
			options={options}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			renderInput={params => (
				<TextField
					{...params}
					variant="standard"
					label={label}
					inputProps={{
						...params.inputProps,
					}}
					sx={{
						width: 266,
						paddingBottom: error ? '21.5px' : 0,
					}}
					error={error}
					helperText={error && errorMessage}
				/>
			)}
			renderOption={(props, option) => {
				return (
					// <li {...props} key={option.id}>
					// 	{option.label}
					// </li>

					<MenuItem {...props} key={option.id}>
						<SelectItem
							label={option.label}
							description={
								showDescription ? option.description : null
							}
							// additionalText={option.additionalText}
						/>
					</MenuItem>
				);
			}}
			getOptionDisabled={option =>
				option.disabled === true && optionDisableEnabled
			}
		/>
	);
};

TilesAutocomplete.propTypes = {
	disabled: PropTypes.bool,
	hidden: PropTypes.bool,
	error: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	initialValue: PropTypes.object,
	options: PropTypes.arrayOf(PropTypes.object).isRequired,
	label: PropTypes.string,
	value: PropTypes.object,
	optionDisableEnabled: PropTypes.bool,
	showDescription: PropTypes.bool,
};
