import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import { ButtonBase, styled } from '@mui/material';
import Color from 'color';

import ArrowIcon from '@mui/icons-material/ArrowForwardIos';
import CheckIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import ErrorIcon from '@mui/icons-material/Error';

import { INVALID, NOT_ACCESSED, VALID } from '../../processing/utils/constants';
import { useWorkflowSessionContext } from '../../contexts/WorkflowSessionContext';

const StatusIconClass = 'StatusIcon';

const Button = styled(ButtonBase)(({ theme }) => ({
	alignItems: 'center',
	backgroundColor: Color(theme.palette.primary.main)
		.fade(0.82)
		.toString(),
	borderRadius: 4,
	display: 'flex',
	height: 36,
	justifyContent: 'flex-start',
	padding: '0 8px',
	boxSizing: 'border-box',
	width: '100%',

	'&:hover': {
		backgroundColor: Color(theme.palette.primary.main)
			.fade(0.75)
			.toString(),
	},

	transition: theme.transitions.create(['background-color'], {
		duration: '0.2s',
	}),
}));

const ButtonContent = styled('span')(() => ({
	alignContent: 'center',
	display: 'flex',
	justifyContent: 'space-between',
	width: '100%',
}));

const StyledArrow = styled(ArrowIcon)(({ theme }) => ({
	color: Color(theme.palette.text.primary)
		.fade(0.4)
		.toString(),
}));

const ContentContainer = styled('div')(() => ({
	alignItems: 'center',
	display: 'flex',
	overflow: 'hidden',
}));

const StatusIconContainer = styled('div')(({ status, theme }) => ({
	alignItems: 'center',
	display: 'flex',
	height: 20,
	justifyContent: 'center',
	marginRight: '0.6em',
	width: 20,

	'& > .StatusIcon': {
		color: (() => {
			switch (status) {
				case INVALID:
					return theme.palette.indicators.error.main;

				case NOT_ACCESSED:
					return Color(theme.palette.text.primary)
						.fade(0.5)
						.toString();

				case VALID:
					return theme.palette.indicators.success.main;

				default:
					return 'transparent';
			}
		})(),
		fontSize: status === NOT_ACCESSED ? 12 : 20,
	},
}));

const Title = styled('span')(() => ({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
}));

export const WorkflowPageButton = forwardRef(({ template }, ref) => {
	const { setPage, workflow } = useWorkflowSessionContext();

	const attributes = useMemo(
		() => {
			const { status, valid } = template.page;

			if (status === NOT_ACCESSED) {
				return {
					Icon: <CircleIcon className={StatusIconClass} />,
					status: NOT_ACCESSED,
				};
			}

			if (valid) {
				return {
					Icon: (
						<CheckIcon
							className={StatusIconClass}
							fontSize="20px"
						/>
					),
					status: VALID,
				};
			}

			return {
				Icon: <ErrorIcon className={StatusIconClass} fontSize="20px" />,
				status: INVALID,
			};
		},
		[template]
	);

	const title = useMemo(
		() => {
			const foundPage = workflow.pages.find(
				({ uuid }) => uuid === template?.pageUuid
			);

			const name = (() => {
				if (!foundPage) {
					return template?.pageUuid;
				}

				const { uuid } =
					template?.page.children?.find(({ primary }) => primary) ||
					{};

				const primaryAnswer = template.page.answers[uuid];

				if (primaryAnswer) {
					return primaryAnswer;
				}

				return foundPage.name;
			})();

			return name;
		},
		[template, workflow]
	);

	return (
		<Button
			ref={ref}
			focusRipple
			onClick={() => setPage(template.page, template.uuid)}
		>
			<ButtonContent>
				<ContentContainer>
					<StatusIconContainer status={attributes.status}>
						{attributes.Icon}
					</StatusIconContainer>
					<Title>{title}</Title>
				</ContentContainer>
				<StyledArrow />
			</ButtonContent>
		</Button>
	);
});

WorkflowPageButton.propTypes = {
	template: PropTypes.shape({
		page: PropTypes.shape({
			answers: PropTypes.object,
			children: PropTypes.arrayOf(PropTypes.object).isRequired,
			status: PropTypes.string.isRequired,
			valid: PropTypes.bool,
		}).isRequired,
		pageUuid: PropTypes.string.isRequired,
		uuid: PropTypes.string.isRequired,
	}),
};

WorkflowPageButton.displayName = 'PageButton';

export default WorkflowPageButton;
