import React, {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';

import { ButtonBase, Link } from '@mui/material';
import Color from 'color';
import { styled } from '@mui/material/styles';
import useUsingObjects from '../../hooks/useUsingObjects';
import { v4 as uuid } from 'uuid';

import { useTheme } from '@mui/styles';
import { getSafeColorForBackground } from '../../utils/colourUtils';
import useOverflowedElementCount from '../../hooks/useOverflowedElementCount';

const Container = styled('div')(() => ({
	margin: '1em 0',
	position: 'relative',
	width: '100%',
}));

const ButtonContainer = styled('div')(({ height, hashidden }) => ({
	boxSizing: 'border-box',
	height,
	overflow: 'hidden',
	paddingRight: hashidden ? 32 : 0,
	position: 'relative',
	width: '100%',
}));

const Button = styled(ButtonBase)(({ color, selected, theme }) => ({
	backgroundColor: (() => {
		if (color) {
			return selected
				? color
				: Color(color)
						.fade(0.6)
						.toString();
		}

		return selected
			? theme.palette.primary.main
			: theme.palette.grey['300'];
	})(),
	color: (() => {
		if (selected) {
			return getSafeColorForBackground(color);
		}

		return 'inherit';
	})(),
	borderRadius: 8,
	height: 32,
	margin: '0 8px 8px 0',
	padding: '0 1em',
}));

export const MoreIndicatorContainer = styled(ButtonBase)(({ left, theme }) => ({
	alignItems: 'center',
	backgroundColor: theme.palette.secondary.main,
	borderRadius: 16,
	color: '#ffffff',
	display: 'flex',
	fontSize: 12,
	fontWeight: 'bold',
	height: 32,
	justifyContent: 'center',
	left,
	marginLeft: 8,
	position: 'absolute',
	top: 0,
	width: 32,

	'&:focus': {
		outline: `${theme.palette.indicators.info.main} solid 2px`,
	},
}));

export const BtQuickFilter = forwardRef(
	(
		{
			colorKey,
			defaultSelected,
			filters,
			idKey,
			labelKey,
			multiple,
			nullable,
			onChange,
			value,
			showAllOption,
		},
		ref
	) => {
		const buttonContainerRef = useRef();
		const buttonWrapperRef = useRef();

		const theme = useTheme();
		const usingObjects = useUsingObjects(filters);
		const [selected, setSelected] = useState();
		const [showHidden, setShowHidden] = useState(false);

		const ALL_ID = 'all';
		const GREY_COLOR = theme.palette.grey['300'];

		useImperativeHandle(ref, () => ({
			value: selected,
		}));

		const {
			count: overflowCount,
			firstHiddenIndex,
			topRowWidth: moreBtnPos,
		} = useOverflowedElementCount(buttonWrapperRef);

		useEffect(
			() => {
				if (overflowCount === 0) {
					setShowHidden(false);
				}
			},
			[overflowCount]
		);

		const getColor = useCallback(
			filter => {
				if (usingObjects && filter[idKey] !== ALL_ID && colorKey) {
					return theme.palette.colourPicker[
						filter[colorKey]?.toLowerCase()
					];
				}

				return GREY_COLOR;
			},
			[colorKey, GREY_COLOR, idKey, theme, usingObjects]
		);

		useEffect(
			() => {
				if (!!defaultSelected && !selected) {
					setSelected(defaultSelected);
				}
			},
			[defaultSelected, selected]
		);

		useEffect(
			() => {
				if (value) {
					setSelected(value);
				}
			},
			[value]
		);

		const isSelected = useCallback(
			filter => {
				if (multiple && (selected || []).includes(filter)) {
					return true;
				}

				return selected === filter;
			},
			[multiple, selected]
		);

		const handleFilterClick = (filter, selectedStatus, isAll = false) => {
			if (multiple) {
				let newSelected;
				if (isAll) {
					newSelected = [];
				} else {
					newSelected = selectedStatus
						? (selected || []).filter(item => item !== filter)
						: [...(selected || []), filter];
				}

				setSelected(newSelected);
				onChange?.(newSelected);
				return;
			}

			const newSelected =
				isAll || (nullable && selectedStatus) ? null : filter;
			setSelected(newSelected);
			onChange?.(newSelected);
		};

		useEffect(
			() => {
				if (!nullable && !value && (filters || []).length > 1) {
					setSelected(showAllOption ? null : filters[0]);
				}
			},
			[filters, nullable, showAllOption, value]
		);

		return (
			<Container ref={ref}>
				<ButtonContainer
					ref={buttonContainerRef}
					height={
						showHidden
							? buttonWrapperRef?.current?.clientHeight
							: 32
					}
					expanded={+showHidden}
					hashidden={+(overflowCount > 0)}
				>
					<div ref={buttonWrapperRef}>
						{showAllOption && (
							<Button
								color={GREY_COLOR}
								selected={
									!selected || (selected || []).length === 0
								}
								onClick={() =>
									handleFilterClick(null, null, true)
								}
								focusRipple
							>
								All
							</Button>
						)}
						{(filters || []).map((filter, index) => {
							const selectedStatus = isSelected(filter);

							return (
								<Button
									key={
										usingObjects
											? filter[idKey] ?? uuid()
											: filter
									}
									color={getColor(filter)}
									selected={selectedStatus}
									onClick={() =>
										handleFilterClick(
											filter,
											selectedStatus
										)
									}
									focusRipple
									tabIndex={
										index >= firstHiddenIndex && !showHidden
											? -1
											: 0
									}
								>
									{usingObjects ? filter[labelKey] : filter}
								</Button>
							);
						})}
						{showHidden && (
							<Link
								onClick={() => setShowHidden(false)}
								style={{
									cursor: 'pointer',
									whiteSpace: 'nowrap',
								}}
								className="overflow_not_countable"
							>
								Show Less
							</Link>
						)}
					</div>
				</ButtonContainer>
				{overflowCount > 0 &&
					!showHidden && (
						<MoreIndicatorContainer
							onClick={() => setShowHidden(true)}
							left={moreBtnPos}
						>
							{overflowCount < 100 ? `+${overflowCount}` : '...'}
						</MoreIndicatorContainer>
					)}
			</Container>
		);
	}
);

const filterPropType = PropTypes.oneOfType([
	PropTypes.string,
	PropTypes.object,
]);

const selectedPropType = PropTypes.oneOfType([
	filterPropType,
	PropTypes.arrayOf(filterPropType),
]);

BtQuickFilter.propTypes = {
	colorKey: PropTypes.string,
	defaultSelected: selectedPropType,
	filters: PropTypes.arrayOf(filterPropType),
	idKey: PropTypes.string,
	labelKey: PropTypes.string,
	multiple: PropTypes.bool,
	nullable: PropTypes.bool,
	onChange: PropTypes.func,
	value: selectedPropType,
	showAllOption: PropTypes.bool,
};

BtQuickFilter.displayName = 'QuickFilter';

export default BtQuickFilter;
