import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';

import { styled } from '@mui/styles';
import {
	buildGroupBarData,
	buildGroupedBarVisOptions,
	buildLineData,
	buildLineVisOptions,
	phonics,
} from '../../../Development/vis-dev/mockData/demoChartDataGenerators';
import LineChartComponent from '../../../views/Insights/Components/VisComponents/LineChartComponent';
import BarChartComponent from '../../../views/Insights/Components/VisComponents/BarChartComponent';
import StatusCardComponent from '../../../views/Insights/Components/VisComponents/StatusCardComponent';
import { demoStatusCardData } from '../../../Development/vis-dev/mockData/statusCard/data';
import ActionButtonContainer from '../../generic/ActionButtonContainer';

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
	backgroundColor: theme.palette.background.insights,
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
	backgroundColor: theme.palette.background.insights,
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
	backgroundColor: theme.palette.background.insights,
}));

const ChartsContainer = styled(Box)(() => ({
	display: 'flex',
	flexWrap: 'wrap',
}));

const ChartContainer = styled(Box)(({ theme }) => ({
	// display: 'flex',
	height: '300px',
	width: '450px',
	// maxWidth: '450px',
	minWidth: '200px',
	flexGrow: 1,
	backgroundColor: theme.palette.background.paper,
	borderRadius: '8px',
	margin: '5px',
	padding: '5px',
}));

const Line = ({ palette }) => {
	const sourceData = palette.palette.map(({ name }) => name);
	const colours = palette.palette.map(({ hex }) => hex) || [];
	const data = buildLineData(sourceData);
	const visOptions = buildLineVisOptions(sourceData);
	return (
		<ChartContainer>
			<LineChartComponent
				data={data}
				visOptions={visOptions}
				palette={colours}
			/>
		</ChartContainer>
	);
};

const GroupedBar = ({ palette }) => {
	const sourceCategories = phonics.slice(0, 5);
	// const sourceCategories = palette;
	// const subCategories = ['email', 'sms', 'social'];
	const sourceData = palette.palette.map(({ name }) => name);
	const colours = palette.palette.map(({ hex }) => hex) || [];

	const subCategories = sourceData;
	const data = buildGroupBarData(sourceCategories, subCategories);
	const visOptions = buildGroupedBarVisOptions(subCategories);
	return (
		<ChartContainer>
			<BarChartComponent
				data={data}
				visOptions={visOptions}
				palette={colours}
			/>
		</ChartContainer>
	);
};

export default function PaletteDemoDialog({ palette, onClose, open }) {
	const _visOptions = useMemo(() => {
		return {
			params: [
				{
					label: 'Phase 3',
					value: 'E3',
				},
			],
		};
	}, []);
	return (
		<Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
			<StyledDialogTitle>Chart Examples</StyledDialogTitle>
			<StyledDialogContent>
				<ChartsContainer>
					<Line
						palette={palette}
						// palette={theme?.palette.map(({ hex }) => hex) || []}
					/>
					<GroupedBar
						palette={palette}

						// palette={theme?.palette.map(({ hex }) => hex) || []}
					/>
					{palette && (
						<ChartContainer sx={{ maxWidth: '300px', flexGrow: 0 }}>
							<StatusCardComponent
								data={demoStatusCardData}
								visOptions={_visOptions}
								palette={palette.palette.map(({ hex }) => hex)}
							/>
						</ChartContainer>
					)}
				</ChartsContainer>
			</StyledDialogContent>

			<StyledDialogActions>
				<ActionButtonContainer>
					<Button
						variant="contained"
						disableElevation
						onClick={onClose}
					>
						close
					</Button>
				</ActionButtonContainer>
			</StyledDialogActions>
		</Dialog>
	);
}

PaletteDemoDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	palette: PropTypes.shape({
		uuid: PropTypes.string.isRequired,
		palette: PropTypes.arrayOf(
			PropTypes.shape({
				hex: PropTypes.string.isRequired,
				uuid: PropTypes.string.isRequired,
			})
		).isRequired,
	}),
};

GroupedBar.propTypes = {
	palette: PropTypes.shape({
		uuid: PropTypes.string.isRequired,
		palette: PropTypes.arrayOf(
			PropTypes.shape({
				hex: PropTypes.string.isRequired,
				uuid: PropTypes.string.isRequired,
			})
		).isRequired,
	}),
};

Line.propTypes = {
	palette: PropTypes.shape({
		uuid: PropTypes.string.isRequired,
		palette: PropTypes.arrayOf(
			PropTypes.shape({
				hex: PropTypes.string.isRequired,
				uuid: PropTypes.string.isRequired,
			})
		).isRequired,
	}),
};
