import { Button, DialogActions, Menu, MenuItem } from '@mui/material';
import { Lock, LockOpen } from 'mdi-material-ui';
import { memo, useCallback, useMemo, useState } from 'react';
import BtDialog from '../../../BtDialog';
import { useAppContext } from '../../../../../context/ContextManager';
import { useRecordManagementContext } from '../../RecordManagementContext';
import {
	CURRENT_USER,
	UNLOCKED,
	ANOTHER_USER,
	UNLOCK,
	LOCK_TO_OTHER,
	LOCK_TO_CURRENT,
	LOCK_STATUS,
} from '../../utils/constants';
import ActionButtonContainer from '../../../ActionButtonContainer';
import BtFilterSelect from '../../../filterSelect/BtFilterSelect';
import { StatusRow } from '../generic/StatusDisplay';
import { SwapHoriz } from '@mui/icons-material';

export const DraftLockStatus = () => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const {
		draftLockStatus,
		handleCallback,
		orgUsers,
		lockUser,
		sending,
	} = useRecordManagementContext();
	const { userInfo } = useAppContext();
	const [transferLockDialogOpen, setTransferLockDialogOpen] = useState(false);
	const [nextLockUser, setNextLockUser] = useState(null);
	const handleOpenMenu = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	const handleCloseTransferLockDialog = useCallback(() => {
		setTransferLockDialogOpen(false);
	}, []);

	// TODO - for future development
	// a user who can override the lock
	const superUser = false;

	const lockUserOptions = useMemo(
		() => {
			if (orgUsers) {
				const result = orgUsers
					.filter(user => user.uuid !== userInfo.uuid)
					.map(user => {
						const tempUser = {};
						tempUser.title = user.user_name;
						tempUser.id = user.uuid;
						return tempUser;
					});
				return result;
			}
		},
		[orgUsers, userInfo.uuid]
	);

	const handleNewLockUser = useCallback(nextUser => {
		if (nextUser) {
			setNextLockUser(nextUser);
		} else {
			setNextLockUser(null);
		}
	}, []);

	const attributes = useMemo(
		() => {
			const icons = {
				[UNLOCKED]: LockOpen,
				[CURRENT_USER]: Lock,
				[ANOTHER_USER]: Lock,
			};

			const labels = {
				[UNLOCKED]: 'The draft is not locked',
				[CURRENT_USER]: 'The draft is locked to you',
				[ANOTHER_USER]: `The Draft is locked to ${lockUser?.user_name}`,
			};

			const description = {
				[UNLOCKED]:
					'In order to view or edit the draft it needs to be locked to you',
				[CURRENT_USER]:
					'You can make changes to and publish the draft. Only you can edit or view the draft',
				[ANOTHER_USER]:
					'If you want to edit or view the draft ask the other user to either release the lock or transfer it to you',
			};

			const isDisabled = {
				[UNLOCKED]: false,
				[CURRENT_USER]: false,
				[ANOTHER_USER]: true,
			};

			const result = {};
			result.Icon = icons[draftLockStatus];
			result.label = labels[draftLockStatus];
			result.text = description[draftLockStatus];
			result.disabled = superUser ? false : isDisabled[draftLockStatus];
			return result;
		},
		[draftLockStatus, lockUser, superUser]
	);

	const handleTransferLockClick = useCallback(() => {
		handleCloseMenu();
		setTransferLockDialogOpen(true);
	}, []);

	const handleLockChange = useCallback(
		mode => {
			if (!mode) {
				console.warn('No mode specified for handleLockChange');
				return;
			}
			handleCloseMenu();
			if (mode === UNLOCK) {
				handleCallback({
					callback: LOCK_STATUS,
					mode,
					nextLockUser: null,
				});
			} else if (mode === LOCK_TO_CURRENT) {
				handleCallback({
					callback: LOCK_STATUS,
					mode,
					nextLockUser: userInfo.uuid,
				});
			} else if (mode === LOCK_TO_OTHER) {
				handleCallback({
					callback: LOCK_STATUS,
					mode,
					nextLockUser: nextLockUser.id,
				});
			}
		},
		[handleCallback, nextLockUser, userInfo.uuid]
	);

	const handleSelectLockUser = useCallback(
		() => {
			setTransferLockDialogOpen(false);
			handleLockChange(LOCK_TO_OTHER);
		},
		[handleLockChange]
	);

	const Body = memo(() => {
		const SuperUserMenu = () => {
			return (
				<>
					<MenuItem onClick={() => handleLockChange(UNLOCK)}>
						Release lock
					</MenuItem>
					<MenuItem onClick={handleTransferLockClick}>
						Transfer lock to a different user
					</MenuItem>
					<MenuItem onClick={() => handleLockChange(LOCK_TO_CURRENT)}>
						Lock draft to you
					</MenuItem>
				</>
			);
		};

		const Content = {
			[CURRENT_USER]: (
				<>
					<MenuItem onClick={() => handleLockChange(UNLOCK)}>
						Release lock
					</MenuItem>
					<MenuItem onClick={handleTransferLockClick}>
						Transfer lock to a different user
					</MenuItem>
				</>
			),
			[UNLOCKED]: (
				<MenuItem onClick={() => handleLockChange(LOCK_TO_CURRENT)}>
					Lock draft to you
				</MenuItem>
			),
			// TODO - future implementation of sending notification to the other user
			// [ANOTHER_USER]: (
			// 	<MenuItem onClick={() => handleLockChange()}>
			// 		Ask user to release lock
			// 	</MenuItem>
			// ),
			[ANOTHER_USER]: null,
		};

		return superUser ? <SuperUserMenu /> : Content[draftLockStatus];
	});

	return (
		<>
			<StatusRow
				icon={
					<attributes.Icon
						sx={{
							marginRight: '10px',
							fontSize: '30px',
						}}
					/>
				}
				label={attributes.label}
				text={attributes.text}
				onClick={handleOpenMenu}
				buttonText="change lock state"
				buttonDisabled={attributes.disabled || sending}
				// bodyComponent={
				// 	draftLockStatus !== UNLOCKED ? (
				// 		<BtUserRow user={lockUser} />
				// 	) : null
				// }
			/>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleCloseMenu}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				<Body handleCloseMenu={handleCloseMenu} />
			</Menu>
			<BtDialog
				open={transferLockDialogOpen}
				onClose={handleCloseTransferLockDialog}
				maxWidth="xs"
				fullWidth
			>
				<BtFilterSelect
					title="Select user to transfer lock to"
					options={lockUserOptions}
					onChange={handleNewLockUser}
					dense
					sortOptions
					placeholderText="Filter Users"
				/>
				<DialogActions>
					<ActionButtonContainer>
						<Button
							disableElevation
							variant="outlined"
							onClick={handleCloseTransferLockDialog}
						>
							cancel
						</Button>
						<Button
							disableElevation
							disabled={!nextLockUser}
							variant="contained"
							onClick={handleSelectLockUser}
							startIcon={<SwapHoriz />}
						>
							transfer lock
						</Button>
					</ActionButtonContainer>
				</DialogActions>
			</BtDialog>
		</>
	);
};
