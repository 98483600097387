import React, { useMemo, useRef, useState } from 'react';

import { TextField } from '@mui/material';

import { useDataConfigContext } from '../DataConfigContext';
import useGetSelectionData from '../hooks/useGetSelectionData';

export const FieldNameInput = () => {
	const cancelFlag = useRef(false);

	const { disabled, renameField } = useDataConfigContext();
	const {
		parentLocation,
		siblingKeys,
		key: defaultKey,
	} = useGetSelectionData();

	const [acceptedTitle, setAcceptedTitle] = useState(defaultKey);
	const [key, setKey] = useState(defaultKey);

	const error = useMemo(
		() => {
			if (!key.trim()) {
				return 'Enter a valid field name';
			}

			if ((siblingKeys || []).includes(key.trim())) {
				return 'Field name already taken';
			}

			return null;
		},
		[siblingKeys, key]
	);

	return (
		<TextField
			disabled={disabled}
			error={!!error}
			fullWidth
			helperText={error ?? ''}
			label="Field Name"
			onBlur={() => {
				if (error) {
					setKey(acceptedTitle);

					return;
				}

				if (key === acceptedTitle || cancelFlag.current) {
					cancelFlag.current = false;

					return;
				}

				const trimmedTitle = key.trim();

				renameField({
					parentLocation,
					oldField: acceptedTitle,
					newField: trimmedTitle,
				});

				setAcceptedTitle(trimmedTitle);
			}}
			onChange={event => setKey(event.target.value)}
			onKeyDown={event => {
				if (event.key === 'Enter' && !error) {
					event.target.blur();
				}

				if (event.key === 'Escape') {
					cancelFlag.current = true;
					setKey(acceptedTitle);

					event.target.blur();
				}
			}}
			placeholder="Enter a field name"
			style={{ marginBottom: '1em' }}
			value={key}
			variant="standard"
		/>
	);
};

export default FieldNameInput;
