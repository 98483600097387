import React from 'react';
import PropTypes from 'prop-types';
import PermissionConfirmation from './PermissionConfirmation';
import PermissionMethods from './PermissionMethods';
import PermissionResources from './PermissionResources';
import { PermWizardContextProvider } from './PermWizardContext';
import { withWizard, Wizard } from '../../../../components/wizard';

const PermissionWizard = withWizard(
	({
		addedPerms,
		ownerId,
		onClose,
		permToEdit,
		setOwner,
		getPermissionsList,
		editPermission,
		addPermission,
	}) => {
		return (
			<PermWizardContextProvider
				addedPerms={addedPerms}
				onClose={onClose}
				permToEdit={permToEdit}
			>
				<Wizard>
					<PermissionMethods
						getPermissionsList={getPermissionsList}
					/>
					<PermissionResources />
					<PermissionConfirmation
						ownerId={ownerId}
						setOwner={setOwner}
						editPermission={editPermission}
						addPermission={addPermission}
					/>
				</Wizard>
			</PermWizardContextProvider>
		);
	}
);

PermissionWizard.propTypes = {
	addedPerms: PropTypes.arrayOf(PropTypes.object),
	ownerId: PropTypes.string,
	onClose: PropTypes.func.isRequired,
	permToEdit: PropTypes.object,
	setOwner: PropTypes.func.isRequired,
};

PermissionWizard.displayName = 'PermissionWizard';

export default PermissionWizard;
