import React from 'react';

import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogActions } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CloseIcon from '@mui/icons-material/Close';
import BusinessIcon from '@mui/icons-material/Business';

import ActionButtonContainer from '../generic/ActionButtonContainer';
import BtDialog from '../generic/BtDialog';
import { useAppContext } from '../../context/ContextManager';
import BtOrgLogo from '../generic/BtOrgLogo';

export default function OrgSelectDialog({ setOpen, onClose }) {
	const { userInfo } = useAppContext();
	const handleDialogCancel = () => {
		onClose(null);
	};

	const handleOrgSelect = org_uuid => {
		onClose(org_uuid);
	};

	return (
		<BtDialog
			open={setOpen}
			onClose={handleDialogCancel}
			fullWidth
			maxWidth="xs"
		>
			<DialogTitle id="form-dialog-title">
				Select Organisation
			</DialogTitle>
			<DialogContent>
				<List>
					{userInfo.organisations.map((org, index) => (
						<ListItem
							button
							key={index}
							onClick={() => handleOrgSelect(org.uuid)}
						>
							<ListItemIcon>
								{/*<BusinessIcon />*/}
								<BtOrgLogo
									logo={org.logo32}
									initials={org.initials}
									name={org.name}
								/>
							</ListItemIcon>
							<ListItemText primary={org.name} />
						</ListItem>
					))}
				</List>
			</DialogContent>
			<DialogActions>
				<ActionButtonContainer>
					<Button
						variant="outlined"
						startIcon={<CloseIcon />}
						onClick={handleDialogCancel}
						disableElevation
					>
						Cancel
					</Button>
				</ActionButtonContainer>
			</DialogActions>
		</BtDialog>
	);
}
