import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import AddTagIcon from '@mui/icons-material/Add';
import ValueListItem from './ValueListItem';

import BtDialog from '../../../components/generic/BtDialog';
import { BtFormSection } from '../../../components/generic/forms';
import BtList from '../../../components/generic/BtList';
import TagValuesDialog from './TagValuesDialog';

export default function TagsEditorValues({ groupId, setGroup, tagValues }) {
	const [valuesDialog, setValuesDialog] = useState(false);
	const [valueToEdit, setValueToEdit] = useState(null);
	const [sendingName, setSendingName] = useState(false);

	return (
		<>
			<BtFormSection style={{ marginBottom: 0 }}>
				<Button
					variant="contained"
					onClick={() => {
						setValueToEdit(null);
						setValuesDialog(true);
					}}
					startIcon={<AddTagIcon />}
					disableElevation
				>
					Add a Value
				</Button>
				<BtList
					items={tagValues}
					primaryField="name"
					subject="Tag Values"
					renderItem={tagValue => (
						<ValueListItem
							groupId={groupId}
							onEditClick={() => {
								setValueToEdit(tagValue);
								setValuesDialog(true);
							}}
							setGroup={setGroup}
							tagValue={tagValue}
						/>
					)}
				/>
			</BtFormSection>
			<BtDialog
				open={valuesDialog}
				fullWidth
				maxWidth="xs"
				onClose={() => {
					if (!sendingName) {
						setValuesDialog(false);
					}
				}}
			>
				<TagValuesDialog
					groupId={groupId}
					onClose={() => setValuesDialog(false)}
					sending={sendingName}
					setGroup={setGroup}
					setSending={setSendingName}
					tagValues={tagValues}
					valueToEdit={valueToEdit}
				/>
			</BtDialog>
		</>
	);
}

TagsEditorValues.propTypes = {
	groupId: PropTypes.string,
	setGroup: PropTypes.func.isRequired,
	tagValues: PropTypes.arrayOf(PropTypes.object),
};
