import React from 'react';
import { BtTabs } from '../../../../../../../components/generic/tabs/BtTabs';
import { Box, DialogActions, DialogContent, styled } from '@mui/material';
import { PropertiesForm } from './PropertiesForm';
import {
	BtForm,
	BtFormActionButtons,
	BtFormActionsContainer,
	withFormContextMethods,
} from '../../../../../../../components/generic/forms';
import { visualisationSchema } from '../../../../../../../API/validations/insightCollectionsValidation';
import PropTypes from 'prop-types';
import { VisOptionsForm } from './VisOptionsForm';
import ReactJson from 'react-json-view';
import { ComponentPreview } from '../ComponentPreview';

const DialogContentBox = styled(Box)(() => ({
	height: `calc(90vh - 201px)`,
	display: 'flex',
	flexDirection: 'row',
	gap: 10,
}));

const TabContainer = styled(Box)(() => ({
	paddingTop: 20,
	overflow: 'auto',
}));

const ColumnBox = styled(Box)(() => ({
	width: '50%',
}));

const JsonEditComponent = withFormContextMethods(({ watch, reset }) => {
	const liveComponent = watch();

	const handleJsonUpdate = jsonUpdate => {
		reset(jsonUpdate.updated_src, {
			keepDefaultValues: true,
		});
	};

	return (
		<ReactJson
			src={liveComponent}
			onEdit={handleJsonUpdate}
			onAdd={handleJsonUpdate}
			onDelete={handleJsonUpdate}
			// collapsed={1}
			quotesOnKeys={false}
		/>
	);
});

export const EditComponentForm = ({ defaultValues, handleSubmit, onClose }) => {
	const allTabs = [
		{
			label: 'Properties',
			content: (
				<TabContainer>
					<PropertiesForm />
				</TabContainer>
			),
		},
		// {
		// 	label: 'Data Source',
		// 	content: <DataSourceForm dataSources={dataSources} />,
		// },
		{
			label: 'Visualisation Options',
			content: (
				<TabContainer>
					<VisOptionsForm />
				</TabContainer>
			),
		},
		{
			label: 'JSON Edit',
			content: (
				<TabContainer>
					<JsonEditComponent />
				</TabContainer>
			),
		},
	];

	return (
		<BtForm
			onSubmit={values => handleSubmit(values)}
			validationSchema={visualisationSchema}
			defaultValues={defaultValues}
		>
			<DialogContent>
				<DialogContentBox>
					<ColumnBox>
						<ComponentPreview />
					</ColumnBox>

					<ColumnBox>
						<BtTabs top tabs={allTabs} />
					</ColumnBox>
				</DialogContentBox>
			</DialogContent>
			<DialogActions>
				<BtFormActionsContainer>
					{/* <Button onClick={onClose}>cancel</Button>
					<Button type="submit">Submit</Button> */}
					<BtFormActionButtons
						showDestructivePrompt={false}
						onDestroy={onClose}
					/>
				</BtFormActionsContainer>
			</DialogActions>
		</BtForm>
	);
};

EditComponentForm.propTypes = {
	// dataSources: PropTypes.array.isRequired,
	defaultValues: PropTypes.object.isRequired,
	// palette: PropTypes.array.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
};
