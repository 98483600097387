import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const PermWizardContext = createContext();

const PermWizardContextProvider = ({
	addedPerms,
	children,
	onClose,
	permToEdit,
}) => {
	const [data, setData] = useState({});

	return (
		<PermWizardContext.Provider
			value={{
				addedPerms,
				data,
				onClose,
				permToEdit,
				setData,
			}}
		>
			{children}
		</PermWizardContext.Provider>
	);
};

const permissionShape = PropTypes.shape({
	uuid: PropTypes.string.isRequired,
	group: PropTypes.string.isRequired,
	method: PropTypes.string.isRequired,
	resources: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
});

PermWizardContextProvider.propTypes = {
	addedPerms: PropTypes.arrayOf(permissionShape).isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	onClose: PropTypes.func.isRequired,
	permissionToEdit: permissionShape,
};

export { PermWizardContext, PermWizardContextProvider };
