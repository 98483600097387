import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import {
	Button,
	Card,
	DialogActions,
	DialogContent,
	FormControl,
	FormHelperText,
	IconButton,
	styled,
	Tooltip,
	Typography,
} from '@mui/material';
import SignatureCanvas from 'react-signature-canvas';

import EditIcon from '@mui/icons-material/ModeEdit';
import GestureIcon from '@mui/icons-material/Gesture';

import ActionButtonContainer from './ActionButtonContainer';
import BtDialog from './BtDialog';
import BtImageDialog from './BtImageDialog';
import { useThemeContext } from '../../context/ThemeContext';

const CanvasContainer = styled('div')(() => ({
	backgroundColor: '#fff',
	borderRadius: 4,
	boxShadow: '0 0 0 1px #0001, 0 2px 4px 4px #0001',
	margin: '4px 0',
}));

const CanvasHeader = styled('div')(() => ({
	alignItems: 'center',
	display: 'flex',
	justifyContent: 'space-between',
	padding: '16px 24px 0',
	userSelect: 'none',
}));

const Container = styled(Card)(({ hasError, isDisabled, theme }) => ({
	borderColor: hasError ? theme.palette.indicators.error.main : 'transparent',
	borderStyle: 'solid',
	borderWidth: 1,
	height: 100,
	opacity: isDisabled ? 0.5 : 1,
	padding: '10px 0',
	position: 'relative',
	width: '100%',
}));

const Content = styled('button')(() => ({
	background: 'none',
	border: 'none',
	boxSizing: 'border-box',
	height: '100%',
	outline: 'none',
	padding: '0 50px 0 0',
	width: '100%',

	'& > span': {
		height: '100%',
		position: 'relative',
		width: '100%',
	},
}));

const EditButtonContainer = styled('div')(() => ({
	alignItems: 'center',
	height: '100%',
	display: 'flex',
	paddingRight: '0.5em',
	position: 'absolute',
	right: 0,
	top: 0,
}));

const PreviewImage = styled('img')(() => ({
	backgroundRepeat: 'no-repeat',
	display: 'block',
	height: '100%',
	objectFit: 'contain',
	width: '100%',
}));

const Canvas = ({ onChange, onClose, signature }) => {
	const canvasRef = useRef();
	const initialised = useRef();

	useEffect(
		() => {
			canvasRef.current.fromDataURL(signature, {
				width: 500,
				height: 300,
			});

			initialised.current = true;
		},
		[signature]
	);

	return (
		<>
			<CanvasHeader>
				<Typography variant="h5">Sign below</Typography>
				<Button
					disableElevation
					onClick={() => canvasRef.current.clear()}
				>
					Clear
				</Button>
			</CanvasHeader>
			<DialogContent style={{ paddingBottom: '0.5em' }}>
				<CanvasContainer>
					<SignatureCanvas
						ref={canvasRef}
						canvasProps={{
							width: 500,
							height: 300,
							className: 'sigCanvas',
						}}
					/>
				</CanvasContainer>
			</DialogContent>
			<DialogActions style={{ padding: 0 }}>
				<ActionButtonContainer>
					<Button disableElevation onClick={onClose} variant="text">
						Cancel
					</Button>
					<Button
						disableElevation
						onClick={() => {
							if (canvasRef.current.isEmpty()) {
								onChange(undefined);
							} else {
								onChange(
									canvasRef.current.getCanvas().toDataURL()
								);
							}

							onClose();
						}}
						variant="contained"
					>
						Done
					</Button>
				</ActionButtonContainer>
			</DialogActions>
		</>
	);
};

export default function BtSignature({
	defaultValue,
	disabled,
	errorMessage,
	label,
	onChange,
	value,
	...otherProps
}) {
	const { theme } = useThemeContext();

	const [showCanvas, setShowCanvas] = useState(false);
	const [showPreview, setShowPreview] = useState(false);
	const [_value, setValue] = useState(defaultValue);

	const handleChange = useCallback(
		newValue => {
			setValue(newValue);

			onChange?.(newValue);
		},
		[onChange]
	);

	const currentValue = value || _value;

	const imageFilter =
		theme.palette.mode === 'dark' ? { filter: 'invert(1)' } : {};

	return (
		<>
			<FormControl disabled={disabled} error={!!errorMessage}>
				{label && (
					<Typography
						color={errorMessage ? 'error' : 'default'}
						style={{ marginBottom: 5 }}
					>
						{label}
					</Typography>
				)}
				<Container
					hasError={+!!errorMessage}
					isDisabled={+disabled}
					{...otherProps}
				>
					<Content
						disabled={disabled}
						onClick={() => {
							if (currentValue) {
								setShowPreview(true);

								return;
							}

							setShowCanvas(true);
						}}
					>
						<span>
							<div
								style={{
									display: 'flex',
									height: '100%',
									position: 'relative',
									width: '100%',
								}}
							>
								{currentValue ? (
									<PreviewImage
										src={currentValue}
										style={{
											...imageFilter,
										}}
									/>
								) : (
									<div
										style={{
											alignItems: 'center',
											display: 'flex',
											margin: '0 1em',
										}}
									>
										<GestureIcon
											style={{ marginRight: '0.5em' }}
										/>
										<Typography>No signature</Typography>
									</div>
								)}
							</div>
						</span>
					</Content>
					<EditButtonContainer>
						<Tooltip disableInteractive title="Edit Signature">
							<IconButton
								disabled={disabled}
								onClick={event => {
									event.stopPropagation();

									setShowCanvas(true);
								}}
							>
								<EditIcon />
							</IconButton>
						</Tooltip>
					</EditButtonContainer>
				</Container>
				{errorMessage && (
					<FormHelperText style={{ margin: '0.5em 0 0' }}>
						{errorMessage}
					</FormHelperText>
				)}
			</FormControl>
			<BtDialog onClose={() => setShowCanvas(false)} open={showCanvas}>
				<Canvas
					onChange={handleChange}
					onClose={() => setShowCanvas(false)}
					signature={currentValue}
				/>
			</BtDialog>
			<BtImageDialog
				imageString={currentValue}
				imageStyle={{
					...(theme.palette.mode === 'light'
						? { backgroundColor: '#fff' }
						: {}),
					...imageFilter,
				}}
				onClose={() => setShowPreview(false)}
				open={showPreview}
			/>
		</>
	);
}

Canvas.propTypes = {
	onChange: PropTypes.func,
	onClose: PropTypes.func,
	open: PropTypes.bool,
	signature: PropTypes.string,
};

BtSignature.propTypes = {
	defaultValue: PropTypes.string,
	disabled: PropTypes.bool,
	errorMessage: PropTypes.string,
	label: PropTypes.string,
	onChange: PropTypes.func,
	required: PropTypes.bool,
	value: PropTypes.string,
};
