import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Box, Drawer } from '@mui/material';
import { v4 as generateUuid } from 'uuid';

import BtDialogHeading from '../BtDialogHeading';
import Comment from './Comment';
import CommentField from './CommentField';
import { CommentsContextProvider } from './CommentsContext';
import CommentsDisabled from './CommentsDisabled';
import { useAppContext } from '../../../context/ContextManager';

const EDITABLE = 'editable',
	HIDDEN = 'hidden',
	READ_ONLY = 'readOnly';

export default function Comments({
	availability,
	comments,
	modificationMutator,
	onAddComment,
	onClose,
	...props
}) {
	const { userInfo } = useAppContext();

	const handleAddComment = useCallback(
		commentText => {
			const { user_name: userName, uuid: userUuid } = userInfo;

			const newComment = {
				comment: commentText,
				timestamp: +new Date(),
				user: {
					userName,
					uuid: userUuid,
				},
				uuid: generateUuid(),
			};

			onAddComment({
				...newComment,
				...(modificationMutator?.(newComment) ?? {}),
			});
		},
		[onAddComment, modificationMutator, userInfo]
	);

	return (
		<Drawer anchor="right" onClose={onClose} open={!!comments}>
			<CommentsContextProvider
				availability={availability}
				comments={comments}
				modificationMutator={modificationMutator}
				{...props}
			>
				<Box
					style={{
						boxSizing: 'border-box',
						maxWidth: 400,
						padding: '64px 1em 1em 1em',
						width: '100vw',
					}}
				>
					<BtDialogHeading
						onClose={onClose}
						style={{ margin: '1em 0 0.5em 0' }}
						title="Comments"
					/>
					{comments?.map(comment => (
						<Comment key={comment.uuid} comment={comment} />
					))}
					{!!comments &&
						availability === EDITABLE && (
							<CommentField
								onSubmit={handleAddComment}
								style={{ marginBottom: '1em' }}
							/>
						)}
					{availability !== EDITABLE && <CommentsDisabled />}
				</Box>
			</CommentsContextProvider>
		</Drawer>
	);
}

Comments.propTypes = {
	availability: PropTypes.oneOf([EDITABLE, HIDDEN, READ_ONLY]),
	comments: PropTypes.arrayOf(PropTypes.object),
	isEditablePredicate: PropTypes.func,
	markAsNewPredicate: PropTypes.func,
	modificationMutator: PropTypes.func,
	newCommentMessage: PropTypes.string,
	onAddComment: PropTypes.func,
	onClose: PropTypes.func,
	onEditComment: PropTypes.func,
	onDeleteComment: PropTypes.func,
	readOnlyMessage: PropTypes.string,
	renderAdornment: PropTypes.func,
};
