import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	// useState,
} from 'react';

import PropTypes from 'prop-types';

import Alert from '@mui/material/Alert';

import { baseStagePropTypes } from './base-prop-type';
import limitStage from './BtQueryBuilderStageLimit';
import unwindStage from './BtQueryBuilderStageUnwind';
import replaceRootStage from './BtQueryBuilderStageReplaceRoot';
import matchStage from './BtQueryBuilderStageMatch';
import sortStage from './BtQueryBuilderStageSort';
import projectStage from './BtQueryBuilderStageProject';
// import skipStage from './BtQueryBuilderStageSkip';
import groupStage from './BtQueryBuilderStageGroup';
import {
	useQueryBuilderStageContext,
	useQueryBuilderVariableContext,
} from '../../context';
import { BtForm } from '../../../forms';

export const definedStages = [
	{
		key: '$group',
		description: 'Calculate aggregated values',
		...groupStage,
	},
	{
		key: '$limit',
		description: 'Reduce the number of results',
		...limitStage,
	},
	{
		key: '$match',
		description: 'Filter the results by field values',
		...matchStage,
	},
	{
		key: '$project',
		description:
			'Choose fields to hide, add calculated fields based on values',
		...projectStage,
	},
	{
		key: '$replaceRoot',
		description: 'Promote an embedded document to the top level',
		...replaceRootStage,
	},
	// skip works but the end point doesn't allow it
	// {
	// 	key: '$skip',
	// 	description:
	// 		'Ignore a number of results at the start of the collection',
	//   ...skipStage
	// },
	{
		key: '$sort',
		description: 'Define the ordering of the result',
		...sortStage,
	},

	{
		key: '$unwind',
		description:
			'Create a new result document for each element in an array and promote the document to its parent',
		...unwindStage,
	},
];

function FormContent(props) {
	const { Element, onChange, ...rest } = props;

	// const {
	// 	watch,
	// 	formState: { isValid, isValidating, errors },
	// } = useFormContext();

	// // const hasErrors = useMemo(() => Object.keys(errors || {}).length > 0, [errors])

	// // const watchAll = watch();

	// useEffect(
	// 	() => {
	// 		const sub = watch((data, next) => {
	// 			if (!next.values && isValid && !isValidating) {
	// 				console.log(data);
	// 				onChange({ isValid: true }, data);
	// 			}
	// 		});

	// 		return () => {
	// 			sub.unsubscribe();
	// 		};
	// 	},
	// 	[watch, isValid, isValidating, onChange]
	// );

	return <Element {...rest} />;
}

export const BtQueryBuilderStageComponent = props => {
	const {
		query,
		input,
		queryStr,
		type: stageType,
		id: stageId,
	} = useQueryBuilderStageContext();

	const { allowedStages, onChange, ...rest } = props;

	const allowedStage = useMemo(
		() =>
			!allowedStages || allowedStages.includes(stageType) ? true : false,
		[allowedStages, stageType]
	);

	const { variables } = useQueryBuilderVariableContext();

	const stageData = useMemo(
		() => definedStages.filter(s => s.key === stageType)?.[0],
		[stageType]
	);

	const flatConfigSchema = useMemo(
		() => {
			return input?.flatConfigSchema || {};
		},
		[input]
	);

	const activeStageId = useRef(stageId);

	const dv = useRef(
		stageData?.buildFormValues?.(query, flatConfigSchema, variables) || {}
	);

	// const [defaultValues, setDefaultValues] = useState(
	// 	stageData?.buildFormValues?.(query, flatConfigSchema, variables) || {}
	// );

	// if (dv.current !== defaultValues) {
	// 	console.log('dv !== defaultValues', dv.current);
	// }
	useEffect(
		() => {
			if (stageData && activeStageId.current !== stageId) {
				// setDefaultValues(
				dv.current =
					stageData.buildFormValues?.(
						query,
						flatConfigSchema,
						variables
					) || {};
				// );
				activeStageId.current = stageId;
			}
		},
		[flatConfigSchema, query, stageId, stageData, variables]
	);

	const lastChangeValue = useRef(queryStr);

	const onFormStateChange = useCallback(
		(formState, formValues) => {
			if (
				formState.isValid &&
				!formState.isValidating &&
				Object.keys(formState?.errors || {}).length === 0
			) {
				const query = stageData?.buildQueryStage?.(
					formValues,
					variables
				);
				const str = JSON.stringify(query);
				if (lastChangeValue.current !== str) {
					lastChangeValue.current = str;
					onChange(query);
				}
			}
		},
		[onChange, stageData, variables]
	);

	if (!allowedStage) {
		return (
			<Alert severity="error">
				You cannot use {`${stageType}`} stages in this query.
			</Alert>
		);
	}

	if (!stageData) {
		return (
			<Alert severity="info">
				{`${stageType}`} stages can only be created using the plain
				query text for now.
			</Alert>
		);
	}

	return (
		<BtForm
			validationSchema={stageData.formSchema}
			onFormStateChange={onFormStateChange}
			defaultValues={dv.current} //defaultValues}
		>
			<FormContent
				Element={stageData.FormContent || null}
				// onChange={onFormStateChange}
				{...rest}
				configSchema={input?.configSchema}
			/>
		</BtForm>
	);
};

BtQueryBuilderStageComponent.propTypes = {
	...baseStagePropTypes,
	stageType: PropTypes.string, //PropTypes.oneOf(definedStages),
	allowedStages: PropTypes.arrayOf(PropTypes.string),
	onChange: PropTypes.func.isRequired,
};

FormContent.propTypes = {
	Element: PropTypes.func,
	...baseStagePropTypes,
};
