import React, { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useAppContext } from '../../../../../context/ContextManager';
import { ScreenConfigDialog } from '../screensConfig/ScreenConfigDialog';
import { RenameScreenDialog } from '../../../../../views/Admin/screens/RenameScreenDialog';
import { ScreensContext } from '../../ScreensContext';
import { ScreenControls } from './ScreenControls';
import { EditControls } from './EditControls';
import BtDialog from '../../../BtDialog';

export const Controls = ({
	setShowAddMenu,
	showAddMenu,
	onDragStart,
	style,
	onScreenChange,
	onNativeChange,
	currentScreenData,
}) => {
	const [controlsVisible, setControlsVisible] = useState(false);
	const { deviceType } = useAppContext();
	const [showAddScreenDialog, setShowAddScreenDialog] = useState(false);

	const [showRenameScreenDialog, setShowRenameScreenDialog] = useState(false);

	const handleClickAdd = useCallback(
		() => {
			setShowAddMenu(previousState => !previousState);
		},
		[setShowAddMenu]
	);
	const {
		editEnabled,
		setEditEnabled,
		adminMode,
		screens,
		currentScreen,
		nativeEditMode,
		setNativeEditMode,
		userPermissions,
		// columns,
		// cellSize,
	} = useContext(ScreensContext);

	const handleClickEdit = useCallback(
		() => {
			if (editEnabled === true) {
				setShowAddMenu(false);
			}
			if (nativeEditMode && showAddMenu) {
				setNativeEditMode(false);
			}
			setEditEnabled(previousState => !previousState);
		},
		[
			editEnabled,
			nativeEditMode,
			setEditEnabled,
			setNativeEditMode,
			setShowAddMenu,
			showAddMenu,
		]
	);

	// displays the edit buttons on mouse over
	const handleMouseEnter = useCallback(() => {
		setControlsVisible(true);
	}, []);
	const handleMouseLeave = useCallback(() => {
		setControlsVisible(false);
	}, []);

	// apply permissions to the edit controls
	const showEditControls = useMemo(
		() => {
			switch (adminMode) {
				case true:
					return userPermissions.ScreenAdminEdit;
				case false:
					if (
						screens[currentScreen]?.ownerType === 'Organisation' &&
						!userPermissions.ScreenGroupEdit &&
						!userPermissions.ScreenCreate &&
						!userPermissions.ScreenEdit
					) {
						return false;
					} else if (
						userPermissions.ScreenEdit ||
						userPermissions.ScreenGroupEdit ||
						(!userPermissions.ScreenGroupEdit &&
							!userPermissions.ScreenCreate)
					) {
						return true;
					} else return false;

				default:
					break;
			}
		},
		[adminMode, currentScreen, screens, userPermissions]
	);

	const handleClose = useCallback((event, reason) => {
		if (reason !== 'backdropClick') {
			setShowAddScreenDialog(false);
		}
	}, []);

	return (
		<>
			<div
				style={{
					width: 'auto',
					display: 'flex',
					flexDirection: 'column',
					position: 'fixed',
					bottom: deviceType === 'desktop' ? 50 : 20,
					right: deviceType === 'desktop' ? 50 : 20,
					...style,
				}}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				{/* <pre>{JSON.stringify({ cellSize: cellSize }, null, 4)}</pre>
				<pre>{JSON.stringify({ columns: columns }, null, 4)}</pre> */}

				{showEditControls &&
					(controlsVisible || deviceType !== 'desktop') && (
						<EditControls
							handleClickEdit={handleClickEdit}
							showAddMenu={showAddMenu}
							handleClickAdd={handleClickAdd}
							onDragStart={onDragStart}
							onNativeChange={onNativeChange}
							onScreenChange={onScreenChange}
							setShowAddScreenDialog={setShowAddScreenDialog}
							setShowRenameScreenDialog={
								setShowRenameScreenDialog
							}
						/>
					)}

				<ScreenControls />
			</div>
			{screens && (
				<>
					{/* <ScreenConfigDialog
						open={showAddScreenDialog}
						onClose={(event, reason) => {
							if (reason !== 'backdropClick') {
								setShowAddScreenDialog(false);
							}
						}}
						onScreenChange={onScreenChange}
                    /> */}
					<BtDialog
						open={showAddScreenDialog}
						onClose={handleClose}
						// fullWidth={!userScreenListError || !orgScreenListError}
						maxWidth="sm"
					>
						<ScreenConfigDialog
							// open={showAddScreenDialog}
							// onClose={() => setShowAddScreenDialog(false)}
							// onClose={(event, reason) => {
							// 	if (reason !== 'backdropClick') {
							// 		setShowAddScreenDialog(false);
							// 	}
							// }}
							onClose={handleClose}
							onScreenChange={onScreenChange}
						/>
					</BtDialog>
					<RenameScreenDialog
						open={showRenameScreenDialog}
						onClose={(event, reason) => {
							if (reason !== 'backdropClick') {
								setShowRenameScreenDialog(false);
							}
						}}
						onScreenChange={onScreenChange}
						screen={screens[currentScreen]}
					/>
				</>
			)}
		</>
	);
};

Controls.propTypes = {
	setShowAddMenu: PropTypes.func,
	showAddMenu: PropTypes.bool,
	onDragStart: PropTypes.func,
	style: PropTypes.object,
	onScreenChange: PropTypes.func.isRequired,
	screenList: PropTypes.array,
};

EditControls.propTypes = {
	handleClickEdit: PropTypes.func.isRequired,
	showAddMenu: PropTypes.bool,
	handleClickAdd: PropTypes.func.isRequired,
	onDragStart: PropTypes.func.isRequired,
};
