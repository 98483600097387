import { Button } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useScreensConfigContext } from '../../ScreenConfigContext';
import { TilesAutocomplete } from '../../../formComponents/TilesAutocomplete';
import BtConfirmDialog from '../../../../../BtConfirmDialog';

import PropTypes from 'prop-types';
import BtMessage from '../../../../../BtMessage';
import { ScreensContext } from '../../../../ScreensContext';
import BtRedactor from '../../../../../../BtRedactor';

const initScreenValue = (editScreenParams, options) => {
	return editScreenParams.screenUuid === null
		? options[0]
		: options.find(option => option.uuid === editScreenParams.screenUuid);
};

export default function SelectScreen({ onScreenChange }) {
	const [error, setError] = useState(false);
	const [dialogOpen, setDialogOpen] = useState(false);
	const {
		editScreenParams,
		setEditScreenParams,
		setEditNextStepDisabled,
		editOptions,
	} = useScreensConfigContext();
	const { userPermissions } = useContext(ScreensContext);
	// value of the autocomplete, initialized with previous selection or first user-managed option
	const [value, setValue] = useState(
		initScreenValue(editScreenParams, editOptions)
	);
	// console.log({ screenToEditOptions });
	// watch the autocomplete value, and -
	// - update the context parameters
	// - manage next button state
	useEffect(
		() => {
			setError(value === null);
			if (value) {
				console.log(value);
				let newParams = structuredClone(editScreenParams);
				newParams.screenUuid = value.uuid;
				newParams.newScreenName = value.label;
				newParams.managedBy = value.managedBy;
				newParams.ownerType = value.ownerType;
				newParams.existingPosition = value.existingPosition;
				// newParams.backgroundColor = value.backgroundColor;
				newParams.existingBackgroundColor = value.backgroundColor;
				newParams.existingScreenName = value.label;
				setEditScreenParams(newParams);
				setEditNextStepDisabled(value.ownerType === 'Organisation');
			}
			if (value === null) {
				setEditNextStepDisabled(true);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[value]
	);

	const handleRemoveScreen = () => {
		onScreenChange({
			screenUuid: editScreenParams.screenUuid,
			mode: 'remove',
		});
		console.log('delete');
	};

	const AssignedMessage = () => {
		return (
			<BtMessage
				style={{ marginTop: '1em', marginBottom: '1em' }}
				message="Options for organisation screens are managed by the
                organisation. As this screen has been assigned to you it cannot be removed."
			/>
		);
	};

	const AvailableMessage = () => {
		return (
			<BtMessage
				style={{ marginTop: '1em', marginBottom: '1em' }}
				message="Options for organisation screens are managed by the
                    organisation. You may remove the screen if you like."
			/>
		);
	};

	return (
		<div
			style={{
				flexDirection: 'column',
				display: 'flex',
				// height: 230,
				justifyContent: 'flex-end',
				paddingBottom: 25,
				alignItems: 'center',
			}}
		>
			{value?.managedBy === 'Organisation' && <AssignedMessage />}

			{value?.ownerType === 'Organisation' &&
				value?.managedBy === 'User' && <AvailableMessage />}

			<div
				style={{
					height: 70,
					// marginTop: 5,
				}}
			>
				<TilesAutocomplete
					// optionDisableEnabled={true}
					onChange={setValue}
					options={editOptions}
					value={value}
					label="Select a screen to edit"
					error={error}
					errorMessage="Screen to edit is required"
				/>
			</div>
			<BtRedactor
				requiredPermissionsAll={{ screens: ['ScreenGroupEdit'] }}
			>
				<Button
					variant="outlined"
					color="error"
					disableElevation
					onClick={() => setDialogOpen(true)}
					disabled={
						error ||
						value?.managedBy === 'Organisation' ||
						!userPermissions.ScreenGroupEdit
					}
				>
					remove screen
				</Button>
			</BtRedactor>

			<BtConfirmDialog
				action={handleRemoveScreen}
				isDestructive
				onClose={() => setDialogOpen(false)}
				open={dialogOpen}
				prompt="Are you sure you want to remove this screen?"
				title="Remove screen"
				verb="Remove"
			/>
		</div>
	);
}

SelectScreen.propTypes = {
	onScreenChange: PropTypes.func.isRequired,
};
