import React, { useEffect, useState, useMemo, useCallback } from 'react';

import admin_nav_item from '../AdminNavItem';
import admin_options from '../AdminOptions';
import BtError from '../../../components/generic/BtError';
import {
	BtForm,
	BtFormActionButtons,
	BtFormChangesBlocker,
	BtFormContainer,
} from '../../../components/generic/forms';
import { orgDetailsGet, orgDetailsPut } from '../../../API/orgDetails';
import SectionBasicInfo from './SectionBasicInfo';
import SectionContactInfo from './SectionContactInfo';
import SectionRegionalSettings from './SectionRegionalSettings';
import useFetch from '../../../hooks/useFetch';
import { useNavContext, useAppContext } from '../../../context/ContextManager';

import { useSnackbar } from 'notistack';
import { validationSchema } from './validationSchema';

export default function GeneralInfo() {
	const { enqueueSnackbar } = useSnackbar();
	const { setActivityIndicator } = useAppContext();

	const [sending, setSending] = useState(false);

	// Set contextual navigation items and breadcrumbs
	const { setContextualNav, setBreadcrumbs } = useNavContext();
	useEffect(
		() => {
			setContextualNav([...admin_nav_item, ...admin_options]);
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'Admin', link: '/Admin' },
				{ text: 'General Info', link: '' },
			]);
			return () => setContextualNav(null);
		},
		[setContextualNav, setBreadcrumbs]
	);

	const { loading, data: storedInfo, error, request: getInfo } = useFetch(
		orgDetailsGet
	);

	useEffect(
		() => {
			getInfo();
		},
		[getInfo]
	);

	const defaultValues = useMemo(
		() => {
			if (!storedInfo) return null;
			return {
				...(storedInfo.generalInfo && storedInfo['generalInfo']),
				...(storedInfo.contactInfo && storedInfo['contactInfo']),
				...(storedInfo.regionalSettings &&
					storedInfo['regionalSettings']),
			};
		},
		[storedInfo]
	);

	const onSubmit = useCallback(
		async (values, reset) => {
			const {
				displayName,
				legalTradingName,
				organisationType,
				regNo,
				isVatRegistered,
				vatRegNo,
				contactName,
				contactEmail,
				telephone,
				address,
				townCity,
				regionState,
				postalZipCode,
				currency,
				dateFormat,
				firstWeekDay,
				numberFormat,
			} = values;
			try {
				setSending(true);
				setActivityIndicator(true);
				await orgDetailsPut({
					orgDetails: {
						generalInfo: {
							displayName,
							legalTradingName,
							organisationType,
							regNo,
							isVatRegistered,
							vatRegNo,
						},
						contactInfo: {
							contactName,
							contactEmail,
							telephone,
							address,
							townCity,
							regionState,
							postalZipCode,
						},
						regionalSettings: {
							currency,
							dateFormat,
							firstWeekDay,
							numberFormat,
						},
					},
				});
				reset(values);
				enqueueSnackbar('Successfully saved', {
					variant: 'success',
				});
			} catch (error) {
				enqueueSnackbar('Failed to save', {
					variant: 'error',
				});
				console.error(error);
			} finally {
				setSending(false);
				setActivityIndicator(false);
			}
		},
		[enqueueSnackbar, setActivityIndicator]
	);

	if (error) {
		return (
			<BtError
				action={getInfo}
				title="Failed To Load"
				description="Sorry, an error occurred when trying to load the General Info page."
				variant="error"
			/>
		);
	}

	return (
		<BtForm
			defaultValues={defaultValues} // Adding empty array for organisationLogo for now until image handling is figured out
			validationSchema={validationSchema}
			loading={loading}
			sending={sending}
			onSubmit={onSubmit}
		>
			<BtFormContainer
				title="General Info"
				ActionButtons={
					<BtFormActionButtons destructiveOutlined={false} />
				}
				style={{ maxWidth: 720 }}
			>
				<SectionBasicInfo />
				<SectionContactInfo />
				<SectionRegionalSettings />
			</BtFormContainer>
			<BtFormChangesBlocker />
		</BtForm>
	);
}
