import { v4 } from 'uuid';
import {
	ACTIVE,
	// COLLECTION,
	// ORGANISATION,
	PLATFORM,
	contrastSafeBaseColours,
} from './constants';
import Color from 'color';
import { darken, hslToRgb, lighten, rgbToHex } from '@mui/material';
import { FAIL, evalContrastRatios } from '../../../utils/colourUtils';

/**
 * @returns options in shape to suit the filterSelectComponent
 */
const mapPaletteSelectOptions = palettes => {
	const result = palettes.map(({ uuid, name, palette, type, status }) => {
		const theme = {};
		theme.id = uuid;
		theme.title = name;
		theme.type = type;
		theme.palette = palette;
		theme.status = status;
		return theme;
	});
	return result;
};

/**
 * transforms the option from filter select shape to original shape
 * @returns the option in original palette shape
 */
const unMapSelectedOption = option => {
	const result = {};
	result.uuid = option.id;
	result.name = option.title;
	result.palette = option.palette;
	result.type = option.type;
	result.status = option.status;
	return result;
};

/**
 * @returns palettes array with values updated as per update passed
 */
const processPalettesUpdate = (update, paletteIndex, palettes) => {
	const paletteToUpdate = { ...palettes[paletteIndex] };
	Object.keys(update).forEach(item => {
		paletteToUpdate[item] = update[item];
	});

	const palettesUpdate = [...palettes];
	palettesUpdate.splice(paletteIndex, 1, paletteToUpdate);
	return palettesUpdate;
};

const buildNewPalette = partialProperties => {
	const newPalette = {};
	newPalette.name = partialProperties.name || '';
	newPalette.uuid = partialProperties.uuid || v4();
	newPalette.type = partialProperties.type || PLATFORM;
	newPalette.palette = partialProperties.palette || [];
	newPalette.status = partialProperties.status || ACTIVE;

	return newPalette;
};

const getRandomBaseColour = () => {
	return contrastSafeBaseColours[
		Math.floor(Math.random() * contrastSafeBaseColours.length)
	];
};

/**
 *
 * @param {*} colours array of strings(hex colour ref)
 * @returns - palette object
 *
 */
const buildPaletteArray = colours => {
	const paletteArray = colours.map((colour, index) => {
		const colourObj = {};
		colourObj.hex = colour;
		colourObj.uuid = v4();
		colourObj.name = `Colour ${index + 1}`;

		return colourObj;
	});

	return paletteArray;
};

/**
 * Checks the contrast of the colour and adjusts if necessary
 * @returns the colour in hex format
 */
const adjustColourContrast = colour => {
	// check contrast of the colour
	const contrast = evalContrastRatios(colour);

	// function to lighten the colour to meet contrast requirement
	const lightenColour = colour => {
		// makes small adjustment to colour
		const incrementColour = colourToIncrement => {
			const lightenedRGB = lighten(colourToIncrement, 0.05);
			return rgbToHex(lightenedRGB);
		};

		// working value
		let lightenedColour = incrementColour(colour);

		// keep adjusting the colour until it passes
		while (evalContrastRatios(lightenedColour).dark === FAIL) {
			lightenedColour = incrementColour(lightenedColour);
		}
		return lightenedColour;
	};

	// the same as above but for darkening..
	const darkenColour = colour => {
		const incrementColour = colourToIncrement => {
			const darkenedRGB = darken(colourToIncrement, 0.05);
			return rgbToHex(darkenedRGB);
		};

		let darkenedColour = incrementColour(colour);

		while (evalContrastRatios(darkenedColour).light === FAIL) {
			darkenedColour = incrementColour(darkenedColour);
		}
		return darkenedColour;
	};

	let result;

	// check the contrast and make adjustments if required
	if (contrast.dark === FAIL) {
		result = lightenColour(colour);
	} else if (contrast.light === FAIL) {
		result = darkenColour(colour);
	} else {
		result = colour;
	}

	return result;
};

/**
 *
 * @param {*} baseColor base colour, optional, if not provided a colour is provided internally
 * @param {*} numberOfColours optional, defaults to 4
 * @returns a palette array
 */
const generateRandomPalette = (baseColor, numberOfColours) => {
	const count = numberOfColours ? numberOfColours : 4;

	const base = baseColor ? Color(baseColor) : Color(getRandomBaseColour());

	const offset = 360 / count;

	const baseHue = base.hue();

	const colours = Array.from({ length: count }, (v, i) => i).map(i => {
		const hsl = base.hue(baseHue + offset * i).toString();
		const rgb = hslToRgb(hsl);
		const hex = rgbToHex(rgb);
		if (i === 0) {
			return hex;
		} else {
			return adjustColourContrast(hex);
		}
	});

	const palette = buildPaletteArray(colours);

	return palette;
};

export {
	adjustColourContrast,
	generateRandomPalette,
	getRandomBaseColour,
	buildNewPalette,
	processPalettesUpdate,
	unMapSelectedOption,
	mapPaletteSelectOptions,
	buildPaletteArray,
};
