import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	styled,
	Tooltip,
	Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { v4 as generateUuid } from 'uuid';

import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import SaveIcon from '@mui/icons-material/SaveOutlined';

import BtBadge from '../../../../../components/generic/BtBadge';
import BtConfirmDialog from '../../../../../components/generic/BtConfirmDialog';
import {
	BtForm,
	BtFormContainer,
} from '../../../../../components/generic/forms';
import BtPopover from '../../../../../components/generic/BtPopover';
import usePageParser from '../../processing/hooks/usePageParser';
import { useWorkflowSessionContext } from '../../contexts/WorkflowSessionContext';
import WorkflowAction from './WorkflowAction';
import WorkflowComponentsParser from './WorkflowComponentsParser';
import WorkflowHeaderActionButton from './WorkflowHeaderActionButton';

const Header = styled('div')(() => ({
	display: 'flex',
	justifyContent: 'space-between',
}));

const uuid = generateUuid();

export default function WorkflowPage() {
	const formRef = useRef();

	const {
		commentAvailability,
		currentPage,
		onFormStateChange,
		saveChanges,
		setCanLeave,
		setShowWorkflowComments,
		workflowComments,
	} = useWorkflowSessionContext();

	const {
		answers,
		components,
		defaultValues,
		validationSchema,
	} = usePageParser(currentPage);

	const history = useHistory();

	const [leaveMenuAnchor, setLeaveMenuAnchor] = useState();
	const [showDiscardDialog, setShowDiscardDialog] = useState(false);

	// Merge default answers with previous answers
	const finalDefaults = useMemo(() => ({ ...defaultValues, ...answers }), [
		answers,
		defaultValues,
	]);

	useEffect(
		() => {
			const { dirtyFields } = currentPage;

			if (dirtyFields) {
				formRef.current.trigger(dirtyFields || []);
			}
		},
		[currentPage]
	);

	return (
		<>
			<BtForm
				ref={formRef}
				defaultValues={finalDefaults}
				onFormStateChange={onFormStateChange}
				validationSchema={validationSchema}
			>
				<BtFormContainer
					maxWidth="sm"
					header={
						<Header>
							<div>
								<Typography variant="h4">
									{currentPage?.name || 'Unknown Page'}
								</Typography>
								<Typography variant="h5">
									{currentPage?.description}
								</Typography>
							</div>
							<div>
								{commentAvailability.workflow !== 'hidden' && (
									<Tooltip
										disableInteractive
										title="Workflow Comments"
									>
										<WorkflowHeaderActionButton
											focusRipple
											onClick={() =>
												setShowWorkflowComments(true)
											}
										>
											<ModeCommentIcon />
											<BtBadge
												count={workflowComments?.length}
												style={{ top: -5, right: -5 }}
											/>
										</WorkflowHeaderActionButton>
									</Tooltip>
								)}
								<Tooltip disableInteractive title="Leave">
									<WorkflowHeaderActionButton
										focusRipple
										onClick={event =>
											setLeaveMenuAnchor(
												event.currentTarget
											)
										}
									>
										<LogoutIcon />
									</WorkflowHeaderActionButton>
								</Tooltip>
							</div>
						</Header>
					}
				>
					<WorkflowComponentsParser
						components={components}
						Parser={WorkflowComponentsParser}
					/>
					<WorkflowAction key={uuid} />
				</BtFormContainer>
			</BtForm>
			<BtPopover
				anchorEl={leaveMenuAnchor}
				onClose={() => setLeaveMenuAnchor(null)}
				anchorOrigin={{
					horizontal: 'left',
					vertical: 'bottom',
				}}
				transformOrigin={{
					horizontal: 'left',
					vertical: 'top',
				}}
			>
				<List dense>
					<ListItemButton onClick={saveChanges}>
						<ListItemIcon style={{ minWidth: 32 }}>
							<SaveIcon />
						</ListItemIcon>
						<ListItemText primary="Save Changes" />
					</ListItemButton>
					<ListItemButton
						onClick={() => {
							setLeaveMenuAnchor(null);
							setShowDiscardDialog(true);
							setCanLeave(true);
						}}
					>
						<ListItemIcon style={{ minWidth: 32 }}>
							<CloseIcon />
						</ListItemIcon>
						<ListItemText primary="Discard Changes" />
					</ListItemButton>
				</List>
			</BtPopover>
			<BtConfirmDialog
				action={() => history.push('/Workflows/Submissions')}
				ActionIcon={<CloseIcon />}
				isDestructive
				open={showDiscardDialog}
				onClose={() => {
					setShowDiscardDialog(false);
					setCanLeave(false);
				}}
				prompt="Are you sure you want to discard your changes? This action cannot be undone."
				title="Discard Changes"
				verb="Discard"
			/>
		</>
	);
}
