import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { userGetById } from '../../API';
import useFetch from '../../hooks/useFetch';
import useTimeFormat from '../../hooks/useTimeFormat';
import { CircularProgress, Typography } from '@mui/material';

const CONTRIBUTE = 'contribute',
	CREATE = 'create',
	MODIFY = 'modify';

export default function BtContributorLabel({
	date,
	LabelComponent,
	labelProps,
	LoadingComponent,
	loadingProps,
	omitOpening,
	userUuid,
	variant,
	...props
}) {
	const { request: getUser, data: user, error, loading } = useFetch(
		userGetById
	);

	const dateTime = useTimeFormat(date, true);

	useEffect(
		() => {
			if (userUuid) {
				getUser({ userUuid });
			}
		},
		[getUser, userUuid]
	);

	const userName = useMemo(
		() => {
			if (error) {
				return 'Unknown ';
			} else if (user?.user_name) {
				return `${user?.user_name} `;
			}

			return '';
		},
		[error, user]
	);

	const dateStr = useMemo(
		() => {
			let str = '';

			if (userName) {
				str = 'at ';
			}

			return (str += dateTime);
		},
		[dateTime, userName]
	);

	const label = useMemo(
		() => {
			let opening = '';

			if (!omitOpening) {
				switch (variant) {
					case CREATE:
						opening = 'Created ';
						break;

					case MODIFY:
						opening = 'Modified ';
						break;

					default:
						opening = 'Contributed to ';
						break;
				}

				if (userName) {
					opening += 'by ';
				}
			}

			return opening + userName + dateStr;
		},
		[dateStr, omitOpening, userName, variant]
	);

	const Label = LabelComponent ?? Typography;

	return (
		<div {...props}>
			{loading &&
				(!!LoadingComponent ? (
					<LoadingComponent {...loadingProps} />
				) : (
					<CircularProgress size={18} {...loadingProps} />
				))}
			{!loading && <Label {...labelProps}>{label}</Label>}
		</div>
	);
}

BtContributorLabel.propTypes = {
	date: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.object,
		PropTypes.string,
	]),
	LabelComponent: PropTypes.elementType,
	labelProps: PropTypes.object,
	LoadingComponent: PropTypes.elementType,
	loadingProps: PropTypes.object,
	omitOpening: PropTypes.bool,
	userUuid: PropTypes.string,
	variant: PropTypes.oneOf([CONTRIBUTE, CREATE, MODIFY]),
};
