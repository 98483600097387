import { apiClient } from './api_client';
import { uuidRegex } from './utils';

import {
	screenGetListSchema,
	screenGetSchema,
	screenGroupGetSchema,
	screenPostSchema,
	screenPutSchema,
	screenGroupPutSchema,
} from './validations/screenValidation';

//========================================================================================================
async function screenListGet(props) {
	var req_url = `screen`;
	try {
		if (props?.ownerFilter) {
			if (props.ownerFilter === 'Organisation') {
				req_url = `screen?owner=Organisation`;
			} else if (props.ownerFilter === 'User') {
				req_url = `screen?owner=User`;
			}
		}

		const response = await apiClient({ method: 'get', url: req_url });
		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				return await screenGetListSchema.validate(response.screens);
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function screenGet({ screenUuid }) {
	if (!uuidRegex.test(screenUuid)) {
		throw new Error('Param Error');
	}
	// console.log({ screenUuid });
	var req_url = `screen/${screenUuid}`;
	try {
		const response = await apiClient({ method: 'get', url: req_url });
		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				return await screenGetSchema.validate(response.screen);
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function screenCreate({ newScreen }) {
	// Validate the new submission data
	try {
		await screenPostSchema.validate(newScreen);
	} catch (error) {
		throw error;
	}
	console.log({ newScreen });
	var req_url = `screen`;
	try {
		const response = await apiClient({
			method: 'post',
			url: req_url,
			body: newScreen,
		});
		if (response) {
			if (response.result !== 'OK') {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function screenUpdate({ screenUuid, screenUpdate }) {
	if (!uuidRegex.test(screenUuid)) {
		throw new Error('Param Error');
	}
	try {
		await screenPutSchema.validate(screenUpdate);
	} catch (error) {
		throw error;
	}
	console.log({ screenUuid, screenUpdate });
	var req_url = `screen/${screenUuid}`;
	try {
		const response = await apiClient({
			method: 'put',
			url: req_url,
			body: screenUpdate,
		});
		if (response) {
			if (response.result !== 'OK') {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function screenGroupGet({ screenGroup }) {
	const req_url = `screen/group/${screenGroup}`;
	try {
		const response = await apiClient({ method: 'get', url: req_url });

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				return await screenGroupGetSchema.validate(response.screens);
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function screenGroupUpdate({ screenGroup, screenGroupUpdate }) {
	console.log({ screenGroup, screenGroupUpdate });
	try {
		await screenGroupPutSchema.validate(screenGroupUpdate);
	} catch (error) {
		throw error;
	}
	var req_url = `screen/group/${screenGroup}`;
	try {
		const response = await apiClient({
			method: 'put',
			url: req_url,
			body: screenGroupUpdate,
		});
		if (response) {
			if (response.result !== 'OK') {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

export {
	screenListGet,
	screenGet,
	screenGroupGet,
	screenCreate,
	screenUpdate,
	screenGroupUpdate,
};
