import React, { useMemo } from 'react';
import { Switch } from 'react-router-dom';

import CollectionEdit from './CollectionEdit';
// import InsightCollectionEditPages from './PageEdit';
// import InsightCollectionEditDataSources from './DataSources';
// import InsightCollectionEditTile from './EditTile';
import BtAuthRoute from '../../../../components/generic/BtAuthRoute';
import { InsightCollectionEditContextProvider } from '../../insightCollectionEditContext/InsightCollectionEditContext';
import { INSIGHT_COLLECTION } from '../../../../components/generic/recordManagement/utils/constants';
import { recordManagerRequiredPermissionsAll } from '../../../../components/generic/recordManagement/utils/lookupTables/permissions';

export default function InsightCollectionEditRoute({ match }) {
	const insightId = useMemo(() => match.params.id, [match]);
	const module = INSIGHT_COLLECTION;
	const requiredPermissionsAll = recordManagerRequiredPermissionsAll[module];

	return (
		<Switch>
			<InsightCollectionEditContextProvider
				insightCollectionUuid={insightId}
			>
				<BtAuthRoute
					exact
					path="/InsightCollection/:id/Edit/Collection"
					component={CollectionEdit}
					requiredPermissionsAll={requiredPermissionsAll}
					validSubscriptionsAny={['Insights Editor']}
				/>
				{/* <BtAuthRoute
					exact
					path="/InsightCollection/:id/Edit/DataSources"
					component={InsightCollectionEditDataSources}
					requiredPermissionsAll={{ insights: ['InsightsEdit'] }}
					validSubscriptionsAny={['Insights Editor']}
				/>
				<BtAuthRoute
					exact
					path="/InsightCollection/:id/Edit/Tile"
					component={InsightCollectionEditTile}
					requiredPermissionsAll={{ insights: ['InsightsEdit'] }}
					validSubscriptionsAny={['Insights Editor']}
				/>
				<BtAuthRoute
					exact
					path="/InsightCollection/:id/Edit/Pages"
					component={InsightCollectionEditPages}
					requiredPermissionsAll={{ insights: ['InsightsEdit'] }}
					validSubscriptionsAny={['Insights Editor']}
				/> */}
			</InsightCollectionEditContextProvider>
		</Switch>
	);
}
