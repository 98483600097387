const yup = require('yup');

const tagsGetListSchema = yup.array().of(
	yup.object({
		uuid: yup
			.string()
			.uuid()
			.required(),
		name: yup.string().required(),
		description: yup.string().required(),
		status: yup.string().required(),
		managedBy: yup.string().required(),
		valueLimit: yup.boolean().required(),
		displayColour: yup.string().required(),
		create_by: yup.string().required(),
		create_timestamp: yup.number().required(),
		modify_by: yup.string().required(),
		modify_timestamp: yup.number().required(),
	})
);

const tagGroupSearchSchema = yup.array().of(
	yup.object({
		groupUuid: yup
			.string()
			.uuid()
			.required(),
		groupName: yup.string().required(),
		groupStatus: yup.string().required(),
		valueUuid: yup
			.string()
			.uuid()
			.required(),
		valueName: yup.string().required(),
		valueStatus: yup.string().required(),
	})
);

const tagsPostSchema = yup.object({
	name: yup.string().required(),
	description: yup.string().required(),
	valueLimit: yup.boolean().required(),
	displayColour: yup.string().required(),
});

const tagGroupGetSchema = yup.object({
	uuid: yup
		.string()
		.uuid()
		.required(),
	name: yup.string().default(''),
	description: yup.string().default(''),
	status: yup.string().required(),
	managedBy: yup.string().required(),
	valueLimit: yup.boolean().required(),
	displayColour: yup.string().required(),
	create_by: yup.string().required(),
	create_timestamp: yup.number().required(),
	modify_by: yup.string().required(),
	modify_timestamp: yup.number().required(),
	values: yup
		.array()
		.of(
			yup.object({
				uuid: yup
					.string()
					.uuid()
					.required(),
				name: yup.string().required(),
				status: yup.string().required(),
			})
		)
		.required(),
});

// The following are optional so as we can accept a reduced list or params and only update them
const tagsPutSchema = yup.object({
	name: yup.string().optional(),
	description: yup.string().optional(),
	valueLimit: yup.boolean().optional(),
	displayColour: yup.string().optional(),
	status: yup.string().optional(),
});

const tagValuePostSchema = yup.object({
	name: yup.string().required(),
});

// The following are optional so as we can accept a reduced list or params and only update them
const tagValuePutSchema = yup.object({
	name: yup.string().optional(),
	status: yup.string().optional(),
});

module.exports = {
	tagsGetListSchema,
	tagGroupSearchSchema,
	tagsPostSchema,
	tagGroupGetSchema,
	tagsPutSchema,
	tagValuePostSchema,
	tagValuePutSchema,
};
