import React from 'react';
import { Typography } from '@mui/material';
import { timePickerUnits } from '../../utils/TimeControl/TimeRangeUtilConstants';
import { BtTimePickerButton } from './BtTimePickerButton';
import { styled } from '@mui/styles';
import { nearestValue } from '../../utils/TimeControl/TimeRangeUtilFunctions';
import useBreakpointValue from '../../hooks/useBreakpointValue';
import PropTypes from 'prop-types';

const ScrollColumn = styled('div')(({ style, theme }) => ({
	width: 'auto',
	height: '260px',
	overflow: 'scroll',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	backgroundColor:
		theme.palette.mode === 'dark'
			? '#212121'
			: theme.palette.background.default,

	'&::-webkit-scrollbar': {
		display: 'none',
	},
	'-ms-overflow-style': 'none' /* IE and Edge */,
	'scrollbar-width': 'none' /* Firefox */,
}));

const TitleSubContainer = styled('div')(
	({ style, is_separator, is_pad_left, size }) => ({
		alignItems: 'center',
		display: 'inline-flex',
		justifyContent: 'center',
		width: is_separator ? '6px' : size === 'xs' ? '20px' : '36px',
		paddingLeft: is_pad_left ? '2px' : '0px',
		height: '30px',
	})
);

const TitleContainer = styled('div')(({ style, theme }) => ({
	display: 'flex',
	// width: '84px',
	height: '30px',
	marginBottom: '8px',
	marginTop: '16px',
	backgroundColor:
		theme.palette.mode === 'dark'
			? '#212121'
			: theme.palette.background.default,
}));

const TitleTypography = styled(Typography)(({ style }) => ({
	fontWeight: '450',
	fontSize: '1.3333rem',
}));

export const BtTimePicker = ({ handleMinutes, handleHours, value }) => {
	const breakpoint = useBreakpointValue();

	const TitlePart = ({ text, separator, pad_left }) => {
		const is_pad_left = pad_left ? +pad_left : 0;
		const is_separator = separator ? +separator : 0;

		return (
			<TitleSubContainer
				size={breakpoint}
				is_separator={is_separator}
				is_pad_left={is_pad_left}
			>
				<TitleTypography>{text}</TitleTypography>
			</TitleSubContainer>
		);
	};

	const Title = () => {
		return (
			<TitleContainer>
				<TitlePart text="HH" />
				<TitlePart separator text=":" />
				<TitlePart pad_left text="MM" />
			</TitleContainer>
		);
	};

	return (
		<>
			<div>
				<Title />
				<div style={{ display: 'flex' }}>
					<ScrollColumn>
						{timePickerUnits.h.map((unit, index) => (
							<BtTimePickerButton
								size={breakpoint}
								onClick={event => {
									handleHours(event.target.value);
								}}
								value={unit.value}
								key={unit.label}
								label={unit.label}
								selected={value.getHours() === unit.value}
							/>
						))}
					</ScrollColumn>
					<ScrollColumn>
						{timePickerUnits.m.map((unit, index) => (
							<BtTimePickerButton
								size={breakpoint}
								onClick={event => {
									handleMinutes(event.target.value);
								}}
								value={unit.value}
								key={unit.label}
								label={unit.label}
								selected={
									nearestValue({
										val: value.getMinutes(),
									}) === unit.value
								}
							/>
						))}
					</ScrollColumn>
				</div>
			</div>
		</>
	);
};

BtTimePicker.propTypes = {
	handleMinutes: PropTypes.func.isRequired,
	handleHours: PropTypes.func.isRequired,
	value: PropTypes.number,
};
