const utils = require('./expression.js');

// logical operators
require('./operator/and.js');
require('./operator/or.js');
require('./operator/eq.js');
require('./operator/exists.js');
require('./operator/comparator.js');

// numerical operators that allow dates
require('./operator/add.js');
require('./operator/subtract.js');
// numerical operators that only allow numerical inputs
require('./operator/number.js');

// date operators
require('./operator/dateTrunc.js');
require('./operator/dateToString.js');

// string operators
require('./operator/concat.js');
require('./operator/regex.js');

// type conversion
require('./operator/convert.js'); // handles toInt, toBool, toDate, toString...

// array operators
require('./operator/arrayToObject.js');

// conditional operators
require('./operator/ifNull.js');

// special operators
require('./operator/expr.js');
require('./operator/literal.js');
require('./operator/mergeObjects.js');

// geo operators
require('./operator/near.js');

// accumulator expressions
require('./operator/accumulator/first.js');
require('./operator/accumulator/last.js');
require('./operator/accumulator/avg.js');
require('./operator/accumulator/sum.js');
require('./operator/accumulator/min.js');
require('./operator/accumulator/max.js');
require('./operator/accumulator/push.js');

module.exports.expression = utils.evaluateExpression;
module.exports.OPERATION_MODE = utils.OPERATION_MODE;
