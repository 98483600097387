const {
	ERROR,
	setOperator,
	evaluateExpression,
	OPERATION_MODE,
} = require('../expression.js');

const operatorKey = '$subtract';

setOperator(
	operatorKey,
	/**
	 * @type {ExpressionFunction<(number  | Date)[], number | Date>}
	 */
	(context, args, options) => {
		const arr = !Array.isArray(args) ? [args] : args;

		/**
		 * @type {number | Date}
		 */
		let total = null;
		let isNull = false;

		if (args.length !== 2) {
			throw new Error(ERROR.INVALID_NUMBER_ARGS(operatorKey, 2, 2));
		}

		for (let pos = 0; pos < arr.length; pos++) {
			const arg = arr[pos];

			/**
			 * @type {any}
			 */
			let value = evaluateExpression(context, arg);

			if (typeof value !== 'number' && !(value instanceof Date)) {
				if (value === undefined || value === null) {
					isNull = true;
					continue;
				}

				throw new Error(
					ERROR.INVALID_OPERATOR_ARGUMENT(
						operatorKey,
						'[number | date]',
						value,
						pos
					)
				);
			}

			if (pos === 0) {
				total = value instanceof Date ? new Date(value) : value;
				continue;
			}

			if (total instanceof Date) {
				if (!(value instanceof Date)) {
					const tempDate = new Date(total);
					tempDate.setTime(total.getTime() - value);
					total = tempDate;
					continue;
				}

				total = total.getTime() - value.getTime();
				continue;
			} else if (value instanceof Date) {
				throw new Error(
					ERROR.INVALID_OPERATOR_ARGUMENT(
						operatorKey,
						'subtracting date from an int'
					)
				);
			}
			total -= value;
		}

		if (isNull) {
			return null;
		}

		return total;
	},

	[OPERATION_MODE.AGGREGATE, OPERATION_MODE.QUERY]
);
