import React from 'react';
import PropTypes from 'prop-types';

import { Button, CircularProgress } from '@mui/material';

import CreateIcon from '@mui/icons-material/Add';

import { withFormContextMethods } from '../../../components/generic/forms';

const CreateActionButtons = withFormContextMethods(
	({ formState: { isDirty }, onClose, sending }) => {
		return (
			<>
				<Button variant="outlined" onClick={onClose} disabled={sending}>
					Cancel
				</Button>
				<Button
					variant="contained"
					disabled={!isDirty || sending}
					type="submit"
					startIcon={
						sending ? (
							<CircularProgress size={20} />
						) : (
							<CreateIcon />
						)
					}
					disableElevation
				>
					Create Data Source
				</Button>
			</>
		);
	}
);

CreateActionButtons.propTypes = {
	isDirty: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	sending: PropTypes.bool.isRequired,
};

export default CreateActionButtons;
