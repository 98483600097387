import { v4 as uuidV4 } from 'uuid';
import {
	screenCreate,
	screenGet,
	screenGroupUpdate,
	screenUpdate,
} from '../../../../API';
import _ from 'lodash';
import { screenAdminUpdate } from '../../../../API/screen_admin.api';

/**
 * Updates the screen group with change,
 * updates the screens state value,
 * prunes data for the backend and makes the screenGroupUpdate API request
 *
 */
const processGroupUpdate = async ({
	screens,
	newScreen,
	screenToRemove,
	position,
	setScreens,
	screenGroupKey,
	appOrgUuid,
	setScreensRefreshPending,
	screenToEdit,
}) => {
	let shouldScreenGroupUpdate = true;

	// exit if no update data passed
	if (!screenToEdit && !newScreen && !screenToRemove) {
		console.log('no screen passed to process updater');
		return;
	}

	// copy of screens for editing
	let newScreens = [...screens];

	// if adding a new screen insert in the screens array
	if (newScreen) {
		newScreens.splice(position - 1, 0, newScreen);
	}

	// if removing a screen...
	if (screenToRemove) {
		newScreens = screens.filter(screen => screen.uuid !== screenToRemove);
	}

	// if editing a screen, remove it from old position and add to new
	if (screenToEdit) {
		//copy the screens
		newScreens = [...screens];
		//remove the screen to edit from the array
		newScreens.splice(_.findIndex(screens, { uuid: screenToEdit.uuid }), 1);
		//put the screen to edit at the selected position
		newScreens.splice(position - 1, 0, screenToEdit);

		//check if the index of the screen to edit has changed
		const prevIndex = _.findIndex(screens, { uuid: screenToEdit.uuid });
		const nextIndex = _.findIndex(newScreens, { uuid: screenToEdit.uuid });
		//use the above to check if the screen group needs to bu updated on the back end
		shouldScreenGroupUpdate = prevIndex !== nextIndex;
	}

	if (shouldScreenGroupUpdate) {
		// strip screen data not required for backend
		let newBackendScreens = newScreens.map(i => ({
			screen_uuid: i.uuid,
			managedBy: i.managedBy,
		}));

		// build screen group update
		let newScreenGroup = {};
		newScreenGroup.organisation = appOrgUuid;
		newScreenGroup.screen_group = screenGroupKey;
		newScreenGroup.screens = [...newBackendScreens];

		await screenGroupUpdate({
			screenGroup: screenGroupKey,
			screenGroupUpdate: newScreenGroup,
		});
	}

	// update component state
	setScreens(newScreens);

	// cancel the pending flag(to allow component to render)
	if (setScreensRefreshPending) setScreensRefreshPending(false);
};

/**
 * Returns tiles and layouts with new uuids
 * @param {*} layouts
 * @param {*} tiles
 * @returns
 */
export const refreshTileUuids = (layouts, tiles, newScreenUuid) => {
	let newLayouts = {};
	let newTiles = {};

	let childTiles = []; //exiting uuids

	const newTileUuids = {}; // {oldUuid: newUuid, ...} - used to match old layout with new tiles

	// find all child tiles so we can skip them later
	Object.keys(tiles).forEach(oldTileUuid => {
		if (tiles[oldTileUuid].variant === 'container') {
			childTiles = childTiles.concat(tiles[oldTileUuid].data.children);
		}
	});

	Object.keys(tiles).forEach(oldTileUuid => {
		// treat the container tiles differently so we can handle the child tiles
		if (tiles[oldTileUuid].variant === 'container') {
			// new children array
			const newChildArray = [];
			// clone the container tile
			const newContainerTile = structuredClone(tiles[oldTileUuid]);
			// assign screen and tile uuids
			const newContainerUuid = uuidV4();
			newContainerTile.uuid = newContainerUuid;
			newContainerTile.data.screen_uuid = newScreenUuid;

			// go through each child in the children array
			newContainerTile.data.children.forEach(previousChildUuid => {
				const newChildUuid = uuidV4();
				// copy the existing child
				const newChildTile = structuredClone(tiles[previousChildUuid]);
				// assign property updates
				newChildTile.uuid = newChildUuid;
				newChildTile.data.screen_uuid = newScreenUuid;
				// add new uuid to children array
				newChildArray.push(newChildUuid);
				// add child tile to tiles object
				newTiles[newChildUuid] = newChildTile;
			});
			// add new children array to new container tile
			newContainerTile.data.children = newChildArray;
			// add new container tile to tiles object
			newTiles[newContainerUuid] = newContainerTile;
			// add new container tile uuid to old/new uuid ref object
			newTileUuids[oldTileUuid] = newContainerUuid;

			// skip the tile of it has been identified as a child tile, as has already been updated
		} else if (!childTiles.includes(oldTileUuid)) {
			// do the same as we did the child tiles above
			const newTileUuid = uuidV4();
			const newTile = structuredClone(tiles[oldTileUuid]);
			newTile.uuid = newTileUuid;
			newTile.data.screen_uuid = newScreenUuid;
			newTiles[newTileUuid] = newTile;
			newTileUuids[oldTileUuid] = newTileUuid;
		}
	});

	// loop through the each layout's array of layout objects
	Object.keys(layouts).forEach(layout => {
		// new array to hold layout data
		const layoutArr = [];

		layouts[layout].forEach(layoutItem => {
			// build a new layout object for each item, using a new uuid
			// const newTileUuid = uuidV4();

			// handle the native layout - which is defined by strings not objects
			if (layout === 'native') {
				// const layoutStr = newTileUuid;
				// layoutArr.push(layoutStr);
				layoutArr.push(newTileUuids[layoutItem]);
				// handle the other layouts
			} else {
				let layoutObj = {};
				layoutObj.uuid = uuidV4();
				layoutObj.tile_uuid = newTileUuids[layoutItem.tile_uuid];
				layoutObj.x = layoutItem.x;
				layoutObj.y = layoutItem.y;
				layoutObj.w = layoutItem.w;
				layoutObj.h = layoutItem.h;

				// add the new layout item to the temp array
				layoutArr.push(layoutObj);
			}
		});
		newLayouts[layout] = layoutArr;
	});

	return [newLayouts, newTiles];
};

/**
 * - Handle new screen - copy or blank
 * - Handle adding the screen to the screen group
 */
export const addScreen = async ({
	screenType,
	newScreenName,
	position,
	copyScreenReference,
	addScreenReference,
	setScreens,
	appOrgUuid,
	userInfo,
	screens,
	screenGroupKey,
	// newScreenData,
	setScreensRefreshPending,
	backgroundColor,
}) => {
	// console.log({ newScreenData });
	switch (screenType) {
		case 'blank':
			// create new screen object and assign values
			let newBlankScreen = {};
			newBlankScreen.layouts = {
				desktop: [],
				tablet: [],
				mobile: [],
				native: [],
			};
			newBlankScreen.managedBy = 'User';
			newBlankScreen.organisation = appOrgUuid;
			newBlankScreen.ownerType = 'User';
			newBlankScreen.screen_name = newScreenName;
			newBlankScreen.tiles = {};
			newBlankScreen.userOwnerId = userInfo.uuid;
			newBlankScreen.uuid = uuidV4();
			newBlankScreen.background_color = backgroundColor;

			// add screen to the backend
			await screenCreate({ newScreen: newBlankScreen });

			// execute the state and backend updates
			processGroupUpdate({
				screens: screens,
				newScreen: newBlankScreen,
				position: position,
				setScreens: setScreens,
				screenGroupKey: screenGroupKey,
				appOrgUuid: appOrgUuid,
				setScreensRefreshPending: setScreensRefreshPending,
			});
			break;
		case 'copy':
			// get the screen to copy data
			let screenCopy = await screenGet({
				screenUuid: copyScreenReference.uuid,
			});
			screenCopy.uuid = uuidV4();

			// refresh the tile uuids
			const [newLayouts, newTiles] = refreshTileUuids(
				screenCopy.layouts,
				screenCopy.tiles,
				screenCopy.uuid
			);

			// assign values to new screen object

			screenCopy.screen_name = newScreenName;
			screenCopy.layouts = newLayouts;
			screenCopy.tiles = newTiles;
			screenCopy.managedBy = 'User';
			screenCopy.background_color = backgroundColor
				? backgroundColor
				: '';
			// screenCopy.ownerType = 'User') {
			// 	newScreen.userOwnerId = req.userid;

			// add screen to the back end
			await screenCreate({ newScreen: screenCopy });

			console.log({ screenCopy });

			// execute the screen group state and API updates
			processGroupUpdate({
				screens: screens,
				newScreen: screenCopy,
				position: position,
				setScreens: setScreens,
				screenGroupKey: screenGroupKey,
				appOrgUuid: appOrgUuid,
				setScreensRefreshPending: setScreensRefreshPending,
			});

			break;
		case 'addScreen':
			let addScreen = await screenGet({
				screenUuid: addScreenReference.uuid,
			});
			addScreen.managedBy = 'User';

			// execute the screen group state and API updates
			processGroupUpdate({
				screens: screens,
				newScreen: addScreen,
				position: position,
				setScreens: setScreens,
				screenGroupKey: screenGroupKey,
				appOrgUuid: appOrgUuid,
				setScreensRefreshPending: setScreensRefreshPending,
			});
			break;
		default:
			break;
	}
};

/**
 *  Execute removal of screen from the screen group
 */
export const removeScreen = ({
	screenGroupKey,
	screens,
	screenUuid,
	setScreens,
	appOrgUuid,
	setScreensRefreshPending,
}) => {
	// execute the screen group state and backend updates
	processGroupUpdate({
		screens: screens,
		screenToRemove: screenUuid,
		setScreens: setScreens,
		screenGroupKey: screenGroupKey,
		appOrgUuid: appOrgUuid,
		setScreensRefreshPending: setScreensRefreshPending,
	});
};

export const renameScreen = async ({
	screen,
	newScreenName,
	setScreensRefreshPending,
	setScreens,
}) => {
	const screenEdit = structuredClone(screen);
	screenEdit.screen_name = newScreenName;
	await screenAdminUpdate({
		screenUuid: screen.uuid,
		screenUpdate: screenEdit,
	});
	setScreens([screenEdit]);
	setScreensRefreshPending(false);
};

/**
 * Execute changes to screen and screen group -
 * - screen name
 * - screen position
 * - screen background color
 */
export const editScreen = async ({
	screens,
	screenGroupKey,
	setScreens,
	appOrgUuid,
	screenUuid,
	newScreenName,
	position,
	backgroundColor,
	setScreensRefreshPending,
}) => {
	// flag to determine if the backend should be updated
	let shouldScreenUpdate = true;

	// make a clone of the screen to update
	let screenEdit = structuredClone(
		screens.find(screen => screen.uuid === screenUuid)
	);

	// check if there are updates to the data
	// if not, prevent backend update
	if (
		screenEdit.screen_name === newScreenName &&
		screenEdit.background_color === backgroundColor
	) {
		shouldScreenUpdate = false;
	}

	// assign values
	screenEdit.managedBy = 'User';
	screenEdit.screen_name = newScreenName;
	screenEdit.background_color = backgroundColor;

	// if required, update the backend
	if (shouldScreenUpdate) {
		await screenUpdate({
			screenUuid: screenUuid,
			screenUpdate: screenEdit,
		});
	}

	// execute the screen group state and backend updates
	processGroupUpdate({
		screens: screens,
		screenToEdit: screenEdit,
		setScreens: setScreens,
		screenGroupKey: screenGroupKey,
		appOrgUuid: appOrgUuid,
		setScreensRefreshPending: setScreensRefreshPending,
		position: position,
	});
};
