import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import BtMessage from '../../components/generic/BtMessage';
import { useNavContext } from '../../context/ContextManager';
import PrivacyDialog from '../auth/footer/PrivacyDialog';
import TermsDialog from '../auth/footer/TermsDialog';
import FormContainer from './FormContainer';
import { user_settings_nav_item } from './UserSettingsNavItem';
import user_settings_options from './UserSettingsOptions';

export default function About() {
	const { setBreadcrumbs, setContextualNav } = useNavContext();
	const [showTerms, setShowTerms] = useState(false);
	const [showPrivacy, setShowPrivacy] = useState(false);

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'User Settings', link: '/UserSettings' },
				{ text: 'About', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([
				...user_settings_nav_item,
				...user_settings_options,
			]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	const items = [
		{
			label: 'privacy policy',
			onClick: () => setShowPrivacy(true),
		},
		{
			label: 'terms of use',
			onClick: () => setShowTerms(true),
		},
	];

	return (
		<FormContainer maxWidth="xs" text="About">
			<div
				style={{
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					gap: '1em',
				}}
			>
				<BtMessage message="Review the Beacon Technology Privacy Policy and Terms of Use." />
				{items.map(({ label, onClick }) => (
					<Button
						key={label}
						disableElevation
						variant="contained"
						onClick={onClick}
					>
						{label}
					</Button>
				))}
			</div>
			<PrivacyDialog
				open={showPrivacy}
				onClose={() => setShowPrivacy(false)}
			/>
			<TermsDialog open={showTerms} onClose={() => setShowTerms(false)} />
		</FormContainer>
	);
}
