import Icon from '@mdi/react';
import { Box, styled, Tooltip } from '@mui/material';
import { memo } from 'react';
import PropTypes from 'prop-types';

const IndicatorContainer = styled(Box)(() => ({
	height: '36px',
	width: '36px',
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

const IndicatorBadge = styled(Box)(({ background }) => ({
	position: 'absolute',
	top: 0,
	right: 0,
	height: '18px',
	width: '18px',
	borderRadius: '50%',
	backgroundColor: background,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

/**
 * @param {} badgeIcon if not passed the badge is not shown
 * @param {} tooltipText if not passed the tooltip is not shown
 * @param {} componentIcon if not passed a nothing is displayed
 *
 * Similar to MUI badge but has a smaller size and currently
 * only supports display of the provided icons.  Icons are expected to be an svg string.
 */
const IconBadge = memo(function ContrastStatusIndicator({
	badgeColour,
	badgeIcon,
	colour,
	componentIcon,
	tooltipText,
}) {
	if (!componentIcon) return <IndicatorContainer />;
	return (
		<Tooltip title={tooltipText}>
			<IndicatorContainer>
				<Icon path={componentIcon} size="24px" />
				{badgeIcon && (
					<IndicatorBadge background={badgeColour}>
						<Icon path={badgeIcon} color={colour} size="14px" />
					</IndicatorBadge>
				)}
			</IndicatorContainer>
		</Tooltip>
	);
});

IconBadge.propTypes = {
	badgeColour: PropTypes.string.isRequired,
	badgeIcon: PropTypes.string,
	colour: PropTypes.string.isRequired,
	componentIcon: PropTypes.string.isRequired,
	tooltipText: PropTypes.string,
};

IconBadge.displayName = 'IconBadge';

export default IconBadge;
