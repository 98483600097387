const {
	ERROR,
	setOperator,
	evaluateExpression,
	OPERATION_MODE,
} = require('../../expression.js');

const operatorKey = '$last';

setOperator(
	operatorKey,
	/**
	 * @type {ExpressionAccumulatorFunction<number , number>}
	 */
	(context, args, options) => {
		const arr = !Array.isArray(args) ? [args] : args;

		if (arr.length !== 1) {
			throw new Error(ERROR.INVALID_NUMBER_ARGS(operatorKey, 1, 1));
		}

		const arg = arr[0];

		context.fieldValues[0] =
			evaluateExpression(
				{ ...context, operationMode: OPERATION_MODE.AGGREGATE },
				arg
			) ?? null;
		return context.fieldValues[0];
	},
	[OPERATION_MODE.ACCUMULATE]
);
