import { apiClient } from '../api_client';

//========================================================================================================
export async function dataViewQueryGet({
	dataViewUuid,
	filters,
	timeRange,
	primaryKey,
	query,
}) {
	// TODO: Add scheam validataion for dataViewUuid
	const req_url = `data_view/${dataViewUuid}/query`;

	// TODO: Add validation for the query

	// console.log(filters);

	var requestBody = { ...query, filters: filters };

	if (timeRange) {
		if (timeRange.start || timeRange.end) {
			requestBody.timeRange = {
				start: timeRange.start,
				end: timeRange.end,
			};
		} else if (timeRange.start) {
			requestBody.timeRange = {
				start: timeRange.start,
			};
		} else if (timeRange.end) {
			requestBody.timeRange = {
				end: timeRange.end,
			};
		}
	}

	if (primaryKey) {
		requestBody.primaryKey = primaryKey;
	}

	try {
		const response = await apiClient({
			method: 'post',
			url: req_url,
			body: requestBody,
		});

		if (response) {
			if (response.result === 'OK') {
				//console.log(response);
				// Validate the returned data object
				// TODO: How can we validate this
				//return await dataSetStoreGetSchema.validate(response.data);
				return response.data;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
export async function dataViewFilterOptionsGet({ dataViewUuid, timeRange }) {
	// TODO: Add scheam validataion for dataViewUuid

	// TODO: Add validation for the query

	var timeRangeQuery = '';
	if (timeRange) {
		if (timeRange.start || timeRange.end) {
			timeRangeQuery = `?time_range_min=${
				timeRange.start
			}&time_range_max=${timeRange.end}`;
		} else if (timeRange.start) {
			timeRangeQuery = `?time_range_min=${timeRange.start}`;
		} else if (timeRange.end) {
			timeRangeQuery = `?time_range_max=${timeRange.end}`;
		}
	}

	const req_url = `data_view/${dataViewUuid}/filter_options${timeRangeQuery}`;
	// console.log('req_url', req_url);
	try {
		const response = await apiClient({
			method: 'get',
			url: req_url,
		});

		if (response) {
			if (response.result === 'OK') {
				// console.log(response);
				// Validate the returned data object
				// TODO: How can we validate this
				//return await dataSetStoreGetSchema.validate(response.data);
				return response.filter_options;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
export async function dataViewFilterOptionsGetFiltered({
	dataViewUuid,
	timeRange,
	filters,
}) {
	// TODO: Add scheam validataion for dataViewUuid

	// TODO: Add validation for the query

	var requestBody = {};
	if (filters) {
		requestBody.filters = filters;
	}

	if (timeRange) {
		if (timeRange.start || timeRange.end) {
			requestBody.timeRange = {
				start: timeRange.start,
				end: timeRange.end,
			};
		} else if (timeRange.start) {
			requestBody.timeRange = {
				start: timeRange.start,
			};
		} else if (timeRange.end) {
			requestBody.timeRange = {
				end: timeRange.end,
			};
		}
	}

	const req_url = `data_view/${dataViewUuid}/filter_options`;
	// console.log('req_url', req_url);
	try {
		const response = await apiClient({
			method: 'post',
			url: req_url,
			body: requestBody,
		});

		if (response) {
			if (response.result === 'OK') {
				// console.log(response);
				// Validate the returned data object
				// TODO: How can we validate this
				//return await dataSetStoreGetSchema.validate(response.data);
				return response.filter_options;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}
