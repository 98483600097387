import {
	add,
	sub,
	endOfHour,
	startOfHour,
	startOfMinute,
	endOfMinute,
	startOfSecond,
	intervalToDuration,
	endOfDay,
	endOfMonth,
	endOfYear,
	endOfWeek,
	startOfYear,
	startOfMonth,
	startOfWeek,
	startOfDay,
	addMilliseconds,
	startOfQuarter,
	endOfQuarter,
	subQuarters,
	addQuarters,
	isWithinInterval,
	format,
	formatDuration,
} from 'date-fns';
import { capitalize } from 'lodash';
import * as yup from 'yup';

import {
	timeUnits,
	weekdays,
	timeFormat,
	timePickerUnits,
} from './timeRangeConstants';
import { regionSettingsSelectData } from '../../../../../../views/Admin/general-info/selectData';

// Return singular or plural versions of time units, for use in labels
export const decidePlural = ({ timeUnit, duration }) => {
	if (timeUnit) {
		const unitData = timeUnits.find(
			({ labelPlural }) => labelPlural === timeUnit
		);

		return parseInt(duration) === 1 ? unitData.label : unitData.labelPlural;
	} else {
		return 'units';
	}
};

// Generated label from parameters provided
export const generateLabel = ({
	duration,
	unit,
	whole,
	rangeType,
	start,
	end,
	realTime,
	absolute,
}) => {
	if (rangeType === 'relative' && whole) {
		let relativeTense = '';
		if (isWithinInterval(DATE, { start: start, end: end })) {
			relativeTense = 'this';
		} else if (unit === 'quarters') {
			if (
				isWithinInterval(subQuarters(DATE, duration), {
					start: start,
					end: end,
				})
			) {
				relativeTense = 'last';
			} else if (
				isWithinInterval(addQuarters(DATE, duration), {
					start: start,
					end: end,
				})
			) {
				relativeTense = 'next';
			}
		} else if (
			isWithinInterval(sub(DATE, { [unit]: duration }), {
				start: start,
				end: end,
			})
		) {
		} else if (
			isWithinInterval(add(DATE, { [unit]: duration }), {
				start: start,
				end: end,
			})
		) {
			relativeTense = 'next';
		}

		return unit === 'days' && duration === 1 && relativeTense === 'this'
			? 'Today'
			: `${capitalize(relativeTense)} ${duration} ${decidePlural({
					duration: duration,
					timeUnit: unit,
			  })}`;
	} else if (realTime) {
		return `${duration} ${decidePlural({
			duration: duration,
			timeUnit: unit,
		})} real time`;
	} else if (absolute) {
		return formatDuration(intervalToDuration({ start: start, end: end }));
	} else {
		return `${duration} ${decidePlural({
			duration: duration,
			timeUnit: unit,
		})}`;
	}
};

const DATE = new Date();

// yup schema for the new time range filter object returned by buildTimeRangeObject
export const TimeRangeSchema = yup.object().shape({
	interval: yup.object().shape({
		label: yup
			.string()
			.min(4)
			.max(20),
		duration: yup
			.number()
			.positive()
			.integer(),
		// .required(),
		unit: yup
			.string()
			.matches(
				/(seconds|minutes|hours|days|weeks|months|quarters|years)/
			),
		tense: yup.string().matches(/(this|next|last)/),
	}),
	start: yup
		.number()
		.integer()
		.required(),
	end: yup
		.number()
		.integer()
		.required(),
	rangeType: yup.string(), // TODO - enhance this validation
	// .required(), TODO - make required
	realTime: yup.boolean(),
});

// Time interval start and end dates are generated
export const generateInterval = ({
	tense,
	unit,
	whole,
	duration,
	firstWeekDay,
}) => {
	if (tense === 'this') {
		switch (unit) {
			case 'seconds':
				return {
					start: startOfSecond(DATE),
					end: addMilliseconds(endOfMinute(DATE), 1),
				};
			case 'minutes':
				return {
					start: startOfMinute(DATE),
					end: addMilliseconds(endOfMinute(DATE), 1),
				};
			case 'hours':
				return {
					start: startOfHour(DATE),
					end: addMilliseconds(endOfHour(DATE), 1),
				};
			case 'days':
				return {
					start: startOfDay(DATE),
					end: addMilliseconds(endOfDay(DATE), 1),
				};
			case 'weeks':
				return {
					start: startOfWeek(DATE, {
						weekStartsOn: weekdays[firstWeekDay],
					}),
					end: addMilliseconds(
						endOfWeek(DATE, {
							weekStartsOn: weekdays[firstWeekDay],
						}),
						1
					),
				};
			case 'months':
				return {
					start: startOfMonth(DATE),
					end: addMilliseconds(endOfMonth(DATE), 1),
				};
			case 'quarters':
				return {
					start: startOfQuarter(DATE),
					end: addMilliseconds(endOfQuarter(DATE), 1),
				};
			case 'years':
				return {
					start: startOfYear(DATE),
					end: addMilliseconds(endOfYear(DATE), 1),
				};
			default:
				return null;
		}
	} else if (!whole) {
		return tense === 'last'
			? {
					start:
						unit === 'quarters'
							? subQuarters(DATE, duration)
							: sub(DATE, { [unit]: duration }),
					end: DATE,
			  }
			: {
					start: DATE,
					end:
						unit === 'quarters'
							? addQuarters(DATE, duration)
							: add(DATE, { [unit]: duration }),
			  };
	} else if (whole) {
		let origin = {};
		switch (unit) {
			case 'seconds':
				tense === 'last'
					? (origin = startOfSecond(DATE))
					: (origin = startOfSecond(add(DATE, { [unit]: 1 })));
				break;
			case 'minutes':
				tense === 'last'
					? (origin = startOfMinute(DATE))
					: (origin = startOfMinute(add(DATE, { [unit]: 1 })));

				break;
			case 'hours':
				tense === 'last'
					? (origin = startOfHour(DATE))
					: (origin = startOfHour(add(DATE, { [unit]: 1 })));

				break;
			case 'days':
				tense === 'last'
					? (origin = startOfDay(DATE))
					: (origin = startOfDay(add(DATE, { [unit]: 1 })));
				break;
			case 'weeks':
				tense === 'last'
					? (origin = startOfWeek(DATE, {
							weekStartsOn: weekdays[firstWeekDay],
					  }))
					: (origin = startOfWeek(add(DATE, { [unit]: 1 }), {
							weekStartsOn: weekdays[firstWeekDay],
					  }));
				break;
			case 'months':
				tense === 'last'
					? (origin = startOfMonth(DATE))
					: (origin = startOfMonth(add(DATE, { [unit]: 1 })));

				break;
			case 'quarters':
				tense === 'last'
					? (origin = startOfQuarter(DATE))
					: (origin = startOfQuarter(add(DATE, { [unit]: 1 })));
				break;
			case 'years':
				origin = startOfYear(DATE);
				tense === 'last'
					? (origin = startOfYear(DATE))
					: (origin = startOfYear(add(DATE, { [unit]: 1 })));
				break;
			default:
				return null;
		}
		return tense === 'last'
			? {
					start:
						unit === 'quarters'
							? subQuarters(origin, 1)
							: sub(origin, { [unit]: duration }),
					end: origin,
			  }
			: {
					start: origin,
					end:
						unit === 'quarters'
							? addQuarters(origin, 1)
							: add(origin, { [unit]: duration }),
			  };
	}
};

// handle offsetting the time range, used when previous and next buttons are clicked
export const handleOffset = ({ unit, duration, shiftData }) => {
	const offsetDate = origin => {
		switch (shiftData.direction) {
			case 'previous':
				return unit === 'quarters'
					? subQuarters(origin, duration)
					: sub(origin, {
							[unit]: duration,
					  });
			case 'next':
				return unit === 'quarters'
					? addQuarters(origin, duration)
					: add(origin, {
							[unit]: duration,
					  });
			default:
				break;
		}
	};
	return {
		start: offsetDate(shiftData.start),
		end: offsetDate(shiftData.end),
	};
};

// The date and time string is formatted to the to the organisation date format
// The time is not shown if the range is rounded to full days
// If rounded we also minus 1ms from the formatted time to show previous time unit
export const formatTimeDateString = ({ dateFormat, start, end, whole }) => {
	// Valid date formats from input options
	const validDateFormats = regionSettingsSelectData
		.find(item => item.field === 'dateFormat')
		.data.map(format => format.value);

	// date format is checked against the list of valid formats and if not
	// validated it is replaced with a valid format
	const validDateFormat = validDateFormats.includes(dateFormat)
		? dateFormat
		: validDateFormats[2];

	// Time is hidden if the time range is rounded to a whole day
	const hideTime =
		new Date(start).toTimeString().split(' ')[0] === '00:00:00' &&
		new Date(end).toTimeString().split(' ')[0] === '00:00:00';

	return hideTime
		? {
				start: `${format(start, validDateFormat)}`,
				end: `${format(whole ? end - 1 : end, validDateFormat)}`,
		  }
		: {
				start: `${format(start, `${validDateFormat} ${timeFormat}`)}`,
				end: `${format(
					whole ? end - 1 : end,
					`${validDateFormat} ${timeFormat}`
				)}`,
		  };
};

export const nearestValue = ({ val }) =>
	timePickerUnits.m
		.map(unit => unit.value)
		.reduce(
			(p, n) => (Math.abs(p) > Math.abs(n - val) ? n - val : p),
			Infinity
		) + val;

const generateLiveInterval = ({ unit, duration }) => {
	const origin = new Date();
	return {
		start:
			unit === 'quarters'
				? subQuarters(origin, duration)
				: sub(origin, {
						[unit]: duration,
				  }),
		end: origin,
	};
};

export async function buildTimeRangeObject({
	label,
	tense,
	duration,
	unit,
	whole,
	shift,
	shiftData,
	rangeType,
	firstWeekDay,
	realTime,
	start,
	end,
	absolute,
}) {
	// Check if there is a valid first day of the week provided in the appOrg,
	// if not resort to fallback value
	const validFirstWeekDay = Object.keys(weekdays).includes(firstWeekDay)
		? firstWeekDay
		: 'MONDAY';

	const startEnd = shift
		? handleOffset({
				unit: unit,
				duration: duration,
				shiftData: shiftData,
		  })
		: absolute
			? { start: start, end: end }
			: realTime
				? generateLiveInterval({
						unit: unit,
						duration: duration,
				  })
				: generateInterval({
						tense: tense,
						unit: unit,
						whole: whole,
						duration: duration,
						firstWeekDay: validFirstWeekDay,
				  });

	// Generate label
	const newLabel = generateLabel({
		label: label,
		tense: tense,
		duration: duration,
		unit: unit,
		whole: whole,
		rangeType: rangeType,
		start: startEnd.start,
		end: startEnd.end,
		firstWeekDay: validFirstWeekDay,
		realTime: realTime,
		absolute: absolute,
	});

	// New time range object values
	const filter = {
		interval: {
			label: newLabel,
			duration: duration,
			unit: unit,
			tense: tense,
			whole: whole,
		},
		start:
			typeof startEnd.start === 'number'
				? startEnd.start
				: startEnd.start.getTime(),
		end:
			typeof startEnd.end === 'number'
				? startEnd.end
				: startEnd.end.getTime(),
		realTime: realTime,
	};

	// Validation of the time range object
	const validatedOutput = await TimeRangeSchema.validate(filter).then(
		obj => {
			return obj;
		},
		err => {
			console.log(err, filter);
		}
	);

	return validatedOutput;
}
