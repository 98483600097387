const {
	setOperator,
	evaluateExpression,
	OPERATION_MODE,
	ERROR,
} = require('../expression.js');

const operatorKey = '$exists';

setOperator(
	operatorKey,

	/**
	 * @type {ExpressionFunction<[string, boolean],boolean>}
	 */
	(context, args, options) => {
		const arr = !Array.isArray(args) ? [args] : args;

		if (arr.length !== 2) {
			throw new Error(ERROR.INVALID_NUMBER_ARGS(operatorKey, 2, 2));
		}

		/**
		 * @type {boolean}
		 */
		const checkExists = arr[1];

		if (typeof checkExists !== 'boolean') {
			throw new Error(
				ERROR.INVALID_OPERATOR_ARGUMENT(
					operatorKey,
					'boolean',
					checkExists,
					1
				)
			);
		}

		/**
		 * @type {any}
		 */
		const field = evaluateExpression(context, arr[0]);

		if (checkExists) {
			return checkExists && field !== undefined;
		}

		return field === undefined;
	},
	[OPERATION_MODE.QUERY]
);
