import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	IconButton,
	Tooltip,
	Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { tagGroupUpdateValue } from '../../../API/tags.api';
import { useSnackbar } from 'notistack';

export default function ValueListItem({
	groupId,
	onEditClick,
	setGroup,
	tagValue,
}) {
	const { enqueueSnackbar } = useSnackbar();

	const toggleArchival = useCallback(
		async checked => {
			const newStatus = checked ? 'Archived' : 'Active';

			try {
				const newGroup = await tagGroupUpdateValue({
					tagGroupUuid: groupId,
					tagGroupValueUuid: tagValue.uuid,
					tagValueUpdate: { status: newStatus },
				});
				setGroup(newGroup);
			} catch (error) {
				enqueueSnackbar(
					`Failed to ${newStatus.toLowerCase()} ${
						tagValue.name
					} value`,
					{
						variant: 'error',
					}
				);
				console.error(error);
			}
			return;
		},
		[enqueueSnackbar, groupId, setGroup, tagValue]
	);

	return (
		<div
			style={{
				alignItems: 'center',
				display: 'flex',
				justifyContent: 'space-between',
			}}
		>
			<Typography>{tagValue.name}</Typography>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<Tooltip title="Edit" disableInteractive>
					<IconButton
						style={{
							width: 40,
							height: 40,
						}}
						onClick={() => onEditClick(tagValue)}
					>
						<EditIcon />
					</IconButton>
				</Tooltip>
				<FormControl
					style={{
						transform: 'translateY(2px)',
						marginLeft: 20,
						marginRight: -10,
					}}
				>
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									checked={tagValue.status === 'Archived'}
									onChange={event =>
										toggleArchival(event.target.checked)
									}
								/>
							}
							label={
								<Typography variant="subtitle2">
									Archive
								</Typography>
							}
						/>
					</FormGroup>
				</FormControl>
			</div>
		</div>
	);
}

ValueListItem.propTypes = {
	groupId: PropTypes.string,
	onEditClick: PropTypes.func.isRequired,
	setGroup: PropTypes.func.isRequired,
	tagValue: PropTypes.object.isRequired,
};
