import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

import logo from '../../assets/bt_logo_no_text.svg';

const Logo = styled('img')(() => ({
	position: 'absolute',
	width: 60,
	height: 60,
	top: 30,
	left: 30,
	animation: 'grow 240ms cubic-bezier(0.325, 1.5, 0.32, 1.575);',

	'@keyframes grow': {
		'0%': {
			transform: 'scale(0.0)',
		},
		'100%': {
			transform: 'scale(1.0)',
		},
	},
}));

export default function BtLoading({
	children,
	fullScreen,
	loading,
	LoadingComponent,
	showLogo,
	style,
}) {
	if (loading) {
		return (
			<Box
				style={{
					alignItems: 'center',
					display: 'flex',
					justifyContent: 'center',
					minHeight: fullScreen ? '100vh' : '100%',
					width: fullScreen ? '100vw' : '100%',
					...style,
				}}
			>
				{!!LoadingComponent && LoadingComponent}
				{!LoadingComponent &&
					(showLogo ? (
						<div
							style={{
								width: 120,
								height: 120,
								position: 'relative',
							}}
						>
							<CircularProgress
								size={100}
								thickness={1}
								style={{
									position: 'absolute',
									top: 10,
									left: 10,
								}}
							/>
							<Logo src={logo} alt="BT Logo" />
						</div>
					) : (
						<CircularProgress />
					))}
			</Box>
		);
	}

	if (Children.toArray(children).length > 0) {
		return children;
	}

	return null;
}

BtLoading.defaultProps = {
	loading: true,
};

BtLoading.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	fullScreen: PropTypes.bool,
	loading: PropTypes.bool,
	LoadingComponent: PropTypes.node,
	showLogo: PropTypes.bool,
	style: PropTypes.object,
};
