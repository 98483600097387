import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Controller, useFormContext } from 'react-hook-form';
import { Skeleton, TextField } from '@mui/material';

import useFormElRequired from '../../../hooks/useFormElRequired';

export default function BtFormBarCodeScanner({
	disabled,
	name,
	label,
	type,
	isRequired,
	...other
}) {
	const {
		control,
		watch,
		loading,
		sending,
		setValue,
		trigger,
		validationSchema,
		formState: { errors },
	} = useFormContext() || { formState: {} };

	const value = watch(name) || '';

	const handleChange = useCallback(
		event => {
			const value = event.target.value;

			if (errors?.[name]) {
				trigger(name);
			}

			setValue(name, type === 'number' ? +value : value, {
				shouldTouch: true,
				shouldDirty: true,
			});
		},
		[errors, name, setValue, trigger, type]
	);

	const required = useFormElRequired(isRequired, validationSchema, name);

	if (loading) {
		return (
			<Skeleton animation="wave" style={{ width: '100%', height: 45 }} />
		);
	}

	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<TextField
					variant="standard"
					size="small"
					label={
						!!label
							? `${label}${!required ? ' (optional)' : ''}`
							: undefined
					}
					color="primary"
					fullWidth
					type={type}
					{...field}
					onChange={handleChange}
					disabled={loading || sending || disabled}
					value={value}
					error={!!errors[name]}
					helperText={errors[name]?.message}
					{...other}
				/>
			)}
		/>
	);
}

BtFormBarCodeScanner.propTypes = {
	disabled: PropTypes.bool,
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	isRequired: PropTypes.bool,
};
