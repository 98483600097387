import React from 'react';

import {
	BtFormCheckbox,
	BtFormContent,
	BtFormLateralContainer,
	BtFormSection,
	BtFormSelect,
	BtFormTextField,
	withFormContextMethods,
} from '../../../components/generic/forms';
import BtFilePicker from '../../../components/generic/BtFilePicker';
import { organisationTypes } from './selectData';

const RegNoController = withFormContextMethods(({ watch }) => {
	const isVisible = watch('isVatRegistered');

	if (isVisible) {
		return (
			<BtFormTextField name="vatRegNo" label="VAT Registration Number" />
		);
	}

	return null;
});

export default function SectionBasicInfo() {
	return (
		<BtFormSection title="Basic Information">
			<BtFormContent>
				<BtFormLateralContainer>
					<BtFormTextField name="displayName" label="Display Name" />
					<BtFormTextField
						name="legalTradingName"
						label="Legal/Trading Name"
					/>
				</BtFormLateralContainer>
				<BtFilePicker
					label="Organisation Logo"
					imagesOnly
					maxFiles={1}
					maxFileSize={52428800}
				/>
				<BtFormSelect
					name="organisationType"
					label="Organisation Type"
					items={organisationTypes}
				/>
				<BtFormTextField name="regNo" label="Registration Number" />
				<BtFormCheckbox
					name="isVatRegistered"
					label="Are you VAT registered?"
				/>
				<RegNoController />
			</BtFormContent>
		</BtFormSection>
	);
}
