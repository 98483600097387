const { isDocument } = require('../../utils.js');
const {
	ERROR,
	setOperator,
	evaluateExpression,
	OPERATION_MODE,
	evaluateExpressionType,
	EXPRESSION,
	getExpressionType,
} = require('../expression.js');

const operatorKey = '$dateToString';

function leftPad(string, padWith = '0', length = 2) {
	let padded = string;

	if (!padWith) {
		return string;
	}

	while (padded.length < length) {
		padded = padWith + padded;
	}

	return padded;
}

/**
 *
 * @param {Date} date
 * @param {string} formatString
 *
 * @returns {string}
 */
function formatDate(date, formatString) {
	const format = formatString.split('%');

	let out = format[0];

	for (let index = 1; index < format.length; index++) {
		const specifier = format[index][0];
		const rest = format[index].slice(1);

		let part = '';

		switch (specifier) {
			case '%':
				// escaped %
				part = '%';
				break;
			case 'd':
				// day of month (2 digit, zero padded)
				part = leftPad(date.getUTCDate() + '');
				break;
			case 'G':
			case 'Y':
				// full year (4 digit, zero padded)
				part = leftPad(date.getUTCFullYear() + '', '0', 4);
				break;
			case 'H':
				// hour  (2 digit, zero padded)
				part = leftPad(date.getUTCHours() + '');
				break;
			case 'j': {
				// day of year (3 digit, zero padded)
				const dayMilliseconds = 1000 * 60 * 60 * 24;
				const zerothDayOfYear = Date.UTC(date.getUTCFullYear(), 0, 0);
				const msDiff =
					Date.UTC(
						date.getUTCFullYear(),
						date.getUTCMonth(),
						date.getUTCDate()
					) - zerothDayOfYear;
				part = leftPad(msDiff / dayMilliseconds + '', '0', 3);
				break;
			}
			case 'L':
				// milliseconds (3 digit, zero padded)
				part = leftPad(date.getUTCMilliseconds() + '', '0', 3);
				break;
			case 'm':
				// month (2 digit, zero padded)
				part = leftPad(date.getUTCMonth() + 1 + '');
				break;
			case 'M':
				// minute (2 digit, zero padded)
				part = leftPad(date.getUTCMinutes() + '');
				break;
			case 'S':
				// second (2 digit, zero padded)
				part = leftPad(date.getUTCSeconds() + '');
				break;
			case 'w':
				// day of week (1-sunday, 7-saturday)

				// js date 0 is sunday, 6 is saturday
				part = date.getUTCDay() + 1 + '';

				break;
			case 'u':
				// day of week ISO 8601 (1-monday, 7-sunday)

				// js date 0 is sunday, 6 is saturday
				part = date.getUTCDay() === 0 ? '7' : date.getUTCDay() + '';
				break;
			case 'U': {
				// week of year (2 digit, zero padded) (00 - 53)
				const dayMilliseconds = 1000 * 60 * 60 * 24;
				const zerothDayOfYear = Date.UTC(date.getUTCFullYear(), 0, 0);

				const msDiff =
					Date.UTC(
						date.getUTCFullYear(),
						date.getUTCMonth(),
						date.getUTCDate()
					) - zerothDayOfYear;

				const dayOfYear = Math.ceil(msDiff / dayMilliseconds);

				// translate to mongo day numbers (1-sunday, 7-saturday)
				const dayOfWeek = date.getUTCDay() + 1;

				const val = Math.trunc((dayOfYear - dayOfWeek + 10) / 7);

				// js date 0 is sunday, 6 is saturday
				part = leftPad(val + '');
				break;
			}
			case 'V': {
				// week of year (2 digit, zero padded) (01 - 53)
				/// The ISO 8601 week number of the current year as a
				// decimal number, range 01 to 53, where week 1 is the first
				// week that has at least 4 days in the current year, and with
				// Monday as the first day of the week

				const dayMilliseconds = 1000 * 60 * 60 * 24;
				const zerothDayOfYear = Date.UTC(date.getUTCFullYear(), 0, 0);

				const msDiff =
					Date.UTC(
						date.getUTCFullYear(),
						date.getUTCMonth(),
						date.getUTCDate()
					) - zerothDayOfYear;

				const dayOfYear = Math.ceil(msDiff / dayMilliseconds);
				const dayOfWeek = date.getUTCDay() === 0 ? 7 : date.getUTCDay();

				let val = Math.trunc((dayOfYear - dayOfWeek + 10) / 7);

				if (val === 0) {
					// work out last week number of previous year (52 or 53)
					const zMsDiff =
						zerothDayOfYear -
						Date.UTC(date.getUTCFullYear() - 1, 0, 0);

					const zDoY = Math.ceil(zMsDiff / dayMilliseconds);
					const zDoW = new Date(zerothDayOfYear).getUTCDay();

					val = Math.trunc((zDoY - zDoW + 10) / 7);
				}

				part = leftPad(val + '');
				break;
			}

			// Note: JS does not provide means to do translation on dates to
			// arbitrary timezone, only local or UTC.
			// so all dates will be either UTC or system TZ without an
			// additional library in place.

			// the below two functions would work if "date" could have
			// a timezone other than the specified two...

			case 'z': {
				// The timezone offset from UTC. +/-[hh][mm]
				const tzo = date.getTimezoneOffset();

				const hrs = Math.abs(Math.trunc(tzo / 60));
				const mins = Math.abs(tzo % 60);

				part =
					(tzo < 0 ? '-' : '+') +
					leftPad(hrs + '') +
					leftPad(mins + '');
			}
			case 'Z': {
				// The minutes offset from UTC as a number. For example, if the
				// timezone offset (+/-[hh][mm]) was +0445, the minutes offset
				// is +285.
				part = '' + date.getTimezoneOffset();
			}
			default:
				break;
		}

		out += part + rest;
	}

	return out;
}

setOperator(
	operatorKey,
	function (context, args, options) {
		if (!isDocument(args)) {
			throw new Error(
				ERROR.INVALID_OPERATOR_ARGUMENT(
					operatorKey,
					'object as its argument'
				)
			);
		}

		const hasInput = Object.hasOwnProperty.call(args, 'date');

		if (!hasInput) {
			throw new Error(
				ERROR.INVALID_OPERATOR_ARGUMENT(
					operatorKey,
					'"date" property to be set'
				)
			);
		}

		const date = evaluateExpression(context, args.date);

		if (date === null || date === undefined) {
			if (Object.hasOwnProperty.call(args, 'onNull')) {
				return evaluateExpression(context, args.onNull);
			}

			return null;
		}

		if (!(date instanceof Date)) {
			throw new Error(
				ERROR.INVALID_OPERATOR_ARGUMENT(
					operatorKey,
					'"date" expression to evaluate to a Date object',
					date
				)
			);
		}

		let format = null;

		if (Object.hasOwnProperty.call(args, 'format')) {
			const formatType = getExpressionType(args.format);

			if (
				formatType.type !== EXPRESSION.LITERAL ||
				typeof formatType.value !== 'string'
			) {
				throw new Error(
					ERROR.INVALID_OPERATOR_ARGUMENT(
						operatorKey,
						'"format" to be a string literal',
						date
					)
				);
			}

			format = args.format;
		}

		if (format === null) {
			return date.toISOString();
		}

		// if timezone...

		// do formatting and return result
		return formatDate(date, format);
	},
	[OPERATION_MODE.AGGREGATE]
);
