import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

// COMPONENTS
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
//import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import 'date-fns';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

const styles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(2),
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
	},
	dialogTitle: {
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.contrastText,
	},
	dialogContent: {
		minWidth: '300px',
		minHeight: '150px',
	},
	dialogText: {
		padding: theme.spacing(2),
		textAlign: 'center',
	},
	dialogButton: {
		width: '30%',
	},
}));

export default function DialogConfirm({ setOpen, onClose, title, text, icon }) {
	const classes = styles();
	const handleDialogCancel = () => {
		onClose({ result: 'cancel' });
	};
	const handleDialogConfirm = () => {
		onClose({ result: 'confirm' });
	};

	return (
		<>
			<Dialog
				open={setOpen}
				onClose={handleDialogCancel}
				className={classes.root}
			>
				<DialogTitle
					id="form-dialog-title"
					className={classes.dialogTitle}
				>
					{title}
				</DialogTitle>
				<DialogContent className={classes.dialogContent}>
					<Box display="flex" flexDirection="column">
						<Typography className={classes.dialogText} variant="h6">
							{text}
						</Typography>
					</Box>

					<Box display="flex" flexDirection="column">
						<Divider />
						<Box
							display="flex"
							flexDirection="row"
							justifyContent="center"
						>
							<Button
								variant="outlined"
								startIcon={<CheckIcon />}
								onClick={handleDialogConfirm}
								className={classes.dialogButton}
							>
								OK
							</Button>
							<Button
								variant="outlined"
								startIcon={<CloseIcon />}
								onClick={handleDialogCancel}
								className={classes.dialogButton}
							>
								CANCEL
							</Button>
						</Box>
					</Box>
				</DialogContent>
			</Dialog>
		</>
	);
}
