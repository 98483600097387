import React, {
	forwardRef,
	useCallback,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';

import { ButtonBase, InputBase, styled } from '@mui/material';
import Color from 'color';
import { CalendarToday as DateIcon } from '@mui/icons-material';
import _ from 'lodash';

import BtDateTimePicker from '../../../../../components/generic/BtDateTimePicker';
import DataSetErrorTooltip from './DataSetErrorTooltip';

const Container = styled('div')(({ error, theme }) => ({
	alignItems: 'center',
	backgroundColor: Color(theme.palette.primary.main)
		.fade(0.9)
		.toString(),
	borderRadius: 6,
	boxShadow: error
		? `0 0 0 1px ${theme.palette.indicators.error.text}, inset 0 0 2px ${
				theme.palette.indicators.error.main
		  }`
		: 'none',
	cursor: 'pointer',
	display: 'flex',
	height: 22.5,
	marginLeft: -4,
	padding: '0 0 0 4px',

	'& input': {
		cursor: 'pointer',
	},

	'&:hover': {
		backgroundColor: Color(theme.palette.primary.main)
			.fade(0.8)
			.toString(),
	},

	'&:focus-within': {
		backgroundColor: Color(theme.palette.text.solid)
			.fade(0.92)
			.toString(),
		cursor: 'text',

		'& input': {
			cursor: 'text',
		},
	},
}));

const PickerButton = styled(ButtonBase)(({ theme }) => ({
	borderBottomRightRadius: 6,
	borderTopRightRadius: 6,
	height: 22.5,
	padding: '0 2px',
	width: 26.5,

	'&:hover': {
		backgroundColor: Color(theme.palette.text.solid)
			.alpha(0.05)
			.toString(),
	},
}));

export const DataSetDatePicker = forwardRef(
	({ defaultValue, disabled, onChange, value }, ref) => {
		const buttonRef = useRef();
		const inputFieldRef = useRef();

		const validationCheck = useCallback(value => !!value, []);

		const error = useRef(
			!validationCheck(defaultValue) ? 'A valid date is required.' : null
		);

		const [open, setOpen] = useState(false);
		const [_value, setValue] = useState(() => {
			const defaultDateTime = value ?? defaultValue;

			if (validationCheck(defaultDateTime)) {
				return new Date(defaultDateTime).toISOString();
			}
		});

		const handleChange = useCallback(
			newDateTime => {
				const dateTime = new Date(newDateTime).toISOString();

				if (validationCheck(newDateTime)) {
					if (dateTime !== _value) {
						setValue(dateTime);
						onChange?.(null, dateTime);
					}

					error.current = null;

					return;
				}

				error.current = 'A valid date is required';
			},
			[onChange, validationCheck, _value]
		);

		useImperativeHandle(ref, () => ({
			focus: () => {
				setTimeout(() => inputFieldRef?.current?.focus(), 1);
			},
		}));

		const Input = useMemo(
			() => ({ btnRef, inputRef, ...params }) => (
				<Container ref={inputRef} error={+!!error.current}>
					<DataSetErrorTooltip
						open={!!error.current}
						title={error.current ?? ''}
						verticalOffset={-8}
					>
						<InputBase
							inputRef={inputFieldRef}
							style={{ maxWidth: 150 }}
							{..._.omit(params, ['InputProps'])}
						/>
					</DataSetErrorTooltip>
					<PickerButton
						ref={btnRef}
						focusRipple
						onClick={() => setOpen(true)}
					>
						<DateIcon style={{ height: 18, width: 18 }} />
					</PickerButton>
				</Container>
			),
			[error]
		);

		return (
			<div ref={ref}>
				<BtDateTimePicker
					disabled={disabled}
					mask={null}
					onChange={handleChange}
					onClose={() => {
						setOpen(false);

						setTimeout(() => buttonRef.current.blur(), 1);
					}}
					open={open}
					renderInput={params => (
						<Input btnRef={buttonRef} {...params} />
					)}
					value={new Date(_value)}
					variant="datetime"
				/>
			</div>
		);
	}
);

const valuePropType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

DataSetDatePicker.propTypes = {
	defaultValue: valuePropType,
	disabled: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	value: valuePropType,
};

DataSetDatePicker.displayName = 'DataSetDatePicker';

export default DataSetDatePicker;
