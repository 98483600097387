import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { format } from 'date-fns';
import { Typography } from '@mui/material';

import {
	DATE,
	DATETIME,
	TIME,
	TIME_FORMAT,
} from '../../../../../utils/timeConstants';
import useDateFormat from '../../../../../hooks/useDateFormat';

export default function WorkflowTemporalResult({ value, variant }) {
	const dateFormat = useDateFormat();

	const formats = useMemo(
		() => ({
			[DATE]: dateFormat,
			[DATETIME]: `${dateFormat} ${TIME_FORMAT}`,
			[TIME]: TIME_FORMAT,
		}),
		[dateFormat]
	);

	const _value = useMemo(() => format(value, formats[variant]), [
		formats,
		value,
		variant,
	]);

	return <Typography>{_value}</Typography>;
}

WorkflowTemporalResult.defaultProps = {
	variant: DATETIME,
};

WorkflowTemporalResult.propTypes = {
	value: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.object,
		PropTypes.string,
	]),
	variant: PropTypes.oneOf([DATE, DATETIME, TIME]),
};
