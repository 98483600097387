import React, { useEffect, useState, memo } from 'react';
import { useTheme } from '@mui/styles';

import {
	generateTimeSeriesTickValues,
	fontSize,
	formatTimestamp,
	getTextDimensions,
} from '../Utils';
import { useComponentsContext } from './Chart';

export function XAxis({ dataKey }) {
	const [ticks, setTicks] = useState(null);
	const THEME = useTheme();
	const [xTickFormat, setXTickFormat] = useState('HH:mm');
	const [xSupplementaryTicks, setXSupplementaryTicks] = useState([]);
	const [xSupplementaryTickFormat, setXSupplementaryTickFormat] = useState(
		''
	);
	const [timestampFormat, setTimestampFormat] = useState('DD/MM/YYYY HH:mm');

	const { plotArea, timeRange } = useComponentsContext();

	useEffect(
		() => {
			if (timeRange) {
				const {
					ticks,
					supplementaryTicks,
					supplementaryTickFormat,
					timestampFormat,
					tickFormat,
				} = generateTimeSeriesTickValues(
					timeRange.start,
					timeRange.end
				);

				var maxLabelWidth = 0;
				ticks.forEach(tick => {
					const formattedTimestamp = formatTimestamp(
						tick,
						xTickFormat
					);
					const textWidth = getTextDimensions(
						formattedTimestamp,
						fontSize
					).width;
					if (textWidth > maxLabelWidth) {
						maxLabelWidth = textWidth;
					}
				});

				const tickSpacing = plotArea.width / ticks.length;
				const tickModulo = Math.floor(maxLabelWidth / tickSpacing) + 1;

				const formattedTicks = ticks.map((tick, index) => {
					return {
						value: tick,
						formattedValue:
							index % tickModulo === 0
								? formatTimestamp(tick, xTickFormat)
								: '',
					};
				});

				setTicks(formattedTicks);

				setXSupplementaryTicks(supplementaryTicks);
				setXSupplementaryTickFormat(supplementaryTickFormat);
				setXTickFormat(tickFormat);
				setTimestampFormat(timestampFormat);
			}
		},
		[timeRange, plotArea.width, xTickFormat]
	);

	const CustomXAxisTick = memo(function CustomXAxisTick({
		x,
		y,
		// stroke,
		payload,
	}) {
		return (
			<g transform={`translate(${x},${y})`}>
				<line
					x1={0}
					y1="0"
					x2={0}
					y2="10"
					stroke={THEME.palette.chart.tick}
					strokeWidth="1"
				/>
				<text
					x={0}
					y={0}
					dy={20}
					textAnchor="middle"
					fill={THEME.palette.chart.label}
					fontSize={fontSize}
				>
					{payload.formattedValue}
				</text>
				{xSupplementaryTicks?.includes(payload.value) && (
					<text
						x={0}
						y={0}
						dy={32}
						textAnchor="start"
						fill={THEME.palette.chart.tick}
						fontSize={fontSize}
					>
						{payload.formattedValue}
					</text>
				)}
			</g>
		);
	});

	return (
		<g
			fill="none"
			transform={`translate(${plotArea.x} ${plotArea.height})`}
		>
			<line
				x1="0"
				y1="0"
				x2={plotArea.width}
				y2="0"
				stroke={THEME.palette.chart.tick}
				strokeWidth="1"
			/>

			{(ticks || []).map((tick, index) => {
				const tickOffset = index * (plotArea.width / ticks.length);
				return (
					<CustomXAxisTick
						key={index}
						x={tickOffset}
						y={0}
						payload={tick}
					/>
				);
			})}
		</g>
	);
}
