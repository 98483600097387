import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ArrowRightThin } from 'mdi-material-ui';
import { Button, styled, Tooltip, Typography } from '@mui/material';
import { Check, Sensors } from '@mui/icons-material';
import { roundToNearestMinutes, subDays } from 'date-fns';

import { a11yProps, BtTab, BtTabBar, BtTabPanel } from '../../../BtTabView';
import { BtPopperExtendedActions } from '../../../BtPopperContent';
import { BtTimeDateStaticPicker } from '../../../BtTimeDateStaticPicker';
import {
	buildTimeRangeObject,
	formatTimeDateString,
} from './utils/timeRangeFunctions';
import CommonRanges from './CommonRanges';
import QuickSelect from './QuickSelect';
import { timePickerUnits } from './utils/timeRangeConstants';
import { useAppContext } from '../../../../../context/ContextManager';

const END = 'end',
	START = 'start';

const TimeRangeContainer = styled('div')(
	({ clickable, error, pad_left, selected, separator, theme }) => ({
		borderBottom: !clickable
			? `1px solid ${theme.palette.divider}`
			: error
				? `2px solid ${theme.palette.indicators.error.main}`
				: selected
					? `2px solid ${theme.palette.primary.main}`
					: `2px solid ${theme.palette.divider}`,
		cursor: clickable ? 'pointer' : 'auto',
		// paddingLeft: pad_left,
		pointerEvents: clickable ? 'all' : 'none',
		// width: separator ? '18px' : '200px',
		width: separator ? '26px' : 'calc(50% - 12px)',
		paddingLeft: separator ? 0 : '1em',
		paddingRight: separator ? 0 : '1em',
		minHeight: '100%',
		paddingTop: '0.5em',
		paddingBottom: separator ? '1em' : '0.5em',
	})
);

const TimeRangesContainer = styled('div')(() => ({
	alignItems: 'flex-end',
	justifyContent: 'center',
	display: 'flex',
	// gridTemplateColumns: 'repeat(3, auto)',
	// justifyContent: 'space-between',
	width: '100%',
	boxSizing: 'border-box',
}));

const TimeRangeTypography = styled(Typography)(
	({ theme, clickable, selected, error }) => ({
		color:
			clickable && error
				? theme.palette.indicators.error.main
				: clickable && selected
					? theme.palette.primary.dark
					: theme.palette.text.primary,
	})
);

const TimeRangeLabel = ({ clickable, error, icon, selected, text, title }) => {
	return (
		<div
			style={{
				justifyContent: 'center',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<TimeRangeTypography
				clickable={+clickable}
				error={+error}
				selected={selected}
				variant="caption"
			>
				{title}
			</TimeRangeTypography>
			<div style={{ display: 'flex' }}>
				{icon && icon}
				<TimeRangeTypography
					clickable={+clickable}
					error={+error}
					selected={selected}
				>
					{text}
				</TimeRangeTypography>
			</div>
		</div>
	);
};

const RangePreview = ({
	clickable,
	error,
	onClick,
	previewRange,
	showStartOrEnd,
}) => {
	const { appOrg } = useAppContext();

	const dateTimeString = formatTimeDateString({
		dateFormat: appOrg.regionalSettings.dateFormat
			? appOrg.regionalSettings.dateFormat
			: 'dd/MM/yyyy',
		start: previewRange.start,
		end: previewRange.end,
		whole: previewRange.interval.whole,
	});

	const [errorTooltipOpen, setErrorTooltipOpen] = useState(false);

	useEffect(
		() => {
			if (error) {
				setErrorTooltipOpen(true);

				setTimeout(() => {
					setErrorTooltipOpen(false);
				}, 4000);
			}
		},
		[error]
	);

	const isClickable = +clickable;
	const isError = +error;

	const handleTooltipOpen = useCallback(
		() => {
			if (error) {
				setErrorTooltipOpen(true);
			}
		},
		[error]
	);

	const handleTooltipClose = useCallback(() => {
		setErrorTooltipOpen(false);
	}, []);

	return (
		<Tooltip
			arrow
			disableHoverListener={!error}
			onClose={handleTooltipClose}
			onOpen={handleTooltipOpen}
			open={errorTooltipOpen}
			placement="top"
			title="Invalid range. Start must be before End"
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					width: '100%',
				}}
			>
				<TimeRangesContainer>
					<TimeRangeContainer
						clickable={isClickable}
						error={isError}
						onClick={() => {
							onClick(START);
						}}
						pad_left="24px"
						selected={showStartOrEnd === START}
					>
						<TimeRangeLabel
							clickable={clickable}
							error={error}
							selected={showStartOrEnd === START}
							text={dateTimeString.start}
							title="Start"
						/>
					</TimeRangeContainer>
					<TimeRangeContainer
						clickable={isClickable}
						error={isError}
						separator={1}
						style={{
							alignItems: 'center',
							cursor: 'default',
							display: 'flex',
						}}
					>
						<ArrowRightThin />
					</TimeRangeContainer>
					<TimeRangeContainer
						clickable={
							isClickable && !previewRange.realTime ? 1 : 0
						}
						error={isError}
						pad_left="40px"
						onClick={() => {
							onClick(END);
						}}
						selected={showStartOrEnd === END}
					>
						<TimeRangeLabel
							clickable={clickable}
							error={error}
							icon={previewRange.realTime && <Sensors />}
							selected={showStartOrEnd === END}
							text={
								previewRange.realTime
									? 'Real time updating'
									: dateTimeString.end
							}
							title="End"
						/>
					</TimeRangeContainer>
				</TimeRangesContainer>
			</div>
		</Tooltip>
	);
};

export function TimeRangePicker({
	onClose,
	popperClickAway,
	previewRange,
	setPopperClickAway,
	setPreviewRange,
	setTimeRange,
	timeControlOpen,
	timeRange,
}) {
	const { appOrg } = useAppContext();
	const [currentTab, setCurrentTab] = useState(0);
	const [showStartOrEnd, setShowStartOrEnd] = useState(START);
	const [rangeError, setRangeError] = useState(false);

	const handleRangeClick = useCallback(value => {
		setShowStartOrEnd(value);
	}, []);

	useEffect(
		() => {
			setRangeError(previewRange.start >= previewRange.end);
		},
		[appOrg, previewRange.end, previewRange.realTime, previewRange.start]
	);

	const handleChangeStart = useCallback(
		value => {
			setPreviewRange({
				...previewRange,
				end: previewRange.end,
				start: value,
			});
		},
		[previewRange, setPreviewRange]
	);

	const handleChangeEnd = useCallback(
		value => {
			setPreviewRange({
				...previewRange,
				end: value,
				start: previewRange.start,
			});
		},
		[previewRange, setPreviewRange]
	);

	const onSubmit = useCallback(
		async () => {
			const newTimeRange = await buildTimeRangeObject({
				start: previewRange.start,
				end: previewRange.end,
				absolute: true,
				shift: false,
				realTime: false,
				firstWeekDay: appOrg.regionalSettings.firstWeekDay,
			});

			if (newTimeRange) {
				setTimeRange(newTimeRange);
			}

			onClose();
		},
		[appOrg, onClose, previewRange, setTimeRange]
	);

	// If the date picker tab is selected the time range data is sanitized
	//  - time is rounded to the same resolution as the time picker
	//  - the end is set to the current time
	useEffect(
		() => {
			if (currentTab === 1) {
				const origin = roundToNearestMinutes(new Date(), {
					nearestTo: timePickerUnits.minute_resolution,
				});

				setPreviewRange(prev => ({
					...prev,
					start: subDays(origin, 1).getTime(),
					end: origin,
				}));
			}
		},
		[currentTab, setPreviewRange]
	);

	return (
		<>
			<BtTabBar
				currentTab={currentTab}
				onTabChange={(event, selectedTab) => setCurrentTab(selectedTab)}
			>
				<BtTab label="Common Time Ranges" {...a11yProps(0)} />
				<BtTab label="Relative" {...a11yProps(1)} />
				<BtTab label="Absolute" {...a11yProps(2)} />
			</BtTabBar>

			{/* start -> end preview */}
			<RangePreview
				clickable={currentTab === 2}
				onClick={handleRangeClick}
				showStartOrEnd={showStartOrEnd}
				error={rangeError}
				previewRange={previewRange}
			/>

			{/* common ranges */}
			<BtTabPanel currentTab={currentTab} index={0}>
				<CommonRanges
					onClose={onClose}
					previewRange={previewRange}
					setPreviewRange={setPreviewRange}
					setTimeRange={setTimeRange}
				/>
			</BtTabPanel>

			{/* relative */}
			<BtTabPanel currentTab={currentTab} index={1}>
				<QuickSelect
					onClose={onClose}
					popperClickAway={popperClickAway}
					previewRange={previewRange}
					setPopperClickAway={setPopperClickAway}
					setPreviewRange={setPreviewRange}
					setTimeRange={setTimeRange}
					timeControlOpen={timeControlOpen}
					timeRange={timeRange}
				/>
			</BtTabPanel>

			{/* absolute */}
			<BtTabPanel currentTab={currentTab} index={2}>
				{showStartOrEnd === START ? (
					<BtTimeDateStaticPicker
						onChange={handleChangeStart}
						value={previewRange.start}
					/>
				) : (
					<BtTimeDateStaticPicker
						onChange={handleChangeEnd}
						value={previewRange.end}
					/>
				)}
				<BtPopperExtendedActions style={{ padding: '0 12px 12px' }}>
					<Button
						disabled={rangeError}
						disableElevation
						onClick={() => onSubmit()}
						startIcon={<Check />}
						variant="contained"
					>
						Go
					</Button>
				</BtPopperExtendedActions>
			</BtTabPanel>
		</>
	);
}

TimeRangeLabel.propTypes = {
	clickable: PropTypes.bool,
	error: PropTypes.bool,
	icon: PropTypes.node,
	selected: PropTypes.bool,
	text: PropTypes.string,
	title: PropTypes.string,
};

RangePreview.propTypes = {
	clickable: PropTypes.bool,
	error: PropTypes.bool,
	onClick: PropTypes.func,
	previewRange: PropTypes.object,
	showStartOrEnd: PropTypes.oneOf([END, START]),
};

TimeRangePicker.propTypes = {
	onClose: PropTypes.func.isRequired,
	popperClickAway: PropTypes.bool,
	previewRange: PropTypes.object,
	setPopperClickAway: PropTypes.func,
	setPreviewRange: PropTypes.func,
	setTimeRange: PropTypes.func,
	timeControlOpen: PropTypes.bool,
	timeRange: PropTypes.object,
};

export default TimeRangePicker;
