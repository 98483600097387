const {
	getExpressionType,
	EXPRESSION,
} = require('../../expression/expression');
const { ERROR } = require('../../pipeline/pipeline');
const { deepCopy } = require('../../utils');
const { setStageSchemaFn } = require('../pipeline');
const {
	newDefaultSchema,
	dotGetInSchema,
	getType,
	getArrayOf,
	dotSetInSchema,
} = require('../utils');

const stageKey = '$unwind';

function processUnwind(context, options) {
	const schema = context.schema;

	const path = options.path.slice(1);

	const arr = dotGetInSchema(schema, path);

	if (getType(arr) !== 'array') {
		// unwinding a non array
		return schema;
	}

	const arrayOf = getArrayOf(arr);

	if (getType(arrayOf) === 'array') {
		throw new Error(
			ERROR.INVALID_STAGE_ARGUMENT(
				stageKey,
				'cannot unwind an array within an array, unwind the parent array first'
			)
		);
	}

	const arrType = getType(arrayOf);
	const newSchema = deepCopy(schema);

	if (!arrType || arrType !== 'object') {
		dotSetInSchema(newSchema, newDefaultSchema('object'), path);

		return newSchema;
	}

	dotSetInSchema(newSchema, arrayOf, path);

	if (options.includeArrayIndex) {
		dotSetInSchema(
			newSchema,
			newDefaultSchema('number'),
			options.includeArrayIndex
		);
	}

	return newSchema;
}

setStageSchemaFn(stageKey, (context, args, options) => {
	const expression = getExpressionType(args);

	if (
		expression.type !== EXPRESSION.FIELD_PATH &&
		expression.type !== EXPRESSION.EXPRESSION_OBJECT
	) {
		throw new Error(
			ERROR.INVALID_STAGE_ARGUMENT(
				stageKey,
				'expected a field path or document',
				args
			)
		);
	}

	const arg =
		expression.type === EXPRESSION.FIELD_PATH
			? {
					path: expression.path,
					includeArrayIndex: null,
					preserveNullAndEmptyArrays: null,
			  }
			: {
					path: expression.value.path,
					includeArrayIndex:
						expression.value.includeArrayIndex || null,
					preserveNullAndEmptyArrays:
						expression.value.preserveNullAndEmptyArrays || null,
			  };

	if (
		!Object.hasOwnProperty.call(arg, 'path') ||
		arg.path.length < 2 ||
		arg.path[0] !== '$'
	) {
		throw new Error(
			ERROR.INVALID_STAGE_ARGUMENT(
				stageKey,
				'a field path as the "path" value',
				arg.path
			)
		);
	}

	return processUnwind(context, arg);
});
