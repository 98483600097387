import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Controller, useFormContext } from 'react-hook-form';
import { Skeleton } from '@mui/material';

import useFormElRequired from '../../../hooks/useFormElRequired';
import BtValidationBuilder from '../BtValidationBuilder';

export default function BtFormValidationBuilder({
	disabled,
	fetching,
	isRequired,
	label,
	name,
	valueType,
	...other
}) {
	const {
		control,
		watch,
		loading,
		sending,
		setValue,
		validationSchema,
		formState: { errors },
	} = useFormContext() || { formState: {} };

	const value = watch(name) || '';

	// const handleChange = useCallback(
	// 	newValidationArray => {
	// 		console.log('FORM UPDATE');
	// 		setValue(name, newValidationArray, {
	// 			shouldValidate: true,
	// 			shouldDirty: true,
	// 			shouldTouch: true,
	// 		});
	// 	},
	// 	[setValue, name]
	// );

	if (loading) {
		return (
			<Skeleton animation="wave" style={{ width: '100%', height: 150 }} />
		);
	}

	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<BtValidationBuilder
					title={label}
					valueType={valueType}
					currentValidation={value}
					// onChange={handleChange}
					disabled={loading || sending || disabled || fetching}
					{...field}
					{...other}
				/>
			)}
		/>
	);
}

BtFormValidationBuilder.propTypes = {
	disabled: PropTypes.bool,
	fetching: PropTypes.bool,
	isRequired: PropTypes.bool,
	label: PropTypes.string,
	name: PropTypes.string.isRequired,
	valueType: PropTypes.string.isRequired,
};
