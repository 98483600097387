import React from 'react';
import { Account, Cog, Information } from 'mdi-material-ui';
import { Category, Security } from '@mui/icons-material';
import SubscriptionsIcon from '@mui/icons-material/EventRepeat';

const user_settings_options = [
	{
		id: 'UpdateDetails',
		route: '/UpdateDetails',
		icon: <Account />,
		text: 'Your details',
		description: 'View and change your details',
	},
	{
		id: 'Security',
		route: '/Security',
		icon: <Security />,
		text: 'Security',
		description: 'Change your password',
	},
	{
		id: 'PlatformSettings',
		route: 'PlatformSettings',
		icon: <Cog />,
		text: 'Platform settings',
		description: 'View and change platform settings such as appearance',
	},
	{
		id: 'UserPermissions',
		route: '/UserPermissions',
		icon: <Category />,
		text: 'Permissions',
		description: 'View your permissions',
	},
	{
		id: 'UserSubscriptions',
		route: '/UserSubscriptions',

		icon: <SubscriptionsIcon />,
		text: 'Subscriptions',
		description: 'View your Subscriptions',
	},
	{
		id: 'About',
		route: '/About',
		icon: <Information />,
		text: 'About',
		description: 'View the privacy policy and terms and conditions',
	},
];

export default user_settings_options;
