import React, {
	useCallback,
	useState,
	useMemo,
	useEffect,
	forwardRef,
	useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
	Box,
	Button,
	Card as MuiCard,
	FormControl,
	FormGroup,
	FormHelperText,
	IconButton,
	Tooltip,
	Typography,
} from '@mui/material';
import { useTheme } from '@mui/material';
import AddImageIcon from '@mui/icons-material/AddPhotoAlternate';
import AddFileIcon from '@mui/icons-material/UploadFile';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import FileIcon from '@mui/icons-material/InsertDriveFile';
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import { v4 as uuid } from 'uuid';

import BtDetailSnackbar from './BtDetailSnackbar';

import Resizer from 'react-image-file-resizer';
import BtImageDialog, { ImageSource } from './BtImageDialog';

const Container = styled(MuiCard)(() => ({
	display: 'flex',
	flexDirection: 'column',
	gap: 10,
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: 10,
	padding: '30px 20px',
	border: '1px solid #7773',
}));

const CardContainer = styled('div')(() => ({
	height: '120px',
	width: '120px',
	position: 'relative',
}));

const CardContents = styled('div')(() => ({
	width: '100%',
	height: '100%',
	borderRadius: 20,
	backgroundColor: 'white',
	overflow: 'hidden',
	boxShadow: '0 0 0 1px #7775',
}));

const ImageContainer = styled('div')(({ theme }) => ({
	width: '100%',
	height: '100%',
	borderWidth: 2,
	borderStyle: 'solid',
	borderRadius: 20,
	cursor: 'pointer',
	overflow: 'hidden',
	// backgroundColor: theme.palette.divider,
	// boxShadow: '0 0 0 1px #7775',
	borderColor: theme.palette.divider,
	'&:hover': {
		borderColor: theme.palette.primary.main,
	},
}));

const FileDescriptor = styled('div')(() => ({
	width: '100%',
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',

	'& > svg': {
		fontSize: 60,
		marginBottom: 10,
	},

	'& > p': {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		width: '100%',
		padding: '0 5px',
		boxSizing: 'border-box',
		textAlign: 'center',
	},
}));

const ErrorContainer = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
	gap: 10,
}));

const memoryUnits = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

function Card({ file, index, onRemove, disabled }) {
	const [showImageDialog, setShowImageDialog] = useState(false);
	const [imageSource, setImageSource] = useState(ImageSource.IMAGE_REPO);
	const theme = useTheme();

	// console.log(file);
	return (
		<>
			<CardContainer>
				{file.thumbnail ? (
					<ImageContainer
						onClick={() => {
							console.log('Image Click', file);
							if (file.imageSource === ImageSource.LOCAL) {
								setImageSource(ImageSource.LOCAL);
							}
							setShowImageDialog(true);
						}}
						theme={theme}
					>
						<img
							src={'data:image/png;base64,' + file.thumbnail}
							width={120}
							height={120}
							style={{ objectFit: 'cover' }}
							alt={file.name}
						/>
					</ImageContainer>
				) : (
					<CardContents>
						<FileDescriptor>
							<FileIcon />
							<Typography variant="subtitle2">
								{file.name || 'Unknown File Name'}
							</Typography>
						</FileDescriptor>
					</CardContents>
				)}

				<Tooltip
					title={!disabled ? 'Remove File' : ''}
					disableInteractive
				>
					<span
						style={{
							position: 'absolute',
							top: 0,
							right: 0,
							transform: 'translate(33%, -33%)',
						}}
					>
						<IconButton
							onClick={() => onRemove(index)}
							size="small"
							style={{
								backgroundColor: '#7776',
							}}
							disabled={disabled}
						>
							<CloseIcon />
						</IconButton>
					</span>
				</Tooltip>
			</CardContainer>

			<BtImageDialog
				imageSource={imageSource}
				imageFile={file}
				imageUuid={file.uuid}
				imageRepoUuid={file.imageRepo}
				imageFileName={file.path || file.uuid}
				onClose={() => setShowImageDialog(false)}
				open={showImageDialog}
			/>
		</>
	);
}

export const BtFilePicker = forwardRef(
	(
		{
			acceptedFiles,
			disabled,
			errorText,
			imagesOnly,
			label,
			maxFiles,
			maxFileSize,
			onChange,
			value,
		},
		ref
	) => {
		const { enqueueSnackbar } = useSnackbar();

		const [files, setFiles] = useState([]);

		useImperativeHandle(ref, () => ({
			value: () => files,
			reset: () => {
				if (onChange) {
					onChange([]);
				}
				setFiles([]);
			},
		}));

		const allowMultiple = useMemo(() => maxFiles !== 1, [maxFiles]);
		const fileCount = useMemo(() => (files || []).length, [files]);
		const maxedOut = useMemo(
			() => allowMultiple && fileCount === maxFiles,
			[maxFiles, fileCount, allowMultiple]
		);
		const noun = useMemo(() => (imagesOnly ? 'image' : 'file'), [
			imagesOnly,
		]);

		const resizeFile = file =>
			new Promise(resolve => {
				Resizer.imageFileResizer(
					file,
					100,
					100,
					'JPEG',
					100,
					0,
					uri => {
						resolve(uri);
					},
					'base64'
				);
			});

		const processFileData = useCallback(async file => {
			console.log('processFileData', file);
			var fileWithId = Object.assign(file, {
				id: uuid(),
			});
			// if (fileWithId.type?.startsWith('image')) {
			// 	try {
			// 		const newImage = await resizeFile(file);

			// 		console.log(newImage);
			// 	} catch (error) {
			// 		console.log(error);
			// 	}

			// 	return Object.assign(fileWithId, {
			// 		preview: URL.createObjectURL(fileWithId),
			// 	});
			// }
			// return fileWithId;

			// const newFile = { ...file, id: uuid() };
			console.log('fileWithId', fileWithId);
			console.log(fileWithId.type);
			if (fileWithId.type?.startsWith('image')) {
				try {
					console.log('Create thumbnail');
					const newImage = await resizeFile(file);
					fileWithId.thumbnail = newImage.replace(
						'data:image/jpeg;base64,',
						''
					); // data:image/jpeg;base64,/

					console.log('Thumbnail size', newImage.length);

					fileWithId.attachmentUploaded = false;
					console.log(fileWithId);

					fileWithId.imageSource = ImageSource.LOCAL;

					return fileWithId;
				} catch (error) {
					console.log(error);
				}

				// return Object.assign(newFile, {
				// 	preview: URL.createObjectURL(newFile),
				// });
			}
			return fileWithId;
		}, []);

		useEffect(
			() => {
				if (value) {
					setFiles(value);
				}
			},
			[value]
		);

		const onDrop = useCallback(
			async newFiles => {
				if ((newFiles || []).length === 0) return;

				let filesToAdd = [];

				if (maxFiles > 1 && newFiles.length > maxFiles - fileCount) {
					filesToAdd = [...newFiles.slice(0, maxFiles - fileCount)];
					enqueueSnackbar(
						`You can only select a maximum of ${maxFiles} ${noun}s`,
						{
							variant: 'warning',
						}
					);
				} else {
					filesToAdd = [...newFiles];
				}

				if (!allowMultiple && newFiles.length > 1) {
					enqueueSnackbar(`You can only select a single ${noun}`, {
						variant: 'warning',
					});
				}

				if (maxFiles !== 1) {
					const updatedFileList = [...files];

					for (const file of filesToAdd) {
						console.log('push file');
						updatedFileList.push(await processFileData(file));
					}

					console.log(updatedFileList);
					setFiles(updatedFileList);

					if (onChange) {
						onChange(updatedFileList);
					}

					return updatedFileList;

					// setFiles(prev => {
					// 	const newFiles = [
					// 		...prev,
					// 		// ...filesToAdd.map(file => {
					// 		// 	return processFileData(file);
					// 		// }),
					// 	];
					// 	for(const file of filesToAdd){
					// 		newFiles.push(await processFileData(file))
					// 	}

					// 	if (onChange) {
					// 		onChange(newFiles);
					// 	}
					// 	return newFiles;
					// });
				} else {
					const newFile = [await processFileData(newFiles[0])];
					if (onChange) {
						onChange(newFile);
					}
					setFiles(newFile);
				}
			},
			[
				files,
				maxFiles,
				fileCount,
				allowMultiple,
				processFileData,
				enqueueSnackbar,
				onChange,
				noun,
			]
		);

		const onRemoveFile = useCallback(
			index => {
				let filesTemp = [...files];
				filesTemp.splice(index, 1);
				if (onChange) {
					onChange(filesTemp);
				}
				setFiles(filesTemp);
			},
			[files, onChange]
		);

		const handleErrors = useCallback(
			errors => {
				const errorMessage = `File${
					errors?.length > 1 ? 's' : ''
				} not allowed`;
				enqueueSnackbar(errorMessage, {
					content: (key, message) => (
						<BtDetailSnackbar
							id={key}
							Icon={<ErrorIcon />}
							message={message}
							variant="error"
						>
							<ErrorContainer>
								{errors.map((error, index) => (
									<div key={index}>
										<Typography
											variant="subtitle2"
											style={{ fontWeight: 'bold' }}
										>
											{error.file.name}
										</Typography>
										{error.errors?.map(errorDesc => (
											<Typography
												key={
													errorDesc?.message || uuid()
												}
											>{`• ${
												errorDesc.message
											}`}</Typography>
										))}
									</div>
								))}
							</ErrorContainer>
						</BtDetailSnackbar>
					),
					persist: true,
				});
			},
			[enqueueSnackbar]
		);

		const { getRootProps, getInputProps, isDragActive, open } = useDropzone(
			{
				onDrop,
				noClick: true,
				disabled: disabled,
				accept:
					imagesOnly && !acceptedFiles ? 'image/*' : acceptedFiles,
				onDropRejected: handleErrors,
				maxSize: maxFileSize,
			}
		);

		const niceFileSize = useMemo(
			() => {
				if (!maxFileSize) return;

				let discretion = 0,
					value = parseInt(maxFileSize, 10) || 0;

				while (value >= 1024 && (discretion += 1)) {
					value = value / 1024;
				}

				return `${value.toFixed(
					value < 10 && discretion > 0 ? 1 : 0
				)} ${memoryUnits[discretion]}`;
			},
			[maxFileSize]
		);

		const prompt = useMemo(
			() => {
				const countStr = (() => {
					if (allowMultiple) {
						if (maxFiles === undefined && fileCount === 0)
							return '';
						return (
							`${fileCount}${
								maxFiles !== undefined ? '/' + maxFiles : ''
							}` +
							` selected${fileCount !== maxFiles ? ' • ' : ''}`
						);
					}
					return '';
				})();

				const promptStr = (() => {
					if (!allowMultiple && fileCount === 1)
						return `Drag and drop a replacement ${noun}`;
					if (fileCount === maxFiles) return '';
					return `Drag and drop ${
						allowMultiple && fileCount + 1 !== maxFiles
							? `${fileCount > 0 ? 'more ' : ''}${noun}s`
							: imagesOnly
								? 'an image'
								: 'a file'
					}`;
				})();

				return (
					countStr +
					promptStr +
					(niceFileSize ? ` (max file size: ${niceFileSize})` : '')
				);
			},
			[maxFiles, allowMultiple, fileCount, noun, imagesOnly, niceFileSize]
		);

		const theme = useTheme();

		const PromptIcon = useMemo(
			() => (imagesOnly ? AddImageIcon : AddFileIcon),
			[imagesOnly]
		);

		return (
			<FormControl
				ref={ref}
				required
				error={!!errorText}
				disabled={disabled}
				style={{ width: '100%', marginTop: 10 }}
			>
				{label && (
					<Typography
						color={!!errorText ? 'error' : 'default'}
						style={{ marginBottom: 5 }}
					>
						{label}
					</Typography>
				)}
				<FormGroup>
					<Container
						{...getRootProps()}
						style={{
							cursor: 'default',
							...(!!errorText && {
								borderColor: theme.palette.error.main,
							}),
							...(isDragActive &&
								!maxedOut && {
									borderColor: theme.palette.secondary.main,
								}),
							...(disabled && { borderColor: '#7774' }),
						}}
					>
						{files?.length > 0 && (
							<Box
								style={{
									display: 'flex',
									flexWrap: 'wrap',
									gap: 20,
									width: '100%',
									justifyContent: 'center',
									marginBottom: 10,
								}}
							>
								{files.map((file, index) => (
									<Card
										key={file.id}
										file={file}
										index={index}
										onRemove={onRemoveFile}
										disabled={disabled}
									/>
								))}
							</Box>
						)}
						<input {...getInputProps()} />
						{(files || []).length === 0 && (
							<PromptIcon
								style={{
									fontSize: 60,
									opacity: disabled ? 0.5 : 1,
								}}
								color={
									isDragActive && !maxedOut
										? 'secondary'
										: 'default'
								}
							/>
						)}
						<Typography
							style={{
								textAlign: 'center',
								...(disabled && { color: '#7775' }),
							}}
							variant="subtitle2"
						>
							{isDragActive && !maxedOut
								? `Drop ${
										allowMultiple ? `${noun}s` : noun
								  } here`
								: prompt}
						</Typography>
						{!maxedOut && (
							<Button
								onClick={() => open()}
								variant="outlined"
								style={{ marginTop: 20 }}
								disabled={disabled}
								disableElevation
							>
								{`Choose ${noun}${allowMultiple ? 's' : ''}`}
							</Button>
						)}
					</Container>
					{errorText && <FormHelperText>{errorText}</FormHelperText>}
				</FormGroup>
			</FormControl>
		);
	}
);

BtFilePicker.defaultProps = {
	acceptedFiles: null,
	imagesOnly: false,
	maxFileSize: null,
};

BtFilePicker.propTypes = {
	acceptedFiles: PropTypes.string,
	disabled: PropTypes.bool,
	errorText: PropTypes.string,
	imagesOnly: PropTypes.bool,
	label: PropTypes.string,
	maxFiles: PropTypes.number,
	maxFileSize: PropTypes.number,
	onChange: PropTypes.func,
	value: PropTypes.array,
};

BtFilePicker.displayName = 'FilePicker';

export default BtFilePicker;
