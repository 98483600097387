import {
	endOfMinute,
	endOfHour,
	endOfDay,
	endOfMonth,
	endOfTomorrow,
	endOfWeek,
	endOfYear,
} from 'date-fns';

export const millisPerUnit = {
	seconds: 1e3,
	minutes: 6e4,
	hours: 3.6e6,
	days: 8.64e7,
	weeks: 6.048e8,
	months: 2.628e9,
	years: 3.156e10,
};

export const temporalDeicticFuncs = {
	minute: endOfMinute,
	hour: endOfHour,
	today: endOfDay,
	tommorrow: endOfTomorrow,
	week: endOfWeek,
	month: endOfMonth,
	year: endOfYear,
};

export const DATE = 'date';
export const DATETIME = 'datetime';
export const TIME = 'time';
export const TIME_FORMAT = 'HH:mm';
