import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
	BtFormCheckbox,
	BtFormSelect,
	BtFormTextField,
	withFormContextMethods,
} from '../../../../../../../components/generic/forms';
import { useEditFormContext } from '../../EditFormContext';
import { useWatch } from 'react-hook-form';
import { Box, Typography, useTheme } from '@mui/material';
import BtFormDataConfigNodeSelect from '../../../../../../../components/generic/forms/BtFormDataConfigNodeSelect';
import {
	BAR_CHART,
	GAUGE,
	INFO_CARD,
	LINE_CHART,
	PIE_CHART,
	RADIAL_BAR_CHART,
	STATUS_CARD,
	TABLE,
} from '../../../../../InsightPage/visualisationConstants';
import {
	gaugeSchema,
	infoCardSchema,
	lineChartSchema,
	pieChartSchema,
	radialBarChartSchema,
	statusCardSchema,
	tableSchema,
} from '../../../../../../../API/validations/insightVisualizationsValidation';
import { Col } from 'react-bootstrap';
import { InputWithExtrasBox, OpenInBox } from './generic';
import BtOpenInNew from '../../../../../../../components/generic/BtOpenInNew';
import { useParams } from 'react-router';

const resolveVisOptionsSchema = type => {
	const schemaTable = {
		[BAR_CHART]: lineChartSchema,
		[GAUGE]: gaugeSchema,
		[INFO_CARD]: infoCardSchema,
		[LINE_CHART]: lineChartSchema,
		[PIE_CHART]: pieChartSchema,
		[RADIAL_BAR_CHART]: radialBarChartSchema,
		[STATUS_CARD]: statusCardSchema,
		[TABLE]: tableSchema,
	};
	// console.log(type);
	const schemaDesc = schemaTable[type].describe();
	const visOptionsFields = schemaDesc.fields;
	// console.log({ schemaDesc });
	return visOptionsFields;
};

const DataConfigNodeSelectController = withFormContextMethods(
	({ propKey, name }) => {
		const { configSchemas } = useEditFormContext();
		const dataSource = useWatch({
			name: 'datasource.uuid',
		});
		const transform = useWatch({
			name: 'datasource.transform',
		});
		const configSchema = useMemo(
			() => {
				if (configSchemas && dataSource && transform) {
					return configSchemas[dataSource][transform];
				}
			},
			[configSchemas, dataSource, transform]
		);

		// useEffect(
		// 	() => {
		// 		console.log({
		// 			dataSource,
		// 			transform,
		// 			configSchema,
		// 			configSchemas,
		// 		});
		// 	},
		// 	[configSchema, dataSource, transform, configSchemas]
		// );

		return configSchema ? (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Typography variant="caption">
					{/* Data Origin(optional) */}
					{propKey}
				</Typography>

				<BtFormDataConfigNodeSelect
					configSchema={configSchema}
					name={name}
					// label="Data Origin"
				/>
			</Box>
		) : (
			<Box>
				<Typography variant="caption">
					No config schema available, cannot use
					BtFormDataConfigNodeSelect
				</Typography>
				<BtFormTextField label={propKey} name={name} />
			</Box>
		);
	}
);

const TagLabel = ({ tag, colour }) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				height: '20px',
				// width: '60px',
				borderRadius: '10px',
				backgroundColor: colour,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				paddingRight: '10px',
				paddingLeft: '10px',
			}}
		>
			<Typography
				sx={{
					color: theme.palette.getContrastText(colour),
				}}
				variant="caption"
			>
				{tag}
			</Typography>
		</Box>
	);
};

const ColourTagSelect = withFormContextMethods(({ setValue, label, name }) => {
	const { paletteConfig, collectionPalettes } = useEditFormContext();
	const paletteUuid = useWatch({ name: 'palette.uuid' });
	const currentTag = useWatch({ name: name });
	const { id } = useParams();

	const tags = useMemo(
		() => {
			if (paletteUuid) {
				try {
					const visualisationPalette = collectionPalettes.find(
						p => p.uuid === paletteUuid
					);
					const visualisationPaletteConfig = paletteConfig.find(
						({ uuid }) => uuid === paletteUuid
					);
					let tags = [];

					visualisationPaletteConfig.colours.forEach(colour => {
						colour.tags.forEach(tag => {
							tags.push({
								value: tag,
								label: (
									<TagLabel
										tag={tag}
										colour={
											visualisationPalette.palette.find(
												p => p.uuid === colour.uuid
											).hex
										}
									/>
								),
							});
						});
					});
					return tags;
				} catch (error) {
					return [];
				}
			}
			return [];
		},
		[collectionPalettes, paletteConfig, paletteUuid]
	);

	useEffect(
		() => {
			if (!tags.find(({ value }) => value === currentTag)) {
				console.log('tag not found in tags, setting to null');
				setValue(name, null);
			}
		},
		[currentTag, name, setValue, tags]
	);

	if (!paletteUuid) {
		return (
			<Box>
				<Typography variant="caption">{label}</Typography>
				<Typography>
					{
						<em>
							No palette selected - select a palette in properties
							tab to see the available tags
						</em>
					}
				</Typography>
			</Box>
		);
	}

	if (paletteUuid && !tags.length) {
		return (
			<InputWithExtrasBox>
				<Box>
					<Typography variant="caption">{label}</Typography>
					<Typography>
						{
							<em>
								No tags available - add tags in edit collection
							</em>
						}
					</Typography>
				</Box>{' '}
				<OpenInBox>
					<BtOpenInNew
						buttonProps={{
							color: undefined,
						}}
						name="View and edit collection palettes (opens in new tab)"
						size="medium"
						stopPropagation
						url={`InsightCollection/${id}/Edit/Collection`}
					/>
				</OpenInBox>
			</InputWithExtrasBox>
		);
	}

	return (
		<InputWithExtrasBox>
			<BtFormSelect fullWidth name={name} label={label} items={tags} />
			<OpenInBox>
				<BtOpenInNew
					buttonProps={{
						color: undefined,
					}}
					name="View and edit collection palettes (opens in new tab)"
					size="medium"
					stopPropagation
					url={`InsightCollection/${id}/Edit/Collection`}
				/>
			</OpenInBox>
		</InputWithExtrasBox>
	);
});

const InputController = ({ label, propDetails, name }) => {
	// console.log({ label, propDetails, name });
	if (propDetails.oneOf.length > 0) {
		return (
			<BtFormSelect
				label={label}
				name={name}
				items={propDetails.oneOf.map(option => ({
					label: option,
					value: option,
				}))}
				hideNone={propDetails.oneOf.includes('none')}
			/>
		);
	} else if (propDetails.type === 'boolean') {
		return <BtFormCheckbox label={label} name={name} />;
	} else if (label === 'value') {
		return <DataConfigNodeSelectController name={name} propKey={label} />;
	} else if (label === 'colour_tag') {
		return <ColourTagSelect name={name} label={label} />;
	} else if (propDetails.type === 'number') {
		return <BtFormTextField type="number" label={label} name={name} />;
	} else if (propDetails.type === 'string') {
		return <BtFormTextField label={label} name={name} />;
	}
	console.log('input controller did not return input with props', {
		label,
		propDetails,
		name,
	});
	return null;
};

InputController.propTypes = {
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	propDetails: PropTypes.object.isRequired,
};

ColourTagSelect.propTypes = {
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
};

TagLabel.propTypes = {
	colour: PropTypes.string.isRequired,
	tag: PropTypes.string.isRequired,
};

export { InputController, resolveVisOptionsSchema };
