import { apiClient } from '../api_client';

import {
	dataSetStoreGetSchema,
	dataSetStoreInsertResponseSchema,
} from '../validations/dataSetStoreValidation';

//========================================================================================================
export async function dataSetStoreGet({ dataSetUuid, offset, limit }) {
	const req_url = `data_set_store/${dataSetUuid}`;
	try {
		var params = {};
		if (offset) {
			params.offset = offset;
		}
		if (limit) {
			params.limit = limit;
		}

		const response = await apiClient({
			method: 'get',
			url: req_url,
			params: params,
		});
		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				return await dataSetStoreGetSchema.validate(response.data);
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
export async function dataSetStoreQueryGet({
	dataSetUuid,
	sourceList,
	timeRange,
	primaryKey,
	query,
}) {
	// TODO: Add scheam validataion for dataSetUuid and sourceList
	var req_url = '';
	if (dataSetUuid) {
		req_url = `data_set_store/${dataSetUuid}/query`;
	} else if (sourceList) {
		req_url = `data_set_store/query`;
	} else {
		throw new Error('param error');
	}

	// TODO: Add validation for the query

	var requestBody = { ...query };

	if (timeRange) {
		if (timeRange.start || timeRange.end) {
			requestBody.timeRange = {
				start: timeRange.start,
				end: timeRange.end,
			};
		} else if (timeRange.start) {
			requestBody.timeRange = {
				start: timeRange.start,
			};
		} else if (timeRange.end) {
			requestBody.timeRange = {
				end: timeRange.end,
			};
		}
	}

	if (primaryKey) {
		requestBody.primaryKey = primaryKey;
	}

	// If we have been provided a source list then add to the body
	if (sourceList) {
		requestBody.datasets = [...sourceList];
	}

	try {
		const response = await apiClient({
			method: 'post',
			url: req_url,
			body: requestBody,
		});

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				// TODO: How can we validate this
				//return await dataSetStoreGetSchema.validate(response.data);
				return response.data;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
export async function dataSetStoreInsert({ dataSetUuid, newRecord }) {
	const req_url = `data_set_store/${dataSetUuid}`;
	try {
		const response = await apiClient({
			method: 'post',
			url: req_url,
			body: newRecord,
		});

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				// TODO - Action validation
				// return await dataSetStoreInsertResponseSchema.validate(
				// 	response.data
				// );
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
export async function dataSetStoreUpdate({
	dataSetUuid,
	recordUuid,
	updatedRecord,
}) {
	const req_url = `data_set_store/${dataSetUuid}/${recordUuid}`;

	try {
		const response = await apiClient({
			method: 'put',
			url: req_url,
			body: updatedRecord,
		});

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				// TODO - Action validation
				// return await dataSetStoreInsertResponseSchema.validate(
				// 	response.data
				// );
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
export async function dataSetStoreDelete({ dataSetUuid, recordUuid }) {
	const req_url = `data_set_store/${dataSetUuid}/${recordUuid}`;

	try {
		const response = await apiClient({
			method: 'delete',
			url: req_url,
		});

		if (response) {
			if (response.result === 'OK') {
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
export async function dataSetStoreUpload({
	dataSetUuid,
	csvFile,
	uploadMode,
	replace = false,
}) {
	var req_url = `data_set_store/${dataSetUuid}/upload/add`;
	if (replace) {
		req_url = `data_set_store/${dataSetUuid}/upload/replace`;
	}
	console.log(csvFile);
	try {
		var formData = new FormData();
		formData.append('csvFile', csvFile);
		formData.append('uploadMode', uploadMode);

		const response = await apiClient({
			method: 'post',
			url: req_url,
			body: formData,
			headers: { 'Content-Type': 'multipart/form-data' },
		});

		if (response) {
			if (response.result !== 'OK') {
				throw new Error('Server Error');
			} else {
				return response.newRecordCount;
			}
		}
	} catch (error) {
		throw error;
	}
}
