const {
	ERROR,
	setOperator,
	evaluateExpression,
	OPERATION_MODE,
} = require('../expression.js');

const operatorKey = '$concat';

setOperator(
	operatorKey,
	/**
	 * @type {ExpressionFunction<string | string[], string>}
	 */
	(context, args, options) => {
		const arr = !Array.isArray(args) ? [args] : args;

		let str = '';
		let isNull = false;

		for (let pos = 0; pos < arr.length; pos++) {
			const arg = arr[pos];

			/**
			 * @type {any}
			 */
			let value = evaluateExpression(context, arg);

			if (typeof value !== 'string') {
				if (value === undefined || value === null) {
					isNull = true;
					continue;
				}

				throw new Error(
					ERROR.INVALID_OPERATOR_ARGUMENT(
						operatorKey,
						'[string]',
						value,
						pos
					)
				);
			}

			if (!isNull) {
				str += value;
			}
		}

		if (isNull) {
			return null;
		}

		return str;
	},
	[OPERATION_MODE.AGGREGATE]
);
