import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@mui/material';

import { BtListButton } from '../../../BtListButton';
import {
	BtPopperExtendedContent,
	BtPopperExtendedSubTitle,
} from '../../../BtPopperContent';
import { buildTimeRangeObject } from './utils/timeRangeFunctions';
import { commonRangesData } from './utils/timeRangeConstants';
import { useAppContext } from '../../../../../context/ContextManager';

export default function CommonRanges({
	onClose,
	previewRange,
	setPreviewRange,
	setTimeRange,
}) {
	const { appOrg } = useAppContext();

	const handleClick = async function(event) {
		// Get the range parameters required for BuildTimeRange
		const timeRangeObjectParams = commonRangesData.find(
			({ id }) => id === event.target.id
		);

		// Generate the time range data object and pass it to the dataProvider
		const newTimeRange = await buildTimeRangeObject({
			...timeRangeObjectParams,
			rangeType: 'relative',
			firstWeekDay: appOrg.regionalSettings.firstWeekDay,
		});

		if (newTimeRange) {
			setTimeRange(newTimeRange);
		}

		onClose();
	};

	const handleMouseOver = async function(event) {
		// Get the range parameters required for BuildTimeRange
		const timeRangeObjectParams = commonRangesData.find(
			({ id }) => id === event.target.id
		);

		// Generate the preview time range data object and pass it to the preview
		const newPreviewRange = await buildTimeRangeObject({
			...timeRangeObjectParams,
			firstWeekDay: appOrg.regionalSettings.firstWeekDay,
			rangeType: 'relative',
		});

		setPreviewRange({
			...newPreviewRange,
			end: newPreviewRange.end,
			interval: {
				...previewRange.interval,
				whole: timeRangeObjectParams.whole,
			},
			start: newPreviewRange.start,
		});
	};

	return (
		<BtPopperExtendedContent>
			<BtPopperExtendedSubTitle subtitle="Commonly used" />
			<Grid container spacing={0.5}>
				{commonRangesData.map((range, index) => (
					<Grid key={index} item xs={6}>
						<BtListButton
							key={range.id}
							id={range.id}
							label={range.label}
							onClick={event => handleClick(event)}
							onMouseOver={event => handleMouseOver(event)}
						/>
					</Grid>
				))}
			</Grid>
		</BtPopperExtendedContent>
	);
}

CommonRanges.propTypes = {
	onClose: PropTypes.func.isRequired,
	previewRange: PropTypes.object,
	setPreviewRange: PropTypes.func.isRequired,
	setTimeRange: PropTypes.func.isRequired,
};
