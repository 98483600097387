import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { ORGANISATION, PLATFORM } from './utils/constants';
import { PaletteManager } from './PaletteManager';
import BtLoading from '../generic/BtLoading';
import { orgDetailsGet, orgDetailsPut } from '../../API/orgDetails';
import BtError from '../generic/BtError';

/**
 * @param editing - one of 'organisation' or 'platform'
 */
const BtPaletteManager = memo(function BtPaletteManager({ editing }) {
	const [showArchived, setShowArchived] = useState(false);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	const [theme, setTheme] = useState(null);

	const getOrgTheme = useCallback(async () => {
		// setActivityIndicator(true);
		let orgDetails;
		try {
			orgDetails = await orgDetailsGet();

			return orgDetails.theme;
		} catch (error) {
			console.error(error);
			setError(true);
		}
	}, []);

	const initComponent = useCallback(
		async () => {
			if (editing === ORGANISATION) {
				const orgTheme = await getOrgTheme();
				setTheme(orgTheme);
				setLoading(false);
			}
		},
		[editing, getOrgTheme]
	);

	useEffect(
		() => {
			initComponent();
		},
		[initComponent]
	);

	const putOrgPalettes = useCallback(
		async paletteUpdate => {
			try {
				// clone the theme and update the palettes
				const newOrgTheme = { ...theme };
				newOrgTheme.palettes = paletteUpdate;
				// update the back end
				await orgDetailsPut({ theme: newOrgTheme });
				// reinitialise the component, to make sure we are up to date with the back end
				initComponent();
			} catch (error) {
				console.error(error);
				setError(true);
			}
		},
		[initComponent, theme]
	);

	const onChange = useMemo(
		() => {
			switch (editing) {
				case ORGANISATION:
					return putOrgPalettes;
				case PLATFORM:
					console.log('no updater defined for platform palettes');
					break;
				default:
					return [];
			}
		},
		[editing, putOrgPalettes]
	);

	if (error) return <BtError />;

	return (
		<>
			<BtLoading loading={loading}>
				{theme && (
					<PaletteManager
						editing={editing}
						palettes={theme.palettes}
						onChangePalettes={onChange}
						setShowArchived={setShowArchived}
						showArchived={showArchived}
					/>
				)}
			</BtLoading>
		</>
	);
});

BtPaletteManager.propTypes = {
	editing: PropTypes.oneOf([ORGANISATION, PLATFORM]),
};

BtPaletteManager.displayName = 'BtPaletteManager';

export { BtPaletteManager };
