import { Box } from '@mui/material';
import React, { memo } from 'react';
import PropTypes from 'prop-types';

const TabPanel = memo(function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tab-panel"
			hidden={value !== index}
			id={`simple-tab-panel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box
				// sx={{ p: 3 }}
				>
					{children}
				</Box>
			)}
		</div>
	);
});

const TabPanels = memo(function TabPanels({ tabs, value }) {
	return tabs.map((tab, index) => (
		<TabPanel value={value} key={index} index={tab.value}>
			{tab.content}
		</TabPanel>
	));
});

const tabShape = PropTypes.shape({
	label: PropTypes.string.isRequired,
	content: PropTypes.node.isRequired,
	icon: PropTypes.node,
	value: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
});

TabPanel.propTypes = {
	children: PropTypes.element.isRequired,
	value: PropTypes.string.isRequired,
	index: PropTypes.string.isRequired,
};

TabPanels.propTypes = {
	tabs: PropTypes.arrayOf(tabShape).isRequired,
	value: PropTypes.string.isRequired,
};

TabPanel.displayName = 'TabPanel';
TabPanels.displayName = 'TabPanels';

export { TabPanel, TabPanels };
