import { apiClient } from '../api_client';
import {
	deviceGetListSchema,
	deviceGetSchema,
} from '../validations/deviceValidation';
import { deviceTypeGetList } from './device_types.api';
import { msTimeToStr } from '../../utils/timeUtils';

import _ from 'lodash';

//========================================================================================================
async function devicesGetList() {
	const req_url = 'devices';
	try {
		let device_types = await deviceTypeGetList();
		const response = await apiClient({ method: 'get', url: req_url });

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let devices = await deviceGetListSchema.validate(
					response.devices
				);
				console.log(devices);
				// Convert and flatten data for easier use by the front end
				devices = devices.map(device => {
					try {
						let devInfo = {
							uid: device.uid,
							name: device.name,
							type: _.find(device_types, ['uuid', device.type])
								.device_code,
							description: device.description,
						};

						if (device.stats === null) {
							devInfo = {
								...devInfo,
								status: '-',
								rssi: '-',
								rtt: '-',
								pkt_drop: '-',
								con_count: '-',
								boot_str: '-',
								uptime: '-',
							};
						} else {
							devInfo = {
								...devInfo,
								status: device.stats.connected ? 'ONLINE' : '-',
								rssi: device.stats.stats.rssi
									? device.stats.stats.rssi + ' dbm'
									: '-',
								rtt: device.stats.stats.rtt
									? device.stats.stats.rtt + ' ms'
									: '-',
								pkt_drop: device.stats.stats.pkt_drop
									? device.stats.stats.pkt_drop
									: '-',
								con_count: device.stats.stats.con_count
									? device.stats.stats.con_count
									: '-',
								boot_str: device.stats.stats.boot_str
									? device.stats.stats.boot_str
									: '-',
								uptime: device.stats.stats.uptime
									? msTimeToStr(device.stats.stats.uptime)
									: '-',
							};
						}
						return devInfo;
					} catch (error) {}
					/*return {
						uid: device.uid,
						name: device.name,
						type: _.find(device_types, ['uuid', device.type])
							.device_code,
						description: device.description,
						status: device.stats.connected ? 'ONLINE' : '-',
						rssi: device.stats.stats.rssi
							? device.stats.stats.rssi + ' dbm'
							: '-',
						rtt: device.stats.stats.rtt
							? device.stats.stats.rtt + ' ms'
							: '-',
						pkt_drop: device.stats.stats.pkt_drop
							? device.stats.stats.pkt_drop
							: '-',
						con_count: device.stats.stats.con_count
							? device.stats.stats.con_count
							: '-',
						boot_str: device.stats.stats.boot_str
							? device.stats.stats.boot_str
							: '-',
						uptime: device.stats.stats.uptime
							? msTimeToStr(device.stats.stats.uptime)
							: '-',
					};*/
				});
				return devices;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function deviceGet({ deviceId }) {
	try {
		const req_url = 'device/' + deviceId;
		console.log(req_url);
		const response = await apiClient({ method: 'get', url: req_url });
		console.log(response);
		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let device = await deviceGetSchema.validate(response.device);
				return device;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

export { devicesGetList, deviceGet };
