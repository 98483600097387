import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BtTabs } from '../../../../../../components/generic/tabs/BtTabs';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { InsightComponentContainer } from '../../../UiComponents/InsightComponentContainer';
import { withFormContextMethods } from '../../../../../../components/generic/forms';
import { useEditFormContext } from '../EditFormContext';
import { useWatch } from 'react-hook-form';
import { VisOptionsFormSectionWrapper } from './formComponents/VisOptionsFormSectionWrapper';

const VisualisationContainer = styled(Box)(({ theme }) => ({
	overflow: 'auto',
	padding: '10px',
	backgroundColor: theme.palette.background.insights,
	height: `calc(70vh - 201px)`,
	width: '100%',
}));

const DefinitionContainer = styled(Box)(() => ({
	height: `calc(90vh - 201px)`,
	padding: '10px',
	overflow: 'auto',
	width: '100%',
}));

const PreviewTabs = ({
	dataSources,
	liveComponent,
	palette,
	dataSample,
	setDataSample,
	paletteConfig,
	collectionPalettes,
}) => {
	// useEffect(
	// 	() => {
	// 		console.log({ liveComponent });
	// 	},
	// 	[liveComponent]
	// );
	const previewTabs = [
		// {
		// 	label: 'Visualisation',
		// 	content: (
		// 		<VisualisationContainer>
		// 			<InsightComponentContainer
		// 				component={liveComponent}
		// 				editEnabled={false}
		// 				palette={palette}
		// 				setDataSample={setDataSample}
		// 			/>
		// 		</VisualisationContainer>
		// 	),
		// },
		{
			label: 'Visualisation',
			content: (
				<DefinitionContainer>
					<VisualisationContainer>
						<InsightComponentContainer
							component={liveComponent}
							editEnabled={false}
							palette={palette}
							setDataSample={setDataSample}
							paletteConfig={paletteConfig}
							collectionPalettes={collectionPalettes}
						/>
					</VisualisationContainer>
					<VisOptionsFormSectionWrapper
						initialOpenState={false}
						title={'Data Sample'}
					>
						<pre>{JSON.stringify(dataSample, null, 5)}</pre>
					</VisOptionsFormSectionWrapper>
				</DefinitionContainer>
			),
		},
		{
			label: 'Visualisation Definition',
			content: (
				<DefinitionContainer>
					<pre>{JSON.stringify(liveComponent, null, 5)}</pre>
				</DefinitionContainer>
			),
		},
		{
			label: 'Collection Data Sources',
			content: (
				<DefinitionContainer>
					<pre>{JSON.stringify(dataSources, null, 5)}</pre>
				</DefinitionContainer>
			),
		},
		// {
		// 	label: 'Data Sample',
		// 	content: (
		// 		<DefinitionContainer>
		// 			<pre>{JSON.stringify(dataSample, null, 5)}</pre>
		// 		</DefinitionContainer>
		// 	),
		// },
	];

	return <BtTabs top tabs={previewTabs} />;
};

const ComponentPreview = () => {
	const {
		palette,
		dataSources,
		dataSample,
		setDataSample,
		paletteConfig,
		collectionPalettes,
	} = useEditFormContext();

	const liveComponent = useWatch();

	// useEffect(() => console.log({ liveComponent }), [liveComponent]);
	return (
		<PreviewTabs
			palette={palette}
			dataSources={dataSources}
			liveComponent={liveComponent}
			dataSample={dataSample}
			setDataSample={setDataSample}
			paletteConfig={paletteConfig}
			collectionPalettes={collectionPalettes}
		/>
	);
};

ComponentPreview.displayName = 'ComponentPreview';

PreviewTabs.propTypes = {
	dataSources: PropTypes.array.isRequired,
	liveComponent: PropTypes.object.isRequired,
	palette: PropTypes.array.isRequired,
};

export { ComponentPreview };
