import React, { useState } from 'react';

import { Grid, AppBar, Toolbar } from '@mui/material';

import { lightTheme } from '../../../style/muiTheme';

import TermsDialog from './TermsDialog';
import PrivacyDialog from './PrivacyDialog';

import {
	FooterTypography,
	FooterTypographyBarSpacer,
} from './FooterTypography';

export default function Footer() {
	const [footerTermsDialogOpen, setFooterTermsDialogOpen] = useState(false);
	const [footerPrivacyDialogOpen, setFooterPrivacyDialogOpen] = useState(
		false
	);

	function handleClickFooterTermsLink() {
		setFooterTermsDialogOpen(true);
	}
	function handleClickFooterPrivacyLink() {
		setFooterPrivacyDialogOpen(true);
	}

	/*
    Callbacks passed to dialog components to control its open state
    */
	function closeFooterTermsDialog() {
		setFooterTermsDialogOpen(false);
	}
	function closeFooterPrivacyDialog() {
		setFooterPrivacyDialogOpen(false);
	}

	return (
		<AppBar
			position="fixed"
			elevation={0}
			sx={{
				backgroundColor: '#ffffff',
				color: `${lightTheme.text}`,
				top: 'auto',
				bottom: 0,
				maxWidth: '100vm',
				height: '65px',
			}}
		>
			<Toolbar>
				<Grid
					container
					spacing={0}
					direction="column-reverse"
					alignItems="center"
					justifyContent="flex-end"
				>
					<Grid item xs={12}>
						<FooterTypography
							onClick={() => handleClickFooterTermsLink()}
							text={'Terms of Use'}
						/>
						<FooterTypographyBarSpacer />
						<FooterTypography
							onClick={() => handleClickFooterPrivacyLink()}
							text={'Privacy'}
						/>
						<FooterTypographyBarSpacer />
						<FooterTypography
							text={
								'Help Center' // onClick={() => handleClickTermsLink()}
							}
						/>
					</Grid>
				</Grid>
			</Toolbar>
			<TermsDialog
				onClose={closeFooterTermsDialog}
				open={footerTermsDialogOpen}
			/>
			<PrivacyDialog
				onClose={closeFooterPrivacyDialog}
				open={footerPrivacyDialogOpen}
			/>
		</AppBar>
	);
}
