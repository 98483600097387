import React, { useEffect, useState, useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Auth } from 'aws-amplify';
import { useAppContext } from '../../context/ContextManager';

import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import Chart from '../generic/Chart';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
//import { loadingLogo } from "aws-amplify";

import moment from 'moment';

const styles = makeStyles(theme => ({
	button_row: {},
	root: {
		width: '100%',
		minWidth: 200,
	},
	config_paper: {
		padding: 10,
	},
	tableHeader: {
		fontWeight: 700,
	},
	tableRow: {
		cursor: 'pointer',
		borderBottom: '1px solid red',
		borderBottomColor: theme.palette.primary.shade_dark,
		'&:hover': {
			backgroundColor: theme.palette.primary.shade_light,
		},
	},
}));

export default function DeviceStats({ dev_id }) {
	const classes = styles();
	const [devStats, setDevStats] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [chartData, setChartData] = useState([]);
	const [chartParams, setChartParams] = useState([]);
	const [lastCon, setLastCon] = useState('');
	const [lastComms, setLastComms] = useState('');
	const [deviceLogs, setDeviceLogs] = useState([]);
	const { appOrg } = useAppContext();

	const loadStats = useCallback(
		async () => {
			var req_url =
				process.env.REACT_APP_API_SERVER_URL +
				`/v1/device_stats/` +
				dev_id;

			Auth.currentSession().then(user_session => {
				let user_token = user_session.getAccessToken();

				fetch(req_url, {
					method: 'GET',
					headers: {
						Authorization: user_token.jwtToken,
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Organisation: appOrg.uuid,
					},
				})
					.then(res => res.json())
					.then(response => {
						setLastCon(response.last_con);
						setLastComms(response.last_comms);
						setDevStats(response.device_stats);

						//console.log(response);

						var new_stats = response.device_stats.map(stats => {
							return {
								time: moment(stats.time_bucket).valueOf(),
								packets_rx: stats.packets_rx,
								packets_tx: stats.packets_tx,
								bytes_rx: stats.bytes_rx,
								bytes_tx: stats.bytes_tx,
								con_event: stats.con_event,
							};
						});
						setChartData(new_stats);
						setIsLoading(false);
					})
					.catch(error => console.log(error));
			});
		},
		[dev_id, appOrg]
	);

	const loadLog = useCallback(
		async () => {
			var req_url =
				process.env.REACT_APP_API_SERVER_URL +
				`/v1/device_log/` +
				dev_id;

			Auth.currentSession().then(user_session => {
				let user_token = user_session.getAccessToken();

				fetch(req_url, {
					method: 'GET',
					headers: {
						Authorization: user_token.jwtToken,
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Organisation: appOrg.uuid,
					},
				})
					.then(res => res.json())
					.then(response => {
						setDeviceLogs(response.log);
					})
					.catch(error => console.log(error));
			});
		},
		[dev_id, appOrg]
	);

	useEffect(
		() => {
			setChartParams([
				'packets_rx',
				'packets_tx',
				'bytes_rx',
				'bytes_tx',
				'con_event',
			]);

			loadStats();
			loadLog();

			const timer = setInterval(() => {
				loadStats();
				loadLog();
			}, 5000);
			return () => clearInterval(timer);
		},
		[loadStats, loadLog]
	);

	return (
		<>
			{isLoading && (
				<Paper>
					<p>Loading...</p>
					<CircularProgress size={'120px'} />
				</Paper>
			)}

			{!isLoading && (
				<>
					<Typography variant="h6">
						Last Connect Time: {new Date(lastCon).toLocaleString()}
					</Typography>

					<Typography variant="h6">
						Last Communication Time:{' '}
						{new Date(lastComms).toLocaleString()}
					</Typography>

					<TableContainer>
						<Table
							className={classes.table}
							size="small"
							aria-label="a dense table"
						>
							<TableHead>
								<TableRow>
									<TableCell className={classes.tableHeader}>
										Time
									</TableCell>
									<TableCell className={classes.tableHeader}>
										Connection Events
									</TableCell>
									<TableCell className={classes.tableHeader}>
										Packets RX
									</TableCell>
									<TableCell className={classes.tableHeader}>
										Packets TX
									</TableCell>
									<TableCell className={classes.tableHeader}>
										Bytes RX
									</TableCell>
									<TableCell className={classes.tableHeader}>
										Bytes TX
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{devStats.map((stats, index) => (
									<TableRow
										key={index}
										title={stats.time_bucket}
									>
										<TableCell>
											{new Date(
												stats.time_bucket
											).toLocaleString()}
										</TableCell>
										<TableCell>{stats.con_event}</TableCell>
										<TableCell>
											{stats.packets_rx}
										</TableCell>
										<TableCell>
											{stats.packets_tx}
										</TableCell>
										<TableCell>{stats.bytes_rx}</TableCell>
										<TableCell>{stats.bytes_tx}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>

					<div style={{ height: '800px' }}>
						<Chart
							data={chartData}
							param_names={chartParams}
							xaxis_name="time"
						/>
					</div>

					<TableContainer>
						<Table
							className={classes.table}
							size="small"
							aria-label="a dense table"
						>
							<TableHead>
								<TableRow>
									<TableCell className={classes.tableHeader}>
										Time
									</TableCell>
									<TableCell className={classes.tableHeader}>
										Event Code
									</TableCell>
									<TableCell className={classes.tableHeader}>
										Event Message
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{deviceLogs.map((log, index) => (
									<TableRow key={index} title={log.timestamp}>
										<TableCell>
											{new Date(
												log.timestamp
											).toLocaleString()}
										</TableCell>
										<TableCell>{log.event_code}</TableCell>
										<TableCell>{log.message}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</>
			)}
		</>
	);
}
