import React, {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useMemo,
	useState,
} from 'react';
import PropTypes from 'prop-types';

import Color from 'color';
import { InputBase, styled, Typography } from '@mui/material';
import _ from 'lodash';

import DataSetErrorTooltip from './DataSetErrorTooltip';
import { NUMBER } from '../../../../../utils/commonDataTypes';
import { encodeSchema, transformAll } from '../../../../../utils/yup-ast';

const EditButton = styled('button')(({ haserror, theme }) => ({
	border: 'none',
	borderRadius: 6,
	backgroundColor: Color(theme.palette.primary.main)
		.fade(0.9)
		.toString(),
	boxShadow: haserror
		? `0 0 0 1px ${theme.palette.indicators.error.text}, inset 0 0 2px ${
				theme.palette.indicators.error.main
		  }`
		: 'none',
	margin: '0 0 0 -4px',
	minWidth: 24,
	overflow: 'hidden',
	padding: '0 4px',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',

	'&:hover': {
		backgroundColor: Color(theme.palette.primary.main)
			.fade(0.8)
			.toString(),
	},
}));

const Input = styled(InputBase)(({ haserror, theme }) => ({
	backgroundColor: Color(theme.palette.text.solid)
		.fade(0.92)
		.toString(),
	borderRadius: 6,
	boxShadow: haserror
		? `0 0 0 1px ${theme.palette.indicators.error.text}, inset 0 0 2px ${
				theme.palette.indicators.error.main
		  }`
		: 'none',
	height: 22.5,
	marginLeft: -4,
	padding: '0 4px',
}));

export const DataSetFieldEditor = forwardRef(
	(
		{
			defaultValue,
			disabled,
			inputProps,
			onChange,
			schema,
			setFocused,
			value,
		},
		ref
	) => {
		const [editing, setEditing] = useState(false);
		const [initialValue, setInitialValue] = useState();
		const [_value, setValue] = useState(value ?? defaultValue ?? false);

		const error = useMemo(
			() => {
				if (!schema) return null;

				try {
					transformAll(encodeSchema(schema)).validateSync(_value);

					return null;
				} catch (validationError) {
					return validationError.message;
				}
			},
			[schema, _value]
		);

		const handleValueUpdate = useCallback(
			newValue => {
				setValue(newValue);
				onChange?.(null, newValue);
			},
			[onChange]
		);

		const handleChange = useCallback(
			event => {
				let newValue = event.target.value;

				const finalVal =
					inputProps?.type === NUMBER ? +newValue : newValue;

				if (finalVal !== _value) {
					handleValueUpdate(finalVal);
				}
			},
			[handleValueUpdate, inputProps, _value]
		);

		const handleKeyDown = useCallback(
			event => {
				const blur = () => {
					event.target.blur();
					setFocused(false);
				};

				if (event.key === 'Enter') {
					blur();
				}

				if (event.key === 'Escape') {
					setValue(initialValue);
					onChange?.(event, initialValue);

					blur();
				}
			},
			[initialValue, onChange, setFocused]
		);

		const initialiseEdit = useCallback(
			() => {
				setInitialValue(_value);
				setEditing(true);
				setFocused(true);
			},
			[setFocused, _value]
		);

		useImperativeHandle(ref, () => ({
			focus: () => {
				initialiseEdit();
			},
		}));

		useEffect(
			() => {
				if (!_.isNil(value) && value !== _value) {
					setValue(
						inputProps.type === NUMBER ? value.toString() : value
					);
				}
			},
			[inputProps, value, _value]
		);

		return (
			<DataSetErrorTooltip
				open={!!error}
				title={error ?? 'Error'}
				verticalOffset={-5}
			>
				<div ref={ref}>
					{editing && (
						<Input
							autoFocus
							defaultValue={_value}
							haserror={+!!error}
							onBlur={() => {
								setEditing(false);
								setFocused(false);
							}}
							onChange={handleChange}
							onKeyDown={handleKeyDown}
							{...inputProps}
						/>
					)}
					{!editing && (
						<EditButton
							disabled={disabled}
							haserror={+!!error}
							onClick={initialiseEdit}
							title={_value}
						>
							<span>
								{_.isNil(_value) || _value === '' ? (
									<Typography
										style={{
											fontStyle: 'italic',
											opacity: 0.7,
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
										}}
									>
										Blank
									</Typography>
								) : (
									<Typography>{_value}</Typography>
								)}
							</span>
						</EditButton>
					)}
				</div>
			</DataSetErrorTooltip>
		);
	}
);

const valuePropType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

DataSetFieldEditor.propTypes = {
	defaultValue: valuePropType,
	disabled: PropTypes.bool,
	inputProps: PropTypes.object,
	onChange: PropTypes.func.isRequired,
	schema: PropTypes.object,
	setFocused: PropTypes.func.isRequired,
	value: valuePropType,
};

DataSetFieldEditor.displayName = 'DataSetFieldEditor';

export default DataSetFieldEditor;
