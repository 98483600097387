/* eslint no-useless-escape: 0 */

import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useNavContext, useAppContext } from '../../context/ContextManager';
import { v1 as uuidv1 } from 'uuid';
import _ from 'lodash';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/SaveAlt';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import {
	ListTable,
	ListBody,
	ListRow,
	ListHeader,
	ListCell,
} from '../../components/generic/ListTable';

import {
	SelectionTable,
	SelectionTableContainer,
	SelectionTableHead,
	SelectionTableHeadRow,
	SelectionTableBody,
	SelectionTableRow,
	SelectionTableCell,
} from '../../components/generic/BtSelectionTable';
import CustomSnackbar from '../../components/generic/CustomSnackbar';
import SectionHeader from '../../components/generic/SectionHeader';

import ContainerTaskEditDialog from '../../components/dialogs/ContainerTaskEditDialog';

// Data for landing page selection table and contextual navigation
import IoT_options from './IoTOptions';

import IoT_breadcrumbs from './IoTBreadcrumbs';

import IoT_nav_item from './IoTNavItem';

export default function ContainerTaskNew({ match }) {
	const [task, setTask] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const { appOrg } = useAppContext();
	const { setContextualNav } = useNavContext();
	const { setBreadcrumbs } = useNavContext();

	// Snackbar control values
	const [errorOpen, setErrorOpen] = useState(false);
	const onErrorClose = () => {
		setErrorOpen(false);
	};
	const [snackMessage, setSnackMessage] = useState('');

	// Edit Container Dialog values
	const [editContainerOpen, setEditContainerOpen] = useState(false);
	const [containerEdit, setContainerEdit] = useState({});

	// Form values
	const [name, setName] = useState('');
	const [nameError, setNameError] = useState(false);
	const [nameHelper, setNameHelper] = useState('');

	const [description, setDescription] = useState('');

	const [volumes, setVolumes] = useState([]);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([...IoT_nav_item, ...IoT_options]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	const task_uuid = match.params.uuid;
	const task_revision = match.params.revision;
	const task_name = name;

	const onContainerClick = container_name => () => {
		let container = _.filter(task.containers, { name: container_name })[0];
		if (container === undefined) {
			setSnackMessage('Error opening container edit');
			setErrorOpen(true);
		} else {
			setContainerEdit(container);
			setEditContainerOpen(true);
		}
	};

	const handleAddContainer = () => {
		// Add a container to the task
		let new_container = {
			name: 'not_set',
			image: 'not_set',
			registry_authentication: null,
			memory_soft_limit: 128,
			command: null,
			links: [],
			environment: [],
			mount_points: [],
			port_mappings: [],
			docker_config: [],
		};
		setTask({ ...task, containers: [...task.containers, new_container] });

		onContainerClick('not_set');
	};

	const handleRemoveContainer = name => event => {
		event.stopPropagation();
		event.preventDefault();

		// Remove the/any container which matches the name
		setTask({
			...task,
			containers: task.containers.filter(
				container => container.name !== name
			),
		});
	};

	const onEditContainerClose = () => {
		console.log('Save container changes!!!');
		console.log(containerEdit);
		setEditContainerOpen(false);
	};

	const validateForm = () => {
		var valid = true;

		// Validate the task name
		const nameRegExp = /^([A-Z]|[a-z]|[0-9]|[-_]){4,40}$/; // Allowed chars: a-z, A-Z, 0-9, -, _ must be length 4-40
		if (name === '') {
			setNameError(true);
			setNameHelper('Name value is required');
			valid = false;
		} else if (!nameRegExp.test(name)) {
			setNameError(true);
			setNameHelper(
				'Name value must contain olny a-z, A-Z, 0-9, -, _ and be between 4 and 40 characters'
			);
			valid = false;
		} else {
			setNameError(false);
			setNameHelper('');
		}

		// Validate the Volumes
		const volumeNameRegExp = /[^\w_\-]/; // Allowed chars: a-z, A-Z, 0-9, -, _ must be length 2-20
		const volumeSourcePathRegExp = /[^\w_\/\-]/;
		setVolumes(
			volumes.map(volume => {
				// Validate the volume name
				if (volume.name === '') {
					volume.name_error = true;
					volume.name_error_text = 'Name value is required';
					valid = false;
				} else if (volume.name.length < 2 || volume.name.length > 20) {
					volume.name_error = true;
					volume.name_error_text = 'Length must be between 2-20 ';
					valid = false;
				} else if (volumeNameRegExp.test(volume.name)) {
					volume.name_error = true;
					volume.name_error_text =
						'Name value must contain olny a-z, A-Z, 0-9, -, _ and be between 2 and 20 characters';
					valid = false;
				} else {
					volume.name_error = false;
					volume.name_error_text = '';
				}
				// Validate the volume source_path
				if (volume.source_path === '') {
					volume.source_path_error = true;
					volume.source_path_error_text = 'Name value is required';
					valid = false;
				} else if (
					volume.source_path.length < 2 ||
					volume.source_path.length > 40
				) {
					volume.source_path_error = true;
					volume.source_path_error_text =
						'Length must be between 2-40 ';
					valid = false;
				} else if (volumeSourcePathRegExp.test(volume.source_path)) {
					volume.source_path_error = true;
					volume.source_path_error_text =
						'Source path must contain olny a-z, A-Z, 0-9, -, _, / and be between 2 and 40 characters';
					valid = false;
				} else {
					volume.source_path_error = false;
					volume.source_path_error_text = '';
				}
				return volume;
			})
		);

		return valid;
	};

	const onBlur = event => {
		validateForm();
	};

	const handleAddVolume = () => {
		setVolumes([
			...volumes,
			{
				source_path: '',
				name: '',
				type: 'bind',
				uuid: uuidv1(),
				name_error: false,
				source_path_error: false,
			},
		]);
	};
	const handleRemoveVolume = index => () => {
		let newVolumes = [...volumes];
		newVolumes.splice(index, 1);
		setVolumes(newVolumes);
	};

	const handleVolumeNameChange = event => {
		setVolumes(
			volumes.map(volume => {
				if (volume.uuid === event.target.id) {
					volume.name = event.target.value;
				}
				return volume;
			})
		);
	};

	const handleVolumeSourceChange = event => {
		setVolumes(
			volumes.map(volume => {
				if (volume.uuid === event.target.id) {
					volume.source_path = event.target.value;
				}
				return volume;
			})
		);
	};

	const handleVolumeTypeChange = uuid => event => {
		setVolumes(
			volumes.map(volume => {
				if (volume.uuid === uuid) {
					volume.type = event.target.value;
				}
				return volume;
			})
		);
	};

	const handleCancel = () => {
		console.log('Cancel');
	};

	const handleSave = () => {
		console.log('Save');
		let formIsValid = validateForm();

		if (formIsValid) {
			console.log('Save New Revision');

			let updatedTask = { ...task };
			updatedTask.name = name;
			updatedTask.description = description;
			updatedTask.revision = parseInt(task_revision);
			updatedTask.volumes = volumes.map(volume => {
				return {
					name: volume.name,
					source_path: volume.source_path,
					type: volume.type,
					name_error: false,
					source_path_error: false,
					name_error_text: '',
					source_path_error_text: '',
				};
			});

			console.log(updatedTask);
		} else {
			setSnackMessage('Field Error, please check what you have entered');
			setErrorOpen(true);
		}
	};

	useEffect(
		() => {
			var req_url = `${
				process.env.REACT_APP_API_SERVER_URL
			}/v1/container_task/${task_uuid}/${task_revision - 1}`; // Get the previous revision

			Auth.currentSession().then(user_session => {
				let user_token = user_session.getAccessToken();
				fetch(req_url, {
					method: 'GET',
					headers: {
						Authorization: user_token.jwtToken,
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Organisation: appOrg.uuid,
					},
				})
					.then(res => res.json())
					.then(response => {
						setTask(response.task);

						// Load the task details into individual state values
						setName(response.task.name);
						setDescription(response.task.description);

						// We need a unique key for React to track our objects so add a UUID value
						let new_volumes = [...response.task.volumes];
						new_volumes = new_volumes.map(volume => {
							return {
								...volume,
								uuid: uuidv1(),
								name_error: false,
								source_path_error: false,
								name_error_text: '',
								source_path_error_text: '',
							};
						});
						setVolumes(new_volumes);
						setIsLoading(false);
					})
					.catch(error => {
						console.log(error);
						setIsLoading(false);
					});
			});
		},
		[appOrg.uuid, task_revision, task_uuid]
	);

	// Set breadcrumbs
	// useEffect(() => {
	// 	setBreadcrumbs([ ...IoT_breadcrumbs, {"text":"Container Tasks", "link":"/ContainerTaskList"}, {"text": "Add New Task Revision", "link":`/ContainerTask/${task_uuid}`}, {"text": task_revision, "link":""}]);
	// }, [setBreadcrumbs, task_revision, task_uuid]);

	useEffect(
		() => {
			setBreadcrumbs([
				...IoT_breadcrumbs,
				{ text: 'Container Tasks', link: '/ContainerTaskList' },
				{ text: `${task_name}`, link: `/ContainerTask/${task_uuid}` },
				{ text: `Revision ${task_revision}`, link: '' },
			]);
		},
		[setBreadcrumbs, task_name, task_revision, task_uuid]
	);

	return (
		<>
			{isLoading && (
				<Container
					maxWidth="lg"
					sx={{ display: 'flex', flexDirection: 'column' }}
				>
					<Typography variant="h3" sx={{ m: 'auto' }}>
						Loading...
					</Typography>
					<CircularProgress sx={{ m: 'auto' }} size={'120px'} />
				</Container>
			)}

			{!isLoading && (
				<Container maxWidth="lg">
					<SectionHeader>Task Details</SectionHeader>
					<ListTable>
						<ListBody>
							<ListRow>
								<ListHeader>Revision:</ListHeader>
								<ListCell>{task_revision}</ListCell>
							</ListRow>
						</ListBody>
					</ListTable>
					<Container>
						<TextField
							variant="outlined"
							fullWidth
							label="Task Name"
							error={nameError}
							helperText={nameHelper}
							required
							id="name"
							defaultValue={name}
							onChange={event => {
								setName(event.target.value);
							}}
							onBlur={onBlur}
						/>

						<TextField
							variant="outlined"
							fullWidth
							label="Description"
							error=""
							id="description"
							multiline
							minRows="3"
							defaultValue={description}
							onChange={event => {
								setDescription(event.target.value);
							}}
							onBlur={onBlur}
						/>
					</Container>

					<SectionHeader>Containers</SectionHeader>
					<SelectionTableContainer component={Box}>
						<SelectionTable aria-label="Container task table">
							<SelectionTableHead>
								<SelectionTableHeadRow>
									<SelectionTableCell>
										Container
									</SelectionTableCell>
									<SelectionTableCell>
										Image
									</SelectionTableCell>
									<SelectionTableCell>
										Remove Container
									</SelectionTableCell>
								</SelectionTableHeadRow>
							</SelectionTableHead>
							<SelectionTableBody>
								{task.containers.map((container, index) => (
									<SelectionTableRow
										key={index}
										onClick={onContainerClick(
											container.name
										)}
										title={container.name}
									>
										<SelectionTableCell
											component="th"
											scope="row"
										>
											{container.name}
										</SelectionTableCell>
										<SelectionTableCell>
											{container.image}
										</SelectionTableCell>
										<SelectionTableCell>
											<IconButton
												variant="text"
												onClick={handleRemoveContainer(
													container.name
												)}
											>
												<CloseIcon />
											</IconButton>
										</SelectionTableCell>
									</SelectionTableRow>
								))}
							</SelectionTableBody>
						</SelectionTable>
						<Button
							variant="text"
							startIcon={<AddCircleIcon />}
							onClick={handleAddContainer}
						>
							Add Container
						</Button>
					</SelectionTableContainer>

					<SectionHeader>Volumes</SectionHeader>
					<TableContainer component={Box} sx={{ width: 600 }}>
						<Table>
							<TableBody>
								{volumes.map((volume, index) => (
									<TableRow key={volume.uuid}>
										<TableCell>
											<TextField
												variant="outlined"
												fullWidth
												label="Name"
												size="small"
												error={volume.name_error}
												helperText={
													volume.name_error_text
												}
												required
												defaultValue={volume.name}
												id={volume.uuid}
												onChange={
													handleVolumeNameChange
												}
											/>
										</TableCell>
										<TableCell>
											<TextField
												variant="outlined"
												fullWidth
												label="Source Path"
												size="small"
												error={volume.source_path_error}
												helperText={
													volume.source_path_error_text
												}
												required
												defaultValue={
													volume.source_path
												}
												id={volume.uuid}
												onChange={
													handleVolumeSourceChange
												}
											/>
										</TableCell>
										<TableCell>
											<FormControl fullWidth>
												<InputLabel id="demo-simple-select-label">
													Type
												</InputLabel>
												<Select
													labelId="demo-simple-select-label"
													id={volume.uuid}
													value={volume.type}
													label="Type"
													size="small"
													onChange={handleVolumeTypeChange(
														volume.uuid
													)}
												>
													<MenuItem value={'bind'}>
														Bind Mount
													</MenuItem>
													<MenuItem value={'docker'}>
														Docker
													</MenuItem>
												</Select>
											</FormControl>
										</TableCell>
										<TableCell>
											<IconButton
												variant="text"
												onClick={handleRemoveVolume(
													index
												)}
											>
												<CloseIcon />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						<Button
							variant="text"
							startIcon={<AddCircleIcon />}
							onClick={handleAddVolume}
						>
							Add Volume
						</Button>
					</TableContainer>

					<Box sx={{ flexGrow: 0 }}>
						<Box display="flex" flexDirection="row-reverse">
							<Button
								variant="contained"
								color="primary"
								startIcon={<SaveIcon />}
								onClick={handleSave}
							>
								Save
							</Button>
							<Button
								variant="outlined"
								startIcon={<CloseIcon />}
								onClick={handleCancel}
							>
								Cancel
							</Button>
						</Box>
					</Box>
				</Container>
			)}

			<ContainerTaskEditDialog
				setOpen={editContainerOpen}
				onClose={onEditContainerClose}
				container={containerEdit}
				volumes={volumes}
			/>
			<CustomSnackbar
				open={errorOpen}
				onClose={onErrorClose}
				message={snackMessage}
				type={'error'}
				duration={4000}
			/>
		</>
	);
}
