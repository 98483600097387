import PropTypes from 'prop-types';
import {
	ARRAY,
	BOOLEAN,
	DATE,
	NULL,
	NUMBER,
	OBJECT,
	STRING,
} from '../../../utils/commonDataTypes';

export const type = PropTypes.oneOf([
	ARRAY,
	BOOLEAN,
	DATE,
	NULL,
	NUMBER,
	OBJECT,
	STRING,
]).isRequired;
