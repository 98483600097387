import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
	Box,
	buttonClasses,
	List,
	ListItemButton,
	ListItemText,
	Typography,
} from '@mui/material';
// import { ScreenDistributionWizardContext } from './ScreenDistributionWizardContext';
import { CheckBold } from 'mdi-material-ui';
import { FLAT, GROUPED } from '../utils/constants';
import sorter from '../utils/sorter';
import {
	Container,
	HeadingContainer,
	IconContainer,
	ListButton,
	NoItemsContainer,
	Fade,
} from './listComponents';

const BtFilterSelectOptionsList = ({
	options,
	disabled,
	onSelectOption,
	groupId,
	dense,
	RenderItem,
	sortOptions,
}) => {
	const sortedOptions = useMemo(
		() => {
			if (sortOptions) {
				return sorter(options, 'title');
			} else {
				return options;
			}
		},
		[options, sortOptions]
	);

	return (
		<List
			component="nav"
			aria-label="filter select"
			sx={{
				padding: 0,
				[`&& .${buttonClasses.disabled}`]: {
					opacity: 1,
				},
			}}
			dense={dense}
			disabled={disabled}
		>
			{sortedOptions.map(option => {
				const optionDisabled = option.disabled;

				if (RenderItem) {
					return (
						<RenderItem
							{...option}
							disabled={optionDisabled}
							key={option.id}
							selected={option.selected}
							title={option.title}
							onClick={() => onSelectOption(option, groupId)}
						/>
					);
				}

				return (
					<ListButton
						disabled={optionDisabled}
						key={option.id}
						selected={option.selected}
						onClick={() => onSelectOption(option, groupId)}
					>
						<ListItemText
							primary={
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										flexWrap: 'wrap',
									}}
								>
									<Typography
										style={{
											wordBreak: 'break-word',
											opacity: optionDisabled ? 0.7 : 1,
										}}
									>
										{option.title}
									</Typography>
								</div>
							}
							secondary={
								<Typography
									variant="subtitle2"
									style={{
										opacity: optionDisabled ? 0.7 : 1,
									}}
								>
									{option.subtitle}
								</Typography>
							}
						/>
						<IconContainer>
							{option.selected && (
								<CheckBold fontSize="medium" color="primary" />
							)}
						</IconContainer>
					</ListButton>
				);
			})}
		</List>
	);
};

const BtFilterSelectGroupTitle = ({ title }) => {
	return (
		<HeadingContainer>
			<Typography
				variant="subtitle2"
				style={{
					fontWeight: 'bold',
				}}
			>
				{title}
			</Typography>
			<Fade />
		</HeadingContainer>
	);
};

const BtFilterSelectListDisplay = ({
	dense,
	disabled,
	onSelectOption,
	options,
	optionsType,
	RenderItem,
	sortOptions,
}) => {
	if (optionsType === GROUPED) {
		return options.map(group => (
			<Container key={group.id}>
				<BtFilterSelectGroupTitle title={group.groupName} />
				<BtFilterSelectOptionsList
					options={group.options}
					disabled={disabled}
					onSelectOption={onSelectOption}
					groupId={group.id}
					dense={dense}
					RenderItem={RenderItem}
					sortOptions={sortOptions}
				/>
			</Container>
		));
	}

	if (optionsType === FLAT) {
		return (
			<Container>
				<BtFilterSelectOptionsList
					options={options}
					disabled={disabled}
					onSelectOption={onSelectOption}
					dense={dense}
					RenderItem={RenderItem}
					sortOptions={sortOptions}
				/>
			</Container>
		);
	}

	return null;
};

const BtFilterSelectNoItems = () => {
	return (
		<NoItemsContainer>
			<Typography>{'No matches found'}</Typography>
		</NoItemsContainer>
	);
};

export {
	BtFilterSelectGroupTitle,
	BtFilterSelectListDisplay,
	BtFilterSelectNoItems,
	BtFilterSelectOptionsList,
	// ListButton,
	// ListItemText,
	// IconContainer,
};

BtFilterSelectGroupTitle.propTypes = {
	title: PropTypes.string.isRequired,
};

BtFilterSelectListDisplay.propTypes = {
	dense: PropTypes.bool,
	disabled: PropTypes.bool,
	onSelectOption: PropTypes.func.isRequired,
	options: PropTypes.array.isRequired,
	optionsType: PropTypes.oneOf([GROUPED, FLAT]),
};

BtFilterSelectOptionsList.propTypes = {
	dense: PropTypes.bool,
	disabled: PropTypes.bool,
	groupId: PropTypes.string,
	onSelectOption: PropTypes.func.isRequired,
	options: PropTypes.array.isRequired,
	optionsType: PropTypes.oneOf([GROUPED, FLAT]),
	sortOptions: PropTypes.bool,
	RenderItem: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.element,
		PropTypes.func,
	]),
};
