import React, {
	memo,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';

import Color from 'color';
import {
	IconButton,
	Link,
	styled,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

const CancelButton = styled(Link)(({ theme }) => ({
	cursor: 'pointer',
	fontSize: 11,
	textDecoration: 'none',

	'&:hover': {
		color: Color(theme.palette.primary.main)
			.darken(0.2)
			.toString(),
		textDecoration: 'underline',
	},
}));

const Container = styled('div')(() => ({
	position: 'relative',
	width: '100%',
}));

const StyledTextField = styled(TextField)(() => ({
	'& textarea': {
		padding: '2px 32px 2px 2px',
	},
}));

const SubmitButton = styled(IconButton)(({ theme }) => ({
	backgroundColor: theme.palette.primary.main,
	color: 'white',
	height: 24,
	width: 24,

	'&:hover': {
		backgroundColor: Color(theme.palette.primary.main)
			.darken(0.2)
			.toString(),
	},
}));

const SubmitButtonContainer = styled('div')(({ interactive }) => ({
	bottom: 6,
	pointerEvents: interactive ? 'all' : 'none',
	position: 'absolute',
	right: 0,
}));

export const CommentField = memo(
	({
		autoFocus,
		defaultValue,
		disablePadding,
		editMode,
		onCancel,
		onSubmit,
		...props
	}) => {
		const inputRef = useRef();

		const [comment, setComment] = useState(defaultValue);
		const [focused, setFocused] = useState(false);

		const canSubmit = useMemo(
			() => {
				return comment.trim().length > 0;
			},
			[comment]
		);

		const reset = useCallback(
			() => {
				setComment(defaultValue);

				inputRef.current.blur();
			},
			[defaultValue]
		);

		const handleSubmit = useCallback(
			() => {
				onSubmit(comment);

				reset();
			},
			[comment, onSubmit, reset]
		);

		const handleCancel = useCallback(
			() => {
				onCancel?.(comment);

				reset();
			},
			[comment, onCancel, reset]
		);

		useEffect(
			() => {
				if (autoFocus) {
					inputRef.current.focus();
				}
			},
			[autoFocus, inputRef]
		);

		return (
			<Container {...props}>
				<div style={{ position: 'relative' }}>
					<StyledTextField
						fullWidth
						inputRef={inputRef}
						multiline
						nopadding={+disablePadding}
						onBlur={() => setFocused(false)}
						onChange={event => setComment(event.target.value)}
						onFocus={() => setFocused(true)}
						onKeyDown={event => {
							if (
								canSubmit &&
								event.key === 'Enter' &&
								event.ctrlKey
							) {
								handleSubmit();
							}

							if (event.key === 'Escape') {
								event.stopPropagation();

								handleCancel();
							}
						}}
						placeholder="Write a comment..."
						value={comment}
						variant="standard"
					/>
					<SubmitButtonContainer interactive={+canSubmit}>
						<Tooltip
							disableInteractive
							title={canSubmit ? 'Submit (Ctrl + Enter)' : ''}
						>
							<span>
								<SubmitButton
									disabled={!canSubmit}
									onClick={handleSubmit}
								>
									<SendIcon style={{ fontSize: 16 }} />
								</SubmitButton>
							</span>
						</Tooltip>
					</SubmitButtonContainer>
				</div>
				{focused && (
					<Typography
						style={{ height: 19, fontSize: 11, marginTop: 4 }}
					>
						Press Esc to cancel
					</Typography>
				)}
				{((editMode && !focused) ||
					(!editMode && !focused && comment.length > 0)) && (
					<div
						style={{
							alignItems: 'flex-start',
							display: 'flex',
							height: 19,
							marginTop: 4,
						}}
					>
						<CancelButton onClick={() => handleCancel()}>
							Cancel
						</CancelButton>
					</div>
				)}
			</Container>
		);
	}
);

CommentField.defaultProps = {
	autoFocus: false,
	defaultValue: '',
	disablePadding: false,
	editMode: false,
};

CommentField.propTypes = {
	autoFocus: PropTypes.bool,
	defaultValue: PropTypes.string,
	disablePadding: PropTypes.bool,
	editMode: PropTypes.bool,
	onBlur: PropTypes.func,
	onCancel: PropTypes.func,
	onSubmit: PropTypes.func.isRequired,
};

CommentField.displayName = 'CommentField';

export default CommentField;
