import {
	add,
	// addMonths,
	format,
	getTime,
	// getYear,
	intervalToDuration,
	isSameMonth,
	isSameWeek,
	isSameYear,
	startOfDay,
	startOfHour,
	startOfMinute,
	startOfMonth,
	startOfSecond,
	startOfWeek,
	startOfYear,
} from 'date-fns';
// import { secondsInMonth, secondsInYear } from 'date-fns/constants';
// import _ from 'lodash';

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;
// const MONTH = secondsInMonth * SECOND;
// const YEAR = secondsInYear * SECOND;
const YEAR = 31536000 * SECOND;
const MONTH = YEAR / 12;

// const secondTicks = {
// 	spacing: SECOND,
// 	timestampFormat: 'DD/MM/YYYY HH:mm:ss',
// 	tickFormat: 'HH:mm:ss',
// };
// const minuteTicks = {
// 	spacing: MINUTE,
// 	timestampFormat: 'DD/MM/YYYY HH:mm:ss',
// 	tickFormat: 'HH:mm:ss',
// };
// const hourTicks = {
// 	spacing: HOUR,
// 	timestampFormat: 'DD/MM/YYYY HH:mm:ss',
// 	tickFormat: 'HH:mm',
// };
// const dayTicks = {
// 	spacing: DAY,
// 	timestampFormat: 'DD/MM/YYYY',
// 	tickFormat: 'DD/MM',
// };
// const weekTicks = {
// 	spacing: WEEK,
// 	timestampFormat: 'DD/MM/YYYY',
// 	tickFormat: 'DD/MM',
// };
// const monthTicks = {
// 	spacing: MONTH,
// 	timestampFormat: 'DD/MM/YYYY',
// 	tickFormat: 'MMM',
// };
// const yearTicks = {
// 	spacing: YEAR,
// 	timestampFormat: 'DD/MM/YYYY',
// 	tickFormat: 'YYYY',
// };
const secondTicks = {
	spacing: { seconds: 1 },
	startOfTimePeriodFunc: startOfSecond,
	timestampFormat: 'DD/MM/YYYY HH:mm:ss',
	tickFormat: 'HH:mm:ss',
	supplementaryTickFormat: 'YYYY',
};
const minuteTicks = {
	spacing: { minutes: 1 },
	startOfTimePeriodFunc: startOfMinute,
	timestampFormat: 'DD/MM/YYYY HH:mm:ss',
	tickFormat: 'HH:mm:ss',
	supplementaryTickFormat: 'YYYY',
};
const hourTicks = {
	spacing: { hours: 1 },
	startOfTimePeriodFunc: startOfHour,
	timestampFormat: 'DD/MM/YYYY HH:mm:ss',
	tickFormat: 'HH:mm',
	supplementaryTickFormat: 'YYYY',
};
const dayTicks = {
	spacing: { days: 1 },
	startOfTimePeriodFunc: startOfDay,
	isSameTimePeriodFunc: isSameWeek,
	timestampFormat: 'DD/MM/YYYY',
	tickFormat: 'dd',
	// tickFormat: 'DD/MM',
	supplementaryTickFormat: 'DD/MM',
};
const weekTicks = {
	spacing: { weeks: 1 },
	startOfTimePeriodFunc: startOfWeek,
	isSameTimePeriodFunc: isSameMonth,
	timestampFormat: 'DD/MM/YYYY',
	tickFormat: 'DD/MM',
	supplementaryTickFormat: 'MMM',
};
const monthTicks = {
	spacing: { months: 1 },
	startOfTimePeriodFunc: startOfMonth,
	isSameTimePeriodFunc: isSameYear,
	timestampFormat: 'DD/MM/YYYY',
	tickFormat: 'MMM',
	supplementaryTickFormat: 'YYYY',
};
const yearTicks = {
	spacing: { years: 1 },
	startOfTimePeriodFunc: startOfYear,
	timestampFormat: 'DD/MM/YYYY',
	tickFormat: 'YYYY',
	supplementaryTickFormat: 'YYYY',
};

export const timeSpanTickBreakpoints = [
	{
		breakpoint: MINUTE,
		tickFormat: secondTicks,
	},
	{
		breakpoint: HOUR,
		tickFormat: minuteTicks,
	},
	{
		breakpoint: DAY,
		tickFormat: hourTicks,
	},
	{
		breakpoint: 2 * WEEK,
		tickFormat: dayTicks,
	},
	{
		breakpoint: MONTH,
		tickFormat: weekTicks,
	},
	{
		breakpoint: YEAR,
		tickFormat: monthTicks,
	},
];

export {
	secondTicks,
	minuteTicks,
	hourTicks,
	dayTicks,
	weekTicks,
	monthTicks,
	yearTicks,
};

const logFormat = 'dd/MM/yyyy HH:mm';

const generateTicks = (tickData, start, end) => {
	const supplementaryTicks = [];
	const firstTick = getTime(
		tickData.startOfTimePeriodFunc(add(start, tickData.spacing))
	);
	const ticks = [start];
	ticks.push(firstTick);

	while (ticks[ticks.length - 1] < end) {
		const currentTick = ticks[ticks.length - 1];
		const nextTick = add(currentTick, tickData.spacing);
		if (nextTick > end) break;

		ticks.push(getTime(nextTick));
		if (Object.hasOwn(tickData, 'isSameTimePeriodFunc')) {
			if (!tickData.isSameTimePeriodFunc(currentTick, nextTick)) {
				supplementaryTicks.push(getTime(nextTick));
			}
		}
	}
	return { ticks: ticks, supplementaryTicks: supplementaryTicks };
};

// TODO add chart size breakpoint logic
const determineTickSpacing = timeSpan => {
	let result = yearTicks;
	for (const value of timeSpanTickBreakpoints) {
		if (timeSpan <= value.breakpoint) {
			result = value.tickFormat;
			break;
		}
	}
	return result;
};

export default function generateTimeSeriesTickValues(start, end) {
	// console.log(format(start, logFormat), format(end, logFormat));
	// console.log({ start, end });

	const timeSpan = end - start;

	if (timeSpan === 0) {
		return {
			ticks: [start],
			spacing: undefined,
			// timestampFormat: '',
			// tickFormat: '',
			timestampFormat: 'DD/MM/YYYY',
			tickFormat: 'DD/MM',
		};
	} else {
		const result = determineTickSpacing(timeSpan);

		const { ticks, supplementaryTicks } = generateTicks(result, start, end);

		result.ticks = ticks;
		result.supplementaryTicks = supplementaryTicks;

		return result;
	}
}
