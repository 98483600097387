import { FLAT, GROUPED } from './constants';

/**
 * sets the selected value to false for all options
 * @param {array} data - the unfiltered options data, can be grouped or flat but be sure to pass grouped if passing groupId and flat if not
 * @param {string} optionsType - 'flat' || 'grouped', determines how the data is processed, be sure to pass data of matching type
 * @returns
 */
export default function normaliseSingularSelect(data, optionsType) {
	const checkSelected = options => {
		let trueCount = 0;
		options.forEach(element => {
			if (Object.hasOwn(element, 'selected')) {
				if (element.selected) ++trueCount;
			}
		});
		return trueCount;
	};

	// function to handle options processing
	const processOptions = (options, overrideDataSelected) => {
		return options.map(option => {
			const tempOption = { ...option };
			// if (!Object.hasOwn(option, 'selected')) {
			// 	tempOption.selected = false;
			// }
			if (overrideDataSelected || !Object.hasOwn(option, 'selected')) {
				tempOption.selected = false;
			}

			return tempOption;
		});
	};

	let result = [];

	if (optionsType === GROUPED) {
		// as we are handling singular select,
		// check if more than one option is marked as selected
		const multipleTrue =
			data
				.map(group => checkSelected(group.options))
				.reduce((a, b) => a + b, 0) > 1;

		// use processOptions to evaluate and seed the selected property
		result = data.map(group => {
			const tempGroup = { ...group };
			tempGroup.options = processOptions(group.options, multipleTrue);
			return tempGroup;
		});

		// if more than one option was marked as selected,
		// set the first to selected as they will have all
		// have been set to false by processOptions
		if (multipleTrue) result[0].options[0].selected = true;
	} else if (optionsType === FLAT) {
		// as we are handling singular select,
		// check if more than one option is marked as selected
		const multipleTrue = checkSelected(data) > 1;

		// use processOptions to evaluate and seed the selected property
		result = processOptions(data, multipleTrue);

		// if more than one option was marked as selected,
		// set the first to selected as they will have all
		// have been set to false by processOptions
		if (multipleTrue) result[0].selected = true;
	}

	return result;
}
