import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { v1 as uuidv1 } from 'uuid';
import * as yup from 'yup';
import _ from 'lodash';

import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Typography,
} from '@mui/material';
import Icon from '@mdi/react';

import AddIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import HomeIcon from '@mui/icons-material/Home';

import BtConfirmDialog from '../../components/generic/BtConfirmDialog';
import BtDialog from '../../components/generic/BtDialog';
import BtError from '../../components/generic/BtError';
import {
	BtForm,
	BtFormActionsContainer,
	BtFormContainer,
	BtFormContent,
	BtFormSelect,
	BtFormTextField,
	withFormContextMethods,
} from '../../components/generic/forms';
import BtLoading from '../../components/generic/BtLoading';
import BtSelectionTable from '../../components/generic/BtSelectionTable';
import IconResolver from '../../utils/iconResolver';
import { useNavContext, useAppContext } from '../../context/ContextManager';
import useFetch from '../../hooks/useFetch';

import workflow_options from './WorkflowsOptions';
import workflow_nav_item from './WorkflowsNavItem';
import {
	workflowTemplateGetList,
	workflowTemplateCreate,
	workflowSubmissionCreate,
} from '../../API';

const ActionButtons = withFormContextMethods(
	({ formState: { isDirty }, onClose, sending }) => {
		return (
			<>
				<Button disabled={sending} onClick={onClose} variant="outlined">
					Cancel
				</Button>
				<Button
					disabled={!isDirty || sending}
					disableElevation
					startIcon={<AddIcon />}
					type="submit"
					variant="contained"
				>
					Add New Template
				</Button>
			</>
		);
	}
);

function NewTemplateDialog({ showDialog, onClose }) {
	const { appOrg } = useAppContext();
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();

	const [sending, setSending] = useState(false);

	const schema = yup.object({
		name: yup
			.string()
			.required()
			.min(3),
		description: yup.string().nullable(),
		group: yup
			.string()
			.uuid('Please select a group')
			.required(),
	});

	const defaultValues = { name: '', description: '', group: '' };

	const onSubmit = async formData => {
		setSending(true);

		try {
			const newTemplateResult = await workflowTemplateCreate({
				newTemplate: formData,
			});

			enqueueSnackbar(
				`Created new template '${newTemplateResult.name}'`,
				{
					variant: 'success',
				}
			);

			onClose();

			setSending(false);

			history.push(
				`/Workflows/Templates/${newTemplateResult.uuid}/settings`
			);
		} catch (error) {
			console.log(error);

			setSending(false);

			enqueueSnackbar('Failed to create Workflow template', {
				variant: 'error',
			});
		}
	};

	return (
		<BtDialog open={showDialog} fullWidth maxWidth="xs" onClose={onClose}>
			<DialogTitle>Create New Workflow Template</DialogTitle>

			<BtForm
				defaultValues={defaultValues}
				onSubmit={onSubmit}
				sending={sending}
				validationSchema={schema}
			>
				<DialogContent>
					<BtFormContent>
						<BtFormTextField name="name" label="Template Name" />
						<BtFormTextField
							label="Template Description"
							name="description"
						/>
						<BtFormSelect
							items={(appOrg?.workflow_template_groups || []).map(
								templateGroup => {
									return {
										value: templateGroup.uuid,
										label: templateGroup.name,
									};
								}
							)}
							label="Template Group"
							name="group"
						/>
					</BtFormContent>
				</DialogContent>

				<DialogActions>
					<BtFormActionsContainer>
						<ActionButtons onClose={onClose} />
					</BtFormActionsContainer>
				</DialogActions>
			</BtForm>
		</BtDialog>
	);
}

const tableColumns = [
	{
		field: 'groupIcon',
		searchable: false,
		text: 'Group Icon',
		minBreakpoint: 'md',
	},
	{ field: 'group', searchable: false, text: 'Group', minBreakpoint: 'sm' },
	{ field: 'name', text: 'Name' },
	{ field: 'description', text: 'Description', minBreakpoint: 'md' },
	{ field: 'status', text: 'Status', minBreakpoint: 'md' },
	{ field: 'current_version', text: 'Latest Version', minBreakpoint: 'md' },
	{ field: 'edit', searchable: false, text: 'Edit', minBreakpoint: 'md' },
];

export default function Workflows() {
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const { setContextualNav, setBreadcrumbs } = useNavContext();
	const { userInfo, appOrg } = useAppContext();

	const [confirmOpen, setConfirmOpen] = useState(false);
	const [newSubTempName, setNewSubTempName] = useState('');
	const [newSubTempUuid, setNewSubTempUuid] = useState(null);
	const [sending, setSending] = useState(false);
	const [showNewTemplateDialog, setShowNewTemplateDialog] = useState(false);

	useEffect(
		() => {
			setContextualNav([...workflow_nav_item, ...workflow_options]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	const {
		data: templateList,
		error: templateListError,
		loading: templateListLoading,
		request: getTemplateList,
	} = useFetch(workflowTemplateGetList);

	useEffect(
		() => {
			getTemplateList();
		},
		[getTemplateList]
	);

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'Workflows', link: '/Workflows' },
				{ text: 'Templates', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	const handleEdit = uuid => event => {
		event.stopPropagation();

		history.push(`/Workflows/Templates/${uuid}/settings`);
	};

	const handleTableClick = uuid => {
		const template = _.find(templateList, { uuid: uuid });

		if (template.user_creatable === false) {
			enqueueSnackbar('This template cannot be created by a user', {
				variant: 'info',
			});
		} else if (template.status !== 'Active') {
			enqueueSnackbar('This template is not active', {
				variant: 'info',
			});
		} else {
			setConfirmOpen(true);

			setNewSubTempUuid(uuid);
			setNewSubTempName(template.name);
		}
	};

	const handleCreateSubmission = async templateUuid => {
		try {
			const template = _.find(templateList, { uuid: templateUuid });
			const newSubmissionUuid = uuidv1();
			const newSubmission = {
				uuid: newSubmissionUuid,
				due_date: null,
				template: {
					uuid: template.uuid,
					version: template.current_version,
				},
				create_timestamp: Date.now(),
				data: {},
				log: [
					{
						timestamp: Date.now(),
						uuid: uuidv1(),
						issuer_type: 'User',
						issuer_id: userInfo.uuid,
						status: 'InProgress', // TODO: change this to pick up the correct value
					},
				],
				session_data: { tree: null },
			};

			await workflowSubmissionCreate({
				newSubmission: newSubmission,
			});

			enqueueSnackbar(`Created new Submission '${template.name}'`, {
				variant: 'success',
			});

			history.push(`/Workflows/Submissions/${newSubmissionUuid}`);
		} catch (error) {
			console.log(error);

			setSending(false);

			enqueueSnackbar('Failed to create Workflow submission', {
				variant: 'error',
			});
		}
	};

	if (templateListError) {
		return (
			<BtError
				action={() => history.push('/')}
				actionIcon={<HomeIcon />}
				actionLabel="Go To Home"
				description="An error occurred when attempted to retrieve the workflow templates."
				title="Retrieval Error"
			/>
		);
	}

	return (
		<>
			<BtFormContainer
				maxWidth="lg"
				style={{ marginBottom: '2em' }}
				title="Workflow Templates"
			>
				<BtLoading loading={templateListLoading || sending}>
					{templateList && (
						<BtSelectionTable
							columns={tableColumns}
							enableSearching
							subject="Workflow Templates"
							onClick={handleTableClick}
							title={
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										flexWrap: 'wrap',
										gap: 10,
									}}
								>
									<Typography
										sx={{
											fontWeight: 'bold',
											padding: '4px 0',
										}}
										variant="h5"
									>
										Your Workflows Templates
									</Typography>
									<Button
										variant="contained"
										onClick={() =>
											setShowNewTemplateDialog(true)
										}
										startIcon={<AddIcon />}
										disableElevation
									>
										Create New Template
									</Button>
								</div>
							}
							data={(templateList || []).map(
								({
									uuid,
									name,
									description,
									status,
									group,
									current_version,
								}) => ({
									id: uuid,
									description: description,
									current_version: current_version + 1,
									name: name,
									status: status,
									groupIcon: (
										<Icon
											path={IconResolver(
												_.find(
													appOrg?.workflow_template_groups,
													{ uuid: group }
												).icon
											)}
											size={2}
											color={
												_.find(
													appOrg?.workflow_template_groups,
													{ uuid: group }
												).colour
											}
										/>
									),
									group: (
										<Typography
											variant="body"
											sx={{
												color: _.find(
													appOrg?.workflow_template_groups,
													{ uuid: group }
												).colour,
											}}
										>
											{' '}
											{
												_.find(
													appOrg?.workflow_template_groups,
													{ uuid: group }
												).name
											}{' '}
										</Typography>
									),
									edit: (
										<IconButton
											onClick={handleEdit(uuid)}
											size="small"
										>
											<EditIcon />
										</IconButton>
									),
								})
							)}
						/>
					)}
				</BtLoading>
			</BtFormContainer>
			<BtConfirmDialog
				action={() => {
					handleCreateSubmission(newSubTempUuid);
				}}
				ActionIcon={<AddIcon />}
				open={confirmOpen}
				onClose={() => {
					setConfirmOpen(false);
				}}
				prompt={`Are you sure you wish to create a new '${newSubTempName}' submission?`}
				title="Create New Submission"
				verb="Create"
			/>
			<NewTemplateDialog
				onClose={() => {
					setShowNewTemplateDialog(false);
				}}
				showDialog={showNewTemplateDialog}
			/>
		</>
	);
}

NewTemplateDialog.propTypes = {
	showDialog: PropTypes.bool,
	onClose: PropTypes.func,
};
