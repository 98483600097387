import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { useNavContext } from '../../context/ContextManager';

// Data for landing page selection table and contextual navigation
import beacon_admin_options from './BeaconAdminOptions';
import beacon_admin_nav_item from './BeaconAdminNavItem';

import useFetch from '../../hooks/useFetch';

import {
	a11yProps,
	BtTab,
	BtTabBar,
	BtTabPanel,
	BtTabView,
} from '../../components/generic/BtTabView';

import AddIcon from '@mui/icons-material/AddCircle';
import NewUserIcon from '@mui/icons-material/PersonAdd';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { BtFormContainer } from '../../components/generic/forms';
import BtLoading from '../../components/generic/BtLoading';
import BtUserRow from '../../components/generic/BtUserRow';
import BtSelectionTable from '../../components/generic/BtSelectionTable';
import BtTableSkeleton from '../../components/generic/BtTableSkeleton';
import NewOrganisationDialog from './NewOrganisationDialog';

import { platformAdminOrganisationGet } from '../../API';
import OrganisationSubscriptions from './OrganisationSubscriptions';
import { BtChipsDisplay } from '../../components/generic/BtChips';
import BtDialog from '../../components/generic/BtDialog';
import { ButtonGroup } from '@mui/material';
import BeaconAdminOrgUserInvite from './BeaconAdminOrgUserInvite';
import BeaconAdminOrgUserAdd from './BeaconAdminOrgUserAdd';
import { useThemeContext } from '../../context/ThemeContext';
import ReactJson from 'react-json-view';
import { set } from 'dot-object';
import { platformAdminOrganisationUpdate } from '../../API/PlatformAdmin/platformAdminOrganisation.api';

const tableColumns = [
	{ field: 'name', text: 'Organisation' },
	{ field: 'createTimestamp', text: 'Created', minBreakpoint: 'sm' },
	{ field: 'updateTimestamp', text: 'Modified', minBreakpoint: 'sm' },
];

export default function BeaconAdminOrganisation({ match }) {
	const history = useHistory();
	const { themeDark } = useThemeContext();

	// navContext contextual navigation items
	const { setContextualNav } = useNavContext();
	// navContext breadcrumbs
	const { setBreadcrumbs } = useNavContext();

	const [currentTab, setCurrentTab] = useState(0);

	const [newCollectionDialog, setNewCollectionDialog] = useState(false);

	const [userDetails, setUserDetails] = useState([]);
	const [userTableData, setUserTableData] = useState([]);
	const [showInviteDialog, setShowInviteDialog] = useState(false);
	const [showAddUserDialog, setShowAddUserDialog] = useState(false);
	const [orgDetails, setOrgDetails] = useState(null);

	const orgId = match.params.uuid;

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'BeaconAdmin', link: '/BeaconAdmin' },
				{ text: 'Organisations', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	//Boilerplate for future implementation set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([
				...beacon_admin_nav_item,
				...beacon_admin_options,
			]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	const { loading, data, error, request: getOrgDetails } = useFetch(
		platformAdminOrganisationGet,
		setOrgDetails
	);

	useEffect(
		() => {
			getOrgDetails({ orgUuid: orgId });
		},
		[orgId]
	);

	const onRefreshOrg = () => {
		getOrgDetails({ orgUuid: orgId });
	};

	useEffect(
		() => {
			if (orgDetails) {
				console.log(orgDetails);

				const newUserList = orgDetails.users.map(user => {
					return {
						...user,
						roles: orgDetails.roles.filter(role => {
							return role.users.includes(user.uuid);
						}),
					};
				});

				setUserDetails(newUserList);
				setUserTableData(
					newUserList.map(user => {
						return {
							userRow: <BtUserRow user={user} />,
							roles: (
								<BtChipsDisplay
									value={user.roles}
									primaryField="name"
									idKey="uuid"
									size="small"
									readOnly
									partOfButton
									singleLine
								/>
							),
							route: `/BeaconAdmin/Organisation/${orgId}/UserEdit/${
								user.uuid
							}`,
						};
					})
				);
			}
		},
		[orgDetails]
	);

	const onJsonAdd = async ({ updated_src: updatedOrgDetails }) => {
		try {
			await platformAdminOrganisationUpdate({
				orgUuid: orgId,
				orgDetails: updatedOrgDetails,
			});
			setOrgDetails(updatedOrgDetails);
		} catch (e) {
			console.log(e);
		}
	};

	const onJsonEdit = async ({ updated_src: updatedOrgDetails }) => {
		try {
			await platformAdminOrganisationUpdate({
				orgUuid: orgId,
				orgDetails: updatedOrgDetails,
			});
			setOrgDetails(updatedOrgDetails);
		} catch (e) {
			console.log(e);
		}
	};

	const refreshSubscriptions = () => {
		getOrgDetails({ orgUuid: orgId });
	};

	return (
		<>
			<BtLoading
				loading={loading}
				LoadingComponent={<BtTableSkeleton hasActionButton />}
			>
				<BtTabView>
					<BtFormContainer
						header={
							<BtTabBar
								currentTab={currentTab}
								onTabChange={(event, selectedTab) =>
									setCurrentTab(selectedTab)
								}
							>
								<BtTab
									label="Organisation Details"
									{...a11yProps(0)}
								/>
								<BtTab label="Users" {...a11yProps(1)} />
								<BtTab
									label="Subscriptions"
									{...a11yProps(2)}
								/>
							</BtTabBar>
						}
						maxWidth="lg"
						title={
							orgDetails?.name
								? `${orgDetails?.name}`
								: 'Loading...'
						}
					>
						<BtTabPanel currentTab={currentTab} index={0}>
							<ReactJson
								src={orgDetails || {}}
								style={{
									backgroundColor: 'transparent',
									fontSize: 14,
								}}
								theme={themeDark ? 'chalk' : 'rjv-default'}
								onEdit={onJsonEdit}
								onAdd={onJsonAdd}
							/>
						</BtTabPanel>
						<BtTabPanel currentTab={currentTab} index={1}>
							<BtSelectionTable
								title={
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											flexWrap: 'wrap',
											gap: 10,
										}}
									>
										<Typography
											sx={{
												fontWeight: 'bold',
												padding: '4px 0',
											}}
											variant="h5"
										>
											Your organisation's users
										</Typography>
										<ButtonGroup>
											<Button
												variant="outlined"
												onClick={() =>
													setShowAddUserDialog(true)
												}
												startIcon={<NewUserIcon />}
												disableElevation
											>
												Add Existing
											</Button>
											<Button
												variant="outlined"
												onClick={() =>
													setShowInviteDialog(true)
												}
												startIcon={<NewUserIcon />}
												disableElevation
											>
												Invite a New User
											</Button>
										</ButtonGroup>
									</div>
								}
								columns={[
									{ field: 'userRow', text: 'User' },
									{ field: 'roles', text: 'roles' },
								]}
								data={userTableData}
							/>

							{/* <pre>
								{JSON.stringify(
									orgDetails?.users,
									null,
									4
								)}
							</pre> */}
						</BtTabPanel>
						<BtTabPanel currentTab={currentTab} index={2}>
							{/* <pre>
								{JSON.stringify(
									orgDetails?.subscriptions,
									null,
									4
								)}
							</pre> */}
							<OrganisationSubscriptions
								orgUuid={orgId}
								subscriptions={orgDetails?.subscriptions || []}
								refreshSubscriptions={refreshSubscriptions}
							/>
						</BtTabPanel>
					</BtFormContainer>
				</BtTabView>
				<BtDialog
					open={showInviteDialog}
					onClose={() => setShowInviteDialog(false)}
					fullWidth
					maxWidth="sm"
				>
					<BeaconAdminOrgUserInvite
						orgUuid={orgId}
						onRefreshOrg={onRefreshOrg}
						onClose={() => setShowInviteDialog(false)}
					/>
				</BtDialog>
				<BtDialog
					open={showAddUserDialog}
					onClose={() => setShowAddUserDialog(false)}
					fullWidth
					maxWidth="sm"
				>
					<BeaconAdminOrgUserAdd
						orgUuid={orgId}
						onRefreshOrg={onRefreshOrg}
						onClose={() => setShowAddUserDialog(false)}
					/>
				</BtDialog>
			</BtLoading>
		</>
	);
}
