const {
	evaluateExpression,
	OPERATION_MODE,
} = require('../../expression/expression.js');
const { isDocument } = require('../../utils.js');
const { setStage, ERROR } = require('../pipeline.js');

/**
 * @type {PipelineStageFunction<{newRoot: any;},{stage:string;}>}
 */
function replaceRoot(context, args, options) {
	const stageKey = options.stage;

	if (!isDocument(args) || !Object.hasOwnProperty.call(args, 'newRoot')) {
		throw new Error(
			ERROR.INVALID_STAGE_ARGUMENT(
				stageKey,
				'a document with the newRoot property',
				args
			)
		);
	}

	const accumulator = [];

	for (let c = 0; c < context.collection.length; c++) {
		let current = context.collection[c];

		const expression = evaluateExpression(
			{
				...context,
				current: current,
				operationMode: OPERATION_MODE.AGGREGATE,
			},
			args.newRoot
		);

		if (!isDocument(expression)) {
			throw new Error(
				ERROR.INVALID_STAGE_ARGUMENT(
					stageKey,
					'newRoot expression to resolve to a document',
					expression
				)
			);
		}
		accumulator.push(expression);
	}

	return accumulator;
}

setStage('$replaceRoot', (context, args, options) =>
	replaceRoot(
		context,
		args,
		options
			? { ...options, stage: '$replaceRoot' }
			: { stage: '$replaceRoot' }
	)
);

setStage('$replaceWith', (context, args, options) =>
	replaceRoot(
		context,
		{ newRoot: args },
		options
			? { ...options, stage: '$replaceRoot' }
			: { stage: '$replaceRoot' }
	)
);
