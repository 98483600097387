import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import InfoIcon from '@mui/icons-material/Info';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';

const Chip = styled('div')(({ attrs }) => ({
	alignItems: 'center',
	backgroundColor: attrs.backgroundColor + '40',
	borderRadius: 16,
	color: attrs.textColor,
	display: 'flex',
	justifyContent: 'center',
	minHeight: 32,
	padding: '0 16px',
	userSelect: 'none',
	width: 'auto',

	'& *': {
		fontWeight: 'bold !important',
		textAlign: 'center',
	},

	'& svg': {
		color: attrs.textColor,
		fontSize: 20,
		marginRight: '0.3em',
	},
}));

const INFO = 'info';
const SUCCESS = 'success';
const WARNING = 'warning';
const ERROR = 'error';

export default function BtInfoChip({
	chipStyle,
	Icon,
	showIcon,
	style,
	text,
	tooltip,
	tooltipPlacement,
	variant,
	textAlign,
}) {
	const theme = useTheme();

	const attributes = useMemo(
		() => ({
			textColor: theme.palette.indicators[variant].text,
			backgroundColor: theme.palette.indicators[variant].main,
			IconToDisplay:
				Icon ||
				(() => {
					switch (variant) {
						case SUCCESS:
							return <SuccessIcon />;
						case WARNING:
							return <WarningIcon />;
						case ERROR:
							return <ErrorIcon />;
						default:
							return <InfoIcon />;
					}
				})(),
		}),
		[Icon, theme, variant]
	);

	return (
		<div style={style}>
			<Tooltip
				title={tooltip || ''}
				placement={tooltipPlacement}
				disableInteractive
			>
				<Chip attrs={attributes} style={chipStyle}>
					{showIcon && attributes.IconToDisplay}
					<Typography align={textAlign} variant="subtitle2">
						{text}
					</Typography>
				</Chip>
			</Tooltip>
		</div>
	);
}

BtInfoChip.defaultProps = {
	showIcon: true,
	tooltipPlacement: 'bottom',
	variant: INFO,
};

BtInfoChip.propTypes = {
	chipStyle: PropTypes.object,
	Icon: PropTypes.node,
	showIcon: PropTypes.bool,
	style: PropTypes.object,
	text: PropTypes.string.isRequired,
	tooltip: PropTypes.string,
	tooltipPlacement: PropTypes.string,
	variant: PropTypes.oneOf([INFO, SUCCESS, WARNING, ERROR]),
	textAlign: PropTypes.oneOf([
		'center',
		'inherit',
		'justify',
		'left',
		'right',
	]),
};
