import { Auth } from 'aws-amplify';
import { getAppStoreItem } from '../utils/appStore';

export default async function getAuthHeaders() {
	var authHeaders = {};

	try {
		if (process.env.REACT_APP_API_SERVER_URL.includes('localhost')) {
			authHeaders = { userid: await getAppStoreItem('DevUser') };
		} else {
			const userSession = await Auth.currentSession();
			const userToken = userSession.getAccessToken();
			authHeaders = { Authorization: userToken.jwtToken };
		}

		try {
			const orgUuid = await getAppStoreItem('OrgUuid');
			if (orgUuid) {
				authHeaders = { ...authHeaders, Organisation: orgUuid };
			}
		} catch (error) {
			// Unable to get org UUID, assume not set and return no value for it
		}
	} catch (error) {
		// Error getting user auth token, not need to handle this here we simply return an empty header obj
	}

	return authHeaders;
}
