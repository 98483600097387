import PropTypes from 'prop-types';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import {
	Box,
	Button,
	ClickAwayListener,
	DialogActions,
	Paper,
	Popper,
	Typography,
	styled,
	useTheme,
} from '@mui/material';
import { Check, Delete, Plus } from 'mdi-material-ui';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import { mdiCheckBold, mdiContrastCircle, mdiExclamationThick } from '@mdi/js';
import IconBadge from './ContrastStatusIndicator';
import { FAIL, evalContrastRatios } from '../../../../utils/colourUtils';
import { NameForm } from './NameForm';
import { memo } from 'react';

const StyledHexColorPicker = styled(HexColorPicker)(({ theme }) => ({
	'.react-colorful__saturation-pointer': {
		width: '8px',
		height: '8px',
		borderWidth: '1px',
	},
	'.react-colorful__saturation': {
		margin: '8px',
	},
	'.react-colorful__hue': {
		margin: '0px 8px',
	},
	'.react-colorful__hue-pointer': {
		width: '8px',
		borderRadius: '3px',
		borderWidth: '1px',
	},
	'.react-colorful__saturation, .react-colorful__hue': {
		width: '284px',
		borderRadius: '4px',
	},
	'.react-colorful__hue-pointer, .react-colorful__hue': {
		height: '10px',
	},
}));

const StyledHexColorInput = styled(HexColorInput)(({ theme }) => ({
	backgroundColor: theme.palette.background.paper,
	width: '80px',
	border: `solid 1px ${theme.palette.divider}`,
	borderRadius: '4px',
	'&:focus': {
		outline: 'none',
		border: `solid 1px ${theme.palette.primary.main}`,
	},
	'&:hover': {
		border: `solid 1px ${theme.palette.primary.main}`,
	},
}));

const InputContainer = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	gap: '5px',
}));

const InputInfoContainer = styled(Box)(() => ({
	margin: '8px',
	display: 'flex',
	alignItems: 'center',
	gap: '5px',
	justifyContent: 'space-between',
}));

const ColourPicker = memo(function ColourPicker({
	anchorEl,
	inputColour,
	onChangeInputColour,
	onClose,
	onRemoveColour,
	onChangeName,
	newColour,
	name,
	names,
}) {
	const [isOpen, setIsOpen] = useState(false);
	const theme = useTheme();
	const [localAnchorEl, setLocalAnchorEl] = useState(null);
	const [tempName, setTempName] = useState();
	const [nameFormIsValid, setNameFormIsValid] = useState(false);
	// const withTags = useMemo(() => Array.isArray(tags) && !disableTags, [
	// 	tags,
	// 	disableTags,
	// ]);

	useEffect(
		() => {
			if (nameFormIsValid && tempName) {
				onChangeName(tempName);
			}
		},
		// including onChangeName in the dependency array causes race conditionß
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[nameFormIsValid, tempName]
	);

	// Check if the anchor element' display property has been changed
	// This is necessary to eliminate a react warning as the anchor element is initially display 'none'
	// We use a local copy of the anchorEl as the external one is nulled prior to the popper closing which causes a warning - this seem to be popper specific
	useEffect(
		() => {
			// console.log(anchorEl);
			if (
				anchorEl &&
				window.getComputedStyle(anchorEl).display !== 'none'
			) {
				setLocalAnchorEl(anchorEl);
				setIsOpen(true);
			} else {
				setIsOpen(false);
				setLocalAnchorEl(null);
			}
		},
		[anchorEl]
	);

	// Attributes for the colour contrast UI
	const contrastStatusAttributes = useMemo(
		() => {
			if (inputColour) {
				const contrastStatus = evalContrastRatios(inputColour);

				const failString = status => {
					if (status.dark === FAIL) {
						return ' the dark theme';
					} else if (status.light === FAIL) {
						return ' the light theme';
					} else if (status.light === FAIL && status.dark === FAIL) {
						return ' both themes';
					}
				};

				const validGlobalStatus = !Object.values(
					contrastStatus
				).includes(FAIL);

				const backgroundColour = validGlobalStatus
					? theme.palette.indicators.success.main
					: theme.palette.indicators.error.main;

				const colour = theme.palette.getContrastText(backgroundColour);

				const iconPath = validGlobalStatus
					? mdiCheckBold
					: mdiExclamationThick;

				const tooltipText = validGlobalStatus
					? 'This colour meets the recommended level of contrast.'
					: `This colour does not meet the recommended level of contrast for ${failString(
							contrastStatus
					  )}.`;

				return {
					backgroundColour,
					colour,
					iconPath,
					tooltipText,
				};
			}
		},
		[inputColour, theme]
	);

	const handleRemoveClick = useCallback(
		() => {
			setIsOpen(false);
			onRemoveColour();
		},
		[onRemoveColour]
	);

	const handleClose = useCallback(
		() => {
			if (nameFormIsValid) {
				setIsOpen(false);
				onClose();
			}
		},
		[nameFormIsValid, onClose]
	);

	// const handlePopperClose = useCallback(
	// 	() => {
	// 		console.log('popperClose');
	// 		handleClose();
	// 		// onCommitChange(); removed as handled by BtPalette, was used by popover at animation end
	// 	},
	// 	[handleClose]
	// );

	return (
		// <Popover
		// 	id="color-picker"
		// 	open={isOpen}
		// 	anchorEl={anchorEl}
		// 	onClose={handleClose}
		// 	anchorOrigin={{
		// 		vertical: 'bottom',
		// 		horizontal: 'left',
		// 	}}
		// 	TransitionProps={{
		// 		onExited: onCommitChange,
		// 	}}
		// >
		<Popper
			id="color-picker"
			open={isOpen}
			anchorEl={localAnchorEl}
			onClose={handleClose}
			sx={{ zIndex: 1000 }}
			// onClose={handlePopperClose}
			// anchorOrigin={{
			// 	vertical: 'bottom',
			// 	horizontal: 'left',
			// }}
			// TransitionProps={{
			// 	onExited: onCommitChange,
			// }}
		>
			<ClickAwayListener
				onClickAway={handleClose}
				// onClickAway={handlePopperClose}
			>
				<Paper>
					<Box sx={{ width: '300px' }}>
						<StyledHexColorPicker
							color={inputColour}
							onChange={onChangeInputColour}
						/>
						<InputInfoContainer>
							<InputContainer>
								<Typography>Hex</Typography>
								{/* <Tooltip interactive title="A hexadecimal colour value">
							<IconButton
								href="https://en.wikipedia.org/wiki/Web_colors"
								target="_blank"
								sx={{ height: '18px', width: '18px' }}
							>
								<Information />
							</IconButton>
						</Tooltip> */}
								<StyledHexColorInput
									color={inputColour}
									onChange={onChangeInputColour}
									prefixed
								/>
							</InputContainer>

							{contrastStatusAttributes && (
								<IconBadge
									badgeColour={
										contrastStatusAttributes.backgroundColour
									}
									badgeIcon={
										contrastStatusAttributes.iconPath
									}
									colour={contrastStatusAttributes.colour}
									componentIcon={mdiContrastCircle}
									tooltipText={
										contrastStatusAttributes.tooltipText
									}
								/>
							)}
						</InputInfoContainer>
						{/* {withTags && (
							<Tags onChange={onChangeTags} tags={tags} />
						)} */}
						<NameForm
							name={name}
							names={names}
							onChange={setTempName}
							setNameFormIsValid={setNameFormIsValid}
						/>

						{/* <BtChipsDisplay
					size="small"
					value={names.slice(0, 11)}
					onClick={() => {
						console.log('clicked');
					}}
				/> */}
					</Box>
					<DialogActions>
						<Box
							sx={{
								// margin: '8px',
								// width: '100%',
								display: 'flex',
								gap: '10px',
								// justifyContent: 'space-between',
							}}
						>
							<Button
								// fullWidth
								color="error"
								disableElevation
								variant="outlined"
								startIcon={<Delete />}
								onClick={handleRemoveClick}
							>
								{newColour ? 'cancel' : 'delete colour'}
							</Button>
							{/* {newColour && ( */}
							<Button
								sx={{ width: '110px' }}
								// fullWidth
								// color="error"
								disableElevation
								variant="contained"
								startIcon={newColour ? <Plus /> : <Check />}
								onClick={handleClose}
								disabled={!nameFormIsValid}
							>
								{newColour ? 'add' : 'ok'}
							</Button>
							{/* )} */}
						</Box>
					</DialogActions>
				</Paper>
			</ClickAwayListener>
			{/* </BtPopper> */}
		</Popper>
		// {/* </Popover> */}
	);
});

ColourPicker.propTypes = {
	anchorEl: PropTypes.object,
	inputColour: PropTypes.string,
	onChangeInputColour: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	onRemoveColour: PropTypes.func.isRequired,
	onChangeName: PropTypes.func.isRequired,
	newColour: PropTypes.bool,
	name: PropTypes.string,
	names: PropTypes.arrayOf(PropTypes.string),
};

export { ColourPicker };
