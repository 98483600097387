import { useEffect } from 'react';
import { useNavContext } from '../../context/ContextManager';
import FormContainer from './FormContainer';
import { user_settings_nav_item } from './UserSettingsNavItem';
import user_settings_options from './UserSettingsOptions';
import UserChangePassword from './UserChangePassword';

export default function Security() {
	const { setBreadcrumbs, setContextualNav } = useNavContext();

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'User Settings', link: '/UserSettings' },
				{ text: 'Security', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([
				...user_settings_nav_item,
				...user_settings_options,
			]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	return (
		<FormContainer maxWidth="xs" text="Change password">
			<UserChangePassword />
		</FormContainer>
	);
}
