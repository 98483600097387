import {
	Button,
	IconButton,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import _ from 'lodash';

import Color from 'color';
import { InputBase, styled } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

const Input = styled(InputBase)(({ theme }) => ({
	backgroundColor: Color(theme.palette.text.solid)
		.fade(0.92)
		.toString(),
	borderRadius: 6,
	height: 22.5,
	marginLeft: -4,
	padding: '0 4px',
}));

const validationOptions = {
	String: [
		{ type: 'yup.min', label: 'Minimum', hasValue: true },
		{ type: 'yup.max', label: 'Maximum', hasValue: true },
		{ type: 'yup.uuid', label: 'UUID', hasValue: false },
	],
	Number: [
		{ type: 'yup.min', label: 'Minimum', hasValue: true },
		{ type: 'yup.max', label: 'Maximum', hasValue: true },
		{ type: 'yup.posative', label: 'Posative', hasValue: false },
		{ type: 'yup.integer', label: 'Integer', hasValue: false },
	],
};

function ValidationLine({
	validation,
	removeValidation,
	unusedValidations,
	onUpdate,
	index,
	disabled,
}) {
	const [availableValidations, setAvailableValidations] = useState([]);
	const [ready, setReady] = useState(false);
	const [type, setType] = useState(null);
	const [value, setValue] = useState(null);
	const [message, setMessage] = useState(null);
	const [valueDisabled, setValueDisabled] = useState(true);

	useEffect(
		() => {
			setType(validation.type);
			setValue(validation.value);
			setMessage(validation.message);

			setAvailableValidations([
				validation.validator,
				...unusedValidations,
			]);

			setValueDisabled(!validation.validator.hasValue);

			setReady(true);
		},
		[validation, unusedValidations]
	);

	const handleRemove = () => {
		removeValidation(index, type);
	};

	const handleValidationChange = event => {
		setType(event.target.value);

		var update = [event.target.value];
		if (validation.validator.hasValue) {
			update.push(parseInt(value));
		}
		if (message !== '') {
			update.push(message);
		}
		onUpdate(index, update);

		const newValidation = _.findLast(unusedValidations, {
			type: event.target.value,
		});
		setValueDisabled(!newValidation.hasValue);
		if (!newValidation.hasValue) {
			setValue('');
		}
	};

	const handleValueChange = event => {
		setValue(event.target.value);

		var update = [validation.type];
		if (validation.validator.hasValue) {
			update.push(parseInt(event.target.value));
		}
		if (message !== '') {
			update.push(message);
		}
		onUpdate(index, update);
	};

	const handleMessageChange = event => {
		setMessage(event.target.value);

		var update = [validation.type];
		if (validation.validator.hasValue) {
			update.push(parseInt(value));
		}
		if (event.target.value !== '') {
			update.push(event.target.value);
		}
		onUpdate(index, update);
	};

	return (
		<TableRow
			sx={{
				'&:last-child td, &:last-child th': {
					border: 0,
				},
			}}
		>
			<TableCell component="th" scope="row">
				<IconButton
					size="small"
					onClick={handleRemove}
					disabled={disabled}
				>
					<CancelIcon />
				</IconButton>
			</TableCell>
			<TableCell>
				{ready && (
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={type}
						label="Age"
						onChange={handleValidationChange}
						variant="standard"
						sx={{ width: '100%' }}
						disabled={disabled}
					>
						{availableValidations.map(({ type, label }) => (
							<MenuItem key={type} value={type}>
								{label}
							</MenuItem>
						))}
					</Select>
				)}
			</TableCell>
			<TableCell>
				<Input
					type="number"
					disabled={valueDisabled || disabled}
					defaultValue={validation?.value}
					onChange={handleValueChange}
				/>
			</TableCell>
			<TableCell>
				<Input
					defaultValue={validation?.message}
					onChange={handleMessageChange}
					disabled={disabled}
				/>
			</TableCell>
		</TableRow>
	);
}

export default function BtValidationBuilder({
	title,
	valueType,
	currentValidation,
	onChange,
	disabled,
}) {
	const [validationLines, setValidationLines] = useState([]);
	const [unusedValidations, setUnsuedValidations] = useState([]);
	const [disableAdd, setDisableAdd] = useState(true);
	const [newValidation, setNewValidation] = useState([]);

	useEffect(
		() => {
			const typeValidations = validationOptions[valueType];
			if (!typeValidations) {
				throw new Error(`Unsupported value type: ${valueType}`);
			}

			var newUnused = [];
			var newValidators = [];

			typeValidations.forEach(valOption => {
				const matchingCurrent = _.find(currentValidation, o => {
					return o[0] === valOption.type;
				});

				if (matchingCurrent) {
					if (valOption.hasValue) {
						newValidators.push({
							validator: valOption,
							type: matchingCurrent[0],
							value: matchingCurrent[1],
							message: matchingCurrent[2],
						});
					} else {
						newValidators.push({
							validator: valOption,
							type: matchingCurrent[0],
							value: null,
							message: matchingCurrent[1],
						});
					}
				} else {
					newUnused.push(valOption);
				}
			});

			setUnsuedValidations(newUnused);
			setValidationLines(newValidators);

			setNewValidation(currentValidation);

			setDisableAdd(newUnused.length === 0 ? true : false);
		},
		[valueType, currentValidation]
	);

	const handleAddValidation = () => {
		if (unusedValidations.length > 0) {
			const newVal = {
				validator: unusedValidations[0],
				type: unusedValidations[0].type,
				value: unusedValidations[0].hasValue ? 0 : null,
				message: '',
			};
			setValidationLines([...validationLines, newVal]);

			const newUnsued = _.filter([...unusedValidations], o => {
				return o.type !== newVal.type;
			});
			setUnsuedValidations(newUnsued);
			const updatedValidation = [...newValidation];
			if (newVal.validator.hasValue) {
				updatedValidation.push([newVal.type, 0, '']);
			} else {
				updatedValidation.push([newVal.type, '']);
			}
			setNewValidation(updatedValidation);
			if (onChange) {
				onChange(updatedValidation);
			}

			setDisableAdd(newUnsued.length === 0 ? true : false);
		}
	};

	const removeValidation = (index, validationType) => {
		const newValidations = _.filter([...validationLines], o => {
			return o.type !== validationType;
		});
		setValidationLines(newValidations);

		const updatedValidation = [...newValidation];
		updatedValidation.splice(index, 1);
		setNewValidation(updatedValidation);
		if (onChange) {
			onChange(updatedValidation);
		}

		var newUnused = [];
		const typeValidations = validationOptions[valueType];
		typeValidations.forEach(valOption => {
			const matchingCurrent = _.find(newValidations, {
				type: valOption.type,
			});

			if (!matchingCurrent) {
				newUnused.push(valOption);
			}
		});

		setUnsuedValidations(newUnused);

		setDisableAdd(newUnused.length === 0 ? true : false);
	};

	const onLineUpdate = (index, update) => {
		const updatedValidation = [...newValidation];
		updatedValidation[index] = update;
		setNewValidation(updatedValidation);

		if (onChange) {
			onChange(updatedValidation);
		}
	};

	return (
		<>
			{title && <Typography variant="subtitle">{title}</Typography>}
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell>Remove</TableCell>
						<TableCell>Validation</TableCell>
						<TableCell>Value</TableCell>
						<TableCell>Message</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{validationLines.map((validation, index) => (
						<ValidationLine
							index={index}
							key={validation.type}
							validation={validation}
							removeValidation={removeValidation}
							unusedValidations={unusedValidations}
							onUpdate={onLineUpdate}
							disabled={disabled}
						/>
					))}
				</TableBody>
			</Table>
			<Button
				variant="outlined"
				onClick={handleAddValidation}
				disabled={disableAdd || disabled}
			>
				Add Validation
			</Button>
		</>
	);
}
