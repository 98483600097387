// This import is used on the front end only,
// the back end uses the below const

import { pie } from 'd3-shape';
import {
	BAR_CHART,
	GAUGE,
	INFO_CARD,
	LINE_CHART,
	PIE_CHART,
	RADIAL_BAR_CHART,
	STATUS_CARD,
	TABLE,
	STATUS_TIMELINE_CHART,
	VIS_LIST_TYPES,
} from '../../views/Insights/InsightPage/visualisationConstants';

import * as yup from 'yup';

// This a hard coded const of the above import,
// we use this on the back end instead of the imports
// const
//     BAR_CHART= 'BarChart',
//     GAUGE= 'Gauge',
//     INFO_CARD = 'InfoCard',
//     LINE_CHART = 'LineChart',
//     PIE_CHART = 'PieChart',
//     RADIAL_BAR_CHART = 'RadialBarChart',
//     STATUS_CARD = 'StatusCard',
//     TABLE = 'Table',
//     VIS_LIST_TYPES = [
//       'BarChart',
//       'Gauge',
//       'ImageGallery',
//       'InfoCard',
//       'LineChart',
//       'PieChart',
//       'RadialBarChart',
//       'StatusCard',
//       'Table'
//     ]

// pie chart variants
const PIE = 'pie';
const DOUGHNUT = 'doughnut';
const TWO_SERIES = 'twoSeries';
const pirChartVariants = [PIE, DOUGHNUT, TWO_SERIES];

// gauge chart variants
const RADIAL = 'radial';
const LINEAR = 'linear';
const gaugeVariants = [RADIAL, LINEAR];

// param label types, ordered alphabetically
const NONE = 'none';
const NAME = 'name';
const PERCENTAGE = 'percentage';
const PROGRESS = 'progress';
const ABSOLUTE = 'absolute';
// array of param label types
const paramLabelTypes = [NONE, NAME, PERCENTAGE, PROGRESS, ABSOLUTE];

const DECIMAL_PLACES_0 = {
	yupString: 'none',
	number: 0,
};
const DECIMAL_PLACES_1 = {
	yupString: '0.0',
	number: 1,
};
const DECIMAL_PLACES_2 = {
	yupString: '0.00',
	number: 2,
};
const DECIMAL_PLACES_3 = {
	yupString: '0.000',
	number: 3,
};
const DECIMAL_PLACES_4 = {
	yupString: '0.0000',
	number: 4,
};
const decimalPlaces = ['none', '0.0', '0.00', '0.000', '0.0000'];

// const decimalPlaces = [
// 	DECIMAL_PLACES_0,
// 	DECIMAL_PLACES_1,
// 	DECIMAL_PLACES_2,
// 	DECIMAL_PLACES_3,
// 	DECIMAL_PLACES_4,
// ].map(({ yupString }) => yupString);

/**
 ******************** partial vis options property schemas ********************
 */
const genericParamsValuePrimaryAxisDefaults = {
	label: null,
	prefix: null,
	suffix: null,
};

const genericParamsDefaults = {
	...genericParamsValuePrimaryAxisDefaults,
	value: null,
};

const genericParamsDefaultValues = [genericParamsDefaults];

const genericTextParam = yup
	.string()
	.max(200)
	.nullable()
	.default(null);

const genericParamsValuePrimaryAxis = {
	label: genericTextParam,
	prefix: genericTextParam,
	suffix: genericTextParam,
};

const genericParams = {
	value: genericTextParam,
	...genericParamsValuePrimaryAxis,
};

const genericParamSchema = yup
	.object({
		...genericParams,
	})
	.default(genericParamsDefaults)
	.noUnknown();

const genericLabelSchema = {
	display_percentage_value: yup.boolean().default(true),
	// display_on_hover: yup.boolean().default(false),
	display_absolute_value: yup.boolean().default(false),
	display_name: yup.boolean().default(true),
	percentage_decimal_places: yup
		.number()
		.min(0)
		.max(4)
		.default(0),
	absolute_decimal_places: yup
		.number()
		.min(0)
		.max(4)
		.default(0),
	disabled: yup
		.boolean()
		.default(false)
		.nullable(),
};

const genericLabelSchemaDefaults = {
	display_percentage_value: true,
	// display_on_hover: false,
	display_absolute_value: false,
	display_name: true,
	percentage_decimal_places: 0,
};

const pieChartLabelSchema = yup
	.object({
		...genericLabelSchema,
	})
	.default(genericLabelSchemaDefaults);

const radialBarChartLabelSchema = yup
	.object({
		...genericLabelSchema,
		display_progress_value: yup
			.boolean()
			.default(false)
			.nullable(),
	})
	.default({
		...genericLabelSchemaDefaults,
		display_progress_value: false,
	});

// const label = yup
// 	.object({
// 		display_percentage_value: yup.boolean().default(true),
// 		// display_on_hover: yup.boolean().default(false),
// 		display_absolute_value: yup.boolean().default(false),
// 		display_name: yup.boolean().default(true),
// 		percentage_decimal_places: yup
// 			.number()
// 			.min(0)
// 			.max(4)
// 			.default(0),
// 		disabled: yup
// 			.boolean()
// 			.default(false)
// 			.nullable(),
// 	})
// 	.default({
// 		display_percentage_value: true,
// 		// display_on_hover: false,
// 		display_absolute_value: false,
// 		display_name: true,
// 		percentage_decimal_places: 0,
// 	});

const legend = yup
	.object({
		disabled: yup
			.boolean()
			.default(false)
			.nullable(),
	})
	.noUnknown()
	.nullable()
	.default({ disabled: false });

const ordinalAxis = yup
	.object({
		param: yup
			.object({
				...genericParams,
				orientation: yup
					.string()
					.oneOf(['vertical', 'horizontal'])
					.nullable()
					.default('horizontal'),
			})
			.noUnknown(),
	})
	.noUnknown()
	.nullable()
	.default({ ...genericParamsDefaults, orientation: null });

const axisBoundsSchema = yup
	.object({
		min: yup.lazy(
			value =>
				typeof value === 'string'
					? yup
							.string()
							.oneOf(['auto'])
							.nullable()
							.default(null)
					: yup
							.number()
							.nullable()
							.default(null)
		),
		max: yup.lazy(
			value =>
				typeof value === 'string'
					? yup
							.string()
							.oneOf(['auto'])
							.nullable()
							.default(null)
					: yup
							.number()
							.nullable()
							.default(null)
		),
	})
	.noUnknown()
	.nullable()
	.default({ min: null, max: null });

const valuePrimaryAxis = yup
	.object({
		axisBounds: axisBoundsSchema,
		...genericParamsValuePrimaryAxis,
	})
	.noUnknown()
	.nullable()
	.default({
		...genericParamsValuePrimaryAxisDefaults,
		axisBounds: { min: null, max: null },
	});

const axisRangeSchema = yup.object({
	value: yup
		.number()
		.nullable()
		.default(null),

	colour: yup
		.string()
		.nullable()
		.default(null),
});

const gaugeValuePrimaryAxis = yup
	.object({
		// axis_range_origin: yup
		// 	.number()
		// 	.nullable()
		// 	.default(null),

		axis_ranges: yup
			.array()
			.of(axisRangeSchema)
			.nullable()
			.default(null),
		axisBounds: axisBoundsSchema,
		...genericParamsValuePrimaryAxis,
	})
	.noUnknown()
	.nullable()
	.default({
		...genericParamsValuePrimaryAxisDefaults,
		axisRanges: null,
		axisBounds: { min: null, max: null },
	});

const axisSchema = yup
	.string()
	.oneOf(['primary', 'secondary'])
	.nullable()
	.default('primary');

const lineChartParams = yup
	.array()
	.of(
		yup
			.object({
				...genericParams,
				axis: axisSchema,
			})
			.noUnknown()
	)
	.nullable()
	.default(null);

const pieChartParam = yup
	.object({
		...genericParams,
		colour_tag: yup
			.string()
			.nullable()
			.default(null),
	})
	.noUnknown()
	.default({
		...genericParamsDefaultValues,
		colour_tag: null,
	});

const pieChartParams = yup
	.array()
	.of(pieChartParam)
	.nullable()
	.default(null);

const radialBarChartParam = yup
	.object({
		...genericParams,
		colour_tag: yup
			.string()
			.nullable()
			.default(null),
		min: yup
			.number()
			.required()
			.default(0),
		max: yup
			.number()
			.required()
			.default(10),
		label_type: yup
			.string()
			.oneOf(paramLabelTypes)
			.nullable()
			.default('percentage'),
		label_value_decimal_places: yup
			.string()
			.oneOf(decimalPlaces)
			.nullable()
			.default('none'),
	})
	.noUnknown()
	.default({
		...genericParamsDefaultValues,
		min: 0,
		max: 10,
		label_type: 'percentage',
		label_value_decimal_places: 'none',
	});

const radialBarChartParams = yup
	.array()
	.of(radialBarChartParam)
	.default([]);

export const tableParams = yup
	.array()
	.of(
		yup
			.object({
				...genericParams,
				axis: axisSchema,
				hidden: yup
					.boolean()
					.nullable()
					.default(null),
			})
			.noUnknown()
	)
	.nullable()
	.default(null);

/**
 ******************** root property schemas ********************
 */

const type = yup.string().oneOf(VIS_LIST_TYPES);

// const visDataSourceItem = yup
// 	.object({
// 		transform: yup
// 			.string()
// 			.uuid(),
// 		uuid: yup
// 			.string()
// 			.uuid(),
// 		dataOrigin: yup
// 			.string()
// 			.nullable()
// 			.default(null),
// 	})
// 	.nullable()
//     .default(null);

const visDataSourceItem = yup
	.object({
		transform: yup.lazy(
			value =>
				!value
					? yup
							.string()
							.nullable()
							.default(null)
					: yup.string().uuid()
		),
		uuid: yup.lazy(
			value =>
				!value
					? yup
							.string()
							.nullable()
							.default(null)
					: yup.string().uuid()
		),
		dataOrigin: yup
			.string()
			.nullable()
			.default(null),
	})
	.nullable()
	.default(null);

const titleSubtitle = yup
	.string()
	.min(3)
	.max(50)
	.required();

/**
 ******************** vis options schemas for different visualisation types ********************
 */

const gaugeSchema = yup
	.object({
		params: yup
			.array()
			.of(genericParamSchema)
			.nullable()
			.default(null),
		// .default(genericParamsDefaultValues),
		valuePrimaryAxis: gaugeValuePrimaryAxis,
		variant: yup.object({
			type: yup
				.string()
				.oneOf(gaugeVariants)
				.default(RADIAL),
		}),
	})
	.noUnknown();

// const imageGallerySchema = '';

const infoCardSchema = yup
	.object({
		text: yup
			.string()
			.default(null)
			.nullable(),
	})
	.noUnknown();

const lineChartSchema = yup
	.object({
		ordinalAxis: ordinalAxis,
		valuePrimaryAxis: valuePrimaryAxis,
		legend: legend,
		params: lineChartParams,
	})
	.noUnknown();

const radialBarChartSchema = yup
	.object({
		// ordinalAxis: ordinalAxis,
		// valuePrimaryAxis: valuePrimaryAxis,
		legend: legend,
		params: radialBarChartParams,
		// label: radialBarChartLabelSchema,
		background: yup.object({
			use_series_colour: yup
				.boolean()
				.default(true)
				.nullable(),
			disabled: yup
				.boolean()
				.default(false)
				.nullable(),
		}),
	})
	.noUnknown();

const pieChartSchema = yup
	.object({
		legend: legend,
		params: yup
			.array()
			.of(pieChartParam)
			.nullable()
			.default(null),
		label: pieChartLabelSchema,
		variant: yup.object({
			type: yup
				.string()
				.oneOf(pirChartVariants)
				.default(PIE),
		}),
	})
	.noUnknown();

const statusCardSchema = yup
	.object({
		params: yup
			.array()
			.of(genericParamSchema)
			.nullable()
			.default(null),
	})
	.noUnknown();

// svg component for future development
// const svgSchema = ''

const tableSchema = yup
	.object({
		params: tableParams,
	})
	.noUnknown();

// TODO: Add status timeline schema
const statusTimelineSchema = yup
	.object({
		idField: yup.string().required(),
		startTimeField: yup.string().required(),
		endTimeField: yup.string().required(),
		statusField: yup.string().required(),
	})
	.noUnknown();

/**
 ******************** visualisation & visualisations schemas ********************
 */

const visualisationRootPropertiesSchema = yup
	.object({
		subtitle: titleSubtitle,
		title: titleSubtitle,
		type: type.required(),
	})
	.noUnknown();

const visualisationSchema = yup.object({
	datasource: visDataSourceItem,
	subtitle: titleSubtitle,
	title: titleSubtitle,
	type: type.required(),
	palette: yup
		.object({
			uuid: yup
				.string()
				// .uuid()
				.nullable()
				.default(null),
		})
		.default({ uuid: null })
		.required(),
	uuid: yup
		.string()
		.uuid()
		.required(),
	vis_options: yup
		.object()
		.when('type', {
			is: BAR_CHART,
			then: lineChartSchema,
		})
		.when('type', {
			is: GAUGE,
			then: gaugeSchema,
		})
		.when('type', {
			is: INFO_CARD,
			then: infoCardSchema,
		})
		.when('type', {
			is: LINE_CHART,
			then: lineChartSchema,
		})
		.when('type', {
			is: PIE_CHART,
			then: pieChartSchema,
		})
		.when('type', {
			is: RADIAL_BAR_CHART,
			then: radialBarChartSchema,
		})
		.when('type', {
			is: STATUS_CARD,
			then: statusCardSchema,
		})
		.when('type', {
			is: TABLE,
			then: tableSchema,
		})
		.when('type', {
			is: STATUS_TIMELINE_CHART,
			then: statusTimelineSchema,
		}),
});

const tileSchema = visualisationSchema.nullable().default(null);

const visualisationsSchema = yup
	.array()
	.of(visualisationSchema)
	.nullable();

// module.exports = {
// 	visualisationSchema,
// 	conditionalFieldTest,
// 	visualisationsSchema,
// };

// schemas
export {
	tileSchema,
	visualisationRootPropertiesSchema,
	visualisationSchema,
	visualisationsSchema,
	lineChartSchema,
	infoCardSchema,
	statusCardSchema,
	pieChartSchema,
	tableSchema,
	gaugeSchema,
	radialBarChartSchema,
	radialBarChartParam,
};

// Pie chart variants
export { DOUGHNUT, PIE, TWO_SERIES, pirChartVariants };

// Gauge chart variants
export { RADIAL, LINEAR, gaugeVariants };

// paramLabelTypes
export { ABSOLUTE, NAME, NONE, PERCENTAGE, PROGRESS, paramLabelTypes };
// decimalPlaces
export {
	DECIMAL_PLACES_0,
	DECIMAL_PLACES_1,
	DECIMAL_PLACES_2,
	DECIMAL_PLACES_3,
	DECIMAL_PLACES_4,
	decimalPlaces,
};
