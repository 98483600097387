import { UPDATE_RECORD_API } from '../constants';
import { lookupEndpoint } from '../lookupTables/apiEndpoints';

const handleChangeDetails = async ({
	apiUuidParamPayload,
	moduleFullName,
	module,
	recordHistoryGet,
	payload,
}) => {
	// Initialise return value
	const result = {};
	result.variant = 'success';

	try {
		// get the request from lookup
		const updateRecordEndpoint = lookupEndpoint(module, UPDATE_RECORD_API);

		// dispatch the request or raise error if lookup has returned null
		if (updateRecordEndpoint) {
			await updateRecordEndpoint({
				...apiUuidParamPayload,
				update: payload,
			});

			// Update return value
			result.text = `${moduleFullName} updated`;
		} else {
			throw new Error('API request not defined');
		}
	} catch (error) {
		console.log(error);
		result.text = `Error updating ${moduleFullName}`;
		result.variant = 'error';
	} finally {
		// Reload history data
		recordHistoryGet();
		return result;
	}
};

export { handleChangeDetails };
