import React, {
	forwardRef,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import PropTypes from 'prop-types';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
	DateTimePicker,
	DesktopDatePicker,
	LocalizationProvider,
	TimePicker,
} from '@mui/x-date-pickers';
import '@mui/lab';
import { format } from 'date-fns';
import { TextField } from '@mui/material';

import { DATE, DATETIME, TIME, TIME_FORMAT } from '../../utils/timeConstants';
import useDateFormat from '../../hooks/useDateFormat';

export const BtDateTimePicker = forwardRef(
	(
		{
			ampm,
			ampmInClock,
			defaultValue,
			fieldProps,
			label,
			onChange,
			renderInput,
			value,
			variant,
			...other
		},
		ref
	) => {
		const dateFormat = useDateFormat();

		const [_value, setValue] = useState();

		const convertValue = useCallback(
			val => {
				if (typeof val === 'number') {
					return format(new Date(val), dateFormat);
				}

				return val;
			},
			[dateFormat]
		);

		const handleOnChange = useCallback(
			newDate => {
				setValue(newDate);

				const timestamp = +newDate;
				onChange(isNaN(timestamp) ? null : timestamp);
			},
			[onChange]
		);

		const textFieldElement = useCallback(
			params => (
				<TextField
					size="small"
					variant="standard"
					{...params}
					{...fieldProps}
				/>
			),
			[fieldProps]
		);

		const componentLookup = useMemo(
			() => ({
				[DATE]: {
					Component: DesktopDatePicker,
					props: { inputFormat: dateFormat, label: label || 'Date' },
				},
				[DATETIME]: {
					Component: DateTimePicker,
					props: {
						inputFormat: `${dateFormat} ${TIME_FORMAT}`,
						label: label || 'Date & Time',
					},
				},
				[TIME]: {
					Component: TimePicker,
					props: {
						inputFormat: TIME_FORMAT,
						label: label || 'Time',
					},
				},
			}),
			[dateFormat, label]
		);

		const elementData = useMemo(() => componentLookup[variant], [
			componentLookup,
			variant,
		]);

		useEffect(
			() => {
				setValue(convertValue(value ?? defaultValue));
			},
			[convertValue, defaultValue, value]
		);

		return (
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<elementData.Component
					ref={ref}
					{...elementData.props}
					ampm={ampm}
					ampmInClock={ampmInClock}
					disableMaskedInput
					onChange={handleOnChange}
					renderInput={renderInput ?? textFieldElement}
					value={_value}
					{...other}
				/>
			</LocalizationProvider>
		);
	}
);

BtDateTimePicker.defaultProps = {
	ampm: false,
	ampmInClock: false,
	value: new Date(),
	variant: DATETIME,
};

const dateType = PropTypes.oneOfType([
	PropTypes.number,
	PropTypes.object,
	PropTypes.string,
]);

BtDateTimePicker.propTypes = {
	ampm: PropTypes.bool,
	ampmInClock: PropTypes.bool,
	defaultValue: dateType,
	fieldProps: PropTypes.object,
	label: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	renderInput: PropTypes.element,
	value: dateType,
	variant: PropTypes.oneOf([DATE, DATETIME, TIME]),
};

BtDateTimePicker.displayName = 'BtDateTimePicker';

export default BtDateTimePicker;
