import { lookupEndpoint } from '../lookupTables/apiEndpoints';

const handlePublish = async ({
	apiUuidParamPayload,
	moduleFullName,
	module,
	recordHistoryGet,
}) => {
	// Initialise return value
	const result = {};
	result.variant = 'success';

	try {
		// get the request from lookup
		const publishRecordEndpoint = lookupEndpoint(module, 'publishRecord');

		// dispatch the request or raise error if lookup has returned null
		if (publishRecordEndpoint) {
			await publishRecordEndpoint(apiUuidParamPayload);

			// Update return value
			result.text = `${moduleFullName} published`;
		} else {
			throw new Error('');
		}
	} catch (error) {
		console.log(error);
		result.text = `Error publishing ${moduleFullName}`;
		result.variant = 'error';
	} finally {
		// Reload history data
		recordHistoryGet();
		return result;
	}
};

export { handlePublish };
