import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router';
import { getInsightCollection } from '../../../InsightCollection/utils';
import BtLoading from '../../../../../components/generic/BtLoading';
import BtError from '../../../../../components/generic/BtError';
import { useSnackbar } from 'notistack';
import { EditComponentForm } from './components/formComponents/EditComponentForm';
import { handleUpdateDraft } from './insightComponentEditUtils';
import { EditFormContextProvider } from './EditFormContext';

export default function InsightComponentEdit({
	component,
	onClose,
	palette,
	refreshCollectionData,
	paletteConfig,
	collectionPalettes,
	// onComponentUpdate,
}) {
	const { id, version } = useParams();
	const location = useLocation();
	const [insightLoading, setInsightLoading] = useState(true);
	const [insightCollection, setInsightCollection] = useState(null);
	const [insightError, setInsightError] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	useEffect(
		() => {
			const loadCollection = async () => {
				try {
					let collection;
					collection = await getInsightCollection(
						id,
						location,
						version
					);
					setInsightCollection(collection);
					setInsightLoading(false);
				} catch (error) {
					console.log(error);
					setInsightLoading(false);
					setInsightError(true);
				}
			};

			loadCollection();
		},
		[component, id, location, version]
	);

	const dataSourceOptions = useMemo(
		() => {
			if (insightCollection) {
				// console.log('build dataSourceOptions');
				return insightCollection.datasources.map(({ uuid }) => {
					return {
						label: uuid,
						value: uuid,
					};
				});
			} else {
				return [];
			}
		},
		[insightCollection]
	);

	const handleSubmit = useCallback(
		async componentUpdate => {
			console.log('handleSubmit, componentUpdate:', componentUpdate);
			await handleUpdateDraft(
				insightCollection?.visualisations,
				componentUpdate,
				id,
				enqueueSnackbar,
				onClose,
				refreshCollectionData
			);
		},
		[enqueueSnackbar, id, insightCollection, onClose, refreshCollectionData]
	);

	useEffect(() => console.log(component), [component]);

	if (insightError) {
		return <BtError />;
	}

	return (
		<BtLoading loading={insightLoading || !dataSourceOptions}>
			<EditFormContextProvider
				palette={palette}
				paletteConfig={paletteConfig}
				collectionPalettes={collectionPalettes}
				collection={insightCollection}
				component={component}
			>
				<EditComponentForm
					defaultValues={component}
					palette={palette}
					handleSubmit={handleSubmit}
					onClose={onClose}
					dataSources={insightCollection?.datasources ?? []}
				/>
			</EditFormContextProvider>
		</BtLoading>
	);
}

InsightComponentEdit.propTypes = {
	component: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	refreshCollectionData: PropTypes.func.isRequired,
	palette: PropTypes.array.isRequired,
};
