import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import useFetch from '../../../../hooks/useFetch';
import { insightCollectionTileGet } from '../../../../API';
import InsightContent from './InsightContent';
import { TileContext } from '../components/TileContext';

export default function InsightCollectionTile() {
	const { tileData, setError } = useContext(TileContext);
	const {
		data: insightTile,
		error: insightTileError,
		loading: insightTileLoading,
		request: insightTileRequest,
	} = useFetch(insightCollectionTileGet);

	useEffect(
		() => {
			if (tileData.data.insight_collection !== '') {
				insightTileRequest({
					insightUuid: tileData.data.insight_collection,
				});
			}
		},
		[insightTileRequest, tileData.data.insight_collection]
	);

	useEffect(
		() => {
			if (insightTileError) setError({ serverError: true });
		},
		[insightTileError, setError]
	);

	// useEffect(
	// 	() => {
	// 		if (!insightTileLoading)
	// 			if (
	// 				!insightTileError &&
	// 				tileData.data.insight_collection !== ''
	// 			) {
	// 				setClickDisabled && setClickDisabled(false);
	// 				// } else if (insightTileError) {
	// 				// 	setError({ serverError: true });
	// 				// } else {
	// 				// 	setError({ insightTileConfigRequired: true });
	// 			}
	// 	},
	// 	[
	// 		insightTileError,
	// 		insightTileLoading,
	// 		setClickDisabled,
	// 		setError,
	// 		tileData.data.insight_collection,
	// 	]
	// );

	return (
		<InsightContent
			// clickDisabled={clickDisabled}
			tileVariant={tileData.variant}
			title={tileData.title}
			subtitle={tileData.subtitle}
			loading={insightTileLoading}
			insightTile={insightTile}
			collectionUuid={tileData.data.insight_collection}
		/>
	);
}

// InsightCollectionTile.propTypes = {
// 	setClickDisabled: PropTypes.func.isRequired,
// 	clickDisabled: PropTypes.bool,
// };
