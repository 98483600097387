import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	CircularProgress,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
} from '@mui/material';
import CreateIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import BtDialog from '../../../../../components/generic/BtDialog';
import {
	BtForm,
	BtFormActionsContainer,
	BtFormContent,
	BtFormTextField,
	withFormContextMethods,
} from '../../../../../components/generic/forms';
import { roleAdd } from '../../../../../API/role.api';
import { Save } from '@mui/icons-material';

const blankDefaults = {
	name: '',
	description: '',
};

const ActionButtons = withFormContextMethods(
	({ formState: { isDirty }, onClose, sending, pageEdit }) => {
		return (
			<>
				<Button variant="outlined" onClick={onClose} disabled={sending}>
					Cancel
				</Button>
				<Button
					variant="contained"
					disabled={!isDirty || sending}
					type="submit"
					startIcon={pageEdit ? <Save /> : <CreateIcon />}
					disableElevation
				>
					{pageEdit && <>Edit Page</>}
					{!pageEdit && <>Add Page</>}
				</Button>
			</>
		);
	}
);

export default function InsightPageDialog({
	open,
	onClose,
	currentPageDetails,
	onPageAdd,
	onPageEdit,
}) {
	const { enqueueSnackbar } = useSnackbar();

	const [sending, setSending] = useState(false);

	const schema = yup.object({
		name: yup
			.string()
			.min(3)
			.required()
			.label('Page Name'),
		description: yup
			.string()
			.nullable()
			.label('Page Description'),
	});

	const onSubmit = async (values, reset) => {
		if (currentPageDetails) {
			onPageEdit(values);
		} else {
			console.log(values.name, values.description);
			onPageAdd(values.name, values.description);
		}
		onClose();
		reset();
	};

	return (
		<BtDialog open={open} onClose={onClose} minwidth="sm" fullWidth>
			{sending && (
				<LinearProgress
					color="secondary"
					style={{
						position: 'absolute',
						top: 0,
						width: '100%',
					}}
				/>
			)}
			{currentPageDetails && <DialogTitle>Edit Page</DialogTitle>}
			{!currentPageDetails && <DialogTitle>Add New Page</DialogTitle>}
			<BtForm
				onSubmit={onSubmit}
				sending={sending}
				validationSchema={schema}
				defaultValues={currentPageDetails || blankDefaults}
			>
				<DialogContent>
					<BtFormContent>
						<BtFormTextField name="name" label="Name" autoFocus />
						<BtFormTextField
							name="description"
							label="Description"
							multiline
						/>
					</BtFormContent>
				</DialogContent>
				<DialogActions>
					<BtFormActionsContainer>
						<ActionButtons
							onClose={onClose}
							sending={sending}
							pageEdit={!!currentPageDetails}
						/>
					</BtFormActionsContainer>
				</DialogActions>
			</BtForm>
		</BtDialog>
	);
}

ActionButtons.propTypes = {
	isDirty: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	sending: PropTypes.bool.isRequired,
};

InsightPageDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};
