import { styled } from '@mui/material';

export const WorkflowActionContainer = styled('div')(() => ({
	display: 'flex',
	justifyContent: 'center',
	margin: '2em 0 1em',
	width: '100%',
}));

export default WorkflowActionContainer;
