import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Typography from '@mui/material/Typography';
import { CircularProgress, TextField } from '@mui/material';

import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/system/Box';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { password_regex, no_spaces_regex } from '../../utils/regexLibrary';

// yup schema for new password
const scheme = yup
	.object({
		newPassword: yup
			.string()
			.required('Password is a required field')
			.matches(no_spaces_regex.regex, no_spaces_regex.error_text, {
				excludeEmptyString: true,
			})
			.matches(password_regex.regex, password_regex.error_text, {
				excludeEmptyString: true,
			})
			.label('New Password'),
		newPasswordConfirm: yup
			.string()
			.required('Password confirmation is a required field')
			.oneOf([yup.ref('newPassword'), null], 'Passwords must match')
			.label('Confirm New Password'),
	})
	.required();

export default function ChangePasswordFrom(props) {
	// React Hook Form
	const {
		getValues,
		control,
		watch,
		handleSubmit,
		reset,
		formState: { touchedFields, errors },
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(scheme),
		defaultValues: { newPassword: '', newPasswordConfirm: '' },
	});

	// Password visibility state
	const [showPasswordValues, setShowPasswordValues] = useState({
		showNewPassword: false,
		showConfirmNewPassword: false,
	});

	// Password visibility
	function handleClickShowNewPassword() {
		setShowPasswordValues({
			...showPasswordValues,
			showNewPassword: !showPasswordValues.showNewPassword,
		});
	}
	function handleClickShowConfirmNewPassword() {
		setShowPasswordValues({
			...showPasswordValues,
			showConfirmNewPassword: !showPasswordValues.showConfirmNewPassword,
		});
	}

	function handleMouseDown(event) {
		event.preventDefault();
	}

	// On form submit call the callback function provided by parent
	function onSubmit() {
		// setSending(true);
		props.handleFormSubmit(getValues('newPasswordConfirm'));
		reset();
		// setSending(false);
	}

	// Icons for password requirements checklist
	function Dot() {
		return <CloseIcon sx={{ fontSize: 'small' }} />;
	}
	function Check() {
		return <CheckCircleIcon color="primary" sx={{ fontSize: 'small' }} />;
	}

	function ChecklistItem(props) {
		const { sx, ...other } = props;
		return (
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					minWidth: '50%',
					p: '0px',
					m: '0px',
					...sx,
				}}
				{...other}
			>
				{props.check}
				<Typography variant="caption" {...other}>
					{props.text}
				</Typography>
			</Box>
		);
	}

	ChecklistItem.propTypes = {
		sx: PropTypes.oneOfType([
			PropTypes.arrayOf(
				PropTypes.oneOfType([
					PropTypes.func,
					PropTypes.object,
					PropTypes.bool,
				])
			),
			PropTypes.func,
			PropTypes.object,
		]),
	};

	return (
		<form key={2} onSubmit={handleSubmit(onSubmit)}>
			<input
				type="hidden"
				id="email"
				name="email"
				autoComplete="email"
				value={props.user_email}
			/>
			{/***************  New Password  ***************/}
			<div style={{ width: '100%' }}>
				<Box
					sx={{
						p: '0px',
						display: 'flex',
						flexWrap: 'wrap',
						justifyContent: 'space-between',
					}}
				>
					<ChecklistItem
						text={'At least 10 characters'}
						check={
							watch('newPassword').length < 10 ? (
								<Dot />
							) : (
								<Check />
							)
						}
					/>
					<ChecklistItem
						text={'Uppercase letters'}
						check={
							!/[A-Z]/.test(watch('newPassword')) ? (
								<Dot />
							) : (
								<Check />
							)
						}
					/>
					<ChecklistItem
						text={'At least one number'}
						check={
							!/\d/.test(watch('newPassword')) ? (
								<Dot />
							) : (
								<Check />
							)
						}
					/>
					<ChecklistItem
						text={'Lowercase letters'}
						check={
							!/[a-z]/.test(watch('newPassword')) ? (
								<Dot />
							) : (
								<Check />
							)
						}
					/>

					<ChecklistItem
						text={'A special character'}
						check={
							!/[!@#$%^.\-&*[\]{}()?"/\\,><';:|_~`+=]/.test(
								watch('newPassword')
							) ? (
								<Dot />
							) : (
								<Check />
							)
						}
					/>
				</Box>
			</div>

			<Controller
				name="newPassword"
				control={control}
				render={({
					field: { ref, ...field },
					fieldState: { error },
				}) => (
					<TextField
						disabled={props.sending}
						{...field}
						key={2}
						variant="standard"
						fullWidth
						margin="normal"
						label="New Password"
						id={field.name}
						type={
							showPasswordValues.showNewPassword
								? 'text'
								: 'password'
						}
						autoComplete="new-password"
						error={
							!!errors.newPassword ||
							!/^\S*$/gm.test(watch('newPassword'))
						}
						helperText={
							error?.message ||
							(!/^\S*$/gm.test(watch('newPassword')) &&
								'No spaces allowed')
						}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowNewPassword}
										onMouseDown={handleMouseDown}
									>
										{showPasswordValues.showNewPassword ? (
											<VisibilityOff />
										) : (
											<Visibility />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				)}
			/>
			{/***************  Confirm New Password  ***************/}
			<Controller
				name="newPasswordConfirm"
				control={control}
				render={({
					field: { ref, ...field },
					fieldState: { error },
				}) => (
					<TextField
						disabled={props.sending}
						{...field}
						key={2}
						variant="standard"
						fullWidth
						margin="normal"
						label="Confirm New Password"
						id={field.name}
						type={
							showPasswordValues.showConfirmNewPassword
								? 'text'
								: 'password'
						}
						autoComplete="new-password"
						error={!!error}
						helperText={
							error?.message
							// 'Passwords must match'
						}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={
											handleClickShowConfirmNewPassword
										}
										onMouseDown={handleMouseDown}
									>
										{showPasswordValues.showConfirmNewPassword ? (
											<VisibilityOff />
										) : (
											<Visibility />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				)}
			/>

			<Button
				sx={{ mt: '20px' }}
				size="large"
				variant="contained"
				fullWidth
				disabled={
					Object.keys(errors).length > 0 ||
					(Object.keys(errors).length === 0 &&
						Object.keys(touchedFields).length === 0) ||
					props.sending ||
					props.disableForm
				}
				type="submit"
				disableElevation
				// startIcon={
				// 	props.sending ? (
				// 		<CircularProgress size={20} />
				// 	) : (
				// 		<SaveIcon />
				// 	)
				// }
				startIcon={props.sending && <CircularProgress size={20} />}
			>
				{props.sending ? 'submitting' : 'change password'}
			</Button>
		</form>
	);
}
