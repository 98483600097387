import React, {
	useState,
	useEffect,
	createContext,
	useContext,
	useMemo,
} from 'react';

import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { lightTheme, darkTheme } from '../style/muiTheme';
import { useMediaQuery } from '@mui/material';

const ThemeContext = createContext(null);

const useThemeContext = () => {
	const context = useContext(ThemeContext);
	if (context === undefined) {
		throw new Error('useThemeContext was used outside of its Provider');
	}
	return context;
};

function ThemeContextProvider({ children }) {
	const [themeDark, setThemeDark] = useState(false);
	const [themeMode, setThemeMode] = useState('light'); // light || dark || auto

	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

	// Load the users theme value from cookie
	useEffect(
		() => {
			// Get the theme setting from local storage
			const theme = localStorage.getItem('preferred-theme');
			if (theme) {
				if (theme === 'dark') {
					setThemeMode('dark');
					setThemeDark(true);
				} else if (theme === 'auto') {
					setThemeMode('auto');
					setThemeDark(prefersDarkMode ? true : false);
				} else {
					setThemeMode('light');
					setThemeDark(false);
				}
			} else {
				localStorage.setItem('preferred-theme', 'auto');
				setThemeDark(prefersDarkMode ? true : false);
			}
		},
		[prefersDarkMode]
	);

	const currentTheme = useMemo(() => (themeDark ? darkTheme : lightTheme), [
		themeDark,
	]);

	return (
		<ThemeContext.Provider
			value={{
				themeDark,
				setThemeDark,
				theme: currentTheme,
				prefersDarkMode,
				themeMode,
				setThemeMode,
			}}
		>
			<MuiThemeProvider theme={currentTheme}>{children}</MuiThemeProvider>
		</ThemeContext.Provider>
	);
}

export { ThemeContextProvider, useThemeContext };
