import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import {
	BtForm,
	BtFormActionButtons,
	BtFormActionsContainer,
	BtFormCheckbox,
	BtFormChangesBlocker,
	BtFormColourPicker,
	BtFormContent,
	BtFormSection,
	BtFormTextField,
} from '../../../components/generic/forms';
import BtMessage from '../../../components/generic/BtMessage';
import { tagGroupUpdate } from '../../../API/tags.api';

const schema = yup.object({
	name: yup
		.string()
		.min(3)
		.required()
		.label('Name'),
	description: yup
		.string()
		.min(3)
		.required()
		.label('Description'),
	valueLimit: yup.boolean().label('Limit Values to List'),
	displayColour: yup
		.string()
		.required('Colour required')
		.label('Display Colour'),
	status: yup
		.string()
		.oneOf(['Active', 'Archived'])
		.required()
		.label('status'),
});

const blankDefaults = {
	name: '',
	description: '',
	valueLimit: false,
	displayColour: '',
	status: 'Active',
};

export default function TagsEditorDetails({ group, loading, setGroup }) {
	const [sending, setSending] = useState(false);

	const defaultValues = useMemo(
		() => {
			if (!group) return blankDefaults;

			const {
				name,
				description,
				valueLimit,
				displayColour,
				status,
			} = group;
			return {
				name,
				description,
				valueLimit,
				displayColour,
				status,
			};
		},
		[group]
	);

	const platformManaged = useMemo(() => group?.managedBy === 'Platform', [
		group,
	]);

	const onSubmit = useCallback(
		async (values, reset) => {
			try {
				setSending(true);
				const newGroup = await tagGroupUpdate({
					tagGroupUuid: group.uuid,
					tagGroupUpdate: values,
				});
				setGroup(newGroup);
				reset(values);
			} catch (error) {
				console.error(error);
			} finally {
				setSending(false);
			}
		},
		[group, setGroup]
	);

	return (
		<BtForm
			defaultValues={defaultValues}
			loading={loading}
			onSubmit={onSubmit}
			sending={sending}
			validationSchema={schema}
		>
			<BtFormSection style={{ marginBottom: 0 }}>
				<BtFormContent>
					{platformManaged && (
						<BtMessage message="This tag group is managed by the platform, not your organisation. Therefore, you cannot edit the tag group details." />
					)}
					<BtFormTextField
						name="name"
						label="Name"
						disabled={platformManaged}
					/>
					<BtFormTextField
						name="description"
						label="Description"
						multiline
						maxRows={4}
						disabled={platformManaged}
					/>
					<div
						style={{
							alignItems: 'center',
							display: 'flex',
							flexWrap: 'wrap',
						}}
					>
						<BtFormColourPicker
							name="displayColour"
							disabled={platformManaged}
							style={{ marginRight: '1em' }}
						/>
						<BtFormCheckbox
							name="valueLimit"
							label="Limit Values to List"
							disabled={platformManaged}
							style={{ marginRight: '1em' }}
						/>
						<BtFormCheckbox
							name="status"
							label="Archive"
							falseValue="Active"
							trueValue="Archived"
							disabled={platformManaged}
						/>
					</div>

					{!platformManaged && (
						<BtFormActionsContainer
							style={{
								padding: '1em 0',
							}}
						>
							<BtFormActionButtons submitVerb="Save Details" />
						</BtFormActionsContainer>
					)}
				</BtFormContent>
			</BtFormSection>
			<BtFormChangesBlocker />
		</BtForm>
	);
}

TagsEditorDetails.propTypes = {
	group: PropTypes.object,
	loading: PropTypes.bool,
	setGroup: PropTypes.func.isRequired,
};
