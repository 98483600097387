const {
	EXPRESSION,
	OPERATION_MODE,
	getExpressionType,
	evaluateExpressionType,
} = require('../../expression/expression.js');
const { shallowCopy } = require('../../utils.js');
const { setStage, ERROR } = require('../pipeline.js');

const stageKey = '$match';

setStage(
	stageKey,
	/**
	 * @type {PipelineStageFunction<Object, {
	 * 	limit: number;
	 * }>}
	 */
	(context, args, options) => {
		const accumulator = [];
		const expression = getExpressionType(args);

		if (
			expression.type !== EXPRESSION.OPERATOR &&
			expression.type !== EXPRESSION.EXPRESSION_OBJECT
		) {
			throw new Error(
				ERROR.INVALID_STAGE_ARGUMENT(stageKey, 'expression object')
			);
		}

		// handle {$match: {}}
		if (
			expression.type === EXPRESSION.EXPRESSION_OBJECT &&
			Object.keys(expression.value).length === 0
		) {
			return shallowCopy(context.collection);
		}

		for (let c = 0; c < context.collection.length; c++) {
			const current = context.collection[c];

			const match = evaluateExpressionType(
				{
					...context,
					current: current,
					operationMode: OPERATION_MODE.QUERY,
				},
				expression
			);

			if (match) {
				accumulator.push(current);
				if (options?.limit && accumulator.length >= options.limit) {
					break;
				}
			}
		}

		return accumulator;
	},
	{ isFilterStage: true }
);
