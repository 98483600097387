const yup = require('yup');

export const dataSetStoreGetSchema = yup
	.object()
	.required()
	.shape({
		totalRecordCount: yup.number().required(),
		records: yup
			.array()
			.of(
				yup
					.object({
						uuid: yup
							.string()
							.uuid()
							.required(),
						data_set: yup
							.string()
							.uuid()
							.required(),
						insert_timestamp: yup.number().required(),
						update_timestamp: yup.number().required(),
						values: yup.object(),
					})
					.nullable()
			)
			.nullable(),
	});

export const dataSetStoreInsertResponseSchema = yup.object({
	uuid: yup
		.string()
		.uuid()
		.required(),
	data_set: yup
		.string()
		.uuid()
		.required(),
	insert_timestamp: yup.number().required(),
	update_timestamp: yup.number().required(),
	values: yup.object(),
});
