import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Popover,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export const BtPopover = forwardRef(
	({ anchorEl, autoOpen, children, onClose, open, ...props }, ref) => {
		const actionRef = useRef();

		const theme = useTheme();
		const screenDownSm = useMediaQuery(theme.breakpoints.down('sm'));

		useImperativeHandle(ref, () => ({
			updatePosition: () => {
				actionRef?.current?.updatePosition();
			},
		}));

		const isOpen = useMemo(() => (autoOpen ? Boolean(anchorEl) : open), [
			anchorEl,
			autoOpen,
			open,
		]);

		if (screenDownSm) {
			return (
				<Dialog fullScreen open={isOpen} onClose={onClose}>
					<DialogContent>{children}</DialogContent>
					<DialogActions style={{ justifyContent: 'center' }}>
						<Button
							disableElevation
							fullWidth
							onClick={onClose}
							startIcon={<CloseIcon />}
							variant="outlined"
						>
							Close
						</Button>
					</DialogActions>
				</Dialog>
			);
		}

		return (
			<Popover
				ref={forwardRef}
				action={actionRef}
				anchorEl={anchorEl}
				onClose={onClose}
				open={autoOpen ? Boolean(anchorEl) : open}
				{...props}
			>
				<div>{children}</div>
			</Popover>
		);
	}
);

BtPopover.displayName = 'BtPopover';

BtPopover.defaultProps = {
	autoOpen: true,
};

BtPopover.displayName = 'BtPopover';

BtPopover.propTypes = {
	anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
	autoOpen: PropTypes.bool,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	onClose: PropTypes.func,
	open: PropTypes.bool,
};

export default BtPopover;
