import { apiClient } from '../api_client';

import { dataSourceGetListSchema } from '../validations/dataSourceValidation';

//========================================================================================================
export async function dataSourceGetList() {
	const req_url = 'data_source';
	try {
		const response = await apiClient({ method: 'get', url: req_url });

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				return await dataSourceGetListSchema.validate(
					response.data_sources
				);
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}
