import { apiClient } from '../api_client';
import { firmwareVersionGetSchema } from '../validations/firmwareValidation';

//========================================================================================================
async function firmwareVersionGet({ firmwareId }) {
	try {
		const req_url = 'file/version/' + firmwareId;
		const response = await apiClient({ method: 'get', url: req_url });
		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				let config = await firmwareVersionGetSchema.validate(
					response.file_data
				);
				return config;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

export { firmwareVersionGet };
