import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	CircularProgress,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
} from '@mui/material';
import CreateIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import BtDialog from '../../../components/generic/BtDialog';
import {
	BtForm,
	BtFormActionsContainer,
	BtFormCheckbox,
	BtFormColourPicker,
	BtFormContent,
	BtFormTextField,
	withFormContextMethods,
} from '../../../components/generic/forms';
import { tagGroupAdd } from '../../../API/tags.api';

const blankDefaults = {
	name: '',
	description: '',
	valueLimit: false,
	displayColour: '',
};

const ActionButtons = withFormContextMethods(
	({ onClose, sending, formState: { isDirty } }) => {
		return (
			<>
				<Button variant="outlined" onClick={onClose} disabled={sending}>
					Cancel
				</Button>
				<Button
					variant="contained"
					disabled={!isDirty || sending}
					type="submit"
					startIcon={
						sending ? (
							<CircularProgress size={20} />
						) : (
							<CreateIcon />
						)
					}
					disableElevation
				>
					Create Group
				</Button>
			</>
		);
	}
);

export default function NewTagsGroupDialog({ existingNames, onClose, open }) {
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();

	const [sending, setSending] = useState(false);

	const schema = useMemo(
		() =>
			yup.object({
				name: yup
					.string()
					.min(3)
					.notOneOf(
						existingNames || [],
						'A group with this name already exists'
					)
					.required()
					.label('Role Name'),
				description: yup
					.string()
					.min(3)
					.required()
					.label('Role Description'),
				valueLimit: yup.boolean().label('Limit Values'),
				displayColour: yup
					.string()
					.required('Colour required')
					.label('Display Colour'),
			}),
		[existingNames]
	);

	const onSubmit = useCallback(
		async (values, reset) => {
			setSending(true);
			try {
				const { uuid } = await tagGroupAdd({
					newTagGroup: { ...values },
				});
				reset(values);
				enqueueSnackbar('Created tag group', {
					variant: 'success',
				});
				history.push(`/Tags/Edit/${uuid}`);
			} catch (error) {
				setSending(false);
				enqueueSnackbar('Failed to create role', {
					variant: 'error',
				});
			}
		},
		[enqueueSnackbar, history]
	);

	return (
		<BtDialog open={open} onClose={onClose} minwidth="sm" fullWidth>
			{sending && (
				<LinearProgress
					style={{
						position: 'absolute',
						top: 0,
						width: '100%',
					}}
				/>
			)}
			<DialogTitle>Create New Tag Group</DialogTitle>
			<BtForm
				onSubmit={onSubmit}
				sending={sending}
				validationSchema={schema}
				defaultValues={blankDefaults}
			>
				<DialogContent>
					<BtFormContent>
						<BtFormTextField name="name" label="Name" />
						<BtFormTextField
							name="description"
							label="Description"
						/>
						<div
							style={{
								alignItems: 'center',
								display: 'flex',
								flexWrap: 'wrap',
							}}
						>
							<BtFormColourPicker
								name="displayColour"
								style={{ marginRight: '1em' }}
							/>
							<BtFormCheckbox
								name="valueLimit"
								label="Limit Values to List"
							/>
						</div>
					</BtFormContent>
				</DialogContent>
				<DialogActions>
					<BtFormActionsContainer>
						<ActionButtons onClose={onClose} sending={sending} />
					</BtFormActionsContainer>
				</DialogActions>
			</BtForm>
		</BtDialog>
	);
}

ActionButtons.propTypes = {
	isDirty: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	sending: PropTypes.bool.isRequired,
};

NewTagsGroupDialog.propTypes = {
	existingNames: PropTypes.arrayOf(PropTypes.string),
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};
