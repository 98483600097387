import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ListItem } from '@mui/material';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import produce from 'immer';
import { useSnackbar } from 'notistack';

import BtError from '../../../components/generic/BtError';
import BtListComposer from '../../../components/generic/BtListComposer';
import BtUserRow from '../../../components/generic/BtUserRow';
import { roleAddUser, roleDeleteUser } from '../../../API/role.api';

export default function RoleUsers({
	getUsers,
	role,
	setRole,
	error,
	userList,
}) {
	const { enqueueSnackbar } = useSnackbar();

	const users = useMemo(
		() =>
			(userList || []).filter(({ uuid }) =>
				(role?.users || []).includes(uuid)
			),
		[role, userList]
	);

	const addUser = useCallback(
		async newUser => {
			const newUsers = [...users.map(({ uuid }) => uuid), newUser.uuid];
			setRole(
				produce(draft => {
					draft.users = newUsers;
				})
			);

			try {
				await roleAddUser({
					roleUuid: role?.uuid,
					userUuid: newUser.uuid,
				});
				setRole(
					produce(draft => {
						draft.modify_timestamp = Date.now();
					})
				);
			} catch {
				setRole(
					produce(draft => {
						draft.users = (draft.users || []).filter(
							user => user !== newUser.uuid
						);
					})
				);
				enqueueSnackbar(
					`Could not add user ${newUser.first_name} ${
						newUser.last_name
					} to role`,
					{
						variant: 'error',
					}
				);
			}
		},
		[enqueueSnackbar, users, role, setRole]
	);

	const removeUser = useCallback(
		async userToDelete => {
			const newUsers = users
				.map(({ uuid }) => uuid)
				.filter(uuid => uuid !== userToDelete.uuid);
			setRole(
				produce(draft => {
					draft.users = newUsers;
				})
			);

			try {
				await roleDeleteUser({
					roleUuid: role?.uuid,
					userUuid: userToDelete.uuid,
				});
				setRole(
					produce(draft => {
						draft.modify_timestamp = Date.now();
					})
				);
			} catch {
				setRole(
					produce(draft => {
						draft.users = [...draft.users, userToDelete.uuid];
					})
				);
				enqueueSnackbar(
					`Could not delete user ${userToDelete.first_name} ${
						userToDelete.last_name
					} from role`,
					{
						variant: 'error',
					}
				);
			}
		},
		[enqueueSnackbar, users, role, setRole]
	);

	if (!!error) {
		return (
			<BtError
				title="Retrieval Error"
				description="An error occurred when attempting to retrieve applicable users."
				variant="error"
				action={getUsers}
				fullScreen={false}
			/>
		);
	}

	return (
		<BtListComposer
			allItems={userList || []}
			confirmDeletion
			deleteable
			deleteVerb="Remove"
			items={users}
			primaryField="user_name"
			DeleteIcon={<PersonRemoveIcon />}
			sortComparator={(a, b) => a?.user_name?.localeCompare(b?.user_name)}
			renderOption={(props, user) => (
				<ListItem
					{...props}
					style={{
						padding: '0 1em',
					}}
				>
					<BtUserRow key={user.uuid} user={user} hideAvatar />
				</ListItem>
			)}
			renderItem={user => <BtUserRow key={user.uuid} user={user} />}
			subject="users"
			onItemAdd={addUser}
			onItemDelete={removeUser}
		/>
	);
}

RoleUsers.propTypes = {
	getUsers: PropTypes.func.isRequired,
	role: PropTypes.object,
	setRole: PropTypes.func.isRequired,
	error: PropTypes.any,
	userList: PropTypes.arrayOf(PropTypes.object),
};
