import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import BtInfoChip from './BtInfoChip';

export default function BtManagedBy({
	managedBy,
	organisationTooltip,
	platformTooltip,
	tooltipPlacement,
	...props
}) {
	const { Icon, text, tooltip, variant } = useMemo(
		() => {
			if (managedBy === 'Platform') {
				return {
					Icon: <LockIcon />,
					text: 'Platform-managed',
					tooltip: platformTooltip || '',
					variant: 'warning',
				};
			}

			if (managedBy === 'Organisation') {
				return {
					Icon: <LockOpenIcon />,
					text: 'Organisation-managed',
					tooltip: organisationTooltip || '',
					variant: 'info',
				};
			}

			return {
				Icon: null,
				text: 'Unknown Manager',
				tooltip: 'Something has gone wrong',
				variant: 'error',
			};
		},
		[managedBy, organisationTooltip, platformTooltip]
	);

	return (
		<BtInfoChip
			{...props}
			Icon={Icon}
			text={text}
			tooltip={tooltip}
			tooltipPlacement={tooltipPlacement}
			variant={variant}
		/>
	);
}

BtManagedBy.defaultProps = {
	tooltipPlacement: 'bottom',
};

BtManagedBy.propTypes = {
	managedBy: PropTypes.string,
	organisationTooltip: PropTypes.string.isRequired,
	platformTooltip: PropTypes.string.isRequired,
	tooltipPlacement: PropTypes.string,
};
