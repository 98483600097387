const {
	OPERATION_MODE,
	flattenExpressionForProject,
} = require('../../expression/expression');
const { ERROR } = require('../../pipeline/pipeline');
const { deepCopy } = require('../../utils');
const { evaluateExpressionSchema } = require('../expression');
const { setStageSchemaFn } = require('../pipeline');
const {
	dotRemoveFromSchema,
	dotCopyToSchema,
	newDefaultSchema,
	dotSetInSchema,
} = require('../utils');

function projectionExclude(context, projection) {
	const newSchema = deepCopy(context.schema);

	for (const prop in projection) {
		if (Object.hasOwnProperty.call(projection, prop)) {
			if (prop === '_id' && projection[prop] !== false) {
				// then don't remove it
				continue;
			}
			dotRemoveFromSchema(newSchema, prop);
		}
	}

	return newSchema;
}
function projectionInclude(context, projection, defaultNullIdField) {
	let newSchema = newDefaultSchema('object');

	for (const prop in projection) {
		if (Object.hasOwnProperty.call(projection, prop)) {
			if (prop === '_id' && projection[prop] === false) {
				// dotRemoveFromSchema(current, '_id');
				continue;
			}

			if (
				prop === '_id' &&
				!context.schema.objectContent?._id &&
				!defaultNullIdField
			) {
				continue;
			}

			newSchema = dotCopyToSchema(
				newSchema,
				context.schema,
				prop

				// uncomment this to enable defaulting projected null fields to
				// null objects

				//newDefaultSchema('object')
			);
		}
	}

	return newSchema;
}

function projectionAddFields(context, addFields) {
	const newSchema = deepCopy(context.schema);

	for (const field in addFields) {
		if (Object.hasOwnProperty.call(addFields, field)) {
			const expr = addFields[field];

			const value = evaluateExpressionSchema(context, expr);

			dotSetInSchema(newSchema, value, field, { createStructure: true });
		}
	}

	return newSchema;
}

function project(context, args, options) {
	const stageKey = options?.stageKey || '$project';

	const flatExpression = flattenExpressionForProject(args);

	const projection = { _id: true }; // default to include the _id
	const addFields = {};

	let defaultNullIdField = false;

	// $addFields has no projection stage
	const projectionStage = options?.skipProjectStage !== true;
	let addFieldsStage = !projectionStage ? true : false;

	let isExclude = !projectionStage ? false : null;

	for (const key in flatExpression) {
		if (Object.hasOwnProperty.call(flatExpression, key)) {
			const fieldExpression = flatExpression[key];
			if (!projectionStage) {
				addFields[key] = fieldExpression;
				continue;
			}

			if (
				typeof fieldExpression === 'number' ||
				typeof fieldExpression === 'boolean'
			) {
				if (isExclude === null) {
					if (key !== '_id') {
						isExclude = !fieldExpression;
					}
				} else if (isExclude !== !fieldExpression) {
					if (key !== '_id') {
						throw "can't mix include & exclude";
					}
				}

				if (key === '_id' && fieldExpression) {
					defaultNullIdField = true;
				}

				// num !== 0 || bool true => include
				projection[key] = !!fieldExpression;
			} else {
				if (isExclude) {
					throw "can't add fields in an exclude";
				}

				addFieldsStage = true;
				isExclude = false;

				addFields[key] = fieldExpression;
				projection[key] = true;
			}
		}
	}

	if (isExclude === null && flatExpression['_id'] === undefined) {
		throw new Error(
			ERROR.INVALID_STAGE_ARGUMENT(
				stageKey,
				'specification to have at least one field'
			)
		);
	} else if (isExclude === null && projection['_id'] === false) {
		isExclude = true;
	}

	const projectContext = {
		...context,
		operationMode: OPERATION_MODE.AGGREGATE,
	};

	if (addFieldsStage && !isExclude) {
		projectContext.schema = projectionAddFields(projectContext, addFields);
	} else if (isExclude) {
		return projectionExclude(projectContext, projection);
	}

	if (projectionStage) {
		return projectionInclude(
			projectContext,
			projection,
			defaultNullIdField
		);
	}

	return projectContext.schema;
}

setStageSchemaFn('$project', (context, args, options) =>
	project(context, args, { ...(options || {}), stageKey: '$project' })
);

setStageSchemaFn('$addFields', (context, args, options) =>
	project(context, args, {
		...(options || {}),
		stageKey: '$addFields',
		skipProjectStage: true,
	})
);
