import { apiClient } from '../api_client';

import {
	dataSetGetListSchema,
	dataSetGetSchema,
	dataSetAddSchema,
	dataSetAddResponseSchema,
	dataSetUpdateSchema,
	// dataSetUpdateResponseSchema,
} from '../validations/dataSetValidation';

//========================================================================================================
export async function dataSetGetList() {
	const req_url = `data_set`;
	try {
		const response = await apiClient({ method: 'get', url: req_url });

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				return await dataSetGetListSchema.validate(response.data_sets);
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
export async function dataSetGet({ dataSetUuid }) {
	console.log('dataSetGet', dataSetUuid);
	const req_url = `data_set/${dataSetUuid}`;
	try {
		const response = await apiClient({ method: 'get', url: req_url });

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				return await dataSetGetSchema.validate(response.data_set);
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
export async function dataSetAdd({ newDataSet }) {
	// Validate the new Data Set
	try {
		await dataSetAddSchema.validate(newDataSet);
	} catch (error) {
		throw error;
	}

	const req_url = 'data_set';
	try {
		const response = await apiClient({
			method: 'post',
			url: req_url,
			body: newDataSet,
		});

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				await dataSetAddResponseSchema.validate(response.data_set);
				return response.data_set;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw error;
	}
}

//========================================================================================================
// Update a Data View by uuid
export async function dataSetUpdate({ dataSetUuid, dataSetUpdate }) {
	try {
		await dataSetUpdateSchema.validate(dataSetUpdate);
	} catch (error) {
		throw new Error(error);
	}

	const url = `data_set/${dataSetUuid}`;

	try {
		const response = await apiClient({
			body: dataSetUpdate,
			method: 'put',
			url,
		});

		if (response) {
			if (response.result === 'OK') {
				return;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error(error);
	}
}
