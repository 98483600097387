import {
	useRef,
	useCallback,
	createContext,
	forwardRef,
	useEffect,
	useContext,
	useMemo,
} from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList } from 'react-window';
import {
	Chip,
	Typography,
	Popper,
	FormControlLabel,
	Switch,
	Tooltip,
} from '@mui/material';
import useOverflowedElementCount from '../../../../hooks/useOverflowedElementCount';
import { EmoticonSadOutline } from 'mdi-material-ui';
import ValueError from './filter/ValueError';

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
	const { data, index, style } = props;
	const dataSet = data[index];
	const inlineStyle = {
		...style,
		top: style.top + LISTBOX_PADDING,
	};

	if (dataSet.hasOwnProperty('group')) {
		return (
			<ListSubheader
				key={dataSet.key}
				component="div"
				style={inlineStyle}
			>
				{dataSet.group}
			</ListSubheader>
			// groupBy={option => option[0].toUpperCase()}
		);
	}

	return (
		<Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
			{`${dataSet[1]}`}
		</Typography>
	);
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
	const outerProps = useContext(OuterElementContext);
	return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
	const ref = useRef(null);
	useEffect(
		() => {
			if (ref.current != null) {
				ref.current.resetAfterIndex(0, true);
			}
		},
		[data]
	);
	return ref;
}

// Adapter for react-window
const ListboxComponent = forwardRef(function ListboxComponent(props, ref) {
	const { children, ...other } = props;
	const itemData = [];
	children.forEach(item => {
		itemData.push(item);
		itemData.push(...(item.children || []));
	});
	const theme = useTheme();
	const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
		noSsr: true,
	});
	const itemCount = itemData.length;
	const itemSize = smUp ? 36 : 48;

	const getChildSize = child => {
		if (child.hasOwnProperty('group')) {
			return 48;
		}

		return itemSize;
	};

	const getHeight = () => {
		if (itemCount > 8) {
			return 8 * itemSize;
		}
		return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
	};

	const gridRef = useResetCache(itemCount);

	// console.log(typeof children, { children });

	return (
		<div ref={ref}>
			<OuterElementContext.Provider value={other}>
				<VariableSizeList
					itemData={itemData}
					height={getHeight() + 2 * LISTBOX_PADDING}
					width="100%"
					ref={gridRef}
					outerElementType={OuterElementType}
					innerElementType="ul"
					itemSize={index => getChildSize(itemData[index])}
					overscanCount={5}
					itemCount={itemCount}
				>
					{renderRow}
				</VariableSizeList>
			</OuterElementContext.Provider>
		</div>
	);
});

ListboxComponent.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.object),
		PropTypes.object,
	]), // was previously 'node' only, which caused proptypes warning
};

const StyledPopper = styled(Popper)({
	[`& .${autocompleteClasses.listbox}`]: {
		boxSizing: 'border-box',
		'& ul': {
			padding: 0,
			margin: 0,
		},
	},
});

export const MoreIndicator = styled('div')(({ left, theme }) => ({
	alignItems: 'center',
	backgroundColor: theme.palette.insightsChips.backgroundColor,
	borderRadius: 12,
	color: theme.palette.text.primary,
	cursor: 'default',
	display: 'flex',
	fontSize: 10,
	fontWeight: 'bold',
	height: 24,
	justifyContent: 'center',
	left,
	margin: 3,
	minWidth: 24,
	pointerEvents: 'all',
	position: 'absolute',
	bottom: 0,
	width: 24,

	'&:hover': {
		backgroundColor: theme.palette.insightsChips.hover,
	},

	'&:active': {
		backgroundColor: theme.palette.insightsChips.active,
		boxShadow: 'none',
	},
}));

export default function CategoryAutocomplete({
	onChange,
	value,
	options,
	singleValue,
	handleSingleValueChange,
	disabled,
	singleValueRequired,
}) {
	const containerRef = useRef();
	const {
		assessOverflow: recheckOverflow,
		count: overflowCount,
		topRowWidth: moreIndicatorPos,
		firstHiddenIndex,
	} = useOverflowedElementCount(containerRef, true);

	const hasHidden = useMemo(() => +(overflowCount > 0), [overflowCount]);

	useEffect(() => recheckOverflow(), [recheckOverflow, value]);

	// On field select - set the controlled value and initialize filter data
	const onFieldSelect = useCallback(
		newValue => {
			if (singleValue) {
				onChange([newValue]);
			} else {
				onChange(newValue);
			}
		},
		[onChange, singleValue]
	);
	// console.log({
	// 	onChange,
	// 	value,
	// 	options,
	// 	// handleSelectAll,
	// 	singleValue,
	// 	handleSingleValueChange,
	// });
	// useEffect(
	// 	() => {
	// 		console.log({
	// 			firstHiddenIndex,
	// 			hasHidden,
	// 			overflowCount,
	// 			moreIndicatorPos,
	// 		});
	// 		// console.log(containerRef.);
	// 	},
	// 	[firstHiddenIndex, hasHidden, overflowCount, moreIndicatorPos]
	// );

	const handleDeleteChip = useCallback(
		item => {
			// console.log(item);
			const newValue = value.filter(valueItem => valueItem !== item);
			onChange(newValue);
		},
		[onChange, value]
	);

	const allChecked = useMemo(
		() => (options?.length || [].length) === (value?.length || [].length),
		[options, value]
	);

	const handleSelectAll = useCallback(
		() => {
			if (allChecked) {
				onChange([]);
			} else {
				// console.log({ options });
				onChange([...options]);
			}
		},
		[allChecked, onChange, options]
	);

	const sortedOptions = useMemo(
		() => options.sort((a, b) => a.localeCompare(b)),
		[options]
	);

	if (!options || options?.length === 0) {
		return <ValueError />;
	}

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			{/* <Typography variant="h6">Values</Typography> */}
			<div style={{ marginLeft: 5 }}>
				<div>
					{!singleValueRequired &&
						!disabled && (
							<FormControlLabel
								control={
									<Switch
										checked={singleValue || false}
										onChange={handleSingleValueChange}
										disabled={
											singleValueRequired || disabled
										}
									/>
								}
								label="Single Value"
							/>
						)}
					{!singleValueRequired &&
						!disabled && (
							<FormControlLabel
								control={
									<Switch
										checked={allChecked}
										name={'select_all'}
										onChange={handleSelectAll}
										disabled={singleValue || disabled}
									/>
								}
								label={`Select all (${options.length})`}
							/>
						)}
				</div>

				{!singleValue && (
					<div
						style={{
							width: '100%',
							position: 'relative',
							// marginLeft: -3,
							overflow: 'hidden',
						}}
					>
						<div
							ref={containerRef}
							style={{
								maxHeight: 90,
								alignItems: 'center',
								display: 'flex',
								flexWrap: 'wrap',
								justifyContent: 'flex-start',
								paddingRight: (() => {
									if (hasHidden) {
										return 26;
									}
									return 0;
								})(),
								width: '100%',
							}}
						>
							{value.length === 0 ? (
								<Chip
									size="small"
									style={{ margin: 3 }}
									label="Nothing selected yet.."
									variant="outlined"
								/>
							) : (
								value.slice(0, 100).map(item => (
									<div key={item}>
										<Chip
											size="small"
											style={{ margin: 3 }}
											label={item}
											onDelete={() =>
												handleDeleteChip(item)
											}
											disabled={disabled}
										/>
									</div>
								))
							)}
						</div>
						{firstHiddenIndex > 0 && (
							<Tooltip
								title={`Showing ${firstHiddenIndex} of ${
									value.length
								}.  This is all we can show here, use the text field below to view all values.`}
								disableInteractive
							>
								<MoreIndicator left={moreIndicatorPos}>
									<span>
										{value.length - firstHiddenIndex < 100
											? `+${value.length -
													firstHiddenIndex}`
											: '...'}
									</span>
								</MoreIndicator>
							</Tooltip>
						)}
					</div>
				)}

				<div style={{ display: 'flex' }}>
					<Autocomplete
						style={{
							marginTop: '1em',
							flex: '3 1 100%',
						}}
						id="virtualize-demo"
						disableListWrap
						PopperComponent={StyledPopper}
						ListboxComponent={ListboxComponent}
						options={sortedOptions}
						renderInput={params => (
							<TextField
								{...params}
								variant="standard"
								placeholder="Search and select values"
							/>
						)}
						// renderOption={(props, option, state) => [
						// 	props,
						// 	option,
						// 	state.index,
						// ]}
						renderOption={(props, option, state) => [
							props,
							option,
							state.index,
						]}
						// TODO: Post React 18 update - validate this conversion, look like a hidden bug
						// renderGroup={params => params}
						// groupBy={option => option[0].toUpperCase()}
						// groupBy={() => null}
						multiple={!singleValue}
						// disableCloseOnSelect={!singleValue}
						value={value}
						onChange={(event, newValue) => {
							onFieldSelect(newValue);
						}}
						disableClearable={singleValue}
						renderTags={() => null} // disable the tags in the input
						disabled={disabled}
					/>
				</div>
			</div>
		</div>
	);
}

CategoryAutocomplete.propTypes = {
	onChange: PropTypes.func.isRequired,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]).isRequired,
	options: PropTypes.arrayOf(PropTypes.string).isRequired,
	singleValue: PropTypes.bool,
	handleSingleValueChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	singleValueRequired: PropTypes.bool,
};
