import { styled } from '@mui/material/styles';

export default styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	gap: '1em',
	width: '100%',
	justifyContent: 'center',
	padding: '1em 1.5em',

	[theme.breakpoints.down('sm')]: {
		flexDirection: 'column-reverse',
	},
}));
