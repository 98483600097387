import { createContext, useContext, useMemo, useState } from 'react';

// Main app context for infrequent changes like user
const TileConfigContext = createContext(null);

const useTileConfigContext = () => {
	const context = useContext(TileConfigContext);
	if (context === undefined) {
		throw new Error(
			'useTileConfigContext was used outside of its Provider'
		);
	}
	return context;
};

function TileConfigContextProvider({
	children,
	tileData,
	screenId,
	nativeMode,
}) {
	// const [currentTab, setCurrentTab] = useState(0);
	const [tileUpdateParams, setTileUpdateParams] = useState(tileData);
	const [existingTileData, setExistingTileData] = useState(tileData);
	const [screenUuid, setScreenUuid] = useState(screenId);
	const [isFormValid, setIsFormValid] = useState(false);
	const [error, setError] = useState();

	const isNativeMode = useMemo(
		() => {
			return nativeMode;
		},
		[nativeMode]
	);

	return (
		<TileConfigContext.Provider
			value={{
				tileUpdateParams,
				setTileUpdateParams,
				existingTileData,
				setExistingTileData,
				screenUuid,
				setScreenUuid,
				isFormValid,
				setIsFormValid,
				isNativeMode,
				error,
				setError,
			}}
		>
			{children}
		</TileConfigContext.Provider>
	);
}

export { TileConfigContextProvider, useTileConfigContext };
