import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { Auth } from 'aws-amplify';
import { useNavContext } from '../../context/ContextManager';
import { useAppContext } from '../../context/ContextManager';

import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import SaveIcon from '@mui/icons-material/SaveAlt';
import CloseIcon from '@mui/icons-material/Close';
import {
	ListTable,
	ListRow,
	ListHeader,
	ListCell,
} from '../../components/generic/ListTable';

import ConfigEditChannelDialog from '../../components/dialogs/ConfigEditChannelDialog';
import ConfigChannels from '../../components/DeviceComponents/ConfigChannels';

import { configLatestGet, deviceTypeGet } from '../../API';

// Data for landing page selection table and contextual navigation
import IoT_options from './IoTOptions';

import IoT_breadcrumbs from './IoTBreadcrumbs';

import IoT_nav_item from './IoTNavItem';

const styles = makeStyles(theme => ({
	button_row: {},
	root: {
		minWidth: 200,
		padding: '1rem',
		margin: '1rem',
		'& .MuiTextField-root': {
			margin: '10px 0',
		},
	},
	config_paper: {
		padding: 10,
		margin: '10px 0',
	},
	description_text: {
		height: 200,
		color: 'red',
	},
	jsonEdit: {
		backgroundColor: '#f5f5f5',
		margin: '0px 0',
		fontSize: '2rem',
	},
	tabPanel: {
		margin: 0,
		padding: 0,
		marginBottom: 16,
		backgroundColor: '#f5f5f5',
		borderRadius: 8,
	},
	tabsAppBar: {
		backgroundColor: '#ffffff',
		boxShadow: 'none',
	},
	actionButton: {
		width: '120px',
	},
	deviceSelect: {
		margin: '16px 0',
		minWidth: 200,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	groupBox: {
		border: '1px solid black',
		borderRadius: theme.shape.borderRadius,
		borderColor: theme.palette.primary.border_color,
		margin: '0.5rem 0.5rem',
		padding: '0.5rem 0.5rem',
	},
	triggerBox: {
		height: '100%',
	},
}));

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={2}>{children}</Box>}
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

export default function DeviceConfigNewRev({ match, location }) {
	const classes = styles();
	const [isLoading, setIsLoading] = useState(true);
	const [device, setDevice] = useState(
		'36d17e30-8a05-11ea-9734-1f4c9ccfccf9'
	);
	const [deviceDef, setDeviceDef] = useState({});
	const [description, setDescription] = useState('');
	const [name, setName] = useState('');
	const [version, setVersion] = useState(0);
	//const [json, setJson] = useState('[]');
	const [configChannels, setConfigChannels] = useState([]);
	const history = useHistory();
	const [commsActive, setCommsActive] = useState(false);

	const [channelEditOpen, setChannelEditOpen] = useState(false);
	const { setContextualNav } = useNavContext();
	const { setBreadcrumbs } = useNavContext();
	const { appOrg } = useAppContext();

	var config_id = match.params.id;
	//const url_query = queryString.parse(location.search);

	// TODO: check the version type is correct
	const url_query = queryString.parse(location.search);
	var init_version = 1;
	if (url_query.version !== undefined) {
		init_version = url_query.version;
	}

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([...IoT_nav_item, ...IoT_options]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				...IoT_breadcrumbs,
				{ text: 'Config', link: '/Config' },
				{ text: name, link: '/config/' + config_id },
				{ text: 'New Config Version', link: '' },
			]);
		},
		[setBreadcrumbs, name, config_id]
	);

	//const handleChangeDevice = (event) => {setDevice(event.target.value);};
	//const handleJsonChange = (event) => {setJson(event.target.value);};
	//const handleDescriptionChange = (event) => {setDescription(event.target.value);};
	//const handleTabChange = (event, newTabValue) => {setTabValue(newTabValue);};
	const handleCancel = event => {
		history.goBack();
	};

	useEffect(
		() => {
			const onLoad = async () => {
				try {
					let config_data = await configLatestGet({
						configId: config_id,
					});
					setName(config_data['name']);
					setDevice(config_data['device_type']);
					/*setJson(
								JSON.stringify(
									response.config_data.channels,
									null,
									2
								)
							);*/
					setConfigChannels(config_data.channels);
					setDescription(config_data['description']);
					setVersion(config_data.version + 1);
					console.log(config_data);
					let devDetails = await deviceTypeGet({
						typeId: config_data.device_type,
					});
					console.log(devDetails);
					setDeviceDef(devDetails);
				} catch (error) {
					console.log(error);
				}
				setIsLoading(false);
			};

			onLoad();
		},
		[config_id]
	);

	// useEffect(
	// 	() => {
	// 		var req_url =
	// 			process.env.REACT_APP_API_SERVER_URL +
	// 			`/v1/config/latest/` +
	// 			config_id;

	// 		Auth.currentSession().then(user_session => {
	// 			let user_token = user_session.getAccessToken();
	// 			fetch(req_url, {
	// 				method: 'GET',
	// 				headers: {
	// 					Authorization: user_token.jwtToken,
	// 					Accept: 'application/json',
	// 					'Content-Type': 'application/json',
	// 					Organisation: appOrg.uuid,
	// 				},
	// 			})
	// 				.then(res => res.json())
	// 				.then(response => {
	// 					// The API will respond with OK if a config with this name is already present
	// 					if (response['result'] === 'OK') {
	// 						setName(response.config_data['name']);
	// 						setDevice(response.config_data['device_type']);
	// 						/*setJson(
	// 							JSON.stringify(
	// 								response.config_data.channels,
	// 								null,
	// 								2
	// 							)
	// 						);*/
	// 						setConfigChannels(response.config_data.channels);
	// 						setDescription(response.config_data['description']);
	// 						setVersion(response.config_data.version + 1);
	// 					} else {
	// 						alert('Server connection error.');
	// 					}
	// 					setIsLoading(false);
	// 				})
	// 				.catch(error => {
	// 					alert('Server Error!');
	// 					setIsLoading(false);
	// 				});
	// 		});
	// 	},
	// 	[config_id, init_version]
	// );

	const handleSave = event => {
		var config = {};
		config['version'] = version;
		config['channels'] = configChannels;

		setCommsActive(true);

		var req_url =
			process.env.REACT_APP_API_SERVER_URL + `/v1/config/` + config_id;

		Auth.currentSession().then(user_session => {
			let user_token = user_session.getAccessToken();
			fetch(req_url, {
				method: 'PUT',
				headers: {
					Authorization: user_token.jwtToken,
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Organisation: appOrg.uuid,
				},
				body: JSON.stringify(config),
			})
				.then(res => res.json())
				.then(response => {
					if (response['result'] !== 'OK') {
						alert('Internal server error.');
					} else {
						alert('Configuration Saved.');
						history.goBack();
					}
					setCommsActive(false);
				})
				.catch(error => {
					alert('Failed to connect to the server.');
					setCommsActive(false);
				});
		});
	};

	const handleChannelEditDialogClose = channelArray => {
		setChannelEditOpen(false);
	};

	return (
		<>
			{isLoading && (
				<Paper>
					<p>Loading...</p>
					<CircularProgress size={'120px'} />
				</Paper>
			)}

			{!isLoading && (
				<form className={classes.root} noValidate autoComplete="off">
					<Paper className={classes.config_paper}>
						<h1>Device Config: {name}</h1>
						<Container>
							<ListTable>
								<ListRow>
									<ListHeader>Device Type:</ListHeader>
									<ListCell>{device}</ListCell>
								</ListRow>
								<ListRow>
									<ListHeader>Description:</ListHeader>
									<ListCell>{description}</ListCell>
								</ListRow>
								<ListRow>
									<ListHeader>Config UUID:</ListHeader>
									<ListCell>{config_id}</ListCell>
								</ListRow>
								<ListRow>
									<ListHeader>New Version:</ListHeader>
									<ListCell>{version}</ListCell>
								</ListRow>
							</ListTable>
						</Container>

						<Divider />

						<ConfigChannels
							config={configChannels}
							editable={true}
							deviceDef={deviceDef}
						/>

						<Divider />
						<Box
							display="flex"
							flexDirection="row-reverse"
							p={1}
							m={1}
							bgcolor="background.paper"
						>
							<Button
								variant="outlined"
								startIcon={<SaveIcon />}
								onClick={handleSave}
								className={classes.actionButton}
							>
								Save
							</Button>

							<Button
								variant="outlined"
								startIcon={<CloseIcon />}
								onClick={handleCancel}
								className={classes.actionButton}
							>
								Cancel
							</Button>
						</Box>
					</Paper>

					<Backdrop className={classes.backdrop} open={commsActive}>
						<CircularProgress color="inherit" />
					</Backdrop>
				</form>
			)}

			<ConfigEditChannelDialog
				setOpen={channelEditOpen}
				onClose={handleChannelEditDialogClose}
				channelData={configChannels}
				selectedChannel={{}}
				deviceDef={deviceDef}
			/>
		</>
	);
}
