import { Divider, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { InsightComponentContainer } from '../../../../views/Insights/Components/UiComponents/InsightComponentContainer';
import { buildTimeRangeObject } from '../../../../utils/TimeControl/TimeRangeUtilFunctions';
import { commonRangesData } from '../../../../utils/TimeControl/TimeRangeUtilConstants';

import BtLoading from '../../BtLoading';
import { ConfigureTilePlaceholder } from '../components/ConfigureTilePlaceholder';

import { useDataProviderContext } from '../../../../context/DataProvider/DataProvider';
import React, { useContext, useState, useEffect } from 'react';
import { TileContext } from '../components/TileContext';
import useInsightPaletteConfig from '../../../../views/Insights/Components/VisComponents/hooks/useInsightPaletteConfig';

export default function InsightContent({
	style,
	clickDisabled,
	// editEnabled,
	tileVariant,
	title,
	subtitle,
	loading,
	insightTile,
	collectionUuid,
}) {
	const THEME = useTheme();
	const {
		addDataViews,
		addDataSources,
		removeDataSources,
		addDataSource,
	} = useDataProviderContext();
	const { error } = useContext(TileContext);
	const [dataSourceReady, setDataSourceReady] = useState(false);
	const {
		palette,
		loading: paletteLoading,
		error: paletteError,
		getPalette,
	} = useInsightPaletteConfig(collectionUuid);
	// const palette = [
	// 	'#5ec1c9',
	// 	'#ef9b20',
	// 	'#ea5545',
	// 	'#d40099',
	// 	'#a300b5',
	// 	'#0092ff',
	// 	'#8061d9',
	// 	'#87bc45',
	// ];
	// const paletteLoading = false;
	// const paletteError = false;

	const handleDataSourceUpdate = (
		dataSourceUuid,
		data,
		timeRange,
		filterOptions,
		filters
	) => {
		// console.log('handleDataSourceUpdate');
	};

	useEffect(
		() => {
			const onLoad = async () => {
				// console.log(insightTile);
				if (insightTile.datasource) {
					const timeRangeConfig = _.find(commonRangesData, {
						id: insightTile?.visualisation?.initialTimeRange?.value,
					});
					const defaultTimeRange = await buildTimeRangeObject({
						...timeRangeConfig,
					});

					await addDataSource(
						insightTile.datasource.uuid,
						insightTile.datasource.dataView,
						defaultTimeRange,
						insightTile.datasource.predefinedFilters,
						insightTile.datasource.filterDependencies,
						insightTile.datasource.transforms,
						handleDataSourceUpdate
					);
				}
				setDataSourceReady(true);
			};

			if (insightTile) {
				onLoad();
			}
		},
		[insightTile]
	);

	return (
		<div
			style={{
				height: '100%',
				width: '100%',
				...style,
			}}
		>
			{/* {clickDisabled ? (
				<ConfigureTilePlaceholder
					// editEnabled={editEnabled}
					tileVariant={tileVariant}
				/>
			) : ( */}

			{!error &&
				(!paletteError && (
					<BtLoading loading={loading || paletteLoading}>
						<div
							style={{
								height: `calc(100% - 40px)`,
								width: '100%',
							}}
						>
							{dataSourceReady && (
								<InsightComponentContainer
									component={insightTile.visualisation}
									palette={palette}
								/>
							)}
						</div>

						<div
							style={{
								height: 40,
								width: '100%',
								position: 'absolute',
								bottom: 0,
							}}
						>
							<Divider style={{ color: THEME.palette.divider }} />
							<div
								style={{
									paddingTop: 5,
									paddingRight: 10,
									paddingLeft: 10,
									height: '100%',
								}}
							>
								<Typography>{title}</Typography>

								{/* <Typography noWrap fontStyle="italic">
								{subtitle}
							</Typography> */}
							</div>
						</div>
					</BtLoading>
				))}

			{/* )} */}
		</div>
	);
}

InsightContent.defaultProps = {
	title: 'title',
	// subtitle: 'subtitle',
};

InsightContent.propTypes = {
	style: PropTypes.object,
	// editEnabled: PropTypes.bool,
	clickDisabled: PropTypes.bool,
	loading: PropTypes.bool,
	insightTile: PropTypes.object,
	tileVariant: PropTypes.oneOf([
		'insight_collection',
		'insight_visualisation',
	]),
	title: PropTypes.string,
	// subtitle: PropTypes.string,
};
