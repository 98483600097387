const { setOperator, EXPRESSION, OPERATION_MODE } = require('../expression.js');
const { shallowCopy } = require('../../utils.js');

const operatorKey = '$literal';

setOperator(
	operatorKey,
	/**
	 * @type {ExpressionFunction<unknown, unknown>}
	 */
	(context, args, options) => {
		// return { type: EXPRESSION.LITERAL, value: shallowCopy(args) };
		return shallowCopy(args);
	},

	[OPERATION_MODE.AGGREGATE, OPERATION_MODE.QUERY]
);
