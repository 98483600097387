import _, { cloneDeep } from 'lodash';
import {
	COLUMNS,
	LAYOUT_COORDINATE_KEYS,
	LAYOUT_KEYS,
	LAYOUT_SIZE_KEYS,
	LAYOUT_TYPES,
} from './visualisationConstants';

const createAddVisPayload = (
	insightPage,
	visualisations,
	newVis,
	layoutItem,
	layout
) => {
	const pageUpdate = { ...insightPage };

	// Add the new visualisation to the visualisations array,
	// for backend update
	const visualisationsUpdate = [...visualisations, newVis];

	// the new item id properties
	const newLayoutItem = {
		visualisation: newVis.uuid,
		uuid: layoutItem.i,
	};

	LAYOUT_TYPES.forEach(layoutType => {
		pageUpdate.layouts[layoutType] = [
			...pageUpdate.layouts[layoutType],
			newLayoutItem,
		].map(layoutItem => {
			const itemIndex = _.findIndex(layout, {
				i: layoutItem.uuid,
			});

			// create new layout object and add uuid and visualisation uuid
			const newLayoutItem = {};
			newLayoutItem.visualisation = layoutItem.visualisation;
			newLayoutItem.uuid = layoutItem.uuid;

			// layout properties will only exist for the current layout -
			// so if we don't find a layout for the new item add some
			// default values
			if (itemIndex === -1) {
				LAYOUT_COORDINATE_KEYS.forEach(key => (newLayoutItem[key] = 0));
				LAYOUT_SIZE_KEYS.forEach(key => (newLayoutItem[key] = 1));
			} else {
				LAYOUT_KEYS.forEach(
					key => (newLayoutItem[key] = layout[itemIndex][key])
				);
			}
			return newLayoutItem;
		});
	});

	return [pageUpdate, visualisationsUpdate];
};

/**
 * Returns the number of columns to show by evaluating device type, screen orientation and breakpoint
 *
 */
const evaluateColumns = (deviceType, breakpoint, isLandscape) => {
	// console.log('evaluateColumns', breakpoint, deviceType, isLandscape);
	if (deviceType === 'mobile') {
		switch (isLandscape) {
			case true:
				return COLUMNS.sm;
			case false:
				return COLUMNS.xs;
			default:
				break;
		}
	} else if (deviceType === 'tablet') {
		switch (isLandscape) {
			case true:
				return COLUMNS.lg;
			case false:
				return COLUMNS.md;
			default:
				break;
		}
	} else {
		return COLUMNS[breakpoint];
	}
};

const createLayoutChangePayload = (insightPage, editWidth, layout) => {
	const pageUpdate = cloneDeep(insightPage);

	// console.log({ insightPage, editWidth, layout });

	if (editWidth === 'Desktop') {
		pageUpdate.layouts.desktop = pageUpdate.layouts.desktop.map(
			layoutItem => {
				const itemIndex = _.findIndex(layout, {
					i: layoutItem.uuid,
				});

				return {
					visualisation: layoutItem.visualisation,
					uuid: layoutItem.uuid,
					x: layout[itemIndex].x,
					y: layout[itemIndex].y,
					h: layout[itemIndex].h,
					w: layout[itemIndex].w,
				};
			}
		);
	} else if (editWidth === 'Tablet') {
		pageUpdate.layouts.tablet = pageUpdate.layouts.tablet.map(
			layoutItem => {
				const itemIndex = _.findIndex(layout, {
					i: layoutItem.uuid,
				});

				return {
					visualisation: layoutItem.visualisation,
					uuid: layoutItem.uuid,
					x: layout[itemIndex].x,
					y: layout[itemIndex].y,
					h: layout[itemIndex].h,
					w: layout[itemIndex].w,
				};
			}
		);
	} else if (editWidth === 'Mobile') {
		pageUpdate.layouts.mobile = pageUpdate.layouts.mobile.map(
			layoutItem => {
				const itemIndex = _.findIndex(layout, {
					i: layoutItem.uuid,
				});

				return {
					visualisation: layoutItem.visualisation,
					uuid: layoutItem.uuid,
					x: layout[itemIndex].x,
					y: layout[itemIndex].y,
					h: layout[itemIndex].h,
					w: layout[itemIndex].w,
				};
			}
		);
	}
	return pageUpdate;
};

export { createAddVisPayload, evaluateColumns, createLayoutChangePayload };
