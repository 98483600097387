import React, { createContext, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const ScreenDistributionWizardContext = createContext();

const seedData = {
	method: 'assignTo',
	availableTo: {
		users: [],
		roles: [],
		userGroups: [],
	},
	assignTo: [
		{
			screen_group: 'home',
			users: [],
			roles: [],
			userGroups: [],
		},
		{
			screen_group: 'workflows',
			users: [],
			roles: [],
			userGroups: [],
		},
		{
			screen_group: 'insights',
			users: [],
			roles: [],
			userGroups: [],
		},
	],
	screenGroup: 'home',
};

/**
 * Component data initializer
 * @param {*} seedTargets
 * @returns the data object seeded with values as provided by the back end
 */
const initData = seedTargets => {
	// seed assignTo properties, defining empty data structures if necessary
	const initAssignTo = () => {
		let newAssignTo = [];
		seedData.assignTo.forEach(screenGroup => {
			const assignGroup = seedTargets.assignTo.find(
				({ screen_group }) => screen_group === screenGroup.screen_group
			);
			if (assignGroup) {
				newAssignTo.push(assignGroup);
			} else {
				const protoAssignGroup = seedData.assignTo.find(
					({ screen_group }) =>
						screen_group === screenGroup.screen_group
				);
				newAssignTo.push(protoAssignGroup);
			}
		});
		return newAssignTo;
	};

	const initAvailableTo = () => {
		if (seedTargets.availableTo) {
			return seedTargets.availableTo;
		} else {
			return seedData.availableTo;
		}
	};

	const tempData = {};
	tempData.method = 'assignTo';
	tempData.assignTo = initAssignTo();
	tempData.screenGroup = structuredClone(seedData.screenGroup);
	tempData.availableTo = initAvailableTo();

	return tempData;
};

const ScreenDistributionWizardContextProvider = ({
	children,
	onClose,
	seedTargets,
}) => {
	const [data, setData] = useState(initData(seedTargets));

	/**
	 * Resets the target values to the same as component initialization,
	 * useful when going back in the wizard
	 */
	const resetTargets = useCallback(() => {
		const tempData = initData(seedData);
		tempData.method = data.method;
		tempData.screenGroup = data.screenGroup;
		setData(tempData);
	});

	/**
	 * Resets all data values to the same as component initialization,
	 * useful when going back in the wizard
	 */
	const resetScreenGroups = useCallback(() => {
		const tempData = initData(seedData);
		setData(tempData);
	});

	return (
		<ScreenDistributionWizardContext.Provider
			value={{
				data,
				onClose,
				setData,
				resetTargets,
				resetScreenGroups,
			}}
		>
			{children}
		</ScreenDistributionWizardContext.Provider>
	);
};

ScreenDistributionWizardContextProvider.propTypes = {
	seedTargets: PropTypes.object.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	onClose: PropTypes.func.isRequired,
};

export {
	ScreenDistributionWizardContext,
	ScreenDistributionWizardContextProvider,
};
