const { isDocument } = require('../../utils.js');
const {
	ERROR,
	setOperator,
	evaluateExpression,
	OPERATION_MODE,
} = require('../expression.js');

const operatorKey = '$mergeObjects';

setOperator(
	operatorKey,
	/**
	 * @type {ExpressionFunction<unknown, unknown>}
	 */
	(context, args, options) => {
		if (!Array.isArray(args)) {
			throw new Error(
				ERROR.INVALID_OPERATOR_ARGUMENT(
					operatorKey,
					'must be passed an array of expressions that resolve to documents',
					args
				)
			);
		}

		let accumulator = {};

		for (let pos = 0; pos < args.length; pos++) {
			const arg = args[pos];

			/**
			 * @type {any}
			 */
			let value = evaluateExpression(context, arg);

			if (value === null || value === undefined) {
				continue;
			}

			if (!isDocument(value)) {
				throw new Error(
					ERROR.INVALID_OPERATOR_ARGUMENT(
						operatorKey,
						'all arguments to resolve to objects',
						value,
						pos
					)
				);
			}

			accumulator = { ...accumulator, ...value };
		}

		return accumulator;
	},

	[OPERATION_MODE.AGGREGATE]
);
