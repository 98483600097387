const {
	ERROR,
	setOperator,
	evaluateExpression,
	OPERATION_MODE,
} = require('../expression.js');

const OPERATORS = {
	GREATER_THAN: '$gt',
	GREATER_THAN_EQUAL_TO: '$gte',
	LESS_THAN: '$lt',
	LESS_THAN_EQUAL_TO: '$lte',
};

/**
 * @typedef {{
 * 	maxArgs?: number;
 *  minArgs?: number;
 * }} NumericalOperatorOptions
 */

/**
 * @type {ExpressionFunction<any, boolean, {operator:string;}>}
 */
function operation(context, args, options) {
	const arr = !Array.isArray(args) ? [args] : args;

	if (arr.length !== 2) {
		throw new Error(ERROR.INVALID_NUMBER_ARGS(options.operator, 2, 2));
	}

	/**
	 * @type {any}
	 */
	let value1 = evaluateExpression(context, arr[0]);

	/**
	 * @type {any}
	 */
	let value2 = evaluateExpression(context, arr[1]);

	if (value1 instanceof Date && value2 instanceof Date) {
		value1 = value1.getTime();
		value2 = value2.getTime();
	}

	if (Array.isArray(value1)) {
		if (context.operationMode === OPERATION_MODE.QUERY) {
			for (let a = 0; a < value1.length; a++) {
				if (operation(context, [value1[a], value2], options)) {
					return true;
				}
			}
		}
		return false;
	}

	switch (options.operator) {
		case OPERATORS.LESS_THAN:
			return value1 < value2;
		case OPERATORS.LESS_THAN_EQUAL_TO:
			return value1 <= value2;
		case OPERATORS.GREATER_THAN:
			return value1 > value2;
		case OPERATORS.GREATER_THAN_EQUAL_TO:
			return value1 >= value2;
	}
}

Object.keys(OPERATORS).forEach((operator) => {
	const key = OPERATORS[operator];
	setOperator(
		key,
		(context, args, options) =>
			operation(
				context,
				args,
				options ? { ...options, operator: key } : { operator: key }
			),
		[OPERATION_MODE.AGGREGATE, OPERATION_MODE.QUERY]
	);
});
