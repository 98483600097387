import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

// import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import { BtQueryBuilderPropertyField } from './BtQueryBuilderPropertyField';
import { determineValueType, replaceDynamicTypedValue } from './stages/utils';
import {
	useQueryBuilderStageContext,
	useQueryBuilderVariableContext,
} from '../context';

const defaultParams = [
	{
		component: BtQueryBuilderPropertyField,
		allowedFieldTypes: ['field-path', 'expression', 'variable', 'static'],
		fullWidth: true,
	},
];

const defaultParamValues = [
	() => ({
		typeValue: 'field-path',
		fieldValue: null,
	}),
];

const defaultOperator = {
	title: 'set a title',
	description: 'set a description',
	parameter_fields: defaultParams,
	parameter_count: -1, // as many as you want
	defaultValues: defaultParamValues,
};

const operator_expressions = {
	$and: {
		title: 'And',
		description:
			'Returns true if all parameters are truthy, and false otherwise',
		parameter_fields: defaultParams,
		parameter_count: -1, // as many as you want
		defaultValues: defaultParamValues,
	},
	$or: {
		title: 'Or',
		description:
			'Returns true if any parameter is truthy, and false otherwise',
		parameter_fields: defaultParams,
		parameter_count: -1, // as many as you want
		defaultValues: defaultParamValues,
	},
	$not: {
		title: 'Not',
		description:
			'Returns true if the parameter is falsey, and false otherwise',
		parameter_fields: defaultParams,
		defaultValues: defaultParamValues,
		parameter_count: 1,
		unary_operator: true,
	},
	$toString: {
		title: 'To String',
		description:
			'Converts a value to a string, or null if the value cannot be converted to a string',
		parameter_fields: defaultParams,
		defaultValues: defaultParamValues,
		parameter_count: 1,
		unary_operator: true,
	},
	$toDate: {
		title: 'To Date',
		description:
			'Converts number or string to a date type. Date strings should be in ISO 8601 format for compatibility. Numbers are interpreted as milliseconds since unix epoch',
		parameter_fields: defaultParams,
		defaultValues: defaultParamValues,
		parameter_count: 1,
		unary_operator: true,
	},
	$toLong: {
		title: 'To Long',
		description:
			'Converts a value to an integer long, the value is truncated if it is a float or double',
		parameter_fields: defaultParams,
		defaultValues: defaultParamValues,
		parameter_count: 1,
		unary_operator: true,
	},
	$concat: {
		title: 'String Concatenate',
		description: 'Concatenate a collection of strings together',
		parameter_fields: defaultParams,
		defaultValues: defaultParamValues,
		parameter_count: -1,
	},
	$eq: {
		title: 'Equal',
		description: 'Returns true if the first value is equal to the second',
		parameter_fields: defaultParams,
		parameter_count: 2,
		defaultValues: defaultParamValues,
	},
	$ne: {
		title: 'Not equal',
		description:
			'Returns true if the first value is not equal to the second',
		parameter_fields: defaultParams,
		parameter_count: 2,
		defaultValues: defaultParamValues,
	},
	$gt: {
		title: 'Greater than',
		description:
			'Returns true if the value of the first input is greater than the second',
		parameter_fields: defaultParams,
		parameter_count: 2,
		defaultValues: defaultParamValues,
	},
	$gte: {
		title: 'Greater than or equal',
		description:
			'Returns true if the value of the first input is greater than or equal to the second',
		parameter_fields: defaultParams,
		parameter_count: 2,
		defaultValues: defaultParamValues,
	},
	$lt: {
		title: 'Less than',
		description:
			'Returns true if the value of the first input is less than the second',
		parameter_fields: defaultParams,
		parameter_count: 2,
		defaultValues: defaultParamValues,
	},
	$lte: {
		title: 'Less than or equal',
		description:
			'Returns true if the value of the first input is less than or equal to the second',
		parameter_fields: defaultParams,
		parameter_count: 2,
		defaultValues: defaultParamValues,
	},
	$ifNull: {
		title: 'If Null',
		description: 'If the first value is null, returns the second value',
		parameter_fields: defaultParams,
		parameter_count: 2,
		defaultValues: defaultParamValues,
	},
	$dateTrunc: {
		title: 'Truncate Date',
		description: 'Truncates a date to the nearest unit',
		parameter_fields: [
			{ ...defaultParams[0], label: 'date' },
			{
				component: FixedListSelect,
				label: 'unit',
				options: [
					{ value: 'second' },
					{ value: 'minute' },
					{ value: 'hour' },
					{ value: 'day' },
					{ value: 'week' },
					{ value: 'month' },
					{ value: 'quarter' },
					{ value: 'year' },
				],
			},
			{
				component: TextField,
				type: 'number',
				label: 'Bin Size',
				variant: 'standard',
				fullWidth: true,
				onChange: event => Math.trunc(Number(event.target.value)),
				step: 1,
				min: 1,
			},
			{
				component: FixedListSelect,

				label: 'Week Start Day',
				options: [
					{ value: 'mon', label: 'Monday' },
					{ value: 'tue', label: 'Tuesday' },
					{ value: 'wed', label: 'Wednesday' },
					{ value: 'thu', label: 'Thursday' },
					{ value: 'fri', label: 'Friday' },
					{ value: 'sat', label: 'Saturday' },
					{ value: 'sun', label: 'Sunday' },
				],
				coerce: val =>
					val === null ? 'sun' : val.toLowerCase().slice(0, 3),
			},
			{
				component: FixedListSelect,
				label: 'Time Zone',
				options: [{ value: 'Europe/London', label: 'Europe / London' }],
			},
		],
		parameter_count: 1,
		propertyMap: ['date', 'unit', 'binSize', 'startOfWeek', 'timezone'],
		defaultValues: [
			defaultParamValues[0],
			'week',
			1,
			'sunday',
			'Europe/London',
		],
		unary_operator: true,
	},
	$arrayToObject: {
		title: 'Array to Object',
		description:
			'Convert an array to an object, each array item should be an object with property k and v, being the key in the new object and value respectively',
		// parameter_fields: [
		// 	{ ...defaultParams[0], label: 'key', type: 'string' },
		// 	{ ...defaultParams[0], label: 'value' },
		// ],
		// parameter_count: -1,
		// defaultValues: [
		// 	() => ({
		// 		typeValue: 'static_string',
		// 		fieldValue: '',
		// 	}),
		// 	defaultParamValues[0],
		// ],
		// propertyMap: ['k', 'v'],
		unary_operator: true,
		parameter_fields: defaultParams,
		parameter_count: 1,
		defaultValues: defaultParamValues,
	},
	$mergeObjects: {
		title: 'Merge Objects',
		description:
			'takes each object and merges its properties, the last occurrence of the property will be the final value',
		parameter_fields: defaultParams,
		parameter_count: -1,
		defaultValues: defaultParamValues,
	},

	$dateToString: {
		title: 'Date to String',
		description: 'Converts a Date field to a string in a format',
		parameter_fields: [
			{ ...defaultParams[0], label: 'date' },
			{
				...defaultParams[0],
				label: 'format',
				type: 'string',
				allowedFieldTypes: ['static'],
			},
		],
		parameter_count: 1,
		propertyMap: ['date', 'format'],
		defaultValues: [
			defaultParamValues[0],
			() => ({
				typeValue: 'static_string',
				fieldValue: '%Y-%m-%d',
			}),
		],
		unary_operator: true,
	},
	$literal: {
		title: 'Literal Value',
		description:
			'Use when you want to insert an object or string that would normally evaluate as an expression (e.g strings starting with a dollar sign or objects)',
		parameter_fields: { ...defaultParams, allowedFieldTypes: 'static' },
		defaultValues: defaultParamValues,
		parameter_count: 1,
		unary_operator: true,
	},
	$abs: {
		...defaultOperator,
		title: 'Absolute',
		description: 'Returns the absolute value of a number field',
		parameter_count: 1,
		unary_operator: true,
	},
	$add: {
		...defaultOperator,
		title: 'Add',
		description:
			'Add together 2 or more numbers, if any parameter is null, the final result is null',
	},
	$subtract: {
		...defaultOperator,
		title: 'Subtract',
		description:
			'Returns the result of subtracting the second argument from the first',
		parameter_count: 2,
	},
	$multiply: {
		...defaultOperator,
		title: 'Multiply',
		description: 'Returns the product of all values',
	},
	$divide: {
		...defaultOperator,
		title: 'Divide',
		description:
			'Returns the result of dividing the first input by the second',
		parameter_count: 2,
	},
	$pow: {
		...defaultOperator,
		title: 'Power of',
		description:
			'Returns the value of the first input to the power of the second',
		parameter_count: 2,
	},
	$ceil: {
		...defaultOperator,
		title: 'Ceil',
		description: 'Returns the rounded up value of the input',
		parameter_count: 1,
	},
	$floor: {
		...defaultOperator,
		title: 'Floor',
		description: 'Returns the rounded down value of the input',
		parameter_count: 1,
	},
	$trunc: {
		...defaultOperator,
		title: 'Truncate',
		description:
			'Returns the value as an integer truncated to a significant number of places between -20 and 100',
		parameter_count: 2,
	},
};

const accumulator_expressions = {
	$avg: {
		title: 'Average',
		description: 'Returns the mean value of the property in the group',
		parameter_fields: defaultParams,
		defaultValues: defaultParamValues,
		parameter_count: 1,
		unary_operator: true,
	},

	$count: {
		title: 'Count',
		description: 'Returns the count of values in the group',
		parameter_fields: [],
		defaultValues: [],
		propertyMap: [],
		parameter_count: 1,
		unary_operator: true,
	},

	$sum: {
		title: 'Sum',
		description: 'Accumulates the sum of the values in the group',
		parameter_fields: defaultParams,
		defaultValues: defaultParamValues,
		parameter_count: 1,
		unary_operator: true,
	},

	$min: {
		title: 'Min',
		description: 'Returns the minimum value of the property',
		parameter_fields: defaultParams,
		defaultValues: defaultParamValues,
		parameter_count: 1,
		unary_operator: true,
	},
	$max: {
		title: 'Max',
		description: 'Returns the maximum value of the property',
		parameter_fields: defaultParams,
		defaultValues: defaultParamValues,
		parameter_count: 1,
		unary_operator: true,
	},
	$first: {
		title: 'First',
		description:
			'Returns the first value of the property, the group stage does not guarantee any sort order, ensure you have a sort stage before',
		parameter_fields: defaultParams,
		defaultValues: defaultParamValues,
		parameter_count: 1,
		unary_operator: true,
	},
	$last: {
		title: 'Last',
		description:
			'Returns the last value of the property, the group stage does not guarantee any sort order, ensure you have a sort stage before',
		parameter_fields: defaultParams,
		defaultValues: defaultParamValues,
		parameter_count: 1,
		unary_operator: true,
	},
	$push: {
		title: 'Push',
		description: 'Adds each value to an array in the output',
		parameter_fields: defaultParams,
		defaultValues: defaultParamValues,
		parameter_count: 1,
		unary_operator: true,
	},
	$mergeObjects: {
		title: 'Merge Objects',
		description:
			'takes each object and merges its properties, the last occurrence of the property will be the final value',
		parameter_fields: defaultParams,
		parameter_count: 1,
		defaultValues: defaultParamValues,
		unary_operator: true,
	},
};

function FixedListSelect(props) {
	const { options, value, onChange, label, coerce, ...rest } = props;

	const selectedOption = useMemo(
		() =>
			options.filter(
				opt => (coerce?.(value) || value) === opt.value
			)[0] || {
				value: '',
				label: '',
				// label: 'None',
			},
		[options, value, coerce]
	);

	const handleChange = useCallback(event => onChange(event.target.value), [
		onChange,
	]);

	return (
		<FormControl variant="standard" fullWidth {...rest}>
			{label && <InputLabel>{label}</InputLabel>}
			<Select
				variant="standard"
				required
				value={coerce?.(value) ?? value ?? ''}
				onChange={handleChange}
				renderValue={val =>
					selectedOption?.label ?? selectedOption?.value
				}
			>
				{/* <MenuItem key="empty">
					<em>None</em>
				</MenuItem> */}
				{options.map(opt => (
					<MenuItem key={opt.value} value={opt.value}>
						{opt.label ?? opt.value}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}

function getExpressionType(obj) {
	for (const key in obj) {
		if (Object.hasOwnProperty.call(obj, key)) {
			return key;
		}
	}
}

function createExpressionDefaults(defaultValues, count) {
	const arr = [];
	for (let index = 0; index < count; index++) {
		arr.push(
			defaultValues.map(dv => (typeof dv === 'function' ? dv() : dv))
		);
	}

	return arr;
}

function buildFormValues(expression, expressions, flatConfigSchema, variables) {
	const exprKey = getExpressionType(expression);

	const expr = expressions[exprKey];

	// TODO - handle unsupported expression?

	if (expr.propertyMap) {
		return [
			expr.propertyMap.map((prop, n) => {
				const formField = expr.parameter_fields[n];
				if (formField.component === BtQueryBuilderPropertyField) {
					const [type, value] = determineValueType(
						expression[exprKey][prop] ?? null,
						flatConfigSchema,
						!!formField.asPath,
						variables
					);
					return { typeValue: type, fieldValue: value };
				} else {
					return expression[exprKey][prop] ?? null;
				}
			}),
		];
	}

	const arr = Array.isArray(expression[exprKey])
		? expression[exprKey]
		: [expression[exprKey]];

	const fields = [];

	const formField = expr.parameter_fields[0];

	for (let index = 0; index < arr.length; index++) {
		const field = arr[index];

		if (formField.component === BtQueryBuilderPropertyField) {
			const [type, value] = determineValueType(
				field,
				flatConfigSchema,
				!!formField.asPath,
				variables
			);

			fields.push([{ typeValue: type, fieldValue: value }]);
		}
	}

	return fields;
}

function buildExpressionObject(expression, expressions, parameters, variables) {
	let out = null;

	if (!expression) {
		return out;
	}

	if (expressions[expression].propertyMap) {
		out = {};
	} else {
		out = [];
	}

	parameters.forEach(parameter => {
		if (expressions[expression].propertyMap) {
			out = {};
			// the arguments build up an object rather than an array
			parameter?.forEach((field, n) => {
				const propName = expressions[expression].propertyMap[n];

				if (
					field !== null &&
					typeof field === 'object' &&
					Object.hasOwnProperty.call(field, 'typeValue') &&
					Object.hasOwnProperty.call(field, 'fieldValue')
				) {
					out[propName] = replaceDynamicTypedValue(
						'field',
						{
							field_type: field.typeValue,
							field: field.fieldValue,
						},
						variables
					);
				} else {
					out[propName] = field;
				}
			});

			out = [out];
		} else {
			parameter?.forEach(field => {
				if (
					typeof field === 'object' &&
					Object.hasOwnProperty.call(field, 'typeValue') &&
					Object.hasOwnProperty.call(field, 'fieldValue')
				) {
					out.push(
						replaceDynamicTypedValue(
							'field',
							{
								field_type: field.typeValue,
								field: field.fieldValue,
							},
							variables
						)
					);
				} else {
					out.push(field);
				}
			});
		}
	});

	if (expressions[expression].unary_operator) {
		out = out[0];
	}

	return { [expression]: out };
}

function ParameterField(props) {
	const {
		onChange,
		component,
		componentProps,
		value,
		mode,
		depth,
		...rest
	} = props;
	const {
		onChange: componentOnChange,
		...restOfComponentProps
	} = componentProps;

	const handleChange = useCallback(
		event =>
			componentOnChange
				? onChange(componentOnChange(event))
				: onChange(event),
		[componentOnChange, onChange]
	);

	if (component === BtQueryBuilderPropertyField) {
		return (
			<Box
				component={component}
				{...restOfComponentProps}
				{...value}
				{...rest}
				expressionMode={mode}
				expressionDepth={depth}
				onChange={handleChange}
			/>
		);
	}

	return (
		<Box
			component={component}
			{...restOfComponentProps}
			{...rest}
			value={value}
			onChange={handleChange}
		/>
	);
}

function ParameterFields(props) {
	const {
		parameters,
		dispatch,
		values,
		index,
		canDelete,
		expressionFieldId,
		mode,
		depth,
	} = props;

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					direction: 'row',
					width: '100%',
					marginY: 0.25,
					justifyContent: 'stretch',
					flexWrap: 'wrap',
					alignItems: 'flex-end',
				}}
			>
				{parameters.map((field, n) => {
					const { component, ...componentProps } = field;

					return (
						<Box
							key={n}
							sx={{ flexGrow: 1, minWidth: 'calc(50% - 24px)' }}
						>
							<Box sx={{ marginX: 1, marginY: 0.5 }}>
								<ParameterField
									component={component}
									componentProps={componentProps}
									value={values[n] || {}}
									id={
										expressionFieldId +
										'-' +
										index +
										'-' +
										n
									}
									name={
										expressionFieldId +
										'-' +
										index +
										'-' +
										n
									}
									onChange={event => {
										dispatch({
											action: 'update',
											index: index,
											fieldIndex: n,
											value:
												component ===
												BtQueryBuilderPropertyField
													? {
															typeValue:
																event.type,
															fieldValue:
																event.field,
													  }
													: event,
										});
									}}
									mode={mode}
									depth={depth}
								/>
							</Box>
						</Box>
					);
				})}
				{canDelete && (
					<Box flexShrink="1">
						<IconButton
							onClick={() =>
								dispatch({
									action: 'delete',
									index: index,
								})
							}
							sx={{
								flexShrink: 1,
							}}
						>
							<CloseIcon fontSize="small" />
						</IconButton>
					</Box>
				)}
			</Box>
		</>
	);
}

export function BtQueryBuilderExpressionField(props) {
	const {
		mode: _mode,
		depth: _depth,
		value: _expressionString,
		id,
		onChange,
	} = props;

	const mode = useRef(_mode || 'operator');

	const { variables } = useQueryBuilderVariableContext();
	const { input } = useQueryBuilderStageContext();

	const depth = _depth || 0;

	const expressions = useRef(
		(() => {
			switch (mode.current) {
				case 'accumulator':
					return accumulator_expressions;

				default:
					return operator_expressions;
			}
		})()
	);

	const _expressions = useRef(Object.keys(expressions.current));

	const [value, setValue] = useState(() => {
		try {
			return JSON.parse(_expressionString);
		} catch (err) {
			return null;
		}
	});

	const [expression, setExpression] = useState(
		(value && getExpressionType(value)) || undefined
	);

	const [fieldValues, setFieldValues] = useState(() => {
		if (expression) {
			let val = [];

			// val = value?.[expression] ?? [];
			// val = Array.isArray(val) ? val : [val];

			if (value?.[expression]) {
				val = buildFormValues(
					value,
					expressions.current,
					input?.flatConfigSchema || {},
					variables
				);
			}

			val = val.concat(
				createExpressionDefaults(
					expressions.current[expression].defaultValues,
					expressions.current[expression].parameter_count - val.length
				)
			);

			return val;
		}

		return [];
	});

	const expressionData = useMemo(
		() => expressions.current[expression] || null,
		[expression]
	);

	const handleParameterFieldChange = useCallback(
		({ action, index, value, fieldIndex }) => {
			if (action === 'delete') {
				setFieldValues(f => {
					const n = [...f];
					n.splice(index, 1, null);
					return n;
				});
				return;
			}

			setFieldValues(f => {
				const n = [...f];
				const fv = n.splice(index, 1);

				const nv = [...fv[0]];
				nv.splice(fieldIndex, 1, value);
				n.splice(index, 0, nv);

				return n;
			});
		},
		[setFieldValues]
	);

	const handleAddParameter = useCallback(
		() => {
			setFieldValues(f => [
				...f,
				createExpressionDefaults(expressionData.defaultValues, 1)[0],
			]);
		},
		[expressionData]
	);

	const selectedExpr = useRef(expression);

	const handleExpressionChange = useCallback(
		event => {
			const value = event.target.value;

			if (value !== selectedExpr.current) {
				setExpression(value);

				if (
					selectedExpr.current &&
					expressions.current[value].parameter_fields ===
						expressions.current[selectedExpr.current]
							.parameter_fields
				) {
					if (expressions.current[value].parameter_count > 0) {
						setFieldValues(fv => {
							if (
								fv.length >
								expressions.current[value].parameter_count
							) {
								return fv.slice(
									0,
									expressions.current[value].parameter_count
								);
							}

							const out = [].concat(
								fv,
								createExpressionDefaults(
									expressions.current[value].defaultValues,
									expressions.current[value].parameter_count -
										fv.length
								)
							);

							return out;
						});
					}
				} else {
					setFieldValues(
						createExpressionDefaults(
							expressions.current[value].defaultValues,
							expressions.current[value].parameter_count
						)
					);
				}

				selectedExpr.current = value;
			}
		},
		[setExpression]
	);

	const outputExpression = useRef(_expressionString);

	useEffect(
		() => {
			const out = buildExpressionObject(
				expression,
				expressions.current,
				fieldValues,
				variables
			);

			const str = JSON.stringify(out);

			if (outputExpression.current !== str) {
				outputExpression.current = str;
				onChange({ expressionStr: str, expression: out });
			}
		},
		[expression, fieldValues, onChange, variables]
	);

	return (
		<>
			<Box sx={{ marginLeft: depth && 0.5 * depth + 'rem' }}>
				<FormControl variant="standard" sx={{ width: '160px' }}>
					{/* <InputLabel>Operator</InputLabel> */}
					<Select
						variant="standard"
						// label="Operator"
						required
						value={expression || ''}
						onChange={handleExpressionChange}
						renderValue={val => expressions.current[val]?.title}
						id={id}
					>
						{/* <MenuItem key="empty">
							<em>None</em>
						</MenuItem> */}
						{_expressions.current.map(exp => (
							<MenuItem key={exp} value={exp}>
								<Box>
									<Typography variant="body1">
										{expressions.current[exp].title}
									</Typography>
									{expressions.current[exp].description && (
										<Typography variant="subtitle2">
											{
												expressions.current[exp]
													.description
											}
										</Typography>
									)}
								</Box>
							</MenuItem>
						))}
					</Select>
				</FormControl>

				{expressionData &&
					fieldValues.map(
						(fv, idx) =>
							fv === null ? null : (
								<ParameterFields
									expressionFieldId={id || 'expr'}
									key={idx}
									parameters={expressionData.parameter_fields}
									values={fv}
									index={idx}
									dispatch={handleParameterFieldChange}
									mode={mode.current}
									canDelete={
										expressionData?.parameter_count < 0 &&
										mode.current !== 'accumulator'
									}
									depth={depth + 1}
								/>
							)
					)}
				{expressionData?.parameter_count < 0 &&
					mode.current !== 'accumulator' && (
						<Button
							onClick={handleAddParameter}
							startIcon={<AddIcon />}
							size="small"
						>
							Add Property
						</Button>
					)}
			</Box>
		</>
	);
}

BtQueryBuilderExpressionField.propTypes = {
	mode: PropTypes.oneOf(['accumulator', 'operator', 'match']),
	depth: PropTypes.number,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
