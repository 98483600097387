import {
	PUBLISH_RECORD_API,
	RECORD_STATUS_API,
	UNPUBLISHED,
} from '../constants';
import { lookupEndpoint } from '../lookupTables/apiEndpoints';
import { nextStatus } from '../lookupTables/moduleAssets';

const changeRecordStatus = async ({
	status,
	apiUuidParamPayload,
	recordHistoryGet,
	moduleFullName,
	module,
}) => {
	// Initialise return value
	const result = {};
	result.variant = 'success';

	if (status === UNPUBLISHED) {
		try {
			// get the request from lookup
			const publishRecordEndpoint = lookupEndpoint(
				module,
				PUBLISH_RECORD_API
			);

			// dispatch the request or raise error if lookup has returned null
			if (publishRecordEndpoint) {
				await publishRecordEndpoint(apiUuidParamPayload);

				// Update return value
				result.text = `${moduleFullName} published`;
			} else {
				throw new Error('');
			}
		} catch (error) {
			console.log(error);
			result.text = `Error publishing ${moduleFullName}`;
			result.variant = 'error';
		}
	} else {
		try {
			// get the request from lookup
			const changeStatusEndpoint = lookupEndpoint(
				module,
				RECORD_STATUS_API
			);

			// dispatch the request or raise error if lookup has returned null
			if (changeStatusEndpoint) {
				await changeStatusEndpoint({
					...apiUuidParamPayload,
					newStatus: nextStatus(status),
				});
			} else {
				throw new Error('error with status update');
			}

			// Update return value
			result.text = `${moduleFullName} status updated`;
		} catch (error) {
			console.log(error);
			result.text = `Error changing ${moduleFullName} status`;
			result.variant = 'error';
		}
	}
	// Reload history data
	recordHistoryGet();
	return result;
};

export { changeRecordStatus };
