import React, { memo, useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { TabPanels } from './TabPanel';
import { TabsAutocomplete } from './TabsAutocomplete';
import { DraggableTabs } from './DraggableTabs';
import useBreakpointValue from '../../../../hooks/useBreakpointValue';

const TabsDisplayContainer = styled(Box)(() => ({
	height: '100%',
	width: '100%',
	typography: 'body1',
	minHeight: '100%',
	// paddingTop: '5px',
}));

const TabListBox = styled(Box)(({ theme, width }) => ({
	height: '54px',
	width: width,
	// display: 'flex',
	// borderBottom: `2px solid ${
	// 	theme.palette.mode === 'dark' ? '#ffffff3a' : '#0000001d'
	// }`,
	borderBottom: `1px solid ${theme.palette.divider}`,
	// boxSizing: 'border-box',
	// marginBottom: '5px',
	backgroundColor: theme.palette.background.default,
}));

const TabBarBox = styled(Box)(({ theme }) => ({
	width: '100%',
	display: 'flex',
	justifyContent: 'space-between',
	position: 'sticky',
	zIndex: 95,
	paddingTop: '10px',
	backgroundColor: theme.palette.background.default,
}));

const TabsDisplay = memo(function TabsDisplay({
	autoComplete,
	autoCompleteOptions,
	containerWidth,
	draggable,
	initializing,
	offsetTop,
	onDragEnd,
	setValue,
	tabs,
	value,
}) {
	const breakpoint = useBreakpointValue();

	const handleChange = useCallback(
		(event, newValue) => {
			setValue(newValue);
		},
		[setValue]
	);

	const showAutoComplete = useMemo(
		() => autoComplete && !['xs', 'xxs'].includes(breakpoint),
		[autoComplete, breakpoint]
	);

	const TabListBoxWidth = useMemo(
		() => (showAutoComplete ? `${containerWidth - 250}px` : containerWidth),
		[showAutoComplete, containerWidth]
	);

	return (
		<TabsDisplayContainer>
			{!initializing ? (
				<TabBarBox top={offsetTop}>
					<TabListBox width={TabListBoxWidth}>
						<DraggableTabs
							draggable={draggable}
							handleChange={handleChange}
							onDragEnd={onDragEnd}
							value={value}
							tabs={tabs}
						/>
					</TabListBox>
					{showAutoComplete && (
						<TabsAutocomplete
							autoCompleteOptions={autoCompleteOptions}
							onChange={handleChange}
						/>
					)}
				</TabBarBox>
			) : (
				<TabListBox />
			)}
			<TabPanels value={value} tabs={tabs} />
		</TabsDisplayContainer>
	);
});

const tabShape = PropTypes.shape({
	content: PropTypes.element.isRequired,
	label: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	icon: PropTypes.element,
});

TabsDisplay.propTypes = {
	autoComplete: PropTypes.bool,
	autoCompleteOptions: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
		})
	),
	containerWidth: PropTypes.number,
	draggable: PropTypes.bool,
	initializing: PropTypes.bool,
	offsetTop: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
	onDragEnd: PropTypes.func.isRequired,
	setValue: PropTypes.func.isRequired,
	tabs: PropTypes.arrayOf(tabShape),
	value: PropTypes.string.isRequired,
};

TabsDisplay.displayName = 'TabsDisplay';

export { TabsDisplay };
