import { apiClient } from './api_client';
import { object, number } from 'yup';

const paginationResultSchema = object({
	anchor: number().nullable(),
	total: number().required(),
	unseen: number().required(),
});

export default async function serverRequest({
	resDataProp,
	responseSchema = null,
	...other
}) {
	// Make the api request
	const apiResponse = await apiClient({ ...other });

	// Check for a correct result
	if (apiResponse.result !== 'OK') {
		throw new Error('Response Error');
	}

	// If a response schema is set validate the response
	var validatedData = apiResponse[resDataProp];
	if (responseSchema) {
		validatedData = await responseSchema.validate(apiResponse[resDataProp]);
	}

	// If there is a pagination object in the response validate it
	var validatedPagination = null;
	if (apiResponse.pagination) {
		validatedPagination = await paginationResultSchema.validate(
			apiResponse.pagination
		);
	}

	// Build the response
	var response = { data: validatedData };
	if (validatedPagination) {
		response.pagination = validatedPagination;
	}
	return response;
}
