import { useContext } from 'react';
import { TileContext } from '../components/TileContext';
import { Divider, Typography, useTheme } from '@mui/material';

export default function InformationTile() {
	const { screenData } = useContext(TileContext);
	const theme = useTheme();
	return (
		<div
			style={{
				height: '100%',
				width: '100%',
				// padding: '0.5em',
				display: 'flex',
				borderLeft: `5px solid ${theme.palette.indicators.info.main}`,
				borderRadius: 8,
				flexDirection: 'column',
			}}
		>
			<Typography
				sx={{
					fontWeight: 'bold',
					padding: '4px',
				}}
				// variant="h5"
			>
				Screen details
			</Typography>
			<Divider />

			<div
				style={{
					padding: '4px',
					overflow: 'scroll',
				}}
			>
				<Typography variant="caption">Name</Typography>
				<Typography>{screenData.screen_name}</Typography>
				<Typography variant="caption">Owner</Typography>
				<Typography>
					<em>
						{screenData.ownerType === 'organisation'
							? 'Organisation'
							: 'User'}
					</em>
				</Typography>
			</div>

			{/* <pre>{JSON.stringify(tileData.data.items, null, 4)}</pre> */}
			{/* <InfoIcon
				style={{
					color: theme.palette.indicators.info.main,
					fontSize: 20,
					// margin: '0.1em',
				}}
			/> */}
			{/* <Information /> */}
			{/* <Typography>i am info</Typography> */}
			{/* {tileData.data.items?.map(item => <Typography>{item}</Typography>)} */}
			{/* {tileData.data.items?.map(item => <li>{item}</li>)}*/}
			{/* <div
				style={{
					display: 'flex',
					alignItems: 'center',
					// justifyContent: 'space-between',
				}}
			>
				<InfoIcon
					style={{
						color: theme.palette.indicators.info.main,
						fontSize: 20,
						marginRight: '0.1em',
					}}
				/>
				<Typography variant="caption">Screen</Typography>
			</div> */}
			{/* <Typography variant="caption">Screen</Typography> */}
			{/* <Divider /> */}
			{/* <Typography style={{ marginLeft: 5 }} variant="caption">
				Screen details
			</Typography> */}
		</div>
	);
}
