import React from 'react';
import { Chart } from '../Components/Chart';
import { STATUS_TIMELINE_CHART } from '../Constants/constants';
import propTypes from 'prop-types';

export function StatusTimelineChart({ data, children }) {
	return (
		<Chart data={data} type={STATUS_TIMELINE_CHART}>
			{children}
		</Chart>
	);
}

StatusTimelineChart.propTypes = {
	data: propTypes.array,
	children: propTypes.array,
};
