const { OPERATION_MODE } = require('../../expression');
const {
	setOperatorSchemaFn,
	evaluateExpressionSchema,
} = require('../expression');
const { getType, newDefaultSchema } = require('../utils');

const operationModes = [OPERATION_MODE.AGGREGATE];

function convert(context, args, options) {
	const to = args['to'];

	switch (to) {
		case 'string':
			return newDefaultSchema('string');
		case 'bool':
			return newDefaultSchema('boolean');
		case 'date':
			return newDefaultSchema('date');
		case 'int':
			return newDefaultSchema('number');
		case 'long':
			return newDefaultSchema('number');
		case 'double':
			return newDefaultSchema('number');
		case 'decimal':
			return newDefaultSchema('number');
	}
}

setOperatorSchemaFn(
	'$convert',
	(...props) => convert(...props, '$convert'),
	operationModes
);
