const {
	setOperator,
	evaluateExpression,
	OPERATION_MODE,
} = require('../expression.js');

const operatorKey = '$ifNull';

setOperator(
	operatorKey,

	/**
	 * @type {ExpressionFunction<any[],any>}
	 */
	(context, args, options) => {
		const arr = !Array.isArray(args) ? [args] : args;

		for (let pos = 0; pos < arr.length; pos++) {
			/**
			 * @type {any}
			 */
			const value = evaluateExpression(context, arr[pos]);

			if (value !== null && value !== undefined) {
				return value;
			}
		}

		return null;
	},
	[OPERATION_MODE.AGGREGATE]
);
