import PropTypes from 'prop-types';
import { FormatListBulleted } from 'mdi-material-ui';
import { useCallback, useContext, useMemo, useState } from 'react';
import PermsSubsErrorDetailDialog from '../../../dialogs/PermsSubsErrorDetailDialog';
import BtDialog from '../../BtDialog';
import BtError from '../../BtError';
import { tileLabels } from '../tileConstants';
import { TileContext } from './TileContext';

const XS = 'xs';
const SM = 'sm';
// const MD = 'md';
// const LG = 'lg';
const DEFAULT = 'default';

export default function TileError() {
	const { tileData, tileSize, error } = useContext(TileContext);
	const [showDetailsDialog, setShowDetailsDialog] = useState(false);

	const handleClose = useCallback(
		() => {
			setShowDetailsDialog(false);
		},
		[setShowDetailsDialog]
	);

	const variant = useMemo(() => tileData.variant, [tileData.variant]);

	const size = useMemo(
		() => {
			if (tileSize.w < 150 || tileSize.h < 150) {
				return XS;
			} else if (tileSize.w < 270 || tileSize.h < 270) {
				return SM;
			}
			return DEFAULT;
		},
		[tileSize]
	);

	const errorAttributes = useMemo(
		() => {
			if (!error) return null;

			if (error?.missingSubscriptions || error?.missingSubscriptions) {
				return {
					description:
						size === XS
							? 'Subscription Error'
							: `This ${
									tileLabels[variant]
							  } tile cannot be displayed due to a problem with your subscriptions.`,
					variant: 'subscription',
					action: () => setShowDetailsDialog(true),
					actionIcon: <FormatListBulleted />,
					actionLabel: 'Details',
				};
			}

			if (error?.missingPermissionsAll || error?.missingPermissionsAny) {
				return {
					description:
						size === XS
							? 'Permission Error'
							: `This ${
									tileLabels[variant]
							  } tile cannot be displayed due to a problem with your permissions.`,
					variant: 'permission',
					action: () => setShowDetailsDialog(true),
					actionIcon: <FormatListBulleted />,
					actionLabel: 'Details',
				};
			}

			if (error?.serverError) {
				return {
					description:
						size === XS
							? 'Server Error'
							: `This ${
									tileLabels[variant]
							  } tile cannot be displayed as something went wrong whilst requesting the required data.`,
					variant: 'permission',
					action: () => setShowDetailsDialog(true),
					actionIcon: <FormatListBulleted />,
					actionLabel: 'Details',
				};
			}

			if (error?.containerTileConfigRequired) {
				return {
					description:
						size === XS
							? 'Empty Container'
							: 'This Container is empty, please use the controls at the top right to add buttons.',
					variant: 'permission',
					// action: () => setShowDetailsDialog(true),
					// actionIcon: <FormatListBulleted />,
					// actionLabel: 'Details',
				};
			}

			if (error?.insightTileConfigRequired) {
				return {
					description:
						size === XS
							? // ? `${tileLabels[variant]} requires configuration`
							  'Config required'
							: `This ${
									tileLabels[variant]
							  } requires configuration, please use the controls at the top right.`,
					variant: 'permission',
					// action: () => setShowDetailsDialog(true),
					// actionIcon: <FormatListBulleted />,
					// actionLabel: 'Details',
				};
			}

			if (error?.buttonTileConfigRequired) {
				return {
					description:
						size === XS
							? // ? `${tileLabels[variant]} requires configuration`
							  'Config required'
							: `This ${
									tileLabels[variant]
							  } requires configuration, please use the controls at the top right.`,
					variant: 'permission',
					// action: () => setShowDetailsDialog(true),
					// actionIcon: <FormatListBulleted />,
					// actionLabel: 'Details',
				};
			}
		},
		[error, size, variant]
	);
	// console.log({ error, children });
	// if (error) {
	return (
		<>
			<BtError
				size={size}
				tileError
				title={errorAttributes?.title}
				description={errorAttributes?.description}
				variant={errorAttributes?.variant}
				action={errorAttributes?.action}
				actionIcon={errorAttributes?.actionIcon}
				actionLabel={errorAttributes?.actionLabel}
			/>
			<BtDialog open={showDetailsDialog} onClose={handleClose}>
				<PermsSubsErrorDetailDialog
					error={error}
					onClose={handleClose}
				/>
			</BtDialog>
		</>
	);
	// } else {
	// 	// console.log({ children });
	// 	return children ? children : null;
	// }
}

TileError.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};
