import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import {
	Autocomplete,
	ButtonBase,
	Chip as MuiChip,
	FormControl,
	FormGroup,
	FormHelperText,
	ListItem,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import Color from 'color';
import { styled } from '@mui/material/styles';
import { v4 as uuid } from 'uuid';

import { getSafeColorForBackground } from '../../utils/colourUtils';
import useUsingObjects from '../../hooks/useUsingObjects';
import useOverflowedElementCount from '../../hooks/useOverflowedElementCount';

const SMALL = 'small';
const MEDIUM = 'medium';

const containerStyle = ({
	clickable,
	disabled,
	singleline,
	isempty,
	small,
	theme,
}) => ({
	alignItems: 'flex-start',
	backgroundColor:
		clickable && isempty && !disabled
			? theme.palette.grey['400'] + 20
			: 'inherit',
	border: 'none',
	borderRadius: 5,
	cursor: clickable && !disabled ? 'pointer' : 'default',
	display: 'flex',
	height: (() => {
		if (singleline) {
			if (small) return 28;
			return 38;
		}
		return 'auto';
	})(),
	justifyContent: 'flex-start',
	minHeight: (() => {
		if (clickable) {
			if (small) return 28;
			return 38;
		}
		return 'inherit';
	})(),
	outline: 'none',
	overflow: 'hidden',
	padding: 0,
	pointerEvents: clickable ? 'all' : 'none',
	userSelect: 'none',
	width: '100%',

	'&:hover': {
		backgroundColor:
			clickable && !disabled
				? Color(theme.palette.grey['400'])
						.fade(0.75)
						.toString()
				: 'inherit',
	},

	'&:active': {
		backgroundColor:
			clickable && !disabled
				? Color(theme.palette.grey['400'])
						.fade(0.7)
						.toString()
				: 'inherit',
	},
});

const ChipWrapper = styled('div')(({ hashidden, small }) => ({
	alignItems: 'center',
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'flex-start',
	paddingRight: (() => {
		if (hashidden) {
			return small ? 26 : 35;
		}
		return 0;
	})(),
	width: '100%',
}));

const MoreIndicator = styled('div')(
	({ pointercursor, left, small, theme }) => ({
		alignItems: 'center',
		backgroundColor: theme.palette.grey['300'],
		borderRadius: small ? 12 : 16,
		...(pointercursor && { cursor: 'pointer' }),
		color: '#000000',
		display: 'flex',
		fontSize: small ? 10 : 12,
		fontWeight: 'bold',
		height: small ? 24 : 32,
		justifyContent: 'center',
		left,
		margin: small ? 2 : 3,
		minWidth: small ? 24 : 32,
		pointerEvents: 'all',
		position: 'absolute',
		top: 0,
		width: small ? 24 : 32,
	})
);

const Button = styled(ButtonBase)(containerStyle);
const Display = styled('div')(containerStyle);

const Chip = styled(MuiChip)(({ small }) => ({
	margin: small ? 2 : 3,
	pointerEvents: 'none',
	userSelect: 'none',
}));

const extractLabel = (option, usingObjects, primaryField) =>
	(() => {
		if (usingObjects) {
			if (primaryField) {
				return option[primaryField];
			}

			return 'Item';
		}

		return option;
	})();

const ChipContainer = ({
	clickable,
	colorKey,
	idKey,
	isSmall,
	partOfButton,
	primaryField,
	singleLine,
	size,
	usingObjects,
	value,
}) => {
	const containerRef = useRef();

	const {
		assessOverflow: recheckOverflow,
		count: overflowCount,
		topRowWidth: moreIndicatorPos,
		firstHiddenIndex,
	} = useOverflowedElementCount(containerRef);

	const hasHidden = useMemo(() => +(overflowCount > 0), [overflowCount]);

	useEffect(() => recheckOverflow(), [recheckOverflow, value]);

	return (
		<div style={{ width: '100%', position: 'relative' }}>
			<ChipWrapper
				ref={containerRef}
				hashidden={hasHidden}
				small={isSmall}
			>
				{(value || []).map(item => {
					const label = extractLabel(
						item,
						usingObjects,
						primaryField
					);

					const backgroundColour = colorKey && item[colorKey];
					const foregroundColour = getSafeColorForBackground(
						backgroundColour
					);

					return (
						<div key={usingObjects ? item[idKey] ?? uuid() : label}>
							<Chip
								label={label}
								size={size}
								small={+isSmall}
								style={{
									...(colorKey && {
										backgroundColor: backgroundColour,
										color: foregroundColour,
									}),
								}}
							/>
						</div>
					);
				})}
			</ChipWrapper>
			{overflowCount > 0 &&
				singleLine && (
					<Tooltip
						title={
							<ul
								style={{
									listStyleType: 'none',
									margin: 0,
									padding: 0,
								}}
							>
								{(value || [])
									.slice(-(value.length - firstHiddenIndex))
									.map((value, index) => (
										<li key={index}>
											{(() => {
												if (usingObjects) {
													return value[primaryField];
												}

												return value;
											})()}
										</li>
									))}
							</ul>
						}
						disableInteractive
					>
						<MoreIndicator
							pointercursor={clickable || +partOfButton}
							left={moreIndicatorPos}
							small={isSmall}
						>
							<span>
								{overflowCount < 100
									? `+${overflowCount}`
									: '...'}
							</span>
						</MoreIndicator>
					</Tooltip>
				)}
		</div>
	);
};

export const BtChipsDisplay = ({
	colorKey,
	disabled,
	singleLine,
	idKey,
	onClick,
	partOfButton,
	placeholder,
	primaryField,
	readOnly,
	size,
	value,
}) => {
	const Container = useMemo(() => (readOnly ? Display : Button), [readOnly]);

	const usingObjects = useUsingObjects(value || []);

	const isEmpty = useMemo(() => (value || []).length === 0, [value]);
	const isSmall = useMemo(() => +(size === SMALL), [size]);
	const clickable = useMemo(() => +(!readOnly && !disabled), [
		disabled,
		readOnly,
	]);

	return (
		<Container
			isempty={+isEmpty}
			clickable={clickable}
			small={isSmall}
			onClick={onClick}
			disabled={disabled}
			singleline={+singleLine}
			style={{ opacity: disabled ? 0.8 : 1.0 }}
		>
			{(value || []).length === 0 ? (
				<Typography
					variant="subtitle2"
					style={{
						opacity: 0.7,
						margin: readOnly ? 0 : '0 1em',
						alignSelf: 'center',
					}}
					className="overflow_not_countable"
				>
					{placeholder}
				</Typography>
			) : (
				<ChipContainer
					clickable={clickable}
					colorKey={colorKey}
					idKey={idKey}
					isSmall={isSmall}
					partOfButton={partOfButton}
					primaryField={primaryField}
					singleLine={singleLine}
					size={size}
					usingObjects={usingObjects}
					value={value}
				/>
			)}
		</Container>
	);
};

export const BtChips = forwardRef(
	(
		{
			colorKey,
			defaultValue,
			disabled,
			errorText,
			singleLine,
			inputPlaceholder,
			items,
			idKey,
			label,
			onChange,
			partOfButton,
			placeholder,
			primaryField,
			readOnly,
			renderOption,
			size,
			value,
		},
		ref
	) => {
		const [editMode, setEditMode] = useState(false);
		const [_value, setValue] = useState(null);

		const usingObjects = useUsingObjects(items);

		useEffect(
			() => {
				if (!_value && defaultValue) {
					setValue(defaultValue);
				}
			},
			[_value, defaultValue]
		);

		useEffect(
			() => {
				if (value) {
					setValue(value);
				}
			},
			[value]
		);

		const isSmall = useMemo(() => +(size === SMALL), [size]);

		return (
			<FormControl
				error={!!errorText}
				disabled={disabled}
				ref={ref}
				fullWidth
			>
				{label && (
					<Typography
						color={!!errorText ? 'error' : 'default'}
						style={{
							marginBottom: '0.5em',
							opacity: disabled ? 0.7 : 1.0,
						}}
						variant="subtitle2"
					>
						{label}
					</Typography>
				)}
				<FormGroup>
					{!editMode && (
						<BtChipsDisplay
							value={_value}
							size={size}
							readOnly={readOnly}
							disabled={disabled}
							singleline={singleLine}
							idKey={idKey}
							partOfButton={partOfButton}
							placeholder={placeholder}
							primaryField={primaryField}
							colorKey={colorKey}
							onClick={() => {
								if (!disabled && !readOnly) {
									setEditMode(true);
								}
							}}
						/>
					)}
					{editMode && (
						<Autocomplete
							multiple
							options={items}
							value={_value ?? []}
							loading={(items || []).length === 0}
							size={size}
							disabled={disabled || readOnly}
							onChange={(event, values) => {
								setValue(values);
								if (onChange) {
									onChange(event, values);
								}
							}}
							getOptionLabel={option =>
								extractLabel(option, usingObjects, primaryField)
							}
							renderTags={value =>
								value.map(option => (
									<Chip
										label={
											usingObjects
												? option[primaryField]
												: option
										}
										style={{
											...(colorKey && {
												backgroundColor:
													option[colorKey],
											}),
										}}
									/>
								))
							}
							fullWidth
							renderInput={params => (
								<div
									style={{
										minHeight: isSmall ? 30 : 40,
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<TextField
										{...params}
										placeholder={inputPlaceholder}
										variant="standard"
										disabled={disabled}
										autoFocus
										onBlur={() => {
											setEditMode(false);
										}}
									/>
								</div>
							)}
							renderOption={(options, state) => {
								if (renderOption === undefined) {
									return (
										<ListItem {...options}>
											<Typography>
												{(() => {
													if (usingObjects) {
														if (primaryField) {
															return state[
																primaryField
															];
														}
														return 'Item';
													}
													return state;
												})()}
											</Typography>
										</ListItem>
									);
								}
								return renderOption(options, state);
							}}
						/>
					)}
					{errorText && (
						<FormHelperText style={{ margin: 0 }}>
							{errorText}
						</FormHelperText>
					)}
				</FormGroup>
			</FormControl>
		);
	}
);

const commonDefaultProps = {
	singleLine: false,
	placeholder: 'None',
	size: MEDIUM,
};

BtChips.defaultProps = {
	...commonDefaultProps,
	inputPlaceholder: 'Search for items',
	subject: 'items',
};

BtChipsDisplay.defaultProps = {
	...commonDefaultProps,
};

const itemsType = PropTypes.arrayOf(
	PropTypes.oneOfType([PropTypes.string, PropTypes.object])
);

const commonPropTypes = {
	colorKey: PropTypes.string,
	disabled: PropTypes.bool,
	idKey: PropTypes.string,
	partOfButton: PropTypes.bool,
	placeholder: PropTypes.string,
	primaryField: PropTypes.string,
	readOnly: PropTypes.bool,
	singleLine: PropTypes.bool,
	size: PropTypes.oneOf([SMALL, MEDIUM]),
	value: itemsType,
};

BtChips.propTypes = {
	...commonPropTypes,
	defaultValue: itemsType,
	errorText: PropTypes.string,
	inputPlaceholder: PropTypes.string,
	items: itemsType,
	label: PropTypes.string,
	onChange: PropTypes.func,
	renderOption: PropTypes.func,
};

BtChipsDisplay.propTypes = {
	...commonPropTypes,
	onClick: PropTypes.func,
};

ChipContainer.propTypes = {
	clickable: PropTypes.number.isRequired,
	colorKey: commonPropTypes.colorKey,
	idKey: commonPropTypes.idKey,
	isSmall: PropTypes.number.isRequired,
	partOfButton: PropTypes.bool,
	primaryField: commonPropTypes.primaryField,
	singleLine: commonPropTypes.singleLine,
	size: commonPropTypes.size,
	usingObjects: PropTypes.bool.isRequired,
	value: commonPropTypes.value,
};

BtChips.displayName = 'Chips';

export default BtChips;
