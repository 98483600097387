import React, { Fragment, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Card, CardActionArea, styled, Typography } from '@mui/material';
import Color from 'color';
import { dot } from 'dot-object';
import _ from 'lodash';

import {
	ARRAY,
	DATE,
	NULL,
	OBJECT,
	UNDEFINED,
} from '../../../../../utils/commonDataTypes';
import BtHeightAnimator from '../../../../../components/generic/BtHeightAnimator';
import DataSetExpanded from './DataSetExpanded';
import determineType from '../../../utils/determineType';
import {
	IN_DEPTH_CHIPS,
	NUMBER_OF_PREVIEW_ITEMS,
	TOP_LEVEL_PREVIEW,
} from '../../../utils/constants';
import { useDataSetContext } from '../../../hocs/withDataSetContext';
import { useDataSetEditorContext } from '../../../contexts/DataSetEditorContext';
import { useTimeFormatter } from '../../../../../hooks/useTimeFormat';

const StyledCard = styled(Card)(({ theme }) => ({
	marginBottom: '0.5em',
	padding: 0,

	'&.editing': {
		border: `1px solid ${theme.palette.indicators.info.main}`,
	},
}));

const ChipsContainer = styled('div')(() => ({
	borderRadius: 4,
	boxOrient: 'vertical',
	display: '-webkit-box',
	fontFamily: 'monospace',
	fontSize: 13,
	lineClamp: 4,
	marginRight: 4,
	maxHeight: 76,
	overflowWrap: 'break-word',
	overflow: 'hidden',
	padding: 0,
	WebkitBoxOrient: 'vertical',
	WebkitLineClamp: 4,
	wordBreak: 'break-word',
}));

const ChipsContent = styled('dl')(() => ({
	alignItems: 'baseline',
	border: 'none',
	boxSizing: 'border-box',
	fontSize: 13,
	margin: '0 !important',
	marginBlockEnd: 4,
	marginBlockStart: 4,
	marginInlineEnd: 0,
	marginInlineStart: 0,
	padding: 0,

	'& > dt:first-of-type': {
		marginLeft: 0,
	},
}));

const ChipsKey = styled('dt')(({ theme }) => ({
	backgroundColor: Color(theme.palette.indicators.info.main)
		.fade(0.87)
		.desaturate(0.75)
		.toString(),
	borderBottomLeftRadius: 4,
	borderTopLeftRadius: 4,
	color: theme.palette.text.solid,
	display: 'inline',
	fontSize: 13,
	fontWeight: 500,
	margin: '0 0 0 4px',
	overflowWrap: 'break-word !important',
	padding: '0 4px',
	wordBreak: 'break-word',
}));

const ChipsValue = styled('dd')(({ theme, type }) => ({
	backgroundColor: Color(theme.palette.indicators.info.main)
		.fade(0.93)
		.desaturate(1)
		.toString(),
	borderBottomRightRadius: 4,
	borderTopRightRadius: 4,
	color: Color(theme.palette.dataTypes[type])
		[theme.palette.mode === 'light' ? 'darken' : 'lighten'](0.3)
		.toString(),
	display: 'inline',
	fontSize: 13,
	margin: '0 2px 0 0',
	overflowWrap: 'break-word !important',
	padding: '0 4px',
	wordBreak: 'break-word',
}));

const PreviewKey = styled('span')(({ theme }) => ({
	color: theme.palette.text.solid,
	fontWeight: 500,
}));

const PreviewSepatator = styled('span')(() => ({
	marginRight: '1ch',
}));

const PreviewValue = styled('span')(({ theme, type }) => ({
	color: (() => {
		const nonColorized = Color(theme.palette.text.solid)
			.fade(0.3)
			.toString();

		const colorLookup = {
			...theme.palette.dataTypes,
			array: nonColorized,
			object: nonColorized,
		};

		return colorLookup[type];
	})(),
}));

const TopLevelPreviewList = styled('ul')(() => ({
	listStyleType: 'none',
	margin: 0,
	paddingLeft: 10,
}));

export default function DataSetListItem({ schema, viewMode }) {
	const { editedData, editMode } = useDataSetEditorContext();
	const formatDate = useTimeFormatter();
	const { sending } = useDataSetContext();

	const [expanded, setExpanded] = useState(false);

	const { uuid } = editedData;

	const getBoolString = useCallback(bool => (bool ? 'true' : 'false'), []);

	const chips = useMemo(
		() => {
			const flattened =
				dot(
					_.omit({ uuid, ...editedData.values }, [
						'data_set',
						'insert_timestamp',
						'update_timestamp',
					])
				) ?? {};

			return Object.keys(flattened).reduce((accumulator, key) => {
				const value = flattened[key];

				const valueString =
					typeof value === 'boolean' ? getBoolString(value) : value;

				return [
					...accumulator,
					<Fragment key={key}>
						<ChipsKey>{key}</ChipsKey>
						<ChipsValue type={typeof value}>
							{valueString}
						</ChipsValue>
					</Fragment>,
				];
			}, []);
		},
		[editedData, getBoolString, uuid]
	);

	const preview = useMemo(
		() => {
			const previewData = { uuid, ...editedData.values };

			const keys = Object.keys(previewData);
			const slicedKeys = keys.slice(
				0,
				keys.length > NUMBER_OF_PREVIEW_ITEMS
					? NUMBER_OF_PREVIEW_ITEMS - 1
					: NUMBER_OF_PREVIEW_ITEMS
			);

			const formatValue = (value, type) => {
				if (type === ARRAY) {
					const length = value.length;

					return `Array (${length} item${length > 1 ? 's' : ''})`;
				}

				if (type === DATE) {
					return formatDate(value, true, true);
				}

				if (type === NULL) return 'null';

				if (type === OBJECT) {
					const length = Object.keys(value).length;

					return `Object (${length} key/value pair${
						length > 1 ? 's' : ''
					})`;
				}

				if (type === UNDEFINED) return 'undefined';

				try {
					const valueString = value.toString();
					return valueString;
				} catch (error) {
					return '';
				}
			};

			return [
				...slicedKeys.map(key => {
					const value = previewData[key];

					const type = determineType(
						value,
						schema?.objectContent?.[key]
					);

					return (
						<li key={key}>
							<PreviewKey>{key}</PreviewKey>
							<PreviewSepatator>:</PreviewSepatator>
							<PreviewValue type={type}>
								{formatValue(value, type)}
							</PreviewValue>
						</li>
					);
				}),
				...(keys.length > slicedKeys.length
					? [
							<li>
								Plus {keys.length - slicedKeys.length} more
								fields
							</li>,
					  ]
					: []),
			];
		},
		[editedData, formatDate, schema, uuid]
	);

	const dateString = useMemo(
		() => {
			let str = `Inserted: ${formatDate(
				editedData.insert_timestamp,
				true,
				true
			)}`;

			if (editedData.insert_timestamp !== editedData.update_timestamp) {
				str =
					str +
					` • Updated: ${
						editedData.approxUpdate ? '~' : ''
					}${formatDate(editedData.update_timestamp, true, true)}`;
			}

			return str;
		},
		[editedData, formatDate]
	);

	return (
		<StyledCard
			className={editMode && !sending ? 'editing' : undefined}
			variant="outlined"
		>
			<BtHeightAnimator>
				{!expanded && (
					<CardActionArea
						disableRipple
						onClick={() => setExpanded(true)}
						style={{ padding: '0.8em' }}
					>
						<Typography
							style={{
								fontSize: 12,
								fontWeight: 'bold',
								marginBottom: '0.33em',
							}}
						>
							{dateString}
						</Typography>
						{viewMode === IN_DEPTH_CHIPS && (
							<ChipsContainer>
								<ChipsContent>{chips}</ChipsContent>
							</ChipsContainer>
						)}
						{viewMode === TOP_LEVEL_PREVIEW && (
							<TopLevelPreviewList>{preview}</TopLevelPreviewList>
						)}
					</CardActionArea>
				)}
				{expanded && (
					<DataSetExpanded
						dateString={dateString}
						onCollapse={() => setExpanded(false)}
					/>
				)}
			</BtHeightAnimator>
		</StyledCard>
	);
}

DataSetListItem.propTypes = {
	schema: PropTypes.object,
	viewMode: PropTypes.string.isRequired,
};
