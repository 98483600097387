import React from 'react';

import Login from './Login';
import ForgotPassword from './ForgotPassword';
import AccountSetup from './AccountSetup';
import ChangePassword from './ChangePassword';
import Disabled from './Disabled';
import Error from './Error';

export default function AuthView(props) {
	// Returns change password view if flag is true
	if (props.passwordResetRequired) {
		return (
			<ChangePassword
				setUserPropsCallback={props.setUserPropsCallback}
				userProps={props.userProps}
				changeView={props.changeView}
				changeSetPasswordResetRequired={
					props.changeSetPasswordResetRequired
				}
			/>
		);
	}

	// Otherwise route to other auth views
	switch (props.view) {
		case 'ForgotPassword':
			return <ForgotPassword changeView={props.changeView} />;
		case 'ChangePassword':
			return (
				<ChangePassword
					setUserPropsCallback={props.setUserPropsCallback}
					userProps={props.userProps}
					changeView={props.changeView}
					changeSetPasswordResetRequired={
						props.changeSetPasswordResetRequired
					}
				/>
			);
		case 'AccountSetup':
			return <AccountSetup changeView={props.changeView} />;
		case 'Disabled':
			return <Disabled />;
		case 'Error':
			return <Error changeView={props.changeView} />;
		default:
			return (
				<Login
					setUserPropsCallback={props.setUserPropsCallback}
					changeView={props.changeView}
					passwordResetRequired={props.passwordResetRequired}
					changeSetPasswordResetRequired={
						props.changeSetPasswordResetRequired
					}
					setAuthLoadingCallback={props.setAuthLoadingCallback}
				/>
			);
	}
}
