import React, {
	Children,
	forwardRef,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';

import {
	ChevronRight,
	ErrorRounded,
	WarningRounded,
} from '@mui/icons-material';
import { Collapse, styled, Tooltip, Typography } from '@mui/material';
import Color from 'color';

import AddAnother from './AddAnother';
import AddField from './AddField';
import {
	ARRAY,
	BOOLEAN,
	DATE,
	NULL,
	NUMBER,
	OBJECT,
	STRING,
	UNDEFINED,
} from '../../../../../utils/commonDataTypes';
import DataSetBooleanPicker from './DataSetBooleanPicker';
import DataSetFieldEditor from './DataSetFieldEditor';
import DataSetDatePicker from './DataSetDatePicker';
import DataSetType from './DataSetType';
import DeleteItem from './DeleteItem';
import determineType from '../../../utils/determineType';
import elementGenerator from '../../../utils/elementGenerator';
import { useDataSetContext } from '../../../hocs/withDataSetContext';
import { useDataSetEditorContext } from '../../../contexts/DataSetEditorContext';
import { useTimeFormatter } from '../../../../../hooks/useTimeFormat';

const CanAddIndicator = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.indicators.success.main,
	borderRadius: 3,
	height: 6,
	opacity: 1,
	pointerEvents: 'none',
	position: 'absolute',
	transform: 'translateX(4px)',
	width: 6,

	transition: theme.transitions.create(['opacity', 'transform'], {
		duration: 100,
	}),
}));

const Chevron = styled(ChevronRight)(({ expanded, theme }) => ({
	transform: `rotate(${expanded ? 90 : 0}deg)`,

	transition: theme.transitions.create(['transform'], {
		duration: 250,
	}),
}));

const ChevronContainer = styled('div')(() => ({
	alignItems: 'center',
	display: 'flex',
	justifyContent: 'flex-start',
	width: 24,
}));

const CollapseContent = styled('div')(({ theme }) => ({
	borderLeft: `1px solid ${Color(theme.palette.text.solid)
		.fade(0.85)
		.toString()}`,
	boxSizing: 'border-box',
	marginLeft: 15,
}));

const Container = styled('div')(({ theme }) => ({
	alignItems: 'center',
	display: 'flex',
	width: '100%',

	'& .action_container': {
		opacity: 0,
		pointerEvents: 'none',
		transform: 'translateX(-3px)',

		transition: theme.transitions.create(['opacity', 'transform'], {
			duration: 100,
		}),
	},

	'&:hover .action_container, &:focus-within .action_container': {
		opacity: 1,
		pointerEvents: 'all',
		transform: 'translateX(0px)',
	},

	'&:hover .canAddIndicator': {
		opacity: 0,
		transform: 'translateX(8px)',
	},
}));

const InnerContent = styled('div')(({ error, theme }) => ({
	alignItems: 'center',
	backgroundColor: error ? theme.palette.solidErrorHighlight : 'transparent',
	borderRadius: 4,
	display: 'flex',
	height: '100%',
	marginLeft: -5,
	padding: '0 5px',
}));

const commonIconStyles = {
	fontSize: 18,
	marginRight: 5,
};

const ErrorIcon = styled(ErrorRounded)(({ theme }) => ({
	...commonIconStyles,
	color: theme.palette.indicators.error.main,
}));

const WarningIcon = styled(WarningRounded)(({ theme }) => ({
	...commonIconStyles,
	color: theme.palette.indicators.warning.main,
}));

const elementStyle = () => ({
	alignItems: 'center',
	background: 'none',
	border: 'none',
	display: 'flex',
	height: 26,
	overflow: 'hidden',
	padding: '0 0 0 5px',
	position: 'relative',
});

const ElementButton = styled('button')(elementStyle);
const ElementContainer = styled('div')(elementStyle);

const Element = ({
	absentFields,
	children,
	dataType,
	disabled,
	expanded,
	isNotConformant,
	onAddField,
	onDelete,
	showChevron,
	wasCorrected,
	...other
}) => {
	const { editMode } = useDataSetEditorContext();

	const editable = editMode && !disabled;
	const canDelete = editable && onDelete;
	const canAdd = editable && onAddField && (absentFields || []).length > 0;

	const Content = useMemo(
		() => (showChevron ? ElementButton : ElementContainer),
		[showChevron]
	);

	return (
		<Container>
			<Content {...other}>
				<ChevronContainer>
					{showChevron && <Chevron expanded={+expanded} />}
				</ChevronContainer>
				<InnerContent error={+isNotConformant}>
					{dataType && (
						<DataSetType
							style={{ marginRight: 5 }}
							variant={dataType}
						/>
					)}
					{wasCorrected && (
						<Tooltip
							disableInteractive
							title={
								<Typography textAlign="center" variant="body2">
									{'The data stored in the database is invalid and ' +
										'had to be temporarily corrected for display. ' +
										`${
											editMode
												? 'Saving'
												: 'Editing and saving'
										} the document will automatically replace the ` +
										'stored value with the value displayed here.'}
								</Typography>
							}
						>
							<WarningIcon />
						</Tooltip>
					)}
					{isNotConformant && (
						<Tooltip
							disableInteractive
							title={
								<Typography textAlign="center" variant="body2">
									This field does not conform to the schema
									defined in the data set configuration. You
									cannot save the document with this error
									present.
								</Typography>
							}
						>
							<ErrorIcon />
						</Tooltip>
					)}
					{children}
				</InnerContent>
			</Content>
			{canAdd && (
				<div
					style={{
						alignItems: 'center',
						display: 'flex',
						position: 'relative',
					}}
				>
					<CanAddIndicator className="canAddIndicator" />
					<div className="action_container">
						<AddField
							fields={absentFields}
							onAddField={onAddField}
						/>
					</div>
				</div>
			)}
			{canDelete && (
				<div className="action_container">
					<DeleteItem onDelete={onDelete} />
				</div>
			)}
		</Container>
	);
};

const CollapsableContainer = ({
	absentFields,
	children,
	dataType,
	disabled,
	empty,
	onAddAnother,
	onAddField,
	onDelete,
	schema,
	subTitle,
	title,
}) => {
	const { editMode } = useDataSetEditorContext();

	const [expanded, setExpanded] = useState(true);

	const canAddAnother = dataType === ARRAY && editMode && onAddAnother;

	const childCount = Children.count(children);
	const itemsCount =
		dataType === ARRAY
			? (() => {
					if (!childCount) return ' (empty)';

					return ` (${childCount} item${
						childCount === 1 ? '' : 's'
					})`;
			  })()
			: '';

	return (
		<>
			<Element
				absentFields={absentFields}
				dataType={dataType}
				expanded={expanded}
				isNotConformant={!schema}
				onAddField={onAddField}
				onClick={() => setExpanded(prev => !prev)}
				onDelete={onDelete}
				showChevron
			>
				<Typography style={{ display: 'inline', fontWeight: 'bold' }}>
					{title + itemsCount}
				</Typography>
				{subTitle && (
					<Typography
						style={{
							display: 'inline',
							marginLeft: '0.66ch',
							opacity: 0.7,
						}}
						variant="subtitle2"
					>
						{subTitle}
					</Typography>
				)}
			</Element>
			<Collapse in={expanded} timeout={100}>
				<CollapseContent>{children}</CollapseContent>
				{canAddAnother && (
					<AddAnother
						disabled={disabled}
						empty={empty}
						onAdd={onAddAnother}
						title={title}
					/>
				)}
			</Collapse>
		</>
	);
};

const ArrayParser = ({ data, disabled, onDelete, onUpdate, schema, title }) => {
	const { seedUuid } = useDataSetEditorContext();

	const handleAddAnother = useCallback(
		() => onUpdate([...data, ...elementGenerator(schema)], true),
		[data, onUpdate, schema]
	);

	const handleDelete = useCallback(
		index => {
			const former = data.slice(0, index);
			const latter = data.slice(index + 1, data.length);

			onUpdate([...former, ...latter], true);
		},
		[data, onUpdate]
	);

	return (
		<CollapsableContainer
			dataType={ARRAY}
			disabled={disabled}
			empty={!data?.length}
			onAddAnother={schema ? handleAddAnother : null}
			onDelete={onDelete}
			schema={schema}
			title={title}
		>
			{(data || []).map((dataPartial, index) => (
				<DataSetNode
					key={`${seedUuid}-${title}-${index}`}
					data={dataPartial}
					disabled={disabled}
					onDelete={() => handleDelete(index)}
					onUpdate={(newNodeData, hardUpdate) =>
						onUpdate(
							(() => {
								data[index] = newNodeData;

								return data;
							})(),
							hardUpdate
						)
					}
					schema={schema?.arrayContent}
					subTitle={title}
					title={`${index}`}
				/>
			))}
		</CollapsableContainer>
	);
};

const ObjectParser = ({
	data,
	disabled,
	onDelete,
	onUpdate,
	schema,
	subTitle,
	title,
}) => {
	const { updateData } = useDataSetEditorContext();

	const absentFields = useMemo(
		() => {
			const schemaKeys = Object.keys(schema?.objectContent ?? {});
			const dataKeys = Object.keys(data ?? {});

			return schemaKeys.filter(
				schemaKey => !dataKeys.includes(schemaKey)
			);
		},
		[data, schema]
	);

	const updateFunc = useMemo(() => (onUpdate ? onUpdate : updateData), [
		onUpdate,
		updateData,
	]);

	const handleAddField = useCallback(
		key => {
			updateFunc(
				{ ...data, [key]: elementGenerator(schema.objectContent[key]) },
				true
			);
		},
		[data, updateFunc, schema]
	);

	const handleDelete = useCallback(
		key => {
			const newObject = { ...data };
			delete newObject[key];

			updateFunc(newObject, true);
		},
		[data, updateFunc]
	);

	return (
		<CollapsableContainer
			absentFields={absentFields}
			dataType={OBJECT}
			disabled={disabled}
			onAddField={handleAddField}
			onDelete={onDelete}
			schema={schema}
			subTitle={subTitle}
			title={title ?? 'Root'}
		>
			{data &&
				Object.keys(data).map(key => (
					<DataSetNode
						key={key}
						data={data[key]}
						disabled={disabled}
						onDelete={
							schema?.objectContent[key]?.required?.value
								? undefined
								: () => handleDelete(key)
						}
						onUpdate={(newNodeData, hardUpdate) => {
							data[key] = newNodeData;

							updateFunc(data, hardUpdate);
						}}
						schema={schema?.objectContent[key]}
						title={key}
					/>
				))}
		</CollapsableContainer>
	);
};

const EditControl = forwardRef(
	({ data, schema, setFocused, type, ...props }, ref) => {
		switch (type) {
			case BOOLEAN:
				return <DataSetBooleanPicker ref={ref} {...props} />;

			case DATE:
				return <DataSetDatePicker ref={ref} {...props} />;

			case NULL:
				return <Typography>null</Typography>;

			case NUMBER:
				return (
					<DataSetFieldEditor
						inputProps={{ type: 'number' }}
						schema={schema}
						setFocused={setFocused}
						ref={ref}
						{...props}
					/>
				);

			case STRING:
				return (
					<DataSetFieldEditor
						schema={schema}
						setFocused={setFocused}
						ref={ref}
						{...props}
					/>
				);

			case UNDEFINED:
				return <Typography>undefined</Typography>;

			default:
				return (
					<DataSetFieldEditor
						schema={schema}
						setFocused={setFocused}
						ref={ref}
						{...props}
					/>
				);
		}
	}
);

const castingFunctions = {
	[BOOLEAN]: value => {
		if (
			typeof value === 'string' &&
			value.toLowerCase().trim() === 'false'
		) {
			return false;
		}

		return Boolean(value).valueOf();
	},
	[DATE]: value => new Date(value).toISOString(),
	[NUMBER]: value => {
		const normalisedValue =
			typeof value === STRING ? value.match(/^-?[0-9]*\.?[0-9]*/) : value;

		const conversion = Number(normalisedValue).valueOf();

		return isNaN(conversion) ? 0 : conversion;
	},
	[STRING]: value => String(value).valueOf(),
};

const PrimitiveParser = ({
	data,
	disabled,
	onDelete,
	onUpdate,
	schema,
	title,
	type,
}) => {
	const { editData } = useDataSetContext();
	const { editMode, enterEditMode, updated } = useDataSetEditorContext();

	const castedData = useMemo(() => castingFunctions[type]?.(data) ?? data, [
		data,
		type,
	]);

	const formatDate = useTimeFormatter();

	const compRef = useRef();
	const isInitialFocus = useRef(false);

	const [corrected, setCorrected] = useState(false);
	const [focused, setFocused] = useState(false);

	const boolParser = useCallback(b => (b ? 'true' : 'false'), []);

	const dateParser = useCallback(d => formatDate(d, true, true), [
		formatDate,
	]);

	const getFormattedValue = useCallback(
		() => {
			switch (type) {
				case BOOLEAN:
					return boolParser(castedData);

				case DATE:
					return dateParser(castedData);

				case NULL:
					return 'null';

				case UNDEFINED:
					return 'undefined';

				default:
					return castedData;
			}
		},
		[boolParser, castedData, dateParser, type]
	);

	useEffect(
		() => {
			if (isInitialFocus.current) {
				compRef?.current?.focus?.();

				isInitialFocus.current = false;
			}
		},
		[editMode]
	);

	useEffect(
		() => {
			if (data !== castedData) {
				onUpdate(castedData, true);

				setCorrected(true);
			}
		},
		[castedData, data, editMode, onUpdate]
	);

	const Value = useMemo(
		() => ({ ...props }) => (
			<Typography
				{...props}
				style={{
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					opacity: type === NULL || type === UNDEFINED ? 0.8 : 1,
				}}
			>
				{getFormattedValue(castedData)}
			</Typography>
		),
		[castedData, getFormattedValue, type]
	);

	const EditControlSwitcher = useMemo(
		() => () => {
			return (
				<Value
					onClick={event => {
						if (
							!disabled &&
							event.detail === 2 &&
							!editData.isEditing
						) {
							isInitialFocus.current = true;

							enterEditMode();
						}
					}}
				/>
			);
		},
		[disabled, editData, enterEditMode]
	);

	return (
		<Element
			dataType={type}
			disabled={disabled}
			isNotConformant={!schema}
			onDelete={focused ? null : onDelete}
			wasCorrected={corrected && !updated}
		>
			<Typography style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
				{title}
			</Typography>
			<Typography style={{ marginRight: '0.66ch' }}>:</Typography>
			{editMode && (
				<EditControl
					ref={compRef}
					data={castedData}
					defaultValue={castedData}
					disabled={disabled}
					onChange={(event, newValue) => onUpdate(newValue, false)}
					schema={schema}
					setFocused={setFocused}
					type={type}
				/>
			)}
			{!editMode && <EditControlSwitcher />}
		</Element>
	);
};

export const DataSetNode = ({
	data,
	disabled,
	onDelete,
	onUpdate,
	schema,
	subTitle,
	title,
}) => {
	const type = useMemo(() => determineType(data, schema), [data, schema]);

	const Component = useMemo(
		() => {
			const coreProps = {
				data,
				disabled,
				onDelete,
				onUpdate,
				schema,
				subTitle,
				type,
				title,
			};

			if (type === NULL) {
				return <PrimitiveParser {...coreProps} />;
			}

			switch (type) {
				case ARRAY:
					return <ArrayParser {...coreProps} />;

				case OBJECT:
					return <ObjectParser {...coreProps} />;

				default:
					return <PrimitiveParser {...coreProps} />;
			}
		},
		[data, disabled, onDelete, onUpdate, schema, subTitle, type, title]
	);

	return Component;
};

const dataPropType = PropTypes.oneOfType([
	PropTypes.array,
	PropTypes.bool,
	PropTypes.number,
	PropTypes.object,
	PropTypes.string,
]);

const dataTypesPropType = PropTypes.oneOf([
	ARRAY,
	BOOLEAN,
	DATE,
	NULL,
	NUMBER,
	OBJECT,
	STRING,
	UNDEFINED,
]);

Element.propTypes = {
	absentFields: PropTypes.arrayOf(PropTypes.string),
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	dataType: dataTypesPropType.isRequired,
	disabled: PropTypes.bool,
	expanded: PropTypes.bool,
	isNotConformant: PropTypes.bool,
	onAddField: PropTypes.func,
	onDelete: PropTypes.func,
	showChevron: PropTypes.bool,
	wasCorrected: PropTypes.bool,
};

CollapsableContainer.propTypes = {
	absentFields: PropTypes.arrayOf(PropTypes.string),
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	dataType: dataTypesPropType.isRequired,
	disabled: PropTypes.bool,
	empty: PropTypes.bool,
	onAddAnother: PropTypes.func,
	onAddField: PropTypes.func,
	onDelete: PropTypes.func,
	schema: PropTypes.object,
	subTitle: PropTypes.string,
	title: PropTypes.string.isRequired,
};

ArrayParser.propTypes = {
	data: dataPropType,
	disabled: PropTypes.bool,
	onDelete: PropTypes.func,
	onUpdate: PropTypes.func.isRequired,
	schema: PropTypes.object,
	title: PropTypes.string.isRequired,
};

ObjectParser.propTypes = {
	data: dataPropType,
	disabled: PropTypes.bool,
	onDelete: PropTypes.func,
	onUpdate: PropTypes.func,
	schema: PropTypes.object,
	subTitle: PropTypes.string,
	title: PropTypes.string,
};

EditControl.propTypes = {
	data: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.number,
		PropTypes.string,
	]),
	schema: PropTypes.object,
	setFocused: PropTypes.func,
	type: PropTypes.string,
};

PrimitiveParser.propTypes = {
	data: dataPropType,
	disabled: PropTypes.bool,
	onDelete: PropTypes.func,
	onUpdate: PropTypes.func.isRequired,
	schema: PropTypes.object,
	title: PropTypes.string.isRequired,
};

DataSetNode.propTypes = {
	data: dataPropType,
	disabled: PropTypes.bool,
	onDelete: PropTypes.func,
	onUpdate: PropTypes.func,
	schema: PropTypes.object,
	subTitle: PropTypes.string,
	title: PropTypes.string,
};

DataSetNode.displayName = 'DataSetNode';

export default DataSetNode;
