import BtDialog from '../../../BtDialog';
import TileConfigDialogContent from './TileConfigDialogContent';
import PropTypes from 'prop-types';
// import { useEffect, useState } from 'react';
// import _ from 'lodash';
import { TileConfigContextProvider } from './TileConfigDialogContext';
import { DialogTitle } from '@mui/material';
import { tileLabels } from '../../tileConstants';
import { useContext } from 'react';
import { TileContext } from '../TileContext';

export const TileConfigDialog = ({ onClose }) => {
	// console.log({ nativeMode, tileData });
	const { tileData, nativeEditMode, screenData } = useContext(TileContext);

	return (
		<>
			<TileConfigContextProvider
				tileData={tileData}
				screenId={screenData.uuid}
				nativeMode={nativeEditMode}
			>
				<TileConfigDialogContent onClose={onClose} />
			</TileConfigContextProvider>
		</>
	);
};

TileConfigDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
};
