import { Box, Paper, Popover, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import PropTypes from 'prop-types';
import { memo, useCallback, useState } from 'react';
import { colourSwatchGeometryStyle } from '../../../../../../components/palette/utils/constants';
import { ColourTags } from './ColourTags';
import {
	ACTIVE,
	ARCHIVED,
	INSIGHTS,
	PLATFORM,
} from '../../../../../../components/paletteManager/utils/constants';

// outermost wrapper of the palette UI
const PaletteContainer = styled(Paper)(({ theme }) => ({
	padding: '10px 20px',
	height: 'fit-content',
	maxWidth: '45%',
	[theme.breakpoints.down('md')]: {
		maxWidth: '100%',
	},
}));

// paper rendered in the colour swatch popover
const ColourPaper = styled(Paper)(({ colour }) => ({
	width: '180px',
	height: '120px',
	backgroundColor: colour,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: '5px',
	flexDirection: 'column',
}));

// text rendered in the colour swatch popover
const ColourText = styled(Typography)(({ theme, colour }) => ({
	color: theme.palette.getContrastText(colour),
	fontWeight: 500,
}));

// container for each colour row
const ColourRowContainer = styled(Box)(() => ({
	display: 'flex',
	marginBottom: '5px',
	gap: '10px',
	alignItems: 'flex-start',
}));

const ColourSwatch = styled(Box)(({ colour }) => ({
	height: '30px',
	width: '60px',
	borderRadius: colourSwatchGeometryStyle.borderRadius,
	backgroundColor: colour,
	flexShrink: 0,
}));

// container for multiple typography elements
const MultiTextContainer = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'flex-end',
}));

const TextRow = memo(function TextRow({ bg, label, name }) {
	return (
		<MultiTextContainer>
			<ColourText colour={bg}>{label}</ColourText>
			<ColourText colour={bg}>
				<em> &nbsp;- {name}</em>
			</ColourText>
		</MultiTextContainer>
	);
});

const PopSwatch = memo(function PopSwatch({ colour }) {
	return (
		<ColourPaper colour={colour.hex}>
			<TextRow bg={colour.hex} name={colour.hex} label="Colour" />
			<TextRow bg={colour.hex} name={colour.name} label="Name" />
		</ColourPaper>
	);
});

const ColourRow = memo(function ColourRow({ colourObj, onChange }) {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleOpenColour = (event, colour, name) => {
		setAnchorEl({
			el: event.currentTarget,
			colour: { name: name, hex: colour },
		});
	};
	const handleCloseColour = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<ColourRowContainer>
			{/* <Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: '5px',
					flexShrink: 0,
				}}
			> */}
			<ColourSwatch
				colour={colourObj.hex}
				onMouseEnter={e =>
					handleOpenColour(e, colourObj.hex, colourObj.name)
				}
				onMouseLeave={handleCloseColour}
			/>
			{/* <Typography
					sx={{
						flexShrink: 0,
					}}
				>
					{colourObj.name}
				</Typography> */}
			{/* </Box> */}
			{/* <ChipDisplay
				tags={colourObj.tags}
				onChange={onChange}
				uuid={colourObj.uuid}
			/> */}
			<ColourTags
				tags={colourObj.tags}
				onChange={onChange}
				uuid={colourObj.uuid}
			/>
			{anchorEl?.colour && (
				<Popover
					id="mouse-over-popover"
					sx={{
						pointerEvents: 'none',
					}}
					open={open}
					anchorEl={anchorEl?.el}
					anchorOrigin={{
						vertical: 'center',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'center',
						horizontal: 'left',
					}}
					onClose={handleCloseColour}
					disableRestoreFocus
				>
					<PopSwatch colour={anchorEl.colour} />
				</Popover>
			)}
		</ColourRowContainer>
	);
});

const PaletteDisplay = memo(function PaletteDisplay({ paletteObj, onChange }) {
	const handleChange = useCallback(
		payload => {
			onChange({ update: payload, paletteUuid: paletteObj.uuid });
		},
		[onChange, paletteObj]
	);

	return (
		<PaletteContainer>
			<MultiTextContainer>
				<Typography variant="h6">Palette</Typography>
				<Typography variant="h6" sx={{ fontStyle: 'italic' }}>
					&nbsp;- {paletteObj.name}
				</Typography>
			</MultiTextContainer>

			{paletteObj.palette.map(c => (
				<ColourRow onChange={handleChange} key={c.uuid} colourObj={c} />
			))}
		</PaletteContainer>
	);
});

const colourObject = PropTypes.shape({
	hex: PropTypes.string.isRequired,
	uuid: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	tags: PropTypes.arrayOf(PropTypes.string).isRequired,
});

const popSwatchColourObject = PropTypes.shape({
	hex: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
});

const paletteObject = PropTypes.shape({
	uuid: PropTypes.string.isRequired,
	type: PropTypes.oneOf([INSIGHTS, PLATFORM]).isRequired,
	status: PropTypes.oneOf([ACTIVE, ARCHIVED]).isRequired,
	name: PropTypes.string.isRequired,
	palette: PropTypes.arrayOf(colourObject).isRequired,
});

PaletteDisplay.propTypes = {
	paletteObj: paletteObject,
	onChange: PropTypes.func.isRequired,
};

ColourRow.propTypes = {
	colourObj: colourObject,
	onChange: PropTypes.func.isRequired,
};

PopSwatch.propTypes = { colour: popSwatchColourObject };

TextRow.propTypes = {
	bg: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
};

PaletteDisplay.displayName = 'PaletteDisplay';

export { PaletteDisplay };
