import {
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	useTheme,
} from '@mui/material';
import { styled } from '@mui/styles';
import PropTypes from 'prop-types';

const IconRadioDefault = styled('div')(({ theme }) => ({
	borderRadius: 8,
	width: 80,
	height: 60,
	borderColor: theme.palette.primary.light,
	borderWidth: 1,
	borderStyle: 'solid',
	backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : '#f5f8fa',
	'input:hover ~ &': {
		backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
	},
	'input:disabled ~ &': {
		boxShadow: 'none',
		background:
			theme.palette.mode === 'dark'
				? 'rgba(57,75,89,.5)'
				: 'rgba(206,217,224,.5)',
		borderColor: theme.palette.divider,
	},
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	color: theme.palette.text.icon,
}));

const IconRadioChecked = styled(IconRadioDefault)(({ theme }) => ({
	borderColor: theme.palette.primary.main,
	borderWidth: 2,
	borderStyle: 'solid',
	'input:hover ~ &': {
		borderColor: theme.palette.primary.main,
	},
}));

function IconRadio(props) {
	const theme = useTheme();
	return (
		<Radio
			sx={{
				'&:hover': {
					bgcolor: 'transparent',
				},
			}}
			disableRipple
			color="default"
			checkedIcon={
				<IconRadioChecked theme={theme}>
					{props.children}
				</IconRadioChecked>
			}
			icon={
				<IconRadioDefault theme={theme}>
					{props.children}
				</IconRadioDefault>
			}
			{...props}
		/>
	);
}

export default function BtIconRadio({
	value,
	onChange,
	items,
	name,
	label,
	labelPlacement,
}) {
	// console.log({ value });

	return (
		<>
			<FormLabel>{label && label}</FormLabel>
			<div
				style={{
					width: '100%',
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					flexDirection: 'column',
				}}
			>
				<RadioGroup
					name={name}
					value={value}
					onChange={onChange}
					row={true}
				>
					{items.map(item => (
						<FormControlLabel
							key={item.value}
							disabled={item.disabled}
							sx={{ width: 130 }}
							labelPlacement={labelPlacement}
							value={item.value}
							control={
								<IconRadio value={item.value}>
									{item.icon}
								</IconRadio>
							}
							label={item.label}
						/>
					))}
				</RadioGroup>
			</div>
		</>
	);
}

BtIconRadio.defaultProps = {
	labelPlacement: 'bottom',
};

BtIconRadio.propTypes = {
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
				.isRequired,
			label: PropTypes.string.isRequired,
			disabled: PropTypes.bool,
		})
	).isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	labelPlacement: PropTypes.oneOf(['bottom', 'top', 'end', 'start']),
};

IconRadio.propTypes = {
	props: PropTypes.object,
};
