import React, {
	createContext,
	useContext,
	memo,
	useState,
	useRef,
	Children,
	isValidElement,
	useMemo,
	useEffect,
} from 'react';
import PropTypes from 'prop-types';

import { WizardContext } from './withWizard';

export const WizardInternalContext = createContext(null);

const Wizard = memo(({ children, Footer, Header, startIndex, Wrapper }) => {
	const { setValues } = useContext(WizardContext) || {};
	const hocFound = useMemo(() => !!setValues, [setValues]);

	const [activeStep, setActiveStep] = useState(startIndex);
	const [isLoading, setIsLoading] = useState(false);
	const hasNextStep = useRef(true);
	const hasPreviousStep = useRef(false);
	const nextStepHandler = useRef(() => {});
	const stepCount = Children.toArray(children).length;

	hasNextStep.current = activeStep < stepCount - 1;
	hasPreviousStep.current = activeStep > 0;

	const goToNextStep = useRef(() => {
		if (hasNextStep.current) {
			setActiveStep(activeStep => activeStep + 1);
		}
	});

	const goToPreviousStep = useRef(() => {
		if (hasPreviousStep.current) {
			nextStepHandler.current = null;
			setActiveStep(activeStep => activeStep - 1);
		}
	});

	const goToStep = useRef(stepIndex => {
		if (stepIndex >= 0 && stepIndex < stepCount) {
			nextStepHandler.current = null;
			setActiveStep(stepIndex);
		} else {
			if (process.env.NODE_ENV === 'development') {
				console.error(
					`Invalid step index [${stepIndex}] passed to 'goToStep'. `
				);
			}
		}
	});

	const handleStep = useRef(handler => {
		nextStepHandler.current = handler;
	});

	const doNextStep = useRef(async () => {
		if (hasNextStep.current && nextStepHandler.current) {
			try {
				setIsLoading(true);
				await nextStepHandler.current();
				setIsLoading(false);
				nextStepHandler.current = null;
				goToNextStep.current();
			} catch (error) {
				setIsLoading(false);
				throw error;
			}
		} else {
			goToNextStep.current();
		}
	});

	const values = useMemo(
		() => ({
			nextStep: doNextStep.current,
			previousStep: goToPreviousStep.current,
			handleStep: handleStep.current,
			isLoading,
			activeStep,
			stepCount,
			isFirstStep: !hasPreviousStep.current,
			isLastStep: !hasNextStep.current,
			goToStep: goToStep.current,
		}),
		[activeStep, stepCount, isLoading]
	);

	useEffect(
		() => {
			if (hocFound) {
				setValues(values);
			}
		},
		[setValues, values, hocFound]
	);

	const activeStepContent = useMemo(
		() => {
			const reactChildren = Children.toArray(children);

			if (process.env.NODE_ENV === 'development') {
				// No steps passed
				if (reactChildren.length === 0) {
					console.error(
						'Make sure to pass your steps as children in your <Wizard>'
					);
				}
				// The passed start index is invalid
				if (activeStep > reactChildren.length) {
					console.error(
						'An invalid startIndex is passed to <Wizard>'
					);
				}
				// Invalid header element
				if (Header && !isValidElement(Header)) {
					console.error('Invalid header passed to <Wizard>');
				}
				// Invalid footer element
				if (Footer && !isValidElement(Footer)) {
					console.error('Invalid footer passed to <Wizard>');
				}
			}

			return reactChildren[activeStep];
		},
		[activeStep, children, Header, Footer]
	);

	const enhancedActiveStepContent = React.useMemo(
		() =>
			Wrapper
				? React.cloneElement(Wrapper, {
						children: activeStepContent,
				  })
				: activeStepContent,
		[Wrapper, activeStepContent]
	);

	if (hocFound) {
		return (
			<>
				{Header}
				{enhancedActiveStepContent}
				{Footer}
			</>
		);
	}

	return (
		<WizardInternalContext.Provider value={values}>
			{Header}
			{enhancedActiveStepContent}
			{Footer}
		</WizardInternalContext.Provider>
	);
});

Wizard.defaultProps = {
	startIndex: 0,
};

Wizard.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	Footer: PropTypes.node,
	Header: PropTypes.node,
	startIndex: PropTypes.number,
	Wrapper: PropTypes.node,
};

Wizard.displayName = 'Wizard';

export default Wizard;
