import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useAppContext } from '../../../../../context/ContextManager';

export function TilesStepper({ steps, activeStep }) {
	const { deviceType } = useAppContext();

	return (
		<Box
			sx={{
				width: '100%',
				padding: deviceType === 'desktop' ? 2 : 0,
				mt: deviceType === 'desktop' ? 1 : 3,
				height: '100%',
				paddingBottom: 0,
			}}
		>
			<Stepper
				activeStep={activeStep}
				// alternativeLabel
			>
				{steps.map((step, index) => {
					const stepProps = {};
					const labelProps = {};
					return (
						<Step key={step.label} {...stepProps}>
							<StepLabel {...labelProps}>{step.label}</StepLabel>
						</Step>
					);
				})}
			</Stepper>

			<div
				style={{
					flexDirection: 'column',
					display: 'flex',
					width: '100%',
					alignItems: 'center',
					marginTop: deviceType === 'desktop' ? 10 : 30,
					// height: deviceType === 'desktop' ? 230 : 'auto',
					// height: 250,
					justifyContent: 'flex-end',
				}}
			>
				{steps[activeStep].component}
			</div>
		</Box>
	);
}

TilesStepper.propTypes = {
	steps: PropTypes.array.isRequired,
};
