import React from 'react';
import PropTypes from 'prop-types';

import ReactJson from 'react-json-view';

import { useThemeContext } from '../../../../../context/ThemeContext';

export default function DataSetJsonView({ data, style, ...other }) {
	const { themeDark } = useThemeContext();

	return (
		<ReactJson
			src={data}
			style={{
				backgroundColor: 'transparent',
				fontSize: 14,
				...style,
			}}
			theme={themeDark ? 'chalk' : 'rjv-default'}
			{...other}
		/>
	);
}

DataSetJsonView.propTypes = {
	data: PropTypes.object.isRequired,
	style: PropTypes.object,
};
