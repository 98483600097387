import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
	Button,
	FormControl,
	FormControlLabel,
	MenuItem,
	Select,
	styled,
	Switch,
	TextField,
} from '@mui/material';
import { Check } from 'mdi-material-ui';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
	BtPopperExtendedActions,
	BtPopperExtendedContent,
} from '../../../BtPopperContent';
import { buildTimeRangeObject, decidePlural } from './utils/timeRangeFunctions';
import { timeUnits, timeTenses } from './utils/timeRangeConstants';
import { useAppContext } from '../../../../../context/ContextManager';

const StyledFormControl = styled(FormControl)(({ width_override }) => ({
	marginRight: 10,
	width: width_override ? width_override : 80,
}));

// yup schema for the duration input
const schema = yup.object({
	duration: yup
		.number('must be a number')
		.typeError('must be a number')
		.integer('whole number required')
		.positive('must be positive')
		.required('required'),
});

export default function QuickSelect({
	onClose,
	previewRange,
	setPopperClickAway,
	setPreviewRange,
	setTimeRange,
}) {
	const [values, setValues] = useState({
		duration: previewRange.interval.duration
			? previewRange.interval.duration
			: 1,
		realTime: previewRange.realTime ? previewRange.realTime : false,
		tense: previewRange.interval.tense
			? previewRange.interval.tense
			: 'last',
		unit: previewRange.interval.unit ? previewRange.interval.unit : 'days',
		whole: previewRange.interval.whole
			? previewRange.interval.whole
			: false,
	});

	const { appOrg } = useAppContext();

	const handlePopperClickAway = () => {
		setPopperClickAway(true);
	};

	// Input component for user to select the time tense
	const TenseSelect = () => {
		const handleChange = event => {
			setValues({
				...values,
				tense: event.target.value,
				whole: event.target.value === 'this' ? true : values.whole, // TODO - is this right
			});
			setPopperClickAway(true);
		};

		return (
			<StyledFormControl variant="standard">
				<Select
					disabled={values.realTime}
					label="Time tense"
					labelId="time-tense-select"
					MenuProps={{
						TransitionProps: {
							onExited: () => handlePopperClickAway(),
						},
					}}
					onChange={handleChange}
					onOpen={() => setPopperClickAway(false)}
					value={values.tense}
				>
					{timeTenses.map((item, index) => (
						<MenuItem key={item.id} value={item.id}>
							{item.label}
						</MenuItem>
					))}
				</Select>
			</StyledFormControl>
		);
	};

	// Input component for user to select the time unit
	const UnitSelect = () => {
		const handleChange = event => {
			setValues({
				...values,
				unit: event.target.value,
			});
			setPopperClickAway(true);
		};

		return (
			<StyledFormControl width_override={90} variant="standard">
				<Select
					disabled={false}
					label="Time unit"
					labelId="time-unit-select"
					MenuProps={{
						TransitionProps: {
							onExited: () => handlePopperClickAway(),
						},
					}}
					onChange={handleChange}
					onOpen={() => setPopperClickAway(false)}
					value={values.unit}
				>
					{timeUnits.map((item, index) => (
						<MenuItem key={item.id} value={item.labelPlural}>
							{item.labelPlural}
						</MenuItem>
					))}
				</Select>
			</StyledFormControl>
		);
	};

	// Input component for user to specify if time unit should be rounded to nearest whole unit
	// If the tense is set to 'this', this component is set to true and disabled
	const WholeSelect = () => {
		const handleChange = () => {
			setValues({
				...values,
				whole: !values.whole,
			});
		};

		return (
			<div>
				<FormControlLabel
					control={
						<Switch
							checked={values.whole}
							disabled={
								values.tense === 'this' ||
								values.realTime === true
							}
							inputProps={{ 'aria-label': 'controlled' }}
							onChange={handleChange}
						/>
					}
					label={`Round to ${watch('duration')} whole ${decidePlural({
						duration: values.duration,
						timeUnit: values.unit,
					})}?`}
					labelPlacement="end"
				/>
			</div>
		);
	};

	// Input component for user to specify if time
	const RealTime = () => {
		const handleChange = () => {
			setValues({
				...values,
				realTime: !values.realTime,
				tense: !values.realTime ? 'last' : values.tense,
				whole: !values.realTime ? false : values.whole,
			});
		};

		return (
			<div>
				<FormControlLabel
					control={
						<Switch
							checked={values.realTime}
							inputProps={{ 'aria-label': 'controlled' }}
							onChange={handleChange}
						/>
					}
					label="Enable live data tracking?"
					labelPlacement="end"
				/>
			</div>
		);
	};

	// React Hook Form
	const {
		control,
		formState: { errors },
		getValues,
		handleSubmit,
		watch,
	} = useForm({
		defaultValues: { duration: values.duration },
		mode: 'onChange',
		resolver: yupResolver(schema),
	});

	// Input component for user to select the duration of the time range
	const ValidatedDurationSelect = () => {
		return (
			<StyledFormControl width_override={100}>
				<Controller
					control={control}
					name="duration"
					render={({
						field: { ref, ...field },
						fieldState: { error },
					}) => (
						<TextField
							{...field}
							key={1}
							id={field.name}
							error={!!errors.duration}
							helperText={error?.message}
							type="number"
							variant="standard"
							inputProps={{
								min: 1,
							}}
						/>
					)}
				/>
			</StyledFormControl>
		);
	};

	// The input data is passed into the buildTimeRangeObject function and
	// if a value is returned it is passed into the dataProvider context store.
	const onSubmit = async function() {
		const newTimeRange = await buildTimeRangeObject({
			...values,
			duration: getValues('duration'),
			firstWeekDay: appOrg.regionalSettings.firstWeekDay,
		});
		if (newTimeRange) {
			setTimeRange(newTimeRange);
		}
		onClose();
	};

	// The validated duration value is watched and when changed
	// is added to the values state object
	useEffect(
		() => {
			const subscription = watch(data => {
				setValues({ ...values, duration: data.duration });
			});
			return () => {
				subscription.unsubscribe();
			};
		},
		[values, watch]
	);

	// As changes are made to the form values a preview of the
	// time range is generated
	useEffect(
		() => {
			const buildPreview = async function() {
				const newPreviewRange = await buildTimeRangeObject({
					...values,
					firstWeekDay: appOrg.regionalSettings.firstWeekDay,
				});
				setPreviewRange({
					...newPreviewRange,
				});
			};
			buildPreview();
		},
		[appOrg.regionalSettings.firstWeekDay, setPreviewRange, values]
	);

	return (
		<>
			<BtPopperExtendedContent
				onSubmit={handleSubmit(onSubmit)}
				style={{ padding: '0 12px 8px 26px' }}
			>
				<div
					style={{
						display: 'flex',
						padding: '18px 0',
					}}
				>
					<TenseSelect />
					<ValidatedDurationSelect />
					<UnitSelect />
				</div>
				<WholeSelect />
			</BtPopperExtendedContent>
			<BtPopperExtendedActions style={{ padding: '0 12px 12px' }}>
				<div
					style={{
						display: 'flex',
						flexBasis: '100%',
						justifyContent: 'space-between',
						paddingLeft: '14px',
					}}
				>
					<RealTime />
					<Button
						disabled={!!errors.duration}
						disableElevation
						onClick={onSubmit}
						startIcon={<Check />}
						variant="contained"
					>
						Apply
					</Button>
				</div>
			</BtPopperExtendedActions>
		</>
	);
}

QuickSelect.propTypes = {
	onClose: PropTypes.func.isRequired,
	previewRange: PropTypes.object,
	setPopperClickAway: PropTypes.func.isRequired,
	setPreviewRange: PropTypes.func.isRequired,
	setTimeRange: PropTypes.func.isRequired,
};
