import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
	Box,
	Divider,
	List,
	ListItem as MuiListItem,
	ListItemIcon,
	ListItemText,
	Toolbar,
	Tooltip,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/WarningRounded';
import HomeIcon from '@mui/icons-material/Home';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';

import { useAppContext } from '../context/ContextManager';
import BtUserRow from '../components/generic/BtUserRow';
import BtOrgButton from './BtOrgButton';

const ListContainer = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	height: '100%',
}));

const ListItem = styled(MuiListItem)(({ theme }) => ({
	[theme.breakpoints.up('sm')]: {
		borderTopRightRadius: 8,
		borderBottomRightRadius: 8,
	},
}));

const UserButton = styled('button')(({ theme }) => ({
	width: '100%',
	padding: '0 0.8em',
	boxSizing: 'border-box',
	backgroundColor: '#7771',
	borderRadius: 8,
	border: 'none',
	outline: 'none',

	'&:hover': {
		outline: 'none',
		backgroundColor: '#7772',
	},

	'&:focus': {
		outline: `2px solid ${theme.palette.primary.main}`,
		backgroundColor: '#7770',
	},

	'&:active': {
		outline: 'none',
		backgroundColor: '#7775',
	},
}));

const Warning = styled(WarningIcon)(({ iconmode, theme }) => ({
	color: theme.palette.indicators.warning.main,
	fontSize: 16,
	marginLeft: iconmode ? 0 : '0.3em',
	position: iconmode ? 'absolute' : 'inherit',
	left: iconmode ? 10 : 'inherit',
	bottom: iconmode ? 10 : 'inherit',
	transform: 'translateY(2px)',
}));

export default function BtNavigation({
	contextualNav,
	drawerOpen,
	navItems,
	navClick,
	classes,
	setOrgSelectOpen,
}) {
	const { showMobile } = useAppContext();
	const { userInfo, appOrg } = useAppContext();
	const history = useHistory();

	const composeTooltipText = useCallback(
		(text, subscriptionExpired) => {
			if (!(drawerOpen || showMobile)) {
				return `${text}${
					subscriptionExpired ? ' • Subscription Expired' : ''
				}`;
			}
			if (subscriptionExpired) {
				return 'Subscription Expired';
			}
			return '';
		},
		[drawerOpen, showMobile]
	);

	return (
		<>
			<ListContainer>
				<div>
					<Toolbar />
					<Divider />
					<List style={{ minHeight: '100%' }}>
						{showMobile && (
							<BtOrgButton
								organisation={appOrg}
								onClick={() => {
									setOrgSelectOpen(true);
									navClick()();
								}}
							/>
						)}
						{contextualNav && (
							<Tooltip
								title={drawerOpen || showMobile ? '' : 'Home'}
								placement="right"
								disableInteractive
							>
								<ListItem
									button
									key={'home'}
									onClick={navClick('/')}
									className={classes.item}
								>
									<ListItemIcon>
										<HomeIcon />
									</ListItemIcon>
									<ListItemText>Home</ListItemText>
								</ListItem>
							</Tooltip>
						)}
						{navItems.map(
							({
								id,
								text,
								route,
								icon,
								active,
								subscriptionExpired,
							}) => (
								<Tooltip
									title={composeTooltipText(
										text,
										subscriptionExpired
									)}
									placement="right"
									disableInteractive
									key={`${contextualNav ? '_' : ''}${id}`}
								>
									<ListItem
										button
										onClick={navClick(route)}
										className={cx(
											active && classes.activeItem
										)}
									>
										<ListItemIcon
											className={classes.itemIcon}
										>
											{icon}
											{subscriptionExpired &&
												!drawerOpen && (
													<Warning iconmode={+true} />
												)}
										</ListItemIcon>
										<ListItemText>
											{text}
											{subscriptionExpired && <Warning />}
										</ListItemText>
									</ListItem>
								</Tooltip>
							)
						)}
					</List>
				</div>
				{showMobile && (
					<div
						style={{
							padding: '1em',
							boxSizing: 'border-box',
							width: '100%',
						}}
					>
						<UserButton
							onClick={event => {
								history.push('/UserSettings');
								navClick()();
								event.currentTarget.blur();
							}}
							aria-label="Go to User Settings"
							title="User Settings"
						>
							<Box style={{ width: '100%', display: 'flex' }}>
								<BtUserRow
									user={{
										user_name: `${userInfo.first_name} ${
											userInfo.last_name
										}`,
										first_name: userInfo.first_name,
										last_name: userInfo.last_name,
										initials: userInfo.initials,
									}}
									style={{ margin: 0 }}
								/>
							</Box>
						</UserButton>
					</div>
				)}
			</ListContainer>
		</>
	);
}

BtNavigation.propTypes = {
	contextualNav: PropTypes.arrayOf(PropTypes.object),
	drawerOpen: PropTypes.bool.isRequired,
	navItems: PropTypes.arrayOf(PropTypes.object),
	navClick: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	setOrgSelectOpen: PropTypes.func.isRequired,
};
