import React, { useCallback, useEffect, useState } from 'react';

import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import BackIcon from '@mui/icons-material/ArrowBack';
import SubmitIcon from '@mui/icons-material/ArrowUpward';

import BtLoading from '../../../../../components/generic/BtLoading';
import { useWorkflowSessionContext } from '../../contexts/WorkflowSessionContext';
import WorkflowActionButton from '../core/WorkflowActionButton';

export default function WorkflowSummaryActions() {
	const history = useHistory();

	const {
		confirmBeforeSubmission,
		isFinal,
		nextStates,
		sessionSubmitting,
		success,
	} = useWorkflowSessionContext();

	const [nextState, setNextState] = useState('');

	useEffect(
		() => {
			if (nextStates) {
				const defaultNextState = _.find(nextStates, 'default');

				if (defaultNextState) {
					setNextState(defaultNextState);
				}
			}
		},
		[nextStates]
	);

	const onSubmit = useCallback(
		() => {
			confirmBeforeSubmission(nextState);
		},
		[nextState, confirmBeforeSubmission]
	);

	return (
		<>
			<BtLoading loading={sessionSubmitting}>
				{!isFinal && (
					<div
						style={{
							alignItems: 'flex-end',
							display: 'flex',
							paddingTop: '2em',
						}}
					>
						<FormControl
							style={{ marginRight: '1em', width: '100%' }}
							variant="standard"
						>
							<InputLabel>Next State</InputLabel>
							<Select
								color="primary"
								label="Next State"
								onChange={event => {
									setNextState(event.target.value);
								}}
								size="small"
								value={nextState}
								variant="standard"
							>
								{nextStates?.map(state => (
									<MenuItem key={state.state} value={state}>
										{state.text}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<WorkflowActionButton
							disabled={sessionSubmitting || success}
							disableElevation
							onClick={onSubmit}
							startIcon={<SubmitIcon />}
							style={{ height: 36, margin: 0 }}
							variant="contained"
						>
							Submit
						</WorkflowActionButton>
					</div>
				)}
				{isFinal && (
					<Button
						onClick={() => history.push('/Workflows/Submissions')}
						startIcon={<BackIcon />}
						style={{ marginTop: '1em' }}
					>
						Back to Submissions
					</Button>
				)}
			</BtLoading>
		</>
	);
}
