import React from 'react';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';

import { useQueryBuilderDispatchContext } from '../context';
import { definedStages } from './stages';

export default function BtQueryBuilderStageTypeSelect(props) {
	const dispatch = useQueryBuilderDispatchContext();

	const { allowedStages, stageId } = props;

	const allowed = !allowedStages
		? definedStages
		: definedStages.filter(s => allowedStages.includes(s.key));

	return (
		<Stack direction="row" flexWrap="wrap">
			{allowed.map(s => (
				<Card
					key={s.key}
					sx={{
						width: 'calc(50% - 1em)',
						m: '0.5em',
					}}
					variant="outlined"
				>
					<CardActionArea
						onClick={() =>
							dispatch({
								id: stageId,
								action: 'setType',
								type: s.key,
							})
						}
						sx={{
							// make the CardActionArea fill the whole card
							display: 'flex',
							justifyContent: 'space-between',
							flexDirection: 'column',
							height: '100%',
						}}
					>
						<CardContent sx={{ width: '100%' }}>
							<Typography
								variant="h6	"
								gutterBottom
								component="div"
							>
								{s.key}
							</Typography>
							<Typography variant="body2">
								{s.description}
							</Typography>
						</CardContent>
					</CardActionArea>
				</Card>
			))}
		</Stack>
	);
}

BtQueryBuilderStageTypeSelect.propTypes = {
	allowedStages: PropTypes.arrayOf(
		PropTypes.oneOf(definedStages.map(s => s.key))
	),
	stageId: PropTypes.number.isRequired,
};
