import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Controller, useFormContext } from 'react-hook-form';
import useFormElRequired from '../../../hooks/useFormElRequired';
import BtSignature from '../BtSignature';

export default function BtFormSignature({
	disabled,
	fetching,
	isRequired,
	label,
	name,
	...otherProps
}) {
	const {
		control,
		formState: { errors },
		loading,
		sending,
		setValue,
		validationSchema,
		watch,
	} = useFormContext() || { formState: {} };

	const value = watch(name);

	const required = useFormElRequired(isRequired, validationSchema, name);

	const isDisabled = disabled || fetching || loading || sending;

	const handleChange = useCallback(
		newValue => {
			setValue(name, newValue, {
				shouldValidate: true,
				shouldDirty: true,
				shouldTouch: true,
			});
		},
		[name, setValue]
	);

	return (
		<Controller
			control={control}
			name={name}
			render={() => (
				<BtSignature
					disabled={isDisabled}
					errorMessage={errors[name]?.message}
					label={
						label
							? `${label}${!required ? ' (optional)' : ''}`
							: undefined
					}
					loading={loading}
					onChange={handleChange}
					required={required}
					value={value}
					{...otherProps}
				/>
			)}
		/>
	);
}

BtFormSignature.propTypes = {
	disabled: PropTypes.bool,
	fetching: PropTypes.bool,
	isRequired: PropTypes.bool,
	label: PropTypes.string,
	name: PropTypes.string.isRequired,
};
