import _ from 'lodash';

// const yAxisTickBreakpoints = {
// 	xxs: 2,
// 	xs: 3,
// 	sm: 4,
// 	md: 6,
// 	lg: 6,
// 	xl: 8,
// };

// const evaluateTickBreakpoint = height => {
// 	if (height <= 100) {
// 		return 'xxs';
// 	} else if (height <= 150) {
// 		return 'xs';
// 	} else if (height <= 200) {
// 		return 'sm';
// 	} else if (height <= 300) {
// 		return 'md';
// 	} else if (height <= 400) {
// 		return 'lg';
// 	} else if (height <= 500) {
// 		return 'xl';
// 	} else {
// 		return height * 0.02;
// 	}
// };

const evaluateYTickCount = height => {
	if (height <= 100) {
		return 2;
	} else if (height <= 150) {
		return 3;
	} else {
		return height * 0.02;
	}
};

// evaluate min and max data values
const evaluateDataValues = (options, dataParam) => {
	// extract key to access data values
	const values = options.params.map(param => {
		return param.value;
	});

	// define placeholders
	let [rangeMin, rangeMax] = [undefined, undefined];

	// TEMP DEV array of all values
	let allValues = [];

	// establish min and max data values
	dataParam.forEach(dataObj => {
		values.forEach(value => {
			if (Object.hasOwn(dataObj, value)) {
				const currentValue = dataObj[value];
				if (rangeMin === undefined || rangeMax === undefined) {
					rangeMax = currentValue;
					rangeMin = currentValue;
				} else if (currentValue < rangeMin) {
					rangeMin = currentValue;
				} else if (currentValue > rangeMax) {
					rangeMax = currentValue;
				}
				// TEMP DEV
				allValues.push(dataObj[value]);
			}
		});
	});
	return [rangeMin, rangeMax];
};

// sanitize the min and max bound parameters
const resolveParamBounds = (options, dataMin, dataMax) => {
	let resolvedMin = dataMin;
	let resolvedMax = dataMax;

	if (Object.hasOwn(options.valuePrimaryAxis, 'axisBounds')) {
		if (Object.hasOwn(options.valuePrimaryAxis.axisBounds, 'min')) {
			if (options.valuePrimaryAxis.axisBounds.min === 'auto') {
				resolvedMin = options.valuePrimaryAxis.axisBounds.min;
			} else if (
				typeof options.valuePrimaryAxis.axisBounds.min === 'number'
			) {
				resolvedMin = options.valuePrimaryAxis.axisBounds.min;
			}
		}

		if (Object.hasOwn(options.valuePrimaryAxis.axisBounds, 'max')) {
			if (options.valuePrimaryAxis.axisBounds.max === 'auto') {
				resolvedMax = options.valuePrimaryAxis.axisBounds.max;
			} else if (
				typeof options.valuePrimaryAxis.axisBounds.max === 'number'
			) {
				resolvedMax = options.valuePrimaryAxis.axisBounds.max;
			}
		}
	}
	// else {
	// 	console.log('WARNING, "axisBounds" property not present');
	// }
	return [resolvedMin, resolvedMax];
};

// resolve bound values
const resolveBounds = (paramMin, paramMax, dataMin, dataMax) => {
	const resolveMin = () => {
		if (paramMin === 'auto' || paramMin >= dataMin) {
			return dataMin;
		}
		return paramMin;
	};

	const resolveMax = () => {
		if (paramMax === 'auto' || paramMax <= dataMax) {
			return dataMax;
		}
		return paramMax;
	};
	const rangeMin = resolveMin();
	const rangeMax = resolveMax();
	return [rangeMin, rangeMax];
};

/**
 * Generate nice ticks, rounded to user friendly values
 * @param {number} min the lowest value to capture
 * @param {number} max inverse of min
 * @param {*} tickCount preferred number of ticks
 * @returns the distance between ticks, min and max values
 */
function niceTicks(min, max, tickCount, decimalPoints) {
	let minPoint = min;
	let maxPoint = max;
	let maxTicks = tickCount;
	let tickSpacing;
	let range;
	let niceMin;
	var niceMax;

	/**
	 * Calculate and update values for tick spacing and nice
	 * minimum and maximum data points on the axis.
	 */
	function calculate() {
		range = niceNum(maxPoint - minPoint, false);
		tickSpacing = niceNum(range / (maxTicks - 1), true);

		// Attempt to correct tick spacing due to snapping behavior and nice method.
		const tickCountF = (maxPoint - minPoint) / tickSpacing;
		if (tickCountF / maxTicks <= 0.5) {
			tickSpacing /= 2.0;
		}

		niceMin = Math.floor(minPoint / tickSpacing) * tickSpacing;
		niceMax = Math.ceil(maxPoint / tickSpacing) * tickSpacing;
	}

	/**
	 * Returns a "nice" number approximately equal to range Rounds
	 * the number if round = true Takes the ceiling if round = false.
	 *
	 *  localRange the data range
	 *  round whether to round the result
	 *  a "nice" number to be used for the data range
	 */
	function niceNum(localRange, round) {
		var exponent; /** exponent of localRange */
		var fraction; /** fractional part of localRange */
		var niceFraction; /** nice, rounded fraction */

		exponent = Math.floor(Math.log10(localRange));
		fraction = localRange / Math.pow(10, exponent);

		if (round) {
			if (fraction < 1.5) niceFraction = 1;
			else if (fraction < 3) niceFraction = 2;
			else if (fraction < 7) niceFraction = 5;
			else niceFraction = 10;
		} else {
			if (fraction <= 1) niceFraction = 1;
			else if (fraction <= 2) niceFraction = 2;
			else if (fraction <= 5) niceFraction = 5;
			else niceFraction = 10;
		}

		return niceFraction * Math.pow(10, exponent);
	}

	calculate();
	return {
		tickSpacing: tickSpacing,
		niceMinimum: _.round(niceMin, decimalPoints),
		niceMaximum: _.round(niceMax, decimalPoints),
		// niceMinimum: niceMin,
		// niceMaximum: niceMax,
	};
}

/**
 *
 * @param {object} visOptions
 * @param {object[]} data
 * @param {number} height
 * @returns
 */
export default function generateYAxisTickValues(
	visOptions,
	data,
	height,
	setError
) {
	const tempDefault = {
		// domain: ['dataMin', 'dataMax'],
		// ticks: undefined,
		domain: [0, 0],
		ticks: [0],
	};
	try {
		if (!data || data?.length === 0) {
			console.log('Zero length or no data provided to tick generator');
			return tempDefault;
		}

		if (!visOptions) {
			console.log('No visOptions provided to tick generator');
			return tempDefault;
		}

		if (!Object.hasOwn(visOptions, 'valuePrimaryAxis')) {
			console.log(
				'No visOptions.valuePrimaryAxis provided to tick generator, visOptions: ',
				visOptions
			);
			setError(true);
			return tempDefault;
		}

		// resolve decimal points
		let decimalPoints = null;
		if (Object.hasOwn(visOptions.valuePrimaryAxis, 'decimalPoints')) {
			if (
				typeof +visOptions.valuePrimaryAxis.decimalPoints === 'number'
			) {
				decimalPoints = +visOptions.valuePrimaryAxis.decimalPoints;
			}
		} else {
			decimalPoints = 2;
		}

		// declare data min / max
		const [dataMin, dataMax] = evaluateDataValues(visOptions, data);

		// declare the sanitized bound min and max
		const [min, max] = resolveParamBounds(visOptions, dataMin, dataMax);

		// declare the bound values
		const [rangeMin, rangeMax] = resolveBounds(min, max, dataMin, dataMax);

		// declare preferred tick count
		const tickCount = evaluateYTickCount(height);

		// generate nice tick parameters
		const { tickSpacing, niceMinimum, niceMaximum } = niceTicks(
			rangeMin,
			rangeMax,
			tickCount,
			decimalPoints
		);

		// generate tick array
		// const ticks = [
		// 	..._.range(niceMinimum, niceMaximum, tickSpacing),
		// 	niceMaximum,
		// ].map(value => _.round(value, decimalPoints));
		const ticks = [
			..._.range(niceMinimum, niceMaximum, tickSpacing),
			niceMaximum,
		];

		// console.log({ tickSpacing, niceMinimum, niceMaximum });

		// build and return result
		const result = {};
		result.ticks = ticks;
		result.domain = [rangeMin, rangeMax];
		return result;
	} catch (error) {
		console.log(error);
		return tempDefault;
	}
}
