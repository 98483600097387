const { OPERATION_MODE } = require('../../expression');
const { setOperatorSchemaFn } = require('../expression');
const { newDefaultSchema } = require('../utils');

// always produces a numeric output
const numericResultOperators = [
	'$divide',
	'$multiply',
	'$pow',
	'$abs',
	'$ceil',
	'$floor',
	'$trunc',
	'$toInt',
	'$toLong',
	'$toDecimal',
	'$toDouble',
];

numericResultOperators.forEach((operator) => {
	setOperatorSchemaFn(
		operator,
		(context, args, options) => {
			return newDefaultSchema('number');
		},
		[OPERATION_MODE.AGGREGATE]
	);
});

// always produces a boolean output
const booleanOperators = [
	'$and',
	'$or',
	'$not',
	'$ne',
	'$exists',
	'$lt',
	'$lte',
	'$gt',
	'$gte',
	'$near',
	'$regex',
	'$toBool',
];

booleanOperators.forEach((operator) => {
	setOperatorSchemaFn(
		operator,
		(context, args, options) => {
			return newDefaultSchema('boolean');
		},
		[OPERATION_MODE.AGGREGATE]
	);
});

// always produces a date output
const dateOperators = ['$dateTrunc', '$toDate'];

dateOperators.forEach((operator) => {
	setOperatorSchemaFn(
		operator,
		(context, args, options) => {
			return newDefaultSchema('date');
		},
		[OPERATION_MODE.AGGREGATE]
	);
});

// always produces a string output
const stringOperators = ['$concat', '$toString', '$dateToString'];

stringOperators.forEach((operator) => {
	setOperatorSchemaFn(
		operator,
		(context, args, options) => {
			return newDefaultSchema('string');
		},
		[OPERATION_MODE.AGGREGATE]
	);
});

// output dependent on input and some process
require('./add.js');
require('./subtract.js');
require('./convert.js');
require('./ifNull.js');
require('./literal.js');
require('./mergeObjects.js');
require('./arrayToObject.js');
require('./accumulator/index.js');
