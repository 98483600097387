import { apiClient } from './api_client';
import { uuidRegex } from './utils';

import {
	roleGetListSchema,
	roleGetSchema,
	roleAddSchema,
	roleAddResponseSchema,
	roleAddPermissionSchema,
	roleAddPermissionResponseSchema,
	roleEditPermissionSchema,
} from './validations/roleValidation';

//========================================================================================================
async function roleGetList() {
	const req_url = 'role';
	try {
		const response = await apiClient({ method: 'get', url: req_url });

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				await roleGetListSchema.validate(response.roles);
				return response.roles;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function roleGet({ roleUuid }) {
	if (!uuidRegex.test(roleUuid)) {
		throw new Error('Param Error');
	}

	const req_url = 'role/' + roleUuid;
	try {
		const response = await apiClient({ method: 'get', url: req_url });

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				await roleGetSchema.validate(response.role);
				return response.role;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function roleAdd({ newRole }) {
	// Validate the orgDetails
	try {
		await roleAddSchema.validate(newRole);
	} catch (error) {
		throw error;
	}

	const req_url = 'role';
	try {
		const response = await apiClient({
			method: 'post',
			url: req_url,
			body: newRole,
		});

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				await roleAddResponseSchema.validate(response.role);
				return response.role;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw error;
	}
}

//========================================================================================================
async function roleUpdate({ roleUuid, roleUpdate }) {
	if (!uuidRegex.test(roleUuid)) {
		throw new Error('Param Error');
	}

	// Validate the orgDetails
	try {
		// The role update has the same schema are the add
		await roleAddSchema.validate(roleUpdate);
	} catch (error) {
		throw error;
	}

	const req_url = 'role/' + roleUuid;
	try {
		const response = await apiClient({
			method: 'put',
			url: req_url,
			body: roleUpdate,
		});

		if (response) {
			if (response.result === 'OK') {
				return;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw error;
	}
}

//========================================================================================================
async function roleDelete({ roleUuid }) {
	if (!uuidRegex.test(roleUuid)) {
		throw new Error('Param Error');
	}

	const req_url = 'role/' + roleUuid;
	try {
		const response = await apiClient({
			method: 'delete',
			url: req_url,
		});

		if (response) {
			if (response.result === 'OK') {
				return;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw error;
	}
}

//========================================================================================================
async function roleAddUser({ roleUuid, userUuid }) {
	if (!uuidRegex.test(roleUuid)) {
		throw new Error('Invalid role UUID');
	}

	// Validate the orgDetails
	// try {
	// 	// The role update has the same schema are the add
	// 	await roleAddSchema.validate(roleUpdate);
	// } catch (error) {
	// 	throw error;
	// }

	const req_url = `role/${roleUuid}/user`;
	try {
		const response = await apiClient({
			method: 'post',
			url: req_url,
			body: { user_uuid: userUuid },
		});

		if (response) {
			if (response.result === 'OK') {
				return;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw error;
	}
}

//========================================================================================================
async function roleDeleteUser({ roleUuid, userUuid }) {
	if (!uuidRegex.test(roleUuid)) {
		throw new Error('Invalid role UUID');
	}

	if (!uuidRegex.test(userUuid)) {
		throw new Error('Invalid user UUID');
	}

	const req_url = `role/${roleUuid}/user`;
	try {
		const response = await apiClient({
			method: 'delete',
			url: req_url,
			body: { user_uuid: userUuid },
		});

		if (response) {
			if (response.result === 'OK') {
				return;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw error;
	}
}

//========================================================================================================
async function roleAddPermission({ ownerUuid, newPermission }) {
	if (!uuidRegex.test(ownerUuid)) {
		throw new Error('Invalid role UUID');
	}

	// Validate the permission
	try {
		// The role update has the same schema are the add
		await roleAddPermissionSchema.validate(newPermission);
	} catch (error) {
		throw error;
	}

	const req_url = `role/${ownerUuid}/permission`;
	try {
		const response = await apiClient({
			method: 'post',
			url: req_url,
			body: newPermission,
		});

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				await roleAddPermissionResponseSchema.validate(
					response.permission
				);
				return response.permission;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw error;
	}
}

//========================================================================================================
async function roleEditPermission({
	ownerUuid,
	permissionUuid,
	newPermission,
}) {
	if (!uuidRegex.test(ownerUuid)) {
		throw new Error('Invalid role UUID');
	}

	if (!uuidRegex.test(permissionUuid)) {
		throw new Error('Invalid permission UUID');
	}

	// Validate the permission
	try {
		// The role update has the same schema are the add
		await roleEditPermissionSchema.validate(newPermission);
	} catch (error) {
		throw error;
	}

	const req_url = `role/${ownerUuid}/permission/${permissionUuid}`;
	try {
		const response = await apiClient({
			method: 'put',
			url: req_url,
			body: newPermission,
		});

		if (response) {
			if (response.result === 'OK') {
				return;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw error;
	}
}

//========================================================================================================
async function roleDeletePermission({ roleUuid, permissionUuid }) {
	if (!uuidRegex.test(roleUuid)) {
		throw new Error('Invalid role UUID');
	}

	if (!uuidRegex.test(permissionUuid)) {
		throw new Error('Invalid permission UUID');
	}

	const req_url = `role/${roleUuid}/permission/${permissionUuid}`;
	try {
		const response = await apiClient({
			method: 'delete',
			url: req_url,
		});

		if (response) {
			if (response.result === 'OK') {
				return;
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		throw error;
	}
}

export {
	roleGetList,
	roleGet,
	roleAdd,
	roleUpdate,
	roleDelete,
	roleAddUser,
	roleDeleteUser,
	roleAddPermission,
	roleEditPermission,
	roleDeletePermission,
};
