import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
	Button,
	IconButton,
	LinearProgress,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { v4 as uuidV4 } from 'uuid';
import BtSelectionTable from '../../../components/generic/BtSelectionTable';
import { useNavContext } from '../../../context/ContextManager';

import admin_options from '../AdminOptions';
import admin_nav_item from '../AdminNavItem';
import { BtFormContainer } from '../../../components/generic/forms';
import useNavRedactor from '../../../hooks/useNavRedactor';
import useFetch from '../../../hooks/useFetch';
import BtLoading from '../../../components/generic/BtLoading';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/AddCircle';
import { NewScreenDialog } from './NewScreenDialog';
import {
	screenAdminAssign,
	screenAdminAvailable,
	screenAdminCreate,
	screenAdminGet,
	screenAdminListGet,
} from '../../../API/screen_admin.api';
import BtError from '../../../components/generic/BtError';
import {
	roleGetList,
	screenGet,
	tagGroupGet,
	userAdminGetList,
} from '../../../API';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';
import { refreshTileUuids } from '../../../components/generic/screens/functions/screenConfig';
import ScreenDistributionWizard from './screenDistributionWizard/ScreenDistributionWizard';
import BtDialog from '../../../components/generic/BtDialog';
import { AccountEditOutline, Close } from 'mdi-material-ui';
import BtRedactor from '../../../components/BtRedactor';
import useAvailableToUser from '../../../hooks/useAvailableToUser';

const tableColumns = [
	{ field: 'name', text: 'Screen name', minBreakpoint: 'sm' },
	{ field: 'distribution', text: 'Manage Distribution', minBreakpoint: 'sm' },
	// { field: 'delete', text: 'Delete Screen', minBreakpoint: 'sm' },
];

export default function Screens() {
	const history = useHistory();
	// navContext contextual navigation items
	const { setContextualNav } = useNavContext();
	// navContext breadcrumbs
	const { setBreadcrumbs } = useNavContext();
	const [showDistributionDialog, setShowDistributionDialog] = useState(false);
	const [screenToEdit, setScreenToEdit] = useState(null);
	const [showNewScreenDialog, setShowNewScreenDialog] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const [sending, setSending] = useState(false);
	const [screenLoading, setScreenLoading] = useState(false);
	const [screenError, setScreenError] = useState(false);

	const redactNav = useNavRedactor();
	const theme = useTheme();
	const screenDownSm = useMediaQuery(theme.breakpoints.down('sm'));

	// if the user does not have the 'ScreenAdminDistribution' permission the distribution column is be redacted
	const checkAvailability = useAvailableToUser();
	const requiredPermissionsAll = {
		screens: ['ScreenAdminDistribution'],
		orgAdmin: ['RolesList', 'UserList'],
	};
	const availability = checkAvailability({ requiredPermissionsAll });

	const redactedTableColumns = useMemo(
		() => {
			if (!availability.available) {
				return tableColumns.filter(
					({ field }) => field !== 'distribution'
				);
			} else {
				return tableColumns;
			}
		},
		[availability.available]
	);

	// Set breadcrumbs
	useEffect(
		() => {
			setBreadcrumbs([
				{ text: 'Home', link: '/' },
				{ text: 'Admin', link: '/' },
				{ text: 'Screens', link: '' },
			]);
		},
		[setBreadcrumbs]
	);

	const redactedAdminOptions = useMemo(() => redactNav(admin_options), [
		redactNav,
	]);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([...admin_nav_item, ...redactedAdminOptions]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav, redactedAdminOptions]
	);

	const {
		loading: screenListLoading,
		data: screenList,
		error: screenListError,
		request: fetchScreenList,
	} = useFetch(screenAdminListGet);

	// get the org screens from back end
	useEffect(
		() => {
			fetchScreenList();
		},
		[fetchScreenList]
	);

	const handleManageDistribution = useCallback(async (event, uuid) => {
		event.stopPropagation();
		setScreenLoading(true);
		try {
			const res = await screenAdminGet({ screenUuid: uuid });
			setScreenToEdit(res);
			setShowDistributionDialog(true);
		} catch (error) {
			setScreenError(true);
			console.log({ error });
		} finally {
			setScreenLoading(false);
		}
	}, []);

	const onClose = () => {
		setShowDistributionDialog(false);
	};

	const onClickRow = uuid => {
		history.push(`/Screens/ScreenEdit/${uuid}`);
	};

	const handleAddScreen = async ({
		mode,
		screenToCopyUuid,
		newScreenName,
		appOrgUuid,
	}) => {
		try {
			console.log({ mode, screenToCopyUuid, newScreenName, appOrgUuid });
			const newScreen = {};
			newScreen.uuid = uuidV4();
			newScreen.screen_name = newScreenName;
			newScreen.ownerType = 'Organisation';
			newScreen.organisation = appOrgUuid;
			newScreen.layouts = {
				desktop: [],
				tablet: [],
				mobile: [],
				native: [],
			};
			newScreen.tiles = {};
			newScreen.background_color = null;

			switch (mode) {
				case 'copy':
					let userScreenToCopy = await screenGet({
						screenUuid: screenToCopyUuid,
					});

					// refresh the tile adn layout uuids
					const [newUserLayouts, newUserTiles] = refreshTileUuids(
						userScreenToCopy.layouts,
						userScreenToCopy.tiles,
						newScreen.uuid
					);

					newScreen.tiles = newUserTiles;
					newScreen.layouts = newUserLayouts;

					newScreen.background_color =
						userScreenToCopy.background_color;
					console.log({ userScreenToCopy, newScreen });
					break;
				case 'orgScreen':
					let orgScreenToCopy = await screenAdminGet({
						screenUuid: screenToCopyUuid,
					});

					// refresh the tile adn layout uuids
					const [newOrgLayouts, newOrgTiles] = refreshTileUuids(
						orgScreenToCopy.layouts,
						orgScreenToCopy.tiles,
						newScreen.uuid
					);

					newScreen.tiles = newOrgTiles;
					newScreen.layouts = newOrgLayouts;
					newScreen.background_color =
						orgScreenToCopy.background_color;
					console.log({ orgScreenToCopy, newScreen });
					break;
				default:
					break;
			}

			// add screen to the backend
			await screenAdminCreate({ newScreen: newScreen });

			fetchScreenList({ ownerFilter: 'Organisation' });
			enqueueSnackbar(`Created new Screen '${newScreenName}'`, {
				variant: 'success',
			});
		} catch (error) {
			console.log({ error });
			enqueueSnackbar(`Failed to create new Screen '${newScreenName}'`, {
				variant: 'error',
			});
		}
	};

	const handleAssign = useCallback(
		async ({ screenGroup, assignees }) => {
			console.log({ screenToEdit, screenGroup, assignees });
			setSending(true);
			try {
				await screenAdminAssign({
					screenUuid: screenToEdit.uuid,
					group: screenGroup,
					assignTo: assignees,
				});
				enqueueSnackbar(`Screen assignment updated`, {
					variant: 'success',
				});
				setShowDistributionDialog(false);
			} catch (error) {
				console.log({ error });
				enqueueSnackbar(`Failed to update screen assignment`, {
					variant: 'error',
				});
				setShowDistributionDialog(false);
			} finally {
				setSending(false);
			}
		},
		[enqueueSnackbar, screenToEdit]
	);

	const handleAvailable = useCallback(
		async ({ availableTo }) => {
			console.log({ screenToEdit, availableTo });
			setSending(true);
			try {
				await screenAdminAvailable({
					screenUuid: screenToEdit.uuid,
					availableTo: availableTo,
				});
				enqueueSnackbar(`Screen availability updated`, {
					variant: 'success',
				});
				setShowDistributionDialog(false);
			} catch (error) {
				console.log({ error });
				enqueueSnackbar(`Failed to update screen availability`, {
					variant: 'error',
				});
				setShowDistributionDialog(false);
			} finally {
				setSending(false);
			}
		},
		[enqueueSnackbar, screenToEdit]
	);

	const handleDelete = uuid => event => {
		event.stopPropagation();
		console.log('delete:', uuid);
	};

	if (screenListError) {
		return (
			<BtError
				title="Retrieval Error"
				description={
					<>
						<Typography>
							An error occurred whilst trying to retrieve the
							screens.
						</Typography>
					</>
				}
				action={() => fetchScreenList()}
			/>
		);
	}

	if (screenError) {
		return (
			<BtError
				title="Retrieval Error"
				description={
					<>
						<Typography>
							An error occurred whilst trying to retrieve the
							required data.
						</Typography>
					</>
				}
				action={() => setScreenError(false)}
				actionLabel="Close"
				actionIcon={<Close />}
			/>
		);
	}

	return (
		<BtLoading loading={screenListLoading || screenLoading}>
			{screenList && (
				<BtFormContainer title="Screens Configuration" maxWidth="md">
					<BtSelectionTable
						subject="Screens"
						// columns={tableColumns}
						columns={redactedTableColumns}
						onClick={onClickRow}
						title={
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									flexWrap: 'wrap',
									gap: 10,
								}}
							>
								<Typography
									sx={{
										fontWeight: 'bold',
										padding: '4px 0',
									}}
									variant="h5"
								>
									Your organisation screens
								</Typography>
								<BtRedactor
									requiredPermissionsAll={{
										screens: ['ScreenAdminCreate'],
									}}
								>
									<Button
										variant="contained"
										onClick={() =>
											setShowNewScreenDialog(true)
										}
										startIcon={<AddIcon />}
										disableElevation
									>
										Add New Screen
									</Button>
								</BtRedactor>
							</div>
						}
						data={(screenList || []).map(
							({ uuid, screen_name }) => ({
								id: uuid,
								name: screen_name,
								distribution: (
									<Tooltip
										title="Manage distribution"
										disableInteractive
									>
										<IconButton
											size="small"
											onClick={event =>
												handleManageDistribution(
													event,
													uuid
												)
											}
										>
											<AccountEditOutline />
										</IconButton>
									</Tooltip>
								),
								delete: (
									<Tooltip title="Delete" disableInteractive>
										<IconButton
											color="error"
											onClick={handleDelete(uuid)}
										>
											<DeleteIcon />
										</IconButton>
									</Tooltip>
								),
							})
						)}
					/>
				</BtFormContainer>
			)}
			<BtDialog
				open={showDistributionDialog}
				onClose={(event, reason) => {
					if (reason !== 'backdropClick') {
						setShowDistributionDialog(false);
					}
				}}
				minwidth="md"
				style={{
					maxHeight: screenDownSm ? '100%' : 720,
					margin: 'auto',
				}}
				fullWidth
			>
				{sending && (
					<LinearProgress
						style={{ position: 'absolute', width: '100%', top: 0 }}
					/>
				)}
				{screenToEdit && (
					<ScreenDistributionWizard
						userAdminGetList={userAdminGetList}
						roleGetList={roleGetList}
						tagGroupGet={tagGroupGet}
						onClose={onClose}
						sending={sending}
						handleAssign={handleAssign}
						handleAvailable={handleAvailable}
						screenData={screenToEdit}
					/>
				)}
			</BtDialog>

			<BtDialog
				open={showNewScreenDialog}
				onClose={(event, reason) => {
					if (reason !== 'backdropClick') {
						setShowNewScreenDialog(false);
					}
				}}
				// fullWidth={!userScreensError || !orgScreensError}
				// fullWidth
				maxWidth="sm"
			>
				<NewScreenDialog
					onClose={() => setShowNewScreenDialog(false)}
					handleSubmit={handleAddScreen}
				/>
			</BtDialog>
		</BtLoading>
	);
}
