import { apiClient } from './api_client';
import { uuidRegex } from './utils';

import {
	workflowTemplateGetListSchema,
	workflowTemplateGetSchema,
	workflowTemplateHistoryGetSchema,
	workflowTemplateDraftGetSchema,
	workflowTemplatePostSchema,
	workflowTemplatePostResponseSchema,
	workflowTemplatePutSchema,
} from './validations/workflowTemplateValidation';

//========================================================================================================
async function workflowTemplateGetList() {
	const req_url = 'workflow/template';
	try {
		const response = await apiClient({
			method: 'get',
			url: req_url,
		});

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				return await workflowTemplateGetListSchema.validate(
					response.workflow_templates
				);
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function workflowTemplateGet({ workflowTemplateUuid }) {
	if (!uuidRegex.test(workflowTemplateUuid)) {
		throw new Error('Param Error');
	}

	const req_url = `workflow/template/${workflowTemplateUuid}`;
	try {
		const response = await apiClient({
			method: 'get',
			url: req_url,
		});

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				return await workflowTemplateGetSchema.validate(
					response.workflow_template
				);
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function workflowTemplateHistoryGet({ workflowTemplateUuid }) {
	if (!uuidRegex.test(workflowTemplateUuid)) {
		throw new Error('Param Error');
	}

	const req_url = `workflow/template/${workflowTemplateUuid}/history`;
	try {
		const response = await apiClient({
			method: 'get',
			url: req_url,
		});

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				return await workflowTemplateHistoryGetSchema.validate(
					response.workflow_template
				);
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function workflowTemplateGetDraft({ workflowTemplateUuid }) {
	if (!uuidRegex.test(workflowTemplateUuid)) {
		throw new Error('Param Error');
	}

	const req_url = `workflow/template/${workflowTemplateUuid}/draft`;
	try {
		const response = await apiClient({
			method: 'get',
			url: req_url,
		});
		console.log(response);
		console.log(response.workflow_draft.name);

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				return await workflowTemplateDraftGetSchema.validate(
					// response.workflow_draft_template
					response.workflow_draft
				);
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function workflowTemplateGetVersion({ workflowTemplateUuid, version }) {
	if (!uuidRegex.test(workflowTemplateUuid)) {
		throw new Error('Param Error');
	}

	const req_url = `workflow/template/${workflowTemplateUuid}/${version}`;
	try {
		const response = await apiClient({
			method: 'get',
			url: req_url,
		});

		if (response) {
			if (response.result === 'OK') {
				// Validate the returned data object
				return await workflowTemplateGetSchema.validate(
					response.workflow_template
				);
			} else {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function workflowTemplateCreate({ newTemplate }) {
	try {
		await workflowTemplatePostSchema.validate(newTemplate);
	} catch (error) {
		throw error;
	}

	const req_url = `workflow/template`;
	try {
		const response = await apiClient({
			method: 'post',
			url: req_url,
			body: newTemplate,
		});
		console.log(response);
		if (response) {
			if (response.result === 'OK') {
				return await workflowTemplatePostResponseSchema.validate(
					response.workflow_template
				);
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function workflowTemplateDraftUpdate({
	workflowTemplateUuid,
	templateUpdate,
}) {
	if (!uuidRegex.test(workflowTemplateUuid)) {
		throw new Error('Param Error');
	}

	// TODO: Add schema validation

	const req_url = `workflow/template/${workflowTemplateUuid}/draft`;
	try {
		const response = await apiClient({
			method: 'put',
			url: req_url,
			body: templateUpdate,
		});

		if (response) {
			if (!response.result === 'OK') {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function workflowTemplateChangeStatus({
	workflowTemplateUuid,
	newStatus,
}) {
	if (!uuidRegex.test(workflowTemplateUuid)) {
		throw new Error('Param Error');
	}

	if (newStatus !== 'Active' && newStatus !== 'Inactive') {
		throw new Error('Param Error');
	}

	const req_url = `workflow/template/${workflowTemplateUuid}/status`;
	try {
		const response = await apiClient({
			method: 'put',
			url: req_url,
			body: { new_status: newStatus },
		});

		if (response) {
			if (!response.result === 'OK') {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function workflowTemplateUpdateDraftLock({
	workflowTemplateUuid,
	userUuid,
}) {
	if (!uuidRegex.test(workflowTemplateUuid)) {
		throw new Error('Param Error');
	}

	if (!uuidRegex.test(userUuid) && userUuid != null) {
		throw new Error('Param Error');
	}

	const req_url = `workflow/template/${workflowTemplateUuid}/draft/lock`;
	try {
		const response = await apiClient({
			method: 'put',
			url: req_url,
			body: { user: userUuid },
		});

		if (response) {
			if (!response.result === 'OK') {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function workflowTemplatePublish({ workflowTemplateUuid }) {
	if (!uuidRegex.test(workflowTemplateUuid)) {
		throw new Error('Param Error');
	}

	const req_url = `workflow/template/${workflowTemplateUuid}/draft/publish`;
	try {
		const response = await apiClient({
			method: 'put',
			url: req_url,
		});

		if (response) {
			if (!response.result === 'OK') {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function workflowTemplateUpdateDetails({ workflowTemplateUuid, update }) {
	console.log(workflowTemplateUuid, update);
	if (!uuidRegex.test(workflowTemplateUuid)) {
		throw new Error('Param Error');
	}

	const req_url = `workflow/template/${workflowTemplateUuid}/collection/update`;

	try {
		const validTemplateUpdate = await workflowTemplatePutSchema.validate(
			update
		);

		const response = await apiClient({
			method: 'put',
			url: req_url,
			body: { ...validTemplateUpdate },
		});

		if (response) {
			if (!response.result === 'OK') {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

//========================================================================================================
async function workflowTemplateDraftCommit({ workflowTemplateUuid }) {
	if (!uuidRegex.test(workflowTemplateUuid)) {
		throw new Error('Param Error');
	}

	const req_url = `workflow/template/${workflowTemplateUuid}/draft/commit`;
	try {
		const response = await apiClient({
			method: 'put',
			url: req_url,
		});

		if (response) {
			if (!response.result === 'OK') {
				throw new Error('Server Error');
			}
		}
	} catch (error) {
		console.log(error);
		throw new Error('Response Error');
	}
}

export {
	workflowTemplateGetList,
	workflowTemplateGet,
	workflowTemplateGetVersion,
	workflowTemplateCreate,
	// workflowTemplateHistoryGet,
	// workflowTemplateDraftGet,
	// workflowTemplateChangeStatus,
	// workflowTemplateUpdateDraft,
	// workflowTemplateUpdateDraftLock,
	// workflowTemplateDraftPublish,

	workflowTemplateUpdateDetails,
	workflowTemplateChangeStatus,
	workflowTemplateDraftCommit,
	workflowTemplateDraftUpdate,
	workflowTemplateGetDraft,
	workflowTemplateHistoryGet,
	workflowTemplatePublish,
	workflowTemplateUpdateDraftLock,
};
