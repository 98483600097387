import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import {
	AppBar as MuiAppBar,
	Box,
	Container,
	IconButton,
	LinearProgress,
	ListItem,
	Menu,
	MenuItem,
	Toolbar,
	Tooltip,
	Typography,
} from '@mui/material';
import { Auth } from 'aws-amplify';
import Cookies from 'universal-cookie';
import { styled, useTheme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';

import BtOrgLogo from '../components/generic/BtOrgLogo';
import { drawerWidth } from './BtResponsiveDrawer';
import logo from '../assets/bt_logo_no_text.svg';
import { setAppStoreItem } from '../utils/appStore';
import { useNavContext, useAppContext } from '../context/ContextManager';

const styleSection = {
	height: '100%',
	display: 'flex',
	alignItems: 'center',
};

const styleActionButton = {
	...styleSection,
	cursor: 'pointer',
	'&:hover': {
		outline: 'none',
		backgroundColor: '#7772',
	},
};

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,

	transition: theme.transitions.create(['margin', 'width'], {
		duration: theme.transitions.duration.leavingScreen,
		easing: theme.transitions.easing.sharp,
	}),

	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,

		transition: theme.transitions.create(['margin', 'width'], {
			duration: theme.transitions.duration.enteringScreen,
			easing: theme.transitions.easing.sharp,
		}),
	}),
}));

const cookies = new Cookies();

const HeadingButton = ({ children, Icon, label, onClick, showLabel }) => (
	<Box onClick={onClick} sx={styleActionButton} component="div">
		<ListItem
			style={{
				alignItems: 'center',
				display: 'flex',
				height: '100%',
				justifyContent: 'center',
				paddingRight: showLabel ? 16 : 16,
				whiteSpace: 'nowrap',
			}}
		>
			{Icon && <Icon style={{ margin: '8px' }} />}
			{children}
			{showLabel && <Typography variant={'body1'}>{label}</Typography>}
		</ListItem>
	</Box>
);

export default function BtAppBar({
	drawerOpen,
	setDrawerOpen,
	swipeDrawerOpen,
	setSwipeDrawerOpen,
	setOrgSelectDialog,
}) {
	const history = useHistory();
	const theme = useTheme();
	const screen_downMd = useMediaQuery(theme.breakpoints.down('md'));
	const { showMobile, localDevMode } = useAppContext();
	const { breadcrumbs } = useNavContext();
	const {
		activityIndicator,
		appOrg,
		setDevUser,
		setLoadApp,
		userHasAuthenticated,
		userInfo,
	} = useAppContext();

	const [anchorEl, setAnchorEl] = useState(null);

	const penultimateBrdCrmb = useMemo(
		() => {
			if (breadcrumbs?.length > 1) {
				return breadcrumbs[breadcrumbs.length - 2];
			}
			return null;
		},
		[breadcrumbs]
	);

	const handleBreadcrumbClick = useCallback(
		link => () => {
			history.push(link);
		},
		[history]
	);

	const handleLogoutClick = useCallback(
		() => {
			setAnchorEl(null);
			Auth.signOut().then(async () => {
				setDevUser(null);
				userHasAuthenticated(false);
				setLoadApp(false);

				await setAppStoreItem('DevUser', null);
			});
		},
		[setDevUser, setLoadApp, userHasAuthenticated]
	);

	const handleHomeClick = useCallback(
		() => {
			history.push('/');
		},
		[history]
	);

	return (
		<AppBar
			color="transparent"
			elevation={0}
			position="fixed"
			sx={{
				backgroundColor: 'background.paper',
				borderBottom: '0.1em solid',
				borderColor: 'divider',
				color: 'text.primary',
				height: '6.5rem',
			}}
		>
			<Toolbar
				sx={{
					height: '6.5rem',
					paddingLeft: '24px !important',
					paddingRight: '24px !important',
				}}
			>
				<Box>
					<Tooltip
						disableInteractive
						title={
							showMobile
								? `${
										swipeDrawerOpen ? 'Hide' : 'Show'
								  } Navigation`
								: `${
										drawerOpen ? 'Collapse' : 'Expand'
								  } Navigation`
						}
					>
						<IconButton
							aria-label="open drawer"
							color="inherit"
							onClick={() => {
								if (showMobile) {
									setSwipeDrawerOpen(prev => !prev);
								} else {
									setDrawerOpen(prev => {
										cookies.set(
											'drawer_open',
											prev ? 'false' : 'true',
											{ path: '/' }
										);
										return !prev;
									});
								}
							}}
							sx={{
								marginLeft: '-15px',
								marginRight: '12px',
								padding: '7px',
							}}
						>
							<MenuIcon />
						</IconButton>
					</Tooltip>
				</Box>

				{/* Beacon Tech logo */}
				<Box
					style={{
						display: 'flex',
						flexGrow: 1,
						justifyContent: 'center',
						// padding: '0 1em',
					}}
				>
					<HeadingButton
						label={''}
						onClick={handleHomeClick}
						showLabel={false}
					>
						<img src={logo} alt="BT Logo" />
					</HeadingButton>
				</Box>

				{/* Breadcrumbs */}
				{breadcrumbs &&
					!showMobile && (
						<Container
							aria-label="breadcrumb"
							sx={{
								...styleSection,
								margin: 0,
								maxWidth: { sm: '4000px' },
							}}
						>
							{screen_downMd && penultimateBrdCrmb ? (
								<Typography
									sx={{
										alignContent: 'center',
										display: 'flex',
										overflow: 'hidden',
										whiteSpace: 'nowrap',

										'&:hover': {
											color: 'primary.shade_light',
											cursor: 'pointer',
											textDecoration: 'underline',
										},
									}}
									color={'inherit'}
									onClick={handleBreadcrumbClick(
										penultimateBrdCrmb.link
									)}
									variant={'body1'}
								>
									<ArrowBackIcon
										style={{
											marginRight: 5,
										}}
									/>
									{penultimateBrdCrmb.text}
								</Typography>
							) : (
								<Box>
									{breadcrumbs.map(
										({ text, link }, index) => (
											<Box
												key={index}
												sx={{
													display: 'inline',
													padding: 0,
												}}
											>
												{link && (
													<>
														<Typography
															color={'inherit'}
															onClick={handleBreadcrumbClick(
																link
															)}
															sx={{
																display:
																	'inline',

																'&:hover': {
																	color:
																		'primary.shade_light',
																	cursor:
																		'pointer',
																	textDecoration:
																		'underline',
																},
															}}
															variant={'body1'}
														>
															{text}
														</Typography>
														<ChevronRightIcon
															style={{
																height: 20,
																transform:
																	'translateY(5px)',
																width: 20,
															}}
														/>
													</>
												)}
												{!link && (
													<Typography
														sx={{
															display: 'inline',
														}}
														color="inherit"
														variant="body1"
													>
														{text}
													</Typography>
												)}
											</Box>
										)
									)}
								</Box>
							)}
						</Container>
					)}

				{localDevMode && (
					<Container
						aria-label="breadcrumb"
						sx={{
							...styleSection,
							margin: 0,
							maxWidth: { sm: '400px' },
						}}
					/>
				)}

				{/* Heading Buttons Container */}
				{!showMobile && (
					<Box style={{ ...styleSection, marginLeft: 'auto' }}>
						{/* Organisation */}
						<HeadingButton
							label={appOrg ? appOrg.name : 'Organisation'}
							onClick={() => setOrgSelectDialog(true)}
							showLabel={!showMobile}
						>
							<BtOrgLogo
								initials={appOrg.initials}
								logo={appOrg.logo32}
								name={appOrg.name}
							/>
						</HeadingButton>

						{/* User */}
						<HeadingButton
							onClick={event => setAnchorEl(event.currentTarget)}
							showLabel={!showMobile}
						>
							<BtOrgLogo
								initials={userInfo.initials}
								logo={userInfo.logo32}
								name={userInfo.user_name}
							/>
						</HeadingButton>

						<Menu
							id="simple-menu"
							anchorEl={anchorEl}
							anchorOrigin={{
								horizontal: 'center',
								vertical: 'bottom',
							}}
							keepMounted
							onClose={() => setAnchorEl(null)}
							open={Boolean(anchorEl)}
							transformOrigin={{
								horizontal: 'center',
								vertical: 'top',
							}}
						>
							<div style={{ minWidth: 160 }}>
								<MenuItem
									onClick={() => {
										setAnchorEl(null);
										history.push('/UserSettings');
									}}
								>
									Settings
								</MenuItem>
								<MenuItem onClick={handleLogoutClick}>
									Logout
								</MenuItem>
							</div>
						</Menu>
					</Box>
				)}

				{/* Notifications */}
				<div style={{ transform: 'translateX(10px)' }}>
					<Tooltip title="No New Notifications">
						<IconButton>
							<NotificationsIcon />
						</IconButton>
					</Tooltip>
				</div>
			</Toolbar>
			{activityIndicator && (
				<LinearProgress
					style={{ position: 'absolute', width: '100vw', bottom: -4 }}
				/>
			)}
		</AppBar>
	);
}

HeadingButton.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	Icon: PropTypes.elementType,
	label: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	showLabel: PropTypes.bool,
};

BtAppBar.propTypes = {
	drawerOpen: PropTypes.bool.isRequired,
	setDrawerOpen: PropTypes.func.isRequired,
	swipeDrawerOpen: PropTypes.bool.isRequired,
	setSwipeDrawerOpen: PropTypes.func.isRequired,
	setOrgSelectDialog: PropTypes.func.isRequired,
};
