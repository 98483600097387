import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Controller, useFormContext } from 'react-hook-form';
import {
	FormControl,
	FormHelperText,
	InputLabel,
	Skeleton,
} from '@mui/material';
import { v4 as uuid } from 'uuid';

import useFormElRequired from '../../../hooks/useFormElRequired';
import BtDataConfigNodeSelect from '../DataConfigNodeSelect/BtDataConfigNodeSelect';

export default function BtFormDataConfigNodeSelect({
	disabled,
	fetching,
	isRequired,
	configSchema,
	label,
	name,
	helperText,
	...other
}) {
	const id = useMemo(() => uuid(), []);

	const {
		control,
		setValue,
		watch,
		loading,
		sending,
		validationSchema,
		getFieldState,
		//formState: { errors },
	} = useFormContext() || { formState: {} };
	const selectNodeValue = watch(name);

	const { error } = getFieldState(name);

	const required = useFormElRequired(isRequired, validationSchema, name);

	console.log({ selectNodeValue, configSchema });

	// const handleChange = useCallback(
	// 	value => {
	// 		setValue(name, value, {
	// 			shouldValidate: true,
	// 			shouldDirty: true,
	// 			shouldTouch: true,
	// 		});
	// 	},
	// 	[setValue, name]
	// );

	const isDisabled = useMemo(() => loading || sending || disabled, [
		loading,
		sending,
		disabled,
	]);

	if (loading) {
		return (
			<Skeleton animation="wave" style={{ width: '100%', height: 45 }} />
		);
	}

	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<BtDataConfigNodeSelect
					{...field}
					label={label}
					schema={configSchema}
					//onChange={handleChange}
					value={selectNodeValue}
					error={!!error}
					disabled={isDisabled}
					required={required}
					helperText={error ? error.message : helperText || null}
					{...other}
				/>
			)}
		/>
	);
}

BtFormDataConfigNodeSelect.propTypes = {
	disabled: PropTypes.bool,
	fetching: PropTypes.bool,
	fullWidth: PropTypes.bool,
	isRequired: PropTypes.bool,
	configSchema: PropTypes.object.isRequired,
	label: PropTypes.string,
	name: PropTypes.string.isRequired,
	helperText: PropTypes.node,
};
