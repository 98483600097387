import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Auth } from 'aws-amplify';
import { useAppContext } from '../../context/ContextManager';

// COMPONENTS
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
//import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ListTable, ListRow, ListHeader, ListCell } from '../generic/ListTable';

//import 'date-fns';
//import DateFnsUtils from '@date-io/date-fns';
/*import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';*/
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

const styles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(2),
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
	},
	dialogTitle: {
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.contrastText,
	},
	dialogContent: {
		minWidth: '600px',
		minHeight: '500px',
	},
	selectionText: {
		display: 'inline',
		borderBottom: '1px',
		fontSize: '1.5rem',
		lineHeight: '24px',
	},
	selectionDiv: {
		borderTop: '1px solid red',
		borderTopColor: theme.palette.primary.shade_dark,
		flexGrow: 1,
	},
	selectionDivRow: {
		cursor: 'pointer',
		borderBottom: '1px solid red',
		borderBottomColor: theme.palette.primary.shade_dark,
		'&:hover': {
			backgroundColor: theme.palette.primary.shade_light,
		},
	},
	selectionCheck: {
		margin: 5,
	},
	flexBox: {
		height: '300px',
	},
	textField: {
		backgroundColor: '#f5f5f5',
		margin: '0px 0',
		fontSize: '2rem',
	},
	deploySelect: {
		minWidth: '200px',
	},
	datePicker: {
		marginTop: 40,
	},
}));

function getSteps() {
	return [
		'Select Configuration',
		'Select Version',
		'How to deploy',
		'Confirm',
	];
}

export default function SelectConfigDialog({ setOpen, onClose, deviceInfo }) {
	const classes = styles();
	const [isLoading, setIsLoading] = useState(true);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [configList, setConfigList] = useState([]);
	const [configVersions, setConfigVersions] = useState([]);
	const [selectedConfig, setSelectedConfig] = useState('');
	const [selectedConfigUUID, setSelectedConfigUUID] = useState('');
	const [selectedVersion, setSelectedVersion] = useState('');
	const [selectedVersionUUID, setSelectedVersionUUID] = useState('');
	const [delpomentType, setDelpomentType] = useState('now');
	const [nextDisabled, setNextDisabled] = useState(true);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const { appOrg } = useAppContext();
	//const [newTaskUuid, setNewTaskUuid] = useState("");

	const [activeStep, setActiveStep] = useState(0);
	const steps = getSteps();

	const handleSelectConfigClose = () => {
		onClose();
	};
	const onConfigClick = (uuid, config) => event => {
		setSelectedConfig(config);
		setSelectedConfigUUID(uuid);
		setSelectedVersion('');
		setNextDisabled(false);
	};
	const onVersionClick = (uuid, version) => event => {
		setSelectedVersionUUID(uuid);
		setSelectedVersion(version);
		setNextDisabled(false);
	};
	const handleChangeDeploment = event => {
		setDelpomentType(event.target.value);
	};
	const handleDateChange = date => {
		setSelectedDate(date);
	};

	// This is called only when the dialog is opened
	const handleOnEnter = () => {
		var req_url =
			process.env.REACT_APP_API_SERVER_URL +
			'/v1/config?device_type=' +
			deviceInfo.type.uuid;

		Auth.currentSession().then(user_session => {
			let user_token = user_session.getAccessToken();
			fetch(req_url, {
				method: 'GET',
				headers: {
					Authorization: user_token.jwtToken,
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Organisation: appOrg.uuid,
				},
			})
				.then(res => res.json())
				.then(response => {
					setConfigList(response.config_list);
					setIsLoading(false);
				})
				.catch(error => {
					alert('Server Error!');
					//console.log(error);
					setIsLoading(false);
				});
		});
	};

	const handleCancel = () => {
		setActiveStep(0);
		onClose();
	};
	const handleBack = () => {
		setActiveStep(prevActiveStep => prevActiveStep - 1);
		setNextDisabled(false);
	};
	const handleReset = () => {
		setActiveStep(0);
		onClose();
	};
	const handleNext = () => {
		setActiveStep(prevActiveStep => prevActiveStep + 1);

		var current_step = activeStep + 1;

		switch (current_step) {
			case 1:
				setNextDisabled(true);
				setIsLoading(true);
				var req_url =
					process.env.REACT_APP_API_SERVER_URL +
					'/v1/config/history/' +
					selectedConfigUUID;

				Auth.currentSession().then(user_session => {
					let user_token = user_session.getAccessToken();
					fetch(req_url, {
						method: 'GET',
						headers: {
							Authorization: user_token.jwtToken,
							Accept: 'application/json',
							'Content-Type': 'application/json',
							Organisation: appOrg.uuid,
						},
					})
						.then(res => res.json())
						.then(response => {
							response.config_data.versions.sort(
								(a, b) =>
									a.version > b.version
										? -1
										: b.version > a.version
											? 1
											: 0
							);
							setConfigVersions(response.config_data);
							setIsLoading(false);
						})
						.catch(error => {
							//console.log(error);
							alert('Server Error!');
						});
				});
				break;

			case 4:
				submitTask();
				break;

			default:
				break;
		}
	};

	const getDeployContent = type => {
		switch (type) {
			case 'now':
				return (
					<Typography variant="subtitle1">
						The new configuration will be deployed to the device as
						soon as possible.
					</Typography>
				);
			case 'time_delay':
				return (
					<Grid container direction="column" alignItems="center">
						<Grid item>
							<Typography variant="subtitle1">
								The new configuration will be deployed the next
								time the device connects after the time.
							</Typography>
						</Grid>
						<Grid item>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DatePicker
									label="Deployment Date Time"
									format="MM/dd/yyyy HH:mm:ss"
									value={selectedDate}
									onChange={handleDateChange}
									className={classes.datePicker}
								/>
							</LocalizationProvider>
						</Grid>
					</Grid>
				);
			case 'event_delay':
				return <div>event delay</div>;
			default:
				return <div>Type Error</div>;
		}
	};

	const getStepContent = step => {
		switch (step) {
			case 0:
				return (
					<div className={classes.selectionDiv}>
						{configList.map((config, index) => (
							<Grid
								container
								direction="row"
								alignItems="center"
								className={classes.selectionDivRow}
								onClick={onConfigClick(
									config.config_uuid,
									config.name
								)}
								key={index}
							>
								{selectedConfig !== config.name && (
									<Grid item xs={1}>
										<RadioButtonUncheckedIcon
											className={classes.selectionCheck}
										/>
									</Grid>
								)}
								{selectedConfig === config.name && (
									<Grid item xs={1}>
										<RadioButtonCheckedIcon
											className={classes.selectionCheck}
											color="primary"
										/>
									</Grid>
								)}
								<Grid item xs={11}>
									<Typography
										variant="h6"
										className={classes.selectionText}
									>
										{config.name}
									</Typography>
								</Grid>
							</Grid>
						))}
					</div>
				);
			case 1:
				return (
					<div className={classes.selectionDiv}>
						{configVersions.versions.map((config, index) => (
							<Grid
								container
								direction="row"
								alignItems="center"
								className={classes.selectionDivRow}
								onClick={onVersionClick(
									config.uuid,
									config.version
								)}
								key={index}
							>
								{selectedVersion !== config.version && (
									<Grid item xs={1}>
										<RadioButtonUncheckedIcon
											className={classes.selectionCheck}
										/>
									</Grid>
								)}
								{selectedVersion === config.version && (
									<Grid item xs={1}>
										<RadioButtonCheckedIcon
											className={classes.selectionCheck}
											color="primary"
										/>
									</Grid>
								)}
								<Grid item xs={11}>
									<Typography
										variant="h6"
										className={classes.selectionText}
									>
										{configVersions.name}:{config.version}
									</Typography>
								</Grid>
							</Grid>
						))}
					</div>
				);
			case 2:
				return (
					<div>
						<Grid
							container
							direction="row"
							alignItems="center"
							spacing={2}
						>
							<Grid item>
								<Typography variant="h6">
									Deployment Type:
								</Typography>
							</Grid>
							<Grid item>
								<FormControl
									variant="outlined"
									className={classes.formControl}
								>
									<Select
										labelId="demo-simple-select-outlined-label"
										id="demo-simple-select-outlined"
										value={delpomentType}
										onChange={handleChangeDeploment}
										className={classes.deploySelect}
									>
										<MenuItem value={'now'}>
											Update Now
										</MenuItem>
										<MenuItem value={'time_delay'}>
											Update on Time
										</MenuItem>
										<MenuItem value={'event_delay'}>
											Update on Event
										</MenuItem>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
						<Divider />
						{getDeployContent(delpomentType)}
					</div>
				);
			case 3:
				return (
					<div>
						<Typography>
							Confirm you are happy to deploy the configuration.
						</Typography>
						<Divider />
						<ListTable>
							<ListRow>
								<ListHeader>Configuration:</ListHeader>
								<ListCell>{selectedConfig}</ListCell>
							</ListRow>
							<ListRow>
								<ListHeader>Version:</ListHeader>
								<ListCell>{selectedVersion}</ListCell>
							</ListRow>
							<ListRow>
								<ListHeader>Deployment Type:</ListHeader>
								<ListCell>
									{delpomentType === 'now' && <>Deploy now</>}
									{delpomentType === 'time_delay' && (
										<>
											Deploy at{' '}
											{selectedDate.toLocaleString()}
										</>
									)}
									{delpomentType === 'event_delay' && (
										<>Deploy at event Restart</>
									)}
								</ListCell>
							</ListRow>
							<ListRow>
								<ListHeader>Config Version UUID:</ListHeader>
								<ListCell>{selectedVersionUUID}</ListCell>
							</ListRow>
						</ListTable>
					</div>
				);
			default:
				return <Typography>Unknown step</Typography>;
		}
	};

	const submitTask = () => {
		setIsSubmitting(true);

		var device_update_str = {};
		device_update_str['config'] = selectedVersionUUID;
		console.log(device_update_str);
		var req_url =
			process.env.REACT_APP_API_SERVER_URL +
			'/v1/device/config/' +
			deviceInfo.uid;

		Auth.currentSession().then(user_session => {
			let user_token = user_session.getAccessToken();
			fetch(req_url, {
				method: 'PUT',
				headers: {
					Authorization: user_token.jwtToken,
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Organisation: appOrg.uuid,
				},
				body: JSON.stringify(device_update_str),
			})
				.then(res => res.json())
				.then(response => {
					if (response['result'] !== 'OK') {
						alert('Failed to set config');
					}
					setIsLoading(false);
					setIsSubmitting(false);
				})
				.catch(error => {
					console.log(error);
					setIsSubmitting(false);
				});
		});
	};

	return (
		<>
			<Dialog
				open={setOpen}
				onClose={handleSelectConfigClose}
				className={classes.root}
				TransitionProps={{
					onEnter: handleOnEnter,
				}}
			>
				<DialogTitle
					id="form-dialog-title"
					className={classes.dialogTitle}
				>
					Select Device Configuration
				</DialogTitle>
				<DialogContent className={classes.dialogContent}>
					{isLoading && (
						<>
							<p>Loading...</p>
							<CircularProgress size={'120px'} />
						</>
					)}

					{!isLoading && (
						<>
							<Stepper activeStep={activeStep}>
								{steps.map((label, index) => {
									const stepProps = {};
									const labelProps = {};

									return (
										<Step key={label} {...stepProps}>
											<StepLabel {...labelProps}>
												{label}
											</StepLabel>
										</Step>
									);
								})}
							</Stepper>

							<Divider />

							{activeStep === steps.length ? (
								<div>
									{isSubmitting && (
										<>
											<p>Loading...</p>
											<CircularProgress size={'120px'} />
										</>
									)}

									{!isSubmitting && (
										<Box
											display="flex"
											flexDirection="column"
										>
											<Box
												flexGrow={1}
												className={classes.flexBox}
											>
												<Typography
													className={classes.steps}
												>
													New config deployed.
												</Typography>
											</Box>
											<Box>
												<Divider />
												<Box
													display="flex"
													flexDirection="row-reverse"
												>
													<Button
														variant="outlined"
														startIcon={
															<CloseIcon />
														}
														onClick={handleReset}
														className={
															classes.button
														}
													>
														Close
													</Button>
												</Box>
											</Box>
										</Box>
									)}
								</div>
							) : (
								<Box display="flex" flexDirection="column">
									<Box
										flexGrow={1}
										className={classes.flexBox}
									>
										{getStepContent(activeStep)}
									</Box>
									<Box>
										<Divider />
										<Box
											display="flex"
											flexDirection="row-reverse"
										>
											<Button
												variant="outlined"
												startIcon={<ArrowForwardIcon />}
												disabled={nextDisabled}
												onClick={handleNext}
												className={classes.button}
											>
												{activeStep === steps.length - 1
													? 'Deploy'
													: 'Next'}
											</Button>

											<Button
												variant="outlined"
												startIcon={<ArrowBackIcon />}
												disabled={activeStep === 0}
												onClick={handleBack}
												className={classes.button}
											>
												Back
											</Button>

											<Button
												variant="outlined"
												startIcon={<CloseIcon />}
												onClick={handleCancel}
												className={classes.button}
											>
												Cancel
											</Button>
										</Box>
									</Box>
								</Box>
							)}
						</>
					)}
				</DialogContent>
			</Dialog>
		</>
	);
}
