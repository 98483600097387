import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	CircularProgress,
	Container,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
} from '@mui/material';
import CreateIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import BtDialog from '../../components/generic/BtDialog';
import {
	BtForm,
	BtFormActionsContainer,
	BtFormContent,
	BtFormTextField,
	withFormContextMethods,
} from '../../components/generic/forms';
import { platformAdminOrganisationCreate } from '../../API';

const blankDefaults = {
	name: '',
	description: '',
};

const ActionButtons = withFormContextMethods(
	({ formState: { isDirty }, onClose, sending }) => {
		return (
			<>
				<Button variant="outlined" onClick={onClose} disabled={sending}>
					Cancel
				</Button>
				<Button
					variant="contained"
					disabled={!isDirty || sending}
					type="submit"
					startIcon={
						sending ? (
							<CircularProgress size={20} />
						) : (
							<CreateIcon />
						)
					}
					disableElevation
				>
					Create Organisation
				</Button>
			</>
		);
	}
);

export default function NewOrganisationDialog({ onClose, open }) {
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();

	const [sending, setSending] = useState(false);

	const schema = useMemo(
		() =>
			yup.object({
				name: yup
					.string()
					.min(3)
					.required(),
			}),
		[]
	);

	const onSubmit = useCallback(
		async (values, reset) => {
			setSending(true);
			try {
				const { uuid } = await platformAdminOrganisationCreate({
					newOrgName: values.name,
				});
				reset(values);
				enqueueSnackbar('Created Organisation', {
					variant: 'success',
				});
				history.push(`/BeaconAdmin/Organisation/${uuid}`);
			} catch (error) {
				setSending(false);
				enqueueSnackbar('Failed to create organisation', {
					variant: 'error',
				});
			}
		},
		[enqueueSnackbar, history]
	);

	return (
		<BtDialog open={open} onClose={onClose} minwidth="sm" fullWidth>
			{sending && (
				<LinearProgress
					style={{
						position: 'absolute',
						top: 0,
						width: '100%',
					}}
				/>
			)}
			<DialogTitle>Create New Organisation</DialogTitle>
			<BtForm
				onSubmit={onSubmit}
				sending={sending}
				validationSchema={schema}
				defaultValues={blankDefaults}
			>
				<DialogContent>
					<Container maxWidth="xs">
						<BtFormContent>
							<BtFormTextField
								name="name"
								label="Organisation Name"
								autoFocus
							/>
						</BtFormContent>
					</Container>
				</DialogContent>
				<DialogActions>
					<BtFormActionsContainer>
						<ActionButtons onClose={onClose} sending={sending} />
					</BtFormActionsContainer>
				</DialogActions>
			</BtForm>
		</BtDialog>
	);
}

ActionButtons.propTypes = {
	isDirty: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	sending: PropTypes.bool.isRequired,
};

NewOrganisationDialog.propTypes = {
	existingNames: PropTypes.arrayOf(PropTypes.string),
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};
