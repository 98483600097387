export const ARRAY = 'array';
export const BOOLEAN = 'boolean';
export const DATE = 'date';
export const NUMBER = 'number';
export const OBJECT = 'object';
export const STRING = 'string';

export const NULL = 'null';
export const UNDEFINED = 'undefined';

export const nonNilTypes = [ARRAY, BOOLEAN, DATE, NUMBER, OBJECT, STRING];
