const {
	ERROR,
	setOperator,
	evaluateExpression,
	OPERATION_MODE,
} = require('../../expression.js');

const operatorKey = '$avg';

setOperator(
	operatorKey,
	/**
	 * @type {ExpressionAccumulatorFunction<number , number>}
	 */
	(context, args, options) => {
		const arr = !Array.isArray(args) ? [args] : args;

		if (arr.length !== 1) {
			throw new Error(ERROR.INVALID_NUMBER_ARGS(operatorKey, 1, 1));
		}

		const arg = arr[0];

		const acc = context.fieldValues[0] || { total: 0, count: 0 };

		const value =
			evaluateExpression(
				{ ...context, operationMode: OPERATION_MODE.AGGREGATE },
				arg
			) ?? null;

		if (typeof value !== 'number') {
			return context.fieldValues.length === 0
				? null
				: acc.total / acc.count;
		}

		acc.total += value;
		acc.count++;

		context.fieldValues[0] = acc;

		return acc.total / acc.count;
	},
	[OPERATION_MODE.ACCUMULATE]
);
