import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import {
	BtFormColourPicker,
	BtFormLateralContainer,
	BtFormSelect,
	BtFormTextField,
	withFormContextMethods,
} from '../../../../../../../components/generic/forms';
import {
	Box,
	Button,
	Checkbox,
	Chip,
	FormControlLabel,
	Typography,
	useTheme,
} from '@mui/material';

import { useWatch } from 'react-hook-form';
import { InputController, resolveVisOptionsSchema } from './InputController';
import { gaugeSchema } from '../../../../../../../API/validations/insightVisualizationsValidation';
import { useEditFormContext } from '../../EditFormContext';
import { VisOptionsFormSectionWrapper } from './VisOptionsFormSectionWrapper';

const setValueOptions = { shouldDirty: true, shouldTouch: true };

const AxisBoundsComponent = withFormContextMethods(
	({ getValues, rootPropertyName, setValue }) => {
		const propertyNames = useMemo(
			() => ({
				min: `${rootPropertyName}.axisBounds.min`,
				max: `${rootPropertyName}.axisBounds.max`,
			}),
			[rootPropertyName]
		);
		const min = getValues(propertyNames.min);
		const max = getValues(propertyNames.max);
		const [minAuto, setMinAuto] = useState(min === 'auto');
		const [maxAuto, setMaxAuto] = useState(max === 'auto');

		const handleChangeMinAuto = event => {
			setMinAuto(event.target.checked);
			// console.log('should set value', event.target.checked);
			setValue(
				propertyNames.min,
				event.target.checked ? 'auto' : 0,
				setValueOptions
			);
		};

		const handleChangeMaxAuto = event => {
			setMaxAuto(event.target.checked);
			setValue(
				propertyNames.max,
				event.target.checked ? 'auto' : 0,
				setValueOptions
			);
		};

		return (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: '10px',
				}}
			>
				<BtFormLateralContainer>
					<BtFormTextField
						disabled={max === 'auto'}
						name={propertyNames.max}
						label="Axis bound max"
						type={max === 'auto' ? 'text' : 'number'}
					/>
					<FormControlLabel
						label="Auto"
						control={
							<Checkbox
								checked={maxAuto}
								onChange={handleChangeMaxAuto}
								inputProps={{
									'aria-label': 'axis-bound-max-auto',
								}}
							/>
						}
					/>
				</BtFormLateralContainer>
				<BtFormLateralContainer>
					<BtFormTextField
						disabled={min === 'auto'}
						name={propertyNames.min}
						label="Axis bound min"
						type={min === 'auto' ? 'text' : 'number'}
					/>
					<FormControlLabel
						label="Auto"
						control={
							<Checkbox
								checked={minAuto}
								onChange={handleChangeMinAuto}
								inputProps={{
									'aria-label': 'axis-bound-min-auto',
								}}
							/>
						}
					/>
				</BtFormLateralContainer>
			</Box>
		);
	}
);

const AxisRangesComponent = withFormContextMethods(({ axis, setValue }) => {
	const rootPropertyName = `vis_options.${axis}.axis_ranges`;
	const axisRanges = useWatch({ name: rootPropertyName });
	const { palette } = useEditFormContext();
	const theme = useTheme();

	console.log({ axisRanges });

	const handleAdd = () => {
		const newRange = {};
		if (axisRanges) {
			newRange.value = axisRanges[axisRanges.length - 1].value + 1;
			newRange.colour = axisRanges[axisRanges.length - 1].colour;
		} else {
			newRange.value = 1;
			newRange.colour = palette[1];
		}

		let newRanges;
		if (axisRanges) {
			newRanges = [...axisRanges, newRange];
		} else {
			newRanges = [newRange];
		}
		setValue(rootPropertyName, newRanges, {
			shouldDirty: true,
			shouldTouch: true,
		});
	};

	const colourItems = palette.map(colour => ({
		label: (
			<Box
				sx={{
					height: '20px',
					width: '60px',
					borderRadius: '10px',
					backgroundColor: colour,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					paddingRight: '5px',
					paddingLeft: '5px',
				}}
			>
				<Typography
					sx={{
						color: theme.palette.getContrastText(colour),
					}}
					variant="caption"
				>
					{colour}
				</Typography>
			</Box>
		),
		value: colour,
	}));

	console.log(colourItems);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '10px',
			}}
		>
			{axisRanges ? (
				axisRanges.map((range, index) => (
					// <Typography key={value}>{value}</Typography>
					// <Box
					// 	sx={{
					// 		display: 'flex',
					// 		justifyContent: 'space-between',
					// 		gap: 10,
					// 	}}
					// 	key={index}
					// >
					<BtFormLateralContainer key={index}>
						{/* <FormInputs index={index} /> */}
						<BtFormTextField
							// style={{ width: '50%' }}
							fullWidth
							type="number"
							name={`${rootPropertyName}.${index}.value`}
							label="Value"
						/>
						<BtFormSelect
							fullWidth
							items={colourItems}
							name={`${rootPropertyName}.${index}.colour`}
							label="Colour"
						/>
						{/* <BtFormColourPicker
							// style={{ width: '50%' }}
							name={`${rootPropertyName}.${index}.colour`}
							label="Colour"
						/> */}
					</BtFormLateralContainer>
					// </Box>
				))
			) : (
				<Typography>no ranges defined</Typography>
			)}
			<Button onClick={handleAdd} disableElevation variant="contained">
				Add range
			</Button>
		</Box>
	);
});

const VIS_OPTIONS = 'vis_options';
const VALUE_PRIMARY_AXIS = 'valuePrimaryAxis';
const VALUE_SECONDARY_AXIS = 'valueSecondaryAxis';

const ValueAxisForm = ({ axis }) => {
	const type = useWatch({ name: 'type' });
	const axisFields = useMemo(
		() => resolveVisOptionsSchema(type)[axis].fields,
		[axis, type]
	);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '10px',
			}}
		>
			{Object.keys(axisFields).map((fieldKey, index) => {
				if (fieldKey === 'axisBounds') {
					return (
						<AxisBoundsComponent
							key={`${fieldKey}-${index}`}
							rootPropertyName={`${VIS_OPTIONS}.${axis}`}
						/>
					);
				}
				if (fieldKey === 'axis_ranges') {
					return (
						<VisOptionsFormSectionWrapper
							key={`${fieldKey}-${index}`}
							title={fieldKey}
						>
							<AxisRangesComponent axis={axis} />
						</VisOptionsFormSectionWrapper>
					);
				}
				return (
					<InputController
						key={fieldKey}
						label={fieldKey}
						name={`${VIS_OPTIONS}.${axis}.${fieldKey}`}
						propDetails={{
							type: axisFields[fieldKey].type,
							oneOf: axisFields[fieldKey].oneOf,
						}}
					/>
				);
			})}
		</Box>
	);
};

ValueAxisForm.propTypes = {
	axis: PropTypes.oneOf([VALUE_PRIMARY_AXIS, VALUE_SECONDARY_AXIS]),
};

export { ValueAxisForm, VALUE_PRIMARY_AXIS, VALUE_SECONDARY_AXIS };
