import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useAppContext, useNavContext } from '../../../context/ContextManager';
// import { useInsightCollectionEditContext } from '../Insight/InsightContext';
import { useHistory } from 'react-router-dom';

import { v1 as uuidv1 } from 'uuid';
import _, { update } from 'lodash';

import {
	insightCollectionDraftUpdate,
	insightCollectionGet,
} from '../../../API';
import { commonRangesData } from '../../../utils/TimeControl/TimeRangeUtilConstants';
import { buildTimeRangeObject } from '../../../utils/TimeControl/TimeRangeUtilFunctions';
import { useDataProviderContext } from '../../../context/DataProvider/DataProvider';

// import {
// 	a11yProps,
// 	BtTab,
// 	BtTabBar,
// 	BtTabPanel,
// } from '../../../components/generic/BtTabView';

import Typography from '@mui/material/Typography';
import BtLoading from '../../../components/generic/BtLoading';
import BtError from '../../../components/generic/BtError';
import InsightPage from '../InsightPage/InsightPage';

// Data for landing page selection table and contextual navigation
import insights_options from '../InsightsOptions';
import insights_breadcrumbs from '../InsightsBreadcrumbs';
import insights_nav_item from '../InsightsNavItem';
// import 'react-grid-layout/css/styles.css';
// import 'react-resizable/css/styles.css';
import { useTheme } from '@mui/styles';
import { BtTabs } from '../../../components/generic/tabs/BtTabs';
import { useParams } from 'react-router';
import { useLocation } from 'react-router';
import { InsightFilters } from './InsightFilters';
import {
	evalDraft,
	getInsightCollection,
	mapTabs,
	seedNewPageProps,
	stripTabProps,
	updatePagesArray,
} from './utils';
import EditControls from '../Components/EditComponents/EditControls';
import useInsightPaletteConfig from '../Components/VisComponents/hooks/useInsightPaletteConfig';
import { Container, Box } from '@mui/material';

export default function InsightCollection() {
	const [currentPage, setCurrentPage] = useState('1');
	const { id, version } = useParams();
	const location = useLocation();
	const { setBreadcrumbs, setContextualNav } = useNavContext();
	const [insightLoading, setInsightLoading] = useState(true);
	const [insightError, setInsightError] = useState(false);
	const [insightCollection, setInsightCollection] = useState(null);
	const [dataSources, setDataSources] = useState([]);
	// const [tabs, setTabs] = useState(null);
	const history = useHistory();
	const {
		addDataSource,
		removeDataSources,
		setTimeRange,
		setDataFilters,
	} = useDataProviderContext();

	const {
		loading: paletteLoading,
		error: paletteError,
		palette,
		collectionPalettes,
	} = useInsightPaletteConfig();

	const editing = useMemo(() => evalDraft(location), [location]);

	const currentPageData = useMemo(
		() => {
			const properties = {
				name: '',
				description: '',
			};
			if (insightCollection && insightCollection.pages.length > 0) {
				properties.name = insightCollection.pages[currentPage - 1].name;
				properties.description =
					insightCollection.pages[currentPage - 1].description;
			}
			return properties;
		},
		[currentPage, insightCollection]
	);

	useEffect(() => console.log(palette, collectionPalettes), [
		palette,
		collectionPalettes,
	]);

	// const handleFilterChange = useCallback(
	// 	newFilters => {
	// 		setInsightFilters(newFilters);
	// 	},
	// 	[setInsightFilters]
	// );

	// const handlePageUpdate = useCallback(
	// 	(uuid, pageUpdate) => console.log(uuid, pageUpdate),
	// 	[]
	// );

	const refreshCollectionData = useCallback(
		async () => {
			try {
				let collection;
				collection = await getInsightCollection(id, location, version);
				// console.log('got new data: ', collection);
				setInsightCollection(collection);
				// setTabs(mapTabs(collection.pages, collection.visualisations));
			} catch (error) {
				console.log(error);
				setInsightError(true);
			}
		},
		[id, location, version]
	);

	const updateDraft = useCallback(
		async update => {
			try {
				await insightCollectionDraftUpdate({
					insightUuid: id,
					draftUpdate: update,
				});
				refreshCollectionData();
			} catch (error) {
				console.log(error);
				refreshCollectionData();
			}
		},
		[id, refreshCollectionData]
	);

	const handlePageUpdate = useCallback(
		async (pageUpdate, visualisationsUpdate) => {
			const pagesUpdate = updatePagesArray(
				insightCollection.pages,
				pageUpdate,
				currentPage
			);
			// console.log({ pagesUpdate, pageUpdate, visualisationsUpdate });
			const payload = {};
			payload.pages = pagesUpdate;
			if (visualisationsUpdate) {
				payload.visualisations = visualisationsUpdate;
			}
			await updateDraft(payload);
		},
		[currentPage, insightCollection, updateDraft]
	);

	useEffect(() => console.log({ insightCollection }));

	const noPages = useMemo(
		() => {
			if (insightCollection && !insightCollection.pages.length > 0) {
				return true;
			} else {
				return false;
			}
		},
		[insightCollection]
	);

	const tabs = useMemo(
		() => {
			if (insightCollection && palette) {
				if (insightCollection.pages.length > 0) {
					return mapTabs(
						insightCollection.pages,
						insightCollection.visualisations,
						handlePageUpdate,
						editing,
						palette,
						refreshCollectionData,
						collectionPalettes,
						insightCollection.palette_config
					);
				} else {
					return [
						{
							label: 'No pages in collection',
							content: (
								<Container
									maxWidth="sm"
									sx={{
										// paddingTop: '20px',
										height: '500px',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											gap: '20px',
										}}
									>
										<Typography variant="h3">
											This collection has no pages
										</Typography>
										<Typography variant="body">
											Start building this collection by
											adding a page with the controls
											bottom right
										</Typography>
									</Box>
								</Container>
							),
						},
					];
				}
			} else {
				return [];
			}
		},
		[
			editing,
			handlePageUpdate,
			insightCollection,
			palette,
			refreshCollectionData,
		]
	);

	// console.log('====>>> InsightCollection Render <<<====');
	// Set breadcrumbs
	useEffect(
		() => {
			if (insightCollection) {
				setBreadcrumbs([
					...insights_breadcrumbs,
					{ text: 'Collections', link: '/InsightCollections' },
					{ text: insightCollection.name, link: '' },
				]);
			} else {
				setBreadcrumbs([
					...insights_breadcrumbs,
					{ text: 'Collections', link: '/InsightCollections' },
				]);
			}
		},
		[setBreadcrumbs, insightCollection]
	);

	const handleDataSourceUpdate = (
		dataSourceUuid,
		data,
		timeRange,
		filterOptions,
		filters
	) => {
		// TODO: the following is using 'dataSourceUuid' incorrectly, it's setting a prop called dataSourceUuid not the UUID
		// insightContextRef.current.dataSources.dataSourceUuid = {
		// 	timeRange: timeRange,
		// 	filterOptions: filterOptions,
		// 	filters: filters,
		// };
	};

	// const updateInsightCollection = async updatedCollection => {
	// 	setInsightCollection(updatedCollection);

	// 	await insightCollectionDraftUpdate({
	// 		insightUuid: id,
	// 		draftUpdate: updatedCollection,
	// 	});
	// };

	useEffect(
		() => {
			const loadCollection = async () => {
				// console.log('INSIGHT COLLECTION LOADING');

				// console.log(version);
				setInsightLoading(true);
				try {
					let collection;

					collection = await getInsightCollection(
						id,
						location,
						version
					);

					setInsightCollection(collection);
					// setTabs(
					// 	mapTabs(collection.pages, collection.visualisations)
					// );

					const timeRangeConfig = _.find(commonRangesData, {
						id: collection.initialTimeRange?.value,
					});
					const defaultTimeRange = await buildTimeRangeObject({
						...timeRangeConfig,
					});

					// setContextDefaultTimeRange(defaultTimeRange);

					// console.log('DATA SORUCES', collection.data_sources);

					// Store the data_sources in locla state to be used for removing
					setDataSources(collection.datasources);

					for (const dataSource of collection.datasources) {
						// Add the Data View instance to the DataProvider
						const dataView = await addDataSource(
							dataSource.uuid,
							dataSource.dataView,
							defaultTimeRange,
							dataSource.predefinedFilters,
							dataSource.filterDependencies,
							dataSource.transforms,
							handleDataSourceUpdate
						);
					}
					setInsightLoading(false);
				} catch (error) {
					console.log(error);
					setInsightLoading(false);
					setInsightError(true);
				}
			};

			loadCollection();

			return () => {
				// console.log('Send unsub');
				removeDataSources(dataSources);
			};
		},
		[id]
	);

	// set Contextual navigation items
	useEffect(
		() => {
			setContextualNav([...insights_nav_item, ...insights_options]);
			return () => {
				setContextualNav(null);
			};
		},
		[setContextualNav]
	);

	const onSettingsClick = () => {
		//setShowEditDialog(true);
		history.push(`/InsightCollection/${id}/Settings`);
	};

	// const handleAddNewPage = () => {
	// 	const newPage = {
	// 		uuid: uuidv1(),
	// 		name: 'New Page',
	// 		description: 'A new page',
	// 		filter_default: '',
	// 		time_key: '',
	// 		layouts: {
	// 			desktop: [],
	// 			tablet: [],
	// 			mobile: [],
	// 		},
	// 	};
	// 	const updatedPages = [...insightCollection.pages, newPage];

	// 	updateInsightCollection({ ...insightCollection, pages: updatedPages });
	// };

	// const handlePageDelete = uuid => {
	// 	const updatedPages = [...insightCollection.pages].filter(
	// 		page => page.uuid !== uuid
	// 	);

	// 	updateInsightCollection({ ...insightCollection, pages: updatedPages });
	// };

	// const handlePagePositionChange = (currentPosition, newPosition) => {
	// 	if (currentPosition !== newPosition) {
	// 		var updatedPages = [...insightCollection.pages];
	// 		updatedPages.splice(
	// 			newPosition,
	// 			0,
	// 			...updatedPages.splice(currentPosition, 1)
	// 		);
	// 		updateInsightCollection({
	// 			...insightCollection,
	// 			pages: updatedPages,
	// 		});
	// 	}
	// };

	const handlePagePositionChange = useCallback(
		async update => {
			const pagesUpdate = stripTabProps(update);
			await updateDraft({ pages: pagesUpdate });
		},
		[updateDraft]
	);

	const handlePagePropertiesUpdate = useCallback(
		async update => {
			const pagesUpdate = updatePagesArray(
				insightCollection.pages,
				update,
				currentPage
			);
			await updateDraft({ pages: pagesUpdate });
		},
		[currentPage, insightCollection, updateDraft]
	);

	const handlePageDelete = useCallback(
		async pageUuid => {
			const pagesUpdate = [...insightCollection.pages].filter(
				({ uuid }) => uuid !== pageUuid
			);

			await updateDraft({ pages: pagesUpdate });
		},
		[insightCollection, updateDraft]
	);

	const handleNewPage = useCallback(
		async (name, description) => {
			const newPage = seedNewPageProps(name, description);
			await updateDraft({ pages: [...insightCollection.pages, newPage] });
		},
		[insightCollection, updateDraft]
	);

	const onDragStart = type => event => {
		// This is a hack for firefox
		// Firefox requires some kind of initialization which we can do by adding this attribute @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
		event.dataTransfer.setData('dragData', type);
		console.log('onDragStart');
	};

	// const handleFilterChange = useCallback(
	// 	newFilters => {
	// 		setInsightFilters(newFilters);
	// 	},
	// 	[setInsightFilters]
	// );

	if (insightError || paletteError) {
		return (
			<BtError
				title="Retrieval Error"
				description={
					<>
						<Typography>
							An error occurred whilst trying to retrieve the
							Insight Collection.
						</Typography>
						<Typography>Please try again.</Typography>
					</>
				}
			/>
		);
	}

	return (
		<BtLoading loading={insightLoading || paletteError}>
			{insightCollection &&
				!insightLoading && (
					<>
						<InsightFilters insightCollection={insightCollection} />

						<BtTabs
							autoComplete
							currentTab={currentPage}
							draggable={editing}
							onChangeCurrentTab={setCurrentPage}
							onChangeOrder={handlePagePositionChange}
							tabs={tabs}
						/>
					</>
				)}
			{editing && (
				<EditControls
					onAddPage={handleNewPage}
					onDragStart={onDragStart}
					onPageEdit={handlePagePropertiesUpdate}
					onPageDelete={handlePageDelete}
					pageProperties={currentPageData}
					pages={insightCollection?.pages ?? []}
					noPages={noPages}
				/>
			)}
		</BtLoading>
	);
}
