const colourSwatchGeometryStyle = {
	height: '60px',
	width: '60px',
	borderRadius: '2px',
	padding: '0px',
	maxWidth: '60px',
	minWidth: '60px',
};

export { colourSwatchGeometryStyle };
