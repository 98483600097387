import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Controller, useFormContext } from 'react-hook-form';
import {
	FormControl,
	FormHelperText,
	Radio,
	RadioGroup,
	FormControlLabel,
	Skeleton,
	FormLabel,
	useTheme,
} from '@mui/material';

import { styled } from '@mui/styles';

import useFormElRequired from '../../../hooks/useFormElRequired';

const IconRadioDefault = styled('div')(({ theme }) => ({
	borderRadius: 8,
	width: 80,
	height: 60,
	borderColor: theme.palette.primary.light,
	borderWidth: 1,
	borderStyle: 'solid',
	backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : '#f5f8fa',
	'input:hover ~ &': {
		backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
	},
	'input:disabled ~ &': {
		boxShadow: 'none',
		background:
			theme.palette.mode === 'dark'
				? 'rgba(57,75,89,.5)'
				: 'rgba(206,217,224,.5)',
		borderColor: theme.palette.divider,
	},
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	color: 'GrayText',
}));

const IconRadioChecked = styled(IconRadioDefault)(({ theme }) => ({
	borderColor: theme.palette.primary.main,
	borderWidth: 2,
	borderStyle: 'solid',
	'input:hover ~ &': {
		borderColor: theme.palette.primary.main,
	},
}));

function IconRadio(props) {
	const theme = useTheme();
	return (
		<Radio
			sx={{
				'&:hover': {
					bgcolor: 'transparent',
				},
			}}
			disableRipple
			color="default"
			checkedIcon={
				<IconRadioChecked theme={theme}>
					{props.children}
				</IconRadioChecked>
			}
			icon={
				<IconRadioDefault theme={theme}>
					{props.children}
				</IconRadioDefault>
			}
			{...props}
		/>
	);
}

export default function BtFormIconRadio({
	disabled,
	isRequired,
	items,
	label,
	name,
	...other
}) {
	const {
		control,
		setValue,
		watch,
		loading,
		sending,
		trigger,
		validationSchema,
		formState: { errors },
	} = useFormContext() || { formState: {} };
	const radioValue = watch(name);
	const hasError = !!errors[name];

	const required = useFormElRequired(isRequired, validationSchema, name);

	const handleChange = useCallback(
		event => {
			if (errors?.[name]) {
				trigger(name);
			}

			setValue(name, event.target.value, {
				shouldTouch: true,
			});
		},
		[errors, name, setValue, trigger]
	);

	const isDisabled = useMemo(() => loading || sending || disabled, [
		loading,
		sending,
		disabled,
	]);

	if (loading) {
		return (
			<Skeleton animation="wave" style={{ width: '100%', height: 45 }} />
		);
	}

	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<FormControl
					variant="standard"
					disabled={isDisabled}
					error={hasError}
				>
					<FormLabel>
						{!!label
							? `${label}${!required ? ' (optional)' : ''}`
							: undefined}
					</FormLabel>
					<RadioGroup
						value={radioValue || ''}
						onChange={handleChange}
						row={true}
						sx={{ justifyContent: 'space-evenly' }}
					>
						{items?.map(item => (
							<FormControlLabel
								key={item.value}
								disabled={isDisabled}
								labelPlacement="bottom"
								{...other}
								control={
									<IconRadio {...field} value={item.value}>
										{item.icon}
									</IconRadio>
								}
								label={item?.label}
							/>
						))}
					</RadioGroup>

					{hasError && (
						<FormHelperText>{errors[name]?.message}</FormHelperText>
					)}
				</FormControl>
			)}
		/>
	);
}

BtFormIconRadio.propTypes = {
	disabled: PropTypes.bool,
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
				.isRequired,
			label: PropTypes.string.isRequired,
		})
	).isRequired,
	isRequired: PropTypes.bool,
};
