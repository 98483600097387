import { userGetById } from '../../../../../API';

export async function getUserDetails(lockedTo, setError) {
	try {
		const lockUser = await userGetById({
			userUuid: lockedTo,
		});
		return lockUser;
	} catch (error) {
		console.log(error);
		setError(true);
	}
}
