import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import PropTypes from 'prop-types';
import { tileSubscriptionsPermissions } from '../tileConstants';
import useAvailableToUser from '../../../../hooks/useAvailableToUser';
import { ScreensContext } from '../ScreensContext';

const TileContext = createContext();

const TileContextProvider = ({
	children,
	layoutDefinition,
	tileData,
	screenData,
	handleDeleteTile,
	onTileChange,
	onContainerChange,
}) => {
	const { userButtonPerms, cellSize } = useContext(ScreensContext);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	const [initializing, setInitializing] = useState(true);
	// const screenUuid = useMemo(() => screenData.uuid, [screenData.uuid]);
	// const tileUuid = useMemo(() => tileData.uuid, [tileData.uuid]);
	const screenUuid = screenData.uuid;
	const tileUuid = tileData.uuid;

	// console.log()

	const [tileSize, setTileSize] = useState(null);

	const variant = useMemo(() => tileData.variant, [tileData.variant]);

	const checkAvailability = useAvailableToUser();

	useEffect(
		() => {
			if ((tileData, userButtonPerms, variant)) {
				const isAvailable = checkAvailability({
					requiredPermissionsAll:
						tileSubscriptionsPermissions[variant].permissionsAll ||
						{},
					requiredPermissionsAny:
						tileSubscriptionsPermissions[variant].permissionsAny ||
						{},
					validSubscriptionsAny:
						tileSubscriptionsPermissions[variant]
							.subscriptionsAny || [],
				});

				// const missingPermissionsAll = isAvailable.missingPermissionsAll || [];
				if (!isAvailable.available) {
					setError(isAvailable);
					setInitializing(false);
				}

				if (tileData.variant === 'insight_collection') {
					if (tileData.data.insight_collection === '') {
						setError({ insightTileConfigRequired: true });
						setInitializing(false);
					}
					setInitializing(false);
				}

				if (tileData.variant === 'insight_visualisation') {
					if (
						tileData.data.insight_collection === '' ||
						tileData.data.insight_component === ''
					) {
						setError({ insightTileConfigRequired: true });
						setInitializing(false);
					}
					setInitializing(false);
				}

				if (tileData.variant === 'button') {
					if (tileData.data.button_type === '') {
						setError({ buttonTileConfigRequired: true });
						setInitializing(false);
					} else if (tileData.data.button_type === 'shortcut') {
						setInitializing(false);
					} else {
						const isButtonAvailable =
							userButtonPerms[tileData.data.button_type];
						// console.log(
						// 	{ isButtonAvailable },
						// 	userButtonPerms[tileData.data.button_type]
						// );

						if (isButtonAvailable.available) {
							setInitializing(false);
						} else {
							setError(isButtonAvailable);
							setInitializing(false);
						}
					}
				}

				if (tileData.variant === 'container') {
					if (tileData.data.children.length === 0) {
						setError({ containerTileConfigRequired: true });
						setInitializing(false);
					} else {
						setInitializing(false);
					}
				}

				if (
					[
						'workflow_templates',
						'workflow_submissions',
						'container',
						'information',
					].includes(tileData.variant)
				) {
					setInitializing(false);
				}
			}
		},

		[checkAvailability, tileData, userButtonPerms, variant]
	);

	return (
		<TileContext.Provider
			value={{
				layoutDefinition,
				tileData,
				screenData,
				screenUuid,
				tileUuid,
				tileSize,
				setTileSize,
				error,
				setError,
				loading,
				setLoading,
				initializing,
				setInitializing,
				handleDeleteTile,
				onTileChange,
				onContainerChange,
			}}
		>
			{children}
		</TileContext.Provider>
	);
};

TileContextProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	layoutDefinition: PropTypes.object.isRequired,
	tileData: PropTypes.object.isRequired,
	screenData: PropTypes.object.isRequired,
};

export { TileContext, TileContextProvider };
