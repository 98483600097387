import { TileContextProvider } from './TileContext';
import PropTypes from 'prop-types';
import TileContent from '../content/TileContent';

export default function Tile({
	layoutDefinition,
	handleDeleteTile,
	onTileChange,
	onContainerChange,
	component,
	screenData,
}) {
	return (
		<TileContextProvider
			layoutDefinition={layoutDefinition}
			tileData={component}
			screenData={screenData}
			handleDeleteTile={handleDeleteTile}
			onTileChange={onTileChange}
			onContainerChange={onContainerChange}
		>
			<TileContent />
		</TileContextProvider>
	);
}

Tile.propTypes = {
	layoutDefinition: PropTypes.object.isRequired,
	handleDeleteTile: PropTypes.func.isRequired,
	onTileChange: PropTypes.func.isRequired,
	onContainerChange: PropTypes.func.isRequired,
	component: PropTypes.object.isRequired,
	screenData: PropTypes.object.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};
